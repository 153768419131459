import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tag } from 'antd';
import moment from 'moment';

import { safe } from '../../../../../helpers/reduxSelectors';
import Time from '../../../../shared/Time'

const diffHrs = Time.getTzOffset();

class Reschedule extends React.Component {
  static propTypes = {
    patient_id: PropTypes.number,
    session_data: PropTypes.object,
    switchPane: PropTypes.func,
  }

  handleCancel = () => {
    this.props.switchPane(this.props.patient_id,0,1);
  }

  render() {
    const sd = safe(this.props,'session_data.session_date');
    const st = safe(this.props,'session_data.start_time',0);
    const { session_date, session_date_local, time, local_time } = Time.fixDateTime(sd, st);

    return (
      <div style={{marginLeft:'1px',background:'#fffbe6', height:'100%',padding:'10px'}}>
        <h3>Rescheduling</h3>
        <div>Use the panel to the left to search for an alternative time to
        reschedule this session.</div>

        <div style={{paddingTop:'20px'}}>
          <h3>Appointment Information</h3>
          <div>Patient: {safe(this.props,'session_data.patient.full_name')}</div>
          <div>
            Date:&nbsp;
            {moment(sd).format('ddd, M/D/YYYY')}
            {diffHrs ? (
              <>
                <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
                {st ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {session_date_local.format('MM/DD/YYYY')}</Tag> : null}
              </>
            ): null}
          </div>
          <div>
            Time:&nbsp;
            {(new Time(st)).toString(false)}
            {diffHrs ? (
              <>
                <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
                {local_time ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {(local_time)?.toString(false)}</Tag> : null}
              </>
            ): null}
          </div>
          <div>Type: {(safe(this.props,'session_data.session_type') === 1) ? 'Eval' : 'Followup'}</div>
          <div>Duration: {safe(this.props,'session_data.duration')}</div>
          <div>Provider: {safe(this.props,'session_data.user.full_name')}</div>
          <div>Location: {safe(this.props,'session_data.location.name')}</div>
          <div>Telemedicine: {(safe(this.props,'session_data.telemedicine')) ? 'Yes' : 'No'}</div>
        </div>

        <div style={{paddingTop:'20px',textAlign:'center'}}>
          <Button onClick={this.handleCancel}>
            Cancel
          </Button>
        </div>

      </div>
    )
  }
}

export default Reschedule;
