import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import moment from 'moment';

const columns: Array<ColumnI> = [
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    width: 100,
    render: (val) => moment(val).format('MM/DD/YYYY'),
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    render: (val) => moment(val).format('MM/DD/YYYY'),
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'user',
    width: 100,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 75,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    width: 75,
  },
  {
    title: 'Day of Week',
    dataIndex: 'day_of_week',
  },
  {
    title: 'Start Time',
    dataIndex: 'start_time',
  },
  {
    title: 'End Time',
    dataIndex: 'end_time',
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
  },
  {
    title: '20m FU',
    dataIndex: 'allow_20m',
  },
  {
    title: '30m FU',
    dataIndex: 'allow_30m',
  },
  {
    title: '45m FU',
    dataIndex: 'allow_45m',
  },
  {
    title: '60m FU',
    dataIndex: 'allow_60m',
  },
  {
    title: 'Allow Video',
    dataIndex: 'allow_video',
  },
  {
    title: 'Allow Intakes',
    dataIndex: 'allow_intake',
  },
  {
    title: "Age's Allowed",
    dataIndex: 'age_category',
  },
  {
    title: 'Fixed Date',
    dataIndex: 'fixed_date',
  },
];

export default columns;
