import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Spin, Radio, Button, DatePicker } from 'antd';
//import moment from 'moment';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';
import { processEventNotification } from '../../../helpers/util';

const REPORT_CLAIMSUMMARY = 7;

class ReportsLS_claim_summary extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      params: {}
    }
  }

  componentDidUpdate(prevProps) {
    processEventNotification(prevProps,this.props,'addQueue','Report Added to Queue');
  }

  queueReport = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          params: values
        },()=>{
          this.props.queueReport(REPORT_CLAIMSUMMARY,this.state.params);
        });
      }
    });
  }

  reportForm = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Form layout="inline">
          <table style={{width:'100%'}}>
            <tbody>
              <tr style={{verticalAlign:'top'}}>
                <td>
                  <Form.Item>
                  {getFieldDecorator('date_type', {
                    initialValue: 'dos'
                  })(
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value="dos">Date of Service</Radio.Button>
                      <Radio.Button value="cd">Creation Date</Radio.Button>
                    </Radio.Group>
                  )}
                  </Form.Item>
                  <Form.Item>
                   {getFieldDecorator('start_date', {
                     rules: [{ required: true, message: ' ' }],
                   })(
                     <DatePicker format='MM/DD/YYYY' placeholder="Starting Date"/>
                   )}
                   </Form.Item>
                   <Form.Item>
                    {getFieldDecorator('end_date', {
                      rules: [{ required: true, message: ' ' }],
                    })(
                      <DatePicker format='MM/DD/YYYY' placeholder="Ending Date"/>
                    )}
                   </Form.Item>
                </td>
                <td style={{textAlign:'right'}}>
                  <Button onClick={this.queueReport}>Queue Report for Download</Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      </div>
    );
  }

  render() {
    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
          </div>

          <h3>Lifestance Claims Summary</h3>

          { this.reportForm() }

          <p>This report cannot be displayed. It can only be downloaded.</p>
        </Spin>
      </div>
    )
  }


}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'reports.queue.add.processing',false)
    ) ? true : false,
    addQueue: safe(state,'reports.queue.add',{})
  }
}

export default connect(mapStateToProps,actions)(Form.create()(ReportsLS_claim_summary));
