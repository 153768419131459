import React from 'react';
import { CopyOutlined, EditOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import CreateTemplate from './CreateTemplate';
import EditTemplate from './EditTemplate';
import CopyTemplate from './CopyTemplate';

import { processEventNotification } from '../../../../../helpers/util';
import * as actions from '../../../../../actions/scheduler';
import { safe } from '../../../../../helpers/reduxSelectors';

class TemplateChooser extends React.Component {

  state = {
    editModalVisible: false,
    createModalVisible: false,
    copyModalVisible: false,
    copyModalData: null
  }

  componentDidUpdate(prevProps) {
    if (prevProps.templateList !== this.props.templateList) {
      if (this.props.templateList.length > 0) {
        if (!this.props.templateList.find(x=>x.id === this.props.selected_template_id)) {
          let id = this.props.templateList[0].id;
          this.props.templateList.forEach((x)=>{
            if (moment().isBetween(x.start_date,x.end_date,'day','[]')) {
              id = x.id;
            }
          });
          this.props.setSchedulerState({
            selected_template_id: id
          });
        }
      }
    }

    processEventNotification(prevProps,this.props,'removeTemplateEvent','Template Deleted');
  }

  showCreateModal = () => {
    this.setState({
      createModalVisible: true
    });
  }

  closeCreateModal = () => {
    this.setState({
      createModalVisible: false
    });
  }

  showEditModal = (e,template_id) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      editModalVisible: true,
      editTemplateId: template_id
    });
  }

  showCopyModal = (e,record) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      copyModalVisible: true,
      copyModalData: record
    });
  }

  closeEditModal = () => {
    this.setState({
      editModalVisible: false
    });
  }

  closeCopyModal = () => {
    this.setState({
      copyModalVisible: false
    });
  }

  selectRow = (template_id) => {
    this.props.setSchedulerState({
      selected_template_id: template_id
    });
  }

  unlock = (provider_id,template_id) => {
    const This = this;
    Modal.confirm({
      title: 'Are you sure you want to unlock this schedule template?',
      content: 'Doing so can change a clinician\'s productivity measures',
      onOk() {
        This.props.unlockTemplate(provider_id,template_id)
      },
      onCancel() {
      },
    });
  }

  getLock = (template_id,locked) => {
    if (locked) {
      return <Button icon={<LockOutlined />} style={{color:'red'}} type='link' onClick={()=>{this.unlock(this.props.provider_id,template_id)}} />;
    } else {
      return <Button icon={<UnlockOutlined />} style={{color:'green'}} type='link' onClick={()=>{this.props.lockTemplate(this.props.provider_id,template_id)}} />;
    }
  }

  displayHrRange = (a,b) => {
    const is_prescriber = safe(this.props,'provider_roles',[]).includes('prescriber');
    if (is_prescriber) {
      return ' (' + a + ' hrs)';
    }
    if (a === b) {
      return ' (' + a + ' hrs)';
    } else {
      return ' (' + a + '-' + b + ' hrs)'
    }
  }

  render() {
    const style = {
      height: '100%',
      width: '100%',
      padding: '5px'
    };
    return (
      <div style={style}>

        <Button
          type='primary'
          onClick={this.showCreateModal}
          style={{width:'100%'}}
        >
          Create New Schedule Template
        </Button>

        <table className='template-chooser' cellSpacing={10} cellPadding={10}>
          <tbody>
            { this.props.templateList.map(x=>(
              <tr
                className={(x.id === this.props.selected_template_id) ? 'template_selected' : null}
                onClick={()=>{this.selectRow(x.id)}}
                key={x.id}
              >
                <td>
                  <div className='template_daterange'>
                    { moment(x.start_date).format('M/D/YY') } -
                    { moment(x.end_date).format('M/D/YY')}
                    { this.displayHrRange(x.total_hrs,x.max_total_hrs)}
                  </div>
                  <div className='template_title'>{this.getLock(x.id,x.locked)} { x.comments }</div>
                </td>
                <td style={{textAlign:'right',verticalAlign:'center'}}>
                  <Button size='small' type='link' icon={<CopyOutlined />} onClick={(e)=>this.showCopyModal(e,x)} />
                  <Button disabled={x.locked} size='small' type='link' icon={<EditOutlined />} onClick={(e)=>this.showEditModal(e,x.id)} />
                </td>
              </tr>
            )) }
          </tbody>
        </table>

        <CreateTemplate
          visible={this.state.createModalVisible}
          onClose={this.closeCreateModal}
          provider_id={this.props.provider_id}
          provider_roles={this.props.provider_roles}
        />

        <EditTemplate
          template_id={this.state.editTemplateId}
          visible={this.state.editModalVisible}
          onClose={this.closeEditModal}
          provider_id={this.props.provider_id}
          provider_roles={this.props.provider_roles}
        />

        <CopyTemplate
          visible={this.state.copyModalVisible}
          onClose={this.closeCopyModal}
          template_id={safe(this.state.copyModalData,'id',0)}
          provider_id={this.props.provider_id}
          provider_roles={this.props.provider_roles}
          data={this.state.copyModalData}
        />

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    templateList: safe(state,'scheduler.template.list.data',[]),
    templateListEvent: safe(state,'scheduler.template.list',{}),
    selected_template_id: safe(state,'scheduler.settings.selected_template_id'),
    provider_id: safe(state,'scheduler.settings.provider_id',0),
    provider_roles: safe(state,'scheduler.settings.provider_roles',[]),
    settings: safe(state,'scheduler.settings'),
    removeTemplateEvent: safe(state,'scheduler.template.remove',{}),
  }
}

export default connect(mapStateToProps,actions)(TemplateChooser);
