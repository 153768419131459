import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import moment from 'moment';

const columns: Array<ColumnI> = [
  {
    title: 'Location Name',
    dataIndex: 'location',
    key: 'location',
    width: 200,
  },
  {
    title: 'Date of Service',
    dataIndex: 'dos',
    key: 'dos',
    width: 100,
    render: (val: string) => moment(val).format('MM/DD/YYYY'),
  },
  {
    title: 'Procedure Code',
    dataIndex: 'cpt',
    key: 'cpt',
    width: 100,
  },
  {
    title: 'Charge Amount',
    dataIndex: 'charge_amount',
    key: 'charge_amount',
    width: 100,
  },
  {
    title: 'Adjustment',
    dataIndex: 'adjustment',
    key: 'adjustment',
    width: 100,
  },
  {
    title: 'Ins Payment',
    dataIndex: 'ins_payment',
    key: 'ins_payment',
    width: 100,
  },
  {
    title: 'Pt Payment',
    dataIndex: 'pt_payment',
    key: 'pt_payment',
    width: 100,
  },
  {
    title: 'Ins Balance',
    dataIndex: 'ins_balance',
    key: 'ins_balance',
    width: 100,
  },
  {
    title: 'Pt Balance',
    dataIndex: 'pt_balance',
    key: 'pt_balance',
    width: 100,
  },
  {
    title: 'Total Balance',
    dataIndex: 'total_balance',
    key: 'total_balance',
    width: 100,
  },
  {
    title: 'AR Aging',
    dataIndex: 'ar_aging',
    key: 'ar_aging',
    width: 100,
  },
  {
    title: 'AR Type',
    dataIndex: 'ar_type',
    key: 'ar_type',
    width: 100,
  },
  {
    title: 'Patient Name',
    dataIndex: 'patient_name',
    key: 'patient',
    width: 200,
  },
  {
    title: 'HFID',
    dataIndex: 'hfid',
    key: 'hfid',
    width: 150,
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => moment(val).format('MM/DD/YYYY'),
  },
];

export default columns;
