/**
 * Patient Message History component for admins to export
 * entire message history for a given patient.
 */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spin, notification, Button } from 'antd';
import OppositeEnds from 'components/shared/OppositeEnds';
import get from 'lodash/get';
import useFetch, { CachePolicies } from 'use-http';

import PatientSelector from 'components/shared/PatientSelector';
import { snakeCase } from 'lodash';

const PatientMessageSummary: React.FC = () => {
  const topRef = useRef<HTMLDivElement>(null);
  const [patientId, setPatientId] = useState(-1);
  const [patient, setPatient] = useState<Record<string,unknown>>({});

  const patients = useSelector((state) => get(state, 'shared.patient.list.data'));
  
  const patientMessageSummary = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const patientMessageHistory = useFetch({ cachePolicy: CachePolicies.NO_CACHE });

  const isLoading = patientMessageSummary.loading;
    
  useEffect(() => {
    topRef?.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (patientId > -1) {
      patientMessageSummary.get(`/api/v2/chat-history-summary/${patientId}`);
    }
  }, [patientId]);

  useEffect(() => {
    if (patientMessageHistory.data) {
      if (!patientMessageHistory?.data?.error) {
        const element = document.createElement("a");
        const file = new Blob(
          [patientMessageHistory.data],    
          {type: 'text/plain;charset=utf-8'}
        );
        element.href = URL.createObjectURL(file);
        element.download = `message_history_${snakeCase(patient?.full_name as string)}.html`;
        document.body.appendChild(element);
        element.click();
      } else {
        handleError();
      }
    }
  }, [patientMessageHistory.data]);

  useEffect(() => {
    if (patientMessageSummary?.data?.error) {
      handleError();
    }
  }, [patientMessageSummary.data]);
  
  const onSelectPatient = (pid) => {
    const patientData = patients?.find((p) => {
      return pid === parseInt(p.id, 10);
    });
    setPatientId(pid);
    setPatient(patientData);
  };

  const handleError = () => {
    notification.error({
      message: 'Report Display Problem',
      description: patientMessageSummary?.data.message,
    });
  }

  const downloadFile = async () => {
    patientMessageHistory.get(`/api/v2/chat-history-export/${patientId}`);
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <div ref={topRef}>
          <div className="p4">
            <OppositeEnds left={(<h3>Patient Message History</h3>)} />
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <PatientSelector onSelectPatient={onSelectPatient} value={patientId} />
            </div>
          </div>
          {patient && patientId > -1 ? (
            <div className="p4">
              <h4>
                Patient: {patient?.full_name}
              </h4>
              <Button type="primary" onClick={downloadFile}>Download Message History</Button>
              <br /><br />
              {!patientMessageSummary?.data?.error && patientMessageSummary?.data ? (
                <>
                  <b>Total Chat Threads:</b> {patientMessageSummary?.data?.length}
                  <br /><br />
                  {patientMessageSummary?.data.map((chat, key) => (
                    <div key={key}>
                      <div>
                        <b>Chat Thread #{key + 1}</b>
                      </div>
                      <div>
                        <b>Members:</b> {chat.members.join(' ')}
                      </div>
                      <div>
                        <b>Messages:</b> {chat.messages}
                      </div>
                      <br />
                    </div>
                  ))}
                </>
              ): null}
            </div>
          ) : null}
        </div>
      </Spin>
    </>
  );
};

export default PatientMessageSummary;
