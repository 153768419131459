import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import moment from 'moment';
import numeral from 'numeral';
import get from 'lodash/get';

const columns: Array<ColumnI> = [
  {
    title: 'Date of Service',
    dataIndex: 'session_date',
    key: 'dos',
    width: 100,
    render: (val) => moment(val).format('MM/DD/YYYY'),
  },
  {
    title: 'Telemedicine',
    dataIndex: 'telemedicine',
    key: 'telemedicine',
    width: 50,
    render: (val: string): string => {
      return val ? 'Yes' : 'No';
    },
  },
  {
    title: 'Creation Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 100,
    render: (val: string) => moment(val).format('MM/DD/YYYY'),
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'user',
    width: 100,
    render: (_val, record) => get(record, 'user.full_name') as string,
  },
  {
    title: 'Patient',
    dataIndex: 'patient',
    key: 'patient',
    width: 100,
    render: (_val, record) => get(record, 'patient.full_name') as string,
  },
  {
    title: 'MR#',
    dataIndex: 'patient_id',
    key: 'patient_id',
    render: (_val, record) => numeral(get(record, 'patient.id')).format('000000'),
  },
  {
    title: 'DOB',
    dataIndex: 'patient_dob',
    key: 'patient_dob',
    render: (_val, record) => moment(get(record, 'patient.dob') as string).format('MM/DD/YYYY'),
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 75,
    render: (_val, record) => get(record, 'location.name') as string,
  },
];

export default columns;
