/**
 * SelectReportPopover.tsx
 * The popover button which displays the lists of possible
 * reports for the user to select. Some slight hand-holding is
 * done for the component to flow more smoothly than the default
 * implementation of the AntD Popover component.
 */
import { useContextSelector } from 'use-context-selector';
import { Popover, Button } from 'antd';
import SelectReportLists from './SelectReportPopover_/SelectReportLists';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import debounce from 'lodash/debounce';
import styles from './SelectReportPopover.style';

const SelectReportPopover: React.FC = () => {
  const popoverVisible = useContextSelector(InlineContext, (c) => c.state.popoverVisible);
  const setState = useContextSelector(InlineContext, (c) => c.setState);

  const setPopoverVisible = (popoverVisible) => {
    setState((s) => ({ ...s, popoverVisible }));
  };

  // wait slightly before opening/closing the popover when user hovers on/off
  const setPopoverVisibleDebounced = debounce((visible: boolean) => {
    setPopoverVisible(visible);
  }, 250);

  // immediately show the popover on hover
  const handleMouseEnter = () => {
    setPopoverVisible(true);
    setPopoverVisibleDebounced.cancel();
  };

  const handleMouseClick = handleMouseEnter;

  const handleMouseLeave = () => {
    setPopoverVisibleDebounced(false);
  };

  const PopoverContent = (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <SelectReportLists />
    </div>
  );

  return (
    <>
      <div role="none" style={styles.root}>
        <div style={styles.popoverWrapper}>
          <Popover placement="bottom" title={null} content={PopoverContent} visible={popoverVisible}>
            <Button
              type="dashed"
              block
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleMouseClick}
            >
              Select Report
            </Button>
          </Popover>
        </div>
      </div>
    </>
  );
};

export default SelectReportPopover;
