import React from 'react';
import { Row, Col } from 'antd';
//import moment from 'moment';
import numeral from 'numeral';

import { safe } from '../../../../helpers/reduxSelectors';

class EDI837P extends React.Component {

  renderClaim = (data) => {
    if (data) {
      return (
        <div key={data.patient_control_number}>
          <div>DIAGNOSES: {data.diagnosis.join(',')}</div>
          <Row>
            <Col span={4}>CTRL: {data.patient_control_number}</Col>
            <Col span={6}>PROV: {safe(data,'rendering_provider.firstname')} {safe(data,'rendering_provider.middlename')} {safe(data,'rendering_provider.lastname')}</Col>
            <Col span={6}>NPI: {safe(data,'rendering_provider.npi')}</Col>
            <Col span={4}>{safe(data,'service_location.name')}</Col>
            <Col span={4}>{numeral(data.charge_amount).format('$0,0.00')}</Col>
          </Row>
          {safe(data,'service_lines',[]).map(sl=>{
            return (
              <Row key={sl.service_line_control_num}>
                <Col span={4}>DOS: {sl.dos}</Col>
                <Col span={6}>CPT: {sl.cpt}</Col>
                <Col span={3}>UNITS: {sl.units}</Col>
                <Col span={3}>POS: {sl.location_type}</Col>
                <Col span={4}>DIAG: {safe(sl,'diagnosis_pointers',[]).join(',')}</Col>
                <Col span={4}>{numeral(sl.amount).format('$0,0.00')}</Col>
              </Row>
            );
          })}
        </div>
      );
    }
    return null;
  }

  renderSubscriberDetail = (data) => {
    if (data) {
      if (data.patient) {
        return (
          <div style={{paddingTop:'20px'}}>
            <div>SUB: <strong>{safe(data,'subscriber.firstname')} {safe(data,'subscriber.lastname')}</strong></div>
            <div>PT: <strong>{safe(data.patient[0],'patient.firstname')} {safe(data.patient[0],'patient.lastname')}</strong>(DOB: {safe(data.patient[0],'patient.dob')})</div>
            <div>PAYER: <strong>{safe(data,'payer.name')} ({safe(data,'payer.payer_id')})</strong></div>
            <div>{safe(data.patient[0],'claims',[]).map(x=>this.renderClaim(x))}</div>
          </div>
        );
      } else {
        return (
          <div style={{paddingTop:'20px'}}>
            <div>SUB: <strong>{safe(data,'subscriber.firstname')} {safe(data,'subscriber.lastname')}</strong>
              (DOB: {safe(data,'subscriber.dob')})</div>
            <div>PAYER: <strong>{safe(data,'payer.name')} ({safe(data,'payer.payer_id')})</strong></div>
            <div>{safe(data,'claims',[]).map(x=>this.renderClaim(x))}</div>
          </div>
        );
      }
    }
    return null;
  }

  renderBillingProvider = (data) => {
    if (data) {
      const billing_provider = safe(data,'billing_provider',{});
      return (
        <div key={billing_provider.npi}>
          <div><strong>Billing Provider</strong>: {billing_provider.name}</div>
          <div><strong>Address</strong>: {billing_provider.address1}, {billing_provider.address2},
          {billing_provider.city}, {billing_provider.state}, {billing_provider.zip}</div>
          <div><strong>EIN</strong>: {billing_provider.ein}</div>
          <div><strong>NPI</strong>: {billing_provider.npi}</div>
          <div>{safe(data,'subscriber_detail',[]).map(x=>this.renderSubscriberDetail(x))}</div>
        </div>
      );
    }
    return null;
  }

  renderBillingProviders = (data) => {
    if (data) {
      return (
        <div>
          {data.map(x=>this.renderBillingProvider(x))}
        </div>
      );
    }
    return null;
  }

  renderTransactionSet = (data) => {
    if (data) {
      return (
        <div>
          <div><strong>Submitter: </strong>{safe(data,'submitter.name')} (EIN: {safe(data,'submitter.ein')})</div>
          <div><strong>Receiver: </strong>{safe(data,'receiver.name')} (EIN: {safe(data,'receiver.ein')})</div>
          <div style={{paddingTop:'20px'}}>
            {this.renderBillingProviders(data.billing_providers)}
          </div>
        </div>
      )
    }
    return null;
  }

  renderFunctionalGroup = (data) => {
    if (data) {
      return (
        <div>
          {this.renderTransactionSet(data.transaction_set)}
        </div>
      );
    }
    return null;
  }

  renderInterchange = (data) => {
    if (data) {
      return (
        <div>
          <div><strong>Interchange Date/Time: </strong> {data.interchange_date} {data.interchange_time}</div>
          <div><strong>Interchange Control #: </strong> {data.interchange_control_number}</div>
          <div><strong>Interchange Test/Production Status: </strong> {data.usage_indicator}</div>
          <div><strong>TA1 Acknowledgement Requested: </strong> {data.acknowledgement_requested}</div>
          <div style={{paddingTop:'20px'}}>
            {this.renderFunctionalGroup(data.functional_group)}
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        {this.renderInterchange(this.props.data)}
      </div>
    );
  }
}

export default EDI837P;
/*
"subscriber_detail": [
            {
              "subscriber": {
                "type": "1",
                "lastname": "O'HALLORAN",
                "firstname": "COLLEEN",
                "id_qualifier": "MI",
                "id": "957443643"
              },
              "patient": [
                {
                  "relation_2_patient": "G8",
                  "patient": {
                    "id_qualifier": "1",
                    "lastname": "MCGREGOR",
                    "firstname": "MATTHEW",
                    "address1": "1851 S. SYCAMORE AVE.",
                    "city": "LOS ANGELES",
                    "state": "CA",
                    "zip": "900190000",
                    "dob": "19840715",
                    "gender": "M"
                  },
                  "claims": [
                    {
                      "patient_control_number": "15350",
                      "charge_amount": "98",
                      "patient_id": "15350",
                      "diagnosis": [
                        "ABK:F4324"
                      ],
                      "rendering_provider": {
                        "type": "1",
                        "lastname": "ALLIBHOY",
                        "middlename": "ZAHEEN",
                        "npi": "1386976496",
*/

/*
{
  "interchange_sender_id_qualifier": "ZZ",
  "interchange_sender_id": "AV09311993",
  "interchange_received_id_qualifier": "01",
  "interchange_receiver_id": "030240928",
  "interchange_date": "04/06/2019",
  "interchange_time": "11:14",
  "interchange_control_version_number": "00501",
  "interchange_control_number": "000000044",
  "acknowledgement_requested": "1",
  "usage_indicator": "P",
  "functional_group": {
    "functional_identifier_code": "HC",
    "application_sender_code": "V10767",
    "application_receiver_id": "030240928",
    "date": "04/06/2019",
    "time": "11:14",
    "control_group_number": "000000044",
    "responsible_agency_code": "X",
    "version_identifier": "005010X222A1",
    "transaction_set": {
      "transaction_set_id_code": "837",
      "transaction_set_control_number": "0001",
      "submitter": {
        "type": "2",
        "name": "CRYSTAL BAY SOFTWARE",
        "ein": "814425640"
      },
      "receiver": {
        "type": "2",
        "name": "OA",
        "ein": "330897513"
      },
      "billing_providers": [
        {
          "billing_provider": {
            "type": "2",
            "name": "PACIFIC COAST PSYCHIATRIC ASSOCIATES",
            "npi": "1023125465",
            "address1": "490 POST STREET",
            "address2": "SUITE 1043",
            "city": "SAN FRANCISCO",
            "state": "CA",
            "zip": "941021301",
            "ein": "421703993"
          },
          "subscriber_detail": [
            {
              "subscriber": {
                "type": "1",
                "lastname": "STRINES",
                "firstname": "THERESA",
                "id_qualifier": "MI",
                "id": "0015824108",
                "address1": "860 GEARY ST. APT. 308",
                "city": "SAN FRANCISCO",
                "state": "CA",
                "zip": "941090000",
                "dob": "19750825",
                "gender": "F"
              },
              "claims": [
                {
                  "patient_control_number": "15450",
                  "charge_amount": "88",
                  "patient_id": "15450",
                  "diagnosis": [
                    "ABK:F319",
                    "ABF:Z630"
                  ],
                  "rendering_provider": {
                    "type": "1",
                    "lastname": "WEINER",
                    "middlename": "AHNDREA",
                    "npi": "1679783906",
                    "taxonomy": "106H00000X"
                  },
                  "service_location": {
                    "type": "2",
                    "name": "TELEMEDICINE",
                    "address1": "490 POST STREET",
                    "address2": "SUITE 1043",
                    "city": "SAN FRANCISCO",
                    "state": "CA",
                    "zip": "941021301"
                  },
                  "service_lines": [
                    {
                      "cpt": "90837",
                      "amount": 88,
                      "units": 1,
                      "location_type": "11",
                      "diagnosis_pointers": "1:2",
                      "dos": "04/05/2019",
                      "service_line_control_num": "15450X90837"
                    }
                  ]
                }
              ]
            },
            {
              "subscriber": {
                "type": "1",
                "lastname": "KEPLER",
                "firstname": "DANIEL",
                "id_qualifier": "MI",
                "id": "605077863",
                "address1": "1747 CENTINELA AVE. UNIT C",
                "city": "SANTA MONICA",
                "state": "CA",
                "zip": "904030000",
                "dob": "19861003",
                "gender": "M"
              },
              "claims": [
                {
                  "patient_control_number": "15451",
                  "charge_amount": "98",
                  "patient_id": "15451",
                  "diagnosis": [
                    "ABK:F4323",
                    "ABF:F321"
                  ],
                  "rendering_provider": {
                    "type": "1",
                    "lastname": "WEINER",
                    "middlename": "AHNDREA",
                    "npi": "1679783906",
                    "taxonomy": "106H00000X"
                  },
                  "service_location": {
                    "type": "2",
                    "name": "6200 WILSHIRE BLVD",
                    "address1": "6200 WILSHIRE BLVD",
                    "address2": "SUITE 1010",
                    "city": "LOS ANGELES",
                    "state": "CA",
                    "zip": "900485811"
                  },
                  "service_lines": [
                    {
                      "cpt": "90834",
                      "amount": 98,
                      "units": 1,
                      "location_type": "11",
                      "diagnosis_pointers": "1:2",
                      "dos": "04/05/2019",
                      "service_line_control_num": "15451X90834"
                    }
                  ]
                }
              ]
            },
            {
              "subscriber": {
                "type": "1",
                "lastname": "GLASS",
                "firstname": "BRITTANY",
                "id_qualifier": "MI",
                "id": "VXB168A71114",
                "address1": "1068 65TH ST.",
                "city": "OAKLAND",
                "state": "CA",
                "zip": "946080000",
                "dob": "19800711",
                "gender": "F"
              },
              "claims": [
                {
                  "patient_control_number": "15452",
                  "charge_amount": "93",
                  "patient_id": "15452",
                  "diagnosis": [
                    "ABK:F331",
                    "ABF:F411",
                    "ABF:G4700"
                  ],
                  "rendering_provider": {
                    "type": "1",
                    "lastname": "HOFMEISTER",
                    "middlename": "ANTJE",
                    "npi": "1689826927",
                    "taxonomy": "106H00000X"
                  },
                  "service_location": {
                    "type": "2",
                    "name": "TELEMEDICINE",
                    "address1": "490 POST STREET",
                    "address2": "SUITE 1043",
                    "city": "SAN FRANCISCO",
                    "state": "CA",
                    "zip": "941021301"
                  },
                  "service_lines": [
                    {
                      "cpt": "90837",
                      "amount": 93,
                      "units": 1,
                      "location_type": "11",
                      "diagnosis_pointers": "1:2:3",
                      "dos": "04/05/2019",
                      "service_line_control_num": "15452X90837"
                    }
                  ]
                }
              ]
            },
            {
              "subscriber": {
                "type": "1",
                "lastname": "MCINTURFF",
                "firstname": "ALEX",
                "id_qualifier": "MI",
                "id": "XDP801426366",
                "address1": "1923 9TH ST APT B",
                "city": "BERKELEY",
                "state": "CA",
                "zip": "947100000",
                "dob": "19851015",
                "gender": "M"
              },
              "claims": [
                {
                  "patient_control_number": "15453",
                  "charge_amount": "93",
                  "patient_id": "15453",
                  "diagnosis": [
                    "ABK:F341"
                  ],
                  "rendering_provider": {
                    "type": "1",
                    "lastname": "HOFMEISTER",
                    "middlename": "ANTJE",
                    "npi": "1689826927",
                    "taxonomy": "106H00000X"
                  },
                  "service_location": {
                    "type": "2",
                    "name": "TELEMEDICINE",
                    "address1": "490 POST STREET",
                    "address2": "SUITE 1043",
                    "city": "SAN FRANCISCO",
                    "state": "CA",
                    "zip": "941021301"
                  },
                  "service_lines": [
                    {
                      "cpt": "90837",
                      "amount": 93,
                      "units": 1,
                      "location_type": "11",
                      "diagnosis_pointers": "1",
                      "dos": "04/04/2019",
                      "service_line_control_num": "15453X90837"
                    }
                  ]
                }
              ]
            },
            {
              "subscriber": {
                "type": "1",
                "lastname": "EVANS",
                "firstname": "MICHELLE",
                "id_qualifier": "MI",
                "id": "909034329",
                "address1": "377 BLUE DOLPHIN DRIVE",
                "city": "PORT HUENEME",
                "state": "CA",
                "zip": "930410000",
                "dob": "19820201",
                "gender": "F"
              },
              "claims": [
                {
                  "patient_control_number": "15454",
                  "charge_amount": "88",
                  "patient_id": "15454",
                  "diagnosis": [
                    "ABK:F319",
                    "ABF:F411"
                  ],
                  "rendering_provider": {
                    "type": "1",
                    "lastname": "THOMPSON",
                    "middlename": "LISA",
                    "npi": "1457673352",
                    "taxonomy": "1041C0700X"
                  },
                  "service_location": {
                    "type": "2",
                    "name": "TELEMEDICINE",
                    "address1": "490 POST STREET",
                    "address2": "SUITE 1043",
                    "city": "SAN FRANCISCO",
                    "state": "CA",
                    "zip": "941021301"
                  },
                  "service_lines": [
                    {
                      "cpt": "90837",
                      "amount": 88,
                      "units": 1,
                      "location_type": "11",
                      "diagnosis_pointers": "1:2",
                      "dos": "04/05/2019",
                      "service_line_control_num": "15454X90837"
                    }
                  ]
                }
              ]
            },
            {
              "subscriber": {
                "type": "1",
                "lastname": "HABERMAN",
                "firstname": "VALERIE",
                "id_qualifier": "MI",
                "id": "948461846",
                "address1": "691 POST STREET",
                "city": "SAN FRANCISCO",
                "state": "CA",
                "zip": "941090000",
                "dob": "19860506",
                "gender": "F"
              },
              "claims": [
                {
                  "patient_control_number": "15455",
                  "charge_amount": "98",
                  "patient_id": "15455",
                  "diagnosis": [
                    "ABK:F331",
                    "ABF:F411"
                  ],
                  "rendering_provider": {
                    "type": "1",
                    "lastname": "THOMPSON",
                    "middlename": "LISA",
                    "npi": "1457673352",
                    "taxonomy": "1041C0700X"
                  },
                  "service_location": {
                    "type": "2",
                    "name": "TELEMEDICINE",
                    "address1": "490 POST STREET",
                    "address2": "SUITE 1043",
                    "city": "SAN FRANCISCO",
                    "state": "CA",
                    "zip": "941021301"
                  },
                  "service_lines": [
                    {
                      "cpt": "90834",
                      "amount": 98,
                      "units": 1,
                      "location_type": "11",
                      "diagnosis_pointers": "1:2",
                      "dos": "04/05/2019",
                      "service_line_control_num": "15455X90834"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      "segment_count": 137,
      "control_number": "0001"
    },
    "number_of_transaction_sets": "1",
    "control_group_number2": "000000044"
  },
  "number_of_functional_groups": 1,
  "interchange_control_number2": "000000044"
}
*/
