import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Button } from 'antd';

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

class CreditCardForm extends React.Component {
  state = {
    errorMessage: '',
  };

  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    if (this.props.stripe) {
      this.props.stripe.createToken().then(this.props.handleResult);
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    return (
      <div className="cc-entry">
        <form onSubmit={this.handleSubmit.bind(this)}>
          <label>
            New Credit Card Details
            <CardElement
              onChange={this.handleChange}
              {...createOptions()}
            />
          </label>
          <div className="error" role="alert">
            {this.state.errorMessage}
          </div>
          <div style={{paddingTop:'10px'}}>
            <Button type='primary' htmlType='submit'>Store Credit Card Details</Button>
          </div>
        </form>
      </div>
    );
  }
}

export default injectStripe(CreditCardForm);
