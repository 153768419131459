import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Radio, Switch, Select, DatePicker, Table, notification } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import * as actions from '../../../../../actions/scheduler';

import { safe } from '../../../../../helpers/reduxSelectors';
import Time from '../../../../shared/Time';

class IntakesEntryModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.getProviderLocations(this.props.activeUserId);
      this.props.form.resetFields();
    }

    if (this.props.createIntakeEvent !== prevProps.createIntakeEvent) {
      if (this.props.createIntakeEvent.success) {
        notification.success({
          message: 'Intake Time Created',
        });
      } else if (this.props.createIntakeEvent.error) {
        notification.error({
          message: 'Error Creating Intake Time',
          description: safe(this.props.createIntakeEvent, 'error.data.message'),
        });
      }
    }
  }

  handleOk = async () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await this.props.intakesNewIntakeTime(values);
        this.props.onClose();
      }
    });

  }

  handleCancel = () => {
    this.props.onClose();
  }

  render() {
    const FormItem = Form.Item;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
      },
    };
    const timeRange = Time.range(new Time(600),new Time(2200),5);

    const defaultLocation = (this.props.providerLocationsList.length > 0) ? this.props.providerLocationsList[0].id : null;

    return (
      <Modal
        title='Enter Intake Time'
        visible={this.props.visible}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        width={400}
        centered={true}
      >
        <Form className='compressed-form' autoComplete='false'>

          <FormItem {...formItemLayout} label="Date">
            {getFieldDecorator('fixed_date', {
              rules: [{ required: true, message: " " }],
              initialValue: moment()
            })(
              <DatePicker format='M/D/YYYY'/>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Starting Time">
            {getFieldDecorator('start_time', {
              rules: [{ required: true, message: " " }],
            })(
              <Select>
                {timeRange.map(x=><Option key={x.id} value={x.id}>{x.name}</Option>)}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Duration">
            {getFieldDecorator('duration', {
              rules: [{ required: true, message: " " }],
              initialValue: 60,
            })(
              <Select>
                <Option value={60}>1 hr</Option>
                <Option value={120}>2 hrs</Option>
                <Option value={180}>3 hrs</Option>
                <Option value={240}>4 hrs</Option>
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Patient Age">
            {getFieldDecorator('age_category', {
              initialValue: 0,
            })(
              <Select>
                <Option value={0}>Adult/Child</Option>
                <Option value={1}>Adult Only</Option>
                <Option value={2}>Child Only (&lt; 18)</Option>
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="TeleMedicine">
            {getFieldDecorator('allow_video', {
              rules: [{ required: true, message: " " }],
              initialValue: 1,
            })(
              <Select>
                <Option value={0}>Video/Office</Option>
                <Option value={1}>Video Only</Option>
                <Option value={2}>Office Only</Option>
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Location">
            {getFieldDecorator('location_id', {
              rules: [{ required: true, message: " " }],
              initialValue: defaultLocation,
            })(
              <Select>
                {this.props.providerLocationsList.map(x=><Select.Option value={x.id} key={x.id}>{x.name}</Select.Option>)}
              </Select>
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeUserId: safe(state,'shared.domain.activeUser.id'),
    providerLocationsList: safe(state,'scheduler.provider.locations.data',[]),
    createIntakeEvent: safe(state,'scheduler.provider_intakes.newitem',{}),
  }
}

export default connect(mapStateToProps,actions)(Form.create()(IntakesEntryModal));
