/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * Productivity.tsx
 */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, DatePicker, Modal, Spin, Switch, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import useFetch, { CachePolicies } from 'use-http';
import get from 'lodash/get';
import moment, { Moment } from 'moment';
import Productivity from '../Productivity';
import OppositeEnds from '../../../shared/OppositeEnds';
import { useContextSelector } from 'use-context-selector';
import DirectorContext from './DirectorContext';

interface PropsI {
  active: boolean;
  reload?: boolean;
}

const DirectorProductivity: React.FC<PropsI> = ({ active, reload }: PropsI) => {
  const activeUserId = useSelector((state) => get(state, 'shared.domain.activeUser.id'));

  const [selected, setSelected] = useState<Array<number>>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editReviewDate, setEditReviewDate] = useState<null | Moment>(null);
  const [editReviewId, setEditReviewId] = useState<null | number>(null);

  const directorListAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const directorAddAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const directorRemoveAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const updateProviderTermAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const updateProviderAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const getProviderList = useContextSelector(DirectorContext, (c) => c.getProviderList);

  const isBusy = getProviderList?.loading ||
  directorAddAPI?.loading ||
  directorListAPI?.loading ||
  directorRemoveAPI?.loading ||
  updateProviderTermAPI?.loading;

  const term = editReviewId !== null ? directorListAPI?.data?.find((r) => r.id === editReviewId) : null;

  const getDirectorList = () => directorListAPI.get(`/api/v1/director`);
  const updateProviderTerm = (providerId, termId, data) => updateProviderTermAPI.put(`/api/v1/scheduler/provider/${providerId}/term/${termId}`, data);
  const updateProvider = (providerId, data) => updateProviderAPI.put(`/api/v1/user/${providerId}`, data);

  useEffect(() => {
    if (active) {
      getDirectorList();
    }
  }, [active, reload]);

  useEffect(() => {
    if (term?.review_date) {
      setEditReviewDate(moment(term.review_date));
    }
  }, [term]);

  const directorRemove = async (e: any, id: number) => {
    e.stopPropagation();
    await directorRemoveAPI.del(`/api/v1/director/${id}`);
    getDirectorList();
  };

  const productivityPane = (record) => {
    if (selected.length === 0 || record.id != selected[0]) {
      return null;
    }

    const provider = getProviderList.data.find(x=>x.id===record.id);
    const isPrescriber = provider.roles && provider.roles.includes('prescriber');

    return (
      <div>
        <Productivity
          userId={record.id}
          userName={record.name}
          userIsPrescriber={isPrescriber}
          userEmploymentType={provider.employment_type}
        />
      </div>
    )
  };

  const onExpand = (expand,record) => {
    const newSelected = expand ? [record.id] : [];
    setSelected(newSelected);
  }

  const onUpdateReviewDate = async () => {
    await updateProviderTerm(term.id, term.term_id, {
      start_date: term.term_start_date,
      end_date: term.term_end_date,
      productivity_start_date: term.productivity_start_date,
      review_date: editReviewDate?.format('YYYY-MM-DD')
    });
    setModalVisible(false);
    return getDirectorList();
  };

  const toggleEditModal = async(e: any, id: number) => {
    e.stopPropagation();
    setEditReviewId(id);
    setModalVisible(true);
  };

  const onEditReviewDateChange = async(date: Moment | null) => {
    setEditReviewDate(date);
  };

  const onCloseModal = () => {
    setEditReviewId(null);
    setEditReviewDate(null);
    setModalVisible(false);
  };

  const onChangeSupervised = async (userId, checked) => {
    const supervisor_user_id = checked ? activeUserId : null;
    await updateProvider(userId, { supervisor_user_id });
    if (checked) {
      await updateProvider(supervisor_user_id, { is_supervisor: true });
    }
    return getDirectorList();
  };

  const columns: Array<any> = [{
    title: 'Provider',
    dataIndex: 'name'
  },{
    title: 'Current Term',
    dataIndex: 'term_start_date',
    render: (_value,record) => {
      return (
        <span>
          {moment(record.term_start_date).format('MM/DD/YYYY')}
          -
          {moment(record.term_end_date).format('MM/DD/YYYY')}
        </span>
      );
    }
  },{
    title: 'Notes > 2d',
    align: 'right',
    dataIndex: 'notes_2'
  },{
    title: 'Notes > 7d',
    align: 'right',
    dataIndex: 'notes_7'
  },{
    title: 'Review Date',
    dataIndex: 'review_date',
    render: (value,record) => {
      return (
        <>
          {value ? moment(value).format('MM/DD/YYYY') : null}&nbsp;
          <Button type="link" onClick={(e)=>toggleEditModal(e, record.id)} style={{ zIndex: 999 }}>
            <EditOutlined />
          </Button>
        </>
      );
    }
  },{
    title: 'Supervising',
    dataIndex: 'supervisor_id',
    render: (_value,record) => {
      const isSupervising = record.supervisor_id === activeUserId;
      return (
        <span onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
          <Switch checked={isSupervising} onChange={(checked) => onChangeSupervised(record.id, checked)} style={{ zIndex: 999 }}  />&nbsp;
          {record?.supervisor?.full_name}
        </span>
      );
    }
  },
  {
    title: '',
    width: '60',
    align: 'right',
    render: (_value,record) => {
      return (
        <Button type="link" onClick={(e)=>directorRemove(e, record.id)} style={{ zIndex: 999 }}>
          <DeleteOutlined />
        </Button>
      );
    }
  }];

  if (directorListAPI.response && !directorListAPI?.data?.length) {
    return (
      <div>
        No providers have been added to your list.
      </div>
    )
  }

  return (
    <Spin spinning={isBusy}>
      <OppositeEnds right={(<a href={`/api/v1/director?csv=1`} style={{ padding: '1.5rem'}}>Clinicians CSV</a>)} />
      
      <Table
        dataSource={directorListAPI?.data}
        columns={columns}
        size="small"
        rowKey="id"
        pagination={false}
        expandRowByClick={true}
        onExpand={onExpand}
        expandedRowKeys={selected}
        expandedRowRender={(record) => productivityPane(record)}
      />
      <Modal
        title={"Edit Review Date"}
        visible={modalVisible}
        onCancel={onCloseModal}
        okText={"Update"}
        width={650}
        onOk={onUpdateReviewDate}
      >
        <DatePicker onChange={(e)=>onEditReviewDateChange(e)} value={editReviewDate} format="MM/DD/YYYY" />
      </Modal>
    </Spin>
  );
};

export default DirectorProductivity;
