/**
 * SelectReportLists.tsx
 * The output for the Popover wrapper SelectReportPopover, which displays multiple
 * lists depending on their list number defined within the reports module.
 * Anytime a report is selected, the popover is manually hidden and user sent to
 * the report view they selected.
 */
import { useContextSelector } from 'use-context-selector';
import { Button, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import styles from './SelectReportLists.style';

interface ListItemI {
  wrapperName: string;
  name: string;
}

type ListT = Array<ListItemI>;

const list1: ListT = [
  {
    wrapperName: 'completed-sessions',
    name: 'Completed Sessions List',
  },
  {
    wrapperName: 'adjustments',
    name: 'Adjustments Report',
  },
  {
    wrapperName: 'lyra-claims',
    name: 'Lyra Sessions to Bill',
  },
  {
    wrapperName: 'duplicate-sessions',
    name: 'Duplicate Sessions',
  },
  {
    wrapperName: 'intakes',
    name: 'Filled/Unfilled Intakes Report',
  },
  {
    wrapperName: 'unsigned-missing',
    name: 'Unsigned and Missed Session Report',
  },
  {
    wrapperName: 'patient-credits',
    name: 'Patient Credits',
  },
  {
    wrapperName: 'patient-unapplied-payments',
    name: 'Patient Unapplied Payments',
  },
  {
    wrapperName: 'session-counts',
    name: 'Session Counts',
  },
  {
    wrapperName: 'patients-on-hold-balances',
    name: 'Patients On-Hold with Balances',
  },
  {
    wrapperName: 'posted-payments-counts',
    name: 'Posted Payments Counts',
  },
];

const list2: ListT = [
  {
    wrapperName: 'payroll',
    name: 'Payroll Report',
  },
  {
    wrapperName: 'charge-collection',
    name: 'Charge Collection Report',
  },
  {
    wrapperName: 'hourly-employees-payroll',
    name: 'Hourly Provider Payroll',
  },
  {
    wrapperName: 'chart-audit-log',
    name: 'Chart Audit Log',
  },
  {
    wrapperName: 'employee-settings',
    name: 'Employee Settings Report',
  },
  {
    wrapperName: 'amd-era-missing-items',
    name: 'AMD ERA Missing Items',
  },
  {
    wrapperName: 'patient-audit-log',
    name: 'Patient Audit Log',
  },
  {
    wrapperName: 'amd-transition',
    name: 'AMD Transition',
  },
];

const list3: ListT = [
  {
    wrapperName: 'patients',
    name: 'Patient List',
  },
  {
    wrapperName: 'evals',
    name: 'List of Evals',
  },
  {
    wrapperName: 'provider-open-times',
    name: 'Open Times',
  },
  {
    wrapperName: 'schedule-templates',
    name: 'Provider Schedule Templates',
  },
  {
    wrapperName: 'anthem-uncredentialed-sessions',
    name: 'Anthem Uncredentialed Sessions',
  },
];

const list4: ListT = [
  {
    wrapperName: 'lifestance-tx-detail-adjustments',
    name: 'Transaction Detail - Adjustments',
  },
  {
    wrapperName: 'lifestance-tx-detail-charges',
    name: 'Transaction Detail - Charges',
  },
  {
    wrapperName: 'lifestance-tx-detail-payments',
    name: 'Transaction Detail - Payments',
  },
  {
    wrapperName: 'lifestance-claim-summary',
    name: 'Claim Summary',
  },
  {
    wrapperName: 'lifestance-ar-aging',
    name: 'AR Aging',
  },
  {
    wrapperName: 'lifestance-ar-aging-detail',
    name: 'AR Aging Detail',
  },
  {
    wrapperName: 'lifestance-ar-carrier-aging',
    name: 'AR Carrier Aging',
  },
  {
    wrapperName: 'lifestance-patient-totals',
    name: 'Patient Totals',
  },
  {
    wrapperName: 'lifestance-future-sessions',
    name: 'Scheduled Future Sessions',
  },
  {
    wrapperName: 'lifestance-optum-collections',
    name: 'Optum Collections',
  },
];

const SelectReportLists: React.FC = () => {
  const history = useHistory();
  const setState = useContextSelector(InlineContext, (c) => c.setState);

  const setPopoverVisible = (popoverVisible) => {
    setState((s) => ({ ...s, popoverVisible }));
  };

  const openReport = (wrapperName: string) => {
    setState((s) => ({ ...s, inlineData: [] }));
    setPopoverVisible(false);
    setTimeout(() => history.push(`/reports/${wrapperName}`));
  };

  const filterList = (list: ListT) => {
    return (
      <ul id={`list-${list}`} style={styles.list}>
        {list.map((report, key: number) => (
          <li key={key}>
            <Button size="small" style={styles.button} type="link" onClick={() => openReport(report.wrapperName)}>
              {report.name}
            </Button>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <h3>PCPA</h3>
      <div style={styles.root}>
        {filterList(list1)}
        {filterList(list2)}
        {filterList(list3)}
      </div>
      <Divider />
      <h3>Lifestance</h3>
      <div style={styles.root}>{filterList(list4)}</div>
    </div>
  );
};

export default SelectReportLists;
