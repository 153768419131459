/**
 * DateTypeToggle.tsx
 * The bottom half of the QueueReports modal form which contains the
 * parameters for the report itself.
 */
import { useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Form, Radio } from 'antd';
import formItemRules from 'components/App_/ReportsV2_/formItemRules';
import QueueContext from 'components/App_/ReportsV2_/QueueContext';

const DateType: React.FC = () => {
  const form = useContextSelector(QueueContext, (c) => c.state.form);
  const [dateType, setDateType] = useState<number | undefined>();

  const onDateTypeChange = (e) => {
    const dateType = e.target.value;
    setDateType(dateType);
    form?.setFieldsValue({ dateType });
  };

  return (
    <>
      <h5>Date Type</h5>
      <Form.Item style={{ display: 'inline-block' }} className="m0" name="dateType" rules={formItemRules.dateType}>
        <Radio.Group buttonStyle="solid" onChange={onDateTypeChange} value={dateType} size="small">
          <Radio.Button value="dos">Date of Service</Radio.Button>
          <Radio.Button value="cd">Creation Date</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default DateType;
