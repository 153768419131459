/**
 * Chart Access control component for admins to control which patients need
 * this enabled, and which providers have chart access to an enabled
 * patient's chart.
 */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Spin, Modal, List, Typography } from 'antd';
import OppositeEnds from 'components/shared/OppositeEnds';
import get from 'lodash/get';
import useFetch, { CachePolicies } from 'use-http';

import PatientSelector from 'components/shared/PatientSelector';
import ProviderSelector from 'components/shared/ProviderSelector';

const ChartAccessControl: React.FC = () => {
  const topRef = useRef<HTMLDivElement>(null);
  const [patientId, setPatientId] = useState(-1);
  const [patient, setPatient] = useState<Record<string,unknown>>({});
  const [enabled, setEnabled] = useState<null | boolean>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const patients = useSelector((state) => get(state, 'shared.patient.list.data'));
  
  const patientAccess = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const toggleAccess = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const addAccess = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const removeAccess = useFetch({ cachePolicy: CachePolicies.NO_CACHE });

  const patientHasChartAccess = get(patientAccess, 'data.chart_access', false);

  const isLoading = patientAccess.loading || toggleAccess.loading;
    
  useEffect(() => {
    topRef?.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (patientId > -1) {
      patientAccess.get(`/api/v2/chart-access-control/${patientId}`);
    }
  }, [patientId]);

  useEffect(() => {
    if (typeof enabled === 'boolean') {
      if (enabled) {
        onCloseModal();
        onOk();
      }
    }
  }, [enabled]);

  const onSelectPatient = (pid) => {
    const patientData = patients?.find((p) => {
      return pid === parseInt(p.id, 10);
    });
    setPatientId(pid);
    setPatient(patientData);
  };

  const onSelectProvider = async (provider) => {
    await addAccess.post(`/api/v2/chart-access-control/${patientId}/${provider.id}`);
    patientAccess.get(`/api/v2/chart-access-control/${patientId}`);
  };

  const toggleChartAccess = async (en: boolean) => {
    setModalVisible(!en);
    setEnabled(en);
  };

  const removeProviderAccess = async (pid: number) => {
    await removeAccess.delete(`/api/v2/chart-access-control/${patientId}/${pid}`);
    patientAccess.get(`/api/v2/chart-access-control/${patientId}`);
  };

  const onCloseModal = () => {
    if (modalVisible) {
      setModalVisible(false);
    }
  };

  const onOk = async () => {
    onCloseModal();
    await toggleAccess.post(`/api/v2/chart-access-control/${patientId}`, { enabled });
    patientAccess.get(`/api/v2/chart-access-control/${patientId}`);
  };
  
  return (
    <>
      <Spin spinning={isLoading}>
        <div ref={topRef}>
          <div className="p4">
            <OppositeEnds left={(<h3>Chart Access</h3>)} />
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <PatientSelector onSelectPatient={onSelectPatient} value={patientId} />
            </div>
          </div>
          {patientId > -1 ? (
            <div className="p4">
              <h4>
                Patient: {patient?.full_name}
              </h4>
              {!patientAccess.data ? null : (
                <>
                  {patientHasChartAccess ? (
                  <>
                    <OppositeEnds
                      left={(
                        <ProviderSelector
                          onSelect={onSelectProvider}
                        />
                      )}
                      right={(
                        <Button danger onClick={() => toggleChartAccess(false)} disabled={toggleAccess.loading}>
                          Disable Chart Access
                        </Button>
                      )}
                    />

                    <div className="mt4">
                      <List
                        header={<div>Providers with access:</div>}
                        bordered
                        dataSource={patientAccess?.data?.chart_access_control}
                        renderItem={(item: any) => (
                          <List.Item>
                            <Typography.Text mark>{item?.provider?.full_name}</Typography.Text>
                            <Button danger size="small" onClick={() => removeProviderAccess(item?.provider?.id)} disabled={removeAccess.loading}>
                              Revoke
                            </Button>
                          </List.Item>
                        )}
                      />
                    </div>
                  </>
                ) : (
                  <Button type="primary" onClick={() => toggleChartAccess(true)} disabled={toggleAccess.loading}>
                    Enable Chart Access
                  </Button>
                )}
                </>
              )}
            </div>
          ) : null}
        </div>
      </Spin>
      <Modal
        title={"Remove Chart Access"}
        visible={modalVisible}
        onCancel={onCloseModal}
        okText={"Yes"}
        width={500}
        onOk={onOk}
      >
        Are you sure you want to disable chart access?
        <br/><br/>
        This will remove <b>all provider's chart access</b> and return the patient chart to normal access mode.
      </Modal>
    </>
  );
};

export default ChartAccessControl;
