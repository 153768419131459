/**
 * PatientSelector.tsx
 */
import { useEffect, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { AutoComplete, Form } from 'antd';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import useFetch, { CachePolicies } from 'use-http';

const style = {
  width: '300px',
};

const PatientSelector: React.FC = () => {
  const form = useContextSelector(InlineContext, (c) => c.state.form);
  const patientsAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const [query, setQuery] = useState('');
  const patientNotesListURL = `/api/v1/patient?type=1&query=${query}&archive=1&onhold=1`;
  const [patient, setPatient] = useState<Record<string, string>>();

  useEffect(() => {
    const getPatients = async () => {
      await patientsAPI.get(patientNotesListURL);
    };
    getPatients();
  }, [query]);

  const onChange = (value) => {
    const patients = getSource();
    const foundPatient = patients.find((p) => p.value === value);
    setPatient(foundPatient);
    const patientId = foundPatient?.value;
    form?.setFieldsValue({ patientId });
  };

  const onSelect = onChange;

  const onSearch = (value) => {
    setQuery(value);
  };

  const getSource = () => {
    if (patientsAPI.data) {
      return patientsAPI.data.map((item) => {
        let label = item.full_name + ' (' + item.id + ')';
        if (!item.open) {
          label = '@' + label;
        }
        if (item.on_hold) {
          label = '#' + label;
        }
        if (item.oncareteam) {
          label = '> ' + label;
        }
        label = label.trim();
        const value = item.id;
        return {
          value, // patient ID
          label, // patient name + id string
        };
      });
    }
    return [];
  };

  return !patientsAPI.data ? null : (
    <>
      <Form.Item name="patientId" hidden />
      <AutoComplete
        allowClear
        style={style}
        placeholder="Search by patient name, id or dob"
        value={patient?.label}
        options={getSource()}
        onSearch={onSearch}
        onChange={onChange}
        onSelect={onSelect}
        defaultActiveFirstOption={false}
        className="mr4"
      />
    </>
  );
};

export default PatientSelector;
