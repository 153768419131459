import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import numeral from 'numeral';
import get from 'lodash/get';

const columns: Array<ColumnI> = [
  {
    title: 'Patient ID',
    dataIndex: 'id',
    render: (_val, record) => numeral(get(record, 'patients.id', 0)).format('000000'),
  },
  {
    title: 'Patient',
    dataIndex: 'full_name',
    render: (_val, record) => get(record, 'patients.full_name') as string,
  },
  {
    title: 'Patient Unapplied',
    dataIndex: 'pt_unapplied',
    render: (val) => numeral(val).format('$0.00'),
  },
];

export default columns;
