import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import numeral from 'numeral';

const columns: Array<ColumnI> = [
  {
    title: 'Record #',
    dataIndex: 'billingPatientId',
  },
  {
    title: 'Patient Name',
    dataIndex: 'patient',
  },
  {
    title: 'Patient Insurance',
    dataIndex: 'insurance',
  },
  {
    title: 'Date of Service',
    dataIndex: 'dos',
  },
  {
    title: 'Provider',
    dataIndex: 'renderingProvider',
  },
  {
    title: 'Date Appointment Scheduled',
    dataIndex: 'apptSchedAt',
  },
  {
    title: 'Appointment Scheduled By',
    dataIndex: 'apptCreatedBy',
  },
  {
    title: 'Insurance Profile Added',
    dataIndex: 'insuranceProfileAdddedAt',
  },
  {
    title: 'Total Outstanding Balance',
    dataIndex: 'outstanding',
    render: (value) => numeral(value).format('$0,0.00'),
  },
];

export default columns;
