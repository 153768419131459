import React from 'react';
import { Table, Button, Spin } from 'antd';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { Link } from 'react-router-dom';

import { safe } from '../../../helpers/reduxSelectors';
import * as billing_actions from '../../../actions/billing';
import * as shared_actions from '../../../actions/shared';
import { processEventNotification } from '../../../helpers/util';

class PtPaymentApply extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedRows: []
    }
    this.pageSize = 15;
  }

  componentDidMount() {
    this.props.getPtPaymentApplyList(this.props.pt_payment_apply_page,this.pageSize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pt_payment_apply_page !== this.props.pt_payment_apply_page) {
      this.props.getPtPaymentApplyList(this.props.pt_payment_apply_page,this.pageSize);
    }
    processEventNotification(prevProps,this.props,'applyEvent','Payments Applied');
  }

  applyAmount = (id) => {
    this.props.applyPtPayment(id,this.props.pt_payment_apply_page,this.pageSize);
  }

  tableChange = (x) => {
    this.props.setBillingState({
      pt_payment_apply_page:x.current
    });
  }

  render() {
    const columns = [{
      title: 'Patient',
      dataIndex: 'id',
      width: 200,
      render: (value,record) => {
        return <Link to={'/billing/patients/'+value}>{safe(record,'patients.full_name','')}</Link>
      }
    },{
      title: 'Amount Due',
      dataIndex: 'pt_outstanding',
      width: 200,
      align: 'right',
      render: (value) => numeral(value).format('$0,0.00')
    },{
      title: 'Unapplied Payments',
      dataIndex: 'pt_total_collected',
      width: 200,
      align: 'right',
      render: (value,record) => numeral(record.pt_total_collected-record.pt_paid).format('$0,0.00')
    },{
      title: 'Action',
      align: 'left',
      render: (value,record) => {
        return (
          <Button onClick={()=>this.applyAmount(record.id)}>Apply</Button>
        );
      }
    }];

    return (
      <div className='main'>
        <Spin spinning={this.props.isBusy}>
          <h3>Apply Patient Payments</h3>
          <Table
            columns={columns}
            dataSource={this.props.rows}
            bordered={false}
            size='small'
            onChange={this.tableChange}
            rowKey='id'
            pagination={{
              current: this.props.pt_payment_apply_page,
              pageSize: this.pageSize,
              total: this.props.count
            }}
          />
        </Spin>
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.ptapplylist.list.processing',false) ||
      safe(state,'billing.ptapplylist.apply.processing',false)
    ) ? true : false,
    rows: safe(state,'billing.ptapplylist.list.data.rows',[]),
    count: safe(state,'billing.ptapplylist.list.data.count',1),
    pt_payment_apply_page: safe(state,'shared.domain.billing.pt_payment_apply_page',1),
    applyEvent: safe(state,'billing.ptapplylist.apply')
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(PtPaymentApply);
