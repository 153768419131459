import React from 'react';
import moment from 'moment';

import { safe } from '../../../../../../helpers/reduxSelectors';

class HFNote extends React.Component {

  renderSignature = () => {
    const providerName = safe(this.props,'data.provider.full_name');
    const signedDateTime = safe(this.props,'data.signedAt',null);

    return (signedDateTime ? (
      <div className='chartnotesignature'>
        Electronically signed by {providerName} on {moment(signedDateTime).format("MM/DD/YYYY")}
      </div>
    ) : null);
  }

  render() {
    return (
      <div>
        <div className='chartnotetitle'>Health Fusion Note</div>
        <div>
          <table style={{borderCollapse:'separate',borderSpacing:'0px 2px'}}>
            <tbody>
              <tr>
                <td className='chartnotefieldlabel' style={{width:'150px'}}>Provider:</td>
                <td>{safe(this.props,'data.provider.full_name')}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div dangerouslySetInnerHTML={{
          __html: '<div id="hfnote">' + safe(this.props,'data.oldnote','') + '</div>'
        }} />

        {this.renderSignature()}
      </div>
    );
  }
}

export default HFNote;
