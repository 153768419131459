/**
 * DayOfMonthInput.tsx
 */
import { useContextSelector } from 'use-context-selector';
import { Form, InputNumber } from 'antd';
import isInteger from 'lodash/isInteger';
import formItemRules from 'components/App_/ReportsV2_/formItemRules';
import QueueContext from 'components/App_/ReportsV2_/QueueContext';

const DayOfMonthInput: React.FC = () => {
  const form = useContextSelector(QueueContext, (c) => c.state.form);
  const dayOfMonth = useContextSelector(QueueContext, (c) => c.state.dayOfMonth);
  const setState = useContextSelector(QueueContext, (c) => c.setState);

  const onDayInputChange = (dayOfMonth) => {
    if (isInteger(dayOfMonth)) {
      form?.setFieldsValue({ dayOfMonth });
      setState((s) => ({ ...s, dayOfMonth }));
    }
  };

  return (
    <div className="mt4">
      <h5>Specific Day</h5>
      <Form.Item name="dayOfMonth" className="m0" rules={formItemRules.dayOfMonth}>
        <InputNumber min={1} max={31} value={dayOfMonth} onChange={onDayInputChange} size="small" />
      </Form.Item>
    </div>
  );
};

export default DayOfMonthInput;
