import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';
import { Spin, Select } from 'antd';

import { safe, isAdmin, isClinician, isClinicalSupervisor } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/emr';


class Caseload extends React.Component {
  componentDidMount() {
    this.props.getCaseload();
    this.props.getProvidersList();
  }

  renderActive() {
    const ap = this.props.data.filter(x=>x.active);
    if (ap.length === 0) {
      return (
        <div>No patients</div>
      )
    }

    let counter = 1;
    return (
      <table style={{width:'100%'}}>
        <tbody>
          <tr>
            <th></th>
            <th>Patient</th>
            <th>MR #</th>
            <th>Began Treatment</th>
            <th>Last Session</th>
            <th>Days</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>

          { ap.map(x=>(
            <tr key={counter}>
              <td>{counter++}</td>
              <td><Link to={'/emr/patients/'+x.id}>{x.name}</Link></td>
              <td>{numeral(x.id).format('000000')}</td>
              <td>{moment(x.treatment_start).format('MM/DD/YYYY')}</td>
              <td>{(moment(x.last_session).isValid()) ? moment(x.last_session).format('MM/DD/YYYY') : ''}</td>
              <td>{x.last_session_days}</td>
              <td>{x.email}</td>
              <td>{x.phone}</td>
            </tr>
          )) }
        </tbody>
      </table>
    );
  }

  renderInactive() {
    const iap = this.props.data.filter(x=>!x.active);
    if (iap.length === 0) {
      return (
        <div>No patients</div>
      )
    }

    let counter = 1;

    return (
      <table style={{width:'100%'}}>
        <tbody>
          <tr>
            <th></th>
            <th>Patient</th>
            <th>First Session</th>
            <th>Final Session</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>

          { iap.map(x=>(
            <tr key={counter}>
              <td>{counter++}</td>
              <td><Link to={'/emr/patients/'+x.id}>{x.name}</Link></td>
              <td>{moment(x.treatment_start).format('MM/DD/YYYY')}</td>
              <td>{moment(x.treatment_end).format('MM/DD/YYYY')}</td>
              <td>{x.email}</td>
              <td>{x.phone}</td>
            </tr>
          )) }
        </tbody>
      </table>
    );
  }

  renderCaseload = () => {
    return (
      <div>
        <h3>Active Caseload</h3>
        {this.renderActive()}

        <h3 style={{paddingTop:'30px'}}>Patients Previously Seen</h3>
        {this.renderInactive()}
      </div>
    );
  }

  renderContents = () => {
    if (this.props.isAdmin || this.props.isClinicalSupervisor) {
      return (
        <div>
          <Select onChange={this.props.getCaseloadForProvider} style={{width:'300px',paddingBottom: '20px'}} placeholder='Select a Provider'>
            { this.props.userList.map(x=><Select.Option value={x.id} key={x.id}>{x.full_name}</Select.Option>) }
          </Select>
          { this.renderCaseload() }
        </div>
      )
    } else if (this.props.isClinician) {
      return this.renderCaseload();
    }
    return null;
  }

  render() {
    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          { this.renderContents() }
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'emr.caseload.processing',false) ||
      safe(state,'emr.providers.list.processing',false)
    ) ? true : false,
    data: safe(state,'emr.caseload.data',[]),
    userList: safe(state,'emr.providers.list.data',[]),
    isAdmin: isAdmin(state),
    isClinicalSupervisor: isClinicalSupervisor(state),
    isClinician: isClinician(state)
  }
}

export default connect(mapStateToProps,actions)(withRouter(Caseload));
