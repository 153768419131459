import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { progression } from '../../../../../../helpers/progression';
import { severity } from '../../../../../../helpers/severity';

class ProblemList extends Component {
  static defaultProps = {
    print: false
  };

  static propTypes = {
    problemlist: PropTypes.array,
    print: PropTypes.bool
  };

  // Should the following be double equal!
  
  outputProblemSeverity = (x) => {
    const resp = severity.find(a => a.value === x);
    return (resp) ? resp.title : '-';
  }

  outputProblemProgression = (x) => {
    const resp = progression.find(a => a.value === x);
    return (resp) ? resp.title : '-';
  }

  outputProblem = (i,x) => {
    return (
      <li key={i}>
        <strong>Problem: {x.description}</strong><br/>
        Diagnosis: { (x['diagnosis.id']) ? x['diagnosis.icd10cm'] + '-' + x['diagnosis.description'] : 'None'}<br/>
        Severity: {this.outputProblemSeverity(x.severity)}<br/>
        Progression: {this.outputProblemProgression(x.progression)}
      </li>
    );
  }

  render() {
    if (this.props.problemlist && this.props.problemlist.length > 0) {
      let i = 1;
      return (
        <div>
          <strong>Problem List </strong>
          <ol>
            { this.props.problemlist.map(x => this.outputProblem(i++,x)) }
          </ol>
        </div>
      );
    }
    return null;
  }
}

export default ProblemList;
