export const BILLINGSTATE_SET = 'BILLINGSTATE_SET';

// Groups

export const PATIENT_REG_REQUEST = 'PATIENT_REG_REQUEST';
export const PATIENT_REG_SUCCESS = 'PATIENT_REG_SUCCESS';
export const PATIENT_REG_FAILURE = 'PATIENT_REG_FAILURE';
export const PATIENT_REG_CLEAR = 'PATIENT_REG_CLEAR';

export const GROUPS_LOAD_REQUEST = 'GROUPS_LOAD_REQUEST';
export const GROUPS_LOAD_SUCCESS = 'GROUPS_LOAD_SUCCESS';
export const GROUPS_LOAD_FAILURE = 'GROUPS_LOAD_FAILURE';
export const GROUPS_LOAD_CLEAR = 'GROUPS_LOAD_CLEAR'
export const GROUPS_SAVE_REQUEST = 'GROUPS_SAVE_REQUEST';
export const GROUPS_SAVE_SUCCESS = 'GROUPS_SAVE_SUCCESS';
export const GROUPS_SAVE_FAILURE = 'GROUPS_SAVE_FAILURE';
export const GROUPS_SAVE_CLEAR = 'GROUPS_SAVE_CLEAR'
export const GROUPS_CREATE_REQUEST = 'GROUPS_CREATE_REQUEST';
export const GROUPS_CREATE_SUCCESS = 'GROUPS_CREATE_SUCCESS';
export const GROUPS_CREATE_FAILURE = 'GROUPS_CREATE_FAILURE';
export const GROUPS_CREATE_CLEAR = 'GROUPS_CREATE_CLEAR'
export const GROUPS_DEACTIVATE_REQUEST = 'GROUPS_DEACTIVATE_REQUEST';
export const GROUPS_DEACTIVATE_SUCCESS = 'GROUPS_DEACTIVATE_SUCCESS';
export const GROUPS_DEACTIVATE_FAILURE = 'GROUPS_DEACTIVATE_FAILURE';
export const GROUPS_DEACTIVATE_CLEAR = 'GROUPS_DEACTIVATE_CLEAR';
export const GROUPS_REACTIVATE_REQUEST = 'GROUPS_REACTIVATE_REQUEST';
export const GROUPS_REACTIVATE_SUCCESS = 'GROUPS_REACTIVATE_SUCCESS';
export const GROUPS_REACTIVATE_FAILURE = 'GROUPS_REACTIVATE_FAILURE';
export const GROUPS_REACTIVATE_CLEAR = 'GROUPS_REACTIVATE_CLEAR';
export const GROUPS_LIST_REQUEST = 'GROUPS_LIST_REQUEST';
export const GROUPS_LIST_SUCCESS = 'GROUPS_LIST_SUCCESS';
export const GROUPS_LIST_FAILURE = 'GROUPS_LIST_FAILURE';
export const GROUPS_LIST_CLEAR = 'GROUPS_LIST_CLEAR';

export const CARETEAM_LIST_REQUEST = 'CARETEAM_LIST_REQUEST';
export const CARETEAM_LIST_SUCCESS = 'CARETEAM_LIST_SUCCESS';
export const CARETEAM_LIST_FAILURE = 'CARETEAM_LIST_FAILURE';
export const CARETEAM_LIST_CLEAR = 'CARETEAM_LIST_CLEAR';
export const CARETEAM_ADD_REQUEST = 'CARETEAM_ADD_REQUEST';
export const CARETEAM_ADD_SUCCESS = 'CARETEAM_ADD_SUCCESS';
export const CARETEAM_ADD_FAILURE = 'CARETEAM_ADD_FAILURE';
export const CARETEAM_ADD_CLEAR = 'CARETEAM_ADD_CLEAR';
export const CARETEAM_REMOVE_REQUEST = 'CARETEAM_REMOVE_REQUEST';
export const CARETEAM_REMOVE_SUCCESS = 'CARETEAM_REMOVE_SUCCESS';
export const CARETEAM_REMOVE_FAILURE = 'CARETEAM_REMOVE_FAILURE';
export const CARETEAM_REMOVE_CLEAR = 'CARETEAM_REMOVE_CLEAR';

export const RECENT_PATIENTLIST_REQUEST = 'RECENT_PATIENTLIST_REQUEST';
export const RECENT_PATIENTLIST_SUCCESS = 'RECENT_PATIENTLIST_SUCCESS';
export const RECENT_PATIENTLIST_FAILURE = 'RECENT_PATIENTLIST_FAILURE';
export const RECENT_PATIENTLIST_CLEAR = 'RECENT_PATIENTLIST_CLEAR';


export const SET_ACTIVE_USER = 'SET_ACTIVE_USER';
export const SET_ACTIVE_PATIENT = 'SET_ACTIVE_PATIENT';

// Get data from user table about logged in user
// Logging in and out
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGIN_CLEAR = 'USER_LOGIN_CLEAR';
export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';
export const USER_LOGOUT_CLEAR = 'USER_LOGOUT_CLEAR';

// User actions
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE';
export const USER_LIST_CLEAR = 'USER_LIST_CLEAR';

export const USER_LOAD_REQUEST = 'USER_LOAD_REQUEST';
export const USER_LOAD_SUCCESS = 'USER_LOAD_SUCCESS';
export const USER_LOAD_FAILURE = 'USER_LOAD_FAILURE';
export const USER_LOAD_CLEAR = 'USER_LOAD_CLEAR';

export const USER_SAVE_REQUEST = 'USER_SAVE_REQUEST';
export const USER_SAVE_SUCCESS = 'USER_SAVE_SUCCESS';
export const USER_SAVE_FAILURE = 'USER_SAVE_FAILURE';
export const USER_SAVE_CLEAR = 'USER_SAVE_CLEAR';

export const USER_ADMINLIST_REQUEST = 'USER_ADMINLIST_REQUEST';
export const USER_ADMINLIST_SUCCESS = 'USER_ADMINLIST_SUCCESS';
export const USER_ADMINLIST_FAILURE = 'USER_ADMINLIST_FAILURE';
export const USER_ADMINLIST_CLEAR = 'USER_ADMINLIST_CLEAR';

export const USER_DEACTIVATE_REQUEST = 'USER_DEACTIVATE_REQUEST';
export const USER_DEACTIVATE_SUCCESS = 'USER_DEACTIVATE_SUCCESS';
export const USER_DEACTIVATE_FAILURE = 'USER_DEACTIVATE_FAILURE';
export const USER_DEACTIVATE_CLEAR = 'USER_DEACTIVATE_CLEAR';

export const USER_REACTIVATE_REQUEST = 'USER_REACTIVATE_REQUEST';
export const USER_REACTIVATE_SUCCESS = 'USER_REACTIVATE_SUCCESS';
export const USER_REACTIVATE_FAILURE = 'USER_REACTIVATE_FAILURE';
export const USER_REACTIVATE_CLEAR = 'USER_REACTIVATE_CLEAR';

export const EFFECTIVEDATES_LOAD_REQUEST = 'EFFECTIVEDATES_LOAD_REQUEST';
export const EFFECTIVEDATES_LOAD_SUCCESS = 'EFFECTIVEDATES_LOAD_SUCCESS';
export const EFFECTIVEDATES_LOAD_FAILURE = 'EFFECTIVEDATES_LOAD_FAILURE';
export const EFFECTIVEDATES_LOAD_CLEAR = 'EFFECTIVEDATES_LOAD_CLEAR';

export const EFFECTIVEDATES_SAVE_REQUEST = 'EFFECTIVEDATES_SAVE_REQUEST';
export const EFFECTIVEDATES_SAVE_SUCCESS = 'EFFECTIVEDATES_SAVE_SUCCESS';
export const EFFECTIVEDATES_SAVE_FAILURE = 'EFFECTIVEDATES_SAVE_FAILURE';
export const EFFECTIVEDATES_SAVE_CLEAR = 'EFFECTIVEDATES_SAVE_CLEAR';

export const EFFECTIVEDATES_DELETE_REQUEST = 'EFFECTIVEDATES_DELETE_REQUEST';
export const EFFECTIVEDATES_DELETE_SUCCESS = 'EFFECTIVEDATES_DELETE_SUCCESS';
export const EFFECTIVEDATES_DELETE_FAILURE = 'EFFECTIVEDATES_DELETE_FAILURE';
export const EFFECTIVEDATES_DELETE_CLEAR = 'EFFECTIVEDATES_DELETE_CLEAR';

// Service Locations

export const LOCATION_LOAD_REQUEST = 'LOCATION_LOAD_REQUEST';
export const LOCATION_LOAD_SUCCESS = 'LOCATION_LOAD_SUCCESS';
export const LOCATION_LOAD_FAILURE = 'LOCATION_LOAD_FAILURE';
export const LOCATION_LOAD_CLEAR = 'LOCATION_LOAD_CLEAR'
export const LOCATION_SAVE_REQUEST = 'LOCATION_SAVE_REQUEST';
export const LOCATION_SAVE_SUCCESS = 'LOCATION_SAVE_SUCCESS';
export const LOCATION_SAVE_FAILURE = 'LOCATION_SAVE_FAILURE';
export const LOCATION_SAVE_CLEAR = 'LOCATION_SAVE_CLEAR'
export const LOCATION_CREATE_REQUEST = 'LOCATION_CREATE_REQUEST';
export const LOCATION_CREATE_SUCCESS = 'LOCATION_CREATE_SUCCESS';
export const LOCATION_CREATE_FAILURE = 'LOCATION_CREATE_FAILURE';
export const LOCATION_CREATE_CLEAR = 'LOCATION_CREATE_CLEAR'
export const LOCATION_DEACTIVATE_REQUEST = 'LOCATION_DEACTIVATE_REQUEST';
export const LOCATION_DEACTIVATE_SUCCESS = 'LOCATION_DEACTIVATE_SUCCESS';
export const LOCATION_DEACTIVATE_FAILURE = 'LOCATION_DEACTIVATE_FAILURE';
export const LOCATION_DEACTIVATE_CLEAR = 'LOCATION_DEACTIVATE_CLEAR';
export const LOCATION_REACTIVATE_REQUEST = 'LOCATION_REACTIVATE_REQUEST';
export const LOCATION_REACTIVATE_SUCCESS = 'LOCATION_REACTIVATE_SUCCESS';
export const LOCATION_REACTIVATE_FAILURE = 'LOCATION_REACTIVATE_FAILURE';
export const LOCATION_REACTIVATE_CLEAR = 'LOCATION_REACTIVATE_CLEAR';
export const LOCATION_LIST_REQUEST = 'LOCATION_LIST_REQUEST';
export const LOCATION_LIST_SUCCESS = 'LOCATION_LIST_SUCCESS';
export const LOCATION_LIST_FAILURE = 'LOCATION_LIST_FAILURE';
export const LOCATION_LIST_CLEAR = 'LOCATION_LIST_CLEAR';

// Billing Locations
export const BILLINGLOCATION_LOAD_REQUEST = 'BILLINGLOCATION_LOAD_REQUEST';
export const BILLINGLOCATION_LOAD_SUCCESS = 'BILLINGLOCATION_LOAD_SUCCESS';
export const BILLINGLOCATION_LOAD_FAILURE = 'BILLINGLOCATION_LOAD_FAILURE';
export const BILLINGLOCATION_LOAD_CLEAR = 'BILLINGLOCATION_LOAD_CLEAR'
export const BILLINGLOCATION_SAVE_REQUEST = 'BILLINGLOCATION_SAVE_REQUEST';
export const BILLINGLOCATION_SAVE_SUCCESS = 'BILLINGLOCATION_SAVE_SUCCESS';
export const BILLINGLOCATION_SAVE_FAILURE = 'BILLINGLOCATION_SAVE_FAILURE';
export const BILLINGLOCATION_SAVE_CLEAR = 'BILLINGLOCATION_SAVE_CLEAR'
export const BILLINGLOCATION_CREATE_REQUEST = 'BILLINGLOCATION_CREATE_REQUEST';
export const BILLINGLOCATION_CREATE_SUCCESS = 'BILLINGLOCATION_CREATE_SUCCESS';
export const BILLINGLOCATION_CREATE_FAILURE = 'BILLINGLOCATION_CREATE_FAILURE';
export const BILLINGLOCATION_CREATE_CLEAR = 'BILLINGLOCATION_CREATE_CLEAR'
export const BILLINGLOCATION_DEACTIVATE_REQUEST = 'BILLINGLOCATION_DEACTIVATE_REQUEST';
export const BILLINGLOCATION_DEACTIVATE_SUCCESS = 'BILLINGLOCATION_DEACTIVATE_SUCCESS';
export const BILLINGLOCATION_DEACTIVATE_FAILURE = 'BILLINGLOCATION_DEACTIVATE_FAILURE';
export const BILLINGLOCATION_DEACTIVATE_CLEAR = 'BILLINGLOCATION_DEACTIVATE_CLEAR';
export const BILLINGLOCATION_REACTIVATE_REQUEST = 'BILLINGLOCATION_REACTIVATE_REQUEST';
export const BILLINGLOCATION_REACTIVATE_SUCCESS = 'BILLINGLOCATION_REACTIVATE_SUCCESS';
export const BILLINGLOCATION_REACTIVATE_FAILURE = 'BILLINGLOCATION_REACTIVATE_FAILURE';
export const BILLINGLOCATION_REACTIVATE_CLEAR = 'BILLINGLOCATION_REACTIVATE_CLEAR';
export const BILLINGLOCATION_LIST_REQUEST = 'BILLINGLOCATION_LIST_REQUEST';
export const BILLINGLOCATION_LIST_SUCCESS = 'BILLINGLOCATION_LIST_SUCCESS';
export const BILLINGLOCATION_LIST_FAILURE = 'BILLINGLOCATION_LIST_FAILURE';
export const BILLINGLOCATION_LIST_CLEAR = 'BILLINGLOCATION_LIST_CLEAR';

// Location Groups

export const LOCATIONGROUP_LOAD_REQUEST = 'LOCATIONGROUP_LOAD_REQUEST';
export const LOCATIONGROUP_LOAD_SUCCESS = 'LOCATIONGROUP_LOAD_SUCCESS';
export const LOCATIONGROUP_LOAD_FAILURE = 'LOCATIONGROUP_LOAD_FAILURE';
export const LOCATIONGROUP_LOAD_CLEAR = 'LOCATIONGROUP_LOAD_CLEAR'
export const LOCATIONGROUP_SAVE_REQUEST = 'LOCATIONGROUP_SAVE_REQUEST';
export const LOCATIONGROUP_SAVE_SUCCESS = 'LOCATIONGROUP_SAVE_SUCCESS';
export const LOCATIONGROUP_SAVE_FAILURE = 'LOCATIONGROUP_SAVE_FAILURE';
export const LOCATIONGROUP_SAVE_CLEAR = 'LOCATIONGROUP_SAVE_CLEAR'
export const LOCATIONGROUP_CREATE_REQUEST = 'LOCATIONGROUP_CREATE_REQUEST';
export const LOCATIONGROUP_CREATE_SUCCESS = 'LOCATIONGROUP_CREATE_SUCCESS';
export const LOCATIONGROUP_CREATE_FAILURE = 'LOCATIONGROUP_CREATE_FAILURE';
export const LOCATIONGROUP_CREATE_CLEAR = 'LOCATIONGROUP_CREATE_CLEAR'
export const LOCATIONGROUP_REMOVE_REQUEST = 'LOCATIONGROUP_REMOVE_REQUEST';
export const LOCATIONGROUP_REMOVE_SUCCESS = 'LOCATIONGROUP_REMOVE_SUCCESS';
export const LOCATIONGROUP_REMOVE_FAILURE = 'LOCATIONGROUP_REMOVE_FAILURE';
export const LOCATIONGROUP_REMOVE_CLEAR = 'LOCATIONGROUP_REMOVE_CLEAR';
export const LOCATIONGROUP_LIST_REQUEST = 'LOCATIONGROUP_LIST_REQUEST';
export const LOCATIONGROUP_LIST_SUCCESS = 'LOCATIONGROUP_LIST_SUCCESS';
export const LOCATIONGROUP_LIST_FAILURE = 'LOCATIONGROUP_LIST_FAILURE';
export const LOCATIONGROUP_LIST_CLEAR = 'LOCATIONGROUP_LIST_CLEAR';

// CRUD + List actions for Carriers
export const CARRIER_LOAD_REQUEST = 'CARRIER_LOAD_REQUEST';
export const CARRIER_LOAD_SUCCESS = 'CARRIER_LOAD_SUCCESS';
export const CARRIER_LOAD_FAILURE = 'CARRIER_LOAD_FAILURE';
export const CARRIER_LOAD_CLEAR = 'CARRIER_LOAD_CLEAR'
export const CARRIER_SAVE_REQUEST = 'CARRIER_SAVE_REQUEST';
export const CARRIER_SAVE_SUCCESS = 'CARRIER_SAVE_SUCCESS';
export const CARRIER_SAVE_FAILURE = 'CARRIER_SAVE_FAILURE';
export const CARRIER_SAVE_CLEAR = 'CARRIER_SAVE_CLEAR'
export const CARRIER_CREATE_REQUEST = 'CARRIER_CREATE_REQUEST';
export const CARRIER_CREATE_SUCCESS = 'CARRIER_CREATE_SUCCESS';
export const CARRIER_CREATE_FAILURE = 'CARRIER_CREATE_FAILURE';
export const CARRIER_CREATE_CLEAR = 'CARRIER_CREATE_CLEAR'
export const CARRIER_DEACTIVATE_REQUEST = 'CARRIER_DEACTIVATE_REQUEST';
export const CARRIER_DEACTIVATE_SUCCESS = 'CARRIER_DEACTIVATE_SUCCESS';
export const CARRIER_DEACTIVATE_FAILURE = 'CARRIER_DEACTIVATE_FAILURE';
export const CARRIER_DEACTIVATE_CLEAR = 'CARRIER_DEACTIVATE_CLEAR';
export const CARRIER_REACTIVATE_REQUEST = 'CARRIER_REACTIVATE_REQUEST';
export const CARRIER_REACTIVATE_SUCCESS = 'CARRIER_REACTIVATE_SUCCESS';
export const CARRIER_REACTIVATE_FAILURE = 'CARRIER_REACTIVATE_FAILURE';
export const CARRIER_REACTIVATE_CLEAR = 'CARRIER_REACTIVATE_CLEAR';
export const CARRIER_LIST_REQUEST = 'CARRIER_LIST_REQUEST';
export const CARRIER_LIST_SUCCESS = 'CARRIER_LIST_SUCCESS';
export const CARRIER_LIST_FAILURE = 'CARRIER_LIST_FAILURE';
export const CARRIER_LIST_CLEAR = 'CARRIER_LIST_CLEAR';

export const ALERTS_LIST_REQUEST = 'ALERTS_LIST_REQUEST';
export const ALERTS_LIST_SUCCESS = 'ALERTS_LIST_SUCCESS';
export const ALERTS_LIST_FAILURE = 'ALERTS_LIST_FAILURE';
export const ALERTS_LIST_CLEAR = 'ALERTS_LIST_CLEAR';
export const ALERTS_SAVE_REQUEST = 'ALERTS_SAVE_REQUEST';
export const ALERTS_SAVE_SUCCESS = 'ALERTS_SAVE_SUCCESS';
export const ALERTS_SAVE_FAILURE = 'ALERTS_SAVE_FAILURE';
export const ALERTS_SAVE_CLEAR = 'ALERTS_SAVE_CLEAR';
export const ALERTS_DELETE_REQUEST = 'ALERTS_DELETE_REQUEST';
export const ALERTS_DELETE_SUCCESS = 'ALERTS_DELETE_SUCCESS';
export const ALERTS_DELETE_FAILURE = 'ALERTS_DELETE_FAILURE';
export const ALERTS_DELETE_CLEAR = 'ALERTS_DELETE_CLEAR';

// patients
export const PATIENT_CREATE_REQUEST = 'PATIENT_CREATE_REQUEST';
export const PATIENT_CREATE_SUCCESS = 'PATIENT_CREATE_SUCCESS';
export const PATIENT_CREATE_FAILURE = 'PATIENT_CREATE_FAILURE';
export const PATIENT_CREATE_CLEAR = 'PATIENT_CREATE_CLEAR';
export const PATIENT_LIST_REQUEST = 'PATIENT_LIST_REQUEST';
export const PATIENT_LIST_SUCCESS = 'PATIENT_LIST_SUCCESS';
export const PATIENT_LIST_FAILURE = 'PATIENT_LIST_FAILURE';
export const PATIENT_LIST_CLEAR = 'PATIENT_LIST_CLEAR';
export const PATIENT_LISTADVANCED_REQUEST = 'PATIENT_LISTADVANCED_REQUEST';
export const PATIENT_LISTADVANCED_SUCCESS = 'PATIENT_LISTADVANCED_SUCCESS';
export const PATIENT_LISTADVANCED_FAILURE = 'PATIENT_LISTADVANCED_FAILURE';
export const PATIENT_LISTADVANCED_CLEAR = 'PATIENT_LISTADVANCED_CLEAR';
export const PATIENT_LOAD_REQUEST = 'PATIENT_LOAD_REQUEST';
export const PATIENT_LOAD_SUCCESS = 'PATIENT_LOAD_SUCCESS';
export const PATIENT_LOAD_FAILURE = 'PATIENT_LOAD_FAILURE';
export const PATIENT_LOAD_CLEAR = 'PATIENT_LOAD_CLEAR';
export const PATIENT_SAVE_REQUEST = 'PATIENT_SAVE_REQUEST';
export const PATIENT_SAVE_SUCCESS = 'PATIENT_SAVE_SUCCESS';
export const PATIENT_SAVE_FAILURE = 'PATIENT_SAVE_FAILURE';
export const PATIENT_SAVE_CLEAR = 'PATIENT_SAVE_CLEAR';

export const PATIENT_PLACEHOLD_REQUEST = 'PATIENT_PLACEHOLD_REQUEST';
export const PATIENT_PLACEHOLD_SUCCESS = 'PATIENT_PLACEHOLD_SUCCESS';
export const PATIENT_PLACEHOLD_FAILURE = 'PATIENT_PLACEHOLD_FAILURE';
export const PATIENT_PLACEHOLD_CLEAR = 'PATIENT_PLACEHOLD_CLEAR';
export const PATIENT_REMOVEHOLD_REQUEST = 'PATIENT_REMOVEHOLD_REQUEST';
export const PATIENT_REMOVEHOLD_SUCCESS = 'PATIENT_REMOVEHOLD_SUCCESS';
export const PATIENT_REMOVEHOLD_FAILURE = 'PATIENT_REMOVEHOLD_FAILURE';
export const PATIENT_REMOVEHOLD_CLEAR = 'PATIENT_REMOVEHOLD_CLEAR';
export const PATIENT_ARCHIVE_REQUEST = 'PATIENT_ARCHIVE_REQUEST';
export const PATIENT_ARCHIVE_SUCCESS = 'PATIENT_ARCHIVE_SUCCESS';
export const PATIENT_ARCHIVE_FAILURE = 'PATIENT_ARCHIVE_FAILURE';
export const PATIENT_ARCHIVE_CLEAR = 'PATIENT_ARCHIVE_CLEAR';
export const PATIENT_UNARCHIVE_REQUEST = 'PATIENT_UNARCHIVE_REQUEST';
export const PATIENT_UNARCHIVE_SUCCESS = 'PATIENT_UNARCHIVE_SUCCESS';
export const PATIENT_UNARCHIVE_FAILURE = 'PATIENT_UNARCHIVE_FAILURE';
export const PATIENT_UNARCHIVE_CLEAR = 'PATIENT_UNARCHIVE_CLEAR';
