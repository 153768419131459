import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { VLayout, VLayoutHeader, VLayoutContent } from '../../shared/Layout';
//import { AppChartContent, AppChartHeader, AppChart } from '../../shared/Layout';

import ChartHeader from './Patient_/ChartHeader';
import Chart from './Patient_/Chart';
import EditPatient from '../../shared/EditPatient';
import ProgressNote from './Patient_/ProgressNote';
import GeneralChartNote from './Patient_/GeneralChartNote';
import TemplateLetterNote from './Patient_/TemplateLetterNote';
import AdminTimeNote from './Patient_/AdminTimeNote';
import LateCancelNote from './Patient_/LateCancelNote';
import LabRequestNote from './Patient_/LabRequestNote';
import ChartOutput from './Patient_/ChartOutput';
import PatientNotFound from './Patient_/PatientNotFound';

import * as actions from '../../../actions/emr';
import { safe } from '../../../helpers/reduxSelectors';

class Patient extends Component {

  componentDidMount() {
    this.props.setEmrState({
      patientId: parseInt(this.props.match.params.patient_id,10)
    });
  }

  componentDidUpdate(prevProps) {
    if (parseInt(this.props.match.params.patient_id,10) !== this.props.patientId) {
      this.props.setEmrState({
        patientId: parseInt(this.props.match.params.patient_id,10)
      });
    }
  }

  render() {
    return (
      <VLayout>
        <VLayoutHeader>
          <Route path="/emr/patients/:patient_id" component={ChartHeader} />
        </VLayoutHeader>
        <VLayoutContent>
          <Switch>
            <Route exact path="/emr/patients/:patient_id/edit" component={EditPatient}/>
            <Route exact path="/emr/patients/:patient_id/progressnote/:note_id" component={ProgressNote}/>
            <Route exact path="/emr/patients/:patient_id/generalnote/:note_id" component={GeneralChartNote}/>
            <Route exact path="/emr/patients/:patient_id/letternote" component={TemplateLetterNote}/>
            <Route exact path="/emr/patients/:patient_id/admintimenote" component={AdminTimeNote}/>
            <Route exact path="/emr/patients/:patient_id/latecancelnote" component={LateCancelNote}/>
            <Route exact path="/emr/patients/:patient_id/labrequestnote" component={LabRequestNote}/>
            <Route exact path="/emr/patients/:patient_id/chartoutput" component={ChartOutput} />
            <Route exact path="/emr/patients/:patient_id" component={Chart} />
            <Route component={PatientNotFound} />
          </Switch>
        </VLayoutContent>
      </VLayout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    patientId: safe(state,'emr.emr.patientId')
  }
}

export default connect(mapStateToProps,actions)(Patient);
