import React from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Spin, DatePicker, Select, notification } from 'antd';
import moment from 'moment';

import * as actions from '../../../../../../actions/billing';
import { safe } from '../../../../../../helpers/reduxSelectors';

class PreparedStatementsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.createStatementEvent !== this.props.createStatementEvent) {
      if (this.props.createStatementEvent.success) {
        notification['success']({
          message: 'Success',
          description: 'Statement or Superbill created'
        });
      } else if (this.props.createStatementEvent.error) {
        notification['error']({
          message: 'Error',
          description: safe(this.props,'createStatementEvent.error.data.message')
        })
      }
    }
  }

  showDialog = () => {
    this.setState({
      visible: true
    });
  }

  handleCancel = () => {
    this.setState({
      visible: false
    });
  }

  handleOk = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.createBillingStatement(this.props.patientId,
          values.type,values.start_date,values.end_date);
        this.setState({
          visible: false
        });
      }
    });

  }

  renderModalContent = () => {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };

    return (
      <Form autoComplete='off'>

        <Form.Item {...formItemLayout} style={{marginBottom:'0px'}} label='Type'>
          {getFieldDecorator('type', {
            rules: [
              { required: true, message: " " }
            ],
            initialValue: 2
          })(
            <Select>
              <Select.Option value={1} key={1}>Superbill</Select.Option>
              <Select.Option value={2} key={2}>Statement</Select.Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} style={{marginBottom:'0px'}} label='Starting Date'>
          {getFieldDecorator('start_date', {
            rules: [
              { required: true, message: " " }
            ]
          })(
            <DatePicker format='MM/DD/YYYY'/>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} style={{marginBottom:'0px'}} label='Ending Date'>
          {getFieldDecorator('end_date', {
            rules: [
              { required: true, message: " " }
            ],
            initialValue: moment()
          })(
            <DatePicker format='MM/DD/YYYY'/>
          )}
        </Form.Item>

      </Form>
    );
  }

  render() {
    return (
      <div>
        <Spin spinning={this.props.isBusy}>
          <Modal
            title="Generate Patient Documents"
            visible={this.state.visible}
            onOk={this.handleOk}
            okText='Submit'
            onCancel={this.handleCancel}
            destroyOnClose={true}
          >
            {this.renderModalContent()}
          </Modal>
          <a onClick={this.showDialog}>
            {this.props.children}
          </a>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'billing.statements.create.processing',false),
    createStatementEvent: safe(state,'billing.statements.create',{})
  }
}

export default connect(mapStateToProps,actions)(Form.create()(PreparedStatementsDialog));
