import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Modal, Tabs, message, Spin } from 'antd';

import * as actions from '../../../actions/billing';
import { safe } from '../../../helpers/reduxSelectors';

class Products extends Component {
  componentDidMount() {
    this.props.getProductList();
  }

  onClickAddProduct = () => {
    this.props.history.push('/settings/products/0');
  };

  componentDidUpdate(prevProps) {
    if (prevProps.deactivate !== this.props.deactivate) {
      if (this.props.deactivate.success) {
        message.success('Product code deactivated');
        this.props.productDeactivateClear();
      }
      if (this.props.deactivate.error) {
        message.error(safe(this.props, 'deactivate.error.data.message'));
        this.props.productDeactivateClear();
      }
    }

    if (prevProps.reactivate !== this.props.reactivate) {
      if (this.props.reactivate.success) {
        message.success('Product code reactivated');
        this.props.productReactivateClear();
      }
      if (this.props.reactivate.error) {
        message.error(safe(this.props, 'reactivate.error.data.message'));
        this.props.productReactivateClear();
      }
    }
  }

  deactivate = (record) => {
    const confirm = Modal.confirm;
    const du = this.props.productDeactivate;

    confirm({
      title: 'Deactivate Product: ' + record.name + '?',
      onOk() {
        du(record.id);
      },
      onCancel() {},
    });
  };

  reactivate = (record) => {
    const confirm = Modal.confirm;
    const ru = this.props.productReactivate;

    confirm({
      title: 'Reactivate Product: ' + record.name + '?',
      onOk() {
        ru(record.id);
      },
      onCancel() {},
    });
  };

  renderActiveTable = () => {
    const { Column } = Table;

    return (
      <Table
        dataSource={this.props.productList.filter((item) => item.active === true)}
        rowKey="id"
        size="small"
        pagination={false}
      >
        <Column
          title="Product"
          dataIndex="name"
          key="name"
          render={(text, record) => (
            <Link to={'/settings/products/' + record.id} style={{ whiteSpace: 'nowrap' }}>
              {text}
            </Link>
          )}
        />

        <Column title="Description" dataIndex="description" key="description" />

        <Column
          title="Action"
          key="action"
          width={100}
          fixed="right"
          render={(text, record, index) => (
            <span>
              <a
                onClick={() => {
                  this.deactivate(record);
                }}
              >
                Deactivate
              </a>
            </span>
          )}
        />
      </Table>
    );
  };

  renderInactiveTable = () => {
    const { Column } = Table;

    return (
      <Table
        dataSource={this.props.productList.filter((item) => item.active === false)}
        rowKey="id"
        size="small"
        pagination={false}
      >
        <Column title="Product" dataIndex="name" key="name" />

        <Column title="Description" dataIndex="description" key="description" />

        <Column
          title="Deactivated"
          dataIndex="deactivatedAt"
          key="deactivatedAt"
          render={(text, record) => new Date(text).toLocaleString()}
        />

        <Column
          title="Action"
          key="action"
          width={100}
          fixed="right"
          render={(text, record) => (
            <span>
              <a
                onClick={() => {
                  this.reactivate(record);
                }}
              >
                Reactivate
              </a>
            </span>
          )}
        />
      </Table>
    );
  };

  render() {
    const TabPane = Tabs.TabPane;

    return (
      <Spin spinning={this.props.isBusy} size="large">
        <div style={{ padding: '10px' }}>
          <h3>Products</h3>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Active" key="1">
              {this.renderActiveTable()}
            </TabPane>
            <TabPane tab="Inactive" key="2">
              {this.renderInactiveTable()}
            </TabPane>
          </Tabs>
          <div style={{ position: 'fixed', bottom: 40, right: 40 }}>
            <Button
              disabled={this.props.isBusy}
              onClick={this.onClickAddProduct}
              size="large"
              shape="circle"
              type="primary"
              icon={<PlusOutlined />}
            />
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deactivate: safe(state, 'billing.product.deactivate'),
    reactivate: safe(state, 'billing.product.reactivate'),
    productList: safe(state, 'billing.product.list.data', []),
    isBusy:
      safe(state, 'billing.product.load.processing') ||
      safe(state, 'billing.product.list.processing') ||
      safe(state, 'billing.product.deactivate.processing') ||
      safe(state, 'billing.product.reactivate.processing')
        ? true
        : false,
  };
};

export default connect(mapStateToProps, actions)(Products);
