import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, DatePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import * as actions from '../../../../../actions/scheduler';
import { safe } from '../../../../../helpers/reduxSelectors';
import { processEventNotification } from '../../../../../helpers/util';

class CreateTemplate extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.form.resetFields();
    }
    processEventNotification(prevProps,this.props,'createTemplateEvent','New Template Created');
  }

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.createTemplate(this.props.provider_id,{
          ...values,
          max_total_hrs: (this.props.is_prescriber) ? values.total_hrs : values.max_total_hrs,
          start_date: moment(values.start_date).tz('America/Los_Angeles').format('YYYY-MM-DD'),
          end_date: moment(values.end_date).tz('America/Los_Angeles').format('YYYY-MM-DD')
        })
        this.props.onClose();
      }
    });
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
      },
    };

    const hours_title = (this.props.is_prescriber) ? "Weekly Hours" : 'Min Weekly Hours';

    return (
      <Form className='compressed-form' autoComplete='false'>
        <FormItem {...formItemLayout} label="Start Date">
          {getFieldDecorator('start_date', {
            rules: [{ required: true, message: " " }],
          })(
            <DatePicker format='MM/DD/YYYY' />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="End Date">
          {getFieldDecorator('end_date', {
            rules: [{ required: true, message: " " }],
          })(
            <DatePicker format='MM/DD/YYYY'/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label={hours_title}>
          {getFieldDecorator('total_hrs', {
            rules: [{ required: true, message: " " }],
          })(
            <Input />
          )}
        </FormItem>

        {!this.props.is_prescriber && <FormItem {...formItemLayout} label='Max Weekly Hours'>
          {getFieldDecorator('max_total_hrs', {
            rules: [{ required: true, message: " " }],
          })(
            <Input />
          )}
        </FormItem> }

        <FormItem {...formItemLayout} label="Title">
          {getFieldDecorator('comments', {
            rules: [{ required: true, message: " " }],
          })(
            <Input />
          )}
        </FormItem>
      </Form>
    );
  }

  render() {
    return (
      <Modal
        title="Create New Template"
        visible={this.props.visible}
        onOk={this.handleOk}
        okText='Create'
        onCancel={this.props.onClose}
      >
        {this.renderForm()}
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    createTemplateEvent: safe(state,'scheduler.template.create',{}),
    is_prescriber: safe(ownProps,'provider_roles',[]).includes('prescriber')
  }
}

export default connect(mapStateToProps,actions)(Form.create()(CreateTemplate));
