import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, message, Spin, DatePicker } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import * as actions from '../../../actions/settings';
import { safe } from '../../../helpers/reduxSelectors';

class HolidayEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id,10)
    }
  }

  componentDidMount() {
    if (this.state.id !== 0) {
      this.props.loadHoliday(this.state.id);
    } else {
      this.props.loadHolidayClear();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load !== this.props.load) {
      if (this.props.load.error) {
        this.props.history.push('/settings/holidays');
        message.error('Unable to load holiday information');
      }
    }

    if (prevProps.update !== this.props.update) {
      if (this.props.update.success) {
        this.props.updateHolidayClear();
        this.props.history.push('/settings/holidays');
        message.success('Holiday saved');
      } else if (this.props.error) {
        message.error('Unable to save holiday');
      }
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        this.props.createHolidayClear();
        this.props.history.push('/settings/holidays');
        message.success('Holiday created');
      } else if (this.props.error) {
        message.error('Unable to save holiday code');
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.id === 0) {
          this.props.createHoliday(values);
        } else {
          this.props.updateHoliday(this.state.id,values);
        }
      }
    });
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const data = safe(this.props,'load.data',{});

    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off" className='cpt-form'>
        <FormItem {...formItemLayout} label="Holiday Date">
          {getFieldDecorator('date', {
            rules: [{ required: true, message: " " }],
            initialValue: moment(data.date)
          })(
            <DatePicker format='MM/DD/YYYY'/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Holiday Description">
          {getFieldDecorator('name', {
            initialValue: data.name
          })(
            <Input />
          )}
        </FormItem>

        <Row>
          <Col xs={36} sm={16}>
            <Button
              htmlType="submit"
              style={{float:'right'}}
              disabled={this.props.isBusy}
              type="primary">
                Save
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  getTitle = () => {
    return (this.state.id === 0) ? 'Add a Holiday' : 'Edit a Holiday';
  }

  render() {
    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <h3>{this.getTitle()}</h3>
          { this.renderForm() }
        </Spin>
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    load: safe(state,'settings.holidays.load'),
    update: safe(state,'settings.holidays.update'),
    create: safe(state,'settings.holidays.create'),
    isBusy: (
      safe(state,'settings.holidays.load.processing') ||
      safe(state,'settings.holidays.update.processing') ||
      safe(state,'settings.holidays.create.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(HolidayEdit)));
