/* eslint-disable react/display-name */
/**
 * Intakes.tsx
 */
import reports from 'reports';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import ReportWrapper from 'components/App_/ReportsV2_/ReportWrapper';
import InlineFormWrapper from 'components/App_/ReportsV2_/InlineFormWrapper';
import Table from 'components/App_/ReportsV2_/Table';
import columns from 'components/App_/ReportsV2_/TableColumns_/amdEraMissingItems';
import { InlineDataT } from 'reports';
import DetailsTable from 'components/App_/ReportsV2_/DetailsTable';
import useAutoRun from 'components/App_/ReportsV2_/useAutoRun';
import numeral from 'numeral';
import orderBy from 'lodash/orderBy';

type RowT = {
  eraId?: number;
  isPCPA: boolean;
  status: number;
};

const PROCESSED = 3;
const FAILED_PARSE = 4;
const reportId = 32;

const AmdEraMissingItems: React.FC = () => {
  const inlineData = useContextSelector(InlineContext, (c) => c.state.inlineData) as InlineDataT;
  const report = reports.find((r) => r.id === reportId);

  useAutoRun(reportId);

  const formOptions = { reportId };

  const subColumns: Array<Record<string, unknown>> = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (val: number, record) => {
        // console.log('record', record);
        if (!record?.isPCPA) {
          return 'Not PCPA';
        }
        switch (val) {
          case 1:
            return 'Parsed';
          case 2:
            return 'Stored';
          case 3:
            return 'Processed';
          case 4:
            return 'Failed Parse';
          case 5:
            return 'Failed Stored';
          case 6:
            return 'Failed Processed';
          case 7:
            return 'Already Exists';
          case 0:
          default:
            return 'Unprocessed';
        }
      },
    },
    {
      title: 'Check #',
      dataIndex: 'era.check_num',
      align: 'right',
      render: (_val: string, record) => record?.era?.check_num,
    },
    {
      title: 'Amount $',
      dataIndex: 'era.amount',
      align: 'right',
      render: (_val: string, record) => numeral(record?.era?.amount).format('$0,0.00'),
    },
  ];

  const transformData = () => {
    return inlineData.map((r) => {
      const rowItems = r.items as Array<RowT>;
      if (!rowItems) {
        return r;
      }
      const pcpaFiles = rowItems.filter((i) => i.isPCPA);
      const parsingErrors = rowItems.filter((i) => i.status === FAILED_PARSE);
      const processedEras = rowItems.filter((i: RowT) => {
        return i.eraId && i.isPCPA && i.status === PROCESSED;
      });
      const missingEras = rowItems.filter((i: RowT) => {
        return i.eraId === null && i.isPCPA && i.status == 0;
      });

      return {
        ...r,
        pcpaFilesCount: pcpaFiles.length,
        parsingErrorsCount: parsingErrors.length,
        missingErasCount: missingEras.length,
        processedErasCount: processedEras.length,
      };
    });
  };

  const tableOptions = {
    data: inlineData ? transformData() : [],
    columns,
    reportId,
    rowKey: 'id',
    total: inlineData?.length,
    expandable: {
      expandedRowRender: (instance) => {
        const items = instance?.items;
        // console.log('items', items);
        const firstSort = (item) => item.isPCPA && item.status !== 7;
        const secondSort = (item) => item.status;
        const thirdSort = (item) => item.fileName;
        // const thirdSort = (item) => item.status;
        const details = orderBy(items, [firstSort, secondSort, thirdSort], ['desc', 'desc', 'asc']);
        return <DetailsTable columns={subColumns} details={details} pagination={false} />;
      },
    },
    rowClassName: (instance) => {
      if (instance.processedErasCount && !instance.missingErasCount) {
        return 'blue-row';
      } else if (instance.missingErasCount) {
        return 'red-row';
      }
      return '';
    },
    page: 1,
    pageSize: 100,
  };

  return (
    <ReportWrapper>
      <h3>{report?.name}</h3>
      <InlineFormWrapper {...formOptions} />
      <Table {...tableOptions} />
    </ReportWrapper>
  );
};

export default AmdEraMissingItems;
