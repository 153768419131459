import React from 'react';
import { connect } from 'react-redux';
import { Alert, message } from 'antd';
import numeral from 'numeral';

import Assist from './Patients_/Assist';
import LedgerHeader from './Patients_/LedgerHeader';
import Ledger from './Patients_/Ledger';

import ChartStatusBar from '../../shared/ChartStatusBar';

import { safe } from '../../../helpers/reduxSelectors';
import * as billing_actions from '../../../actions/billing';
import * as shared_actions from '../../../actions/shared';
import * as emr_actions from '../../../actions/emr';

import { VLayout, VLayoutHeader, VLayoutContent, HLayout, HLayoutRight, HLayoutContent } from '../../shared/Layout';

class Patients extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      patientId: parseInt(props.match.params.id,10)
    }
  }

  loadChart = (patientId) => {
    this.props.loadPatient(patientId);
    this.props.getBillingLedger(patientId);
    this.props.getBillingComments(patientId);
    this.props.getBillingPolicies(patientId);
    this.props.getBillingStatements(patientId);
    this.props.getRecallPatientItems(patientId);
    this.props.getAuthorizationList(patientId);
    this.props.getAdminAttachments(patientId);
    this.props.loadAlerts(patientId);
  }

  componentDidMount() {
    // Store the current patient id in global state for when we need to come
    // back to this chart.

    this.props.setBillingState({
      patientId: this.state.patientId
    });

    if (this.state.patientId !== 0) {
      this.loadChart(this.state.patientId);
    }
  }

  getPatientName = () => {
    const p = this.props.demographics;
    const fi = (p.firstname && p.firstname.length > 0) ? p.firstname.substring(0,1) + ' ' : '';

    return fi + p.lastname + ' (' + numeral(this.props.demographics.id).format('000000')+ ')';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      const newPatientId = parseInt(this.props.match.params.id,10);
      this.setState({
        patientId: newPatientId
      });

      this.props.setBillingState({
        patientId: newPatientId
      });

      if (newPatientId !== 0) {
        this.loadChart(newPatientId);
      }
    }

    if (prevProps.archive !== this.props.archive) {
      if (this.props.archive.success) {
        this.props.chartArchiveClear();
        this.props.loadPatient(this.state.patientId);
      } else if (this.props.archive.error) {
        this.props.chartArchiveClear();
        message.error("Unable to archive chart");
      }
    }

    if (prevProps.unarchive !== this.props.unarchive) {
      if (this.props.unarchive.success) {
        this.props.chartUnarchiveClear();
        this.props.loadPatient(this.state.patientId);
      } else if (this.props.unarchive.error) {
        this.props.chartUnarchiveClear();
        message.error("Unable to unarchive chart");
      }
    }

    if (prevProps.placeHold !== this.props.placeHold) {
      if (this.props.placeHold.success) {
        this.props.chartPlaceHoldClear();
        this.props.loadPatient(this.state.patientId);
      } else if (this.props.placeHold.error) {
        this.props.chartPlaceHoldClear();
        message.error("Unable to place hold on chart");
      }
    }

    if (prevProps.removeHold !== this.props.removeHold) {
      if (this.props.removeHold.success) {
        this.props.chartRemoveHoldClear();
        this.props.loadPatient(this.state.patientId);
      } else if (this.props.removeHold.error) {
        this.props.chartRemoveHoldClear();
        message.error("Unable to remove hold on chart");
      }
    }

    if (this.props.isSuccess && prevProps.isSuccess !== this.props.isSuccess) {
      this.props.updateBillingRecentList(1,this.state.patientId,this.getPatientName());
    }

  }

  render() {
    if (this.state.patientId === 0) {
      return (
        <div style={{marginTop:'50px'}}>
          <Alert
            style={{width:'400px',margin:'auto',textAlign:'center'}}
            message="No Chart Loaded"
            type="info"
          />
        </div>
      );
    } else if (this.props.isBusy === false && this.props.isSuccess === false) {
      return (
        <div style={{marginTop:'50px'}}>
          <Alert
            style={{width:'400px',margin:'auto',textAlign:'center'}}
            message="Error Loading Billing Chart"
            type="error"
          />
        </div>
      );
    }

    return (
      <HLayout>
        <HLayoutContent>
          <VLayout>
            <VLayoutHeader>
              <ChartStatusBar
                isChartOnHold={this.props.isChartOnHold}
                isChartOpen={this.props.isChartOpen}
                isPortalRegistered={true}
                isRegistrationComplete={true}
              />
              <LedgerHeader
                patientId={this.state.patientId}
                demographics={this.props.demographics}
                alerts={this.props.alerts}
              />
            </VLayoutHeader>
            <VLayoutContent>
              <Ledger
                data={this.props.services}
                policies={this.props.policies}
                patientId={this.state.patientId}
                pt_totals={this.props.pt_totals}
              />
            </VLayoutContent>
          </VLayout>
        </HLayoutContent>
        <HLayoutRight style={{
          width:(this.props.patient_sider_collapsed) ? 0 : 400,
          minWidth:(this.props.patient_sider_collapsed) ? 0 : 400,
          overflowY:'scroll'
        }}>
          <Assist
            patientId={this.state.patientId}
            comments={this.props.comments}
            pt_payments={this.props.pt_payments}
            ins_payments={this.props.ins_payments}
            policies={this.props.policies}
            statements={this.props.statements}
            demographics={this.props.demographics}
            recall_items={this.props.recall_items}
            authorizations={this.props.authorizations}
            selection={this.props.patient_sider_selection}
            admin_attachments={this.props.admin_attachments}
            autopay={safe(this.props,'pt_totals.autopay')}
          />
        </HLayoutRight>
      </HLayout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'shared.patient.load.processing',false) ||
      safe(state,'billing.ledger.load.processing',false) ||
      safe(state,'billing.ledger.adj.processing',false) ||
      safe(state,'shared.patient.archive.processing',false) ||
      safe(state,'shared.patient.unarchive.processing',false) ||
      safe(state,'shared.patient.placehold.processing',false) ||
      safe(state,'shared.patient.removehold.processing',false)
    ) ? true : false,

    isSuccess: (
      safe(state,'shared.patient.load.success',false) &&
      safe(state,'billing.ledger.load.success',false)
    ) ? true : false,

    demographics: safe(state,'shared.patient.load.data',{}),
    alerts: safe(state,'shared.alert.list.data',[]),

    services: safe(state,'billing.ledger.load.data.services',[]),
    pt_payments: safe(state,'billing.ledger.load.data.pt_payments', []),
    ins_payments: safe(state,'billing.ledger.load.data.ins_payments', []),
    pt_totals: safe(state,'billing.ledger.load.data.pt_totals',{}),

    recall_items: safe(state,'billing.recall.patient_list.data',[]),

    comments: safe(state,'billing.comments.list.data',[]),
    policies: safe(state,'billing.policies.list.data',[]),
    statements: safe(state,'billing.statements.list.data',[]),
    authorizations: safe(state,'billing.authorizations.list.data',[]),

    admin_attachments: safe(state,'billing.attachments.list.data',[]),

    isChartOnHold: safe(state,'shared.patient.load.data.on_hold',false),
    isChartOpen: safe(state,'shared.patient.load.data.open',true),

    archive: safe(state,'shared.patient.archive',{}),
    unarchive: safe(state,'shared.patient.unarchive',{}),
    placeHold: safe(state,'shared.patient.placehold',{}),
    removeHold: safe(state,'shared.patient.removehold',{}),

    isPortalRegistered: safe(state,'shared.patient.load.data.portal_registered'),

    patient_sider_collapsed: safe(state,'shared.domain.billing.patient_sider_collapsed',false),
    patient_sider_selection: safe(state,'shared.domain.billing.patient_sider_selection',undefined)
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions,...emr_actions})(Patients);

/*
<AppBillingPatientContent>
  <AppChartContent className='patient_content'>
    <AppChartHeader>
      <div>
        <ChartStatusBar
          isChartOnHold={this.props.isChartOnHold}
          isChartOpen={this.props.isChartOpen}
          isPortalRegistered={true}
        />
        <LedgerHeader
          patientId={this.state.patientId}
          demographics={this.props.demographics}
          alerts={this.props.alerts}
        />
      </div>
    </AppChartHeader>

    <AppChart>
      <Ledger
        data={this.props.services}
        policies={this.props.policies}
        patientId={this.state.patientId}
        pt_totals={this.props.pt_totals}
      />
    </AppChart>
  </AppChartContent>
  <AppBillingPatientAssist width={400} collapsed={this.props.patient_sider_collapsed}>

    <Assist
      patientId={this.state.patientId}
      comments={this.props.comments}
      pt_payments={this.props.pt_payments}
      ins_payments={this.props.ins_payments}
      policies={this.props.policies}
      statements={this.props.statements}
      demographics={this.props.demographics}
      recall_items={this.props.recall_items}
      authorizations={this.props.authorizations}
      selection={this.props.patient_sider_selection}
      autopay={safe(this.props,'pt_totals.autopay')}
    />

  </AppBillingPatientAssist>

</AppBillingPatientContent>

*/
