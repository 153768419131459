import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import AttachmentList from './AttachmentList';

import { safe } from '../../../../../../helpers/reduxSelectors';

class LabsChartNote extends Component {

  static defaultProps = {
    print: false
  };

  static propTypes = {
    providerName: PropTypes.string.isRequired,
    diagnosis: PropTypes.string,
    labs: PropTypes.array,
    otherNote: PropTypes.string,
    signedDateTime: PropTypes.string,
    patientName: PropTypes.string,
    patientDOB: PropTypes.string
  };

  header = () => {
    if (this.props.print) {
      return (
        <div style={{paddingBottom:'20px'}}>
          <div align='center'>
            <img src='/images/pcpalogo-med.png' alt='PCPA Logo' width='75'/>
          </div>
          <hr/>
          <div align='center'>
            <div style={{fontSize:'larger'}}>Pacific Coast Psychiatric Associates</div>
            <div>490 Post Street, Suite 1043</div>
            <div>San Francisco, CA 94102</div>
            <div>phone: 415-296-5290 * fax: 415-296-5299</div>
            <div>reception@pcpasf.com</div>
            <div>pcpasf.com</div>
          </div>
          <br />
          <div align='center' style={{fontWeight:'bold'}}>
            PLEASE FAX RESULTS TO 415-296-5299
          </div>
        </div>
      );
    }
    return null;
  }

  breakLines = (x) => {
    if (!x) {
      return null;
    }
    return x.split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    });
  }

  renderTitle = (x) => {
    return (x ? (<div className='chartnotetitle'>{x}</div>) : null);
  }

  renderSignature = () => {
    if (this.props.print) {
      if (this.props.signedDateTime) {
        return (
          <div>
            <div className='chartnotefield'>
              <br />
              <span className='chartnotefieldlabel'>Ordering Physician</span>&nbsp;
              {this.props.providerName} (NPI #: {this.props.providerNPI})
            </div>
            <div className='chartnotesignature'>
              Electronically signed by {this.props.providerName} at {moment(this.props.signedDateTime).format("MM/DD/YYYY h:mm A")}
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return (this.props.signedDateTime ? (
        <div className='chartnotesignature'>
          Electronically signed by {this.props.providerName} at {moment(this.props.signedDateTime).format("MM/DD/YYYY h:mm A")}
        </div>
      ) : null);
    }
  }

  renderItem = (fieldValue,label) => {
    return (fieldValue ? (
      <div className='chartnotefield'>
        <span className='chartnotefieldlabel'>{label} </span>{this.breakLines(fieldValue)}
      </div>
    ) : null);
  }

  renderLabs = () => {
    if (!this.props.labs || this.props.labs.length === 0) {
      return null;
    }
    return (
      <div className='chartnotefield'>
        <div className='chartnotefieldlabel'>Test(s) Requested</div>
        <ol>
        {this.props.labs.map(x => (<li key={x} className='chartnote_ms'>{x}</li>))}
        </ol>
      </div>
    );
  }

  renderPrintOption = () => {
    if (this.props.print) {
      return null;
    }

    return (
      <div style={{paddingTop:'10px'}}>
        <a href={'/display/lab/'+this.props.noteId} target='_blank'>(Display printable copy)</a>
      </div>
    )
  }
//

  renderNote = () => {
    return (
      <div>
        {this.header()}
        {this.renderItem(moment(this.props.noteDate).format('M/DD/YYYY'),'Order Date')}
        <br/>
        {this.renderItem(this.props.patientName,'Patient Name')}
        {this.renderItem(moment(this.props.patientDOB).format('M/DD/YYYY'),'Patient Date of Birth')}
        <br/>
        {this.renderItem(this.props.diagnosis,'Patient Diagnosis')}
        <br/>
        {this.renderLabs()}
        {this.renderItem(this.props.otherNote,'Note')}
        {this.renderPrintOption()}
      </div>
    );
  }

  render() {
    return (
      <div>
        {!this.props.print && this.renderTitle('Labs Request Note')}
        {this.renderNote()}
        {this.renderSignature()}
        <AttachmentList list={this.props.attachments} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    attachments: safe(ownProps,'data.attachments',[]),
    noteId: safe(ownProps,'data.id'),
    noteDate: safe(ownProps,'data.note_date'),
    providerName: safe(ownProps,'data.provider.full_name'),
    providerNPI: safe(ownProps,'data.provider.npi'),
    diagnosis: safe(ownProps,'data.note.diagnosis',[]),
    labs: safe(ownProps,'data.note.labs'),
    otherNote: safe(ownProps,'data.note.other_note'),
    patientId: safe(ownProps,'data.patient_id'),
    signedDateTime: safe(ownProps,'data.signedAt',null),
    patientName: safe(ownProps,'data.note.patientName',null),
    patientDOB: safe(ownProps,'data.note.patientDOB',null)
  }
}

export default connect(mapStateToProps,null)(LabsChartNote);
