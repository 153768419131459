/**
 * LifestancePatientTotals.tsx
 */
import reports from 'reports';
import ReportWrapper from 'components/App_/ReportsV2_/ReportWrapper';
import QueueFormWrapper from 'components/App_/ReportsV2_/QueueFormWrapper';
import DownloadOnly from 'components/App_/ReportsV2_/DownloadOnly';
import styles from './styles';

const reportId = 11;

const LifestancePatientTotals: React.FC = () => {
  const report = reports.find((r) => r.id === reportId);
  return (
    <ReportWrapper>
      <h3>{report?.name}</h3>
      <div style={styles.root}>
        <DownloadOnly />
        <QueueFormWrapper reportId={reportId} />
      </div>
    </ReportWrapper>
  );
};

export default LifestancePatientTotals;
