import * as types from '../../actions/billing/types';

const initialState = {
  load: {},
  save: {},
  create: {},
  list: {},
  deactivate: {},
  reactivate: {}
}

export default function(state = initialState, action) {
  switch (action.type) {

    case types.PRODUCT_LOAD_REQUEST:
      return {
        ...state,
        load: {
          processing: true
        }
      };

    case types.PRODUCT_LOAD_SUCCESS:
      return {
        ...state,
        load: {
          processing: false,
          success: true,
          data: action.payload
        }
      };

    case types.PRODUCT_LOAD_FAILURE:
      return {
        ...state,
        load: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.PRODUCT_LOAD_CLEAR:
      return {
        ...state,
        load: {}
      };

    case types.PRODUCT_CREATE_REQUEST:
      return {
        ...state,
        create: {
          processing: true
        }
      }

    case types.PRODUCT_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          processing: false,
          success: true
        }
      }

    case types.PRODUCT_CREATE_FAILURE:
      return {
        ...state,
        create: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.PRODUCT_CREATE_CLEAR:
      return {
        ...state,
        create: {}
      };

    case types.PRODUCT_SAVE_REQUEST:
      return {
        ...state,
        save: {
          processing: true
        }
      }

    case types.PRODUCT_SAVE_SUCCESS:
      return {
        ...state,
        save: {
          processing: false,
          success: true
        }
      }

    case types.PRODUCT_SAVE_FAILURE:
      return {
        ...state,
        save: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.PRODUCT_SAVE_CLEAR:
      return {
        ...state,
        save: {}
      };

    case types.PRODUCT_DEACTIVATE_REQUEST:
      return {
        ...state,
        deactivate: {
          processing: true
        }
      }

    case types.PRODUCT_DEACTIVATE_SUCCESS:
      return {
        ...state,
        deactivate: {
          processing: false,
          success: true
        }
      };

    case types.PRODUCT_DEACTIVATE_FAILURE:
      return {
        ...state,
        deactivate: {
          processing: false,
          success: false,
          error: action.error
        }
      }

    case types.PRODUCT_DEACTIVATE_CLEAR:
      return {
        ...state,
        deactivate: {}
      }

    case types.PRODUCT_REACTIVATE_REQUEST:
      return {
        ...state,
        reactivate: {
          processing: true
        }
      }

    case types.PRODUCT_REACTIVATE_SUCCESS:
      return {
        ...state,
        reactivate: {
          processing: false,
          success: true
        }
      }

    case types.PRODUCT_REACTIVATE_FAILURE:
      return {
        ...state,
        reactivate: {
          processing: false,
          success: false,
          error: action.error
        }
      }

    case types.PRODUCT_REACTIVATE_CLEAR:
      return {
        ...state,
        reactivate: {}
      }

    case types.PRODUCT_LIST_REQUEST:
      return {
        ...state,
        list: {
          processing: true
        }
      }

    case types.PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        list: {
          processing: false,
          success: true,
          data: action.payload
        }
      }

    case types.PRODUCT_LIST_FAILURE:
      return {
        ...state,
        list: {
          processing: false,
          success: false,
          error: action.error
        }
      }

    case types.PRODUCT_LIST_CLEAR:
      return {
        ...state,
        list: {}
      };

    default:
      return state;
  }
}
