import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, List, Skeleton, Tag, AutoComplete, Radio, Input, Button } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import * as emr_actions from '../../../../../actions/emr';
import * as shared_actions from '../../../../../actions/shared';
import { safe } from '../../../../../helpers/reduxSelectors';
import Time from '../../../../shared/Time';

const diffHrs = Time.getTzOffset();

class SessionHistory extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    visible: PropTypes.bool,
    provider_id: PropTypes.number,
  };

  state = {
    query: '',
    query2: '',
    expandedList: {},
    provider_selection: 1,
  };

  componentDidMount() {
    this.props.getLocationsList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible !== this.props.visible && !this.props.visible) {
      this.setState({
        query: '',
        expandedList: {},
        provider_selection: 1,
      });
    }

    if (
      (prevProps.visible !== this.props.visible && this.props.visible) ||
      prevState.query !== this.state.query ||
      prevState.provider_selection !== this.state.provider_selection
    ) {
      const provider_id = this.state.provider_selection ? this.props.provider_id : 0;
      this.props.getProviderHistory(provider_id, this.state.query);
    }
  }

  getAction = (action) => {
    switch (action) {
      case 1:
        return <Tag color="green">New</Tag>;
      case 2:
        return <Tag color="blue">Changed</Tag>;
      case 3:
        return <Tag color="red">Deleted</Tag>;
      case 5:
        return <Tag color="purple">Late Cancel</Tag>;
      default:
        return '';
    }
  };

  getTitle = (item) => {
    const x = [];
    const patientName = safe(item, 'patient');
    if (patientName) {
      x.push(patientName);
    }

    if (item.action === 2) {
      // Modification
      const sd = safe(item, 'data.session_date', {});
      const sessionDate = sd.after ? moment(sd.after).format('M/D/YYYY') : undefined;
      if (sessionDate) {
        x.push(sessionDate);
      }

      const st = safe(item, 'data.start_time', {});
      try {
        const sessionTime = st.after ? new Time(st.after).toString(false) : undefined;
        if (sessionTime) {
          x.push(sessionTime);
        }
      } catch (err) {}
    } else {
      // New or Delete
      const sessionDate = safe(item, 'data.session_date')
        ? moment(item.data.session_date).format('M/D/YYYY')
        : undefined;
      if (sessionDate) {
        x.push(sessionDate);
      }

      try {
        const sessionTime = safe(item, 'data.start_time') ? new Time(item.data.start_time).toString(false) : undefined;
        if (sessionTime) {
          x.push(sessionTime);
        }
      } catch (err) {}

      if (item.data && item.data.duration) {
        x.push(item.data.duration + ' min');
      }
    }
    return x.join(', ');
  };

  getDate = (x) => {
    if (typeof x === 'object') {
      const before = x.before ? moment(x.before).format('M/D/YYYY') : undefined;
      const after = x.after ? moment(x.after).format('M/D/YYYY') : undefined;
      return before === after ? before : before + '->' + after;
    } else {
      return moment(x).format('M/D/YYYY');
    }
  };

  getTime = (x) => {
    if (typeof x === 'object') {
      let before = '';
      try {
        before = new Time(x.before).toString(false);
      } catch (err) {}
      let after = '';
      try {
        after = new Time(x.after).toString(false);
      } catch (err) {}
      return before === after ? before : before + '->' + after;
    } else {
      let t = '';
      try {
        t = new Time(x).toString(false);
      } catch (err) {}
      return t;
    }
  };

  getDuration = (x) => {
    if (typeof x === 'object') {
      return x.before === x.after ? x.before : x.before + '->' + x.after;
    } else {
      return x;
    }
  };

  getType = (x) => {
    if (typeof x === 'object') {
      const before = x.before === 1 ? 'Eval' : 'Followup';
      const after = x.after === 1 ? 'Eval' : 'Followup';
      return before === after ? before : before + '->' + after;
    } else {
      return x === 1 ? 'Eval' : 'Followup';
    }
  };

  getLocation = (x) => {
    if (typeof x === 'object' && x) {
      const before = safe(
        this.props.locationList.find((y) => y.id === x.before),
        'name',
      );
      const after = safe(
        this.props.locationList.find((y) => y.id === x.after),
        'name',
      );
      return before === after ? before : before + '->' + after;
    } else {
      const location = this.props.locationList.find((y) => y.id === x);
      return location ? location.name : '';
    }
  };

  getRepeats = (x) => {
    if (typeof x === 'object') {
      const before = x.before ? 'Yes' : 'No';
      const after = x.after ? 'Yes' : 'No';
      return before === after ? before : before + '->' + after;
    } else {
      return x ? 'Yes' : 'No';
    }
  };

  getTelemedicine = (x) => {
    if (typeof x === 'object') {
      const before = x.before ? 'Yes' : 'No';
      const after = x.after ? 'Yes' : 'No';
      return before === after ? before : before + '->' + after;
    } else {
      return x ? 'Yes' : 'No';
    }
  };

  getSessionContents = (seg) => {
    const item = safe(seg, 'data', {});
    const sd = this.getDate(item.session_date);
    const st = this.getTime(item.start_time);
    const { session_date, session_date_local, time, local_time } = Time.fixDateTime(sd, st);

    return (
      <div>
        <div>Provider: {safe(seg, 'provider')}</div>
        <div>
          Date: {sd}&nbsp;
          {diffHrs ? (
            <>
              <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>&nbsp;
              {session_date_local ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {session_date_local.format('MM/DD/YYYY')}</Tag> : null}
            </>
          ) : null}
        </div>
        <div>
          Time: {st}&nbsp;
          {diffHrs ? (
            <>
              <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>&nbsp;
              {local_time ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {(local_time)?.toString(false)}</Tag> : null}
            </>
          ) : null}
        </div>
        <div>Duration: {this.getDuration(item.duration)} min</div>
        <div>Type: {this.getType(item.session_type)}</div>
        <div>Location: {this.getLocation(item.location_id)}</div>
        <div>Telemedicine: {this.getTelemedicine(item.telemedicine)}</div>
        <div>Repeats: {this.getRepeats(item.repeat)}</div>
      </div>
    );
  };

  getDescription = (item) => {
    if (this.state.expandedList[item.id]) {
      const actorName = item.portal_id ? safe(item, 'portal_actor') + ' (patient)' : safe(item, 'actor');
      let action = '';
      switch (item.action) {
        case 1:
          action = 'Created by ';
          break;
        case 2:
          action = 'Modified by ';
          break;
        case 3:
          action = 'Deleted by ';
          break;
        case 5:
          action = 'No Show/Late Cancel by ';
          break;
        default:
          break;
      }
      const actionTimestamp = moment(item.createdAt).format('M/D/YYYY @ hh:mm:ss A');
      return (
        <div style={{ paddingLeft: '20px', fontSize: 'smaller' }}>
          {this.getSessionContents(item)}
          <span style={{ fontWeight: 'bold', margin: '-10px' }}>
            {action} {actorName} on {actionTimestamp}
          </span>
        </div>
      );
    }
    return null;
  };

  handleSearch = (query) => {
    this.setState({
      query: query,
    });
  };

  selectProvider = (x) => {
    this.setState({
      provider_selection: x.target.value,
    });
  };

  /*
  <Select
    style={{width:'100%'}}
    value={this.state.provider_selection}
    onChange={this.selectProvider}
  >
    <Option value={1}>{this.props.provider_name}</Option>
    <Option value={0}>All Providers</Option>
  </Select>
  */

  getDrawerTitle = () => {
    return (
      <span>
        <Radio.Group value={this.state.provider_selection} buttonStyle="solid" onChange={this.selectProvider}>
          <Radio.Button value={1}>{this.props.provider_name}</Radio.Button>
          <Radio.Button value={0} disabled={true}>
            All Providers
          </Radio.Button>
        </Radio.Group>
        <AutoComplete
          style={{ width: '100%', marginTop: '20px' }}
          allowClear={true}
          value={this.state.query}
          onSearch={this.handleSearch}
          placeholder="Search session scheduling history by name or date"
        />
      </span>
    );
  };

  /**
   * expandItem -we must entirely rewrite the array of what's expanded
   * otherwise the component is not rerendered.
   */

  expandItem = (id) => {
    const status = this.state.expandedList[id] ? false : true;
    this.setState({
      expandedList: {
        ...this.state.expandedList,
        [id]: status,
      },
    });
  };

  render() {
    return (
      <Drawer
        title={this.getDrawerTitle()}
        placement="right"
        closable={false}
        onClose={this.props.onClose}
        visible={this.props.visible}
        width={500}
      >
        <div style={{ padding: '5px' }}>
          <List
            loading={this.props.isBusy}
            itemLayout="horizontal"
            dataSource={this.props.data}
            size="small"
            renderItem={(item) => (
              <List.Item
                onClick={() => {
                  this.expandItem(item.id);
                }}
                style={{ cursor: 'pointer' }}
              >
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta title={this.getTitle(item)} description={this.getDescription(item)} />
                  {this.getAction(item.action)}
                </Skeleton>
              </List.Item>
            )}
          />
        </div>
      </Drawer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state, 'emr.provider.history.data.processing', false),
    data: safe(state, 'emr.provider.history.data', []),
    locationList: safe(state, 'shared.location.list.data', []),
  };
};

export default connect(mapStateToProps, { ...emr_actions, ...shared_actions })(SessionHistory);
