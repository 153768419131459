import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, message, Spin } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/billing';

class RecallListEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id,10)
    }
  }

  componentDidMount() {
    if (this.state.id !== 0) {
      this.props.loadRecallList(this.state.id);
    } else {
      this.props.loadRecallListClear();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load !== this.props.load) {
      if (this.props.load.error) {
        this.props.history.push('/settings/recalllist');
        message.error('Unable to load recall list information');
      }
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.success) {
        this.props.saveRecallListClear();
        this.props.history.push('/settings/recalllist');
        message.success('List saved');
      } else if (this.props.error) {
        message.error('Unable to save list');
      }
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        this.props.createRecallListClear();
        this.props.history.push('/settings/recalllist');
        message.success('List saved');
      } else if (this.props.error) {
        message.error('Unable to save list');
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.id === 0) {
          this.props.createRecallList(values);
        } else {
          this.props.saveRecallList(this.state.id,values);
        }
      }
    });
  }

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const name = safe(this.props,'load.data.name','New');
    const data = safe(this.props,'load.data',{});

    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <h3>Recall List / {name}</h3>

          <Form onSubmit={this.handleSubmit} autoComplete="off">
            <FormItem {...formItemLayout} label="List Name">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: "Please enter a name for this recall list", whitespace: true }],
                initialValue: data.name
              })(
                <Input />
              )}
            </FormItem>

            <Row>
              <Col xs={36} sm={16}><Button htmlType="submit" style={{float:'right'}} disabled={this.props.isBusy} type="primary">Save</Button></Col>
            </Row>
          </Form>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.recall.lists_load.processing',false) ||
      safe(state,'billing.recall.lists_save.processing',false) ||
      safe(state,'billing.recall.lists_create.processing',false)
    ) ? true : false,
    load: safe(state,'billing.recall.lists_load',{}),
    save: safe(state,'billing.recall.lists_save',{}),
    create: safe(state,'billing.recall.lists_create',{})
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(RecallListEdit)));
