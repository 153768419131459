import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import TherapistProgressNote from '../Patient_/Chart_/ChartNotes_/TherapistProgressNote';
import PrescriberProgressNote from '../Patient_/Chart_/ChartNotes_/PrescriberProgressNote';

class SupervisorCell extends Component {
  static propTypes = {
    note: PropTypes.object,
    userId: PropTypes.number
  }

  constructor(props) {
    super(props);
    this.state = {
      noteVisible : false
    }
  }

  toggleNote = () => {
    this.setState({ noteVisible : !this.state.noteVisible });
  }

  getNote = () => {
    if (this.props.note.type === 1) {
      return <TherapistProgressNote data={this.props.note} userId={this.props.userId} print={true}/>
    } else if (this.props.note.type === 2) {
      return <PrescriberProgressNote data={this.props.note} userId={this.props.userId} print={true}/>
    } else {
      return null;
    }
  }


  render() {
    if (this.state.noteVisible) {
      return (
        <div>
          <div style={{paddingBottom:'15px'}}>
            <a onClick={this.toggleNote}>
              <DownCircleOutlined />&nbsp;
              {moment(this.props.note.note_date).format('M/DD/YYYY')}: {this.props.note.patient.full_name}
            </a>
          </div>
          <div>{this.getNote()}</div>
        </div>
      );
    } else {
      return (
        <div style={{paddingBottom:'15px'}}>
          <a onClick={this.toggleNote}>
            <UpCircleOutlined />&nbsp;
            {moment(this.props.note.note_date).format('M/DD/YYYY')}: {this.props.note.patient.full_name}
          </a>
        </div>
      );
    }
  }
}

export default SupervisorCell;
