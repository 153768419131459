import React from 'react';
import numeral from 'numeral';

import { safe } from '../../../../helpers/reduxSelectors';
import { x12CarCodes } from '../../../../helpers/x12CarCodes';

class ClaimDisplay extends React.Component {

  findCarCode = (reason) => {
    const y = x12CarCodes.find(x=>x.xCode === reason);
    return (y) ? y.xDescription : null;
  }

  serviceLevelInformation = (x) => {
    let counter = 1;
    return (
      <div style={{paddingTop:'30px'}}>
        <div className='bold' style={{textAlign:'center'}}>Service Level Information for Claim ID - {safe(x,'payer_control_number','')}</div>
        <table width='100%'>
          <thead>
            <tr>
              <th>DOS</th>
              <th>PL</th>
              <th>Procedure</th>
              <th style={{textAlign:'right'}}>Units</th>
              <th style={{textAlign:'right'}}>Charges</th>
              <th style={{textAlign:'right'}}>Allowed</th>
              <th style={{textAlign:'right'}}>Adjust</th>
              <th style={{textAlign:'right'}}>Co-pay</th>
              <th style={{textAlign:'right'}}>Deduct</th>
              <th style={{textAlign:'right'}}>Co-Ins</th>
              <th style={{textAlign:'right'}}>Adj Code</th>
              <th style={{textAlign:'right'}}>Patient</th>
              <th style={{textAlign:'right'}}>Ins Paid</th>
            </tr>
          </thead>
          <tbody>
            { x.services.map(z => {
              return (
                <tr key={counter++}>
                  <td>{safe(z,'dos',x.dos)}</td>
                  <td>{safe(x,'service_place','')}</td>
                  <td>{safe(z,'cpt','')}</td>
                  <td style={{textAlign:'right'}}>{safe(z,'units','')}</td>
                  <td style={{textAlign:'right'}}>{numeral(safe(z,'billed_amount',0)).format('$0,0.00')}</td>
                  <td style={{textAlign:'right'}}>{numeral(safe(z,'allowed_amount',0)).format('$0,0.00')}</td>
                  <td style={{textAlign:'right'}}>{numeral(safe(z,'adj',0)).format('$0,0.00')}</td>
                  <td style={{textAlign:'right'}}>{numeral(safe(z,'copay',0)).format('$0,0.00')}</td>
                  <td style={{textAlign:'right'}}>{numeral(safe(z,'deductible',0)).format('$0,0.00')}</td>
                  <td style={{textAlign:'right'}}>{numeral(safe(z,'coins',0)).format('$0,0.00')}</td>
                  <td style={{textAlign:'right'}}>{safe(z,'adj_code','')}</td>
                  <td style={{textAlign:'right'}}>{numeral(safe(z,'patient',0)).format('$0,0.00')}</td>
                  <td style={{textAlign:'right'}}>{numeral(safe(z,'paid_amount',0)).format('$0,0.00')}</td>
                </tr>
              );
            }) }
            <tr>
              <th colSpan='4'>Total:</th>
              <td style={{textAlign:'right'}}>{numeral(safe(x,'billed_amount',0)).format('$0,0.00')}</td>
              <td style={{textAlign:'right'}}>{numeral(safe(x,'allowed_amount',0)).format('$0,0.00')}</td>
              <td style={{textAlign:'right'}}>{numeral(safe(x,'adj',0)).format('$0,0.00')}</td>
              <td style={{textAlign:'right'}}>{numeral(safe(x,'copay',0)).format('$0,0.00')}</td>
              <td style={{textAlign:'right'}}>{numeral(safe(x,'deductible',0)).format('$0,0.00')}</td>
              <td style={{textAlign:'right'}}>{numeral(safe(x,'coins',0)).format('$0,0.00')}</td>
              <td></td>
              <td style={{textAlign:'right'}}>{numeral(safe(x,'pt_resp',0)).format('$0,0.00')}</td>
              <td style={{textAlign:'right'}}>{numeral(safe(x,'paid_amount',0)).format('$0,0.00')}</td>
            </tr>
            <tr><td><br/></td></tr>
            <tr>
              <th colSpan='12' style={{textAlign:'right'}}>Claim Interest</th>
              <td style={{textAlign:'right'}}>{numeral(safe(x,'supplemental.coverage_interest',0)).format('$0,0.00')}</td>
            </tr>
            <tr>
              <th colSpan='12' style={{textAlign:'right'}}>Discount</th>
              <td style={{textAlign:'right'}}>{numeral(safe(x,'supplemental.discount_amount',0)).format('$0,0.00')}</td>
            </tr>
            <tr>
              <th colSpan='12' style={{textAlign:'right'}}>Claim Adjustments</th>
              <td style={{textAlign:'right'}}>{numeral(safe(x,'total_claim_adjustments',0)).format('$0,0.00')}</td>
            </tr>
            <tr>
              <th colSpan='12' style={{textAlign:'right'}}>Paid Amount</th>
              <td style={{textAlign:'right'}}>{numeral(safe(x,'paid_amount',0)).format('$0,0.00')}</td>
            </tr>

          </tbody>
        </table>
      </div>
    );
  }

  serviceSupplementalInformation = (services) => {
    if (!services || services.length === 0) {
      return null;
    }

    let counter = 1;

    return (
      <div style={{paddingTop:'30px'}}>
        <h3 style={{textAlign:'center'}}>Supplemental Information</h3>
        <table width='100%'>
          <tbody>
            <tr>
              <th width='33%'>Procedure</th>
              <th width='33%'>Amount Qualifier</th>
              <th width='33%' style={{textAlign:'right'}}>Amount</th>
            </tr>
            { services.map(x => {
              return (
                <tr key={counter++}>
                  <td>{x.cpt}</td>
                  <td>Allowed</td>
                  <td style={{textAlign:'right'}}>{numeral(safe(x,'allowed_amount',0)).format('$0,0.00')}</td>
                </tr>
              );
            }) }
          </tbody>
        </table>
      </div>
    );
  }

  adjustmentInformation = (claim) => {
    const result = [];

    if (claim.services) {
      claim.services.forEach(service => {
        const cpt = service.cpt;
        if (service.service_adjustments) {
          service.service_adjustments.forEach(adj => {
            result.push({
              dos: safe(service,'dos',claim.dos),
              cpt: cpt,
              level: 'Service',
              code: adj.group_code,
              reason: adj.reason_code,
              amount: adj.amount
            });
          });
        }
      });
    }

    if (claim.claim_adjustments) {
      claim.claim_adjustments.forEach(item => {
        result.push({
          dos: '',
          cpt: '',
          level: 'Claim',
          code: item.group_code,
          reason: item.reason_code,
          amount: item.amount
        })
      })
    }

    if (result.length === 0) {
      return null;
    }

    let counter = 1000;

    return (
      <div style={{paddingTop:'30px'}}>
        <h3 style={{textAlign:'center'}}>Adjustment Information</h3>
        <table width='100%'>
          <thead>
            <tr>
              <th>Procedure</th>
              <th>DOS</th>
              <th>Adj Level</th>
              <th>Code</th>
              <th>Reason</th>
              <th style={{width:'65%'}}>Description</th>
              <th style={{textAlign:'right'}}>Amount</th>
            </tr>
            { result.map(q=>{
                return (
                  <tr key={counter++} style={{verticalAlign:'top'}}>
                    <td>{q.cpt}</td>
                    <td>{q.dos}</td>
                    <td>{q.level}</td>
                    <td>{q.code}</td>
                    <td>{q.reason}</td>
                    <td style={{width:'65%'}}>{this.findCarCode(q.reason)}</td>
                    <td style={{textAlign:'right'}}>{numeral(q.amount).format('$0,0.00')}</td>
                  </tr>
                );
            }) }
          </thead>
        </table>
      </div>
    );
  }

  render() {
    const x = this.props.data;

    return (
      <div>
        <div style={{paddingTop:'30px'}}>
          <table width='100%'>
            <tbody>
              <tr>
                <th style={{textAlign:'right'}}>Patient Name:</th>
                <td>{ safe(x,'patient.lastname','') } {safe(x,'patient.firstname','')} { safe(x,'patient.middlename','')} { safe(x,'patient.id_num','') }</td>
                <th style={{textAlign:'right'}}>Provider:</th>
                <td>{ safe(x,'provider.lastname','') } {safe(x,'provider.firstname','')} { safe(x,'provider.middlename','')}</td>
                <th style={{textAlign:'right'}}>Network ID:</th>
                <td>{safe(x,'network_id','')}</td>
              </tr>
              <tr>
                <th style={{textAlign:'right'}}>Corrected Pt/Insured Name:</th>
                <td>{ safe(x,'corrected_patient.lastname','') } {safe(x,'corrected_patient.firstname','')} { safe(x,'corrected_patient.middlename','')} { safe(x,'corrected_patient.id_num','') }</td>
                <th style={{textAlign:'right'}}>Patient Acct#:</th>
                <td>{ safe(x,'control_number','')}</td>
                <th style={{textAlign:'right'}}>Claim ID:</th>
                <td>{safe(x,'payer_control_number','')}</td>
              </tr>
              <tr>
                <th style={{textAlign:'right'}}>Insured Name:</th>
                <td>{ safe(x,'insured.lastname','') } {safe(x,'insured.firstname','')} { safe(x,'insured.middlename','')} { safe(x,'insured.id_num','') }</td>
              </tr>
            </tbody>
          </table>
        </div>

        { this.serviceLevelInformation(x) }
        { this.adjustmentInformation(x) }
        { this.serviceSupplementalInformation(x.services) }

      </div>
    );
  }
}

export default ClaimDisplay;
