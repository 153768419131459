import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Button, Row, Col, message, Spin } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as billing_actions from '../../../actions/billing';
import * as shared_actions from '../../../actions/shared';
import { safe } from '../../../helpers/reduxSelectors';
import { usStateAbbrOptions } from '../../../helpers/states';

class TpaEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tpa_id: parseInt(this.props.match.params.id,10)
    }
  }

  componentDidMount() {
    if (this.state.tpa_id !== 0) {
      this.props.tpaLoad(this.state.tpa_id);
    } else {
      this.props.tpaLoadClear();
    }
    this.props.getCarrierList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load !== this.props.load) {
      if (this.props.load.error) {
        this.props.history.push('/billing/tpa');
        message.error('Unable to load TPA information');
      }
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.success) {
        this.props.tpaSaveClear();
        this.props.history.push('/billing/tpa');
        message.success('TPA saved');
      } else if (this.props.error) {
        message.error('Unable to save TPA');
      }
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        this.props.payerCreateClear();
        this.props.history.push('/billing/tpa');
        message.success('TPA created');
      } else if (this.props.error) {
        message.error('Unable to save TPA');
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.tpa_id === 0) {
          this.props.tpaCreate(values);
        } else {
          this.props.tpaSave(this.state.tpa_id,values);
        }
      }
    });
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const TextArea = Input.TextArea;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };

    const data = safe(this.props,'load.data',{});

    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off" className='tpa-form'>
        <FormItem {...formItemLayout} label="TPA Name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.name
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Associated Carrier">
          {getFieldDecorator('carrier_id', {
            rules: [{ required: true, message: " " }],
            initialValue: data.carrier_id
          })(
            <Select>
              { this.props.carrierList.map(x=><Option key={x.id} value={x.id}>{x.name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Payer ID"
        >
          {getFieldDecorator('payerid', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.payerid
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Address 1"
        >
          {getFieldDecorator('address1', {
            initialValue: data.address1
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Address 2"
        >
          {getFieldDecorator('address2', {
            initialValue: data.address2
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="City"
        >
          {getFieldDecorator('city', {
            initialValue: data.city
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="State">
          {getFieldDecorator('state', {
            initialValue: (data.state || 'CA'),
          })(
            <Select autoComplete="off">
              {usStateAbbrOptions}
            </Select>
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Zip">
          {getFieldDecorator('zip', {
            initialValue: data.zip
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Notes'>
          {getFieldDecorator('notes', {
            initialValue: data.notes
          })(
            <TextArea rows={4}/>
          )}
        </FormItem>

        <br />

        <Row>
          <Col xs={36} sm={16}>
            <Button
              htmlType="submit"
              style={{float:'right'}}
              disabled={this.props.isBusy}
              type="primary">
              Save
            </Button>
          </Col>
        </Row>

      </Form>
    );
  }

  render() {
    return (
      <div style={{padding:'10px'}}>
        <h3>Edit Third-Party Processor</h3>
        <Spin spinning={this.props.isBusy} size="large">
          { this.renderForm() }
        </Spin>
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    load: safe(state,'billing.tpa.load'),
    save: safe(state,'billing.tpa.save'),
    create: safe(state,'billing.tpa.create'),
    carrierList: safe(state,'shared.carrier.list.data',[]).filter(x=>x.active),
    isBusy: (
      safe(state,'billing.tpa.load.processing') ||
      safe(state,'billing.tpa.save.processing') ||
      safe(state,'billing.tpa.create.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(Form.create()(withRouter(TpaEdit)));
