import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Col, Spin, message, Select } from 'antd';
import { connect } from 'react-redux';

import * as actions from '../../../actions/settings';
import { safe } from '../../../helpers/reduxSelectors';

class Scheduling extends Component {

  componentDidMount() {
    this.props.getSchedulingInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.save.processing) {
      if (this.props.save.success) {
        message.success('Scheduling settings saved');
      } else {
        message.error('Unable to save scheduling settings');
      }
      this.props.clearSchedulingSave();
    }
  }

  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.saveSchedulingInfo(values);
      }
    });
  }

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const data = safe(this.props,'load.data',{});
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };

    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy}>
          <h3>Scheduling Settings</h3>
          <Form className='org-form'>
            <FormItem {...formItemLayout} label="Practice Search Ahead Psychiatry">
              {getFieldDecorator('searchahead_psychiatry', {
                initialValue: data.searchahead_psychiatry
              })(
                <Select>
                  <Option value={2}>2 Weeks</Option>
                  <Option value={3}>3 Weeks</Option>
                  <Option value={4}>4 Weeks</Option>
                  <Option value={5}>5 Weeks</Option>
                  <Option value={6}>6 Weeks</Option>
                  <Option value={7}>7 Weeks</Option>
                  <Option value={8}>8 Weeks</Option>
                  <Option value={9}>9 Weeks</Option>
                  <Option value={10}>10 Weeks</Option>
                  <Option value={11}>11 Weeks</Option>
                  <Option value={12}>12 Weeks</Option>
                </Select>
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Practice Search Ahead Therapy">
              {getFieldDecorator('searchahead_therapy', {
                initialValue: data.searchahead_therapy
              })(
                <Select>
                  <Option value={2}>2 Weeks</Option>
                  <Option value={3}>3 Weeks</Option>
                  <Option value={4}>4 Weeks</Option>
                  <Option value={5}>5 Weeks</Option>
                  <Option value={6}>6 Weeks</Option>
                  <Option value={7}>7 Weeks</Option>
                  <Option value={8}>8 Weeks</Option>
                  <Option value={9}>9 Weeks</Option>
                  <Option value={10}>10 Weeks</Option>
                  <Option value={11}>11 Weeks</Option>
                  <Option value={12}>12 Weeks</Option>
                </Select>
              )}
            </FormItem>

            <Row style={{paddingTop:'30px'}}>
              <Col xs={36} sm={16}>
                <Button
                  disabled={this.props.isBusy}
                  onClick={this.handleSubmit}
                  style={{float:'right'}}
                  type="primary"
                >
                  Save
                </Button>
              </Col>
            </Row>

          </Form>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    load: safe(state,'settings.scheduling.load'),
    save: safe(state,'settings.scheduling.save'),
    isBusy: (
      safe(state,'settings.scheduling.load.processing') ||
      safe(state,'settings.scheduling.save.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(Form.create()(Scheduling));
