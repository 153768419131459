import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

class FormattedCell extends React.Component {
  static defaultProps = {
    colors: true,
    blankonzero: true,
    reversed: true,
    value: 0.0
  }
  static propTypes = {
    colors: PropTypes.bool,
    blankonzero: PropTypes.bool,
    reversed: PropTypes.bool,
  }

  renderNumber = () => {
    if (this.props.value === undefined || (this.props.blankonzero && parseFloat(this.props.value) === 0)) {
      return null;
    }
    return numeral(this.props.value).format('$0,0.00');
  }

  getColor = () => {
    let value = (this.props.reversed) ? -this.props.value : this.props.value;

    if (this.props.colors) {
      if (value < 0.0) {
        return 'rgb(255,0,0)';
      } else if (value > 0.0) {
        return 'rgb(0,0,255)';
      }
    }
    return null;
  }

  render() {
    const c = this.getColor();
    if (c) {
      return <span style={{color:c}}>{this.renderNumber()}</span>
    } else {
      return <span>{this.renderNumber()}</span>
    }
  }
}

export default FormattedCell;
