import axios from 'axios';
import * as types from './types';
import queryString from 'qs';

const reportsLogout = (dispatch,err) => {
  if (err && err.response && err.response.status === 401) {
    dispatch ({type:'USER_LOGIN_CLEAR'});
  }
}

export const reportsPaymentsPostedCount = (year,month) => async dispatch => {
  dispatch ({type: types.REPORTS_PAYMENTSPOSTED_REQUEST});
  try {
    const res = await axios.get('/api/v1/reports/paymentspostedcount/'+year+'/'+month);
    dispatch ({type: types.REPORTS_PAYMENTSPOSTED_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_PAYMENTSPOSTED_FAILURE, error: err.response});
  }
}

export const reportsEvals = (start_date,end_date,page,pagesize) => async dispatch => {
  dispatch ({type: types.REPORTS_EVALS_REQUEST});
  try {
    const qs = queryString.stringify({
      page: page,
      pagesize: pagesize,
      start_date: start_date,
      end_date: end_date
    });
    const res = await axios.get('/api/v1/reports/evals?'+qs);
    dispatch ({type: types.REPORTS_EVALS_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_EVALS_FAILURE, error: err.response});
  }
}

export const reportsOpenTimes = (start_date,end_date,page,pagesize) => async dispatch => {
  dispatch ({type: types.REPORTS_OPENTIMES_REQUEST});
  try {
    const qs = queryString.stringify({
      page: page,
      pagesize: pagesize,
      start_date: start_date,
      end_date: end_date
    });
    const res = await axios.get('/api/v1/reports/opentimes?'+qs);
    dispatch ({type: types.REPORTS_OPENTIMES_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_OPENTIMES_FAILURE, error: err.response});
  }
}

export const reportsScheduleTemplates = (page,pagesize) => async dispatch => {
  dispatch ({type: types.REPORTS_SCHEDULETEMPLATES_REQUEST});
  try {
    const qs = queryString.stringify({
      page: page,
      pagesize: pagesize
    });
    const res = await axios.get('/api/v1/reports/scheduletemplates?'+qs);
    dispatch ({type: types.REPORTS_SCHEDULETEMPLATES_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_SCHEDULETEMPLATES_FAILURE, error: err.response});
  }
}

export const reportsOnHold = () => async dispatch => {
  dispatch ({type: types.REPORTS_ONHOLD_REQUEST});
  try {
    const res = await axios.get('/api/v1/reports/onhold');
    dispatch ({type: types.REPORTS_ONHOLD_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_ONHOLD_FAILURE, error: err.response});
  }
}

export const reportsSessionCounts = () => async dispatch => {
  dispatch ({type: types.REPORTS_SESSIONCOUNTS_REQUEST});
  try {
    const res = await axios.get('/api/v1/reports/sessioncounts');
    dispatch ({type: types.REPORTS_SESSIONCOUNTS_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_SESSIONCOUNTS_FAILURE, error: err.response});
  }
}

export const reportsGetHourlyEmployees = (start,end) => async dispatch => {
  dispatch ({type: types.REPORTS_HOURLYEMPLOYEES_REQUEST});
  try {
    const res = await axios.get('/api/v1/reports/hourlyemployees/'+start+'/'+end);
    dispatch ({type: types.REPORTS_HOURLYEMPLOYEES_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_HOURLYEMPLOYEES_FAILURE, error: err.response});
  }
}

export const reportsGetPtCredits = (page,pagesize) => async dispatch => {
  dispatch ({type: types.REPORTS_PTCREDITS_REQUEST});
  try {
    const page_encoded = encodeURIComponent(page);
    const pagesize_encoded = encodeURIComponent(pagesize);
    const res = await axios.get(`/api/v1/reports/ptcredits?page=${page_encoded}&pagesize=${pagesize_encoded}`);
    dispatch ({type: types.REPORTS_PTCREDITS_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_PTCREDITS_FAILURE, error: err.response});
  }
}

export const reportsGetPtUnallocated = (page,pagesize) => async dispatch => {
  dispatch ({type: types.REPORTS_PTUNALLOCATED_REQUEST});
  try {
    const page_encoded = encodeURIComponent(page);
    const pagesize_encoded = encodeURIComponent(pagesize);
    const res = await axios.get(`/api/v1/reports/ptunallocated?page=${page_encoded}&pagesize=${pagesize_encoded}`);
    dispatch ({type: types.REPORTS_PTUNALLOCATED_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_PTUNALLOCATED_FAILURE, error: err.response});
  }
}

export const getIntakesReport = (params) => async dispatch => {
  dispatch ({type: types.REPORTS_INTAKES_REQUEST});
  try {
    const res = await axios.post('/api/v1/reports/intakes',params);
    dispatch ({type: types.REPORTS_INTAKES_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_INTAKES_FAILURE, error: err.response});
  }
}

export const getUnsignedMissingReport = () => async dispatch => {
  dispatch ({type: types.REPORTS_UNSIGNEDMISSED_REQUEST});
  try {
    const res = await axios.get('/api/v1/reports/unsignedmissing');
    dispatch ({type: types.REPORTS_UNSIGNEDMISSED_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_UNSIGNEDMISSED_FAILURE, error: err.response});
  }
}

export const queueReport = (report_id,params) => async dispatch => {
  dispatch ({type: types.REPORTS_QUEUE_ADD_REQUEST});
  try {
    const res = await axios.post('/api/v1/reports/queue/'+report_id,params);
    dispatch ({type: types.REPORTS_QUEUE_ADD_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_QUEUE_ADD_FAILURE, error: err.response});
  }
}

export const queueDelete = (queue_id,page,pagesize) => async dispatch => {
  dispatch ({type: types.REPORTS_QUEUE_DELETE_REQUEST});
  try {
    const res = await axios.delete('/api/v1/reports/queue/'+queue_id+'?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pagesize));
    dispatch ({type: types.REPORTS_QUEUE_DELETE_SUCCESS, payload: res.data});
    dispatch ({type: types.REPORTS_QUEUE_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_QUEUE_DELETE_FAILURE, error: err.response});
  }
}

export const queueCancel = (queue_id,page,pagesize) => async dispatch => {
  dispatch ({type: types.REPORTS_QUEUE_CANCEL_REQUEST});
  try {
    const res = await axios.delete('/api/v1/reports/queue/cancel/'+queue_id+'?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pagesize));
    dispatch ({type: types.REPORTS_QUEUE_CANCEL_SUCCESS, payload: res.data});
    dispatch ({type: types.REPORTS_QUEUE_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_QUEUE_CANCEL_FAILURE, error: err.response});
  }
}

export const loadMyReports = (page,pagesize) => async dispatch => {
  dispatch ({type: types.REPORTS_QUEUE_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/reports/myreports?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pagesize));
    dispatch ({type: types.REPORTS_QUEUE_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_QUEUE_LIST_FAILURE, error: err.response});
  }
}

export const getBillsList = (page,pagesize) => async dispatch => {
  dispatch ({type: types.REPORTS_BILLS_REQUEST});
  try {
    const res = await axios.get('/api/v1/reports/bills?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pagesize));
    dispatch ({type: types.REPORTS_BILLS_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_BILLS_FAILURE, error: err.response});
  }
}

export const getPayrollList = (params,page,pagesize) => async dispatch => {
  dispatch ({type: types.REPORTS_PAYROLL_REQUEST});
  try {
    const res = await axios.post('/api/v1/reports/payroll?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pagesize),params);
    dispatch ({type: types.REPORTS_PAYROLL_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_PAYROLL_FAILURE, error: err.response});
  }
}

export const getChargeCollectionList = (params,page,pagesize) => async dispatch => {
  dispatch ({type: types.REPORTS_CHARGECOLLECTION_REQUEST});
  try {
    const res = await axios.post('/api/v1/reports/chargecollection?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pagesize),params);
    dispatch ({type: types.REPORTS_CHARGECOLLECTION_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_CHARGECOLLECTION_FAILURE, error: err.response});
  }
}

export const getAdjustmentsReport = () => async dispatch => {
  dispatch ({type: types.REPORTS_ADJUSTMENTS_REQUEST});
  try {
    const res = await axios.get('/api/v1/reports/adjustments');
    dispatch ({type: types.REPORTS_ADJUSTMENTS_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_ADJUSTMENTS_FAILURE, error: err.response});
  }
}

export const reportsGetPatients = (page,pagesize) => async dispatch => {
  dispatch ({type: types.REPORTS_PATIENTS_REQUEST});
  try {
    const res = await axios.get('/api/v1/reports/patients?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pagesize));
    dispatch ({type: types.REPORTS_PATIENTS_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_PATIENTS_FAILURE, error: err.response});
  }
}

export const reportsGetGroupStatus = () => async dispatch => {
  dispatch ({type: types.REPORTS_GROUPSTATS_REQUEST});
  try {
    const res = await axios.get('/api/v1/reports/groupstats');
    dispatch ({type: types.REPORTS_GROUPSTATS_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_GROUPSTATS_FAILURE, error: err.response});
  }
}

export const reportsDupSessions = () => async dispatch => {
  dispatch ({type: types.REPORTS_DUPSESSIONS_REQUEST});
  try {
    const res = await axios.get('/api/v1/reports/dupsessions');
    dispatch ({type: types.REPORTS_DUPSESSIONS_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_DUPSESSIONS_FAILURE, error: err.response});
  }
}

export const reportsLyra = () => async dispatch => {
  dispatch ({type: types.REPORTS_LYRA_REQUEST});
  try {
    const res = await axios.get('/api/v1/reports/lyra');
    dispatch ({type: types.REPORTS_LYRA_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_LYRA_FAILURE, error: err.response});
  }
}

export const reportsLifestance = (params,page,pagesize) => async dispatch => {
  dispatch ({type: types.REPORTS_LIFESTANCE_REQUEST});
  try {
    const res = await axios.get('/api/v1/reports/lifestance?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pagesize),params);
    dispatch ({type: types.REPORTS_LIFESTANCE_SUCCESS, payload: res.data});
  } catch (err) {
    reportsLogout(dispatch,err);
    dispatch ({type: types.REPORTS_LIFESTANCE_FAILURE, error: err.response});
  }
}

export const billsListClear = () => async dispatch => {
  dispatch ({type: types.REPORTS_BILLS_CLEAR});
}
