/**
 * EndReportingDate.tsx
 */
import { useContextSelector } from 'use-context-selector';
import { DatePicker, Form } from 'antd';
import formItemRules from 'components/App_/ReportsV2_/formItemRules';
import moment from 'moment';
import QueueContext from 'components/App_/ReportsV2_/QueueContext';
import { ONE_TIME, WEEKLY, MONTHLY } from 'reports';

const EndReporting: React.FC = () => {
  const form = useContextSelector(QueueContext, (c) => c.state.form);
  const type = useContextSelector(QueueContext, (c) => c.state.type);
  const dayOfMonth = useContextSelector(QueueContext, (c) => c.state.dayOfMonth);
  const subType = useContextSelector(QueueContext, (c) => c.state.subType);

  const onScheduleEndDateChange = (date: null | moment.Moment) => {
    const scheduleEndDate = date?.seconds(0).milliseconds(0);
    form?.setFieldsValue({ scheduleEndDate });
  };

  const disabledScheduledEndDate = (current) => {
    let datesOlderThanEndOfToday = true;
    let datesThatAreWeekDay = true;
    let datesThatAreMonthDay = true;

    switch (type) {
      case ONE_TIME:
        datesOlderThanEndOfToday = current < moment().startOf('day');
        break;
      case WEEKLY:
        datesOlderThanEndOfToday = current < moment().add(1, 'weeks').startOf('day');
        break;
      case MONTHLY:
        datesOlderThanEndOfToday = current < moment().add(1, 'months').startOf('day');
        break;
    }

    if (type === 1 && subType) {
      datesThatAreWeekDay = moment(current).isoWeekday() === subType;
    }
    if (type === 2 && subType !== undefined) {
      if (subType === 0) {
        const lastDayOfMonth = moment(current).endOf('month').date();
        // LAST DAY OF MONTH
        datesThatAreMonthDay = moment(current).date() === lastDayOfMonth;
      } else if (subType === 1) {
        // FIRST DAY OF MONTH
        datesThatAreMonthDay = moment(current).date() === subType;
      } else if (subType === 2) {
        // SPECIFIC DAY OF MONTH
        datesThatAreMonthDay = moment(current).date() === dayOfMonth;
      }
    }
    return datesOlderThanEndOfToday || !datesThatAreWeekDay || !datesThatAreMonthDay;
  };

  const scheduleEndDateParams = {
    placeholder: '',
    disabledDate: disabledScheduledEndDate,
    onChange: onScheduleEndDateChange,
    showNow: false,
    format: 'YYYY-MM-DD',
  };

  return (
    <div className="mt4">
      <Form.Item
        className="m0"
        style={{ display: 'inline-block' }}
        name="scheduleEndDate"
        rules={formItemRules.scheduleEndDate}
      >
        <h5>End Reporting</h5>
        <DatePicker {...scheduleEndDateParams} size="small" />
      </Form.Item>
    </div>
  );
};

export default EndReporting;
