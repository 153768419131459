import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';

const columns: Array<ColumnI> = [
  {
    title: 'Provider',
    dataIndex: 'name',
  },
  {
    title: 'Unsigned Progress Notes',
    dataIndex: 'unsigned',
  },
  {
    title: 'Sessions Missing Progress Notes',
    dataIndex: 'missing',
  },
  {
    title: 'Total Unsigned or Missing > 7 Days',
    dataIndex: 'over_7',
  },
  {
    title: 'Total',
    dataIndex: 'total',
  },
];

export default columns;
