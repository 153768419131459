import React from 'react';
import { connect } from 'react-redux';
import { Input, Radio, Tag, Spin } from 'antd';
import moment from 'moment';

import * as actions from '../../../../../actions/billing';
import { safe } from '../../../../../helpers/reduxSelectors';

class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      label: 1
    }
  }

  addComment = () => {
    if (this.state.label) {
      this.props.addBillingComment(this.props.patientId,this.state.comment,this.state.label);
      this.setState({
        comment: ''
      });
    }
  }

  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  displayDateTime = (x) => {
    return moment(x).format('MM/DD/YYYY hh:mm:ss A');
  }

  renderLabel = (x) => {
    switch (x) {
      case 1: return <Tag color="#f50">Insurance</Tag>;
      case 2: return <Tag color="#87d068">Patient</Tag>;
      case 3: return <Tag color="#108ee9">HF</Tag>;
      default:
        break;
    }
    return null;
  }

  render() {
    return (
      <Spin spinning={this.props.isBusy}>
        <div>
          <Radio.Group
            onChange={this.onChange}
            buttonStyle="solid"
            value={this.state.label}
            name='label'
          >
            <Radio.Button value={1}>Insurance</Radio.Button>
            <Radio.Button value={2}>Patient</Radio.Button>
          </Radio.Group>
          <Input.TextArea
            onPressEnter={this.addComment}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
            style={{marginTop:'5px'}}
            rows={2}
            placeholder='Enter comment'
            value={this.state.comment}
            name='comment'
          />
          <div className='comment-list'>
            { this.props.data.map(x=>
              <div key={x.id} className='comment-entry'>
                <div className='comment-byline'>{safe(x,'creator.initials')},
                  {this.displayDateTime(x.createdAt)}
                  <span style={{float:'right'}}>{this.renderLabel(x.label)}</span></div>
                <div className='comment-text'>{x.comment}</div>
              </div>
            )}
          </div>
        </div>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.comments.list.processing',false) ||
      safe(state,'billing.comments.create.processing')
    ) ? true : false,
    data: safe(state,'billing.comments.list.data',[])
  }
}

export default connect(mapStateToProps,actions)(Comments);
