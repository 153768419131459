/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * Form.tsx
 * A form for selecting what to remove from a chart note
 */
import { useContextSelector } from 'use-context-selector';
import useApiPost from 'hooks/useApiPost';
import { Button, Checkbox, Divider, Form, Typography, message } from 'antd';
import { FileOutlined, PaperClipOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import Context from './context';
import FormConfirm from './FormConfirm';
import renderNoteType from './renderNoteType';
import { DateTime } from 'luxon';

interface ItemI {
  fileName: string;
  id: number;
  title: string;
  type: number;
}

const { Text } = Typography;

const NoteHideForm: React.FC = () => {
  const setState = useContextSelector(Context, (c) => c.setState);
  const note = useContextSelector(Context, (c) => c.state.note);
  const form = useContextSelector(Context, (c) => c.state.form);
  const isConfirmVisible = useContextSelector(Context, (c) => c.state.isConfirmVisible);
  const attachmentsDisabled = useContextSelector(Context, (c) => c.state.attachmentsDisabled);
  const hideNoteAPI = useApiPost('/api/v2/notes/hide');
  const attachments = get(note, 'attachments', []) as Array<ItemI>;
  const provider = get(note, 'provider', []) as Record<string, string>;

  const validate = async () => {
    const validatedData = await form?.validateFields();
    const { noteId, attachmentIds } = validatedData;
    if (noteId || attachmentIds?.length) {
      setState((s) => ({ ...s, isConfirmVisible: true }));
    } else {
      message.error('Select at least 1 item');
    }
  };

  const onFinish = () => {
    validate();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('onFinishFailed:', errorInfo);
  };

  const onChangeEntireNote = (e) => {
    const noteId = e.target.checked ? note?.id : undefined;
    setState((s) => ({ ...s, attachmentsDisabled: e.target.checked }));
    const attachmentIds = attachments.map((a) => a.id);
    form?.setFieldsValue({ attachmentIds, noteId });
  };

  const handleCancel = () => {
    setState((s) => ({ ...s, isModalVisible: false }));
  };

  const onReviewChanges = () => {
    validate();
  };

  const options = attachments.map((attachment) => ({
    disabled: attachmentsDisabled,
    label: (
      <>
        <PaperClipOutlined /> {attachment.title}
      </>
    ),
    value: attachment.id,
  }));

  const signedAt = DateTime.fromISO(note?.signedAt as string).toLocaleString(DateTime.DATETIME_SHORT);

  return (
    <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item hidden={isConfirmVisible} name="noteId">
        <Checkbox onChange={onChangeEntireNote}>
          <span>
            <FileOutlined /> {renderNoteType(note?.type as number)}
          </span>
          <div>
            <Text type="secondary">
              Electronically signed by {provider?.full_name} at {signedAt}
            </Text>
          </div>
        </Checkbox>
      </Form.Item>
      <Form.Item hidden={isConfirmVisible} name="attachmentIds">
        <Checkbox.Group options={options} />
      </Form.Item>
      {!isConfirmVisible ? (
        <>
          <Divider />
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>
            <Button key="submit" type="primary" loading={hideNoteAPI?.loading} onClick={onReviewChanges}>
              Review
            </Button>
          </div>
        </>
      ) : (
        <FormConfirm />
      )}
    </Form>
  );
};

export default NoteHideForm;
