import React, { Component } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, DatePicker, Button, Input, Select, Spin, Row, Col, AutoComplete, message, Modal, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { MSE,fieldsMSE } from './MSE';
import { Vitals } from './Vitals';
import ProblemList from './ProblemList';
import AttachmentUpload from './AttachmentUpload';
import SuperviseeComment from './SuperviseeComment';
//import NavigationPrompt from "react-router-navigation-prompt";
//import ConfirmNavigationModal from './ConfirmNavigationModal';

import * as actions from '../../../../../actions/emr';
import { safe } from '../../../../../helpers/reduxSelectors';
//import { therapyTypes } from '../../../../../helpers/therapyTypes';

class ProgressNotePrescriber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      problemlist:[],
      timer: undefined
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.patientId !== this.props.patientId || prevProps.noteId !== this.props.noteId) {
      this.props.form.resetFields();
    }

    if (this.props.loadSuccess && !prevProps.loadSuccess) {
      const timer = setInterval(()=>{
        const fields = [
          'other_note','cc','hpi','psy_history','sub_history','social_history',
          'mental_status','poc','note_date','session_type',
          'session_status','location_id','child_present','medical_history',
          'dev_history','soc_acd_history','allergies','ros','vitals','include_therapy','therapy',
          'therapy_duration','therapy_type','medication_change','rtc',
          'session_duration',
        ];
        if (this.props.form.isFieldsTouched(fields)) {
          if (!this.props.isSaving && !this.props.isSigning) {
            const values = this.props.form.getFieldsValue();
            this.props.noteAutoSave(this.props.noteId,{
              ...values,
              problemlist: this.state.problemlist
            });
          }
        }
      },30000);
      this.setState({
        timer: timer
      });
      this.sessionDurationChange(this.props.thisNote.note.session_duration);
    }
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearInterval(this.state.timer);
    }
  }

  plChange = (problemlist) => {
    this.setState({
      problemlist: problemlist
    });
  }

  discardNote = () => {
    this.props.noteDelete(this.props.noteId);
  }

  saveNote = () => {
    const values = this.props.form.getFieldsValue();
    this.props.noteSave(this.props.noteId,{
      ...values,
      problemlist: this.state.problemlist
    });
  }

  validateProblemList = (values) => {
    const hasDiagnosis = (this.state.problemlist.find(x => x['diagnosis.id'])) ? true : false;
    if (!hasDiagnosis && values.session_status === 1) {
      message.error('A note cannot be signed without a diagnosis');
      return false;
    }

    const gzCodes = this.state.problemlist.filter(x=>{
      const d = x['diagnosis.icd10cm'];
      // The no diagnosis code should be allowed
      if (d === 'Z03.89') {
        return false;
      }
      return (d && d.length > 0 && (d.charAt(0) === 'G' || d.charAt(0) === 'Z')) ? true : false;
    });

    // Also check for solitary G and Z codes. That's invalid.

    if (this.state.problemlist.length === gzCodes.length && values.session_status === 1) {
      message.error('A progress note must include diagnoses other than G or Z codes.');
      return false;
    }

    // Check for duplicate diagnoses
    const diagCount = {};
    this.state.problemlist.forEach(x=>{
      if (x['diagnosis.icd10cm']) {
        if (!diagCount[x['diagnosis.icd10cm']]) {
          diagCount[x['diagnosis.icd10cm']] = 0;
        }
        diagCount[x['diagnosis.icd10cm']]++;
      }
    });

    for (let val of Object.values(diagCount)) {
      if (val > 1) {
        message.error('Your problem list contains duplicate diagnoses');
        return false;
      }
    }

    return true;
  }

  signNote = () => {
    this.props.form.validateFields((errors, values) => {
      if (errors) {
        const errorKeys = Object.keys(errors);
        const errorVals = Object.values(errors);
        const errorMessages = errorKeys.map((k,keyNum) => errorVals[keyNum].errors[0].message);
        notification.error({
          message: 'Missing field(s)',
          description: (
            <>
              {errorMessages.map((msg) => (
                <>
                  {msg}<br/>
                </>
              ))}
            </>
          ),  
        });
      }
      
    });
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err && this.validateProblemList(values)) {
        this.props.noteSign(this.props.noteId,{
          ...values,
          patient_id: this.props.patientId,
          problemlist: this.state.problemlist
        });
      }
    });
  }

  getLocationList = () => {
    const { primary_location, secondary_location } = this.props.activeUser;
    const result = [];

    if (primary_location) {
      result.push({id: primary_location.id, name: primary_location.name});
      if (secondary_location) {
        result.push({id: secondary_location.id, name: secondary_location.name});
      }
    }

    return result;
  }

  sessionDurationChange = (e) => {
    let td = e;
    switch (e) {
      case 20: td = 16; break;
      case 30: td = 20; break;
      case 45: td = 40; break;
      case 60: td = 53; break;
      default: break;
    }
    this.props.form.setFieldsValue({ therapy_duration:td}); 
  }

  handleValidateMSE = (rule, value, callback) => {
    const isPatientPresent = this.props.form.getFieldValue('child_present');
    const age= this.props.patientData.age;

    if (!age || (age < 18 && isPatientPresent) || age > 18) {
      let error = false;
      for (let i = 0; i < fieldsMSE.length; i++) {
        if (value[fieldsMSE[i].name] === undefined || value[fieldsMSE[i].name].length === 0) {
          error = true;
          break;
        }
      }
      if (error) {
        callback(<span style={{color:'red'}}>Missing fields in the MSE</span>);
      }
    }
    callback()
  }

  handleValidateVitals = (rule, value, callback) => {
    //let error = false;
    const isPatientPresent = this.props.form.getFieldValue('child_present');
    const age= this.props.patientData.age;
    if (age === undefined) {
      callback(<span style={{color:'red'}}>Patient DOB must be entered before signing this note</span>);
    } else {
      if (age < 18 && isPatientPresent === 1) {
        let x = 0;
        if (value.weight && value.weight.length > 0) { x++; }
        if (value.pulse && value.pulse.length > 0) { x++; }
        if (value.bp && value.bp.length > 0) { x++; }
        if (value.height && value.height.length > 0) { x++; }

        if (x < 3) {
          callback(<span style={{color:'red'}}>3 out of 4 vitals must be entered for minors</span>);
        }
      }
    }
    callback();
  }

  showDiscardConfirm = () => {
    const dn = this.discardNote;
    Modal.confirm({
      title: 'Are you sure you want to discard this note?',
      onOk() {
        dn();
      }
    });
  }

  clinicianButtons = () => {
    return (
      <Row>
        <Col span={12}>
          <Button type="danger" icon={<DeleteOutlined />} disabled={this.props.isBusy} onClick={this.showDiscardConfirm}>Discard Note</Button>
        </Col>
        <Col span={12} style={{textAlign:'right'}}>
          {this.props.isAutoSaving && (<span className="p4"><Spin/>&nbsp; Autosaving Note...</span>)}
          <Button style={{marginRight:'40px'}} disabled={this.props.isBusy} onClick={this.saveNote}>Save Note</Button>
          <Button type="primary" disabled={this.props.isBusy} onClick={this.signNote}>Sign Note</Button>
        </Col>
      </Row>
    );
  }

  includeButtons = () => {
    return this.clinicianButtons();
  }

  isChildNotPresent = () => {
    const a = this.props.form.getFieldValue('child_present');
    if (a === 1 || a === undefined) {
      return false;
    }
    return true;
  }

  getNoteFields = (initialValues) => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const formItemLayout = {};
    const Option = Select.Option;

    const status = this.props.form.getFieldValue('session_status');
    const session_type = this.props.form.getFieldValue('session_type');
    const include_therapy = this.props.form.getFieldValue('include_therapy');

    if (status === 2 || status === 3) {
      return (
        <div>
          <FormItem
            {...formItemLayout}
            label="No Show Note"
          >
            {getFieldDecorator('other_note', {
              initialValue: initialValues.other_note
            })(
              <TextArea rows={5} autosize={{minRows: 5,maxRows: 6}}/>
            )}
          </FormItem>

          {session_type === 2 && <FormItem
            {...formItemLayout}
            label="Session Duration"
          >
            {getFieldDecorator('session_duration', {
              rules: [{ required: true, message: "Choose session duration." }],
              initialValue: initialValues.session_duration
            })(
              <Select onChange={this.sessionDurationChange}>
                <Option value={20}>20 Minutes</Option>
                <Option value={30}>30 Minutes</Option>
                <Option value={45}>45 Minutes</Option>
                <Option value={60}>60 Minutes</Option>
              </Select>
            )}
          </FormItem> }
        </div>
      );
    }

    const patientAge = safe(this.props,'patientData.age');
    const isChild = (patientAge && patientAge < 18) ? true : false;
    
    return (
      <div>
        { patientAge && patientAge < 18 &&
          <FormItem
            {...formItemLayout}
            label="Is the child present in this session"
          >
            {getFieldDecorator('child_present', {
              rules: [{ required: true, message: " "}],
              initialValue: initialValues.child_present
            })(
              <Select>
                <Option value={1}>Yes</Option>
                <Option value={0}>No</Option>
              </Select>
            )}
          </FormItem>
        }

        <FormItem
          {...formItemLayout}
          label="CC"
        >
          {getFieldDecorator('cc', {
            rules: [{ required: true, message: "Enter Chief Complaint", whitespace: true}],
            initialValue: initialValues.cc
          })(
            <TextArea rows={2} autosize={{minRows: 2,maxRows: 3}}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="HPI/Subjective"
        >
          {getFieldDecorator('hpi', {
            rules: [{ required: true, message: "Enter HPI." }],
            initialValue: initialValues.hpi
          })(
            <TextArea rows={15} autosize={{minRows: 15,maxRows: 20}}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
        >
          {getFieldDecorator('include_therapy', {
            initialValue: typeof initialValues.include_therapy === 'undefined' ? true : initialValues.include_therapy,
            valuePropName: 'checked'
          })(
            <Checkbox>Check if this visit includes psychotherapy</Checkbox>
          )}
        </FormItem>

        <div style={{
          visibility: include_therapy ? 'visible' : 'hidden',
          height: include_therapy ? 'inherit' : '0px',
        }}>
          <FormItem
            {...formItemLayout}
            label="Therapy"
          >
            {getFieldDecorator('therapy', {
              rules: [{ required: include_therapy, message: "Enter therapy." }],
              initialValue: initialValues.therapy,
            })(
              <TextArea rows={5} autosize={{minRows: 5,maxRows: 6}}/>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Therapy Duration"
          >
            {getFieldDecorator('therapy_duration', {
              rules: [{ required: include_therapy, message: "Choose therapy duration." }],
              initialValue: initialValues.therapy_duration,
            })(
              <Select disabled={true}>
                <Option value={20}>20 Minutes</Option>
                <Option value={40}>40 Minutes</Option>
                <Option value={53}>53 Minutes</Option>
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Therapy Type"
          >
            {getFieldDecorator('therapy_type', {
              rules: [{ required: include_therapy, message: "Choose therapy type." }],
              initialValue: initialValues.therapy_type,
            })(
              <Select>
                <Option value='cbt'>CBT</Option>
                <Option value='mindfulness'>Mindfulness</Option>
                <Option value='psychodynamic'>Psychodynamic</Option>
                <Option value='problemsolving'>Problem Solving</Option>
                <Option value='socialskills'>Social Skills Training</Option>
                <Option value='playtherapy'>Play Therapy</Option>
                <Option value='supportive'>Supportive Therapy</Option>
              </Select>
            )}
          </FormItem>
        </div>

        <FormItem
          {...formItemLayout}
          label="Past Psychiatric History"
        >
          {getFieldDecorator('psy_history', {
            rules: [{ required: true, message: "Enter past psychiatric history." }],
            initialValue: initialValues.psy_history
          })(
            <TextArea rows={6} autosize={{minRows: 6,maxRows: 10}}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Substance Abuse History"
        >
          {getFieldDecorator('sub_history', {
            rules: [{ required: true, message: "Enter substance abuse history." }],
            initialValue: initialValues.sub_history
          })(
            <TextArea rows={6} autosize={{minRows: 6,maxRows: 10}}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Medical History"
        >
          {getFieldDecorator('medical_history', {
            rules: [{ required: true, message: "Enter Medical History." }],
            initialValue: initialValues.medical_history
          })(
            <TextArea rows={5} autosize={{minRows: 5,maxRows: 6}}/>
          )}
        </FormItem>

        {isChild && <FormItem
          {...formItemLayout}
          label="Development History"
        >
          {getFieldDecorator('dev_history', {
            rules: [{ required: true, message: "Enter Development History." }],
            initialValue: initialValues.dev_history
          })(
            <TextArea rows={5} autosize={{minRows: 5,maxRows: 6}}/>
          )}
        </FormItem> }

        {isChild && <FormItem
          {...formItemLayout}
          label="Social and Academic Development"
        >
          {getFieldDecorator('soc_acd_history', {
            rules: [{ required: true, message: "Enter Social and Academic Development." }],
            initialValue: initialValues.soc_acd_history
          })(
            <TextArea rows={5} autosize={{minRows: 5,maxRows: 6}}/>
          )}
        </FormItem> }

        <FormItem
          {...formItemLayout}
          label="Social History"
        >
          {getFieldDecorator('social_history', {
            rules: [{ required: true, message: "Enter Social History." }],
            initialValue: initialValues.social_history
          })(
            <TextArea rows={5} autosize={{minRows: 5,maxRows: 6}}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Allergies"
        >
          {getFieldDecorator('allergies', {
            rules: [{ required: true, message: "Enter Allergies." }],
            initialValue: initialValues.allergies
          })(
            <TextArea rows={5} autosize={{minRows: 5,maxRows: 6}}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="ROS"
        >
          {getFieldDecorator('ros', {
            rules: [{ required: true, message: "Enter ROS." }],
            initialValue: initialValues.ros
          })(
            <TextArea rows={5} autosize={{minRows: 5,maxRows: 6}}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Mental Status"
          validateStatus='success'
        >
          {getFieldDecorator('mental_status', {
            rules: [
              { required: true, message: "Enter Mental Status." },
              { validator: this.handleValidateMSE}
            ],
            initialValue: initialValues.mental_status,
          })(
            <MSE disabled={this.isChildNotPresent()}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Vitals"
          validateStatus='success'
        >
          {getFieldDecorator('vitals', {
            rules: [
              { validator: this.handleValidateVitals}
            ],
            initialValue: initialValues.vitals
          })(
            <Vitals/>
          )}
        </FormItem>

        <ProblemList patientId={this.props.patientId} onChange={this.plChange}/>

        <FormItem
          {...formItemLayout}
          label="Assessment and Plan"
        >
          {getFieldDecorator('poc', {
            rules: [{ required: true, message: "Enter Assessment and Plan" }],
            initialValue: initialValues.poc
          })(
            <TextArea rows={8} autosize={{minRows: 8,maxRows: 10}}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Medication Change"
        >
          {getFieldDecorator('medication_change', {
            rules: [{ required: true, message: "Choose medication change." }],
            initialValue: initialValues.medication_change
          })(
            <Select>
              <Option value='yes'>Yes</Option>
              <Option value='no'>No</Option>
            </Select>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="RTC In"
        >
          {getFieldDecorator('rtc', {
            rules: [{ required: true, message: "Enter RTC." }],
            initialValue: initialValues.rtc
          })(
            <AutoComplete
              dataSource={[
                '1 week','2 weeks','3 weeks','6 weeks','1 month','2 months','3 months'
              ]}
              filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Session Duration"
        >
          {getFieldDecorator('session_duration', {
            rules: [{ required: true, message: "Choose session duration." }],
            initialValue: initialValues.session_duration
          })(
            <Select onChange={this.sessionDurationChange}>
              <Option value={20}>20 Minutes</Option>
              <Option value={30}>30 Minutes</Option>
              <Option value={45}>45 Minutes</Option>
              <Option value={60}>60 Minutes</Option>
            </Select>
          )}
        </FormItem>

      </div>
    );
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const formItemLayout = {};
    const locationList = this.getLocationList();
    const initialValues = {
      note_date: moment(safe(this.props,'thisNote.note_date')).tz('America/Los_Angeles'),
      session_type: safe(this.props,'thisNote.note.session_type'),
      session_status: safe(this.props,'thisNote.note.session_status'),
      location_id: safe(this.props,'thisNote.location_id'),
      cc: safe(this.props,'thisNote.note.cc'),
      hpi: safe(this.props,'thisNote.note.hpi'),
      medical_history: safe(this.props,'thisNote.note.medical_history'),
      social_history: safe(this.props,'thisNote.note.social_history'),
      allergies: safe(this.props,'thisNote.note.allergies'),
      mental_status: safe(this.props,'thisNote.note.mental_status'),
      vitals: safe(this.props,'thisNote.note.vitals'),
      ros: safe(this.props,'thisNote.note.ros'),
      therapy: safe(this.props,'thisNote.note.therapy'),
      therapy_duration: safe(this.props,'thisNote.note.therapy_duration','45'),
      therapy_type: safe(this.props,'thisNote.note.therapy_type'),
      poc: safe(this.props,'thisNote.note.poc'),
      medication_change: safe(this.props,'thisNote.note.medication_change'),
      rtc: safe(this.props,'thisNote.note.rtc'),
      session_duration: safe(this.props,'thisNote.note.session_duration','30'),
      other_note: safe(this.props,'thisNote.note.other_note'),
      child_present: safe(this.props,'thisNote.note.child_present',1),
      telemedicine: safe(this.props,'thisNote.telemedicine'),
      include_therapy: safe(this.props,'thisNote.note.include_therapy'),
      psy_history: safe(this.props,'thisNote.note.psy_history'),
      sub_history: safe(this.props,'thisNote.note.sub_history'),
      dev_history: safe(this.props,'thisNote.note.dev_history'),
      soc_acd_history: safe(this.props,'thisNote.note.soc_acd_history')
    }

/*
<Col span={8}>
  <FormItem
    {...formItemLayout}
    label="Session Time"
  >
    {getFieldDecorator('note_time', {
      rules: [{ type:'object', required: true, message: "Please enter the session time", whitespace: true }],
      initialValue: initialValues.note_time
    })(
      <TimePicker use12Hours format="h:mm A" minuteStep={5}/>
    )}
  </FormItem>
</Col>
*/
    return (
      <Form layout={'vertical'} autoComplete="off">

        <Row>
          <Col span={8}>
            <FormItem {...formItemLayout} label="Session Date">
              {getFieldDecorator('note_date', {
                rules: [{ type: 'object', required: true, message: "Please enter a date for this session", whitespace: true }],
                initialValue: initialValues.note_date
              })(
                <DatePicker disabled={true} format='M/DD/YYYY' />
              )}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem {...formItemLayout} label="Telemedicine">
              {getFieldDecorator('telemedicine', {
                rules: [{ required: true, message: "Choose whether session is telemedicine" }],
                initialValue: initialValues.telemedicine,
              })(
                <Select>
                  <Option value={1}>Yes</Option>
                  <Option value={0}>No</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{paddingRight:'10px'}}>
            <FormItem
              {...formItemLayout}
              label="Session Type"
            >
              {getFieldDecorator('session_type', {
                rules: [{ required: true, message: "Choose session status." }],
                initialValue: initialValues.session_type
              })(
                <Select disabled={true}>
                  <Option value={1}>Initial Evaluation</Option>
                  <Option value={2}>Followup</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={8} style={{paddingRight:'10px'}}>
            <FormItem
              {...formItemLayout}
              label="Session Status"
            >
              {getFieldDecorator('session_status', {
                rules: [{ required: true, message: "Choose session status." }],
                initialValue: initialValues.session_status
              })(
                <Select>
                  <Option value={1}>Show</Option>
                  <Option value={2}>No Show</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              {...formItemLayout}
              label="Service Location"
            >
              {getFieldDecorator('location_id', {
                rules: [{ required: true, message: "Choose a service location." }],
                initialValue: initialValues.location_id
              })(
                <Select>
                  { locationList.map(a => (<Option key={a.id} value={a.id}>{a.name}</Option>)) }
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        {this.getNoteFields(initialValues)}

        <AttachmentUpload patientId={this.props.patientId} noteId={this.props.noteId}/>

        {this.includeButtons()}

      </Form>
    );
  }

  autoSaveNote = () => {
    if (this.props.autoSaveTimeDate) {
      return (
        <div style={{fontStyle:'italic'}}>Last autosaved {this.props.autoSaveTimeDate}</div>
      );
    }
    return null;
  }
//           <Link to={"/emr/patients/"+this.props.patientId}>&lt;&lt; Return to patient chart</Link>
/*
<NavigationPrompt when={true}>
 {({ onConfirm, onCancel }) => (
   <ConfirmNavigationModal
     when={true}
     onCancel={onCancel}
     onConfirm={onConfirm}
   />
)}
</NavigationPrompt>
*/
  render() {
    /*
    if (!this.props.patientData) {
      this.props.history.push('/emr/patients');
      return null;
    } else { */
      return (
        <div style={{padding:'15px'}}>
          <h3 style={{paddingTop:'10px'}}>Prescriber Progress Note</h3>
          { this.autoSaveNote() }
          <div style={{paddingBottom:'20px'}}/>
          <SuperviseeComment
            providerId={this.props.thisNote.provider_id}
            superviserId={this.props.thisNote.supervising_provider_id}
            noteId={this.props.noteId}
            comments={this.props.thisNoteComments}
            ac={this.props.addComment}
          />
          { this.renderForm() }
        </div>
      );
//    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loadSuccess: safe(state,'emr.note.load.success'),
    loadProcessing: safe(state,'emr.note.load.processing'),
    isBusy: (
      safe(state,'emr.note.load.processing',false) ||
      safe(state,'emr.note.create.processing',false) ||
      safe(state,'emr.note.save.processing',false) ||
      safe(state,'emr.note.autosave.processing',false) ||
      safe(state,'emr.note.sign.processing',false) ||
      safe(state,'emr.note.delete.processing',false)
    ) ? true : false,
    isSaving: safe(state,'emr.note.save.processing',false),
    isAutoSaving: safe(state,'emr.note.autosave.processing',false),
    isSigning: safe(state,'emr.note.sign.processing',false),
    patientData: safe(state,'shared.patient.load.data',{}),
    activeUser: safe(state,'shared.domain.activeUser'),
    thisNote: safe(state,'emr.note.load.data',{}),
    thisNoteComments: safe(state,'emr.comments.list.data',[])
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(ProgressNotePrescriber)));
