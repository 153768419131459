import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Spin } from 'antd';
import moment from 'moment';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';

class ReportsPatientsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pageSize: 50,
      currentPage: 1
    }
  }

  componentDidMount() {
    this.props.reportsGetPatients(this.state.currentPage,this.state.pageSize);
  }

  tableChange = (x) => {
    this.setState({
      currentPage:x.current
    },() => {
      this.props.reportsGetPatients(this.state.currentPage,this.state.pageSize)
    });
  }

  address = (r) => {
    let result = [];
    if (r) {
      if (r.address1) { result.push(r.address1) }
      if (r.address2) { result.push(r.address2) }
      if (r.city) { result.push(r.city) }
      if (r.state) { result.push(r.state) }
      if (r.zip) { result.push(r.zip) }
    }
    return result.join(',');
  }

  render() {
    const columns = [{
      title: 'Patient ID',
      dataIndex: 'id',
      key: 'id'
    },{
      title: 'Patient',
      dataIndex: 'full_name',
      key: 'patient'
    },{
      title: 'Patient DOB',
      dataIndex: 'dob',
      key: 'dob',
      render: (val) => {
        const m = moment(val);
        return (m.isValid()) ? m.format('MM/DD/YYYY') : "";
      }
    },{
      title: 'Patient Age',
      dataIndex: 'age',
      key: 'age'
    },{
      title: 'Patient Gender',
      dataIndex: 'gender',
      key: 'gender'
    },{
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },{
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },{
      title: "Address",
      dataIndex: 'address1',
      key: 'address',
      render: (val,record) => {
        return this.address(record);
      }
    },{
      title: 'Chart Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (val) => {
        return moment(val).format('MM/DD/YYYY')
      }
    }];

    const data = safe(this.props.list,'rows',[]);
    const count = safe(this.props.list,'count',0);

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
            <span style={{float:'right'}}><a href="/api/v1/reports/patients?csv=1">CSV Download</a></span>
          </div>

          <h3>Patient List</h3>
          <Table
            className='reports-sessionlist-table'
            columns={columns}
            dataSource={data}
            bordered={true}
            size='small'
            rowKey='key'
            scroll={{ x:true }}
            onChange={this.tableChange}
            pagination={{
              current: this.state.currentPage,
              pageSize: this.state.pageSize,
              total: count
            }}
          />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    list: safe(state,'reports.patients.data',{}),
    isBusy: safe(state,'reports.patients.processing',false)
  }
}

export default connect(mapStateToProps,actions)(ReportsPatientsList);
