import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BarsOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { safe } from '../../../../../helpers/reduxSelectors';

import Policy from './BillingPolicy_/Policy';
import PolicyEntryDialog from './BillingPolicy_/PolicyEntryDialog';
import PolicyHistoryDialog from './BillingPolicy_/PolicyHistoryDialog';

class BillingPolicy extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      dialogData: {},
      dialogBillingPolicyId: 0,
      dialogBillingPatientId: 0,

      historyDialogVisible: false,
      historyDialogPatientId: 0
    }
  }

  showDialog = (newBillingPolicyId,newBillingPatientId,newData) => {
    this.setState({
      dialogVisible: true,
      dialogBillingPolicyId: newBillingPolicyId,
      dialogBillingPatientId: newBillingPatientId,
      dialogData: newData
    });
  }

  hideDialog = () => {
    this.setState({
      dialogVisible: false
    })
  }

  hideHistoryDialog = () => {
    this.setState({
      historyDialogVisible: false
    });
  }

  onClickAddBillingPolicy = () => {
    this.showDialog(0,this.props.patientId,this.props.patientData);
  }

  onClickBillingPolicyHistory = () => {
    this.setState({
      historyDialogVisible: true,
      historyDialogPatientId: this.props.patientId
    })
  }

  render() {
    return (
      <Spin spinning={this.props.isBusy}>
        <div>
          <div style={{paddingTop: '10px'}}>
            <span>
              <a onClick={this.onClickAddBillingPolicy}>
                <PlusCircleOutlined />&nbsp; Add a Billing Policy
              </a>
            </span>
            <span style={{float:'right'}}>
              <a onClick={this.onClickBillingPolicyHistory}>
                <BarsOutlined />&nbsp; History
              </a>
            </span>
          </div>

          { this.props.data.map(x=>
            <Policy
              data={x}
              key={x.id}
              showDialog={this.showDialog}
            />
          )}

          <PolicyEntryDialog
            visible={this.state.dialogVisible}
            hideDialog={this.hideDialog}
            billingPatientId={this.state.dialogBillingPatientId}
            billingPolicyId={this.state.dialogBillingPolicyId}
            patientData={this.state.dialogData}
          />

          <PolicyHistoryDialog
            visible={this.state.historyDialogVisible}
            hideDialog={this.hideHistoryDialog}
            patientId={this.state.historyDialogPatientId}
          />

        </div>
      </Spin>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.policies.list.processing',false) ||
      safe(state,'billing.policies.save.processing',false) ||
      safe(state,'billing.policies.create.processing',false) ||
      safe(state,'billing.policies.delete.processing',false)
    ) ? true : false
  }
}

export default connect(mapStateToProps,null)(BillingPolicy);
