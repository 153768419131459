import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import { safe } from '../../../../helpers/reduxSelectors';

class BillingStatement extends React.Component {
  static propTypes = {
    data: PropTypes.object,
  };

  formatMoney = (col, type, x) => {
    if (col === type) {
      return numeral(x).format('$0,0.00');
    }
    return '';
  };

  displayDescription = (description, details, payment_amount) => {
    if (description === 'Payment') {
      const result = [];
      details.forEach((x) => {
        result.push(numeral(x.amount).format('$0,0.00') + ' - ' + x.dos);
      });
      return (
        <div>
          <div>{payment_amount < 0 ? 'Refund' : 'Payment'}</div>
          {result.map((y) => (
            <div style={{ paddingLeft: '10px' }}>{y}</div>
          ))}
        </div>
      );
    } else {
      return description;
    }
  };

  renderServices = (services) => {
    return (
      <table width="100%" className="statement-table">
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Date</th>
            <th style={{ textAlign: 'left' }}>Description</th>
            <th style={{ textAlign: 'right' }}>Patient Charges</th>
            <th style={{ textAlign: 'right' }}>Patient Payments</th>
            <th style={{ textAlign: 'right' }}>Balance</th>
          </tr>
        </thead>
        <tbody>
          {services.map((x) => (
            <tr key={x.id}>
              <td style={{ textAlign: 'left' }}>{x.date}</td>
              <td style={{ textAlign: 'left' }}>
                {this.displayDescription(x.description, x.payment_details, x.payment)}
              </td>
              <td style={{ textAlign: 'right' }}>{this.formatMoney(1, x.type, x.charge)}</td>
              <td style={{ textAlign: 'right' }}>{this.formatMoney(2, x.type, x.payment)}</td>
              <td style={{ textAlign: 'right' }}>{this.formatMoney(3, 3, x.balance)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderAddress = (x) => {
    return (
      <div>
        <div>
          Address: {x.address1}, {x.address2}
        </div>
        <div>
          {x.city}, {x.state}, {x.zip}
        </div>
      </div>
    );
  };

  render() {
    const startbalance = safe(this.props, 'data.statement.previous_balance', 0);
    const ptpayment = safe(this.props, 'data.statement.total_payments', 0);
    const ptcharges = safe(this.props, 'data.statement.total_charges', 0);
    const outstanding = safe(this.props, 'data.statement.total_outstanding', 0);

    return (
      <div style={{ padding: '10px' }}>
        <div>
          <div align="center">
            <img src="/images/pcpalogo-med.png" alt="logo" width="75" />
          </div>
          <hr />
          <div align="center">
            <div style={{ fontSize: 'larger' }}>{safe(this.props, 'data.statement.org.name')}</div>
            <div>phone: 415-296-5290 * fax: 415-296-5299</div>
            <div>reception@pcpasf.com</div>
            <div>pcpasf.com</div>
            <div>EIN: {safe(this.props, 'data.statement.org.ein')}</div>
            <div>NPI: {safe(this.props, 'data.statement.org.npi')}</div>
          </div>
        </div>
        <br />
        <h2>{safe(this.props, 'data.statement.title')}</h2>
        <div>Statement #: {numeral(this.props.data.id).format('000000')}</div>
        <div>Statement Date: {safe(this.props, 'data.statement.statement_date')}</div>
        <div>Period Start: {safe(this.props, 'data.statement.statement_daterange.start')}</div>
        <div>Period End: {safe(this.props, 'data.statement.statement_daterange.end')}</div>

        <br />

        <div>Patient: {safe(this.props, 'data.statement.patient.name')}</div>
        <div>DOB: {moment(safe(this.props, 'data.statement.patient.dob')).format('MM/DD/YYYY')}</div>
        {this.renderAddress(this.props.data.statement.patient)}

        <br />
        {this.renderServices(safe(this.props, 'data.statement.items', []))}

        <div style={{ float: 'right', paddingTop: '20px' }}>
          <table style={{ textAlign: 'right', fontWeight: 'bold' }}>
            <tbody>
              <tr>
                <td>Starting Balance:</td>
                <td>{numeral(Math.abs(startbalance)).format('$0,0.00')}</td>
                <td>{startbalance < 0 ? 'Credit' : ''}</td>
              </tr>
              <tr>
                <td>Total Patient Charges:</td>
                <td>{numeral(Math.abs(ptcharges)).format('$0,0.00')}</td>
                <td>{ptcharges < 0 ? 'Credit' : ''}</td>
              </tr>
              <tr>
                <td>Total Patient Payments:</td>
                <td>{numeral(Math.abs(ptpayment)).format('$0,0.00')}</td>
                <td>{ptpayment < 0 ? 'Credit' : ''}</td>
              </tr>
              <tr>
                <td>Total Outstanding Balance:</td>
                <td>{numeral(Math.abs(outstanding)).format('$0,0.00')}</td>
                <td>{outstanding < 0 ? 'Credit' : ''}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default BillingStatement;
