import React from 'react';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, List, Button, Tag } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/emr';
import { processEventNotification } from '../../../../helpers/util';
import CreateTimeoffModal from './Timeoff_/CreateTimeoffModal';
import Time from '../../../shared/Time';

class Timeoff extends React.Component {
  state = {
    createTimeoffModalVisible: false,
  }

  handleCancel = () => {
    this.props.closeDialog();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.getTimeoffList();
    }
    processEventNotification(prevProps,this.props,'deleteEvent','Time Off Deleted');
  }

  showCreateTimeoffModal = () => {
    this.setState({ createTimeoffModalVisible: true });
  }

  closeCreateTimeoffModal = () => {
    this.setState({ createTimeoffModalVisible: false });
  }

  getItemTitle = (item) => {
    let tagColor='';
    let tagName = '';
    if (item.status === 1) {
      tagColor='blue';
      tagName='Request';
    } else if (item.status === 2) {
      tagColor='green';
      tagName='Approved';
    } else if (item.status === 3) {
      tagColor='red';
      tagName='Rejected';
    } else if (item.status === 4) {
      tagColor='black';
      tagName='Cancelled';
    }

    let type = null;
    if (item.type === 4) {
      type = <Tag color='purple'>PTO</Tag>
    } else if (item.type === 5) {
      type = <Tag color='purple'>UTO</Tag>
    } else {
      type = <Tag color='black'>BLK</Tag>
    }

    let times = null;
    if (item.duration === 1) {
      times = '('+(new Time(item.start_time)).toString(false) + '-' + (new Time(item.end_time)).toString(false)+')';
    }

    return (
      <span>
        <Tag color={tagColor}>{tagName}</Tag> {type}
          {item.duration === 2 && moment(item.start_date).format('ddd M/D/YYYY')}
          {item.duration === 2 && <span>&nbsp; - &nbsp;</span>}
          {moment(item.end_date).format('ddd M/D/YYYY')}
          &nbsp;{times}
      </span>
    );
  }

  getItemDescription = (item) => {
    const cp = safe(item,'covering_provider.full_name','No Provider');
    return (
      <div>
        { cp && <div>Covering Provider: {cp}</div> }
        { item.request_comment && <div>Request Comment: {item.request_comment}</div> }
        { item.response_comment && <div>Response Comment: {item.response_comment}</div> }
      </div>
    );
  }

  deleteItem = (id) => {
    this.props.deleteTimeoff(id);
  }

  getItemActions = (item) => {
    if (item.status === 1) {
      return [
        <Button type='link' icon={<DeleteOutlined />} onClick={()=>this.deleteItem(item.id)} />
      ];
    } else {
      return [];
    }
  }

  torHelp = () => {
    const url=`https://docs.google.com/document/d/1vjsms6EE5qGjfh5o_tXHUzWtvDuWB6w0ipvdJQpr6cc/edit?usp=sharing`;
    window.open(url,'_blank');
  }

  render() {
    return (
      <div>
        <Modal
          title='Timeoff Request'
          visible={this.props.visible}
          onCancel={this.handleCancel}
          width={700}
          footer={null}
        >
          <div>
            <div style={{textAlign:'right',paddingBottom:'20px'}}>
              <Button type='link' onClick={this.torHelp}>
                <QuestionCircleOutlined /> Instructions for Time Off Requests
              </Button>
            </div>

            <Button
              type='primary'
              style={{width:'100%'}}
              onClick={this.showCreateTimeoffModal}
            >
              New Time Off Request
            </Button>
            <hr />
            <div style={{height:'400px',overflow:'auto'}}>
              <List
                itemLayout="horizontal"
                dataSource={this.props.list}
                renderItem={item => (
                  <List.Item actions={this.getItemActions(item)}>
                    <List.Item.Meta
                      title={this.getItemTitle(item)}
                      description={this.getItemDescription(item)}
                    />
                  </List.Item>
                )}
              />
            </div>
          </div>
        </Modal>

        <CreateTimeoffModal
          visible={this.state.createTimeoffModalVisible}
          onClose={this.closeCreateTimeoffModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    list: safe(state,'emr.timeoff.list.data',[]),
    deleteEvent: safe(state,'emr.timeoff.delete',{})
  }
}

export default connect(mapStateToProps,actions)(Timeoff);
