import { combineReducers } from 'redux';
import { ajaxReducer } from '../../helpers/util';

export default combineReducers({
  reg_list: ajaxReducer('REGISTRATION_LIST'),
  reg_verify: ajaxReducer('REGISTRATION_VERIFY'),
  linked_list: ajaxReducer('LINKED_LIST'),
  linked_add: ajaxReducer('LINKED_ADD'),
  linked_delete: ajaxReducer('LINKED_DELETE'),
  portal_search: ajaxReducer('PACCOUNT_SEARCH'),
  portal_load: ajaxReducer('PACCOUNT_LOAD'),
  portal_update: ajaxReducer('PACCOUNT_UPDATE'),
  portal_links: ajaxReducer('PACCOUNT_LINKEDLIST'),
  portal_addlink: ajaxReducer('PACCOUNT_ADDLINK'),
  portal_deletelink: ajaxReducer('PACCOUNT_DELETELINK'),
  portal_delete: ajaxReducer('PACCOUNT_DELETE'),
  bulkcreate: ajaxReducer('PATIENT_BULKCREATE')
});
