import React, { Component } from 'react';
import { Table } from 'antd';
import moment from 'moment';

import { safe } from '../../../../../helpers/reduxSelectors';

class NotesTable extends Component {
  render() {
    const columns=[{
      title: 'Date',
      dataIndex: 'createdAt',
      width: 175,
      render: (text,record) => {
        return moment(text).format('MM/DD/YYYY, HH:mm:ss');
      }
    },{
      title: 'User',
      dataIndex: 'user',
      width: 50,
      render: (text,record) => {
        return safe(record,'user.initials');
      }
    },{
      title: 'Note',
      dataIndex: 'description'
    }];

    return (
      <div>
        <Table
          dataSource={this.props.data}
          columns={columns}
          bordered={false}
          showHeader={true}
          pagination={false}
          size='small'
          rowKey='id'
          className='sl_history_table'
        />
      </div>
    )
  }
}

export default NotesTable;
