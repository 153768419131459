import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { VideoCameraOutlined } from '@ant-design/icons';

import { safe } from '../../../../../helpers/reduxSelectors';
import Time from '../../../../shared/Time'

class ApptInfo extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired
  }

  getProviderName = () => (this.props.data.user) ? safe(this.props.data,'user.full_name') : null;
  getDate = () => (this.props.data.session_date) ? moment(this.props.data.session_date).format('ddd, MM/DD/YYYY') : null;
  getTime = () => (this.props.data.start_time) ? (new Time(this.props.data.start_time)).toString(false) : null;
  getSessionType = () => (this.props.data.session_type === 1) ? 'Eval' : 'Followup';
  getTelemedicine = () => (this.props.data.telemedicine || this.props.data.location_id === 4) ? <VideoCameraOutlined /> : null;
  getDuration = () => (this.props.data.duration) ? safe(this.props.data,'duration',0) + ' mins': null;
  getLocation = () => (this.props.data.location_id && this.props.data.location_id !== 4 && !this.props.data.telemedicine) ? safe(this.props.data,'location.name') : null;

  render() {
    return (
      <div style={{paddingBottom:'5px'}}>
        <div>
          <a onClick={() => this.props.onSelect(this.props.data)}>
            {this.getDate()} {this.getTelemedicine()} {this.getTime()} {this.getProviderName()}
          </a>
        </div>
        <div style={{marginLeft:'10px',fontSize:'smaller',color:'#AAAAAA'}}>
          {this.getSessionType()} ({this.getDuration()}) {this.getLocation()}
        </div>
      </div>
    )
  }
}

export default ApptInfo;
