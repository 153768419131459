import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Spin, Select, Input, InputNumber, Checkbox, DatePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import * as billing_actions from '../../../../../../actions/billing';
import * as shared_actions from '../../../../../../actions/shared';
import { safe } from '../../../../../../helpers/reduxSelectors';
import { processEventNotification } from '../../../../../../helpers/util';
//     processEventNotification(prevProps,this.props,'updateEvent','Authorization Updated');

class AuthorizationEdit extends React.Component {

  componentDidMount() {
    this.props.getCarrierList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible === false) {
      this.props.form.resetFields();
    }
    processEventNotification(prevProps,this.props,'updateEvent','Authorization Updated');
    processEventNotification(prevProps,this.props,'createEvent','Authorization Created');
  }

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.data.id) {
          this.props.updateAuthorization(this.props.patientId,this.props.data.id,values);
        } else {
          this.props.createAuthorization(this.props.patientId,values);
        }
        this.props.closeDialog();
      }
    });
  }

  handleCancel = () => {
    this.props.closeDialog();
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
      },
    };

    const data = (this.props.data) ? this.props.data : {};

    return (
      <Form className='payment-form' autoComplete='false'>
        <FormItem {...formItemLayout} label="Carrier">
          {getFieldDecorator('carrier_id', {
            rules: [{ required: true, message: " " }],
            initialValue: data.carrier_id
          })(
            <Select>
              {this.props.carrierList.filter(x=>!x.selfpay).map(x=><Option key={x.id} value={x.id}>{x.name}</Option>)}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Authorization #">
          {getFieldDecorator('auth_num', {
            rules: [{ required: true, message: " " }],
            initialValue: data.auth_num
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Starting Date">
          {getFieldDecorator('start_date', {
            rules: [{ required: true, message: " " }],
            initialValue: (data.start_date) ? moment(data.start_date) : ''
          })(
            <DatePicker format='M/D/YYYY'/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Ending Date">
          {getFieldDecorator('end_date', {
            rules: [{ required: true, message: " " }],
            initialValue: (data.end_date) ? moment(data.end_date) : ''
          })(
            <DatePicker format='M/D/YYYY'/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="# of Uses">
          {getFieldDecorator('uses', {
            initialValue: safe(data,'uses',20)
          })(
            <InputNumber />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Type">
          {getFieldDecorator('type', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(data,'type',1)
          })(
            <Select>
              <Select.Option value={1} key={1}>Therapy</Select.Option>
              <Select.Option value={2} key={2}>Psychiatry</Select.Option>
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Modifier">
          {getFieldDecorator('modifier', {
            initialValue: safe(data,'modifier')
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Active">
          {getFieldDecorator('active', {
            initialValue: safe(data,'active',true),
            valuePropName: 'checked'
          })(
            <Checkbox />
          )}
        </FormItem>
      </Form>
    );
  }

  render() {
    return (
      <Modal
        title="Authorization"
        visible={this.props.visible}
        destroyOnClose={true}
        onOk={this.handleOk}
        okText='Save'
        onCancel={this.handleCancel}
        width={600}
        bodyStyle={{paddingLeft:'0px',paddingRight:'0px',height:'350px',overflowY:'scroll'}}
      >
        <Spin spinning={this.props.isBusy}>
          { this.renderForm() }
        </Spin>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    updateEvent: safe(state,'billing.authorizations.update',{}),
    createEvent: safe(state,'billing.authorizations.create',{}),
    carrierList : safe(state,'shared.carrier.list.data',[]),
    isBusy: (
      safe(state,'billing.authorizations.update.processing') ||
      safe(state,'billing.authorizations.create.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(Form.create()(AuthorizationEdit));
