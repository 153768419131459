/**
 * FrequencyToggle.tsx
 */
import { useContextSelector } from 'use-context-selector';
import QueueContext from 'components/App_/ReportsV2_/QueueContext';
import { Form, Radio } from 'antd';
import formItemRules from 'components/App_/ReportsV2_/formItemRules';

const FrequencyToggle: React.FC = () => {
  const form = useContextSelector(QueueContext, (c) => c.state.form);
  const setState = useContextSelector(QueueContext, (c) => c.setState);

  const onRecurringChange = (e) => {
    const isRecurring = e.target.value;
    const subType = undefined;
    const type = undefined;
    const dayOfMonth = undefined;
    const showNthDayOfMonth = undefined;
    setState((s) => ({ ...s, isRecurring, showNthDayOfMonth, dayOfMonth, subType, type }));
    form?.setFieldsValue({ isRecurring, showNthDayOfMonth, dayOfMonth, subType, type });
  };

  const FrequencySelect = () => (
    <Form.Item style={{ display: 'inline-block' }} className="mb0" name="isRecurring" rules={formItemRules.isRecurring}>
      <Radio.Group onChange={onRecurringChange} buttonStyle="solid" size="small">
        <Radio.Button value={false}>One-Time</Radio.Button>
        <Radio.Button value={true}>Recurring</Radio.Button>
      </Radio.Group>
    </Form.Item>
  );

  return (
    <>
      <h5>Frequency</h5>
      <FrequencySelect />
    </>
  );
};

export default FrequencyToggle;
