import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Modal, Spin } from 'antd';
import { withRouter } from 'react-router-dom';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/shared';
import { processEventNotification } from '../../../helpers/util';
import OppositeEnds from '../../shared/OppositeEnds';

class LocationGroups extends Component {

  componentDidMount() {
    this.props.getLocationsList();
    this.props.getLocationGroups();
  }

  componentDidUpdate(prevProps) {
    processEventNotification(prevProps,this.props,'remove','Location Group Removed');
  }

  removeGroup = (record) => {
    const This = this;

    Modal.confirm({
      title: 'Delete Location Group: ' + record.name + '?',
      onOk() {
        This.props.locationGroupRemove(record.id)
      },
      onCancel() { }
    });
  }

  newServiceButton = () => {
    return (
      <Button
        type='primary'
        onClick={() => this.props.history.push('/settings/glocations/0')}
        disabled={this.props.isBusy}
      >
        Add New Location Group
      </Button>
    );
  }

  renderLocation = (x) => {
    const result = [];
    if (x.address1) {
      result.push(x.address1);
    }
    if (x.address2) {
      result.push(x.address2);
    }
    if (x.city) {
      result.push(x.city);
    }
    return <span>{result.join(', ')}</span>;
  }


  listRender = (x) => {
    let refs = safe(x,'refs',[]);
    if (!refs) {
      refs = [];
    }
    const included = [];
    this.props.locationsList.forEach(x=>{
      if (refs.includes(x.id)) {
        included.push(x);
      }
    });

    return (
      <div>
        { (included.length > 0) ? included.map(q => (<div>{ this.renderLocation(q) }</div>)) : 'None'}
      </div>
    )
  }

  render() {
    const columns = [{
      title: 'Group Name',
      dataIndex: 'name',
      render: (value,record) => {
        return <Link to={'/settings/glocations/'+record.id}>{value}</Link>
      }
    },{
      title: 'Abbreviation',
      dataIndex: 'abbr'
    },{
      title: 'Service Locations',
      dataIndex: 'locations',
      render: (value,record) => this.listRender(record)
    },{
      title: 'Action',
      render: (value,record) => {
        return <a onClick={()=>this.removeGroup(record)}><DeleteOutlined /></a>;
      }
    }];

    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <OppositeEnds left={<h3>Location Groups</h3>} right={this.newServiceButton()} />
          <br />
          <Table
            dataSource={this.props.groupList}
            columns={columns}
            rowKey="id"
            size='small'
            pagination={false}
          />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'shared.location_group.list.processing',false) ||
      safe(state,'shared.location_group.remove.processing',false)
    ) ? true : false,
    groupList: safe(state,'shared.location_group.list.data',[]),
    locationsList: safe(state,'shared.location.list.data',[]),
    remove: safe(state,'shared.location_group.remove',{}),
  }
}

export default connect(mapStateToProps,actions)(withRouter(LocationGroups));
