// eslint-disable-next-line react/display-name
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AutoComplete } from 'antd';

interface PropsI {
  data: Array<Record<string, string>>;
  setSelected: Dispatch<SetStateAction<undefined | Record<string, string>>>;
  selected: undefined | Record<string, string>;
  filterProperty: string;
  labelProperty: string;
  valueProperty: string;
  placeholder: string;
};

const AutoCompleteFilter: React.FC<PropsI> = (props: PropsI) => {
  const { selected, filterProperty, labelProperty, valueProperty, data, placeholder, setSelected } = props;
  const [queryString, setQueryString] = useState('');

  // clear the query string when selected is cleared
  useEffect(() => {
    if (!selected) {
      setQueryString('');
    }
  }, [selected]);

  const getSource = () => {
    if (data) {
      return data.filter((item) => item[filterProperty].toLowerCase().includes(queryString)).map((item) => {
        const label = item[labelProperty];
        const value = item[valueProperty];
        return { value, label };
      });
    }
    return [];
  };

  const onChange = (value) => {
    const foundCarrier = getSource().find((p) => p.value === value);
    setSelected(foundCarrier);
  };

  const onSearch = (value) => setQueryString(value);

  const onSelect = onChange;
  const style = { width: '100%' };
  const defaultActiveFirstOption = false;
  const value = selected?.label || queryString;

  return (
    <AutoComplete
      allowClear
      style={style}
      placeholder={placeholder}
      value={value}
      options={getSource()}
      onSearch={onSearch}
      onChange={onChange}
      onSelect={onSelect}
      defaultActiveFirstOption={defaultActiveFirstOption}
    />
  );
};

export default AutoCompleteFilter;
