import React from 'react';

class BriefView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.brief !== this.props.brief) {
      this.setState({
        expand:false
      })
    }
  }

  expandToggle = () => { this.setState({expand:!this.state.expand}) }

  showMoreOrLess = () => {
    if (!this.props.brief) {
      return null;
    }
    if (this.state.expand) {
      return (<div style={{paddingTop:'10px',paddingBottom:'30px',paddingLeft:'150px'}}><a onClick={this.expandToggle}>Show less...</a></div>);
    } else {
      return (<div style={{paddingTop:'10px',paddingBottom:'30px',paddingLeft:'150px'}}><a onClick={this.expandToggle}>Show more...</a></div>);
    }
  }

  render() {
    const style = (this.props.brief && !this.state.expand) ? {
      overflow:'hidden',
      height:'100px'
    } : {};

    if (this.props.print) {
      return (
        <div>
          <div>
            {this.props.children}
          </div>
        </div>
      );
    } else {
      if (false) {
        // This option temporarily disabled. Allows clicking anywhere in the note
        // to close it.
        return (
          <div>
            <div style={style} onClick={this.expandToggle}>
              {this.props.children}
            </div>
            {this.showMoreOrLess()}
          </div>
        );
      } else {
        return (
          <div>
            <div style={style}>
              {this.props.children}
            </div>
            {this.showMoreOrLess()}
          </div>
        );
      }
    }
  }
}

export default BriefView;
