import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Tabs } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import * as actions from '../../../../../actions/emr';
import { safe, isAdmin, isClinicalSupervisor } from '../../../../../helpers/reduxSelectors';
import Admin from './AutoSessionCreate_/Admin';
import BlockedOut from './AutoSessionCreate_/BlockedOut';
import Session from './AutoSessionCreate_/Session';

class AutoSessionCreate extends React.Component {

  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    openItem: PropTypes.object,
    provider_id: PropTypes.number,
    provider_name: PropTypes.string,
    provider_roles: PropTypes.array
  }

  state = {
    activeTab: 'session',
    createButtonPressed: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.setState({
        activeTab: 'session',
        createButtonPressed: false
      });
    }
  }

  closeAndResetDialog = () => {
    this.props.onClose();
    this.setState({
      activeTab: 'none',
      createButtonPressed: false
    });
  }

  handleCreate = () => {
    this.setState({
      createButtonPressed: true
    });
  }

  changeTab = (key) => {
    this.setState({
      activeTab: key
    });
  }

  render() {
    const TabPane = Tabs.TabPane;
    return (
      <Modal
        title="Create Session"
        visible={this.props.visible}
        onOk={this.handleCreate}
        okText='Create'
        onCancel={this.closeAndResetDialog}
      >

        <div className='schedule-session'>

          <Tabs activeKey={this.state.activeTab} onChange={this.changeTab}>

            <TabPane tab='Session' key='session'>
              <Session
                visible={this.state.activeTab === 'session'}
                createButtonPressed={this.state.createButtonPressed && this.state.activeTab === 'session'}
                onClose={this.closeAndResetDialog}
                openItem={this.props.openItem}
                provider_id={this.props.provider_id}
                provider_name={this.props.provider_name}
                provider_roles={this.props.provider_roles}
              />
            </TabPane>

            {this.props.isAdmin && <TabPane tab='Admin Time' key='admin'>
              <Admin
                visible={this.state.activeTab === 'admin'}
                createButtonPressed={this.state.createButtonPressed && this.state.activeTab === 'admin'}
                onClose={this.closeAndResetDialog}
                openItem={this.props.openItem}
                provider_id={this.props.provider_id}
                provider_name={this.props.provider_name}
                provider_roles={this.props.provider_roles}
              />
            </TabPane> }

            <TabPane tab='Blocked-Out Time' key='blocked'>
              <BlockedOut
                visible={this.state.activeTab === 'blocked'}
                createButtonPressed={this.state.createButtonPressed && this.state.activeTab === 'blocked'}
                onClose={this.closeAndResetDialog}
                openItem={this.props.openItem}
                provider_id={this.props.provider_id}
                provider_name={this.props.provider_name}
                provider_roles={this.props.provider_roles}
              />
            </TabPane>

          </Tabs>

        </div>

      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    locationsList: safe(state,'emr.provider.locations.data',[]),
    calendar_date: safe(state,'emr.emr.active_date',moment().format('YYYY-MM-DD')),
    isAdmin: isAdmin(state) || isClinicalSupervisor(state),
    isBusy: (
      safe(state,'emr.session.create.processing',false)
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(AutoSessionCreate);
