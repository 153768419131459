import axios from 'axios';
import * as types from './types';
//import qs from 'qs';

const emrLogout = (dispatch,err) => {
  if (err && err.response && err.response.status === 401) {
    dispatch ({type:'USER_LOGIN_CLEAR'});
  }
}

export const directorList = () => async dispatch => {
  dispatch ({type:types.DIRECTOR_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/director');
    dispatch ({type:types.DIRECTOR_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.DIRECTOR_LIST_FAILURE, error: err.response});
  }
}

export const directorAdd = (supervisee_id) => async dispatch => {
  dispatch ({type:types.DIRECTOR_ADD_REQUEST});
  try {
    const resp = await axios.post('/api/v1/director/'+supervisee_id);
    dispatch ({type:types.DIRECTOR_ADD_SUCCESS, payload: resp.data });
    dispatch ({type:types.DIRECTOR_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.DIRECTOR_ADD_FAILURE, error: err.response});
  }
}

export const directorRemove = (supervisee_id) => async dispatch => {
  dispatch ({type:types.DIRECTOR_REMOVE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/director/'+supervisee_id);
    dispatch ({type:types.DIRECTOR_REMOVE_SUCCESS, payload: resp.data });
    dispatch ({type:types.DIRECTOR_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.DIRECTOR_REMOVE_FAILURE, error: err.response});
  }
}

export const toggleRegistrationComplete = (patient_id) => async dispatch => {
  dispatch ({type:types.REGISTRATION_TOGGLE_REQUEST});
  try {
    const resp = await axios.get('/api/v1/registration/toggle/'+patient_id);
    dispatch ({type:types.REGISTRATION_TOGGLE_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.REGISTRATION_TOGGLE_FAILURE, error: err.response});
  }
}

export const toggleRegistrationClear = () => dispatch => {
  dispatch ({type:types.REGISTRATION_TOGGLE_CLEAR});
}

export const getPatientNotificationHistory = (patient_id) => async dispatch => {
  dispatch ({type:types.NOTIFICATIONS_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/notifications/history/'+patient_id);
    dispatch ({type:types.NOTIFICATIONS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTIFICATIONS_LIST_FAILURE, error: err.response});
  }
}

export const loadSession = (session_id) => async dispatch => {
  dispatch ({type:types.SESSION_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/session/'+session_id);
    dispatch ({type:types.SESSION_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.SESSION_LOAD_FAILURE, error: err.response});
  }
}

export const getProviderSearchParams = (patient_id,data={}) => async dispatch => {
  dispatch ({type:types.CALENDAR_SEARCHPARAMS_REQUEST});
  try {
    const resp = await axios.post('/api/v1/calendarsearchparams/'+patient_id,data);
    dispatch ({type:types.CALENDAR_SEARCHPARAMS_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.CALENDAR_SEARCHPARAMS_FAILURE, error: err.response});
  }
}

export const getSessionHistoryAndFuture = (patient_id) => async dispatch => {
  dispatch ({type:types.SESSION_HISTORY_REQUEST});
  try {
    const resp = await axios.get('/api/v1/patient/'+patient_id+'/sessions');
    dispatch ({type:types.SESSION_HISTORY_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.SESSION_HISTORY_FAILURE, error: err.response});
  }
}

export const getHolidays = () => async dispatch => {
  dispatch ({type:types.CALENDAR_HOLIDAYS_REQUEST});
  try {
    const resp = await axios.get('/api/v1/holidays');
    dispatch ({type:types.CALENDAR_HOLIDAYS_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.CALENDAR_HOLIDAYS_FAILURE, error: err.response});
  }
}

export const getTimeoffList = () => async dispatch => {
  dispatch ({type:types.TIMEOFF_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/timeoff');
    dispatch ({type:types.TIMEOFF_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.TIMEOFF_LIST_FAILURE, error: err.response});
  }
}

export const createTimeoff = (data) => async dispatch => {
  dispatch ({type:types.TIMEOFF_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/timeoff',data);
    dispatch ({type:types.TIMEOFF_CREATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.TIMEOFF_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.TIMEOFF_CREATE_FAILURE, error: err.response});
  }
}

export const updateTimeoff = (timeoff_id,data) => async dispatch => {
  dispatch ({type:types.TIMEOFF_UPDATE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/timeoff/'+timeoff_id,data);
    dispatch ({type:types.TIMEOFF_UPDATE_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.TIMEOFF_UPDATE_FAILURE, error: err.response});
  }
}

export const deleteTimeoff = (timeoff_id) => async dispatch => {
  dispatch ({type:types.TIMEOFF_DELETE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/timeoff/'+timeoff_id);
    dispatch ({type:types.TIMEOFF_DELETE_SUCCESS, payload: resp.data });
    dispatch ({type:types.TIMEOFF_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.TIMEOFF_DELETE_FAILURE, error: err.response});
  }
}

export const getProviderProductivity = (provider_id,term_id,grouping=1) => async dispatch => {
  dispatch ({type:types.PROVIDER_PRODUCTIVITY_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/productivity/'+term_id+'/'+grouping);
    dispatch ({type:types.PROVIDER_PRODUCTIVITY_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.PROVIDER_PRODUCTIVITY_FAILURE, error: err.response});
  }
}

export const createProgressNoteFromSession = (provider_id,session_id,add_to_careteam=0) => async dispatch => {
  dispatch ({type:types.SESSION_CREATENOTE_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+
      '/session/'+session_id+'/createnote/'+add_to_careteam);
    dispatch ({type:types.SESSION_CREATENOTE_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.SESSION_CREATENOTE_FAILURE, error: err.response});
  }
}

export const searchAppointments = (data) => async dispatch => {
  dispatch ({type:types.CALENDAR_SEARCH_REQUEST});
  try {
    const resp = await axios.post('/api/v1/scheduler/search',data);
    dispatch ({type:types.CALENDAR_SEARCH_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.CALENDAR_SEARCH_FAILURE, error: err.response});
  }
}

export const getProviderHistory = (provider_id,query) => async dispatch => {
  dispatch ({type:types.PROVIDER_HISTORY_REQUEST});
  try {
    const resp = await axios.post('/api/v1/scheduler/provider/'+provider_id+'/sessionhistory',{
      query:query
    });
    dispatch ({type:types.PROVIDER_HISTORY_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.PROVIDER_HISTORY_FAILURE, error: err.response});
  }
}

export const getProviderCalendar = (provider_id,cal_type,cal_date) => async dispatch => {
  dispatch ({type:types.CALENDAR_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/calendar/'+cal_type+'/'+cal_date);
    dispatch ({type:types.CALENDAR_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.CALENDAR_LIST_FAILURE, error: err.response});
  }
}

export const getProviderOpenTimes = (provider_id,cal_date) => async dispatch => {
  dispatch ({type:types.PROVIDER_OPENTIMES_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/opentimes/'+cal_date);
    dispatch ({type:types.PROVIDER_OPENTIMES_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.PROVIDER_OPENTIMES_FAILURE, error: err.response});
  }
}

export const createSession = (provider_id,cal_type,cal_date,data) => async dispatch => {
  dispatch ({type:types.SESSION_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/scheduler/provider/'+provider_id+'/calendar/'+cal_type+'/'+cal_date,data);
    dispatch ({type:types.SESSION_CREATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.CALENDAR_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.SESSION_CREATE_FAILURE, error: err.response});
  }
}

export const reserveSession = (provider_id,cal_type,cal_date,data) => async dispatch => {
  dispatch ({type:types.SESSION_RESERVE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/scheduler/provider/'+provider_id+'/calendar/'+cal_type+'/'+cal_date,data);
    dispatch ({type:types.SESSION_RESERVE_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.SESSION_RESERVE_FAILURE, error: err.response});
  }
}

export const updateSession = (provider_id,cal_type,cal_date,session_id,seq_type,data) => async dispatch => {
  dispatch ({type:types.SESSION_UPDATE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/scheduler/provider/'+provider_id+
      '/calendar/'+cal_type+'/'+cal_date+'/'+session_id+'/'+seq_type,data);
    dispatch ({type:types.SESSION_UPDATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.CALENDAR_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.SESSION_UPDATE_FAILURE, error: err.response});
  }
}

export const removeSession = (provider_id,cal_type,cal_date,session_id,seq_type) => async dispatch => {
  dispatch ({type:types.SESSION_REMOVE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/scheduler/provider/'+provider_id+'/calendar/'+cal_type+'/'+cal_date+'/'+session_id+'/'+seq_type);
    dispatch ({type:types.SESSION_REMOVE_SUCCESS, payload: resp.data });
    dispatch ({type:types.CALENDAR_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.SESSION_REMOVE_FAILURE, error: err.response});
  }
}

export const lateCancelSession = (provider_id,cal_type,cal_date,session_id,seq_type) => async dispatch => {
  dispatch ({type:types.SESSION_LATECANCEL_REQUEST});
  try {
    const resp = await axios.patch('/api/v1/scheduler/provider/'+provider_id+'/calendar/'+cal_type+'/'+cal_date+'/'+session_id+'/'+seq_type);
    dispatch ({type:types.SESSION_LATECANCEL_SUCCESS, payload: resp.data });
    dispatch ({type:types.CALENDAR_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.SESSION_LATECANCEL_FAILURE, error: err.response});
  }
}

export const reserveAppt = (provider_id,data) => async dispatch => {
  dispatch ({type:types.APPT_RESERVE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/scheduler/provider/'+provider_id+'/appointments',data);
    dispatch ({type:types.APPT_RESERVE_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.APPT_RESERVE_FAILURE, error: err.response});
  }
}

export const updateAppt = (provider_id,session_id,seq_type,data) => async dispatch => {
  dispatch ({type:types.APPT_UPDATE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/scheduler/provider/'+provider_id+
      '/appointments/'+session_id+'/'+seq_type,data);
    dispatch ({type:types.APPT_UPDATE_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.APPT_UPDATE_FAILURE, error: err.response});
  }
}

export const removeAppt = (provider_id,session_id,seq_type) => async dispatch => {
  dispatch ({type:types.APPT_REMOVE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/scheduler/provider/'+provider_id+'/appointments/'+session_id+'/'+seq_type);
    dispatch ({type:types.APPT_REMOVE_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.APPT_REMOVE_FAILURE, error: err.response});
  }
}

export const lateCancelAppt = (provider_id,session_id) => async dispatch => {
  dispatch ({type:types.APPT_LATECANCEL_REQUEST});
  try {
    const resp = await axios.patch('/api/v1/scheduler/provider/'+provider_id+'/appointments/'+session_id);;
    dispatch ({type:types.APPT_LATECANCEL_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.APPT_LATECANCEL_FAILURE, error: err.response});
  }
}

export const getProviderLocations = (provider_id) => async dispatch => {
  dispatch ({type:types.PROVIDER_LOCATIONS_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/locations');
    dispatch ({type:types.PROVIDER_LOCATIONS_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.PROVIDER_LOCATIONS_FAILURE, error: err.response});
  }
}

export const unreadThread = (threadStatusId) => async dispatch => {
  dispatch ({type:types.MESSAGESTATUS_UNREAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/messagestatus/unread/'+threadStatusId);
    dispatch ({type:types.MESSAGESTATUS_UNREAD_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.MESSAGESTATUS_UNREAD_FAILURE, error: err.response});
  }
}

export const getPrimaryDiagnosis = (patient_id) => async dispatch => {
  dispatch ({type:types.DIAGNOSIS_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/diagnosis/'+patient_id);
    dispatch ({type:types.DIAGNOSIS_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.DIAGNOSIS_LOAD_FAILURE, error: err.response});
  }
}

export const getPatientMessageThreads = (patient_id) => async dispatch => {
  dispatch ({type:types.MESSAGETHREADS_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/messagethreads/'+patient_id);
    dispatch ({type:types.MESSAGETHREADS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.MESSAGETHREADS_LIST_FAILURE, error: err.response});
  }
}

export const getMessagesForThread = (thread_id) => async dispatch => {
  if (thread_id) {
    dispatch ({type:types.MESSAGETHREADS_MESSAGES_REQUEST});
    try {
      const resp = await axios.get('/api/v1/messagethreads/messages/'+thread_id);
      dispatch ({type:types.MESSAGETHREADS_MESSAGES_SUCCESS, payload: resp.data });
    } catch (err) {
      emrLogout(dispatch,err);
      dispatch ({type:types.MESSAGETHREADS_MESSAGES_FAILURE, error: err.response});
    }
  } else {
    dispatch ({type:types.MESSAGETHREADS_MESSAGES_CLEAR});
  }
}

export const getProvidersList = () => async dispatch => {
  dispatch ({type:types.PROVIDERS_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/providers');
    dispatch ({type:types.PROVIDERS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.PROVIDERS_LIST_FAILURE, error: err.response});
  }
}

export const deleteLateCancel = (patientId,values) => async dispatch => {
  dispatch ({type:types.NOTE_LATECANCEL_DELETE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/latecancel/delete/'+patientId,values);
    dispatch ({type:types.NOTE_LATECANCEL_DELETE_SUCCESS, payload: {} });
    dispatch ({type:types.NOTE_LIST_SUCCESS, payload: resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_LATECANCEL_DELETE_FAILURE, error: err.response});
  }
}

export const setInboxStar = (value) => dispatch => {
  dispatch ({type:types.INBOX_FILTER_STAR, payload: value });
}

export const setAdminLabel = (value) => dispatch => {
  dispatch ({type:types.INBOX_SET_LABEL, payload: value});
}

export const useNewThread = (patient_id,author,members) => async dispatch => {
  dispatch ({type:types.THREAD_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/messages/members/'+ author.type +
      '/'+(author.user_id || author.group_id) + '/' + patient_id,members);
    dispatch ({type:types.THREAD_CREATE_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.THREAD_CREATE_FAILURE, error: err.response});
  }
}

export const useNewThreadClear = () => dispatch => {
  dispatch ({type:types.THREAD_CREATE_CLEAR});
}

export const starThread = (id,starred,message) => async dispatch => {
  dispatch ({type:types.THREAD_STAR_REQUEST});
  try {
    const resp = await axios.post('/api/v1/messages/star/'+id,{
      starred: starred,
      message: message
    });
    dispatch ({type:types.THREAD_STAR_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.THREAD_STAR_FAILURE, error: err.response});
  }
}

export const getPtContacts = (patient_id) => async dispatch => {
  dispatch ({type:types.PTCONTACTS_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/messages/members/'+patient_id);
    dispatch ({type:types.PTCONTACTS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.PTCONTACTS_LIST_FAILURE, error: err.response});
  }
}

export const getCaseload = () => async dispatch => {
  dispatch ({type:types.CASELOAD_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/caseload');
    dispatch ({type:types.CASELOAD_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.CASELOAD_LIST_FAILURE, error: err.response});
  }
}

export const getCaseloadForProvider = (provider_id) => async dispatch => {
  dispatch ({type:types.CASELOAD_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/caseload/'+provider_id);
    dispatch ({type:types.CASELOAD_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.CASELOAD_LIST_FAILURE, error: err.response});
  }
}

export const loadVacationCoverage = (user_id=null) => async dispatch => {
  dispatch ({type:types.COVERAGE_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/coverage'+((user_id) ? '/'+ user_id : ''));
    dispatch ({type:types.COVERAGE_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.COVERAGE_LOAD_FAILURE, error: err.response});
  }
}

export const saveVacationCoverage = (data,user_id=null) => async dispatch => {
  dispatch ({type:types.COVERAGE_SAVE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/coverage'+((user_id) ? '/'+user_id : ''),data);
    dispatch ({type:types.COVERAGE_SAVE_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.COVERAGE_SAVE_FAILURE, error: err.response});
  }
}

export const getCoverageProviders = (user_id=null) => async dispatch => {
  dispatch ({type:types.COVERAGE_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/coverageproviders'+((user_id) ? '/'+user_id: ''));
    dispatch ({type:types.COVERAGE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.COVERAGE_LIST_FAILURE, error: err.response});
  }
}

export const getAdminCoverageList = () => async dispatch => {
  dispatch ({type:types.COVERAGE_ADMINLIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/admincoverage');
    dispatch ({type:types.COVERAGE_ADMINLIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.COVERAGE_ADMINLIST_FAILURE, error: err.response});
  }
}

export const deleteProviderCoverage = (provider_id) => async dispatch => {
  dispatch ({type:types.COVERAGE_ADMINDELETE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/admincoverage/'+provider_id);
    dispatch ({type:types.COVERAGE_ADMINDELETE_SUCCESS, payload: {}});
    dispatch ({type:types.COVERAGE_ADMINLIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.COVERAGE_ADMINDELETE_FAILURE, error: err.response});
  }
}

export const deleteMyVacationCoverage = () => async dispatch => {
  dispatch ({type:types.COVERAGE_DELETE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/coverage');
    dispatch ({type:types.COVERAGE_DELETE_SUCCESS, payload: {}});
    dispatch ({type:types.COVERAGE_ADMINLIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.COVERAGE_DELETE_FAILURE, error: err.response});
  }
}

export const setCurrentInbox = (value) => dispatch => {
  dispatch ({type:types.INBOX_SET_CURRENT, payload: value});
}

export const getInbox = (userId) => async dispatch => {
  dispatch ({type:types.INBOX_LIST_REQUEST});
  try {
    const resp = await axios.get(`/api/v1/inbox?userId=${userId}`);
    dispatch ({type:types.INBOX_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.INBOX_LIST_FAILURE, error: err.response});
  }
}

export const getInboxMessages = (thread_id,member) => async dispatch => {
  dispatch ({type:types.INBOX_MESSAGES_REQUEST});
  try {
    const resp = await axios.get('/api/v1/inbox/'+member.type +
      '/'+(member.user_id || member.group_id) + '/' + thread_id);
    dispatch ({type:types.INBOX_MESSAGES_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.INBOX_MESSAGES_FAILURE, error: err.response});
  }
}

export const hideMessageStatus = (status_id) => async dispatch => {
  dispatch ({type:types.MESSAGESTATUS_HIDE_REQUEST});
  try {
    const resp = await axios.get('/api/v1/messagestatus/hide/'+status_id);
    dispatch ({type:types.MESSAGESTATUS_HIDE_SUCCESS, payload: {} });
    dispatch ({type:types.INBOX_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.MESSAGESTATUS_HIDE_FAILURE, error: err.response});
  }
}

export const addMessage = (thread_id,member,message) => async dispatch => {
  dispatch ({type:types.INBOX_ADDMESSAGE_REQUEST});

  try {
    const resp = await axios.post('/api/v1/inbox/'+member.type +
      '/'+(member.user_id || member.group_id) + '/' + thread_id,{
      message: message
    });
    dispatch ({type:types.INBOX_ADDMESSAGE_SUCCESS, payload: resp.data });
    dispatch ({type:types.INBOX_MESSAGES_SUCCESS, payload: resp.data });
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.INBOX_ADDMESSAGE_FAILURE, error: err.response});
  }
}

export const rcopiaNotificationsList = () => async dispatch => {
  dispatch ({type: types.RCOPIA_NOTIFICATIONS_REQUEST});
  try {
    const resp = await axios.get('/api/v1/rcopia/notifications');
    dispatch ({type: types.RCOPIA_NOTIFICATIONS_SUCCESS, payload: resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.RCOPIA_NOTIFICATIONS_FAILURE, error: err.response});
  }
}

export const getChartParameters = (patient_id) => async dispatch => {
  dispatch ({type: types.CHART_PARAMS_REQUEST});
  try {
    const resp = await axios.get('/api/v1/charts/'+patient_id);
    dispatch ({type: types.CHART_PARAMS_SUCCESS, payload: resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.CHART_PARAMS_FAILURE, error: err.response});
  }
}

export const chartParamsClear = () => dispatch => {
  dispatch ({type: types.CHART_PARAMS_CLEAR});
}


export const addComment = (note_id,comment) => async dispatch => {
  dispatch ({type: types.COMMENT_ADD_REQUEST});
  try {
    const res = await axios.post('/api/v1/notes/'+note_id+'/comments',{comment:comment});
    dispatch ({type: types.COMMENT_ADD_SUCCESS, payload: res.data});
    dispatch ({type: types.COMMENT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.COMMENT_ADD_FAILURE, error: err.response});
  }
}

export const addCommentClear = () => async dispatch => {
  dispatch ({type: types.COMMENT_ADD_CLEAR});
}

export const getComments = (note_id) => async dispatch => {
  dispatch ({type: types.COMMENT_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/notes/'+note_id+'/comments');
    dispatch ({type: types.COMMENT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.COMMENT_LIST_FAILURE, error: err.response});
  }
}

export const getCommentClear = () => async dispatch => {
  dispatch ({type: types.COMMENT_LIST_CLEAR});
}

export const setEmrState = (data) => dispatch => {
  dispatch ({ type: types.EMR_SET, payload: data });
}

export const transferDocumentsToChart = (patient_id,document_ids) => async dispatch => {
  dispatch ({type: types.DOCUMENTS_TRANSFER_REQUEST});
  try {
    const res = await axios.put('/api/v1/documents/'+patient_id,{
      document_ids: document_ids
    });
    dispatch ({type: types.DOCUMENTS_TRANSFER_SUCCESS, payload: res.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.DOCUMENTS_TRANSFER_FAILURE, error: err.response});
  }
}

export const transferAttachmentToDocuments = (patient_id,attachment_id) => async dispatch => {
  dispatch ({type: types.DOCUMENTS_TRANSFER2_REQUEST});
  try {
    const res = await axios.get('/api/v1/documents/'+patient_id+'/'+attachment_id);
    dispatch ({type: types.DOCUMENTS_TRANSFER2_SUCCESS, payload: res.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.DOCUMENTS_TRANSFER2_FAILURE, error: err.response});
  }
}

export const transferAttachmentToDocumentsClear = () => dispatch => {
  dispatch ({type: types.DOCUMENTS_TRANSFER2_CLEAR});
}

export const getDocuments = (patient_id) => async dispatch => {
  dispatch ({type: types.DOCUMENTS_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/documents/'+patient_id);
    dispatch ({type: types.DOCUMENTS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.DOCUMENTS_LIST_FAILURE, error: err.response});
  }
}

export const deleteDocument = (patient_id,document_id) => async dispatch => {
  dispatch ({type: types.DOCUMENTS_DELETE_REQUEST});
  try {
    const res = await axios.delete('/api/v1/documents/'+patient_id+'/'+document_id);
    dispatch ({type: types.DOCUMENTS_DELETE_SUCCESS});
    dispatch ({type: types.DOCUMENTS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.DOCUMENTS_DELETE_FAILURE, error: err.response});
  }
}

export const getAttachments = (note_id) => async dispatch => {
  dispatch ({type: types.ATTACHMENTS_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/attachments/'+note_id);
    dispatch ({type: types.ATTACHMENTS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.ATTACHMENTS_LIST_FAILURE, error: err.response});
  }
}

export const getAttachmentsClear = () => async dispatch => {
  dispatch ({type: types.ATTACHMENTS_LIST_CLEAR});
}

export const deleteAttachment = (note_id,attachment_id) => async dispatch => {
  dispatch ({type: types.ATTACHMENTS_DELETE_REQUEST});
  try {
    const res = await axios.delete('/api/v1/attachments/'+note_id+'/'+attachment_id);
    dispatch ({type: types.ATTACHMENTS_DELETE_SUCCESS});
    dispatch ({type: types.ATTACHMENTS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.ATTACHMENTS_DELETE_FAILURE, error: err.response});
  }
}

export const deleteAttachmentsClear = () => async dispatch => {
  dispatch ({type: types.ATTACHMENTS_DELETE_CLEAR});
}

export const renameAttachment = (note_id,attachment_id,newName,newType) => async dispatch => {
  dispatch ({type: types.ATTACHMENTS_RENAME_REQUEST});
  try {
    const res = await axios.put('/api/v1/attachments/'+note_id+'/'+attachment_id,
      {name:newName,type:newType});
    dispatch ({type: types.ATTACHMENTS_RENAME_SUCCESS});
    dispatch ({type: types.ATTACHMENTS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.ATTACHMENTS_RENAME_FAILURE, error: err.response});
  }
}

export const renameAttachmentsClear = () => async dispatch => {
  dispatch ({type: types.ATTACHMENTS_RENAME_CLEAR});
}

export const getPatientChartForPrinting = (patient_id,start_date,end_date,providers) => async dispatch => {
  dispatch ({type: types.CHART_PRINTLOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/patientchartprint/'+patient_id +
      '/' + start_date + '/' + end_date + '/' + providers.join('-'));
    dispatch ({type: types.CHART_PRINTLOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.CHART_PRINTLOAD_FAILURE, error: err.response});
  }
}

export const getPatientChart = (patient_id) => async dispatch => {
  dispatch ({type: types.CHART_FULLLOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/patientchart/'+patient_id);
    dispatch ({type: types.CHART_FULLLOAD_SUCCESS, payload: resp.data});
    dispatch ({type: 'PATIENT_LOAD_SUCCESS', payload: resp.data.patient});
    dispatch ({type: 'ALERTS_LIST_SUCCESS', payload: resp.data.alerts});
    dispatch ({type: types.NOTE_LIST_SUCCESS, payload: resp.data.notes});
    dispatch ({type: 'RECENT_PATIENTLIST_SUCCESS', payload: resp.data.recent});
    dispatch ({type: types.NOTE_UNSIGNEDLIST_SUCCESS, payload: resp.data.unsigned});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.CHART_FULLLOAD_FAILURE, error: err.response});
  }
}

export const supervisorListLoad = (user_id) => async dispatch => {
  dispatch ({type: types.SUPERVISOR_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/supervisors/'+user_id);
    dispatch ({type: types.SUPERVISOR_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.SUPERVISOR_LIST_FAILURE, error: err.response});
  }
}

export const supervisorListClear = () => async dispatch => {
  dispatch ({type: types.SUPERVISOR_LIST_CLEAR});
}

export const bulkSupervisorApprove = (data) => async dispatch => {
  dispatch ({type: types.NOTE_SUPERVISORBULKAPPROVE_REQUEST});
  try {
    const res = await axios.post('/api/v1/supervisorbulkapprove',data);
    dispatch ({type: types.NOTE_SUPERVISORBULKAPPROVE_SUCCESS, payload: res.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.NOTE_SUPERVISORBULKAPPROVE_FAILURE, error: err.response});
  }
}

export const bulkSupervisorApproveClear = () => async dispatch => {
  dispatch ({type: types.NOTE_SUPERVISORBULKAPPROVE_CLEAR});
}

export const getTemplateLetterList = () => async dispatch => {
  dispatch ({type:types.TEMPLATELETTER_LIST_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/lettertemplates');
    dispatch ({type:types.TEMPLATELETTER_LIST_LOAD_SUCCESS,payload:resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.TEMPLATELETTER_LIST_LOAD_FAILURE,error:err.response});
  }
}

export const getTemplateFieldValues = (patient_id) => async dispatch => {
  dispatch ({type:types.TEMPLATELETTER_FIELDS_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/lettertemplates/'+patient_id);
    dispatch ({type:types.TEMPLATELETTER_FIELDS_LOAD_SUCCESS,payload:resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.TEMPLATELETTER_FIELDS_LOAD_FAILURE,error:err.response});
  }
}

export const saveTemplateLetter = (template_id,data) => async dispatch => {
  dispatch ({type:types.TEMPLATELETTER_SAVE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/lettertemplates/'+template_id,data);
    dispatch ({type:types.TEMPLATELETTER_LIST_LOAD_SUCCESS,payload:resp.data});
    dispatch ({type:types.TEMPLATELETTER_SAVE_SUCCESS});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.TEMPLATELETTER_SAVE_FAILURE,error:err.response});
  }
}

export const createNewTemplateLetter = (data) => async dispatch => {
  dispatch ({type:types.TEMPLATELETTER_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/lettertemplates',data);
    dispatch ({type:types.TEMPLATELETTER_LIST_LOAD_SUCCESS,payload:resp.data.list});
    dispatch ({type:types.TEMPLATELETTER_CREATE_SUCCESS,payload:{id:resp.data.id}});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.TEMPLATELETTER_CREATE_FAILURE,error:err.response});
  }
}

export const getProblemList = (patient_id) => async dispatch => {
  dispatch ({type:types.PROBLEMLIST_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/problemlist/'+patient_id);
    dispatch ({type:types.PROBLEMLIST_LOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.PROBLEMLIST_LOAD_FAILURE, error: err.response});
  }
}

export const clearProblemList = () => async dispatch => {
  dispatch ({type:types.PROBLEMLIST_LOAD_CLEAR});
}

export const activateProblemListItem = (patient_id,item_id) => async dispatch => {
  dispatch ({type:types.PROBLEMLIST_ACTIVATE_REQUEST});
  try {
    const resp = await axios.get('/api/v1/problemlist/'+patient_id+'/subscribe/'+item_id);
    dispatch ({type:types.PROBLEMLIST_ACTIVATE_SUCCESS});
    dispatch ({type:types.PROBLEMLIST_LOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.PROBLEMLIST_ACTIVATE_FAILURE, error: err.response});
  }
}

export const deactivateProblemListItem = (patient_id,item_id) => async dispatch => {
  dispatch ({type:types.PROBLEMLIST_DEACTIVATE_REQUEST});
  try {
    const resp = await axios.get('/api/v1/problemlist/'+patient_id+'/unsubscribe/'+item_id);
    dispatch ({type:types.PROBLEMLIST_DEACTIVATE_SUCCESS});
    dispatch ({type:types.PROBLEMLIST_LOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.PROBLEMLIST_DEACTIVATE_FAILURE, error: err.response});
  }
}

export const saveProblemListItem = (patient_id,item_id,data) => async dispatch => {
  dispatch ({type:types.PROBLEMLIST_SAVE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/problemlist/'+patient_id+'/save/'+item_id,data);
    dispatch ({type:types.PROBLEMLIST_SAVE_SUCCESS});
    dispatch ({type:types.PROBLEMLIST_LOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.PROBLEMLIST_SAVE_FAILURE, error: err.response});
  }
}

export const createProblemListItem = (patient_id,data) => async dispatch => {
  dispatch ({type:types.PROBLEMLIST_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/problemlist/'+patient_id,data);
    dispatch ({type:types.PROBLEMLIST_CREATE_SUCCESS});
    dispatch ({type:types.PROBLEMLIST_LOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.PROBLEMLIST_CREATE_FAILURE, error: err.response});
  }
}

export const getDiagnosisList = (query) => async dispatch => {
  const q = (query.length === 0) ? '' : '?query='+encodeURIComponent(query);
  dispatch ({type:types.DIAGNOSIS_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/diagnosislist'+q);
    dispatch ({type:types.DIAGNOSIS_LIST_SUCCESS, payload: resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.DIAGNOSIS_LIST_FAILURE, error: err.response});
  }
}

export const noteCreateAddendum = (note_id,note) => async dispatch => {
  dispatch ({type:types.NOTE_ADDENDUM_CREATE_REQUEST});
  try {
    await axios.post('/api/v1/notes/'+note_id+'/addendum',{note: note});
    dispatch ({type:types.NOTE_ADDENDUM_CREATE_SUCCESS});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_ADDENDUM_CREATE_FAILURE, error:err.response});
  }
}

export const noteCreateAddendumClear = () => async dispatch => {
  dispatch ({type:types.NOTE_ADDENDUM_CREATE_CLEAR});
}

export const getMissingNotesList = () => async dispatch => {
  dispatch ({type:types.NOTE_MISSINGLIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/missingnotes');
    dispatch ({type:types.NOTE_MISSINGLIST_SUCCESS,payload:resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_MISSINGLIST_FAILURE, error:err.response});
  }
}

export const getUnsignedNotesList = () => async dispatch => {
  dispatch ({type:types.NOTE_UNSIGNEDLIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/unsignednoteslist');
    dispatch ({type:types.NOTE_UNSIGNEDLIST_SUCCESS,payload:resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_UNSIGNEDLIST_FAILURE, error:err.response});
  }
}

export const getSuperviseeCountersignList = () => async dispatch => {
  dispatch ({type:types.NOTE_SUPERVISEEUNSIGNEDLIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/superviseenoteslist');
    dispatch ({type:types.NOTE_SUPERVISEEUNSIGNEDLIST_SUCCESS,payload:resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_SUPERVISEEUNSIGNEDLIST_FAILURE, error:err.response});
  }
}

export const noteCreate = (patient_id,note_type,values) => async dispatch => {
  dispatch ({type:types.NOTE_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/notes/0/create/'+patient_id+'/'+note_type,values);
    dispatch ({type:types.NOTE_CREATE_SUCCESS,payload:resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_CREATE_FAILURE,error:err.response});
  }
}

export const noteCreateClear = () => dispatch => {
  dispatch ({type:types.NOTE_CREATE_CLEAR});
}

export const noteSave = (id,values) => async dispatch => {
  dispatch ({type:types.NOTE_SAVE_REQUEST});
  try {
    await axios.post('/api/v1/notes/'+id,values);
    dispatch ({type:types.NOTE_SAVE_SUCCESS});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_SAVE_FAILURE,error:err.response});
  }
}

export const noteSaveClear = () => dispatch => {
  dispatch ({type:types.NOTE_SAVE_CLEAR});
}

export const noteAutoSave = (id,values) => async dispatch => {
  dispatch ({type:types.NOTE_AUTOSAVE_REQUEST});
  try {
    await axios.post('/api/v1/notes/'+id,values);
    dispatch ({type:types.NOTE_AUTOSAVE_SUCCESS});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_AUTOSAVE_FAILURE,error:err.response});
  }
}

export const noteAutoSaveClear = () => dispatch => {
  dispatch ({type:types.NOTE_AUTOSAVE_CLEAR});
}

export const noteReject = (note_id,supervisor_comment) => async dispatch => {
  dispatch ({type:types.NOTE_REJECT_REQUEST});
  try {
    const resp = await axios.post('/api/v1/notes/'+note_id+'/supervisor/reject',{
      comment: supervisor_comment
    });
    dispatch ({type:types.NOTE_REJECT_SUCCESS});
    dispatch ({type: types.NOTE_SUPERVISEEUNSIGNEDLIST_SUCCESS,payload: resp.data.supervisee});
    dispatch ({type: types.NOTE_UNSIGNEDLIST_SUCCESS,payload: resp.data.unsignednotes});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_REJECT_FAILURE,error:err.response});
  }
}

export const noteRejectClear = () => dispatch => {
  dispatch ({type:types.NOTE_REJECT_CLEAR});
}

export const noteApprove = (note_id,supervisor_comment) => async dispatch => {
  dispatch ({type:types.NOTE_APPROVE_REQUEST});
  try {
    const values = { supervisor_comment: supervisor_comment };
    await axios.post('/api/v1/notes/'+note_id+'/supervisor/approve',values);
    dispatch ({type:types.NOTE_APPROVE_SUCCESS});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_APPROVE_FAILURE,error:err.response});
  }
}

export const noteApproveClear = () => dispatch => {
  dispatch ({type:types.NOTE_APPROVE_CLEAR});
}

export const noteSign = (id,values) => async dispatch => {
  dispatch ({type:types.NOTE_SIGN_REQUEST});
  try {
    await axios.put('/api/v1/notes/'+id,values);
    dispatch ({type:types.NOTE_SIGN_SUCCESS});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_SIGN_FAILURE,error:err.response});
  }
}

export const noteSignClear = () => dispatch => {
  dispatch ({type:types.NOTE_SIGN_CLEAR});
}

export const noteLoad = (id) => async dispatch => {
  dispatch ({type:types.NOTE_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/notes/'+id);
    dispatch ({type:types.NOTE_LOAD_SUCCESS,payload:resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_LOAD_FAILURE,error:err.response});
  }
}

export const noteLoadClear = () => dispatch => {
  dispatch ({type:types.NOTE_LOAD_CLEAR});
}

export const getNotesList = (patient_id) => async dispatch => {
  dispatch ({type:types.NOTE_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/notes/0/list/'+patient_id);
    dispatch ({type:types.NOTE_LIST_SUCCESS,payload:resp.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_LIST_FAILURE,error:err.response});
  }
}

export const noteDelete = (id) => async dispatch => {
  dispatch ({type:types.NOTE_DELETE_REQUEST});
  try {
    await axios.delete('/api/v1/notes/'+id);
    dispatch ({type:types.NOTE_DELETE_SUCCESS});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type:types.NOTE_DELETE_FAILURE,error:err.response});
  }
}

export const noteDeleteClear = () => dispatch => {
  dispatch ({type:types.NOTE_DELETE_CLEAR});
}

export const createChartAuditLogRecord = (data) => async dispatch => {
  dispatch ({type: types.CHART_AUDIT_LOG_CREATE_REQUEST});
  try {
    const res = await axios.post(`/api/v2/chart-audit-log`, data);
    dispatch ({type: types.CHART_AUDIT_LOG_CREATE_SUCCESS, payload: res.data});
  } catch (err) {
    emrLogout(dispatch,err);
    dispatch ({type: types.CHART_AUDIT_LOG_CREATE_FAILURE, error: err.response});
  }
}