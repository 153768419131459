import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload, Button, Progress, Spin } from 'antd';

import AttachmentUploadList from './AttachmentUpload_/AttachmentUploadList';

import * as actions from '../../../../../actions/emr';
import { safe } from '../../../../../helpers/reduxSelectors';

class AttachmentUpload extends Component {

  static propTypes = {
    patientId: PropTypes.number,
    noteId: PropTypes.number
  }

  constructor(props) {
    super(props);
    this.state = {
      select: undefined,
      uploadProgress: undefined
    }
  }

  componentDidMount() {
    this.props.getAttachments(this.props.noteId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.noteId !== this.props.noteId) {
      this.props.getAttachments(this.props.noteId);
    }
  }

  renderUploadProgress = () => {
    if (this.state.uploadProgress) {
      return (
        <Progress style={{width:'500px'}} percent={this.state.uploadProgress} />
      );
    }
    return null;
  }

  onSelectChange = (value) => {
    this.setState({
      select: value
    })
  }

  renderUploadControl = () => {
    var self = this;
    const props = {
      action: '/api/v1/attachments/' + this.props.patientId + '/' + this.props.noteId + '/1',
      showUploadList: false,
      onChange(info) {
        if (info.event) {
          self.setState({
            uploadProgress: Math.trunc(info.event.percent)
          });
        }
        if (info.file.status === 'done') {
          self.setState({ uploadProgress: undefined });
          self.props.getAttachments(self.props.noteId);
          //message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          self.setState({ uploadProgress: undefined });
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
      <span style={{width:'250px',paddingLeft:'10px'}}>
        <Upload {...props}>
          <Button>
            <UploadOutlined /> Click or Drag to Upload
          </Button>
        </Upload>
      </span>
    );
  }

  render() {
    return (
      <div style={{paddingBottom:'60px'}}>
        <Spin spinning={this.props.isBusy}>
          <h4>Attachments { this.renderUploadControl() }</h4>
          <div>

          </div>
          <div>
            { this.renderUploadProgress() }
          </div>
          <AttachmentUploadList list={this.props.list} noteId={this.props.noteId}/>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'attachments.list.processing',false) ||
      safe(state,'attachments.rename.processing',false) ||
      safe(state,'attachments.delete.processing',false)
    ) ? true : false,
    list: safe(state,'emr.attachments.list.data',[])
  }
}

export default connect(mapStateToProps,actions)(AttachmentUpload);
