import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import { credentialToString } from 'helpers/credential';
import numeral from 'numeral';

const columns: Array<ColumnI> = [
  {
    title: 'Provider',
    dataIndex: 'provider',
  },
  {
    title: 'Pay Period Start',
    dataIndex: 'pps',
  },
  {
    title: 'Pay Period End',
    dataIndex: 'ppe',
  },
  {
    title: 'Type',
    dataIndex: 'credential',
    render: (val) => credentialToString(val, false),
  },
  {
    title: 'Location',
    dataIndex: 'location',
  },
  {
    title: 'Total Hours Worked',
    dataIndex: 'total_hrs_worked',
    render: (val) => numeral(val).format('0.00'),
  },
];

export default columns;
