/**
 * QueueFormWrapper.tsx
 *
 * A wrapper for any report allowing queued reporting which
 * allows custom Report Parameter form components to be
 * passed through depending on the report's needs. The
 * ReportParametersWrapper component below outputs the child
 * components passed through.
 *
 * ScheduledParameters never change so those don't need to
 * be custom in any way at the moment.
 */
import { useEffect } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Alert, Button, Modal, Form } from 'antd';
import QueueContext from 'components/App_/ReportsV2_/QueueContext';
import ScheduledParameters from './QueueReport_/ScheduledParameters';
import ReportParametersWrapper from './QueueReport_/ReportParametersWrapper';
import formItemRules from './formItemRules';
import styles from './QueueFormWrapper.style';

interface PropsI {
  reportId: number;
  children?: React.ReactNode;
}

const formFieldNames = Object.keys(formItemRules);
const emptyFieldsValues = Object.fromEntries(formFieldNames.map((key: string) => [key, undefined]));

const QueueFormWrapper: React.FC<PropsI> = (props: PropsI): any => {
  const { children, reportId } = props;
  const doQueueReport = useContextSelector(QueueContext, (c) => c.doQueueReport);
  const form = useContextSelector(QueueContext, (c) => c.state.form);
  const isModalVisible = useContextSelector(QueueContext, (c) => c.state.isModalVisible);
  const setState = useContextSelector(QueueContext, (c) => c.setState);
  const isRecurring = useContextSelector(QueueContext, (c) => c.state.isRecurring);

  useEffect(() => {
    if (!isModalVisible) {
      form?.resetFields();
      form?.setFieldsValue(emptyFieldsValues);
    }
  }, [isModalVisible]);

  const showModal = () => {
    setState((s) => ({ ...s, isModalVisible: true }));
  };

  const handleCancel = () => {
    setState((s) => ({ ...s, isModalVisible: false }));
  };

  const onFinish = () => {
    doQueueReport();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const ModalControls = () => (
    <div className="pt8" style={styles.root}>
      <Button key="back" onClick={handleCancel}>
        Cancel
      </Button>
      <Form.Item key="submit">
        <Button type="default" htmlType="submit">
          Queue Report
        </Button>
      </Form.Item>
    </div>
  );

  return (
    <>
      <Button className="mr4" onClick={showModal}>
        Queue Report
      </Button>
      <Modal title="Queue Report" visible={isModalVisible} footer={null} destroyOnClose={true} onCancel={handleCancel}>
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item hidden={true} initialValue={reportId} name="reportId" rules={formItemRules.reportId} />
          <ScheduledParameters />
          <ReportParametersWrapper>{children}</ReportParametersWrapper>
          {isRecurring && (
            <div className="p4">
              <Alert message="Recurring reports run at midnight." type="info" />
            </div>
          )}
          <ModalControls />
        </Form>
      </Modal>
    </>
  );
};

export default QueueFormWrapper;
