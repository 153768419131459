import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import moment from 'moment';

const columns: Array<ColumnI> = [
  {
    title: 'Date',
    dataIndex: 'session_date',
    key: 'dos',
    width: 100,
    render: (val) => moment(val).format('MM/DD/YYYY'),
  },
  {
    title: 'Start Time',
    dataIndex: 'start_time',
  },
  {
    title: 'End Time',
    dataIndex: 'end_time',
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
  },
  {
    title: 'Allow Video',
    dataIndex: 'allow_video',
  },
  {
    title: 'Allow Intakes',
    dataIndex: 'allow_intake',
    render: (val) => Boolean(val),
  },
  {
    title: "Age's Allowed",
    dataIndex: 'age_category',
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'user',
    width: 100,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 75,
  },
];

export default columns;
