import React from 'react';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table, Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import TemplateChooser from './Templates_/TemplateChooser';
import TemplateItem from './Templates_/TemplateItem';
import Time from '../../../shared/Time';

import * as scheduler_actions from '../../../../actions/scheduler';
import * as shared_actions from '../../../../actions/shared';
import { safe } from '../../../../helpers/reduxSelectors';

class Templates extends React.Component {
  state = {
    modal_type: 1,
    template_item_visible: false,
    template_item_id: null,
    template_item_data: null,
  }

  componentDidMount() {
    this.props.getTemplatesList(this.props.provider_id);
    this.props.getLocationsList();
  }

  componentDidUpdate(prevProps) {
    if ( (prevProps.visible !== this.props.visible && this.props.visible) ||
         (prevProps.provider_id !== this.props.provider_id && this.props.provider_id !== 0) ) {
      this.props.getTemplatesList(this.props.provider_id);
    }
  }

  showCreateItem = () => {
    this.showTemplateItemModal(1,null,{});
  }

  showEditItem = (template_item_id,template_item_data) => {
    this.showTemplateItemModal(2,template_item_id,template_item_data);
  }

  showCopyItem = (template_item_id,template_item_data) => {
    const newData = JSON.parse(JSON.stringify(template_item_data));
    this.showTemplateItemModal(3,template_item_id,newData);
  }

  showTemplateItemModal = (modal_type,template_item_id,template_item_data) => {
    this.setState({
      modal_type: modal_type,
      template_item_visible: true,
      template_item_id: template_item_id,
      template_item_data: template_item_data,
    });
  }

  closeTemplateItemModal = () => {
    this.setState({
      template_item_visible: false
    });
  }

  /*
  getLocationName = (id) => {
    switch (id) {
      case 1: return 'SF';
      case 2: return 'WC';
      case 3: return 'LA';
      case 4: return 'TEL';
      default: return 'OTH';
    }
  }
  */

  deleteItem = (template_item_id) => {
    const This = this;
    Modal.confirm({
      title: 'Are you sure you want to delete this item?',
      onOk() {
        This.props.removeTemplateItem(This.props.provider_id,
          This.props.selected_template_id,template_item_id);
      },
      onCancel() {
      },
    });
  }

  displayStats = (a,b) => {
    return a + '/' + b + ' hrs';
    /*
    const is_prescriber = safe(this.props,'provider_roles',[]).includes('prescriber');
    if (is_prescriber) {
      return a + '/' + b + ' hrs';
    } else {
      return a + ' hrs ('+Math.round(a*100*1.3334)/100+' ses) /' + b + ' hrs ('+Math.round(b*100*1.3334)/100+' ses)';
    }
    */
  }

  renderTemplateList = () => {
    const columns=[{
      title: 'Type',
      dataIndex: 'type',
      render: (value) => {
        switch (value) {
          case 1: return 'Recurring';
          case 2: return 'One-Time';
          case 3: return 'Extra Recurring';
          default: return '--';
        }
      }
    },{
      title: 'Day/Date',
      dataIndex: 'day_of_week',
      render: (value,record) => {
        if (record.type === 1 || record.type === 3) {
          switch (value) {
            case 0: return 'Sun';
            case 1: return 'Mon';
            case 2: return 'Tue';
            case 3: return 'Wed';
            case 4: return 'Thu';
            case 5: return 'Fri';
            case 6: return 'Sat';
            default: return value;
          }
        } else {
          return moment(record.fixed_date).format('ddd, M/D/YYYY')
        }
      }
    },{
      title: 'Starting',
      dataIndex: 'start_time',
      render: (value) => {
        return (value) ? (new Time(value)).toString(false) : '-';
      }
    },{
      title: 'Ending',
      dataIndex: 'end_time',
      render: (value) => {
        return (value) ? (new Time(value)).toString(false) : '-';
      }
    },{
      title: 'Location',
      dataIndex: 'location_id',
      render: (value) => {
        const record = this.props.locationList.find(x=>x.id===value);
        return (record) ? record.abbr : '--';
      }
    },{
      title: '20m',
      dataIndex: 'allow_20m',
      render: (value) => {
        return (value) ? 'X' : '';
      }
    },{
      title: '30m',
      dataIndex: 'allow_30m',
      render: (value) => {
        return (value) ? 'X' : '';
      }
    },{
      title: '45m',
      dataIndex: 'allow_45m',
      render: (value) => {
        return (value) ? 'X' : '';
      }
    },{
      title: '60m',
      dataIndex: 'allow_60m',
      render: (value) => {
        return (value) ? 'X' : '';
      }
    },{
      title: 'Intakes',
      dataIndex: 'allow_intake',
      render: (value) => {
        return (value) ? 'X' : '';
      }
    },{
      title: 'Age',
      dataIndex: 'age_category',
      render: (value) => {
        switch (value) {
          case 0: return 'Ad/Ch';
          case 1: return 'Ad';
          case 2: return 'Ch';
          default: return '-';
        }
      }
    },{
      title: 'TeleMedicine',
      dataIndex: 'allow_video',
      render: (value) => {
        switch (value) {
          case 0: return 'Video/Office';
          case 1: return 'Video Only';
          case 2: return 'Office Only';
          default: return '-';
        }
      }
    },{
      title: 'Actions',
      dataIndex: 'id',
      align: 'center',
      render: (id,record) => {
        return (
          <span style={{whiteSpace: 'nowrap'}}>
            <Button disabled={!unlocked} type='link' icon={<EditOutlined />} onClick={()=>{this.showEditItem(id,record)}} />
            <Button disabled={!unlocked} type='link' icon={<CopyOutlined />} onClick={()=>{this.showCopyItem(id,record)}} />
            <Button disabled={!unlocked} type='link' icon={<DeleteOutlined />} onClick={()=>{this.deleteItem(id)}} />
          </span>
        );
      }
    }];

    const template = this.props.templateList.find(x=>x.id === this.props.selected_template_id);
    const data = safe(template,'items',[]);
    const unlocked = (template && !template.locked) ? true : false;

    const max_total_hrs = safe(template,'max_total_hrs',0);
  //  const max_total_hrs = safe(template,'max_total_hrs',0);
  //  const extra_hrs = safe(template,'extra_hrs',0);

    const calculated_hrs = data.reduce((acc,x)=>{
      return (x.type === 1) ? acc+x.duration : acc
    },0)/60;

    /*
    const calculated_extra_hrs = data.reduce((acc,x)=>{
      return (x.type == 3) ? acc+x.duration : acc
    },0)/60;

    */

    const bgcolor = (max_total_hrs === calculated_hrs) ? 'green' : 'red';

    const provider = this.props.providerList.find(x=>x.id === this.props.provider_id);
    const provider_name = safe(provider,'full_name');

    return (
      <div style={{padding:'5px'}}>
        <table style={{width:'100%'}}>
          <tbody>
            <tr>
              <td style={{textAlign:'left'}}>
                <h3>Schedule Templates: {provider_name}</h3>
              </td>
              <td>
                <div style={{backgroundColor:bgcolor,color:'white',textAlign:'center',padding:'10px'}}>
                  { this.displayStats(calculated_hrs,max_total_hrs) }
                </div>
              </td>
              <td style={{textAlign:'right'}}>
                <Button disabled={!unlocked} onClick={this.showCreateItem}>
                  Create New Template Item
                </Button>
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{paddingTop:'10px'}}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            size='small'
            bordered={true}
            rowKey='id'
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <Spin spinning={this.props.is_busy}>
        <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'stretch'}}>
          <div style={{flex:'1 1 0%',overflowX:'auto',height:'100%'}}>
            { this.renderTemplateList() }
          </div>

          <div style={{width:'350px',minWidth:'350px',height:'100%',flex:'0 1 0%',borderLeft:'1px solid lightgray'}}>
            <TemplateChooser
              provider_id={this.props.provider_id}
            />
          </div>

          <TemplateItem
            visible={this.state.template_item_visible}
            provider_id={this.props.provider_id}
            template_id={this.props.selected_template_id}
            template_item_id={this.state.template_item_id}
            template_item_data={this.state.template_item_data}
            modal_type={this.state.modal_type}
            onClose={this.closeTemplateItemModal}
          />
        </div>
      </Spin>

    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    is_busy: (
      safe(state,'scheduler.template.copy.processing',false) ||
      safe(state,'scheduler.template.create.processing',false) ||
      safe(state,'scheduler.template.update.processing',false) ||
      safe(state,'scheduler.template_item.create.processing',false) ||
      safe(state,'scheduler.template_item.update.processing',false) ||
      safe(state,'scheduler.template_item.remove.processing',false)
    ) ? true : false,
    templateList: safe(state,'scheduler.template.list.data',[]),
    selected_template_id: safe(state,'scheduler.settings.selected_template_id'),
    providerList: safe(state,'scheduler.provider.list.data',[]),
    locationList: safe(state,'shared.location.list.data',[]),
    provider_roles: safe(state,'scheduler.settings.provider_roles',[]),
  }
}

export default connect(mapStateToProps,{...scheduler_actions,...shared_actions})(Templates);
