/* eslint-disable react/display-name */
/**
 * DirectorList.tsx
 *
 */
import { useEffect, useState } from 'react';
import { Button, List, Modal, Select, Spin, Typography, notification } from 'antd';
import useFetch, { CachePolicies } from 'use-http';
import { DeleteOutlined } from '@ant-design/icons';

const Option = Select.Option;

interface PropsI {
  visible: boolean;
  closeDialog: () => void;
};

const DirectorList: React.FC<PropsI> = (props: PropsI) => {
  const { visible, closeDialog } = props;

  const [providerId, setProviderId] = useState<undefined | number>(undefined);
  const [directorId, setDirectorId] = useState<undefined | number>(undefined);

  const providerAddAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const providerListAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const directorListAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const directorProviderListAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const directorRemoveAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });

  const isBusy = providerListAPI?.loading || providerAddAPI?.loading || directorListAPI?.loading || directorProviderListAPI?.loading || directorRemoveAPI?.loading;

  const getProviderList = () => providerListAPI.get(`/api/v1/scheduler/provider`);
  const getDirectorList = () => directorListAPI.get(`/api/v1/directorslist`);

  useEffect(() => {
    if (visible) {
      getProviderList();
      getDirectorList();
    }
  }, [visible]);

  useEffect(() => {
    if (directorId && directorId >= 0) {
      directorGetProviders(directorId);
    }
  }, [directorId]);

  const directorRemove = async (e: any, superviseeId: number) => {
    e.stopPropagation();
    await directorRemoveAPI.del(`/api/v1/director/${directorId}/${superviseeId}`);
    await directorProviderListAPI?.get(`/api/v2/director/${directorId}`);
  };

  const directorGetProviders = async (id: number) => {
    if (!directorProviderListAPI?.loading) {
      const response = await directorProviderListAPI?.get(`/api/v2/director/${id}`);
      if (response?.error) {
        notification.error({
          message: 'Problem adding provider',
          description: response?.message,
        });
      }
    }
  };

  const providerAdd = async (id: number) => {
    if (!providerAddAPI?.loading) {
      const response = await providerAddAPI?.post(`/api/v1/director/${directorId}/${id}`);
      if (response?.error) {
        notification.error({
          message: 'Problem adding provider',
          description: response?.message,
        });
      }
    }
  };

  const onSelectProvider = async (id) => {
    await providerAdd(id);
    await directorProviderListAPI?.get(`/api/v2/director/${directorId}`);
    setProviderId(undefined);
  };

  const onSelectDirector = async (id) => {
    setDirectorId(id);
  };

  const onChange = (id) => {
    setProviderId(id);
  };

  return (
    <Modal
      style={{padding:'20px'}}
      title={"Manage Director Lists"}
      visible={visible}
      onCancel={closeDialog}
      okText={"Close"}
      width={800}
      onOk={closeDialog}
    >
      <Spin spinning={isBusy}>
        <div style={{ marginTop: '1rem', display: 'flex' }}>
          <div style={{ marginTop: '1rem' }}>
            <Select
              showSearch
              style={{ width: '300px' }}
              placeholder="Select a director"
              optionFilterProp="children"
              onSelect={onSelectDirector}
              value={directorId}
              filterOption={(input, option) => {
                return option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              }
            >
              {directorListAPI?.data?.map((x) => {
                return <Option key={x.id} value={x.id}>{x.full_name}</Option>;
              })}
            </Select>
            {directorId ? (
              <div style={{ marginTop: '1rem' }}>
                <Select
                  showSearch
                  style={{ width: '300px' }}
                  placeholder="Add a provider"
                  optionFilterProp="children"
                  onSelect={onSelectProvider}
                  onChange={onChange}
                  value={providerId}
                  filterOption={(input, option) => {
                    return option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  }
                >
                  {providerListAPI?.data?.map((x) => {
                    return <Option key={x.id} value={x.id}>{x.full_name}</Option>;
                  })}
                </Select>
              </div>
            ) : null}
          </div>
          {directorId ? (
            <div style={{ marginTop: '1rem', marginLeft: '1rem' }}>
              <List
                header={<div>Current supervisees:</div>}
                bordered
                dataSource={directorProviderListAPI?.data}
                renderItem={(item: any) => (
                  <List.Item>
                    <Typography.Text>{item.supervisee.full_name}</Typography.Text>&nbsp;
                    <Button disabled={isBusy} type="link" onClick={(e)=>directorRemove(e, item.supervisee_id)} style={{ zIndex: 999 }}>
                      <DeleteOutlined />
                    </Button>
                  </List.Item>
                )}
              />
            </div>
          ) : null}
        </div>
      </Spin>
    </Modal>
  );
};

export default DirectorList;
