/**
 * ProviderSelector.tsx
 */
import { useEffect } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Form, Select } from 'antd';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import useFetch, { CachePolicies } from 'use-http';

const { Option } = Select;

const ProviderSelector: React.FC = () => {
  const form = useContextSelector(InlineContext, (c) => c.state.form);
  const usersAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });

  useEffect(() => {
    const getProviders = async () => {
      await usersAPI.get('/api/v1/user');
    };
    getProviders();
  }, []);

  const onChange = (value) => {
    form?.setFieldsValue({ userId: value });
  };

  return !usersAPI.data ? null : (
    <Form.Item name="userId">
      <Select
        allowClear
        showSearch
        style={{ width: 200 }}
        placeholder="Select a provider"
        optionFilterProp="children"
        onChange={onChange}
        filterOption={(input, option) => {
          // console.log({ input, option });
          return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      >
        {usersAPI.data.map((patient, key) => (
          <Option key={key} value={patient.id}>
            {patient.full_name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ProviderSelector;
