import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Spin } from 'antd';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';

class ReportsGroupStats extends React.Component {

  componentDidMount() {
    this.props.reportsGetGroupStatus();
  }

  render() {
    const columns = [{
      title: 'Group',
      dataIndex: 'group_id',
      key: 'id',
      render: (val) => {
        if (val === 1) {
          return 'Reception';
        } else if (val === 2) {
          return 'Billing';
        }
        return 'Other';
      }
    },{
      title: 'Member',
      dataIndex: 'name',
      key: 'name'
    },{
      title: 'Region',
      dataIndex: 'type',
      key: 'type',
      render: (val) => {
        switch (val) {
          case 1: return 'SF';
          case 2: return 'WC';
          case 3: return 'LA';
          default: return 'OTHER';
        }
      }
    },{
      title: '# of Assigned Patients',
      dataIndex: 'customers',
      key: 'customers'
    }];

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
            <span style={{float:'right'}}><a href="/api/v1/reports/groupstats?csv=1">CSV Download</a></span>
          </div>

          <h3>Admin Group Membership Stats</h3>
          <Table
            className='reports-sessionlist-table'
            columns={columns}
            dataSource={this.props.list}
            bordered={true}
            size='small'
            rowKey='key'
            scroll={{ x:true }}
            pagination={false}
          />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'reports.groupstats.processing',false),
    list: safe(state,'reports.groupstats.data',[]),
  }
}

export default connect(mapStateToProps,actions)(ReportsGroupStats);
