/**
 * Emr.js
 * Core module for managing the electronic medical records program.
 */

import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  AuditOutlined,
  BarChartOutlined,
  CalendarOutlined,
  LikeOutlined,
  ScheduleOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Menu } from 'antd';
import { VLayout, VLayoutHeader, VLayoutContent, HLayout, HLayoutLeft, HLayoutContent } from '../shared/Layout';

import EmrHeader from './Emr_/EmrHeader';
import Patient from './Emr_/Patient';
import Supervisor from './Emr_/Supervisor';
import RecentPatientList from './Emr_/RecentPatientList';
import UnsignedNotesList from './Emr_/UnsignedNotesList';
import MissingNotesList from './Emr_/MissingNotesList';
import Timeout from '../shared/Timeout';
import Caseload from './Emr_/Caseload';
import Scheduler from './Emr_/Scheduler';
import Productivity from './Emr_/ProductivityWrapper';
import Search from './Emr_/Search';
import DirectorList from './Emr_/DirectorList';
import Director from './Emr_/Director';

import { safe, isDirector } from '../../helpers/reduxSelectors';
import * as emr_actions from '../../actions/emr';
import * as shared_actions from '../../actions/shared';

class Emr extends Component {
  componentDidMount() {
    // The first time the EMR is loaded we must load in the list of unsigned
    // notes and recently accessed patients.
    this.props.getUnsignedNotesList();
    this.props.loadRecentPatientList();
    this.props.getMissingNotesList();

    // Every time the route changes, we reload these items in.
    this.unlisten = this.props.history.listen(({ pathname }) => {
      if (pathname !== '/logout') {
        this.props.getUnsignedNotesList();
        this.props.loadRecentPatientList();
        this.props.getMissingNotesList();
      }
    });
  }

  // Remove the route listener on destruction of the component
  componentWillUnmount() {
    this.unlisten();
  }

  onClickMenu = ({ key }) => {
    switch (key) {
      case 'patients':
        if (this.props.patientId) {
          this.props.history.push('/emr/patients/' + this.props.patientId);
        } else {
          this.props.history.push('/emr/patients/0');
        }
        return;
      case 'appointments':
        if (this.props.patientId) {
          this.props.history.push('/emr/appointments/' + this.props.patientId);
        } else {
          this.props.history.push('/emr/appointments/0');
        }
        return;
      default:
        this.props.history.push('/emr/' + key);
    }
  };

  navMenu = () => {
    let pathname = this.props.location.pathname;
    let folders = pathname.split('/');
    let page = folders[2];

    const list = this.props.unsignedNotesList.filter((x) => {
      if (this.props.userId === x.supervising_provider_id && x.signedAt !== null && (x.type === 1 || x.type === 2)) {
        return true;
      }
      return false;
    });

    return (
      <Menu style={{ padding: '0px', borderRight: '0px' }} onClick={this.onClickMenu} selectedKeys={[page]}>
        <Menu.Item key="scheduler">
          <CalendarOutlined />
          <span>Calendar</span>
        </Menu.Item>

        <Menu.Item disabled={!this.props.patientId} key="appointments">
          <ScheduleOutlined />
          <span>Appointments</span>
        </Menu.Item>

        <Menu.Item key="patients">
          <UserOutlined />
          <span>Charts</span>
        </Menu.Item>

        <Menu.Item key="caseload">
          <TeamOutlined />
          <span>Caseload</span>
        </Menu.Item>

        <Menu.Item key="productivity">
          <BarChartOutlined />
          <span>Productivity</span>
        </Menu.Item>

        {this.props.isSupervisor && (
          <Menu.Item key="supervisor">
            <LikeOutlined />
            <span>Supervisees ({list.length})</span>
          </Menu.Item>
        )}

        {this.props.isDirector && (
          <Menu.Item key="director">
            <AuditOutlined />
            <span>Director</span>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  findEarliestUnsignedProgressNote = () => {
    let earliestDate = null;
    //    this.props.unsignedNotesList.filter(x=>x.provider_id === this.props.userId).forEach(x=>{
    this.props.unsignedNotesList
      .filter((x) => x.provider_id === this.props.userId || x.supervising_provider_id === this.props.userId)
      .forEach((x) => {
        if (x.type === 1 || x.type === 2) {
          // Progress notes
          if (earliestDate === null) {
            earliestDate = moment(x.note_date);
          } else {
            if (moment(x.note_date).isBefore(earliestDate)) {
              earliestDate = moment(x.note_date);
            }
          }
        }
      });
    return earliestDate;
  };

  render() {
    //#2b8ed8
    return (
      <Timeout>
        <VLayout className="main-app">
          <VLayoutHeader style={{ height: '50px', minHeight: '50px', backgroundColor: '#2b8ed8', color: 'white' }}>
            <EmrHeader earliestUnsignedNote={this.findEarliestUnsignedProgressNote} />
          </VLayoutHeader>

          <VLayoutContent>
            <HLayout>
              <HLayoutLeft
                style={{ width: '200px', minWidth: '200px', borderRight: '1px solid #e8e8e8', overflow: 'auto' }}
              >
                {this.navMenu()}
                <RecentPatientList list={this.props.recentPatientList} />
                <UnsignedNotesList
                  list={this.props.unsignedNotesList}
                  userId={this.props.userId}
                  isSupervisor={this.props.isSupervisor}
                />
                <MissingNotesList list={this.props.missingNotesList} />
              </HLayoutLeft>
              <HLayoutContent>
                <Route path="/emr/patients/:patient_id" component={Patient} />
                <Route path="/emr/appointments/:patient_id?/:session_id?/:pane_id?" component={Search} />
                <Route path="/emr/supervisor" component={Supervisor} />
                <Route path="/emr/caseload" component={Caseload} />
                <Route path="/emr/scheduler" component={Scheduler} />
                <Route path="/emr/productivity" component={Productivity} />
                <Route path="/emr/director" component={Director} />
              </HLayoutContent>
            </HLayout>
          </VLayoutContent>
        </VLayout>
      </Timeout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: safe(state, 'shared.auth.data.id'),
    patientId: safe(state, 'emr.emr.patientId'),
    isDirector: isDirector(state),
    isSupervisor: safe(state, 'shared.auth.data.is_supervisor'),
    recentPatientList: safe(state, 'shared.patient.recent.data', []),
    unsignedNotesList: safe(state, 'emr.note.unsignedlist.data', []),
    missingNotesList: safe(state, 'emr.note.missingnotes.data', []),
  };
};

export default connect(mapStateToProps, { ...emr_actions, ...shared_actions })(withRouter(Emr));
