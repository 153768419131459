import React from 'react';
import { Modal, Button, Spin } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/billing';

class DisplaySourceDialog extends React.Component {

  componentDidMount() {
    if (this.props.sourceId) {
      this.props.loadClearinghouseEntry(this.props.sourceId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sourceId !== this.props.sourceId && this.props.sourceId) {
      this.props.loadClearinghouseEntry(this.props.sourceId);
    }
  }

  onCancel = () => {
    this.props.closeDialog(true);
  }

  onOk = () => {
    this.props.closeDialog(true);
  }

  render() {
    const ediData = safe(this.props,'data.contents','');
    const createdDate = moment(this.props.data.createdAt).format('MM/DD/YYYY HH:mm:ss');

    return (
      <Modal
        title='Display EDI Source'
        visible={this.props.visible}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='Submit'
        width={700}
        bodyStyle={{height:'400px',overflowY:'scroll',overflowX:'hidden'}}
        footer={[
          <Button key="close" onClick={this.onCancel}>Close</Button>,
        ]}
      >
        <div >
          <Spin spinning={this.props.isBusy}>
            <h3>{this.props.data.filename} ({createdDate})</h3>
            <pre>
              { ediData }
            </pre>
          </Spin>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.clearinghouse.load.processing',false)
    ) ? true : false,
    data: safe(state,'billing.clearinghouse.load.data',{})
  }
}

export default connect(mapStateToProps,actions)(DisplaySourceDialog);
