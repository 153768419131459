/**
 * Payroll.tsx
 */
import reports from 'reports';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import InlineFormWrapper from 'components/App_/ReportsV2_/InlineFormWrapper';
import ReportWrapper from 'components/App_/ReportsV2_/ReportWrapper';
import Table from 'components/App_/ReportsV2_/Table';
import columns from 'components/App_/ReportsV2_/TableColumns_/payroll';
import { PaginatedDataT } from 'reports';
import DateRangeSelector from 'components/App_/ReportsV2_/InlineControls_/DateRangeSelector';
import DisplayReport from 'components/App_/ReportsV2_/InlineControls_/DisplayReport';
import InlineDateTypeSelector from 'components/App_/ReportsV2_/InlineControls_/DateTypeSelector';
import QueueDateTypeToggle from 'components/App_/ReportsV2_/QueueReport_/ReportParameters_/DateTypeToggle';
import QueueFormWrapper from 'components/App_/ReportsV2_/QueueFormWrapper';
import StartDate from 'components/App_/ReportsV2_/QueueReport_/ReportParameters_/StartDateType';
import EndDate from 'components/App_/ReportsV2_/QueueReport_/ReportParameters_/EndDateType';
import styles from './styles';

const reportId = 1;
const page = 1;
const pageSize = 15;

const Payroll: React.FC = () => {
  const inlineData = useContextSelector(InlineContext, (c) => c.state.inlineData) as PaginatedDataT;
  const report = reports.find((r) => r.id === reportId);

  const formOptions = {
    page,
    pageSize,
    reportId,
  };

  const tableOptions = {
    data: inlineData?.rows,
    columns,
    page,
    pageSize,
    reportId,
    rowKey: 'id',
    total: inlineData?.count,
  };

  return (
    <ReportWrapper>
      <h3>{report?.name}</h3>
      <div style={styles.root}>
        <InlineFormWrapper {...formOptions}>
          <InlineDateTypeSelector />
          <DateRangeSelector />
          <DisplayReport />
        </InlineFormWrapper>
        <QueueFormWrapper reportId={reportId}>
          <QueueDateTypeToggle />
          <StartDate />
          <EndDate />
        </QueueFormWrapper>
      </div>
      <Table {...tableOptions} />
    </ReportWrapper>
  );
};

export default Payroll;
