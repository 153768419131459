import React from 'react';
import { Row, Col } from 'antd';
import numeral from 'numeral';
import moment from 'moment';

class IBR extends React.Component {

  formatDate = (d) => {
    const year = d.substring(0,4);
    const month = d.substring(4,6);
    const day = d.substring(6,8);
    return month + '/' + day + '/' + year;
  }

  showClaims = (data) => {
    let key = 0;
    return data.map(x=>{

      const status = x.status;
      const errors = x['error_detail'].map(x=>x.error_message);
      const style = (status === 'R') ?  { color:'red',paddingBottom:'10px' } : {paddingBottom:'10px'};
/*
  // The following are still not included:
                "provider_billing_id": "1023125465",
                "clearinghouse_trace_num": "NA",
                */

      key++;
      return (
        <div key={key} style={style}>
          <Row>
            <Col span={6} style={{overflow:'hidden'}}>
              <div>{key}. {x.patient_name}</div>
            </Col>
            <Col span={3}>
              <div>{this.formatDate(x.dos_from)}</div>
            </Col>
            <Col span={3}>
              <div>{x.submitter_batch_id}</div>
            </Col>
            <Col span={3}>
              <div>{x.patient_control_number}</div>
            </Col>
            <Col span={3}>
              <div>{x.availity_trace_num}</div>
            </Col>
            <Col span={3}>
              <div>{status}</div>
            </Col>
            <Col span={3}>
              <div>{numeral(x.total_claim_charge).format('$0,0.00')}</div>
            </Col>
          </Row>
          { errors.length > 0 &&
            <Row>
              <Col span={24}>
                <div style={{paddingLeft:'20px',paddingRight:'20px'}}>
                  { errors.map(e=><div>{e}</div>) }
                </div>
              </Col>
            </Row>
          }
        </div>
      );
    });
  }

  showClaimsSection = (data) => {
    if (!data) {
      return null;
    }
    return (
      <div>
        <Row style={{borderBottom:'1px solid lightgray'}}>
          <Col span={6}><strong>Patient Name</strong></Col>
          <Col span={3}><strong>Date of Service</strong></Col>
          <Col span={3}><strong>Batch ID</strong></Col>
          <Col span={3}><strong>Patient #</strong></Col>
          <Col span={3}><strong>Availity #</strong></Col>
          <Col span={3}><strong>Status</strong></Col>
          <Col span={3}><strong>Amount</strong></Col>
        </Row>
        {this.showClaims(data)}
      </div>
    );
  }

  showPayer = (data) => {
    return (
      <div style={{paddingTop:'20px'}}>
        <div><strong>Payer: {data.payer_name} ({data.payer_id})</strong></div>
        { this.showClaimsSection(data.claims) }
      </div>
    );
  }

  header = (data) => {
    return (
      <div>
        <Row>
          <Col span={6}>
            <div><strong>Date Received: </strong> </div>
            <div><strong>Time Received: </strong> </div>
            <div><strong>Submission PCPA ID: </strong></div>
            <div><strong>Submission Filename: </strong></div>
          </Col>
          <Col span={6}>
            <div>{moment(data.date_received).format('MM/DD/YYYY')}</div>
            <div>{data.time_received}</div>
            <div>{data.file_control_num}</div>
            <div>{data.original_filename}</div>
          </Col>
          <Col span={6}>
            <div><strong>Availity Batch ID: </strong></div>
            <div><strong>Availity Customer ID: </strong></div>
            <div><strong>Availity File ID: </strong></div>
            <div><strong>Availity Message:</strong></div>
          </Col>
          <Col span={6}>
            <div>{data.availity_batch_id}</div>
            <div>{data.availity_customer_id}</div>
            <div>{data.availity_file_id}</div>
            <div>{data.availity_messages}</div>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <div><strong>Total Submitted Claims:</strong></div>
            <div><strong>Total Accepted Claims:</strong></div>
            <div><strong>Total Rejected Claims:</strong></div>

          </Col>
          <Col span={6}>
            <div>{data.total_submitted_claims}</div>
            <div>{data.total_accepted_claims}</div>
            <div>{data.total_rejected_claims}</div>
          </Col>
          <Col span={6}>
            <div><strong>Total Submitted Charges:</strong></div>
            <div><strong>Total Accepted Charges:</strong></div>
            <div><strong>Total Rejected Charges:</strong></div>
          </Col>
          <Col span={6}>
            <div style={{textAlign:'right'}}>{numeral(data.total_submitted_charges).format('$0,0.00')}</div>
            <div style={{textAlign:'right'}}>{numeral(data.total_accepted_charges).format('$0,0.00')}</div>
            <div style={{textAlign:'right'}}>{numeral(data.total_rejected_charges).format('$0,0.00')}</div>
          </Col>
        </Row>

        { data.payers.map(payer=>this.showPayer(payer)) }
      </div>
    );
  }

  render() {
    const { data } = this.props;

    return (
      <div>
        { this.header(data) }
      </div>
    )
  }

}

export default IBR;
