/**
 * RecurringTypeSelect.tsx
 */
import { useContextSelector } from 'use-context-selector';
import QueueContext from 'components/App_/ReportsV2_/QueueContext';
import { Form, Select } from 'antd';
import formItemRules from 'components/App_/ReportsV2_/formItemRules';
import styles from './FrequencyToggle.style';

const { Option } = Select;
const recurringTypes = ['Weekly', 'Monthly', 'Daily'];

const RecurringTypeSelect: React.FC = () => {
  const form = useContextSelector(QueueContext, (c) => c.state.form);
  const setState = useContextSelector(QueueContext, (c) => c.setState);

  const onChange = (type) => {
    const subType = undefined;
    setState((s) => ({ ...s, type, subType }));
    form?.setFieldsValue({ type, subType });
  };

  return (
    <div className="mt4">
      <h5>Recurring Type</h5>
      <Form.Item style={{ display: 'inline-block' }} className="m0" name="type" rules={formItemRules.type}>
        <Select onChange={onChange} size="small" style={styles.type}>
          {recurringTypes.map((interval, key) => (
            <Option value={key + 1} key={key}>
              {interval}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default RecurringTypeSelect;
