import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Select, Spin, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../../actions/settings';
import { safe } from '../../../helpers/reduxSelectors';
import { usStateAbbrOptions } from '../../../helpers/states';

class Organization extends Component {

  componentDidMount() {
    this.props.getOrgInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.save.processing) {
      if (this.props.save.success) {
        message.success('Organizational settings saved');
      } else {
        message.error('Unable to save organizational settings');
      }
      this.props.clearOrgSave();
    }
  }

  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.saveOrgInfo(values);
      }
    });
  }

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const data = safe(this.props,'load.data',{});
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };

    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy}>
          <h3>Organization Settings</h3>
          <Form className='org-form'>

            <FormItem {...formItemLayout} label="NPI Type">
              {getFieldDecorator('org_npi_type', {
                rules: [{ required: true, message: "Please enter the organization's NPI type"}],
                initialValue: (data.org_npi_type) ? data.org_npi_type : 2
              })(
                <Select>
                  <Select.Option value={1}>Individual</Select.Option>
                  <Select.Option value={2}>Group</Select.Option>
                </Select>
              )}
            </FormItem>

            { this.props.form.getFieldValue('org_npi_type') === 2 && <FormItem {...formItemLayout} label='Organization Name'>
              {getFieldDecorator('org_name', {
                rules: [{ required: true, message: "Please enter the organization's name", whitespace: true }],
                initialValue: data.org_name
              })(
                <Input />
              )}
            </FormItem> }

            { this.props.form.getFieldValue('org_npi_type') === 1 && <FormItem {...formItemLayout} label='Provider Last Name'>
              {getFieldDecorator('org_name', {
                rules: [{ required: true, message: "Please enter the provider's last name", whitespace: true }],
                initialValue: data.org_name
              })(
                <Input />
              )}
            </FormItem> }

            { this.props.form.getFieldValue('org_npi_type') === 1 && <FormItem {...formItemLayout} label='Provider Firstname'>
              {getFieldDecorator('org_firstname', {
                rules: [{ required: true, message: "Please enter the person's firstname", whitespace: true }],
                initialValue: data.org_firstname
              })(
                <Input/>
              )}
            </FormItem> }

            <FormItem {...formItemLayout} label='NPI #'>
              {getFieldDecorator('org_npi', {
                rules: [{ required: true, message: "Please enter the organization's NPI number", whitespace: true }],
                initialValue: data.org_npi
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label='EIN #'>
              {getFieldDecorator('org_ein', {
                rules: [{ required: true, message: "Please enter the organization's tax id number", whitespace: true }],
                initialValue: data.org_ein
              })(
                <Input />
              )}
            </FormItem>
            
            <h4>Claims Billing Address</h4>

            <FormItem {...formItemLayout} label="Address 1">
              {getFieldDecorator('org_address1', {
                rules: [{ required: true, message: "Please enter address", whitespace: true }],
                initialValue: data.org_address1
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Address 2">
              {getFieldDecorator('org_address2', {
                initialValue: data.org_address2
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="City">
              {getFieldDecorator('org_city', {
                rules: [{ required: true, message: "Please enter the city", whitespace: true }],
                initialValue: data.org_city
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="State">
              {getFieldDecorator('org_state', {
                rules: [{ required: true, message: "Please enter the state", whitespace: true }],
                initialValue: data.org_state || 'CA',
              })(
                <Select autoComplete="off">
                  {usStateAbbrOptions}
                </Select>
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Zip">
              {getFieldDecorator('org_zip', {
                rules: [{ required: true, message: "Please enter the zip", whitespace: true }],
                initialValue: data.org_zip
              })(
                <Input />
              )}
            </FormItem>

            <h4>Pay-To Address</h4>

            <FormItem {...formItemLayout} label="Address 1">
              {getFieldDecorator('payto_address1', {
                rules: [{ required: true, message: "Please enter address", whitespace: true }],
                initialValue: data.payto_address1
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Address 2">
              {getFieldDecorator('payto_address2', {
                initialValue: data.payto_address2
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="City">
              {getFieldDecorator('payto_city', {
                rules: [{ required: true, message: "Please enter the city", whitespace: true }],
                initialValue: data.payto_city
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="State">
              {getFieldDecorator('payto_state', {
                rules: [{ required: true, message: "Please enter the state", whitespace: true }],
                initialValue: data.payto_state || 'CA',
              })(
                <Select autoComplete="off">
                  {usStateAbbrOptions}
                </Select>
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Zip">
              {getFieldDecorator('payto_zip', {
                rules: [{ required: true, message: "Please enter the zip", whitespace: true }],
                initialValue: data.payto_zip
              })(
                <Input />
              )}
            </FormItem>

            <br />

            <FormItem {...formItemLayout} label="Phone #">
              {getFieldDecorator('org_phone', {
                rules: [{ required: true, message: "Please enter the phone #", whitespace: true }],
                initialValue: data.org_phone
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="FAX #">
              {getFieldDecorator('org_fax', {
                rules: [{ required: true, message: "Please enter the fax #", whitespace: true }],
                initialValue: data.org_fax
              })(
                <Input />
              )}
            </FormItem>

            <Row style={{paddingTop:'30px'}}>
              <Col xs={36} sm={16}>
                <Button
                  disabled={this.props.isBusy}
                  onClick={this.handleSubmit}
                  style={{float:'right'}}
                  type="primary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    load: safe(state,'settings.org_load'),
    save: safe(state,'settings.org_save'),
    isBusy: (
      safe(state,'settings.org_load.processing') ||
      safe(state,'settings.org_save.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(Organization)));
