import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import { DateTime } from 'luxon';
import get from 'lodash/get';

// TODO: Bring these into shared local module 'reports', change name...?
export const types = [
  'Login Success',
  'Login Failure',
  'Logout',
  'Timeout',
  'Billing Statement Viewed',
  'Billing Superbill Viewed',
  'Message Thread Viewed',
  'Document Retrieved',
  'Document Created',
  'Document Removed',
  'Document List Retrieved'
];

const columns: Array<ColumnI> = [
  {
    title: 'Timestamp',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_SHORT),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (val: string) => types[parseInt(val, 10) - 1],
  },
  {
    title: 'Patient',
    dataIndex: 'patientId',
    render: (_val, record): string => {
      const patientName = get(record, 'patient.full_name') as string;
      return patientName;
    },
  },
  {
    title: 'Document',
    dataIndex: 'documentId',
    render: (_val, record): string => {
      return get(record, 'document.title') as string;
    },
  },
  {
    title: 'Thread',
    dataIndex: 'threadId',
    render: (_val, record): string => {
      let threadName = '';
      if (record.thread) {
        const thread = record.thread as Record<string, unknown>;
        const members = thread.members as Array<Record<string, string>>;
        members.forEach((m) => {
          threadName = `${threadName}${m.name} `;
        });
      }
      return threadName.trim();
    },
  },
  {
    title: 'Data',
    dataIndex: 'data',
    render: (val): string => (val ? JSON.stringify(val) : ''),
  },
];

export default columns;
