import { combineReducers } from 'redux';
import { ajaxReducer } from '../../helpers/util';

export default combineReducers({
  bills: ajaxReducer('REPORTS_BILLS'),
  patients: ajaxReducer('REPORTS_PATIENTS'),
  groupstats: ajaxReducer('REPORTS_GROUPSTATS'),
  payroll: ajaxReducer('REPORTS_PAYROLL'),
  chargecollection: ajaxReducer('REPORTS_CHARGECOLLECTION'),
  adjustments: ajaxReducer('REPORTS_ADJUSTMENTS'),
  lyra: ajaxReducer('REPORTS_LYRA'),
  lifestance: ajaxReducer('REPORTS_LIFESTANCE'),
  dupsessions: ajaxReducer('REPORTS_DUPSESSIONS'),
  intakes: ajaxReducer('REPORTS_INTAKES'),
  unsignedmissed: ajaxReducer('REPORTS_UNSIGNEDMISSED'),
  ptcredits: ajaxReducer('REPORTS_PTCREDITS'),
  ptunallocated: ajaxReducer('REPORTS_PTUNALLOCATED'),
  hourlyemployees: ajaxReducer('REPORTS_HOURLYEMPLOYEES'),
  sessioncounts: ajaxReducer('REPORTS_SESSIONCOUNTS'),
  onhold: ajaxReducer('REPORTS_ONHOLD'),
  evals: ajaxReducer('REPORTS_EVALS'),
  opentimes: ajaxReducer('REPORTS_OPENTIMES'),
  scheduletemplates: ajaxReducer('REPORTS_SCHEDULETEMPLATES'),
  queue: combineReducers({
    list: ajaxReducer('REPORTS_QUEUE_LIST'),
    add: ajaxReducer('REPORTS_QUEUE_ADD'),
    delete: ajaxReducer('REPORTS_QUEUE_DELETE'),
    cancel: ajaxReducer('REPORTS_QUEUE_CANCEL')
  }),
  postedpayments: ajaxReducer('REPORTS_PAYMENTSPOSTED')
});
