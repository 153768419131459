import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';

const columns: Array<ColumnI> = [
  {
    title: 'Date of Service',
    dataIndex: 'dos',
    key: 'dos',
    width: 100,
  },
  {
    title: 'Responsible Payer',
    dataIndex: 'responsible_payer',
    key: 'responsible_payer',
    width: 150,
  },
  {
    title: 'Rendering Provider',
    dataIndex: 'rendering_provider',
    key: 'redering_provider',
    width: 200,
  },
  {
    title: 'Servicing Provider',
    dataIndex: 'servicing_provider',
    key: 'servicing_provider',
    width: 200,
  },
  {
    title: 'Procedure Code',
    dataIndex: 'cpt',
    key: 'cpt',
    width: 100,
  },
  {
    title: 'Units',
    dataIndex: 'units',
    key: 'units',
    width: 75,
  },
  {
    title: 'Location Name',
    dataIndex: 'location',
    key: 'location',
    width: 200,
  },
  {
    title: 'Charge Amount',
    dataIndex: 'charge_amount',
    key: 'charge_amount',
    width: 100,
  },
  {
    title: 'Total Payment',
    dataIndex: 'total_payment',
    key: 'total_payment',
  },
  {
    title: 'Patient DOB',
    dataIndex: 'patient_dob',
    key: 'patient_dob',
    width: 100,
  },
  {
    title: 'Patient Gender',
    dataIndex: 'patient_gender',
    key: 'patient_gender',
    width: 100,
  },
  {
    title: 'Patient Age',
    dataIndex: 'patient_age',
    key: 'patient_age',
    width: 100,
  },
  {
    title: 'Patient ID',
    dataIndex: 'patient_id',
    key: 'patient_id',
    width: 100,
  },
  {
    title: 'Patient HFID',
    dataIndex: 'patient_hfid',
    key: 'patient_hfid',
    width: 100,
  },
  {
    title: 'Patient Name',
    dataIndex: 'patient_name',
    key: 'patient',
    width: 200,
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
];

export default columns;
