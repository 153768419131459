import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Radio, Switch, Select, DatePicker, Table } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import * as scheduler_actions from '../../../../../actions/scheduler';
import * as shared_actions from '../../../../../actions/shared';
import * as emr_actions from '../../../../../actions/emr';
import { safe } from '../../../../../helpers/reduxSelectors';
import Time from '../../../../shared/Time';
import OppositeEnds from '../../../../shared/OppositeEnds';
import IntakeEntryModal from './IntakeEntryModal';

const FIXED_ONE_TIME = 2;

class IntakesModal extends React.Component {

  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
  }

  state = {
    entryDialogVisible: false,
    allow_intakes: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      // Load in the user record that says whether intakes are turned on or off
      // and the templates for this user.
      this.props.getTemplatesList(this.props.activeUserId);
      this.props.userLoad(this.props.activeUserId);
      this.props.getProviderLocations(this.props.activeUserId);
      this.props.getLocationsList();
    }

    if (this.props.userData !== prevProps.userData && this.props.userData.id) {
      this.setState({
        allow_intakes: this.props.userData.allow_intakes,
      });
    }

    if (prevState.entryDialogVisible && !this.state.entryDialogVisible) {
      this.props.getTemplatesList(this.props.activeUserId);
      if (this.props.calendarProvider.id) {
        // This is not actually refreshing the calendar!
        this.props.getProviderCalendar(this.props.calendarProvider.id,1,this.props.active_date);
      }
    }
  }

  handleCancel = () => {
    this.props.onClose();
  }

  getTemplateItemList = () => {
    const resultList = [];
    if (this.props.templateListData && this.props.templateListData.length > 0) {
      this.props.templateListData.forEach(template => {
        if (moment(template.end_date).isAfter(moment()) && template.items && template.items.length > 0) {
          template.items.forEach(item => {
            if (item.type === FIXED_ONE_TIME && item.fixed_date &&
              !item.allow_20m && !item.allow_30m && !item.allow_45m && !item.allow_60m && item.allow_intake) {
              resultList.push({
                id: item.id,
                template_id: item.template_id,
                location_id: item.location_id,
                fixed_date: item.fixed_date,
                age_category: item.age_category,
                start_time: item.start_time,
                end_time: item.end_time,
                duration: item.duration,
              });
            }
          });
        }
      });
    }
    return resultList;
  }

  removeItem = async (id) => {
    await this.props.intakesDeleteIntakeTime(id);
    this.props.getTemplatesList(this.props.activeUserId);
    if (this.props.calendarProvider.id) {
      // This is not actually refreshing the calendar!
      this.props.getProviderCalendar(this.props.calendarProvider.id,1,this.props.active_date);
    }
  }

  setAvailability = (value) => {
    this.setState({
      allow_intakes: value
    });
    this.props.intakesSetAvailability(value);
  }

// ?Location,
  displayItems = (list) => {
    const columns = [{
      title: 'Date',
      align: 'left',
      dataIndex: 'fixed_date',
      render: (value) => {
        return (value) ? moment(value).format('MM/DD/YYYY') : '';
      }
    },{
      title: 'Start Time',
      align: 'right',
      dataIndex: 'start_time',
      render: (value) => {
        return (value) ? new Time(value).toString(false) : '';
      }

    },{
      title: 'Duration (min)',
      align: 'right',
      dataIndex: 'duration',
    },{
      title: 'Patients',
      dataIndex: 'age_category',
      render: (value) => {
        switch (value) {
          case 0: return 'Adult/Child';
          case 1: return 'Adult Only';
          case 2: return 'Child Only';
        }
      }
    }, {
      title: 'Action',
      align: 'right',
      dataIndex: 'id',
      render: (value,record) => <Button type="link" icon={<DeleteOutlined />} onClick={() => this.removeItem(record.id)}/>
    }];

    if (list && list.length > 0) {
      return (
        <Table
          dataSource={list}
          columns={columns}
          size="small"
          rowKey="id"
          style={{marginTop: '20px'}}
          pagination={false}
        />
      );
    }

    return (
      <div style={{paddingTop: '20px'}}>
        No additional intake availability times stored
      </div>
    );
  }

  closeIntakeEntryModal = () => {
    this.setState({
      entryDialogVisible: false
    });
    this.props.getTemplatesList(this.props.activeUserId);
  }

  render() {
    return (
      <>
        <Modal
          title='Additional Intake Availability'
          visible={this.props.visible}
          onCancel={this.handleCancel}
          footer={null}
          width={600}
          centered={true}
        >
          <div>
            <Button type="primary" icon={<PlusOutlined />}
              onClick={()=>this.setState({entryDialogVisible:true})}>
              Add New Intake Time
            </Button>

            { this.displayItems(this.getTemplateItemList()) }

          </div>
        </Modal>
        <IntakeEntryModal visible={this.state.entryDialogVisible} onClose={this.closeIntakeEntryModal} />
      </>
    );
  }
}
/*
<OppositeEnds left={
  <span><Switch
    checkedChildren={<Icon type="check" />}
    unCheckedChildren={<Icon type="close" />}
    checked={this.state.allow_intakes}
    onChange={this.setAvailability}
  />
  &nbsp; Intakes Turned On/Off</span>
} right={<Button type="primary" icon="plus"
  onClick={()=>this.setState({entryDialogVisible:true})}>
    Add New Intake Time
  </Button>} />
 */

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'scheduler.template.list.loading',false) ||
      safe(state,'shared.user.load.loading',false)
    ) ? true : false,
    templateListData: safe(state,'scheduler.template.list.data',[]),
    userData: safe(state,'shared.user.load.data',{}),
    activeUserId: safe(state,'shared.domain.activeUser.id'),
    allLocationsList: safe(state,'shared.location.list.data',[]),
    intakesRemoveEvent: safe(state,'scheduler.provider_intakes.removeItem',{}),

    active_date: safe(state,'emr.emr.active_date',moment().format('YYYY-MM-DD')),
    calendarProvider: safe(state,'emr.emr.calendarProvider',{}),
  }
}

export default connect(mapStateToProps,{...scheduler_actions, ...shared_actions, ...emr_actions})(IntakesModal);
