import React from 'react';
import PropTypes from 'prop-types';
import { InboxOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { connect } from 'react-redux';

import { attachments } from '../../../../../../helpers/attachments';
import * as emr_actions from '../../../../../../actions/emr';
import * as shared_actions from '../../../../../../actions/shared';
import { safe } from '../../../../../../helpers/reduxSelectors';

class AttachmentList extends React.Component {
  static propTypes = {
    list: PropTypes.array
  }

  componentDidUpdate(prevProps) {
    if (prevProps.doctransfer2 !== this.props.doctransfer2) {
      if (this.props.doctransfer2.success) {
        message.success('Attachment added to patient document box');
        this.props.transferAttachmentToDocumentsClear();
      } else if (this.props.doctransfer2.error) {
        message.error('Unable to add note attachment to document box');
      }
    }
  }

  getAttachmentName = (x) => {
    const y = attachments.find(a=>a.id===x);
    return (y === undefined) ? '-' : y.title;
  }

  addToDocumentBox = (id) => {
    this.props.transferAttachmentToDocuments(this.props.patientData.id,id);
  }

  renderList = () => {
    return this.props.list.map(x => {
      return (
        <div key={x.id} style={{paddingLeft:'10px'}}>
          <a target="_blank" href={'/attachments/'+x.filename}>
            <PaperClipOutlined /> {x.title}
            &nbsp; ({this.getAttachmentName(parseInt(x.type,10))})
          </a>
          &nbsp;<Button size='small' onClick={() => {this.addToDocumentBox(x.id)}}><InboxOutlined /></Button>
        </div>
      );
    });
  }

  render() {
    return (
      <div style={{paddingTop:'10px'}}>
        { this.renderList() }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    patientData: safe(state,'shared.patient.load.data',{}),
    doctransfer2: safe(state,'emr.documents.transfer2',{}),
  }
}

export default connect(mapStateToProps,{...emr_actions,...shared_actions})(AttachmentList);
