import React from 'react';
import { connect } from 'react-redux';

import * as emr_actions from '../../../../../actions/emr';
import * as shared_actions from '../../../../../actions/shared';
import { safe } from '../../../../../helpers/reduxSelectors';

class InboxSync extends React.Component {
  state = {
    timer: null
  }

  componentDidMount() {
    this.props.getInbox(this.props.userId);
    this.props.getGroupsList();
    this.startTimer();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.thread !== this.props.thread) {
      this.clearTimer();
      this.retrieveThreadsAndMessages();
      this.startTimer();
    }

    if (this.isEmpty(this.props.currentInbox) && this.props.inbox.length > 0) {
      this.props.setCurrentInbox(this.props.inbox[0].member);
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }


  isEmpty = (x) => {
    return (Object.keys(x).length === 0);
  }

  getInboxLastAdded = () => {
    if (this.props.inbox && this.props.inbox.length > 0) {
      return safe(this.props.inbox[0],'lastAddedAt',null);
    }
    return null;
  }

  getMessageListLastUpdated = () => {
    if (this.props.messages && this.props.messages.length > 0) {
      return safe(this.props.messages[0],'lastUpdatedAt',null);
    }
    return null;
  }

  retrieveThreadsAndMessages = () => {
    this.props.getInbox(this.props.userId);
    if (this.props.thread) {
      this.props.getInboxMessages(this.props.thread.id,this.props.currentInbox,this.getMessageListLastUpdated());
    }
  }

  startTimer = () => {
    this.setState({
      timer: setInterval(()=>{
        this.retrieveThreadsAndMessages();
      },30000)
    });
  }

  clearTimer = () => {
    if (this.state.timer) {
      clearInterval(this.state.timer);
      this.setState({
        timer: null
      });
    }
  }

  render() {
    return null;
  }

}

const mapStateToProps = (state,ownProps) => {
  return {
    inbox: safe(state,'emr.inbox.list.data',[]),
    messages: safe(state,'emr.inbox.messages.data',[]),
    currentInbox: safe(state,'emr.inbox.current'),
  }
}

export default connect(mapStateToProps,{...emr_actions,...shared_actions})(InboxSync);
