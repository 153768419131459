import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Spin, Button, Tag, Checkbox, Switch, Radio, Divider, Input } from 'antd';

import * as actions from '../../../actions/settings';
import { safe } from '../../../helpers/reduxSelectors';
import { processEventNotification } from '../../../helpers/util';

class Portal extends Component {

  componentDidMount() {
    this.props.loadPortalSettings();
  }

  componentDidUpdate(prevProps) {
    processEventNotification(prevProps,this.props,'saveEvent',
      'Portal Alert Settings Saved','Unable to Save Portal Alert Settings');
  }

  renderNotificationSettings = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const data = this.props.data;
    const radioStyle = {
      display: 'block',
    };

    if (!this.props.form.getFieldValue('portal_alerts')) {
      return null;
    }

    return (
      <div>
        <FormItem label='Type'>
          {getFieldDecorator('alerts_type', {
            initialValue: data.alerts_type
          })(
            <Radio.Group>
              <Radio value='success'><Tag color='green'>Good News</Tag></Radio>
              <Radio value='info'><Tag color='blue'>Informational</Tag></Radio>
              <Radio value='warning'><Tag color='gold'>Warning</Tag></Radio>
              <Radio value='error'><Tag color='red'>Attention</Tag></Radio>
            </Radio.Group>
          )}
        </FormItem>

        <FormItem label='Alert Text'>
          {getFieldDecorator('alerts_text', {
            initialValue: data.alerts_text
          })(
            <Input.TextArea rows={4}/>
          )}
        </FormItem>

        <FormItem label='Display on Pages'>
          {getFieldDecorator('alerts_pages', {
            initialValue: data.alerts_pages
          })(
            <Checkbox.Group>
              <div><Checkbox value={1}>General Search Page</Checkbox></div>
              <div><Checkbox style={radioStyle} value={2}>Portal Home Page</Checkbox></div>
              <div><Checkbox style={radioStyle} value={3}>Portal Scheduling Page</Checkbox></div>
            </Checkbox.Group>
          )}
        </FormItem>
      </div>
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.savePortalSettings(values);
      }
    });
  }

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;

    return (
      <Spin spinning={this.props.isBusy} size='large'>
        <div style={{padding:'10px'}}>
          <h3>Patient Portal Settings</h3>
          <Divider />
          <h4>Patient Portal General Alerts</h4>
          <p>When activated the portal alert message below will appear on the
          selected pages in the patient portal.</p>
          <Form onSubmit={this.handleSubmit} autoComplete="off" >
            <FormItem label="Portal Alerts">
              {getFieldDecorator('portal_alerts', {
                initialValue: this.props.data.portal_alerts,
                valuePropName: 'checked'
              })(
                <Switch />
              )}
            </FormItem>

            { this.renderNotificationSettings() }

            <div style={{textAlign:'center'}}>
              <Button type='primary' htmlType='submit'>Save</Button>
            </div>
          </Form>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: safe(state,'settings.portal.load.data',{}),
    saveEvent: safe(state,'settings.portal.save'),
    isBusy: (
      safe(state,'settings.portal.load.processing') ||
      safe(state,'settings.portal.save.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(Form.create()(Portal));
