import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';
import { CloseOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Table, Spin, Button, Input, DatePicker, InputNumber, Checkbox, Select } from 'antd';
import { withRouter, Link } from 'react-router-dom';

import FormattedCell from '../../../shared/FormattedCell';

import { safe } from '../../../../helpers/reduxSelectors';
import { paymentMethods } from '../../../../helpers/paymentMethods';
import * as billing_actions from '../../../../actions/billing';
import * as shared_actions from '../../../../actions/shared';

class InsPayments extends React.Component {

  componentDidMount() {
    this.props.getPayerList();
    this.props.getUserList();
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.payments_tab !== this.props.payments_tab ||
        prevProps.payments_ins_page !== this.props.payments_ins_page) {
      this.reload();
    }
  }

  gatherSearchParams = () => {
    return {
      era_search_check_date: (this.props.era_search_check_date) ? moment(this.props.era_search_check_date,'MM/DD/YYYY').format('MM/DD/YYYY') : undefined,
      era_search_check_num: this.props.era_search_check_num,
      era_search_amount: this.props.era_search_amount,
      era_search_payer_name: this.props.era_search_payer_name,
      era_type: this.props.era_type
    };
  }

  reload = () => {
    this.props.getInsPaymentList(this.props.payments_ins_page,
      this.props.payments_pagesize,{
      search_payment_id: this.props.search_payment_id,
      search_creator_id: this.props.search_creator_id,
      search_check_date: (this.props.search_check_date) ? moment(this.props.search_check_date,'MM/DD/YYYY').format('MM/DD/YYYY') : undefined,
      search_posted_date: (this.props.search_posted_date) ? moment(this.props.search_posted_date,'MM/DD/YYYY').format('MM/DD/YYYY') : undefined,
      search_check_num: this.props.search_check_num,
      search_payment_type: this.props.search_payment_type,
      search_amount: this.props.search_amount,
      search_payer_id: this.props.search_payer_id,
      search_unallocated_amount: this.props.search_unallocated_amount,
      search_claim_num: this.props.search_claim_num
    });
  }

  resetSearchbar = () => {
    const page = this.props.payments_ins_page;
    this.props.setBillingState({
      search_payment_id: undefined,
      search_creator_id: undefined,
      search_check_date: undefined,
      search_posted_date: undefined,
      search_check_num: undefined,
      search_payment_type: undefined,
      search_amount: undefined,
      search_payer_id: undefined,
      search_unallocated_amount: undefined,
      search_claim_num: undefined,
      payments_ins_page:1
    });
    if (page === 1) {
      this.props.getInsPaymentList(1,this.props.payments_pagesize,{});
    }
  }

  search = () => {
    if (this.props.payments_ins_page === 1) {
      this.reload();
    } else {
      // Setting the page back to 1 causes it to automatically reload unless
      // it's already at 1!
      this.props.setBillingState({
        payments_ins_page: 1
      });
    }
  }

  tableChange = ({current}) => {
    // This will automatically trigger a reload from componentDidUpdate
    this.props.setBillingState({
      payments_ins_page: current
    });
  }

  deletePayment = (id) => {
    this.props.deletePayment(id,this.props.payments_ins_page,
      this.props.payments_pagesize,this.gatherSearchParams());
  }

  newPayment = () => {
    this.props.history.push('/billing/payments/1/0');
  }

  renderSearchBar = () => {
    const userList = this.props.userList.filter(x=>{
      return (x.roles.includes('staff') || x.roles.includes('staffsupervisor') ||
        x.roles.includes('superuser')) ? true : false;
    }).map(z=>{
      return {
        id: z.id,
        name: z.initials
      }
    });

    const Option = Select.Option;

    const columns = [{
      title: 'Payment ID',
      dataIndex: 'id',
      align: 'left',
      width: 100,
      render: (value,record) => {
        return (
          <Input
            style={{width:'100%'}}
            placeholder='Pay ID'
            value={this.props.search_payment_id}
            onChange={({target})=>{this.props.setBillingState({search_payment_id:target.value})}}
          />
        );
      }
    },{
      title: 'Creator',
      dataIndex: 'creator_id',
      align: 'left',
      width: 80,
      render: (value,record) => {
        return (
          <Select
            style={{width:'100%'}}
            value={this.props.search_creator_id}
            placeholder='人'
            onChange={(value)=>{this.props.setBillingState({search_creator_id:value})}}
          >
            {userList.map(x => <Option value={x.id} key={x.id}>{x.name}</Option>)}
          </Select>
        );
      }
    },{
      title: 'Date Posted',
      dataIndex: 'posted_date',
      align: 'left',
      width: 175,
      render: (value,record) => {
        return (
          <DatePicker
            style={{width:'100%'}}
            placeholder='Date Posted'
            format='MM/DD/YYYY'
            value={this.props.search_posted_date}
            onChange={(value)=>{this.props.setBillingState({search_posted_date:value})}}
          />
        );
      }
    },{
      title: 'Date Paid',
      dataIndex: 'check_date',
      align:'left',
      width: 150,
      render: (value,record) => {
        return (
          <DatePicker
            style={{width:'100%'}}
            placeholder='Date Paid'
            format='MM/DD/YYYY'
            value={this.props.search_check_date}
            onChange={(value)=>{this.props.setBillingState({search_check_date:value})}}
          />
        );
      }
    },{
      title: 'Check #',
      dataIndex: 'check_num',
      width: 175,
      render: (value,record) => {
        return (
          <Input
            value={this.props.search_check_num}
            placeholder='Check #'
            onChange={({target})=>{this.props.setBillingState({search_check_num:target.value})}}
            style={{width:'100%'}}
          />
        );
      }
    },{
      title: 'Claim #',
      dataIndex: 'claim_num',
      width: 125,
      render: (value,record) => {
        return (
          <Input
            value={this.props.search_claim_num}
            placeholder='Claim #'
            onChange={({target})=>{this.props.setBillingState({search_claim_num:target.value})}}
            style={{width:'100%'}}
          />
        )
      }
    },{
      title: 'Payment Type',
      dataIndex: 'payment_type',
      width: 130,
      render: (value,record) => {
        return (
          <Select
            style={{width:'100%'}}
            placeholder='Type'
            value={this.props.search_payment_type}
            onChange={(value)=>{this.props.setBillingState({search_payment_type:value})}}
          >
            {paymentMethods.map(x=><Option value={x.id} key={x.id}>{x.name}</Option>)}
          </Select>
        );
      }
    },{
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      align: 'right',
      render: (value,record) => {
        return (
          <InputNumber
            style={{width:'100%'}}
            placeholder='Amount'
            value={this.props.search_amount}
            onChange={(value)=>{this.props.setBillingState({search_amount:value})}}
          />
        );
      }
    },{
      title: 'Unallocated',
      dataIndex: 'unallocated_amount',
      width: 100,
      align: 'right',
      render: (value,record) => {
        return (
          <Checkbox
            checked={this.props.search_unallocated_amount}
            onChange={({target})=>{this.props.setBillingState({search_unallocated_amount:target.checked})}}
          />
        );
      }
    },{
      title: 'Payer',
      dataIndex: 'payer_id',
      align: 'left',
      render: (value,record) => {
        return (
          <Select
            style={{width:'100%'}}
            value={this.props.search_payer_id}
            placeholder='Payer'
            onChange={(value)=>{this.props.setBillingState({search_payer_id:value})}}
          >
            { this.props.payerList.map(x=><Option value={x.id} key={x.id}>{x.name}</Option>) }
          </Select>
        );
      }
    },{
      dataIndex: 'deleted',
      align: 'right',
      width: 60,
      render: (value,record) => {
        return (
          <span style={{width:'100%',whiteSpace:'nowrap'}}>
            <Button onClick={this.resetSearchbar}><CloseOutlined /></Button>&nbsp;
            <Button onClick={this.search} type='primary'><SearchOutlined /></Button>&nbsp;
            <Button onClick={this.newPayment}><PlusOutlined /></Button>
          </span>
        );
      }
    }];

    return (
      <Table
        bordered={false}
        rowKey='id'
        columns={columns}
        size='small'
        dataSource={[{}]}
        pagination={false}
        showHeader={false}
        style={{minWidth:'1300px',marginBottom:'10px'}}
        className='table-nohighlight'
      />
    );
  }

  render() {
    const columns = [{
      title: 'Payment ID',
      dataIndex: 'id',
      align: 'left',
      width: 100,
      render: (value,record) => {
        return <Link to={'/billing/payments/1/'+value}>{numeral(value).format('000000')}</Link>;
      }
    },{
      title: 'Creator',
      dataIndex: 'creator_id',
      align: 'left',
      width: 80,
      render: (value,record) => {
        return safe(record,'creator.initials','-');
      }
    },{
      title: 'Date Posted',
      dataIndex: 'posted_date',
      align: 'left',
      width: 175,
      render: (value,record) => {
        return moment(value).format('MM/DD/YYYY HH:mm:ss');
      }
    },{
      title: 'Date Paid',
      dataIndex: 'check_date',
      align:'left',
      width: 150,
      render: (value,record) => {
        return moment(value).format('M/DD/YYYY');
      }
    },{
      title: 'Check #',
      dataIndex: 'check_num',
      width: 175
    },{
      title: 'ERA',
      dataIndex: 'era_id',
      width: 125,
      render: (value,record) => {
        if (value) {
          return <Link to={'/billing/era/reconcile/'+value}>View</Link>
        }
        return null;
      }
    },{
      title: 'Payment Type',
      dataIndex: 'payment_type',
      width: 130,
      render: (value,record) => {
        const r = paymentMethods.find(x=>x.id===value);
        return (r) ? r.name : null;
      }
    },{
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      align: 'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00');
      }
    },{
      title: 'Unallocated',
      dataIndex: 'unallocated_amount',
      width: 100,
      align: 'right',
      render: (value,record) => {
        if (!record.deleted) {
          return <FormattedCell value={value} blankonzero={false}/>;
        }
        return 'N/A';
      }
    },{
      title: 'Payer',
      dataIndex: 'payer_id',
      align: 'left',
      render: (value,record) => {
        return safe(record,'billing_payers.carrier.name','') + ' / ' + safe(record,'billing_payers.name','');
      }
    },{
      dataIndex: 'deleted',
      align: 'right',
      width: 60,
      render: (value,record) => {
        if (!record.deleted) {
          return <a onClick={()=>this.deletePayment(record.id)}><DeleteOutlined style={{fontSize:'14pt'}} /></a>;
        }
      }
    }];

    const data = safe(this.props,'currentPaymentList.rows',[]);
    const count = safe(this.props,'currentPaymentList.count',0);


    return (
      <Spin spinning={this.props.isBusy}>
        <div style={{overflow:'auto'}}>

          { this.renderSearchBar() }

          <Table
            bordered={false}
            rowKey='id'
            rowClassName={(record,index) => {
              if (record.deleted) {
                return 'cancelrow';
              }
              return null;
            }}
            columns={columns}
            onChange={this.tableChange}
            pagination={{
              current: this.props.payments_ins_page,
              pageSize: this.props.payments_pagesize,
              total: count,
              showQuickJumper: true
            }}
            dataSource={data}
            size='small'
            style={{minWidth:'1300px'}}
          />
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.inspayments.list.processing') ||
      safe(state,'billing.inspayments.delete.processing')
    ) ? true : false,
    payments_tab: safe(state,'shared.domain.billing.payments_tab',"1"),

    currentPaymentList: safe(state,'billing.inspayments.list.data',{}),
    payments_ins_page: safe(state,'shared.domain.billing.payments_ins_page',1),
    payments_pagesize: safe(state,'shared.domain.billing.payments_pagesize',50),

    payerList: safe(state,'billing.payer.list.data',[]),
    userList: safe(state,'shared.user.list.data',[]),

    search_payment_id: safe(state,'shared.domain.billing.search_payment_id'),
    search_creator_id: safe(state,'shared.domain.billing.search_creator_id'),
    search_check_date: safe(state,'shared.domain.billing.search_check_date'),
    search_posted_date: safe(state,'shared.domain.billing.search_posted_date'),
    search_check_num: safe(state,'shared.domain.billing.search_check_num'),
    search_payment_type: safe(state,'shared.domain.billing.search_payment_type'),
    search_amount: safe(state,'shared.domain.billing.search_amount'),
    search_payer_id: safe(state,'shared.domain.billing.search_payer_id'),
    search_unallocated_amount: safe(state,'shared.domain.billing.search_unallocated_amount'),
    search_claim_num: safe(state,'shared.domain.billing.search_claim_num')
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(withRouter(InsPayments));
