import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import moment from 'moment';

const columns: Array<ColumnI> = [
  {
    title: 'Patient ID',
    dataIndex: 'patient_id',
    key: 'id',
  },
  {
    title: 'Patient',
    dataIndex: 'patient_name',
    key: 'patient',
  },
  {
    title: 'DOS',
    dataIndex: 'dos',
    key: 'dos',
    render: (val: string): string => {
      const m = moment(val);
      return m.isValid() ? m.format('MM/DD/YYYY') : '';
    },
  },
];

export default columns;
