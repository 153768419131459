/**
 * formItemRules.ts
 * This file contains all the possible form item validation rules.
 * The messages contained within will display to the user when
 * form validation fails for that item.
 */
export default {
  reportId: [{ required: true, message: 'Report ID is required' }],
  page: [{ required: false, message: 'Page is optional' }],
  pageSize: [{ required: false, message: 'Page size is optional' }],
  // SCHEDULE PARAMS
  isRecurring: [{ required: true, message: 'Select frequency' }],
  type: [{ required: true, message: 'Select recurring type' }],
  subType: {
    week: [{ required: true, message: 'Select day of week' }],
    month: [{ required: true, message: 'Select day of month' }],
  },
  dayOfMonth: [{ required: true, message: 'Enter day number' }],
  showBeginReporting: [{ required: true, message: 'Select report beginning' }],
  showEndReporting: [{ required: true, message: 'Select report ending' }],
  scheduleStartDate: [{ required: true, message: 'Select start date & time' }],
  scheduleEndDate: [{ required: true, message: 'Select end date & time' }],
  // REPORT PARAMS
  dateType: [{ required: true, message: 'Select date type' }],
  startDate: [{ required: true, message: 'Select start date' }],
  endDate: [{ required: true, message: 'Select end date' }],
  startDateType: [{ required: true, message: 'Select start date type' }],
  endDateType: [{ required: true, message: 'Select end date type' }],
  yearMonth: [{ required: true, message: 'Select a month/year' }],
  payPeriod: [{ required: true, message: 'Select a pay period' }],
};
