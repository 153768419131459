import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload, Modal, message } from 'antd';

class UploadFilesDialog extends React.Component {

  onCancel = () => {
    this.props.closeDialog(true);
  }

  onOk = () => {
    this.props.closeDialog(true);
  }

  render() {
    const Dragger = Upload.Dragger;

    const props = {
      name: 'file',
      multiple: true,
      action: '/api/v1/clearinghouse',
      onChange(info) {
        const status = info.file.status;
        if (status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (status === 'done') {
          //message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    };

    return (
      <Modal
        title='Upload Clearinghouse Responses'
        destroyOnClose={true}
        visible={this.props.visible}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='Submit'
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Dragger>
      </Modal>
    );
  }
}

export default UploadFilesDialog;
