/* eslint-disable react/display-name */
/**
 * Notes.tsx
 */
import { useEffect, useState } from 'react';
import { Input, Select, Spin } from 'antd';
import useFetch, { CachePolicies } from 'use-http';
import moment from 'moment';
import get from 'lodash/get';

const Option = Select.Option;

interface PropsI {
  active: boolean;
  reload?: boolean;
}

const DirectorNotes: React.FC<PropsI> = ({ active }: PropsI) => {
  const [comment, setComment] = useState('');
  const [supervisorId, setSupervisorId] = useState<undefined | number>(undefined);
  const directorListAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const directorCommentAddAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const supervisorCommentsAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  
  const isBusy = directorListAPI?.loading;

  const getDirectorList = () => directorListAPI.get(`/api/v1/director`);
  const getSupervisorComments = () => supervisorCommentsAPI.get(`/api/v2/director/comment/${supervisorId}`);
  const addDirectorComment = () => directorCommentAddAPI.post(`/api/v2/director/comment/${supervisorId}`, { comment });

  useEffect(() => {
    if (active) {
      setComment('');
      setSupervisorId(undefined);
      getDirectorList();
    }
  }, [active]);
  
  useEffect(() => {
    if (supervisorId !== undefined) {
      getSupervisorComments();
    }
  }, [supervisorId]);
  
  const onSelectProvider = async (id) => {
    setSupervisorId(id);
  };

  const addComment = async () => {
    await addDirectorComment();
    getSupervisorComments();
    setComment('');
  };

  const onChangeComment = (event) => {
    setComment(event.target.value);
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const displayDateTime = (x) => {
    return moment(x).format('MM/DD/YYYY hh:mm:ss A');
  }

  if (directorListAPI.response && !directorListAPI?.data?.length) {
    return (
      <div>
        No providers have been added to your list.
      </div>
    )
  }

  return (
    <Spin spinning={isBusy}>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '2rem' }}>
          <Select
            showSearch
            style={{ width: '300px' }}
            placeholder="Select a provider"
            optionFilterProp="children"
            onSelect={onSelectProvider}
            value={supervisorId}
            filterOption={(input, option) => {
              return option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }
            }
          >
            {directorListAPI?.data?.map((x) => {
              return <Option key={x.id} value={x.id}>{x.name}</Option>;
            })}
          </Select>
          <br />
          <Input.TextArea
            onPressEnter={addComment}
            onChange={onChangeComment}
            onKeyPress={onKeyPress}
            style={{marginTop:'5px', width: '400px'}}
            rows={2}
            placeholder='Enter comment'
            value={comment}
            name='comment'
          />
        </div>
        <div className='comment-list' style={{
          width: '600px',
          borderLeft: '1px solid lightgray',
          padding: '1rem',
          minHeight: '75vh'
        }}>
          {supervisorCommentsAPI?.data?.map((x) => (
            <div key={x.id} className='comment-entry'>
            <div className='comment-byline'>
              {get(x,'creator.full_name')},&nbsp;
              {displayDateTime(x.createdAt)}
            </div>
            <div className='comment-text'>{x.comment}</div>
          </div>
          ))}
        </div>
      </div>
    </Spin>
  );
};

export default DirectorNotes;
