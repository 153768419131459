import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table, Spin, Select } from 'antd';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';

class ReportsPostPayments extends React.Component {
  state = {
    months: [],
    selection: undefined
  }

  componentDidMount() {
    const months = this.getMonthsList();
    this.setState({
      months: months,
      selection: (months && months.length > 0) ? 0 : undefined
    });
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.state.selection !== undefined && prevState.selection !== this.state.selection) {
      const item = this.state.months[this.state.selection];
      this.props.reportsPaymentsPostedCount(item.year,item.month);
    }
  }

  getMonthsList = () => {
    let month = moment().month();
    let year = moment().year();
    const list = [];
    while (year*100 + month >= 202001) {
      list.push({year,month});
      if (--month < 1) {
        month = 12;
        year--;
      }
    }
    return list;
  }

  onSelectChange = (val) => {
    this.setState({
      selection: val
    });
  }

  reportForm = () => {
    return (
      <div style={{paddingBottom:'20px'}}>
        Month: <Select onChange={this.onSelectChange}
        value={this.state.selection} style={{width:'250px'}}>
          { this.state.months.map((x,i)=>(
              <Select.Option key={i} value={i}>
                {x.year}/{x.month}
              </Select.Option>
          )) }
        </Select>
      </div>
    );
  }

  render() {
    const columns = [{
      title: 'User',
      dataIndex: 'creator',
    },{
      title: 'Payments Posted',
      dataIndex: 'total'
    }];

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
          </div>

          <h3>Posted Payments Count</h3>
          { this.reportForm() }

          <Table
            className='reports-sessionlist-table'
            columns={columns}
            dataSource={this.props.list}
            bordered={true}
            size='small'
            rowKey='creator_id'
            scroll={{ x:true }}
            pagination={false}
          />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'reports.postedpayments.processing',false),
    list: safe(state,'reports.postedpayments.data',[]),
  }
}

export default connect(mapStateToProps,actions)(ReportsPostPayments);
