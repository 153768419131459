import { Dispatch, SetStateAction } from 'react';
import { PostReturnI } from 'hooks/definitions';
import { FormInstance } from 'antd';
import { createContext } from 'use-context-selector';

export type DoQueueReportT = () => void;

export interface QueueContextI {
  form?: FormInstance<any>;
  queueReportAPI: PostReturnI | undefined;
  isRecurring?: boolean;
  isModalVisible?: boolean;
  type?: number;
  subType?: number;
  dayOfMonth?: number;
  showNthDayOfMonth?: boolean;
}

type SetStateT = Dispatch<SetStateAction<QueueContextI>>;

export interface ContextT {
  state: QueueContextI;
  setState: SetStateT;
  doQueueReport: DoQueueReportT;
}

export const defaultState: QueueContextI = {
  form: undefined,
  queueReportAPI: undefined,
  isRecurring: undefined,
  isModalVisible: undefined,
  type: undefined,
  subType: undefined,
  dayOfMonth: undefined,
  showNthDayOfMonth: undefined,
};

const defaultContext = {
  state: defaultState,
  setState: () => null,
  doQueueReport: () => null,
};

export default createContext<ContextT>(defaultContext);
