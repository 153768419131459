import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { CalendarOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { VLayout, VLayoutHeader, VLayoutContent, HLayout, HLayoutLeft, HLayoutContent } from '../shared/Layout';

import HeaderLogo from '../shared/HeaderLogo';
import AppSelector from '../shared/AppSelector';
import Providers from './Scheduler_/Providers';
import IntakesSalaried from './Scheduler_/Intakes';
import IntakesFFS from './Scheduler_/IntakesFFS';
import TimeOff from './Scheduler_/TimeOff';
import Timeout from '../shared/Timeout';

import { safe } from '../../helpers/reduxSelectors';

class Scheduler extends Component {
  onClickMenu = ({ key }) => {
    switch (key) {
      case 'providers':
        this.props.history.push('/scheduler/providers');
        return;
      case 'intakes-salaried':
        this.props.history.push('/scheduler/intakes-salaried');
        return;
      case 'intakes-ffs':
        this.props.history.push('/scheduler/intakes-ffs');
        return;
      case 'timeoff':
        this.props.history.push('/scheduler/timeoff');
        return;
      default:
        return;
    }
  };

  navMenu = () => {
    let pathname = this.props.location.pathname;
    let folders = pathname.split('/');
    let page = folders[2];

    return (
      <Menu style={{ padding: '0px', borderRight: '0px' }} onClick={this.onClickMenu} selectedKeys={[page]}>
        <Menu.Item key="providers">
          <UserOutlined />
          <span>Providers</span>
        </Menu.Item>

        <Menu.Item key="intakes-salaried">
          <TeamOutlined />
          <span>Intakes-Salaried</span>
        </Menu.Item>

        <Menu.Item key="intakes-ffs">
          <TeamOutlined />
          <span>Intakes-FFS</span>
        </Menu.Item>

        <Menu.Item key="timeoff">
          <CalendarOutlined />
          <span>Time Off</span>
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    const headerStyle = {
      background: '#2b8ed8',
      color: 'white',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '5px',
      height: '50px',
      minHeight: '42px',
    };

    return (
      <Timeout>
        <VLayout>
          <VLayoutHeader style={headerStyle}>
            <HeaderLogo title="PCPA Scheduler" />
            <AppSelector activeUserName={this.props.activeUserName} />
          </VLayoutHeader>

          <VLayoutContent>
            <HLayout>
              <HLayoutLeft
                style={{ width: '200px', minWidth: '200px', borderRight: '1px solid #e8e8e8', overflow: 'auto' }}
              >
                {this.navMenu()}
              </HLayoutLeft>
              <HLayoutContent>
                <Route path="/scheduler/providers" component={Providers} />
                <Route path="/scheduler/intakes-salaried" component={IntakesSalaried} />
                <Route path="/scheduler/intakes-ffs" component={IntakesFFS} />
                <Route path="/scheduler/timeoff" component={TimeOff} />
              </HLayoutContent>
            </HLayout>
          </VLayoutContent>
        </VLayout>
      </Timeout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeUserName: safe(state, 'shared.domain.activeUser.full_name'),
  };
};

export default connect(mapStateToProps, null)(withRouter(Scheduler));
