/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DownOutlined, EditTwoTone, PaperClipOutlined, RightOutlined } from '@ant-design/icons';
import { Select, Spin, Tag } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';

import { attachments } from '../../../../../helpers/attachments';

import GeneralChartNote from './ChartNotes_/GeneralChartNote';
import LabsChartNote from './ChartNotes_/LabsChartNote';
import PrescriberProgressNote from './ChartNotes_/PrescriberProgressNote';
import TemplateLetterChartNote from './ChartNotes_/TemplateLetterChartNote';
import TherapistProgressNote from './ChartNotes_/TherapistProgressNote';
import AdminTimeNote from './ChartNotes_/AdminTimeNote';
import HFNote from './ChartNotes_/HFNote';
import UnknownChartNote from './ChartNotes_/UnknownChartNote';
import IntakeNote from './ChartNotes_/IntakeNote';
import ConsentsNote from './ChartNotes_/ConsentsNote';
import NoteHide from './NoteHide';
import renderNoteType from './NoteHide_/renderNoteType';

import * as actions from '../../../../../actions/emr';
import { safe, isStaffSupervisor } from '../../../../../helpers/reduxSelectors';
import Time from '../../../../shared/Time'

const diffHrs = Time.getTzOffset();
const CHART_NOTE_EXPANDED = 7;

class ChartNotesTable extends Component {
  static defaultProps = {
    print: false
  };

  static propTypes = {
    noteList: PropTypes.array.isRequired,
    userId: PropTypes.number.isRequired,
    print: PropTypes.bool
  };

  state = {
    selectedRow: null
  }

  renderStatus = (x) => {
    switch (x) {
      case 1: return <span style={{color:'green'}}>Show</span>;
      case 2: return <span style={{color:'red'}}>No Show</span>;
      case 3: return <span style={{color:'red'}}>Late Cancel</span>;
      default: return '';
    }
  }

  getAttachmentName = (x) => {
    const y = attachments.find(a=>a.id===x);
    return (y === undefined) ? '-' : y.title;
  }

  transferAtoD = (event,id) => {
    event.stopPropagation();
    this.props.transferAttachmentToDocuments(this.props.patientId,id);
  }

  viewAttachment = (event,filename) => {
    event.stopPropagation();
    window.open('/attachments/'+filename,'_blank');
  }

  renderAttachments = (x) => {
    if (x.length === 0) {
      return null;
    }

    return (
      <span>
        <PaperClipOutlined />
        {x.map(y=>y.title + ' ('+this.getAttachmentName(parseInt(y.type,10)) +')').join(', ') }
      </span>
    );

    /*
    } else if (x.length === 1) {
      return (
        <span>
          <Button size='small' type='link' onClick={(e) => this.viewAttachment(e,x[0].filename)}>
            <Icon type='paper-clip'/> {x[0].title}
            &nbsp; ({this.getAttachmentName(parseInt(x[0].type,10))})
          </Button>
          &nbsp;<Button size='small' onClick={(e) => {this.transferAtoD(e,x[0].id)}}><Icon type='inbox'/></Button>
        </span>
      );
    } else {
      return 'Multiple attachments...'
    }
    */
  }

  selectRow = (id) => {
    const selectedRow = (id === this.state.selectedRow) ? null : id;
    this.setState({ selectedRow });
    if (selectedRow) {
      this.props.createChartAuditLogRecord({
        patientId: this.props.patientId,
        type: CHART_NOTE_EXPANDED,
        noteId: selectedRow
      });
    }
  }

  isSelectedClassName = (id) => {
    return (id === this.state.selectedRow) ? 'selected-row' : 'normal-row';
  }

  renderNoteContent = (x) => {
    switch (x.type) {
      case 1: return <TherapistProgressNote data={x} userId={this.props.userId} print={this.props.print}/>;
      case 2: return <PrescriberProgressNote data={x} userId={this.props.userId} print={this.props.print}/>;
      case 3: return <GeneralChartNote data={x} userId={this.props.userId} print={this.props.print}/>;
      case 4: return <AdminTimeNote data={x} userId={this.props.userId} print={this.props.print}/>;
      case 5: return <LabsChartNote data={x} print={this.props.print} />;
      case 6: return <TemplateLetterChartNote data={x} print={this.props.print}/>;
      case 7: return <HFNote data={x} print={this.props.print} />
      case 8: return <IntakeNote data={x} print={this.props.print} />
      case 9: return <ConsentsNote data={x} print={this.props.print} />
      default: return <UnknownChartNote print={this.props.print}/>;
    }
  }

  renderNote = (x) => {
    return (
      <tr key={x.id + 'A'} className='expanded-note' style={{borderBottom: '1px solid #ddd'}}>
        <td colSpan='9'>
          <div style={{marginLeft:'10px'}}>
            { this.renderNoteContent(x) }
          </div>
          {this.props.isStaffSupervisor && (
            <div className="tar">
            {x.signedAt && (
              <NoteHide note={x} />
            )}
          </div>
          )}
        </td>
      </tr>
    )
  }

  renderType = (x) => {
    if (x === 1) {
      return 'Intake';
    } else if (x === 2) {
      return 'Followup';
    }
    return null;
  }

  renderLine = (x,bottomLine=true) => {
    const style = (bottomLine) ? { borderBottom: '1px solid #ddd'} : { borderBottom: '0px solid white' };
    const typeStyle = (!x.signedAt && x.provider_id === this.props.userId) ? {color:'#1890FF',fontWeight:'bold'} : {};
    return (
      <tr key={x.id} onClick={()=>{this.selectRow(x.id)}} className={this.isSelectedClassName(x.id)} style={style}>
        <td style={{width:'15px'}}>
          { (x.id === this.state.selectedRow) ? <DownOutlined /> : <RightOutlined /> }
        </td>
        <td style={{width:'100px'}}>{moment(x.note_date).tz('America/Los_Angeles').format('MM/DD/YYYY')}</td>
        <td style={{width:'40px'}}>
          { (!x.signedAt && x.provider_id === this.props.userId) ? <EditTwoTone twoToneColor="#1890FF" /> : ''}
        </td>
        <td style={{maxWidth:'200px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
          <span style={typeStyle}>{renderNoteType(x.type)}</span>
        </td>
        <td style={{width:'100px'}}>
          {this.renderType(safe(x,'note.session_type'))}
        </td>
        <td style={{maxWidth:'200px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
          {safe(x,'provider.full_name')}
        </td>
        <td style={{width:'200px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
          {safe(x,'location.name')}
        </td>
        <td style={{width:'100px'}}>
          {this.renderStatus(safe(x,'note.session_status'))}
        </td>
        <td>{this.renderAttachments(safe(x,'attachments',[]))}</td>
      </tr>
    );
  }

  renderIndividualNote = (x) => {
    if (x.id === this.state.selectedRow) {
      return [
        this.renderLine(x,false),
        this.renderNote(x)
      ]
    } else {
      return [
        this.renderLine(x)
      ]
    }
  }

  renderTable = (list) => {
    return (
      <div>
        <table className='chart-notes-table'>
          <thead>
            <tr style={{borderBottom: '1px solid #ddd'}}>
              <th></th>
              <th>
                <span>DOS<Tag color="green" style={{ margin: 0, marginLeft: '.2rem' }}>{Time.getPSTPDT()}</Tag></span>
              </th>
              <th></th>
              <th>Note Type</th>
              <th>Session</th>
              <th>Provider</th>
              <th>Location</th>
              <th>Status</th>
              <th>Attachments</th>
            </tr>
          </thead>
          <tbody>
            {list.map(x=>this.renderIndividualNote(x))}
          </tbody>
        </table>
      </div>
    );
  }

  changeFilter = (value) => {
    this.props.setEmrState({
      chartFilterNotes: value
    });
  }

  renderFilterControls = () => {
    if (this.props.print) {
      return null;
    }

    return (
      <div style={{paddingBottom:'25px',paddingTop:'0px'}}>
        <Select value={this.props.chartFilterNotes} style={{width:'300px',marginRight:'10px'}} onChange={this.changeFilter}>
          <Select.Option value={1}>All Notes</Select.Option>
          <Select.Option value={2}>Progress Notes Only</Select.Option>
          <Select.Option value={3}>Only Notes with Attachments</Select.Option>
        </Select>
      </div>
    )
  }

  render() {
    const allNotes = (this.props.chartFilterNotes === 1) ? true : false;
    const progNotesOnly = (this.props.chartFilterNotes === 2) ? true : false;
    const attachmentsOnly = (this.props.chartFilterNotes === 3) ? true : false;

    const list = this.props.noteList.filter(x=>{
      return (
        (attachmentsOnly && x.attachments && x.attachments.length > 0 ) ||
        (progNotesOnly && (x.type === 1 || x.type === 2 || x.type === 7)) ||
        (allNotes)
      ) ? true : false;
    });

    if (list.length === 0) {
      if (this.props.noteListProcessing) {
        return null;
      }

      return (
        <div style={{padding:'15px'}}>
          { this.renderFilterControls() }
          No Notes
        </div>
      );
    } else {
      return (
        <div style={{padding:'15px'}}>
          <Spin spinning={this.props.isBusy}>
            { this.renderFilterControls() }
            { this.renderTable(list) }
          </Spin>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    chartFilterNotes: safe(state,'emr.emr.chartFilterNotes',1),
    isBusy: (
      safe(state,'emr.documents.transfer2.processing',false)
    ) ? true : false,
    isStaffSupervisor: isStaffSupervisor(state),
  }
}

export default connect(mapStateToProps,actions)(withRouter(ChartNotesTable));
