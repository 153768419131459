import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Spin } from 'antd';
import moment from 'moment';

import * as actions from '../../../actions/billing';
import { safe } from '../../../helpers/reduxSelectors';

class RateTables extends Component {

  componentDidMount() {
    this.props.getRateTableList();
  }

  onClickAddRateTable = () => {
    this.props.history.push('/settings/ratetables/0');
  }

  render() {
    const columns = [{
      title: 'Carrier',
      dataIndex: 'carriers_id',
      width: 200,
      render: (text,record) => {
        return <Link to={"/settings/ratetables/"+record.id} style={{whiteSpace: 'nowrap'}}>
          {safe(record,'carriers.name','-')}
        </Link>
      }
    },{
      title: 'Location',
      dataIndex: 'locations_id',
      width: 200,
      render: (text,record) => {
        const val = safe(record,'locations.name',null);
        return (val === null) ? 'All Locations' : val;
      }
    },{
      title: 'Start Date',
      dataIndex: 'start_date',
      width: 150,
      render: (text,record) => {
        return moment(text).format('M/DD/YYYY');
      }
    },{
      title: 'End Date',
      dataIndex: 'end_date',
      width: 150,
      render: (text,record) => {
        return moment(text).format('M/DD/YYYY');
      }
    },{
      title: 'Description',
      dataIndex: 'description'
    }];

    return (
      <Spin spinning={this.props.isBusy} size="large">
        <div style={{padding:'10px'}}>
          <h3>Fee Schedules</h3>
          <Table
            dataSource={this.props.rateTableList}
            rowKey="id"
            size='small'
            pagination={false}
            columns={columns}
          />
          <div style={{position:'fixed',bottom:40,right:40}}>
            <Button onClick={this.onClickAddRateTable}
              disabled={this.props.isBusy}
              size='large'
              shape='circle'
              type="primary"
              icon={<PlusOutlined />}
            />
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.ratetable.load.processing') ||
      safe(state,'billing.ratetable.list.processing')
    ) ? true : false,
    rateTableList: safe(state,'billing.ratetable.list.data',[]),
  }
}

export default connect(mapStateToProps,actions)(RateTables);
