const styles: Record<string, React.CSSProperties> = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  toggle: {
    flexBasis: '10rem',
  },
};

export default styles;
