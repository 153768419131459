import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, InputNumber, Button, Select, Checkbox, Row, Col, Spin, message } from 'antd';

import { usStateAbbrOptions } from '../../../helpers/states';
import ColorSelect from '../../shared/ColorSelect';
import { safe, isSuperUser } from '../../../helpers/reduxSelectors';
import * as shared_actions from '../../../actions/shared';
import * as emr_actions from '../../../actions/emr';

class UsersEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: parseInt(this.props.match.params.id,10),
      timestamp: (new Date()).getTime()
    }
  }

  componentDidMount() {
    this.props.userLoadClear();
    this.props.userSaveClear();
    this.props.supervisorListClear();
    this.props.locationListClear();

    if (this.state.user_id !== 0) {
      this.props.userLoad(this.state.user_id);
    }

    this.props.supervisorListLoad(this.state.user_id);
    this.props.getLocationsList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load !== this.props.load) {
      if (this.props.load.error) {
        message.error('Unable to load user info');
        this.props.history.push('/settings/users');
      }
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.success) {
        this.props.userSaveClear();
        message.success('User saved');
        this.props.history.push('/settings/users');
      } else if (this.props.save.error) {
        message.error('Unable to save user info:' + safe(this.props,'save.error.data.message'));
        this.props.userSaveClear();
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.userSave(this.state.user_id,values);
      }
    });
  }

  isPsychologist = (c) => {
    return (c === 4 || c === 5) ? true : false;
  }

  isMaTherapist = (c) => {
    return (c === 6 || c === 7 || c === 8) ? true : false;
  }

  isNp = (c) => {
    return (c === 3) ? true : false;
  }

  isPsychiatrist = (c) => {
    return (c === 1 || c === 2) ? true : false;
  }

  renderSupervisorField = () => {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const isSup = this.props.form.getFieldValue('is_supervisor');

    // Now we allow any supervisor
    const supervisorOptions = safe(this.props,'supervisorList.data',[]).map(b=><Option key={b.id} value={b.id}>{b.full_name}</Option>);

    return (
      <FormItem {...formItemLayout} label='Supervised by'>
        {getFieldDecorator('supervisor_user_id',
          {initialValue: this.props.userData.supervisor_user_id
        })(
          <Select disabled={isSup}>
            {supervisorOptions}
          </Select>
        )}
      </FormItem>
    )
  }

  adminFields = () => {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <div>
        <FormItem {...formItemLayout} label='Color'>
          {getFieldDecorator('color',
            {initialValue: this.props.userData.color
          })(
            <ColorSelect />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Google Login ID'>
          {getFieldDecorator('google_id',
            {initialValue: this.props.userData.google_id
          })(
            <Input allowClear />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Microsoft Login ID'>
          {getFieldDecorator('microsoft_id',
            {initialValue: this.props.userData.microsoft_id
          })(
            <Input allowClear />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Patient Messaging Disabled'>
          {getFieldDecorator('disable_messaging',{
            initialValue: this.props.userData.disable_messaging,
            valuePropName: 'checked'
          })(
            <Checkbox />
          )}
        </FormItem>

        {
          // eslint-disable-next-line
          this.props.form.getFieldValue('disable_messaging') == 1 && <FormItem {...formItemLayout} label='Disable Messaging Notification'>
          {getFieldDecorator('disable_messaging_notification',{
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: this.props.userData.disable_messaging_notification,
          })(
            <Input allowClear />
          )}
        </FormItem> }

      </div>
    )
  }

  clinicianFields = () => {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const officeLocationOptions = safe(this.props,'locationList.data',[]).map(a =>
        <Option key={a.id} value={a.id}>{a.name}</Option>
    );

    const ollist = [...(safe(this.props,'locationList.data',[]))];
    ollist.unshift({id: null, name: 'None'});
    const secondary_officeLocationOptions = ollist.map(a =>
        <Option key={a.id} value={a.id}>{a.name}</Option>
    );

    const requiredClinicianField = (this.props.form.getFieldValue('roles') &&
      (this.props.form.getFieldValue('roles').includes('therapist') ||
      this.props.form.getFieldValue('roles').includes('prescriber')));

    if (!requiredClinicianField)
      return null;

    return (
      <div>
        <FormItem {...formItemLayout} label="NPI #">
          {getFieldDecorator('npi', {
            rules: [{
              required: true,message: ' ',
            }, {
              len: 10,message: 'An NPI # must be 10 digits long'
            }
            ],
            initialValue: this.props.userData.npi
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="License #">
          {getFieldDecorator('license', {
            rules: [{
              required: requiredClinicianField,
              message: ' ',
            }],
            initialValue: this.props.userData.license
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="License State">
          {getFieldDecorator('license_state', {
            initialValue: this.props.userData.license_state,
            rules: [{
              required: requiredClinicianField,
              message: ' ',
            }]
          })(
            <Select autoComplete="off">
              {usStateAbbrOptions}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Primary Office Location'>
          {getFieldDecorator('primary_location_id', {
            rules: [{
              required: requiredClinicianField, message: ' '
            }],
            initialValue: this.props.userData.primary_location_id,
          })(
            <Select>
              {officeLocationOptions}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Secondary Office Location'>
          {getFieldDecorator('secondary_location_id', {
            initialValue: this.props.userData.secondary_location_id
          })(
            <Select>
              {secondary_officeLocationOptions}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Is a Supervisor?'>
          {getFieldDecorator('is_supervisor', {
            valuePropName: 'checked',
            initialValue: this.props.userData.is_supervisor
          })(
            <Checkbox/>
          )}
        </FormItem>

        { this.renderSupervisorField() }

      </div>
    )
  }

  /*

          <div className="ant-row ant-form-item">
            <div className="ant-form-item-label ant-col-xs-12 ant-col-sm-6">
              <label>Effective Dates</label>
            </div>
            <div className="ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-16">
              <EffectiveDates userId={this.state.user_id} />
            </div>
          </div>
  */

  renderForm = () => {
    const CheckboxGroup = Checkbox.Group;
    const { TextArea } = Input;
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    //const roles = (getFieldValue('roles')) ? getFieldValue('roles') : [];

    const requiredClinicianField = (this.props.form.getFieldValue('roles') &&
      (this.props.form.getFieldValue('roles').includes('therapist') ||
      this.props.form.getFieldValue('roles').includes('prescriber')));

    return (
      <Form onSubmit={this.handleSubmit} autoComplete="false" className='users-form'>
        <FormItem {...formItemLayout} label="First name">
          {getFieldDecorator('firstname', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: this.props.userData.firstname
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Last name">
          {getFieldDecorator('lastname', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: this.props.userData.lastname
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Middle name"
        >
          {getFieldDecorator('middlename', {
            rules: [{ required: false, message: " ", whitespace: true }],
            initialValue: this.props.userData.middlename
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Nickname"
        >
          {getFieldDecorator('nickname', {
            rules: [{ required: false, message: " ", whitespace: true }],
            initialValue: this.props.userData.nickname
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Email"
        >
          {getFieldDecorator('email', {
            rules: [{
              type: 'email', message: ' '
            }, {
              required: true, message: ' '
            },
            /*
            {
              pattern: /\@pcpasf.com$/, message: 'Only email addresses at pcpasf.com are allowed'
            }*/
            ],
            initialValue: this.props.userData.email
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Microsoft Email"
        >
          {getFieldDecorator('microsoft_email', {
            rules: [{
              type: 'email', message: ' '
            }, {
              required: false, message: ' '
            },
            /*
            {
              pattern: /\@pcpasf.com$/, message: 'Only email addresses at pcpasf.com are allowed'
            }*/
            ],
            initialValue: this.props.userData.microsoft_email
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Roles'>
          {getFieldDecorator('roles', {
            rules: [
              {required: true, message: ' '},
              {validator: (rule,value,callback) => {
                if (value && value.includes('therapist') && value.includes('prescriber')) {
                  callback('Only "therapist" or "prescriber" can be selected, but not both.');
                }
                callback()
              }}
            ],
            initialValue: this.props.userData.roles
          })(
            <CheckboxGroup style={{ width: '100%'}}>
              <Row>
                <Col span={12}><Checkbox value='therapist'>Therapist</Checkbox></Col>
                <Col span={12}><Checkbox value='prescriber'>Prescriber</Checkbox></Col>
                <Col span={12}><Checkbox value='resident'>Resident</Checkbox></Col>
                <Col span={12}><Checkbox value='clinicalsup'>Clinical Supervisor</Checkbox></Col>
                <Col span={12}><Checkbox value='staff'>Staff</Checkbox></Col>
                <Col span={12}><Checkbox value='staffsupervisor'>Staff Supervisor</Checkbox></Col>
                <Col span={12}><Checkbox value='billing'>Billing</Checkbox></Col>
                <Col span={12}><Checkbox value='test'>Testing</Checkbox></Col>
                <Col span={12}><Checkbox value='intake'>Intake</Checkbox></Col>
                <Col span={12}><Checkbox value='director'>Clinical Director</Checkbox></Col>
                {this.props.isSuperUser && (
                  <Col span={12}><Checkbox value='superuser'>Superuser</Checkbox></Col>
                )}
                <Col span={12}><Checkbox value='hr'>HR</Checkbox></Col>
              </Row>
            </CheckboxGroup>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Credential">
          {getFieldDecorator('credential', {
            initialValue: (this.props.userData.credential) ? this.props.userData.credential : 0,
            rules: [{
              required: requiredClinicianField,
              message: ' ',
            }, {
              validator: (rule,value,callback) => {
                if (this.props.form.getFieldValue('roles') && this.props.form.getFieldValue('roles').includes('therapist')) {
                  if (value !== 4 && value !== 5 && value !== 6 && value !== 7 && value !== 8) {
                    callback('A therapist role requires a PhD, PsyD, LCSW, MFT or LPCC credential.');
                  } else {
                    callback();
                  }
                }
                else if (this.props.form.getFieldValue('roles') && this.props.form.getFieldValue('roles').includes('prescriber')) {
                  if (value !== 1 && value !== 2 &&value !== 3) {
                    callback('A prescriber role requires an MD, DO, or NP credential.');
                  } else {
                    callback();
                  }
                } else {
                  callback();
                }
              }
            }]
          })(
            <Select>
              <Option value={0}>None</Option>
              <Option value={1}>MD</Option>
              <Option value={2}>DO</Option>
              <Option value={3}>NP</Option>
              <Option value={4}>PhD</Option>
              <Option value={5}>PsyD</Option>
              <Option value={6}>LCSW</Option>
              <Option value={7}>LMFT</Option>
              <Option value={8}>LPCC</Option>
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Employment Relationship'>
          {getFieldDecorator('employment_relationship', {
            initialValue: this.props.userData.employment_relationship
          })(
            <Select>
              <Option value={1}>Employee</Option>
              <Option value={2}>Contractor</Option>
            </Select>
          )}
        </FormItem>

        { this.clinicianFields() }

        { this.adminFields() }

        <div style={{paddingTop:'5px'}}></div>

        <FormItem {...formItemLayout} label='Notes'>
          {getFieldDecorator('notes', {initialValue:this.props.userData.notes})(
            <TextArea rows={4}/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Disable Note Signing'>
          {getFieldDecorator('disable_note_signing', {
            initialValue: Boolean(this.props.userData.disable_note_signing),
            valuePropName: 'checked'
          })(
          <Checkbox />
        )}
        </FormItem>

        <FormItem {...formItemLayout} label="Obie URL">
          {getFieldDecorator('obie_url', {
            initialValue: this.props.userData.obie_url
          })(
            <Input />
          )}
        </FormItem>

        <Row style={{paddingTop:'20px'}}>
          <Col xs={36} sm={16}><Button htmlType="submit" style={{float:'right'}} disabled={this.props.isLoading} type="primary">Save</Button></Col>
        </Row>
      </Form>
    );

  }

  getFullName = () => {
    if (this.state.user_id === 0) {
      return 'New User';
    }
    if (this.props.userData) {
      return this.props.userData.full_name;
    }
    return 'New User';
  }

  wait = (ms) => new Promise((r, j)=>setTimeout(r, ms));

  renderProfileImage = () => {
    const name = safe(this.props,'userData.full_name');
    return (
      <div style={{width:'200px',height:'200px',border:'1px solid #ddd'}}>
        <img width='200px' height='200px' src={'/api/v1/provider/'+this.state.user_id+'/image'} alt={name}/>
      </div>
    );

    /*
    //const baseurl = 'https://res.cloudinary.com/dha4srnxz/pcpa'
    //const url = 'https://res.cloudinary.com/dha4srnxz/c_thumb,g_face,h_200,w_200/v'+this.state.timestamp+'/pcpa/';
//    const url = 'https://res.cloudinary.com/dha4srnxz/image/upload/c_thumb,g_face,h_200,w_200/v1/pcpa/';
    //const u = url + this.state.user_id + '.png';
    const name = safe(this.props,'userData.full_name');
    const This = this;
    const props = {
      action: '/api/v1/userimage/'+this.state.user_id,
      showUploadList: false,
      onChange(info) {
        const status = info.file.status
        if (status == 'done') {
          message.success('user image stored');
          This.setState({
            timestamp: (new Date()).getTime()
          });
        } else if (status == 'error') {
          // notification that there was an error
          message.error('Unable to store user image');
          This.setState({
            timestamp: (new Date()).getTime()
          });
        }
      }
    };


    return (
      <div>
        <div style={{width:'200px',height:'200px',border:'1px solid #ddd'}}>
          <img width='200px' height='200px' src={u} alt={name}/>
        </div>
        <Upload {...props}>
          <Button style={{marginTop:'5px'}} type='primary'>Click to Upload an Image</Button>
        </Upload>
      </div>
    );
    */
  }

  render() {
    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <h3>Users / {this.getFullName()}</h3>
          <Row>
            <Col span={18}>
              {this.renderForm()}
            </Col>
            <Col span={6}>
              {this.renderProfileImage()}
            </Col>
          </Row>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'shared.user.load.processing',false) ||
      safe(state,'shared.user.save.processing',false) ||
      safe(state,'emr.supervisor.processing',false) ||
      safe(state,'shared.location.list.processing',false)
    ) ? true : false,
    load: safe(state,'shared.user.load',{}),
    userData: safe(state,'shared.user.load.data',{}),
    save: safe(state,'shared.user.save',{}),
    supervisorList: safe(state,'emr.supervisor',{}),
    locationList: safe(state,'shared.location.list',{}),
    isSuperUser: isSuperUser(state)
  }
}

export default connect(mapStateToProps,{...emr_actions,...shared_actions})(Form.create()(withRouter(UsersEdit)));
