/**
 * EmployeeSettings.tsx
 */
import reports, { PaginatedDataT } from 'reports';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import ReportWrapper from 'components/App_/ReportsV2_/ReportWrapper';
import InlineFormWrapper from 'components/App_/ReportsV2_/InlineFormWrapper';
import Table from 'components/App_/ReportsV2_/Table';
import columns from 'components/App_/ReportsV2_/TableColumns_/employeeSettings';
import CSVDownload from '../InlineControls_/CSVDownload';
import styles from './styles';
import useAutoRun from 'components/App_/ReportsV2_/useAutoRun';

const reportId = 30;
const page = 1;
const pageSize = 50;

const EmployeeSettings: React.FC = () => {
  const inlineData = useContextSelector(InlineContext, (c) => c.state.inlineData) as PaginatedDataT;
  const report = reports.find((r) => r.id === reportId);

  useAutoRun(reportId);

  const formOptions = {
    page,
    pageSize,
    reportId,
  };

  const tableOptions = {
    data: inlineData?.rows,
    columns,
    page,
    pageSize,
    reportId,
    rowKey: 'id',
    scrollX: true,
    total: inlineData?.count,
  };

  return (
    <ReportWrapper>
      <h3>{report?.name}</h3>
      <div style={styles.root}>
        <InlineFormWrapper {...formOptions}>
          <CSVDownload prefix={report?.csvPrefix} />
        </InlineFormWrapper>
      </div>
      <Table {...tableOptions} />
    </ReportWrapper>
  );
};

export default EmployeeSettings;
