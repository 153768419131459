import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Divider } from 'antd';

import NoteAddendum from './NoteAddendum';
import ProblemList from './ProblemList';
import AttachmentList from './AttachmentList';

import { fieldsMSE } from '../../ProgressNote_/MSE';
import { safe } from '../../../../../../helpers/reduxSelectors';
import { therapyTypes } from '../../../../../../helpers/therapyTypes';
import Time from 'components/shared/Time';

class PrescriberProgressNote extends Component {

  breakLines = (x) => {
    if (!x) {
      return x;
    }
    return x.split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    });
  }

  renderTitle = (x) => {
    const hasLink = (this.props.signedDateTime === null && this.props.providerId === this.props.userId);

    return (x ? (
      <div className='chartnotetitle'>
        {(hasLink && !this.props.print) ? (<Link to={this.props.editURL}>{x}</Link>) : x}
      </div>) : null);
  }

  renderType = (x) => {
    if (x === 1) return ("Initial Session");
    if (x === 2) return ("Followup");
    return null;
  }

  renderSignature = () => {
    return (this.props.signedDateTime ? (
      <div className='chartnotesignature'>
        Electronically signed by {this.props.providerName} at {moment(this.props.signedDateTime).format("MM/DD/YYYY h:mm A")}
      </div>
    ) : null);
  }

  renderCounterSignature = () => {
    return (this.props.counterSignedDateTime ? (
      <div className='chartnotescosignature'>
        Electronically signed by {this.props.supervisingProviderName} at {moment(this.props.counterSignedDateTime).format("MM/DD/YYYY h:mm A")}
      </div>
    ) : null);
  }

  renderItem = (fieldValue,label) => {
    return (fieldValue ? (
      <div className='chartnotefield'>
        <span className='chartnotefieldlabel'>{label} </span>{this.breakLines(fieldValue)}
      </div>
    ) : null);
  }

  getStatusName = (x) => {
    if (x === 1) return (<span className='status_show'>Show</span>);
    if (x === 2) return (<span className='status_noshow'>No Show</span>);
    if (x === 3) return (<span className='status_latecancel'>Late Cancel</span>);
    return null;
  }

  renderSessionStatus = (fieldValue) => {
    return (fieldValue ? (
      <div className='chartnotefield'>
        <span className='chartnotefieldlabel'>Session Status </span>
        {this.getStatusName(fieldValue)}
      </div>
    ) : null);
  }

  includeMentalStatus = () => {
    if (this.props.child_present === undefined || this.props.child_present === 1) {
      return (
        <div>
          <Divider orientation="left">MENTAL STATUS EXAM</Divider>
          {this.renderMentalStatus()}
        </div>
      );
    }
    return null;
  }

  renderMentalStatus = () => {
    if (this.props.mentalStatus) {
      return (
        <div className='chartnotefield'>
          {fieldsMSE.map(a => (
            <div className='chartnote_ms' key={a.name}>
              <span className='chartnotefieldlabel'>{a.label} </span>
              {this.props.mentalStatus[a.name]}
            </div>
          ))}
        </div>
      );
    }
    return null;
  }

  outputVitals = (x) => {
    if (x && (x.weight || x.pulse || x.bp || x.height)) {
      return (
        <div>
          <table style={{borderCollapse:'separate',borderSpacing:'0px 2px'}}>
            <tbody>
              <tr>
                <td className='chartnotefieldlabel' style={{width:'150px'}}>Weight:</td>
                <td>{x.weight}</td>
              </tr>
              <tr>
                <td className='chartnotefieldlabel' style={{width:'150px'}}>Pulse:</td>
                <td>{x.pulse}</td>
              </tr>
              <tr>
                <td className='chartnotefieldlabel' style={{width:'150px'}}>BP:</td>
                <td>{x.bp}</td>
              </tr>
              <tr>
                <td className='chartnotefieldlabel' style={{width:'150px'}}>Height:</td>
                <td>{x.height}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  }

  renderProblemList = () => {
    return (
      <ProblemList problemlist={this.props.problemlist} print={this.props.print}/>
    );
  }

  renderTherapy = () => {
    const hasTherapy = Boolean(this.props.therapy_type && this.props.therapy_duration && this.props.therapy);

    if (this.props.include_therapy === false || (typeof this.props.include_therapy === 'undefined' && !hasTherapy)) {
      return null;
    }

    const tt_record = therapyTypes.find(x=>x.value===this.props.therapy_type);
    const tt = (tt_record) ? tt_record.title : undefined;

    const {
      session_date,
      session_date_local,
      time,
      local_time
    } = Time.fixDateTime(this.props.session_date, this.props.session_start_time);
    
    const start_time = moment(session_date_local);
    const end_time = moment(session_date_local).add({
      minutes: this.props.therapy_duration
    });

    return (
      <>
        <Divider orientation="left">THERAPY</Divider>
        <div>
          <div>{this.breakLines(this.props.therapy)}</div>
          <table style={{borderCollapse:'separate',borderSpacing:'0px 2px'}}>
            <tbody>
              <tr>
                <td className='chartnotefieldlabel' style={{width:'150px'}}>Therapy Type:</td>
                <td>{tt}</td>
              </tr>
              {this.props.therapy_duration &&
              <tr>
                <td className='chartnotefieldlabel' style={{width:'150px'}}>Therapy Duration:</td>
                <td>{this.props.therapy_duration+' minutes'}</td>
              </tr> }
              {local_time && this.props.carrier_id === 1 ? (
              <>
                <tr>
                  <td className='chartnotefieldlabel' style={{width:'150px'}}>Therapy Start Time:</td>
                  <td>{start_time.format("MM/DD/YYYY h:mm A")}</td>
                </tr>
                <tr>
                  <td className='chartnotefieldlabel' style={{width:'150px'}}>Therapy End Time:</td>
                  <td>{end_time.format("MM/DD/YYYY h:mm A")}</td>
                </tr>
              </>
            ) : null}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  renderDevelopmentHistory = () => {
    if (this.props.dev_history && this.props.dev_history.length > 0) {
      return (
        <div>
          <br/>
          <div><strong>DEVELOPMENT HISTORY</strong></div>
          {this.breakLines(this.props.dev_history)}
        </div>
      );
    }
    return null;
  }

  renderSocialAcademicDevelopment = () => {
    if (this.props.soc_acd_history && this.props.soc_acd_history.length > 0) {
      return (
        <div>
          <br />
          <div><strong>SOCIAL & ACADEMIC DEVELOPMENT</strong></div>
          {this.breakLines(this.props.soc_acd_history)}
        </div>
      );
    }
    return null;
  }

  renderNote = () => {
    return (
      <div>
        <Divider orientation="left">CHIEF COMPLAINT</Divider>
        <div>{this.breakLines(this.props.cc)}</div>

        <Divider orientation="left">HPI/SESSION SUMMARY</Divider>
        <div>{this.breakLines(this.props.hpi)}</div>

        <Divider orientation="left">PAST PSYCHIATRIC HISTORY</Divider>
        <div>{this.breakLines(this.props.psy_history)}</div>

        <Divider orientation="left">SUBSTANCE ABUSE HISTORY</Divider>
        <div>{this.breakLines(this.props.sub_history)}</div>

        <Divider orientation="left">MEDICAL HISTORY</Divider>
        <div>{this.breakLines(this.props.medical_history)}</div>
        <div><br/>{this.renderItem(this.props.allergies,'Allergies')}</div>

        {this.renderDevelopmentHistory()}
        {this.renderSocialAcademicDevelopment()}

        {this.props.carrier_id !== 1 ? (
          <>
            <Divider orientation="left">REVIEW OF SYSTEMS</Divider>
            <div>{this.breakLines(this.props.ros)}</div>
          </>
        ): null}
          

        <Divider orientation="left">SOCIAL HISTORY</Divider>
        <div>{this.breakLines(this.props.social_history)}</div>

        { this.includeMentalStatus() }

        <Divider orientation="left">VITALS</Divider>
        <div>{this.outputVitals(this.props.vitals)}</div>

        {this.renderTherapy()}
        
        <Divider orientation="left">PROBLEM LIST</Divider>
        {this.renderProblemList()}

        <Divider orientation="left">ASSESSMENT AND PLAN</Divider>
        <div>{this.breakLines(this.props.poc)}</div>
        <br />
        {this.renderItem(this.props.medication_change,'Medication Change')}
        {this.renderItem(this.props.rtc,'RTC')}
      </div>
    );
  }

  renderNoteHeader = () => {
    return (
      <table style={{borderCollapse:'separate',borderSpacing:'0px 2px'}}>
        <tbody>
          <tr>
            <td className='chartnotefieldlabel' style={{width:'150px',padding:'0px'}}>Provider:</td>
            <td style={{padding:'0px'}}>{this.props.providerName}</td>
          </tr>
          <tr>
            <td className='chartnotefieldlabel' style={{width:'150px',padding:'0px'}}>Location:</td>
            <td style={{padding:'0px'}}>{this.props.locationName}</td>
          </tr>
          <tr>
            <td className='chartnotefieldlabel' style={{width:'150px',padding:'0px'}}>Telemedicine:</td>
            <td style={{padding:'0px'}}>{(this.props.telemedicine) ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td className='chartnotefieldlabel' style={{width:'150px',padding:'0px'}}>Session Type:</td>
            <td style={{padding:'0px'}}>{this.renderType(this.props.session_type)} &nbsp;
              {(this.props.session_duration) ? this.props.session_duration + ' minutes' : ''}</td>
          </tr>
          { this.props.child_present !== undefined && <tr>
              <td className='chartnotefieldlabel' style={{width:'150px',padding:'0px'}}>Child Present?</td>
              <td style={{padding:'0px'}}>{ (this.props.child_present === 1) ? 'Yes' : 'No'}</td>
            </tr>
          }
          <tr>
            <td className='chartnotefieldlabel' style={{width:'150px',padding:'0px'}}>Session Status:</td>
            <td style={{padding:'0px'}}>{this.getStatusName(this.props.session_status)}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  render() {
    const noteUnsigned = (this.props.signedDateTime === null) ? true : false;
    const noteNeedsToBeCountersigned = (this.props.supervisingProviderId !== null) ? true : false;
    const noteIsUnCountersigned = (this.props.counterSignedDateTime === null) ? true : false;
    const noteAuthorIsThisProvider = (this.props.providerId === this.props.userId || this.props.userId === this.props.supervisingProviderId) ? true : false;
    const noteInProcess = (
         (noteUnsigned && !noteAuthorIsThisProvider) ||
         (noteNeedsToBeCountersigned && noteIsUnCountersigned && !noteUnsigned && !noteAuthorIsThisProvider) );
    const waitingForFeedback = (!noteUnsigned && noteNeedsToBeCountersigned && noteIsUnCountersigned && noteAuthorIsThisProvider) ? true : false;

    if (noteInProcess) {
      return (
        <div style={{border:'1px dashed lightgray',backgroundColor:'rgb(219,236,252)',marginRight:'20px'}}>
          <div className='chartnotetitle' style={{padding:'20px'}}>
            Prescriber Progress Note by {this.props.providerName} in process...
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {this.renderTitle('PRESCRIBER ENCOUNTER NOTE')}
          {waitingForFeedback && <div><span style={{backgroundColor:'yellow'}}>Waiting for Supervisor Review</span></div>}

          { this.renderNoteHeader() }

          {this.props.session_status===1 ? this.renderNote() : this.renderItem(this.props.otherNote,'Note')}

          {this.renderSignature()}
          {this.renderCounterSignature()}
          <NoteAddendum
            patientId={this.props.patientId}
            signedDateTime={this.props.signedDateTime}
            noteId={this.props.noteId}
            addendums={this.props.addendums}
            print={this.props.print}
          />
          <AttachmentList list={this.props.attachments} />
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    attachments: safe(ownProps,'data.attachments',[]),
    providerName: safe(ownProps,'data.provider.full_name'),
    locationName: safe(ownProps,'data.location.name'),
    type: safe(ownProps,'data.type'),
    session_type: safe(ownProps,'data.note.session_type'),
    session_status: safe(ownProps,'data.note.session_status'),
    session_date: safe(ownProps, 'data.session.session_date'),
    session_start_time: safe(ownProps, 'data.session.start_time'),
    cc: safe(ownProps,'data.note.cc'),
    hpi: safe(ownProps,'data.note.hpi'),
    poc: safe(ownProps,'data.note.poc'),
    child_present: safe(ownProps,'data.note.child_present'),
    mentalStatus: safe(ownProps,'data.note.mental_status'),
    problemlist: safe(ownProps,'data.note.problemlist'),
    otherNote: safe(ownProps,'data.note.other_note'),
    medical_history: safe(ownProps,'data.note.medical_history'),
    social_history: safe(ownProps,'data.note.social_history'),
    allergies: safe(ownProps,'data.note.allergies'),
    vitals: safe(ownProps,'data.note.vitals'),
    include_therapy: safe(ownProps,'data.note.include_therapy'),
    therapy: safe(ownProps,'data.note.therapy'),
    therapy_type: safe(ownProps,'data.note.therapy_type'),
    therapy_duration: safe(ownProps,'data.note.therapy_duration'),
    medication_change: safe(ownProps,'data.note.medication_change'),
    rtc: safe(ownProps,'data.note.rtc'),
    session_duration: safe(ownProps,'data.note.session_duration'),
    ros: safe(ownProps,'data.note.ros'),
    providerId: safe(ownProps,'data.provider_id'),
    patientId: safe(ownProps,'data.patient_id'),
    signedDateTime: safe(ownProps,'data.signedAt',null),
    supervisingProviderId: safe(ownProps,'data.supervising_provider_id'),
    supervisingProviderName: safe(ownProps,'data.supervisor.full_name'),
    supervisorComment: safe(ownProps,'data.supervisor_comment'),
    counterSignedDateTime: safe(ownProps,'data.countersignedAt',null),
    noteId: safe(ownProps,'data.id'),
    addendums: safe(ownProps,'data.addendums'),
    editURL: '/emr/patients/'+ownProps.data.patient_id+'/progressnote/'+ownProps.data.id,
    psy_history: safe(ownProps,'data.note.psy_history'),
    sub_history: safe(ownProps,'data.note.sub_history'),
    dev_history: safe(ownProps,'data.note.dev_history'),
    soc_acd_history: safe(ownProps,'data.note.soc_acd_history'),
    telemedicine: safe(ownProps,'data.telemedicine'),
    carrier_id: safe(ownProps, 'data.note.carrier_id')
  }
}

export default connect(mapStateToProps,null)(PrescriberProgressNote);
