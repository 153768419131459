import React from 'react';
import { connect } from 'react-redux';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Select, Button, Spin, Table, message, Divider } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

import RecallEntryModal from './RecallList_/RecallEntryModal';

import { safe, getBillingState } from '../../../helpers/reduxSelectors';
import * as billing_actions from '../../../actions/billing';
import * as shared_actions from '../../../actions/shared';

class RecallList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      initialValues: {}
    };
    this.pageSize = 50;
  }

  componentDidMount() {
    this.props.getRecallLists();
    this.props.getRecallItemList(
      this.props.recall_list_selection,
      this.props.recall_status_selection,
      this.props.recalllist_page,
      this.pageSize
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.recall_items_create !== prevProps.recall_items_create) {
      if (this.props.recall_items_create.success) {
        message.success('Recall list item saved');
      } else if (this.props.recall_items_create.error) {
        message.error('Unable to save recall list item');
      }
    }

    if (this.props.recall_items_save !== prevProps.recall_items_save) {
      if (this.props.recall_items_save.success) {
        message.success('Recall list item saved');
      } else if (this.props.recall_items_save.error) {
        message.error('Unable to save recall list item');
      }
    }

    if (prevProps.recalllist_page !== this.props.recalllist_page) {
      this.props.getRecallItemList(
        this.props.recall_list_selection,
        this.props.recall_status_selection,
        this.props.recalllist_page,
        this.pageSize
      );
    }
  }

  closeDialog = () => {
    this.setState({
      dialogVisible: false
    });
  }

  onEdit = (record) => {
    this.props.setPatientList([{
      full_name: record.billing_patient.patients.full_name,
      id: record.billing_patient.id,
      open: true
    }]);
    this.setState({
      dialogVisible: true,
      initialValues: record
    });
  }

  onNew = () => {
    this.props.setPatientList([]);
    this.setState({
      dialogVisible: true,
      initialValues: undefined
    })
  }

  onChange = (field,value) => {
    const x = {
      recall_list_selection: this.props.recall_list_selection,
      recall_status_selection: this.props.recall_status_selection,
      recalllist_page: 1
    };
    x[field] = value;
    this.props.setBillingState(x);


    // Since the billing state gets asynchronously updated, must set these now.
    this.props.getRecallItemList(
      x.recall_list_selection,
      x.recall_status_selection,
      1,
      this.pageSize
    );
  }

  tableChange = ({current,pageSize,total}) => {
    this.props.setBillingState({
      recalllist_page: current
    });
    // This will automatically trigger a reload from componentDidUpdate
  }

  closeRecord = (record) => {
    this.props.saveRecallItem(
      this.props.recall_list_selection,
      this.props.recall_status_selection,
      record.id,
      { status: 2 },
      this.props.recalllist_page,
      this.pageSize
    );
  }

  render() {
    const Option = Select.Option;
    const modList = [{ id: 0, name: 'All Lists'},...this.props.listData];

    const columns = [{
      title: 'List',
      width:200,
      dataIndex: 'list_id',
      render: (value) => {
        const x = this.props.listData.find(x=>x.id===value);
        return <div style={{maxWidth:'150px',whiteSpace: 'nowrap',overflow:'hidden',textOverflow: 'ellipsis'}}>{safe(x,'name')}</div>
      }
    },{
      title: 'Created',
      dataIndex: 'createdAt',
      width: 115,
      render: (value) => {
        return (value) ? moment(value).format('MM/DD/YYYY') : '-'
      }
    },{
      title:'Recall',
      dataIndex: 'recall_date',
      width: 115,
      render: (value) => {
        if (value == null) {
          return '-';
        }
        return moment(value).format('MM/DD/YYYY');
      }
    },{
      title:'Status',
      dataIndex: 'status',
      width: 100,
      render: (value) => {
        if (value === 1) {
          return 'Open';
        } else if (value === 2) {
          return 'Closed';
        } else {
          return '-';
        }
      }
    },{
      title:'Patient',
      dataIndex: 'billing_patient_id',
      width: 200,
      render: (value,record) => {
        return <Link to={'/billing/patients/'+safe(record,'billing_patient.id',0)}>
          {safe(record,'billing_patient.patients.full_name','')}
        </Link>
      }
    },{
      title: 'Title',
      dataIndex: 'title'
    },{
      width:20,
      render: (value,record) => {
        if (record.status === 1) {
          return (
            <span style={{whiteSpace:'nowrap'}}>
              <a onClick={()=>this.onEdit(record)}><EditOutlined /></a>
              <Divider type='vertical'/>
              <a onClick={()=>this.closeRecord(record)}><CloseOutlined /></a>
            </span>
          );
        } else {
          return <a onClick={()=>this.onEdit(record)}><EditOutlined /></a>;
        }
      }
    }];

    return (
      <div className='main'>
        <h3>Recall List</h3>
        <Spin spinning={this.props.isBusy}>
          <table style={{width:'100%'}}>
            <tbody>
              <tr>
                <td>
                  <Select name='recall_list_selection'
                    value={this.props.recall_list_selection} onChange={(value)=>this.onChange('recall_list_selection',value)}
                    style={{width: '200px'}}>
                    { modList.map(x=><Option key={x.id} value={x.id}>{x.name}</Option>)}
                  </Select>
                  <Select name='recall_status_selection'
                    value={this.props.recall_status_selection} onChange={(value)=>this.onChange('recall_status_selection',value)}
                    style={{width: '120px',marginLeft:'5px'}}>
                    <Option value={0}>All</Option>
                    <Option value={1}>Open</Option>
                    <Option value={2}>Closed</Option>
                  </Select>
                </td>
                <td style={{textAlign:'right'}}>
                  <Button onClick={this.onNew} type='primary'>New Recall Item</Button>
                </td>
              </tr>
            </tbody>
          </table>

          <div style={{paddingTop:'5px'}}>
            <Table
              columns={columns}
              size='small'
              dataSource={this.props.itemsData}
              rowKey='id'
              onChange={this.tableChange}
              className='recall-table'
              pagination={{
                current: this.props.recalllist_page,
                pageSize: this.pageSize,
                total: this.props.itemsCount
              }}
            />
          </div>
        </Spin>

        <RecallEntryModal
          visible={this.state.dialogVisible}
          onClose={this.closeDialog}
          initialValues={this.state.initialValues}
        />

      </div>
    )
  }
}
// <Pagination pageSize={5} defaultCurrent={1} onChange={this.changePage} total={100} />
const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.recall.lists_list.processing')
    ) ? true : false,
    listData: safe(state,'billing.recall.lists_list.data',[]),
    itemsCount: safe(state,'billing.recall.items_list.data.count',0),
    itemsData: safe(state,'billing.recall.items_list.data.rows',[]),
    recall_items_create: safe(state,'billing.recall.items_create',{}),
    recall_items_save: safe(state,'billing.recall.items_save',{}),
    recall_list_selection: getBillingState(state,'recall_list_selection',0),
    recall_status_selection: getBillingState(state,'recall_status_selection',1),
    recalllist_page: safe(state,'shared.domain.billing.recalllist_page',1)
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(RecallList);
