import Time from '../../../shared/Time';

const diffHrs = Time.getTzOffset();

export function getSessionStartTimes(seg,selected_session_length,isPrescriber,
  session_type,intake_duration) {
  // Here we just find out the number of times the given segment duration
  // can occur. For simplicity, we locate those times at the beginning or
  // end of the segment and then combine the lists together.

  const count = lookupMaxSessionCount(seg.duration,seg.allow_20m,
    seg.allow_30m,seg.allow_45m,seg.allow_60m,seg.allow_intake,
    isPrescriber,intake_duration,selected_session_length,session_type);

  if (!seg || Object.keys(seg).length === 0) {
    return [];
  }
  const st = new Time(seg.start_time);
  const et = new Time(seg.end_time);
  const uniqueItems = {};
  let myStartTime;
  for (let i = 0; i < count; i++) {
    myStartTime = null;
    if (diffHrs) {
      let localTime = st.toInt() + (diffHrs * 100);
      if (localTime > 2400) {
        const diff = localTime - 2400;
        localTime = diff.toString().padStart(4, '0');
      }
      myStartTime = diffHrs ? new Time(localTime) : null;
    }
    uniqueItems[st.add(i*selected_session_length).toInt()] = true;
    uniqueItems[et.sub((i+1)*selected_session_length).toInt()] = true;
  }
  const result = Object.keys(uniqueItems).map(x=>{
    const t = new Time(x);
    return {
      id: t.toInt(),
      name: t.toString(false),
      local: myStartTime ? myStartTime.toString(false) : null
    };
  });
  return result;
}

/**
 * lookup -
 * This function solves a crucial problem in the app design: given a open
 * chunk of time of _duration_ minutes long, what's the most efficient way
 * to stack it with 20, 30, 45 or 60 minute sessions so that we can maximize
 * the doctor's schedule. The following algorithm is based on the specifics
 * of these 3 possible times rather than a more general solution. The lookup
 * arrays in the code below are generated by carefully examining open
 * segments of each type and determining how best to stack the sessions in that
 * segment given that each session duration is allowed or not.
 */

 const m20=[
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1],
   [0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1],
   [0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0],
   [0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0],
   [0,2,0,2,0,2,0,2,0,2,0,2,0,2,0,2],
   [0,2,0,2,0,0,0,0,0,2,0,2,0,0,0,0],
   [0,2,0,1,0,0,0,1,0,2,0,1,0,0,0,1],
   [0,2,0,1,0,0,0,1,0,2,0,1,0,0,0,1],
   [0,3,0,3,0,3,0,3,0,3,0,3,0,3,0,3],
   [0,3,0,3,0,1,0,1,0,3,0,3,0,1,0,1],
   [0,3,0,2,0,1,0,2,0,3,0,2,0,1,0,2],
   [0,3,0,2,0,1,0,0,0,3,0,2,0,1,0,0],
   [0,4,0,4,0,4,0,4,0,4,0,4,0,4,0,4],
   [0,4,0,4,0,2,0,2,0,4,0,4,0,2,0,2],
   [0,4,0,3,0,0,0,3,0,4,0,3,0,0,0,3],
   [0,4,0,3,0,0,0,1,0,4,0,3,0,0,0,1],
   [0,5,0,5,0,5,0,5,0,5,0,5,0,5,0,5],
   [0,5,0,5,0,3,0,3,0,5,0,5,0,3,0,3],
   [0,5,0,4,0,1,0,4,0,5,0,4,0,1,0,4],
   [0,5,0,4,0,1,0,2,0,5,0,4,0,1,0,2],
   [0,6,0,6,0,6,0,6,0,6,0,6,0,6,0,6],
   [0,6,0,6,0,4,0,4,0,6,0,6,0,4,0,4],
   [0,6,0,5,0,2,0,5,0,6,0,5,0,2,0,5],
   [0,6,0,5,0,0,0,3,0,6,0,5,0,0,0,3],
   [0,7,0,7,0,7,0,7,0,7,0,7,0,7,0,7],
   [0,7,0,7,0,5,0,5,0,7,0,7,0,5,0,5],
   [0,7,0,6,0,3,0,6,0,7,0,6,0,3,0,6],
   [0,7,0,6,0,1,0,4,0,7,0,6,0,1,0,4],
   [0,8,0,8,0,8,0,8,0,8,0,8,0,8,0,8],
   [0,8,0,8,0,6,0,6,0,8,0,8,0,6,0,6],
   [0,8,0,7,0,4,0,7,0,8,0,7,0,4,0,7],
   [0,8,0,7,0,2,0,5,0,8,0,7,0,2,0,5],
   [0,9,0,9,0,9,0,9,0,9,0,9,0,9,0,9],
   [0,9,0,9,0,7,0,7,0,9,0,9,0,7,0,7],
   [0,9,0,8,0,5,0,8,0,9,0,8,0,5,0,8],
   [0,9,0,8,0,3,0,6,0,9,0,8,0,3,0,6],
   [0,10,0,10,0,10,0,10,0,10,0,10,0,10,0,10],
   [0,10,0,10,0,8,0,8,0,10,0,10,0,8,0,8],
   [0,10,0,9,0,6,0,9,0,10,0,9,0,6,0,9],
   [0,10,0,9,0,4,0,7,0,10,0,9,0,4,0,7],
   [0,11,0,11,0,11,0,11,0,11,0,11,0,11,0,11],
   [0,11,0,11,0,9,0,9,0,11,0,11,0,9,0,9],
   [0,11,0,10,0,7,0,10,0,11,0,10,0,7,0,10],
   [0,11,0,10,0,5,0,8,0,11,0,10,0,5,0,8],
   [0,12,0,12,0,12,0,12,0,12,0,12,0,12,0,12],
   [0,12,0,12,0,10,0,10,0,12,0,12,0,10,0,10],
   [0,12,0,11,0,8,0,11,0,12,0,11,0,8,0,11],
   [0,12,0,11,0,6,0,9,0,12,0,11,0,6,0,9],
   [0,13,0,13,0,13,0,13,0,13,0,13,0,13,0,13],
   [0,13,0,13,0,11,0,11,0,13,0,13,0,11,0,11],
   [0,13,0,12,0,9,0,12,0,13,0,12,0,9,0,12],
   [0,13,0,12,0,7,0,10,0,13,0,12,0,7,0,10],
   [0,14,0,14,0,14,0,14,0,14,0,14,0,14,0,14],
   [0,14,0,14,0,12,0,12,0,14,0,14,0,12,0,12],
   [0,14,0,13,0,10,0,13,0,14,0,13,0,10,0,13],
   [0,14,0,13,0,8,0,11,0,14,0,13,0,8,0,11],
   [0,15,0,15,0,15,0,15,0,15,0,15,0,15,0,15],
   [0,15,0,15,0,13,0,13,0,15,0,15,0,13,0,13],
   [0,15,0,14,0,11,0,14,0,15,0,14,0,11,0,14],
   [0,15,0,14,0,9,0,12,0,15,0,14,0,9,0,12],
   [0,16,0,16,0,16,0,16,0,16,0,16,0,16,0,16],
   [0,16,0,16,0,14,0,14,0,16,0,16,0,14,0,14],
   [0,16,0,15,0,12,0,15,0,16,0,15,0,12,0,15],
   [0,16,0,15,0,10,0,13,0,16,0,15,0,10,0,13],
   [0,17,0,17,0,17,0,17,0,17,0,17,0,17,0,17],
   [0,17,0,17,0,15,0,15,0,17,0,17,0,15,0,15],
   [0,17,0,16,0,13,0,16,0,17,0,16,0,13,0,16],
   [0,17,0,16,0,11,0,14,0,17,0,16,0,11,0,14],
   [0,18,0,18,0,18,0,18,0,18,0,18,0,18,0,18],
   [0,18,0,18,0,16,0,16,0,18,0,18,0,16,0,16],
   [0,18,0,17,0,14,0,17,0,18,0,17,0,14,0,17],
   [0,18,0,17,0,12,0,15,0,18,0,17,0,12,0,15],
   [0,19,0,19,0,19,0,19,0,19,0,19,0,19,0,19],
   [0,19,0,19,0,17,0,17,0,19,0,19,0,17,0,17],
   [0,19,0,18,0,15,0,18,0,19,0,18,0,15,0,18],
   [0,19,0,18,0,13,0,16,0,19,0,18,0,13,0,16],
   [0,20,0,20,0,20,0,20,0,20,0,20,0,20,0,20],
   [0,20,0,20,0,18,0,18,0,20,0,20,0,18,0,18],
   [0,20,0,19,0,16,0,19,0,20,0,19,0,16,0,19],
   [0,20,0,19,0,14,0,17,0,20,0,19,0,14,0,17],
   [0,21,0,21,0,21,0,21,0,21,0,21,0,21,0,21],
   [0,21,0,21,0,19,0,19,0,21,0,21,0,19,0,19],
   [0,21,0,20,0,17,0,20,0,21,0,20,0,17,0,20],
   [0,21,0,20,0,15,0,18,0,21,0,20,0,15,0,18],
   [0,22,0,22,0,22,0,22,0,22,0,22,0,22,0,22],
   [0,22,0,22,0,20,0,20,0,22,0,22,0,20,0,20],
   [0,22,0,21,0,18,0,21,0,22,0,21,0,18,0,21],
   [0,22,0,21,0,16,0,19,0,22,0,21,0,16,0,19],
   [0,23,0,23,0,23,0,23,0,23,0,23,0,23,0,23],
   [0,23,0,23,0,21,0,21,0,23,0,23,0,21,0,21],
   [0,23,0,22,0,19,0,22,0,23,0,22,0,19,0,22],
   [0,23,0,22,0,17,0,20,0,23,0,22,0,17,0,20],
   [0,24,0,24,0,24,0,24,0,24,0,24,0,24,0,24],
   [0,24,0,24,0,22,0,22,0,24,0,24,0,22,0,22],
   [0,24,0,23,0,20,0,23,0,24,0,23,0,20,0,23],
   [0,24,0,23,0,18,0,21,0,24,0,23,0,18,0,21],
   [0,25,0,25,0,25,0,25,0,25,0,25,0,25,0,25],
   [0,25,0,25,0,23,0,23,0,25,0,25,0,23,0,23],
   [0,25,0,24,0,21,0,24,0,25,0,24,0,21,0,24],
   [0,25,0,24,0,19,0,22,0,25,0,24,0,19,0,22],
   [0,26,0,26,0,26,0,26,0,26,0,26,0,26,0,26],
   [0,26,0,26,0,24,0,24,0,26,0,26,0,24,0,24],
   [0,26,0,25,0,22,0,25,0,26,0,25,0,22,0,25],
   [0,26,0,25,0,20,0,23,0,26,0,25,0,20,0,23],
   [0,27,0,27,0,27,0,27,0,27,0,27,0,27,0,27],
   [0,27,0,27,0,25,0,25,0,27,0,27,0,25,0,25],
   [0,27,0,26,0,23,0,26,0,27,0,26,0,23,0,26],
   [0,27,0,26,0,21,0,24,0,27,0,26,0,21,0,24],
   [0,28,0,28,0,28,0,28,0,28,0,28,0,28,0,28],
   [0,28,0,28,0,26,0,26,0,28,0,28,0,26,0,26],
   [0,28,0,27,0,24,0,27,0,28,0,27,0,24,0,27],
   [0,28,0,27,0,22,0,25,0,28,0,27,0,22,0,25],
   [0,29,0,29,0,29,0,29,0,29,0,29,0,29,0,29],
   [0,29,0,29,0,27,0,27,0,29,0,29,0,27,0,27],
   [0,29,0,28,0,25,0,28,0,29,0,28,0,25,0,28],
   [0,29,0,28,0,23,0,26,0,29,0,28,0,23,0,26],
   [0,30,0,30,0,30,0,30,0,30,0,30,0,30,0,30],
   [0,30,0,30,0,28,0,28,0,30,0,30,0,28,0,28],
   [0,30,0,29,0,26,0,29,0,30,0,29,0,26,0,29],
   [0,30,0,29,0,24,0,27,0,30,0,29,0,24,0,27],
   [0,31,0,31,0,31,0,31,0,31,0,31,0,31,0,31],
   [0,31,0,31,0,29,0,29,0,31,0,31,0,29,0,29],
   [0,31,0,30,0,27,0,30,0,31,0,30,0,27,0,30],
   [0,31,0,30,0,25,0,28,0,31,0,30,0,25,0,28],
   [0,32,0,32,0,32,0,32,0,32,0,32,0,32,0,32],
   [0,32,0,32,0,30,0,30,0,32,0,32,0,30,0,30],
   [0,32,0,31,0,28,0,31,0,32,0,31,0,28,0,31],
   [0,32,0,31,0,26,0,29,0,32,0,31,0,26,0,29],
   [0,33,0,33,0,33,0,33,0,33,0,33,0,33,0,33],
   [0,33,0,33,0,31,0,31,0,33,0,33,0,31,0,31],
   [0,33,0,32,0,29,0,32,0,33,0,32,0,29,0,32],
   [0,33,0,32,0,27,0,30,0,33,0,32,0,27,0,30],
   [0,34,0,34,0,34,0,34,0,34,0,34,0,34,0,34],
   [0,34,0,34,0,32,0,32,0,34,0,34,0,32,0,32],
   [0,34,0,33,0,30,0,33,0,34,0,33,0,30,0,33],
   [0,34,0,33,0,28,0,31,0,34,0,33,0,28,0,31],
   [0,35,0,35,0,35,0,35,0,35,0,35,0,35,0,35],
   [0,35,0,35,0,33,0,33,0,35,0,35,0,33,0,33],
   [0,35,0,34,0,31,0,34,0,35,0,34,0,31,0,34],
   [0,35,0,34,0,29,0,32,0,35,0,34,0,29,0,32],
   [0,36,0,36,0,36,0,36,0,36,0,36,0,36,0,36],
 ];

 const m30=[
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,1,1,0,0,1,1,0,0,1,1,0,0,1,1],
   [0,0,1,1,0,0,1,1,0,0,1,1,0,0,1,1],
   [0,0,1,0,0,0,1,0,0,0,1,0,0,0,1,0],
   [0,0,1,0,0,0,0,0,0,0,1,0,0,0,0,0],
   [0,0,1,1,0,0,0,1,0,0,1,1,0,0,0,1],
   [0,0,1,1,0,0,0,1,0,0,1,1,0,0,0,1],
   [0,0,2,2,0,0,2,2,0,0,2,2,0,0,2,2],
   [0,0,2,2,0,0,2,0,0,0,2,2,0,0,2,0],
   [0,0,2,1,0,0,2,1,0,0,2,1,0,0,2,1],
   [0,0,2,1,0,0,1,1,0,0,2,1,0,0,1,1],
   [0,0,2,2,0,0,1,2,0,0,2,2,0,0,1,2],
   [0,0,2,2,0,0,1,0,0,0,2,2,0,0,1,0],
   [0,0,3,3,0,0,3,3,0,0,3,3,0,0,3,3],
   [0,0,3,3,0,0,3,1,0,0,3,3,0,0,3,1],
   [0,0,3,2,0,0,3,2,0,0,3,2,0,0,3,2],
   [0,0,3,2,0,0,2,2,0,0,3,2,0,0,2,2],
   [0,0,3,3,0,0,2,3,0,0,3,3,0,0,2,3],
   [0,0,3,3,0,0,2,1,0,0,3,3,0,0,2,1],
   [0,0,4,4,0,0,4,4,0,0,4,4,0,0,4,4],
   [0,0,4,4,0,0,4,2,0,0,4,4,0,0,4,2],
   [0,0,4,3,0,0,4,3,0,0,4,3,0,0,4,3],
   [0,0,4,3,0,0,3,3,0,0,4,3,0,0,3,3],
   [0,0,4,4,0,0,3,4,0,0,4,4,0,0,3,4],
   [0,0,4,4,0,0,3,2,0,0,4,4,0,0,3,2],
   [0,0,5,5,0,0,5,5,0,0,5,5,0,0,5,5],
   [0,0,5,5,0,0,5,3,0,0,5,5,0,0,5,3],
   [0,0,5,4,0,0,5,4,0,0,5,4,0,0,5,4],
   [0,0,5,4,0,0,4,4,0,0,5,4,0,0,4,4],
   [0,0,5,5,0,0,4,5,0,0,5,5,0,0,4,5],
   [0,0,5,5,0,0,4,3,0,0,5,5,0,0,4,3],
   [0,0,6,6,0,0,6,6,0,0,6,6,0,0,6,6],
   [0,0,6,6,0,0,6,4,0,0,6,6,0,0,6,4],
   [0,0,6,5,0,0,6,5,0,0,6,5,0,0,6,5],
   [0,0,6,5,0,0,5,5,0,0,6,5,0,0,5,5],
   [0,0,6,6,0,0,5,6,0,0,6,6,0,0,5,6],
   [0,0,6,6,0,0,5,4,0,0,6,6,0,0,5,4],
   [0,0,7,7,0,0,7,7,0,0,7,7,0,0,7,7],
   [0,0,7,7,0,0,7,5,0,0,7,7,0,0,7,5],
   [0,0,7,6,0,0,7,6,0,0,7,6,0,0,7,6],
   [0,0,7,6,0,0,6,6,0,0,7,6,0,0,6,6],
   [0,0,7,7,0,0,6,7,0,0,7,7,0,0,6,7],
   [0,0,7,7,0,0,6,5,0,0,7,7,0,0,6,5],
   [0,0,8,8,0,0,8,8,0,0,8,8,0,0,8,8],
   [0,0,8,8,0,0,8,6,0,0,8,8,0,0,8,6],
   [0,0,8,7,0,0,8,7,0,0,8,7,0,0,8,7],
   [0,0,8,7,0,0,7,7,0,0,8,7,0,0,7,7],
   [0,0,8,8,0,0,7,8,0,0,8,8,0,0,7,8],
   [0,0,8,8,0,0,7,6,0,0,8,8,0,0,7,6],
   [0,0,9,9,0,0,9,9,0,0,9,9,0,0,9,9],
   [0,0,9,9,0,0,9,7,0,0,9,9,0,0,9,7],
   [0,0,9,8,0,0,9,8,0,0,9,8,0,0,9,8],
   [0,0,9,8,0,0,8,8,0,0,9,8,0,0,8,8],
   [0,0,9,9,0,0,8,9,0,0,9,9,0,0,8,9],
   [0,0,9,9,0,0,8,7,0,0,9,9,0,0,8,7],
   [0,0,10,10,0,0,10,10,0,0,10,10,0,0,10,10],
   [0,0,10,10,0,0,10,8,0,0,10,10,0,0,10,8],
   [0,0,10,9,0,0,10,9,0,0,10,9,0,0,10,9],
   [0,0,10,9,0,0,9,9,0,0,10,9,0,0,9,9],
   [0,0,10,10,0,0,9,10,0,0,10,10,0,0,9,10],
   [0,0,10,10,0,0,9,8,0,0,10,10,0,0,9,8],
   [0,0,11,11,0,0,11,11,0,0,11,11,0,0,11,11],
   [0,0,11,11,0,0,11,9,0,0,11,11,0,0,11,9],
   [0,0,11,10,0,0,11,10,0,0,11,10,0,0,11,10],
   [0,0,11,10,0,0,10,10,0,0,11,10,0,0,10,10],
   [0,0,11,11,0,0,10,11,0,0,11,11,0,0,10,11],
   [0,0,11,11,0,0,10,9,0,0,11,11,0,0,10,9],
   [0,0,12,12,0,0,12,12,0,0,12,12,0,0,12,12],
   [0,0,12,12,0,0,12,10,0,0,12,12,0,0,12,10],
   [0,0,12,11,0,0,12,11,0,0,12,11,0,0,12,11],
   [0,0,12,11,0,0,11,11,0,0,12,11,0,0,11,11],
   [0,0,12,12,0,0,11,12,0,0,12,12,0,0,11,12],
   [0,0,12,12,0,0,11,10,0,0,12,12,0,0,11,10],
   [0,0,13,13,0,0,13,13,0,0,13,13,0,0,13,13],
   [0,0,13,13,0,0,13,11,0,0,13,13,0,0,13,11],
   [0,0,13,12,0,0,13,12,0,0,13,12,0,0,13,12],
   [0,0,13,12,0,0,12,12,0,0,13,12,0,0,12,12],
   [0,0,13,13,0,0,12,13,0,0,13,13,0,0,12,13],
   [0,0,13,13,0,0,12,11,0,0,13,13,0,0,12,11],
   [0,0,14,14,0,0,14,14,0,0,14,14,0,0,14,14],
   [0,0,14,14,0,0,14,12,0,0,14,14,0,0,14,12],
   [0,0,14,13,0,0,14,13,0,0,14,13,0,0,14,13],
   [0,0,14,13,0,0,13,13,0,0,14,13,0,0,13,13],
   [0,0,14,14,0,0,13,14,0,0,14,14,0,0,13,14],
   [0,0,14,14,0,0,13,12,0,0,14,14,0,0,13,12],
   [0,0,15,15,0,0,15,15,0,0,15,15,0,0,15,15],
   [0,0,15,15,0,0,15,13,0,0,15,15,0,0,15,13],
   [0,0,15,14,0,0,15,14,0,0,15,14,0,0,15,14],
   [0,0,15,14,0,0,14,14,0,0,15,14,0,0,14,14],
   [0,0,15,15,0,0,14,15,0,0,15,15,0,0,14,15],
   [0,0,15,15,0,0,14,13,0,0,15,15,0,0,14,13],
   [0,0,16,16,0,0,16,16,0,0,16,16,0,0,16,16],
   [0,0,16,16,0,0,16,14,0,0,16,16,0,0,16,14],
   [0,0,16,15,0,0,16,15,0,0,16,15,0,0,16,15],
   [0,0,16,15,0,0,15,15,0,0,16,15,0,0,15,15],
   [0,0,16,16,0,0,15,16,0,0,16,16,0,0,15,16],
   [0,0,16,16,0,0,15,14,0,0,16,16,0,0,15,14],
   [0,0,17,17,0,0,17,17,0,0,17,17,0,0,17,17],
   [0,0,17,17,0,0,17,15,0,0,17,17,0,0,17,15],
   [0,0,17,16,0,0,17,16,0,0,17,16,0,0,17,16],
   [0,0,17,16,0,0,16,16,0,0,17,16,0,0,16,16],
   [0,0,17,17,0,0,16,17,0,0,17,17,0,0,16,17],
   [0,0,17,17,0,0,16,15,0,0,17,17,0,0,16,15],
   [0,0,18,18,0,0,18,18,0,0,18,18,0,0,18,18],
   [0,0,18,18,0,0,18,16,0,0,18,18,0,0,18,16],
   [0,0,18,17,0,0,18,17,0,0,18,17,0,0,18,17],
   [0,0,18,17,0,0,17,17,0,0,18,17,0,0,17,17],
   [0,0,18,18,0,0,17,18,0,0,18,18,0,0,17,18],
   [0,0,18,18,0,0,17,16,0,0,18,18,0,0,17,16],
   [0,0,19,19,0,0,19,19,0,0,19,19,0,0,19,19],
   [0,0,19,19,0,0,19,17,0,0,19,19,0,0,19,17],
   [0,0,19,18,0,0,19,18,0,0,19,18,0,0,19,18],
   [0,0,19,18,0,0,18,18,0,0,19,18,0,0,18,18],
   [0,0,19,19,0,0,18,19,0,0,19,19,0,0,18,19],
   [0,0,19,19,0,0,18,17,0,0,19,19,0,0,18,17],
   [0,0,20,20,0,0,20,20,0,0,20,20,0,0,20,20],
   [0,0,20,20,0,0,20,18,0,0,20,20,0,0,20,18],
   [0,0,20,19,0,0,20,19,0,0,20,19,0,0,20,19],
   [0,0,20,19,0,0,19,19,0,0,20,19,0,0,19,19],
   [0,0,20,20,0,0,19,20,0,0,20,20,0,0,19,20],
   [0,0,20,20,0,0,19,18,0,0,20,20,0,0,19,18],
   [0,0,21,21,0,0,21,21,0,0,21,21,0,0,21,21],
   [0,0,21,21,0,0,21,19,0,0,21,21,0,0,21,19],
   [0,0,21,20,0,0,21,20,0,0,21,20,0,0,21,20],
   [0,0,21,20,0,0,20,20,0,0,21,20,0,0,20,20],
   [0,0,21,21,0,0,20,21,0,0,21,21,0,0,20,21],
   [0,0,21,21,0,0,20,19,0,0,21,21,0,0,20,19],
   [0,0,22,22,0,0,22,22,0,0,22,22,0,0,22,22],
   [0,0,22,22,0,0,22,20,0,0,22,22,0,0,22,20],
   [0,0,22,21,0,0,22,21,0,0,22,21,0,0,22,21],
   [0,0,22,21,0,0,21,21,0,0,22,21,0,0,21,21],
   [0,0,22,22,0,0,21,22,0,0,22,22,0,0,21,22],
   [0,0,22,22,0,0,21,20,0,0,22,22,0,0,21,20],
   [0,0,23,23,0,0,23,23,0,0,23,23,0,0,23,23],
   [0,0,23,23,0,0,23,21,0,0,23,23,0,0,23,21],
   [0,0,23,22,0,0,23,22,0,0,23,22,0,0,23,22],
   [0,0,23,22,0,0,22,22,0,0,23,22,0,0,22,22],
   [0,0,23,23,0,0,22,23,0,0,23,23,0,0,22,23],
   [0,0,23,23,0,0,22,21,0,0,23,23,0,0,22,21],
   [0,0,24,24,0,0,24,24,0,0,24,24,0,0,24,24],
 ];

 const m45=[
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,1,1,1,1,0,0,0,0,1,1,1,1],
   [0,0,0,0,1,1,1,0,0,0,0,0,1,1,1,0],
   [0,0,0,0,1,1,1,0,0,0,0,0,1,1,1,0],
   [0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,1,1,0,1,0,0,0,0,0,1,0,1],
   [0,0,0,0,1,1,0,0,0,0,0,0,0,1,0,0],
   [0,0,0,0,1,1,1,1,0,0,0,0,0,1,1,1],
   [0,0,0,0,1,0,1,0,0,0,0,0,0,0,1,0],
   [0,0,0,0,1,1,1,1,0,0,0,0,0,1,1,1],
   [0,0,0,0,2,2,2,2,0,0,0,0,2,2,2,2],
   [0,0,0,0,2,2,2,1,0,0,0,0,2,2,2,1],
   [0,0,0,0,2,0,2,0,0,0,0,0,2,0,2,0],
   [0,0,0,0,2,1,1,1,0,0,0,0,1,1,1,1],
   [0,0,0,0,2,2,1,2,0,0,0,0,1,2,1,2],
   [0,0,0,0,2,2,1,1,0,0,0,0,1,2,1,1],
   [0,0,0,0,2,0,2,2,0,0,0,0,0,0,2,2],
   [0,0,0,0,2,1,2,1,0,0,0,0,0,1,2,1],
   [0,0,0,0,2,2,2,2,0,0,0,0,0,2,2,2],
   [0,0,0,0,3,3,3,3,0,0,0,0,3,3,3,3],
   [0,0,0,0,3,0,3,2,0,0,0,0,3,0,3,2],
   [0,0,0,0,3,1,3,1,0,0,0,0,3,1,3,1],
   [0,0,0,0,3,2,2,2,0,0,0,0,2,2,2,2],
   [0,0,0,0,3,3,2,3,0,0,0,0,2,3,2,3],
   [0,0,0,0,3,0,2,2,0,0,0,0,2,0,2,2],
   [0,0,0,0,3,1,3,3,0,0,0,0,1,1,3,3],
   [0,0,0,0,3,2,3,2,0,0,0,0,1,2,3,2],
   [0,0,0,0,3,3,3,3,0,0,0,0,1,3,3,3],
   [0,0,0,0,4,4,4,4,0,0,0,0,4,4,4,4],
   [0,0,0,0,4,1,4,3,0,0,0,0,4,1,4,3],
   [0,0,0,0,4,2,4,2,0,0,0,0,4,2,4,2],
   [0,0,0,0,4,3,3,3,0,0,0,0,3,3,3,3],
   [0,0,0,0,4,4,3,4,0,0,0,0,3,4,3,4],
   [0,0,0,0,4,1,3,3,0,0,0,0,3,1,3,3],
   [0,0,0,0,4,2,4,4,0,0,0,0,2,2,4,4],
   [0,0,0,0,4,3,4,3,0,0,0,0,2,3,4,3],
   [0,0,0,0,4,4,4,4,0,0,0,0,2,4,4,4],
   [0,0,0,0,5,5,5,5,0,0,0,0,5,5,5,5],
   [0,0,0,0,5,2,5,4,0,0,0,0,5,2,5,4],
   [0,0,0,0,5,3,5,3,0,0,0,0,5,3,5,3],
   [0,0,0,0,5,4,4,4,0,0,0,0,4,4,4,4],
   [0,0,0,0,5,5,4,5,0,0,0,0,4,5,4,5],
   [0,0,0,0,5,2,4,4,0,0,0,0,4,2,4,4],
   [0,0,0,0,5,3,5,5,0,0,0,0,3,3,5,5],
   [0,0,0,0,5,4,5,4,0,0,0,0,3,4,5,4],
   [0,0,0,0,5,5,5,5,0,0,0,0,3,5,5,5],
   [0,0,0,0,6,6,6,6,0,0,0,0,6,6,6,6],
   [0,0,0,0,6,3,6,5,0,0,0,0,6,3,6,5],
   [0,0,0,0,6,4,6,4,0,0,0,0,6,4,6,4],
   [0,0,0,0,6,5,5,5,0,0,0,0,5,5,5,5],
   [0,0,0,0,6,6,5,6,0,0,0,0,5,6,5,6],
   [0,0,0,0,6,3,5,5,0,0,0,0,5,3,5,5],
   [0,0,0,0,6,4,6,6,0,0,0,0,4,4,6,6],
   [0,0,0,0,6,5,6,5,0,0,0,0,4,5,6,5],
   [0,0,0,0,6,6,6,6,0,0,0,0,4,6,6,6],
   [0,0,0,0,7,7,7,7,0,0,0,0,7,7,7,7],
   [0,0,0,0,7,4,7,6,0,0,0,0,7,4,7,6],
   [0,0,0,0,7,5,7,5,0,0,0,0,7,5,7,5],
   [0,0,0,0,7,6,6,6,0,0,0,0,6,6,6,6],
   [0,0,0,0,7,7,6,7,0,0,0,0,6,7,6,7],
   [0,0,0,0,7,4,6,6,0,0,0,0,6,4,6,6],
   [0,0,0,0,7,5,7,7,0,0,0,0,5,5,7,7],
   [0,0,0,0,7,6,7,6,0,0,0,0,5,6,7,6],
   [0,0,0,0,7,7,7,7,0,0,0,0,5,7,7,7],
   [0,0,0,0,8,8,8,8,0,0,0,0,8,8,8,8],
   [0,0,0,0,8,5,8,7,0,0,0,0,8,5,8,7],
   [0,0,0,0,8,6,8,6,0,0,0,0,8,6,8,6],
   [0,0,0,0,8,7,7,7,0,0,0,0,7,7,7,7],
   [0,0,0,0,8,8,7,8,0,0,0,0,7,8,7,8],
   [0,0,0,0,8,5,7,7,0,0,0,0,7,5,7,7],
   [0,0,0,0,8,6,8,8,0,0,0,0,6,6,8,8],
   [0,0,0,0,8,7,8,7,0,0,0,0,6,7,8,7],
   [0,0,0,0,8,8,8,8,0,0,0,0,6,8,8,8],
   [0,0,0,0,9,9,9,9,0,0,0,0,9,9,9,9],
   [0,0,0,0,9,6,9,8,0,0,0,0,9,6,9,8],
   [0,0,0,0,9,7,9,7,0,0,0,0,9,7,9,7],
   [0,0,0,0,9,8,8,8,0,0,0,0,8,8,8,8],
   [0,0,0,0,9,9,8,9,0,0,0,0,8,9,8,9],
   [0,0,0,0,9,6,8,8,0,0,0,0,8,6,8,8],
   [0,0,0,0,9,7,9,9,0,0,0,0,7,7,9,9],
   [0,0,0,0,9,8,9,8,0,0,0,0,7,8,9,8],
   [0,0,0,0,9,9,9,9,0,0,0,0,7,9,9,9],
   [0,0,0,0,10,10,10,10,0,0,0,0,10,10,10,10],
   [0,0,0,0,10,7,10,9,0,0,0,0,10,7,10,9],
   [0,0,0,0,10,8,10,8,0,0,0,0,10,8,10,8],
   [0,0,0,0,10,9,9,9,0,0,0,0,9,9,9,9],
   [0,0,0,0,10,10,9,10,0,0,0,0,9,10,9,10],
   [0,0,0,0,10,7,9,9,0,0,0,0,9,7,9,9],
   [0,0,0,0,10,8,10,10,0,0,0,0,8,8,10,10],
   [0,0,0,0,10,9,10,9,0,0,0,0,8,9,10,9],
   [0,0,0,0,10,10,10,10,0,0,0,0,8,10,10,10],
   [0,0,0,0,11,11,11,11,0,0,0,0,11,11,11,11],
   [0,0,0,0,11,8,11,10,0,0,0,0,11,8,11,10],
   [0,0,0,0,11,9,11,9,0,0,0,0,11,9,11,9],
   [0,0,0,0,11,10,10,10,0,0,0,0,10,10,10,10],
   [0,0,0,0,11,11,10,11,0,0,0,0,10,11,10,11],
   [0,0,0,0,11,8,10,10,0,0,0,0,10,8,10,10],
   [0,0,0,0,11,9,11,11,0,0,0,0,9,9,11,11],
   [0,0,0,0,11,10,11,10,0,0,0,0,9,10,11,10],
   [0,0,0,0,11,11,11,11,0,0,0,0,9,11,11,11],
   [0,0,0,0,12,12,12,12,0,0,0,0,12,12,12,12],
   [0,0,0,0,12,9,12,11,0,0,0,0,12,9,12,11],
   [0,0,0,0,12,10,12,10,0,0,0,0,12,10,12,10],
   [0,0,0,0,12,11,11,11,0,0,0,0,11,11,11,11],
   [0,0,0,0,12,12,11,12,0,0,0,0,11,12,11,12],
   [0,0,0,0,12,9,11,11,0,0,0,0,11,9,11,11],
   [0,0,0,0,12,10,12,12,0,0,0,0,10,10,12,12],
   [0,0,0,0,12,11,12,11,0,0,0,0,10,11,12,11],
   [0,0,0,0,12,12,12,12,0,0,0,0,10,12,12,12],
   [0,0,0,0,13,13,13,13,0,0,0,0,13,13,13,13],
   [0,0,0,0,13,10,13,12,0,0,0,0,13,10,13,12],
   [0,0,0,0,13,11,13,11,0,0,0,0,13,11,13,11],
   [0,0,0,0,13,12,12,12,0,0,0,0,12,12,12,12],
   [0,0,0,0,13,13,12,13,0,0,0,0,12,13,12,13],
   [0,0,0,0,13,10,12,12,0,0,0,0,12,10,12,12],
   [0,0,0,0,13,11,13,13,0,0,0,0,11,11,13,13],
   [0,0,0,0,13,12,13,12,0,0,0,0,11,12,13,12],
   [0,0,0,0,13,13,13,13,0,0,0,0,11,13,13,13],
   [0,0,0,0,14,14,14,14,0,0,0,0,14,14,14,14],
   [0,0,0,0,14,11,14,13,0,0,0,0,14,11,14,13],
   [0,0,0,0,14,12,14,12,0,0,0,0,14,12,14,12],
   [0,0,0,0,14,13,13,13,0,0,0,0,13,13,13,13],
   [0,0,0,0,14,14,13,14,0,0,0,0,13,14,13,14],
   [0,0,0,0,14,11,13,13,0,0,0,0,13,11,13,13],
   [0,0,0,0,14,12,14,14,0,0,0,0,12,12,14,14],
   [0,0,0,0,14,13,14,13,0,0,0,0,12,13,14,13],
   [0,0,0,0,14,14,14,14,0,0,0,0,12,14,14,14],
   [0,0,0,0,15,15,15,15,0,0,0,0,15,15,15,15],
   [0,0,0,0,15,12,15,14,0,0,0,0,15,12,15,14],
   [0,0,0,0,15,13,15,13,0,0,0,0,15,13,15,13],
   [0,0,0,0,15,14,14,14,0,0,0,0,14,14,14,14],
   [0,0,0,0,15,15,14,15,0,0,0,0,14,15,14,15],
   [0,0,0,0,15,12,14,14,0,0,0,0,14,12,14,14],
   [0,0,0,0,15,13,15,15,0,0,0,0,13,13,15,15],
   [0,0,0,0,15,14,15,14,0,0,0,0,13,14,15,14],
   [0,0,0,0,15,15,15,15,0,0,0,0,13,15,15,15],
   [0,0,0,0,16,16,16,16,0,0,0,0,16,16,16,16],
 ];

 const m60=[
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
   [0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1],
   [0,0,0,0,0,0,0,0,1,1,1,1,1,0,1,0],
   [0,0,0,0,0,0,0,0,1,1,1,0,1,0,1,0],
   [0,0,0,0,0,0,0,0,1,1,1,0,1,0,0,0],
   [0,0,0,0,0,0,0,0,1,1,1,1,1,1,0,1],
   [0,0,0,0,0,0,0,0,1,1,1,1,1,0,0,0],
   [0,0,0,0,0,0,0,0,1,1,1,1,0,0,1,1],
   [0,0,0,0,0,0,0,0,1,1,1,1,0,0,1,0],
   [0,0,0,0,0,0,0,0,1,1,1,1,0,1,1,1],
   [0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1],
   [0,0,0,0,0,0,0,0,1,1,1,1,1,0,1,1],
   [0,0,0,0,0,0,0,0,1,1,1,1,1,0,1,0],
   [0,0,0,0,0,0,0,0,2,2,2,2,2,2,2,2],
   [0,0,0,0,0,0,0,0,2,2,2,2,2,1,2,1],
   [0,0,0,0,0,0,0,0,2,2,2,1,2,0,2,1],
   [0,0,0,0,0,0,0,0,2,2,2,1,0,0,1,1],
   [0,0,0,0,0,0,0,0,2,2,2,2,0,2,1,2],
   [0,0,0,0,0,0,0,0,2,2,2,2,0,1,1,1],
   [0,0,0,0,0,0,0,0,2,2,2,2,1,1,2,2],
   [0,0,0,0,0,0,0,0,2,2,2,2,1,0,2,1],
   [0,0,0,0,0,0,0,0,2,2,2,2,1,2,2,2],
   [0,0,0,0,0,0,0,0,2,2,2,2,2,2,2,2],
   [0,0,0,0,0,0,0,0,2,2,2,2,2,1,2,2],
   [0,0,0,0,0,0,0,0,2,2,2,2,2,0,2,1],
   [0,0,0,0,0,0,0,0,3,3,3,3,3,3,3,3],
   [0,0,0,0,0,0,0,0,3,3,3,3,3,2,3,2],
   [0,0,0,0,0,0,0,0,3,3,3,2,3,1,3,2],
   [0,0,0,0,0,0,0,0,3,3,3,2,1,1,2,2],
   [0,0,0,0,0,0,0,0,3,3,3,3,1,3,2,3],
   [0,0,0,0,0,0,0,0,3,3,3,3,1,2,2,2],
   [0,0,0,0,0,0,0,0,3,3,3,3,2,2,3,3],
   [0,0,0,0,0,0,0,0,3,3,3,3,2,1,3,2],
   [0,0,0,0,0,0,0,0,3,3,3,3,2,3,3,3],
   [0,0,0,0,0,0,0,0,3,3,3,3,3,3,3,3],
   [0,0,0,0,0,0,0,0,3,3,3,3,3,2,3,3],
   [0,0,0,0,0,0,0,0,3,3,3,3,3,1,3,2],
   [0,0,0,0,0,0,0,0,4,4,4,4,4,4,4,4],
   [0,0,0,0,0,0,0,0,4,4,4,4,4,3,4,3],
   [0,0,0,0,0,0,0,0,4,4,4,3,4,2,4,3],
   [0,0,0,0,0,0,0,0,4,4,4,3,2,2,3,3],
   [0,0,0,0,0,0,0,0,4,4,4,4,2,4,3,4],
   [0,0,0,0,0,0,0,0,4,4,4,4,2,3,3,3],
   [0,0,0,0,0,0,0,0,4,4,4,4,3,3,4,4],
   [0,0,0,0,0,0,0,0,4,4,4,4,3,2,4,3],
   [0,0,0,0,0,0,0,0,4,4,4,4,3,4,4,4],
   [0,0,0,0,0,0,0,0,4,4,4,4,4,4,4,4],
   [0,0,0,0,0,0,0,0,4,4,4,4,4,3,4,4],
   [0,0,0,0,0,0,0,0,4,4,4,4,4,2,4,3],
   [0,0,0,0,0,0,0,0,5,5,5,5,5,5,5,5],
   [0,0,0,0,0,0,0,0,5,5,5,5,5,4,5,4],
   [0,0,0,0,0,0,0,0,5,5,5,4,5,3,5,4],
   [0,0,0,0,0,0,0,0,5,5,5,4,3,3,4,4],
   [0,0,0,0,0,0,0,0,5,5,5,5,3,5,4,5],
   [0,0,0,0,0,0,0,0,5,5,5,5,3,4,4,4],
   [0,0,0,0,0,0,0,0,5,5,5,5,4,4,5,5],
   [0,0,0,0,0,0,0,0,5,5,5,5,4,3,5,4],
   [0,0,0,0,0,0,0,0,5,5,5,5,4,5,5,5],
   [0,0,0,0,0,0,0,0,5,5,5,5,5,5,5,5],
   [0,0,0,0,0,0,0,0,5,5,5,5,5,4,5,5],
   [0,0,0,0,0,0,0,0,5,5,5,5,5,3,5,4],
   [0,0,0,0,0,0,0,0,6,6,6,6,6,6,6,6],
   [0,0,0,0,0,0,0,0,6,6,6,6,6,5,6,5],
   [0,0,0,0,0,0,0,0,6,6,6,5,6,4,6,5],
   [0,0,0,0,0,0,0,0,6,6,6,5,4,4,5,5],
   [0,0,0,0,0,0,0,0,6,6,6,6,4,6,5,6],
   [0,0,0,0,0,0,0,0,6,6,6,6,4,5,5,5],
   [0,0,0,0,0,0,0,0,6,6,6,6,5,5,6,6],
   [0,0,0,0,0,0,0,0,6,6,6,6,5,4,6,5],
   [0,0,0,0,0,0,0,0,6,6,6,6,5,6,6,6],
   [0,0,0,0,0,0,0,0,6,6,6,6,6,6,6,6],
   [0,0,0,0,0,0,0,0,6,6,6,6,6,5,6,6],
   [0,0,0,0,0,0,0,0,6,6,6,6,6,4,6,5],
   [0,0,0,0,0,0,0,0,7,7,7,7,7,7,7,7],
   [0,0,0,0,0,0,0,0,7,7,7,7,7,6,7,6],
   [0,0,0,0,0,0,0,0,7,7,7,6,7,5,7,6],
   [0,0,0,0,0,0,0,0,7,7,7,6,5,5,6,6],
   [0,0,0,0,0,0,0,0,7,7,7,7,5,7,6,7],
   [0,0,0,0,0,0,0,0,7,7,7,7,5,6,6,6],
   [0,0,0,0,0,0,0,0,7,7,7,7,6,6,7,7],
   [0,0,0,0,0,0,0,0,7,7,7,7,6,5,7,6],
   [0,0,0,0,0,0,0,0,7,7,7,7,6,7,7,7],
   [0,0,0,0,0,0,0,0,7,7,7,7,7,7,7,7],
   [0,0,0,0,0,0,0,0,7,7,7,7,7,6,7,7],
   [0,0,0,0,0,0,0,0,7,7,7,7,7,5,7,6],
   [0,0,0,0,0,0,0,0,8,8,8,8,8,8,8,8],
   [0,0,0,0,0,0,0,0,8,8,8,8,8,7,8,7],
   [0,0,0,0,0,0,0,0,8,8,8,7,8,6,8,7],
   [0,0,0,0,0,0,0,0,8,8,8,7,6,6,7,7],
   [0,0,0,0,0,0,0,0,8,8,8,8,6,8,7,8],
   [0,0,0,0,0,0,0,0,8,8,8,8,6,7,7,7],
   [0,0,0,0,0,0,0,0,8,8,8,8,7,7,8,8],
   [0,0,0,0,0,0,0,0,8,8,8,8,7,6,8,7],
   [0,0,0,0,0,0,0,0,8,8,8,8,7,8,8,8],
   [0,0,0,0,0,0,0,0,8,8,8,8,8,8,8,8],
   [0,0,0,0,0,0,0,0,8,8,8,8,8,7,8,8],
   [0,0,0,0,0,0,0,0,8,8,8,8,8,6,8,7],
   [0,0,0,0,0,0,0,0,9,9,9,9,9,9,9,9],
   [0,0,0,0,0,0,0,0,9,9,9,9,9,8,9,8],
   [0,0,0,0,0,0,0,0,9,9,9,8,9,7,9,8],
   [0,0,0,0,0,0,0,0,9,9,9,8,7,7,8,8],
   [0,0,0,0,0,0,0,0,9,9,9,9,7,9,8,9],
   [0,0,0,0,0,0,0,0,9,9,9,9,7,8,8,8],
   [0,0,0,0,0,0,0,0,9,9,9,9,8,8,9,9],
   [0,0,0,0,0,0,0,0,9,9,9,9,8,7,9,8],
   [0,0,0,0,0,0,0,0,9,9,9,9,8,9,9,9],
   [0,0,0,0,0,0,0,0,9,9,9,9,9,9,9,9],
   [0,0,0,0,0,0,0,0,9,9,9,9,9,8,9,9],
   [0,0,0,0,0,0,0,0,9,9,9,9,9,7,9,8],
   [0,0,0,0,0,0,0,0,10,10,10,10,10,10,10,10],
   [0,0,0,0,0,0,0,0,10,10,10,10,10,9,10,9],
   [0,0,0,0,0,0,0,0,10,10,10,9,10,8,10,9],
   [0,0,0,0,0,0,0,0,10,10,10,9,8,8,9,9],
   [0,0,0,0,0,0,0,0,10,10,10,10,8,10,9,10],
   [0,0,0,0,0,0,0,0,10,10,10,10,8,9,9,9],
   [0,0,0,0,0,0,0,0,10,10,10,10,9,9,10,10],
   [0,0,0,0,0,0,0,0,10,10,10,10,9,8,10,9],
   [0,0,0,0,0,0,0,0,10,10,10,10,9,10,10,10],
   [0,0,0,0,0,0,0,0,10,10,10,10,10,10,10,10],
   [0,0,0,0,0,0,0,0,10,10,10,10,10,9,10,10],
   [0,0,0,0,0,0,0,0,10,10,10,10,10,8,10,9],
   [0,0,0,0,0,0,0,0,11,11,11,11,11,11,11,11],
   [0,0,0,0,0,0,0,0,11,11,11,11,11,10,11,10],
   [0,0,0,0,0,0,0,0,11,11,11,10,11,9,11,10],
   [0,0,0,0,0,0,0,0,11,11,11,10,9,9,10,10],
   [0,0,0,0,0,0,0,0,11,11,11,11,9,11,10,11],
   [0,0,0,0,0,0,0,0,11,11,11,11,9,10,10,10],
   [0,0,0,0,0,0,0,0,11,11,11,11,10,10,11,11],
   [0,0,0,0,0,0,0,0,11,11,11,11,10,9,11,10],
   [0,0,0,0,0,0,0,0,11,11,11,11,10,11,11,11],
   [0,0,0,0,0,0,0,0,11,11,11,11,11,11,11,11],
   [0,0,0,0,0,0,0,0,11,11,11,11,11,10,11,11],
   [0,0,0,0,0,0,0,0,11,11,11,11,11,9,11,10],
   [0,0,0,0,0,0,0,0,12,12,12,12,12,12,12,12],
 ];

function lookupMaxSessionCount(duration,a20,a30,a45,a60,aIntakes,isPrescriber,
  intakeDuration,selected_duration,session_type) {

  if (isNaN(duration)) {
    return 0;
  }

  // 1. Compute the col index as if it were a binary number.
  // a20 - 1
  // a30 - 2
  // a20, a30 - 3
  // a45 - 4
  // a20, a45 - 5
  // a30, a45 - 6
  // a20, a30, a45 - 7
  // a60 - 8
  // ...

  // The lookup tables handle arrangements of 30, 45, and 60 minute sessions.
  // Psychiatric Evals are 60 minutes, but they are different than a 60 minute
  // followup. Here we modify the condition to lookup in the table under 60 min
  // sessions in the mix, but below in the switch statement, we deliberately
  // exclude the column if necessary.

  const a60mIntake = (aIntakes && intakeDuration === 60) ? true : false;
  const a45mIntake = (aIntakes && intakeDuration === 45) ? true : false;

  const col = (a20 ? 1 : 0) + (a30 ? 2 : 0) + (a45 || a45mIntake ? 4 : 0) + (a60 || a60mIntake ? 8 : 0);
  const col_45mIntake = (a45mIntake ? 4 : 0) + (a20 ? 1 : 0) + (a30 ? 2 : 0) + (a60 ? 8 : 0)
  const col_60mIntake = (a60mIntake ? 8 : 0) + (a20 ? 1 : 0) + (a30 ? 2 : 0) + (a45 ? 4 : 0);

  // 2. Discard extra time beyond a multiple of 15.
  // 3. Then convert time into a multiple of 15
  const increment = 5;
  const total_time = 12;
  const total_lines = (60/increment)*total_time;

  const normalized_duration = (duration - (duration % increment))/increment;

  // 3. Get the # of 12 hr segments.
  const num_segs = Math.trunc(normalized_duration/total_lines);
  const remainder = normalized_duration-(num_segs*total_lines);

  // 4. Lookup tables for the # of each segment type.
  if (session_type === 1) {  // INTAKE
    switch (selected_duration) {
      case 45: return m45[remainder][col_45mIntake]+m45[total_lines][col_45mIntake]*num_segs;
      case 60: return m60[remainder][col_60mIntake]+m60[total_lines][col_60mIntake]*num_segs;
      default:
        return 0;
    }
  } else if (session_type === 2) { // FOLLOWUP
    switch (selected_duration) {
      case 20: return m20[remainder][col]+m20[total_lines][col]*num_segs;
      case 30: return m30[remainder][col]+m30[total_lines][col]*num_segs;
      case 45: return (a45) ? m45[remainder][col]+m45[total_lines][col]*num_segs : 0;
      case 60: return (a60) ? m60[remainder][col]+m60[total_lines][col]*num_segs : 0;
      default:
        return 0;
    }
  }
  return 0;
}

export function availableTypes(duration,a20,a30,a45,a60,aIntakes,intakeDuration,
  isPrescriber) {

  if (isNaN(duration)) {
    return 0;
  }

  const a60mIntake = (aIntakes && intakeDuration === 60) ? true : false;
  const a45mIntake = (aIntakes && intakeDuration === 45) ? true : false;

  const col = (a20 ? 1 : 0) + (a30 ? 2 : 0) + (a45 || a45mIntake ? 4 : 0) + (a60 || a60mIntake ? 8 : 0);

  // 2. Discard extra time beyond a multiple of 15.
  // 3. Then convert time into a multiple of 15
  const increment = 5;
  const total_time = 12;
  const total_lines = (60/increment)*total_time;

  const normalized_duration = (duration - (duration % increment))/increment;

  // 3. Get the # of 12 hr segments.
  const num_segs = Math.trunc(normalized_duration/total_lines);
  const remainder = normalized_duration-(num_segs*total_lines);

  // 4. Lookup tables for the # of each segment type.
  const count_20 = m20[remainder][col]+m20[total_lines][col]*num_segs;
  const count_30 = m30[remainder][col]+m30[total_lines][col]*num_segs;
  const count_45 = m45[remainder][col]+m45[total_lines][col]*num_segs;
  const count_60 = m60[remainder][col]+m60[total_lines][col]*num_segs;

  return {
    allow_20m: (count_20 > 0) ? true : false,
    allow_30m: (count_30 > 0) ? true : false,
    allow_45m: (count_45 > 0 && a45) ? true : false,
    allow_60m: (count_60 > 0 && a60) ? true : false,
    allow_intake: ((count_60 > 0 && a60mIntake) || (count_45 >0 && a45mIntake)) ? true : false
  }
}

// ---- The code below this is modified from the FC Scheduler -----

export function filterData(data) {
  const calStartTime = 6;

  Object.values(data).forEach(col=>{
    computeSegVerticals(col,calStartTime);
    computeSegHorizontals(col);
  });
}

function computeSegVerticals(col,calStartTime) {
  col.forEach(seg=>{
    seg.top = (new Time(seg.start_time)).toFloat()-calStartTime;
    seg.bottom = -((new Time(seg.end_time)).toFloat()-calStartTime)
  });
}

// Given an array of segments that are all in the same column, sets the backwardCoord and forwardCoord on each.
// Assumed the segs are already ordered.
// NOTE: Also reorders the given array by date!
function computeSegHorizontals(segs) {
  let levels
  let level0
  let i

  levels = buildSlotSegLevels(segs);
  computeForwardSlotSegs(levels)

  if ((level0 = levels[0])) {

    for (i = 0; i < level0.length; i++) {
      computeSlotSegPressures(level0[i])
    }

    for (i = 0; i < level0.length; i++) {
      computeSegForwardBack(level0[i], 0, 0)
    }
  }
}


// Calculate seg.forwardCoord and seg.backwardCoord for the segment, where both values range
// from 0 to 1. If the calendar is left-to-right, the seg.backwardCoord maps to "left" and
// seg.forwardCoord maps to "right" (via percentage). Vice-versa if the calendar is right-to-left.
//
// The segment might be part of a "series", which means consecutive segments with the same pressure
// who's width is unknown until an edge has been hit. `seriesBackwardPressure` is the number of
// segments behind this one in the current series, and `seriesBackwardCoord` is the starting
// coordinate of the first segment in the series.
function computeSegForwardBack(seg, seriesBackwardPressure, seriesBackwardCoord) {
  let forwardSegs = seg.forwardSegs
  let i

  if (seg.forwardCoord === undefined) { // not already computed

    if (!forwardSegs.length) {

      // if there are no forward segments, this segment should butt up against the edge
      seg.forwardCoord = 1
    } else {

      // sort highest pressure first
      sortForwardSegs(forwardSegs)

      // this segment's forwardCoord will be calculated from the backwardCoord of the
      // highest-pressure forward segment.
      computeSegForwardBack(forwardSegs[0], seriesBackwardPressure + 1, seriesBackwardCoord)
      seg.forwardCoord = forwardSegs[0].backwardCoord
    }

    // calculate the backwardCoord from the forwardCoord. consider the series
    seg.backwardCoord = seg.forwardCoord -
      (seg.forwardCoord - seriesBackwardCoord) / // available width for series
      (seriesBackwardPressure + 1) // # of segments in the series

    // use this segment's coordinates to computed the coordinates of the less-pressurized
    // forward segments
    for (i = 0; i < forwardSegs.length; i++) {
      computeSegForwardBack(forwardSegs[i], 0, seg.forwardCoord)
    }
  }
}

function sortForwardSegs(forwardSegs) {
  let objs = forwardSegs.map(buildTimeGridSegCompareObj)

  let specs = [
    // put higher-pressure first
    { field: 'forwardPressure', order: -1 },
    // put segments that are closer to initial edge first (and favor ones with no coords yet)
    { field: 'backwardCoord', order: 1 }
  ]

  objs.sort(function(obj0, obj1) {
    return compareByFieldSpecs(obj0, obj1, specs)
  })

  return objs.map(function(c) {
    return c._seg
  })
}

function compareByFieldSpecs(obj0, obj1, fieldSpecs) {
  let i
  let cmp

  for (i = 0; i < fieldSpecs.length; i++) {
    cmp = compareByFieldSpec(obj0, obj1, fieldSpecs[i])
    if (cmp) {
      return cmp
    }
  }
  return 0
}

function compareByFieldSpec(obj0, obj1, fieldSpec) {
  return flexibleCompare(obj0[fieldSpec.field], obj1[fieldSpec.field])
    * (fieldSpec.order || 1)
}

function flexibleCompare(a, b) {
  if (!a && !b) {
    return 0
  }
  if (b == null) {
    return -1
  }
  if (a == null) {
    return 1
  }
  if (typeof a === 'string' || typeof b === 'string') {
    return String(a).localeCompare(String(b))
  }
  return a - b
}

// Builds an array of segments "levels". The first level will be the leftmost tier of segments if the calendar is
// left-to-right, or the rightmost if the calendar is right-to-left. Assumes the segments are already ordered by date.
function buildSlotSegLevels(segs) {
  let levels = []
  let i
  let seg
  let j

  for (i = 0; i < segs.length; i++) {
    seg = segs[i]

    // go through all the levels and stop on the first level where there are no collisions
    for (j = 0; j < levels.length; j++) {
      if (!computeSlotSegCollisions(seg, levels[j]).length) {
        break
      }
    }

    seg.level = j;

    (levels[j] || (levels[j] = [])).push(seg)
  }

  return levels
}

// Find all the segments in `otherSegs` that vertically collide with `seg`.
// Append into an optionally-supplied `results` array and return.
function computeSlotSegCollisions(seg, otherSegs, results= []) {
  for (let i = 0; i < otherSegs.length; i++) {
    if (isSlotSegCollision(seg, otherSegs[i])) {
      results.push(otherSegs[i])
    }
  }
  return results
}

// Do these segments occupy the same vertical space?
function isSlotSegCollision(seg1, seg2) {
  return seg1.end_time > seg2.start_time && seg1.start_time < seg2.end_time;
}



// For every segment, figure out the other segments that are in subsequent
// levels that also occupy the same vertical space. Accumulate in seg.forwardSegs
function computeForwardSlotSegs(levels) {
  let i
  let level
  let j
  let seg
  let k

  for (i = 0; i < levels.length; i++) {
    level = levels[i]

    for (j = 0; j < level.length; j++) {
      seg = level[j]

      seg.forwardSegs = []
      for (k = i + 1; k < levels.length; k++) {
        computeSlotSegCollisions(seg, levels[k], seg.forwardSegs)
      }
    }
  }
}

// Figure out which path forward (via seg.forwardSegs) results in the longest path until
// the furthest edge is reached. The number of segments in this path will be seg.forwardPressure
function computeSlotSegPressures(seg) {
  let forwardSegs = seg.forwardSegs
  let forwardPressure = 0
  let i
  let forwardSeg

  if (seg.forwardPressure === undefined) { // not already computed

    for (i = 0; i < forwardSegs.length; i++) {
      forwardSeg = forwardSegs[i]

      // figure out the child's maximum forward path
      computeSlotSegPressures(forwardSeg)

      // either use the existing maximum, or use the child's forward pressure
      // plus one (for the forwardSeg itself)
      forwardPressure = Math.max(
        forwardPressure,
        1 + forwardSeg.forwardPressure
      )
    }

    seg.forwardPressure = forwardPressure
  }
}

function buildTimeGridSegCompareObj(seg) {
  let obj = buildSegCompareObj(seg)

  obj.forwardPressure = seg.forwardPressure
  obj.backwardCoord = seg.backwardCoord

  return obj
}

// returns a object with all primitive props that can be compared
function buildSegCompareObj(seg) {
  return {
    id: seg.id,
    start_time: seg.start_time,
    end_time: seg.end_time,
    duration: (new Time(seg.start_time)).diff(new Time(seg.end_time)),
    _seg: seg
  }
}
