import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Input, DatePicker, message } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import PhoneInput from './PhoneInput';

import { safe } from '../../helpers/reduxSelectors';
import * as shared_actions from '../../actions/shared';
import * as emr_actions from '../../actions/emr';

class PortalRegistration extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.form.resetFields();
    }

    if (prevProps.ptReg !== this.props.ptReg) {
      if (this.props.ptReg.success) {
        message.success('Patient registration sent out');
        this.props.sendPatientRegistrationClear();
        this.props.getPatientChart(this.props.patientData.id);
      } else if (this.props.ptReg.error) {
        message.error(safe(this.props.ptReg,'error.data.message',''));
        this.props.sendPatientRegistrationClear();
      }
    }
  }

  handleOk = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.sendPatientRegistration(this.props.patientData.id,values);
        this.props.closeDialog();
      }
    });
  }

  handleCancel = () => {
    this.props.closeDialog();
  }

  customFooter = () => {
    const buttons = [];
    buttons.push(<Button key={1001} type='primary' onClick={this.handleOk}>Send Registration Email</Button>);
    return buttons;
  }

  contents = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 18 },
      },
    };

    return (
      <div>
        <p>Please verify the following information with the patient. The
        patient will need these exact details to login to the patient portal</p>

        <Form autoComplete="off">
          <FormItem {...formItemLayout} label="Email">
            {getFieldDecorator('email', {
              rules: [
                {type: 'email', message: 'This is not valid email address'},
                {required: true, message: 'Please input an email address'}
              ],
              initialValue: this.props.patientData.email
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Date of Birth">
            {getFieldDecorator('dob', {
              rules: [{ required: true, message: "Enter date of birth" }],
              initialValue: (this.props.patientData.dob) ? moment(this.props.patientData.dob).tz('America/Los_Angeles') : null
            })(
              <DatePicker format='M/D/YYYY' />
            )}
          </FormItem>


          <FormItem {...formItemLayout} label="Phone">
            {getFieldDecorator('phone', {
              rules: [{ required: true, message: "Enter phone number" }],
              initialValue: this.props.patientData.phone
            })(
              <PhoneInput/>
            )}
          </FormItem>

        </Form>

      </div>
    );
  }

  render() {
    return (
      <Modal
        title={safe(this.props,'patientData.full_name')+ ' Portal Registration'}
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={this.customFooter()}
      >
        {this.contents()}
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    patientData: safe(state,'shared.patient.load.data',{}),
    ptReg: safe(state,'shared.ptreg',{})
  }
}

export default connect(mapStateToProps,{...shared_actions,...emr_actions})(Form.create()(PortalRegistration));
