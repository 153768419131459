import React from 'react';
import { CustomerServiceOutlined, DollarOutlined, LeftOutlined } from '@ant-design/icons';
import { Tooltip, Avatar } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import CreateMessage from './Messages_/CreateMessage';
import MessageList from './Messages_/MessageList';
import ThreadComment from './Messages_/ThreadComment';
import { safe } from '../../../../../helpers/reduxSelectors';

class Messages extends React.Component {

  getImageName = (z) => {
    return '/api/v1/provider/'+z.user_id+'/image';
  }

  getInitials = (name) => {
    return (name) ? (name.match(/\b\w/g) || []).join('').toUpperCase() : null;
  }

  getAvatar = (z) => {
    if (z.type === 1) {
      return (<Avatar style={{marginRight:'5px'}} src={this.getImageName(z)} />);
    } else if (z.type === 2) {
      return (<Avatar style={{backgroundColor:'red',marginRight:'5px'}}>{this.getInitials(z.name)}</Avatar>);
    } else if (z.type === 3) {
      if (z.group_id === 1) {
        return <Avatar style={{backgroundColor:'orange',color:'black',marginRight:'5px'}} icon={<CustomerServiceOutlined />}/>;
      } else if (z.group_id === 2) {
        return <Avatar style={{backgroundColor:'green',marginRight:'5px'}} icon={<DollarOutlined />} />;
      } else {
        return (<Avatar />);
      }
    }
    return null;
  }

  getAvatars = (members,receptionist,billingist) => {
    let i = 0;
    // This is where, if it's a reception/billing, need to insert the active name.
    return members.map(z=>{
      let name = z.name;
      if (z.type === 3) {
        if (z.group_id === 1 && receptionist) {
          name +=  ': ' + receptionist;
        }
        if (z.group_id === 2 && billingist) {
          name +=  ': ' + billingist;
        }
      }

      i++;
      const q = this.getAvatar(z);
      if (q !== null) {
        return (
          <Tooltip key={i} title={name} placement='bottomRight'>
            {this.getAvatar(z)}
          </Tooltip>
        );
      } else {
        return null;
      }
    });
  }

  displayThreadMembers(ptid,ptName,members,receptionist,billingist) {
    return (
      <table style={{width:'100%'}}>
        <tbody>
          <tr>
            <td style={{textAlign: 'left'}}>
              <a onClick={this.props.onBack}>
                <LeftOutlined />Back
              </a>
            </td>
            <td style={{textAlign: 'right',fontWeight:'bold'}}>
              <Link to={'/emr/patients/'+ptid}>{ptName}</Link> &nbsp;
              {this.getAvatars(members,receptionist,billingist)}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    const members = safe(this.props,'threadStatus.thread.members',[]);
    const ptName = safe(this.props,'threadStatus.thread.patient.full_name');
    const ptid = safe(this.props,'threadStatus.thread.patient.id');
    const receptionist = safe(this.props,'threadStatus.thread.patient.receptionist.full_name');
    const billingist = safe(this.props,'threadStatus.thread.patient.billingist.full_name');

    const barStyle = {
      flex:0,
      paddingLeft:'5px',
      paddingRight:'5px',
      paddingTop:'12px',
      paddingBottom:'12px',
      marginBottom:'5px',
      backgroundColor:'#F3F3F3',
      color:'black',
      borderBottom: '1px solid lightgray',
    }

    return (
      <div style={{height:'100vh',width:'100%',display:'flex',flexDirection:'column'}}>

        <div style={barStyle}>
          { this.displayThreadMembers(ptid,ptName,members,receptionist,billingist) }
        </div>
        <ThreadComment threadStatus={this.props.threadStatus} onBack={this.props.onBack}/>
        <MessageList messages={safe(this.props,'messages.data',[])} loading={safe(this.props,'messages.processing')}/>
        <CreateMessage thread_id={safe(this.props,'threadStatus.thread.id')}/>
      </div>
    );
  }

}

const mapStateToProps = (state,ownProps) => {
  return {
    messages: safe(state,'emr.inbox.messages',{})
  }
}

export default connect(mapStateToProps,null)(withRouter(Messages));
