/* eslint-disable react/display-name */
/**
 * Timeoff.tsx
 */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Radio, Spin, Table, Tag } from 'antd';
import useFetch, { CachePolicies } from 'use-http';
import moment from 'moment';
import qs from 'qs';
import get from 'lodash/get';
import Time from 'components/shared/Time';
import OppositeEnds from 'components/shared/OppositeEnds';
import CommentModal from 'components/App_/Scheduler_/TimeOff_/CommentModal';
import CreateTimeoffModal from 'components/App_/Scheduler_/TimeOff_/CreateTimeoffModal';

interface PropsI {
  active: boolean;
  reload?: boolean;
}

const DirectorTimeoff: React.FC<PropsI> = ({ active }: PropsI) => {
  const activeUserId = useSelector((state) => get(state, 'shared.domain.activeUser.id'));

  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [commentModalTimeoffId, setCommentModalTimeoffId] = useState(null);
  const [commentModalAction, setCommentModalAction] = useState(null);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [filter, setFilter] = useState(1);

  const query = qs.stringify({ page, pagesize: pageSize, director_id: activeUserId },{ addQueryPrefix: true });  

  const timeoffsListAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const approveTimeoffAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const providerListAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });

  const isBusy = timeoffsListAPI?.loading

  const getTimeoffs = () => timeoffsListAPI.get(`/api/v1/scheduler/timeoff/${filter}${query}`);
  const getProviderList = () => providerListAPI.get(`/api/v1/scheduler/provider/${activeUserId}`);

  useEffect(() => {
    if (active) {
      getTimeoffs();
      getProviderList();
    }
  }, [trigger]);
  
  const showCommentModal = (timeoff_id,action) => {
    setCommentModalVisible(true);
    setCommentModalTimeoffId(timeoff_id);
    setCommentModalAction(action);
  };

  const closeCommentModal = () => {
    setCommentModalVisible(false);
  };

  const displayDateTimeRange = (start_date,end_date,start_time,end_time,duration) => {
    if (duration === 1) {
      // Partial-Day Timeoff
      try {
        const st = (new Time(start_time)).toString(false);
        const et = (new Time(end_time)).toString(false);
        const sd = moment(start_date).format('MM/DD/YYYY');
        return <span style={{whiteSpace:'nowrap'}}>{sd},{st}-{et}</span>;
      } catch (err) {
        return null;
      }
    } else if (duration === 2) {
      const sd = moment(start_date).format('MM/DD/YYYY');
      const ed = moment(end_date).format('MM/DD/YYYY');
      if (moment(start_date).isSame(end_date,'day')) {
        return <span style={{whiteSpace:'nowrap'}}>{sd}</span>;
      } else {
        return <span style={{whiteSpace:'nowrap'}}>{sd}-{ed}</span>;
      }
    }
  }

  const onTableChange = (p) => {
    const newPage = p.current;
    const newPageSize = p.pageSize;
    // ensure re-run only happens when a real change occurs
    if ((page && newPage !== page) || (pageSize && newPageSize !== pageSize)) {
      setPage(newPage);
      setPageSize(newPageSize);
      setTrigger((t) => !t);
    }
  };

  const onApproveItem = async (timeoff_id,description) => {
    await approveTimeoffAPI.post(`/api/v1/scheduler/timeoff/${timeoff_id}/approve/${filter}${query}`, { description });
    getTimeoffs();
  }

  const onRejectItem = async (timeoff_id,description) => {
    await approveTimeoffAPI.post(`/api/v1/scheduler/timeoff/${timeoff_id}/reject/${filter}${query}`, { description });
    getTimeoffs();
  }

  const onCancelItem = async (timeoff_id) => {
    await approveTimeoffAPI.post(`/api/v1/scheduler/timeoff/${timeoff_id}/cancel/${filter}${query}`);
    getTimeoffs();
  }

  const cancelItem = (timeoff_id) => {
    Modal.confirm({
      title: 'Are you sure you want to cancel this approval?',
      content: 'Doing so will remove all related PTO/UTO time blocks from the provider\'s schedule',
      onOk() {
        onCancelItem(timeoff_id)
      },
      onCancel() {},
    });
  }

  const onCreateTimeoff = async (timeoff) => {
    await approveTimeoffAPI.post(`/api/v1/scheduler/timeoff/${timeoff.provider_id}/${filter}${query}`, timeoff);
    getTimeoffs();
  }

  const changeFilter = (e) => {
    setFilter(e.target.value);
    setPage(1);
    setTrigger((t) => !t);
  };

  const showModal = (name) => {
    if (name === 'create') {
      setCreateModalVisible(true);
    }
  }

  const closeModal = (name) => {
    if (name === 'create') {
      setCreateModalVisible(false);
    }
  };

  const createTimeoffButton = () => {
    if (filter === 1) {
      return <Button type='primary' onClick={()=>showModal('create')}>Create New Time Off Request</Button>
    }
    return null;
  };

  const columns = [{
    title: 'Requested',
    dataIndex: 'createdAt',
    render: (value) => {
      return (
        <span style={{whiteSpace:'nowrap'}}>{moment(value).format('MM/DD/YYYY H:mm A')}</span>
      );
    }
  },{
    title: 'Dates/Times',
    dataIndex: 'start_date',
    render: (_value,record) => displayDateTimeRange(record.start_date, record.end_date,record.start_time,record.end_time,record.duration)
  },{
    title: 'Status',
    dataIndex: 'status',
    render: (value) => {
      switch (value) {
        case 1: return <Tag color='blue'>Request</Tag>;
        case 2: return <Tag color='green'>Approved</Tag>;
        case 3: return <Tag color='red'>Rejected</Tag>;
        case 4: return <Tag color='orange'>Cancelled</Tag>;
        default: return '-';
      }
    }
  },{
    title: 'Type',
    dataIndex: 'type',
    render: (value) => {
      switch (value) {
        case 4: return <Tag color='purple'>PTO</Tag>;
        case 5: return <Tag color='orange'>UTO</Tag>;
        default: return '-';
      }
    }
  },{
    title: 'Provider',
    dataIndex: 'provider_id',
    render: (_value,record) => {
      return (
        <span style={{whiteSpace:'nowrap'}}>
          {record?.provider?.full_name}
        </span>
      );
    }
  },{
    title: 'Covering Provider',
    dataIndex: 'covering_provider_id',
    render: (_value,record) => {
      if (record.status === 1 || record.status === 2) {
        return (
          <span style={{whiteSpace:'nowrap'}}>
            {(record.covering_provider) ? record?.covering_provider?.full_name : 'No Covering Provider'}
          </span>
        );
      }
      return record?.covering_provider?.full_name;
    }
  },{
    title: 'Comments',
    dataIndex: 'request_comment',
    render: (value,record) => {
      return (
        <div>
          {value && <div>REQ: {value}</div>}
          {record.response_comment && <div>RES: {record.response_comment}</div>}
        </div>
      )
    }
  },{
    title: 'Actions',
    align: 'right',
    render: (_value,record) => {
      switch (record.status) {
        case 1:  // requested
          return (
            <span style={{whiteSpace:'nowrap'}}>
              <Button onClick={()=>{showCommentModal(record.id,'approve')}} style={{background:'green',color:'white'}}>Approve</Button> &nbsp;
              <Button onClick={()=>{showCommentModal(record.id,'reject')}} style={{background:'red',color:'white'}}>Reject</Button>
            </span>
          );
        case 2: // approved
          return <Button onClick={()=>{cancelItem(record.id)}} style={{background:'black',color:'white'}}>Cancel</Button>
        default: return null;
      }
    }
  }] as any;

  const pagination = !isBusy && timeoffsListAPI?.data?.rows?.length ? {
    showQuickJumper: true,
    current: page,
    pageSize,
    total: timeoffsListAPI?.data?.count || 0,
  } : false;
  
  return (
    <Spin spinning={isBusy}>
      <OppositeEnds left={(
        <span>
          <Radio.Group value={filter} buttonStyle="solid" onChange={changeFilter} style={{ marginBottom: '1rem' }}>
            <Radio.Button value={1}>To Approve</Radio.Button>
            <Radio.Button value={2}>Currently Active</Radio.Button>
            <Radio.Button value={3}>Approved</Radio.Button>
            <Radio.Button value={4}>Cancelled</Radio.Button>
            <Radio.Button value={5}>Rejected</Radio.Button>
            <Radio.Button value={0}>All</Radio.Button>
          </Radio.Group>
        </span>)}
        right={createTimeoffButton()}/>
      <Table
        rowKey="id"
        size='small'
        columns={columns}
        dataSource={timeoffsListAPI?.data?.rows}
        pagination={pagination}
        onChange={onTableChange}
      />
      <CreateTimeoffModal
        visible={createModalVisible}
        onClose={()=>{closeModal('create')}}
        onCreate={onCreateTimeoff}
        directorId={activeUserId}
      />
      <CommentModal
        visible={commentModalVisible}
        action={commentModalAction}
        timeoff_id={commentModalTimeoffId}
        onClose={closeCommentModal}
        onApprove={onApproveItem}
        onReject={onRejectItem}
      />
    </Spin>
  );
};

export default DirectorTimeoff;
