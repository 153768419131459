import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Alert } from 'antd';

import PortalRegistration from './PortalRegistration';

class ChartStatusBar extends Component {
  static propTypes = {
    isChartOpen: PropTypes.bool,
    isChartOnHold: PropTypes.bool,
    isPortalRegistered: PropTypes.bool,
    isRegistrationComplete: PropTypes.string
  }

  state = {
    portalRegistrationDialogVisible: false
  }

  showPortalRegistrationDialog = () => {
    this.setState({
      portalRegistrationDialogVisible: true
    });
  }

  closePortalRegistrationDialog = () => {
    this.setState({
      portalRegistrationDialogVisible: false
    })
  }

  archivedAndLocked = () => {
    if (!this.props.isChartOpen && this.props.isChartOnHold) {
      return (
        <Alert
          message='CHART ARCHIVED AND CHART ON HOLD'
          banner
          style={{backgroundColor: 'rgb(245,240,255)'}}
        />
      );
    }
    return null;
  }

  archived = () => {
    if (!this.props.isChartOpen && !this.props.isChartOnHold) {
      return (
        <Alert
          message='CHART ARCHIVED'
          banner
          style={{backgroundColor: 'rgb(255,251,230)'}}
        />
      );
    }
    return null;
  }

  locked = () => {
    if (this.props.isChartOpen && this.props.isChartOnHold) {
      return (
        <Alert
          message='CHART ON HOLD'
          banner
          style={{backgroundColor: 'rgb(255,241,240)'}}
        />
      );
    }
    return null;
  }

  incompleteRegistration = () => {
    if (!this.props.isRegistrationComplete) {
      return (
        <Alert message="Patient Registration Incomplete"
          type="warning"
          showIcon
          banner
          description='Patient progress notes should not be written until the patient completes the registration forms.'
        />
      );
    }
    return null;
  }

  portalUnregistered = () => {
    const style = {
      backgroundColor: 'rgb(248,248,248)',
      padding: '15px',
      fontWeight: 'bold',
      textAlign:'center'
    }

    if (!this.props.isPortalRegistered) {
      return (
        <div style={style}>
          <PortalRegistration
            visible={this.state.portalRegistrationDialogVisible}
            closeDialog={this.closePortalRegistrationDialog}
          />
          <Button
            style={{color:'white',backgroundColor:'orange',borderColor:'orange'}}
            onClick={this.showPortalRegistrationDialog}>
            SEND PATIENT PORTAL REGISTRATION EMAIL
          </Button>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        { this.archivedAndLocked() }
        { this.archived() }
        { this.locked() }
        { this.incompleteRegistration() }
        { this.portalUnregistered() }
      </div>
    )
  }
}

export default ChartStatusBar;
