import React from 'react';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import queryString from 'qs';
import { withRouter } from 'react-router-dom';
import { Alert } from 'antd';
import get from 'lodash/get';

import logo from 'assets/images/lifestancelogo.png';
import googleLogo from 'assets/images/google-signin.png';
import microsoftLogo from 'assets/images/microsoft-signin.svg';

import { loginInProcess, loginSuccess } from '../../helpers/reduxSelectors';

class Login extends React.Component {
  state = {
    login_error: false,
  };

  componentDidMount() {
    localStorage.setItem('PCPA', Date.now());
    if (this.props.userLoggedIn) {
      this.props.history.push('/emr/patients/0');
    }
    const values = queryString.parse(this.props.location.search);
    if (values.err) {
      message.error('That account is not authorized to log in to this system');
    }
    const login_error = get(this.props.location, 'search').replace('?', '', false);
    if (login_error === 'err=1' && !this.state.login_error) {
      this.setState({ login_error: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.userLoggedIn && this.props.userLoggedIn) {
      this.props.history.push('/emr/patients/0');
    }
  }

  onCloseAlert = () => {
    this.setState({ login_error: false });
  };

  render() {
    if (this.props.loginInProcess || this.props.userLoggedIn) {
      return null;
    }
    return (
      <div id="login-wrapper">
        {this.state.login_error ? (
          <div style={{ marginBottom: '1em' }}>
            <Alert
              style={{ margin: 'auto' }}
              message="User Not Found"
              description="Please ensure that you've selected the correct email address."
              type="error"
              showIcon
              closable
              onClose={this.onCloseAlert}
            />
          </div>
        ) : null}
        <div id="login">
          <div style={{ textAlign: 'center' }}>
            <img src={logo} alt="pcpa logo text" width={350} style={{ paddingTop: '20px', paddingBottom: '60px' }} />
          </div>
          <div style={{ textAlign: 'center', paddingBottom: '1.15em' }}>
            <a href="/auth/google">
              <img src={googleLogo} alt="google signin" />
            </a>
          </div>
          <div style={{ textAlign: 'center', margin: '0 auto' }}>
            <Button
              type="default"
              size="large"
              href="/auth/microsoft"
              icon={<img src={microsoftLogo} alt="microsoft signin" style={{ padding: 0, margin: 0, width: '15px' }} />}>
                &nbsp;Sign in with Microsoft
              </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loginInProcess: loginInProcess(state, false),
    userLoggedIn: loginSuccess(state, false),
  };
};

export default connect(mapStateToProps, null)(withRouter(Login));
