import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Input, Button, Row, Col } from 'antd';
import { connect } from 'react-redux';

import * as actions from '../../../../actions/scheduler';
import { safe } from '../../../../helpers/reduxSelectors';
import { processEventNotification } from '../../../../helpers/util';

class ProviderInfo extends React.Component {

  componentDidMount() {
    this.props.getProviderBio(this.props.provider_id);
  }

  componentDidUpdate(prevProps) {
    if ( (prevProps.visible !== this.props.visible && this.props.visible ) ||
       (this.props.visible && prevProps.provider_id !== this.props.provider_id) ) {
      this.props.form.resetFields();
      this.props.getProviderBio(this.props.provider_id);
    }
    processEventNotification(prevProps,this.props,'saveEvent','Provider Info Saved');
  }

  saveForm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.saveProviderBio(this.props.provider_id,values);
      }
    });
  }

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 14 },
        sm: { span: 14 },
      },
    };
    const provider = this.props.providerList.find(x=>x.id === this.props.provider_id);
    const provider_name = safe(provider,'full_name');

    return (
      <div style={{padding:'5px'}}>
        <h3>Provider: {provider_name}</h3>
        <Form className='compressed-form' autoComplete='false'>

          <FormItem {...formItemLayout} label="Bio">
            {getFieldDecorator('bio', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(this.props,'provider_info.bio')
            })(
              <Input.TextArea rows={5}/>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Bio 2">
            {getFieldDecorator('bio2', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(this.props,'provider_info.bio2')
            })(
              <Input.TextArea rows={5}/>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Quote">
            {getFieldDecorator('quote', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(this.props,'provider_info.quote')
            })(
              <Input.TextArea rows={2}/>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Title">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(this.props,'provider_info.title')
            })(
              <Input.TextArea rows={2}/>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Languages Spoken">
            {getFieldDecorator('languages', {
              initialValue: safe(this.props,'provider_info.languages')
            })(
              <Input.TextArea />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="VSee Room">
            {getFieldDecorator('telemedicine_room', {
              initialValue: safe(this.props,'provider_info.telemedicine_room')
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="VSee URL">
            {getFieldDecorator('telemedicine_url', {
              initialValue: safe(this.props,'provider_info.telemedicine_url')
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Image">
            {getFieldDecorator('image_url', {
              initialValue: safe(this.props,'provider_info.image_url')
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Skills'>
            {getFieldDecorator('skills', {
              initialValue: safe(this.props,'provider_info.skills')
            })(
              <Checkbox.Group style={{ width: '100%'}}>
                <Row>
                  <Col span={12}><Checkbox value='cbt'>CBT</Checkbox></Col>
                  <Col span={12}><Checkbox value='dbt'>DBT</Checkbox></Col>
                </Row>
              </Checkbox.Group>
            )}
          </FormItem>

          <div style={{textAlign:'center',paddingTop:'10px'}}>
            <Button type='primary' onClick={this.saveForm}>Save</Button>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    provider_info: safe(state,'scheduler.provider_info.get.data',{}),
    saveEvent: safe(state,'scheduler.provider_info.save',{}),
    providerList: safe(state,'scheduler.provider.list.data',[]),
  }
}

export default connect(mapStateToProps,actions)(Form.create()(ProviderInfo));
