import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, InputNumber, DatePicker } from 'antd';

import * as billing_actions from '../../../actions/billing';
import * as shared_actions from '../../../actions/shared';
import { safe } from '../../../helpers/reduxSelectors';
import DiagnosisControl from './Patients_/Ledger_/DiagnosisControl';

class NewServiceModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    closeModal: PropTypes.func,
    patientId: PropTypes.number
  }

  static defaultProps = {
    visible: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible === true) {
      this.props.form.resetFields();
      this.props.getProductList();
      this.props.getLocationsList();
      this.props.getUserList();
      this.props.getCptList();
      this.props.getCarrierList();
      this.props.getTpaList();
    }
  }

  handleOk = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.createBillingServiceLine(this.props.patientId,values);
        this.props.closeModal();
      }
    });
  }

  handleCancel = () => {
    this.props.closeModal();
  }

  newProductForm = () => {
    const FormItem = Form.Item;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };

    const providersList = this.props.userList.filter(x=>(x.roles.includes('therapist') || x.roles.includes('prescriber')));

    return (
      <div>
        <FormItem {...formItemLayout} label="Service Location">
          {getFieldDecorator('location_id', {
            rules: [{ required: true, message: " " }],
          })(
            <Select
              showSearch={true}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              { this.props.locationList.map(x=><Option key={x.id} value={x.id}>{x.name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Rendering Provider">
          {getFieldDecorator('rendering_provider_id', {
            rules: [{ required: true, message: " " }],
          })(
            <Select
              showSearch={true}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              { providersList.map(x=><Option key={x.id} value={x.id}>{x.full_name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Servicing Provider">
          {getFieldDecorator('servicing_provider_id', {
            rules: [{ required: true, message: " " }],
          })(
            <Select allowClear={true}>
              { providersList.map(x=><Option key={x.id} value={x.id}>{x.full_name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Patient Rate">
          {getFieldDecorator('pt_rate', {
            rules: [{ required: true, message: " " }],
          })(
            <InputNumber min={0} max={999} precision={2}/>
          )}
        </FormItem>
      </div>
    );
  }

  newSessionForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };
    const providersList = this.props.userList.filter(x=>(x.roles.includes('therapist') || x.roles.includes('prescriber')));

    return (
      <div>
        <FormItem {...formItemLayout} label="Rendering Provider">
          {getFieldDecorator('rendering_provider_id', {
            rules: [{ required: true, message: " " }],
          })(
            <Select
              showSearch={true}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              { providersList.map(x=><Option key={x.id} value={x.id}>{x.full_name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Servicing Provider">
          {getFieldDecorator('servicing_provider_id', {
            rules: [{ required: true, message: " " }],
          })(
            <Select
              showSearch={true}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              { providersList.map(x=><Option key={x.id} value={x.id}>{x.full_name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Service Location">
          {getFieldDecorator('location_id', {
            rules: [{ required: true, message: " " }],
          })(
            <Select>
              { this.props.locationList.map(x=><Option key={x.id} value={x.id}>{x.name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="CPT">
          {getFieldDecorator('cpt_codes', {
            rules: [{ required: true, message: " " }],
          })(
            <Select mode='multiple'>
              { this.props.cptList.map(x=><Option key={x.code} value={x.code}>{x.code}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Diagnosis">
          {getFieldDecorator('diagnosis', {
            rules: [{ required: true, message: " " }],
            initialValue: []
          })(
            <DiagnosisControl />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Units">
          {getFieldDecorator('units', {
            rules: [{ required: true, message: " " }],
            initialValue: 1
          })(
            <InputNumber min={1} max={10}/>
          )}
        </FormItem>

      </div>
    );
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };

    const product_id = this.props.form.getFieldValue('product_id');

    return (
      <Form autoComplete="off" className='editpatient-form'>
        <FormItem {...formItemLayout} label="Service Type">
          {getFieldDecorator('product_id', {
            rules: [{ required: true, message: " " }],
          })(
            <Select placeholder='Choose a service type'>
              {this.props.productList.filter(x=>x.active).map(x=><Option key={x.id}>{x.name}</Option>)}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Date of Service">
          {getFieldDecorator('dos', {
            rules: [{ required: true, message: " " }],
          })(
            <DatePicker format='M/D/YYYY' />
          )}
        </FormItem>

        { product_id && product_id==="1" && this.newSessionForm() }
        { product_id && product_id !== "1" && this.newProductForm() }
      </Form>
    );
  }

  render() {
    return (
      <Modal
        title='New Service Line'
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        width={800}
        bodyStyle={{padding:'5px'}}
      >
        {this.renderForm()}
      </Modal>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    productList: safe(state,'billing.product.list.data',[]),
    carrierList: safe(state,'shared.carrier.list.data',[]),
    tpaList: safe(state,'billing.tpa.list.data',[]),
    userList: safe(state,'shared.user.list.data',[]),
    cptList: safe(state,'billing.cpt.list.data',[]),
    locationList: safe(state,'shared.location.list.data',[]),
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(Form.create()(NewServiceModal));
