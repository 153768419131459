import React from 'react';
import PropTypes from 'prop-types';

import { safe } from '../../../../../helpers/reduxSelectors';
import Time from '../../../../shared/Time';

const type_type = ['', 'Recurring', 'One-Time', 'Extra'];
const days_of_week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const video_types = ['Video/Office', 'Video Only', 'Office Only'];
const age_types = ['Adult/Child', 'Adult Only', 'Child Only'];

const fields = {
  type: { title: 'Type', type: 'type' },
  start_time: { title: 'Starting Time', type: 'time' },
  end_time: { title: 'Ending Time', type: 'time' },
  allow_20m: { title: 'Allow 20 min', type: 'boolean' },
  allow_30m: { title: 'Allow 30 min', type: 'boolean' },
  allow_45m: { title: 'Allow 45 min', type: 'boolean' },
  allow_60m: { title: 'Allow 60 min', type: 'boolean' },
  duration: { title: 'Duration', type: 'duration' },
  day_of_week: { title: 'Day of Week', type: 'dow' },
  allow_video: { title: 'Video', type: 'video' },
  age_category: { title: 'Ages', type: 'age' },
  allow_intake: { title: 'Intakes', type: 'boolean' },
  fixed_date: { title: 'Fixed Date', type: 'string' },
  location_id: { title: 'Location', type: 'location' },
};

class HistoryTemplateItem extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    locations: PropTypes.array,
  };

  getLocation = (id) => {
    const result = safe(this.props, 'locations', []).find((x) => x.id === id);
    return result ? result.abbr : '';
  };

  renderValue = (x, type) => {
    switch (type) {
      case 'type':
        return type_type[x];
      case 'time':
        return new Time(x).toString(false);
      case 'boolean':
        return x ? 'Yes' : 'No';
      case 'dow':
        return days_of_week[x];
      case 'video':
        return video_types[x];
      case 'age':
        return age_types[x];
      case 'duration':
        return Math.round((100 * x) / 60) / 100 + ' hrs';
      case 'location':
        return this.getLocation(x);
      default:
        return x;
    }
  };

  renderValues = () => {
    return Object.keys(fields).map((field) => {
      const { title, type } = fields[field];
      const value = safe(this.props.data, field);
      if (value) {
        return (
          <div key={field}>
            {title}: {this.renderValue(value, type)}
          </div>
        );
      }
      return false;
    });
  };

  renderUpdate = () => {
    const changed_fields = Object.keys(fields).filter((field) => {
      return safe(this.props.data, field + '.before') !== safe(this.props.data, field + '.after');
    });
    return changed_fields.map((field) => {
      const { title, type } = fields[field];
      const before = this.renderValue(safe(this.props.data, field + '.before'), type);
      const after = this.renderValue(safe(this.props.data, field + '.after'), type);
      return (
        <div key={field}>
          {title}: {before} {'->'} {after}
        </div>
      );
    });
  };

  render() {
    if (!this.props.data) {
      return null;
    } else if (safe(this.props.data, 'id.before')) {
      return <div>{this.renderUpdate()}</div>;
    } else {
      return <div>{this.renderValues()}</div>;
    }
  }
}

export default HistoryTemplateItem;
