import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, message, Spin } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/settings';

class EmailsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id,10)
    }
  }

  componentDidMount() {
    if (this.state.id !== 0) {
      this.props.emailTemplateLoad(this.state.id);
    } else {
      this.props.emailTemplateLoadClear();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load !== this.props.load) {
      if (this.props.load.error) {
        this.props.history.push('/settings/emails');
        message.error('Unable to load email templates information');
      }
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.success) {
        this.props.emailTemplateSaveClear();
        this.props.history.push('/settings/emails');
        message.success('Email template saved');
      } else if (this.props.error) {
        message.error('Unable to save email template');
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.emailTemplateSave(this.state.id,values);
      }
    });
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    //const { TextArea } = Input;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const data = safe(this.props,'load.data',{});

    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off" >

        <FormItem {...formItemLayout} label="Title">
          {getFieldDecorator('title', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.title
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Unique Name">
          {getFieldDecorator('unique_name', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.unique_name
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="From Address">
          {getFieldDecorator('fromAddress', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.fromAddress
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Email Message"
        >
          {getFieldDecorator('content', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.content
          })(
            <Input.TextArea rows={15}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Description"
        >
          {getFieldDecorator('description', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.description
          })(
            <Input.TextArea rows={3}/>
          )}
        </FormItem>

        <br />

        <Row>
          <Col xs={36} sm={16}><Button htmlType="submit" style={{float:'right'}} disabled={this.props.isLoading} type="primary">Save</Button></Col>
        </Row>
      </Form>
    );
  }

  render() {
    const name = safe(this.props,'load.data.name','None');

    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <h3>Email Template Edit / {name}</h3>
          { this.renderForm() }
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'settings.emails_load.processing',false) ||
      safe(state,'settings.emails_save.processing',false)
    ) ? true : false,
    load: safe(state,'settings.emails_load',{}),
    save: safe(state,'settings.emails_save',{}),
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(EmailsEdit)));
