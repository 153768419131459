import React from 'react';
import { Table } from 'antd';
import moment from 'moment';

import { safe } from '../../../../../helpers/reduxSelectors';

class ServiceLineHistoryTable extends React.Component {
  getCellValue = (item) => {
    if (Array.isArray(item)) {
      return item.before.join(',') + ' -> ' + item.after.join(',');
    } else {
      return item.before + '->' + item.after;
    }
  }
  renderChanges = (value) => {
    const labels = {
      'units': 'Units',
      'cpt_codes': 'CPT',
      'modifiers': 'Modifiers',
      'diagnosis': 'Diagnosis',
      'dos': 'DOS',
      'rendering_provider_id': 'Rendering Provider',
      'location_id': 'Location',
      'carrier_id': 'Carrier',
      'tpa_id': 'TPA'
    }
    return (
      <div>
        {Object.keys(value).map(x=>{
          return (
            <div key={x}>
              <span style={{fontWeight:'bold'}}>{labels[x]}: </span> { this.getCellValue(value[x]) }
            </div>
          )
        })}
      </div>
    );
  }

  render() {
    const columns=[{
      title: 'Date',
      dataIndex: 'createdAt',
      width: 175,
      render: (text,record) => {
        return moment(text).format('MM/DD/YYYY, HH:mm:ss');
      }
    },{
      title: 'User',
      dataIndex: 'user',
      width: 50,
      render: (text,record) => {
        return safe(record,'creator.initials');
      }
    },{
      title: 'Changes',
      dataIndex: 'changed_fields',
      render: (value,record) => {
        return this.renderChanges(value);
      }
    }];

    return (
      <div>
        <Table
          dataSource={this.props.data}
          columns={columns}
          bordered={false}
          showHeader={true}
          pagination={false}
          size='small'
          rowKey='id'
          className='sl_history_table'
          style={{backgroundColor:'white',marginTop:'5px'}}
        />
      </div>
    );
  }
}

export default ServiceLineHistoryTable;
