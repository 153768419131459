import React from 'react';
import { connect } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';

import { safe } from '../../helpers/reduxSelectors';
import * as actions from '../../actions/shared';
import PatientSelectorAdvanced from './PatientSelectorAdvanced';

class PatientSelector extends React.Component {
  constructor(props) {
    super(props);
    this.conflict = false;
  }

  state = {
    value: '',
    patientSelection: undefined,
    advancedVisible: false,
  };

  getSource = () => {
    return this.props.patientList.map((item) => {
      //  const style = (item.oncareteam) ? { color: 'green'} : {};
      let val = item.full_name + ' (' + item.id + ')';
      if (!item.open) {
        val = '@' + val;
      }
      if (item.on_hold) {
        val = '#' + val;
      }
      if (item.oncareteam) {
        val = '> ' + val;
      }
      return {
        value: item.id,
        text: val,
      };
    });
  };
  // Turning this green caused an odd error to appear in the Autocomplete component
  //  text: <span style={style}>{val}</span>

  handleSearch = (value) => {
    //console.log('onSearch: ' + value);
    this.props.getPatientList(value, true, true);
  };

  renderOption = (item) => {
    //const Option = Select.Option;
    const Option = AutoComplete.Option;
    const style = item.oncareteam ? { color: 'green' } : {};
    return (
      <Option key={item.id}>
        <span style={style}>
          {!item.open ? '@' : ''}
          {item.full_name}
        </span>
      </Option>
    );
  };

  selectPatient = (value, option) => {
    this.props.patientListClear();
    //console.log('onSelect: ' + value);
    this.setState({
      patientSelection: undefined,
    });
    this.conflict = true;
    this.props.onSelectPatient(parseInt(value, 10), option.props.children);
  };

  onChangePatientValue = (value) => {
    if (this.conflict) {
      this.conflict = false;
    } else {
      //console.log('onChange: ' + value);
      this.setState({
        patientSelection: value,
      });
    }
  };

  onFocus = () => {
    //console.log('onFocus')
  };

  onBlur = () => {
    //console.log('onBlue');
    this.props.patientListClear();
    this.setState({
      patientSelection: undefined,
    });
  };

  filterOption = (input, option) => {
    //console.log('filter',input,option);
  };

  showAdvanced = () => {
    this.setState({
      advancedVisible: true,
    });
  };

  closeAdvanced = () => {
    this.setState({
      advancedVisible: false,
    });
  };

  render() {
    const style = {
      width: '300px',
      marginLeft: '30px',
      ...this.props.style,
    };

    return (
      <span>
        <AutoComplete
          style={style}
          placeholder="Search by patient name, id, or dob"
          dataSource={this.getSource()}
          onSearch={this.handleSearch}
          value={this.state.patientSelection || ''}
          onChange={this.onChangePatientValue}
          onSelect={this.selectPatient}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          defaultActiveFirstOption={false}
        >
          <Input suffix={<SearchOutlined onClick={this.showAdvanced} />} />
        </AutoComplete>
        <PatientSelectorAdvanced
          visible={this.state.advancedVisible}
          onClose={this.closeAdvanced}
          onSelectPatient={this.props.onSelectPatient}
        />
      </span>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    patientList: safe(state, 'shared.patient.list.data', []),
  };
};

export default connect(mapStateToProps, actions)(PatientSelector);
