import React from 'react';
//import moment from 'moment';

class TA1 extends React.Component {
  formatDate = (d) => {
    const year = d.substring(0,2);
    const month = d.substring(2,4);
    const day = d.substring(4,6);
    return month + '/' + day + '/' + year;
  }

  formatTime = (t) => {
    return t.substring(0,2) + ':' + t.substring(2,4);
  }

  message = (x) => {
    if (x === 'A') {
      return 'Accepted';
    } else if (x === 'R') {
      return 'Rejected';
    } else if (x === 'E') {
      return 'Accepted But With Errors';
    }
    return 'Unknown';
  }

  render() {
    const { data } = this.props;

    return (
      <div>
        <div><strong>Interchange PCPA ID: </strong> {data.interchange_control_number}</div>
        <div><strong>Interchange Date/Time: </strong> {this.formatDate(data.interchange_date)} {this.formatTime(data.interchange_time)}</div>
        <div><strong>Interchange Status: </strong> {this.message(data.interchange_status)}</div>
        <div><strong>Interchange Message: </strong> {data.interchange_note_code_text}</div>
      </div>
    )
  }
}

export default TA1;
