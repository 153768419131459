import React from 'react';
import { AutoComplete, Tag } from 'antd';
import { connect } from 'react-redux';

import * as actions from '../../../../../actions/billing';
import { safe } from '../../../../../helpers/reduxSelectors';

class DiagnosisControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_diagnosis: null,
      tag_list: []
    }
    this.debounceSelect = false;
  }

  componentDidMount() {
    if (this.props.value) {
      this.setState({
        tag_list: this.props.value
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        tag_list: this.props.value
      });
    }
  }

  acOnChange = (value) => {
    if (this.debounceSelect) {
      this.debounceSelect = false;
    } else {
      this.setState({new_diagnosis:value});
    }
  }

  acOnSelect = (id) => {
    const idInt = parseInt(id,10);
    const value = this.props.diagnosisList.find(q=>q.id===idInt);
    if (!this.state.tag_list.find(x=>x===value.icd10cm)) {
      this.debounceSelect = true;
      this.setState({
        tag_list: [...this.state.tag_list,value.icd10cm],
        new_diagnosis: null
      },() => {
        this.props.onChange(this.state.tag_list);
      });
    }
  }

  removeTag = (removed_tag) => {
    this.setState({
      tag_list: this.state.tag_list.filter(q=>q !== removed_tag)
    },() => {
      this.props.onChange(this.state.tag_list);
    });
  }

  getSource = () => {
    if (!this.props.diagnosisList) {
      return [];
    }
    return this.props.diagnosisList.map(item => {
      return {
        value:item.id,
        text:item.icd10cm+'-'+item.description
      }
    });
  }

  render() {
    return (
      <div>
        <AutoComplete
          style={{width:'100%',marginRight:'10px'}}
          className="certain-category-search"
          placeholder="Search for diagnosis..."
          allowClear={true}
          dataSource={this.getSource()}
          onSearch={(value) => this.props.getDiagnosisList(value)}
          value={this.state.new_diagnosis}
          onChange={this.acOnChange}
          onSelect={this.acOnSelect}
        />
        <div>
          { this.state.tag_list && this.state.tag_list.map(x=><Tag style={{lineHeight:'30px',height:'30px'}} color="#87d068" key={x} closable onClose={()=>{this.removeTag(x)}}>{x}</Tag>) }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    diagnosisList: safe(state,'billing.diagnosis.list.data',[])
  }
}

export default connect(mapStateToProps,actions)(DiagnosisControl);
