/**
 * AnthemUncredentialedSessions.tsx
 */
import reports from 'reports';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import ReportWrapper from 'components/App_/ReportsV2_/ReportWrapper';
import InlineFormWrapper from 'components/App_/ReportsV2_/InlineFormWrapper';
import Table from 'components/App_/ReportsV2_/Table';
import columns from 'components/App_/ReportsV2_/TableColumns_/anthemUncredentialedSessions';
import { InlineDataT } from 'reports';
import DisplayReport from 'components/App_/ReportsV2_/InlineControls_/DisplayReport';
import DateRangeSelector from 'components/App_/ReportsV2_/InlineControls_/DateRangeSelector';

import styles from './styles';

const reportId = 31;
const page = 1;
const pageSize = 15;

const AnthemUncredentialedSessions: React.FC = () => {
  const inlineData = useContextSelector(InlineContext, (c) => c.state.inlineData) as InlineDataT;
  const report = reports.find((r) => r.id === reportId);

  const formOptions = {
    page,
    pageSize,
    reportId,
  };

  const tableOptions = {
    data: inlineData,
    columns,
    page,
    pageSize,
    reportId,
    rowKey: 'key',
    total: inlineData?.length,
  };

  return (
    <ReportWrapper>
      <h3>{report?.name}</h3>
      <div style={styles.root}>
        <InlineFormWrapper {...formOptions}>
          <DateRangeSelector disableEndDate={true} />
          <DisplayReport />
        </InlineFormWrapper>
      </div>
      <Table {...tableOptions} />
    </ReportWrapper>
  );
};

export default AnthemUncredentialedSessions;
