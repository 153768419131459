import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';

const columns: Array<ColumnI> = [
  {
    title: 'Folder',
    dataIndex: 'folderName',
    render: (val: string) => val.split('T')[0],
  },
  {
    title: 'Total Files',
    dataIndex: 'fileCount',
  },
  {
    title: 'PCPA Files',
    dataIndex: 'pcpaFilesCount',
  },
  {
    title: 'Unprocessed Files',
    dataIndex: 'missingErasCount',
  },
];

export default columns;
