import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';
import { Table, Tag, Button, Radio, Modal, Select } from 'antd';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/scheduler';
import Time from '../../shared/Time';
import OppositeEnds from '../../shared/OppositeEnds';
import CreateTimeoffModal from './TimeOff_/CreateTimeoffModal';
import CommentModal from './TimeOff_/CommentModal';
import CoveringProviderModal from './TimeOff_/CoveringProviderModal';
import Stats from './TimeOff_/Stats';
import { processEventNotification } from '../../../helpers/util';

class TimeOff extends React.Component {
  state = {
    expanded_rows: [],

    createModalVisible: false,

    commentModalVisible: false,
    commentModalTimeoffId: null,
    commentModalAction: null,

    coveringProviderModalVisible: false,
    coveringProviderModalData: null,

    page: 1,
    pageSize: 50,
    filter: 1, // To Approve List
    provider_id: 0
  }

  componentDidMount() {
    this.props.getProviderList();
    this.props.getAdminTimeoffList(
      this.state.filter,
      this.state.provider_id,
      this.state.page,
      this.state.pageSize
    );
  }

  componentDidUpdate(prevProps,prevState) {
    if (prevState.page !== this.state.page || prevState.filter !== this.state.filter ||
        prevState.provider_id !== this.state.provider_id) {
      this.props.getAdminTimeoffList(
        this.state.filter,
        this.state.provider_id,
        this.state.page,
        this.state.pageSize
      );
    }

    processEventNotification(prevProps,this.props,'updateEvent','Covering Provider Updated');
    processEventNotification(prevProps,this.props,'approveEvent','Time Off Approved');
    processEventNotification(prevProps,this.props,'rejectEvent','Time Off Rejected');
    processEventNotification(prevProps,this.props,'cancelEvent','Time Off Cancelled');
  }

  changePage = ({current}) => {
    this.setState({
      page: current
    });
  }

  changeFilter = (e) => {
    this.setState({
      filter: e.target.value,
      page: 1
    });
  }

  showModal = (name,data=null) => {
    if (name === 'create') {
      this.setState({
        createModalVisible: true
      });
    }
  }

  closeModal = (name) => {
    if (name === 'create') {
      this.setState({
        createModalData: null,
        createModalVisible: false
      });
    }
  }

  showCommentModal = (timeoff_id,action) => {
    this.setState({
      commentModalVisible: true,
      commentModalTimeoffId: timeoff_id,
      commentModalAction: action,
    });
  }

  closeCommentModal = () => {
    this.setState({
      commentModalVisible: false
    });
  }

  showCoveringProviderModal = (data) => {
    this.setState({
      coveringProviderModalVisible: true,
      coveringProviderModalData: data
    });
  }

  closeCoveringProviderModal = () => {
    this.setState({
      coveringProviderModalVisible: false
    })
  }

  cancelItem = (timeoff_id) => {
    const This = this;
    Modal.confirm({
      title: 'Are you sure you want to cancel this approval?',
      content: 'Doing so will remove all related PTO/UTO time blocks from the provider\'s schedule',
      onOk() {
        This.props.cancelTimeoff(timeoff_id,'',
          This.state.filter,
          This.state.provider_id,
          This.state.page,
          This.state.pageSize
        );
      },
      onCancel() {},
    });
  }

  approveItem = (timeoff_id,description) => {
    this.props.approveTimeoff(timeoff_id,description,
      this.state.filter,
      this.state.provider_id,
      this.state.page,
      this.state.pageSize);
  }

  rejectItem = (timeoff_id,description) => {
    this.props.rejectTimeoff(timeoff_id,description,
      this.state.filter,
      this.state.provider_id,
      this.state.page,
      this.state.pageSize);
  }

  updateItem = (timeoff_id,data) => {
    this.props.updateAdminTimeoff(timeoff_id,data,
      this.state.filter,
      this.state.provider_id,
      this.state.page,
      this.state.pageSize);
  }

  displayDateTimeRange = (start_date,end_date,start_time,end_time,duration) => {
    if (duration === 1) {
      // Partial-Day Timeoff
      try {
        const st = (new Time(start_time)).toString(false);
        const et = (new Time(end_time)).toString(false);
        const sd = moment(start_date).format('MM/DD/YYYY');
        return <span style={{whiteSpace:'nowrap'}}>{sd},{st}-{et}</span>;
      } catch (err) {
        return null;
      }
    } else if (duration === 2) {
      const sd = moment(start_date).format('MM/DD/YYYY');
      const ed = moment(end_date).format('MM/DD/YYYY');
      if (moment(start_date).isSame(end_date,'day')) {
        return <span style={{whiteSpace:'nowrap'}}>{sd}</span>;
      } else {
        return <span style={{whiteSpace:'nowrap'}}>{sd}-{ed}</span>;
      }
    }
  }

  expandedRow = (record, index, indent, expanded) => {
    const status = (record.status === 1 || record.status === 3) ? true : false;
    return <Stats timeoff_id={record.id} expanded={expanded} status={status}/>;
  }

  onExpand = (expanded,record) => {
    this.setState({
      expanded_rows: (expanded) ? [record.id] : []
    });
  }

  onChangeProvider = (x) => {
    this.setState({
      provider_id: (x) ? x : 0
    });
  }

  createTimeoffButton = () => {
    if (this.state.filter ===1) {
      return <Button type='primary' onClick={()=>this.showModal('create')}>Create New Time Off Request</Button>
    }
    return null;
  }

  render() {
    const columns = [{
      title: 'Requested',
      dataIndex: 'createdAt',
      render: (value) => {
        return (
          <span style={{whiteSpace:'nowrap'}}>{moment(value).format('MM/DD/YYYY H:mm A')}</span>
        );
      }
    },{
      title: 'Dates/Times',
      dataIndex: 'start_date',
      render: (value,record) => this.displayDateTimeRange(record.start_date,
        record.end_date,record.start_time,record.end_time,record.duration)
    },{
      title: 'Status',
      dataIndex: 'status',
      render: (value) => {
        switch (value) {
          case 1: return <Tag color='blue'>Request</Tag>;
          case 2: return <Tag color='green'>Approved</Tag>;
          case 3: return <Tag color='red'>Rejected</Tag>;
          case 4: return <Tag color='orange'>Cancelled</Tag>;
          default: return '-';
        }
      }
    },{
      title: 'Type',
      dataIndex: 'type',
      render: (value) => {
        switch (value) {
          case 4: return <Tag color='purple'>PTO</Tag>;
          case 5: return <Tag color='orange'>UTO</Tag>;
          default: return '-';
        }
      }
    },{
      title: 'Provider',
      dataIndex: 'provider_id',
      render: (value,record) => {
        return (
          <span style={{whiteSpace:'nowrap'}}>
            {safe(record,'provider.full_name')}
          </span>
        );
      }
    },{
      title: 'Covering Provider',
      dataIndex: 'covering_provider_id',
      render: (value,record) => {
        if (record.status === 1 || record.status === 2) {
          return (
            <span style={{whiteSpace:'nowrap'}}>
              {(record.covering_provider) ? safe(record,'covering_provider.full_name') : 'No Covering Provider'}
              <Button type='link' icon={<EditOutlined />} onClick={()=>{this.showCoveringProviderModal(record)}}/>
            </span>
          );
        }
        return safe(record,'covering_provider.full_name');
      }
    },{
      title: 'Comments',
      dataIndex: 'request_comment',
      render: (value,record) => {
        return (
          <div>
            {value && <div>REQ: {value}</div>}
            {record.response_comment && <div>RES: {record.response_comment}</div>}
          </div>
        )
      }
    },{
      title: 'Actions',
      align: 'right',
      render: (value,record) => {
        switch (record.status) {
          case 1:  // requested
            return (
              <span style={{whiteSpace:'nowrap'}}>
                <Button onClick={()=>{this.showCommentModal(record.id,'approve')}} style={{background:'green',color:'white'}}>Approve</Button> &nbsp;
                <Button onClick={()=>{this.showCommentModal(record.id,'reject')}} style={{background:'red',color:'white'}}>Reject</Button>
              </span>
            );
          case 2: // approved
            return <Button onClick={()=>{this.cancelItem(record.id)}} style={{background:'black',color:'white'}}>Cancel</Button>
          default: return null;
        }
      }
    }];

    const This = this;
    const pl = [...this.props.providerList];
    pl.unshift({
      id:0,
      full_name: 'All Providers'
    });

    return (
      <div style={{padding: '5px'}}>
        <h3>Time Off Request Tool</h3>
        <OppositeEnds left={(
          <span>
            <Radio.Group value={this.state.filter} buttonStyle="solid" onChange={this.changeFilter}>
              <Radio.Button value={1}>To Approve</Radio.Button>
              <Radio.Button value={2}>Currently Active</Radio.Button>
              <Radio.Button value={3}>Approved</Radio.Button>
              <Radio.Button value={4}>Cancelled</Radio.Button>
              <Radio.Button value={5}>Rejected</Radio.Button>
              <Radio.Button value={0}>All</Radio.Button>
            </Radio.Group>
            <Select
              allowClear
              showSearch
              style={{width:'300px',paddingLeft:'30px'}}
              value={this.state.provider_id}
              onChange={this.onChangeProvider}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {pl.map(x=><Select.Option key={x.id} value={x.id}>{x.full_name}</Select.Option>)}
            </Select>
          </span>)}
          right={this.createTimeoffButton()}/>
        <div style={{paddingBottom:'20px'}}/>
        <Table
          columns={columns}
          dataSource={this.props.data}
          size='small'
          onChange={this.changePage}
          expandedRowRender={this.expandedRow}
          onExpand={this.onExpand}
          expandedRowKeys={this.state.expanded_rows}
          rowKey='id'
          pagination={{
            current: this.state.page,
            pageSize: this.state.pageSize,
            total: this.props.count,
            showQuickJumper: true
          }}
        />

        <CreateTimeoffModal
          visible={this.state.createModalVisible}
          onClose={()=>{this.closeModal('create')}}
          onCreate={(data)=>This.props.createAdminTimeoff(data.provider_id,
            data,
            this.state.filter,
            this.state.provider_id,
            this.state.page,
            this.state.pageSize)}
        />

        <CommentModal
          visible={this.state.commentModalVisible}
          action={this.state.commentModalAction}
          timeoff_id={this.state.commentModalTimeoffId}
          onClose={this.closeCommentModal}
          onApprove={this.approveItem}
          onReject={this.rejectItem}
        />

        <CoveringProviderModal
          visible={this.state.coveringProviderModalVisible}
          data={this.state.coveringProviderModalData}
          onClose={this.closeCoveringProviderModal}
          onUpdate={this.updateItem}
        />

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    providerList: safe(state,'scheduler.provider.list.data',[]),
    count: safe(state,'scheduler.timeoff.list.data.count',0),
    data: safe(state,'scheduler.timeoff.list.data.rows',[]),
    updateEvent: safe(state,'scheduler.timeoff.update',{}),
    approveEvent: safe(state,'scheduler.timeoff.approve',{}),
    rejectEvent: safe(state,'scheduler.timeoff.reject',{}),
    cancelEvent: safe(state,'scheduler.timeoff.cancel',{})
  }
}

export default connect(mapStateToProps,actions)(TimeOff);
