import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Table, Spin, Modal } from 'antd';
import moment from 'moment';

import * as actions from '../../../actions/settings';
import { safe } from '../../../helpers/reduxSelectors';

class Holidays extends Component {

  componentDidMount() {
    this.props.listHolidays();
  }

  onClickAddHoliday = () => {
    this.props.history.push('/settings/holidays/0');
  }

  removeHoliday = ({id}) => {
    const This = this;

    Modal.confirm({
      title: 'Are you sure you want to remove this holiday?',
      onOk() {
        This.props.removeHoliday(id)
      },
      onCancel() { }
    });
  }

  render() {
    const columns = [{
      title: "Date",
      dataIndex: "date",
      width: 150,
      key: "date",
      render: (val,record) => {
        return (
          <Link
            to={"/settings/holidays/"+record.id}
            style={{whiteSpace: 'nowrap'}}
          >
            {moment(val).format('MM/DD/YYYY')}
          </Link>
        )
      }
    },{
      title: 'Name',
      dataIndex: 'name'
    },{
      title: 'Action',
      width: 100,
      fixed: 'right',
      render: (text,record) => {
        return (
          <Button
            size='small'
            type='link'
            onClick={() => { this.removeHoliday(record) }}
          >
            <DeleteOutlined />
          </Button>
        );
      }
    }];

    return (
      <Spin spinning={this.props.isBusy} size='large'>
        <div style={{padding:'10px'}}>
          <h3>Company Holidays</h3>
          <Table
            dataSource={this.props.list}
            rowKey="id"
            size='small'
            pagination={false}
            columns={columns}
          />
          <div style={{position:'fixed',bottom:40,right:40}}>
            <Button
              disabled={this.props.isBusy}
              onClick={this.onClickAddHoliday}
              size='large'
              shape='circle'
              type="primary"
              icon={<PlusOutlined />}/>
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    list: safe(state,'settings.holidays.list.data',[]),
    isBusy: (
      safe(state,'settings.holidays.list.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(Holidays);
