import React from 'react';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';

import HeaderLogo from '../shared/HeaderLogo';
import AppSelector from '../shared/AppSelector';
import Registrations from './Intakes_/Registrations';
import Accounts from './Intakes_/Accounts';
import Evals from './Intakes_/Evals';
import Timeout from '../shared/Timeout';

import { safe, isAdmin } from '../../helpers/reduxSelectors';
import { VLayout, VLayoutHeader, VLayoutContent, HLayout, HLayoutLeft, HLayoutContent } from '../shared/Layout';

class Intakes extends React.Component {

  renderNav = () => {
    let pathname = this.props.location.pathname;
    let folders = pathname.split('/');
    let page = folders[2];

    return (
      <Menu
        style={{padding: '0px', borderRight: '0px'}}
        onClick={(value) => { this.props.history.push('/intakes/'+value.key) }}
        selectedKeys={[page]}
      >
        <Menu.Item key="accounts">Portal Accounts</Menu.Item>
        <Menu.Item key="registrations">Registrations</Menu.Item>
      </Menu>
    );
  }
//        <Menu.Item key="intakes">Intakes</Menu.Item>

  render() {
    if (!this.props.isAdmin) {
      return (
        <div>
          You are not authorized to use this program.
        </div>
      )
    }

    const headerStyle = {
      background: '#2b8ed8',
      color:'white',
      paddingLeft:'0px',
      paddingRight:'0px',
      paddingTop:'5px',
      height: '50px',
      minHeight: '42px'
    };

    return (
      <Timeout>
        <VLayout>
          <VLayoutHeader style={headerStyle}>
            <HeaderLogo title='PCPA Intakes'/>
            <AppSelector activeUserName={this.props.activeUserName}/>
          </VLayoutHeader>

          <VLayoutContent>
            <HLayout>
              <HLayoutLeft style={{width:'200px',minWidth:'200px'}}>
                { this.renderNav() }
              </HLayoutLeft>
              <HLayoutContent>
                <Switch>
                  <Redirect exact from="/intakes" to="/intakes/accounts" />
                  <Route path="/intakes/accounts" component={Accounts}/>
                  <Route path="/intakes/registrations" component={Registrations}/>
                  <Route path="/intakes/evals" component={Evals}/>
                </Switch>
              </HLayoutContent>
            </HLayout>
          </VLayoutContent>
        </VLayout>
      </Timeout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeUserName: safe(state,'shared.domain.activeUser.full_name'),
    isAdmin: isAdmin(state),
  }
}

export default connect(mapStateToProps,null)(Intakes);
