import * as types from '../../actions/shared/types';

const defaultState = {
  activeUser: {},
  billing: {
    patient_sider_collapsed: false,
    patient_sider_default_panel: '1',

    ar_tab: "1",

    ar_current_page_1: 0,
    ar_page_1_sort: 0,

    ar_current_page_2: 0,
    ar_carrier: 0,

    ar_current_page_3: 0,
    ar_page_3_sort: 0,


    recall_list_selection: 0,
    recall_status_selection: 1,
    recalllist_page: 1,
    clearinghouse_page: 1,

    payments_pagesize: 50,
    payments_tab: "1",
    payments_ins_page: 1,
    payments_pt_page: 1,

    era_page: 1,
    era_pagesize: 25,
    era_type: 1
  }
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case types.SET_ACTIVE_USER:
      return {
        ...state,
        activeUser: action.payload
      };

    case types.BILLINGSTATE_SET:

      return {
        ...state,
        billing: {
          ...state.billing,
          ...action.payload
        }
      };

    default:
      return state;
  }
}
