import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';
import { Modal, Typography, Table, notification, Button } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/billing';

import PatientSelector from '../../../shared/PatientSelector';

class ManualClaimsDialog extends React.Component {
  state = {
    selectedClaims: []
  };

  static propTypes = {
    visible: PropTypes.bool,
    handleClose: PropTypes.func,
    clearinghouselist_type: PropTypes.number,
    clearinghouse_page: PropTypes.number,
    clearinghouse_page_size: PropTypes.number
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.setState({
        selectedClaims: []
      });
    }
    if (prevProps.success !== this.props.success && this.props.success) {
      const n = (this.props.data && this.props.data.length > 0) ? this.props.data.length : 0;
      if (n > 0) {
        notification.success({
          message: 'Patient Claim History Loaded',
          description: n + ' claim(s) loaded'
        });
        this.setState({
          selectedClaims: [
            ...this.props.data,
            ...this.state.selectedClaims
          ]
        });
      } else {
        notification.warning({
          message: 'Patient Claim History Loaded',
          description: 'no claims loaded'
        });
      }
    }

    if (prevProps.error !== this.props.error && this.props.error) {
      notification.error({
        message: 'Unable to load patient claim history',
        description: 'no claims loaded'
      });
    }
  }

  handleOk = () => {
    // Generate claims file here.
    const bs_list = this.state.selectedClaims.map(x=>x.id);
    this.props.generateClaimsFileFromList(
      bs_list,
      this.props.clearinghouselist_type,
      this.props.clearinghouse_page,
      this.props.clearinghouse_page_size
    );
    this.props.handleClose();
  }

  handleCancel = () => {
    this.props.handleClose();
  }

  handleSelectPatient = (ptid) => {
    this.props.getQueuedServices(ptid);
  }

  handleRemove = (id) => {
    this.setState({
      selectedClaims: this.state.selectedClaims.filter(x=>x.id !== id)
    });
  }

  render() {
    const columns = [{
      title: 'Patient',
      dataIndex: 'patient',
    },{
      title: 'DOS',
      dataIndex: 'dos',
      render: (x) => moment(x).format('MM/DD/YYYY')
    },{
      title: 'Carrier',
      dataIndex: 'carrier'
    },{
      title: 'CPT',
      dataIndex: 'cpt_codes'
    },{
      title: 'Bill Amount',
      dataIndex: 'total_rate',
      render: (x) => numeral(x).format('$0,0.00')
    },{
      title: 'Action',
      render: (value,record) => {
        return <Button type='link' size='small' icon={<DeleteOutlined />} onClick={()=>this.handleRemove(record.id)}/>;
      }
    }];

    return (
      <Modal
        title='Manually Generate Claims'
        visible={this.props.visible}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        okText='Create Claims File'
        width={750}
      >
        <Typography style={{paddingBottom:'20px'}}>
          This tool will allow you to create an 837P EDI file for printing paper
          claims or sending manually to a clearinghouse. Choose a patient below
          and the system will add all claims for this patient that have been
          queued for claim submission.
        </Typography>
        <PatientSelector onSelectPatient={this.handleSelectPatient}
          style={{width:'100%',paddingLeft:'0px',paddingBottom: '10px'}}/>
        <Table
          columns={columns}
          size='small'
          rowKey='id'
          dataSource={this.state.selectedClaims}
          pagination={false}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: safe(state,'billing.queue_list.data',[]),
    success: safe(state,'billing.queue_list.success',false),
    error: safe(state,'billing.queue_list.error'),
    isBusy: (
      safe(state,'billing.queue_list.processing',false)
    ) ? true : false,
  }
};

export default connect(mapStateToProps,actions)(ManualClaimsDialog);
