import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, DatePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import * as actions from '../../../../../actions/scheduler';
import { safe } from '../../../../../helpers/reduxSelectors';

class CreateSessionFromOpening extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.form.resetFields();
    }
  }

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.term_id === 0) {
          this.props.createProviderTerm(this.props.provider_id,values);
        } else {
          this.props.updateProviderTerm(this.props.provider_id,this.props.term_id,values);
        }
        this.props.onClose();
      }
    });
  }

  renderForm = (initialValues) => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 12 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };

    return (
      <Form className='compressed-form' autoComplete='false'>
        <FormItem {...formItemLayout} label="Start Date">
          {getFieldDecorator('start_date', {
            rules: [{ required: true, message: " " }],
            initialValue: (initialValues.start_date) ? moment(initialValues.start_date) : undefined
          })(
            <DatePicker format='M/D/YYYY' />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="End Date">
          {getFieldDecorator('end_date', {
            rules: [{ required: true, message: " " }],
            initialValue: (initialValues.end_date) ? moment(initialValues.end_date) : undefined
          })(
            <DatePicker format='M/D/YYYY'/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Productivity Start Date">
          {getFieldDecorator('productivity_start_date', {
            rules: [{ required: true, message: " " }],
            initialValue: (initialValues.productivity_start_date) ? moment(initialValues.productivity_start_date) : undefined
          })(
            <DatePicker format='M/D/YYYY'/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Review Date">
          {getFieldDecorator('review_date', {
            rules: [{ required: true, message: " " }],
            initialValue: (initialValues.review_date) ? moment(initialValues.review_date) : undefined
          })(
            <DatePicker format='M/D/YYYY'/>
          )}
        </FormItem>

      </Form>
    );
  }

  render() {
    const data = safe(this.props,'data',{});
    const title = (data.id) ? 'Edit Term' : 'Create Term';
    const okText = (data.id) ? 'Save' : 'Create';

    return (
      <Modal
        title={title}
        visible={this.props.visible}
        destroyOnClose={true}
        onOk={this.handleOk}
        okText={okText}
        onCancel={this.props.onClose}
      >
        {this.renderForm(data)}
      </Modal>
    );
  }
}

export default connect(null,actions)(Form.create()(CreateSessionFromOpening));
