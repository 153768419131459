import { useEffect, useState } from 'react';
import { Button } from 'antd';
import useApiGet from 'hooks/useApiGet';
import useApiPost from 'hooks/useApiPost';

const Miscellaneous: React.FC = () => {
  const [trigger, setTrigger] = useState(false);
  const unsyncedNotes = useApiGet('/api/v2/notes/getUnsignedNotesSessions', [], [trigger]);
  const fixNotesSessionsAPI = useApiPost('/api/v2/notes/fixUnsignedNotesSessions');

  useEffect(() => {
    setTrigger(true);
  }, []);

  const fixNotesSessions = async () => {
    if (!fixNotesSessionsAPI?.loading) {
      await fixNotesSessionsAPI?.send();
      setTrigger(false);
    }
  };

  return (
    <div className="p2">
      <h3>Miscellaneous</h3>
      <p>Where signed notes have an unsigned calendar entry.</p>
      <div className="mb4">There are currently {unsyncedNotes?.data?.count || 'no'} affected rows.</div>
      <div>
        <Button
          onClick={fixNotesSessions}
          type="primary"
          disabled={fixNotesSessionsAPI.loading || !unsyncedNotes?.data?.count || unsyncedNotes.loading}
        >
          Apply Fix
        </Button>
      </div>
    </div>
  );
};

export default Miscellaneous;
