/**
 * DayOfWeekToggle.tsx
 */
import { useContextSelector } from 'use-context-selector';
import { Form, Radio } from 'antd';
import formItemRules from 'components/App_/ReportsV2_/formItemRules';
import QueueContext from 'components/App_/ReportsV2_/QueueContext';

const DayOfWeekToggle: React.FC = () => {
  const form = useContextSelector(QueueContext, (c) => c.state.form);
  const setState = useContextSelector(QueueContext, (c) => c.setState);

  const onDayOfWeekChange = (e) => {
    const subType = parseInt(e.target.value);
    form?.setFieldsValue({ subType });
    setState((s) => ({ ...s, subType }));
  };

  return (
    <div className="mt4">
      <h5>Day of Week</h5>
      <Form.Item name="subType" className="m0" rules={formItemRules.subType.week}>
        <Radio.Group buttonStyle="solid" onChange={onDayOfWeekChange} size="small">
          <Radio.Button value={1}>Mo</Radio.Button>
          <Radio.Button value={2}>Tu</Radio.Button>
          <Radio.Button value={3}>We</Radio.Button>
          <Radio.Button value={4}>Th</Radio.Button>
          <Radio.Button value={5}>Fr</Radio.Button>
          <Radio.Button value={6}>Sa</Radio.Button>
          <Radio.Button value={7}>Su</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default DayOfWeekToggle;
