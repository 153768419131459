import React, { Component } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Button, Input, Select, Row, Spin, Col, message, Modal, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import ProblemList from './ProblemList';
import AttachmentUpload from './AttachmentUpload';
import SuperviseeComment from './SuperviseeComment';

import * as actions from '../../../../../actions/emr';
import { safe } from '../../../../../helpers/reduxSelectors';
import { MSE, fieldsMSE } from './MSE';

class ProgressNoteTherapist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      problemlist:[]
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.patientId !== this.props.patientId || prevProps.noteId !== this.props.noteId) {
      this.props.form.resetFields();
    }

    if (this.props.loadSuccess && !prevProps.loadSuccess) {
      const timer = setInterval(()=>{
        const fields = [
          'other_note','cc','hpi','psy_history','sub_history','social_history',
          'mental_status','poc','note_date','session_type',
          'session_status','location_id'
        ];
        if (this.props.form.isFieldsTouched(fields)) {
          if (!this.props.isSaving && !this.props.isSigning) {
            const values = this.props.form.getFieldsValue();
            this.props.noteAutoSave(this.props.noteId,{
              ...values,
              problemlist: this.state.problemlist
            });
          }
        }
      },30000);
      this.setState({
        timer: timer
      });
    }
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearInterval(this.state.timer);
    }
  }

  plChange = (problemlist) => {
    this.setState({
      problemlist: problemlist
    });
  }

  discardNote = () => {
    this.props.noteDelete(this.props.noteId);
  }

  saveNote = () => {
    const values = this.props.form.getFieldsValue();
    this.props.noteSave(this.props.noteId,{
      ...values,
      problemlist: this.state.problemlist
    });
  }

  validateProblemList = (values) => {
    const hasDiagnosis = (this.state.problemlist.find(x => x['diagnosis.id'])) ? true : false;
    if (!hasDiagnosis && values.session_status === 1) {
      message.error('A note cannot be signed without a diagnosis');
      return false;
    }

    const gzCodes = this.state.problemlist.filter(x=>{
      const d = x['diagnosis.icd10cm'];
      // The no diagnosis code should be allowed
      if (d === 'Z03.89') {
        return false;
      }
      return (d && d.length > 0 && (d.charAt(0) === 'G' || d.charAt(0) === 'Z')) ? true : false;
    });

    // Also check for solitary G and Z codes. That's invalid.
    if (this.state.problemlist.length === gzCodes.length && values.session_status === 1) {
      message.error('A progress note must include diagnoses other than G or Z codes.');
      return false;
    }

    // Check for duplicate diagnoses
    const diagCount = {};
    this.state.problemlist.forEach(x=>{
      if (x['diagnosis.icd10cm']) {
        if (!diagCount[x['diagnosis.icd10cm']]) {
          diagCount[x['diagnosis.icd10cm']] = 0;
        }
        diagCount[x['diagnosis.icd10cm']]++;
      }
    });

    for (let val of Object.values(diagCount)) {
      if (val > 1) {
        message.error('Your problem list contains duplicate diagnoses');
        return false;
      }
    }

    return true;
  }

  signNote = () => {
    this.props.form.validateFields((errors, values) => {
      if (errors) {
        const errorKeys = Object.keys(errors);
        const errorVals = Object.values(errors);
        const errorMessages = errorKeys.map((k,keyNum) => errorVals[keyNum].errors[0].message);
        notification.error({
          message: 'Missing field(s)',
          description: (
            <>
              {errorMessages.map((msg) => (
                <>
                  {msg}<br/>
                </>
              ))}
            </>
          ),  
        });
      }
      
    });
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err && this.validateProblemList(values)) {
        this.props.noteSign(this.props.noteId,{
          ...values,
          patient_id: this.props.patientId,
          problemlist: this.state.problemlist
        });
      }
    });
  }

  supervisorCancelNote = () => {
    this.props.history.push('/emr/patients/'+this.props.patientId)
  }

  supervisorRejectNote = () => {
    this.props.noteReject(this.props.noteId,this.props.form.getFieldsValue());
  }

  supervisorApproveNote = () => {
    this.props.noteApprove(this.props.noteId,this.props.form.getFieldsValue());
  }

  getLocationList = () => {
    const { primary_location, secondary_location } = this.props.activeUser;
    const result = [];

    if (primary_location) {
      result.push({id: primary_location.id, name: primary_location.name});
      if (secondary_location) {
        result.push({id: secondary_location.id, name: secondary_location.name});
      }
    }

    // Telemedicine is no longer a location: 9/12/2019, JK
    /*
    let addTelemedicine = true;
    result.forEach(x=>{
      if (x.id === 4) {
        addTelemedicine = false;
      }
    });
    if (addTelemedicine) {
      result.push({id: 4, name: 'TeleMedicine'});
    }
    */

    return result;
  }

  handleValidateMSE = (rule, value, callback) => {
    let error = false;
    for (let i = 0; i < fieldsMSE.length; i++) {
      if (value[fieldsMSE[i].name] === undefined || value[fieldsMSE[i].name].length === 0) {
        error = true;
        break;
      }
    }
    if (error) {
      callback(<span style={{color:'red'}}>Missing fields in the MSE</span>);
    }
    callback()
  }

  getNoteFields = (initialValues) => {
    const FormItem = Form.Item;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const formItemLayout = {};

    const status = this.props.form.getFieldValue('session_status');
    if (status === 2 || status === 3) {
      return (
        <FormItem
          {...formItemLayout}
          label="No Show Note"
        >
          {getFieldDecorator('other_note', {
            initialValue: initialValues.other_note
          })(
            <TextArea rows={5} autosize={{minRows: 5,maxRows: 6}} autoComplete="off"/>
          )}
        </FormItem>
      );
    }

    return (
      <div>
        <FormItem
          {...formItemLayout}
          label="CC"
        >
          {getFieldDecorator('cc', {
            rules: [{ required: true, message: "Enter Chief Complaint", whitespace: true}],
            initialValue: initialValues.cc
          })(
            <TextArea rows={2} autosize={{minRows: 2,maxRows: 3}}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="HPI/Session Summary"
        >
          {getFieldDecorator('hpi', {
            rules: [{ required: true, message: "Enter HPI." }],
            initialValue: initialValues.hpi
          })(
            <TextArea rows={15} autosize={{minRows: 15,maxRows: 20}}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Past Psychiatric History"
        >
          {getFieldDecorator('psy_history', {
            rules: [{ required: true, message: "Enter past psychiatric history." }],
            initialValue: initialValues.psy_history
          })(
            <TextArea rows={6} autosize={{minRows: 6,maxRows: 10}}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Substance Abuse History"
        >
          {getFieldDecorator('sub_history', {
            rules: [{ required: true, message: "Enter substance abuse history." }],
            initialValue: initialValues.sub_history
          })(
            <TextArea rows={6} autosize={{minRows: 6,maxRows: 10}}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Social History"
        >
          {getFieldDecorator('social_history', {
            rules: [{ required: true, message: "Enter social history." }],
            initialValue: initialValues.social_history
          })(
            <TextArea rows={6} autosize={{minRows: 6,maxRows: 10}}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Therapy Type"
        >
          {getFieldDecorator('therapy_type', {
            rules: [{ required: true, message: "Choose therapy type." }],
            initialValue: initialValues.therapy_type
          })(
            <Select>
              <Option value='cbt'>CBT</Option>
              <Option value='mindfulness'>Mindfulness</Option>
              <Option value='psychodynamic'>Psychodynamic</Option>
              <Option value='problemsolving'>Problem Solving</Option>
              <Option value='socialskills'>Social Skills Training</Option>
              <Option value='playtherapy'>Play Therapy</Option>
              <Option value='supportive'>Supportive Therapy</Option>
            </Select>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Mental Status"
          validateStatus='success'
        >
          {getFieldDecorator('mental_status', {
            rules: [
              { required: true, message: "Enter Mental Status." },
              { validator: this.handleValidateMSE}
            ],
            initialValue: initialValues.mental_status
          })(
            <MSE/>
          )}
        </FormItem>

        <ProblemList patientId={this.props.patientId} onChange={this.plChange}/>

        <FormItem
          {...formItemLayout}
          label="Assessment and Plan"
        >
          {getFieldDecorator('poc', {
            rules: [{ required: true, message: "Enter Assessment and Plan." }],
            initialValue: initialValues.poc
          })(
            <TextArea rows={8} autosize={{minRows: 8,maxRows: 10}}/>
          )}
        </FormItem>
      </div>
    );
  }

  showDiscardConfirm = () => {
    const dn = this.discardNote;
    Modal.confirm({
      title: 'Are you sure you want to discard this note?',
      onOk() {
        dn();
      }
    });
  }

  clinicianButtons = () => {
    return (
      <Row>
        <Col span={12}>
          <Button type="danger" icon={<DeleteOutlined />} disabled={this.props.isBusy} onClick={this.showDiscardConfirm}>Discard Note</Button>
        </Col>
        <Col span={12} style={{textAlign:'right'}}>
          {this.props.isAutoSaving && (<span className="p4"><Spin/>&nbsp; Autosaving Note...</span>)}
          <Button style={{marginRight:'40px'}} disabled={this.props.isBusy} onClick={this.saveNote}>Save Note</Button>
          <Button type="primary" disabled={this.props.isBusy} onClick={this.signNote}>Sign Note</Button>
        </Col>
      </Row>
    );
  }

  includeButtons = () => {
    return this.clinicianButtons();
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const formItemLayout = {};
    const locationList = this.getLocationList();

    const initialValues = {
      note_date: moment(safe(this.props,'thisNote.note_date')).tz('America/Los_Angeles'),
      session_type: safe(this.props,'thisNote.note.session_type'),
      session_status: safe(this.props,'thisNote.note.session_status'),
      location_id: safe(this.props,'thisNote.location_id'),
      cc: safe(this.props,'thisNote.note.cc'),
      hpi: safe(this.props,'thisNote.note.hpi'),
      mental_status: safe(this.props,'thisNote.note.mental_status'),
      poc: safe(this.props,'thisNote.note.poc'),
      other_note: safe(this.props,'thisNote.note.other_note'),
      psy_history: safe(this.props,'thisNote.note.psy_history'),
      sub_history: safe(this.props,'thisNote.note.sub_history'),
      therapy_type: safe(this.props,'thisNote.note.therapy_type'),
      social_history: safe(this.props,'thisNote.note.social_history'),
      telemedicine: safe(this.props,'thisNote.telemedicine')
    }

    return (
      <Form layout={'vertical'} autoComplete="off">

        <Row>
          <Col span={8}>
            <FormItem {...formItemLayout} label="Session Date">
              {getFieldDecorator('note_date', {
                rules: [{ type: 'object', required: true, message: "Please enter a date for this session", whitespace: true }],
                initialValue: initialValues.note_date
              })(
                <DatePicker disabled={true} format='M/DD/YYYY' />
              )}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem {...formItemLayout} label="Telemedicine">
              {getFieldDecorator('telemedicine', {
                rules: [{ required: true, message: "Choose whether session is telemedicine" }],
                initialValue: initialValues.telemedicine,
              })(
                <Select>
                  <Option value={1}>Yes</Option>
                  <Option value={0}>No</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{paddingRight:'10px'}}>
            <FormItem
              {...formItemLayout}
              label="Session Type"
            >
              {getFieldDecorator('session_type', {
                rules: [{ required: true, message: "Choose session type." }],
                initialValue: initialValues.session_type
              })(
                <Select disabled={true}>
                  <Option value={1}>Initial Evaluation</Option>
                  <Option value={2}>Followup</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={8} style={{paddingRight:'10px'}}>
            <FormItem
              {...formItemLayout}
              label="Session Status"
            >
              {getFieldDecorator('session_status', {
                rules: [{ required: true, message: "Choose session status." }],
                initialValue: initialValues.session_status
              })(
                <Select>
                  <Option value={1}>Show</Option>
                  <Option value={2}>No Show</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              {...formItemLayout}
              label="Service Location"
            >
              {getFieldDecorator('location_id', {
                rules: [{ required: true, message: "Choose a service location." }],
                initialValue: initialValues.location_id
              })(
                <Select>
                  { locationList.map(a => (<Option key={a.id} value={a.id}>{a.name}</Option>)) }
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        {this.getNoteFields(initialValues)}

        <AttachmentUpload patientId={this.props.patientId} noteId={this.props.noteId}/>

        {this.includeButtons()}

      </Form>
    );
  }

  autoSaveNote = () => {
    if (this.props.autoSaveTimeDate) {
      return (
        <div style={{fontStyle:'italic'}}>Last autosaved {this.props.autoSaveTimeDate}</div>
      );
    }
    return null;
  }

  render() {
    return (
      <div style={{padding:'15px'}}>
        <h3 style={{paddingTop:'10px'}}>Therapy Progress Note</h3>
        { this.autoSaveNote() }
        <div style={{paddingBottom:'20px'}}/>
        <SuperviseeComment
          providerId={this.props.thisNote.provider_id}
          superviserId={this.props.thisNote.supervising_provider_id}
          noteId={this.props.noteId}
          comments={this.props.thisNoteComments}
          ac={this.props.addComment}
        />
        { this.renderForm() }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loadSuccess: safe(state,'emr.note.load.success'),
    loadProcessing: safe(state,'emr.note.load.processing'),
    isBusy: (
      safe(state,'emr.note.load.processing',false) ||
      safe(state,'emr.note.create.processing',false) ||
      safe(state,'emr.note.save.processing',false) ||
      safe(state,'emr.note.autosave.processing',false) ||
      safe(state,'emr.note.sign.processing',false) ||
      safe(state,'emr.note.delete.processing',false)
    ) ? true : false,
    isSaving: safe(state,'emr.note.save.processing',false),
    isAutoSaving: safe(state,'emr.note.autosave.processing',false),
    isSigning: safe(state,'emr.note.sign.processing',false),
    patientData: safe(state,'shared.patient.load.data',{}),
    activeUser: safe(state,'shared.domain.activeUser'),
    thisNote: safe(state,'emr.note.load.data',{}),
    thisNoteComments: safe(state,'emr.comments.list.data',[]),
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(ProgressNoteTherapist)));
