import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import { DateTime } from 'luxon';
import get from 'lodash/get';

// TODO: Bring these into shared local module 'reports', change name...?
export const types = [
  'Chart Viewed',
  'Note Hidden',
  'Note Unhidden',
  'Note Attachment Hidden',
  'Note Attachment Unhidden',
  'Patient Demographics Changed',
  'Chart Note Expanded',
  'Document Created',
  'Document Deleted',
  'Document Retrieved',
  'Document List Retrieved',
  'Documents Transferred to Chart',
  'Attachment Transferred to Document Box',
];

const attachmentTypes = [
  'Labs',
  'Registration',
  'Consents',
  'Letter',
  'Outside Notes',
  'Other',
  'Insurance Card',
  'Admin General'
];

// this doesnt seem right.
const documentTypes = [
  'Excuse of Absense',
  'Emotional Support Animal',
  'General Letter',
  'Termination Letter'
];

const columns: Array<ColumnI> = [
  {
    title: 'Timestamp',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_SHORT),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (val: string) => types[parseInt(val, 10) - 1],
  },
  {
    title: 'Provider',
    dataIndex: 'userId',
    render: (_val, record) => get(record, 'user.full_name') as string,
  },
  {
    title: 'Patient',
    dataIndex: 'patientId',
    render: (_val, record): string => {
      const patientName = get(record, 'patient.full_name') as string;
      const notePatientName = get(record, 'note.patient.full_name') as string;
      const attachmentPatientName = get(record, 'attachment.patient.full_name') as string;
      return patientName || notePatientName || attachmentPatientName;
    },
  },
  {
    title: 'Note ID',
    dataIndex: 'noteId',
    render: (val, record): string => {
      const attachmentNoteId = get(record, 'attachment.note_id') as string;
      return val || attachmentNoteId;
    },
  },
  {
    title: 'Note DoS',
    dataIndex: 'noteId',
    render: (_val: string, record: Record<string, unknown>): null | string => {
      const noteDate = get(record, 'note.note_date') as string;
      const attachmentNoteDate = get(record, 'attachment.note.note_date') as string;
      if (noteDate) {
        return DateTime.fromISO(noteDate).toLocaleString(DateTime.DATETIME_SHORT);
      } else if (attachmentNoteDate) {
        return DateTime.fromISO(attachmentNoteDate).toLocaleString(DateTime.DATETIME_SHORT);
      }
      return null;
    },
  },
  {
    title: 'Attachment Type',
    dataIndex: 'attachmentId',
    render: (_val: string, record: Record<string, unknown>): null | string => {
      const type = get(record, 'attachment.type') as number;
      return attachmentTypes[type - 1];
    },
  },
  {
    title: 'Attachment Title',
    dataIndex: 'attachmentId',
    render: (_val: string, record: Record<string, unknown>): null | string => {
      const title = get(record, 'attachment.title') as string;
      if (title) {
        return title;
      }
      return null;
    },
  },
  {
    title: 'Document Type',
    dataIndex: 'documentId',
    render: (_val: string, record: Record<string, unknown>): null | string => {
      const type = get(record, 'document.type') as number;
      return documentTypes[type - 1];
    },
  },
  {
    title: 'Document Title',
    dataIndex: 'documentId',
    render: (_val: string, record: Record<string, unknown>): null | string => {
      const title = get(record, 'document.title') as string;
      if (title) {
        return title;
      }
      return null;
    },
  },
  {
    title: 'Misc',
    dataIndex: 'data',
    render: (_val: string, record: Record<string, unknown>): null | string => {
      if (record.data) {
        return JSON.stringify(record.data);
      }
      return null;
    },
  },
];

export default columns;
