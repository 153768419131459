/**
 * HourlyProviderPayroll.tsx
 */
import reports from 'reports';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import InlineFormWrapper from 'components/App_/ReportsV2_/InlineFormWrapper';
import ReportWrapper from 'components/App_/ReportsV2_/ReportWrapper';
import Table from 'components/App_/ReportsV2_/Table';
import columns from 'components/App_/ReportsV2_/TableColumns_/hourlyEmployeesPayroll';
import { InlineDataT } from 'reports';
import useAutoRun from 'components/App_/ReportsV2_/useAutoRun';
import PayPeriodSelector from 'components/App_/ReportsV2_/InlineControls_/PayPeriodSelector';
import DisplayReport from 'components/App_/ReportsV2_/InlineControls_/DisplayReport';

const reportId = 24;

const HourlyProviderPayroll: React.FC = () => {
  const inlineData = useContextSelector(InlineContext, (c) => c.state.inlineData) as InlineDataT;
  const doRunReport = useContextSelector(InlineContext, (c) => c.doRunReport);
  const report = reports.find((r) => r.id === reportId);

  useAutoRun(reportId);

  const formOptions = { reportId };

  const tableOptions = {
    data: inlineData,
    columns,
    reportId,
    rowKey: 'provider',
    total: inlineData?.length,
  };

  return (
    <ReportWrapper>
      <h3>{report?.name}</h3>
      <InlineFormWrapper {...formOptions}>
        <PayPeriodSelector callback={doRunReport} />
        <DisplayReport />
      </InlineFormWrapper>
      <Table {...tableOptions} />
    </ReportWrapper>
  );
};

export default HourlyProviderPayroll;
