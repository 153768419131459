import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BellOutlined, EditOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Row, Col, Badge } from 'antd';
import moment from 'moment';

import Alerts from '../../../shared/Alerts';

import * as billing_actions from '../../../../actions/billing';
import * as shared_actions from '../../../../actions/shared';

import { isEmpty } from '../../../../helpers/js';
import { genders } from '../../../../helpers/genders';
import { safe } from '../../../../helpers/reduxSelectors';

class LedgerHeader extends Component {

  static propTypes = {
    demographics: PropTypes.object,
    alerts: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {
      alertsVisible: false
    }
  }

  label = (x) => {
    return (false) ? x + ": " : null;
  }

  zeroPadNum(num, size) {
    let s = num+"";
    return (s).padStart(size-s.length,"0");
  }

  getPatientName = () => {
    const {full_name,id} = this.props.demographics;
    return full_name + ' ('+this.zeroPadNum(id,7)+')';
  }

  getPatientAddress = () => {
    const {address1,address2} = this.props.demographics;

    if (address1 && address2 && address1.length > 0 && address2.length > 0) {
      return (<div>{this.label('Address')}{address1},{address2}</div>);
    } else if (address1 && address1.length > 0) {
      return (<div>{this.label('Address')}{address1}</div>);
    } else if (address2 && address2.length > 0){
      return (<div>{this.label('Address')}{address2}</div>);
    }
    return null;
  }

  getPatientCityStateZip = () => {
    const {city,state,zip} = this.props.demographics;
    if (city && state && zip) {
      return (<div>{city}, {state}, {zip}</div>);
    } else {
      return null;
    }
  }

  getPatientPhone = () => {
    const {phone} = this.props.demographics;
    return (phone) ? (<div>{this.label('Phone')}{phone}</div>) : null;
  }

  getPatientEmail = () => {
    const {email} = this.props.demographics;
    return (email) ? (<div>{this.label('Email')}{email}</div>) : null;
  }

  getPatientDOB = () => {
    const {dob,age} = this.props.demographics;
    const dobString = moment(dob).format('M/D/YYYY');
    return (dob) ? (<div>{this.label('Date of Birth')}{dobString} ({age} years old)</div>) : null;
  }

  getPatientGender = () => {
    const {gender} = this.props.demographics;
    const record = genders.find(a=>(a.id === gender));
    return (record) ? (<div>{this.label('Gender')}{record.text}</div>) : null;
  }

  getEmergencyContactName = () => {
    const {ec_name,ec_relation} = this.props.demographics;
    if (ec_name) {
      if (ec_relation && ec_relation.length > 0) {
        return (<div>Emergency Contact: {ec_name} ({ec_relation})</div>);
      } else {
        return (<div>Emergency Contact: {ec_name}</div>);
      }
    }
    return null;
  }

  getEmergencyContactContact = () => {
    const { ec_email,ec_phone } = this.props.demographics;
    if (ec_phone) {
      if (ec_email) {
        return (<div>Emergency Contact: {ec_phone} ({ec_email})</div>);
      }
    } else if (ec_email) {
      return (<div>Emergency Contact: {ec_email})</div>);
    }
    return null;
  }

  showEditButton = () => {
    return (
      <Link to={'/billing/patients/'+this.props.patientId+'/edit'}>
        <EditOutlined style={{fontSize:'18pt'}} />
      </Link>
    );
  }

  showAlerts = () => {
    this.setState({
      alertsVisible: true
    });
  }

  hideAlerts = () => {
    this.setState({
      alertsVisible: false
    });
  }

  showAlertsButton = () => {
    const alertsCount = (this.props.alerts) ? this.props.alerts.length : 0;
    return (
      <Badge count={alertsCount} showZero={false}>
        <a onClick={this.showAlerts}>
          <BellOutlined style={{fontSize:'18pt'}} />
        </a>
      </Badge>
    );
  }

  toggleSider = () => {
    this.props.setBillingState({
      patient_sider_collapsed: ! this.props.patient_sider_collapsed
    });
  }

  render() {
    if (!this.props.demographics || isEmpty(this.props.demographics)) {
      return null;
    }

    return (
      <div style={{backgroundColor:'rgb(248,248,248)',paddingTop:'12px',paddingLeft:'5px',paddingRight:'5px',borderBottom: '1px solid #e8e8e8'}}>
        <h3>
          {this.getPatientName()} {this.showEditButton()} { this.showAlertsButton() }
          <LegacyIcon
            style={{float:'right',fontSize:'20pt'}}
            type={this.props.patient_sider_collapsed ? 'menu-fold' : 'menu-unfold'}
            onClick={this.toggleSider}
          />
        </h3>
        <Row gutter={16} style={{width:'100%'}}>
          <Col span={8}>
            {this.getPatientAddress()}
            {this.getPatientCityStateZip()}
            {this.getPatientPhone()}
          </Col>
          <Col span={8}>
            {this.getPatientDOB()}
            {this.getPatientEmail()}
            {this.getPatientGender()}
          </Col>
          <Col span={8}>
            {this.getEmergencyContactName()}
            {this.getEmergencyContactContact()}
          </Col>
        </Row>

        <Alerts
          visible={this.state.alertsVisible}
          alertData={this.props.alerts}
          patientId={this.props.patientId}
          closeDialog={this.hideAlerts}
        />

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    patient_sider_collapsed: safe(state,'shared.domain.billing.patient_sider_collapsed',false)
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(LedgerHeader);
