import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

import BreakLines from './BreakLines';

import { safe } from '../../../../../../helpers/reduxSelectors';


class AdminTimeNote extends Component {

  renderTitle = (x) => {
    const hasLink = (this.props.signedDateTime === null && this.props.providerId === this.props.userId);
    return (x ? (
      <div className='chartnotetitle'>
        {hasLink ? (<Link to={this.props.editURL}>{x}</Link>) : x}
      </div>) : null);
  }

  renderProvider = () => {
    return (this.props.providerName ? (
      <div className='chartnotefield'>
        <span className='chartnotefieldlabel'>Provider </span>{this.props.providerName}
      </div>) : null);
  }

  renderNote = () => {
    return (this.props.note ? (
      <div>
        <div className='chartnotefield'>
          <span className='chartnotefieldlabel'>Time Spent </span>{this.props.time_amount}
        </div>
        <div className='chartnotefield'>
          <span className='chartnotefieldlabel'>Note </span><br />
          <BreakLines text={this.props.note}/>
        </div>
      </div>
    ) : null);
  }

  renderSignature = () => {
    return (this.props.signedDateTime ? (
      <div className='chartnotesignature'>
        Electronically signed by {this.props.providerName} at {moment(this.props.signedDateTime).format("MM/DD/YYYY h:mm A")}
      </div>
    ) : null);
  }

  render() {
    const noteUnsigned = (this.props.signedDateTime === null) ? true : false;
    const noteAuthorIsThisProvider = (this.props.providerId === this.props.userId) ? true : false;
    const noteInProcess = (noteUnsigned && !noteAuthorIsThisProvider) ? true : false;

    if (noteInProcess) {
      return (
        <div style={{border:'1px dashed lightgray',backgroundColor:'rgb(219,236,252)',marginRight:'20px'}}>
          <div className='chartnotetitle' style={{padding:'20px'}}>
            Admin Time Note by {this.props.providerName} in process...
          </div>
        </div>
      );
    } else {
      return (
        <div>
          { this.renderTitle('Admin Time Note') }
          { this.renderProvider() }
          { this.renderNote() }
          { this.renderSignature() }
        </div>
      );
    }
  }
}

AdminTimeNote.defaultProps = {
  print: false
}

AdminTimeNote.propTypes = {
  providerName: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  signedDateTime: PropTypes.string,
  print: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
  return {
    providerName: safe(ownProps,'data.provider.full_name'),
    time_amount: safe(ownProps,'data.note.time_amount'),
    note: safe(ownProps,'data.note.general_note'),
    signedDateTime: safe(ownProps,'data.signedAt',null),
    providerId: safe(ownProps,'data.provider_id'),
    editURL:'/emr/patients/'+ownProps.data.patient_id+'/admintimenote/'+ownProps.data.id
  }
}

export default connect(mapStateToProps,null)(AdminTimeNote);
