import axios from 'axios';
import queryString from 'qs';
import * as types from './types';

const billingLogout = (dispatch,err) => {
  if (err && err.response && err.response.status === 401) {
    dispatch ({type:'USER_LOGIN_CLEAR'});
  }
}

export const getQueuedServices = (patient_id) => async dispatch => {
  dispatch ({type:types.BILLING_QUEUED_SERVICES_REQUEST});
  try {
    const resp = await axios.post('/api/v1/queue/'+patient_id);
    dispatch ({type:types.BILLING_QUEUED_SERVICES_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_QUEUED_SERVICES_FAILURE, error: err.response});
  }
}

export const claimsSearch = (data,page,pageSize) => async dispatch => {
  dispatch ({type:types.CLAIMS_SEARCH_REQUEST});
  try {
    const qs = queryString.stringify({
      page: page,
      pagesize: pageSize,
    });
    const resp = await axios.post('/api/v1/claims/search?'+qs,data);
    dispatch ({type:types.CLAIMS_SEARCH_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.CLAIMS_SEARCH_FAILURE, error: err.response});
  }
}

export const claimsSearchClear = () => dispatch => {
  dispatch ({type:types.CLAIMS_SEARCH_CLEAR});
}

export const getAdminAttachments = (patient_id) => async dispatch => {
  dispatch ({type:types.BILLING_ATTACHMENTS_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/adminattachments/'+patient_id);
    dispatch ({type:types.BILLING_ATTACHMENTS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_ATTACHMENTS_LIST_FAILURE, error: err.response});
  }
}

export const setAdminAttachmentsCreateRequest = () => dispatch => {
  dispatch ({type:types.BILLING_ATTACHMENTS_CREATE_REQUEST});
}

export const setAdminAttachmentsCreateSuccess = (data) => dispatch => {
  dispatch ({type:types.BILLING_ATTACHMENTS_CREATE_SUCCESS});
  dispatch ({type:types.BILLING_ATTACHMENTS_LIST_SUCCESS, payload: data });
}

export const setAdminAttachmentsCreateFailure = (error) => dispatch => {
  dispatch ({type:types.BILLING_ATTACHMENTS_CREATE_FAILURE, error: error});
}

export const renameAdminAttachment = (patient_id,id,title) => async dispatch => {
  dispatch ({type:types.BILLING_ATTACHMENTS_RENAME_REQUEST});
  try {
    const resp = await axios.put('/api/v1/adminattachments/'+patient_id+'/'+id,{
      title: title
    });
    dispatch ({type:types.BILLING_ATTACHMENTS_RENAME_SUCCESS, payload: resp.data });
    dispatch ({type:types.BILLING_ATTACHMENTS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_ATTACHMENTS_RENAME_FAILURE, error: err.response});
  }
}

export const deleteAdminAttachment = (patient_id,id) => async dispatch => {
  dispatch ({type:types.BILLING_ATTACHMENTS_DELETE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/adminattachments/'+patient_id+'/'+id);
    dispatch ({type:types.BILLING_ATTACHMENTS_DELETE_SUCCESS, payload: resp.data });
    dispatch ({type:types.BILLING_ATTACHMENTS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_ATTACHMENTS_DELETE_FAILURE, error: err.response});
  }
}

export const getEmailStatements = (patient_id) => async dispatch => {
  dispatch ({type:types.BILLING_EMAILSTATEMENTS_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/emailstatements/'+patient_id);
    dispatch ({type:types.BILLING_EMAILSTATEMENTS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_EMAILSTATEMENTS_LIST_FAILURE, error: err.response});
  }
}

export const addServiceLineNote = (service_id,description) => async dispatch => {
  dispatch ({type:types.BILLING_SERVICELINE_ADDNOTE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/servicenote/'+service_id,{
      description: description
    });
    dispatch ({type:types.BILLING_SERVICELINE_ADDNOTE_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_SERVICELINE_ADDNOTE_FAILURE, error: err.response});
  }
}

export const getPaymentDetail = (paymentDetailId) => async dispatch => {
  dispatch ({type:types.PAYMENT_DETAIL_REQUEST});
  try {
    const resp = await axios.get('/api/v1/paymentdetail/'+paymentDetailId);
    dispatch ({type:types.PAYMENT_DETAIL_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.PAYMENT_DETAIL_FAILURE, error: err.response});
  }
}

export const getClaimsList = (type,page,pageSize) => async dispatch => {
  dispatch ({type:types.CLAIMS_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/claims?type='+encodeURIComponent(type)+
      '&page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pageSize));
    dispatch ({type:types.CLAIMS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.CLAIMS_LIST_FAILURE, error: err.response});
  }
}

export const tagClaimLine = (id,state,type,page,pageSize) => async dispatch => {
  dispatch ({type:types.CLAIMS_TAG_REQUEST});
  try {
    const resp = await axios.get('/api/v1/claims/tag/'+id+'/'+state+
      '?type='+encodeURIComponent(type)+
      '&page='+encodeURIComponent(page)+
      '&pagesize='+encodeURIComponent(pageSize)
    );
    dispatch ({type:types.CLAIMS_LIST_SUCCESS, payload: resp.data });
    dispatch ({type:types.CLAIMS_TAG_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.CLAIMS_TAG_FAILURE, error: err.response});
  }
}

export const getPtPaymentApplyList = (page,pageSize) => async dispatch => {
  dispatch ({type:types.BILLING_PTAPPLYLIST_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/ptapplylist?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pageSize));
    dispatch ({type:types.BILLING_PTAPPLYLIST_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_PTAPPLYLIST_LIST_FAILURE, error: err.response});
  }
}

export const applyPtPayment = (id,page,pageSize) => async dispatch => {
  dispatch ({type:types.BILLING_PTAPPLYLIST_APPLY_REQUEST});
  try {
    const resp = await axios.get('/api/v1/ptapplylist/'+id+'?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pageSize));
    dispatch ({type:types.BILLING_PTAPPLYLIST_APPLY_SUCCESS, payload: resp.data });
    dispatch ({type:types.BILLING_PTAPPLYLIST_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_PTAPPLYLIST_APPLY_FAILURE, error: err.response});
  }
}

export const getAuthorizationList = (patient_id) => async dispatch => {
  dispatch ({type:types.AUTHORIZATION_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/authorizations/'+patient_id);
    dispatch ({type:types.AUTHORIZATION_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.AUTHORIZATION_LIST_FAILURE, error: err.response});
  }
}

export const createAuthorization = (patient_id,data) => async dispatch => {
  dispatch ({type:types.AUTHORIZATION_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/authorizations/'+patient_id,data);
    dispatch ({type:types.AUTHORIZATION_CREATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.AUTHORIZATION_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.AUTHORIZATION_CREATE_FAILURE, error: err.response});
  }
}

export const updateAuthorization = (patient_id,id,data) => async dispatch => {
  dispatch ({type:types.AUTHORIZATION_UPDATE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/authorizations/'+patient_id+'/'+id,data);
    dispatch ({type:types.AUTHORIZATION_UPDATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.AUTHORIZATION_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.AUTHORIZATION_UPDATE_FAILURE, error: err.response});
  }
}

export const deleteAuthorization = (patient_id,id) => async dispatch => {
  dispatch ({type:types.AUTHORIZATION_DELETE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/authorizations/'+patient_id+'/'+id);
    dispatch ({type:types.AUTHORIZATION_DELETE_SUCCESS, payload: resp.data });
    dispatch ({type:types.AUTHORIZATION_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.AUTHORIZATION_DELETE_FAILURE, error: err.response});
  }
}

export const getBillingRecentList = () => async dispatch => {
  dispatch ({type:types.BILLING_RECENT_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/billingrecent');
    dispatch ({type:types.BILLING_RECENT_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_RECENT_LIST_FAILURE, error: err.response});
  }
}

export const updateBillingRecentList = (type,ref_id,label) => async dispatch => {
  dispatch ({type:types.BILLING_RECENT_UPDATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/billingrecent',{
      type: type,
      ref_id: ref_id,
      label: label
    });
    dispatch ({type:types.BILLING_RECENT_UPDATE_SUCCESS, payload: {} });
    dispatch ({type:types.BILLING_RECENT_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_RECENT_UPDATE_FAILURE, error: err.response});
  }
}

export const getRecallPatientItems = (patientId) => async dispatch => {
  dispatch ({type:types.RECALL_PATIENT_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/recall/patient/'+patientId);
    dispatch ({type:types.RECALL_PATIENT_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_PATIENT_LIST_FAILURE, error: err.response});
  }
}

export const saveRecallPatientItem = (patient_id,item_id,data) => async dispatch => {
  dispatch ({type:types.RECALL_PATIENT_SAVE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/recall/patient/'+patient_id+'/'+item_id,data);
    dispatch ({type:types.RECALL_PATIENT_SAVE_SUCCESS, payload: {} });
    dispatch ({type:types.RECALL_PATIENT_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_PATIENT_SAVE_FAILURE, error: err.response});
  }
}

export const createRecallPatientItem = (patientId,data) => async dispatch => {
  dispatch ({type:types.RECALL_PATIENT_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/recall/patient/'+patientId,data);
    dispatch ({type:types.RECALL_PATIENT_CREATE_SUCCESS, payload: {} });
    dispatch ({type:types.RECALL_PATIENT_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_PATIENT_CREATE_FAILURE, error: err.response});
  }
}

export const adjustPtResp = (era_id,claim) => async dispatch => {
  dispatch ({type: types.BILLING_SERVICELINE_ADJUSTPT_REQUEST});
  try {
    const resp = await axios.post('/api/v1/adjustptrate/'+era_id,claim);
    dispatch ({type: types.BILLING_SERVICELINE_ADJUSTPT_SUCCESS, payload: {}});
    dispatch ({type: types.ERA_RECONCILED_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    dispatch ({type: types.BILLING_SERVICELINE_ADJUSTPT_FAILURE, error: err.response});
  }
}

export const eraAddToRecallList = (era_id,service_id) => async dispatch => {
  dispatch ({type: types.ERA_ADDRECALL_REQUEST});
  try {
    const resp = await axios.get('/api/v1/era/'+era_id+'/addrecall/'+service_id);
    dispatch ({type: types.ERA_ADDRECALL_SUCCESS, payload: resp.data });
  } catch (err) {
    dispatch ({type: types.ERA_ADDRECALL_FAILURE, error: err.response});
  }
}

export const eraPost = (era_id) => async dispatch => {
  dispatch ({type: types.ERA_POST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/era/'+era_id+'/post');
    dispatch ({type: types.ERA_POST_SUCCESS, payload: resp.data });
  } catch (err) {
    dispatch ({type: types.ERA_POST_FAILURE, error: err.response});
  }
}

export const eraMark = (era_id) => async dispatch => {
  dispatch ({type: types.ERA_MARK_REQUEST});
  try {
    const resp = await axios.get('/api/v1/era/'+era_id+'/mark');
    dispatch ({type: types.ERA_MARK_SUCCESS, payload: resp.data });
  } catch (err) {
    dispatch ({type: types.ERA_MARK_FAILURE, error: err.response});
  }
}

export const getStripePublishedApiKey = () => async dispatch => {
  dispatch ({type: types.CC_GETSTRIPEPK_REQUEST});
  try {
    const res = await axios.get('/api/v1/stripe_pk');
    dispatch ({type: types.CC_GETSTRIPEPK_SUCCESS, payload: res.data});
  } catch (err) {
    dispatch ({type: types.CC_GETSTRIPEPK_FAILURE, error: err.response});
  }
}

export const getPatientCreditCardList = (patient_id) => async dispatch => {
  dispatch ({type: types.CC_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/cc/'+patient_id);
    dispatch ({type: types.CC_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    dispatch ({type: types.CC_LIST_FAILURE, error: err.response});
  }
}

export const createNewCreditCard = (patient_id,token) => async dispatch => {
  dispatch ({type: types.CC_CREATE_REQUEST});
  try {
    const res = await axios.post('/api/v1/cc/'+patient_id+'/'+token.id);
    dispatch ({type: types.CC_CREATE_SUCCESS, payload: {}});
    dispatch ({type: types.CC_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    dispatch ({type: types.CC_CREATE_FAILURE, error: err.response});
  }
}

export const deleteCreditCard = (patient_id,card_id) => async dispatch => {
  dispatch ({type: types.CC_DELETE_REQUEST});
  try {
    const res = await axios.delete('/api/v1/cc/'+patient_id+'/'+card_id);
    dispatch ({type: types.CC_DELETE_SUCCESS, payload: {}});
    dispatch ({type: types.CC_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    dispatch ({type: types.CC_DELETE_FAILURE, error: err.response});
  }
}

export const setDefaultCreditCard = (patient_id,card_id) => async dispatch => {
  dispatch ({type: types.CC_SETDEFAULT_REQUEST});
  try {
    const res = await axios.put('/api/v1/cc/'+patient_id+'/'+card_id);
    dispatch ({type: types.CC_SETDEFAULT_SUCCESS, payload: {}});
    dispatch ({type: types.CC_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    dispatch ({type: types.CC_SETDEFAULT_FAILURE, error: err.response});
  }
}

export const getServiceLines = (patient_id) => async dispatch => {
  dispatch ({type: types.BILLING_SERVICELINE_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/paymentservices/'+patient_id);
    dispatch ({type: types.BILLING_SERVICELINE_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    dispatch ({type: types.BILLING_SERVICELINE_LIST_FAILURE, error: err.response.data});
  }
}

export const getServiceLinesClear = () => dispatch => {
  dispatch ({type: types.BILLING_SERVICELINE_LIST_CLEAR});
}

export const setAutopay = (patientId,state) => async dispatch => {
  const val = (state) ? 1 : 0;
  dispatch ({type: types.AUTOPAY_SET_REQUEST});
  try {
    const resp = await axios.get('/api/v1/autopay/'+patientId+'/'+val);
    dispatch ({type: types.AUTOPAY_SET_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_LEDGER_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.AUTOPAY_SET_FAILURE, error: err.response});
  }
}

export const repriceServiceLine = (patient_id,service_id) => async dispatch => {
  dispatch ({type: types.BILLING_SERVICELINE_REPRICE_REQUEST});
  try {
    const resp = await axios.get('/api/v1/reprice/'+patient_id+'/'+service_id);
    dispatch ({type: types.BILLING_SERVICELINE_REPRICE_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_LEDGER_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_SERVICELINE_REPRICE_FAILURE, error: err.response});
  }
}

export const fixOrphanedPayment = (patient_id,service_id) => async dispatch => {
  dispatch ({type: types.BILLING_SERVICELINE_ORPHAN_REQUEST});
  try {
    const resp = await axios.get('/api/v1/orphan/'+patient_id+'/'+service_id);
    dispatch ({type: types.BILLING_SERVICELINE_ORPHAN_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_LEDGER_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_SERVICELINE_ORPHAN_FAILURE, error: err.response});
  }
}

export const getAutopayList = (page,pagesize) => async dispatch => {
  dispatch ({type: types.BILLING_AUTOPAYLIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/autopay?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pagesize));
    dispatch ({type: types.BILLING_AUTOPAYLIST_SUCCESS, payload: resp.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_AUTOPAYLIST_FAILURE, error: err.response});
  }
}

export const loadClearinghouseEntry = (id) => async dispatch => {
  dispatch ({type:types.CLEARINGHOUSE_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/clearinghouse/edi/'+id);
    dispatch ({type:types.CLEARINGHOUSE_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.CLEARINGHOUSE_LOAD_FAILURE, error: err.response});
  }
}

export const loadClearinghouseEntryClear = () => dispatch => {
  dispatch ({type:types.CLEARINGHOUSE_LOAD_CLEAR});
}

export const generateClaimsFile = (type,page,pagesize) => async dispatch => {
  dispatch ({type:types.CLEARINGHOUSE_GENERATE_REQUEST});
  try {
    const resp = await axios.get('/api/v1/clearinghouse/generate'+
      '?type='+encodeURIComponent(type)+
      '&page='+encodeURIComponent(page)+
      '&pagesize='+encodeURIComponent(pagesize)
    );
    dispatch ({type:types.CLEARINGHOUSE_GENERATE_SUCCESS, payload: {} });
    dispatch ({type:types.CLEARINGHOUSE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.CLEARINGHOUSE_GENERATE_FAILURE, error: err.response});
  }
}

export const generateClaimsFileFromList = (bs_list,type,page,pagesize) => async dispatch => {
  dispatch ({type:types.CLEARINGHOUSE_GENERATELIST_REQUEST});
  try {
    const resp = await axios.post('/api/v1/clearinghouse/generate'+
      '?type='+encodeURIComponent(type)+
      '&page='+encodeURIComponent(page)+
      '&pagesize='+encodeURIComponent(pagesize),{
        list: bs_list
      }
    );
    dispatch ({type:types.CLEARINGHOUSE_GENERATELIST_SUCCESS, payload: {} });
    dispatch ({type:types.CLEARINGHOUSE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.CLEARINGHOUSE_GENERATELIST_FAILURE, error: err.response});
  }
}

export const getClearinghouseList = (type,page,pagesize) => async dispatch => {
  dispatch ({type:types.CLEARINGHOUSE_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/clearinghouse'+
      '?type='+encodeURIComponent(type)+
      '&page='+encodeURIComponent(page)+
      '&pagesize='+encodeURIComponent(pagesize)
    );
    dispatch ({type:types.CLEARINGHOUSE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.CLEARINGHOUSE_LIST_FAILURE, error: err.response});
  }
}

export const deleteClearinghouseItem = (id,type,page,pagesize) => async dispatch => {
  dispatch ({type:types.CLEARINGHOUSE_DELETE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/clearinghouse/'+id+
      '?type='+encodeURIComponent(type)+
      '&page='+encodeURIComponent(page)+
      '&pagesize='+encodeURIComponent(pagesize)
    );
    dispatch ({type:types.CLEARINGHOUSE_DELETE_SUCCESS, payload: resp.data });
    dispatch ({type:types.CLEARINGHOUSE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.CLEARINGHOUSE_DELETE_FAILURE, error: err.response});
  }
}

export const submitClearinghouseItem = (id,type,page,pagesize) => async dispatch => {
  dispatch ({type:types.CLEARINGHOUSE_SUBMIT_REQUEST});
  try {
    const resp = await axios.put('/api/v1/clearinghouse/'+id+
      '?type='+encodeURIComponent(type)+
      '&page='+encodeURIComponent(page)+
      '&pagesize='+encodeURIComponent(pagesize)
    );
    dispatch ({type:types.CLEARINGHOUSE_SUBMIT_SUCCESS, payload: resp.data });
    dispatch ({type:types.CLEARINGHOUSE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.CLEARINGHOUSE_SUBMIT_FAILURE, error: err.response});
  }
}

export const regenerateClearinghouseItem = (id,type,page,pagesize) => async dispatch => {
  dispatch ({type:types.CLEARINGHOUSE_REGENERATE_REQUEST});
  try {
    const resp = await axios.get('/api/v1/clearinghouse/regenerate/'+id+
      '?type='+encodeURIComponent(type)+
      '&page='+encodeURIComponent(page)+
      '&pagesize='+encodeURIComponent(pagesize)
    );
    dispatch ({type:types.CLEARINGHOUSE_REGENERATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.CLEARINGHOUSE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.CLEARINGHOUSE_REGENERATE_FAILURE, error: err.response});
  }
}

export const processClearinghouseItem = (id,type,page,pagesize) => async dispatch => {
  dispatch ({type:types.CLEARINGHOUSE_PROCESS_REQUEST});
  try {
    const resp = await axios.get('/api/v1/clearinghouse/process/'+id+
      '?type='+encodeURIComponent(type)+
      '&page='+encodeURIComponent(page)+
      '&pagesize='+encodeURIComponent(pagesize)
    );
    dispatch ({type:types.CLEARINGHOUSE_PROCESS_SUCCESS, payload: resp.data });
    dispatch ({type:types.CLEARINGHOUSE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.CLEARINGHOUSE_PROCESS_FAILURE, error: err.response});
  }
}

export const getERAList = (page,pagesize,query_params) => async dispatch => {
  dispatch ({type:types.ERA_LIST_REQUEST});
  try {
    const resp = await axios.put('/api/v1/era?page='+encodeURIComponent(page)+
      '&pagesize='+encodeURIComponent(pagesize),query_params);
    dispatch ({type:types.ERA_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.ERA_LIST_FAILURE, error: err.response});
  }
}

export const tagERA = (id,state,page,pagesize,query_params) => async dispatch => {
  dispatch ({type:types.ERA_TAG_REQUEST});
  try {
    const resp = await axios.put('/api/v1/era/'+id+'/tag/'+state+'?page='+encodeURIComponent(page)+
      '&pagesize='+encodeURIComponent(pagesize),query_params);
    dispatch ({type:types.ERA_TAG_SUCCESS, payload: resp.data });
    dispatch ({type:types.ERA_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.ERA_TAG_FAILURE, error: err.response});
  }
}

export const loadERA = (id) => async dispatch => {
  dispatch ({type:types.ERA_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/era/'+id);
    dispatch ({type:types.ERA_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.ERA_LOAD_FAILURE, error: err.response});
  }
}

export const loadReconciledERA = (id) => async dispatch => {
  dispatch ({type:types.ERA_RECONCILED_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/era/'+id+'/reconciled');
    dispatch ({type:types.ERA_RECONCILED_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.ERA_RECONCILED_LOAD_FAILURE, error: err.response});
  }
}

export const loadEraRecallItems = (era_id,claim_ids) => async dispatch => {
  dispatch ({type:types.ERA_CLAIMSRECALL_LIST_REQUEST});
  try {
    const resp = await axios.post('/api/v1/era/'+era_id+'/claimrecalls',{
      claim_ids: claim_ids
    });
    dispatch ({type:types.ERA_CLAIMSRECALL_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.ERA_CLAIMSRECALL_LIST_FAILURE, error: err.response});
  }
}

export const saveEraRecallItem = (era_id,values,recall_id=0) => async dispatch => {
  dispatch ({type:types.ERA_CLAIMSRECALL_SAVE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/era/'+era_id+'/claimrecalls/'+recall_id,values);
    dispatch ({type:types.ERA_CLAIMSRECALL_SAVE_SUCCESS, payload: {} });
    dispatch ({type:types.ERA_CLAIMSRECALL_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.ERA_CLAIMSRECALL_SAVE_FAILURE, error: err.response});
  }
}

export const getRecallLists = () => async dispatch => {
  dispatch ({type:types.RECALL_LISTS_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/recall/lists');
    dispatch ({type:types.RECALL_LISTS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_LISTS_LIST_FAILURE, error: err.response});
  }
}

export const getFullRecallLists = () => async dispatch => {
  dispatch ({type:types.RECALL_LISTS_LISTFULL_REQUEST});
  try {
    const resp = await axios.get('/api/v1/recall/lists?full=1');
    dispatch ({type:types.RECALL_LISTS_LISTFULL_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_LISTS_LISTFULL_FAILURE, error: err.response});
  }
}

export const createRecallList = (data) => async dispatch => {
  dispatch ({type:types.RECALL_LISTS_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/recall/lists',data);
    dispatch ({type:types.RECALL_LISTS_CREATE_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_LISTS_CREATE_FAILURE, error: err.response});
  }
}

export const createRecallListClear = () => dispatch => {
  dispatch ({type:types.RECALL_LISTS_CREATE_CLEAR});
}

export const saveRecallList = (list_id,data) => async dispatch => {
  dispatch ({type:types.RECALL_LISTS_SAVE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/recall/lists/'+list_id,data);
    dispatch ({type:types.RECALL_LISTS_SAVE_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_LISTS_SAVE_FAILURE, error: err.response});
  }
}

export const saveRecallListClear = () => dispatch => {
  dispatch ({type:types.RECALL_LISTS_SAVE_CLEAR});
}

export const loadRecallList = (list_id) => async dispatch => {
  dispatch ({type:types.RECALL_LISTS_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/recall/lists/'+list_id);
    dispatch ({type:types.RECALL_LISTS_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_LISTS_LOAD_FAILURE, error: err.response});
  }
}

export const loadRecallListClear = () => dispatch => {
  dispatch ({type:types.RECALL_LISTS_LOAD_CLEAR});
}

export const deactivateRecallList = (list_id) => async dispatch => {
  dispatch ({type:types.RECALL_LISTS_DEACTIVATE_REQUEST});
  try {
    const resp = await axios.get('/api/v1/recall/lists/'+list_id+'/deactivate');
    dispatch ({type:types.RECALL_LISTS_DEACTIVATE_SUCCESS, payload: {} });
    dispatch ({type:types.RECALL_LISTS_LISTFULL_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_LISTS_DEACTIVATE_FAILURE, error: err.response});
  }
}

export const deactivateRecallListClear = () => dispatch => {
  dispatch ({type:types.RECALL_LISTS_DEACTIVATE_CLEAR});
}

export const reactivateRecallList = (list_id) => async dispatch => {
  dispatch ({type:types.RECALL_LISTS_REACTIVATE_REQUEST});
  try {
    const resp = await axios.get('/api/v1/recall/lists/'+list_id+'/reactivate');
    dispatch ({type:types.RECALL_LISTS_REACTIVATE_SUCCESS, payload: {} });
    dispatch ({type:types.RECALL_LISTS_LISTFULL_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_LISTS_REACTIVATE_FAILURE, error: err.response});
  }
}

export const reactivateRecallListClear = () => dispatch => {
    dispatch ({type:types.RECALL_LISTS_REACTIVATE_CLEAR});
}

export const getRecallItemList = (list_id,status,page,pageSize) => async dispatch => {
  dispatch ({type:types.RECALL_ITEMS_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/recall/items/'+list_id+'/'+status+'?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pageSize));
    dispatch ({type:types.RECALL_ITEMS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_ITEMS_LIST_FAILURE, error: err.response});
  }
}

export const createRecallItem = (list_id,status,data,page,pageSize) => async dispatch => {
  dispatch ({type:types.RECALL_ITEMS_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/recall/items/'+list_id+'/'+status+'?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pageSize),data);
    dispatch ({type:types.RECALL_ITEMS_CREATE_SUCCESS, payload: {} });
    dispatch ({type:types.RECALL_ITEMS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_ITEMS_CREATE_FAILURE, error: err.response});
  }
}

export const saveRecallItem = (list_id,status,item_id,data,page,pageSize) => async dispatch => {
  dispatch ({type:types.RECALL_ITEMS_SAVE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/recall/items/'+list_id+'/'+status+'/'+item_id+'?page='+encodeURIComponent(page)+'&pagesize='+encodeURIComponent(pageSize),data);
    dispatch ({type:types.RECALL_ITEMS_SAVE_SUCCESS, payload: {} });
    dispatch ({type:types.RECALL_ITEMS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_ITEMS_SAVE_FAILURE, error: err.response});
  }
}

export const getRecallCommentsList = (item_id) => async dispatch => {
  dispatch ({type:types.RECALL_COMMENTS_LIST_REQUEST});
  try {
    const resp = await axios.put('/api/v1/recall/comments/'+item_id);
    dispatch ({type:types.RECALL_COMMENTS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_COMMENTS_LIST_FAILURE, error: err.response});
  }
}

export const createRecallComment = (item_id,data) => async dispatch => {
  dispatch ({type:types.RECALL_COMMENTS_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/recall/comments/'+item_id,data);
    dispatch ({type:types.RECALL_COMMENTS_CREATE_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.RECALL_COMMENTS_CREATE_FAILURE, error: err.response});
  }
}

export const setQueue = (patient_id,service_id,type,value) => async dispatch => {
  dispatch ({type:types.BILLING_QUEUE_SET_REQUEST});
  try {
    const resp = await axios.get('/api/v1/queue/'+patient_id+'/'+service_id+'/'+type+'/'+value);
    dispatch ({type:types.BILLING_QUEUE_SET_SUCCESS, payload: {}});
    dispatch ({type:types.BILLING_LEDGER_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_QUEUE_SET_FAILURE, error: err.response});
  }
}

export const getDiagnosisList = (query) => async dispatch => {
  dispatch ({type:types.BILLING_DIAGNOSIS_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/diagnosislist?query='+encodeURIComponent(query));
    dispatch ({type:types.BILLING_DIAGNOSIS_LIST_SUCCESS, payload: resp.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type:types.BILLING_DIAGNOSIS_LIST_FAILURE, error: err.response});
  }
}

export const getBillingServiceLineHistory = (serviceId) => async dispatch => {
  dispatch ({type: types.BILLING_SERVICELINE_HISTORY_REQUEST});
  try {
    const res = await axios.get('/api/v1/servicelinehistory/'+serviceId);
    dispatch ({type: types.BILLING_SERVICELINE_HISTORY_SUCCESS, payload: res.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_SERVICELINE_HISTORY_FAILURE, error: err.response});
  }
}

export const getBillingServiceLine = (serviceId) => async dispatch => {
  dispatch ({type: types.BILLING_SERVICELINE_LOAD_REQUEST});
  try {
    const res = await axios.get('/api/v1/serviceline/'+serviceId);
    dispatch ({type: types.BILLING_SERVICELINE_LOAD_SUCCESS, payload: res.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_SERVICELINE_LOAD_FAILURE, error: err.response});
  }
}

export const saveBillingServiceLine = (patientId,serviceId,data) => async dispatch => {
  dispatch ({type: types.BILLING_SERVICELINE_SAVE_REQUEST});
  dispatch ({type: types.BILLING_LEDGER_LOAD_REQUEST });
  try {
    const res = await axios.put('/api/v1/serviceline/'+serviceId+'/'+patientId,data);
    dispatch ({type: types.BILLING_SERVICELINE_SAVE_SUCCESS, payload: {} });
    dispatch ({type: types.BILLING_LEDGER_LOAD_SUCCESS, payload: res.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_SERVICELINE_SAVE_FAILURE, error: err.response});
    dispatch ({type: types.BILLING_LEDGER_LOAD_FAILURE, error: err.response});
  }
}

export const createBillingServiceLine = (patientId,data) => async dispatch => {
  dispatch ({type: types.BILLING_SERVICELINE_CREATE_REQUEST});
  try {
    const res = await axios.post('/api/v1/serviceline/'+0+'/'+patientId,data);
    dispatch ({type: types.BILLING_SERVICELINE_CREATE_SUCCESS, payload: {} });
    dispatch ({type: types.BILLING_LEDGER_LOAD_SUCCESS, payload: res.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_SERVICELINE_CREATE_FAILURE, error: err.response});
    dispatch ({type: types.BILLING_LEDGER_LOAD_FAILURE, error: err.response});
  }
}

export const getBillingLedger = (patientId) => async dispatch => {
  dispatch ({type: types.BILLING_LEDGER_LOAD_REQUEST});
  try {
    const res = await axios.get('/api/v1/ledger/'+patientId);
    dispatch ({type: types.BILLING_LEDGER_LOAD_SUCCESS, payload: res.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_LEDGER_LOAD_FAILURE, error: err.response});
  }
}

export const getBillingLedgerClear = () => dispatch => {
  dispatch ({type: types.BILLING_LEDGER_LOAD_CLEAR});
}

export const getLedgerLineHistory = (patientId,serviceId) => async dispatch => {
  dispatch ({type: types.BILLING_LEDGER_HISTORY_REQUEST});
  try {
    const res = await axios.get('/api/v1/ledger/'+patientId+'/history/'+serviceId);
    dispatch ({type: types.BILLING_LEDGER_HISTORY_SUCCESS, payload: res.data });
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_LEDGER_HISTORY_FAILURE, error: err.response});
  }
}

export const createBillingLedgerTransfer = (patient_id,billing_service_id,new_ins_rate,reason) => async dispatch => {
  dispatch ({type: types.BILLING_LEDGER_TRANS_REQUEST});
  try {
    const res = await axios.post('/api/v1/ledger/'+patient_id+'/trans/'+billing_service_id,{
      new_ins_rate: new_ins_rate,
      reason: reason
    });
    dispatch ({type: types.BILLING_LEDGER_TRANS_SUCCESS, payload:{}});
    dispatch ({type: types.BILLING_LEDGER_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_LEDGER_TRANS_FAILURE, error: err.response});
  }
}

export const createBillingLedgerAdjustment = (patient_id,billing_service_id,
    ins_adj,pt_adj,adj_type,reason) => async dispatch => {
  dispatch ({type: types.BILLING_LEDGER_ADJ_REQUEST});
  try {
    // /api/billing/transaction/adjustment/patient_id/billing_service_id
    const res = await axios.post('/api/v1/ledger/'+patient_id+'/adj/'+billing_service_id,{
      ins_adj: ins_adj,
      pt_adj: pt_adj,
      adj_type: adj_type,
      reason: reason
    });
    dispatch ({type: types.BILLING_LEDGER_ADJ_SUCCESS, payload:{}});
    dispatch ({type: types.BILLING_LEDGER_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_LEDGER_ADJ_FAILURE, error: err.response});
  }
}

export const createBillingLedgerAdjustmentClear = () => dispatch => {
  dispatch ({type: types.BILLING_LEDGER_ADJ_CLEAR});
}

export const getBillingComments = (patientId) => async dispatch => {
  dispatch ({type: types.BILLING_COMMENTS_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/comments/'+patientId);
    dispatch ({type: types.BILLING_COMMENTS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_COMMENTS_LIST_FAILURE, error: err.response});
  }
}

export const getBillingCommentsClear = () => dispatch => {
  dispatch ({type: types.BILLING_COMMENTS_LIST_CLEAR});
}

export const addBillingComment = (patient_id,comment,label) => async dispatch => {
  dispatch ({type: types.BILLING_COMMENTS_CREATE_REQUEST});
  try {
    const res = await axios.post('/api/v1/comments/'+patient_id,{
      comment: comment,
      label: label
    });
    dispatch ({type: types.BILLING_COMMENTS_CREATE_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_COMMENTS_LIST_SUCCESS, payload: res.data})
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_COMMENTS_CREATE_FAILURE, error: err.response});
  }
}

export const addBillingCommentsClear = () => dispatch => {
  dispatch ({type: types.BILLING_COMMENTS_CREATE_CLEAR});
}


export const getBillingPolicies = (patientId) => async dispatch => {
  dispatch ({type: types.BILLING_POLICY_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/policy/'+patientId);
    dispatch ({type: types.BILLING_POLICY_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_POLICY_LIST_FAILURE, error: err.response});
  }
}

export const getBillingPoliciesHistory = (patientId) => async dispatch => {
  dispatch ({type: types.BILLING_POLICY_HISTORY_REQUEST});
  try {
    const resp = await axios.get('/api/v1/policyhistory/'+patientId);
    dispatch ({type: types.BILLING_POLICY_HISTORY_SUCCESS, payload: resp.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_POLICY_HISTORY_FAILURE, error: err.response});
  }
}

export const getBillingPolicyListClear = () => dispatch => {
  dispatch ({type: types.BILLING_POLICY_LIST_CLEAR});
}

export const getBillingPolicy = (patientId,policyId) => async dispatch => {
  dispatch ({type: types.BILLING_POLICY_LOAD_REQUEST});
  try {
    const res = await axios.get('/api/v1/policy/'+patientId+'/'+policyId);
    dispatch ({type: types.BILLING_POLICY_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_POLICY_LOAD_FAILURE, error: err.response});
  }
}

export const getBillingPolicyClear = () => async dispatch => {
  dispatch ({type: types.BILLING_POLICY_LOAD_CLEAR});
}

export const createBillingPolicy = (patientId,data) => async dispatch => {
  dispatch ({type: types.BILLING_POLICY_CREATE_REQUEST});
  try {
    const res = await axios.post('/api/v1/policy/'+patientId,data);
    dispatch ({type: types.BILLING_POLICY_CREATE_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_POLICY_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_POLICY_CREATE_FAILURE, error: err.response});
  }
}

export const createBillingPolicyClear = () => dispatch => {
  dispatch ({type: types.BILLING_POLICY_CREATE_CLEAR});
}

export const saveBillingPolicy = (patientId,policyId,data) => async dispatch => {
  dispatch ({type: types.BILLING_POLICY_SAVE_REQUEST});
  try {
    const res = await axios.put('/api/v1/policy/'+patientId+'/'+policyId,data);
    dispatch ({type: types.BILLING_POLICY_SAVE_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_POLICY_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_POLICY_SAVE_FAILURE, error: err.response});
  }
}

export const saveBillingPolicyClear = () => dispatch => {
  dispatch ({type: types.BILLING_POLICY_SAVE_CLEAR});
}

export const deleteBillingPolicy = (patientId,policyId) => async dispatch => {
  dispatch ({type: types.BILLING_POLICY_DELETE_REQUEST});
  try {
    const res = await axios.delete('/api/v1/policy/'+patientId+'/'+policyId);
    dispatch ({type: types.BILLING_POLICY_DELETE_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_POLICY_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_POLICY_DELETE_FAILURE, error: err.response});
  }
}

export const deleteBillingPolicyClear = () => dispatch => {
  dispatch ({type: types.BILLING_POLICY_DELETE_CLEAR});
}

export const getBillingStatements = (patientId) => async dispatch => {
  dispatch ({type: types.BILLING_STATEMENTS_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/statements/'+patientId);
    dispatch ({type: types.BILLING_STATEMENTS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_STATEMENTS_LIST_FAILURE, error: err.response});
  }
}

export const getBillingStatementsClear = () => dispatch => {
  dispatch ({type: types.BILLING_STATEMENTS_LIST_CLEAR});
}

export const createBillingStatement = (patient_id,type,start_date,end_date) => async dispatch => {
  dispatch ({type: types.BILLING_STATEMENTS_CREATE_REQUEST});
  try {
    const res = await axios.post('/api/v1/statements/'+patient_id, {
      type: type,
      start_date: start_date,
      end_date: end_date
    });
    dispatch ({type: types.BILLING_STATEMENTS_CREATE_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_STATEMENTS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_STATEMENTS_CREATE_FAILURE, error: err.response});
  }
}

export const createBillingStatementClear = () => dispatch => {
  dispatch ({type: types.BILLING_STATEMENTS_CREATE_CLEAR});
}

export const loadBillingStatement = (patient_id,statement_id) => async dispatch => {
  dispatch ({type: types.BILLING_STATEMENTS_LOAD_REQUEST});
  try {
    const res = await axios.get('/api/v1/statements/'+patient_id+'/'+statement_id);
    dispatch ({type: types.BILLING_STATEMENTS_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_STATEMENTS_LOAD_FAILURE, error: err.response});
  }
}

export const applyPtFundsCredits = (patient_id) => async dispatch => {
  dispatch ({type: types.BILLING_PTPAYMENT_APPLY_REQUEST});
  try {
    const res = await axios.get('/api/v1/ptpayment/apply/'+patient_id);
    dispatch ({type: types.BILLING_PTPAYMENT_APPLY_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_LEDGER_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_PTPAYMENT_APPLY_FAILURE, error: err.response});
  }
}

export const createPtPayment = (patient_id,type,amount,check_num) => async dispatch => {
  dispatch ({type: types.BILLING_PTPAYMENT_CREATE_REQUEST});
  try {
    const res = await axios.post('/api/v1/ptpayment/'+patient_id,{
      type: type,
      amount:amount,
      check_num:check_num
    });
    dispatch ({type: types.BILLING_PTPAYMENT_CREATE_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_LEDGER_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_PTPAYMENT_CREATE_FAILURE, error: err.response});
  }
}

export const refundPtPayment = (patient_id,payment_id,refund_amount) => async dispatch => {
  dispatch ({type: types.BILLING_PTPAYMENT_REFUND_REQUEST});
  try {
    const res = await axios.post('/api/v1/ptpayment/'+patient_id + '/refund/'+payment_id,{
      refund_amount:refund_amount,
    });
    dispatch ({type: types.BILLING_PTPAYMENT_REFUND_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_LEDGER_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_PTPAYMENT_REFUND_FAILURE, error: err.response});
  }
}

export const linkPtPaymentRefund = (patient_id,refund_id,payment_id) => async dispatch => {
  dispatch ({type: types.BILLING_PTPAYMENT_REFUNDLINK_REQUEST});
  try {
    const res = await axios.get('/api/v1/ptpayment/'+patient_id + '/link/'+refund_id+'/'+payment_id);
    dispatch ({type: types.BILLING_PTPAYMENT_REFUNDLINK_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_LEDGER_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_PTPAYMENT_REFUNDLINK_FAILURE, error: err.response});
  }
}

export const unapplyPtPayment = (patient_id,payment_detail_id) => async dispatch => {
  dispatch ({type: types.BILLING_PTPAYMENT_UNAPPLY_REQUEST});
  try {
    const res = await axios.get('/api/v1/ptpayment/'+patient_id + '/unapply/'+payment_detail_id);
    dispatch ({type: types.BILLING_PTPAYMENT_UNAPPLY_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_LEDGER_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_PTPAYMENT_UNAPPLY_FAILURE, error: err.response});
  }
}

export const loadPtAr = (start,limit,sortcolumn,csv) => async dispatch => {
  dispatch ({type: types.AR_LIST1_REQUEST});
  try {
    const query = queryString.stringify({
      start: start,
      limit: limit,
      sortcolumn: sortcolumn,
      csv: (csv) ? 1 : 0
    },{ addQueryPrefix: true });

    const res = await axios.get('/api/v1/ar/pt' + query);
    dispatch ({type: types.AR_LIST1_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.AR_LIST1_FAILURE, error: err.response});
  }
}

export const loadInsClaimAr = (start,limit,carrier_id,csv=false) => async dispatch => {
  dispatch ({type: types.AR_LIST2_REQUEST});
  try {
    const carrierClause = (carrier_id && carrier_id !== '0') ? "&carrier_id="+
      encodeURIComponent(carrier_id) : "";
    const res = await axios.get('/api/v1/ar/ins_per_service?start='+
      encodeURIComponent(start)+'&limit='+encodeURIComponent(limit)+
      '&csv='+((csv) ? 1 : 0) + carrierClause);
    dispatch ({type: types.AR_LIST2_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.AR_LIST2_FAILURE, error: err.response});
  }
}

export const loadInsPtAr = (start,limit,sortcolumn,csv=false) => async dispatch => {
  dispatch ({type: types.AR_LIST3_REQUEST});
  try {
    const query = queryString.stringify({
      start: start,
      limit: limit,
      sortcolumn: sortcolumn,
      csv: (csv) ? 1 : 0
    },{ addQueryPrefix: true });
    const res = await axios.get('/api/v1/ar/ins_per_pt' + query);
    dispatch ({type: types.AR_LIST3_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.AR_LIST3_FAILURE, error: err.response});
  }
}

export const loadPracticeAr = () => async dispatch => {
  dispatch ({type: types.AR_LIST4_REQUEST});
  try {
    const res = await axios.get('/api/v1/ar/practice');
    dispatch ({type: types.AR_LIST4_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.AR_LIST4_FAILURE, error: err.response});
  }
}

export const getInsPaymentList = (page,pagesize,otherParams) => async dispatch => {
  dispatch ({type: types.BILLING_INSPAYMENT_LIST_REQUEST});
  try {
    const qs = queryString.stringify({
      page: page,
      pagesize: pagesize,
      ...otherParams
    });
    const res = await axios.get('/api/v1/inspayment?'+qs);
    dispatch ({type: types.BILLING_INSPAYMENT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_INSPAYMENT_LIST_FAILURE, error: err.response});
  }
}

export const getInsPaymentListClear = () => dispatch => {
  dispatch ({type: types.BILLING_INSPAYMENT_LIST_CLEAR});
}

export const getPtPaymentList = (page,pagesize,otherParams) => async dispatch => {
  dispatch ({type: types.BILLING_PTPAYMENT_LIST_REQUEST});
  try {
    const qs = queryString.stringify({
      page: page,
      pagesize: pagesize,
      ...otherParams
    });
    const res = await axios.get('/api/v1/ptpayment?'+qs);
    dispatch ({type: types.BILLING_PTPAYMENT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_PTPAYMENT_LIST_FAILURE, error: err.response});
  }
}

export const getPtPaymentListClear = () => dispatch => {
  dispatch ({type: types.BILLING_PTPAYMENT_LIST_CLEAR});
}

export const getPayment = (payment_id) => async dispatch => {
  dispatch ({type: types.BILLING_INSPAYMENT_LOAD_REQUEST});
  try {
    const res = await axios.get('/api/v1/inspayment/'+payment_id);
    dispatch ({type: types.BILLING_INSPAYMENT_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_INSPAYMENT_LOAD_FAILURE, error: err.response});
  }
};

export const unapplyInsPayment = (payment_id,payment_detail_id) => async dispatch => {
  dispatch ({type: types.BILLING_INSPAYMENT_UNAPPLY_REQUEST});
  try {
    const res = await axios.get('/api/v1/inspayment/'+payment_id+'/unapply/'+payment_detail_id);
    dispatch ({type: types.BILLING_INSPAYMENT_LOAD_SUCCESS, payload: res.data});
    dispatch ({type: types.BILLING_INSPAYMENT_UNAPPLY_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_INSPAYMENT_UNAPPLY_FAILURE, error: err.response});
  }


}

export const getPaymentClear = () => dispatch => {
  dispatch ({type: types.BILLING_INSPAYMENT_LOAD_CLEAR});
}

export const createPayment = (data) => async dispatch => {
  dispatch ({type: types.BILLING_INSPAYMENT_CREATE_REQUEST});
  try {
    const res = await axios.post('/api/v1/inspayment',data);
    dispatch ({type: types.BILLING_INSPAYMENT_CREATE_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_INSPAYMENT_CREATE_FAILURE, error: err.response});
  }
}

export const createPaymentClear = () => dispatch => {
  dispatch ({type: types.BILLING_INSPAYMENT_CREATE_CLEAR});
}

export const savePayment = (id,data) => async dispatch => {
  dispatch ({type: types.BILLING_INSPAYMENT_SAVE_REQUEST});
  try {
    const res = await axios.put('/api/v1/inspayment/'+id,data);
    dispatch ({type: types.BILLING_INSPAYMENT_SAVE_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_INSPAYMENT_SAVE_FAILURE, error: err.response});
  }
}

export const savePaymentClear = () => dispatch => {
  dispatch ({type: types.BILLING_INSPAYMENT_SAVE_CLEAR});
}

export const deletePayment = (id,page,pagesize,otherParams) => async dispatch => {
  dispatch ({type: types.BILLING_INSPAYMENT_DELETE_REQUEST});
  try {
    const qs = queryString.stringify({
      page: page,
      pagesize: pagesize,
      ...otherParams
    });
    const res = await axios.delete('/api/v1/inspayment/'+id+'?'+qs);
    dispatch ({type: types.BILLING_INSPAYMENT_DELETE_SUCCESS, payload: {}});
    dispatch ({type: types.BILLING_INSPAYMENT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.BILLING_INSPAYMENT_DELETE_FAILURE, error: err.response});
  }
}

export const deletePaymentClear = () => async dispatch => {
  dispatch ({type: types.BILLING_INSPAYMENT_DELETE_CLEAR});
}

export const rateTableCreate = (data) => async dispatch => {
  dispatch ({type: types.RATETABLE_CREATE_REQUEST});
  try {
    const res = await axios.post('/api/v1/ratetables',data);
    dispatch ({type: types.RATETABLE_CREATE_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.RATETABLE_CREATE_FAILURE, error: err.response});
  }
}

export const rateTableCreateClear = () => async dispatch => {
  dispatch ({type: types.RATETABLE_CREATE_CLEAR});
}

export const rateTableSave = (id,data) => async dispatch => {
  dispatch ({type: types.RATETABLE_SAVE_REQUEST});
  try {
    const res = await axios.put('/api/v1/ratetables/'+id,data);
    dispatch ({type: types.RATETABLE_SAVE_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.RATETABLE_SAVE_FAILURE, error: err.response});
  }
}

export const rateTableSaveClear = () => async dispatch => {
  dispatch ({type: types.RATETABLE_SAVE_CLEAR});
}

export const getRateTableList = () => async dispatch => {
  dispatch ({type: types.RATETABLE_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/ratetables');
    dispatch ({type: types.RATETABLE_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.RATETABLE_LIST_FAILURE, error: err.response});
  }
}

export const rateTableListClear = () => async dispatch => {
  dispatch ({type: types.RATETABLE_LIST_CLEAR});
}

export const getRateTable = (id) => async dispatch => {
  dispatch ({type: types.RATETABLE_LOAD_REQUEST});
  try {
    const res = await axios.get('/api/v1/ratetables/'+id);
    dispatch ({type: types.RATETABLE_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.RATETABLE_LOAD_FAILURE, error: err.response});
  }
}

export const rateTableLoadClear = () => async dispatch => {
  dispatch ({type: types.RATETABLE_LOAD_CLEAR});
}

// Payer calls

export const getPayerList = () => async dispatch => {
  dispatch ({type: types.PAYER_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/payer');
    dispatch ({type: types.PAYER_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.PAYER_LIST_FAILURE, error: err.response});
  }
}

export const payerListClear = () => async dispatch => {
  dispatch ({type: types.PAYER_LIST_CLEAR});
}

export const payerDeactivate = (id) => async dispatch => {
  dispatch ({type: types.PAYER_DEACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/payer/'+id+'/deactivate');
    dispatch ({type: types.PAYER_DEACTIVATE_SUCCESS});
    dispatch ({type: types.PAYER_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.PAYER_DEACTIVATE_FAILURE, error: err.response});
  }
};

export const payerDeactivateClear = () => async dispatch => {
  dispatch ({type: types.PAYER_DEACTIVATE_CLEAR});
}

export const payerReactivate = (id) => async dispatch => {
  dispatch ({type: types.PAYER_REACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/payer/'+id+'/reactivate');
    dispatch ({type: types.PAYER_REACTIVATE_SUCCESS});
    dispatch ({type: types.PAYER_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.PAYER_REACTIVATE_FAILURE, error: err.response});
  }
};

export const payerReactivateClear = () => async dispatch => {
  dispatch ({type: types.PAYER_REACTIVATE_CLEAR});
}

export const payerLoad = (id) => async dispatch => {
  dispatch ({type: types.PAYER_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/payer/'+id);
    dispatch ({type: types.PAYER_LOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.PAYER_LOAD_FAILURE, error: err.response});
  }
}

export const payerLoadClear = () => async dispatch => {
  dispatch ({type: types.PAYER_LOAD_CLEAR});
}

export const payerSave = (id,data) => async dispatch => {
  dispatch ({type: types.PAYER_SAVE_REQUEST});
  try {
    await axios.put('/api/v1/payer/'+id,data);
    dispatch ({type: types.PAYER_SAVE_SUCCESS});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.PAYER_SAVE_FAILURE, error: err.response});
  }
}

export const payerSaveClear = () => async dispatch => {
  dispatch ({type: types.PAYER_SAVE_CLEAR});
}

export const payerCreate = (data) => async dispatch => {
  dispatch ({type: types.PAYER_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/payer',data);
    dispatch ({type: types.PAYER_CREATE_SUCCESS, payload: resp.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.PAYER_CREATE_FAILURE, error: err.response});
  }
}

export const payerCreateClear = () => async dispatch => {
  dispatch ({type: types.PAYER_CREATE_CLEAR});
}

// Tpa calls

export const getTpaList = () => async dispatch => {
  dispatch ({type: types.TPA_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/tpa');
    dispatch ({type: types.TPA_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.TPA_LIST_FAILURE, error: err.response});
  }
}

export const tpaListClear = () => async dispatch => {
  dispatch ({type: types.TPA_LIST_CLEAR});
}

export const tpaDeactivate = (id) => async dispatch => {
  dispatch ({type: types.TPA_DEACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/tpa/'+id+'/deactivate');
    dispatch ({type: types.TPA_DEACTIVATE_SUCCESS});
    dispatch ({type: types.TPA_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.TPA_DEACTIVATE_FAILURE, error: err.response});
  }
};

export const tpaDeactivateClear = () => async dispatch => {
  dispatch ({type: types.TPA_DEACTIVATE_CLEAR});
}

export const tpaReactivate = (id) => async dispatch => {
  dispatch ({type: types.TPA_REACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/tpa/'+id+'/reactivate');
    dispatch ({type: types.TPA_REACTIVATE_SUCCESS});
    dispatch ({type: types.TPA_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.TPA_REACTIVATE_FAILURE, error: err.response});
  }
};

export const tpaReactivateClear = () => async dispatch => {
  dispatch ({type: types.TPA_REACTIVATE_CLEAR});
}

export const tpaLoad = (id) => async dispatch => {
  dispatch ({type: types.TPA_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/tpa/'+id);
    dispatch ({type: types.TPA_LOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.TPA_LOAD_FAILURE, error: err.response});
  }
}

export const tpaLoadClear = () => async dispatch => {
  dispatch ({type: types.TPA_LOAD_CLEAR});
}

export const tpaSave = (id,data) => async dispatch => {
  dispatch ({type: types.TPA_SAVE_REQUEST});
  try {
    await axios.put('/api/v1/tpa/'+id,data);
    dispatch ({type: types.TPA_SAVE_SUCCESS});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.TPA_SAVE_FAILURE, error: err.response});
  }
}

export const tpaSaveClear = () => async dispatch => {
  dispatch ({type: types.TPA_SAVE_CLEAR});
}

export const tpaCreate = (data) => async dispatch => {
  dispatch ({type: types.TPA_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/tpa',data);
    dispatch ({type: types.TPA_CREATE_SUCCESS, payload: resp.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.TPA_CREATE_FAILURE, error: err.response});
  }
}

export const tpaCreateClear = () => async dispatch => {
  dispatch ({type: types.TPA_CREATE_CLEAR});
}

// CPT calls

export const getCptList = () => async dispatch => {
  dispatch ({type: types.CPT_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/cpt');
    dispatch ({type: types.CPT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.CPT_LIST_FAILURE, error: err.response.data});
  }
}

export const cptListClear = () => async dispatch => {
  dispatch ({type: types.CPT_LIST_CLEAR});
}

export const cptDeactivate = (id) => async dispatch => {
  dispatch ({type: types.CPT_DEACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/cpt/'+id+'/deactivate');
    dispatch ({type: types.CPT_DEACTIVATE_SUCCESS});
    dispatch ({type: types.CPT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.CPT_DEACTIVATE_FAILURE, error: err.response.data});
  }
};

export const cptDeactivateClear = () => async dispatch => {
  dispatch ({type: types.CPT_DEACTIVATE_CLEAR});
}

export const cptReactivate = (id) => async dispatch => {
  dispatch ({type: types.CPT_REACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/cpt/'+id+'/reactivate');
    dispatch ({type: types.CPT_REACTIVATE_SUCCESS});
    dispatch ({type: types.CPT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.CPT_REACTIVATE_FAILURE, error: err.response.data});
  }
};

export const cptReactivateClear = () => async dispatch => {
  dispatch ({type: types.CPT_REACTIVATE_CLEAR});
}

export const cptLoad = (id) => async dispatch => {
  dispatch ({type: types.CPT_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/cpt/'+id);
    dispatch ({type: types.CPT_LOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.CPT_LOAD_FAILURE, error: err.response.data});
  }
}

export const cptLoadClear = () => async dispatch => {
  dispatch ({type: types.CPT_LOAD_CLEAR});
}

export const cptSave = (id,data) => async dispatch => {
  dispatch ({type: types.CPT_SAVE_REQUEST});
  try {
    await axios.put('/api/v1/cpt/'+id,data);
    dispatch ({type: types.CPT_SAVE_SUCCESS});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.CPT_SAVE_FAILURE, error: err.response.data});
  }
}

export const cptSaveClear = () => async dispatch => {
  dispatch ({type: types.CPT_SAVE_CLEAR});
}

export const cptCreate = (data) => async dispatch => {
  dispatch ({type: types.CPT_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/cpt',data);
    dispatch ({type: types.CPT_CREATE_SUCCESS, payload: resp.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.CPT_CREATE_FAILURE, error: err.response.data});
  }
}

export const cptCreateClear = () => async dispatch => {
  dispatch ({type: types.CPT_CREATE_CLEAR});
}



// Product calls

export const getProductList = () => async dispatch => {
  dispatch ({type: types.PRODUCT_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/product');
    dispatch ({type: types.PRODUCT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.PRODUCT_LIST_FAILURE, error: err.response});
  }
}

export const productListClear = () => async dispatch => {
  dispatch ({type: types.PRODUCT_LIST_CLEAR});
}

export const productDeactivate = (id) => async dispatch => {
  dispatch ({type: types.PRODUCT_DEACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/product/'+id+'/deactivate');
    dispatch ({type: types.PRODUCT_DEACTIVATE_SUCCESS});
    dispatch ({type: types.PRODUCT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.PRODUCT_DEACTIVATE_FAILURE, error: err.response});
  }
};

export const productDeactivateClear = () => async dispatch => {
  dispatch ({type: types.PRODUCT_DEACTIVATE_CLEAR});
}

export const productReactivate = (id) => async dispatch => {
  dispatch ({type: types.PRODUCT_REACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/product/'+id+'/reactivate');
    dispatch ({type: types.PRODUCT_REACTIVATE_SUCCESS});
    dispatch ({type: types.PRODUCT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.PRODUCT_REACTIVATE_FAILURE, error: err.response});
  }
};

export const productReactivateClear = () => async dispatch => {
  dispatch ({type: types.PRODUCT_REACTIVATE_CLEAR});
}

export const productLoad = (id) => async dispatch => {
  dispatch ({type: types.PRODUCT_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/product/'+id);
    dispatch ({type: types.PRODUCT_LOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.PRODUCT_LOAD_FAILURE, error: err.response});
  }
}

export const productLoadClear = () => async dispatch => {
  dispatch ({type: types.PRODUCT_LOAD_CLEAR});
}

export const productSave = (id,data) => async dispatch => {
  dispatch ({type: types.PRODUCT_SAVE_REQUEST});
  try {
    await axios.put('/api/v1/product/'+id,data);
    dispatch ({type: types.PRODUCT_SAVE_SUCCESS});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.PRODUCT_SAVE_FAILURE, error: err.response});
  }
}

export const productSaveClear = () => async dispatch => {
  dispatch ({type: types.PRODUCT_SAVE_CLEAR});
}

export const productCreate = (data) => async dispatch => {
  dispatch ({type: types.PRODUCT_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/product',data);
    dispatch ({type: types.PRODUCT_CREATE_SUCCESS, payload: resp.data});
  } catch (err) {
    billingLogout(dispatch,err);
    dispatch ({type: types.PRODUCT_CREATE_FAILURE, error: err.response});
  }
}

export const productCreateClear = () => async dispatch => {
  dispatch ({type: types.PRODUCT_CREATE_CLEAR});
}
