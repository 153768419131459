import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Spin } from 'antd';
import moment from 'moment';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';

class ReportsScheduleTemplates extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pageSize: 50,
      currentPage: 1,
    }
  }

  componentDidMount() {
    this.props.reportsScheduleTemplates(this.state.currentPage,this.state.pageSize);
  }

  tableChange = (x) => {
    this.setState({
      currentPage:x.current
    },() => {
      this.props.reportsScheduleTemplates(this.state.currentPage,this.state.pageSize);
    });
  }

  report = () => {
    const columns = [{
      title: 'Start Date',
      dataIndex: 'start_date',
      width: 100,
      render: (val) => moment(val).format('MM/DD/YYYY')
    },{
      title: 'End Date',
      dataIndex: 'end_date',
      render: (val) => moment(val).format('MM/DD/YYYY')
    },{
      title: 'Provider',
      dataIndex: 'provider',
      key: 'user',
      width: 100
    },{
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: '75'
    },{
      title: 'Type',
      dataIndex: 'type',
      width: '75'
    },{
      title: 'Day of Week',
      dataIndex: 'day_of_week'
    },{
      title: 'Start Time',
      dataIndex: 'start_time'
    },{
      title: 'End Time',
      dataIndex: 'end_time'
    },{
      title: 'Duration',
      dataIndex: 'duration'
    },{
      title: '20m FU',
      dataIndex: 'allow_20m'
    },{
      title: '30m FU',
      dataIndex: 'allow_30m'
    },{
      title: '45m FU',
      dataIndex: 'allow_45m'
    },{
      title: '60m FU',
      dataIndex: 'allow_60m'
    },{
      title: 'Allow Video',
      dataIndex: 'allow_video'
    },{
      title: 'Allow Intakes',
      dataIndex: 'allow_intake',
      render: (val) => {
        return (val) ? 1 : 0;
      }
    },{
      title: 'Age\'s Allowed',
      dataIndex: 'age_category'
    },{
      title: 'Fixed Date',
      dataIndex: 'fixed_date'
    }];

    const data = safe(this.props.list,'rows',[]);
    const count = safe(this.props.list,'count',0);

    return (
      <Table
        className='reports-sessionlist-table'
        columns={columns}
        dataSource={data}
        bordered={true}
        size='small'
        rowKey='id'
        scroll={{ x:true }}
        onChange={this.tableChange}
        pagination={{
          showQuickJumper: true,
          current: this.state.currentPage,
          pageSize: this.state.pageSize,
          total: count
        }}
      />
    );
  }

  render() {
    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
            <span style={{float:'right'}}><a href="/api/v1/reports/scheduletemplates?csv=1">CSV Download</a></span>
          </div>

          <h3>Schedule Templates List</h3>

          { this.report() }

        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    list: safe(state,'reports.scheduletemplates.data',{}),
    isBusy: safe(state,'reports.schedultemplates.processing',false),
  }
}

export default connect(mapStateToProps,actions)(Form.create()(ReportsScheduleTemplates));
