import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Spin, DatePicker, Button } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import queryString from 'qs';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';

class ReportsEval extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pageSize: 50,
      currentPage: 1,
      params: {},
      display: false
    }
  }

  tableChange = (x) => {
    this.setState({
      currentPage:x.current
    },() => {
      this.props.reportsEvals(this.state.params.start_date.format('YYYY-MM-DD'),
        this.state.params.end_date.format('YYYY-MM-DD'),
        this.state.currentPage,this.state.pageSize)
    });
  }

  displayReport = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          params: values,
          currentPage: 1,
          display: true
        },()=>{
          this.props.reportsEvals(this.state.params.start_date.format('YYYY-MM-DD'),
            this.state.params.end_date.format('YYYY-MM-DD'),
            this.state.currentPage,this.state.pageSize)
        });
      }
    });
  }

  reportForm = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Form layout="inline">
          <Form.Item>
           {getFieldDecorator('start_date', {
             rules: [{ required: true, message: ' ' }],
           })(
             <DatePicker format='MM/DD/YYYY' placeholder="Starting Date"/>
           )}
           </Form.Item>
           <Form.Item>
            {getFieldDecorator('end_date', {
              rules: [{ required: true, message: ' ' }],
            })(
              <DatePicker format='MM/DD/YYYY' placeholder="Ending Date"/>
            )}
           </Form.Item>
           &nbsp;
           <Button type='primary' onClick={this.displayReport}>Display Report</Button>
        </Form>
      </div>
    );
  }

  report = () => {
    const columns = [{
      title: 'Date of Service',
      dataIndex: 'session_date',
      key: 'dos',
      width: 100,
      render: (val) => moment(val).format('MM/DD/YYYY')
    },{
      title: 'Telemedicine',
      dataIndex: 'telemedicine',
      key: 'telemedicine',
      width: 50,
      render: (val) => {
        return (val) ? 'Yes' : 'No';
      }
    },{
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100
    },{
      title: 'Provider',
      dataIndex: 'provider',
      key: 'user',
      width: 100
    },{
      title: 'Patient',
      dataIndex: 'patient',
      key: 'patient',
      width: 100,
    },{
      title: 'MR#',
      dataIndex: 'patient_id',
      key: 'patient_id',
      render: (val) => numeral(val).format('000000')
    },{
      title: 'DOB',
      dataIndex: 'patient_dob',
      key: 'patient_dob'
    },{
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: '75'
    }];

    const data = safe(this.props.list,'rows',[]);
    const count = safe(this.props.list,'count',0);

    if (this.state.display) {
      return (
        <Table
          className='reports-sessionlist-table'
          columns={columns}
          dataSource={data}
          bordered={true}
          size='small'
          rowKey='id'
          scroll={{ x:true }}
          onChange={this.tableChange}
          pagination={{
            showQuickJumper: true,
            current: this.state.currentPage,
            pageSize: this.state.pageSize,
            total: count
          }}
        />
      );
    }
  }

  render() {
    const start_date = this.props.form.getFieldValue('start_date');
    const sd = (start_date) ? start_date.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    const end_date = this.props.form.getFieldValue('end_date');
    const ed = (end_date) ? end_date.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    const qs = queryString.stringify({
      csv: 1,
      start_date: sd,
      end_date: ed
    });

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
            <span style={{float:'right'}}><a href={"/api/v1/reports/evals?"+qs}>CSV Download</a></span>
          </div>

          <h3>List of Evals</h3>

          { this.reportForm() }
          { this.report() }

        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    list: safe(state,'reports.evals.data',{}),
    isBusy: safe(state,'reports.evals.processing',false),
  }
}

export default connect(mapStateToProps,actions)(Form.create()(ReportsEval));
