import axios from 'axios';
import * as types from './types';

const settingsLogout = (dispatch,err) => {
  if (err && err.response && err.response.status === 401) {
    dispatch ({type:'USER_LOGIN_CLEAR'});
  }
}

export const loadPortalSettings = () => async dispatch => {
  dispatch ({type: types.PORTAL_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/portalalert');
    dispatch ({type: types.PORTAL_LOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type: types.PORTAL_LOAD_FAILURE, error: err.response});
  }
}

export const savePortalSettings = (data) => async dispatch => {
  dispatch ({type: types.PORTAL_SAVE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/portalalert',data);
    dispatch ({type: types.PORTAL_SAVE_SUCCESS, payload: resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type: types.PORTAL_SAVE_FAILURE, error: err.response});
  }
}

export const listHolidays = () => async dispatch => {
  dispatch ({type: types.HOLIDAY_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/holidays');
    dispatch ({type: types.HOLIDAY_LIST_SUCCESS, payload: resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type: types.HOLIDAY_LIST_FAILURE, error: err.response});
  }
}

export const loadHoliday = (id) => async dispatch => {
  dispatch ({type: types.HOLIDAY_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/holidays/'+id);
    dispatch ({type: types.HOLIDAY_LOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type: types.HOLIDAY_LOAD_FAILURE, error: err.response});
  }
}

export const loadHolidayClear = () => dispatch => {
  dispatch ({type: types.HOLIDAY_LOAD_CLEAR});
}

export const createHoliday = (data) => async dispatch => {
  dispatch ({type: types.HOLIDAY_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/holidays',data);
    dispatch ({type: types.HOLIDAY_CREATE_SUCCESS, payload: resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type: types.HOLIDAY_CREATE_FAILURE, error: err.response});
  }
}

export const createHolidayClear = () => dispatch => {
  dispatch ({type: types.HOLIDAY_CREATE_CLEAR});
}

export const updateHoliday = (id,data) => async dispatch => {
  dispatch ({type: types.HOLIDAY_UPDATE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/holidays/'+id,data);
    dispatch ({type: types.HOLIDAY_UPDATE_SUCCESS, payload: resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type: types.HOLIDAY_UPDATE_FAILURE, error: err.response});
  }
}

export const updateHolidayClear = () => dispatch => {
  dispatch ({type: types.HOLIDAY_UPDATE_CLEAR});
}

export const removeHoliday = (id) => async dispatch => {
  dispatch ({type: types.HOLIDAY_REMOVE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/holidays/'+id);
    dispatch ({type: types.HOLIDAY_REMOVE_SUCCESS, payload: resp.data});
    dispatch ({type: types.HOLIDAY_LIST_SUCCESS, payload: resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type: types.HOLIDAY_REMOVE_FAILURE, error: err.response});
  }
}

export const groupMemberAdd = (group_id,user_id) => async dispatch => {
  dispatch ({type: types.GROUPS_MEMBERADD_REQUEST});
  try {
    const resp = await axios.post('/api/v1/groups/'+group_id+'/member/'+user_id);
    dispatch ({type: types.GROUPS_INFO_SUCCESS, payload: resp.data});
    dispatch ({type: types.GROUPS_MEMBERADD_SUCCESS, payload: resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type: types.GROUPS_MEMBERADD_FAILURE, error: err.response});
  }
}

export const assignMissingGroupToPatientCareteam = (group_id) => async dispatch => {
  dispatch ({type:types.CARETEAM_ASSIGNGROUP_REQUEST});
  try {
    const resp = await axios.get('/api/v1/careteam/assigngroups/'+group_id);
    dispatch ({type: types.GROUPS_INFO_SUCCESS, payload: resp.data});
    dispatch ({type: types.CARETEAM_ASSIGNGROUP_SUCCESS,payload:resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type:types.CARETEAM_ASSIGNGROUP_FAILURE,error:err.response});
  }
}

export const reassignAdminSupport = (group_id,from_user_id,percentage,to_user_id) => async dispatch => {
  dispatch ({type: types.GROUPS_REASSIGN_REQUEST});
  try {
    const resp = await axios.get('/api/v1/reassignadmin/'+from_user_id+'/'+to_user_id+'/'+group_id+'/'+percentage);
    dispatch ({type: types.GROUPS_INFO_SUCCESS, payload: resp.data});
    dispatch ({type: types.GROUPS_REASSIGN_SUCCESS, payload: resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type: types.GROUPS_REASSIGN_FAILURE, error: err.response});
  }
}

export const groupMemberDelete = (group_id,user_id) => async dispatch => {
  dispatch ({type: types.GROUPS_MEMBERDEL_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/groups/'+group_id+'/member/'+user_id);
    dispatch ({type: types.GROUPS_INFO_SUCCESS, payload: resp.data});
    dispatch ({type: types.GROUPS_MEMBERDEL_SUCCESS, payload: resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type: types.GROUPS_MEMBERDEL_FAILURE, error: err.response});
  }
}

export const groupMemberUpdate = (group_id,user_id,data) => async dispatch => {
  dispatch ({type: types.GROUPS_MEMBERUPDATE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/groups/'+group_id+'/member/'+user_id,data);
    dispatch ({type: types.GROUPS_INFO_SUCCESS, payload: resp.data});
    dispatch ({type: types.GROUPS_MEMBERUPDATE_SUCCESS, payload: resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type: types.GROUPS_MEMBERUPDATE_FAILURE, error: err.response});
  }
}

export const groupUserList = () => async dispatch => {
  dispatch ({type: types.GROUPS_USERLIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/groupsuserlist');
    dispatch ({type: types.GROUPS_USERLIST_SUCCESS, payload: resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type: types.GROUPS_USERLIST_FAILURE, error: err.response});
  }
}

export const getGroupsInfoList = () => async dispatch => {
  dispatch ({type: types.GROUPS_INFO_REQUEST});
  try {
    const res = await axios.get('/api/v1/groupsinfo');
    dispatch ({type: types.GROUPS_INFO_SUCCESS, payload: res.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type: types.GROUPS_INFO_FAILURE, error: err.response});
  }
}

export const getOrgInfo = () => async dispatch => {
  dispatch ({type:types.ORG_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/org');
    dispatch ({type:types.ORG_LOAD_SUCCESS,payload:resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type:types.ORG_LOAD_FAILURE,error:err.response});
  }
}

export const saveOrgInfo = (data) => async dispatch => {
  dispatch ({type:types.ORG_SAVE_REQUEST});
  try {
    await axios.post('/api/v1/org',data);
    dispatch ({type:types.ORG_SAVE_SUCCESS});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type:types.ORG_SAVE_FAILURE,error:err.response});
  }
}

export const clearOrgSave = () => dispatch => {
  dispatch ({type:types.ORG_SAVE_CLEAR});
}


export const getIntegrationsInfo = () => async dispatch => {
  dispatch ({type:types.INTEGRATIONS_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/integrations');
    dispatch ({type:types.INTEGRATIONS_LOAD_SUCCESS,payload:resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type:types.INTEGRATIONS_LOAD_FAILURE,error:err.response});
  }
}

export const saveIntegrationsInfo = (data) => async dispatch => {
  dispatch ({type:types.INTEGRATIONS_SAVE_REQUEST});
  try {
    await axios.post('/api/v1/integrations',data);
    dispatch ({type:types.INTEGRATIONS_SAVE_SUCCESS});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type:types.INTEGRATIONS_SAVE_FAILURE,error:err.response});
  }
}

export const clearIntegrationsSave = () => dispatch => {
  dispatch ({type:types.INTEGRATIONS_SAVE_CLEAR});
}


export const getSchedulingInfo = () => async dispatch => {
  dispatch ({type:types.SCHEDULING_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduling');
    dispatch ({type:types.SCHEDULING_LOAD_SUCCESS,payload:resp.data});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type:types.SCHEDULING_LOAD_FAILURE,error:err.response});
  }
}

export const saveSchedulingInfo = (data) => async dispatch => {
  dispatch ({type:types.SCHEDULING_SAVE_REQUEST});
  try {
    await axios.post('/api/v1/scheduling',data);
    dispatch ({type:types.SCHEDULING_SAVE_SUCCESS});
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type:types.SCHEDULING_SAVE_FAILURE,error:err.response});
  }
}

export const clearSchedulingSave = () => dispatch => {
  dispatch ({type:types.SCHEDULING_SAVE_CLEAR});
}



export const notificationsTemplateSave = (templateId,values) => async dispatch => {
  dispatch ({type:types.NOTIFICATIONS_SAVE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/settings/notifications/'+templateId,values);
    dispatch ({type:types.NOTIFICATIONS_SAVE_SUCCESS, payload: resp.data });
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type:types.NOTIFICATIONS_SAVE_FAILURE, error: err.response});
  }
}

export const notificationsTemplateSaveClear = () => dispatch => {
  dispatch ({type:types.NOTIFICATIONS_SAVE_CLEAR});
}

export const notificationsTemplateLoad = (templateId) => async dispatch => {
  dispatch ({type:types.NOTIFICATIONS_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/settings/notifications/'+templateId);
    dispatch ({type:types.NOTIFICATIONS_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type:types.NOTIFICATIONS_LOAD_FAILURE, error: err.response});
  }
}

export const notificationsTemplateLoadClear = () => dispatch => {
  dispatch ({type:types.NOTIFICATIONS_LOAD_CLEAR});
}

export const getNotificationsTemplates = () => async dispatch => {
  dispatch ({type:types.NOTIFICATIONS_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/settings/notifications');
    dispatch ({type:types.NOTIFICATIONS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type:types.NOTIFICATIONS_LIST_FAILURE, error: err.response});
  }
}



export const emailTemplateSave = (templateId,values) => async dispatch => {
  dispatch ({type:types.EMAILS_SAVE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/settings/emails/'+templateId,values);
    dispatch ({type:types.EMAILS_SAVE_SUCCESS, payload: resp.data });
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type:types.EMAILS_SAVE_FAILURE, error: err.response});
  }
}

export const emailTemplateSaveClear = () => dispatch => {
  dispatch ({type:types.EMAILS_SAVE_CLEAR});
}

export const emailTemplateLoad = (templateId) => async dispatch => {
  dispatch ({type:types.EMAILS_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/settings/emails/'+templateId);
    dispatch ({type:types.EMAILS_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type:types.EMAILS_LOAD_FAILURE, error: err.response});
  }
}

export const emailTemplateLoadClear = () => dispatch => {
  dispatch ({type:types.EMAILS_LOAD_CLEAR});
}

export const getEmailTemplates = () => async dispatch => {
  dispatch ({type:types.EMAILS_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/settings/emails');
    dispatch ({type:types.EMAILS_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    settingsLogout(dispatch,err);
    dispatch ({type:types.EMAILS_LIST_FAILURE, error: err.response});
  }
}
