import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Modal, Tabs, message, Spin } from 'antd';

import * as actions from '../../../actions/billing';
import { safe } from '../../../helpers/reduxSelectors';

class Tpa extends Component {
  componentDidMount() {
    this.props.getTpaList();
  }

  onClickAddTpa = () => {
    this.props.history.push('/billing/tpa/0');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deactivate !== this.props.deactivate) {
      if (this.props.deactivate.success) {
        message.success("TPA Deactivated");
        this.props.tpaDeactivateClear();
      }
      if (this.props.deactivate.error) {
        message.error(this.props.deactivate.error);
        this.props.tpaDeactivateClear();
      }
    }

    if (prevProps.reactivate !== this.props.reactivate) {
      if (this.props.reactivate.success) {
        message.success("TPA Reactivated");
        this.props.tpaReactivateClear();
      }
      if (this.props.reactivate.error) {
        message.error(this.props.reactivate.error);
        this.props.tpaReactivateClear();
      }
    }
  }

  deactivate = (record) => {
    const confirm = Modal.confirm;
    const du = this.props.tpaDeactivate;

    confirm({
      title: 'Deactivate TPA: ' + record.name + '?',
      onOk() { du(record.id) },
      onCancel() {}
    });
  }

  reactivate = (record) => {
    const confirm = Modal.confirm;
    const ru = this.props.tpaReactivate;

    confirm({
      title: 'Reactivate TPA: ' + record.name + '?',
      onOk() { ru(record.id) },
      onCancel() {}
    });
  }

  renderActiveTable = () => {
    const columns = [{
      title: 'Name',
      dataIndex: 'name',
      width: 250,
      render: (text,record) => (
        <Link to={"/billing/tpa/"+record.id} style={{whiteSpace: 'nowrap'}}>{text}</Link>
      )
    },{
      title: 'Carrier Association',
      dataIndex: 'carrier_id',
      width: 200,
      render: (text,record) => (
        <span>{record.carrier.name}</span>
      )
    },{
      title: 'Payer ID',
      dataIndex: 'payerid'
    },{
      title: 'Notes',
      dataIndex: 'notes',
      width: 200
    },{
      title: 'Action',
      width: 100,
      fixed: 'right',
      render: (text, record) => (
        <span>
          <a onClick={() => { this.deactivate(record) }}>Deactivate</a>
        </span>
      )
    }];

    return (
      <Table
        dataSource={this.props.tpaList.filter(item => item.active === true)}
        columns={columns}
        rowKey="id"
        size='small'
        pagination={false}
      />

    );
  }

  renderInactiveTable = () => {
    const columns = [{
      title: 'Name',
      dataIndex: 'name',
      width: 200
    },{
      title: 'Carrier Association',
      dataIndex: 'carrier_id',
      width: 150,
      render: (text,record) => (
        <span>{record.carrier.name}</span>
      )
    },{
      title: 'Payer ID',
      dataIndex: 'payerid'
    },{
      title: 'Deactivated',
      dataIndex:'deactivatedAt',
      width: 200,
      render: (text,record) => (
        (new Date(text)).toLocaleString()
      )
    },{
      title: 'Action',
      width: 100,
      fixed: 'right',
      render: (text, record) => (
        <span>
          <a onClick={() => { this.reactivate(record) }}>Reactivate</a>
        </span>
      )
    }];

    return (
      <Table
        columns={columns}
        dataSource={this.props.tpaList.filter(item => item.active === false)}
        rowKey="id"
        size='small'
        pagination={false}
      />
    );
  }

  render() {
    const TabPane = Tabs.TabPane;
    const isBusy =
      (this.props.load.processing ||
      this.props.list.processing ||
      this.props.deactivate.processing ||
      this.props.reactivate.processing) ? true: false;

    return (
      <Spin spinning={isBusy} size="large">
        <div style={{padding:'10px'}}>
          <h3>Third-Party Processors</h3>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Active" key="1">
              { this.renderActiveTable() }
            </TabPane>
            <TabPane tab="Inactive" key="2">
              { this.renderInactiveTable() }
            </TabPane>
          </Tabs>
          <div style={{position:'fixed',bottom:40,right:40}}>
            <Button onClick={this.onClickAddTpa}
              disabled={isBusy}
              size='large'
              shape='circle'
              type="primary"
              icon={<PlusOutlined />}
            />
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    load : safe(state,'billing.tpa.load'),
    list: safe(state,'billing.tpa.list'),
    tpaList: safe(state,'billing.tpa.list.data',[]),
    deactivate: safe(state,'billing.tpa.deactivate'),
    reactivate: safe(state,'billing.tpa.reactivate')
  }
}

export default connect(mapStateToProps,actions)(Tpa);
