/**
 * A list view for creating carrier exclusions for providers.
 * 
 * Uses prop-drilling instead of context since components are just 1 level deep.
 */
import { useEffect, useRef, useState } from 'react';
import { Button, Modal, Table, Tag, notification, Spin } from 'antd';
import { orderBy } from 'lodash';
import useApiGet from 'hooks/useApiGet';
import useApiPost from 'hooks/useApiPost';
import useApiDel from 'hooks/useApiDel';
import OppositeEnds from 'components/shared/OppositeEnds';
import PickAdultChild from './IntakeAvailability_/PickAdultChild';
import AutoCompleteFilter from 'components/shared/AutoCompleteFilter';

const IntakeAvailability: React.FC = () => {
  const topRef = useRef<HTMLDivElement>(null);
  const [trigger, setTrigger] = useState(false);
  const [child, setChild] = useState(false);
  const [adult, setAdult] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [carrier, setCarrier] = useState<Record<string, string>>();
  const [provider, setProvider] = useState<Record<string, string>>();

  const [carrierFilter, setCarrierFilter] = useState<Record<string, string>>();
  const [providerFilter, setProviderFilter] = useState<Record<string, string>>();

  const [filteredData, setFilteredData] = useState<Array<Record<string, unknown>>>();

  const carriers = useApiGet('/api/v1/carrier', [], []);
  const users = useApiGet('/api/v2/carrier-exclusions', [], [trigger]);
  const exclusionCreate = useApiPost('/api/v2/carrier-exclusions');
  const exclusionRemove = useApiDel();
  
  const isLoading = carriers.loading || users.loading || exclusionCreate.loading || exclusionRemove.loading;

  useEffect(() => {
    setTrigger(true);
    topRef?.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (!users?.loading && users?.data?.length) {
      setFilteredData(filterData());
    }
  }, [carrierFilter, providerFilter, users?.data, users?.loading]);

  const onRemoveExclusion = async (carrierId, userId) => {
    if (!exclusionRemove?.loading) {
      await exclusionRemove.del(`/api/v2/carrier-exclusions/${carrierId}/${userId}`);
      setTrigger((t) => !t);
    }
  };

  const createExclusion = async () => {
    const carrierId = carrier?.value;
    const providerId = provider?.value;
    const errorMessage = 'Carrier exclusion not created';
    if (!providerId) {
      notification.error({
        message: errorMessage,
        description: 'No provider selected',
      });
      return false;
    }
    if (!carrierId) {
      notification.error({
        message: errorMessage,
        description: 'No carrier selected',
      });
      return false;
    }
    if (!adult && !child) {
      notification.error({
        message: errorMessage,
        description: 'Must select adult and/or child.',
      });
      return false;
    }
    if (!exclusionCreate?.loading) {
      const resp = await exclusionCreate?.send({
        adult,
        child,
        carrierId,
        providerId
      });
      if (resp.error) {
        notification.error({
          message: resp.message,
          description: resp.detail
        });
      }
      onCloseModal();
      setTrigger((t) => !t);
    }
  };

  const filterData = () => {
    let data = users?.data;

    // Filter Carriers
    if (carrierFilter) {
      data = data?.filter((i) => {
        const exclusions = i?.exclusion as Array<Record<string, unknown>>;
        return exclusions?.find((e: Record<string, unknown>) => {
          const exclusionCarrier = e.carrier as Record<string, unknown>;
          const carrierId = exclusionCarrier?.id;
          return carrierFilter?.value === carrierId;
        });
      });
    }
    
    // Filter Providers
    if (providerFilter) {
      data = data?.filter((i) => i?.id === providerFilter?.value);
    }

    return data;
  };

  const onCloseModal = () => {
    setAdult(false);
    setChild(false);
    setCarrier(undefined);
    setProvider(undefined);
    setModalVisible(false);
  };

  const onOpenModal = () => setModalVisible(true);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
      width: '300px',
    },
    {
      title: 'Excluded Carriers',
      dataIndex: 'exclusion',
      key: 'exclusion',
      render: (exclusions) => {
        if (exclusions.length) {
          // console.log('exclusions', exclusions);
          // After removing an exclusion, this goes from array of 2 items to array of 1 item,
          // as expected, however, the Ant table removes both!
          const ordered = orderBy(exclusions, ['carrier.name'], ['asc']).map((e: any) => {
            const isBoth = Boolean(e.adult && e.child);
            const adultChildLabel = isBoth ? 'A/C' : `${e.adult ? 'A' : ''}${e.child ? 'C' : ''}`;
            return (
              <Tag closable onClose={() => onRemoveExclusion(e.carrier_id, e.provider_id)}>
                {e.carrier.name} ({adultChildLabel})
              </Tag>
            )
          });
          // console.log('ordered exclusions', ordered);
          return ordered;
        }
        return '';
      }
    },
  ];
  
  return (
    <>
      <div className="p2">
        <OppositeEnds left={(<h3 ref={topRef}>Intake Availability</h3>)} right={(<Button type="primary" onClick={onOpenModal}>Create Carrier Exclusion</Button>)} />
        <div style={{ display: 'flex' }}>
          <div style={{ width: '300px' }} className="mr4">
            <AutoCompleteFilter
              data={users?.data?.filter((i) => i.active)}
              selected={providerFilter}
              setSelected={setProviderFilter}
              filterProperty="full_name"
              labelProperty="full_name"
              valueProperty="id"
              placeholder="Filter by provider"
            />
          </div>
          <div style={{ width: '300px' }} className="mr4">
            <AutoCompleteFilter
              data={carriers?.data?.filter((i) => i.active)}
              selected={carrierFilter}
              setSelected={setCarrierFilter}
              filterProperty="name"
              labelProperty="name"
              valueProperty="id"
              placeholder="Filter by carrier"
            />
          </div>
        </div>
        <div style={{ padding: '2rem 2rem 0 0' }}>
          <Spin tip="Loading..." spinning={isLoading}>
            <Table columns={columns} dataSource={isLoading ? [] : filteredData} pagination={false} />
          </Spin>
        </div>
      </div>
      <Modal
        title={"Create Carrier Exclusion"}
        visible={modalVisible}
        onCancel={onCloseModal}
        okText={"Create"}
        width={500}
        onOk={createExclusion}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ width: '300px' }} className="mr4">
            <AutoCompleteFilter
              data={users?.data?.filter((i) => i.active)}
              selected={provider}
              setSelected={setProvider}
              filterProperty="full_name"
              labelProperty="full_name"
              valueProperty="id"
              placeholder="Search by provider"
            />
          </div>
          <div style={{ width: '300px' }} className="mr4">
            <AutoCompleteFilter
              data={carriers?.data?.filter((i) => i.active)}
              selected={carrier}
              setSelected={setCarrier}
              filterProperty="name"
              labelProperty="name"
              valueProperty="id"
              placeholder="Search by carrier"
            />
          </div>
        </div>
        <div style={{ display: 'flex', paddingTop: '1rem', width: '150px', margin: '0 auto' }}>
          <PickAdultChild child={child} setChild={setChild} adult={adult} setAdult={setAdult} />
        </div>
      </Modal>
    </>
  );
};

export default IntakeAvailability;
