import * as types from '../../actions/billing/types';

const defaultState = {
  create: {},
  list: {},
  load: {},
  reconciled: {},
  addrecall: {},
  post: {},
  mark: {},
  tag: {},
  claimsrecall: {}
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case types.ERA_CREATE_REQUEST:
      return {
        ...state,
        create: {
          processing: true,
        }
      };
    case types.ERA_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.ERA_CREATE_FAILURE:
      return {
        ...state,
        create: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.ERA_CREATE_CLEAR:
      return {
        ...state,
        create: {}
      };

    case types.ERA_LIST_REQUEST:
      return {
        ...state,
        list: {
          processing: true,
        }
      };
    case types.ERA_LIST_SUCCESS:
      return {
        ...state,
        list: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.ERA_LIST_FAILURE:
      return {
        ...state,
        list: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.ERA_LIST_CLEAR:
      return {
        ...state,
        list: {}
      };

    case types.ERA_LOAD_REQUEST:
      return {
        ...state,
        load: {
          processing: true,
        }
      };
    case types.ERA_LOAD_SUCCESS:
      return {
        ...state,
        load: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.ERA_LOAD_FAILURE:
      return {
        ...state,
        load: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.ERA_LOAD_CLEAR:
      return {
        ...state,
        load: {}
      };

    case types.ERA_RECONCILED_LOAD_REQUEST:
      return {
        ...state,
        reconciled: {
          processing: true,
        }
      };
    case types.ERA_RECONCILED_LOAD_SUCCESS:
      return {
        ...state,
        reconciled: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.ERA_RECONCILED_LOAD_FAILURE:
      return {
        ...state,
        reconciled: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.ERA_RECONCILED_LOAD_CLEAR:
      return {
        ...state,
        reconciled: {}
      };

    case types.ERA_ADDRECALL_REQUEST:
      return {
        ...state,
        addrecall: {
          processing: true,
        }
      };
    case types.ERA_ADDRECALL_SUCCESS:
      return {
        ...state,
        addrecall: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.ERA_ADDRECALL_FAILURE:
      return {
        ...state,
        addrecall: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.ERA_ADDRECALL_CLEAR:
      return {
        ...state,
        addrecall: {}
      };

    case types.ERA_POST_REQUEST:
      return {
        ...state,
        post: {
          processing: true,
        }
      };
    case types.ERA_POST_SUCCESS:
      return {
        ...state,
        post: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.ERA_POST_FAILURE:
      return {
        ...state,
        post: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.ERA_POST_CLEAR:
      return {
        ...state,
        post: {}
      };

    case types.ERA_MARK_REQUEST:
      return {
        ...state,
        mark: {
          processing: true,
        }
      };
    case types.ERA_MARK_SUCCESS:
      return {
        ...state,
        mark: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.ERA_MARK_FAILURE:
      return {
        ...state,
        mark: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.ERA_MARK_CLEAR:
      return {
        ...state,
        mark: {}
      };

      case types.ERA_TAG_REQUEST:
        return {
          ...state,
          tag: {
            processing: true,
          }
        };
      case types.ERA_TAG_SUCCESS:
        return {
          ...state,
          tag: {
            processing: false,
            success: true,
            data: action.payload
          }
        };
      case types.ERA_TAG_FAILURE:
        return {
          ...state,
          tag: {
            processing: false,
            success: false,
            error: action.error
          }
        };
      case types.ERA_TAG_CLEAR:
        return {
          ...state,
          tag: {}
        };

        case types.ERA_CLAIMSRECALL_LIST_REQUEST:
          return {
            ...state,
            claimsrecall: {
              processing: true,
            }
          };
        case types.ERA_CLAIMSRECALL_LIST_SUCCESS:
          return {
            ...state,
            claimsrecall: {
              processing: false,
              success: true,
              data: action.payload
            }
          };
        case types.ERA_CLAIMSRECALL_LIST_FAILURE:
          return {
            ...state,
            claimsrecall: {
              processing: false,
              success: false,
              error: action.error
            }
          };
        case types.ERA_CLAIMSRECALL_LIST_CLEAR:
          return {
            ...state,
            claimsrecall: {}
          };

    default:
      return state;
  }
}
