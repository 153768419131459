import React from 'react';
import { CustomerServiceOutlined, DollarOutlined } from '@ant-design/icons';
import { Modal, Tooltip, List, Avatar,  Spin } from 'antd';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { connect } from 'react-redux';

import { safe } from '../../../../../helpers/reduxSelectors';
import * as actions_shared from '../../../../../actions/shared';
import * as actions_emr from '../../../../../actions/emr';
import { HLayout, HLayoutLeft, HLayoutContent } from '../../../../shared/Layout';
import Time from 'components/shared/Time';

//import { processEventNotification } from '../../../../../helpers/util';
//    processEventNotification(prevProps,this.props,'careteamAdd','Added to careteam');

const diffHrs = Time.getTzOffset();

class MessagingHistory extends React.Component {
  state = {
    selectedThreadId: null
  }

  componentDidUpdate(prevProps,prevState) {
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        this.props.getPatientMessageThreads(this.props.patientId);
      } else {
        this.setState({
          selectedThreadId: null
        });
      }
    }

    if (prevState.selectedThreadId !== this.state.selectedThreadId) {
      this.props.getMessagesForThread(this.state.selectedThreadId);
    }
  }

  handleCancel = () => {
    this.props.closeDialog();
  }

  onSelect = (item) => {
    this.setState({
      selectedThreadId: item.id
    });
  }

  renderMessage = (x) => {
    const leftStyle = {
      display: 'inline-block',
      maxWidth: '65%',
      border: '1px solid lightgray',
      backgroundColor: 'white',
      borderRadius: '3px',
      color: '#646464',
      fontSize: '14px',
      padding: '5px',
      margin: '0px'
    };

    const rightStyle = {
      ...leftStyle,
      float:'right',
      backgroundColor: '#a0e759', //'#05728f',
      color: '#353535'
    };

    const leftLabel = {
      margin: '0px',
      display: 'block',
      fontSize: 'smaller',
      color: '#aaaaaa'
    }

    const rightLabel = {
      ...leftLabel,
      float:'right'
    };

    let isPatient = (x.author.type === 2) ? true : false;

    const style = (isPatient) ? leftStyle : rightStyle;
    const label = (isPatient) ? leftLabel : rightLabel;

    return (
      <div style={{marginBottom:'10px',marginTop:'20px'}} key={x.id}>
        <div style={label}>{x.author.name}</div>
        <div style={{clear:'both'}}/>
        <div style={style}>{x.content}</div>
        <div style={{clear:'both'}}/>
        <div style={label}>
            {moment(x.createdAt).format('M/D/YY hh:mm A')}&nbsp;({diffHrs ? 'Local' : Time.getPSTPDT() })
        </div>
        <div style={{clear:'both'}}/>
      </div>
    );
  }

  renderMessageList = () => {
    return (
      <Spin spinning={this.props.isBusy}>
        <div style={{height: '500px',overflow: 'scroll', padding:'5px'}}>
          { this.props.messages.map(this.renderMessage)}
        </div>
      </Spin>
    )
  }

  getInitials = (name) => {
    return (name) ? (name.match(/\b\w/g) || []).join('').toUpperCase() : null;
  }

  getImageName = (z) => {
    return '/api/v1/provider/'+z.user_id+'/image';
  }

  getAvatar = (z) => {
    if (z.type === 1) {
      return (<Avatar style={{marginRight:'5px'}} src={this.getImageName(z)} />);
    } else if (z.type === 2) {
      return (<Avatar style={{backgroundColor:'red',marginRight:'5px'}}>{this.getInitials(z.name)}</Avatar>);
    } else if (z.type === 3) {
      if (z.group_id === 1) {
        return <Avatar style={{backgroundColor:'orange',color:'black',marginRight:'5px'}} icon={<CustomerServiceOutlined />}/>;
      } else if (z.group_id === 2) {
        return <Avatar style={{backgroundColor:'green',marginRight:'5px'}} icon={<DollarOutlined />} />;
      } else {
        return (<Avatar />);
      }
    }
    return null;
  }

  getAvatars = (members) => {
    let i = 0;
    return members.map(z=>{
      i++;
      const q = this.getAvatar(z);
      if (q !== null) {
        return (
          <Tooltip key={i} title={z.name}>
            {this.getAvatar(z)}
          </Tooltip>
        );
      } else {
        return null;
      }
    });
  }

  ordering = (a) => {
    if (a.type === 1) {
      return 2;
    } else if (a.type === 2) {
      return 1;
    } else {
      return 3;
    }
  }

  getListItemTitle = (item) => {
    const members = safe(item,'members',[]).sort((a,b)=>{
      return this.ordering(a)-this.ordering(b);
    });

    return (
      <div>
        {this.getAvatars(members)}
      </div>
    );
  }

  listItem = (item) => {
    const style = (item.id === this.state.selectedThreadId) ? { paddingLeft:'5px',backgroundColor: 'lightgray'} : {paddingLeft:'5px'};
    return (
      <List.Item onClick={()=>{this.onSelect(item)}} style={style} className='message-item'>
        <List.Item.Meta title={this.getListItemTitle(item)} />
      </List.Item>
    );
  }

  render() {
    return (
      <Modal
        title={safe(this.props,'patientData.full_name')+ ' Messaging History'}
        visible={this.props.visible}
        width={900}
        height={500}
        onCancel={this.handleCancel}
        wrapClassName='pcpa-modal'
        footer={null}
      >
        <HLayout>
          <HLayoutLeft style={{width:'200px',minWidth:'200px',borderRight: '1px solid #e8e8e8',overflow:'auto'}}>
            <List
              itemLayout="horizontal"
              className='message-list'
              dataSource={this.props.threads}
              style={{cursor:'pointer'}}
              renderItem={this.listItem}
            />
          </HLayoutLeft>
          <HLayoutContent>
            {this.renderMessageList()}
          </HLayoutContent>
        </HLayout>
      </Modal>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'emr.messagethreads.list.processing',false) ||
      safe(state,'emr.messagethreads.messages.processing',false)
    ) ? true : false,
    patientData: safe(state,'shared.patient.load.data',{}),
    threads: safe(state,'emr.messagethreads.list.data',[]),
    messages: safe(state,'emr.messagethreads.messages.data',[])
  }
}

export default connect(mapStateToProps,{...actions_shared,...actions_emr})(MessagingHistory);
