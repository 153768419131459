/**
 * ScheduledParameters.tsx
 * The top half of the QueueReports modal form which contains the
 * parameters for scheduling the report itself.
 */
import { useContextSelector } from 'use-context-selector';
import QueueContext from 'components/App_/ReportsV2_/QueueContext';
import { useEffect } from 'react';
import { Badge, Card } from 'antd';
import BeginReportingToggle from './ScheduledParameters_/BeginReportingToggle';
import EndReportingDate from './ScheduledParameters_/EndReportingDate';
import FrequencyToggle from './ScheduledParameters_/FrequencyToggle';
import RecurringTypeToggle from './ScheduledParameters_/RecurringTypeToggle';
import DayOfMonthToggle from './ScheduledParameters_/DayOfMonthToggle';
import DayOfMonthInput from './ScheduledParameters_/DayOfMonthInput';
import DayOfWeekToggle from './ScheduledParameters_/DayOfWeekToggle';
import styles from './ScheduledParameters.style';

const ScheduledParameters: React.FC = () => {
  const setState = useContextSelector(QueueContext, (c) => c.setState);
  const isRecurring = useContextSelector(QueueContext, (c) => c.state.isRecurring);
  const showNthDayOfMonth = useContextSelector(QueueContext, (c) => c.state.showNthDayOfMonth);
  const subType = useContextSelector(QueueContext, (c) => c.state.subType);
  const type = useContextSelector(QueueContext, (c) => c.state.type);
  const isModalVisible = useContextSelector(QueueContext, (c) => c.state.isModalVisible);

  useEffect(() => {
    if (!isModalVisible) {
      setState((s) => ({ ...s, isRecurring: undefined, type: undefined }));
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (!isRecurring) {
      setState((s) => ({ ...s, isRecurring: undefined, type: undefined }));
    }
  }, [isRecurring]);

  return (
    <Badge.Ribbon text="Schedule Parameters" color="orange">
      <Card>
        <FrequencyToggle />
        {isRecurring !== undefined && (
          <>
            {isRecurring && <RecurringTypeToggle />}
            {isRecurring && type === 1 && <DayOfWeekToggle />}
            {isRecurring && type === 2 && <DayOfMonthToggle />}
            {isRecurring && type === 2 && showNthDayOfMonth && <DayOfMonthInput />}
            {!isRecurring && type !== undefined && subType !== undefined && (
              <div style={styles.root}>
                <BeginReportingToggle />
              </div>
            )}
            {isRecurring && type !== undefined && subType !== undefined && (
              <div style={styles.root}>
                <EndReportingDate />
              </div>
            )}
          </>
        )}
      </Card>
    </Badge.Ribbon>
  );
};

export default ScheduledParameters;
