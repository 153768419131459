/* eslint-disable react/display-name */
import { useEffect, useState } from 'react';
import Table from 'antd/lib/table';
import moment from 'moment';
import reports from 'reports';
import { Button, Popover, Popconfirm, Space, message } from 'antd';
import useApiDel from 'hooks/useApiDel';
import FormattedParams from './FormattedParams';

const wrapperStyle = {
  maxWidth: '80rem',
  margin: 'auto',
};

interface PropsI {
  data: any[];
}

const ReportsLists: React.FC<PropsI> = (props: PropsI) => {
  const [scheduledReports, setScheduledReports] = useState<Record<string, unknown>[]>();
  const { data } = props;
  const reportDelete = useApiDel();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    setScheduledReports(data);
  }, [data]);

  const cancelReport = (reportId: number) => {
    reportDelete.del(`/api/v2/reports/${reportId}`);
    setScheduledReports((reports) => reports?.filter((r) => r.id !== reportId));
  };

  const onConfirm = (reportId: number) => {
    message.success('Report Canceled');
    cancelReport(reportId);
  };

  const onTableChange = (pg) => {
    const page = pg.current;
    const pageSize = pg.pageSize;
    setPage(page);
    setPageSize(pageSize);
  };

  const columns: Array<Record<string, unknown>> = [
    {
      title: 'Report Type',
      dataIndex: 'reportId',
      render: (val: number) => {
        const foundReport = reports.find((r: any) => r.id === val);
        return foundReport?.name;
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: (val: string) => {
        if (val) {
          return moment(val).format('llll');
        } else {
          return 'Immediately';
        }
      },
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: (val: string, record) => {
        if (val) {
          return moment(val).format('llll');
        } else {
          switch (record.type) {
            case 1:
            case 2:
              return 'Never';
            default:
              return 'n/a';
          }
        }
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (val: number) => {
        switch (val) {
          case 1:
            return 'Weekly';
          case 2:
            return 'Monthly';
          default:
            return 'One-Time';
        }
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_val: string, record) => {
        return (
          <Space size="middle" className="tac">
            <Popover content={<FormattedParams params={record.params} />} trigger="click">
              <Button size="small" type="default">
                View Parameters
              </Button>
            </Popover>

            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => onConfirm(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" type="primary" danger>
                Cancel
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const pagination =
    data.length > 5
      ? {
          showQuickJumper: true,
          current: page,
          pageSize,
          total: data.length,
        }
      : false;

  return (
    <>
      <div className="p4 tac" style={wrapperStyle}>
        <h4>Scheduled Reports</h4>
        <Table
          columns={columns}
          dataSource={scheduledReports}
          bordered={true}
          rowKey="id"
          onChange={onTableChange}
          pagination={pagination}
        />
      </div>
    </>
  );
};

export default ReportsLists;
