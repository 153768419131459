import React from 'react';

class UnknownChartNote extends React.Component {
  render() {
    return (
      <div className='chartnotetitle'>Unknown Chart Note</div>
    );
  }
}

export default UnknownChartNote;
