import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { PhoneOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Spin, notification } from 'antd';
import { connect } from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import ClaimDisplay from './ClaimDisplay';
import RecallEntryModal from './RecallEntryModal';

import * as actions from '../../../../actions/billing';
import { safe } from '../../../../helpers/reduxSelectors';
import { processEventNotification2 } from '../../../../helpers/util';

class EraReconcile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(props.match.params.id,10),
      claim_num: 1,
      tableExpandedState: {},
      recallEntryVisible: false,
      recallClaim: null
    }
  }

  componentDidMount() {
    this.props.loadReconciledERA(this.state.id);
  }

  componentDidUpdate(prevProps) {
    processEventNotification2(prevProps,this.props,'adjustPtEvent','Patient Responsibility Adjusted','bottomLeft');

    if (this.props.eraAddRecallSuccess === true && prevProps.eraAddRecallSuccess !== true) {
      notification['success']({
        message: 'Success',
        description: 'Entry added to recall list',
        placement: 'bottomLeft'
      });
    } else if (this.props.eraAddRecallError && prevProps.eraAddRecallError !== this.props.eraAddRecallError) {
      notification['error']({
        message: 'Error',
        description: 'Unable to add an item to recall list.',
        placement: 'bottomLeft'
      });
    }

    if (this.props.postSuccess === true && prevProps.postSuccess === false) {
      notification['success']({
        message: 'Success',
        description: 'Payment Posted',
        placement: 'bottomLeft'
      });
      this.props.history.push('/billing/era');
    }

    if (this.props.postError && !prevProps.postError) {
      notification['error']({
        message: 'Error',
        description: 'Error Posting Payment',
        placement: 'bottomLeft'
      });
    }

    if (this.props.markSuccess === true && prevProps.markSuccess === false) {
      notification['success']({
        message: 'Success',
        description: 'Payment Marked Posted',
        placement: 'bottomLeft'
      });
      this.props.history.push('/billing/era');
    }

    if (this.props.isLoadSuccess && prevProps.isLoadSuccess !== this.props.isLoadSuccess) {
      this.props.updateBillingRecentList(2,this.state.id,numeral(this.props.ediData.payment.amount).format('$0,0.00'));
      this.getTags();
    }

    if (parseInt(this.props.match.params.id,10) !== this.state.id) {
      this.setState({
        id: parseInt(this.props.match.params.id,10)
      },()=>{
        this.props.loadReconciledERA(this.state.id);
      });
    }
  }

  openRecallEntryModal = (claim) => {
    this.setState({
      recallClaim: claim,
      recallEntryVisible: true
    });
  }

  closeRecallEntryModal = () => {
    this.setState({
      recallEntryVisible: false
    });
  }

  getTags = () => {
    const claims = safe(this.props,'ediData.claims',[]);
    const claimIds = claims.map(x=>x.control_number);
    this.props.loadEraRecallItems(this.state.id,claimIds);
  }

  sumUpAllServicePayments = () => {
    const claims = safe(this.props,'ediData.claims',[]);
    if (claims.length === 0) {
      return undefined;
    }
    return claims.reduce((acc,x)=>acc+x.paid_amount,0);
  }

  renderStatus = () => {
    const payment = safe(this.props,'ediData.payment',{});
    if (payment.status === 1 && payment.billing_payment_id) {
      return (
        <div style={{backgroundColor:'rgb(255,255,224)',padding:'20px',borderBottom:'1px solid lightgray',textAlign:'center',fontWeight:'bold'}}>
          PAYMENT ALREADY STORED&nbsp;
          <Button onClick={()=>this.props.history.push('/billing/payments/1/'+payment.billing_payment_id)}>
            View Stored Payment # {numeral(payment.billing_payment_id).format('000000')}
          </Button>
        </div>
      );
    }

    if (payment.status === 2) {
      return (
        <div style={{backgroundColor:'rgb(144,238,144)',padding:'20px',borderBottom:'1px solid lightgray',textAlign:'center',fontWeight:'bold'}}>
          POSTED {payment.date} &nbsp;
          <Button onClick={()=>this.props.history.push('/billing/payments/1/'+payment.billing_payment_id)}>
            View Stored Payment # {numeral(payment.billing_payment_id).format('000000')}
          </Button>
        </div>
      );
    }
    return null;
  }

  renderPayment = () => {
    const payment = safe(this.props,'ediData.payment',{});
    const allPayments = this.sumUpAllServicePayments();
    const totalStyle = (allPayments && this.floatRound(allPayments) !== this.floatRound(payment.amount)) ? {color:'red'} : {};

    return (
      <div>
        <div><span style={{fontWeight:'bold'}}>Amount</span>: {numeral(payment.amount).format('$0,0.00')}</div>
        <div><span style={{fontWeight:'bold'}}>Itemized Totals Amount</span>: <span style={totalStyle}>{numeral(allPayments).format('$0,0.00')}</span></div>
        <div><span style={{fontWeight:'bold'}}>Date</span>: {moment(payment.date,'MM/DD/YYYY').format('MM/DD/YYYY')}</div>
        <div><span style={{fontWeight:'bold'}}>Method</span>: {payment.method}</div>
        <div><span style={{fontWeight:'bold'}}>Check #</span>: {payment.number}</div>
      </div>
    )
  }

  renderPayer = () => {
    const payer = safe(this.props,'ediData.payer',{});
    return (
      <div><span style={{fontWeight:'bold'}}>Payer</span>: {payer.name} ({payer.ein})</div>
    );
  }

  renderPayee = () => {
    const payee = safe(this.props,'ediData.payee',{});
    return (
      <div><span style={{fontWeight:'bold'}}>Payee</span>: {payee.name} ({payee.ein})</div>
    );
  }

  renderProvider = (x) => {
    return (x) ? x.firstname + ' ' + x.lastname : null;
  }

  renderPatient = (x,patient_id) => {
    if (x) {
      if (patient_id) {
        return <Link to={'/billing/patients/'+patient_id}>{x.firstname + ' ' + x.lastname}</Link>;
      } else {
        return <span>{x.firstname + ' ' + x.lastname}</span>;
      }
    }
    return null;
  }

  money = (x) => {
    if (x === undefined) {
      return '';
    }
    const y = parseFloat(parseFloat(x).toFixed(2));
    return numeral(y).format('$0,0.00');
  }

  money2 = (x,y) => {
    if (x === undefined || y === undefined) {
      return '';
    }
    const z = parseFloat(parseFloat(x+y).toFixed(2));
    return numeral(z).format('$0,0.00');
  }

  /*
  swap = (service_id,new_pt_rate) => {
    this.props.adjustPtResp(this.state.id,service_id,new_pt_rate);
  }
  */

  adjustPtResp = (claim) => {
    this.props.adjustPtResp(this.state.id,claim);
  }

  addToRecallList = (service_id) => {
    this.props.eraAddToRecallList(this.state.id,service_id);
  }

  postPayment = () => {
    this.props.eraPost(this.state.id);
  }

  markPaymentPosted = () => {
    this.props.eraMark(this.state.id);
  }

  toggleTableRow = (id) => {
    const x = this.state.tableExpandedState;
    (x[id]) ? delete(x[id]) : x[id] = 1;
    this.setState({tableExpandedState:x});
  }

  getExpandedStateIcon = (id) => {
    const handler = () => { this.toggleTableRow(id) };
    const iconName = (this.state.tableExpandedState[id]) ? 'down' : 'right';
    return <a onClick={handler}><LegacyIcon type={iconName}/></a>;
  }

  floatRound(x) {
    if (!x || x === null) {
      return 0.0;
    }
    return Math.round((x + 0.00001) * 100) / 100;
  }

  displayRecall = (claim) => {
    const hasRecallItem = this.props.claimsrecall.find(x=>x.id===claim.control_number) ? true : false;

    if (hasRecallItem) {
      return (<span>R</span>);
    } else {
      return <a onClick={()=>{this.openRecallEntryModal(claim)}}><PhoneOutlined /></a>;
    }
  }

  renderClaimSummary = (claim,lines,shadeRow) => {
    const us = claim.reconciled;
    let cpt = [];
    let billed_amount = 0;
    let allowed_amount = 0;
    let adj = 0;
    let paid_amount = 0;
    let pt_resp = 0;

    const patient_id = safe(claim,'reconciled.billing_patient_id');

    lines.forEach(x=> {
      cpt.push(x.cpt);
      billed_amount += x.billed_amount;
      allowed_amount += x.allowed_amount;
      adj += x.adj;
      paid_amount += x.paid_amount;
      pt_resp += (x.coins+x.copay+x.deductible);
    });

    // eslint-disable-next-line
    const chargedAmountsEqual = (us.id && this.floatRound(us.ins_rate+us.pt_rate) == this.floatRound(billed_amount)) ? true : false;
    const chargedHighlight = {textAlign:'right'};
    if (!chargedAmountsEqual) {
      chargedHighlight.color='red';
    }

    // eslint-disable-next-line
    const adjAmountsEqual = (us.id && this.floatRound(us.ins_adj+us.pt_adj) == this.floatRound(adj) ) ? true : false;
    const adjHighlight = {textAlign:'right'};
    if (!adjAmountsEqual) {
      adjHighlight.color = 'red';
    }

    // eslint-disable-next-line
    const ptRespAmountsEqual = (us.id && this.floatRound(pt_resp) == this.floatRound(us.pt_rate)) ? true : false;
    const ptRespHighlight = {textAlign: 'right'};
    if (!ptRespAmountsEqual) {
      ptRespHighlight.color = 'red';
    }

    const showSwap = (us.id && chargedAmountsEqual && adjAmountsEqual && !ptRespAmountsEqual) ? true : false;

    let rowStyle = (false) ? {backgroundColor:'rgb(240,240,240)',borderTop:'1px solid lightgray',verticalAlign:'top'} : {borderTop:'1px solid lightgray',verticalAlign:'top'};

    // eslint-disable-next-line
    if (this.floatRound(allowed_amount) == 0) {
      rowStyle = {backgroundColor: '#FBEAEA',borderTop:'1px solid lightgray',verticalAlign:'top'};
    }

    return (
      <tr key={claim.control_number+'A'} style={rowStyle}>
        <td>{this.getExpandedStateIcon(claim.control_number)}</td>
        <td>{claim.dos}</td>
        <td style={{color:'red'}}>{(!claim.allowed_amount) ? 'D' : ''}</td>
        <td>{this.renderPatient(claim.patient,patient_id)}</td>
        <td>{this.renderProvider(claim.provider)}</td>
        <td>{claim.payer_control_number}</td>
        <td style={{textAlign:'right'}}>{cpt.join(',')}</td>
        <td style={{textAlign:'right',borderLeft:'1px solid lightgray'}}>{this.money(billed_amount)}</td>
        <td style={{textAlign:'right'}}>{this.money(allowed_amount)}</td>
        <td style={chargedHighlight}>{this.money2(us.ins_rate,us.pt_rate)}</td>

        <td style={{textAlign:'right',borderLeft:'1px solid lightgray'}}>{this.money(adj)}</td>
        <td style={adjHighlight}>{this.money2(us.ins_adj,us.pt_adj)}</td>

        <td style={{textAlign:'right',borderLeft:'1px solid lightgray'}}>{this.money(paid_amount)}</td>
        <td style={{textAlign:'right'}}>{this.money(us.ins_paid)}</td>

        <td style={{textAlign:'right',borderLeft:'1px solid lightgray'}}>{this.money(pt_resp)}</td>
        <td style={ptRespHighlight}>{this.money(us.pt_rate)}</td>

        <td style={{borderLeft:'1px solid lightgray'}}>
          {(showSwap) ? <a onClick={()=>{this.adjustPtResp(claim)}}><SwapOutlined /></a> : ''}
        </td>
        <td>
          { this.displayRecall(claim) }
        </td>
      </tr>
    );
  }

  //swap(us.id,pt_resp)

  renderClaimDetail = (claim,shadeRow) => {
    if (this.state.tableExpandedState[claim.control_number]) {
      //const rowStyle = (shadeRow) ? {backgroundColor:'rgb(240,240,240)'} : {};
      return (
        <tr key={claim.control_number+'B'} className='expanded-row'>
          <td colSpan={17} style={{padding:'10px'}}>
            <div style={{border:'1px dashed lightgray',backgroundColor:'#FCF6E9',padding:'5px'}}>
              <ClaimDisplay data={claim}/>
            </div>
          </td>
        </tr>
      );
    }
    return null;
  }

  renderTableHeader = () => {
    return (
      <thead>
        <tr>
          <th colSpan={7}></th>
          <th colSpan={3} style={{textAlign:'center',borderLeft:'1px solid lightgray',width:'225px'}}>Ins Charged</th>
          <th colSpan={2} style={{textAlign:'center',borderLeft:'1px solid lightgray',width:'150px'}}>Adjustments</th>
          <th colSpan={2} style={{textAlign:'center',borderLeft:'1px solid lightgray',width:'150px'}}>Ins Paid</th>
          <th colSpan={2} style={{textAlign:'center',borderLeft:'1px solid lightgray',width:'150px'}}>Pt Resp</th>
          <th style={{textAlign:'center',borderLeft:'1px solid lightgray'}}></th>
          <th></th>
        </tr>
        <tr style={{borderBottom:'1px solid lightgray'}}>
          <th style={{width:'20px'}}></th>
          <th style={{width:'100px'}}>DOS</th>
          <th style={{width:'30px'}}></th>
          <th>Patient</th>
          <th>Provider</th>
          <th>Claim #</th>
          <th>CPT</th>
          <th style={{textAlign:'center',borderLeft:'1px solid lightgray',width:'75px'}}>EOB Ch</th>
          <th style={{textAlign:'center',width:'75px'}}>EOB All</th>
          <th style={{textAlign:'center',width:'75px'}}>Ledger</th>

          <th style={{textAlign:'center',borderLeft:'1px solid lightgray',width:'75px'}}>EOB</th>
          <th style={{textAlign:'center',width:'75px'}}>Ledger</th>
          <th style={{textAlign:'center',borderLeft:'1px solid lightgray',width:'75px'}}>EOB</th>
          <th style={{textAlign:'center',width:'75px'}}>Ledger</th>
          <th style={{textAlign:'center',borderLeft:'1px solid lightgray',width:'75px'}}>EOB</th>
          <th style={{textAlign:'center',width:'75px'}}>Ledger</th>
          <th style={{textAlign:'center',borderLeft:'1px solid lightgray'}} colSpan={2}>Action</th>
        </tr>
      </thead>
    );
  }

  renderTableBody = () => {
    const claims = safe(this.props,'ediData.claims',[]).sort((a,b)=>{
      if (!a.allowed_amount && b.allowed_amount) {
        return 1;
      } else if (a.allowed_amount && !b.allowed_amount) {
        return -1;
      }
      return 0;
    });

    let counter = 0;
    return claims.map(x=>{
      const shadeRow = (counter++ % 2) ? true : false;
      return [
        this.renderClaimSummary(x,x.services,shadeRow),
        this.renderClaimDetail(x,shadeRow)
      ];
    });
  }

  adjustAllPtResp = () => {
    const claimsToAdjust = safe(this.props,'ediData.claims',[]).filter(claim=>{
      const us = claim.reconciled;
      const pt_resp = claim.coins+claim.copay+claim.deductible;
      const chargedAmountsEqual = (us.id && this.floatRound(us.ins_rate+us.pt_rate) === this.floatRound(claim.billed_amount)) ? true : false;
      const adjAmountsEqual = (us.id && this.floatRound(us.ins_adj+us.pt_adj) === this.floatRound(claim.adj) ) ? true : false;
      const ptRespAmountsEqual = (us.id && this.floatRound(us.pt_rate) === this.floatRound(pt_resp)) ? true : false;
      return (us.id && chargedAmountsEqual && adjAmountsEqual && !ptRespAmountsEqual) ? true : false;
    });
    if (claimsToAdjust.length === 0) {
      notification['warning']({
        message: 'Warning',
        description: 'There are no service lines for which to adjust the patient responsibility',
        placement: 'bottomLeft'
      });
    } else {
      this.props.adjustPtResp(this.state.id,claimsToAdjust);
    }
  }

  renderClaims = () => {
    return (
      <div>
        <div style={{textAlign:'right'}}>
          <Button onClick={this.adjustAllPtResp}>
            Adjust All Patient Responsibilites
          </Button>
        </div>
        <table style={{width:'100%',marginTop:'30px'}} className='era-reconcile-table'>
          { this.renderTableHeader() }
          <tbody>
            { this.renderTableBody() }
          </tbody>
        </table>
      </div>
    );
  }

  postButton = () => {
    const payment = safe(this.props,'ediData.payment',{});
    if (payment.status === 1) {
      // NEW
      if (payment.billing_payment_id) {
        // Payment already stored: Button to mark as Posted
        return (
          <Button
            onClick={this.markPaymentPosted}
            type='primary'>
              MARK PAYMENT POSTED
          </Button>
        );
      } else {
        return (
          <div>
            <Button
              onClick={this.markPaymentPosted}
              type='primary'>
                MARK PAYMENT POSTED
            </Button>
            &nbsp;
            <Button
              onClick={this.postPayment}
              type='primary'>
                POST PAYMENT
            </Button>
          </div>
        );
      }
    }
    return null;
  }


  render() {
    return (
      <Spin spinning={this.props.isBusy}>
        { this.renderStatus() }
        <div className='main'>
          <h3>
            Reconcile and Post ERA
            <span style={{float:'right'}}>
              { this.postButton() }
            </span>
          </h3>
          { this.renderPayment() }
          { this.renderPayer() }
          { this.renderPayee() }
          { this.renderClaims() }

          <RecallEntryModal
            claim={this.state.recallClaim}
            visible={this.state.recallEntryVisible}
            onClose={this.closeRecallEntryModal}
            era_id={this.state.id}
          />
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.era.reconciled.processing',false) ||
      safe(state,'billing.serviceline.adjustpt.processing',false) ||
      safe(state,'billing.era.post.processing',false) ||
      safe(state,'billing.era.mark.processing',false)
    ) ? true : false,
    markSuccess: safe(state,'billing.era.mark.success',false),
    postSuccess: safe(state,'billing.era.post.success',false),
    postError: safe(state,'billing.era.post.error'),
    ediData: safe(state,'billing.era.reconciled.data',{}),
    isLoadSuccess: safe(state,'billing.era.reconciled.success',false),
    eraAddRecallSuccess: safe(state,'billing.era.addrecall.success'),
    errAddRecallError: safe(state,'billing.era.addrecall.error'),
    adjustPtEvent : safe(state,'billing.serviceline.adjustpt',{}),
    claimsrecall : safe(state,'billing.era.claimsrecall.data',[])
  }
}

export default connect(mapStateToProps,actions)(withRouter(EraReconcile));
