import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Spin } from 'antd';
import moment from 'moment';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';

class ReportsDupSessions extends React.Component {
  componentDidMount() {
    this.props.reportsDupSessions();
  }

  render() {
    const columns = [{
      title: 'Patient ID',
      dataIndex: 'billing_patient_id',
      key: 'id'
    },{
      title: 'Patient',
      dataIndex: 'patient',
      key: 'patient'
    },{
      title: 'Provider',
      dataIndex: 'provider',
    },{
      title: 'DOS',
      dataIndex: 'day',
      key: 'dos',
      render: (val) => {
        const m = moment(val);
        return (m.isValid()) ? m.format('MM/DD/YYYY') : "";
      }
    },{
      title: '# of Duplicates',
      dataIndex: 'nums'
    }];

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
          </div>

          <h3>Duplicate Sessions</h3>
          <Table
            className='reports-sessionlist-table'
            columns={columns}
            dataSource={this.props.data}
            bordered={true}
            size='small'
            rowKey='billing_patient_id'
            pagination={false}
          />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: safe(state,'reports.dupsessions.data',[]),
    isBusy: safe(state,'reports.dupsessions.processing',false)
  }
}

export default connect(mapStateToProps,actions)(ReportsDupSessions);
