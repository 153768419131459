/**
{ "id": { "after": 38, "before": 38 },
"user_id": { "after": 1, "before": 1 },
"comments": { "after": null, "before": null },
"end_date": { "after": "2020-07-14", "before": "2020-07-15" },
"createdAt": { "after": "2020-02-22T18:08:20.000Z", "before": "2020-02-22T18:08:20.000Z" },
"start_date": { "after": "2019-07-16", "before": "2019-07-16" },
"productivity_start_date": { "after": "2019-07-16", "before": "2019-07-16" } }
*/

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { safe } from '../../../../../helpers/reduxSelectors';

const fields = {
  start_date: { title: 'Term Start Date', type: 'date' },
  end_date: { title: 'Term End Date', type: 'date' },
  productivity_start_date: { title: 'Bonus Start Date', type: 'date' },
  comments: { title: 'Title', type: 'string' },
};

class HistoryTerm extends React.Component {
  static propTypes = {
    data: PropTypes.object,
  };

  renderValue = (x, type) => {
    switch (type) {
      case 'boolean':
        return x ? 'Yes' : 'No';
      case 'date':
        return x ? moment(x).format('MM/DD/YYYY') : '';
      default:
        return x;
    }
  };

  renderValues = () => {
    return Object.keys(fields).filter((field) => {
      const { title, type } = fields[field];
      const value = safe(this.props.data, field);
      if (value) {
        return (
          <div key={field}>
            {title}: {this.renderValue(value, type)}
          </div>
        );
      }
      return false;
    });
  };

  renderUpdate = () => {
    const changed_fields = Object.keys(fields).filter((field) => {
      return safe(this.props.data, field + '.before') !== safe(this.props.data, field + '.after');
    });
    return changed_fields.map((field) => {
      const { title, type } = fields[field];
      const before = this.renderValue(safe(this.props.data, field + '.before'), type);
      const after = this.renderValue(safe(this.props.data, field + '.after'), type);
      return (
        <div key={field}>
          {title}: {before} {'->'} {after}
        </div>
      );
    });
  };

  render() {
    if (!this.props.data) {
      return null;
    } else if (safe(this.props.data, 'id.before')) {
      return <div>{this.renderUpdate()}</div>;
    } else {
      return <div>{this.renderValues()}</div>;
    }
  }
}

export default HistoryTerm;
