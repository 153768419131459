import React from 'react';
import { Row, Col, Divider } from 'antd';
import { connect } from 'react-redux';
import numeral from 'numeral';

import * as actions from '../../../../actions/billing';
import { safe } from '../../../../helpers/reduxSelectors';
import ClaimDisplay from './ClaimDisplay';

class HF extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(props.match.params.id,10),
      claim_num: 1
    }
  }

  componentDidMount() {
    this.props.loadERA(this.state.id);
  }

  eraOverview = () => {
    return (
      <div>
        <h3 style={{textAlign:'center'}}>ERA Overview</h3>
        <Row>
          <Col span={6}>
            <div style={{fontWeight:'bold'}}>ERA Date</div>
            <div>{safe(this.props,'ediData.edi_interchange_date','')}</div>
          </Col>

          <Col span={6}>
            <div style={{fontWeight:'bold'}}>EFT Trace or Check No.</div>
            <div>{safe(this.props,'ediData.payment.number','')} ({safe(this.props,'ediData.payment.method','')})</div>
          </Col>

          <Col span={6}>
            <div style={{fontWeight:'bold'}}>EFT Payment/Check Date:</div>
            <div>{safe(this.props,'ediData.payment.date','')}</div>
          </Col>

          <Col span={6}>
            <div style={{fontWeight:'bold'}}>EFT or Check Payment Amount:</div>
            <div>{numeral(safe(this.props,'ediData.payment.amount',0)).format('$0,00.00')}</div>
          </Col>
        </Row>
      </div>
    );
  }

  payerDetail = () => {
    return (
      <div style={{paddingTop:'30px'}}>
        <h3 style={{textAlign:'center'}}>Payer Detail</h3>
        <Row>
          <Col span={4}>
            <div style={{fontWeight:'bold'}}>Payer Name</div>
            <div>{safe(this.props,'ediData.payer.name','')}</div>
          </Col>

          <Col span={4}>
            <div style={{fontWeight:'bold'}}>Payer Address</div>
            <div>{safe(this.props,'ediData.payer.address1','')} {safe(this.props,'ediData.payer.address2','')}</div>
          </Col>

          <Col span={4}>
            <div style={{fontWeight:'bold'}}>Payer City</div>
            <div>{safe(this.props,'ediData.payer.city','')}</div>
          </Col>

          <Col span={4}>
            <div style={{fontWeight:'bold'}}>Payer State</div>
            <div>{safe(this.props,'ediData.payer.state','')}</div>
          </Col>

          <Col span={4}>
            <div style={{fontWeight:'bold'}}>Payer Zip</div>
            <div>{safe(this.props,'ediData.payer.zip','')}</div>
          </Col>

          <Col span={4}>
            <div style={{fontWeight:'bold'}}>Payer EIN</div>
            <div>{safe(this.props,'ediData.payer.ein','')}</div>
          </Col>

        </Row>
      </div>
    );
  }

  providerDetail = () => {
    return (
      <div style={{paddingTop:'30px'}}>
        <h3 style={{textAlign:'center'}}>Payee Detail</h3>
        <Row>
          <Col span={3}>
            <div style={{fontWeight:'bold'}}>Provider Name</div>
            <div>{safe(this.props,'ediData.payee.name','')}</div>
          </Col>

          <Col span={3}>
            <div style={{fontWeight:'bold'}}>Address</div>
            <div>{safe(this.props,'ediData.payee.address1','')} {safe(this.props,'ediData.payee.address2','')}</div>
          </Col>

          <Col span={3}>
            <div style={{fontWeight:'bold'}}>City</div>
            <div>{safe(this.props,'ediData.payee.city','')}</div>
          </Col>

          <Col span={3}>
            <div style={{fontWeight:'bold'}}>State</div>
            <div>{safe(this.props,'ediData.payee.state','')}</div>
          </Col>

          <Col span={3}>
            <div style={{fontWeight:'bold'}}>Zip</div>
            <div>{safe(this.props,'ediData.payee.zip','')}</div>
          </Col>

          <Col span={3}>
            <div style={{fontWeight:'bold'}}>Phone</div>
            <div>{safe(this.props,'ediData.payee.phone','')}</div>
          </Col>

          <Col span={3}>
            <div style={{fontWeight:'bold'}}>TIN</div>
            <div>{safe(this.props,'ediData.payee.ein','')}</div>
          </Col>

          <Col span={3}>
            <div style={{fontWeight:'bold'}}>PBG</div>
            <div>{safe(this.props,'ediData.payee.pbg')}</div>
          </Col>

        </Row>

      </div>
    );
  }

  claims = () => {
    const claims = safe(this.props,'ediData.claims',[]);
    let i = 1;
    return (
      <div style={{paddingTop:'30px'}}>
        <h3 style={{textAlign:'center',fontWeight:'bold'}}>
          Claim List for EFT/Check {safe(this.props,'ediData.payment.number','')}
          <span style={{paddingLeft:'30px'}}>Total Claims - {claims.length}</span>
        </h3>
        { claims.map(x => {
          return (
            <div>
              <Divider />
              <h3>Claim: {i++}</h3>
              <ClaimDisplay data={x}/>
            </div>
          );
        }) }
      </div>
    );
  }

  render() {
    return (
      <div className='main'>
        {this.eraOverview()}
        {this.payerDetail()}
        {this.providerDetail()}
        {this.claims()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ediData: safe(state,'billing.era.load.data',{})
  }
}

export default connect(mapStateToProps,actions)(HF);
