import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Col, Row, message } from 'antd';
import moment from 'moment';

import * as actions from '../../../../../../actions/emr';

const FormItem = Form.Item;

class NoteAddendum extends Component {

  static defaultProps = {
    print: false
  }

  static propTypes = {
    noteId: PropTypes.number,
    patientId: PropTypes.number,
    signedDateTime: PropTypes.string,
    addendums: PropTypes.arrayOf(PropTypes.shape({
      note: PropTypes.string,
      signedAt: PropTypes.string,
      user_id: PropTypes.number
    })),
    print: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      addendum_link: true
    }
  }

  renderIndividualAddendum = (x) => {
    return (
      <div key={x.id} className='chartnote_addendum_item'>
        <div>{x.note}</div>
        <div className='chartnotescosignature'>Signed by {x.provider.full_name} on {moment(x.createdAt).format('MM/DD/YYYY h:mm')}</div>
      </div>
    )
  }

  expandForm = () => {
    this.setState({
      addendum_link: false
    })
  }

  collapseForm = () => {
    this.setState({
      addendum_link: true
    })
  }

  onAddNote = (e) => {
    const note = this.props.form.getFieldValue('note');
    if (note && note.length > 0) {
      this.props.noteCreateAddendum(this.props.noteId,note);
      this.collapseForm();
    } else {
      message.error("Can't add an addendum");
      this.collapseForm();
    }
  }

  addendumForm = () => {
    const { getFieldDecorator } = this.props.form;

    if (this.props.print) {
      return null;
    }

    if (this.state.addendum_link) {
      return <a onClick={this.expandForm}><PlusCircleOutlined /> Add Addendum</a>;
    } else {
      return (
        <div style={{width:'80%'}}>
          <Form layout='vertical' autoComplete="off">
            <Row gutter={24}>
              <Col span={18}>
                <FormItem>
                  {getFieldDecorator('note', {})(
                    <Input placeholder='Enter Addendum'/>
                  )}
                </FormItem>
              </Col>

              <Col span={3}>
                <Button type="primary" onClick={this.onAddNote}>Add</Button>
              </Col>

              <Col span={3}>
                <Button type="danger" onClick={this.collapseForm}><DeleteOutlined /></Button>
              </Col>
            </Row>
          </Form>
        </div>
      );
    }
  }

  render() {
    if (this.props.signedDateTime === null) {
      return null;
    }

    if (this.props.addendums && this.props.addendums.length > 0) {
      return (
        <div className='chartnote_addendum'>
          { this.props.print && <h4>Note Addendums</h4> }
          { this.props.addendums.map(x => this.renderIndividualAddendum(x)) }
          { this.addendumForm() }
        </div>
      );
    }

    return (
      <div style={{marginTop:'20px'}}>
        { this.addendumForm() }
      </div>
    );
  }
}

export default connect(null,actions)(Form.create()(NoteAddendum));
