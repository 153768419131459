import React, { ReactNode } from 'react';
import DirectorContext from './DirectorContext';
import useFetch, { CachePolicies } from 'use-http';

interface PropsI {
  children: ReactNode;
}

const DirectorWrapper: React.FC<PropsI> = (props: PropsI) => {
  const getProviderList = useFetch(`/api/v1/scheduler/provider`, { cachePolicy: CachePolicies.NO_CACHE }, []);
  return (
    <DirectorContext.Provider
      value={{
        getProviderList
      }}
    >
      {props.children}
    </DirectorContext.Provider>
  );
};

export default DirectorWrapper;
