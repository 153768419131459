export default (x: number): string => {
  switch (x) {
    case 1:
      return 'Therapy Progress Note';
    case 2:
      return 'Prescriber Progress Note';
    case 3:
      return 'General Chart Note';
    case 4:
      return 'Admin Time Note';
    case 5:
      return 'Labs Request';
    case 6:
      return 'Letter';
    case 7:
      return 'HealthFusion Progress Note';
    case 8:
      return 'Patient Registration';
    case 9:
      return 'Patient Consent';
    default:
      return 'Unknown';
  }
};
