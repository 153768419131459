import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Divider } from 'antd';

import NoteAddendum from './NoteAddendum';
import ProblemList from './ProblemList';
import AttachmentList from './AttachmentList';

import { safe } from '../../../../../../helpers/reduxSelectors';
import { fieldsMSE } from '../../ProgressNote_/MSE';
import { therapyTypes } from '../../../../../../helpers/therapyTypes';

class TherapistProgressNote extends Component {

  breakLines = (x) => {
    if (!x) {
      return x;
    }
    return x.split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    });
  }

  renderTitle = (x) => {
    const hasLink = (this.props.signedDateTime === null && this.props.providerId === this.props.userId);

    return (x ? (
      <div className='chartnotetitle'>
        {(hasLink && !this.props.print) ? (<Link to={this.props.editURL}>{x}</Link>) : x}
      </div>) : null);
  }

  renderType = (x) => {
    if (x === 1) return ("Initial Session");
    if (x === 2) return ("Followup");
    return null;
  }

  getStatusName = (x) => {
    if (x === 1) return (<span className='status_show'>Show</span>);
    if (x === 2) return (<span className='status_noshow'>No Show</span>);
    if (x === 3) return (<span className='status_latecancel'>Late Cancel</span>);
    return null;
  }

  renderSessionStatus = (fieldValue) => {
    return (fieldValue ? (
      <div className='chartnotefield'>
        <span className='chartnotefieldlabel'>Session Status </span>
        {this.getStatusName(fieldValue)}
      </div>
    ) : null);
  }

  renderSignature = () => {
    return (this.props.signedDateTime ? (
      <div className='chartnotesignature'>
        Electronically signed by {this.props.providerName} at {moment(this.props.signedDateTime).format("MM/DD/YYYY h:mm A")}
      </div>
    ) : null);
  }

  renderCounterSignature = () => {
    return (this.props.counterSignedDateTime ? (
      <div className='chartnotescosignature'>
        Electronically signed by {this.props.supervisingProviderName} at {moment(this.props.counterSignedDateTime).format("MM/DD/YYYY h:mm A")}
      </div>
    ) : null);
  }

  renderItem = (fieldValue,label) => {
    return (fieldValue ? (
      <div className='chartnotefield'>
        <span className='chartnotefieldlabel'>{label} </span>{this.breakLines(fieldValue)}
      </div>
    ) : null);
  }

  renderMentalStatus = () => {
    if (this.props.mentalStatus) {
      return (
        <div className='chartnotefield'>
          {fieldsMSE.map(a => (
            <div className='chartnote_ms' key={a.name}>
              <span className='chartnotefieldlabel'>{a.label} </span>
              {this.props.mentalStatus[a.name]}
            </div>
          ))}
        </div>
      );
    }
    return null;
  }

  renderProblemList = () => {
    return (
      <ProblemList problemlist={this.props.problemlist} print={this.props.print}/>
    );
  }

  displaySessionDuration = () => {
    return moment(this.props.session_date) < moment('2022-10-30') ? '60' : this.props.session_duration;
  }

  renderNoteHeader = () => {
    return (
      <table style={{borderCollapse:'separate',borderSpacing:'0px 2px'}}>
        <tbody>
          <tr>
            <td className='chartnotefieldlabel' style={{width:'150px',padding:'0px'}}>Provider:</td>
            <td style={{padding:'0px'}}>{this.props.providerName}</td>
          </tr>
          <tr>
            <td className='chartnotefieldlabel' style={{width:'150px',padding:'0px'}}>Location:</td>
            <td style={{padding:'0px'}}>{this.props.locationName}</td>
          </tr>
          <tr>
            <td className='chartnotefieldlabel' style={{width:'150px',padding:'0px'}}>Telemedicine:</td>
            <td style={{padding:'0px'}}>{(this.props.telemedicine) ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td className='chartnotefieldlabel' style={{width:'150px',padding:'0px'}}>Session Type:</td>
            <td style={{padding:'0px'}}>{this.renderType(this.props.session_type)} &nbsp; {this.displaySessionDuration()} minutes</td>
          </tr>
          <tr>
            <td className='chartnotefieldlabel' style={{width:'150px',padding:'0px'}}>Session Status:</td>
            <td style={{padding:'0px'}}>{this.getStatusName(this.props.session_status)}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderTherapy = () => {
    const tt_record = therapyTypes.find(x=>x.value===this.props.therapy_type);
    const tt = (tt_record) ? tt_record.title : undefined;

    // Use therapy type from a helper so it doesn't exist in multiple places
    return (
      <div>
        <div>{this.breakLines(this.props.therapy)}</div>
        <table style={{borderCollapse:'separate',borderSpacing:'0px 2px'}}>
          <tbody>
            <tr>
              <td className='chartnotefieldlabel' style={{width:'150px'}}>Therapy Type:</td>
              <td>{tt}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  renderNote = () => {
    return (

      <div>
        <Divider orientation="left">CHIEF COMPLAINT</Divider>
        <div>{this.breakLines(this.props.cc)}</div>

        <Divider orientation="left">HPI/SESSION SUMMARY</Divider>
        <div>{this.breakLines(this.props.hpi)}</div>

        <Divider orientation="left">PAST PSYCHIATRIC HISTORY</Divider>
        <div>{this.breakLines(this.props.psy_history)}</div>

        <Divider orientation="left">SUBSTANCE ABUSE HISTORY</Divider>
        <div>{this.breakLines(this.props.sub_history)}</div>

        <Divider orientation="left">SOCIAL HISTORY</Divider>
        <div>{this.breakLines(this.props.social_history)}</div>

        <Divider orientation="left">MENTAL STATUS EXAM</Divider>
        {this.renderMentalStatus()}

        {this.props.therapy_type && <Divider orientation="left">THERAPY</Divider> }
        {this.props.therapy_type && this.renderTherapy()}

        <Divider orientation="left">PROBLEM LIST</Divider>
        {this.renderProblemList()}

        <Divider orientation="left">ASSESSMENT AND PLAN</Divider>
        <div>{this.breakLines(this.props.poc)}</div>
      </div>
    );
  }

  render() {
    const noteUnsigned = (this.props.signedDateTime === null) ? true : false;
    const noteNeedsToBeCountersigned = (this.props.supervisingProviderId !== null) ? true : false;
    const noteIsUnCountersigned = (this.props.counterSignedDateTime === null) ? true : false;
    const noteAuthorIsThisProvider = (this.props.providerId === this.props.userId || this.props.userId === this.props.supervisingProviderId) ? true : false;
    const noteInProcess = ( (noteUnsigned && !noteAuthorIsThisProvider) ||
         (noteNeedsToBeCountersigned && noteIsUnCountersigned && !noteUnsigned && !noteAuthorIsThisProvider) );
    const waitingForFeedback = (!noteUnsigned && noteNeedsToBeCountersigned && noteIsUnCountersigned && noteAuthorIsThisProvider) ? true : false;

    if (noteInProcess) {
      return (
        <div style={{border:'1px dashed lightgray',backgroundColor:'rgb(219,236,252)',marginRight:'20px'}}>
          <div className='chartnotetitle' style={{padding:'20px'}}>
            Therapist Progress Note by {this.props.providerName} in process...
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {this.renderTitle('THERAPY ENCOUNTER NOTE')}
          {waitingForFeedback && <div><span style={{backgroundColor:'yellow'}}>Waiting for Supervisor Review</span></div>}

          { this.renderNoteHeader() }

          {this.props.session_status===1 ? this.renderNote() : this.renderItem(this.props.otherNote,'Note')}
          {this.renderSignature()}
          {this.renderCounterSignature()}
          <NoteAddendum
            patientId={this.props.patientId}
            signedDateTime={this.props.signedDateTime}
            noteId={this.props.noteId}
            addendums={this.props.addendums}
            print={this.props.print}
          />
          <AttachmentList list={this.props.attachments} />
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    attachments: safe(ownProps,'data.attachments',[]),
    providerName: safe(ownProps,'data.provider.full_name'),
    locationName: safe(ownProps,'data.location.name'),
    type: safe(ownProps,'data.type'),
    session_type: safe(ownProps,'data.note.session_type'),
    session_status: safe(ownProps,'data.note.session_status'),
    session_date: safe(ownProps,'data.session.session_date'),
    session_duration: safe(ownProps,'data.note.session_duration'),
    cc: safe(ownProps,'data.note.cc'),
    hpi: safe(ownProps,'data.note.hpi'),
    poc: safe(ownProps,'data.note.poc'),
    mentalStatus: safe(ownProps,'data.note.mental_status'),
    problemlist: safe(ownProps,'data.note.problemlist'),
    otherNote: safe(ownProps,'data.note.other_note'),
    providerId: safe(ownProps,'data.provider_id'),
    patientId: safe(ownProps,'data.patient_id'),
    signedDateTime: safe(ownProps,'data.signedAt',null),
    supervisingProviderId: safe(ownProps,'data.supervising_provider_id'),
    supervisingProviderName: safe(ownProps,'data.supervisor.full_name'),
    supervisorComment: safe(ownProps,'data.supervisor_comment'),
    counterSignedDateTime: safe(ownProps,'data.countersignedAt'),
    noteId: safe(ownProps,'data.id'),
    addendums: safe(ownProps,'data.addendums'),
    editURL: '/emr/patients/'+ownProps.data.patient_id+'/progressnote/'+ownProps.data.id,
    psy_history: safe(ownProps,'data.note.psy_history'),
    sub_history: safe(ownProps,'data.note.sub_history'),
    social_history: safe(ownProps,'data.note.social_history'),
    therapy_type: safe(ownProps,'data.note.therapy_type'),
    telemedicine: safe(ownProps,'data.telemedicine')
  }
}

export default connect(mapStateToProps,null)(TherapistProgressNote);
