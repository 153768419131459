/**
 * NoteHide.tsx
 * Takes the note from ChartNotesTable.js as a prop.
 * The form and API endpoints necessary are established here.
 * State and Context is also provided here.
 */
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Context, { defaultState } from './NoteHide_/context';
import { Form, notification } from 'antd';
import useApiPost from 'hooks/useApiPost';
// import useApiGet from 'hooks/useApiGet';
import NoteHideModal from './NoteHide_/Modal';
import useFetch, { CachePolicies } from 'use-http';
import { NOTE_LIST_SUCCESS, NOTE_LIST_REQUEST, NOTE_LIST_FAILURE } from 'actions/emr/types';
import get from 'lodash/get';

interface PropsI {
  note: Record<string, unknown>;
}

const NoteHide: React.FC<PropsI> = (props: PropsI): any => {
  // const [trigger, setTrigger] = useState(false);
  const { note } = props;
  const [state, setState] = useState(defaultState);
  const [form] = Form.useForm();
  const hideNoteAPI = useApiPost('/api/v2/notes/hide');
  const patientId = useSelector((state) => get(state, 'emr.emr.patientId'));
  const dispatch = useDispatch();
  const patientNotesListURL = `/api/v1/notes/0/list/${patientId}`;
  // const getNotesListAPI = useApiGet(`/api/v1/notes/0/list/${patientId}`, [], [trigger]);
  const noteHideAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });

  const doHideNote = async () => {
    if (!hideNoteAPI?.loading) {
      const validatedData = await form.validateFields();
      if (validatedData) {
        dispatch({ type: NOTE_LIST_REQUEST });
        const response = await hideNoteAPI?.send(validatedData);
        if (response?.error) {
          dispatch({ type: NOTE_LIST_FAILURE, error: response?.error });
          notification.error({
            message: 'Removal Problem',
            description: response?.message,
          });
        } else {
          const data = await noteHideAPI.get(patientNotesListURL);
          dispatch({ type: NOTE_LIST_SUCCESS, payload: data });
          setState((s) => ({ ...s, isModalVisible: false }));
          notification.success({
            message: 'Removal Successful',
            description: 'The chart note and/or attachment(s) have been removed.',
          });
        }
      }
    }
  };

  const context = {
    state: {
      ...state,
      form,
      hideNoteAPI,
      note,
    },
    setState,
    doHideNote,
  };

  return (
    <Context.Provider value={context}>
      <NoteHideModal />
    </Context.Provider>
  );
};

export default NoteHide;
