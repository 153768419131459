import React from 'react';
import { connect } from 'react-redux';
import { Radio } from 'antd';

import History from './Providers_/History';
import Productivity from './Providers_/Productivity';
import Templates from './Providers_/Templates';
import Terms from './Providers_/Terms';
import ProviderInfo from './Providers_/ProviderInfo';
import ProviderSettings from './Providers_/ProviderSettings';

import { safe } from '../../../helpers/reduxSelectors';
import { VLayout, VLayoutHeader, VLayoutContent, HLayout, HLayoutContent } from '../../shared/Layout';
import * as actions from '../../../actions/scheduler';
import ProviderSelector from '../../shared/ProviderSelector';

class Providers extends React.Component {
  state = {
    visible: false,
  };

  componentDidMount() {
    this.props.getProviderList();
  }

  onChangeTab = (e) => {
    this.props.setSchedulerState({
      selected_tab: e.target.value
    });
  }

  providerLoaded = () => {
    return (this.props.provider_id === 0) ? false : true;
  }

  renderMainComponent = (key) => {
    if (!this.providerLoaded()) {
      return null;
    }
    switch (key) {
      case 'templates': return <Templates provider_id={this.props.provider_id} visible={true}/>;
      case 'terms': return <Terms provider_id={this.props.provider_id} visible={true}/>;
      case 'bio': return <ProviderInfo provider_id={this.props.provider_id} visible={true}/>
      case 'productivity': return <Productivity provider_id={this.props.provider_id} visible={true}/>;
      case 'history': return <History provider_id={this.props.provider_id} visible={true}/>;
      case 'settings': return <ProviderSettings provider_id={this.props.provider_id} visible={true}/>
      default: return null;
    }
  }

  selectProvider = (x) => {
    this.props.setSchedulerState({
      provider_id: x.id,
      provider_roles: x.roles
    });
  }

  render() {
    /*
    const headerStyle = {
      background: '#2b8ed8',
      color:'white',
      paddingLeft:'0px',
      paddingRight:'0px',
      paddingTop:'5px',
      height: '50px',
      minHeight: '42px'
    };*/

    //Timeout

    const provider = this.props.providerList.find(x=>x.id === this.props.provider_id);
//    const provider_name = safe(provider,'full_name','No Provider Loaded');
    // <Button icon={(this.props.display_nav) ? 'menu-fold' : 'menu-unfold'} onClick={this.toggleNav}/>

    return (
      <HLayout>
        <HLayoutContent style={{borderLeft: '1px solid lightgray'}}>
          <VLayout>
            <VLayoutHeader style={{marginTop:'5px'}}>
              <table style={{width:'100%'}}>
                <tbody>
                  <tr style={{borderBottom: '1px solid lightgray'}}>
                    <td style={{width:'250px',textAlign:'left',paddingLeft:'10px',paddingRight:'5px',verticalAlign:'center'}}>
                      <ProviderSelector
                        onSelect={this.selectProvider}
                        initialValue={provider}
                      />
                    </td>
                    <td style={{padding:'5px'}}>
                      <Radio.Group disabled={!this.providerLoaded()} value={this.props.selected_tab} buttonStyle="solid" onChange={this.onChangeTab}>
                        <Radio.Button value="templates">Schedule Templates</Radio.Button>
                        <Radio.Button value="bio">Web</Radio.Button>
                        <Radio.Button value="settings">Settings</Radio.Button>
                        <Radio.Button value="terms">Terms</Radio.Button>
                        <Radio.Button value="productivity">Productivity</Radio.Button>
                        <Radio.Button value="history">History</Radio.Button>
                      </Radio.Group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </VLayoutHeader>
            <VLayoutContent>
              { this.renderMainComponent(this.props.selected_tab) }
            </VLayoutContent>
          </VLayout>
        </HLayoutContent>

      </HLayout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeUserName: safe(state,'shared.domain.activeUser.full_name'),
    selected_tab: safe(state,'scheduler.settings.selected_tab','templates'),
    provider_id: safe(state,'scheduler.settings.provider_id',0),
    providerList: safe(state,'scheduler.provider.list.data',[]),
    display_nav: safe(state,'scheduler.settings.display_nav',true),
    selected_template_id: safe(state,'scheduler.settings.selected_template_id'),
  }
}

export default connect(mapStateToProps,actions)(Providers);
