import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BarsOutlined, LinkOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Table, Spin } from 'antd';
import moment from 'moment';
import numeral from 'numeral';

import RecordPaymentDialog from './PtPayments_/RecordPaymentDialog';
import CreditCardDialog from './PtPayments_/CreditCardDialog';
import RefundDialog from './PtPayments_/RefundDialog';
import PaymentLinkDialog from './PtPayments_/PaymentLinkDialog';

import { safe } from '../../../../../helpers/reduxSelectors';
import * as actions from '../../../../../actions/billing';

class PtPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refundDialogVisible: false,
      refundDialogTransRecord: null,
      paymentLinkDialogVisible: false,
      paymentLinkDialogPaymentId: null
    }
  }

  showRefundDialog = (record) => {
    this.setState({
      refundDialogVisible: true,
      refundDialogTransRecord: record
    });
  }

  hideRefundDialog = () => {
    this.setState({
      refundDialogVisible: false
    });
  }

  showPaymentLinkDialog = (payment_id) => {
    this.setState({
      paymentLinkDialogVisible: true,
      paymentLinkDialogPaymentId: payment_id
    });
  }

  hidePaymentLinkDialog = () => {
    this.setState({
      paymentLinkDialogVisible: false
    });
  }

  getDOS = (x) => {
    const y = safe(x,'billing_services.dos');
    if (y) {
      return moment(y).format('MM/DD/YYYY');
    }
    return '';
  }

  unapply = (payment_detail_id) => {
    this.props.unapplyPtPayment(this.props.patientId,payment_detail_id);
  }
// style={{}}
  rowRenderer = (record) => {
    if (record.amount >= 0) {
      return (
        <div>
          <div><strong>Payment ID: </strong>{numeral(record.id).format('00000000')}</div>
          <div><strong>Check #: </strong>{record.check_num}</div>
          { record.unallocated_amount > 0  && <div><strong>Unallocated: </strong>{numeral(record.unallocated_amount).format('$0,0.00')}</div> }
          { record.refunded_amount > 0 && <div><strong>Original Payment: </strong>{numeral(record.amount).format('$0,0.00')}</div> }
          { record.refunded_amount > 0 && <div><strong>Refunded: </strong>{numeral(record.refunded_amount).format('$0,0.00')}</div> }
          <table width='100%' style={{borderTop:'1px dashed lightgray'}}>
            <tbody>
              {record.payment_details.map(x=>(
                <tr key={x.id}>
                  <td style={{width:'100px'}}>{this.getDOS(x)}</td>
                  <td style={{width:'75px'}}>{numeral(x.pt_amount).format('$0,0.00')}</td>
                  <td>{x.unapplyable && <a onClick={()=>this.unapply(x.id)}>Unapply</a>}</td>
                </tr>
              )) }
            </tbody>
          </table>
        </div>
      );
    } else {
      if (record.refund_id) {
        return (
          <div>
            Refunded from Payment ID: {numeral(record.refund_id).format('00000000')} from {moment(record.refunds.check_date).format('MM/DD/YYYY')}
          </div>
        );
      } else {
        return (
          <div>
            <a onClick={()=>{this.showPaymentLinkDialog(record.id)}}><LinkOutlined /> Please link this refund to a payment</a>
          </div>
        );
      }
    }
  }

  render() {
    const columns = [{
      title: 'Date/DOS',
      dataIndex: 'check_date',
      width: 100,
      render: (value,record) => {
        return moment(value).format('MM/DD/YYYY');
      }
    },{
      title:'Type',
      dataIndex:'payment_type',
      width: 50,
      render: (value) => {
        switch (value) {
          case 1: return 'CHK';
          case 2: return 'WIR';
          case 3: return 'CC';
          default: return '--';
        }
      }
    },{
      title: 'Amount',
      dataIndex: 'amount',
      align: 'right',
      width: 75,
      render: (value,record) => {
        let style = {};

        if (record.unallocated_amount !== 0 && value >= 0) {
          style = {
            fontWeight: 'bold',
            color: 'rgb(0,0,255)'
          };
        }

        if (record.refunded_amount) {
          return <span style={style}>{numeral(value-record.refunded_amount).format('$0,0.00')}</span>;
        }
        return <span style={style}>{numeral(value).format('$0,0.00')}</span>;
      }
    },{
      title: 'Action',
      align: 'right',
      render: (value,record) => {
        if (record.amount <= 0 || record.unallocated_amount === 0 || record.payment_type !== 3) {
          return null;
        }
        return <a onClick={()=>{this.showRefundDialog(record)}}>Refund</a>;
      }
    }];

    const patientName = safe(this.props,'patientData.full_name');
    return (
      <Spin spinning={this.props.isBusy}>
        <div>

          <table style={{paddingTop: '10px',width:'100%'}}>
            <tbody>
              <tr>
                <td>
                  <RecordPaymentDialog patientId={this.props.patientId}>
                    <span><PlusCircleOutlined /> New Payment</span>
                  </RecordPaymentDialog>
                </td>
                <td style={{textAlign:'right'}}>
                  <CreditCardDialog patientId={this.props.patientId} patientName={patientName}>
                    <span><BarsOutlined />&nbsp; Credit Cards</span>
                  </CreditCardDialog>
                </td>
              </tr>
            </tbody>
          </table>

          <RefundDialog
            visible={this.state.refundDialogVisible}
            transaction={this.state.refundDialogTransRecord}
            closeDialog={this.hideRefundDialog}
            patientId={this.props.patientId}
          />

          <PaymentLinkDialog
            visible={this.state.paymentLinkDialogVisible}
            data={this.props.data}
            closeDialog={this.hidePaymentLinkDialog}
            patientId={this.props.patientId}
            payment_id={this.state.paymentLinkDialogPaymentId}
          />

          <Table
            className='ptpayments'
            columns={columns}
            bordered={false}
            rowKey='id'
            dataSource={this.props.data}
            size='small'
            pagination={false}
            showHeader={true}
            style={{paddingTop:'10px'}}
            expandedRowRender={this.rowRenderer}
          />
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.ptpayments.create.processing',false)
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(PtPayments);
