import React from 'react';
import { connect } from 'react-redux';
import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Input, Button, Divider } from 'antd';

import { processEventNotification2 } from '../../../../../../helpers/util';
import { safe } from '../../../../../../helpers/reduxSelectors';
import * as actions from '../../../../../../actions/billing';

class AttachmentsListItem extends React.Component {
  state = {
    view: 1,
    value: null
  }

  componentDidMount() {
    this.setState({
      value: safe(this.props,'item.title')
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item !== this.props.item) {
      this.setState({
        value: safe(this.props,'item.title')
      });
    }

    processEventNotification2(prevProps,this.props,'deleteEvent','Attachment Deleted','bottomLeft');
    processEventNotification2(prevProps,this.props,'renameEvent','Attachment Renamed','bottomLeft');
  }

  onInputChange = (e) => {
    this.setState({
      value: e.target.value
    });
  }

  openAttachment = (filename) => {
    window.open('/attachments/'+filename,'_blank');
  }

  deleteAttachment = (id) => { this.props.deleteAdminAttachment(this.props.patientId,id) }

  selectEditView = () => {
    this.setState({
      view: 2
    });
  }

  cancelEditView = () => {
    this.setState({
      view: 1,
      value: safe(this.props,'item.title')
    })
  }

  renameTitle = () => {
    this.props.renameAdminAttachment(this.props.patientId,this.props.item.id,
      this.state.value);
    this.setState({
      view: 1
    });
  }

  renderViews(x) {
    if (this.state.view === 1) {
      return (
        <tr>
          <td>
            <Button type='link' onClick={()=>{this.openAttachment(x.filename)}}>
              {x.title}
            </Button>
          </td>

          <td style={{textAlign:'right',whiteSpace:'nowrap'}}>
            <Button type='link' size='small' onClick={this.selectEditView}><EditOutlined /></Button>
            <Divider type='vertical' />
            <Button type='link' size='small' onClick={()=>this.deleteAttachment(x.id)}><DeleteOutlined /></Button>
          </td>
        </tr>
      );
    } else if (this.state.view === 2){
      return (
        <tr>
          <td>
            <Input
              value={this.state.value}
              onChange={this.onInputChange}
              onPressEnter={this.renameTitle}
            />
          </td>

          <td style={{textAlign:'right'}}>
            <Button size='small' type='link' onClick={this.renameTitle}>Save</Button>
            <Divider type='vertical' />
            <Button size='small' type='link' onClick={this.cancelEditView}><CloseOutlined /></Button>
          </td>
        </tr>
      );
    }
    return null;
  }

  render() {
    return this.renderViews(this.props.item);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deleteEvent: safe(state,'billing.attachments.delete',{}),
    renameEvent: safe(state,'billing.attachments.rename',{})
  }
}

export default connect(mapStateToProps,actions)(AttachmentsListItem);
