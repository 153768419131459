import React from 'react';
import PropTypes from 'prop-types';

import { safe } from '../../../../../helpers/reduxSelectors';

class CareTeam extends React.Component {

  static propTypes = {
    careteam: PropTypes.array,
    searchFilter: PropTypes.func
  }

  selectProvider = (provider_id,c) => {
    const isPrescriber = (c === 1 || c === 2 || c === 3) ? true : false;

    this.props.searchFilter({
      treatment_type: (isPrescriber) ? 1 : 2,
      duration: (isPrescriber) ? 30 : 45,
      provider_id: provider_id,
      location_id: 0,
      number_of_weeks: 0
    });
  }

  getCareteam = () => {
    if (this.props.careteam) {
      const active = this.props.careteam.filter(y=>(y.active && y.user));
      if (active.length === 0) {
        return <div>No active members</div>
      }
      return active.map(x=>(
        <div key={x.id} style={{paddingLeft:'10px'}}>
          {x.user && <a onClick={()=>this.selectProvider(x.user.id,x.user.credential)}>{safe(x,'user.full_name')}</a> }
        </div>
      ));
    }
    return null;
  }

  render() {
    return (
      <div style={{paddingBottom:'20px'}}>
        <h3 style={{textDecoration:'underline'}}>Clinical Careteam</h3>
        { this.getCareteam() }
      </div>
    )
  }
}

export default CareTeam;
