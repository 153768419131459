import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';

const columns: Array<ColumnI> = [
  {
    title: 'Reference ID',
    dataIndex: 'ReferenceID',
  },
  {
    title: 'History Created At',
    dataIndex: 'CreatedAt',
  },
  {
    title: 'Action',
    dataIndex: 'Action',
  },
  {
    title: 'Provider',
    dataIndex: 'ProviderName',
  },
  {
    title: 'Credential',
    dataIndex: 'Credential',
  },
  { title: 'Patient First Name', dataIndex: 'PatientFirstName' },
  { title: 'Patient Last Name', dataIndex: 'PatientLastName' },
  { title: 'Patient DOB', dataIndex: 'PatientDOB' },
  {
    title: 'Patient ID',
    dataIndex: 'PatientID',
  },
  {
    title: 'Patient Email',
    dataIndex: 'PatientEmail',
  },
  {
    title: 'Session Date',
    dataIndex: 'SessionDate',
  },
  {
    title: 'Session Type',
    dataIndex: 'SessionType',
  },
  {
    title: 'Start Time',
    dataIndex: 'StartTime',
  },
  {
    title: 'Duration',
    dataIndex: 'Duration',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  { title: 'Action By', dataIndex: 'ActionBy' },
  {
    title: 'Session Date Before',
    dataIndex: 'SessionDateBefore',
  },
  {
    title: 'Session Type Before',
    dataIndex: 'SessionTypeBefore',
  },
  {
    title: 'Session Type After',
    dataIndex: 'SessionTypeAfter',
  },
  {
    title: 'Session Date After',
    dataIndex: 'SessionDateAfter',
  },
  {
    title: 'Start Time Before',
    dataIndex: 'StartTimeBefore',
  },
  {
    title: 'Start Time After',
    dataIndex: 'StartTimeAfter',
  },
  {
    title: 'Duration Before',
    dataIndex: 'DurationBefore',
  },
  {
    title: 'Duration After',
    dataIndex: 'DurationAfter',
  },
  {
    title: 'Status Before',
    dataIndex: 'StatusBefore',
  },
  {
    title: 'Status After',
    dataIndex: 'StatusAfter',
  },
];

export default columns;
