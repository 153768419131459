function credentialToString (x,byType=false) {
  if (byType) {
    switch (parseInt(x,10)) {
      case 0: return 'None';
      case 1:
      case 2:
        return 'MD/DO';
      case 3: return 'NP';
      case 4:
      case 5:
        return 'PhD/PsyD';
      case 6: return 'LCSW';
      case 7: return 'LMFT';
      case 8: return 'LPCC';
      default:
    }
  } else {
    switch (parseInt(x,10)) {
      case 0: return 'None';
      case 1: return 'MD';
      case 2: return 'DO';
      case 3: return 'NP';
      case 4: return 'PhD';
      case 5: return 'PsyD';
      case 6: return 'LCSW';
      case 7: return 'LMFT';
      case 8: return 'LPCC';
      default:
    }
  }
  return '--';
}

module.exports = {
  credentialToString
}