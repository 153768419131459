export function safeGet(data,index,def) {
  return (data && data[index] !== undefined) ? data[index] : def;
}

export function safe(x,fields,def=undefined) {
  let fields2 = fields.split('.');
  try {
    let ar = x;
    fields2.forEach(field => {
      if (ar === undefined) {
        return def;
      }
      ar = ar[field];
    });
    return (ar === undefined) ? def : ar;
  } catch (err) {
    return def;
  }
}

export function getBillingState(state,fieldName,def=undefined) {
  return safe(state,'shared.domain.billing.'+fieldName,def);
}

function checkRole(state,x) {
  const role = safe(state,'shared.auth.data.roles',[]);
  return (role.includes(x)) ? true : false;
}

export function loginInProcess(state) { return safe(state,'shared.auth.processing',false) }
export function loginSuccess(state) { return safe(state,'shared.auth.success',undefined)}
export function loginError(state) { return safe(state,'shared.auth.error') }
export function logoutInProcess(state) { return safe(state,'shared.logout.processing',false)}
export function logoutSuccess(state) { return safe(state,'shared.logout.success',false)}
export function logoutError(state) { return safe(state,'shared.logout.error')}

export function isDirector(state) { return checkRole(state,'director')}
export function isIntake(state) { return checkRole(state,'intake')}
export function isStaff(state) { return checkRole(state,'staff') }
export function isStaffSupervisor(state) { return checkRole(state,'staffsupervisor') }
export function isStaffOrSupervisor(state) { return (isStaff(state) || isStaffSupervisor(state))}
export function isSuperUser(state) { return checkRole(state,'superuser') }
export function isPrescriber(state) { return checkRole(state,'prescriber') }
export function isTherapist(state) { return checkRole(state,'therapist') }
export function isTesting(state) { return checkRole(state,'test') }
export function isClinician(state) { return (isPrescriber(state) || isTherapist(state)) }
export function isClinicalSupervisor(state) { return checkRole(state,'clinicalsup') }
export function isAdmin(state) { return (isStaffOrSupervisor(state) || isSuperUser(state)) }
export function isHR(state) { return checkRole(state,'hr') }
