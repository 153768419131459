import { combineReducers } from 'redux';
import { ajaxReducer } from '../../helpers/util';

export default combineReducers({
  codes: combineReducers({
    list: ajaxReducer('PM_CODES_LIST'),
    load: ajaxReducer('PM_CODES_LOAD'),
    create: ajaxReducer('PM_CODES_CREATE'),
    save: ajaxReducer('PM_CODES_SAVE'),
    update: ajaxReducer('PM_CODES_UPDATE'),
    deactivate: ajaxReducer('PM_CODES_DEACTIVATE'),
    reactivate: ajaxReducer('PM_CODES_REACTIVATE'),
  }),
  feeschedule: combineReducers({
    group: combineReducers({
      list: ajaxReducer('PM_FEESCHEDULE_GROUP_LIST'),
      create: ajaxReducer('PM_FEESCHEDULE_GROUP_CREATE'),
      update: ajaxReducer('PM_FEESCHEDULE_GROUP_UPDATE'),
      remove: ajaxReducer('PM_FEESCHEDULE_GROUP_REMOVE')
    }),
    schedule: combineReducers({
      list: ajaxReducer('PM_FEESCHEDULE_SCHEDULE_LIST'),
      load: ajaxReducer('PM_FEESCHEDULE_SCHEDULE_LOAD'),
      create: ajaxReducer('PM_FEESCHEDULE_SCHEDULE_CREATE'),
      update: ajaxReducer('PM_FEESCHEDULE_SCHEDULE_UPDATE'),
      remove: ajaxReducer('PM_FEESCHEDULE_SCHEDULE_REMOVE')
    })
  }),
});
