import React from 'react';
import PropTypes from 'prop-types';
import { Divider, notification, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/emr';
import Time from '../../shared/Time';

class MissingNotesList extends React.Component {
  static propTypes = {
    list: PropTypes.array,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.createNoteEvent.success !== this.props.createNoteEvent.success) {
      if (this.props.createNoteEvent.success) {
        const d = safe(this.props,'createNoteEvent.data',{});
        this.props.history.push('/emr/patients/'+d.patient_id+'/progressnote/'+d.note_id);
      } else if (this.props.createNoteEvent.error) {
        notification.error({
          message: "Can't Open Chart Note",
          description: safe(this.props.createNoteEvent,'error.data.message')
        });
      }
    }
  }

  selectSession = (x) => {
    if (x.careteam) {
      this.props.createProgressNoteFromSession(x.provider_id,x.id);
    } else {
      const This = this;
      Modal.confirm({
        title: 'Do you want to be added to this patient\'s careteam',
        content: 'In order to create a chart note for this patient you must first be part of the careteam',
        okText: 'Add to Careteam',
        onOk() {
          This.props.createProgressNoteFromSession(x.provider_id,x.id,1);
        },
        onCancel() {},
      });
    }
  }

  renderSession = (x) => {
    const date = moment(x.session_date).format('M/D/YYYY');
    const patient_name = safe(x,'patient_name','');
    const time = (new Time(safe(x,'start_time',0))).toString(false);
    return <span>{ date } { time }<br/>{ patient_name }</span>;
  }

  render() {
    if (this.props.list && this.props.list.length > 0) {
      return (
        <div style={{paddingLeft:'10px'}}>
          <Divider />
          <h4>Missing Notes ({this.props.list.length})</h4>
          <ol style={{paddingTop: '0px', paddingLeft:'15px'}}>
            {this.props.list.map(x => (
              <li key={x.id} style={{padding:'3px',fontSize:'smaller'}}>
                <a onClick={()=>this.selectSession(x)}>{ this.renderSession(x) }</a>
              </li>
            ))}
          </ol>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    createNoteEvent: safe(state,'emr.session.createnote',{}),
  }
}

export default connect(mapStateToProps,actions)(withRouter(MissingNotesList));
