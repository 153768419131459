import React from 'react';
import { Table, Tag } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import { safe } from '../../../helpers/reduxSelectors';
import { credentialToString } from '../../../helpers/credential';
import OppositeEnds from '../../shared/OppositeEnds';
import * as actions from '../../../actions/scheduler';

class Intakes extends React.Component {

  state = {
    providers_allow_intakes: []
  }

  componentDidMount() {
    this.props.getAllProductivity();
  }

  componentDidUpdate(prevProps,prevState) {
    // SET PROVIDER 'allow_intakes' VALUES
    if (!prevProps.data.length && this.props.data.length && !this.state.providers_allow_intakes.length) {
      const providers_allow_intakes = this.props.data.map(provider => {
        const { allow_intakes, provider_id } = provider;
        return { allow_intakes, provider_id };
      });
      this.setState({ providers_allow_intakes });
    }
    // KEEP TRACK OF 'allow_intakes' CHANGES
    if (prevProps.intakes_updating && !this.props.intakes_updating && this.props.updated_intake && this.props.updated_intake.updated) {
      const { allow_intakes, provider_id } = this.props.updated_intake;
      const { providers_allow_intakes } = this.state;
      const found_allow_intakes_id = providers_allow_intakes.findIndex(provider => {
        return provider.provider_id === provider_id;
      });
      providers_allow_intakes[found_allow_intakes_id] = {
        allow_intakes,
        provider_id
      }
      this.setState({ providers_allow_intakes });
      this.props.clearAllowIntakes();
    }
  }

  toggleIntakes = (record,dynamic_value) => {
    this.props.updateAllowIntakes(record.provider_id, dynamic_value ? 0 : 1);
  }

  render() {
    const columns=[{
      title: 'Location',
      dataIndex: 'location'
    },{
      title: 'Credential',
      dataIndex: 'credential',
      render: (val) => {
        return credentialToString(val);
      }
    },{
      title: 'Provider',
      dataIndex: 'provider'
    },{
      title: 'Term Start Date',
      dataIndex: 'term_start_date',
      align: 'right',
      render: (value) => {
        return (value) ? moment(value).format('ddd, M/D/YYYY') : '';
      }
    },{
      title: 'Term End Date',
      dataIndex: 'term_end_date',
      align: 'right',
      render: (value) => {
        return (value) ? moment(value).format('ddd, M/D/YYYY') : '';
      }
    },{
      title: 'Productivity Start Date',
      dataIndex: 'term_productivity_start_date',
      align: 'right',
      render: (value) => {
        return (value) ? moment(value).format('ddd, M/D/YYYY') : '';
      }
    },{
      title: 'Prod To Date',
      dataIndex: 'prod_to_date',
      align: 'right',
      render: (value) => {
        return numeral(value).format('0.0%');
      }
    }, {
      title: 'Bonus Prod To Date',
      dataIndex: 'bonus_prod_to_date',
      align: 'right',
      render: (value) => {
        return numeral(value).format('0.0%');
      }
    },{
      title: 'Productivity This Week',
      dataIndex: 'this_week_prod',
      align: 'right',
      render: (value) => {
        return numeral(value).format('0.0%');
      }
    },{
      title: 'Intakes',
      dataIndex: 'allow_intakes',
      render: (value,record) => {
        // ignore static value and utilize dynamic value
        const found_allow_intakes_id = this.state.providers_allow_intakes.findIndex(provider => {
          return provider.provider_id === record.provider_id;
        });
        const dynamic_value = found_allow_intakes_id > -1 ? this.state.providers_allow_intakes[found_allow_intakes_id].allow_intakes : null;
        if (dynamic_value) {
          return <Tag style={{cursor:'pointer'}} onClick={()=>{this.toggleIntakes(record,dynamic_value)}} color='green'>On</Tag>;
        } else {
          return <Tag style={{cursor:'pointer'}} onClick={()=>{this.toggleIntakes(record,dynamic_value)}} color='red'>Off</Tag>;
        }
      }
    }];

    return (
      <div style={{padding:'10px'}}>
        <OppositeEnds
          left={<h3>Intakes-Salaried</h3>}
          right={<a href='/api/v1/scheduler/productivity?csv=1'>CSV Download</a>}
        />
        <Table
          columns={columns}
          dataSource={this.props.data}
          pagination={false}
          size='small'
          loading={{
            size: 'large',
            tip: 'Loading Productivities',
            spinning: this.props.is_busy
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    is_busy: safe(state,'scheduler.productivity.processing',false),
    data: safe(state,'scheduler.productivity.data',[]),
    intakes_updating: safe(state,'scheduler.intakes.processing',false),
    updated_intake: safe(state,'scheduler.intakes.data',false),
  }
}

export default connect(mapStateToProps,actions)(Intakes);
