import React from 'react';
import { connect } from 'react-redux';
import { TagTwoTone } from '@ant-design/icons';
import { Spin, Table, Tag, Radio, Tooltip, Button, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';

import OppositeEnds from '../../shared/OppositeEnds';
import { safe } from '../../../helpers/reduxSelectors';
import * as billing_actions from '../../../actions/billing';
import * as shared_actions from '../../../actions/shared';
//import { processEventNotification } from '../../../helpers/util';

class Claims extends React.Component {
  constructor(props) {
    super(props);
    this.pageSize = 50;
  }

  componentDidMount() {
    this.props.getClaimsList(this.props.claimslist_type,
      this.props.claimslist_page,this.pageSize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.claimslist_page !== this.props.claimslist_page ||
        prevProps.claimslist_type !== this.props.claimslist_type) {
      this.props.getClaimsList(this.props.claimslist_type,
        this.props.claimslist_page,this.pageSize);
    }
//    processEventNotification(prevProps,this.props,'autopayEvent','Payments Applied');
  }

  changeType = (e) => {
    this.props.setBillingState({
      claimslist_type: e.target.value,
      claimslist_page: 1
    });
  }

  tagItem = (id,value) => {
    this.props.tagClaimLine(id,value,this.props.claimslist_type,
      this.props.claimslist_page,this.pageSize);
  }

  tableChange = (x) => {
    this.props.setBillingState({
      claimslist_page:x.current
    });
  }

  renderTagMenu = (id,value) => {
    const menu = (
      <Menu>
        <Menu.Item>
          <Button type='link' size='small' onClick={()=>this.tagItem(id,0)}>
            None
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button type='link' size='small' onClick={()=>this.tagItem(id,1)}>
            <TagTwoTone twoToneColor="#C0392B" />
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button type='link' size='small' onClick={()=>this.tagItem(id,2)}>
            <TagTwoTone twoToneColor="#F4D03F" />
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button type='link' size='small' onClick={()=>this.tagItem(id,3)}>
            <TagTwoTone twoToneColor="#229954" />
          </Button>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <Button type='link'>Tag...</Button>
      </Dropdown>
    );
  }

  getDownloadLink = () => {
    return <a href='/api/v1/claims/rejected'>Rejected and Denied Claims CSV Download</a>;
  }

  render() {
    const columns = [{
      width: 30,
      dataIndex: 'tagged',
      render: (value,record) => {
        switch (value) {
          case 1: return <TagTwoTone twoToneColor="#C0392B" />;
          case 2: return <TagTwoTone twoToneColor="#F4D03F" />;
          case 3: return <TagTwoTone twoToneColor="#229954" />;
          default: return null;
        }
      }
    },{
      title: 'Claim ID',
      dataIndex: 'id'
    },{
      title: 'Carrier',
      dataIndex: 'carrier_id',
      render: (value,record) => {
        return safe(record,'billing_service.carriers.abbr');
      }
    },{
      title: 'DOS',
      key: 'dos',
      render: (value,record) => {
        return moment(safe(record,'billing_service.dos')).format('MM/DD/YYYY');
      }
    },{
      title: 'Patient',
      width: 200,
      key: 'patient',
      render: (value,record) => {
        return <Link to={'/billing/patients/'+safe(record,'billing_patient.id',0)}>{safe(record,'billing_patient.patients.full_name','')}</Link>
      }
    },{
      title: 'CPT',
      key: 'cpt',
      render: (value,record) => {
        return safe(record,'billing_service.cpt_codes',[]).join(',')
      }
    },{
      title: 'Sub Batch',
      dataIndex: 'submitted_id',
    },{
      title: 'Sub Status',
      dataIndex: 'submitted_status',
      render: (value) => {
        if (value === 1) {
          return 'CREATED';
        } else if (value === 2) {
          return 'SENT';
        }
        return null;
      }
    },{
      title: 'Sub Date',
      dataIndex: 'submitted_date',
      render: (value) => {
        return (value) ? moment(value).format('MM/DD/YYYY HH:mm:ss') : null;
      }
    },{
      title: 'IBR',
      dataIndex: 'ibr_status',
      render: (value,record) => {
        switch (value) {
          case 1: return <Tag color='green'>A</Tag>;
          case 2: return <Tag color='yellow'>E</Tag>;
          case 3: return (
            <Tooltip placement='right' title={record.ibr_message}>
              <Tag color='red'>R</Tag>
            </Tooltip>
          );
          default: return '--';
        }
      }
    },{
      title: 'EBR',
      dataIndex: 'ebr_status',
      render: (value,record) => {
        switch (value) {
          case 1: return <Tag color='green'>A</Tag>;
          case 2: return <Tag color='yellow'>E</Tag>;
          case 3: return (
            <Tooltip placement='right' title={record.ebr_message}>
              <Tag color='red'>R</Tag>
            </Tooltip>
          );
          default: return '--';
        }
      }
    },{
      title: 'DPR',
      dataIndex: 'dpr_status',
      render: (value,record) => {
        switch (value) {
          case 1: return <Tag color='green'>A</Tag>;
          case 2: return <Tag color='yellow'>E</Tag>;
          case 3: return (
            <Tooltip placement='right' title={record.dpr_message}>
              <Tag color='red'>R</Tag>
            </Tooltip>
          );
          default: return '--';
        }
      }
    },
    /*
    {
      title: 'Payer Claim #',
      dataIndex: 'payer_claim_num'
    },*/
    {
      title: 'Ins Rate',
      align: 'right',
      key: 'ins_rate',
      render: (value,record) => {
        const q = safe(record,'billing_service.ins_rate');
        return (q || q===0) ? numeral(q).format('$0,0.00') : null;
      }
    },{
      title: 'Denial',
      dataIndex: 'paid_allowed_amount',
      render: (value)=>{
        if (parseInt(value,10) === 0) {
          return (
            <span style={{color:'red'}}>DENIED</span>
          );
        }
        return null;
      }
    },
    {
      title: 'Paid Amount',
      dataIndex: 'paid_amount',
      align: 'right',
      render: (value)=>{
        return (value || value===0) ? numeral(value).format('$0,0.00') : null;
      }
    },{
      title: 'Paid Date',
      dataIndex: 'paid_date',
      render: (value) => {
        return (value) ? moment(value).format('MM/DD/YYYY HH:mm:ss') : null;
      }
    },{
      title: 'Action',
      key: 'action',
      render: (value,record) => {
        return this.renderTagMenu(record.id,record.tagged);
      }
    }];
/*
<Radio.Button value={2}>Not Sent</Radio.Button>
<Radio.Button value={5}>No Payments</Radio.Button>
<Radio.Button value={6}>Over-Payments</Radio.Button>
<Radio.Button value={7}>Under-Payments</Radio.Button>
<Radio.Button value={8}>$0 Payments</Radio.Button>
*/
    return (
      <div className='main'>
        <Spin spinning={this.props.isBusy}>
          <OppositeEnds left={<h3>Claims List</h3>} right={this.getDownloadLink()} />
          <div style={{paddingBottom:'10px',textAlign:'center'}}>
            <Radio.Group value={this.props.claimslist_type} onChange={this.changeType} buttonStyle="solid">
              <Radio.Button value={1}>All</Radio.Button>
              <Radio.Button value={3}>No Response</Radio.Button>
              <Radio.Button value={4}>Claims Rejected</Radio.Button>
              <Radio.Button value={9}>Denials</Radio.Button>
              <Radio.Button value={10}><TagTwoTone twoToneColor="#C0392B" /></Radio.Button>
              <Radio.Button value={11}><TagTwoTone twoToneColor="#F4D03F" /></Radio.Button>
              <Radio.Button value={12}><TagTwoTone twoToneColor="#229954" /></Radio.Button>
            </Radio.Group>
          </div>
          <Table
            columns={columns}
            dataSource={this.props.rows}
            bordered={false}
            size='small'
            onChange={this.tableChange}
            rowKey='id'
            pagination={{
              current: this.props.claimslist_page,
              pageSize: this.pageSize,
              total: this.props.count
            }}
          />
        </Spin>
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.claims.list.processing',false)
    ) ? true : false,
    rows: safe(state,'billing.claims.list.data.rows',[]),
    count: safe(state,'billing.claims.list.data.count',1),
    claimslist_page: safe(state,'shared.domain.billing.claimslist_page',1),
    claimslist_type: safe(state,'shared.domain.billing.claimslist_type',1),
    claimsEvent: safe(state,'billing.claims.list')
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(Claims);
