/**
 * HR.tsx
 * HR Parent Component
 * Routes, Components, State Providers, etc.
 */
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VLayout, VLayoutHeader, VLayoutContent, HLayout, /*HLayoutLeft,*/ HLayoutContent } from '../shared/Layout';
import HeaderLogo from 'components/shared/HeaderLogo';
import Timeout from 'components/shared/Timeout';
import AppSelector from 'components/shared/AppSelector';
import ADP from 'components/App_/HR_/ADP';
import get from 'lodash/get';
// import LeftMenu from 'components/App_/HR_/LeftMenu';

// import Adjustments from 'components/App_/ReportsV2_/Views_/Adjustments';

const headerStyle = {
  background: '#2b8ed8',
  color: 'white',
  paddingLeft: '0px',
  paddingRight: '0px',
  paddingTop: '5px',
  height: '42px',
  minHeight: '42px',
};

const HR: React.FC = () => {
  const activeUserName = useSelector((state) => get(state, 'shared.domain.activeUser.full_name'));
  return (
    <Timeout>
      <VLayout>
        <VLayoutHeader style={headerStyle}>
          <HeaderLogo title="PCPA HR" />
          <AppSelector activeUserName={activeUserName} />
        </VLayoutHeader>
        <VLayoutContent>
          <HLayout>
            {/*<HLayoutLeft style={{ width: '200px', minWidth: '200px' }}><LeftMenu/></HLayoutLeft>*/}
            <HLayoutContent>
              <Switch>
                <Redirect exact from="/hr" to="/hr/adp" />
                <Route path="/hr/adp" component={ADP} />
              </Switch>
            </HLayoutContent>
          </HLayout>
        </VLayoutContent>
      </VLayout>
    </Timeout>
  );
};

export default HR;
