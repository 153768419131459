import React from 'react';
import { Select, Avatar } from 'antd';
import { connect } from 'react-redux';

import { safe } from '../../../../../../helpers/reduxSelectors';
import * as actions from '../../../../../../actions/emr';

class AdminLabelSelector extends React.Component {

  isGroup = () => {
    return (safe(this.props,'currentInbox.type') === 3) ? true : false;
  }

  getGroupMembers = () => {
    if (this.props.groups) {
      const group = this.props.groups.find(x=>x.id === this.props.currentInbox.group_id);
      if (group) {
        const g = group.members.map(x=>{
          return {
            id: x.user.id,
            name: x.user.full_name,
            initials: x.user.initials,
            color: x.user.color
          }
        });
        /*
        g.unshift({
          id: null,
          name: 'All',
          initials: 'ALL',
          color: 'black'
        });
        */
        return g;
      }
    }
    return [];
  }

  render() {
    const members = this.getGroupMembers();

    if (!this.isGroup() || members.length === 0) {
      return null;
    }

    return (
      <Select
        style={{width:'200px',paddingLeft:'5px'}}
        value={this.props.adminLabel}
        onChange={this.props.setAdminLabel}
      >
        { members.map(x=>(
          <Select.Option key={x.id} value={x.id}>
            <Avatar size='small' shape='circle' style={{backgroundColor:x.color}}>{x.initials}</Avatar>
            &nbsp;{x.name}
          </Select.Option>)
        )}
      </Select>
    );
  }
}

const mapStateToProps = (state,ownProps) => {
  return {
    currentInbox: safe(state,'emr.inbox.current'),
    groups: safe(state,'shared.groups.list.data',[]),
    adminLabel: safe(state,'emr.inbox.label',safe(state,'shared.domain.activeUser.id'))
  }
}

export default connect(mapStateToProps,actions)(AdminLabelSelector);
