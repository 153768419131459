import React from 'react';
import InputMask from 'react-input-mask';

class PhoneInput extends React.Component {
  static validator(rule,value,callback) {
    (value.replace(/[^\d]/g,'').length === 10) ? callback() : callback('Invalid phone')
  }

  render() {
    const { value, ...restProps } = this.props;
    const val = (value) ? value : '';

    return <InputMask {...restProps} value={val} mask="(999) 999-9999" className='ant-input' maskChar="_" />;
  }
}

export default PhoneInput;
