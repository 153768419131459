import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { Spin, Collapse } from 'antd';
import moment from 'moment';

import * as actions from '../../../../actions/scheduler';
import { safe } from '../../../../helpers/reduxSelectors';
import Time from '../../../shared/Time';

class Stats extends React.Component {
  static propTypes = {
    timeoff_id: PropTypes.number,
    expanded: PropTypes.bool,
    status: PropTypes.bool
  }

  componentDidMount() {
    if (this.props.timeoff_id) {
      this.props.timeoffStats(this.props.timeoff_id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.timeoff_id && prevProps.expanded !== this.props.expanded
      && this.props.expanded) {
        this.props.timeoffStats(this.props.timeoff_id);
    }
  }

  computeSummaryStats = () => {
    if (Array.isArray(this.props.stats)) {
      let total_hrs = 0;
      const hrs = [];
      const notifiable = [];
      const unnotifiable = [];
      this.props.stats.forEach(x=>{
        hrs.push({
          date: x.date,
          duration: x.total_duration/60
        });
        total_hrs += x.total_duration;
        if (x.patients_notified && Array.isArray(x.patients_notified)) {
          x.patients_notified.forEach(y=>{
            if (y.notified) {
              notifiable.push(y);
            } else {
              unnotifiable.push(y);
            }
          });
        }
      });

      return {
        total_hrs: Math.round(100*total_hrs/60)/100,
        hrs: hrs,
        notifiable: notifiable,
        unnotifiable: unnotifiable
      }
    }

    return null;
  }

  renderHrs = (stats) => {
    if (!stats || !stats.hrs) {
      return null;
    }

    return (
      <Collapse.Panel header={'Total Hours: ' + stats.total_hrs}>
        { stats.hrs.map(x=>{
          return <div key={x.date}>{moment(x.date).format('MM/DD/YYYY')}: {numeral(x.duration).format('0.00')} hrs</div>
        })}
      </Collapse.Panel>
    );
  }

  renderNotifiable = (stats) => {
    if (!stats || !stats.notifiable) {
      return null;
    }

    const title = (this.props.status) ? 'Patients To Be Notified:' : 'Patients Notified:';

    return (
      <Collapse.Panel header={title + ' ' + stats.notifiable.length}>
        { stats.notifiable.length === 0 && <div>No appointments</div> }
        { stats.notifiable.map(x=>{
          return (
            <div key={x.id}>
              {x.patient} ({numeral(x.patient_id).format('000000')}),
              {moment(x.session_date).format('MM/DD/YYYY')}, {new Time(x.session_time).toString(false)}
            </div>
          );
        })}
      </Collapse.Panel>
    );
  }

  renderUnnotifiable = (stats) => {
    if (!stats || !stats.unnotifiable) {
      return null;
    }

    const title = (this.props.status) ? 'Patients Unable to Be Notified:' : 'Patients Unable to Notify:';

    return (
      <Collapse.Panel header={<span style={{color:'red'}}>{title + ' ' + stats.unnotifiable.length}</span>}>
        { stats.unnotifiable.length === 0 && <div>No appointments</div> }
        { stats.unnotifiable.map(x=>{
          return (
            <div>
              {x.patient} ({numeral(x.patient_id).format('000000')}),
              {moment(x.session_date).format('MM/DD/YYYY')}, {new Time(x.session_time).toString(false)}
            </div>
          );
        })}
      </Collapse.Panel>
    );
  }

  renderBlank = (stats) => {
    if (!stats) {
      return (
        <div>
          { (this.props.error) ? this.props.error : 'No data available' }
        </div>
      )
    }
  }

  render() {
    const stats = this.computeSummaryStats();
    return (
      <Spin spinning={this.props.is_busy}>
        { this.renderBlank(stats) }
        { stats && <Collapse bordered={true}>
          { this.renderHrs(stats) }
          { this.renderNotifiable(stats) }
          { this.renderUnnotifiable(stats) }
        </Collapse> }
      </Spin>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    is_busy: safe(state,'scheduler.timeoff.stats.processing',false),
    error: safe(state,'scheduler.timeoff.stats.error.data.message'),
    stats: safe(state,'scheduler.timeoff.stats.data')
  }
}

export default connect(mapStateToProps,actions)(Stats);
