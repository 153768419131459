/**
 * IntakesFFS.tsx
 */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import useFetch, { CachePolicies } from 'use-http';
import { Table, Tag } from 'antd';
import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import { credentialToString } from 'helpers/credential';
import moment from 'moment';
import {
  SCHEDULER_INTAKES_UPDATE_REQUEST,
  SCHEDULER_INTAKES_UPDATE_SUCCESS,
  SCHEDULER_INTAKES_UPDATE_FAILURE,
  SCHEDULER_INTAKES_UPDATE_CLEAR,
} from 'actions/scheduler/types';
import OppositeEnds from 'components/shared/OppositeEnds';

const IntakesFFS: React.FC = () => {
  const dispatch = useDispatch();
  const intakesUpdating = useSelector((state) => get(state, 'scheduler.intakes.processing', false));
  const updatedIntake = useSelector((state) => get(state, 'scheduler.intakes.data', false));

  const API1 = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const API2 = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  const [providersAllowIntakes, setProviderAllowIntakes] = useState<Array<Record<string, unknown>>>([]);

  const doGetProductivity = () => {
    const getProductivity = async () => {
      await API1.get('/api/v2/scheduler/productivity-ffs');
    };
    getProductivity();
  };

  useEffect(() => {
    doGetProductivity();
  }, []);

  useEffect(() => {
    // SET PROVIDER 'allow_intakes' VALUES
    if (API1?.data && !providersAllowIntakes?.length) {
      const providers_allow_intakes = API1?.data.map((provider) => {
        const { allow_intakes, provider_id } = provider;
        return { allow_intakes, provider_id };
      });
      setProviderAllowIntakes(providers_allow_intakes);
    }
  }, [API1?.data]);

  useEffect(() => {
    // KEEP TRACK OF 'allow_intakes' CHANGES
    if (!intakesUpdating && updatedIntake?.updated) {
      const { allow_intakes, provider_id } = updatedIntake;
      const found_allow_intakes_idx = providersAllowIntakes.findIndex((provider) => {
        return provider.provider_id === provider_id;
      });
      const newProvidersAllowIntakes = [...providersAllowIntakes];
      newProvidersAllowIntakes[found_allow_intakes_idx] = {
        allow_intakes,
        provider_id,
      };
      setProviderAllowIntakes(newProvidersAllowIntakes);
      dispatch({ type: SCHEDULER_INTAKES_UPDATE_CLEAR });
    }
  }, [intakesUpdating, updatedIntake?.updated]);

  const toggleIntakes = (record, dynamic_value) => {
    const doGet = async () => {
      dispatch({ type: SCHEDULER_INTAKES_UPDATE_REQUEST });
      const url = `/api/v1/scheduler/provider/${record.provider_id}/intakes/${(dynamic_value ? 0 : 1).toString()}`;
      const response = await API2.get(url);
      if (response?.error) {
        dispatch({ type: SCHEDULER_INTAKES_UPDATE_FAILURE, error: response?.error });
      } else {
        dispatch({ type: SCHEDULER_INTAKES_UPDATE_SUCCESS, payload: response });
      }
    };
    doGet();
  };

  const columns: Array<ColumnI> = [
    {
      title: 'Location',
      dataIndex: 'location',
    },
    {
      title: 'Credential',
      dataIndex: 'credential',
      render: (val) => {
        return credentialToString(val);
      },
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
    },
    {
      title: 'Term Start Date',
      dataIndex: 'term_start_date',
      align: 'right',
      render: (value) => {
        return value ? moment(value).format('ddd, M/D/YYYY') : '';
      },
      className: 'no-wrap',
    },
    {
      title: 'Term End Date',
      dataIndex: 'term_end_date',
      align: 'right',
      render: (value) => {
        return value ? moment(value).format('ddd, M/D/YYYY') : '';
      },
      className: 'no-wrap',
    },
    {
      title: 'Expected Hours',
      dataIndex: 'expected_hours_total',
      align: 'right',
    },
    {
      title: 'Booked Hours',
      dataIndex: 'booked_hours_total',
      align: 'right',
    },
    {
      title: '% Full to date',
      align: 'right',
      dataIndex: 'pct_full_to_date',
    },
    {
      title: '% Full this week',
      align: 'right',
      dataIndex: 'pct_full_this_week',
    },
    {
      title: 'Hours Blocked',
      dataIndex: 'blocked_hours_total',
      align: 'right',
    },
    {
      title: '% Hours Blocked',
      align: 'right',
      dataIndex: 'pct_hours_blocked',
    },
    {
      title: 'Intakes',
      dataIndex: 'allow_intakes',
      render: (_value, record) => {
        // ignore static value and utilize dynamic value
        const found_allow_intakes_id = providersAllowIntakes.findIndex((provider) => {
          return provider.provider_id === record.provider_id;
        });
        const dynamic_value =
          found_allow_intakes_id > -1 ? providersAllowIntakes[found_allow_intakes_id].allow_intakes : null;
        if (dynamic_value) {
          return (
            <Tag
              style={{ cursor: 'pointer' }}
              onClick={() => {
                toggleIntakes(record, dynamic_value);
              }}
              color="green"
            >
              On
            </Tag>
          );
        } else {
          return (
            <Tag
              style={{ cursor: 'pointer' }}
              onClick={() => {
                toggleIntakes(record, dynamic_value);
              }}
              color="red"
            >
              Off
            </Tag>
          );
        }
      },
    },
  ];

  return API1?.error ? null : (
    <div style={{ padding: '10px' }}>
      <OppositeEnds
        left={<h3>Intakes-FFS</h3>}
        right={<a href="/api/v2/scheduler/productivity-ffs?csv=1">CSV Download</a>}
      />
      <Table
        columns={columns}
        dataSource={API1.data}
        size="small"
        rowKey="provider_id"
        pagination={false}
        loading={{
          size: 'large',
          tip: 'Loading Productivities',
          spinning: API1?.loading,
        }}
      />
    </div>
  );
};

export default IntakesFFS;
