import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { safe } from '../../../../../helpers/reduxSelectors';
import CareTeam from './CareTeam';
import ApptInfo from './ApptInfo';
import PatientNotifications from './PatientNotifications';

class ApptList extends React.Component {

  static propTypes = {
    patient_id: PropTypes.number,
    careteam: PropTypes.array,
    switchPane: PropTypes.func,
    searchFilter: PropTypes.func,
  }

  selectFutureSession = (item) => {
    this.props.switchPane(this.props.patient_id,item.id,2);
  }

  sessionHistory = () => {
    const data = safe(this.props,'session_history',[]);
    return (
      <div style={{paddingLeft:'10px'}}>
        { (data.length === 0) && 'None' }
        {
          data.map(x=>{
            if (!x.signed) {
              return (
                <div key={x.id}>
                  <ApptInfo data={x} onSelect={this.selectFutureSession}/>
                </div>
              );
            } else {
              return <div key={x.id}>{moment(x.session_date).format('ddd, MM/DD/YYYY')} {safe(x,'user.full_name')}</div>
            }
          })
        }
      </div>
    );
  }

  render() {
    return (
      <div style={{padding:'10px'}}>

        <CareTeam
          careteam={this.props.careteam}
          searchFilter={this.props.searchFilter}
        />

        <PatientNotifications patient_id={this.props.patient_id}>
          <h3>Notifications</h3>
        </PatientNotifications>

        <h3 style={{textDecoration:'underline'}}>Appointments</h3>

        <div>
          {this.sessionHistory()}
        </div>

      </div>
    )
  }

}

export default ApptList;
