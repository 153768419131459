import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Modal,
  Input,
  Radio,
  Select,
  message,
  Divider,
  Checkbox,
  Spin,
  Col,
  Row,
} from 'antd';

import * as actions from '../../../../actions/shared';
import { safe } from '../../../../helpers/reduxSelectors';
import { genders } from '../../../../helpers/genders';
import { usStateAbbrOptions } from '../../../../helpers/states';
import PhoneInput from '../../../shared/PhoneInput';
import DateFormControl from '../../../shared/DateFormControl';

class CreatePatientChart extends Component {

  cleanup = (newChartId) => {
    this.props.form.resetFields();
    this.props.patientCreateClear();
    this.props.closeWindow();
    if (newChartId) {
      this.props.history.push('/emr/patients/'+newChartId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.form.resetFields();
      this.props.getCarrierList();
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        const new_patient_id = safe(this.props,'create.data.new_patient_id',0);
        this.cleanup(new_patient_id);
        message.success('Patient chart created!');
      } else if (this.props.create.error) {
        this.props.patientCreateClear();
        const d = safe(this.props,'create.error.data.detail');
        const m = safe(this.props,'create.error.data.message');
        const error = (d) ? m + ' ' + d : m;
        message.error(error);
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.patientCreate({
          ...values,
          options_create_billing_policy: false,
          options_create_portal_and_registration: true,
        });
      }
    });
  }

  isNotSelfPay = () => {
    const cid = this.props.form.getFieldValue('carrier_id');
    return (cid === 7 || cid === 8) ? false : true;
  }

  zipValidator = (rule,value,callback) => {
    const v = parseInt(value,10);
    (isNaN(v) || v.toString().length !== 5) ? callback('Invalid Zip') : callback();
  }

  guardianInfo = () => {
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: { sm: { span: 8 } },
      wrapperCol: { sm: { span: 16 } },
    };

    const dob = this.props.form.getFieldValue('pt_dob');
    if (dob && moment().diff(dob, 'years') < 18) {
      return (
        <div>
          <Divider>Parent/Guardian Information</Divider>

          <Form.Item {...formItemLayout} label="Parent Name">
            {getFieldDecorator('guardian_name', {
              rules: [{required: true, message: 'Enter name', whitespace: true}],
            })(
              <Input />
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label="Relationship">
            {getFieldDecorator('guardian_relation', {
              rules: [{required: true, message: 'Select one'}],
            })(
              <Select>
                <Option value='Mother'>Mother</Option>
                <Option value='Father'>Father</Option>
                <Option value='Guardian'>Legal Guardian</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label="Email">
            {getFieldDecorator('guardian_email', {
              rules: [
                {type: 'email', message: 'Enter a valid email'},
                {required: true, message: 'Enter email number'}
              ],
            })(
              <Input/>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label="Phone #">
            {getFieldDecorator('guardian_phone', {
              rules: [
                {required: true, message: 'Enter phone number'},
                {validate: PhoneInput.validate}
              ],
              initialValue: ''
            })(
              <PhoneInput/>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label="2nd Parent Name">
            {getFieldDecorator('guardian_name2', {
            })(
              <Input />
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label="Relationship">
            {getFieldDecorator('guardian_relation2', {
            })(
              <Select>
                <Option value='Mother'>Mother</Option>
                <Option value='Father'>Father</Option>
                <Option value='Legal Guardian'>Legal Guardian</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label="Email">
            {getFieldDecorator('guardian_email2', {
              rules: [
                {type: 'email', message: 'Enter a valid email'},
              ],
            })(
              <Input/>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label="Phone #">
            {getFieldDecorator('guardian_phone2', {
            })(
              <PhoneInput/>
            )}
          </Form.Item>

        </div>
      );
    }
  }


  renderSubscriberForm = () => {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { sm: { span: 8 } },
      wrapperCol: { sm: { span: 16 } },
    };

    const patient_r2i = this.props.form.getFieldValue('ins_patient_r2i');
    if (!patient_r2i || patient_r2i === 1) {
      return null;
    }

    return (
      <div>
        <Divider>Insurance Subscriber Information</Divider>

        <Form.Item {...formItemLayout} label='First Name'>
          {getFieldDecorator('ins_subscriber_firstname', {
            rules: [{ required: true, message: ' ', whitespace: true }],
          })(
            <Input/>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Last Name'>
          {getFieldDecorator('ins_subscriber_lastname', {
            rules: [{ required: true, message: ' ', whitespace: true }],
          })(
            <Input/>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label="Gender">
          {getFieldDecorator('ins_subscriber_gender', {
            rules: [{ required: true, message: ' ' }],
          })(
            <Select
              showSearch
              optionFilterProp="children"
              autoComplete='off'
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              { genders.map(a=>(<Select.Option key={a.id} value={a.id}>{a.text}</Select.Option>)) }
            </Select>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Date of Birth'>
          {getFieldDecorator('ins_subscriber_dob', {
            rules: [
              {required: true, message: 'Select a date'},
              {validator:DateFormControl.validator}
            ],
          })(
            <DateFormControl />
          )}
        </Form.Item>

      </div>
    );
  }

  insPolicyForm = () => {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { sm: { span: 8 } },
      wrapperCol: { sm: { span: 16 } },
    };
    const Option = Select.Option;

    const insurance_or_selfpay = this.props.form.getFieldValue('ins_insurance_or_selfpay');
    if (insurance_or_selfpay === undefined || insurance_or_selfpay !== 0) {
      return null;
    }

    return (
      <div>
        <Form.Item {...formItemLayout} label='Ins Plan'>
          {getFieldDecorator('ins_insurance', {
            rules: [{ required: true, message: 'Choose one'}],
          })(
            <Select>
              <Option value='AET'>Aetna</Option>
              <Option value='ANT'>Anthem/Blue Cross</Option>
              <Option value='CIG'>Cigna</Option>
              <Option value='MAG'>Magellan</Option>
              <Option value='MHN'>MHN</Option>
              <Option value='OPT'>United Healthcare/Optum</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Ins ID #'>
          {getFieldDecorator('ins_policy_id_num', {
            rules: [{ required: true, message: ' ' }],
          })(
            <Input/>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Ins Phone #'>
          {getFieldDecorator('ins_carrier_phone', {
            rules: [{
//              validator: PhoneInput.validator
            }]
          })(
            <PhoneInput/>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Ins Policy Holder'>
          {getFieldDecorator('ins_patient_r2i', {
            rules: [{ required: true, message: 'Choose one'}],
            initialValue: 1
          })(
          <Select>
            <Option value={1}>The Patient</Option>
            <Option value={2}>Spouse</Option>
            <Option value={3}>Parent</Option>
            <Option value={4}>Someone Else</Option>
          </Select>
          )}
        </Form.Item>

        { this.renderSubscriberForm() }

      </div>
    );
  }

  roi_pcp = () => {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { sm: { span: 8 } },
      wrapperCol: { sm: { span: 16 } },
    };

    if (this.props.form.getFieldValue('roi_pcp_select')) {
      return (
        <div>
          <Form.Item {...formItemLayout} label="Name">
            {getFieldDecorator('roi_pcp_name', {
              rules: [{required: true, message: 'Enter name', whitespace: true}],
              initialValue: safe(this.props.reginfo,'data.roi_pcp_name')
            })(
              <Input/>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label='Phone #'>
            {getFieldDecorator('roi_pcp_phone', {
              rules: [
                {required: true, message: 'Enter phone #'},
//                {validator: PhoneInput.validate}
              ],
            })(
              <PhoneInput />
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label="Address">
            {getFieldDecorator('roi_pcp_address', {
              rules: [{required: true, message: 'Enter address', whitespace: true}],
            })(
              <Input/>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label="City">
            {getFieldDecorator('roi_pcp_city', {
              rules: [{required: true, message: 'Enter city', whitespace: true}],
            })(
              <Input/>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label='State'>
            {getFieldDecorator('roi_pcp_state', {
              rules: [{required: true, message: 'Enter state', whitespace: true}],
            })(
              <Select
                showSearch
                optionFilterProp="children"
                autoComplete='off'
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                { usStateAbbrOptions }
              </Select>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label='Zip Code'>
            {getFieldDecorator('roi_pcp_zip', {
              rules: [
                {required: true, message: 'Enter zip code', whitespace: true},
                {validator: this.zipValidator }
              ],
            })(
              <Input/>
            )}
          </Form.Item>
        </div>
      );
    }
    return null;
  }

  roi_psy = () => {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { sm: { span: 8 } },
      wrapperCol: { sm: { span: 16 } },
    };

    if (this.props.form.getFieldValue('roi_psy_select')) {
      return (
        <div>
          <Form.Item {...formItemLayout} label="Name">
            {getFieldDecorator('roi_psy_name', {
              rules: [{required: true, message: 'Enter name', whitespace: true}],
            })(
              <Input/>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label='Phone #'>
            {getFieldDecorator('roi_psy_phone', {
              rules: [{required: true, message: 'Enter phone #', whitespace: true}],
            })(
              <PhoneInput />
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label="Address">
            {getFieldDecorator('roi_psy_address', {
              rules: [{required: true, message: 'Enter address', whitespace: true}],
            })(
              <Input/>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label="City">
            {getFieldDecorator('roi_psy_city', {
              rules: [{required: true, message: 'Enter city', whitespace: true}],
            })(
              <Input/>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label='State'>
            {getFieldDecorator('roi_psy_state', {
              rules: [{required: true, message: 'Enter state', whitespace: true}],
            })(
              <Select
                showSearch
                optionFilterProp="children"
                autoComplete='off'
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                { usStateAbbrOptions }
              </Select>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label='Zip Code'>
            {getFieldDecorator('roi_psy_zip', {
              rules: [
                {required: true, message: ' '},
                {validator: this.zipValidator }
              ],
            })(
              <Input />
            )}
          </Form.Item>
        </div>
      );
    }
    return null;
  }

  roi_thera = () => {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { sm: { span: 8 } },
      wrapperCol: { sm: { span: 16 } },
    };

    if (this.props.form.getFieldValue('roi_thera_select')) {
      return (
        <div>
          <Form.Item {...formItemLayout} label="Therapist Name">
            {getFieldDecorator('roi_thera_name', {
              rules: [{required: true, message: 'Enter name', whitespace: true}],
            })(
              <Input/>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label='Phone #'>
            {getFieldDecorator('roi_thera_phone', {
              rules: [{required: true, message: 'Enter phone number', whitespace: true}],
            })(
              <PhoneInput />
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label="Address">
            {getFieldDecorator('roi_thera_address', {
              rules: [{required: true, message: 'Enter address', whitespace: true}],
            })(
              <Input/>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label="City">
            {getFieldDecorator('roi_thera_city', {
              rules: [{required: true, message: 'Enter city', whitespace: true}],
            })(
              <Input/>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label='State'>
            {getFieldDecorator('roi_thera_state', {
              rules: [{required: true, message: 'Enter state', whitespace: true}],
            })(
              <Select
                showSearch
                optionFilterProp="children"
                autoComplete='off'
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                { usStateAbbrOptions }
              </Select>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label='Zip Code'>
            {getFieldDecorator('roi_thera_zip', {
              rules: [
                {required: true, message: ' '},
                {validator: this.zipValidator }
              ],
            })(
              <Input />
            )}
          </Form.Item>
        </div>
      );
    }
    return null;
  }

  roi = () => {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { sm: { span: 8 } },
      wrapperCol: { sm: { span: 16 } },
    };

    return (
      <div>
        <Divider>
          ROI's
        </Divider>

        <Form.Item {...formItemLayout} label='PCP'>
          {getFieldDecorator('roi_pcp_select', {
            valuePropName:'checked',
            initialValue:false
          })(
            <Checkbox/>
          )}
        </Form.Item>

        { this.roi_pcp() }

        <Form.Item {...formItemLayout} label='Psychiatrist'>
          {getFieldDecorator('roi_psy_select', {
            valuePropName:'checked',
            initialValue:false
          })(
            <Checkbox/>
          )}
        </Form.Item>

        { this.roi_psy() }

        <Form.Item {...formItemLayout} label='Therapist'>
          {getFieldDecorator('roi_thera_select', {
            valuePropName:'checked',
            initialValue:false
          })(
            <Checkbox/>
          )}
        </Form.Item>

        { this.roi_thera() }
      </div>
    )
  }

  patientColumn = () => {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { sm: { span: 8 } },
      wrapperCol: { sm: { span: 16 } },
    };

    return (
      <Col span={8}>
        <Divider>
          Patient
        </Divider>

        <Form.Item
          {...formItemLayout}
          autoComplete="given-name"
          label="First Name"
        >
          {getFieldDecorator('pt_firstname', {
            rules: [{ required: true, message: " ", whitespace: true }],
          })(
            <Input />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Last Name"
          autoComplete="family-name"
        >
          {getFieldDecorator('pt_lastname', {
            rules: [{ required: true, message: " ", whitespace: true }],
          })(
            <Input />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Email"
          autoComplete="email"
        >
          {getFieldDecorator('pt_email', {
            rules: [{
              type: 'email', message: ' ',
            }, {
              required: true, message: ' ',
            }],
          })(
            <Input />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label="Phone">
          {getFieldDecorator('pt_phone', {
            rules: [
              { required: true, message: " " },
            ],
          })(
            <PhoneInput/>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label="Date of Birth">
          {getFieldDecorator('pt_dob', {
            rules: [
              { required: true, message: " " },
              {validator:DateFormControl.validator}
            ],
          })(
            <DateFormControl />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label="Gender">
          {getFieldDecorator('pt_gender', {
            rules: [{ required: true, message: " " }],
          })(
            <Select
              showSearch
              optionFilterProp="children"
              autoComplete='off'
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              { genders.map(a=>(<Select.Option key={a.id} value={a.id}>{a.text}</Select.Option>)) }
            </Select>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Address"
          autoComplete="address"
        >
          {getFieldDecorator('pt_address', {
            rules: [{ required: true, message: " ", whitespace: true }],
          })(
            <Input />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="City"
          autoComplete="city"
        >
          {getFieldDecorator('pt_city', {
            rules: [{ required: true, message: " ", whitespace: true }],
          })(
            <Input />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label="State">
          {getFieldDecorator('pt_state', {
            rules: [{ required: true, message: " " }],
            initialValue: 'CA'
          })(
            <Select>
              <Select.Option value='CA'>CA</Select.Option>
              <Select.Option value='WA'>WA</Select.Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Zip"
          autoComplete="zip"
        >
          {getFieldDecorator('pt_zip', {
            rules: [
              { required: true, message: " "},
              { validator: this.zipValidator}
            ],
          })(
            <Input />
          )}
        </Form.Item>

        { this.guardianInfo() }
      </Col>
    );
  }

  ecColumn = () => {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { sm: { span: 8 } },
      wrapperCol: { sm: { span: 16 } },
    };

    return (
      <Col span={8}>
        <Divider>
          Emergency Contact
        </Divider>

        <Form.Item {...formItemLayout} autoComplete="off" label='Name'>
          {getFieldDecorator('ec_name', {
            rules: [{ required: true, message: " ", whitespace: true }],
          })(
            <Input autoComplete="off" />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Relationship'>
          {getFieldDecorator('ec_relation', {
            rules: [{ required: true, message: " ", whitespace: true }],
          })(
            <Input />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Phone"
        >
          {getFieldDecorator('ec_phone', {
            rules: [{ required: true}],
            initialValue: ''
          })(
            <PhoneInput />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Email'>
          {getFieldDecorator('ec_email', {
            rules: [
              { required: true, message: " ", whitespace: true },
              { type: 'email', message: 'Enter a valid email'},
            ],
          })(
            <Input />
          )}
        </Form.Item>

        { this.roi() }
      </Col>
    );
  }

  medicareInfo = (e) => {
    if (e.target.value === 0) {
      return;
    }

    const content = (
      <div style={{color:'red'}}>
        <div>
          <strong>Medicare</strong>: Please note that we do not accept Medicare. However, if you have a
          secondary insurance that we do accept or you would like to pay out of
          pocket, you should be able to see any of our doctors <strong>with the exception
          of Dr. Chemodurow, and Dr. Tully</strong>. Please contact our billing department
          at 415-296-5292 or billing@pcpasf.com if you have any questions.
        </div>

        <div style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <strong>Medi-Cal</strong>: Sorry... but unfortunately, our office is not
          set up to accept Medi-Cal. However, you may still book an appointment
          if you would like to pay out of pocket. Just choose "Self Pay" from
          the menu below.
        </div>
      </div>
    );

    Modal.confirm({
      title: 'Confirm',
      content: content
    });
  }

  insColumn = () => {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { sm: { span: 8 } },
      wrapperCol: { sm: { span: 16 } },
    };

    return (
      <Col span={8}>
        <Divider>
          Patient Insurance Information
        </Divider>

        <Form.Item {...formItemLayout} label='Medicare/MediCal'>
          {getFieldDecorator('ins_has_medicare_or_medical', {
            rules: [{required: true, message: ' '}],
          })(
            <Radio.Group onChange={this.medicareInfo}>
              <Radio value={0}>No</Radio>
              <Radio value={1}>Yes</Radio>
            </Radio.Group>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Type'>
          {getFieldDecorator('ins_insurance_or_selfpay', {
            rules: [{required: true, message: ' '}],
          })(
            <Radio.Group>
              <Radio value={0}>Insurance</Radio>
              <Radio value={1}>Self pay</Radio>
            </Radio.Group>
          )}
        </Form.Item>

        { this.insPolicyForm() }

      </Col>
    );
  }

  acknowledgements = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <Col span={12}>
        <Divider>
          Acknowledgements
        </Divider>
        <h4>Acknowledement of No-Show Fee</h4>
        <Form.Item autoComplete="off">
          {getFieldDecorator('ack_no_show_policy', {
            rules: [{ required: true, message: " " }],
            valuePropName:'checked',
            initialValue:false
          })(
            <Checkbox style={{lineHeight:'20px'}}>
            I understand that if I miss my first appointment, I will be
            responsible for PCPA's no-show fee of $175.
            </Checkbox>
          )}
        </Form.Item>

        <h4>Acknowledgement of Insurance Agreement</h4>
        <Form.Item autoComplete="off">
          {getFieldDecorator('ack_ins_agreement_policy', {
            rules: [{ required: true, message: " " }],
            valuePropName:'checked',
            initialValue:false
          })(
            <Checkbox style={{lineHeight:'20px'}}>
            I understand that PCPA will verify my benefits as a courtesy,
            but that benefit information is not guaranteed until confirmed
            by my insurance company. I understand that I am responsible
            for understanding my benefits, and that if my insurance is
            not accepted, I will be responsible for PCPA's self pay rates.
            </Checkbox>
          )}
        </Form.Item>
      </Col>
    )
  }

  options = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <Col span={12}>

        <Divider>
          Options
        </Divider>

        <Form.Item autoComplete="off">
          {getFieldDecorator('options_send_reg_email', {valuePropName:'checked',initialValue:true})(
            <Checkbox>
              Send Patient Portal Registration Email
            </Checkbox>
          )}
        </Form.Item>

        <Form.Item autoComplete="off">
          {getFieldDecorator('options_force_creation', {valuePropName:'checked',initialValue:false})(
            <Checkbox>
              Force Chart Creation
            </Checkbox>
          )}
        </Form.Item>
      </Col>
    )
  }

  render() {

    const canSubmit = (this.props.form.getFieldValue('ack_no_show_policy') &&
      this.props.form.getFieldValue('ack_ins_agreement_policy')) ? true : false;

    return (
      <Modal
        visible={this.props.visible}
        title='Create a New Patient Chart'
        width={1300}
        maskClosable={false}
        closable={false}
        onCancel={()=>this.cleanup()}
        footer={[
          <Button key='clear' onClick={()=>this.cleanup()}>Cancel</Button>,
          <Button
            type="primary"
            key='submit'
            onClick={this.handleSubmit}
            disabled={this.props.isBusy || !canSubmit}
          >
            Create
          </Button>
        ]}
      >
        <Spin spinning={this.props.isBusy} tip='Creating Patient Chart'>
          <Form onSubmit={this.handleSubmit} autoComplete='off' className='compressed-form'>
            <Row gutter={8}>
              {this.patientColumn()}
              {this.ecColumn()}
              {this.insColumn()}
            </Row>
            <Row gutter={32}>
              {this.acknowledgements() }
              {this.options() }
            </Row>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const mapStateToProps = (state,ownProps) => {
  return {
    isBusy: ( safe(state,'shared.patient.create.processing',false) ) ? true : false,
    create: safe(state,'shared.patient.create'),
    carrierList: safe(state,'shared.carrier.list.data',[])
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(CreatePatientChart)));
