import React from 'react';
import { Select, Avatar } from 'antd';
import { colors } from '../../helpers/colors';

class ColorSelect extends React.Component {
  render() {
    return (
      <Select {...this.props}>
        {colors.map(x=>{
          return (
            <Select.Option key={x.color}>
              <Avatar size='small' shape='circle' style={{backgroundColor:x.color}}>&nbsp;</Avatar>
              &nbsp;{x.name}
            </Select.Option>
          )
        })}
      </Select>
    )
  }
}

export default ColorSelect;
