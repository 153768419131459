import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Button, Select, Modal } from 'antd';
import { connect } from 'react-redux';

import * as actions from '../../../../../../actions/emr';
import { progression } from '../../../../../../helpers/progression';
import { severity } from '../../../../../../helpers/severity';

class ActiveProblemList extends Component {
  static propTypes = {
    patientId: PropTypes.number,
    list: PropTypes.array,
    previousList: PropTypes.array
  }

  constructor(props) {
    super(props);
    this.state = {
      list: props.list
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.list !== this.props.list) {
      this.setState({
        list: this.props.list
      });
    }
  }

  saveRecord = (record_id) => {
    const a = this.state.list.find(x=>x.id === record_id);
    this.props.saveProblemListItem(this.props.patientId,record_id,{
      progression:a.progression,
      severity:a.severity,
      description:a.description
    });
  }

  onDescriptionChange = (record_id,new_value) => {
    const new_list = [...this.state.list];
    const a = new_list.find(x=>x.id === record_id);
    if (a) {
      a.description = new_value;
    }
    this.setState(new_list);
  }

  onDescriptionBlur = (record_id) => {
    this.saveRecord(record_id);
  }

  onProgressionChange = (record_id,new_value) => {
    const new_list = [...this.state.list];
    const a = new_list.find(x=>x.id === record_id);
    if (a) {
      a.progression = new_value;
    }
    this.setState(new_list,()=>{this.saveRecord(record_id)});
  }

  onSeverityChange = (record_id,new_value) => {
    const new_list = [...this.state.list];
    const a = new_list.find(x=>x.id === record_id);
    if (a) {
      a.severity = new_value;
    }
    this.setState(new_list,()=>{this.saveRecord(record_id)});
  }

  getProgression = (x) => {
    const y = progression.find(z => z.value === x);
    return (y) ? y.title : '-';
  }

  getSeverity = (x) => {
    const y = severity.find(z => z.value === x);
    return (y) ? y.title : '-';
  }

  getPreviousRecord = (record_id) => {
    return this.props.previousList.find(x => x.id === record_id);
  }

  deactivateItem = (patient_id,record_id,confirm=false) => {
    const This = this;
    if (confirm) {
      Modal.confirm({
        title: 'Are you sure that you want to delete this problem?',
        content: 'Deleting a shared problem will remove it from the problem list for all clinicians seeing this patient',
        onOk() {
          This.props.deactivateProblemListItem(patient_id,record_id);
        },
        onCancel() {},
      });
    } else {
      this.props.deactivateProblemListItem(patient_id,record_id);
    }
  }

  render() {
    const Option = Select.Option;

    return (
      <Table
        size='small'
        bordered={true}
        pagination={false}
        rowKey='id'
        dataSource={this.state.list}
        className='problemlist'
        columns={[{
          title: 'Problem Description',
          dataIndex: 'description',
          width: '30%',
          render: (value, record) => {
            const pv = this.getPreviousRecord(record.id);
            return (
              <div>
                <Input
                  value={value}
                  onBlur={()=>this.onDescriptionBlur(record.id)}
                  onChange={(event) => this.onDescriptionChange(record.id,event.target.value)}
                />
                {(!pv) ? <span style={{color:'red'}}>New Problem</span> : null }
                {(pv && pv.description !== record.description) ? <span style={{color:'red'}}>{pv.description}</span> : null }
              </div>
            );
          }
        }, {
          title: 'Progression',
          dataIndex: 'progression',
          width: '15%',
          editable: true,
          render: (value,record) => {
            const pv = this.getPreviousRecord(record.id);
            return (
              <div>
                <Select
                  style={{width:'100%'}}
                  value={value}
                  onChange={(value) => this.onProgressionChange(record.id,value)}
                >
                  { progression.map(x=> <Option value={x.value} key={x.value}>{x.title}</Option>) }
                </Select>
                {(pv && pv.progression !== record.progression) ? <span style={{color:'red'}}>{this.getProgression(pv.progression)}</span> : null }
              </div>
            );
          }
        }, {
          title: 'Severity',
          dataIndex: 'severity',
          width: '15%',
          editable: true,
          render: (value,record) => {
            const pv = this.getPreviousRecord(record.id);
            return (
              <div>
                <Select
                  value={value}
                  onChange={(value) => this.onSeverityChange(record.id,value)}
                  style={{width:'100%'}}
                >
                  { severity.map(x=> <Option value={x.value} key={x.value}>{x.title}</Option>) }
                </Select>
                {(pv && pv.severity !== record.severity) ? <span style={{color:'red'}}>{this.getSeverity(pv.severity)}</span> : null }
              </div>
            );
          }
        }, {
          title: 'Diagnosis',
          dataIndex: 'diagnosis_id',
          width: '20%',
          editable: false,
          render: (text,record) => {
            return (record['diagnosis.id']) ? record['diagnosis.icd10cm'] + '-' + record['diagnosis.description']: null;
          }
        }, {
          title: 'Action',
          width: '20%',
          editable: true,
          render: (text, record) => {
            if (record['diagnosis.id']) {
              return <Button onClick={()=>this.deactivateItem(this.props.patientId,record.id,true)}>Remove from Shared List</Button>
            } else {
              return <Button onClick={()=>this.deactivateItem(this.props.patientId,record.id)}>Take off my list</Button>
            }
          }
        }]}
      />
    );
  }
}

export default connect(null,actions)(ActiveProblemList);
