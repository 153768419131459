import React from 'react';
import { Row, Col, Divider } from 'antd';
import numeral from 'numeral';
import moment from 'moment';

class EBR extends React.Component {

  formatDate = (d) => {
    const year = d.substring(0,4);
    const month = d.substring(4,6);
    const day = d.substring(6,8);
    return month + '/' + day + '/' + year;
  }

  showClaims = (data) => {
    let key = 0;
    return data.map(x=>{
      const type = x['error_detail'][0].type;
      const errors = x['error_detail'].map(x=>x.error_message);
      const style = (type === 'R') ?  { color:'red',paddingBottom:'10px' } : {paddingBottom:'10px'};

      key++;
      return (
        <div key={key} style={style}>
          <Row>
            <Col span={6} style={{overflow:'hidden'}}>
              <div>{key}. {x.patient_name}</div>
            </Col>
            <Col span={3}>
              <div>{this.formatDate(x.dos_from)}</div>
            </Col>
            <Col span={3}>
              <div>{x.patient_control_number}</div>
            </Col>
            <Col span={3}>
              <div>{x.payer_claim_num}</div>
            </Col>
            <Col span={3}>
              <div>{x.availity_trace_num}</div>
            </Col>
            <Col span={3}>
              <div>{type}</div>
            </Col>
            <Col span={3}>
              <div>{numeral(x.total_claim_charge).format('$0,0.00')}</div>
            </Col>
          </Row>
          { errors.length > 0 &&
            <Row>
              <Col span={24}>
                <div style={{paddingLeft:'20px',paddingRight:'20px'}}>
                  { errors.map(e=><div>{e}</div>) }
                </div>
              </Col>
            </Row>
          }
        </div>
      );
    });
  }

  showClaimsSection = (data) => {
    if (!data) {
      return null;
    }
    return (
      <div>
        <Row style={{borderBottom:'1px solid lightgray'}}>
          <Col span={6}><strong>Patient Name</strong></Col>
          <Col span={3}><strong>Date of Service</strong></Col>
          <Col span={3}><strong>Patient #</strong></Col>
          <Col span={3}><strong>Payer Claim #</strong></Col>
          <Col span={3}><strong>Availity #</strong></Col>
          <Col span={3}><strong>Status</strong></Col>
          <Col span={3}><strong>Amount</strong></Col>
        </Row>
        {this.showClaims(data)}
      </div>
    );
  }

  showPayer = (data) => {
    return (
      <div style={{paddingTop:'20px'}}>
        <Row>
          <Col span={6}>
            <div><strong>Payer:</strong></div>
            <div><strong>Claims Returned:</strong></div>
            <div><strong>Accepted Claims:</strong></div>
            <div><strong>Rejected Claims:</strong></div>
          </Col>
          <Col span={6}>
            <div>{data.payer_name}</div>
            <div>{data.claim_responses_returned}</div>
            <div>{data.total_accepted_claim_count}</div>
            <div>{data.total_rejected_claim_count}</div>
          </Col>
          <Col span={6}>
            <div><strong>Payer ID:</strong></div>
            <div><strong>Charges:</strong></div>
            <div><strong>Charges:</strong></div>
            <div><strong>Charges:</strong></div>
          </Col>
          <Col span={2}>
            <div style={{textAlign:'right'}}>{data.payer_id}</div>
            <div style={{textAlign:'right'}}>{numeral(data.total_claim_responses_returned_charges).format('$0,0.00')}</div>
            <div style={{textAlign:'right'}}>{numeral(data.total_accepted_claim_charges).format('$0,0.00')}</div>
            <div style={{textAlign:'right'}}>{numeral(data.total_rejected_claim_charges).format('$0,0.00')}</div>
          </Col>
        </Row>
        <br />
        { this.showClaimsSection(data.claims) }
      </div>
    );
  }

  header = (data) => {
    return (
      <div>
        <Row>
          <Col span={6}>
            <div><strong>Date Received: </strong> </div>
            <div><strong>Time Received: </strong> </div>
            <div><strong>Submission PCPA ID: </strong></div>
            <div><strong>Submission Filename: </strong></div>
          </Col>
          <Col span={6}>
            <div>{moment(data.date_received).format('MM/DD/YYYY')}</div>
            <div>{data.time_received}</div>
            <div>{data.file_control_num}</div>
            <div>{data.original_filename}</div>
          </Col>
          <Col span={6}>
            <div><strong>Availity Batch ID: </strong></div>
            <div><strong>Availity Customer ID: </strong></div>
            <div><strong>Availity File ID: </strong></div>
          </Col>
          <Col span={6}>
            <div>{data.availity_batch_id}</div>
            <div>{data.availity_customer_id}</div>
            <div>{data.availity_file_id}</div>
          </Col>
        </Row>
        { data.payers.map(payer=>this.showPayer(payer)) }
      </div>
    );
  }

  render() {
    const { data } = this.props;

    return (
      <div>
        { data.content.map(x=>(
          <div>
            { this.header(x) }
            <Divider />
          </div>
        )) }
      </div>
    )
  }

}

export default EBR;
