import * as types from '../../actions/emr/types';

const defaultState = {
  list: {},
  add: {},
  messages: {},
  current: {},
  star: false,
  label: undefined
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case types.INBOX_SET_CURRENT:
      return {
        ...state,
        current: action.payload
      };

    case types.INBOX_SET_LABEL:
      return {
        ...state,
        label: action.payload
      }
    case types.INBOX_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          processing: true,
        }
      };
    case types.INBOX_LIST_SUCCESS:
      return {
        ...state,
        list: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.INBOX_LIST_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.INBOX_LIST_CLEAR:
      return {
        ...state,
        list: {}
      };

    case types.INBOX_ADDMESSAGE_REQUEST:
      return {
        ...state,
        add: {
          processing: true,
        }
      };
    case types.INBOX_ADDMESSAGE_SUCCESS:
      return {
        ...state,
        add: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.INBOX_ADDMESSAGE_FAILURE:
      return {
        ...state,
        add: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.INBOX_ADDMESSAGE_CLEAR:
      return {
        ...state,
        add: {}
      };

    case types.INBOX_MESSAGES_REQUEST:
      return {
        ...state,
        messages: {
          ...state.messages,
          processing: true,
        }
      };
    case types.INBOX_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.INBOX_MESSAGES_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.INBOX_MESSAGES_CLEAR:
      return {
        ...state,
        messages: {}
      };

    case types.INBOX_FILTER_STAR:
      return {
        ...state,
        star: action.payload
      };

    default:
      return state;
  }
}
