import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Input, InputNumber, Select, Button, Row, Col } from 'antd';
import { connect } from 'react-redux';

import * as actions from '../../../../actions/shared';
import { safe } from '../../../../helpers/reduxSelectors';
import { processEventNotification } from '../../../../helpers/util';

class ProviderSettings extends React.Component {

  componentDidMount() {
    this.props.userLoad(this.props.provider_id);
  }

  componentDidUpdate(prevProps) {
    if ( (prevProps.visible !== this.props.visible && this.props.visible ) ||
       (this.props.visible && prevProps.provider_id !== this.props.provider_id) ) {
      this.props.form.resetFields();
      this.props.userLoad(this.props.provider_id);
    }
    processEventNotification(prevProps,this.props,'saveEvent','Provider Settings Saved');
  }

  saveForm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userSave(this.props.provider_id,values);
      }
    });
  }

  render() {
    const FormItem = Form.Item;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 14 },
        sm: { span: 14 },
      },
    };
    const provider = this.props.providerList.find(x=>x.id === this.props.provider_id);
    const provider_name = safe(provider,'full_name');
    let intake_duration_list = [60];
    if (provider && provider.roles && Array.isArray(provider.roles) &&
      provider.roles.includes('therapist')) {
      intake_duration_list = [60,45];
    }

    return (
      <div style={{padding:'5px'}}>
        <h3>Provider: {provider_name}</h3>
        <Form className='compressed-form' autoComplete='false'>

          <FormItem {...formItemLayout} label="Allow Intakes">
            {getFieldDecorator('allow_intakes', {
              initialValue: safe(this.props,'userData.allow_intakes'),
              valuePropName: 'checked'
            })(
              <Checkbox />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Max Intakes/Week">
            {getFieldDecorator('max_intakes_week', {
              initialValue: safe(this.props,'userData.max_intakes_week',3)
            })(
              <InputNumber min={0} max={40} precision={0}/>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Intake Duration">
            {getFieldDecorator('intake_duration', {
              rules: [{ required: true }],
              initialValue: safe(this.props,'userData.intake_duration',60)
            })(
              <Select>
                { intake_duration_list.map(x=><Option value={x} key={x}>{x} mins</Option>) }
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Youngest Age Seen">
            {getFieldDecorator('min_age', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(this.props,'userData.min_age',18)
            })(
              <InputNumber />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Oldest Age Seen">
            {getFieldDecorator('max_age', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(this.props,'userData.max_age',65)
            })(
              <InputNumber />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Employment Type'>
            {getFieldDecorator('employment_type', {
              initialValue: this.props.userData.employment_type
            })(
              <Select>
                <Option value={1}>Salary</Option>
                <Option value={2}>Hourly</Option>
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Anthem Cred Unfinished">
            {getFieldDecorator('carrier_exclusion', {
              initialValue: safe(this.props,'userData.carrier_exclusion'),
              valuePropName: 'checked'
            })(
              <Checkbox />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Individual Therapy">
            {getFieldDecorator('modality_ind_therapy', {
              initialValue: safe(this.props,'userData.modality_ind_therapy'),
              valuePropName: 'checked'
            })(
              <Checkbox />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Couples Therapy">
            {getFieldDecorator('modality_coup_therapy', {
              initialValue: safe(this.props,'userData.modality_coup_therapy'),
              valuePropName: 'checked'
            })(
              <Checkbox />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Family Therapy">
            {getFieldDecorator('modality_fam_therapy', {
              initialValue: safe(this.props,'userData.modality_fam_therapy'),
              valuePropName: 'checked'
            })(
              <Checkbox />
            )}
          </FormItem>

          <div style={{textAlign:'center',paddingTop:'10px'}}>
            <Button type='primary' onClick={this.saveForm}>Save</Button>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loadEvent: safe(state,'shared.user.load',{}),
    userData: safe(state,'shared.user.load.data',{}),
    saveEvent: safe(state,'shared.user.save',{}),
    providerList: safe(state,'scheduler.provider.list.data',[]),
  }
}

export default connect(mapStateToProps,actions)(Form.create()(ProviderSettings));
