import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Chart from './Display_/Chart';
import Lab from './Display_/Lab';
import Letter from './Display_/Letter';
import Statement from './Display_/Statement';
import RcopiaPatient from './Display_/RcopiaPatient';
import RcopiaMessage from './Display_/RcopiaMessage';
import RcopiaReport from './Display_/RcopiaReport';
import HF from './Billing_/Era_/HF';

import { safe } from '../../helpers/reduxSelectors';

class Display extends Component {

  componentDidMount() {
    if (this.props.isUserLoginInProcess === false) {
      if (this.props.isUserLoginSuccess === false || this.props.isUserLogout === true) {
        this.props.history.push('/login');
      }
    }
  }

  componentDidUpdate() {
    if (this.props.isUserLoginInProcess === false) {
      if (this.props.isUserLoadSuccess === false || this.props.isUserLogout === true) {
        this.props.history.push('/login');
      }
    }
  }

  render() {
    return (
      <Switch>
        <Route path="/display/chart/:patient_id" component={Chart}/>
        <Route path="/display/lab/:note_id" component={Lab}/>
        <Route path="/display/letter/:note_id" component={Letter}/>
        <Route path="/display/statement/:patient_id/:statement_id" component={Statement}/>
        <Route path="/display/rcopia/patient/:patient_id" component={RcopiaPatient} />
        <Route path="/display/rcopia/message" component={RcopiaMessage} />
        <Route path="/display/rcopia/report" component={RcopiaReport} />
        <Route path="/display/era/:id" component={HF} />
      </Switch>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoginInProcess: safe(state,'shared.auth.processing'),
    isUserLoginSuccess: safe(state,'shared.auth.success'),
    isUserLoggedOut: safe(state,'state.auth.isLoggedOut'),
  }
}

export default connect(mapStateToProps,null)(withRouter(Display));
