import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Spin } from 'antd';
import moment from 'moment';

import * as actions from '../../../actions/pm';
import { safe } from '../../../helpers/reduxSelectors';
import OppositeEnds from '../../shared/OppositeEnds';
import FeeScheduleGroupAddEditModal from './FeeScheduleGroupAddEditModal';

class FeeScheduleGroups extends Component {
  state = {
    modal_visible: false,
    modal_data: null
  }

  componentDidMount() {
    this.props.feeScheduleGroupList();
  }

  orderFeeScheduleList = (group_id,fs) => {
    const result = [];
    fs.forEach(x=>{
      const location_name = (x.location) ? x.location.name : 'All Locations';
      result.push({
        key: 'fs.'+x.id,
        title: location_name,
        start_date: x.start_date,
        end_date: x.end_date,
        description: x.description
      });
    });
    return (result.length === 0) ? undefined : result.sort((a,b)=>a.title.localeCompare(b.title));
  }

  prepareGroupList = (fs) => {
    const results = [];
    fs.forEach(x=>{
      results.push({
        key: 'group.' + x.id,
        title: x.name,
        children: this.orderFeeScheduleList(x.id,x.feeschedules)
      })
    });
    return results;
  }

  openModal = (data) => {
    this.setState({
      modal_visible: true,
      modal_data: data
    });
  }

  closeModal = () => {
    this.setState({
      modal_visible: false,
      modal_data: null
    });
  }

  newFeeSchedule = () => {
    this.props.history.push('/settings/feeschedules/0');
  }

  addButtons = () => {
    return (
      <div>
        <Button type='primary' icon={<PlusOutlined />} onClick={()=>this.openModal()}>Add New Group</Button>&nbsp;
        <Button type='primary' icon={<PlusOutlined />} onClick={()=>this.newFeeSchedule()}>Add New Fee Schedule</Button>
      </div>
    );
  }

  render() {
    const fs = this.prepareGroupList(this.props.feeschedulesList);
    const columns = [{
      title: 'Name',
      dataIndex: 'title',
      width: 350,
      render: (val,record) => {
        if (record.key && record.key.startsWith('group')) {
          const items = record.key.split('.');
          return <Button size='small' type='link' onClick={()=>{
            this.openModal({
              id: parseInt(items[1],10),
              name: val
            });
          }}>{val}</Button>
        } else {
          const items = record.key.split('.')
          return <Link to={'/settings/feeschedules/'+items[1]}>{val}</Link>;
        }
      }
    },{
      title: 'Start Date',
      dataIndex: 'start_date',
      width: 150,
      render: (val) => {
        return (val) ? moment(val).format('MM/DD/YYYY') : '';
      }
    },{
      title: 'End Date',
      dataIndex: 'end_date',
      width: 150,
      render: (val) => {
        return (val) ? moment(val).format('MM/DD/YYYY') : '';
      }
    },{
      title: 'Description',
      dataIndex: 'description'
    }];

    return (
      <Spin spinning={this.props.isBusy} size="large">
        <div style={{padding:'10px'}}>
          <OppositeEnds
            left={<h3>Fee Schedules</h3>}
            right={this.addButtons()}
          />
          <Table
            dataSource={fs}
            columns={columns}
            pagination={false}
          />
          <FeeScheduleGroupAddEditModal
            visible={this.state.modal_visible}
            data={this.state.modal_data}
            closeModal={this.closeModal}
          />
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'pm.feeschedule.group.list.processing')
    ) ? true : false,
    feeschedulesList: safe(state,'pm.feeschedule.group.list.data',[]),
  }
}

export default connect(mapStateToProps,actions)(withRouter(FeeScheduleGroups));
