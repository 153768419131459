import React from 'react';

class PatientNotFound extends React.Component {
  render() {
    return (
      <div style={{padding:'15px'}}>
        <h3>Patient Not Found</h3>
      </div>
    );
  }
}

export default PatientNotFound;
