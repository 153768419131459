import React, { Component } from 'react';
import { Input } from 'antd';

export const fieldsMSE = [
  {name: 'general_appearance', label: 'General Appearance'},
  {name: 'gait', label: 'Gait'},
  {name: 'orientation', label: 'Orientation'},
  {name: 'behavior', label: 'Behavior'},
  {name: 'speech_and_language', label: 'Speech & Language'},
  {name: 'mood', label: 'Mood'},
  {name: 'affect',label: 'Affect'},
  {name: 'thought_content', label: 'Thought Content'},
  {name: 'thought_process', label: 'Thought Process'},
  {name: 'associations', label: 'Associations'},
  {name: 'attention_concentration', label: 'Attention/Concentration'},
  {name: 'recent_remote_memory', label: 'Recent & Remote Memory'},
  {name: 'fund_of_knowledge', label: 'Fund of Knowledge'},
  {name: 'insight_judgement', label: 'Insight/Judgement'}
];

const blank_mse = {
  general_appearance:'',
  gait:'',
  orientation:'',
  behavior:'',
  speech_and_language:'',
  mood:'',
  affect:'',
  thought_content:'',
  thought_process:'',
  associations:'',
  attention_concentration:'',
  recent_remote_memory:'',
  fund_of_knowledge:'',
  insight_judgement:''
};

export class MSE extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    for (let i = 0; i < fieldsMSE.length; i++) {
      this.state[fieldsMSE[i].name] = fieldsMSE[i].value;
    }
  }

  componentWillReceiveProps(nextProps) {
    if ('value' in nextProps) {
      let value = nextProps.value;
      if (typeof(value) !== 'object') {
        value = blank_mse;
      }
      this.setState(value);
    }
  }

  handleChange = (event) => {
    const target = event.target
    const value = target.value;
    const name = target.name;

    if (!('value' in this.props)) {
      this.setState({
        [name]: value
      });
    }

    const onChange = this.props.onChange;
    if (onChange) {
      onChange({
        ...this.state,
        [name]: value
      })
    }
  }

  render() {
    return fieldsMSE.map(x => (
      <div key={x.name} style={{paddingBottom:'3px'}}>
        <Input
          style={{borderColor:'#d9d9d9',color:'black',width:'100%'}}
          name={x.name}
          type="text"
          value={this.state[x.name]}
          addonBefore={x.label}
          onChange={this.handleChange}
          disabled={this.props.disabled}
          className='has-success'
        />
      </div>
    ));
  }
}
