/**
 * DisplayReport.tsx
 *
 * A form component button for submitting the report
 * if parameters have changed for reports that use it.
 */
import { Button, Form } from 'antd';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';

const DisplayReport: React.FC = () => {
  const runReportAPI = useContextSelector(InlineContext, (c) => c.state.runReportAPI);

  return (
    <Form.Item>
      <Button type="primary" htmlType="submit" disabled={runReportAPI?.loading}>
        Display Report
      </Button>
    </Form.Item>
  );
};

export default DisplayReport;
