import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table, Spin } from 'antd';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';

class ReportsSessionCounts extends React.Component {
  componentDidMount() {
    this.props.reportsSessionCounts();
  }

  render() {
    const columns = [{
      title: 'Week',
      dataIndex: 'yw'
    },{
      title: 'Start Date',
      dataIndex: 'start',
      render: (val) => moment(val).format('MM/DD/YYYY')
    },{
      title: 'End Date',
      dataIndex: 'end',
      render: (val) => moment(val).format('MM/DD/YYYY')
    },{
      title: 'All Visits',
      dataIndex: 'all_visits',
    },{
      title: 'All New Visits',
      dataIndex: 'all_new_visits',
    },{
      title: 'All Telemedicine Visit',
      dataIndex: 'all_telemedicine'
    },{
      title: 'All Telemedicine Intakes',
      dataIndex: 'telemedicine_intakes',
    }];

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
            <span style={{float:'right'}}><a href="/api/v1/reports/sessioncounts?csv=1">CSV Download</a></span>
          </div>

          <h3>Session Counts</h3>
          <Table
            className='reports-sessionlist-table'
            columns={columns}
            dataSource={this.props.list}
            bordered={true}
            size='small'
            rowKey='yw'
            scroll={{ x:true }}
            pagination={false}
          />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'reports.sessioncounts.processing',false),
    list: safe(state,'reports.sessioncounts.data',[]),
  }
}

export default connect(mapStateToProps,actions)(ReportsSessionCounts);
