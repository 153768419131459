/**
 * BeginReportingToggle.tsx
 * A simple toggle to switch from either now or a specific date to start the report.
 */
import { useContextSelector } from 'use-context-selector';
import { useState } from 'react';
import { DatePicker, Form, Radio } from 'antd';
import formItemRules from 'components/App_/ReportsV2_/formItemRules';
import moment from 'moment';
import QueueContext from 'components/App_/ReportsV2_/QueueContext';
import { WEEKLY, MONTHLY } from 'reports';
import styles from './BeginReporting.style';

const BeginReporting: React.FC = () => {
  const form = useContextSelector(QueueContext, (c) => c.state.form);
  const isRecurring = useContextSelector(QueueContext, (c) => c.state.isRecurring);
  const type = useContextSelector(QueueContext, (c) => c.state.type);
  const dayOfMonth = useContextSelector(QueueContext, (c) => c.state.dayOfMonth);
  const subType = useContextSelector(QueueContext, (c) => c.state.subType);
  const [showBeginReporting, setShowBeginReporting] = useState<boolean>();

  const onShowBeginReportingChange = (e) => {
    form?.setFieldsValue({ showBeginReporting: e.target.value });
    setShowBeginReporting(e.target.value);
  };

  const onScheduleStartDateChange = (date: null | moment.Moment) => {
    const scheduleStartDate = date?.seconds(0).milliseconds(0);
    form?.setFieldsValue({ scheduleStartDate });
  };

  const disabledScheduledStartDate = (current) => {
    const datesOlderThanToday = current < moment().startOf('day');
    let datesThatAreWeekDay = true;
    let datesThatAreMonthDay = true;
    if (type === WEEKLY && subType) {
      datesThatAreWeekDay = moment(current).isoWeekday() === subType;
    }
    if (type === MONTHLY && subType !== undefined) {
      if (subType === 0) {
        const lastDayOfMonth = moment(current).endOf('month').date();
        // LAST DAY OF MONTH
        datesThatAreMonthDay = moment(current).date() === lastDayOfMonth;
      } else if (subType === 1) {
        // FIRST DAY OF MONTH
        datesThatAreMonthDay = moment(current).date() === subType;
      } else if (subType === 2) {
        // SPECIFIC DAY OF MONTH
        datesThatAreMonthDay = moment(current).date() === dayOfMonth;
      }
    }
    return datesOlderThanToday || !datesThatAreWeekDay || !datesThatAreMonthDay;
  };

  const scheduleStartDateParams = {
    placeholder: '',
    disabledDate: disabledScheduledStartDate,
    onChange: onScheduleStartDateChange,
    showTime: { format: 'HH:mm' },
    showNow: false,
    format: 'YYYY-MM-DD HH:mm',
  };

  return (
    <div style={styles.root} className="mt4">
      <div style={styles.toggle}>
        <h5>Begin Reporting</h5>
        <Form.Item name="showBeginReporting" className="mb0" rules={formItemRules.showBeginReporting}>
          <Radio.Group onChange={onShowBeginReportingChange} buttonStyle="solid" size="small">
            <Radio.Button value={false}>Now</Radio.Button>
            {!isRecurring && <Radio.Button value={true}>Future</Radio.Button>}
          </Radio.Group>
        </Form.Item>
      </div>
      {showBeginReporting && (
        <div className="ml8">
          <h5>Specific Date &amp; Time</h5>
          <Form.Item name="scheduleStartDate" className="mb0 mt1" rules={formItemRules.scheduleStartDate}>
            <DatePicker {...scheduleStartDateParams} size="small" />
          </Form.Item>
        </div>
      )}
    </div>
  );
};

export default BeginReporting;
