import axios from 'axios';
import * as types from './types';
import qs from 'qs';

const sharedLogout = (dispatch,err) => {
  if (err && err.response && err.response.status === 401) {
    dispatch ({type:'USER_LOGIN_CLEAR'});
  }
}

export const sendPatientRegistration = (patientId,values) => async dispatch => {
  dispatch ({type:types.PATIENT_REG_REQUEST});
  try {
    const resp = await axios.post('/api/v1/ptreg/'+patientId,values);
    dispatch ({type:types.PATIENT_REG_SUCCESS, payload: resp.data });
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type:types.PATIENT_REG_FAILURE, error: err.response});
  }
}

export const sendPatientRegistrationClear = () => dispatch => {
  dispatch ({type:types.PATIENT_REG_CLEAR});
}

export const loadAdminStaff = () => async dispatch => {
  dispatch ({type:types.USER_ADMINLIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/adminlist');
    dispatch ({type:types.USER_ADMINLIST_SUCCESS, payload: resp.data });
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type:types.USER_ADMINLIST_FAILURE, error: err.response});
  }
}

// groups Calls

export const getGroupsList = () => async dispatch => {
  dispatch ({type: types.GROUPS_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/groups');
    dispatch ({type: types.GROUPS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.GROUPS_LIST_FAILURE, error: err.response});
  }
}

export const groupListClear = () => async dispatch => {
  dispatch ({type: types.GROUPS_LIST_CLEAR});
}

export const groupDeactivate = (id) => async dispatch => {
  dispatch ({type: types.GROUPS_DEACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/groups/'+id+'/deactivate');
    dispatch ({type: types.GROUPS_DEACTIVATE_SUCCESS});
    dispatch ({type: types.GROUPS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.GROUPS_DEACTIVATE_FAILURE, error: err.response});
  }
};

export const groupDeactivateClear = () => async dispatch => {
  dispatch ({type: types.GROUPS_DEACTIVATE_CLEAR});
}

export const groupReactivate = (id) => async dispatch => {
  dispatch ({type: types.GROUPS_REACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/groups/'+id+'/reactivate');
    dispatch ({type: types.GROUPS_REACTIVATE_SUCCESS});
    dispatch ({type: types.GROUPS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.GROUPS_REACTIVATE_FAILURE, error: err.response});
  }
};

export const groupReactivateClear = () => async dispatch => {
  dispatch ({type: types.GROUPS_REACTIVATE_CLEAR});
}

export const groupLoad = (id) => async dispatch => {
  dispatch ({type: types.GROUPS_LOAD_REQUEST});
  try {
    const res = await axios.get('/api/v1/groups/'+id);
    dispatch ({type: types.GROUPS_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.GROUPS_LOAD_FAILURE, error: err.response});
  }
}

export const groupLoadClear = () => async dispatch => {
  dispatch ({type: types.GROUPS_LOAD_CLEAR});
}

export const groupSave = (id,data) => async dispatch => {
  dispatch ({type: types.GROUPS_SAVE_REQUEST});
  try {
    await axios.put('/api/v1/groups/'+id,data);
    dispatch ({type: types.GROUPS_SAVE_SUCCESS});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.GROUPS_SAVE_FAILURE, error: err.response});
  }
}

export const groupSaveClear = () => async dispatch => {
  dispatch ({type: types.GROUPS_SAVE_CLEAR});
}

export const groupCreate = (data) => async dispatch => {
  dispatch ({type: types.GROUPS_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/groups',data);
    dispatch ({type: types.GROUPS_CREATE_SUCCESS, payload: resp.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.GROUPS_CREATE_FAILURE, error: err.response});
  }
}

export const groupCreateClear = () => async dispatch => {
  dispatch ({type: types.GROUPS_CREATE_CLEAR});
}

export const loadCareTeam = (patientId) => async dispatch => {
  dispatch ({type: types.CARETEAM_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/careteam/'+patientId);
    dispatch ({type: types.CARETEAM_LIST_SUCCESS, payload: resp.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.CARETEAM_LIST_FAILURE, error: err.response});
  }
}

export const addToCareTeam = (patientId,type,id) => async dispatch => {
  dispatch ({type: types.CARETEAM_ADD_REQUEST});
  try {
    const resp = await axios.post('/api/v1/careteam/'+patientId+'/'+type+'/'+id);
    dispatch ({type: types.CARETEAM_ADD_SUCCESS, payload: resp.data});
    dispatch ({type: types.CARETEAM_LIST_SUCCESS, payload: (resp.data) ? resp.data.ct : []});
    dispatch ({type: types.PATIENT_LOAD_SUCCESS, payload: (resp.data) ? resp.data.pt : {}});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.CARETEAM_ADD_FAILURE, error: err.response});
  }
}

export const removeFromCareTeam = (patientId,type,id) => async dispatch => {
  dispatch ({type: types.CARETEAM_REMOVE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/careteam/'+patientId+'/'+type+'/'+id);
    dispatch ({type: types.CARETEAM_REMOVE_SUCCESS, payload: resp.data});
    dispatch ({type: types.CARETEAM_LIST_SUCCESS, payload: (resp.data) ? resp.data.ct : []});
    dispatch ({type: types.PATIENT_LOAD_SUCCESS, payload: (resp.data) ? resp.data.pt : {}});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.CARETEAM_REMOVE_FAILURE, error: err.response});
  }
}

export const archiveChart = (patient_id) => async dispatch => {
  dispatch ({type: types.PATIENT_ARCHIVE_REQUEST});
  try {
    await axios.get('/api/v1/charts/'+patient_id+'/archive');
    dispatch ({type: types.PATIENT_ARCHIVE_SUCCESS});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.PATIENT_ARCHIVE_FAILURE, error: err.response});
  }
}


export const loadRecentPatientList = () => async dispatch => {
  dispatch ({type: types.RECENT_PATIENTLIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/patients_recentlist');
    dispatch ({type: types.RECENT_PATIENTLIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.RECENT_PATIENTLIST_FAILURE, error: err.response});
  }
}

export const chartArchiveClear = () => async dispatch => {
  dispatch ({type: types.PATIENT_ARCHIVE_CLEAR});
}

export const unarchiveChart = (patient_id) => async dispatch => {
  dispatch ({type: types.PATIENT_UNARCHIVE_REQUEST});
  try {
    await axios.get('/api/v1/charts/'+patient_id+'/unarchive');
    dispatch ({type: types.PATIENT_UNARCHIVE_SUCCESS});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.PATIENT_UNARCHIVE_FAILURE, error: err.response});
  }
}

export const chartUnarchiveClear = () => async dispatch => {
  dispatch ({type: types.PATIENT_UNARCHIVE_CLEAR});
}

export const placeChartOnHold = (patient_id) => async dispatch => {
  dispatch ({type: types.PATIENT_PLACEHOLD_REQUEST});
  try {
    await axios.get('/api/v1/charts/'+patient_id+'/placehold');
    dispatch ({type: types.PATIENT_PLACEHOLD_SUCCESS});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.PATIENT_PLACEHOLD_FAILURE, error: err.response});
  }
}

export const chartPlaceHoldClear = () => async dispatch => {
  dispatch ({type: types.PATIENT_PLACEHOLD_CLEAR});
}

export const removeChartHold = (patient_id) => async dispatch => {
  dispatch ({type: types.PATIENT_REMOVEHOLD_REQUEST});
  try {
    await axios.get('/api/v1/charts/'+patient_id+'/removehold');
    dispatch ({type: types.PATIENT_REMOVEHOLD_SUCCESS});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.PATIENT_REMOVEHOLD_FAILURE, error: err.response});
  }
}

export const chartRemoveHoldClear = () => async dispatch => {
  dispatch ({type: types.PATIENT_REMOVEHOLD_CLEAR});
}

export const setBillingState = (data) => dispatch => {
  dispatch ({
    type: types.BILLINGSTATE_SET,
    payload: data
  });
}

export const loadPatient = (id) => async dispatch => {
  dispatch ({type: types.PATIENT_LOAD_REQUEST, patientId: id});
  try {
    const res = await axios.get('/api/v1/patient/' + id);
    dispatch ({type: types.PATIENT_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.PATIENT_LOAD_FAILURE, error: err.response});
  }
}

export const savePatient = (id,data) => async dispatch => {
  dispatch ({type: types.PATIENT_SAVE_REQUEST});
  try {
    await axios.put('/api/v1/patient/'+id,data);
    dispatch ({type: types.PATIENT_SAVE_SUCCESS});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.PATIENT_SAVE_FAILURE, error: err.response});
  }
}

export const savePatientClear = () => dispatch => {
  dispatch ({type: types.PATIENT_SAVE_CLEAR});
}

export const getUserList = () => async dispatch => {
  dispatch ({type: types.USER_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/user');
    dispatch ({type: types.USER_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.USER_LIST_FAILURE, error: err.response});
  }
};

export const userListClear = () => dispatch => {
  dispatch ({type: types.USER_LIST_CLEAR});
}

export const userDeactivate = (user_id) => async dispatch => {
  dispatch ({type: types.USER_DEACTIVATE_REQUEST});
  try {
    const res = await axios.delete('/api/v1/user/'+user_id);
    dispatch ({type: types.USER_DEACTIVATE_SUCCESS});
    dispatch ({type: types.USER_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.USER_DEACTIVATE_FAILURE, error: err.response});
  }
};

export const userDeactivateClear = () => dispatch => {
  dispatch ({type: types.USER_DEACTIVATE_CLEAR});
}

export const userReactivate = (user_id) => async dispatch => {
  dispatch ({type: types.USER_REACTIVATE_REQUEST});
  try {
    const res = await axios.patch('/api/v1/user/'+user_id);
    dispatch ({type: types.USER_REACTIVATE_SUCCESS});
    dispatch ({type: types.USER_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.USER_REACTIVATE_FAILURE, error: err.response});
  }
};

export const userReactivateClear = () => dispatch => {
  dispatch ({type: types.USER_REACTIVATE_CLEAR});
}

export const userLoad = (user_id) => async dispatch => {
  dispatch ({type: types.USER_LOAD_REQUEST});
  try {
    const res = await axios.get('/api/v1/user/'+user_id);
    dispatch ({type: types.USER_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.USER_LOAD_FAILURE, error: err.response});
  }
};

export const userLoadClear = () => dispatch => {
  dispatch ({type: types.USER_LOAD_CLEAR});
}

export const userSave = (id,data) => async dispatch => {
  dispatch ({type: types.USER_SAVE_REQUEST});
  try {
    if (id === 0) {
      await axios.post('/api/v1/user',data);
    } else {
      await axios.put('/api/v1/user/'+id,data);
    }
    dispatch ({type: types.USER_SAVE_SUCCESS});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.USER_SAVE_FAILURE, error: err.response});
  }
};

export const userSaveClear = () => dispatch => {
  dispatch ({type: types.USER_SAVE_CLEAR});
}

export const loadAlerts = (patient_id) => async dispatch => {
  dispatch ({type: types.ALERTS_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/alerts/'+patient_id);
    dispatch ({type: types.ALERTS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.ALERTS_LIST_FAILURE, error: err.response});
  }
}

export const clearLoadAlerts = () => dispatch => {
  dispatch ({type: types.ALERTS_LIST_CLEAR});
}

export const saveAlert = (patient_id,data) => async dispatch => {
  dispatch ({type: types.ALERTS_SAVE_REQUEST});
  try {
    const res = await axios.post('/api/v1/alerts/'+patient_id,data);
    dispatch ({type: types.ALERTS_SAVE_SUCCESS});
    dispatch ({type: types.ALERTS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.ALERTS_SAVE_FAILURE, error: err.response});
  }
}

export const clearSaveAlerts = () => dispatch => {
  dispatch ({type: types.ALERTS_SAVE_CLEAR});
}

export const deleteAlert = (id) => async dispatch => {
  dispatch ({type: types.ALERTS_DELETE_REQUEST});
  try {
    const res = await axios.delete('/api/v1/alerts/'+id);
    dispatch ({type: types.ALERTS_DELETE_SUCCESS});
    dispatch ({type: types.ALERTS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.ALERTS_DELETE_FAILURE, error: err.response});
  }
}

export const clearDeleteAlerts = () => dispatch => {
  dispatch ({type: types.ALERTS_DELETE_CLEAR});
}

export const loginUser = () => async dispatch => {
  logoutUserClear();
  dispatch ({type: types.USER_LOGIN_REQUEST});
  try {
    const res = await axios.get('/api/v1/current_user');
    dispatch ({type: types.USER_LOGIN_SUCCESS, payload: res.data});
    dispatch ({type: types.SET_ACTIVE_USER, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.USER_LOGIN_FAILURE, error: err.response});
  }
};

export const loginUserClear = () => dispatch => {
  dispatch ({type: types.USER_LOGIN_CLEAR});
}

export const logoutUser = () => async dispatch => {
  loginUserClear();
  dispatch ({type: types.USER_LOGOUT_REQUEST});
  try {
    await axios.get('/api/v1/logout');
    dispatch ({type: types.USER_LOGOUT_SUCCESS});
  } catch (err) {
    dispatch ({type: types.USER_LOGOUT_FAILURE, error: err.response});
  }
}

export const logoutUserClear = () => dispatch => {
  dispatch ({type: types.USER_LOGOUT_CLEAR});
}

export const patientCreate = (data) => async dispatch => {
  dispatch ({type: types.PATIENT_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/patient',data);
    dispatch ({type: types.PATIENT_CREATE_SUCCESS, payload: resp.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.PATIENT_CREATE_FAILURE, error: err.response});
  }
}

export const patientCreateClear = () => dispatch => {
  dispatch ({type: types.PATIENT_CREATE_CLEAR});
}

export const getAdvancedPatientList = (params) => async dispatch => {
  dispatch ({type: types.PATIENT_LISTADVANCED_REQUEST});
  try {
    const q = qs.stringify({
      type: 2,
      ...params
    },{ addQueryPrefix: true });
    const res = await axios.get('/api/v1/patient' + q);
    dispatch ({type: types.PATIENT_LISTADVANCED_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.PATIENT_LISTADVANCED_FAILURE, error: err.response});
  }
}

export const getAdvancedPatientListClear = () => dispatch => {
  dispatch ({type: types.PATIENT_LISTADVANCED_CLEAR});
}

export const getPatientList = (query,include_archived=true,include_onhold=true) => async dispatch => {
  dispatch ({type: types.PATIENT_LIST_REQUEST});
  try {
    const q = qs.stringify({
      type: 1,
      query: query,
      archive: (include_archived) ? 1 : 0,
      onhold: (include_onhold) ? 1 : 0,
    },{ addQueryPrefix: true });
    const res = await axios.get('/api/v1/patient' + q);
    dispatch ({type: types.PATIENT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.PATIENT_LIST_FAILURE, error: err.response});
  }
}

export const setPatientList = (data) => dispatch => {
  dispatch ({type: types.PATIENT_LIST_SUCCESS, payload: data});
}

export const patientListClear = () => dispatch => {
  dispatch ({type: types.PATIENT_LIST_CLEAR});
}

// Location Calls

export const getLocationsList = () => async dispatch => {
  dispatch ({type: types.LOCATION_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/location');
    dispatch ({type: types.LOCATION_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.LOCATION_LIST_FAILURE, error: err.response});
  }
}

export const locationListClear = () => async dispatch => {
  dispatch ({type: types.LOCATION_LIST_CLEAR});
}

export const locationDeactivate = (id) => async dispatch => {
  dispatch ({type: types.LOCATION_DEACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/location/'+id+'/deactivate');
    dispatch ({type: types.LOCATION_DEACTIVATE_SUCCESS});
    dispatch ({type: types.LOCATION_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.LOCATION_DEACTIVATE_FAILURE, error: err.response});
  }
};

export const locationDeactivateClear = () => async dispatch => {
  dispatch ({type: types.LOCATION_DEACTIVATE_CLEAR});
}

export const locationReactivate = (id) => async dispatch => {
  dispatch ({type: types.LOCATION_REACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/location/'+id+'/reactivate');
    dispatch ({type: types.LOCATION_REACTIVATE_SUCCESS});
    dispatch ({type: types.LOCATION_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.LOCATION_REACTIVATE_FAILURE, error: err.response});
  }
};

export const locationReactivateClear = () => async dispatch => {
  dispatch ({type: types.LOCATION_REACTIVATE_CLEAR});
}

export const locationLoad = (id) => async dispatch => {
  dispatch ({type: types.LOCATION_LOAD_REQUEST});
  try {
    const res = await axios.get('/api/v1/location/'+id);
    dispatch ({type: types.LOCATION_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.LOCATION_LOAD_FAILURE, error: err.response});
  }
}

export const locationLoadClear = () => async dispatch => {
  dispatch ({type: types.LOCATION_LOAD_CLEAR});
}

export const locationSave = (id,data) => async dispatch => {
  dispatch ({type: types.LOCATION_SAVE_REQUEST});
  try {
    await axios.put('/api/v1/location/'+id,data);
    dispatch ({type: types.LOCATION_SAVE_SUCCESS});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.LOCATION_SAVE_FAILURE, error: err.response});
  }
}

export const locationSaveClear = () => async dispatch => {
  dispatch ({type: types.LOCATION_SAVE_CLEAR});
}

export const locationCreate = (data) => async dispatch => {
  dispatch ({type: types.LOCATION_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/location',data);
    dispatch ({type: types.LOCATION_CREATE_SUCCESS, payload: resp.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.LOCATION_CREATE_FAILURE, error: err.response});
  }
}

export const locationCreateClear = () => async dispatch => {
  dispatch ({type: types.LOCATION_CREATE_CLEAR});
}

// Billing Locations

export const getBillingLocationsList = () => async dispatch => {
  dispatch ({type: types.BILLINGLOCATION_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/billinglocation');
    dispatch ({type: types.BILLINGLOCATION_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.BILLINGLOCATION_LIST_FAILURE, error: err.response});
  }
}

export const billingLocationListClear = () => async dispatch => {
  dispatch ({type: types.BILLINGLOCATION_LIST_CLEAR});
}

export const billingLocationDeactivate = (id) => async dispatch => {
  dispatch ({type: types.BILLINGLOCATION_DEACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/billinglocation/'+id+'/deactivate');
    dispatch ({type: types.BILLINGLOCATION_DEACTIVATE_SUCCESS});
    dispatch ({type: types.BILLINGLOCATION_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.BILLINGLOCATION_DEACTIVATE_FAILURE, error: err.response});
  }
};

export const billingLocationDeactivateClear = () => async dispatch => {
  dispatch ({type: types.BILLINGLOCATION_DEACTIVATE_CLEAR});
}

export const billingLocationReactivate = (id) => async dispatch => {
  dispatch ({type: types.BILLINGLOCATION_REACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/billinglocation/'+id+'/reactivate');
    dispatch ({type: types.BILLINGLOCATION_REACTIVATE_SUCCESS});
    dispatch ({type: types.BILLINGLOCATION_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.BILLINGLOCATION_REACTIVATE_FAILURE, error: err.response});
  }
};

export const billingLocationReactivateClear = () => async dispatch => {
  dispatch ({type: types.BILLINGLOCATION_REACTIVATE_CLEAR});
}

export const billingLocationLoad = (id) => async dispatch => {
  dispatch ({type: types.BILLINGLOCATION_LOAD_REQUEST});
  try {
    const res = await axios.get('/api/v1/billinglocation/'+id);
    dispatch ({type: types.BILLINGLOCATION_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.BILLINGLOCATION_LOAD_FAILURE, error: err.response});
  }
}

export const billingLocationLoadClear = () => async dispatch => {
  dispatch ({type: types.BILLINGLOCATION_LOAD_CLEAR});
}

export const billingLocationSave = (id,data) => async dispatch => {
  dispatch ({type: types.BILLINGLOCATION_SAVE_REQUEST});
  try {
    await axios.put('/api/v1/billinglocation/'+id,data);
    dispatch ({type: types.BILLINGLOCATION_SAVE_SUCCESS});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.BILLINGLOCATION_SAVE_FAILURE, error: err.response});
  }
}

export const billingLocationSaveClear = () => async dispatch => {
  dispatch ({type: types.BILLINGLOCATION_SAVE_CLEAR});
}

export const billingLocationCreate = (data) => async dispatch => {
  dispatch ({type: types.BILLINGLOCATION_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/billinglocation',data);
    dispatch ({type: types.BILLINGLOCATION_CREATE_SUCCESS, payload: resp.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.BILLINGLOCATION_CREATE_FAILURE, error: err.response});
  }
}

export const billingLocationCreateClear = () => async dispatch => {
  dispatch ({type: types.BILLINGLOCATION_CREATE_CLEAR});
}

// Location Groups

export const getLocationGroups = () => async dispatch => {
  dispatch ({type: types.LOCATIONGROUP_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/locationgroup');
    dispatch ({type: types.LOCATIONGROUP_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.LOCATIONGROUP_LIST_FAILURE, error: err.response});
  }
}

export const locationGroupRemove = (id) => async dispatch => {
  dispatch ({type: types.LOCATIONGROUP_REMOVE_REQUEST});
  try {
    const res = await axios.delete('/api/v1/locationgroup/'+id);
    dispatch ({type: types.LOCATIONGROUP_REMOVE_SUCCESS});
    dispatch ({type: types.LOCATIONGROUP_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.LOCATIONGROUP_REMOVE_FAILURE, error: err.response});
  }
};

export const locationGroupLoad = (id) => async dispatch => {
  dispatch ({type: types.LOCATIONGROUP_LOAD_REQUEST});
  try {
    const res = await axios.get('/api/v1/locationgroup/'+id);
    dispatch ({type: types.LOCATIONGROUP_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.LOCATIONGROUP_LOAD_FAILURE, error: err.response});
  }
}

export const locationGroupLoadClear = () => dispatch => {
  dispatch ({type: types.LOCATIONGROUP_LOAD_CLEAR});
}

export const locationGroupSave = (id,data) => async dispatch => {
  dispatch ({type: types.LOCATIONGROUP_SAVE_REQUEST});
  try {
    await axios.put('/api/v1/locationgroup/'+id,data);
    dispatch ({type: types.LOCATIONGROUP_SAVE_SUCCESS});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.LOCATIONGROUP_SAVE_FAILURE, error: err.response});
  }
}

export const locationGroupSaveClear = () => dispatch => {
  dispatch ({type: types.LOCATIONGROUP_SAVE_CLEAR});
}

export const locationGroupCreate = (data) => async dispatch => {
  dispatch ({type: types.LOCATIONGROUP_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/locationgroup',data);
    dispatch ({type: types.LOCATIONGROUP_CREATE_SUCCESS, payload: resp.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.LOCATIONGROUP_CREATE_FAILURE, error: err.response});
  }
}

export const locationGroupCreateClear = () => dispatch => {
  dispatch ({type: types.LOCATIONGROUP_CREATE_CLEAR});
}


// Carrier calls

export const getCarrierList = () => async dispatch => {
  dispatch ({type: types.CARRIER_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/carrier');
    dispatch ({type: types.CARRIER_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.CARRIER_LIST_FAILURE, error: err.response});
  }
}

export const carrierListClear = () => async dispatch => {
  dispatch ({type: types.CARRIER_LIST_CLEAR});
}

export const getEffectiveDates = (user_id) => async dispatch => {
  dispatch ({type: types.EFFECTIVEDATES_LOAD_REQUEST});
  try {
    const res = await axios.get('/api/v1/effectivedates/'+user_id);
    dispatch ({type: types.EFFECTIVEDATES_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.EFFECTIVEDATES_LOAD_FAILURE, error: err.response});
  }
}

export const setEffectiveDate = (user_id,data) => async dispatch => {
  dispatch ({type: types.EFFECTIVEDATES_SAVE_REQUEST});
  try {
    const res = await axios.post('/api/v1/effectivedates/'+user_id,data);
    dispatch ({type: types.EFFECTIVEDATES_LOAD_SUCCESS, payload: res.data});
    dispatch ({type: types.EFFECTIVEDATES_SAVE_SUCCESS});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.EFFECTIVEDATES_SAVE_FAILURE, error: err.response});
  }
}

export const deleteEffectiveDate = (user_id,id) => async dispatch => {
  dispatch ({type: types.EFFECTIVEDATES_DELETE_REQUEST});
  try {
    const res = await axios.delete('/api/v1/effectivedates/'+user_id+'/'+id);
    dispatch ({type: types.EFFECTIVEDATES_LOAD_SUCCESS, payload: res.data});
    dispatch ({type: types.EFFECTIVEDATES_DELETE_SUCCESS});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.EFFECTIVEDATES_DELETE_FAILURE, error: err.response});
  }
}

export const carrierDeactivate = (id) => async dispatch => {
  dispatch ({type: types.CARRIER_DEACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/carrier/'+id+'/deactivate');
    dispatch ({type: types.CARRIER_DEACTIVATE_SUCCESS});
    dispatch ({type: types.CARRIER_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.CARRIER_DEACTIVATE_FAILURE, error: err.response});
  }
};

export const carrierDeactivateClear = () => async dispatch => {
  dispatch ({type: types.CARRIER_DEACTIVATE_CLEAR});
}

export const carrierReactivate = (id) => async dispatch => {
  dispatch ({type: types.CARRIER_REACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/carrier/'+id+'/reactivate');
    dispatch ({type: types.CARRIER_REACTIVATE_SUCCESS});
    dispatch ({type: types.CARRIER_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.CARRIER_REACTIVATE_FAILURE, error: err.response});
  }
};

export const carrierReactivateClear = () => async dispatch => {
  dispatch ({type: types.CARRIER_REACTIVATE_CLEAR});
}

export const carrierLoad = (id) => async dispatch => {
  dispatch ({type: types.CARRIER_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/carrier/'+id);
    dispatch ({type: types.CARRIER_LOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.CARRIER_LOAD_FAILURE, error: err.response});
  }
}

export const carrierLoadClear = () => async dispatch => {
  dispatch ({type: types.CARRIER_LOAD_CLEAR});
}

export const carrierSave = (id,data) => async dispatch => {
  dispatch ({type: types.CARRIER_SAVE_REQUEST});
  try {
    await axios.put('/api/v1/carrier/'+id,data);
    dispatch ({type: types.CARRIER_SAVE_SUCCESS});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.CARRIER_SAVE_FAILURE, error: err.response});
  }
}

export const carrierSaveClear = () => async dispatch => {
  dispatch ({type: types.CARRIER_SAVE_CLEAR});
}

export const carrierCreate = (data) => async dispatch => {
  dispatch ({type: types.CARRIER_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/carrier',data);
    dispatch ({type: types.CARRIER_CREATE_SUCCESS, payload: resp.data});
  } catch (err) {
    sharedLogout(dispatch,err);
    dispatch ({type: types.CARRIER_CREATE_FAILURE, error: err.response});
  }
}

export const carrierCreateClear = () => async dispatch => {
  dispatch ({type: types.CARRIER_CREATE_CLEAR});
}
