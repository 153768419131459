import React, { Component } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';

import { safe } from '../../../../../helpers/reduxSelectors';
import { adjustments } from '../../../../../helpers/adjustments';

class RatesTable extends Component {
  render() {
    const columns=[{
      title: 'Date',
      dataIndex: 'createdAt',
      width: 175,
      render: (text,record) => {
        return moment(text).format('MM/DD/YYYY, HH:mm:ss');
      }
    },{
      title: 'User',
      dataIndex: 'user',
      width: 50,
      render: (text,record) => {
        return safe(record,'creator.initials');
      }
    },{
      title: 'Type',
      dataIndex: 'type',
      width: 100,
      render: (value,record) => {
        let adj = '';
        if (record.adj_type) {
          const q = adjustments.find(x=>x.id===record.adj_type);
          if (q) {
            adj = safe(q,'text','').toUpperCase();
          }
        }
        switch (value) {
          case 1: return <span>SET RATE</span>;
          case 2: return <span>ADJ {adj}</span>;
          default: return <span>-</span>
        }
      }
    },{
      title: 'Pt',
      dataIndex: 'pt_amount',
      align: 'right',
      width: 100,
      render: (text,record) => {
        return (text === 0.0) ? '' : numeral(text).format('$0,0.00');
      }
    },{
      title: 'Ins',
      dataIndex: 'ins_amount',
      align: 'right',
      width: 100,
      render: (text,record) => {
        return (text === 0.0) ? '' : numeral(text).format('$0,0.00');
      }
    },{
      title: 'Description',
      dataIndex: 'description'
    }];

    return (
      <div>
        <Table
          dataSource={this.props.data}
          columns={columns}
          bordered={false}
          showHeader={true}
          pagination={false}
          size='small'
          rowKey='id'
          style={{backgroundColor:'white',marginTop:'5px'}}
        />
      </div>
    )
  }
}

export default RatesTable;
