import React from 'react';
import PropTypes from 'prop-types';

class HeaderLogo extends React.Component {
  static propTypes = {
    title: PropTypes.string
  };

  componentDidMount(){
    document.title = this.props.title;
  }
// <img src="/images/pcpalogo-small.png" alt="pcpa logo" height="24px"/>&nbsp;&nbsp;
  render() {
    return (
      <span style={{fontWeight:'bold', fontSize: '14px', lineHeight: '24px', paddingLeft: '10px',whiteSpace:'nowrap'}}>
        {this.props.title}
      </span>
    );
  }
}

export default HeaderLogo;
