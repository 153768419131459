import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import { safe } from '../../../../helpers/reduxSelectors';

class Superbill extends React.Component {
  static propTypes = {
    data: PropTypes.object,
  };

  renderServices = (services) => {
    return (
      <table width="100%" className="statement-table">
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Date of Service</th>
            <th style={{ textAlign: 'left' }}>Procedure Codes</th>
            <th style={{ textAlign: 'left' }}>Diagnosis</th>
            <th style={{ textAlign: 'left' }}>Provider</th>
            <th style={{ textAlign: 'left' }}>Provider NPI</th>
            <th style={{ textAlign: 'left' }}>Location</th>
            <th style={{ textAlign: 'right' }}>Charges</th>
            <th style={{ textAlign: 'right' }}>Paid</th>
          </tr>
        </thead>
        <tbody>
          {services.map((x) => (
            <tr key={x.id}>
              <td>{moment(x.dos).format('MM/DD/YYYY')}</td>
              <td>{safe(x, 'cpt', []).join(',')}</td>
              <td>{safe(x, 'diagnosis', []).join(',')}</td>
              <td>{x.provider}</td>
              <td>{x.provider_npi}</td>
              <td>{x.location}</td>
              <td align="right">{numeral(x.charges).format('$0,0.00')}</td>
              <td align="right">{numeral(x.paid).format('$0,0.00')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderAddress = (x) => {
    return (
      <div>
        <div>
          Address: {x.address1}, {x.address2}
        </div>
        <div>
          {x.city}, {x.state}, {x.zip}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div style={{ padding: '10px' }}>
        <div>
          <div align="center">
            <img src="/images/pcpalogo-med.png" alt="logo" width="75" />
          </div>
          <hr />
          <div align="center">
            <div style={{ fontSize: 'larger' }}>{safe(this.props, 'data.statement.org.name')}</div>
            <div>490 Post St, Suite 1043, San Francisco, CA, 94102</div>
            <div>phone: 415-296-5290 * fax: 415-296-5299</div>
            <div>reception@pcpasf.com</div>
            <div>pcpasf.com</div>
            <div>EIN: {safe(this.props, 'data.statement.org.ein')}</div>
            <div>NPI: {safe(this.props, 'data.statement.org.npi')}</div>
          </div>
        </div>
        <br />
        <h2>{safe(this.props, 'data.statement.title')}</h2>
        <div>Statement #: {numeral(this.props.data.id).format('000000')}</div>
        <div>Statement Date: {safe(this.props, 'data.statement.statement_date')}</div>
        <div>Period Start: {safe(this.props, 'data.statement.statement_daterange.start')}</div>
        <div>Period End: {safe(this.props, 'data.statement.statement_daterange.end')}</div>

        <br />

        <div>Patient: {safe(this.props, 'data.statement.patient.name')}</div>
        <div>DOB: {moment(safe(this.props, 'data.statement.patient.dob')).format('MM/DD/YYYY')}</div>
        {this.renderAddress(this.props.data.statement.patient)}

        <br />

        {this.renderServices(safe(this.props, 'data.statement.services', []))}

        <div style={{ textAlign: 'right', paddingTop: '10px', fontWeight: 'bold' }}>
          Total Patient Charges: {numeral(safe(this.props, 'data.statement.total_charges', 0)).format('$0,0.00')}
        </div>
        <div style={{ textAlign: 'right', paddingTop: '10px', fontWeight: 'bold' }}>
          Total Patient Payments: {numeral(safe(this.props, 'data.statement.total_payments', 0)).format('$0,0.00')}
        </div>
      </div>
    );
  }
}

export default Superbill;
