export const colors = [
  {name: 'Red', color:     '#e6194B'},
  {name: 'Green', color:   '#3cb44b'},
  {name: 'Yellow', color:  '#ffe119'},
  {name: 'Blue', color:    '#4363d8'},
  {name: 'Orange', color:  '#f58231'},
  {name: 'Purple', color:  '#911eb4'},
  {name: 'Cyan', color:    '#42d4f4'},
  {name: 'Magenta', color: '#f032e6'},
  {name: 'Lime', color:    '#bfef45'},
  {name: 'Pink', color:    '#fabebe'},
  {name: 'Teal', color:    '#469990'},
  {name: 'Lavender', color:'#e6beff'},
  {name: 'Brown', color:   '#9A6324'},
  {name: 'Beige', color:   '#fffac8'},
  {name: 'Maroon', color:  '#800000'},
  {name: 'Mint', color:    '#aaffc3'},
  {name: 'Olive', color:   '#808000'},
  {name: 'Apricot', color: '#ffd8b1'},
  {name: 'Navy', color:    '#000075'},
  {name: 'Grey', color:    '#a9a9a9'},
  {name: 'Black', color:   '#000000'}
];
