export const EMR_SET = 'EMR_SET';

export const DIRECTOR_LIST_REQUEST = 'DIRECTOR_LIST_REQUEST';
export const DIRECTOR_LIST_SUCCESS = 'DIRECTOR_LIST_SUCCESS';
export const DIRECTOR_LIST_FAILURE = 'DIRECTOR_LIST_FAILURE';
export const DIRECTOR_LIST_CLEAR = 'DIRECTOR_LIST_CLEAR';
export const DIRECTOR_ADD_REQUEST = 'DIRECTOR_ADD_REQUEST';
export const DIRECTOR_ADD_SUCCESS = 'DIRECTOR_ADD_SUCCESS';
export const DIRECTOR_ADD_FAILURE = 'DIRECTOR_ADD_FAILURE';
export const DIRECTOR_ADD_CLEAR = 'DIRECTOR_ADD_CLEAR';
export const DIRECTOR_REMOVE_REQUEST = 'DIRECTOR_REMOVE_REQUEST';
export const DIRECTOR_REMOVE_SUCCESS = 'DIRECTOR_REMOVE_SUCCESS';
export const DIRECTOR_REMOVE_FAILURE = 'DIRECTOR_REMOVE_FAILURE';
export const DIRECTOR_REMOVE_CLEAR = 'DIRECTOR_REMOVE_CLEAR';

export const REGISTRATION_TOGGLE_REQUEST = 'REGISTRATION_TOGGLE_REQUEST';
export const REGISTRATION_TOGGLE_SUCCESS = 'REGISTRATION_TOGGLE_SUCCESS';
export const REGISTRATION_TOGGLE_FAILURE = 'REGISTRATION_TOGGLE_FAILURE';
export const REGISTRATION_TOGGLE_CLEAR = 'REGISTRATION_TOGGLE_CLEAR';

export const NOTIFICATIONS_LIST_REQUEST = 'NOTIFICATIONS_LIST_REQUEST';
export const NOTIFICATIONS_LIST_SUCCESS = 'NOTIFICATIONS_LIST_SUCCESS';
export const NOTIFICATIONS_LIST_FAILURE = 'NOTIFICATIONS_LIST_FAILURE';
export const NOTIFICATIONS_LIST_CLEAR = 'NOTIFICATIONS_LIST_CLEAR';

export const TIMEOFF_LIST_REQUEST = 'TIMEOFF_LIST_REQUEST';
export const TIMEOFF_LIST_SUCCESS = 'TIMEOFF_LIST_SUCCESS';
export const TIMEOFF_LIST_FAILURE = 'TIMEOFF_LIST_FAILURE';
export const TIMEOFF_LIST_CLEAR = 'TIMEOFF_LIST_CLEAR';
export const TIMEOFF_CREATE_REQUEST = 'TIMEOFF_CREATE_REQUEST';
export const TIMEOFF_CREATE_SUCCESS = 'TIMEOFF_CREATE_SUCCESS';
export const TIMEOFF_CREATE_FAILURE = 'TIMEOFF_CREATE_FAILURE';
export const TIMEOFF_CREATE_CLEAR = 'TIMEOFF_CREATE_CLEAR';
export const TIMEOFF_UPDATE_REQUEST = 'TIMEOFF_UPDATE_REQUEST';
export const TIMEOFF_UPDATE_SUCCESS = 'TIMEOFF_UPDATE_SUCCESS';
export const TIMEOFF_UPDATE_FAILURE = 'TIMEOFF_UPDATE_FAILURE';
export const TIMEOFF_UPDATE_CLEAR = 'TIMEOFF_UPDATE_CLEAR';
export const TIMEOFF_DELETE_REQUEST = 'TIMEOFF_DELETE_REQUEST';
export const TIMEOFF_DELETE_SUCCESS = 'TIMEOFF_DELETE_SUCCESS';
export const TIMEOFF_DELETE_FAILURE = 'TIMEOFF_DELETE_FAILURE';
export const TIMEOFF_DELETE_CLEAR = 'TIMEOFF_DELETE_CLEAR';

export const CALENDAR_SEARCHPARAMS_REQUEST = 'CALENDAR_SEARCHPARAMS_REQUEST';
export const CALENDAR_SEARCHPARAMS_SUCCESS = 'CALENDAR_SEARCHPARAMS_SUCCESS';
export const CALENDAR_SEARCHPARAMS_FAILURE = 'CALENDAR_SEARCHPARAMS_FAILURE';
export const CALENDAR_SEARCHPARAMS_CLEAR = 'CALENDAR_SEARCHPARAMS_CLEAR';
export const CALENDAR_LIST_REQUEST = 'CALENDAR_LIST_REQUEST';
export const CALENDAR_LIST_SUCCESS = 'CALENDAR_LIST_SUCCESS';
export const CALENDAR_LIST_FAILURE = 'CALENDAR_LIST_FAILURE';
export const CALENDAR_LIST_CLEAR = 'CALENDAR_LIST_CLEAR';
export const CALENDAR_SEARCH_REQUEST = 'CALENDAR_SEARCH_REQUEST';
export const CALENDAR_SEARCH_SUCCESS = 'CALENDAR_SEARCH_SUCCESS';
export const CALENDAR_SEARCH_FAILURE = 'CALENDAR_SEARCH_FAILURE';
export const CALENDAR_SEARCH_CLEAR = 'CALENDAR_SEARCH_CLEAR';
export const CALENDAR_HOLIDAYS_REQUEST = 'CALENDAR_HOLIDAYS_REQUEST';
export const CALENDAR_HOLIDAYS_SUCCESS = 'CALENDAR_HOLIDAYS_SUCCESS';
export const CALENDAR_HOLIDAYS_FAILURE = 'CALENDAR_HOLIDAYS_FAILURE';
export const CALENDAR_HOLIDAYS_CLEAR = 'CALENDAR_HOLIDAYS_CLEAR';

export const SESSION_LOAD_REQUEST = 'SESSION_LOAD_REQUEST';
export const SESSION_LOAD_SUCCESS = 'SESSION_LOAD_SUCCESS';
export const SESSION_LOAD_FAILURE = 'SESSION_LOAD_FAILURE';
export const SESSION_LOAD_CLEAR = 'SESSION_LOAD_CLEAR';
export const SESSION_HISTORY_REQUEST = 'SESSION_HISTORY_REQUEST';
export const SESSION_HISTORY_SUCCESS = 'SESSION_HISTORY_SUCCESS';
export const SESSION_HISTORY_FAILURE = 'SESSION_HISTORY_FAILURE';
export const SESSION_HISTORY_CLEAR = 'SESSION_HISTORY_CLEAR';
export const SESSION_CREATENOTE_REQUEST = 'SESSION_CREATENOTE_REQUEST';
export const SESSION_CREATENOTE_SUCCESS = 'SESSION_CREATENOTE_SUCCESS';
export const SESSION_CREATENOTE_FAILURE = 'SESSION_CREATENOTE_FAILURE';
export const SESSION_CREATENOTE_CLEAR = 'SESSION_CREATENOTE_CLEAR';

export const SESSION_CREATE_REQUEST = 'SESSION_CREATE_REQUEST';
export const SESSION_CREATE_SUCCESS = 'SESSION_CREATE_SUCCESS';
export const SESSION_CREATE_FAILURE = 'SESSION_CREATE_FAILURE';
export const SESSION_CREATE_CLEAR = 'SESSION_CREATE_CLEAR';
export const SESSION_RESERVE_REQUEST = 'SESSION_RESERVE_REQUEST';
export const SESSION_RESERVE_SUCCESS = 'SESSION_RESERVE_SUCCESS';
export const SESSION_RESERVE_FAILURE = 'SESSION_RESERVE_FAILURE';
export const SESSION_RESERVE_CLEAR = 'SESSION_RESERVE_CLEAR';
export const SESSION_UPDATE_REQUEST = 'SESSION_UPDATE_REQUEST';
export const SESSION_UPDATE_SUCCESS = 'SESSION_UPDATE_SUCCESS';
export const SESSION_UPDATE_FAILURE = 'SESSION_UPDATE_FAILURE';
export const SESSION_UPDATE_CLEAR = 'SESSION_UPDATE_CLEAR';
export const SESSION_REMOVE_REQUEST = 'SESSION_REMOVE_REQUEST';
export const SESSION_REMOVE_SUCCESS = 'SESSION_REMOVE_SUCCESS';
export const SESSION_REMOVE_FAILURE = 'SESSION_REMOVE_FAILURE';
export const SESSION_REMOVE_CLEAR = 'SESSION_REMOVE_CLEAR';
export const SESSION_LATECANCEL_REQUEST = 'SESSION_LATECANCEL_REQUEST';
export const SESSION_LATECANCEL_SUCCESS = 'SESSION_LATECANCEL_SUCCESS';
export const SESSION_LATECANCEL_FAILURE = 'SESSION_LATECANCEL_FAILURE';
export const SESSION_LATECANCEL_CLEAR = 'SESSION_LATECANCEL_CLEAR';

export const APPT_CREATE_REQUEST = 'APPT_CREATE_REQUEST';
export const APPT_CREATE_SUCCESS = 'APPT_CREATE_SUCCESS';
export const APPT_CREATE_FAILURE = 'APPT_CREATE_FAILURE';
export const APPT_CREATE_CLEAR = 'APPT_CREATE_CLEAR';
export const APPT_RESERVE_REQUEST = 'APPT_RESERVE_REQUEST';
export const APPT_RESERVE_SUCCESS = 'APPT_RESERVE_SUCCESS';
export const APPT_RESERVE_FAILURE = 'APPT_RESERVE_FAILURE';
export const APPT_RESERVE_CLEAR = 'APPT_RESERVE_CLEAR';
export const APPT_UPDATE_REQUEST = 'APPT_UPDATE_REQUEST';
export const APPT_UPDATE_SUCCESS = 'APPT_UPDATE_SUCCESS';
export const APPT_UPDATE_FAILURE = 'APPT_UPDATE_FAILURE';
export const APPT_UPDATE_CLEAR = 'APPT_UPDATE_CLEAR';
export const APPT_REMOVE_REQUEST = 'APPT_REMOVE_REQUEST';
export const APPT_REMOVE_SUCCESS = 'APPT_REMOVE_SUCCESS';
export const APPT_REMOVE_FAILURE = 'APPT_REMOVE_FAILURE';
export const APPT_REMOVE_CLEAR = 'APPT_REMOVE_CLEAR';
export const APPT_LATECANCEL_REQUEST = 'APPT_LATECANCEL_REQUEST';
export const APPT_LATECANCEL_SUCCESS = 'APPT_LATECANCEL_SUCCESS';
export const APPT_LATECANCEL_FAILURE = 'APPT_LATECANCEL_FAILURE';
export const APPT_LATECANCEL_CLEAR = 'APPT_LATECANCEL_CLEAR';

export const PROVIDER_PRODUCTIVITY_REQUEST = 'PROVIDER_PRODUCTIVITY_REQUEST';
export const PROVIDER_PRODUCTIVITY_SUCCESS = 'PROVIDER_PRODUCTIVITY_SUCCESS';
export const PROVIDER_PRODUCTIVITY_FAILURE = 'PROVIDER_PRODUCTIVITY_FAILURE';
export const PROVIDER_PRODUCTIVITY_CLEAR = 'PROVIDER_PRODUCTIVITY_CLEAR';

export const PROVIDER_OPENTIMES_REQUEST = 'PROVIDER_OPENTIMES_REQUEST';
export const PROVIDER_OPENTIMES_SUCCESS = 'PROVIDER_OPENTIMES_SUCCESS';
export const PROVIDER_OPENTIMES_FAILURE = 'PROVIDER_OPENTIMES_FAILURE';
export const PROVIDER_OPENTIMES_CLEAR = 'PROVIDER_OPENTIMES_CLEAR';

export const PROVIDER_HISTORY_REQUEST = 'PROVIDER_HISTORY_REQUEST';
export const PROVIDER_HISTORY_SUCCESS = 'PROVIDER_HISTORY_SUCCESS';
export const PROVIDER_HISTORY_FAILURE = 'PROVIDER_HISTORY_FAILURE';
export const PROVIDER_HISTORY_CLEAR = 'PROVIDER_HISTORY_CLEAR';

export const PROVIDER_LOCATIONS_REQUEST = 'PROVIDER_LOCATIONS_REQUEST';
export const PROVIDER_LOCATIONS_SUCCESS = 'PROVIDER_LOCATIONS_SUCCESS';
export const PROVIDER_LOCATIONS_FAILURE = 'PROVIDER_LOCATIONS_FAILURE';
export const PROVIDER_LOCATIONS_CLEAR = 'PROVIDER_LOCATIONS_CLEAR';

export const MESSAGETHREADS_LIST_REQUEST = 'MESSAGETHREADS_LIST_REQUEST';
export const MESSAGETHREADS_LIST_SUCCESS = 'MESSAGETHREADS_LIST_SUCCESS';
export const MESSAGETHREADS_LIST_FAILURE = 'MESSAGETHREADS_LIST_FAILURE';
export const MESSAGETHREADS_LIST_CLEAR = 'MESSAGETHREADS_LIST_CLEAR';
export const MESSAGETHREADS_MESSAGES_REQUEST = 'MESSAGETHREADS_MESSAGES_REQUEST';
export const MESSAGETHREADS_MESSAGES_SUCCESS = 'MESSAGETHREADS_MESSAGES_SUCCESS';
export const MESSAGETHREADS_MESSAGES_FAILURE = 'MESSAGETHREADS_MESSAGES_FAILURE';
export const MESSAGETHREADS_MESSAGES_CLEAR = 'MESSAGETHREADS_MESSAGES_CLEAR';

export const MESSAGESTATUS_UNREAD_REQUEST = 'MESSAGESTATUS_UNREAD_REQUEST';
export const MESSAGESTATUS_UNREAD_SUCCESS = 'MESSAGESTATUS_UNREAD_SUCCESS';
export const MESSAGESTATUS_UNREAD_FAILURE = 'MESSAGESTATUS_UNREAD_FAILURE';
export const MESSAGESTATUS_UNREAD_CLEAR = 'MESSAGESTATUS_UNREAD_CLEAR';
export const MESSAGESTATUS_HIDE_REQUEST = 'MESSAGESTATUS_HIDE_REQUEST';
export const MESSAGESTATUS_HIDE_SUCCESS = 'MESSAGESTATUS_HIDE_SUCCESS';
export const MESSAGESTATUS_HIDE_FAILURE = 'MESSAGESTATUS_HIDE_FAILURE';
export const MESSAGESTATUS_HIDE_CLEAR = 'MESSAGESTATUS_HIDE_CLEAR';

export const PROVIDERS_LIST_REQUEST = 'PROVIDERS_LIST_REQUEST';
export const PROVIDERS_LIST_SUCCESS = 'PROVIDERS_LIST_SUCCESS';
export const PROVIDERS_LIST_FAILURE = 'PROVIDERS_LIST_FAILURE';
export const PROVIDERS_LIST_CLEAR = 'PROVIDERS_LIST_CLEAR';

export const DOCUMENTS_LIST_REQUEST = 'DOCUMENTS_LIST_REQUEST';
export const DOCUMENTS_LIST_SUCCESS = 'DOCUMENTS_LIST_SUCCESS';
export const DOCUMENTS_LIST_FAILURE = 'DOCUMENTS_LIST_FAILURE';
export const DOCUMENTS_LIST_CLEAR = 'DOCUMENTS_LIST_CLEAR';
export const DOCUMENTS_DELETE_REQUEST = 'DOCUMENTS_DELETE_REQUEST';
export const DOCUMENTS_DELETE_SUCCESS = 'DOCUMENTS_DELETE_SUCCESS';
export const DOCUMENTS_DELETE_FAILURE = 'DOCUMENTS_DELETE_FAILURE';
export const DOCUMENTS_DELETE_CLEAR = 'DOCUMENTS_DELETE_CLEAR';
export const DOCUMENTS_TRANSFER_REQUEST = 'DOCUMENTS_TRANSFER_REQUEST';
export const DOCUMENTS_TRANSFER_SUCCESS = 'DOCUMENTS_TRANSFER_SUCCESS';
export const DOCUMENTS_TRANSFER_FAILURE = 'DOCUMENTS_TRANSFER_FAILURE';
export const DOCUMENTS_TRANSFER_CLEAR = 'DOCUMENTS_TRANSFER_CLEAR';
export const DOCUMENTS_TRANSFER2_REQUEST = 'DOCUMENTS_TRANSFER2_REQUEST';
export const DOCUMENTS_TRANSFER2_SUCCESS = 'DOCUMENTS_TRANSFER2_SUCCESS';
export const DOCUMENTS_TRANSFER2_FAILURE = 'DOCUMENTS_TRANSFER2_FAILURE';
export const DOCUMENTS_TRANSFER2_CLEAR = 'DOCUMENTS_TRANSFER2_CLEAR';

export const INBOX_FILTER_STAR = 'INBOX_FILTER_STAR';
export const INBOX_SET_LABEL = 'INBOX_SET_LABEL';

export const THREAD_CREATE_REQUEST = 'THREAD_CREATE_REQUEST';
export const THREAD_CREATE_SUCCESS = 'THREAD_CREATE_SUCCESS';
export const THREAD_CREATE_FAILURE = 'THREAD_CREATE_FAILURE';
export const THREAD_CREATE_CLEAR = 'THREAD_CREATE_CLEAR';
export const THREAD_STAR_REQUEST = 'THREAD_STAR_REQUEST';
export const THREAD_STAR_SUCCESS = 'THREAD_STAR_SUCCESS';
export const THREAD_STAR_FAILURE = 'THREAD_STAR_FAILURE';
export const THREAD_STAR_CLEAR = 'THREAD_STAR_CLEAR';

export const PTCONTACTS_LIST_REQUEST = 'PTCONTACTS_LIST_REQUEST';
export const PTCONTACTS_LIST_SUCCESS = 'PTCONTACTS_LIST_SUCCESS';
export const PTCONTACTS_LIST_FAILURE = 'PTCONTACTS_LIST_FAILURE';
export const PTCONTACTS_LIST_CLEAR = 'PTCONTACTS_LIST_CLEAR';

export const CASELOAD_LIST_REQUEST = 'CASELOAD_LIST_REQUEST';
export const CASELOAD_LIST_SUCCESS = 'CASELOAD_LIST_SUCCESS';
export const CASELOAD_LIST_FAILURE = 'CASELOAD_LIST_FAILURE';
export const CASELOAD_LIST_CLEAR = 'CASELOAD_LIST_CLEAR';

export const COVERAGE_LOAD_REQUEST = 'COVERAGE_LOAD_REQUEST';
export const COVERAGE_LOAD_SUCCESS = 'COVERAGE_LOAD_SUCCESS';
export const COVERAGE_LOAD_FAILURE = 'COVERAGE_LOAD_FAILURE';
export const COVERAGE_LOAD_CLEAR = 'COVERAGE_LOAD_CLEAR';
export const COVERAGE_SAVE_REQUEST = 'COVERAGE_SAVE_REQUEST';
export const COVERAGE_SAVE_SUCCESS = 'COVERAGE_SAVE_SUCCESS';
export const COVERAGE_SAVE_FAILURE = 'COVERAGE_SAVE_FAILURE';
export const COVERAGE_SAVE_CLEAR = 'COVERAGE_SAVE_CLEAR';
export const COVERAGE_LIST_REQUEST = 'COVERAGE_LIST_REQUEST';
export const COVERAGE_LIST_SUCCESS = 'COVERAGE_LIST_SUCCESS';
export const COVERAGE_LIST_FAILURE = 'COVERAGE_LIST_FAILURE';
export const COVERAGE_LIST_CLEAR = 'COVERAGE_LIST_CLEAR';
export const COVERAGE_DELETE_REQUEST = 'COVERAGE_DELETE_REQUEST';
export const COVERAGE_DELETE_SUCCESS = 'COVERAGE_DELETE_SUCCESS';
export const COVERAGE_DELETE_FAILURE = 'COVERAGE_DELETE_FAILURE';
export const COVERAGE_DELETE_CLEAR = 'COVERAGE_DELETE_CLEAR';
export const COVERAGE_ADMINDELETE_REQUEST = 'COVERAGE_ADMINDELETE_REQUEST';
export const COVERAGE_ADMINDELETE_SUCCESS = 'COVERAGE_ADMINDELETE_SUCCESS';
export const COVERAGE_ADMINDELETE_FAILURE = 'COVERAGE_ADMINDELETE_FAILURE';
export const COVERAGE_ADMINDELETE_CLEAR = 'COVERAGE_ADMINDELETE_CLEAR';
export const COVERAGE_ADMINLIST_REQUEST = 'COVERAGE_ADMINLIST_REQUEST';
export const COVERAGE_ADMINLIST_SUCCESS = 'COVERAGE_ADMINLIST_SUCCESS';
export const COVERAGE_ADMINLIST_FAILURE = 'COVERAGE_ADMINLIST_FAILURE';
export const COVERAGE_ADMINLIST_CLEAR = 'COVERAGE_ADMINLIST_CLEAR';

export const INBOX_COUNT_REQUEST = 'INBOX_COUNT_REQUEST';
export const INBOX_COUNT_SUCCESS = 'INBOX_COUNT_SUCCESS';
export const INBOX_COUNT_FAILURE = 'INBOX_COUNT_FAILURE';
export const INBOX_COUNT_CLEAR = 'INBOX_COUNT_CLEAR';
export const INBOX_LIST_REQUEST = 'INBOX_LIST_REQUEST';
export const INBOX_LIST_SUCCESS = 'INBOX_LIST_SUCCESS';
export const INBOX_LIST_FAILURE = 'INBOX_LIST_FAILURE';
export const INBOX_LIST_CLEAR = 'INBOX_LIST_CLEAR';
export const INBOX_ADDMESSAGE_REQUEST = 'INBOX_ADDMESSAGE_REQUEST';
export const INBOX_ADDMESSAGE_SUCCESS = 'INBOX_ADDMESSAGE_SUCCESS';
export const INBOX_ADDMESSAGE_FAILURE = 'INBOX_ADDMESSAGE_FAILURE';
export const INBOX_ADDMESSAGE_CLEAR = 'INBOX_ADDMESSAGE_CLEAR';
export const INBOX_MESSAGES_REQUEST = 'INBOX_MESSAGES_REQUEST';
export const INBOX_MESSAGES_SUCCESS = 'INBOX_MESSAGES_SUCCESS';
export const INBOX_MESSAGES_FAILURE = 'INBOX_MESSAGES_FAILURE';
export const INBOX_MESSAGES_CLEAR = 'INBOX_MESSAGES_CLEAR';
export const INBOX_SET_CURRENT = 'INBOX_SET_CURRENT';

export const RCOPIA_NOTIFICATIONS_REQUEST = 'RCOPIA_NOTIFICATIONS_REQUEST';
export const RCOPIA_NOTIFICATIONS_SUCCESS = 'RCOPIA_NOTIFICATIONS_SUCCESS';
export const RCOPIA_NOTIFICATIONS_FAILURE = 'RCOPIA_NOTIFICATIONS_FAILURE';
export const RCOPIA_NOTIFICATIONS_CLEAR = 'RCOPIA_NOTIFICATIONS_CLEAR';

export const COMMENT_ADD_REQUEST = 'COMMENT_ADD_REQUEST';
export const COMMENT_ADD_SUCCESS = 'COMMENT_ADD_SUCCESS';
export const COMMENT_ADD_FAILURE = 'COMMENT_ADD_FAILURE';
export const COMMENT_ADD_CLEAR = 'COMMENT_ADD_CLEAR';
export const COMMENT_LIST_REQUEST = 'COMMENT_LIST_REQUEST';
export const COMMENT_LIST_SUCCESS = 'COMMENT_LIST_SUCCESS';
export const COMMENT_LIST_FAILURE = 'COMMENT_LIST_FAILURE';
export const COMMENT_LIST_CLEAR = 'COMMENT_LIST_CLEAR';

export const ATTACHMENTS_DELETE_REQUEST = 'ATTACHMENTS_DELETE_REQUEST';
export const ATTACHMENTS_DELETE_SUCCESS = 'ATTACHMENTS_DELETE_SUCCESS';
export const ATTACHMENTS_DELETE_FAILURE = 'ATTACHMENTS_DELETE_FAILURE';
export const ATTACHMENTS_DELETE_CLEAR = 'ATTACHMENTS_DELETE_CLEAR';
export const ATTACHMENTS_RENAME_REQUEST = 'ATTACHMENTS_RENAME_REQUEST';
export const ATTACHMENTS_RENAME_SUCCESS = 'ATTACHMENTS_RENAME_SUCCESS';
export const ATTACHMENTS_RENAME_FAILURE = 'ATTACHMENTS_RENAME_FAILURE';
export const ATTACHMENTS_RENAME_CLEAR = 'ATTACHMENTS_RENAME_CLEAR';
export const ATTACHMENTS_LIST_REQUEST = 'ATTACHMENTS_LIST_REQUEST';
export const ATTACHMENTS_LIST_SUCCESS = 'ATTACHMENTS_LIST_SUCCESS';
export const ATTACHMENTS_LIST_FAILURE = 'ATTACHMENTS_LIST_FAILURE';
export const ATTACHMENTS_LIST_CLEAR = 'ATTACHMENTS_LIST_CLEAR';

export const CHART_PRINTLOAD_REQUEST = 'CHART_PRINTLOAD_REQUEST';
export const CHART_PRINTLOAD_SUCCESS = 'CHART_PRINTLOAD_SUCCESS';
export const CHART_PRINTLOAD_FAILURE = 'CHART_PRINTLOAD_FAILURE';
export const CHART_PRINTLOAD_CLEAR = 'CHART_PRINTLOAD_CLEAR';
export const CHART_FULLLOAD_REQUEST = 'CHART_FULLLOAD_REQUEST';
export const CHART_FULLLOAD_SUCCESS = 'CHART_FULLLOAD_SUCCESS';
export const CHART_FULLLOAD_FAILURE = 'CHART_FULLLOAD_FAILURE';
export const CHART_FULLLOAD_CLEAR = 'CHART_FULLLOAD_CLEAR';
export const CHART_PARAMS_REQUEST = 'CHART_PARAMS_REQUEST';
export const CHART_PARAMS_SUCCESS = 'CHART_PARAMS_SUCCESS';
export const CHART_PARAMS_FAILURE = 'CHART_PARAMS_FAILURE';
export const CHART_PARAMS_CLEAR = 'CHART_PARAMS_CLEAR';

// Template Letter
export const TEMPLATELETTER_LIST_LOAD_REQUEST = 'TEMPLATELETTER_LIST_LOAD_REQUEST';
export const TEMPLATELETTER_LIST_LOAD_SUCCESS = 'TEMPLATELETTER_LIST_LOAD_SUCCESS';
export const TEMPLATELETTER_LIST_LOAD_FAILURE = 'TEMPLATELETTER_LIST_LOAD_FAILURE';
export const TEMPLATELETTER_LIST_LOAD_CLEAR = 'TEMPLATELETTER_LIST_LOAD_CLEAR';
export const TEMPLATELETTER_FIELDS_LOAD_REQUEST = 'TEMPLATELETTER_FIELDS_LOAD_CLEAR_LOAD_REQUEST';
export const TEMPLATELETTER_FIELDS_LOAD_SUCCESS = 'TEMPLATELETTER_FIELDS_LOAD_SUCCESS';
export const TEMPLATELETTER_FIELDS_LOAD_FAILURE = 'TEMPLATELETTER_FIELDS_LOAD_FAILURE';
export const TEMPLATELETTER_FIELDS_LOAD_CLEAR = 'TEMPLATELETTER_FIELDS_LOAD_CLEAR';
export const TEMPLATELETTER_SAVE_REQUEST = 'TEMPLATELETTER_SAVE_REQUEST';
export const TEMPLATELETTER_SAVE_SUCCESS = 'TEMPLATELETTER_SAVE_SUCCESS';
export const TEMPLATELETTER_SAVE_FAILURE = 'TEMPLATELETTER_SAVE_FAILURE';
export const TEMPLATELETTER_SAVE_CLEAR = 'TEMPLATELETTER_SAVE_CLEAR';
export const TEMPLATELETTER_CREATE_REQUEST = 'TEMPLATELETTER_CREATE_REQUEST';
export const TEMPLATELETTER_CREATE_SUCCESS = 'TEMPLATELETTER_CREATE_SUCCESS';
export const TEMPLATELETTER_CREATE_FAILURE = 'TEMPLATELETTER_CREATE_FAILURE';
export const TEMPLATELETTER_CREATE_CLEAR = 'TEMPLATELETTER_CREATE_CLEAR';

export const PROBLEMLIST_LOAD_REQUEST = 'PROBLEMLIST_LOAD_REQUEST';
export const PROBLEMLIST_LOAD_SUCCESS = 'PROBLEMLIST_LOAD_SUCCESS';
export const PROBLEMLIST_LOAD_FAILURE = 'PROBLEMLIST_LOAD_FAILURE';
export const PROBLEMLIST_LOAD_CLEAR = 'PROBLEMLIST_LOAD_CLEAR';
export const PROBLEMLIST_ACTIVATE_REQUEST = 'PROBLEMLIST_ACTIVATE_REQUEST';
export const PROBLEMLIST_ACTIVATE_SUCCESS = 'PROBLEMLIST_ACTIVATE_SUCCESS';
export const PROBLEMLIST_ACTIVATE_FAILURE = 'PROBLEMLIST_ACTIVATE_FAILURE';
export const PROBLEMLIST_ACTIVATE_CLEAR = 'PROBLEMLIST_ACTIVATE_CLEAR';
export const PROBLEMLIST_DEACTIVATE_REQUEST = 'PROBLEMLIST_DEACTIVATE_REQUEST';
export const PROBLEMLIST_DEACTIVATE_SUCCESS = 'PROBLEMLIST_DEACTIVATE_SUCCESS';
export const PROBLEMLIST_DEACTIVATE_FAILURE = 'PROBLEMLIST_DEACTIVATE_FAILURE';
export const PROBLEMLIST_DEACTIVATE_CLEAR = 'PROBLEMLIST_DEACTIVATE_CLEAR';
export const PROBLEMLIST_SAVE_REQUEST = 'PROBLEMLIST_SAVE_REQUEST';
export const PROBLEMLIST_SAVE_SUCCESS = 'PROBLEMLIST_SAVE_SUCCESS';
export const PROBLEMLIST_SAVE_FAILURE = 'PROBLEMLIST_SAVE_FAILURE';
export const PROBLEMLIST_SAVE_CLEAR = 'PROBLEMLIST_SAVE_CLEAR';
export const PROBLEMLIST_CREATE_REQUEST = 'PROBLEMLIST_CREATE_REQUEST';
export const PROBLEMLIST_CREATE_SUCCESS = 'PROBLEMLIST_CREATE_SUCCESS';
export const PROBLEMLIST_CREATE_FAILURE = 'PROBLEMLIST_CREATE_FAILURE';
export const PROBLEMLIST_CREATE_CLEAR = 'PROBLEMLIST_CREATE_CLEAR';

export const DIAGNOSIS_LIST_REQUEST = 'DIAGNOSIS_LIST_REQUEST';
export const DIAGNOSIS_LIST_SUCCESS = 'DIAGNOSIS_LIST_SUCCESS';
export const DIAGNOSIS_LIST_FAILURE = 'DIAGNOSIS_LIST_FAILURE';
export const DIAGNOSIS_LIST_CLEAR = 'DIAGNOSIS_LIST_CLEAR';
export const DIAGNOSIS_LOAD_REQUEST = 'DIAGNOSIS_LOAD_REQUEST';
export const DIAGNOSIS_LOAD_SUCCESS = 'DIAGNOSIS_LOAD_SUCCESS';
export const DIAGNOSIS_LOAD_FAILURE = 'DIAGNOSIS_LOAD_FAILURE';
export const DIAGNOSIS_LOAD_CLEAR = 'DIAGNOSIS_LOAD_CLEAR';

// Supervisor list
export const SUPERVISOR_LIST_REQUEST = 'SUPERVISOR_LIST_REQUEST';
export const SUPERVISOR_LIST_SUCCESS = 'SUPERVISOR_LIST_SUCCESS';
export const SUPERVISOR_LIST_FAILURE = 'SUPERVISOR_LIST_FAILURE';
export const SUPERVISOR_LIST_CLEAR = 'SUPERVISOR_LIST_CLEAR';

export const NOTE_CREATE_REQUEST = 'NOTE_CREATE_REQUEST';
export const NOTE_CREATE_SUCCESS = 'NOTE_CREATE_SUCCESS';
export const NOTE_CREATE_FAILURE = 'NOTE_CREATE_FAILURE';
export const NOTE_CREATE_CLEAR = 'NOTE_CREATE_CLEAR';
export const NOTE_LOAD_REQUEST = 'NOTE_LOAD_REQUEST';
export const NOTE_LOAD_SUCCESS = 'NOTE_LOAD_SUCCESS';
export const NOTE_LOAD_FAILURE = 'NOTE_LOAD_FAILURE';
export const NOTE_LOAD_CLEAR = 'NOTE_LOAD_CLEAR';
export const NOTE_SAVE_REQUEST = 'NOTE_SAVE_REQUEST';
export const NOTE_SAVE_SUCCESS = 'NOTE_SAVE_SUCCESS';
export const NOTE_SAVE_FAILURE = 'NOTE_SAVE_FAILURE';
export const NOTE_SAVE_CLEAR = 'NOTE_SAVE_CLEAR';
export const NOTE_AUTOSAVE_REQUEST = 'NOTE_AUTOSAVE_REQUEST';
export const NOTE_AUTOSAVE_SUCCESS = 'NOTE_AUTOSAVE_SUCCESS';
export const NOTE_AUTOSAVE_FAILURE = 'NOTE_AUTOSAVE_FAILURE';
export const NOTE_AUTOSAVE_CLEAR = 'NOTE_AUTOSAVE_CLEAR';
export const NOTE_SIGN_REQUEST = 'NOTE_SIGN_REQUEST';
export const NOTE_SIGN_SUCCESS = 'NOTE_SIGN_SUCCESS';
export const NOTE_SIGN_FAILURE = 'NOTE_SIGN_FAILURE';
export const NOTE_SIGN_CLEAR = 'NOTE_SIGN_CLEAR';
export const NOTE_DELETE_REQUEST = 'NOTE_DELETE_REQUEST';
export const NOTE_DELETE_SUCCESS = 'NOTE_DELETE_SUCCESS';
export const NOTE_DELETE_FAILURE = 'NOTE_DELETE_FAILURE';
export const NOTE_DELETE_CLEAR = 'NOTE_DELETE_CLEAR';
export const NOTE_REJECT_REQUEST = 'NOTE_REJECT_REQUEST';
export const NOTE_REJECT_SUCCESS = 'NOTE_REJECT_SUCCESS';
export const NOTE_REJECT_FAILURE = 'NOTE_REJECT_FAILURE';
export const NOTE_REJECT_CLEAR = 'NOTE_REJECT_CLEAR';
export const NOTE_APPROVE_REQUEST = 'NOTE_APPROVE_REQUEST';
export const NOTE_APPROVE_SUCCESS = 'NOTE_APPROVE_SUCCESS';
export const NOTE_APPROVE_FAILURE = 'NOTE_APPROVE_FAILURE';
export const NOTE_APPROVE_CLEAR = 'NOTE_APPROVE_CLEAR';
export const NOTE_LIST_REQUEST = 'NOTE_LIST_REQUEST';
export const NOTE_LIST_SUCCESS = 'NOTE_LIST_SUCCESS';
export const NOTE_LIST_FAILURE = 'NOTE_LIST_FAILURE';
export const NOTE_LIST_CLEAR = 'NOTE_LIST_CLEAR';
export const NOTE_ADDENDUM_CREATE_REQUEST = 'NOTE_ADDENDUM_CREATE_REQUEST';
export const NOTE_ADDENDUM_CREATE_SUCCESS = 'NOTE_ADDENDUM_CREATE_SUCCESS';
export const NOTE_ADDENDUM_CREATE_FAILURE = 'NOTE_ADDENDUM_CREATE_FAILURE';
export const NOTE_ADDENDUM_CREATE_CLEAR = 'NOTE_ADDENDUM_CREATE_CLEAR';
export const NOTE_MISSINGLIST_REQUEST = 'NOTE_MISSINGLIST_REQUEST';
export const NOTE_MISSINGLIST_SUCCESS = 'NOTE_MISSINGLIST_SUCCESS';
export const NOTE_MISSINGLIST_FAILURE = 'NOTE_MISSINGLIST_FAILURE';
export const NOTE_MISSINGLIST_CLEAR = 'NOTE_MISSINGLIST_CLEAR';
export const NOTE_UNSIGNEDLIST_REQUEST = 'NOTE_UNSIGNEDLIST_REQUEST';
export const NOTE_UNSIGNEDLIST_SUCCESS = 'NOTE_UNSIGNEDLIST_SUCCESS';
export const NOTE_UNSIGNEDLIST_FAILURE = 'NOTE_UNSIGNEDLIST_FAILURE';
export const NOTE_SUPERVISEEUNSIGNEDLIST_REQUEST = 'NOTE_SUPERVISEEUNSIGNEDLIST_REQUEST';
export const NOTE_SUPERVISEEUNSIGNEDLIST_SUCCESS = 'NOTE_SUPERVISEEUNSIGNEDLIST_SUCCESS';
export const NOTE_SUPERVISEEUNSIGNEDLIST_FAILURE = 'NOTE_SUPERVISEEUNSIGNEDLIST_FAILURE';
export const NOTE_SUPERVISEEUNSIGNEDLIST_CLEAR = 'NOTE_SUPERVISEEUNSIGNEDLIST_CLEAR';
export const NOTE_SUPERVISORBULKAPPROVE_REQUEST = 'NOTE_SUPERVISORBULKAPPROVE_REUQEST';
export const NOTE_SUPERVISORBULKAPPROVE_SUCCESS = 'NOTE_SUPERVISORBULKAPPROVE_SUCCESS';
export const NOTE_SUPERVISORBULKAPPROVE_FAILURE = 'NOTE_SUPERVISORBULKAPPROVE_FAILURE';
export const NOTE_SUPERVISORBULKAPPROVE_CLEAR = 'NOTE_SUPERVISORBULKAPPROVE_CLEAR';
export const NOTE_LATECANCEL_DELETE_REQUEST = 'NOTE_LATECANCEL_DELETE_REQUEST';
export const NOTE_LATECANCEL_DELETE_SUCCESS = 'NOTE_LATECANCEL_DELETE_SUCCESS';
export const NOTE_LATECANCEL_DELETE_FAILURE = 'NOTE_LATECANCEL_DELETE_FAILURE';
export const NOTE_LATECANCEL_DELETE_CLEAR = 'NOTE_LATECANCEL_DELETE_CLEAR';

export const CHART_AUDIT_LOG_CREATE_REQUEST = 'CHART_AUDIT_LOG_CREATE_REQUEST';
export const CHART_AUDIT_LOG_CREATE_SUCCESS = 'CHART_AUDIT_LOG_CREATE_SUCCESS';
export const CHART_AUDIT_LOG_CREATE_FAILURE = 'CHART_AUDIT_LOG_CREATE_FAILURE';
export const CHART_AUDIT_LOG_CREATE_CLEAR = 'CHART_AUDIT_LOG_CREATE_CLEAR';