import React from 'react';
import CalView from './Scheduler_/CalView';

class Scheduler extends React.Component {
  render() {
    return (
      <CalView />
    );
  }
}

/*
const mapStateToProps = (state, ownProps) => {
  return {
    activeUserId: safe(state,'shared.domain.activeUser.id'),
  }
}
*/


export default Scheduler;
