import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, DatePicker, Button, Col, Row, Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';

import { safe } from '../../helpers/reduxSelectors';
import * as actions from '../../actions/shared';

class PatientSelectorAdvanced extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onSelect: PropTypes.func
  }

  state = {
    page: 1,
    pageSize: 8
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.props.form.resetFields();
      this.props.getAdvancedPatientListClear();
    }
  }

  search = () => {
    this.setState({
      page: 1
    },()=>{
      const vals = JSON.parse(JSON.stringify(this.props.form.getFieldsValue()));
      this.props.getAdvancedPatientList({
        ...vals,
        page: this.state.page,
        pageSize: this.state.pageSize
      });
    });
  }

  changePage = ({current}) => {
    this.setState({
      page: current
    },()=>{
      const vals = JSON.parse(JSON.stringify(this.props.form.getFieldsValue()));
      this.props.getAdvancedPatientList({
        ...vals,
        page: this.state.page,
        pageSize: this.state.pageSize
      });
    });
  }

  mainForm = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout="horizontal">
        <Row gutter={16}>
          <Col span={7}>
            <Form.Item>
              {getFieldDecorator('email', {})(
                <Input name='email' placeholder='Email' allowClear/>
              )}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item>
              {getFieldDecorator('phone', {})(
                <Input name='phone' placeholder='Phone' allowClear/>
              )}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item>
              {getFieldDecorator('dob', {})(
                <DatePicker format='M/D/YYYY' placeholder='DOB'/>
              )}
            </Form.Item>
          </Col>
          <Col span={3} style={{textAlign:'right'}}>
            <Form.Item>
              <Button type="primary" onClick={this.search}>Search</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  rowClick = (record) => {
    this.props.onClose();
    this.props.onSelectPatient(record.id);
  }

  onCancel = () => {
    this.props.onClose();
  }

  searchResults = () => {
    const columns = [{
      title: 'Patient Name',
      dataIndex: 'full_name',
      render: (val,record) => {
        return (
          <Button onClick={()=>{this.rowClick(record)}} type='link' size='small'>
            {val}
          </Button>
        )
      }
    },{
      title: 'ID #',
      dataIndex: 'id',
      render: (val) => numeral(val).format('000000')
    },{
      title: 'DOB',
      dataIndex: 'dob',
      render: (val) => moment(val).format('MM/DD/YYYY')
    },{
      title: 'Phone',
      dataIndex: 'phone'
    },{
      title: 'Email',
      dataIndex: 'email'
    }];

    return (
      <Table
        dataSource={this.props.searchResults}
        columns={columns}
        size='small'
        onChange={this.changePage}
        loading={this.isLoading}
        pagination={{
          current: this.state.page,
          pageSize: this.state.pageSize,
          total: this.props.searchCount,
          showQuickJumper: true
        }}
      />
    );
  }

  render() {
    return (
      <Modal
        title='Patient Search'
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='Close'
        visible={this.props.visible}
        footer={false}
        width={1000}
      >
        { this.mainForm() }
        { this.searchResults() }
      </Modal>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: safe(state,'shared.patient.listadvanced.processing',false),
    searchResults: safe(state,'shared.patient.listadvanced.data.rows',[]),
    searchCount: safe(state,'shared.patient.listadvanced.data.count',0)
  }
};

export default connect(mapStateToProps,actions)(Form.create()(PatientSelectorAdvanced));
