import React, { Component } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Button, Input, Row, Col, message, Checkbox, Spin, AutoComplete } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { safe } from '../../../../helpers/reduxSelectors';
import moment from 'moment';

import * as emr_actions from '../../../../actions/emr';
import * as shared_actions from '../../../../actions/shared';

class LabRequestNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: parseInt(this.props.match.params.patient_id,10)
    }
  }

  componentDidMount() {
    this.props.getPrimaryDiagnosis(this.state.patientId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.createNoteEvent !== this.props.createNoteEvent) {
      if (this.props.createNoteEvent.success) {
        this.props.noteCreateClear();
        this.props.history.push('/emr/patients/'+this.state.patientId);
        message.success("Labs request created");
      } else if (this.props.createNoteEvent.error){
        this.props.noteCreateClear();
        this.props.history.push('/emr/patients/'+this.state.patientId)
        message.error("Unable to create a labs request.");
      }
    }
  }

  cancelNote = () => {
    this.props.history.push('/emr/patients/'+this.state.patientId);
  }

  signNote = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.noteCreate(this.state.patientId,5,values);
      }
    });
  }

  handleSearch = (value) => {
    this.props.getDiagnosisList(value);
  }

  renderOption = (item) => {
    return item.icd10cm+'-'+item.description;
  }

  renderOption2 = (item) => {
    if (!item || !item.diagnosis || !item.description) {
      return null;
    }
    return item.diagnosis+'-'+item.description;
  }

  render() {
    console.log('this.props.diagnosisList', this.props.diagnosisList);
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const CheckboxGroup = Checkbox.Group;
    const labTypes = [
      'CBC','CMP','TSH with reflex T4','FT3','folic acid','Vitamin D 25-OH',
      'B12','homocysteine','lipid panel','hemoglobin A1c',
      'iron','total iron binding capacity','transferrin',
      'ferritin','iron saturation','GGT','lithium level',
      'valproic acid level','oxcarbazepine level','magnesium',
      'phosphorus','zinc','HIV','RPR',
      'basic metabolic panel',
      'comprehensive metabolic panel',
      'urine toxicology',
      'urine pregnancy',
      'prolactin',
      'HbA1C',
      'CBC with differential'
    ];

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <h3 style={{paddingTop:'10px',paddingBottom:'20px'}}>Labs Request</h3>

          <Form layout={'vertical'} style={{autoComplete:"off"}}>
            <Row>
              <Col span={8}>
                <FormItem label="Date">
                  {getFieldDecorator('note_date', {
                    rules: [{ type: 'object', required: true, message: "Please enter a date for this note", whitespace: true }],
                    initialValue: moment(new Date())
                  })(
                    <DatePicker format='MM/DD/YYYY' />
                  )}
                </FormItem>
              </Col>
            </Row>

            <FormItem label="Diagnosis">
              {getFieldDecorator('diagnosis', {
                rules: [{ type: 'string', required: true, message: "Please enter a diagnosis" }],
                initialValue: this.renderOption2(safe(this.props,'diagnosis_primary'))
              })(
              <AutoComplete
                className="certain-category-search"
                placeholder="Search for diagnosis..."
                allowClear={true}
                dataSource={this.props.diagnosisList.map(this.renderOption)}
                onSearch={this.handleSearch}
              />)}
            </FormItem>

            <FormItem label="Labs">
              {getFieldDecorator('labs', {
                rules: [{ type: 'array', required: true, message: "Choose at least one lab" }],
              })(
                <CheckboxGroup>
                  <Row>
                    {labTypes.sort().map(x=>(<Col key={x} span={8}><Checkbox value={x}>{x}</Checkbox></Col>))}
                  </Row>
                  <Row>
                    <Col key='other' span={8}><Checkbox value='Other (see note below)'>Other (see note below)</Checkbox></Col>
                  </Row>
                </CheckboxGroup>
              )}
            </FormItem>
            <FormItem label="Additional Note for Labs Request">
              {getFieldDecorator('other_note', {
              })(
                <TextArea style={{autoComplete:'off'}} rows={5} autosize={{minRows: 5,maxRows: 7}}/>
              )}
            </FormItem>
          </Form>

          <Row>
            <Col span={12}>
              <Button type="danger" icon={<DeleteOutlined />} onClick={()=>{this.props.history.push('/emr/patients/'+this.state.patientId)}}>Discard Lab Request</Button>
            </Col>
            <Col span={12} style={{textAlign:'right'}}>
              <Button type="primary" onClick={this.signNote}>Sign Labs Request</Button>
            </Col>
          </Row>
        </Spin>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'emr.note.create.processing',false)
    ) ? true : false,
    createNoteEvent: safe(state,'emr.note.create',{}),
    diagnosisList: safe(state,'emr.diagnosis.data',[]),
    diagnosis_primary: safe(state,'emr.diagnosis_primary.data',{})
  }
}

export default connect(mapStateToProps,{...shared_actions,...emr_actions})(Form.create()(withRouter(LabRequestNote)));
