import React, { Component } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Select, message, Modal, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import TemplatePreview from './TemplatePreview';

import * as emr_actions from '../../../../actions/emr';
import * as shared_actions from '../../../../actions/shared';
import { safe } from '../../../../helpers/reduxSelectors';

class TemplateLetterNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: parseInt(this.props.match.params.patient_id,10),
      letter_id: null,
      template_values: {}
    }
  }

  componentDidMount() {
    this.props.getTemplateLetterList();
    this.props.getTemplateFieldValues(this.state.patientId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        this.props.noteCreateClear();
        this.props.history.push('/emr/patients/'+this.state.patientId);
        message.success("Template letter created");
      } else if (this.props.create.error){
        this.props.noteCreateClear();
        this.props.history.push('/emr/patients/'+this.state.patientId)
        message.error("Unable to create template letter.");
      }
    }
  }

  onChangeLetter = (value) => {
    this.setState({
      letter_id: value
    })
  }

  getReferencedFields = (letter) => {
    let reg = /\|\*(\w+)\*\|/g;
    let result = [];

    let match = reg.exec(letter);
    while (match) {
      result.push(match[1]);
      match = reg.exec(letter);
    }
/*
    for (let match; match = reg.exec(letter);) {
    	result.push(match[1]);
    }
    */
    return result;
  }

  changeField = (event) => {
    this.setState({
      template_values: {
        ...this.state.template_values,
        [event.target.name]: event.target.value
      }
    });
  }

  getInputComponent = (x) => {
    const { TextArea } = Input;

    return (
      <div key={x}>
        <div><label>{x}</label></div>
        <div>
          <TextArea
            onChange={this.changeField}
            value={this.state.template_values[x]}
            name={x}
            placeholder={x}
            rows={4}
            autosize={{minRows: 4,maxRows: 5}}
          />
        </div>
      </div>
    );
  }

  fieldsNotReferenced = () => {
    let html = this.getTemplate();
    let fields = this.getReferencedFields(html);
    let items = fields.filter(x =>!this.props.fieldData[x]);
    return items.map(x => this.getInputComponent(x));
  }

  getOptions = () => {
    const Option = Select.Option;
    return this.props.templateList.map(x => {
      return (<Option key={x.id} value={x.id}>{x.title}</Option>);
    });
  }

  getFieldValues = () => {
    const a = (this.props.fieldData) ? this.props.fieldData : {};
    return {
      ...a,
      ...this.state.template_values
    }
  }

  getTemplate = () => {
    if (this.state.letter_id) {
      return this.props.templateList.find(x=>x.id===this.state.letter_id).letter;
    } else {
      return 'No data';
    }
  }

  getTemplateTitle = () => {
    if (this.state.letter_id) {
      return this.props.templateList.find(x=>x.id===this.state.letter_id).title;
    } else {
      return null;
    }
  }

  preview = () => {
    let a = this.fieldsNotReferenced();
    if (a.length > 0) {
      return (
        <table width='100%'>
          <tbody>
            <tr valign='top'>
              <td style={{width:'40%',paddingRight:'10px'}}>
                <h3>Additional Fields to Fill</h3>
                { a }
              </td>
              <td style={{width:'60%'}}>
                <h3>Template Preview</h3>
                <TemplatePreview
                  fieldValues={this.getFieldValues()}
                  htmlTemplate={this.getTemplate()}
                />
              </td>
            </tr>
          </tbody>
        </table>
      );
    } else {
      return (
        <div>
          <h3>Template Preview</h3>
          <TemplatePreview
            fieldValues={this.getFieldValues()}
            htmlTemplate={this.getTemplate()}
          />
        </div>
      );
    }
  }

  escapeRegExp = (str) => {
    return str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
//    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }

  signNote = () => {
    let newLetter = this.getTemplate();
    const allFields = this.getReferencedFields(newLetter);
    const fieldValues=this.getFieldValues();

    allFields.forEach(key=>{
      const re = new RegExp(this.escapeRegExp("|*"+key+"*|"),"g");
      const val = (fieldValues[key]) ? fieldValues[key] : ' ';
      newLetter = newLetter.replace(re,val);
    });

    this.props.noteCreate(this.state.patientId,6,{
      title: this.getTemplateTitle(),
      letter: newLetter,
    });
  }
//         <Link to={"/emr/patients/"+this.state.patientId}>&lt;&lt; Return to patient chart</Link>

  showDiscardConfirm = () => {
    const dn = () => {
      this.props.history.push("/emr/patients/"+this.state.patientId);
    };
    Modal.confirm({
      title: 'Are you sure you want to discard this note?',
      onOk() {
        dn();
      }
    });
  }

  render() {
    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <h3 style={{paddingTop:'10px'}}>Template Letter Note</h3>
          <table width='100%'>
            <tbody>
              <tr>
                <td>
                  <strong><nobr>Choose Template:</nobr></strong>
                </td>
                <td width='100%'>
                  <Select
                    value={this.state.letter_id}
                    onChange={this.onChangeLetter}
                    style={{width:'100%'}}
                  >
                    {this.getOptions()}
                  </Select>
                </td>
                <td>
                  <Button type='primary' style={{marginLeft:'5px'}} onClick={this.signNote} disabled={!this.state.letter_id}>Sign Letter</Button>
                </td>
              </tr>
            </tbody>
          </table>

          <br />

          {this.preview()}

          <div style={{paddingTop:'20px'}}>
            <Button type="danger" icon={<DeleteOutlined />} onClick={()=>{this.props.history.push('/emr/patients/'+this.state.patientId)}}>Discard Note</Button>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'emr.note.create.processing',false)
    ) ? true : false,
    templateList: safe(state,'emr.templateletter.load.data',[]),
    fieldData: safe(state,'emr.templateletter.fields.data',[]),
    create: safe(state,'emr.note.create',{}),
    note: safe(state,'emr.note',{})
  }
}

export default connect(mapStateToProps,{...shared_actions,...emr_actions})(withRouter(TemplateLetterNote));
