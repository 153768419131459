import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Spin,
  DatePicker,
  Select,
  Input,
  Checkbox,
  InputNumber,
  notification,
} from 'antd';

import DiagnosisControl from './DiagnosisControl';

import * as billing_actions from '../../../../../actions/billing';
import * as shared_actions from '../../../../../actions/shared';

import { safe } from '../../../../../helpers/reduxSelectors';

class EditLineModal extends React.Component {

  static propTypes = {
    patientId: PropTypes.number,
    serviceId: PropTypes.number
  }

  componentDidMount() {
    this.loadLists();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible === true) {
      if (this.props.serviceId) {
        this.props.getBillingServiceLine(this.props.serviceId);
        this.loadLists();
      }
    }

    if (prevProps.servicelineSaveEvent !== this.props.servicelineSaveEvent) {
      if (this.props.servicelineSaveEvent.success) {
        notification['success']({
          message: 'Success',
          description: 'Service line edits recorded'
        });
      } else if (this.props.servicelineSaveEvent.error) {
        notification['error']({
          message: 'Error',
          description: safe(this.props,'servicelineSaveEvent.error.data.message')
        });
      }
    }
  }

  loadLists = () => {
    this.props.getLocationsList();
    this.props.getUserList();
    this.props.getCptList();
  }

  handleOk = () => {
    this.props.form.validateFields((err,values) => {
      if (!err) {
        this.props.saveBillingServiceLine(this.props.patientId,this.props.serviceId,{
          ...values,
          carrier_id: this.props.lineData.carrier_id,
          tpa_id: this.props.lineData.tpa_id
        });
        this.props.closeDialog();
      }
    });
  }

  handleCancel = () => {
    this.props.closeDialog();
  }

  renderSessionForm = () => {
    const providersList = this.props.userList.filter(x=>(x.roles.includes('therapist') || x.roles.includes('prescriber')));
//    const servicing_provider = this.props.userList.find(x=>x.id===this.props.lineData.servicing_provider_id);
    const Option = Select.Option;
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
      },
    };
    const modifierList = ['GT','HJ','95'];

    return (
      <div>
        <FormItem {...formItemLayout} label="Date of Service">
          {getFieldDecorator('dos', {
            rules: [{ required: true, message: " " }],
            initialValue: moment(this.props.lineData.dos)
          })(
            <DatePicker format='M/D/YYYY' />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Rendering Provider">
          {getFieldDecorator('rendering_provider_id', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'lineData.rendering_provider_id')
          })(
            <Select>
              { providersList.map(x=><Option key={x.id} value={x.id}>{x.full_name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Servicing Provider">
          {getFieldDecorator('servicing_provider_id', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'lineData.servicing_provider_id')
          })(
            <Select>
              { providersList.map(x=><Option key={x.id} value={x.id}>{x.full_name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Service Location">
          {getFieldDecorator('location_id', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'lineData.location_id')
          })(
            <Select>
              { this.props.locationList.map(x=><Option key={x.id} value={x.id}>{x.name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="CPT">
          {getFieldDecorator('cpt_codes', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'lineData.cpt_codes')
          })(
            <Select mode='multiple'>
              { this.props.cptList.map(x=><Option key={x.code} value={x.code}>{x.code}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Modifiers">
          {getFieldDecorator('modifiers', {
            rules: [{ required: false, message: " " }],
            initialValue: (safe(this.props,'lineData.modifiers')) ? safe(this.props,'lineData.modifiers') : []
          })(
            <Select mode='multiple'>
              { modifierList.map(x=><Option key={x} value={x}>{x}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Diagnosis">
          {getFieldDecorator('diagnosis', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'lineData.diagnosis')
          })(
            <DiagnosisControl />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Units">
          {getFieldDecorator('units', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'lineData.units',1)
          })(
            <InputNumber min={1} max={10}/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Corrected Claim">
          {getFieldDecorator('corrected_claim', {
            initialValue: safe(this.props,'lineData.claim_data.corrected_claim',false),
            valuePropName: 'checked'
          })(
            <Checkbox/>
          )}
        </FormItem>

        {this.props.form.getFieldValue('corrected_claim') && <FormItem {...formItemLayout} label="Original Claim Number">
          {getFieldDecorator('corrected_claim_number', {
            initialValue: safe(this.props,'lineData.claim_data.corrected_claim_number')
          })(
            <Input/>
          )}
        </FormItem> }

      </div>
    )
  }

  renderOtherForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const providersList = this.props.userList.filter(x=>(x.roles.includes('therapist') || x.roles.includes('prescriber')));
    const Option = Select.Option;

    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
      },
    };


        console.log(this.props.lineData);

    return (
      <div>
        <FormItem {...formItemLayout} label="Date of Service">
          {getFieldDecorator('dos', {
            rules: [{ required: true, message: " " }],
            initialValue: moment(this.props.lineData.dos)
          })(
            <DatePicker format='M/D/YYYY' />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Service Location">
          {getFieldDecorator('location_id', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'lineData.location_id')
          })(
            <Select>
              { this.props.locationList.map(x=><Option key={x.id} value={x.id}>{x.name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Rendering Provider">
          {getFieldDecorator('rendering_provider_id', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'lineData.rendering_provider_id')
          })(
            <Select
              showSearch={true}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              { providersList.map(x=><Option key={x.id} value={x.id}>{x.full_name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Servicing Provider">
          {getFieldDecorator('servicing_provider_id', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'lineData.servicing_provider_id')
          })(
            <Select
              showSearch={true}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              { providersList.map(x=><Option key={x.id} value={x.id}>{x.full_name}</Option>) }
            </Select>
          )}
        </FormItem>
      </div>
    );
  }

  render() {
    const serviceLine = this.props.lineData;
    const product_id = serviceLine.product_id;

    return (
      <div style={{display:'inline-block'}}>
        <Modal
          title="Edit Service Line"
          visible={this.props.visible}
          onOk={this.handleOk}
          okText='Save'
          onCancel={this.handleCancel}
          destroyOnClose={true}
          width={800}
          bodyStyle={{padding:'5px'}}
        >
          <Spin spinning={this.props.isBusy}>
            <Form className='editlinedetails-table' autoComplete='false'>
              { (product_id === 1) && this.renderSessionForm() }
              { (product_id > 1) && this.renderOtherForm() }
            </Form>
          </Spin>
        </Modal>
      </div>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.serviceline.load.processing',false) ||
      safe(state,'shared.carrier.list.processing',false) ||
      safe(state,'billing.tpa.load.processing',false) ||
      safe(state,'shared.user.list.processing',false) ||
      safe(state,'billing.cpt.list.processing',false) ||
      safe(state,'billing.location.list.processing',false)
    ) ? true : false,
    userList: safe(state,'shared.user.list.data',[]),
    lineData: safe(state,'billing.serviceline.load.data',{}),
    cptList: safe(state,'billing.cpt.list.data',[]),
    locationList: safe(state,'shared.location.list.data',[]),
    servicelineSaveEvent: safe(state,'billing.serviceline.save',{})
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(Form.create()(EditLineModal));
