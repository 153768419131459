import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Table } from 'antd';
import numeral from 'numeral';
import moment from 'moment';

import { safe } from '../../../../../helpers/reduxSelectors';

class InsPayments extends Component {

  render() {
    const columns = [{
      title: 'DOS',
      dataIndex: 'date',
      align:'left',
      width: 100,
      render: (value,record) => {
        const d = record['billing_services.dos'];
        if (moment(d).isValid()) {
          return moment(d).format('MM/DD/YYYY');
        }
        return null;
      }
    },{
      title: 'Carrier',
      width: 65,
      render: (value,record) => {
        return record['billing_payments.billing_payers.carrier.abbr'];
      }
    },{
      title: 'Amount',
      dataIndex: 'ins_amount',
      align: 'right',
      width: 70,
      render: (value,record) => {
        return numeral(value).format('$0,0.00');
      }
    }, {
      title: 'Payment ID',
      dataIndex: 'billing_payment_id',
      align: 'right',
      render: (value,record) => {
        return <Link to={'/billing/payments/1/'+safe(record,'billing_payment_id')}>{value}</Link>
      }
    }];

    return (
      <div>

        <Table
          columns={columns}
          className='inspayments'
          bordered={false}
          dataSource={this.props.data}
          size='small'
          pagination={false}
          showHeader={true}
        />
      </div>
    )
  }
}

export default withRouter(InsPayments);
