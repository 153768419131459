/**
 * DateRangeSelector.tsx
 * A date range toggle selector
 */
import { useContextSelector } from 'use-context-selector';
import { DatePicker, Form } from 'antd';
import formItemRules from 'components/App_/ReportsV2_/formItemRules';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';

interface PropsI {
  disableEndDate?: boolean;
}

const DateTimeRangeSelector: React.FC<PropsI> = (props: PropsI) => {
  const { disableEndDate } = props;
  const form = useContextSelector(InlineContext, (c) => c.state.form);

  const onStartDateChange = (date: moment.Moment | null) => {
    const startDate = date?.seconds(0).milliseconds(0);
    form?.setFieldsValue({ startDate });
  };

  const onEndDateChange = (date: moment.Moment | null) => {
    const endDate = date?.seconds(0).milliseconds(0);
    form?.setFieldsValue({ endDate });
  };

  return (
    <>
      <Form.Item name="startDate" rules={formItemRules.startDate}>
        <DatePicker showTime={true} onChange={onStartDateChange} placeholder="Start Date & Time" />
      </Form.Item>
      {!disableEndDate ? (
        <Form.Item name="endDate" rules={formItemRules.endDate}>
          <DatePicker showTime={true} onChange={onEndDateChange} placeholder="End Date & Time" />
        </Form.Item>
      ) : null}
    </>
  );
};

export default DateTimeRangeSelector;
