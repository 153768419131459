import React, {Component} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Spin, Table } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import * as actions from '../../../actions/settings';
import { safe } from '../../../helpers/reduxSelectors';

class Emails extends Component {

  componentDidMount() {
    this.props.getEmailTemplates();
  }

  render() {
    const columns = [{
      title: 'Email Title',
      dataIndex: 'title',
      render: (val,record) => {
        return <a onClick={()=>this.props.history.push('/settings/emails/'+record.id)}>{val}</a>;
      }
    },{
      title: 'Unique Name',
      dataIndex: 'unique_name'
    },{
      title: 'From',
      dataIndex: 'fromAddress'
    },{
      title: 'Description',
      dataIndex: 'description'
    },{
      title: 'Date Created',
      dataIndex: 'createdAt',
      render: (val) => {
        return moment(val).format('M/D/YYYY h:mm:ss A');
      }
    }];

    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy}>
          <h3>Email Templates</h3>
          <Table
            dataSource={safe(this.props,'list.data',[])}
            columns={columns}
            pagination={false}
            size='small'
          />

          <div style={{position:'fixed',bottom:40,right:40}}>
            <Button
              onClick={() => {this.props.history.push('/settings/emails/0')}}
              size='large'
              shape='circle'
              type="primary"
              icon={<PlusOutlined />}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    list: safe(state,'settings.emails_list'),
    isBusy: (
      safe(state,'settings.emails_list.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(withRouter(Emails));
