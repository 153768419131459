import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Spin, Button } from 'antd';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';
import { processEventNotification } from '../../../helpers/util';

const REPORT_ID = 10; // AR Carrier Aging

class ReportsLS_ar_carrier_aging extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      params: {}
    }
  }

  componentDidUpdate(prevProps) {
    processEventNotification(prevProps,this.props,'addQueue','Report Added to Queue');
  }

  queueReport = () => {
    this.props.queueReport(REPORT_ID,this.state.params);
  }

  reportForm = () => {
    return (
      <div>
        <Form layout="inline">
          <table style={{width:'100%'}}>
            <tbody>
              <tr style={{verticalAlign:'top'}}>
                <td>
                </td>
                <td style={{textAlign:'right'}}>
                  <Button onClick={this.queueReport}>Queue Report for Download</Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      </div>
    );
  }

  render() {
    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
          </div>

          <h3>Lifestance AR Carrier Aging</h3>

          { this.reportForm() }

          <p>This report cannot be displayed. It can only be downloaded.</p>
        </Spin>
      </div>
    )
  }


}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'reports.queue.add.processing',false)
    ) ? true : false,
    addQueue: safe(state,'reports.queue.add',{})
  }
}

export default connect(mapStateToProps,actions)(Form.create()(ReportsLS_ar_carrier_aging));
