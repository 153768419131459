import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
//import { Link } from 'react-router-dom';
import { Button, Table, Divider, Spin, Checkbox, Select } from 'antd';
import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/settings';

import { processEventNotification } from '../../../helpers/util';
import ReassignDialog from './Groups_/ReassignDialog';

class Groups extends Component {
  state = {
    dialogVisible: false,
    dialogTransferGroupId: null,
    dialogTransferFromUserId: null,
    dialogTransferFromUserName: null
  }

  componentDidMount() {
    this.props.getGroupsInfoList();
  }

  componentDidUpdate(prevProps) {
    processEventNotification(prevProps,this.props,'memberadd','Group Member Added');
    processEventNotification(prevProps,this.props,'memberdel','Group Member Deleted');
    processEventNotification(prevProps,this.props,'memberupdate','Group Member Updated');
    processEventNotification(prevProps,this.props,'groupAssign','Patients Assigned');
    processEventNotification(prevProps,this.props,'reassign','Patients Reassigned');
  }

  closeDialog = () => {
    this.setState({
      dialogVisible: false
    });
  }

  showDialog = (group_id,user_id,user_name) =>{
    this.setState({
      dialogVisible: true,
      dialogTransferGroupId: group_id,
      dialogTransferFromUserId: user_id,
      dialogTransferFromUserName: user_name
    });
  }

  deleteMember = (username,groupname,group_id,user_id) => {
    this.props.groupMemberDelete(group_id,user_id);
  }

  addGroupMember = (group_id,user_id) => {
    this.props.groupMemberAdd(group_id,user_id);
  }

  acceptAssignmentToggle = (group_id,user_id,newVal) => {
    this.props.groupMemberUpdate(group_id,user_id,{
      accept_assignment: newVal
    });
  }

  changeLocation = (group_id,user_id,newVal) => {
    this.props.groupMemberUpdate(group_id,user_id,{
      type: newVal
    });
  }

  assignMissing = (group_id) => {
    this.props.assignMissingGroupToPatientCareteam(group_id);
  }

  renderTable = (list,group_id) => {
    const columns = [{
      title: "Member Name",
      dataIndex: "name",
      key: "name",
      render: (val) => {
        return (!val) ? 'UNASSIGNED' : val;
      },
      sorter: (a, b) => {
        if (a && a.name) {
          if (b && b.name) {
            return a.name.localeCompare(b.name);
          }
        }
        return 1;
      },
      sortDirections: ['ascend'],
      defaultSortOrder: 'ascend',
    },{
      title:"Location",
      dataIndex: 'member_type',
      key: 'location',
      render: (value,record) => {
        if (!record.name) {
          return null;
        }
        return (
          <Select value={value} style={{width:'100px'}} onSelect={(e)=>this.changeLocation(group_id,record.user_id,e)}>
            <Select.Option value={1} key={1}>SF</Select.Option>
            <Select.Option value={2} key={2}>WC</Select.Option>
            <Select.Option value={3} key={3}>LA</Select.Option>
            <Select.Option value={0} key={0}>None</Select.Option>
          </Select>
        );
        //this.displayLocation(value);
      }
    },{
      title:'Accept Assignments',
      dataIndex: 'group_member_accept_assignment',
      key: 'group_member_accept_assignment',
      render: (value,record) => {
        if (!record.name) {
          return null;
        }
        return <Checkbox checked={value} onChange={(e)=>this.acceptAssignmentToggle(group_id,record.user_id,e.target.checked)}/>
      }
    },{
      title:"Open Charts",
      dataIndex:"open_charts",
      align: 'right',
      key:"open_charts",
    },{
      title:'Archived Charts',
      dataIndex: 'total_charts',
      align: 'right',
      key: 'total_charts',
      render: (value,record) => {
        return record.total_charts-record.open_charts;
      }
    },{
      title: 'Action',
      align: 'right',
      render: (value,record)=> {
        if (record.total_charts === 0) {
          return (
            <Button type='link' onClick={()=>{
              const group_name = (group_id === 1) ? 'Reception' : 'Billing'
              this.deleteMember(record.name,group_name,group_id,record.user_id)
            }}><DeleteOutlined style={{fontSize:'18px'}} /></Button>
          );
        } else {
          return <Button type='link' onClick={()=>{
            this.showDialog(group_id,record.user_id,record.name);
          }}>Reassign</Button>
        }
      }
    }];

    return (
      <div>
        <Table
          dataSource={list}
          rowKey="id"
          size='small'
          pagination={false}
          columns={columns}
          className='groups-table'
        />
      </div>
    );
  }

  renderHeading = (title,group_id) => {
    // Only want to add those users who are not in either list.
    // You can only be in one list.
    const userlist = this.props.userlist.filter(userToAdd=>{
      return (
        this.props.receptionList.find(member=>userToAdd.id === member.user_id) ||
        this.props.billingList.find(member=>userToAdd.id === member.user_id)
      ) ? false : true;
    });

    const missingField = (group_id === 1) ? this.props.receptionMissing : this.props.billingMissing;
    const groupName = (group_id === 1) ? 'Reception' : 'Billing'

    return (
      <div style={{paddingBottom:'20px'}}>
        <h3>{title}</h3>
        <table width='100%'>
          <tbody>
            <tr>
              <td>
                Add Group Member: <Select placeholder='Choose user to add' style={{width:'200px'}}
                  onSelect={(user_id)=>this.addGroupMember(group_id,user_id)}>
                  { userlist.map(x=><Select.Option value={x.id} key={x.id}>{x.name}</Select.Option>)}
                </Select>
              </td>
              <td style={{textAlign:'right'}}>
                { missingField > 0 && <Button type='primary' onClick={()=>{
                  this.assignMissing(group_id)
                }}>Click to add the &nbsp;{groupName}&nbsp; group to the careteams of &nbsp;<strong>{missingField}</strong>&nbsp;active patients for which it is missing</Button> }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          { this.renderHeading('Reception Group',1)}
          { this.renderTable(this.props.receptionList,1)}
          <Divider />
          { this.renderHeading('Billing Group',2)}
          { this.renderTable(this.props.billingList,2)}
        </Spin>
        <ReassignDialog
          visible={this.state.dialogVisible}
          closeDialog={this.closeDialog}
          transferGroupId={this.state.dialogTransferGroupId}
          transferFromUserId={this.state.dialogTransferFromUserId}
          transferFromUserName={this.state.dialogTransferFromUserName}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'settings.groups.info.processing',false),
      safe(state,'settings.groups.memberdel.processing',false),
      safe(state,'settings.groups.memberadd.processing',false),
      safe(state,'settings.groups.memberupdate.processing',false),
      safe(state,'settings.groups.reassign.processing',false),
      safe(state,'settings.careteam.groupassign.processing',false)
    ) ? true : false,
    receptionList: safe(state,'settings.groups.info.data.reception',[]),
    billingList: safe(state,'settings.groups.info.data.billing',[]),
    userlist: safe(state,'settings.groups.info.data.userlist',[]),
    memberdel: safe(state,'settings.groups.memberdel',{}),
    memberadd: safe(state,'settings.groups.memberadd',{}),
    memberupdate: safe(state,'settings.groups.memberupdate',{}),
    receptionMissing: safe(state,'settings.groups.info.data.reception_missing',0),
    billingMissing: safe(state,'settings.groups.info.data.billing_missing',0),
    groupAssign: safe(state,'settings.careteam.groupassign',{}),
    reassign: safe(state,'settings.groups.reassign',{})
  }
}

export default connect(mapStateToProps,actions)(Groups);
