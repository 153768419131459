import React, { Component } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Button, Input, Row, Col, message, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import * as emr_actions from '../../../../actions/emr';
import { safe } from '../../../../helpers/reduxSelectors';
//import { validIndex } from '../../../../helpers/js';

class AdminTimeNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: parseInt(this.props.match.params.patient_id,10)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.noteCreateEvent !== this.props.noteCreateEvent) {
      if (this.props.noteCreateEvent.success) {
        this.props.noteCreateClear();
        message.success("Admin time note saved");
        this.props.history.push('/emr/patients/'+this.state.patientId);
      } if (this.props.noteCreateEvent.error) {
        this.props.noteCreateClear();
        message.error("Unable to save admin time note");
      }
    }
  }

  cancelNote = () => {
    this.props.history.push('/emr/patients/'+this.state.patientId);
  }

  signNote = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.noteCreate(this.state.patientId,4,values);
      }
    });
  }

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const Option = Select.Option;

    return (
      <div style={{padding:'15px'}}>
        <h3 style={{paddingTop:'10px',paddingBottom:'20px'}}>Admin Time Note</h3>

        <Form layout={'vertical'} autoComplete="off">
          <Row>
            <Col span={8}>
              <FormItem label="Date">
                {getFieldDecorator('note_date', {
                  rules: [{ type: 'object', required: true, message: "Please enter a date for this note", whitespace: true }],
                  initialValue: moment(new Date())
                })(
                  <DatePicker format='MM/DD/YYYY' />
                )}
              </FormItem>
            </Col>

            <Col span={8}>
            </Col>

            <Col span={8}>
              <FormItem label="Time">
                {getFieldDecorator('time_amount', {
                  rules: [{ required: true, message: "Please select the amount of time spent" }],
                  initialValue: 15
                })(
                  <Select>
                    <Option value={15}>15 Minutes</Option>
                    <Option value={30}>30 Minutes</Option>
                    <Option value={45}>45 Minutes</Option>
                    <Option value={60}>60 Minutes</Option>
                  </Select>
                )}
              </FormItem>
          </Col>

          </Row>
          <FormItem label="Admin Time Note">
            {getFieldDecorator('general_note', {
              rules: [{ required: true, message: "Enter note." }],
            })(
              <TextArea rows={15} />
            )}
          </FormItem>
        </Form>

        <Row>
          <Col span={12}>
            <Button type="danger" icon={<DeleteOutlined />} onClick={this.cancelNote}>Discard Note</Button>
          </Col>
          <Col span={12} style={{textAlign:'right'}}>
            <Button type="primary" onClick={this.signNote}>Sign Note</Button>
          </Col>
        </Row>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    noteCreateEvent: safe(state,'emr.note.create',{})
  }
}

export default connect(mapStateToProps,emr_actions)(Form.create()(withRouter(AdminTimeNote)));
