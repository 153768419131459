import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, DatePicker, Select, Spin } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/emr';
import { processEventNotification } from '../../../../helpers/util';

class VacationCoverageEntry extends React.Component {
  state = {
    selected_provider_id: null
  }

  componentDidMount() {
    this.setState({
      selected_provider_id: this.props.provider_id
    });
  }

  componentDidUpdate(prevProps,prevState) {
    if (prevProps.provider_id !== this.props.provider_id) {
      this.setState({
        selected_provider_id: this.props.provider_id
      });
    }

    if (prevProps.visible === false && this.props.visible === true) {
      this.props.form.resetFields();
      this.props.getProvidersList();
      this.props.loadVacationCoverage(this.state.selected_provider_id);
      this.props.getCoverageProviders(this.state.selected_provider_id);
    }

    if (prevState.selected_provider_id !== this.state.selected_provider_id) {
      this.props.form.resetFields();
      this.props.loadVacationCoverage(this.state.selected_provider_id);
      this.props.getCoverageProviders(this.state.selected_provider_id);
    }

    processEventNotification(prevProps,this.props,'save_coverage','Coverage Saved');

    if (prevProps.save_coverage !== this.props.save_coverage && this.props.save_coverage.success) {
      this.props.getAdminCoverageList();
    }
  }

  onClickSave = () => {
    this.props.form.validateFields((err,values) => {
      if (!err) {
        this.props.saveVacationCoverage({
          covering_provider_id: values.covering_provider_id,
          covering_start_date: values.covering_dates[0],
          covering_end_date: values.covering_dates[1]
        },this.state.selected_provider_id);
        this.props.closeDialog();
      }
    });
  }

  onClickCancel = () => {
    this.props.closeDialog();
  }

  coverageForm = () => {
    const { getFieldDecorator } = this.props.form;
    const sd = safe(this.props,'load_coverage.covering_start_date');
    const ed = safe(this.props,'load_coverage.covering_end_date');

    return (
      <div style={{paddingTop:'20px'}}>
        <Form>
          <Form.Item label='Covering Provider'>
            {getFieldDecorator('covering_provider_id',{
              rules: [{required: true, message: 'Select a covering provider'}],
              initialValue: safe(this.props,'load_coverage.covering_provider_id')
            })(
              <Select>
                { this.props.provider_list.map(x=><Select.Option key={parseInt(x.id,10)} value={parseInt(x.id,10)}>{x.full_name}</Select.Option>) }
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Coverage Start and End Dates">
            {getFieldDecorator('covering_dates', {
              rules: [{ type: 'array', required: true, message: 'Please select dates' }],
              initialValue: (sd && ed) ? [moment(sd),moment(ed)] : null
            })(
              <DatePicker.RangePicker format='M/D/YYYY'/>
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }

  selectProvider = (value) => {
    this.setState({
      selected_provider_id: value
    });
  }

  render() {
    return (
      <Modal
        title='Vacation Coverage Entry'
        visible={this.props.visible}
        okText='Save'
        onOk={this.onClickSave}
        onCancel={this.onClickCancel}
      >
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>Provider on Vacation:</div>
          <Select
            style={{width:'100%'}}
            value={this.state.selected_provider_id}
            onChange={this.selectProvider}
            placeholder='Choose a provider'
          >
            { this.props.full_provider_list.map(x=>(
              <Select.Option value={x.id} key={x.id}>{x.full_name}</Select.Option>
            )) }
          </Select>
          { this.state.selected_provider_id && this.coverageForm() }
        </Spin>
      </Modal>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'emr.providers.list.processing',false) ||
      safe(state,'emr.coverage.list.processing',false) ||
      safe(state,'emr.coverage.load.processing',false) ||
      safe(state,'emr.coverage.save.processing',false)
    ) ? true : false,
    activeUserId: safe(state,'shared.domain.activeUser.id'),
    provider_list: safe(state,'emr.coverage.list.data',[]),
    full_provider_list: safe(state,'emr.providers.list.data',[]),
    load_coverage: safe(state,'emr.coverage.load.data',{}),
    save_coverage: safe(state,'emr.coverage.save'),
  }
}

export default connect(mapStateToProps,actions)(Form.create()(VacationCoverageEntry));
