import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table, Spin, Select } from 'antd';
import numeral from 'numeral';

import { safe } from '../../../helpers/reduxSelectors';
import { credentialToString } from '../../../helpers/credential';
import * as actions from '../../../actions/reports';

class ReportsHourlyEmployees extends React.Component {
  state = {
    payperiods: [],
    selection: undefined
  }

  componentDidMount() {
    const pp = this.getPayperiodsList();
    this.setState({
      payperiods: pp,
      selection: (pp && pp.length > 0) ? 0 : undefined
    });
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.state.selection !== undefined && prevState.selection !== this.state.selection) {
      const item = this.state.payperiods[this.state.selection];
      this.props.reportsGetHourlyEmployees(item.start.format('YYYY-MM-DD'),item.end.format('YYYY-MM-DD'));
    }
  }

  getPayperiodsList = () => {
    let start = moment('2020-03-02');
    let end = moment(start).add(13,'days');
    const list = [];
    while (moment().isSameOrAfter(end,'days')) {
      list.push({ start, end});
      start = moment(start).add(14,'days');
      end = moment(end).add(14,'days');
    }
    return list;
  }

  onSelectChange = (val) => {
    this.setState({
      selection: val
    });
  }

  reportForm = () => {
    return (
      <div style={{paddingBottom:'20px'}}>
        Pay Period: <Select onChange={this.onSelectChange} value={this.state.selection} style={{width:'250px'}}>
          { this.state.payperiods.map((x,i)=>(
              <Select.Option key={i} value={i}>
                {x.start.format('MM/DD/YYYY')}-{x.end.format('MM/DD/YYYY')}
              </Select.Option>
          )) }
        </Select>
      </div>
    );
  }

  render() {
    const item = this.state.payperiods[this.state.selection];

    const columns = [{
      title: 'Provider',
      dataIndex: 'provider',
    },{
      title: 'Pay Period Start',
      key: 'pps',
      render: (val) => moment(item.start).format('MM/DD/YYYY')
    },{
      title: 'Pay Period End',
      key: 'ppe',
      render: (val) => moment(item.end).format('MM/DD/YYYY')
    },{
      title: 'Type',
      dataIndex: 'credential',
      render: (val) => credentialToString(val)
    },{
      title: 'Location',
      dataIndex: 'location'
    },{
      title: 'Total Hours Worked',
      dataIndex: 'total_hrs_worked',
      render: (val) => numeral(val).format('0.00')
    }];

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
          </div>

          <h3>Hourly Provider Payroll</h3>
          { this.reportForm() }
          <Table
            className='reports-sessionlist-table'
            columns={columns}
            dataSource={this.props.list}
            bordered={true}
            size='small'
            rowKey='provider_id'
            scroll={{ x:true }}
            pagination={false}
          />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'reports.hourlyemployees.processing',false),
    list: safe(state,'reports.hourlyemployees.data',[]),
  }
}

export default connect(mapStateToProps,actions)(ReportsHourlyEmployees);
