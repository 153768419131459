import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, Tag } from 'antd';
import moment from 'moment';

import { safe } from '../../../../../helpers/reduxSelectors';
import * as actions from '../../../../../actions/emr';
import Time from '../../../../shared/Time'
import DeleteSessionModal from './DeleteSessionModal';

const diffHrs = Time.getTzOffset();

class SessionOperations extends React.Component {
  state = {
    deleteModalVisible: false
  }

  static propTypes = {
    patient_id: PropTypes.number,
    session_id: PropTypes.number,
    switchPane: PropTypes.func,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.appt_remove_event.success !== this.props.appt_remove_event.success &&
      this.props.appt_remove_event.success) {
      //this.props.getSessionHistoryAndFuture(this.props.patient_id);
      this.props.switchPane(this.props.patient_id,0,1);
    }

    if (prevProps.appt_update_event.success !== this.props.appt_update_event.success &&
      this.props.appt_update_event.success) {
        //this.props.getSessionHistoryAndFuture(this.props.patient_id);
      this.props.switchPane(this.props.patient_id,0,1);
    }

    if (prevProps.appt_latecancel_event.success !== this.props.appt_latecancel_event.success &&
      this.props.appt_latecancel_event.success) {
      //this.props.getSessionHistoryAndFuture(this.props.patient_id);
      this.props.switchPane(this.props.patient_id,0,1);
    }

  }

  handleCancel = () => {
    this.props.switchPane(this.props.patient_id,0,1);
  }

  handleReschedule = () => {
    this.props.switchPane(this.props.patient_id,this.props.session_id,3);
  }

  handleChangeToVideo = (data) => {
    const This = this;

    const provider_id = safe(data,'user.id');

    Modal.confirm({
      title: 'Change to a Video Session?',
      okText: 'Yes',
      onOk() {
        This.props.updateAppt(provider_id,This.props.session_id,1,{ telemedicine: 1 });
      },
      onCancel() {},
    });
  }

  handleChangeToInOffice = (data) => {
    const This = this;

    const provider_id = safe(data,'user.id');

    Modal.confirm({
      title: 'Change to a In-Office Session @'+safe(data,'location.name') + '?',
      okText: 'Yes',
      onOk() {
        This.props.updateAppt(provider_id,This.props.session_id,1,{ telemedicine: 0 });
      },
      onCancel() {},
    });
  }

  renderRepeat = () => {
    const repeat = safe(this.props,'data.repeat',false);
    const repeat_index = safe(this.props,'data.repeat_index',0);
    const repeat_frequency = safe(this.props,'data.recurring.frequency',0);
    const repeat_type = safe(this.props,'data.recurring.type',0);
    const repeat_end_date = safe(this.props,'data.recurring.end_date',null);
    const repeat_num_sessions = safe(this.props,'data.recurring.num_sessions',0);

    if (repeat) {
      if (repeat_type === 1 && repeat_end_date) {
        return (
          <div><strong>Repeats:</strong> Every {repeat_frequency} week(s) until {moment(repeat_end_date).format('M/D/YYYY')}</div>
        );
      } else if (repeat_type === 2) {
        return (
          <div><strong>Repeats:</strong> Every {repeat_frequency} week(s) for {repeat_num_sessions} times. This is session #: {repeat_index}</div>
        );
      }
    }

    return null;
  }

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true
    });
  }

  closeDeleteModal = () => {
    this.setState({
      deleteModalVisible: false
    });
  }

  render() {
    const can_cancel = safe(this.props,'data.can_cancel',true);
    const telemedicine = safe(this.props,'data.telemedicine',true);
    
    const sd = safe(this.props,'data.session_date');
    const st = safe(this.props,'data.start_time',0);
    const { session_date, session_date_local, time, local_time } = Time.fixDateTime(sd, st);

    return (
      <div style={{padding:'10px'}}>
        <div>
          <h3>Appointment Details</h3>
          <div><strong>Patient:</strong> {safe(this.props,'data.patient.full_name')}</div>
          <div>
            <strong>Date:</strong>&nbsp;
            {moment(sd).format('ddd, M/D/YYYY')}
            {diffHrs ? (
              <>
                <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
                {st ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {session_date_local.format('MM/DD/YYYY')}</Tag> : null}
              </>
            ) : null}
          </div>
          <div>
            <strong>Time:</strong>&nbsp;
            {(new Time(st)).toString(false)}
            {diffHrs ? (
              <>
                <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
                {local_time ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {(local_time)?.toString(false)}</Tag> : null}
              </>
            ) : null}
          </div>
          <div><strong>Type:</strong> {(safe(this.props,'data.session_type') === 1) ? 'Eval' : 'Followup'}</div>
          <div><strong>Duration:</strong> {safe(this.props,'data.duration')}</div>
          <div><strong>Provider:</strong> {safe(this.props,'data.user.full_name')}</div>
          <div><strong>Location:</strong> {safe(this.props,'data.location.name')}</div>
          <div><strong>Telemedicine:</strong> {(safe(this.props,'data.telemedicine')) ? 'Yes' : 'No'}</div>
          { this.renderRepeat() }
          <div style={{paddingTop:'10px'}}>
            {!can_cancel && <div style={{fontStyle:'italic',color:'red'}}>This
            appointment is less than 48 business hours from now. Cancelling it
            will result in the patient being charged a late cancellation fee.</div>}

            {!can_cancel && !telemedicine && <div style={{fontStyle:'italic',color:'red',paddingTop:'10px'}}>Consider
              switching the appointment to a video appointment to avoid late
              cancellation fees.</div>}
          </div>
        </div>

        <table style={{width:'100%',marginTop:'20px'}}>
          <tbody>
            <tr>
              <td style={{textAlign:'left'}}>
                <Button type='primary' onClick={()=>{this.handleReschedule(this.props.data)}}>
                  Reschedule
                </Button>
              </td>
              <td style={{textAlign:'center'}}>
                {!telemedicine && <Button type='primary' onClick={()=>{this.handleChangeToVideo(this.props.data)}}>
                  Change to Video
                </Button> }
              </td>
              <td style={{textAlign:'right'}}>
                <Button type='danger' onClick={()=>{this.showDeleteModal()}}>
                  Delete
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{paddingTop:'50px',textAlign:'right'}}>
          <Button onClick={this.handleCancel}>
            Cancel
          </Button>
        </div>

        <DeleteSessionModal
          visible={this.state.deleteModalVisible}
          onClose={this.closeDeleteModal}
          data={this.props.data}
        />

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    appt_remove_event: safe(state,'emr.appt.remove',{}),
    appt_latecancel_event: safe(state,'emr.appt.latecancel',{}),
    appt_update_event: safe(state,'emr.appt.update',{}),
  }
}

export default connect(mapStateToProps,actions)(SessionOperations);
