/* eslint-disable react/display-name */
import { useState } from 'react';
import { Table } from 'antd';

interface PropsI {
  columns: Array<Record<string, unknown>>;
  details: any;
  customPageSize?: number;
  pagination?: boolean;
}

const DetailsTable: React.FC<PropsI> = (props: PropsI) => {
  const { columns, details, customPageSize, pagination = true } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(customPageSize || 5);

  const onTableChange = (pg) => {
    const page = pg.current;
    const pageSize = pg.pageSize;
    setPage(page);
    setPageSize(pageSize);
  };

  const paginationProps =
    pagination && details.length > 20
      ? {
          showQuickJumper: true,
          current: page,
          pageSize,
          total: details.length,
        }
      : false;

  return (
    <div className="mt2">
      <Table
        columns={columns}
        dataSource={details}
        bordered={true}
        size="small"
        rowKey="id"
        pagination={paginationProps}
        onChange={onTableChange}
      />
    </div>
  );
};

export default DetailsTable;
