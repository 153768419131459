/**
 * ReportWrapper.tsx
 *
 * A wrapper for every report to keep them all consistent
 * with a back button and the report popover selector.
 */
import { useContextSelector } from 'use-context-selector';
import SelectReportPopover from './SelectReportPopover';
import { Spin } from 'antd';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import { Link } from 'react-router-dom';

interface PropsI {
  children: React.ReactNode;
}

const ReportWrapper: React.FC<PropsI> = (props: PropsI): any => {
  const runReportAPI = useContextSelector(InlineContext, (c) => c.state.runReportAPI);

  return (
    <Spin spinning={runReportAPI?.loading}>
      <div className="p4">
        <SelectReportPopover />
        <div className="pb4">
          <Link to="/reports">&lt;&lt; Back to list of reports</Link>
        </div>
        {props.children}
      </div>
    </Spin>
  );
};

export default ReportWrapper;
