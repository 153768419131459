import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, DatePicker, Radio, Tag } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import * as actions from '../../../../../../actions/emr';
import { getSessionStartTimes, availableTypes } from '../../cal';
import { safe } from '../../../../../../helpers/reduxSelectors';
import PatientFormSelector from '../../../../../shared/PatientFormSelector';
import { therapist60minUpdate_date } from '../../../../../Params';
import Time from '../../../../../shared/Time';

const diffHrs = Time.getTzOffset();

class Session extends React.Component {

  componentDidMount() {
    this.props.getProviderLocations(this.props.provider_id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.form.resetFields();
      this.props.getProviderLocations(this.props.provider_id);
    }

    if (this.props.createButtonPressed &&
        prevProps.createButtonPressed !== this.props.createButtonPressed) {
      this.create();
    }
  }

  create = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.createSession(this.props.provider_id,1,this.props.calendar_date,{
          ...values,
          type: 1, // A session
          verify: true   // Requires the server to first check if space is empty
        });
        this.props.onClose();
      }
    });
  }

  getTimeRange = () => {
    return getSessionStartTimes(
      this.props.openItem,
      this.props.form.getFieldValue('duration'),
      safe(this.props,'provider_roles',[]).includes('prescriber'),
      this.props.form.getFieldValue('session_type'),
      this.props.intake_duration
    );
  }

  onChangeSessionType = () => {
    this.props.form.setFieldsValue({
      duration: undefined
    });
  }

  onChangeDuration = () => {
    this.props.form.setFieldsValue({

    });
  }

  render() {
    if (!this.props.openItem || !this.props.visible) {
      return null;
    }

    const FormItem = Form.Item;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };

    const { allow_video, location_id } = this.props.openItem;
    const location_name = safe(this.props.locationsList.find(x=>x.id === location_id),'name');
    const isPrescriber = safe(this.props,'provider_roles',[]).includes('prescriber');
    const { allow_20m, allow_30m, allow_45m, allow_60m, allow_intake, duration } = this.props.openItem;
    const intake_duration = (this.props.intake_duration) ? this.props.intake_duration : 60;
    const at = availableTypes(duration,allow_20m,allow_30m,allow_45m,allow_60m,
      allow_intake,intake_duration,isPrescriber);

    const session_types = [];
    if ( at.allow_20m || at.allow_30m || at.allow_45m || at.allow_60m) {
      session_types.push({
        id: 2,
        name: 'Followup'
      });
    }
    if (at.allow_intake) {
      session_types.push({
        id: 1,
        name: 'Eval'
      });
    }

    const durations = [];
    switch (this.props.form.getFieldValue('session_type')) {
      case 1: // intakes
        if (at.allow_intake) {
          durations.push(intake_duration);
        }
        break;
      case 2: // followup
        if (at.allow_20m) {
          durations.push(20);
        }
        if (at.allow_30m) {
          durations.push(30);
        }
        if (at.allow_45m) {
          durations.push(45);
        }
        if (at.allow_60m) {
          durations.push(60);
        }
        break;
      default:
    }

    const sd = this.props.form.getFieldValue('session_date');
    const st = this.props.form.getFieldValue('start_time');
    const { session_date, session_date_local } = Time.fixDateTime(sd, st);

    return (
      <Form className='compressed-form' autoComplete='false'>

        <FormItem {...formItemLayout} label="Provider">
          {getFieldDecorator('provider_id', {
            initialValue: this.props.provider_id
          })(
            <span>{this.props.provider_name}</span>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Session Date">
          {getFieldDecorator('session_date', {
            initialValue: moment(safe(this.props,'openItem.session_date')).tz('America/Los_Angeles')
          })(
            <span>{moment(safe(this.props,'openItem.session_date')).format('M/D/YYYY')}</span>
          )}
          <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
          {diffHrs && st ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {session_date_local.format('MM/DD/YYYY')}</Tag> : null}
        </FormItem>

        <FormItem {...formItemLayout} label="Location">
          {getFieldDecorator('location_id', {
            initialValue: location_id
          })(
            <span>{location_name}</span>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Patient">
          {getFieldDecorator('patient_id', {
            rules: [{ required: true, message: " " }],
          })(
            <PatientFormSelector />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Type">
          {getFieldDecorator('session_type', {
            rules: [{ required: true, message: " " }],
            initialValue: (session_types.length > 0) ? session_types[0].id : undefined
          })(
            <Select disabled={session_types.length === 0} onChange={()=>this.onChangeSessionType()}>
              { session_types.map(x=><Option key={'ST'+x.id} value={x.id}>{x.name}</Option>)}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Duration">
          {getFieldDecorator('duration', {
            rules: [{ required: true, message: " " }],
            initialValue: (durations.length > 0) ? durations[0] : undefined
          })(
            <Select disabled={durations.length === 0}>
              {durations.map(x=><Option value={x} key={'d'+x}>{x} mins</Option>)}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Telemedicine">
          {getFieldDecorator('telemedicine', {
            rules: [{ required: true, message: " " }],
            initialValue: (allow_video === 0 || allow_video === 2) ? 0 : 1
          })(
            <Select>
              {(allow_video === 0 || allow_video === 1) && <Option value={1}>Yes</Option> }
              {(allow_video === 0 || allow_video === 2) && <Option value={0}>No</Option> }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Starting Time">
          {getFieldDecorator('start_time', {
            rules: [{ required: true, message: " " }],
          })(
            <Select>
              { this.getTimeRange().map((x) => {
                let time = `${x.name} ${Time.getPSTPDT()}`;
                if (diffHrs) {
                  time = `${time} - (${x.local} Local)`;
                }
                return <Option key={x.id} value={x.id}>{time}</Option>;
              }) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Repeats">
          {getFieldDecorator('repeat', {
            rules: [{ required: true, message: " " }],
            initialValue: false
          })(
            <Radio.Group>
              <Radio value={false}>Doesn't Repeat</Radio>
              <Radio value={true}>Repeats Weekly</Radio>
            </Radio.Group>
          )}
        </FormItem>

        { this.props.form.getFieldValue('repeat') &&
          <FormItem {...formItemLayout} label="Repeats Every">
            {getFieldDecorator('repeat_frequency', {
              rules: [{ required: true, message: " " }],
              initialValue: 1
            })(
              <Select>
                <Option value={1}>1 Week</Option>
                <Option value={2}>2 Weeks</Option>
                <Option value={3}>3 Weeks</Option>
                <Option value={4}>4 Weeks</Option>
                <Option value={5}>5 Weeks</Option>
                <Option value={6}>6 Weeks</Option>
                <Option value={7}>7 Weeks</Option>
                <Option value={8}>8 Weeks</Option>
              </Select>
            )}
          </FormItem>
        }

        { this.props.form.getFieldValue('repeat') &&
          <FormItem {...formItemLayout} label="Repeat Type">
            {getFieldDecorator('repeat_type', {
              rules: [{ required: true, message: " " }],
              initialValue: 1
            })(
              <Select>
                <Option value={1}>Until an End Date</Option>
                <Option value={2}>For # of Sessions</Option>
              </Select>
            )}
          </FormItem>
        }

        { this.props.form.getFieldValue('repeat') &&
          this.props.form.getFieldValue('repeat_type') === 2 &&
          <FormItem {...formItemLayout} label="Repeats For">
            {getFieldDecorator('repeat_num_sessions', {
              rules: [{ required: true, message: " " }],
              initialValue: 30
            })(
              <Select>
                <Option value={5}>5 Sessions</Option>
                <Option value={6}>6 Sessions</Option>
                <Option value={7}>7 Sessions</Option>
                <Option value={8}>8 Sessions</Option>
                <Option value={9}>9 Sessions</Option>
                <Option value={10}>10 Sessions</Option>
                <Option value={11}>11 Sessions</Option>
                <Option value={12}>12 Sessions</Option>
                <Option value={13}>13 Sessions</Option>
                <Option value={14}>14 Sessions</Option>
                <Option value={15}>15 Sessions</Option>
                <Option value={16}>16 Sessions</Option>
                <Option value={17}>17 Sessions</Option>
                <Option value={18}>18 Sessions</Option>
                <Option value={19}>19 Sessions</Option>
                <Option value={20}>20 Sessions</Option>
                <Option value={21}>21 Sessions</Option>
                <Option value={22}>22 Sessions</Option>
                <Option value={23}>23 Sessions</Option>
                <Option value={24}>24 Sessions</Option>
                <Option value={25}>25 Sessions</Option>
                <Option value={26}>26 Sessions</Option>
                <Option value={27}>27 Sessions</Option>
                <Option value={28}>28 Sessions</Option>
                <Option value={29}>29 Sessions</Option>
                <Option value={30}>30 Sessions</Option>
              </Select>
            )}
          </FormItem>
        }

        { this.props.form.getFieldValue('repeat') &&
          this.props.form.getFieldValue('repeat_type') === 1 &&
          <FormItem {...formItemLayout} label="Repeats Until">
            {getFieldDecorator('repeat_end_date', {
              rules: [{ required: true, message: " " }],
              initialValue: moment(moment().format('YYYY')+'-12-31')
            })(
              <DatePicker format='M/D/YYYY'/>
            )}
          </FormItem>
        }

      </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    locationsList: safe(state,'emr.provider.locations.data',[]),
    calendar_date: safe(state,'emr.emr.active_date',moment().format('YYYY-MM-DD')),
    intake_duration: safe(state,'emr.emr.calendarProvider.intake_duration',60),
  }
}

export default connect(mapStateToProps,actions)(Form.create()(Session));
