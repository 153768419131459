import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Spin,
  Modal,
  message,
  Select,
  DatePicker,
  Divider,
  Input,
  Radio,
  InputNumber,
  Col,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import * as billing_actions from '../../../../../../actions/billing';
import * as shared_actions from '../../../../../../actions/shared';
import { traditionalGenders,map2TraditionalGenders } from '../../../../../../helpers/genders';
import { usStateAbbrOptions } from '../../../../../../helpers/states';
import { safe } from '../../../../../../helpers/reduxSelectors';


function Required(props) {
  return (
    <span>
      <span style={{color:'#f5222d'}}>*</span>&nbsp;
      {props.children}
    </span>
  );
}

class PolicyEntryDialog extends React.Component {

  componentDidMount() {
    this.props.form.resetFields();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true) {
      this.props.form.resetFields();
      this.props.getCarrierList();
      this.props.getTpaList();
      this.props.getBillingPolicyClear();
      if (this.props.billingPolicyId !== 0) {
        this.props.getBillingPolicy(this.props.billingPatientId,this.props.billingPolicyId);
      }
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.error) {
        message.error(this.props.save.error);
      }
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.error) {
        message.error(this.props.create.error);
      }
    }
  }

  handleOk = (e) => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.props.billingPolicyId === 0) {
          this.props.createBillingPolicy(this.props.billingPatientId,values);
        } else {
          this.props.saveBillingPolicy(this.props.billingPatientId,this.props.billingPolicyId,values);
        }
        this.props.hideDialog();
      }
    });

  }

  handleCancel = (e) => {
    this.props.hideDialog();
  }

  renderSubscriberForm = () => {
    const formItemLayout = {
      labelCol: {
        xs: { span: 1 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 22 },
        sm: { span: 17 },
      },
    };
    const data = this.props.billingpolicy;

    const { getFieldDecorator } = this.props.form;

    const patient_r2i = this.props.form.getFieldValue('patient_r2i');

    if (!patient_r2i || patient_r2i === 1) {
      return null;
    }

    return (
      <div>
        <Divider>Subscriber Information</Divider>

        <Form.Item
          label='Subscriber Name'
          {...formItemLayout}
        >
          <Col span={9}>
            <Form.Item>
              {getFieldDecorator('subscriber_firstname', {
                rules: [{ required: true, message: " ", whitespace: true }],
                initialValue: data.subscriber_firstname
              })(
                <Input placeholder='First' />
              )}
            </Form.Item>
          </Col>
          <Col span={1}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
            &nbsp;
            </span>
          </Col>
          <Col span={9}>
            <Form.Item>
              {getFieldDecorator('subscriber_lastname', {
                rules: [{ required: true, message: " ", whitespace: true }],
                initialValue: data.subscriber_lastname
              })(
                <Input placeholder='Last'/>
              )}
            </Form.Item>
          </Col>
          <Col span={1}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
            &nbsp;
            </span>
          </Col>
          <Col span={4}>
            <Form.Item>
              {getFieldDecorator('subscriber_middlename', {
                initialValue: data.subscriber_middlename
              })(
                <Input placeholder='Middle'/>
              )}
            </Form.Item>
          </Col>
        </Form.Item>

        <Form.Item {...formItemLayout} label='Date of Birth'>
          {getFieldDecorator('subscriber_dob', {
            initialValue: (data.subscriber_dob) ? moment(data.subscriber_dob) : null
          })(
            <DatePicker format='M/D/YYYY' />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Gender"
        >
          {getFieldDecorator('subscriber_gender', {
            initialValue: data.subscriber_gender
          })(
            <Select>
              { traditionalGenders.map(a=>(<Select.Option key={a.id} value={a.id}>{a.text}</Select.Option>)) }
            </Select>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label='Address 1'
        >
          {getFieldDecorator('subscriber_address', {
            initialValue: data.subscriber_address
          })(
            <Input autoComplete="off"/>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label='Address 2'
        >
          {getFieldDecorator('subscriber_address2', {
            initialValue: data.subscriber_address2
          })(
            <Input autoComplete="off"/>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="City"
        >
          <Col span={13}>
            <Form.Item>
              {getFieldDecorator('subscriber_city', {
                initialValue: data.subscriber_city
              })(
                <Input autoComplete="off"/>
              )}
            </Form.Item>
          </Col>
          <Col span={1}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
            &nbsp;
            </span>
          </Col>
          <Col span={4}>
            <Form.Item>
              {getFieldDecorator('subscriber_state', {
                initialValue: (data.subscriber_state) ?  data.subscriber_state : 'CA'
              })(
                <Select autoComplete="off">
                  {usStateAbbrOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={1}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
            &nbsp;
            </span>
          </Col>
          <Col span={5}>
            <Form.Item>
              {getFieldDecorator('subscriber_zip', {
                initialValue: data.subscriber_zip
              })(
                <Input autoComplete="off"/>
              )}
            </Form.Item>
          </Col>
        </Form.Item>
      </div>
    );
  }

  getInitial(s) {
    if (!s || s.length < 1) {
      return null;
    }
    return s.charAt(0);
  }

  concatAddresses(a,b) {
    if (a && a.length > 0) {
      if (b && b.length > 0) {
        return a + ' ' + b;
      } else {
        return a;
      }
    }
    return null;
  }

  renderInsForm = () => {
    const formItemLayout = {
      labelCol: {
        xs: { span: 1 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 22 },
        sm: { span: 17 },
      },
    };

    const data = this.props.billingpolicy;
    let defaultPatientData = {};
    if (this.props.billingPolicyId === 0) {
      defaultPatientData = {
        firstname: safe(this.props,'patientData.firstname'),
        lastname: safe(this.props,'patientData.lastname'),
        middlename: this.getInitial(safe(this.props,'patientData.middlename')),
        dob: safe(this.props,'patientData.dob'),
        gender: map2TraditionalGenders[safe(this.props,'patientData.gender')],
        address: safe(this.props,'patientData.address1',''),
        address2: safe(this.props,'patientData.address2',''),
        city: safe(this.props,'patientData.city'),
        state: safe(this.props,'patientData.state'),
        zip: safe(this.props,'patientData.zip')
      }
    }

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Form.Item {...formItemLayout} label='Member ID'>
          {getFieldDecorator('policy_id_num', {
            rules: [{ required: true, message: " " }],
            initialValue: data.policy_id_num
          })(
            <Input/>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Plan ID'>
          {getFieldDecorator('plan_id', {
            initialValue: data.plan_id
          })(
            <Input/>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Group ID'>
          {getFieldDecorator('group_id', {
            initialValue: data.group_id
          })(
            <Input/>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Copay'>
          {getFieldDecorator('copay', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(data,'copay',0.0)
          })(
            <InputNumber min={0} max={500} precision={2}/>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Coinsurance'>
          {getFieldDecorator('coinsurance', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(data,'coinsurance',0)
          })(
            <InputNumber
              min={0}
              max={100}
              formatter={value => `${value}%`}
              parser={value => value.replace('%', '')}
            />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Carrier Phone'>
          {getFieldDecorator('carrier_phone', {
            initialValue: data.carrier_phone
          })(
            <NumberFormat customInput={Input} format="(###) ###-####" mask="_"/>
          )}
        </Form.Item>

        <Divider>Patient Information</Divider>

        <Form.Item {...formItemLayout} label='Primary Insured'>
          {getFieldDecorator('patient_r2i', {
            rules: [{ required: true, message: " "}],
            initialValue: safe(data,'patient_r2i',1)
          })(
          <Radio.Group>
            <Radio value={1}>Patient (Self)</Radio>
            <Radio value={2}>Spouse</Radio>
            <Radio value={3}>Parents</Radio>
            <Radio value={4}>Other</Radio>
          </Radio.Group>
          )}
        </Form.Item>

        <Form.Item
          label={<Required>Patient Name</Required>}
          {...formItemLayout}
        >
          <Col span={9}>
            <Form.Item>
              {getFieldDecorator('patient_firstname', {
                rules: [{ required: true, message: " ", whitespace: true }],
                initialValue: (data.patient_firstname) ? data.patient_firstname : defaultPatientData.firstname
              })(
                <Input placeholder='First' />
              )}
            </Form.Item>
          </Col>
          <Col span={1}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
            &nbsp;
            </span>
          </Col>
          <Col span={9}>
            <Form.Item>
              {getFieldDecorator('patient_lastname', {
                rules: [{ required: true, message: " ", whitespace: true }],
                initialValue: (data.patient_lastname) ? data.patient_lastname : defaultPatientData.lastname
              })(
                <Input placeholder='Last'/>
              )}
            </Form.Item>
          </Col>
          <Col span={1}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
            &nbsp;
            </span>
          </Col>
          <Col span={4}>
            <Form.Item>
              {getFieldDecorator('patient_middlename', {
                initialValue: (data.patient_middlename) ? data.patient_middlename : defaultPatientData.middlename,
                rules: [{len: 1, message: " "}]
              })(
                <Input placeholder='Middle'/>
              )}
            </Form.Item>
          </Col>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Date of Birth">
          {getFieldDecorator('patient_dob', {
            rules: [{ required: true, message: " " }],
            initialValue: (data.patient_dob) ? moment(data.patient_dob) : moment(defaultPatientData.dob)
          })(
            <DatePicker format='M/D/YYYY' />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Gender"
        >
          {getFieldDecorator('patient_gender', {
            initialValue: (data.patient_gender) ? data.patient_gender : defaultPatientData.gender
          })(
            <Select>
              { traditionalGenders.map(a=>(<Select.Option key={a.id} value={a.id}>{a.text}</Select.Option>)) }
            </Select>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          extra={undefined}
          label="Address 1"
        >
          {getFieldDecorator('patient_address', {
            initialValue: (data.patient_address) ? data.patient_address : defaultPatientData.address
          })(
            <Input autoComplete="off"/>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          extra={undefined}
          label="Address 2"
        >
          {getFieldDecorator('patient_address2', {
            initialValue: (data.patient_address2) ? data.patient_address2 : defaultPatientData.address2
          })(
            <Input autoComplete="off"/>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="City"
        >
          <Col span={13}>
            <Form.Item>
              {getFieldDecorator('patient_city', {
                initialValue: (data.patient_city) ? data.patient_city : defaultPatientData.city
              })(
                <Input autoComplete="off"/>
              )}
            </Form.Item>
          </Col>
          <Col span={1}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
            &nbsp;
            </span>
          </Col>
          <Col span={4}>
            <Form.Item>
              {getFieldDecorator('patient_state', {
                initialValue: (data.patient_state) ?  data.patient_state : ( (defaultPatientData.state) ? defaultPatientData.state : 'CA' )
              })(
                <Select autoComplete="off">
                  {usStateAbbrOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={1}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
            &nbsp;
            </span>
          </Col>
          <Col span={5}>
            <Form.Item>
              {getFieldDecorator('patient_zip', {
                initialValue: (data.patient_zip) ? data.patient_zip : defaultPatientData.zip
              })(
                <Input autoComplete="off"/>
              )}
            </Form.Item>
          </Col>
        </Form.Item>

        { this.renderSubscriberForm() }
      </div>
    );

  }

  renderSelfPayForm = () => {
    return (
      <div style={{paddingLeft:'100px',paddingTop:'30px'}}>
        <strong>Group default self-pay rates will be used</strong>
      </div>
    )
  }

  renderPolicyForm = () => {
    const carrier_id = this.props.form.getFieldValue('carrier_id');
    const carrierRecord = this.props.carrierList.find(x=>x.id===carrier_id);

    if (!carrierRecord) {
      return null;
    }

    if (carrierRecord.selfpay) {
      return this.renderSelfPayForm();
    } else {
      return this.renderInsForm();
    }
  }

  carrierChanged = () => {
    this.props.form.setFieldsValue({
      tpa_id: null
    });
  }

  includeTPAField = () => {
    const carrier_id = this.props.form.getFieldValue('carrier_id');
    if (!carrier_id) {
      return false;
    }
    const item = this.props.carrierList.find(x=>x.id===carrier_id);
    return (item && !item.selfpay) ? true : false;
  }

  renderForm = () => {
    const formItemLayout = {
      labelCol: {
        xs: { span: 1 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 22 },
        sm: { span: 17 },
      },
    };

    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;

    const carrierOptions = this.props.carrierList.map(a =>
      <Option key={a.id} value={a.id}>{a.name}</Option>
    );

    const cid = this.props.form.getFieldValue('carrier_id') || this.props.billingpolicy.carrier_id;
    const tpaOptions = this.props.tpaList.filter(x=>x.carrier_id === cid).map(a =>
      <Option key={a.id} value={a.id}>{a.name}</Option>
    );
    tpaOptions.unshift(<Option key={0} value={null}>None</Option>);

    return (
      <div>
        <Form autoComplete="off" className='policy-form'>

          <FormItem {...formItemLayout} label='Insurance'>
            {getFieldDecorator('carrier_id', {
              rules: [{ required: true, message: " " }],
              initialValue: this.props.billingpolicy.carrier_id
            })(
              <Select placeholder='Select insurance carrier or self pay' onChange={this.carrierChanged}>
                {carrierOptions}
              </Select>
            )}
          </FormItem>

          { this.includeTPAField() &&
            <FormItem {...formItemLayout} label='TPA'>
              {getFieldDecorator('tpa_id', {
                initialValue: (this.props.billingpolicy.tpa_id || null)
              })(
                <Select placeholder='Select a TPA or None'>
                  {tpaOptions}
                </Select>
              )}
            </FormItem>
          }

          <Form.Item
            {...formItemLayout}
            label={<Required>Active Dates</Required>}
          >
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('start_date', {
                  initialValue: (this.props.billingpolicy.start_date) ? moment(this.props.billingpolicy.start_date) : moment()
                })(
                  <DatePicker placeholder='Starting Date' format='M/DD/YYYY' autoComplete="off"/>
                )}
              </Form.Item>
            </Col>
            <Col span={1}>
              <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
              &nbsp;-&nbsp;
              </span>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('end_date', {
                  initialValue: (this.props.billingpolicy.end_date) ? moment(this.props.billingpolicy.end_date) : moment('2100-01-01')
                })(
                  <DatePicker placeholder='Ending Date' format='M/DD/YYYY' autoComplete="off"/>
                )}
              </Form.Item>
            </Col>

          </Form.Item>

          { this.renderPolicyForm() }

        </Form>
      </div>
    );
  }

  render() {
    return (
      <Modal
        title="Payment Policy"
        visible={this.props.visible}
        destroyOnClose={true}
        onOk={this.handleOk}
        okText='Save'
        onCancel={this.handleCancel}
        width={600}
        bodyStyle={{paddingLeft:'0px',paddingRight:'0px',height:'450px',overflowY:'scroll'}}
      >
        <Spin spinning={this.props.isBusy}>
          { this.renderForm() }
        </Spin>
      </Modal>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    carrierList: safe(state,'shared.carrier.list.data',[]).filter(({ active }) => Boolean(active)),
    tpaList: safe(state,'billing.tpa.list.data',[]),
    billingpolicy: safe(state,'billing.policies.load.data',{}),
    save: safe(state,'billing.policies.save'),
    create: safe(state,'billing.policies.create'),
    isBusy: (
      safe(state,'billing.policies.save.processing') ||
      safe(state,'billing.policies.create.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,{...shared_actions,...billing_actions})(Form.create()(PolicyEntryDialog));
