import React, { Component } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';

//import FormattedCell from '../../../../shared/FormattedCell';
//import { safe } from '../../../../../helpers/reduxSelectors';
//import { x12CarCodes } from '../../../../../helpers/x12CarCodes';

class ClaimsTable extends Component {

  getSubmissionStatus = (x) => {
    switch (x) {
      case 1: return 'GENERATED';
      case 2: return 'SUBMITTED';
      default: return '--';
    }
  }

  getAcceptanceStatus = (x) => {
    switch (x) {
      case 1: return 'ACCEPTED';
      case 2: return 'ERROR/ACCEPTED';
      case 3: return 'REJECTED';
      default: return '-';
    }
  }

/*
  findMessage = (code) => {
    const c = code.toString();
    const ans = x12CarCodes.find(x=>x.xCode == c);
    return (ans) ? ans.description : null;
  }
  */

  translateData = () => {
    const result = [];
    this.props.data.forEach(x=>{
      result.push({
        id: x.id,
        key: x.id+'SUB',
        category: 'SUBMISSION',
        type: this.getSubmissionStatus(x.submitted_status),
        date: (x.submitted_date) ? moment(x.submitted_date).format('MM/DD/YYYY HH:mm:ss') : '--',
        description: ''
      });
      if (x.ibr_status) {
        result.push({
          id: x.id,
          key: x.id+'SIBR',
          category: 'STATUS-IBR',
          type: this.getAcceptanceStatus(x.ibr_status),
          date: (x.ibr_date) ? moment(x.ibr_date).format('MM/DD/YYYY HH:mm:ss') : '--',
          description: (x.ibr_status !== 1) ? x.ibr_message : null,
          payer_claim_num: x.payer_claim_num
        });
      }
      if (x.ebr_status) {
        result.push({
          id: x.id,
          key: x.id+'SEBR',
          category: 'STATUS-EBR',
          type: this.getAcceptanceStatus(x.ebr_status),
          date: (x.ebr_date) ? moment(x.ebr_date).format('MM/DD/YYYY HH:mm:ss') : '--',
          description: (x.ebr_status !== 1) ? x.ebr_message : null,
          payer_claim_num: x.payer_claim_num
        });
      }
      if (x.dpr_status) {
        result.push({
          id: x.id,
          key: x.id+'SDPR',
          category: 'STATUS-DPR',
          type: this.getAcceptanceStatus(x.dpr_status),
          date: (x.dpr_date) ? moment(x.dpr_date).format('MM/DD/YYYY HH:mm:ss') : '--',
          description: (x.dpr_status !== 1) ? x.dpr_message : null,
          payer_claim_num: x.payer_claim_num
        });
      }
      if (x.paid_id) {
        const denied = (x.paid_allowed_amount === 0) ? true : false;
        result.push({
          id: x.id,
          key: x.id+'SPAY',
          category: 'PAYMENT',
          type: (denied) ? 'DENIED' : 'PAID',
          date: (x.paid_date) ? moment(x.paid_date).format('MM/DD/YYYY') : '--',
          amount: x.paid_amount,
          description: null,
          payer_claim_num: x.payer_claim_num
        });
      }
    });
    return result;
  }

  render() {
    const columns=[{
      title: <span style={{whiteSpace:'nowrap'}}>Claim #</span>,
      dataIndex: 'id',
      width: 80,
      render: (val) => {
        return numeral(val).format('0000000');
      }
    },{
      title: 'Type',
      dataIndex: 'category',
      width: 125,
      render: (value) => {
        return <span style={{whiteSpace:'nowrap'}}>{value}</span>
      }
    },{
      title: 'Status',
      dataIndex: 'type',
      width: 125,
    },{
      title: 'Date',
      dataIndex: 'date',
      width: 125,
      render: (value) => {
        return moment(value).format('MM/DD/YYYY')
      }
    },{
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      render: (val) => {
        return (val) ? numeral(val).format('$0,0.00') : null;
      }
    },{
      title: 'Payer Claim #',
      dataIndex: 'payer_claim_num'
    },{
      title: 'Description',
      dataIndex: 'description',
    }];

    const data = this.translateData(this.props.data);

    if (data.length > 0) {
      return (
        <div>
          <h4>Claims</h4>
          <Table
            dataSource={data}
            rowKey='key'
            columns={columns}
            bordered={false}
            showHeader={true}
            pagination={false}
            size='small'
            style={{backgroundColor:'white'}}
          />
        </div>
      );
    }
    return null;
  }
}

export default ClaimsTable;
