import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, message, Spin, Select } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../../actions/billing';
import { safe } from '../../../helpers/reduxSelectors';

class CptEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id,10)
    }
  }

  componentDidMount() {
    if (this.state.id !== 0) {
      this.props.cptLoad(this.state.id);
    } else {
      this.props.cptLoadClear();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load !== this.props.load) {
      if (this.props.load.error) {
        this.props.history.push('/settings/cpt');
        message.error('Unable to load CPT information');
      }
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.success) {
        this.props.cptSaveClear();
        this.props.history.push('/settings/cpt');
        message.success('CPT code saved');
      } else if (this.props.error) {
        message.error('Unable to save CPT');
      }
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        this.props.cptCreateClear();
        this.props.history.push('/settings/cpt');
        message.success('CPT created');
      } else if (this.props.error) {
        message.error('Unable to save CPT code');
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.id === 0) {
          this.props.cptCreate(values);
        } else {
          this.props.cptSave(this.state.id,values);
        }
      }
    });
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const data = safe(this.props,'load.data',{});

    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off" className='cpt-form'>
        <FormItem {...formItemLayout} label="CPT Code Name">
          {getFieldDecorator('code', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.code
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Description">
          {getFieldDecorator('description', {
            initialValue: data.description
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Type"
        >
          {getFieldDecorator('type', {
            rules: [{ required: true, message: " " }],
            initialValue: data.type
          })(
            <Select>
              <Option value={1}>All Clinicians</Option>
              <Option value={2}>Prescribers Only</Option>
              <Option value={3}>Therapists Only</Option>
            </Select>
          )}
        </FormItem>

        <Row>
          <Col xs={36} sm={16}>
            <Button
              htmlType="submit"
              style={{float:'right'}}
              disabled={this.props.isBusy}
              type="primary">
                Save
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <h3>Edit CPT Code</h3>
          { this.renderForm() }
        </Spin>
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    load: safe(state,'billing.cpt.load'),
    save: safe(state,'billing.cpt.save'),
    create: safe(state,'billing.cpt.create'),
    isBusy: (
      safe(state,'billing.cpt.load.processing') ||
      safe(state,'billing.cpt.save.processing') ||
      safe(state,'billing.cpt.create.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(CptEdit)));
