import React from 'react';
import { Table, Select, Spin } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import { connect } from 'react-redux';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ReferenceLine
} from 'recharts';
import * as actions from '../../../../actions/scheduler';
import { safe } from '../../../../helpers/reduxSelectors';
import { therapist60minUpdate_date } from '../../../Params';

class Productivity extends React.Component {
  state = {
    term_id: null
  }

  componentDidMount() {
    this.props.clearProviderProductivity();
    this.props.getProviderTerm(this.props.provider_id);
  }

  componentDidUpdate(prevProps,prevState) {
    if (prevProps.provider_id !== this.props.provider_id) {
      this.props.clearProviderProductivity();
      this.props.getProviderTerm(this.props.provider_id);
    }

    if (prevProps.termList !== this.props.termList && this.props.termList) {
      let selected_id = null;
      if (this.props.termList.length > 0) {
        for (let i = 0; i < this.props.termList.length; i++) {
          if (moment().isBetween(this.props.termList[i].start_date,
            this.props.termList[i].end_date,'day','[]')) {
            selected_id = this.props.termList[i].id;
          }
        }
      }
      this.setState({
        term_id: selected_id
      });
    }

    if (prevState.term_id !== this.state.term_id && this.state.term_id) {
      this.props.getProviderProductivity(this.props.provider_id,this.state.term_id,1);
    }
  }

  onSelect = (term_id) => {
    this.setState({
      term_id: term_id
    });
  }

  countRenderer = (value) => {
    return (value) ? value : '';
  }

  detailView = (record) => {
    const data = safe(this.props,'productivityData.'+record.time+'.data',[]);
    const provider = this.props.providerList.find(x=>x.id === this.props.provider_id);
    const isPrescriber = safe(provider,'roles',[]).includes('prescriber');

    if (isPrescriber) {
      return (
        <div>
          <Table
            columns={[{
              title: 'Date of Service',
              dataIndex: 'date',
              render: (value) => {
                return moment(value).format('M/D/YYYY')
              }
            },{
              title: 'Patient',
              dataIndex: 'patient_name'
            },{
              title: 'Evals',
              align:'right',
              dataIndex: 'num_intakes',
              render: (val) => {
                return (val) ? 'X' : null;
              }
            },{
              title: 'F/U 20',
              align: 'right',
              dataIndex: 'num_followup_20',
              render: (val) => {
                return (val) ? 'X' : null;
              }
            },{
              title: 'F/U 30',
              align: 'right',
              dataIndex: 'num_followup_30',
              render: (val) => {
                return (val) ? 'X' : null;
              }
            },{
              title: 'F/U 45',
              align: 'right',
              dataIndex: 'num_followup_45',
              render: (val) => {
                return (val) ? 'X' : null;
              }
            },{
              title: 'F/U 60',
              align: 'right',
              dataIndex: 'num_followup_60',
              render: (val) => {
                return (val) ? 'X' : null;
              }
            },{
              title: 'Admin',
              align: 'right',
              dataIndex: 'admin_mins',
              render: (value)=>{
                return (value) ? numeral(value/60).format('0.00') : null;
              }
            },{
              title: 'PTO',
              align: 'right',
              dataIndex: 'pto_mins',
              render: (value)=>{
                return (value) ? numeral(value/60).format('0.00') : null;
              }
            },{
              title: 'UTO',
              align: 'right',
              dataIndex: 'uto_mins',
              render: (value)=>{
                return (value) ? numeral(value/60).format('0.00') : null;
              }
            }]}
            dataSource={data}
            pagination={false}
            size='small'
          />
        </div>
      )
    } else {
      return (
        <div>
          <Table
            columns={[{
              title: 'Date of Service',
              dataIndex: 'date',
              render: (value) => {
                return moment(value).format('M/D/YYYY')
              }
            },{
              title: 'Patient',
              dataIndex: 'patient_name'
            },{
              title: 'Evals',
              align:'right',
              dataIndex: 'num_intakes',
              render: (val) => {
                return (val) ? 'X' : null;
              }
            },{
              title: 'F/U 45',
              align: 'right',
              dataIndex: 'num_followup_45',
              render: (val) => {
                return (val) ? 'X' : null;
              }
            },{
              title: 'F/U 60',
              align: 'right',
              dataIndex: 'num_followup_60',
              render: (val) => {
                return (val) ? 'X' : null;
              }
            },{
              title: 'Admin',
              align: 'right',
              dataIndex: 'admin_mins',
              render: (value)=>{
                return (value) ? numeral(value/60).format('0.00') : null;
              }
            },{
              title: 'PTO',
              align: 'right',
              dataIndex: 'pto_mins',
              render: (value,record)=>{
                const multiplier = (moment(record.date).isAfter(moment(therapist60minUpdate_date))) ? 1.0 : 1.3334;
                return (value) ? numeral(value*multiplier/60).format('0.00') : null;
              }
            },{
              title: 'UTO',
              align: 'right',
              dataIndex: 'uto_mins',
              render: (value,record)=>{
                const multiplier = (moment(record.date).isAfter(moment(therapist60minUpdate_date))) ? 1.0 : 1.3334;
                return (value) ? numeral(value*multiplier/60).format('0.00') : null;
              }
            }]}
            dataSource={data}
            pagination={false}
            size='small'
          />
        </div>
      )
    }
  }

  xaxis = (v) => {
    return v-Math.trunc(v/100)*100;
  }

  round = (x) => {
    return Math.round(x*1000)/10
  }

  displayGraph = (isPrescriber) => {
    let thisweek = 0;
    let toDateT = 0;
    let toDateB = 0;
    let t = 0;
    let b = 0;

    const data = Object.values(this.props.productivityData).map(q=>{
      t += q.total.total_mins_worked/60;
      if (isPrescriber) {
        b += (q.total.max_target_mins - q.total.pto_mins - q.total.uto_mins - q.total.holiday_mins) / 60;
      } else {
        const multiplier = (moment(q.total.start_date).isAfter(moment(therapist60minUpdate_date))) ? 1.0 : 1.3334;
        b += (q.total.max_target_mins - q.total.pto_mins
          - q.total.uto_mins - q.total.holiday_mins)*multiplier*q.total.scaling_factor/60;
      }

      if (moment().isBetween(q.total.start_date,q.total.end_date,'day','[]')) {
        toDateT = t;
        toDateB = b;
        thisweek = q.total.time.toString()
      }
      return {
        x: q.total.time.toString(),
        y1: t,
        y2: b
      }
    });

    return (
      <table style={{width:'100%'}}>
        <tbody>
          <tr>
            <td>
              <LineChart
                height={300}
                width={700}
                data={data}
                margin={{
                  top: 5, right: 30, left: 20, bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="x" tickFormatter={this.xaxis} label={{ value: 'Week #', position: 'insideTopRight', offset: 30}}/>
                <YAxis label={{ value: 'Hours', angle: -90, position: 'insideLeft' }} />
                <Legend />
                <Line name='Cumulative Hours Worked' type="monotone" dataKey="y1" stroke="#8884d8" dot={false}/>
                <Line name="Cumulative Contracted Hours" type="monotone" dataKey="y2" stroke="#82ca9d" dot={false}/>
                <ReferenceLine x={thisweek} stroke="red" />
              </LineChart>
            </td>
            <td>
              { toDateT > 0 && <div style={{padding:'10px',background:'green',color:'white',fontWeight:'strong'}}>
                Productivity to Date: {(toDateB) ? Math.round((toDateT/toDateB)*1000)/10 : 0}%
              </div> }
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  getPsychiatristColumns = () => {
    const columns=[{
      title: 'Week',
      key: 'week',
      children: [{
        title: 'Year/Week',
        dataIndex:'time',
        render: (tp,record) => {
          // year/week
          const y = Math.trunc(tp/100);
          const w = tp-y*100;
          const val = y + '/' + w;
          if (moment().isBetween(record.start_date,record.end_date,'day','[]')) {
            return (
              <span style={{backgroundColor:'red',color:'white'}}>
                {val}
              </span>
            );
          } else {
            return val;
          }
        }
      },{
        title: 'Start',
        dataIndex: 'start_date',
        render: (value) => moment(value).format('MM/DD/YYYY')
      },{
        title:'% Meds',
        dataIndex:'meds',
        align: 'right',
        render: (value) => {
          if (value) {
            return (Math.trunc(value*1000)/10) + '%';
          }
          return null;
        }
      },{
        title:'Evals',
        dataIndex:'num_intakes',
        align: 'right',
        render: this.countRenderer
      },{
        title:'F/U 20',
        dataIndex:'num_followup_20',
        align: 'right',
        render: this.countRenderer
      },{
        title:'F/U 30',
        dataIndex:'num_followup_30',
        align: 'right',
        render: this.countRenderer
      },{
        title:'F/U 45',
        dataIndex:'num_followup_45',
        align: 'right',
        render: this.countRenderer
      },{
        title:'F/U 60',
        dataIndex:'num_followup_60',
        align: 'right',
        render: this.countRenderer
      },{
        title:'Admin',
        dataIndex:'admin_mins',
        align: 'right',
        render: (value) => {
          return (value) ? numeral(value/60).format('0.00') : null;
        }
      },{
        title:'PTO',
        dataIndex:'pto_mins',
        align: 'right',
        render: (value) => {
          return (value) ? numeral(value/60).format('0.00') : null;
        }
      },{
        title:'UTO',
        dataIndex:'uto_mins',
        align: 'right',
        render: (value) => {
          return (value) ? numeral(value/60).format('0.00') : null;
        }
      },{
        title:'BKT',
        dataIndex: 'blo_mins',
        align: 'right',
        render: (value) => {
          return (value) ? numeral(value/60).format('0.00') : null;
        }
      },{
        title: 'Hol',
        dataIndex: 'holiday_mins',
        align: 'right',
        render: (value) => {
          return (value) ? numeral(value/60).format('0.00') : null;
        }
      },{
        title:'Contracted Hrs',
        dataIndex: 'max_target_mins',
        align: 'right',
        render: (val) => numeral(val/60).format('0.00')
      },{
        title: 'Total Hrs',
        dataIndex:'total_mins_worked',
        align: 'right',
        render: (val) => numeral(val/60).format('0.00')
      },{
        title:'% Productivity',
        dataIndex:'productivity',
        align: 'right',
        render: (val) => {
          if (val) {
            return (Math.trunc(val*1000)/10) + '%';
          }
          return '--';
        }
      }]
    },
    {
      title: 'Term to Date',
      key: 'term_to_date',
      children: [{
        title:'CCH',
        align: 'right',
        dataIndex: 'QCH',
        render: (val) => {
          return (val) ? numeral(val/60).format('0.00') : '';
        }
      },{
        title: 'CTH',
        align: 'right',
        dataIndex: 'QTH',
        render: (val) => {
          return (val) ? numeral(val/60).format('0.00') : '';
        }
      },{
        title: 'C%MED',
        align: 'right',
        dataIndex: 'QMED',
        render: (val) => {
          return (val) ? numeral(val).format('%0.0') : '';
        }
      },{
        title: 'CPROD',
        align: 'right',
        dataIndex: 'QPROD',
        render: (val) => {
          if (val) {
            return (Math.trunc(val*1000)/10) + '%';
          }
          return '--';
        }
      }]
    }];
    return columns;
  }

  getPsychiatristColumnsHourly = () => {
    const columns=[{
      title: 'Start',
      dataIndex: 'start_date',
      render: (value) => moment(value).format('MM/DD/YYYY')
    },{
      title:'Evals',
      dataIndex:'num_intakes',
      align: 'right',
      render: this.countRenderer
    },{
      title:'F/U 20',
      dataIndex:'num_followup_20',
      align: 'right',
      render: this.countRenderer
    },{
      title:'F/U 30',
      dataIndex:'num_followup_30',
      align: 'right',
      render: this.countRenderer
    },{
      title:'F/U 45',
      dataIndex:'num_followup_45',
      align: 'right',
      render: this.countRenderer
    },{
      title:'F/U 60',
      dataIndex:'num_followup_60',
      align: 'right',
      render: this.countRenderer
    },{
      title:'Admin',
      dataIndex:'admin_mins',
      align: 'right',
      render: (value) => {
        return (value) ? numeral(value/60).format('0.00') : null;
      }
    },{
      title:'PTO',
      dataIndex:'pto_mins',
      align: 'right',
      render: (value) => {
        return (value) ? numeral(value/60).format('0.00') : null;
      }
    },{
      title:'UTO',
      dataIndex:'uto_mins',
      align: 'right',
      render: (value) => {
        return (value) ? numeral(value/60).format('0.00') : null;
      }
    },{
      title:'BKT',
      dataIndex: 'blo_mins',
      align: 'right',
      render: (value) => {
        return (value) ? numeral(value/60).format('0.00') : null;
      }
    },{
      title: 'Hol',
      dataIndex: 'holiday_mins',
      align: 'right',
      render: (value) => {
        return (value) ? numeral(value/60).format('0.00') : null;
      }
    },{
      title: 'Total Hrs',
      dataIndex:'total_mins_worked',
      align: 'right',
      render: (val) => numeral(val/60).format('0.00')
    }];

    return columns;
  }

  getTherapistColumns = () => {
    const columns=[{
      title: 'Week',
      key: 'week',
      children: [{
        title: 'Year/Week',
        dataIndex:'time',
        render: (tp,record) => {
          // year/week
          const y = Math.trunc(tp/100);
          const w = tp-y*100;
          const val = y + '/' + w;
          if (moment().isBetween(record.start_date,record.end_date,'day','[]')) {
            return (
              <span style={{backgroundColor:'red',color:'white'}}>
                {val}
              </span>
            );
          } else {
            return val;
          }
        }
      },{
        title: 'Start',
        dataIndex: 'start_date',
        render: (value,record) => moment(value).format('MM/DD/YYYY')
      },{
        title:'Evals',
        dataIndex:'num_intakes',
        align: 'right',
        render: this.countRenderer
      },{
        title: 'F/U 45',
        dataIndex: 'num_followup_45',
        align: 'right',
        render: this.countRenderer
      },{
        title: 'F/U 60',
        dataIndex: 'num_followup_60',
        align: 'right',
        render: this.countRenderer
      },{
        title:'Admin',
        dataIndex:'admin_mins',
        align: 'right',
        render: (value) => {
          return this.countRenderer(value/60);
        }
      },{
        title:'PTO',
        dataIndex:'pto_mins',
        align: 'right',
        render: (value,record) => {
          const multiplier = (moment(record.start_date).isAfter(moment(therapist60minUpdate_date))) ? 1.0 : 1.3334;
          return (value) ? numeral(value*multiplier/60).format('0.00') : null;
        }
      },{
        title:'UTO',
        dataIndex:'uto_mins',
        align: 'right',
        render: (value,record) => {
          const multiplier = (moment(record.start_date).isAfter(moment(therapist60minUpdate_date))) ? 1.0 : 1.3334;
          return (value) ? numeral(value*multiplier/60).format('0.00') : null;
        }
      },{
        title:'BKT',
        dataIndex: 'blo_mins',
        align: 'right',
        render: (value) => {
          return (value) ? numeral(value/60).format('0.00') : null;
        }
      },{
        title: 'Hol',
        dataIndex: 'holiday_mins',
        align: 'right',
        render: (value,record) => {
          const multiplier = (moment(record.start_date).isAfter(moment(therapist60minUpdate_date))) ? 1.0 : 1.3334;
          return (value) ? numeral(value*multiplier/60).format('0.00') : null;
        }
      },{
        title: 'Scaling',
        dataIndex: 'scaling_factor',
        align: 'right',
        render: (val) => {
          return (val) ? numeral(val).format('0.00') : '';
        }
      },{
        title:'Contracted Hrs',
        dataIndex: 'max_target_mins',
        align: 'right',
        render: (val,record) => {
          const multiplier = (moment(record.start_date).isAfter(moment(therapist60minUpdate_date))) ? 1.0 : 1.3334;
          return (val) ? numeral(multiplier*val/60).format('0.00') : '';
        }
      },{
        title: 'Total Hrs',
        dataIndex:'total_mins_worked',
        align: 'right',
        render: (val) => {
          return (val) ? numeral(val/60).format('0.00') : '';
        }
      },{
        title:'% Productivity',
        dataIndex:'productivity',
        align: 'right',
        render: (val) => {
          if (val) {
            return (Math.trunc(val*1000)/10) + '%';
          }
          return '--';
        }
      }]
    },{
      title: 'Term to Date',
      key: 'term_to_date',
      children: [{
        title:'CCH',
        align: 'right',
        dataIndex: 'QCH',
        render: (val) => {
          return (val) ? numeral(val/60).format('0.00') : '';
        }
      },{
        title: 'CTH',
        align: 'right',
        dataIndex: 'QTH',
        render: (val) => {
          return (val) ? numeral(val/60).format('0.00') : '';
        }
      },{
        title: 'CPROD',
        align: 'right',
        dataIndex: 'QPROD',
        render: (val) => {
          if (val) {
            return (Math.trunc(val*1000)/10) + '%';
          }
          return '--';
        }
      }]
    }];
    return columns;
  }

  getTherapistColumnsHourly = () => {
    const columns=[{
      title: 'Start',
      dataIndex: 'start_date',
      render: (value,record) => moment(value).format('MM/DD/YYYY')
    },{
      title:'Evals',
      dataIndex:'num_intakes',
      align: 'right',
      render: this.countRenderer
    },{
      title: 'F/U 30',
      dataIndex: 'num_followup_30',
      align: 'right',
      render: this.countRenderer
    },{
      title: 'F/U 45',
      dataIndex: 'num_followup_45',
      align: 'right',
      render: this.countRenderer
    },{
      title: 'F/U 60',
      dataIndex: 'num_followup_60',
      align: 'right',
      render: this.countRenderer
    },{
      title:'Admin',
      dataIndex:'admin_mins',
      align: 'right',
      render: (value) => {
        return this.countRenderer(value/60);
      }
    },{
      title:'PTO',
      dataIndex:'pto_mins',
      align: 'right',
      render: (value,record) => {
        const multiplier = (moment(record.start_date).isAfter(moment(therapist60minUpdate_date))) ? 1.0 : 1.3334;
        return (value) ? numeral(value*multiplier/60).format('0.00') : null;
      }
    },{
      title:'UTO',
      dataIndex:'uto_mins',
      align: 'right',
      render: (value,record) => {
        const multiplier = (moment(record.start_date).isAfter(moment(therapist60minUpdate_date))) ? 1.0 : 1.3334;
        return (value) ? numeral(value*multiplier/60).format('0.00') : null;
      }
    },{
      title:'BKT',
      dataIndex: 'blo_mins',
      align: 'right',
      render: (value) => {
        return (value) ? numeral(value/60).format('0.00') : null;
      }
    },{
      title: 'Hol',
      dataIndex: 'holiday_mins',
      align: 'right',
      render: (value,record) => {
        const multiplier = (moment(record.start_date).isAfter(moment(therapist60minUpdate_date))) ? 1.0 : 1.3334;
        return (value) ? numeral(value*multiplier/60).format('0.00') : null;
      }
    },{
      title: 'Total Hrs',
      dataIndex:'total_mins_worked',
      align: 'right',
      render: (val) => {
        return (val) ? numeral(val/60).format('0.00') : '';
      }
    }];

    return columns;
  }

  floatRound = (x) => {
    if (!x || x === null) {
      return 0.0;
    }
    return Math.round((x + 0.00001) * 1000) / 1000;
  }

  fteProductivity = () => {
    const provider = this.props.providerList.find(x=>x.id === this.props.provider_id);
    const isPrescriber = safe(provider,'roles',[]).includes('prescriber');
    const data = Object.values(this.props.productivityData).map(x=>x.total);
    let QTH = 0;
    let QCH = 0;
    let QMED_NUM = 0;
    let QMED_DEN = 0;
    data.forEach(x=>{
      QTH += x.total_mins_worked;
      if (isPrescriber) {
        QCH += x.max_target_mins - x.pto_mins - x.uto_mins - x.holiday_mins;
        QMED_NUM += (30*x.num_followup_30+20*x.num_followup_20);
        QMED_DEN += (
          20*x.num_followup_20 +
          30*x.num_followup_30 +
          45*x.num_followup_45 +
          60*x.num_followup_60 +
          60*x.num_intakes +
          x.admin_mins
        );
      } else {
        const multiplier = (moment(x.start_date).isAfter(moment(therapist60minUpdate_date))) ? 1.0 : 1.3334;
        QCH += (x.max_target_mins - x.pto_mins - x.uto_mins - x.holiday_mins)*multiplier*x.scaling_factor;
      }
      x.QTH = QTH;
      x.QCH = QCH;
      if (QCH > 0) {
        x.QPROD = this.floatRound(QTH/QCH);
      }
      if (QMED_DEN > 0) {
        x.QMED = this.floatRound(QMED_NUM/QMED_DEN);
      }
    });

    return (
      <div style={{padding:'5px'}}>
        <Spin spinning={this.props.isBusy}>
          <table style={{width:'100%'}}>
            <tbody>
              <tr>
                <td style={{textAlign:'left'}}>
                  <h3>Productivity:</h3>
                </td>
                <td style={{textAlign:'right'}}>
                  <Select style={{width:'300px'}} onSelect={this.onSelect} value={this.state.term_id}>
                    {this.props.termList.map(x=>{
                      return (
                        <Select.Option key={x.id} value={x.id}>
                          {moment(x.productivity_start_date).format('M/D/YYYY')} -
                          {moment(x.end_date).format('M/D/YYYY')}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </td>
              </tr>
            </tbody>
          </table>

          { this.displayGraph(isPrescriber) }

          <div style={{paddingTop:'10px'}}>
            <Table
              columns={(isPrescriber) ? this.getPsychiatristColumns() : this.getTherapistColumns()}
              dataSource={data}
              pagination={false}
              size='small'
              bordered={true}
              expandedRowRender={this.detailView}
              rowKey='time'
            />
          </div>
        </Spin>
      </div>
    )
  }

  hourlyProductivity = () => {
    const provider = this.props.providerList.find(x=>x.id === this.props.provider_id);
    const isPrescriber = safe(provider,'roles',[]).includes('prescriber');
    const data = Object.values(this.props.productivityData).map(x=>x.total);

    return (
      <div style={{padding:'5px'}}>
        <Spin spinning={this.props.isBusy}>
          <table style={{width:'100%'}}>
            <tbody>
              <tr>
                <td style={{textAlign:'left'}}>
                  <h3>Productivity:</h3>
                </td>
                <td style={{textAlign:'right'}}>
                  <Select style={{width:'300px'}} onSelect={this.onSelect} value={this.state.term_id}>
                    {this.props.termList.map(x=>{
                      return (
                        <Select.Option key={x.id} value={x.id}>
                          {moment(x.productivity_start_date).format('M/D/YYYY')} -
                          {moment(x.end_date).format('M/D/YYYY')}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </td>
              </tr>
            </tbody>
          </table>

          <div style={{paddingTop:'10px'}}>
            <Table
              columns={(isPrescriber) ? this.getPsychiatristColumnsHourly() : this.getTherapistColumnsHourly()}
              dataSource={data}
              pagination={false}
              size='small'
              bordered={true}
              expandedRowRender={this.detailView}
              rowKey='time'
            />
          </div>
        </Spin>
      </div>
    )
  }

  render() {
    const provider = this.props.providerList.find(x=>x.id === this.props.provider_id);
    return (provider.employment_type === 2) ? this.hourlyProductivity() : this.fteProductivity();
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    provider_id: safe(state,'scheduler.settings.provider_id',0),
    providerList: safe(state,'scheduler.provider.list.data',[]),
    termList: safe(state,'scheduler.provider.get.data',[]),
    productivityData: safe(state,'scheduler.provider.productivity.data.payperiods',{}),
    isBusy: (
      safe(state,'scheduler.provider.get.processing',false) ||
      safe(state,'scheduler.provider.productivity.processing',false)
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(Productivity);
