import { useState, useEffect } from 'react';
import useFetch, { CachePolicies } from 'use-http';

interface ReturnI {
  data: any;
  loading: boolean;
  error?: any;
}

const useApiGet = (url: string, def: any, triggers: Array<any>): ReturnI => {
  const [value, setValue] = useState(def);
  const { loading, error, get, data } = useFetch({ cachePolicy: CachePolicies.NO_CACHE });

  useEffect(() => {
    const getRequest = async () => {
      const resp = await get(url);
      if (!error) {
        setValue(resp);
      }
    };
    getRequest();
  }, triggers);

  if (error) {
    return {
      data: value,
      loading,
      error: data,
    };
  }
  return {
    data: value,
    loading,
  };
};

export default useApiGet;
