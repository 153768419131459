export const genders = [
  {id:'F',text:'Female'},
  {id:'M',text:'Male'},
  {id:'M2F',text:'Trans M-to-F'},
  {id:'F2M',text:'Trans F-to-M'},
  {id:'O',text:'Other'}
];

export const traditionalGenders = [
  {id:'F',text:'Female'},
  {id:'M',text:'Male'},
  {id:'U',text:'Unknown'}
];

export const map2TraditionalGenders = {
  'F': 'F',
  'M': 'M',
  'M2F': 'U',
  'F2M': 'U',
  'O': 'U'
};
