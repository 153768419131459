import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import AttachmentList from './AttachmentList';

import { safe } from '../../../../../../helpers/reduxSelectors';

import { Parser as HtmlToReactParser } from 'html-to-react';

class TemplateLetterChartNote extends Component {
  static defaultProps = {
    print: false,
  };

  static propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    letter: PropTypes.string,
    signedDateTime: PropTypes.string,
    print: PropTypes.bool,
  };

  renderSignature = () => {
    return this.props.signedDateTime ? (
      <div className="chartnotesignature">
        Electronically signed by {this.props.providerName} at{' '}
        {moment(this.props.signedDateTime).format('MM/DD/YYYY h:mm A')}
      </div>
    ) : null;
  };

  getLetterHtml = () => {
    var htmlToReactParser = new HtmlToReactParser();
    return htmlToReactParser.parse(this.props.letter);
  };

  renderPrintOption = () => {
    if (this.props.print) {
      return null;
    }

    return (
      <div style={{ paddingTop: '10px' }}>
        <a href={'/display/letter/' + this.props.noteId} target="_blank">
          (Display printable copy)
        </a>
      </div>
    );
  };

  renderTitle = () => {
    if (this.props.print) {
      return null;
    }
    return <h3>Letter: {this.props.title}</h3>;
  };

  render() {
    let style = {
      width: this.props.print ? '' : '80%',
      border: this.props.print ? '' : '1px dashed black',
      padding: '8px',
      backgroundColor: this.props.print ? '' : 'rgb(247,247,247)',
    };

    return (
      <div>
        {this.renderTitle()}
        <div style={style}>{this.getLetterHtml()}</div>
        {this.renderPrintOption()}
        {this.renderSignature()}
        <AttachmentList list={this.props.attachments} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    attachments: safe(ownProps, 'data.attachments', []),
    noteId: safe(ownProps, 'data.id'),
    title: safe(ownProps, 'data.note.title'),
    letter: safe(ownProps, 'data.note.letter'),
    signedDateTime: safe(ownProps, 'data.signedAt', null),
    providerName: safe(ownProps, 'data.provider.full_name'),
  };
};

export default connect(mapStateToProps, null)(TemplateLetterChartNote);
