import { startDateTypes, endDateTypes } from 'reports';
import startCase from 'lodash/startCase';
import map from 'lodash/map';
import moment from 'moment';

const dateTypes = {
  dos: 'Date of Service',
  cd: 'Creation Date',
};

interface PropsI {
  params: Record<string, unknown>;
}

export const paramsFormatting = {
  dateType: (abbr: string): string => dateTypes[abbr],
  startDate: (date: Date): string => moment(date).format('M/D/Y hh:mm A'),
  endDate: (date: Date): string => moment(date).format('M/D/Y hh:mm A'),
  startDateType: (type: number): string => startDateTypes[type - 1],
  endDateType: (type: number): string => endDateTypes[type - 1],
};

const FormattedParams: React.FC<PropsI> = (props: PropsI) => {
  const { params } = props;
  return (
    <ul className="m0 p0" style={{ listStyle: 'none' }}>
      {map(params, (v, k) => {
        const formatter = paramsFormatting[k];
        return (
          <li key={k}>
            <b>{startCase(k)}</b>: {formatter(v)}
          </li>
        );
      })}
    </ul>
  );
};

export default FormattedParams;
