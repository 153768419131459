import React from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'antd';

import InboxSync from './Messenger_/InboxSync';
import InboxList from './Messenger_/InboxList';
import Messages from './Messenger_/Messages';
import NewThread from './Messenger_/NewThread';

import * as shared_actions from '../../../../actions/shared';
import { safe } from '../../../../helpers/reduxSelectors';

class Messenger extends React.Component {
  state = {
    threadStatus: null,
    panel: 1
  };

  componentDidUpdate(prevProps) {
    // If they left the screen on the new thread list, reset it to the main view panel
    if (prevProps.visible === false && this.props.visible === true && this.state.panel === 3) {
      this.onBack();
    }
  }

  onSelect = (threadStatus) => {
    this.setState({
      threadStatus: threadStatus,
      panel: 2
    });
  }

  closeMessenger = () => {
    this.props.onClose();
    this.setState({
      threadStatus: null,
      panel: 1
    })
  }

  onBack = () => {
    this.setState({
      threadStatus: null,
      panel: 1
    });
  }

  onNewThread = () => {
    this.setState({
      threadStatus: null,
      panel: 3
    });
  }

  selectPanel = () => {
    switch (this.state.panel) {
      case 1:
        return <InboxList onSelect={this.onSelect} onNewThread={this.onNewThread}/>;
      case 2:
        return <Messages threadStatus={this.state.threadStatus} onBack={this.onBack} />;
      case 3:
        return <NewThread onBack={this.onBack} onSelect={this.onSelect}/>
      default:
        return null;
    }
  }

  render() {
    return (
      <div>
        {this.props.userId ? <InboxSync userId={this.props.userId} thread={safe(this.state,'threadStatus.thread')} /> : null}
        <Drawer
          placement="right"
          closable={false}
          onClose={this.closeMessenger}
          width={500}
          title={false}
          visible={this.props.visible}
          style={{padding:'0px'}}
        >
          <div style={{overflow: 'scroll'}}>
            { this.selectPanel() }
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state,ownProps) => {
  return {
    userId: safe(state, 'shared.domain.activeUser.id'),
  }
}

export default connect(mapStateToProps,{...shared_actions})(Messenger);
