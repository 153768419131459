import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../actions/shared';
import { logoutInProcess, logoutSuccess, logoutError, loginSuccess } from '../../helpers/reduxSelectors';

class Logout extends React.Component {

  componentDidMount() {
    this.props.logoutUser();
  }

  componentDidUpdate() {
    if (this.props.logoutInProcess) {
      return;
    } else {
      if (this.props.userLoggedIn || this.props.logoutError) {
        this.props.logoutUserClear();
        this.props.loginUserClear();
        this.props.history.push('/login');
      }
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userLoggedIn : loginSuccess(state),
    logoutInProcess: logoutInProcess(state),
    logoutSuccess: logoutSuccess(state),
    logoutError: logoutError(state)
  }
}

export default connect(mapStateToProps, actions)(withRouter(Logout));
