import React from 'react';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';

import HeaderLogo from '../shared/HeaderLogo';
import AppSelector from '../shared/AppSelector';
import Cpt from './Settings_/Cpt';
import CptEdit from './Settings_/CptEdit';
import RateTables from './Settings_/RateTables';
import RateTablesEdit from './Settings_/RateTablesEdit';
import FeeScheduleGroups from './Settings_/FeeScheduleGroups';
import FeeScheduleAddEdit from './Settings_/FeeScheduleAddEdit';
import Carriers from './Settings_/Carriers';
import CarriersEdit from './Settings_/CarriersEdit';
import Organization from './Settings_/Organization';
import Integrations from './Settings_/Integrations';
import RecallList from './Settings_/RecallList';
import RecallListEdit from './Settings_/RecallListEdit';
import Users from './Settings_/Users';
import UsersEdit from './Settings_/UsersEdit';
import Miscellaneous from 'components/App_/Settings_/Miscellanous.tsx';
import IntakeAvailability from './Settings_/IntakeAvailability';
import ChartAccessControl from './Settings_/ChartAccessControl';
import PatientMessageHistory from './Settings_/PatientMessageHistory';

import ServiceLocations from './Settings_/ServiceLocations';
import ServiceLocationsEdit from './Settings_/ServiceLocationsEdit';
import BillingLocations from './Settings_/BillingLocations';
import BillingLocationsEdit from './Settings_/BillingLocationsEdit';
import LocationGroups from './Settings_/LocationGroups';
import LocationGroupsEdit from './Settings_/LocationGroupsEdit';
import Holidays from './Settings_/Holidays';
import HolidaysEdit from './Settings_/HolidaysEdit';

import Letters from './Settings_/Letters';
import Products from './Settings_/Products';
import ProductsEdit from './Settings_/ProductsEdit';
import Groups from './Settings_/Groups';
import GroupsEdit from './Settings_/GroupsEdit';
import Notifications from './Settings_/Notifications';
import NotificationEdit from './Settings_/NotificationEdit';
import Emails from './Settings_/Emails';
import EmailsEdit from './Settings_/EmailsEdit';
import Scheduling from './Settings_/Scheduling';
import Portal from './Settings_/Portal';
import Timeout from '../shared/Timeout';

import ChargeCodes from './Settings_/ChargeCodes';
import ChargeCodesEdit from './Settings_/ChargeCodesEdit';

import { safe, isAdmin, isStaffSupervisor } from '../../helpers/reduxSelectors';
import { VLayout, VLayoutHeader, VLayoutContent, HLayout, HLayoutLeft, HLayoutContent } from '../shared/Layout';

class Settings extends React.Component {
  renderNav = () => {
    let pathname = this.props.location.pathname;
    let folders = pathname.split('/');
    let page = folders[2];

    return (
      <Menu
        style={{ padding: '0px', borderRight: '0px' }}
        onClick={(value) => {
          this.props.history.push('/settings/' + value.key);
        }}
        selectedKeys={[page]}
      >
        <Menu.Item key="users">Users</Menu.Item>
        <Menu.Item key="feeschedules">Fee Schedules</Menu.Item>
        <Menu.Item key="codes">Charge Codes</Menu.Item>
        <Menu.Item key="carriers">Carriers</Menu.Item>
        <Menu.Item key="recalllist">Recall Lists</Menu.Item>
        <Menu.Item key="locations">Service Locations</Menu.Item>
        <Menu.Item key="blocations">Billing Locations</Menu.Item>
        <Menu.Item key="glocations">Location Groups</Menu.Item>
        <Menu.Item key="holidays">Holidays</Menu.Item>
        <Menu.Item key="letters">Letters</Menu.Item>
        <Menu.Item key="products">Products</Menu.Item>
        <Menu.Item key="groups">Admin Groups</Menu.Item>
        <Menu.Item key="emails">Email Templates</Menu.Item>
        <Menu.Item key="notifications">Notification Templates</Menu.Item>
        <Menu.Item key="organization">Organization</Menu.Item>
        <Menu.Item key="integrations">Integrations</Menu.Item>
        <Menu.Item key="scheduling">Scheduling</Menu.Item>
        <Menu.Item key="portal">Patient Portal</Menu.Item>
        <Menu.Item key="miscellaneous">Miscellaneous</Menu.Item>
        <Menu.Item key="intakeavailability">Intake Availability</Menu.Item>
        <Menu.Item key="chartaccesscontrol">Chart Access Control</Menu.Item>
        <Menu.Item key="patientmessagehistory">Patient Message History</Menu.Item>
      </Menu>
    );
  };

  /*
  <Menu.Divider />
  <Menu.Item key="ratetables">Fee Schedules</Menu.Item>
  <Menu.Item key="cpt">Charge Codes</Menu.Item>
  */

  render() {
    if (!this.props.isAdmin && !this.props.isStaffSupervisor) {
      return <div>You are not authorized to use this program.</div>;
    }

    const headerStyle = {
      background: '#2b8ed8',
      color: 'white',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '5px',
      height: '42px',
      minHeight: '42px',
    };

    return (
      <Timeout>
        <VLayout>
          <VLayoutHeader style={headerStyle}>
            <HeaderLogo title="PCPA Settings" />
            <AppSelector activeUserName={this.props.activeUserName} />
          </VLayoutHeader>
          <VLayoutContent>
            <HLayout>
              <HLayoutLeft style={{ width: '200px', minWidth: '200px' }}>{this.renderNav()}</HLayoutLeft>
              <HLayoutContent>
                <Switch>
                  <Redirect exact from="/settings" to="/settings/users" />
                  <Route path="/settings/cpt/:id" component={CptEdit} />
                  <Route path="/settings/cpt" component={Cpt} />
                  <Route path="/settings/codes/:id" component={ChargeCodesEdit} />
                  <Route path="/settings/codes" component={ChargeCodes} />
                  <Route path="/settings/ratetables/:id" component={RateTablesEdit} />
                  <Route path="/settings/ratetables" component={RateTables} />
                  <Route path="/settings/feeschedules/:id" component={FeeScheduleAddEdit} />
                  <Route path="/settings/feeschedules" component={FeeScheduleGroups} />
                  <Route path="/settings/carriers/:id" component={CarriersEdit} />
                  <Route path="/settings/carriers" component={Carriers} />
                  <Route path="/settings/organization" component={Organization} />
                  <Route path="/settings/integrations" component={Integrations} />
                  <Route path="/settings/recalllist/:id" component={RecallListEdit} />
                  <Route path="/settings/recalllist" component={RecallList} />
                  <Route path="/settings/letters" component={Letters} />
                  <Route path="/settings/users/:id" component={UsersEdit} />
                  <Route path="/settings/users" component={Users} />
                  <Route path="/settings/locations/:id" component={ServiceLocationsEdit} />
                  <Route path="/settings/locations" component={ServiceLocations} />
                  <Route path="/settings/blocations/:id" component={BillingLocationsEdit} />
                  <Route path="/settings/blocations" component={BillingLocations} />
                  <Route path="/settings/glocations/:id" component={LocationGroupsEdit} />
                  <Route path="/settings/glocations" component={LocationGroups} />
                  <Route path="/settings/holidays/:id" component={HolidaysEdit} />
                  <Route path="/settings/holidays" component={Holidays} />
                  <Route path="/settings/products/:id" component={ProductsEdit} />
                  <Route path="/settings/products" component={Products} />
                  <Route path="/settings/groups/:id" component={GroupsEdit} />
                  <Route path="/settings/groups" component={Groups} />
                  <Route path="/settings/notifications/:id" component={NotificationEdit} />
                  <Route path="/settings/notifications" component={Notifications} />
                  <Route path="/settings/emails/:id" component={EmailsEdit} />
                  <Route path="/settings/emails" component={Emails} />
                  <Route path="/settings/scheduling" component={Scheduling} />
                  <Route path="/settings/portal" component={Portal} />
                  <Route path="/settings/miscellaneous" component={Miscellaneous} />
                  <Route path="/settings/intakeavailability" component={IntakeAvailability} />
                  <Route path="/settings/chartaccesscontrol" component={ChartAccessControl} />
                  <Route path="/settings/patientmessagehistory" component={PatientMessageHistory} />
                </Switch>
              </HLayoutContent>
            </HLayout>
          </VLayoutContent>
        </VLayout>
      </Timeout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeUserName: safe(state, 'shared.domain.activeUser.full_name'),
    isAdmin: isAdmin(state),
    isStaffSupervisor: isStaffSupervisor(state),
  };
};

export default connect(mapStateToProps, null)(Settings);
