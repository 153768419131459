/**
 * ScheduleTemplates.tsx
 */
import reports from 'reports';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import InlineFormWrapper from 'components/App_/ReportsV2_/InlineFormWrapper';
import ReportWrapper from 'components/App_/ReportsV2_/ReportWrapper';
import Table from 'components/App_/ReportsV2_/Table';
import columns from 'components/App_/ReportsV2_/TableColumns_/scheduleTemplates';
import CSVDownload from '../InlineControls_/CSVDownload';
import { PaginatedDataT } from 'reports';
import useAutoRun from 'components/App_/ReportsV2_/useAutoRun';

const reportId = 28;
const page = 1;
const pageSize = 50;

const ScheduleTemplates: React.FC = () => {
  const inlineData = useContextSelector(InlineContext, (c) => c.state.inlineData) as PaginatedDataT;
  const report = reports.find((r) => r.id === reportId);

  useAutoRun(reportId);

  const formOptions = {
    page,
    pageSize,
    reportId,
  };

  const tableOptions = {
    data: inlineData?.rows,
    columns,
    page,
    pageSize,
    reportId,
    total: inlineData?.count,
  };

  return (
    <ReportWrapper>
      <h3>{report?.name}</h3>
      <InlineFormWrapper {...formOptions}>
        <CSVDownload prefix={report?.csvPrefix} />
      </InlineFormWrapper>
      <Table {...tableOptions} />
    </ReportWrapper>
  );
};

export default ScheduleTemplates;
