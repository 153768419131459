export const PORTAL_LOAD_REQUEST = 'PORTAL_LOAD_REQUEST';
export const PORTAL_LOAD_SUCCESS = 'PORTAL_LOAD_SUCCESS';
export const PORTAL_LOAD_FAILURE = 'PORTAL_LOAD_FAILURE';
export const PORTAL_LOAD_CLEAR = 'PORTAL_LOAD_CLEAR';
export const PORTAL_SAVE_REQUEST = 'PORTAL_SAVE_REQUEST';
export const PORTAL_SAVE_SUCCESS = 'PORTAL_SAVE_SUCCESS';
export const PORTAL_SAVE_FAILURE = 'PORTAL_SAVE_FAILURE';
export const PORTAL_SAVE_CLEAR = 'PORTAL_SAVE_CLEAR';

export const HOLIDAY_LIST_REQUEST = 'HOLIDAY_LIST_REQUEST';
export const HOLIDAY_LIST_SUCCESS = 'HOLIDAY_LIST_SUCCESS';
export const HOLIDAY_LIST_FAILURE = 'HOLIDAY_LIST_FAILURE';
export const HOLIDAY_LIST_CLEAR = 'HOLIDAY_LIST_CLEAR';
export const HOLIDAY_LOAD_REQUEST = 'HOLIDAY_LOAD_REQUEST';
export const HOLIDAY_LOAD_SUCCESS = 'HOLIDAY_LOAD_SUCCESS';
export const HOLIDAY_LOAD_FAILURE = 'HOLIDAY_LOAD_FAILURE';
export const HOLIDAY_LOAD_CLEAR = 'HOLIDAY_LOAD_CLEAR';
export const HOLIDAY_CREATE_REQUEST = 'HOLIDAY_CREATE_REQUEST';
export const HOLIDAY_CREATE_SUCCESS = 'HOLIDAY_CREATE_SUCCESS';
export const HOLIDAY_CREATE_FAILURE = 'HOLIDAY_CREATE_FAILURE';
export const HOLIDAY_CREATE_CLEAR = 'HOLIDAY_CREATE_CLEAR';
export const HOLIDAY_UPDATE_REQUEST = 'HOLIDAY_UPDATE_REQUEST';
export const HOLIDAY_UPDATE_SUCCESS = 'HOLIDAY_UPDATE_SUCCESS';
export const HOLIDAY_UPDATE_FAILURE = 'HOLIDAY_UPDATE_FAILURE';
export const HOLIDAY_UPDATE_CLEAR = 'HOLIDAY_UPDATE_CLEAR';
export const HOLIDAY_REMOVE_REQUEST = 'HOLIDAY_REMOVE_REQUEST';
export const HOLIDAY_REMOVE_SUCCESS = 'HOLIDAY_REMOVE_SUCCESS';
export const HOLIDAY_REMOVE_FAILURE = 'HOLIDAY_REMOVE_FAILURE';
export const HOLIDAY_REMOVE_CLEAR = 'HOLIDAY_REMOVE_CLEAR';

export const EMAILS_SAVE_REQUEST = 'EMAILS_SAVE_REQUEST';
export const EMAILS_SAVE_SUCCESS = 'EMAILS_SAVE_SUCCESS';
export const EMAILS_SAVE_FAILURE = 'EMAILS_SAVE_FAILURE';
export const EMAILS_SAVE_CLEAR = 'EMAILS_SAVE_CLEAR';
export const EMAILS_LOAD_REQUEST = 'EMAILS_LOAD_REQUEST';
export const EMAILS_LOAD_SUCCESS = 'EMAILS_LOAD_SUCCESS';
export const EMAILS_LOAD_FAILURE = 'EMAILS_LOAD_FAILURE';
export const EMAILS_LOAD_CLEAR = 'EMAILS_LOAD_CLEAR';
export const EMAILS_LIST_REQUEST = 'EMAILS_LIST_REQUEST';
export const EMAILS_LIST_SUCCESS = 'EMAILS_LIST_SUCCESS';
export const EMAILS_LIST_FAILURE = 'EMAILS_LIST_FAILURE';
export const EMAILS_LIST_CLEAR = 'EMAILS_LIST_CLEAR';

export const NOTIFICATIONS_SAVE_REQUEST = 'NOTIFICATIONS_SAVE_REQUEST';
export const NOTIFICATIONS_SAVE_SUCCESS = 'NOTIFICATIONS_SAVE_SUCCESS';
export const NOTIFICATIONS_SAVE_FAILURE = 'NOTIFICATIONS_SAVE_FAILURE';
export const NOTIFICATIONS_SAVE_CLEAR = 'NOTIFICATIONS_SAVE_CLEAR';
export const NOTIFICATIONS_LOAD_REQUEST = 'NOTIFICATIONS_LOAD_REQUEST';
export const NOTIFICATIONS_LOAD_SUCCESS = 'NOTIFICATIONS_LOAD_SUCCESS';
export const NOTIFICATIONS_LOAD_FAILURE = 'NOTIFICATIONS_LOAD_FAILURE';
export const NOTIFICATIONS_LOAD_CLEAR = 'NOTIFICATIONS_LOAD_CLEAR';
export const NOTIFICATIONS_LIST_REQUEST = 'NOTIFICATIONS_LIST_REQUEST';
export const NOTIFICATIONS_LIST_SUCCESS = 'NOTIFICATIONS_LIST_SUCCESS';
export const NOTIFICATIONS_LIST_FAILURE = 'NOTIFICATIONS_LIST_FAILURE';
export const NOTIFICATIONS_LIST_CLEAR = 'NOTIFICATIONS_LIST_CLEAR';

export const ORG_LOAD_REQUEST = 'ORG_LOAD_REQUEST';
export const ORG_LOAD_SUCCESS = 'ORG_LOAD_SUCCESS';
export const ORG_LOAD_FAILURE = 'ORG_LOAD_FAILURE';
export const ORG_LOAD_CLEAR = 'ORG_LOAD_CLEAR';
export const ORG_SAVE_REQUEST = 'ORG_SAVE_REQUEST';
export const ORG_SAVE_SUCCESS = 'ORG_SAVE_SUCCESS';
export const ORG_SAVE_FAILURE = 'ORG_SAVE_FAILURE';
export const ORG_SAVE_CLEAR = 'ORG_SAVE_CLEAR';

export const INTEGRATIONS_LOAD_REQUEST = 'INTEGRATIONS_LOAD_REQUEST';
export const INTEGRATIONS_LOAD_SUCCESS = 'INTEGRATIONS_LOAD_SUCCESS';
export const INTEGRATIONS_LOAD_FAILURE = 'INTEGRATIONS_LOAD_FAILURE';
export const INTEGRATIONS_LOAD_CLEAR = 'INTEGRATIONS_LOAD_CLEAR';
export const INTEGRATIONS_SAVE_REQUEST = 'INTEGRATIONS_SAVE_REQUEST';
export const INTEGRATIONS_SAVE_SUCCESS = 'INTEGRATIONS_SAVE_SUCCESS';
export const INTEGRATIONS_SAVE_FAILURE = 'INTEGRATIONS_SAVE_FAILURE';
export const INTEGRATIONS_SAVE_CLEAR = 'INTEGRATIONS_SAVE_CLEAR';

export const SCHEDULING_LOAD_REQUEST = 'SCHEDULING_LOAD_REQUEST';
export const SCHEDULING_LOAD_SUCCESS = 'SCHEDULING_LOAD_SUCCESS';
export const SCHEDULING_LOAD_FAILURE = 'SCHEDULING_LOAD_FAILURE';
export const SCHEDULING_LOAD_CLEAR = 'SCHEDULING_LOAD_CLEAR';
export const SCHEDULING_SAVE_REQUEST = 'SCHEDULING_SAVE_REQUEST';
export const SCHEDULING_SAVE_SUCCESS = 'SCHEDULING_SAVE_SUCCESS';
export const SCHEDULING_SAVE_FAILURE = 'SCHEDULING_SAVE_FAILURE';
export const SCHEDULING_SAVE_CLEAR = 'SCHEDULING_SAVE_CLEAR';

export const GROUPS_MEMBERADD_REQUEST = 'GROUPS_MEMBERADD_REQUEST';
export const GROUPS_MEMBERADD_SUCCESS = 'GROUPS_MEMBERADD_SUCCESS';
export const GROUPS_MEMBERADD_FAILURE = 'GROUPS_MEMBERADD_FAILURE';
export const GROUPS_MEMBERADD_CLEAR = 'GROUPS_MEMBERADD_CLEAR';
export const GROUPS_MEMBERDEL_REQUEST = 'GROUPS_MEMBERDEL_REQUEST';
export const GROUPS_MEMBERDEL_SUCCESS = 'GROUPS_MEMBERDEL_SUCCESS';
export const GROUPS_MEMBERDEL_FAILURE = 'GROUPS_MEMBERDEL_FAILURE';
export const GROUPS_MEMBERDEL_CLEAR = 'GROUPS_MEMBERDEL_CLEAR';
export const GROUPS_MEMBERUPDATE_REQUEST = 'GROUPS_MEMBERUPDATE_REQUEST';
export const GROUPS_MEMBERUPDATE_SUCCESS = 'GROUPS_MEMBERUPDATE_SUCCESS';
export const GROUPS_MEMBERUPDATE_FAILURE = 'GROUPS_MEMBERUPDATE_FAILURE';
export const GROUPS_MEMBERUPDATE_CLEAR = 'GROUPS_MEMBERUPDATE_CLEAR';
export const GROUPS_REASSIGN_REQUEST = 'GROUPS_REASSIGN_REQUEST';
export const GROUPS_REASSIGN_SUCCESS = 'GROUPS_REASSIGN_SUCCESS';
export const GROUPS_REASSIGN_FAILURE = 'GROUPS_REASSIGN_FAILURE';
export const GROUPS_REASSIGN_CLEAR = 'GROUPS_REASSIGN_CLEAR';
export const GROUPS_USERLIST_REQUEST = 'GROUPS_USERLIST_REQUEST';
export const GROUPS_USERLIST_SUCCESS = 'GROUPS_USERLIST_SUCCESS';
export const GROUPS_USERLIST_FAILURE = 'GROUPS_USERLIST_FAILURE';
export const GROUPS_USERLIST_CLEAR = 'GROUPS_USERLIST_CLEAR';
export const GROUPS_INFO_REQUEST = 'GROUPS_INFO_REQUEST';
export const GROUPS_INFO_SUCCESS = 'GROUPS_INFO_SUCCESS';
export const GROUPS_INFO_FAILURE = 'GROUPS_INFO_FAILURE';
export const GROUPS_INFO_CLEAR = 'GROUPS_INFO_CLEAR';

export const CARETEAM_ASSIGNGROUP_REQUEST = 'CARETEAM_ASSIGNGROUP_REQUEST';
export const CARETEAM_ASSIGNGROUP_SUCCESS = 'CARETEAM_ASSIGNGROUP_SUCCESS';
export const CARETEAM_ASSIGNGROUP_FAILURE = 'CARETEAM_ASSIGNGROUP_FAILURE';
export const CARETEAM_ASSIGNGROUP_CLEAR = 'CARETEAM_ASSIGNGROUP_CLEAR';
