import { useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import { DeleteReturnI } from './definitions';

const useApiDel = (): DeleteReturnI => {
  const [value, setValue] = useState();
  const { loading, error, del, data } = useFetch({ cachePolicy: CachePolicies.NO_CACHE });

  const doDel = async (url: string) => {
    const resp = await del(url);
    if (!error) {
      setValue(resp);
    }
    return resp;
  };

  if (error) {
    return {
      del: doDel,
      data: value,
      loading,
      error: data,
    };
  }
  return {
    del: doDel,
    data: value,
    loading,
  };
};

export default useApiDel;
