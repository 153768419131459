import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Button } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import CreateEditTermModal from './Terms_/CreateEditTermModal';

import * as actions from '../../../../actions/scheduler';
import { safe } from '../../../../helpers/reduxSelectors';
import { processEventNotification } from '../../../../helpers/util';

class Terms extends React.Component {
  state = {
    modalVisible: false
  };

  componentDidMount() {
    this.props.getProviderTerm(this.props.provider_id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.provider_id !== this.props.provider_id) {
      this.props.getProviderTerm(this.props.provider_id);
    }

    processEventNotification(prevProps,this.props,'createEvent','Term Created');
    processEventNotification(prevProps,this.props,'updateEvent','Term Updated');
    processEventNotification(prevProps,this.props,'removeEvent','Term Removed');
  }

  showModal = (data,term_id) => {
    this.setState({
      modalData: data,
      modalTermId: term_id,
      modalVisible: true
    })
  }

  closeModal = () => {
    this.setState({
      modalData: {},
      modalTermId: 0,
      modalVisible: false
    });
  }

  onNewTerm = () => {
    this.showModal({},0);
  }

  deleteRecord = (term_id) => {
    this.props.removeProviderTerm(this.props.provider_id,term_id)
  }

  editRecord = (x) => {
    this.showModal(x,x.id);
  }

  renderTermsTable = () => {
    const columns = [{
      title: 'Start',
      dataIndex: 'start_date',
      render: (value) => {
        return moment(value).format('M/D/YYYY')
      }
    },{
      title: 'End',
      dataIndex: 'end_date',
      render: (value) => {
        return moment(value).format('M/D/YYYY')
      }
    },{
      title: 'Productivity Start Date',
      dataIndex: 'productivity_start_date',
      render: (value) => {
        return moment(value).format('M/D/YYYY')
      }
    },{
      title: 'Review Date',
      dataIndex: 'review_date',
      render: (value) => {
        return value ? moment(value).format('M/D/YYYY') : ''
      }
    },{
      title: 'Actions',
      dataIndex: 'id',
      align: 'right',
      render: (value,record) => {
        return (
          <span style={{whiteSpace: 'nowrap'}}>
            <Button type='link' icon={<EditOutlined />} onClick={()=>{this.editRecord(record)}}/>
            <Button type='link' icon={<DeleteOutlined />} onClick={()=>{this.deleteRecord(record.id)}}/>
          </span>
        );
      }
    }];

    return (
      <div style={{paddingTop:'15px'}}>
        <Table
          columns={columns}
          dataSource={this.props.data}
          size='small'
          rowKey='id'
          pagination={false}
        />
      </div>
    );
  }

  render() {
    const provider = this.props.providerList.find(x=>x.id === this.props.provider_id);
    const provider_name = safe(provider,'full_name');

    return (
      <div style={{padding:'5px'}}>
        <table style={{width:'100%'}}>
          <tbody>
            <tr>
              <td style={{textAlign:'left'}}>
                <h3>Terms: {provider_name}</h3>
              </td>
              <td style={{textAlign:'right'}}>
                <Button type='primary' onClick={this.onNewTerm}>New Term</Button>
              </td>
            </tr>
          </tbody>
        </table>

        { this.renderTermsTable() }

        <CreateEditTermModal
          visible={this.state.modalVisible}
          data={this.state.modalData}
          term_id={this.state.modalTermId}
          onClose={this.closeModal}
          provider_id={this.props.provider_id}

        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: safe(state,'scheduler.provider.get.data',[]),
    providerList: safe(state,'scheduler.provider.list.data',[]),
    createEvent: safe(state,'scheduler.provider.create',{}),
    removeEvent: safe(state,'scheduler.provider.remove',{}),
    updateEvent: safe(state,'scheduler.provider.update',{}),
  }
}

export default connect(mapStateToProps,actions)(Terms);
