import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, message, Spin } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/shared';

class GroupsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id,10)
    }
  }

  componentDidMount() {
    if (this.state.id !== 0) {
      this.props.groupLoad(this.state.id);
    } else {
      this.props.groupLoadClear();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load !== this.props.load) {
      if (this.props.load.error) {
        this.props.history.push('/settings/groups');
        message.error('Unable to load groups information');
      }
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.success) {
        this.props.groupSaveClear();
        this.props.history.push('/settings/groups');
        message.success('Group saved');
      } else if (this.props.error) {
        message.error('Unable to save group');
      }
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        this.props.groupCreateClear();
        this.props.history.push('/settings/groups');
        message.success('Group created');
      } else if (this.props.error) {
        message.error('Unable to save group');
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.id === 0) {
          this.props.groupCreate(values);
        } else {
          this.props.groupSave(this.state.id,values);
        }
      }
    });
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    //const { TextArea } = Input;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const data = safe(this.props,'load.data',{});

    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off" className='locations-form'>
        <FormItem {...formItemLayout} label="Group Name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.name
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Description"
        >
          {getFieldDecorator('description', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.description
          })(
            <Input />
          )}
        </FormItem>

        <br />

        <Row>
          <Col xs={36} sm={16}><Button htmlType="submit" style={{float:'right'}} disabled={this.props.isLoading} type="primary">Save</Button></Col>
        </Row>
      </Form>
    );
  }

  render() {
    const name = safe(this.props,'load.data.name','None');

    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <h3>Administrative Message Groups / {name}</h3>
          { this.renderForm() }
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'shared.groups.load.processing',false) ||
      safe(state,'shared.groups.save.processing',false) ||
      safe(state,'shared.groups.create.processing',false)
    ) ? true : false,
    load: safe(state,'shared.groups.load',{}),
    save: safe(state,'shared.groups.save',{}),
    create: safe(state,'shared.groups.create',{})
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(GroupsEdit)));
