import React from 'react';
import { connect } from 'react-redux';
import { Input, Button } from 'antd';

import * as actions from '../../../../../../actions/emr';
import { safe } from '../../../../../../helpers/reduxSelectors';

class CreateMessage extends React.Component {
  state = {
    message: ''
  }

  onChange = (e) => {
    this.setState({ message: e.target.value });
  }

  onSend = () => {
    this.props.addMessage(this.props.thread_id,this.props.currentInbox,this.state.message);
    this.setState({ message: '' });
  }

  render() {
    return (
      <div style={{flexGrow:0, margin: '0px 5px 5px 5px'}}>
        <Input.TextArea
          rows={4}
          value={this.state.message}
          onChange={this.onChange}
          disabled={this.props.disabled}
          placeholder='Enter your message here'
          style={{marginBottom:'5px'}}
        />
        <Button
          disabled={this.state.message.trim().length===0}
          type='primary'
          style={{width:'100%'}}
          onClick={this.onSend}
        >
          Send
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state,ownProps) => {
  return {
    currentInbox: safe(state,'emr.inbox.current')
  }
}

export default connect(mapStateToProps,actions)(CreateMessage);
