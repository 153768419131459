/**
 * InlineProviderWrapper.tsx
 * Provides inline report API / form / state functionality on context
 */
import { useEffect, useState } from 'react';
import InlineContext, { defaultState } from 'components/App_/ReportsV2_/InlineContext';
import { Form, notification } from 'antd';
import useApiPost from 'hooks/useApiPost';
import { useLocation } from 'react-router-dom';

interface PropsI {
  children: React.ReactNode;
}

const InlineProviderWrapper: React.FC<PropsI> = (props: PropsI): any => {
  const [baseState, setState] = useState(defaultState);
  const [form] = Form.useForm();
  const runReportAPI = useApiPost('/api/v2/reports/run');
  const location = useLocation();

  // reset inlineData when the view changes to prevent stale table data
  useEffect(() => {
    setState((s) => ({ ...s, inlineData: undefined }));
  }, [location]);

  const doRunReport = async () => {
    if (!runReportAPI?.loading) {
      const validatedData = await form.validateFields();
      if (validatedData) {
        const inlineData = await runReportAPI?.send(validatedData);
        if (inlineData?.errors) {
          notification.error({
            message: 'Report Display Problem',
            description: inlineData?.message,
          });
        }
        setState((s) => ({ ...s, inlineData }));
      }
    }
  };

  const state = {
    ...baseState,
    form,
    runReportAPI,
  };

  const context = {
    state,
    setState,
    doRunReport,
  };

  return <InlineContext.Provider value={context}>{props.children}</InlineContext.Provider>;
};

export default InlineProviderWrapper;
