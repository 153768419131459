import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Input, message, Spin, DatePicker, Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import RateTableEdit from './RateTablesEdit_/RateTableEdit';

import * as pm_actions from '../../../actions/pm';
import * as shared_actions from '../../../actions/shared';
import { safe } from '../../../helpers/reduxSelectors';

class FeeScheduleAddEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id,10),
      feeSchedule: []
    }
  }

  componentDidMount() {
    this.props.feeScheduleGroupList();
    this.props.feeScheduleLoad(this.state.id);
    this.props.getLocationsList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load.data !== this.props.load.data) {
      this.setState({
        feeSchedule: safe(this.props,'load.data.rates',[])
      });
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.success) {
//        this.props.rateTableSaveClear();
        this.props.history.push('/settings/feeschedules');
        message.success('Fee schedule saved');
      } else if (this.props.error) {
        message.error('Unable to save fee schedule');
      }
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
//        this.props.rateTableCreateClear();
        this.props.history.push('/settings/feeschedules');
        message.success('Fee schedule created');
      } else if (this.props.error) {
        message.error('Unable to save fee schedule');
      }
    }
  }

  validateFeeSchedule = () => {
    return (this.state.feeSchedule.filter(x => {
      return (
        isNaN(parseFloat(x.md)) || x.md.length === 0 ||
        isNaN(parseFloat(x.np)) || x.np.length === 0 ||
        isNaN(parseFloat(x.ph)) || x.ph.length === 0 ||
        isNaN(parseFloat(x.ma)) || x.ma.length === 0
      ) ? true : false;
    }).length === 0) ? true : false;
  }

  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (!this.validateFeeSchedule()) {
          message.error('The fee schedule must be complete and only include numbers.');
        } else {
          const data = {
            ...values,
            rates: this.state.feeSchedule
          };
          if (this.state.id === 0) {
            this.props.feeScheduleCreate(data);
          } else {
            this.props.feeScheduleUpdate(this.state.id,data);
          }
        }
      }
    });
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const data = safe(this.props,'load.data',{});

    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off" className='ratetable-form'>

        <FormItem {...formItemLayout} label="Fee Schedule Group">
          {getFieldDecorator('feeschedule_group_id', {
            rules: [{ required: true, message: " " }],
            initialValue: data.feeschedule_group_id
          })(
            <Select>
              { this.props.feescheduleGroupList.map(x => (<Option key={x.id} value={x.id}>{x.name}</Option>))}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Location">
          {getFieldDecorator('location_id', {
            initialValue: data.location_id
          })(
            <Select>
              <Option key={0} value={null}>All Locations</Option>
              { this.props.locationList.filter(y=>y.name !== 'None').map(x => (<Option key={x.id} value={x.id}>{x.name}</Option>))}
            </Select>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Starting Date"
        >
          {getFieldDecorator('start_date', {
            rules: [{ required: true, message: " " }],
            initialValue: (data.start_date) ? moment(data.start_date) : moment()
          })(
            <DatePicker format='M/DD/YYYY' autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Ending Date"
        >
          {getFieldDecorator('end_date', {
            rules: [{ required: true, message: " "}],
            initialValue: (data.end_date) ? moment(data.end_date) : moment()
          })(
            <DatePicker format='M/DD/YYYY' autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Description"
        >
          {getFieldDecorator('description', {
            initialValue: data.description
          })(
            <Input.TextArea placeholder='Optional description of this fee schedule' rows={3} autoComplete="off"/>
          )}
        </FormItem>

      </Form>
    );
  }

  rtOnChange = (x) => {
    this.setState({
      feeSchedule: x
    });
  }

  render() {
    return (
      <div style={{padding:'10px'}}>
        <h3>{(this.state.id) ? 'Edit' : 'Create'} a Fee Schedule</h3>
        <Spin spinning={this.props.isBusy} size="large">
          {this.renderForm()}

          <Row style={{paddingTop:'20px'}}>
            <Col xs={10} sm={4}>
              &nbsp;
            </Col>
            <Col xs={26} sm={12}>
              <RateTableEdit value={this.state.feeSchedule} onChange={this.rtOnChange}/>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={36} sm={16}>
              <Button
                style={{float:'right'}}
                disabled={this.props.isBusy}
                onClick={this.handleSubmit}
                type="primary">
                Save Fee Schedule
              </Button>
            </Col>
          </Row>

        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    load: safe(state,'pm.feeschedule.schedule.load'),
    save: safe(state,'pm.feeschedule.schedule.update'),
    create: safe(state,'pm.feeschedule.schedule.create'),
    feescheduleGroupList: safe(state,'pm.feeschedule.group.list.data',[]),
    locationList: safe(state,'shared.location.list.data',[]),
    isBusy: (
      safe(state,'pm.feeschedule.schedule.load.processing') ||
      safe(state,'pm.feeschedule.schedule.update.processing') ||
      safe(state,'pm.feeschedule.schedule.create.processing') ||
      safe(state,'pm.feeschedule.group.list.processing') ||
      safe(state,'shared.location.list.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,{...shared_actions,...pm_actions})(Form.create()(withRouter(FeeScheduleAddEdit)));
