import React from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Spin } from 'antd';

import * as actions from '../../../../../actions/billing';
import { safe } from '../../../../../helpers/reduxSelectors';
import { processEventNotification } from '../../../../../helpers/util';

class NotesModal extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.form.resetFields();
    }
    processEventNotification(prevProps,this.props,'addNoteEvent','Service Line Note Added');
  }

  handleOk = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.addServiceLineNote(this.props.serviceId,values.description);
        this.props.closeDialog();
      }
    });
  }

  renderModalContent = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form>
        <Form.Item>
          {getFieldDecorator('description', {
            rules: [
              { required: true, message: " " },
            ],
          })(
            <Input.TextArea rows={4} autoSize={{minRows:4,maxRows:6}}/>
          )}
        </Form.Item>
      </Form>
    );
  }

  render() {
    return (
      <Modal
        title={"Enter a Note for the Service Line: "+this.props.serviceId}
        visible={this.props.visible}
        onOk={this.handleOk}
        okText='Submit'
        onCancel={this.props.closeDialog}
      >
        <Spin spinning={this.props.isBusy}>
          {this.renderModalContent()}
        </Spin>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.serviceline.load.processing',false)
    ) ? true : false,
    addNoteEvent: safe(state,'billing.serviceline.addnote',{})
  }
}

export default connect(mapStateToProps,actions)(Form.create()(NotesModal));
