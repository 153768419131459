import React from 'react';
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { Modal, Upload, message, Button, Table, Spin } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { safe } from '../../../../../helpers/reduxSelectors';
import * as actions from '../../../../../actions/emr';
//import TransferDocumentsModal from './TransferDocumentsModal';

class Documents extends React.Component {
  state = {
    selectedRowKeys: [],
    transferModalVisible: false,
    uploading: false,
    uploadProgress: undefined
  };

  openTransferModal = () => {
    this.setState({
      transferModalVisible: true
    });
  }

  closeTransferModal = () => {
    this.setState({
      transferModalVisible: false
    });
  }

  closeTransferModalFull = () => {
    this.closeTransferModal();
    this.handleCancel();
  }

  componentDidMount() {
    if (this.props.patientId) {
      this.props.getDocuments(this.props.patientId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible === true) {
      this.setState({
        selectedRowKeys: []
      });
      this.props.getDocuments(this.props.patientId);
    }

    if (prevProps.patientId !== this.props.patientId && this.props.visible) {
      this.setState({
        selectedRowKeys: []
      });
      this.props.getDocuments(this.props.patientId);
    }

    if (prevProps.doctransfer !== this.props.doctransfer) {
      if (this.props.doctransfer.success) {
        message.success('Documents Transferred')
        this.handleOk();
        this.props.history.push('/emr/patients/'+this.props.patientId+'/generalnote/'+this.props.doctransfer.data.note_id);
      } else if (this.props.doctransfer.error) {
        message.error('Unable to create general chart note with attachments');
        this.handleOk();
      }
    }
  }

  handleOk = () => {
    this.props.closeDialog();
  }

  handleCancel = () => {
    this.props.closeDialog();
  }

  getTypeFromMime = (type) => {
    switch (type) {
      case 'application/pdf': return 'PDF';
      case 'image/jpg':
      case 'image/jpeg': return 'JPG';
      case 'image/png': return 'PNG';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword': return 'DOC';
      default: return 'OTHER';
    }
  }

  getUploaderName = (x) => {
    if (x.type === 2) {
      return safe(x,'user.full_name');
    } else if (x.type === 1) {
      return safe(x,'portal.name');
    } else {
      return 'Other'
    }
  }

  deleteDocument = (id) => {
    this.props.deleteDocument(this.props.patientId,id);
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  contents = () => {
    if (this.props.documents.length === 0) {
      return (
        <div style={{textAlign:'center',height:'200px',paddingTop:'20px'}}>
          No Documents Stored
        </div>
      );
    }

    const columns = [{
      title: 'Title',
      dataIndex: 'title',
      render: (val,record) => {
        return (
          <a href={"/api/v1/docs/"+record.id} target='_blank'>{val}</a>
        );
      }
    },{
      title: 'Author',
      dataIndex: 'user',
      render: (val,record) => {
        return this.getUploaderName(record);
      }
    },{
      title: 'Uploaded Date',
      dataIndex: 'createdAt',
      render: (val,record) => {
        return moment(val).format('M/D/YYYY HH:mm:ss')
      }
    },{
      title: '',
      dataIndex: 'id',
      render: (val,record) => {
        return (
          <a onClick={()=>this.deleteDocument(val)}>
            <DeleteOutlined />
          </a>
        );
      }
    }];

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div style={{height:'200px',overflow:'auto',paddingTop:'20px'}}>
        <Table
          dataSource={this.props.documents}
          columns={columns}
          pagination={false}
          size='small'
          rowKey='id'
          rowSelection={rowSelection}
        />
      </div>
    );
  }

  renderUploadControl = () => {
    var self = this;
    const props = {
      action: '/api/v1/documents/' + this.props.patientId,
      showUploadList: false,
      multiple: false,
      onChange(info) {
        if (info.event) {
          self.setState({
            uploadProgress: Math.trunc(info.event.percent),
            uploading: true
          });
        }
        if (info.file.status === 'done') {
          self.setState({ uploadProgress: undefined,uploading: false });
          self.props.getDocuments(self.props.patientId);
        } else if (info.file.status === 'error') {
          self.setState({ uploadProgress: undefined,uploading: false });
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
      <div style={{width:'100%'}}>
        <Upload.Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-hint">Click or drag a single file to this area to upload</p>
        </Upload.Dragger>
      </div>
    );
  }

  handleTransfer = () => {
    this.props.transferDocumentsToChart(this.props.patientId,this.state.selectedRowKeys);
  }

  getModalFooter = () => {
    const buttons = [<Button key={1} type='primary' onClick={this.handleCancel}>Close</Button>];
    if (this.state.selectedRowKeys.length > 0) {
      buttons.unshift(<Button key={2} type='primary' onClick={this.handleTransfer}>Transfer to Chart ({this.state.selectedRowKeys.length})</Button>);
    }
    return buttons;
  }

  render() {
    return (
      <Modal
        title={safe(this.props,'patientData.full_name')+ ' Document Transfer Box'}
        visible={this.props.visible}
        footer={this.getModalFooter()}
        onCancel={this.handleCancel}
        width='700px'
      >
        <Spin spinning={this.state.uploading || this.props.isBusy}>
          {this.renderUploadControl()}
          {this.contents()}
        </Spin>
      </Modal>
    );
  }

}

/*
<TransferDocumentsModal
  visible={this.state.transferModalVisible}
  onClose={this.closeTransferModal}
  onFullClose={this.closeTransferModalFull}
  patientId={this.props.patientId}
  documentIds={this.state.selectedRowKeys}
/>
*/

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'emr.documents.transfer.processing',false),
    documents: safe(state,'emr.documents.list.data',[]),
    doctransfer: safe(state,'emr.documents.transfer',{}),
    patientData: safe(state,'shared.patient.load.data',{})
  }
}

export default connect(mapStateToProps,actions)(withRouter(Documents));
