import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Layout, Spin } from 'antd';
import numeral from 'numeral';
import { withRouter, Link } from 'react-router-dom';

import * as actions from '../../../../actions/billing';
import { safe } from '../../../../helpers/reduxSelectors';

class PracticeAr extends Component {

  floatRound = (x) => {
    return Math.round((x + 0.00001) * 100) / 100;
  }

  computeTotals = () => {
    const total = {
      carrier_name: 'Total',
      ar0to30: 0.0,
      ar31to60: 0.0,
      ar61to90: 0.0,
      arOver90: 0.0,
      totalOver30: 0.0,
      total: 0.0
    };

    if (this.props.data && this.props.data.length > 0) {
      this.props.data.forEach(x => {
        total.ar0to30 += x.ar0to30;
        total.ar31to60 += x.ar31to60;
        total.ar61to90 += x.ar61to90;
        total.arOver90 += x.arOver90;
        total.total += x.total;
        total.totalOver30 += x.totalOver30;
      });

      total.ar0to30 = this.floatRound(total.ar0to30);
      total.ar31to60 = this.floatRound(total.ar31to60);
      total.ar61to90 = this.floatRound(total.ar61to90);
      total.arOver90 = this.floatRound(total.arOver90);
      total.total = this.floatRound(total.total);
      total.totalOver30 = this.floatRound(total.totalOver30);
    }

    return total;
  }

  render() {
    const columns=[{
      title: 'Carrier',
      dataIndex: 'carrier_name',
      render: (value,record) => {
        return (value !== 'Total') ? <Link to={'/billing/ar/2?c='+record.id}>{value}</Link> : value;
      }
    },{
      title: '0-30 Days',
      dataIndex: 'ar0to30',
      align:'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    },{
      title: '31-60 Days',
      dataIndex: 'ar31to60',
      align:'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    },{
      title: '61-90 Days',
      dataIndex: 'ar61to90',
      align:'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    },{
      title: '90 Days+',
      dataIndex: 'arOver90',
      align: 'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    },{
      title: 'Total Over 30 Days',
      dataIndex: 'totalOver30',
      align: 'right',
      render: (value) => numeral(value).format('$0,0.00')
    },{
      title: 'Total Balance',
      dataIndex: 'total',
      align:'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    }];

    return (
      <Layout>
        <Layout.Content style={{backgroundColor:'white'}}>
          <Spin spinning={this.props.isBusy}>
            <div>
              <table style={{marginBottom:'10px',width:'100%'}}>
                <tbody>
                  <tr>
                    <td style={{textAlign:'right'}}>
                      <a href='/api/v1/ar/practice?csv=1'>CSV Download</a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Table
                columns={columns}
                dataSource={this.props.data}
                size='small'
                rowKey='carrier_name'
                pagination={false}
              />
              <br />
              <Table
                columns={columns}
                dataSource={[this.computeTotals()]}
                showHeader={true}
                size='small'
                rowKey='carrier_name'
                pagination={false}
              />
            </div>
          </Spin>
        </Layout.Content>
      </Layout>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'billing.ar.list4.processing',false) ? true : false,
    data: safe(state,'billing.ar.list4.data',[]),
  }
}

export default connect(mapStateToProps,actions)(withRouter(PracticeAr));
