import React from 'react';
import { Row, Col, Select } from 'antd';
import moment from 'moment';

class DateFormControl extends React.Component {
  state = {};

  static validator(rule,value,callback) {
    (!value || moment(value).isValid()) ? callback() : callback('Invalid date')
  }

  setDate = (value) => {
    if (value === undefined) {
      this.setState({
        month: undefined,
        day: undefined,
        year: undefined
      })
    }

    if (moment.isMoment(value) && value.isValid()) {
      this.setState({
        month: value.month(),
        day: value.date(),
        year: value.year()
      });
    }
    //throw new Error("input requires a moment object")
  }

  componentDidMount() {
    this.setDate(this.props.value);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setDate(this.props.value);
    }
  }

  getDate = () => {
    if (this.state.month === undefined || this.state.day === undefined ||
      this.state.year === undefined) {
      return undefined;
    }
    return moment([this.state.year, this.state.month, this.state.day]);
  }

  triggerDateChange = () => {
    const d = this.getDate();
    if (d) {
      this.props.onChange(d);
    }
  }

  onMonthChange = (value) => {
    this.setState({ month:value },this.triggerDateChange);
  }

  onDayChange = (value) => {
    this.setState({ day:value },this.triggerDateChange);
  }

  onYearChange = (value) => {
    this.setState({ year:value },this.triggerDateChange);
  }

  getYearList = () => {
    const year = new Date().getFullYear();
    const result = [];
    for (let i = 0; i < 100; i++) {
      result.push(year-i);
    }
    return result;
  }

  render() {
    const Option = Select.Option;
    const yl = this.getYearList();

    return (
      <div style={{width:'100%'}} className='date-form-control'>
        <Row gutter={2}>
          <Col span={8}>
            <Select
              placeholder='Month'
              value={this.state.month}
              onChange={this.onMonthChange}
              showSearch
              optionFilterProp="children"
              autoComplete='off'
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={0}>Jan</Option>
              <Option value={1}>Feb</Option>
              <Option value={2}>Mar</Option>
              <Option value={3}>Apr</Option>
              <Option value={4}>May</Option>
              <Option value={5}>Jun</Option>
              <Option value={6}>Jul</Option>
              <Option value={7}>Aug</Option>
              <Option value={8}>Sep</Option>
              <Option value={9}>Oct</Option>
              <Option value={10}>Nov</Option>
              <Option value={11}>Dec</Option>
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder='Day'
              value={this.state.day}
              onChange={this.onDayChange}
              showSearch
              optionFilterProp="children"
              autoComplete='off'
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
              <Option value={3}>3</Option>
              <Option value={4}>4</Option>
              <Option value={5}>5</Option>
              <Option value={6}>6</Option>
              <Option value={7}>7</Option>
              <Option value={8}>8</Option>
              <Option value={9}>9</Option>
              <Option value={10}>10</Option>
              <Option value={11}>11</Option>
              <Option value={12}>12</Option>
              <Option value={13}>13</Option>
              <Option value={14}>14</Option>
              <Option value={15}>15</Option>
              <Option value={16}>16</Option>
              <Option value={17}>17</Option>
              <Option value={18}>18</Option>
              <Option value={19}>19</Option>
              <Option value={20}>20</Option>
              <Option value={21}>21</Option>
              <Option value={22}>22</Option>
              <Option value={23}>23</Option>
              <Option value={24}>24</Option>
              <Option value={25}>25</Option>
              <Option value={26}>26</Option>
              <Option value={27}>27</Option>
              <Option value={28}>28</Option>
              <Option value={29}>29</Option>
              <Option value={30}>30</Option>
              <Option value={31}>31</Option>
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder='Year'
              value={this.state.year}
              onChange={this.onYearChange}
              showSearch
              optionFilterProp="children"
              autoComplete='off'
            >
              {yl.map(x=><Option key={x} value={x}>{x}</Option>)}
            </Select>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DateFormControl;
