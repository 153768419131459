import React from 'react';
import { connect } from 'react-redux';
import { Spin, Table } from 'antd';
import { Link } from 'react-router-dom';
import numeral from 'numeral';

import { safe } from '../../../helpers/reduxSelectors';
import * as billing_actions from '../../../actions/billing';
import * as shared_actions from '../../../actions/shared';
//import { processEventNotification } from '../../../helpers/util';
//    processEventNotification(prevProps,this.props,'autopayEvent','Payments Applied');

class Autopay extends React.Component {
  constructor(props) {
    super(props);
    this.pageSize = 15;
  }

  componentDidMount() {
    this.props.getAutopayList(this.props.autopaylist_page,this.pageSize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.autopaylist_page !== this.props.autopaylist_page) {
      this.props.getAutopayList(this.props.autopaylist_page,this.pageSize);
    }
  }
  tableChange = (x) => {
    this.props.setBillingState({
      autopaylist_page:x.current
    });
  }

  render() {
    const columns = [{
      title: 'Patient',
      dataIndex: 'id',
      width: 200,
      render: (value,record) => {
        return <Link to={'/billing/patients/'+value}>{safe(record,'patients.full_name','')}</Link>
      }
    },{
      title: 'Amount Due',
      dataIndex: 'pt_outstanding',
      width: 200,
      align: 'right',
      render: (value) => numeral(value).format('$0,0.00')
    },{
      title: 'Unapplied Payments',
      dataIndex: 'pt_total_collected',
      width: 200,
      align: 'right',
      render: (value,record) => numeral(record.pt_total_collected-record.pt_paid).format('$0,0.00')
    },{
      title: 'Autopay',
      render: () => 'On'
    }];

    return (
      <div className='main'>
        <Spin spinning={this.props.isBusy}>
          <h3>Autopay Patient List</h3>
          <Table
            columns={columns}
            dataSource={this.props.rows}
            bordered={false}
            size='small'
            onChange={this.tableChange}
            rowKey='id'
            pagination={{
              current: this.props.autopaylist_page,
              pageSize: this.pageSize,
              total: this.props.count
            }}
          />
        </Spin>
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.autopay.list.processing',false)
    ) ? true : false,
    rows: safe(state,'billing.autopay.list.data.rows',[]),
    count: safe(state,'billing.autopay.list.data.count',1),
    autopaylist_page: safe(state,'shared.domain.billing.autopaylist_page',1),
    autopayEvent: safe(state,'billing.autopay.list')
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(Autopay);
