import React from 'react';
import { connect } from 'react-redux';
import { AutoComplete } from 'antd';

import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/intakes';

class PortalAccountSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      patientSelection: undefined,
    };
    this.conflict = false;
  }

  getSource = () => {
    return this.props.patientList.map((item) => {
      return {
        value: item.id,
        text: item.email,
      };
    });
  };

  handleSearch = (value) => {
    this.props.portalAccountSearch(value);
  };

  selectPatient = (value, option) => {
    this.props.portalAccountSearchClear();
    this.setState({
      patientSelection: undefined,
    });
    this.conflict = true;
    const id = parseInt(value, 10);
    this.props.onSelectPatient(this.props.patientList.find((item) => item.id === id));
  };

  onChangePatientValue = (value) => {
    if (this.conflict) {
      this.conflict = false;
    } else {
      this.setState({
        patientSelection: value,
      });
    }
  };

  onBlur = () => {
    this.props.portalAccountSearchClear();
    this.setState({
      patientSelection: undefined,
    });
  };

  render() {
    return (
      <AutoComplete
        style={{ width: 300, paddingLeft: '30px' }}
        placeholder="Search by portal user email address"
        allowClear={true}
        dataSource={this.getSource()}
        onSearch={this.handleSearch}
        value={this.state.patientSelection || ''}
        onChange={this.onChangePatientValue}
        onSelect={this.selectPatient}
        onBlur={this.onBlur}
        defaultActiveFirstOption={false}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    patientList: safe(state, 'intakes.portal_search.data', []),
  };
};

export default connect(mapStateToProps, actions)(PortalAccountSelector);
