import React from 'react';
import { Select, Button, Card, Row, Col, Avatar, Result, Spin, Divider, Table } from 'antd';
import { connect } from 'react-redux';

import { safe, isPrescriber } from '../../../helpers/reduxSelectors';
import * as emr_actions from '../../../actions/emr';
import * as shared_actions from '../../../actions/shared';
import { VLayout, VLayoutContent, VLayoutHeader, HLayout, HLayoutRight, HLayoutContent } from '../../shared/Layout';
import SessionList from './Search_/SessionList';
import ReserveSessionModal from './Search_/ReserveSessionModal';
import RescheduleSessionModal from './Search_/RescheduleSessionModal';
import SessionPanel from './Search_/SessionPanel';
import PatientHeader from './Search_/PatientHeader';
import { processEventNotification } from '../../../helpers/util';

class Search extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      // Whether list of past sessions is expanded beyond 4
      past_list_expanded: false,

      // Vars for controlling reserving/rescheduling modals
      reserve_session_modal_visible: false,
      reschedule_session_modal_visible: false,
      modal_data: null,

      // Vars for the top search.
      treatment_type: 1,
      duration: 60,
      intake_followup: 1,
      provider_id: 0,
      location_id: 0,
      number_of_weeks: 0,
      modality: 1,
    }
  }

  // Convenience functions to access react router URL params
  getPatientId = () => (this.props.match.params.patient_id) ? parseInt(this.props.match.params.patient_id,10) : 0;
  getSessionId = () => (this.props.match.params.session_id) ? parseInt(this.props.match.params.session_id,10) : 0;
  getPaneId = () => (this.props.match.params.pane_id) ? parseInt(this.props.match.params.pane_id,10) : 0;

  componentDidMount() {
    const patient_id = this.getPatientId();
    const session_id = this.getSessionId();
    const pane_id = this.getPaneId();

    this.props.getProviderSearchParams(patient_id,(session_id && pane_id === 3) ? { session_id } : {});
    this.props.getSessionHistoryAndFuture(patient_id);
    this.props.loadCareTeam(patient_id);
    this.props.getLocationsList();
    this.props.getUserList();

    if (session_id) {
      this.props.loadSession(session_id);
    }

    if (patient_id) {
      this.props.setEmrState({
        patientId: patient_id
      });
    }

  }

  componentDidUpdate(prevProps,prevState) {
    const patient_id = this.getPatientId();
    const session_id = this.getSessionId();
    const pane_id = this.getPaneId();

    // If the URL line changes and the patient_id/session_id vars change,
    // then reload in the appropriate data.

    if (prevProps.match.params.patient_id !== this.props.match.params.patient_id && patient_id) {
      this.props.getProviderSearchParams(patient_id);
      this.props.getSessionHistoryAndFuture(patient_id);
      this.props.loadCareTeam(patient_id);
      this.props.setEmrState({
        patientId: patient_id
      });
    }

    if (prevProps.match.params.session_id !== this.props.match.params.session_id && session_id) {
      this.props.loadSession(session_id);
    }

    // If the request to get new search params from the server succeeds, set them
    // in the state. Subsequently state changes will trigger a search call to the
    // server.
    if (prevProps.search_params_event.success !== this.props.search_params_event.success &&
      this.props.search_params_event.success) {
      this.setState(this.props.search_params);
    }


    processEventNotification(prevProps,this.props,'appt_reserve_event','Appointment Reserved');
    processEventNotification(prevProps,this.props,'appt_update_event','Appointment Updated');
    processEventNotification(prevProps,this.props,'appt_remove_event','Appointment Removed');
    processEventNotification(prevProps,this.props,'appt_latecancel_event','Appointment Late Cancelled');


    if (prevProps.match.params.pane_id !== this.props.match.params.pane_id &&
      pane_id === 3) {
      this.props.getProviderSearchParams(patient_id,{
        session_id: session_id
      });
    }

    // Once a session has been reserved in the other pane, reload the history list
    if (prevProps.appt_reserve_event.success !== this.props.appt_reserve_event.success &&
      this.props.appt_reserve_event.success) {
      this.props.getSessionHistoryAndFuture(patient_id);
//      console.log('reserve event success');
      this.search();
    }

    if (prevProps.appt_update_event.success !== this.props.appt_update_event.success &&
      this.props.appt_update_event.success) {
      this.props.getSessionHistoryAndFuture(patient_id);
//      console.log('update event success');
      this.search();
    }

    if (prevProps.appt_remove_event.success !== this.props.appt_remove_event.success &&
      this.props.appt_remove_event.success) {
      this.props.getSessionHistoryAndFuture(patient_id);
//      console.log('remove event success');
      this.search();
    }

    if (prevProps.appt_latecancel_event.success !== this.props.appt_latecancel_event.success &&
      this.props.appt_latecancel_event.success) {
      this.props.getSessionHistoryAndFuture(patient_id);
//      console.log('late cancel event success');
      this.search();
    }

    // If any of the search parameters change in the state, automatically search

    if (prevState.treatment_type !== this.state.treatment_type ||
        prevState.duration !== this.state.duration ||
        prevState.provider_id !== this.state.provider_id ||
        prevState.location_id !== this.state.location_id ||
        prevState.number_of_weeks !== this.state.number_of_weeks ||
        prevState.modality !== this.state.modality ||
        prevState.intake_followup !== this.state.intake_followup) {
//      console.log('params changed');
      this.search();
    }
  }

  // Methods to manage searching, setting, and resetting search parameters

  search = () => {
//    console.log('HERE');
    this.props.searchAppointments({
      treatment_type: this.state.treatment_type,
      duration: this.state.duration,
      provider_id: this.state.provider_id,
      location_id: this.state.location_id,
      number_of_weeks: this.state.number_of_weeks,
      patient_id: this.state.patient_id,
      session_id: (this.getPaneId() === 3) ? this.getSessionId() : undefined,
      intake_followup: this.state.intake_followup,
      internal_search: true,
      modality: this.state.modality,
    });
  }

  resetSearchParameters = () => {
    this.props.getProviderSearchParams(this.getPatientId());
  }

  setSearchParameters = (params) => {
    this.setState(params);
  }

  // Search form fields

  changeTreatmentType = (value) => {
//    console.log('changeTreatmentType');
    this.setState({
      treatment_type: value,
      duration: (value !== 1) ? 60 : ((this.state.intake_followup === 1) ? 60 : 30),
      provider_id: 0,
      modality: 1,
    });
  }

  getTreatmentType = () => {
    const Option = Select.Option;
    return (
      <Select
        style={{width:'115px'}}
        value={this.state.treatment_type}
        onChange={this.changeTreatmentType}
        disabled={(this.getPaneId() === 3)}
      >
        <Option key='TT1' value={1}>Psychiatry</Option>
        <Option key='TT2' value={2}>Therapy</Option>
      </Select>
    );
  }

  changeModality = (value) => {
    this.setState({
      modality: value
    });
  }

  getModality = () => {
    const Option = Select.Option;
    return (
      <Select
        style={{width:'115px'}}
        value={this.state.modality}
        onChange={this.changeModality}
        disabled={(this.getPaneId() === 3)}
      >
        <Option key='MOD1' value={1}>Individual</Option>
        { this.state.treatment_type === 2 && <Option key='MOD2' value={2}>Couples</Option> }
        { this.state.treatment_type === 2 && <Option key='MOD3' value={3}>Family</Option> }
      </Select>
    );
  }

  changeIntakeFollowup = (value) => {
//    console.log('changeIntakeFollowup');
    this.setState({
      intake_followup:value,
      duration: (this.state.treatment_type !== 1) ? 60 : ((value === 1) ? 60 : 30)
    });
  }

  getIntakeFollowup = () => {
    const Option = Select.Option;
    return (
      <Select
        style={{width:'115px'}}
        value={this.state.intake_followup}
        onChange={this.changeIntakeFollowup}
        disabled={(this.getPaneId() === 3)}
      >
        <Option key='IF1' value={1}>Eval</Option>
        <Option key='IF2' value={2}>Followup</Option>
      </Select>
    );
  }

  changeDuration = (value) => {
//    console.log('changeDuration')
    this.setState({ duration: value });
  }

  durationList = () => {
    if (this.state.intake_followup === 1) {   // Intake
      return [60];
    } else {    // Followup
      if (this.state.treatment_type === 1) {  // Psychiatry
        return [20,30,45,60];
      } else {
        return [60];
      }
    }
  }

  getTreatmentDuration = () => {
    const d = this.durationList();
    const Option = Select.Option;
    return (
      <Select
        value={this.state.duration}
        style={{width:'125px'}}
        onChange={this.changeDuration}
        disabled={(this.getPaneId() === 3)}
      >
        { d.map(x=><Option key={'D'+x} value={x}>{
          (this.state.treatment_type === 2 && x === 60) ? '45/60' : x
        } min</Option>) }
      </Select>
    );
  }

  changeProvider = (value) => {
//    console.log('changeProvider');
    this.setState({ provider_id: value });
  }

  getProviderList = () => {
    const Option = Select.Option;
    const ul = this.props.user_list.filter(x=>{
      if (this.state.treatment_type === 1) { // psychiatry
        if (x.roles.includes('prescriber')) {
          return true;
        }
      } else if (this.state.treatment_type === 2) {
        if (x.roles.includes('therapist')) {
          return true;
        }
      }
      return false;
    });

    return (
      <Select
        style={{width:'250px'}}
        value={this.state.provider_id}
        onChange={this.changeProvider}
        disabled={(this.getPaneId() === 3)}
        showSearch={true}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option key={'U0'} value={0}>Any provider</Option>
        { ul.map(x=><Option key={'U'+x.id} value={x.id}>{x.full_name}</Option>) }
      </Select>
    )
  }

  changeLocation = (value) => {
//    console.log('changeLocation');
    this.setState({ location_id: value });
  }

  getLocationList = () => {
    const Option = Select.Option;
    // This sort is to put telemedicine first
    const ll = this.props.location_list.sort((a,b)=>{
      if (a.id === 4) {
        return -1;
      } else if (b.id === 4) {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
    return (
      <Select
        style={{width:'175px'}}
        value={this.state.location_id}
        onChange={this.changeLocation}
        disabled={(this.getPaneId() === 3)}
      >
        <Option key='L0' value={0}>Any Location</Option>
        { ll.map(x=><Option key={'L'+x.id} value={x.id}>{x.name}</Option>) }
      </Select>
    );
  }

  changeWhen = (value) => {
//    console.log('changeWhen');
    this.setState({ number_of_weeks : value});
  }

  getWhen = () => {
    const included = (this.state.provider_id === 0) ? false : true;

    const Option = Select.Option;
    return (
      <Select
        style={{width:'90px'}}
        value={this.state.number_of_weeks}
        onChange={this.changeWhen}
      >
        <Option value={0} key='W0'>ASAP</Option>
        <Option value={1} key='W1'>1 Wk</Option>
        <Option value={2} key='W2'>2 Wks</Option>
        <Option value={3} key='W3'>3 Wks</Option>
        <Option value={4} key='W4'>4 Wks</Option>
        {included && <Option value={5} key='W5'>5 Wks</Option> }
        {included && <Option value={6} key='W6'>6 Wks</Option> }
        {included && <Option value={8} key='W8'>2 Months</Option> }
        {included && <Option value={12} key='W12'>3 Months</Option> }
      </Select>
    )
  }

  searchForm = () => {
    return (
      <div style={{height:'75px'}}>
        { this.getTreatmentType() }
        &nbsp;
        { this.getModality() }
        &nbsp;
        { this.getIntakeFollowup() }
        &nbsp;
        { this.getTreatmentDuration() }
        &nbsp;@&nbsp;
        { this.getLocationList() }
        &nbsp;with&nbsp;
        { this.getProviderList() }
        &nbsp;
        in
        &nbsp;
        { this.getWhen() }
        &nbsp;
        <Button type='primary' onClick={this.resetSearchParameters}>Reset</Button>
      </div>
    );
  }

  getExclusionReasons = (x) => {
    switch(x) {
      case 1: return "Intakes turned off";
      case 2: return "Doesn't provide couples therapy";
      case 3: return "Doesn't provide family therapy";
      case 4: return "Patient below provider min age";
      case 5: return "Patient above provider max age";
      case 6: return "NP's can't see Kaiser patients";
      case 7: return "Anthem credentialing incomplete";
      case 8: return "Provider max intakes/week reached";
      case 9: return "Provider unable see to patients from this carrier";
      default: return "Unknown reason";
    }
  }

  renderExcluded = () => {
    const providerNames = {};
    this.props.user_list.forEach(x=>{
      providerNames[x.id] = x.full_name;
    });

    const results = [];
    Object.keys(this.props.search_excluded).forEach(x=>{
      results.push({
        name: providerNames[x],
        reasons: this.props.search_excluded[x].map(y=>this.getExclusionReasons(y)).join(', '),
      });
    });

    if (results.length === 0) {
      return null;
    }

    const sortedResults = results.sort((a,b)=>{
      return (a && a.name && b && b.name) ? a.name.localeCompare(b.name) : 0;
    });

    const columns = [{
      title: 'Provider',
      dataIndex: 'name'
    },{
      title: 'Reasons',
      dataIndex: 'reasons',
    }]
    return (
      <div>
        <Divider />
        <h2>Providers Excluded From Search</h2>
        <Table
          dataSource={sortedResults}
          columns={columns}
          size="small"
          pagination={false}
        />
      </div>
    )
  }

  // Rendering Search Results

  getImageName = (z) => {
    return safe(z,'user.bio.image_url');
  }

  renderProvider = (provider) => {
    const user_info = provider[0].user;
    return (
      <Col span={12} key={'C'+user_info.id}>
        <Card style={{marginBottom:'10px'}}>
          <Card.Meta
            avatar={<Avatar src={this.getImageName(provider[0])}/>}
            title={safe(user_info,'full_name')}
            description={<SessionList data={provider} locs={this.props.location_list} onSelect={(x)=>this.openReserveSessionModal(x)}/>} />
        </Card>
      </Col>
    );
  }

  renderResults = () => {
    const providers = this.props.search_results;

    if (this.props.isBusy) {
      return null;
    } else {
      if (providers.length === 0) {
        return <Result title="No Appointments Found" />;
      } else {
        return (
          <div>
            <Row gutter={8}>
              { providers.map((k, idx) => {
                const openings = providers[idx][1];
                // console.log('openings', openings);
                return this.renderProvider(openings);
              })}
            </Row>
          </div>
        );
      }
    }
  }

  // Methods for managing modal dialogs to reserve and reschedule sessions

  closeReserveSessionModal = () => {
    this.setState({
      reserve_session_modal_visible: false
    });
  }

  closeRescheduleSessionModal = () => {
    this.setState({
      reschedule_session_modal_visible: false
    })
  }

  openReserveSessionModal = (data) => {
    if (this.getPaneId() === 3) {
      this.setState({
        reschedule_session_modal_visible: true,
        modal_data: data
      });
    } else {
      this.setState({
        reserve_session_modal_visible: true,
        modal_data: {
          ...data,
          treatment_type: this.state.treatment_type,
//          duration: this.state.duration,
          intake_followup: this.state.intake_followup,
          location: this.props.location_list.find(x=>x.id===data.location_id)
        }
      });
    }
  }

  render() {
    if (this.getPatientId() === 0) {
      return (
        <div>
          <Result
            title="No Chart Loaded"
            status="404"
          />
        </div>
      )
    }

    const rightPanelStyle = {
      width:'400px',
      minWidth:'400px',
      borderLeft: '1px solid #e8e8e8',
      overflow:'auto'
    };

    return (
      <Spin spinning={this.props.isBusy}>
        <HLayout>
          <HLayoutContent style={{padding:'10px'}}>
            <VLayout>
              <VLayoutHeader>
                <PatientHeader patient_id={this.getPatientId()}/>
                {this.searchForm()}
              </VLayoutHeader>
              <VLayoutContent>
                {this.renderResults()}
                {this.renderExcluded()}
              </VLayoutContent>
            </VLayout>

            <ReserveSessionModal
              visible={this.state.reserve_session_modal_visible}
              onClose={this.closeReserveSessionModal}
              data={this.state.modal_data}
              patient_id={this.state.patient_id}
              patient_name={this.state.patient_name}
              session_id={this.state.session_id}
            />

            <RescheduleSessionModal
              visible={this.state.reschedule_session_modal_visible}
              patient_id={this.state.patient_id}
              patient_name={this.state.patient_name}
              old_session={this.props.session_data}
              new_session={this.state.modal_data}
              onClose={this.closeRescheduleSessionModal}
            />

          </HLayoutContent>
          <HLayoutRight style={rightPanelStyle}>
            <SessionPanel
              patient_id={this.getPatientId()}
              patient_name={safe(this.props,'search_params.patient_name')}
              session_id={this.getSessionId()}
              pane_id={this.getPaneId()}
              careteam={this.props.careteam}
              session_history={this.props.session_history}
              session_data={this.props.session_data}
              searchFilter={this.setSearchParameters}
              loading={this.props.isBusy}
            />
          </HLayoutRight>
        </HLayout>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'emr.calendar.search.processing',false) ||
      safe(state,'emr.calendar.searchparams.processing',false) ||
      safe(state,'emr.session.history.processing',false) ||
      safe(state,'emr.session.load.processing',false) ||
      safe(state,'shared.careteam.list.processing',false) ||
      safe(state,'shared.location.list.processing',false) ||
      safe(state,'shared.user.list.processing',false) ||
      safe(state,'emr.appt.reserve.processing',false) ||
      safe(state,'emr.appt.update.processing',false) ||
      safe(state,'emr.appt.remove.processing',false) ||
      safe(state,'emr.appt.latecancel.processing',false)
    ) ? true : false,

    isPrescriber: isPrescriber(state),

    location_list: safe(state,'shared.location.list.data',[]),
    user_list: safe(state,'shared.user.list.data',[]),
    search_results: safe(state,'emr.calendar.search.data.results',[]),
    search_excluded: safe(state,'emr.calendar.search.data.excluded',{}),
    search_params: safe(state,'emr.calendar.searchparams.data',{}),
    session_data: safe(state,'emr.session.load.data',{}),
    careteam: safe(state,'shared.careteam.list.data',[]),
    session_history: safe(state,'emr.session.history.data',[]),

    search_params_event: safe(state,'emr.calendar.searchparams',{}),
    appt_reserve_event: safe(state,'emr.appt.reserve',{}),
    appt_update_event: safe(state,'emr.appt.update',{}),
    appt_remove_event: safe(state,'emr.appt.remove',{}),
    appt_latecancel_event: safe(state,'emr.appt.latecancel',{})
  }
}

export default connect(mapStateToProps,{...shared_actions,...emr_actions})(Search);
