/**
 * DateTypeSelector.tsx
 * A simple date type toggle selector
 */
import { useContextSelector } from 'use-context-selector';
import { Form, Radio, RadioChangeEvent } from 'antd';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import formItemRules from 'components/App_/ReportsV2_/formItemRules';

const DateTypeSelector: React.FC = () => {
  const form = useContextSelector(InlineContext, (c) => c.state.form);

  const onDateTypeChange = (e: RadioChangeEvent) => {
    const dateType = e.target.value;
    form?.setFieldsValue({ dateType });
  };

  return (
    <Form.Item name="dateType" rules={formItemRules.dateType}>
      <Radio.Group buttonStyle="solid" onChange={onDateTypeChange}>
        <Radio.Button value="dos">Date of Service</Radio.Button>
        <Radio.Button value="cd">Creation Date</Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};

export default DateTypeSelector;
