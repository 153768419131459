import React from 'react';
import moment from 'moment';

import { isEmpty } from '../../../../helpers/js';
import { genders } from '../../../../helpers/genders';

class ChartHeader extends React.Component {

  label = (x) => {
    return (this.props.print) ? x + ": " : null;
  }

  zeroPadNum(num, size) {
    let s = num+"";
    return (s).padStart(size-s.length,"0");
  }

  getPatientName = () => {
    const {full_name,id} = this.props.patientData;
    return full_name + ' ('+this.zeroPadNum(id,7)+')';
  }

  getPatientAddress = () => {
    const {address1,address2} = this.props.patientData;

    if (address1 && address2 && address1.length > 0 && address2.length > 0) {
      return (<div>{this.label('Address')}{address1},{address2}</div>);
    } else if (address1 && address1.length > 0) {
      return (<div>{this.label('Address')}{address1}</div>);
    } else if (address2 && address2.length > 0){
      return (<div>{this.label('Address')}{address2}</div>);
    }
    return null;
  }

  getPatientCityStateZip = () => {
    const {city,state,zip} = this.props.patientData;
    if (city && state && zip) {
      return (<div>{city}, {state}, {zip}</div>);
    } else {
      return null;
    }
  }

  getPatientPhone = () => {
    const {phone} = this.props.patientData;
    return (phone) ? (<div>{this.label('Phone')}{phone}</div>) : null;
  }

  getPatientEmail = () => {
    const {email} = this.props.patientData;
    return (email) ? (<div>{this.label('Email')}{email}</div>) : null;
  }

  getPatientDOB = () => {
    const {dob,age} = this.props.patientData;
    const dobString = moment(dob).format('M/D/YYYY');
    return (dob) ? (<div>{this.label('Date of Birth')}{dobString} ({age} years old)</div>) : null;
  }

  getPatientGender = () => {
    const {gender} = this.props.patientData;
    const record = genders.find(a=>(a.id === gender));
    return (record) ? (<div>{this.label('Gender')}{record.text}</div>) : null;
  }

  getEmergencyContactName = () => {
    const {ec_name,ec_relation} = this.props.patientData;
    if (ec_name) {
      if (ec_relation && ec_relation.length > 0) {
        return (<div>EC: {ec_name} ({ec_relation})</div>);
      } else {
        return (<div>EC: {ec_name}</div>);
      }
    }
    return null;
  }

  getEmergencyContactContact = () => {
    const { ec_email,ec_phone } = this.props.patientData;
    let result = [];

    if (ec_phone) {
      result.push(<div key='phone'>{ec_phone}</div>);
    }

    if (ec_email) {
      result.push(<div key='email'>{ec_email}</div>);
    }
    return result;
  }

  render() {
    if (!this.props.patientData || isEmpty(this.props.patientData)) {
      return null;
    }

    return (
      <div style={{backgroundColor:'rgb(248,248,248)',padding:'15px',borderBottom: '1px solid #e8e8e8'}}>
        <h3>{this.getPatientName()}</h3>
        {this.getPatientAddress()}
        {this.getPatientCityStateZip()}
        {this.getPatientPhone()}
        {this.getPatientEmail()}
        {this.getPatientDOB()}
        {this.getPatientGender()}
        {this.getEmergencyContactName()}
        {this.getEmergencyContactContact()}
      </div>
    );
  }
}

export default ChartHeader;
