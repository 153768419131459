/**
 * LifestanceTxDetailPayments.tsx
 */
import reports from 'reports';
import ReportWrapper from 'components/App_/ReportsV2_/ReportWrapper';
import QueueFormWrapper from 'components/App_/ReportsV2_/QueueFormWrapper';
import QueueDateTypeToggle from 'components/App_/ReportsV2_/QueueReport_/ReportParameters_/DateTypeToggle';
import StartDate from 'components/App_/ReportsV2_/QueueReport_/ReportParameters_/StartDateType';
import EndDate from 'components/App_/ReportsV2_/QueueReport_/ReportParameters_/EndDateType';
import DownloadOnly from 'components/App_/ReportsV2_/DownloadOnly';
import styles from './styles';

const reportId = 6;

const LifestanceTxDetailPayments: React.FC = () => {
  const report = reports.find((r) => r.id === reportId);
  return (
    <ReportWrapper>
      <h3>{report?.name}</h3>
      <div style={styles.root}>
        <DownloadOnly />
        <QueueFormWrapper reportId={reportId}>
          <QueueDateTypeToggle />
          <StartDate />
          <EndDate />
        </QueueFormWrapper>
      </div>
    </ReportWrapper>
  );
};

export default LifestanceTxDetailPayments;
