import React from 'react';
import { Collapse, Badge, Button, Modal } from 'antd';
import { connect } from 'react-redux';

import Comments from './Assist_/Comments';
import InsPayments from './Assist_/InsPayments';
import PtPayments from './Assist_/PtPayments';
import PreparedStatements from './Assist_/PreparedStatements';
import BillingPolicy from './Assist_/BillingPolicy';
import Authorizations from './Assist_/Authorizations';
import Recall from './Assist_/Recall';
import AdminAttachments from './Assist_/AdminAttachments';

import * as shared_actions from '../../../../actions/shared';
import * as billing_actions from '../../../../actions/billing';
import { safe } from '../../../../helpers/reduxSelectors';

class Assist extends React.Component {

  formatTitle = (title,count) => {
    return (
      <div>
        <span style={{paddingRight:'5px'}}>
          <Badge count={count} style={{ backgroundColor: '#52c41a' }} />
        </span>
        {title}
      </div>
    );
  }

  onChange = (key) => {
    this.props.setBillingState({
      patient_sider_default_panel: key
    });
  }

  setAutopay = () => {
    this.props.setAutopay(this.props.patientId,!this.props.autopay);
  }

  toggleAutopay = () => {
    const f = this.setAutopay;
    const title = (this.props.autopay) ? 'Are you sure you want to turn off autopay?' : 'Are you sure you want to turn on autopay?';
    Modal.confirm({
      title: title,
      onOk() {
        f();
      },
      onCancel() {
      }
    });
  }

  renderAutopayButton = () => {
    if (this.props.autopay) {
      return (
        <Button style={{width:'100%',color:'black',fontWeight:'bold',backgroundColor:'#EBF7CC'}} onClick={this.toggleAutopay}>
          AUTOPAY ON
        </Button>
      );
    } else {
      return (
        <Button style={{width:'100%',color:'white',fontWeight:'bold',backgroundColor:'#F99E9A'}} onClick={this.toggleAutopay}>
          AUTOPAY OFF
        </Button>
      );
    }
  }

  render() {
    const Panel = Collapse.Panel;

    const demographics = safe(this.props,'demographics',{});
    const policies = safe(this.props,'policies',[]);
    const pt_payments = safe(this.props,'pt_payments',[]);
    const ins_payments = safe(this.props,'ins_payments',[]);
    const statements = safe(this.props,'statements',[]);
    const recall_items = safe(this.props,'recall_items',[]);
    const authorizations = safe(this.props,'authorizations',[]);
    const admin_attachments = safe(this.props,'admin_attachments',[]);

    const recall_item_count = recall_items.filter(x=>x.status===1).length;

    return (
      <div style={{borderLeft: '1px solid #e8e8e8'}}>
        <div style={{padding:'5px'}}>
          { this.renderAutopayButton() }
        </div>
        <Collapse
          accordion={true}
          bordered={false}
          activeKey={this.props.patient_sider_default_panel}
          onChange={this.onChange}
        >

          <Panel key='1' header='Comments'>
            <Comments patientId={this.props.patientId}/>
          </Panel>

          <Panel key='2' header={this.formatTitle('Billing Policies',policies.length)}>
            <BillingPolicy
              patientId={this.props.patientId}
              data={policies}
              patientData={demographics}
            />
          </Panel>

          <Panel key='3' header={this.formatTitle('Insurance Payments',ins_payments.length)}>
            <InsPayments
              patientId={this.props.patientId}
              data={ins_payments}
            />
          </Panel>

          <Panel key='4' header={this.formatTitle('Patient Payments',pt_payments.length)}>
            <PtPayments
              patientId={this.props.patientId}
              data={pt_payments}
              patientData={demographics}
            />
          </Panel>

          <Panel key='7' header={this.formatTitle('Authorizations',authorizations.length)}>
            <Authorizations
              patientId={this.props.patientId}
              data={authorizations}
            />
          </Panel>

          <Panel key='5' header={this.formatTitle('Statements',statements.length)}>
            <PreparedStatements
              patientId={this.props.patientId}
              data={statements}
            />
          </Panel>

          <Panel key='6' header={this.formatTitle('Recall List',recall_item_count)}>
            <Recall
              patientId={this.props.patientId}
              data={recall_items}
            />
          </Panel>

          <Panel key='8' header={this.formatTitle('Attachments',admin_attachments.length)}>
            <AdminAttachments
              patientId={this.props.patientId}
              data={admin_attachments}
            />
          </Panel>

        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    patient_sider_default_panel: safe(state,'shared.domain.billing.patient_sider_default_panel','1')
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(Assist);
