import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { NotificationOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import moment from 'moment';

class UnsignedNotesList extends React.Component {
  static propTypes = {
    list: PropTypes.array,
    userId: PropTypes.number,
    isSupervisor: PropTypes.bool
  }

  linkToNote = (a) => {
    switch (a.type) {
      case 1: return '/emr/patients/' + a.patient.id + '/progressnote/' + a.id;
      case 2: return '/emr/patients/' + a.patient.id + '/progressnote/' + a.id;
      case 3: return '/emr/patients/' + a.patient.id + '/generalnote/' + a.id;
      case 4: return '/emr/patients/' + a.patient.id + '/admintimenote/' + a.id;
      case 5:
      case 6:
      default: return '';
    }
  }

  render() {
//    console.log(this.props.list);
    const list = this.props.list.filter(x => (this.props.userId !== x.supervising_provider_id));
    if (list && list.length > 0) {
      return (
        <div style={{paddingLeft:'10px'}}>
          <Divider />
          <h4>My Notes to Sign ({list.length})</h4>
          <ol style={{paddingTop: '0px', paddingLeft:'15px'}}>
            {list.map(a => (
              <li key={a.id} style={{padding:'3px',fontSize:'smaller'}}>
                {(a.note_state===3) ? <span><NotificationOutlined />&nbsp;</span> : ''}
                <Link to={this.linkToNote(a)}>{moment(a.note_date).format('MM/DD/YY')}<br/>{a.patient.full_name}</Link>
              </li>
            ))}
          </ol>
        </div>
      );
    }
    return null;
  }
}

export default UnsignedNotesList;
