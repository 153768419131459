import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Input } from 'antd';

class Message extends Component {
  static propTypes = {
    message: PropTypes.string,
    timedate: PropTypes.object,
    userName: PropTypes.string,
    isMe: PropTypes.bool
  }

  render() {
    const leftStyle = {
      display: 'inline-block',
      maxWidth: '65%',
      border: '1px solid black',
      backgroundColor: 'white',
      borderRadius: '3px',
      color: '#646464',
      fontSize: '14px',
      padding: '5px',
      margin: '0px'
    };

    const rightStyle = {
      ...leftStyle,
      float:'right',
      backgroundColor: 'rgb(82, 196,26)', //'#05728f',
      color: 'white'
    };

    const leftLabel = {
      margin: '0px',
      display: 'block',
      fontSize: 'smaller'
    }

    const rightLabel = {
      ...leftLabel,
      float:'right'
    };

    const style = (this.props.isMe) ? leftStyle : rightStyle;
    const label = (this.props.isMe) ? leftLabel : rightLabel;

    return (
      <div style={{marginBottom:'10px'}}>
        <div style={style}>{this.props.message}</div>
        <div style={{clear:'both'}}/>
        <div style={label}>
          {this.props.timedate.format('ddd, MMM D, YYYY hh:mm:ss')} {this.props.userName}
        </div>
        <div style={{clear:'both'}}/>
      </div>
    );
/*
    const align = (this.props.isMe) ? 'left' : 'right';
    const bgColor = (this.props.isMe) ? 'white' : 'rgb(13,198,0)';
    const color = (this.props.isMe) ? 'black' : 'white';

    return (
      <div align={align}>
        <div style={{display:'inline',paddingTop:'20px',paddingBottom:'10px',border:'1px solid rgb(220,220,220)',backgroundColor:bgColor,color:color,borderRadius:'5px'}}>
          <span style={{padding:'10px'}}>
            {this.props.message}
          </span>
        </div>
        <div style={{fontSize:'smaller'}}>
          {this.props.timedate.format('ddd, MMM D, YYYY hh:mm:ss')} {this.props.userName}
        </div>
      </div>
    )
    */
  }
}


class MessageList extends Component {
  renderList = () => {
    if (!this.props.comments || this.props.comments.length === 0) {
      return null;
    }
    return this.props.comments.map(x =>
      <div key={x.id}>
        <Message
          message={x.message}
          timedate={moment(x.createdAt)}
          userName={x.user.full_name}
          isMe={(x.user.id===this.props.providerId) ? true : false}
        />
      </div>
    );
  }

  render() {
    const style = {
      backgroundColor:'rgb(240,240,240)',
      padding:'5px',
      minHeight:'200px',
      borderBottom:'1px solid rgb(169,169,169)'
    };

    return (
      <div style={style}>
        { this.renderList() }
      </div>
    );
  }
}

class CommentEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: null
    }
  }

  onChange = (event) => {
    this.setState({
      comment: event.target.value
    })
  }

  onSubmit = () => {
    this.props.ac(this.props.noteId,this.state.comment);
    this.setState({
      comment: ''
    });
  }

  render() {
    return (
      <div style={{margin:'2px'}}>
        <Input
          onPressEnter={this.onSubmit}
          value={this.state.comment}
          onChange={this.onChange}
          placeholder='Enter comment for supervisor here'
        />
      </div>
    );
  }
}

class SuperviseeComment extends Component {
  static propTypes = {
    comments: PropTypes.array,
    providerId: PropTypes.number,
    supervisingId: PropTypes.number,
    noteId: PropTypes.number,
    ac: PropTypes.func
  }

  render() {
    if (!this.props.superviserId) {
      return null;
    }

    return (
      <div style={{margin:'auto',marginBottom:'20px',padding:'15px',width:'75%'}}>
        <h4>Supervisor/Supervisee Communication</h4>
        <div style={{border:'1px solid rgb(169,169,169)'}}>
          <MessageList providerId={this.props.providerId} comments={this.props.comments}/>
          <CommentEntry noteId={this.props.noteId} ac={this.props.ac}/>
        </div>
      </div>
    );
  }
}

export default SuperviseeComment;
