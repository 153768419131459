import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Radio, Checkbox } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import * as actions from '../../../../../actions/emr';
import { safe } from '../../../../../helpers/reduxSelectors';
import { isValidSessionCancellation, isLateCancel } from '../../../../../helpers/lateCancel';

const TYPE_RECURRING_DELETE = 1;
const TYPE_SINGLE_DELETE = 2;
const TYPE_LATECANCEL_SINGLE_DELETE = 3;

class DeleteSessionModal extends React.Component {

  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.object
  }

  state = {
    sequence_type: null,
    waive_cancellation_fees: false
  }

  componentDidMount() {
    this.props.getHolidays();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.setState({
        sequence_type: null,
        waive_cancellation_fees: false
      });
    }
  }

  getSessionType = () => {
    const { data: { type }} = this.props;
    switch (type) {
      case 7:
        return 'Admin Time';
      case 2:
        return 'Blocked-Out Time';
      default:
        return '';
    }
  };

  handleCancel = () => {
    this.props.onClose();
  }

  handleOk = () => {
    const repeat = safe(this.props,'data.repeat',false);
    const session_date = safe(this.props,'data.session_date');
    const start_time = safe(this.props,'data.start_time');
    let lateCancel = isLateCancel(this.props.holidays,session_date,start_time);
    lateCancel = (safe(this.props,'data.type') === 1) ? lateCancel : false;

    if (lateCancel) {
      if (this.state.waive_cancellation_fees) {
        this.props.removeSession(this.props.calendarProviderId,1,
          this.props.calendar_date,this.props.data.id,1);
      } else {
        this.props.lateCancelSession(this.props.calendarProviderId,1,
          this.props.calendar_date,this.props.data.id,this.state.sequence_type);
      }
    } else {
      if (repeat) {
        if (this.state.sequence_type) {
          this.props.removeSession(this.props.calendarProviderId,1,
            this.props.calendar_date,this.props.data.id,this.state.sequence_type);
        }
      } else {
        this.props.removeSession(this.props.calendarProviderId,1,
          this.props.calendar_date,this.props.data.id,1);
      }
    }
    this.props.onClose();
  }

  onChange = (e) => {
    this.setState({
      sequence_type: e.target.value
    });
  }

  recurringDelete = () => {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    return (
      <div>
        <div>Would you like to delete this session or this and all subsequent
        sessions in the series?</div>
        <Radio.Group onChange={this.onChange} value={this.state.sequence_type}>
          <Radio style={radioStyle} value={1}>
            This session only
          </Radio>
          <Radio style={radioStyle} value={2}>
            This session and all subsequent sessions
          </Radio>
        </Radio.Group>
      </div>
    );
  }

  onChangeCheckbox = (e) => {
    this.setState({
      waive_cancellation_fees: e.target.checked
    });
  }

  lateCancelSingleDelete = () => {
    return (
      <div>
        <div style={{fontStyle:'italic',color:'red',paddingBottom:'20px'}}>
          This appointment is less than 48 business hours from now. Cancelling it
          will result in the patient being charged a late cancellation fee.
        </div>
        <Checkbox checked={this.state.waive_cancellation_fees} onChange={this.onChangeCheckbox}>
          Waive cancellation fee and just delete the appointment
        </Checkbox>
      </div>
    );
  }

  singleDelete = () => <div>Are you sure you want to delete this {this.getSessionType()} session?</div>

  getModalFooter = (type) => {
    return (
      <div style={{textAlign:'right'}}>
        <Button onClick={this.handleCancel}>Cancel</Button>
        <Button
          type='danger'
          disabled={type === TYPE_RECURRING_DELETE && !this.state.sequence_type}
          onClick={this.handleOk}
        >
          Delete
        </Button>
      </div>
    );
  }

  render() {
    const repeat = safe(this.props,'data.repeat',0) ? true : false;
    const session_date = safe(this.props,'data.session_date');
    const start_time = safe(this.props,'data.start_time');
    let canCancel = isValidSessionCancellation(this.props.holidays,session_date,start_time);
    canCancel = (safe(this.props,'data.type') === 1) ? canCancel : true;

    const title = (repeat && canCancel) ? 'Delete Recurring Sessions?' : `Delete ${this.getSessionType()} Session?`;

    let dialog_type = null;
    if (repeat && canCancel) {
      dialog_type = TYPE_RECURRING_DELETE;
    } else if (!repeat && canCancel) {
      dialog_type = TYPE_SINGLE_DELETE;
    } else if (!canCancel) {
      dialog_type = TYPE_LATECANCEL_SINGLE_DELETE;
    }

    return (
      <Modal
        title={title}
        visible={this.props.visible}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        footer={this.getModalFooter(dialog_type)}
        width={500}
        centered={true}
      >
        {dialog_type === TYPE_RECURRING_DELETE && this.recurringDelete()}
        {dialog_type === TYPE_SINGLE_DELETE    && this.singleDelete()}
        {dialog_type === TYPE_LATECANCEL_SINGLE_DELETE && this.lateCancelSingleDelete()}
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    holidays: safe(state,'emr.calendar.holidays.data',{}),
    calendar_date: safe(state,'emr.emr.active_date',moment().format('YYYY-MM-DD')),
    calendarProviderId: safe(state,'emr.emr.calendarProvider.id'),
  }
}

export default connect(mapStateToProps,actions)(DeleteSessionModal);
