import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, message } from 'antd';
import moment from 'moment';

import ProgressNoteTherapist from './ProgressNote_/ProgressNoteTherapist';
import ProgressNotePrescriber from './ProgressNote_/ProgressNotePrescriber';

import * as emr_actions from '../../../../actions/emr';
import * as shared_actions from '../../../../actions/shared';
import { validIndex } from '../../../../helpers/js';
import { safe, isPrescriber, isTherapist } from '../../../../helpers/reduxSelectors';

class ProgressNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId:parseInt(this.props.match.params.patient_id,10),
      noteId:parseInt(this.props.match.params.note_id,10),
      autoSaveTimeDate: undefined
    }
  }

  componentDidMount() {
    if (validIndex(this.state.patientId) && validIndex(this.state.noteId)) {
      if (this.state.noteId !== 0) {
        this.props.noteLoad(this.state.noteId);
        this.props.getComments(this.state.noteId);
      }
    } else {
      this.props.history.push('/emr/patients');
    }
  }

  componentDidUpdate(prevProps) {

    if (prevProps.note.load !== this.props.note.load) {
      if (this.props.note.load.success) {
      } else if (this.props.note.load.error) {
        this.props.noteLoadClear();
        message.error('Unable to load progress note');
        this.props.history.push('/emr/patients/'+this.state.patientId);
      }
    }

    if (prevProps.note.create !== this.props.note.create) {
      if (this.props.note.create.success) {
        this.props.noteCreateClear();
        message.success("Progress note created");
        this.props.history.replace('/emr/patients/'+this.state.patientId+
          '/progressnote/'+this.props.note.create.data.id);
      } if (this.props.note.create.error) {
        this.props.noteCreateClear();
        message.error("Unable to create a progress note.");
        this.props.history.push('/emr/patients/'+this.state.patientId);
      }
    }

    if (prevProps.note.save !== this.props.note.save) {
      if (this.props.note.save.success) {
        this.props.noteSaveClear();
        message.success("Progress note saved.")
        this.props.history.push('/emr/patients/'+this.state.patientId);
      } else if (this.props.note.save.error) {
        this.props.noteSaveClear();
//        this.props.history.push('/emr/patients/'+this.state.patientId);
        message.error("Unable to save progress note.")
      }
    }

    if (prevProps.note.autosave !== this.props.note.autosave) {
      if (this.props.note.autosave.success) {
        this.props.noteAutoSaveClear();
        this.setState({
          autoSaveTimeDate: moment().format('dddd, M/D/YYYY h:mm:ss A')
        });
        //message.info('Progress Note Autosaved');
      } else if (this.props.note.autosave.error) {
        this.props.noteAutoSaveClear();
      }
    }

    if (prevProps.note.sign !== this.props.note.sign) {
      if (this.props.note.sign.success) {
        this.props.noteSignClear();
        this.props.history.push('/emr/patients/'+this.state.patientId);
        message.success("Progress note signed.")
      } else if (this.props.note.sign.error) {
        this.props.noteSignClear();
//        this.props.history.push('/emr/patients/'+this.state.patientId);
        message.error("Unable to sign progress note: " + safe(this.props,'note.sign.error.data.message'))
      }
    }

    if (prevProps.note.delete !== this.props.note.delete) {
      if (this.props.note.delete.success) {
        this.props.noteDeleteClear();
        this.props.history.push('/emr/patients/'+this.state.patientId);
        message.success("Progress note draft deleted.")
      } else if (this.props.note.delete.error) {
        this.props.noteDeleteClear();
        this.props.history.push('/emr/patients/'+this.state.patientId);
        message.error("Unable to delete progress note draft.")
      }
    }

    const newPatientId = parseInt(this.props.match.params.patient_id,10);
    const newNoteId = parseInt(this.props.match.params.note_id,10);
    if (newNoteId !== this.state.noteId || newPatientId!== this.state.patientId) {
      this.setState({
        noteId: newNoteId,
        patientId: newPatientId
      }, () => {
        this.props.noteLoad(this.state.noteId);
        this.props.getComments(this.state.noteId);
      });
    }

  }

  spinTip = () => {
    if (this.props.note.create.processing) {
      return "Creating Chart Note";
    }
    return null;
  }

  getNote = () => {
    if (this.props.isPrescriber) {
      return (
        <ProgressNotePrescriber
          noteId={this.state.noteId}
          patientId={this.state.patientId}
          autoSaveTimeDate={this.state.autoSaveTimeDate}
        />
      );
    } else if (this.props.isTherapist) {
      return (
        <ProgressNoteTherapist
          noteId={this.state.noteId}
          patientId={this.state.patientId}
          autoSaveTimeDate={this.state.autoSaveTimeDate}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <Spin size="large" spinning={this.props.isBusy} tip={this.spinTip()}>
        { this.getNote() }
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'emr.note.load.processing',false) ||
      safe(state,'emr.note.create.processing',false) ||
      safe(state,'emr.note.save.processing',false) ||
      safe(state,'emr.note.sign.processing',false) ||
      safe(state,'emr.note.delete.processing',false)
    ) ? true : false,
    note: safe(state,'emr.note',{}),
    isPrescriber: isPrescriber(state),
    isTherapist: isTherapist(state)
  }
}

export default connect(mapStateToProps,{...shared_actions,...emr_actions})(ProgressNote);
