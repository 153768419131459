/**
 * Table.tsx
 * The shared table used by any reports that display data inline
 * ReportID, data and columns are required
 * Pagination and x-scroll are optional
 */
import { useEffect, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import { Table as AntTable } from 'antd';
import ColumnI from './TableColumns_/column';

interface PropsI {
  reportId: number;
  data: any;
  columns: Array<ColumnI>;
  page?: number;
  pageSize?: number;
  rowKey?: string;
  total?: number;
  scrollX?: boolean;
  expandable?: any;
  rowClassName?: (record: any, index: any) => string;
}

const Table: React.FC<PropsI> = (props: PropsI) => {
  const { columns, data, page, pageSize, rowKey, scrollX, total, expandable, rowClassName } = props;
  const doRunReport = useContextSelector(InlineContext, (c) => c.doRunReport);
  const runReportAPI = useContextSelector(InlineContext, (c) => c.state.runReportAPI);
  const form = useContextSelector(InlineContext, (c) => c.state.form);
  const [pg, setPg] = useState<number>();
  const [pgSize, setPgSize] = useState<number>();

  useEffect(() => {
    if (page) {
      setPg(page);
    }
  }, [page]);

  useEffect(() => {
    if (pageSize) {
      setPgSize(pageSize);
    }
  }, [pageSize]);

  const onTableChange = (p) => {
    const newPage = p.current;
    const newPageSize = p.pageSize;
    // ensure re-run only happens when a real change occurs
    if ((pg && newPage !== pg) || (pgSize && newPageSize !== pgSize)) {
      setPg(newPage);
      setPgSize(newPageSize);
      form?.setFieldsValue({ page: newPage, pageSize: newPageSize });
      doRunReport();
    }
  };

  const pagination = {
    showQuickJumper: true,
    current: pg,
    pageSize: pgSize,
    total,
  };

  const tableScroll = scrollX ? { x: true as const } : undefined;

  return (
    <div className="mt4">
      {!runReportAPI?.loading && data && !data?.length && !data?.rows?.length && <div>No data available</div>}
      {runReportAPI?.loading && <div>Loading data...</div>}
      {data?.length ? (
        <AntTable
          className="reports-sessionlist-table"
          columns={columns}
          dataSource={data}
          bordered={true}
          expandable={expandable}
          size="small"
          rowKey={rowKey}
          scroll={tableScroll}
          onChange={onTableChange}
          pagination={page === undefined ? false : pagination}
          rowClassName={rowClassName}
        />
      ) : null}
    </div>
  );
};

export default Table;
