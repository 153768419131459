import React from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Spin, Select } from 'antd';
import moment from 'moment';
import numeral from 'numeral';

import * as actions from '../../../../../../actions/billing';
import { safe } from '../../../../../../helpers/reduxSelectors';
import { processEventNotification } from '../../../../../../helpers/util';

class PaymentLinkDialog extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.form.resetFields();
    }
    processEventNotification(prevProps,this.props,'linkEvent','Refund linked to payment');
  }

  handleCancel = () => {
    this.props.closeDialog()
  }

  handleOk = () => {
    this.props.form.validateFields((err,values) => {
      if (!err) {
        this.props.linkPtPaymentRefund(this.props.patientId,this.props.payment_id,
          values.original_payment_id);
      }
    });
    this.props.closeDialog()
  }

  formatPayment = (p) => {
    return moment(p.check_date).format('MM/DD/YYYY') + ' payment for ' +
      numeral(p.amount).format('$0,0.00') + ' with ' +
      numeral(p.unallocated_amount).format('$0,0.00') +
      ' unallocated';
  }

  renderModalContent = () => {
    const { getFieldDecorator } = this.props.form;
    // payment_id and data.
    const thisRefund = this.props.data.find(x=>x.id===this.props.payment_id);
    if (thisRefund) {
      const list = this.props.data.filter(x=>x.unallocated_amount >= -thisRefund.amount);
      if (list && list.length > 0) {
        return (
          <div>
            <div style={{fontWeight:'bold'}}>Link your {numeral(thisRefund.amount).format('$0,0.00')} refund from {moment(thisRefund.check_date).format('MM/DD/YYYY')} to:
            </div>
            <Form className='payment-form' autoComplete='false'>
              <Form.Item>
                {getFieldDecorator('original_payment_id', {
                  rules: [{ required: true, message: " " }],
                })(
                  <Select>
                    { list.map(x=><Select.Option key={x.id} value={x.id}>{this.formatPayment(x)}</Select.Option>) }
                  </Select>
                )}
              </Form.Item>
            </Form>
          </div>
        );
      } else {
        return (
          <div>
            Unable to link this refund because there are no payments with unallocated
            amounts equal or more than this refund
          </div>
        )
      }
    } else {
      return (
        <div>Unable to link this refund because the refund itself could not be found</div>
      );
    }
  }

  render() {
    return (
      <div>
        <Spin spinning={this.props.isBusy}>
          <Modal
            title="Link Patient Refund"
            visible={this.props.visible}
            onOk={this.handleOk}
            okText='Submit'
            onCancel={this.handleCancel}
          >
            {this.renderModalContent()}
          </Modal>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'billing.ptpayments.refundlink.processing',false),
    linkEvent: safe(state,'billing.ptpayments.refundlink')
  }
}

export default connect(mapStateToProps,actions)(Form.create()(PaymentLinkDialog));
