import React, { Component } from 'react';
import moment from 'moment';
import { Table } from 'antd';

import FormattedCell from '../../../../shared/FormattedCell';
import ClaimsViewDialog from '../../../../shared/ClaimsViewDialog';
import { safe } from '../../../../../helpers/reduxSelectors';

class PaymentsTable extends Component {
  state = {
    dialogVisible: false,
    dialogId: null
  }

  dialogClose = () => {
    this.setState({
      dialogVisible: false
    });
  }

  dialogOpen = (id) => {
    this.setState({
      dialogId: id,
      dialogVisible: true
    });
  }

  render() {
    const columns=[{
      title: 'Date',
      dataIndex: 'createdAt',
      width: 175,
      render: (value,record) => {
        return moment(value).format('MM/DD/YYYY, HH:mm:ss');
      }
    },{
      title: 'User',
      dataIndex: 'creator_id',
      width: 50,
      render: (value,record) => {
        return safe(record,'creator.initials');
      }
    },{
      title: 'Type',
      dataIndex: 'action',
      width: 100,
      render: (value,record) => {
        switch (safe(record,'billing_payments.payment_origin')) {
          case 1: return 'INS PAY';
          case 2: return (record.pt_amount < 0) ? 'PT CREDIT' : 'PT PAY';
          default: return 'UNKNOWN';
        }
      }
    },{
      title: 'Pt',
      dataIndex: 'pt_amount',
      align: 'right',
      width: 100,
      render: (value,record) => {
        if (safe(record,'billing_payments.payment_origin') === 2) {
          return <FormattedCell blankonzero={false} colors={false} value={value} />
        } else {
          return null;
        }
      }
    },{
      title: 'Ins',
      dataIndex: 'ins_amount',
      align: 'right',
      width: 100,
      render: (value,record) => {
        if (safe(record,'billing_payments.payment_origin') === 1) {
          return <FormattedCell blankonzero={false} colors={false} value={value} />
        } else {
          return null;
        }
      }
    },{
      title: 'Description',
      dataIndex: 'description',
    },{
      title: 'Claim #',
      dataIndex: 'claim_num',
      width: 135
    },{
      title: 'Payment ID',
      dataIndex: 'billing_payment_id',
      width: 50,
    },{
      title: 'Detail',
      dataIndex: 'id',
      render: (value,record) => {
        if (value && safe(record,'billing_payments.payment_origin') === 1) {
          return (
            <a onClick={()=>{this.dialogOpen(value)}}>ERA</a>
          );
        }
      }
    }];

    return (
      <div>
        <Table
          dataSource={this.props.data}
          columns={columns}
          bordered={false}
          showHeader={true}
          pagination={false}
          size='small'
          rowKey='id'
          style={{backgroundColor:'white',marginTop:'5px'}}
        />
        <ClaimsViewDialog
          visible={this.state.dialogVisible}
          closeDialog={this.dialogClose}
          id={this.state.dialogId}
        />
      </div>
    )
  }
}

export default PaymentsTable;
