import React, { Component } from 'react';
import { connect } from 'react-redux';

import TemplateLetterChartNote from '../Emr_/Patient_/Chart_/ChartNotes_/TemplateLetterChartNote';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/emr';

class Letter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noteId: parseInt(this.props.match.params.note_id,10),
      print: false
    }
  }

  componentDidMount() {
    this.props.noteLoad(this.state.noteId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data && !this.state.print) {
      this.setState({
        print: true
      });
      setTimeout(()=>{window.print()},3000);
    }
  }

  render() {
    if (!this.props.data.id) {
      return null;
    }

    return (
      <div>
        <TemplateLetterChartNote data={this.props.data} print={true} />
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    data: safe(state,'emr.note.load.data',{}),
  }
}

export default connect(mapStateToProps,actions)(Letter);
