import React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Modal, Tag } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { safe } from '../../../../helpers/reduxSelectors';
import Time from '../../../shared/Time';
import * as actions from '../../../../actions/emr';

const diffHrs = Time.getTzOffset();

class RescheduleSessionModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    old_session: PropTypes.object,
    new_session: PropTypes.object
  }

  handleOk = () => {
    const provider_id = safe(this.props,'old_session.user.id');
    const session_id = safe(this.props,'old_session.id');
    const duration = safe(this.props,'old_session.duration');
    const start_time = new Time(safe(this.props,'new_session.start_time',0));
    this.props.updateAppt(provider_id,session_id,1,{
      session_date: moment(safe(this.props,'new_session.session_date')).tz('America/Los_Angeles').format('YYYY-MM-DD'),
      start_time: start_time.toInt(),
      end_time: start_time.add(duration).toInt(),
      duration: duration,
      verify: true
    });
    this.props.onClose();
    this.props.history.push('/emr/appointments/'+safe(this.props,'old_session.patient_id'));
  }

  handleCancel = () => {
    this.props.onClose();
  }

  dialogContent = () => {
    const sd = safe(this.props,'old_session.session_date');
    const st = safe(this.props,'old_session.start_time',0);
    const { session_date, session_date_local, time, local_time } = Time.fixDateTime(sd, st);

    const sdn = safe(this.props,'new_session.session_date');
    const stn = safe(this.props,'new_session.start_time',0);
    const newFixed = Time.fixDateTime(sdn, stn);

    return (
      <div>
        <table style={{textAlign:'left'}} align='center'>
          <tbody>
            <tr>
              <td>
                <div>Patient Name: {safe(this.props,'old_session.patient.full_name')}</div>
                <div>Provider: {safe(this.props,'old_session.user.full_name')}</div>
                <div>Location: {safe(this.props,'old_session.location.name')}</div>
                <div>Telemedicine: {(safe(this.props,'old_session.telemedicine')) ? 'Yes' : 'No'}</div>
                <div>Type: {(safe(this.props,'old_session.session_type') === 1) ? 'Intake' : 'Followup'}</div>
                <div>Duration: {safe(this.props,'old_session.duration')} mins</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{paddingTop:'20px'}}></div>
        <table style={{margin: '0 auto'}}>
          <tbody>
            <tr><Tag color="green" style={{ marginBottom: '.2rem'}}>{Time.getPSTPDT()}</Tag>&nbsp;</tr>
            <tr>
              <td style={{background:'lightblue',border:'1px solid black'}}>
                <div>{session_date.format('M/D/YYYY')}</div>
                <div>{time?.toString(false)}</div>
              </td>
              <td>
                <ArrowRightOutlined />
              </td>
              <td style={{background:'lightgreen',border:'1px solid black'}}>
                <div>{newFixed.session_date.format('M/D/YYYY')}</div>
                <div>{newFixed.time?.toString(false)}</div>
              </td>
            </tr>
            {diffHrs ? (
              <>
                <tr>&nbsp;</tr>
                <tr>
                  <Tag color="blue" style={{ marginBottom: '.2rem', marginTop: '.2rem' }}>Local</Tag>
                </tr>
                <tr>
                  <td style={{background:'lightblue',border:'1px solid black'}}>
                    <div>{session_date_local.format('M/D/YYYY')}</div>
                    <div>{local_time?.toString(false)}</div>
                  </td>
                  <td>
                    <ArrowRightOutlined />
                  </td>
                  <td style={{background:'lightgreen',border:'1px solid black'}}>
                    <div>{newFixed.session_date_local.format('M/D/YYYY')}</div>
                    <div>{newFixed.local_time?.toString(false)}</div>
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <Modal
        title='Reschedule Session'
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        okText='Reschedule'
        width={500}
        height={300}
      >
        {this.dialogContent()}
      </Modal>
    );
  }
}

export default connect(null,actions)(withRouter(RescheduleSessionModal));
