import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Select, Input, Popover, Button } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from '../../actions/scheduler';
import { safe } from '../../helpers/reduxSelectors';
import { VLayout, VLayoutHeader, VLayoutContent } from './Layout';

// This providerList can be influenced by other parts of the program, need
// to isolate the store from other calls.

class ProviderSelector extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.inputElementRef = React.createRef();
    this.state = {
      providerName: safe(props, 'initialValue.full_name', 'Choose Provider'),
      visible: false,
      searchLocation: 0,
      searchType: 0,
    };
  }

  componentDidMount = () => {
    this.props.getProviderList();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({
        providerName: safe(this.props, 'initialValue.full_name', 'Choose Provider'),
      });
    }
  };

  setSearchName = (e) => this.setState({ searchName: e.target.value });
  setLocation = (e) => this.setState({ searchLocation: e });
  setType = (e) => this.setState({ searchType: e });
  chooseProvider = (p) => {
    this.props.onSelect(p);
    this.onVisibleChange(false);
    this.setState({
      providerName: p.full_name,
    });
  };

  // This should be replaced with a call to get actual locations table abbr
  // but is depending on adding abbreviations to locaitons tables.
  getLocationName = (id) => {
    switch (id) {
      case 1:
        return 'SF';
      case 2:
        return 'WC';
      case 3:
        return 'LA';
      case 4:
        return 'TEL';
      default:
        return 'OTH';
    }
  };

  getTotalLocation = (l1, l2) => {
    const result = [];
    if (l1) {
      result.push(this.getLocationName(l1));
    }
    if (l2) {
      result.push(this.getLocationName(l2));
    }

    return result.sort().join('/');
  };

  getProviderList = () => {
    return this.props.providerList.filter((x) => {
      if (
        this.state.searchLocation !== 0 &&
        x.primary_location_id !== this.state.searchLocation &&
        x.secondary_location_id !== this.state.searchLocation
      ) {
        return false;
      }

      if (this.state.searchLocation === 4 && x.primary_location_id !== 4) {
        return false;
      }

      if (
        !(this.state.searchType === 2 && x.roles.includes('therapist')) &&
        !(this.state.searchType === 1 && x.roles.includes('prescriber')) &&
        this.state.searchType !== 0
      ) {
        return false;
      }

      if (
        this.state.searchName &&
        this.state.searchName.length > 0 &&
        x.full_name.toLowerCase().includes(this.state.searchName.toLowerCase())
      ) {
        return true;
      } else if (!this.state.searchName || this.state.searchName.length === 0) {
        return true;
      } else {
        return false;
      }
    });
  };

  renderContent() {
    const data = this.getProviderList();

    const headerStyle = {
      margin: '0px',
      padding: '0px',
    };

    const plStyle = {
      margin: '0px',
      paddingLeft: '10px',
      height: '100%',
    };

    return (
      <div style={{ height: '400px', width: '400px' }}>
        <VLayout>
          <VLayoutHeader>
            <div style={headerStyle}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>
                      <Input
                        onChange={this.setSearchName}
                        value={this.state.searchName}
                        placeholder="Provider Name"
                        allowClear={true}
                        ref={this.inputElementRef}
                      />
                    </td>
                    <td style={{ width: '75px' }}>
                      <Select value={this.state.searchLocation} onChange={this.setLocation} style={{ width: '95px' }}>
                        <Select.Option value={0}>ALL</Select.Option>
                        <Select.Option value={1}>SF</Select.Option>
                        <Select.Option value={2}>WC</Select.Option>
                        <Select.Option value={3}>LA</Select.Option>
                        <Select.Option value={4}>TEL</Select.Option>
                      </Select>
                    </td>
                    <td style={{ width: '75px' }}>
                      <Select value={this.state.searchType} onChange={this.setType} style={{ width: '75px' }}>
                        <Select.Option value={0}>ALL</Select.Option>
                        <Select.Option value={1}>Rx</Select.Option>
                        <Select.Option value={2}>Psy</Select.Option>
                      </Select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </VLayoutHeader>
          <VLayoutContent>
            <div style={plStyle}>
              <table className="provider-list">
                <tbody>
                  {data.map((x) => (
                    <tr
                      key={x.id}
                      onClick={() => {
                        this.chooseProvider(x);
                      }}
                    >
                      <td>{x.full_name}</td>
                      <td style={{ width: '100px' }}>
                        {this.getTotalLocation(x.primary_location_id, x.secondary_location_id)}
                      </td>
                      <td style={{ width: '75px' }}>{x.roles.includes('prescriber') ? 'Rx' : null}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </VLayoutContent>
        </VLayout>
      </div>
    );
  }

  onVisibleChange = (visible) => {
    this.setState({
      visible: visible,
      searchLocation: 0,
      searchType: 0,
      searchName: null,
    });
    // Hack to get the focus;
    if (this.inputElementRef && this.inputElementRef.current && this.inputElementRef.current.input) {
      this.inputElementRef.current.input.autofocus = true;
    }
  };

  render() {
    return (
      <Popover
        trigger="click"
        placement="bottomLeft"
        content={this.renderContent()}
        visible={this.state.visible}
        onVisibleChange={this.onVisibleChange}
      >
        <Button type="primary">
          {this.state.providerName} <DownOutlined />
        </Button>
      </Popover>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    providerList: safe(state, 'scheduler.provider.list.data', []),
  };
};

export default connect(mapStateToProps, actions)(ProviderSelector);
