import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';
import { Table, Spin } from 'antd';
import { withRouter, Link } from 'react-router-dom';

import FormattedCell from '../../../shared/FormattedCell';

import { safe } from '../../../../helpers/reduxSelectors';
import { paymentMethods } from '../../../../helpers/paymentMethods';
import * as billing_actions from '../../../../actions/billing';
import * as shared_actions from '../../../../actions/shared';

class PtPayments extends React.Component {

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.payments_tab !== this.props.payments_tab ||
        prevProps.payments_pt_page !== this.props.payments_pt_page) {
      this.reload();
    }
  }

  reload = () => {
    this.props.getPtPaymentList(this.props.payments_pt_page,this.props.payments_pagesize,{});
  }

  tableChange = ({current}) => {
    // This will automatically trigger a reload from componentDidUpdate
    this.props.setBillingState({
      payments_pt_page: current
    });
  }

  render() {
    const columns = [{
      title: 'Payment ID',
      dataIndex: 'id',
      align: 'left',
      width: 100
    },{
      title: 'Creator',
      dataIndex: 'creator_id',
      align: 'left',
      width: 75,
      render: (value,record) => {
        return safe(record,'creator.initials','-');
      }
    },{
      title: 'Date Posted',
      dataIndex: 'posted_date',
      align: 'left',
      width: 175,
      render: (value,record) => {
        return moment(value).format('MM/DD/YYYY HH:mm:ss');
      }
    },{
      title: 'Date Paid',
      dataIndex: 'check_date',
      align:'left',
      width: 100,
      render: (value,record) => {
        return moment(value).format('M/DD/YYYY');
      }
    },{
      title: 'Check #',
      dataIndex: 'check_num',
      width: 200
    },{
      title: 'Payment Type',
      dataIndex: 'payment_type',
      width: 130,
      render: (value,record) => {
        const r = paymentMethods.find(x=>x.id===value);
        return (r) ? r.name : null;
      }
    },{
      title: 'Patient',
      render: (value,record) => {
        const patientId = safe(record,'billing_patients.patient_id');
        return (
          <Link to={'/billing/patients/'+patientId}>
            {safe(record,'billing_patients.patients.full_name','')}
          </Link>
        );
      }
    },{
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      align: 'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00');
      }
    },{
      title: 'Unallocated',
      dataIndex: 'unallocated_amount',
      width: 100,
      align: 'right',
      render: (value,record) => {
        if (!record.deleted) {
          return <FormattedCell value={value} blankonzero={false}/>;
        }
        return 'N/A';
      }
    }];

    const data = safe(this.props,'currentPaymentList.rows',[]);
    const count = safe(this.props,'currentPaymentList.count',0);

    return (
      <Spin spinning={this.props.isBusy}>
        <Table
          bordered={false}
          rowKey='id'
          columns={columns}
          onChange={this.tableChange}
          pagination={{
            current: this.props.payments_pt_page,
            pageSize: this.props.payments_pagesize,
            total: count,
            showQuickJumper: true
          }}
          dataSource={data}
          size='small'
        />
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.ptpayments.list.processing')
    ) ? true : false,
    payments_tab: safe(state,'shared.domain.billing.payments_tab',"1"),
    currentPaymentList: safe(state,'billing.ptpayments.list.data',{}),
    payments_pt_page: safe(state,'shared.domain.billing.payments_pt_page',1),
    payments_pagesize: safe(state,'shared.domain.billing.payments_pagesize',50)
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(withRouter(PtPayments));
