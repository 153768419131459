import React, { Component } from 'react';
import { Input } from 'antd';

export const fieldsVitals = [
  {name: 'weight', label: 'Weight'},
  {name: 'pulse', label: 'Pulse'},
  {name: 'bp', label: 'BP'},
  {name: 'height', label: 'Height'}
];

const blank_vitals = {
  weight:'',
  pulse:'',
  bp:'',
  height:'',
};

export class Vitals extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    for (let i = 0; i < fieldsVitals.length; i++) {
      this.state[fieldsVitals[i].name] = fieldsVitals[i].value;
    }
  }

  componentWillReceiveProps(nextProps) {
    if ('value' in nextProps) {
      let value = nextProps.value;
      if (typeof(value) !== 'object') {
        value = blank_vitals;
      }
      this.setState(value);
    }
  }

  handleChange = (event) => {
    const target = event.target
    const value = target.value;
    const name = target.name;

    if (!('value' in this.props)) {
      this.setState({
        [name]: value
      });
    }

    const onChange = this.props.onChange;
    if (onChange) {
      onChange({
        ...this.state,
        [name]: value
      })
    }
  }

  render() {
    return fieldsVitals.map(x => (
      <div key={x.name} style={{paddingBottom:'3px'}}>
        <Input
          name={x.name}
          type="text"
          value={this.state[x.name]}
          addonBefore={(<div style={{width:'100px'}}>{x.label}</div>)}
          onChange={this.handleChange}
        />
      </div>
    ));
  }
}
