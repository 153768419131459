/**
 * InlineFormWrapper.tsx
 */
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import { Form } from 'antd';
import formItemRules from './formItemRules';
import styles from './InlineFormWrapper.style';
import { useEffect } from 'react';

interface PropsI {
  reportId: number;
  children?: React.ReactNode;
  page?: number;
  pageSize?: number;
}

const InlineForm: React.FC<PropsI> = (props: PropsI): any => {
  const { page, pageSize, reportId } = props;
  const doRunReport = useContextSelector(InlineContext, (c) => c.doRunReport);
  const form = useContextSelector(InlineContext, (c) => c.state.form);
  const setState = useContextSelector(InlineContext, (c) => c.setState);

  useEffect(() => {
    form?.setFieldsValue({ page, pageSize, reportId });
  }, [page, pageSize, reportId]);

  const onFinish = () => {
    setState((s) => ({ ...s, inlineData: undefined }));
    doRunReport();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form form={form} layout="inline" onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item hidden={true} initialValue={reportId} name="reportId" rules={formItemRules.reportId} />
      <Form.Item hidden={true} initialValue={page} name="page" rules={formItemRules.page} />
      <Form.Item hidden={true} initialValue={pageSize} name="pageSize" rules={formItemRules.pageSize} />
      <div style={styles.root}>{props.children}</div>
    </Form>
  );
};

export default InlineForm;
