export const PM_CODES_LIST_REQUEST = 'PM_CODES_LIST_REQUEST';
export const PM_CODES_LIST_SUCCESS = 'PM_CODES_LIST_SUCCESS';
export const PM_CODES_LIST_FAILURE = 'PM_CODES_LIST_FAILURE';
export const PM_CODES_LIST_CLEAR   = 'PM_CODES_LIST_CLEAR';
export const PM_CODES_LOAD_REQUEST = 'PM_CODES_LOAD_REQUEST';
export const PM_CODES_LOAD_SUCCESS = 'PM_CODES_LOAD_SUCCESS';
export const PM_CODES_LOAD_FAILURE = 'PM_CODES_LOAD_FAILURE';
export const PM_CODES_LOAD_CLEAR   = 'PM_CODES_LOAD_CLEAR';
export const PM_CODES_SAVE_REQUEST = 'PM_CODES_SAVE_REQUEST';
export const PM_CODES_SAVE_SUCCESS = 'PM_CODES_SAVE_SUCCESS';
export const PM_CODES_SAVE_FAILURE = 'PM_CODES_SAVE_FAILURE';
export const PM_CODES_SAVE_CLEAR   = 'PM_CODES_SAVE_CLEAR';
export const PM_CODES_CREATE_REQUEST = 'PM_CODES_CREATE_REQUEST';
export const PM_CODES_CREATE_SUCCESS = 'PM_CODES_CREATE_SUCCESS';
export const PM_CODES_CREATE_FAILURE = 'PM_CODES_CREATE_FAILURE';
export const PM_CODES_CREATE_CLEAR   = 'PM_CODES_CREATE_CLEAR';
export const PM_CODES_UPDATE_REQUEST = 'PM_CODES_UPDATE_REQUEST';
export const PM_CODES_UPDATE_SUCCESS = 'PM_CODES_UPDATE_SUCCESS';
export const PM_CODES_UPDATE_FAILURE = 'PM_CODES_UPDATE_FAILURE';
export const PM_CODES_UPDATE_CLEAR   = 'PM_CODES_UPDATE_CLEAR';
export const PM_CODES_DEACTIVATE_REQUEST = 'PM_CODES_DEACTIVATE_REQUEST';
export const PM_CODES_DEACTIVATE_SUCCESS = 'PM_CODES_DEACTIVATE_SUCCESS';
export const PM_CODES_DEACTIVATE_FAILURE = 'PM_CODES_DEACTIVATE_FAILURE';
export const PM_CODES_DEACTIVATE_CLEAR   = 'PM_CODES_DEACTIVATE_CLEAR';
export const PM_CODES_REACTIVATE_REQUEST = 'PM_CODES_REACTIVATE_REQUEST';
export const PM_CODES_REACTIVATE_SUCCESS = 'PM_CODES_REACTIVATE_SUCCESS';
export const PM_CODES_REACTIVATE_FAILURE = 'PM_CODES_REACTIVATE_FAILURE';
export const PM_CODES_REACTIVATE_CLEAR   = 'PM_CODES_REACTIVATE_CLEAR';

export const PM_FEESCHEDULE_GROUP_LIST_REQUEST = 'PM_FEESCHEDULE_GROUP_LIST_REQUEST';
export const PM_FEESCHEDULE_GROUP_LIST_SUCCESS = 'PM_FEESCHEDULE_GROUP_LIST_SUCCESS';
export const PM_FEESCHEDULE_GROUP_LIST_FAILURE = 'PM_FEESCHEDULE_GROUP_LIST_FAILURE';
export const PM_FEESCHEDULE_GROUP_LIST_CLEAR = 'PM_FEESCHEDULE_GROUP_LIST_CLEAR';
export const PM_FEESCHEDULE_GROUP_CREATE_REQUEST = 'PM_FEESCHEDULE_GROUP_CREATE_REQUEST';
export const PM_FEESCHEDULE_GROUP_CREATE_SUCCESS = 'PM_FEESCHEDULE_GROUP_CREATE_SUCCESS';
export const PM_FEESCHEDULE_GROUP_CREATE_FAILURE = 'PM_FEESCHEDULE_GROUP_CREATE_FAILURE';
export const PM_FEESCHEDULE_GROUP_CREATE_CLEAR = 'PM_FEESCHEDULE_GROUP_CREATE_CLEAR';
export const PM_FEESCHEDULE_GROUP_UPDATE_REQUEST = 'PM_FEESCHEDULE_GROUP_UPDATE_REQUEST';
export const PM_FEESCHEDULE_GROUP_UPDATE_SUCCESS = 'PM_FEESCHEDULE_GROUP_UPDATE_SUCCESS';
export const PM_FEESCHEDULE_GROUP_UPDATE_FAILURE = 'PM_FEESCHEDULE_GROUP_UPDATE_FAILURE';
export const PM_FEESCHEDULE_GROUP_UPDATE_CLEAR = 'PM_FEESCHEDULE_GROUP_UPDATE_CLEAR';
export const PM_FEESCHEDULE_GROUP_REMOVE_REQUEST = 'PM_FEESCHEDULE_GROUP_REMOVE_REQUEST';
export const PM_FEESCHEDULE_GROUP_REMOVE_SUCCESS = 'PM_FEESCHEDULE_GROUP_REMOVE_SUCCESS';
export const PM_FEESCHEDULE_GROUP_REMOVE_FAILURE = 'PM_FEESCHEDULE_GROUP_REMOVE_FAILURE';
export const PM_FEESCHEDULE_GROUP_REMOVE_CLEAR = 'PM_FEESCHEDULE_GROUP_REMOVE_CLEAR';
export const PM_FEESCHEDULE_SCHEDULE_LIST_REQUEST = 'PM_FEESCHEDULE_SCHEDULE_LIST_REQUEST';
export const PM_FEESCHEDULE_SCHEDULE_LIST_SUCCESS = 'PM_FEESCHEDULE_SCHEDULE_LIST_SUCCESS';
export const PM_FEESCHEDULE_SCHEDULE_LIST_FAILURE = 'PM_FEESCHEDULE_SCHEDULE_LIST_FAILURE';
export const PM_FEESCHEDULE_SCHEDULE_LIST_CLEAR = 'PM_FEESCHEDULE_SCHEDULE_LIST_CLEAR';
export const PM_FEESCHEDULE_SCHEDULE_LOAD_REQUEST = 'PM_FEESCHEDULE_SCHEDULE_LOAD_REQUEST';
export const PM_FEESCHEDULE_SCHEDULE_LOAD_SUCCESS = 'PM_FEESCHEDULE_SCHEDULE_LOAD_SUCCESS';
export const PM_FEESCHEDULE_SCHEDULE_LOAD_FAILURE = 'PM_FEESCHEDULE_SCHEDULE_LOAD_FAILURE';
export const PM_FEESCHEDULE_SCHEDULE_LOAD_CLEAR = 'PM_FEESCHEDULE_SCHEDULE_LOAD_CLEAR';
export const PM_FEESCHEDULE_SCHEDULE_CREATE_REQUEST = 'PM_FEESCHEDULE_SCHEDULE_CREATE_REQUEST';
export const PM_FEESCHEDULE_SCHEDULE_CREATE_SUCCESS = 'PM_FEESCHEDULE_SCHEDULE_CREATE_SUCCESS';
export const PM_FEESCHEDULE_SCHEDULE_CREATE_FAILURE = 'PM_FEESCHEDULE_SCHEDULE_CREATE_FAILURE';
export const PM_FEESCHEDULE_SCHEDULE_CREATE_CLEAR = 'PM_FEESCHEDULE_SCHEDULE_CREATE_CLEAR';
export const PM_FEESCHEDULE_SCHEDULE_UPDATE_REQUEST = 'PM_FEESCHEDULE_SCHEDULE_UPDATE_REQUEST';
export const PM_FEESCHEDULE_SCHEDULE_UPDATE_SUCCESS = 'PM_FEESCHEDULE_SCHEDULE_UPDATE_SUCCESS';
export const PM_FEESCHEDULE_SCHEDULE_UPDATE_FAILURE = 'PM_FEESCHEDULE_SCHEDULE_UPDATE_FAILURE';
export const PM_FEESCHEDULE_SCHEDULE_UPDATE_CLEAR = 'PM_FEESCHEDULE_SCHEDULE_UPDATE_CLEAR';
export const PM_FEESCHEDULE_SCHEDULE_REMOVE_REQUEST = 'PM_FEESCHEDULE_SCHEDULE_REMOVE_REQUEST';
export const PM_FEESCHEDULE_SCHEDULE_REMOVE_SUCCESS = 'PM_FEESCHEDULE_SCHEDULE_REMOVE_SUCCESS';
export const PM_FEESCHEDULE_SCHEDULE_REMOVE_FAILURE = 'PM_FEESCHEDULE_SCHEDULE_REMOVE_FAILURE';
export const PM_FEESCHEDULE_SCHEDULE_REMOVE_CLEAR = 'PM_FEESCHEDULE_SCHEDULE_REMOVE_CLEAR';
