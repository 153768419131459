/**
 * AMDTransition.tsx
 */
import reports from 'reports';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import ReportWrapper from 'components/App_/ReportsV2_/ReportWrapper';
import InlineFormWrapper from 'components/App_/ReportsV2_/InlineFormWrapper';
import Table from 'components/App_/ReportsV2_/Table';
import columns from 'components/App_/ReportsV2_/TableColumns_/amdTransition';
import { InlineDataT } from 'reports';
import DisplayReport from 'components/App_/ReportsV2_/InlineControls_/DisplayReport';
import CSVDownload from '../InlineControls_/CSVDownload';
import DateTimeRangeSelector from '../InlineControls_/DateTimeRangeSelector';

const reportId = 35;

const AmdTransition: React.FC = () => {
  const inlineData = useContextSelector(InlineContext, (c) => c.state.inlineData) as InlineDataT;
  const report = reports.find((r) => r.id === reportId);

  const formOptions = { reportId };

  const tableOptions = {
    data: inlineData,
    columns,
    reportId,
    rowKey: 'ReferenceID',
    total: inlineData?.length,
  };

  return (
    <ReportWrapper>
      <h3>{report?.name}</h3>
      <InlineFormWrapper {...formOptions}>
        <DateTimeRangeSelector />
        <DisplayReport />
        <CSVDownload prefix={report?.csvPrefix} />
      </InlineFormWrapper>
      <Table {...tableOptions} />
    </ReportWrapper>
  );
};

export default AmdTransition;
