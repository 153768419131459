export const attachments = [
  {id:1,title:'Labs & Other Studies'},
  {id:2,title:'Registration Forms'},
  {id:3,title:'Consents'},
  {id:4,title:'Letters'},
  {id:5,title:'Outside Notes'},
  {id:6,title:'Other'},
  {id:7,title:'Insurance Card'}
];

export function filterAttachments(list,hasAttachments,aType=0) {
  return list.filter(x => {
    // Want everything with or without attachments
    if (!hasAttachments) {
      return true;
    } else {
      // Only want notes with attachments
      if (x.attachments && x.attachments.length > 0) {
        if (aType === 0) {
          // Any attachments
          return true;
        } else {
          // Only the specific attachment
          for (let i = 0; i < x.attachments.length; i++) {
            if (x.attachments[i].type === aType) {
              return true;
            }
          }
        }
      }
    }
    return false;
  });
}
