import React from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Spin, notification, Select } from 'antd';
import numeral from 'numeral';

import * as actions from '../../../../../actions/billing';
import { safe } from '../../../../../helpers/reduxSelectors';
import { adjustments } from '../../../../../helpers/adjustments';

class AdjustmentModal extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible === true) {
      this.props.form.resetFields();
      if (this.props.serviceId) {
        this.props.getBillingServiceLine(this.props.serviceId);
      }
    }

    if (prevProps.ledgerAdjEvent !== this.props.ledgerAdjEvent) {
      if (this.props.ledgerAdjEvent.success) {
        notification['success']({
          message: 'Success',
          description: 'Adjustment applied'
        });
      } else if (this.props.ledgerAdjEvent.error) {
        notification['error']({
          message: 'Error',
          description: safe(this.props,'ledgerAdjEvent.error.data.message')
        })
      }
    }
  }

  handleOk = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.createBillingLedgerAdjustment(this.props.patientId,this.props.serviceId,
          parseFloat(values.ins_adj),parseFloat(values.pt_adj),values.adj_type,values.reason);
        this.props.closeDialog();
      }
    });
  }

  validateNumber = (rule, value, callback) => {
    if (isNaN(value)) {
      callback(' ');
    }
    callback();
  }

  renderModalContent = () => {
    const { getFieldDecorator } = this.props.form;
    const ins_rate = parseFloat(safe(this.props,'lineData.ins_rate',0));
    const pt_rate = parseFloat(safe(this.props,'lineData.pt_rate',0));
    const prev_ins_adj = parseFloat(safe(this.props,'lineData.ins_adj',0));
    const prev_pt_adj = parseFloat(safe(this.props,'lineData.pt_adj',0));

    const ins_adj = parseFloat(this.props.form.getFieldValue('ins_adj'));
    const pt_adj = parseFloat(this.props.form.getFieldValue('pt_adj'));

    return (
      <div>
        <Form autoComplete="off" className='adjustments-form'>
          <table className='adjustments-table'>
            <thead>
              <tr>
                <th width={115}></th>
                <th style={{textAlign:'right',width:100}}>Insurance</th>
                <th style={{textAlign:'right',width:100}}>Patient</th>
                <th style={{textAlign:'right',width:100}}>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Rate</th>
                <td style={{textAlign:'right'}}>{numeral(ins_rate).format('$0,0.00')}</td>
                <td style={{textAlign:'right'}}>{numeral(pt_rate).format('$0,0.00')}</td>
                <td style={{textAlign:'right'}}>{numeral(ins_rate+pt_rate).format('$0,0.00')}</td>
              </tr>
              <tr>
                <th>Prev Adjs</th>
                <td style={{textAlign:'right'}}>{numeral(prev_ins_adj).format('$0,0.00')}</td>
                <td style={{textAlign:'right'}}>{numeral(prev_pt_adj).format('$0,0.00')}</td>
                <td style={{textAlign:'right'}}>{numeral(prev_ins_adj+prev_pt_adj).format('$0,0.00')}</td>
              </tr>
              <tr>
                <th>New Adjs</th>
                <td style={{textAlign:'right'}}>
                  <Form.Item style={{marginBottom:'0px'}}>
                    {getFieldDecorator('ins_adj', {
                      rules: [
                        { required: true, message: " " },
                        { validator: this.validateNumber }
                      ],
                      initialValue: 0.0
                    })(
                      <Input style={{height:'18px',textAlign:'right',marginLeft:'-12px',marginRight:'-12px'}} />
                    )}
                  </Form.Item>
                </td>
                <td style={{textAlign:'right'}}>
                  <Form.Item>
                    {getFieldDecorator('pt_adj', {
                      rules: [
                        { required: true, message: " " },
                        { validator: this.validateNumber }
                      ],
                      initialValue: 0.0
                    })(
                      <Input style={{height:'18px',marginBottom:'0px',textAlign:'right',marginLeft:'-12px',marginRight:'-12px'}} />
                    )}
                  </Form.Item>
                </td>
                <td style={{verticalAlign:'middle',textAlign:'right'}}>{numeral(ins_adj+pt_adj).format('$0,0.00')}</td>
              </tr>
              <tr>
                <th>New Rate</th>
                <td style={{borderTop:'1px solid lightgray',textAlign:'right'}}>{numeral(ins_rate-ins_adj-prev_ins_adj).format('$0,0.00')}</td>
                <td style={{borderTop:'1px solid lightgray',textAlign:'right'}}>{numeral(pt_rate-pt_adj-prev_pt_adj).format('$0,0.00')}</td>
                <td style={{borderTop:'1px solid lightgray',textAlign:'right'}}>{numeral(ins_rate+pt_rate-ins_adj-pt_adj-prev_ins_adj-prev_pt_adj).format('$0,0.00')}</td>
              </tr>
            </tbody>
          </table>
          <div style={{paddingTop:'15px'}}>
            <Form.Item>
            {getFieldDecorator('adj_type', {
              rules: [{ required: true, message: " " }],
            })(
              <Select placeholder='Type of adjustment'>
                {adjustments.map(x=><Select.Option key={x.id} value={x.id}>{x.text}</Select.Option>)}
              </Select>
            )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('reason', {
                rules: [{ required: true, message: " " }],
                initialValue: ''
              })(
                <Input placeholder='Reason for the adjustment'/>
              )}
            </Form.Item>
          </div>
        </Form>
      </div>
    )
  }

  render() {
    return (
      <Modal
        title="Record an Adjustment"
        visible={this.props.visible}
        onOk={this.handleOk}
        okText='Submit'
        onCancel={this.props.closeDialog}
      >
        <Spin spinning={this.props.isBusy}>
          {this.renderModalContent()}
        </Spin>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.serviceline.load.processing',false)
    ) ? true : false,
    lineData: safe(state,'billing.serviceline.load.data',{}),
    ledgerAdjEvent: safe(state,'billing.ledger.adj',{})
  }
}

export default connect(mapStateToProps,actions)(Form.create()(AdjustmentModal));
