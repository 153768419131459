import moment from 'moment';
import Time from '../components/shared/Time';

export function isValidSessionCancellation(holidays,session_date,start_time) {
  const current_time = parseInt(moment().format('HHmm'),10);
  const current_date = moment().format('YYYY-MM-DD');
  return isValidCancellation(holidays,current_date,current_time,session_date,start_time);
}

export function isLateCancel(holidays,session_date,start_time) {
  return !isValidSessionCancellation(holidays,session_date,start_time);
}

function isValidCancellation(holidays,start_date,start_time,end_date,end_time) {
  // We check if the difference between two dates + times excluding company
  // holidays and weekends (business hours) is more or less than 48 hours.
  // Less than 48 hours is the time at which a late cancellation fee will
  // be assessed to the patient.

  const sd = moment(start_date);
  const ed = moment(end_date);

  if (ed.diff(sd,'days') < 2) {
    return false;
  }

  let start_hours = ( (new Time(start_time)).diff(new Time(2359))+1 )/60;
  const dow_start = moment(start_date,'YYYY-MM-DD','America/Los_Angeles').day()
  if (holidays[sd.format('YYYY-MM-DD')] || dow_start === 0 || dow_start === 6) {   // is holiday or weekend
    start_hours = 0;
  }

  // Regardless of whether the end_date is a holiday or weekend, we must
  // start counting from the beginning of the day.

  const et = new Time(end_time)
  let end_hours = et.hours + (et.mins/60);

  let dayCount = 0;
  let d = moment(sd).add(1,'days');
  while (d.isBefore(ed,'day')) {
    const dow = moment(d,'YYYY-MM-DD','America/Los_Angeles').day();
    if (!holidays[d.format('YYYY-MM-DD')] && dow !== 0 && dow !== 6) {
      dayCount++;
    }
    d.add(1,'days');
  }

  return (start_hours + end_hours + dayCount*24 < 48) ? false : true;
}
