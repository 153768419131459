import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AttachmentUploadItem from './AttachmentUploadItem';

class AttachmentUploadList extends Component {
  static propTypes = {
    list: PropTypes.array,
    noteId: PropTypes.number
  }

  constructor(props) {
    super(props);
    this.state = {
      editDisplay: false
    }
  }

  render() {
    return (
      <div style={{paddingBottom: '20px'}}>
        <table style={{width:'700px'}}>
          <tbody>
            {this.props.list.map(x =>
              <AttachmentUploadItem
                key={x.id}
                type={x.type}
                name={x.title}
                filename={x.filename}
                id={x.id}
                noteId={this.props.noteId}
              />
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AttachmentUploadList;
