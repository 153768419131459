import React from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Spin } from 'antd';
import numeral from 'numeral';

import * as actions from '../../../../../../actions/billing';
import { safe } from '../../../../../../helpers/reduxSelectors';
import { processEventNotification } from '../../../../../../helpers/util';

class RefundDialog extends React.Component {

  componentDidUpdate(prevProps) {
    processEventNotification(prevProps,this.props,'refundEvent','Refund Applied');
  }

  closeDialog = () => {
    this.props.form.resetFields();
    this.props.closeDialog();
  }

  handleOk = () => {
    this.props.form.validateFields((err,values) => {
      if (!err) {
        this.props.refundPtPayment(
          this.props.patientId,
          this.props.transaction.id,
          values.to_refund
        );
        this.closeDialog();
      }
    });
  }

  renderModalContent = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
      },
    };

    const amount = safe(this.props,'transaction.amount',0);
    const refunded_amount = safe(this.props,'transaction.refunded_amount',0);
    const unallocated_amount = safe(this.props,'transaction.unallocated_amount',0);

    return (
      <Form className='payment-form' autoComplete='false'>

        <FormItem {...formItemLayout} label="Total Charge Amount">
          {getFieldDecorator('amount', {})(
            <div>{numeral(amount).format('$0,0.00')}</div>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Prev Refunded">
          {getFieldDecorator('refunded_amount', {})(
            <div>{numeral(refunded_amount).format('$0,0.00')}</div>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="To Refund">
          {getFieldDecorator('to_refund', {
            rules: [
              { required: true, message: " " },
              { validator: (rule,value,callback) => {
                  if (isNaN(value)) {
                    callback('Must enter a number');
                  }
                  /*
                  if (value > amount-refunded_amount) {
                    callback('Maximum possible refund: '+numeral(amount-refunded_amount).format('$0,0.00'));
                  } */
                  if (value > unallocated_amount) {
                    callback('Maximum possible refund: '+numeral(unallocated_amount).format('$0,0.00'));
                  }
                  callback();
              }}
            ],
            initialValue: (unallocated_amount)
          })(
            <Input />
          )}
        </FormItem>

      </Form>
    )
  }

  render() {
    return (
      <Modal
        title="Refund Patient"
        visible={this.props.visible}
        onOk={this.handleOk}
        okText='Submit'
        onCancel={this.closeDialog}
      >
        <Spin spinning={this.props.isBusy}>
          {this.renderModalContent()}
        </Spin>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'billing.ptpayments.refund.processing') ? true : false,
    refundEvent: safe(state,'billing.ptpayments.refund')
  }
}

export default connect(mapStateToProps,actions)(Form.create()(RefundDialog));
