import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, DatePicker, Spin, Select, Input } from 'antd';
//import moment from 'moment';
import { connect } from 'react-redux';

import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/emr';
import { processEventNotification } from '../../../../helpers/util';

class DeleteLateCancelDialog extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true) {
      this.props.getProvidersList();
      this.props.form.resetFields();
    }

    processEventNotification(prevProps,this.props,'cancelEvent','Note Deleted');
  }

  onClickSave = () => {
    this.props.form.validateFields((err,values) => {
      if (!err) {
        this.props.deleteLateCancel(this.props.patientId,values);
        this.props.closeDialog();
      }
    });
  }

  onClickCancel = () => {
    this.props.closeDialog();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title='Delete Late Cancel/No Show'
        visible={this.props.visible}
        okText='Submit'
        onOk={this.onClickSave}
        onCancel={this.onClickCancel}
      >
        <Spin spinning={this.props.isBusy}>
          <Form>
            <Form.Item label='Provider'>
              {getFieldDecorator('provider_id', {
                rules: [{ required: true, message: " "}],
              })(
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.props.provider_list.map(x=><Select.Option key={x.id} value={x.id}>{x.full_name}</Select.Option>)}
                </Select>
              )}
            </Form.Item>
            <Form.Item label='Date of Service'>
              {getFieldDecorator('dos',{
                rules: [{ required: true, message: " "}],
              })(
                <DatePicker format='M/D/YYYY'/>
              )}
            </Form.Item>
            <Form.Item label='Description'>
              {getFieldDecorator('description',{
                rules: [{ required: true, message: " ", whitespace: true}],
                initialValue: ''
              })(
                <Input.TextArea/>
              )}
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: false,
    activeUserId: safe(state,'shared.domain.activeUser.id'),
    provider_list: safe(state,'emr.providers.list.data',[]),
    cancelEvent: safe(state,'emr.note.latecanceldelete')
  }
}

export default connect(mapStateToProps,actions)(Form.create()(DeleteLateCancelDialog));
