import React from 'react';
import { connect } from 'react-redux';
import { safe } from '../../../helpers/reduxSelectors';

import BillingStatement from './Statement_/BillingStatement';
import Superbill from './Statement_/Superbill';

import * as actions from '../../../actions/billing';

class Statement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: parseInt(this.props.match.params.patient_id,10),
      statementId: parseInt(this.props.match.params.statement_id,10),
      print: false
    }
  }

  componentDidMount() {
    this.props.loadBillingStatement(this.state.patientId,this.state.statementId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load !== this.props.load) {
      if (this.props.load.success === true) {
        if (!this.state.print) {
          this.setState({
            print: true
          });
          setTimeout(()=>{window.print()},3000);
        }
      } else if (this.props.load.error) {
      }
    }
  }

  render() {
    if (this.props.data === {}) {
      return null;
    }

    switch (this.props.data.type) {
      case 1:
        return <Superbill data={this.props.data}/>;
      case 2:
        return <BillingStatement data={this.props.data}/>
      default:
        return null;
    }

  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    load: safe(state,'billing.statements.load',{}),
    data: safe(state,'billing.statements.load.data',{}),
  }
}

export default connect(mapStateToProps,actions)(Statement);
