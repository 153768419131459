import React from 'react';

export default class Timeout extends React.Component {
  static TIMEOUT_MINUTES = 60;
  static POLLING_INTERVAL_MS = 1000;

  static events = [
    'click',
    'keydown',
    'mousedown'
  ];

  constructor(props) {
    super(props);
    this.check();                // If all tabs closed, should timeout on restart
    this.reset();                // Otherwise, if OK, startup.
    this.tId = setInterval(() => {
      this.check();
    }, Timeout.POLLING_INTERVAL_MS);
  }

  componentDidMount() {
    Timeout.events.forEach(e => {
      document.addEventListener(e,this.reset,{capture:true,passive:true});
    });
  }

  componentWillUnmount() {
    clearInterval(this.tId)
    Timeout.events.forEach(e => {
      document.removeEventListener(e,this.reset,{capture:true,passive:true});
    })
  }

  reset = () => {
    localStorage.setItem('PCPA',(Date.now()).toString());
  }

  check = () => {
    const lastAction = parseInt(localStorage.getItem('PCPA'),10);
    if ((lastAction + Timeout.TIMEOUT_MINUTES * 60 * 1000) - Date.now() < 0) {
      window.location.href='/logout?timeout=1';
    }
  }

  render () {
    return this.props.children || null
  }
}
