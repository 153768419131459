import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Parser as HtmlToReactParser } from 'html-to-react';

class TemplatePreview extends Component {
  static propTypes = {
    htmlTemplate: PropTypes.string,
    fieldValues: PropTypes.object,
  };

  escapeRegExp = (str) => {
    return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
    //    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  };

  getPreview = () => {
    if (!this.props.fieldValues || !this.props.htmlTemplate) {
      return null;
    }
    let newLetter = this.props.htmlTemplate;
    for (var key in this.props.fieldValues) {
      let re = new RegExp(this.escapeRegExp('|*' + key + '*|'), 'g');
      let f = this.props.fieldValues[key];
      if (!f) {
        f = '';
      }
      if (key !== 'header') {
        f = f.replace(/</g, '&lt;');
        f = f.replace(/>/g, '&gt;');
        f = f.replace(/\n/g, '<br>');
      }
      newLetter = newLetter.replace(re, f);
    }

    var htmlToReactParser = new HtmlToReactParser();
    return htmlToReactParser.parse(newLetter);
  };

  render() {
    return (
      <div style={{ border: '1px dashed black', padding: '8px', backgroundColor: 'rgb(247,247,247)' }}>
        {this.getPreview()}
        <br />
      </div>
    );
  }
}

export default TemplatePreview;
