/**
 * ReportsV2.tsx
 * Reports Parent Component
 * Routes, Components, State Providers, etc.
 */
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VLayout, VLayoutHeader, VLayoutContent } from '../shared/Layout';
import HeaderLogo from 'components/shared/HeaderLogo';
import Timeout from 'components/shared/Timeout';
import AppSelector from 'components/shared/AppSelector';
import Dashboard from 'components/App_/ReportsV2_/Dashboard';
import InlineProviderWrapper from 'components/App_/ReportsV2_/InlineProviderWrapper';
import QueueProviderWrapper from 'components/App_/ReportsV2_/QueueProviderWrapper';
import get from 'lodash/get';

import Adjustments from 'components/App_/ReportsV2_/Views_/Adjustments';
import ChargeCollection from 'components/App_/ReportsV2_/Views_/ChargeCollection';
import ChartAuditLog from 'components/App_/ReportsV2_/Views_/ChartAuditLog';
import CompletedSessions from 'components/App_/ReportsV2_/Views_/CompletedSessions';
import DuplicateSessions from 'components/App_/ReportsV2_/Views_/DuplicateSessions';
import EmployeeSettings from './ReportsV2_/Views_/EmployeeSettings';
import Evals from './ReportsV2_/Views_/Evals';
import HourlyProviderPayroll from './ReportsV2_/Views_/HourlyProviderPayroll';
import Intakes from 'components/App_/ReportsV2_/Views_/Intakes';
import LifestanceArAging from 'components/App_/ReportsV2_/Views_/LifestanceArAging';
import LifestanceArAgingDetail from 'components/App_/ReportsV2_/Views_/LifestanceArAgingDetail';
import LifestanceArCarrierAging from 'components/App_/ReportsV2_/Views_/LifestanceArCarrierAging';
import LifestanceClaimSummary from 'components/App_/ReportsV2_/Views_/LifestanceClaimSummary';
import LifestanceFutureSessions from 'components/App_/ReportsV2_/Views_/LifestanceFutureSessions';
import LifestanceOptumCollections from 'components/App_/ReportsV2_/Views_/LifestanceOptumCollections';
import LifestancePatientTotals from 'components/App_/ReportsV2_/Views_/LifestancePatientTotals';
import LifestanceTxDetailAdjustments from 'components/App_/ReportsV2_/Views_/LifestanceTxDetailAdjustments';
import LifestanceTxDetailCharges from 'components/App_/ReportsV2_/Views_/LifestanceTxDetailCharges';
import LifestanceTxDetailPayments from 'components/App_/ReportsV2_/Views_/LifestanceTxDetailPayments';
import LyraClaims from 'components/App_/ReportsV2_/Views_/LyraClaims';
import PatientAuditLog from 'components/App_/ReportsV2_/Views_/PatientAuditLog';
import PatientCredits from 'components/App_/ReportsV2_/Views_/PatientCredits';
import PatientUnappliedPayments from 'components/App_/ReportsV2_/Views_/PatientUnappliedPayments';
import PatientsOnHoldBalances from 'components/App_/ReportsV2_/Views_/PatientsOnHoldBalances';
import Patients from 'components/App_/ReportsV2_/Views_/Patients';
import Payroll from 'components/App_/ReportsV2_/Views_/Payroll';
import PostedPaymentsCounts from 'components/App_/ReportsV2_/Views_/PostedPaymentsCounts';
import ProviderOpenTimes from 'components/App_/ReportsV2_/Views_/ProviderOpenTimes';
import ScheduleTemplates from 'components/App_/ReportsV2_/Views_/ScheduleTemplates';
import SessionCounts from 'components/App_/ReportsV2_/Views_/SessionCounts';
import UnsignedMissing from 'components/App_/ReportsV2_/Views_/UnsignedMissing';
import AnthemUncredentialedSessions from 'components/App_/ReportsV2_/Views_/AnthemUncredentialedSessions';
import AmdEraMissingItems from 'components/App_/ReportsV2_/Views_/AmdEraMissingItems';
import AmdTransition from 'components/App_/ReportsV2_/Views_/AmdTransition';

const headerStyle = {
  background: '#2b8ed8',
  color: 'white',
  paddingLeft: '0px',
  paddingRight: '0px',
  paddingTop: '5px',
  height: '42px',
  minHeight: '42px',
};

const ReportsV2: React.FC = () => {
  const activeUserName = useSelector((state) => get(state, 'shared.domain.activeUser.full_name'));
  return (
    <Timeout>
      <VLayout>
        <VLayoutHeader style={headerStyle}>
          <HeaderLogo title="PCPA Reports" />
          <AppSelector activeUserName={activeUserName} />
        </VLayoutHeader>
        <VLayoutContent>
          <InlineProviderWrapper>
            <QueueProviderWrapper>
              <Switch>
                <Route exact path="/reports" component={Dashboard} />
                <Route path="/reports/adjustments" component={Adjustments} />
                <Route path="/reports/amd-era-missing-items" component={AmdEraMissingItems} />
                <Route path="/reports/amd-transition" component={AmdTransition} />
                <Route path="/reports/anthem-uncredentialed-sessions" component={AnthemUncredentialedSessions} />
                <Route path="/reports/charge-collection" component={ChargeCollection} />
                <Route path="/reports/chart-audit-log" component={ChartAuditLog} />
                <Route path="/reports/completed-sessions" component={CompletedSessions} />
                <Route path="/reports/duplicate-sessions" component={DuplicateSessions} />
                <Route path="/reports/employee-settings" component={EmployeeSettings} />
                <Route path="/reports/evals" component={Evals} />
                <Route path="/reports/hourly-employees-payroll" component={HourlyProviderPayroll} />
                <Route path="/reports/intakes" component={Intakes} />
                <Route path="/reports/lifestance-ar-aging" component={LifestanceArAging} />
                <Route path="/reports/lifestance-ar-aging-detail" component={LifestanceArAgingDetail} />
                <Route path="/reports/lifestance-ar-carrier-aging" component={LifestanceArCarrierAging} />
                <Route path="/reports/lifestance-claim-summary" component={LifestanceClaimSummary} />
                <Route path="/reports/lifestance-future-sessions" component={LifestanceFutureSessions} />
                <Route path="/reports/lifestance-optum-collections" component={LifestanceOptumCollections} />
                <Route path="/reports/lifestance-patient-totals" component={LifestancePatientTotals} />
                <Route path="/reports/lifestance-tx-detail-adjustments" component={LifestanceTxDetailAdjustments} />
                <Route path="/reports/lifestance-tx-detail-charges" component={LifestanceTxDetailCharges} />
                <Route path="/reports/lifestance-tx-detail-payments" component={LifestanceTxDetailPayments} />
                <Route path="/reports/lyra-claims" component={LyraClaims} />
                <Route path="/reports/patient-credits" component={PatientCredits} />
                <Route path="/reports/patient-unapplied-payments" component={PatientUnappliedPayments} />
                <Route path="/reports/patients-on-hold-balances" component={PatientsOnHoldBalances} />
                <Route path="/reports/patient-audit-log" component={PatientAuditLog} />
                <Route path="/reports/patients" component={Patients} />
                <Route path="/reports/payroll" component={Payroll} />
                <Route path="/reports/posted-payments-counts" component={PostedPaymentsCounts} />
                <Route path="/reports/provider-open-times" component={ProviderOpenTimes} />
                <Route path="/reports/schedule-templates" component={ScheduleTemplates} />
                <Route path="/reports/session-counts" component={SessionCounts} />
                <Route path="/reports/unsigned-missing" component={UnsignedMissing} />
              </Switch>
            </QueueProviderWrapper>
          </InlineProviderWrapper>
        </VLayoutContent>
      </VLayout>
    </Timeout>
  );
};

export default ReportsV2;
