export const rolesToString = (x) => {
  const result = x.map((item) => {
    switch (item) {
      case 'therapist': return 'Therapist';
      case 'prescriber': return 'Prescriber';
      case 'staff': return 'Staff';
      case 'staffsupervisor': return 'Staff Supervisor';
      case 'billing': return 'Billing';
      case 'it': return 'IT';
      case 'superuser': return 'Superuser';
      case 'intake': return 'Intake';
      case 'resident': return 'Resident';
      case 'clinicalsup': return 'Clinical Supervisor';
      default: return '--';
    }
  });
  return result.join(',');
}
