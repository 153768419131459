import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Modal, Button, Select } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import { safe, isAdmin } from '../../../../../helpers/reduxSelectors';
import * as shared_actions from '../../../../../actions/shared';
import * as emr_actions from '../../../../../actions/emr';
import { processEventNotification } from '../../../../../helpers/util';

class CareTeam extends React.Component {
  componentDidMount() {
    this.props.getProvidersList();
  }

  componentDidUpdate(prevProps) {
    processEventNotification(prevProps,this.props,'careteamAdd','Added to careteam');
    processEventNotification(prevProps,this.props,'careteamRemove','Removed from careteam');
  }

  handleOk = () => {
    this.props.closeDialog();
  }

  handleCancel = () => {
    this.props.closeDialog();
  }

  getGroupMember = (x) => {
    if (!x) {
      return null;
    }

    if (x.id === 1) {
      let receptionist = safe(this.props,'patientData.receptionist.full_name');
      if (receptionist) {
        receptionist = 'Reception ('+receptionist+')';
      } else {
        receptionist = 'Reception';
      }
      return receptionist;
    } else if (x.id === 2) {
      let billingist = safe(this.props,'patientData.billingist.full_name');
      if (billingist) {
        billingist = 'Billing ('+billingist+')';
      } else {
        billingist = 'Billing';
      }
      return billingist;
    } else {
      return x.name;
    }
  }

  adminAddMember = () => {
    if (!this.props.isAdmin) {
      return null;
    }
    const ul = this.props.full_provider_list.filter(x=>!this.isProviderOnCareTeam(x.id));
    // get the userList - all on the careteam!

    return (
      <div style={{paddingTop:'20px'}}>
        <Select
          showSearch
          value={undefined}
          style={{width:'80%'}}
          onSelect={(id)=>{this.props.addToCareTeam(this.props.patientId,1,id)}}
          optionFilterProp="children"
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          placeholder='Choose a provider to add to careteam'
        >
          { ul.map(x=><Select.Option key={x.id} value={x.id}>{x.full_name}</Select.Option>)}
        </Select>
      </div>
    );
  }

  contents = () => {

    const active = this.props.data.filter(x=>x.active);
    const history = this.props.data.filter(x=>!x.active);

    return (
      <div style={{height:'350px',overflow:'auto'}}>
        <h3>Current Care Team Members</h3>
        { active.length === 0 ? <div>No active members</div> : '' }
        { active.map(x=><div key={x.id}>
          {x.user && x.user.full_name}
          {x.group && this.getGroupMember(x.group)}
          &nbsp; ({moment(x.treatment_start).format('M/D/YYYY')})
          { this.props.isAdmin && !x.group && <a
            type='link'
            onClick={()=>{this.props.removeFromCareTeam(this.props.patientId,1,x.user.id)}}
            style={{paddingLeft:'5px'}}>
              <DeleteOutlined />
            </a> }
        </div>) }

        { this.adminAddMember() }

        { history.length > 0 && <h3 style={{paddingTop:'20px'}}>Former Care Team Members</h3> }
        { history.map(x=><div key={x.id}>
          {x.user && x.user.full_name}
          {x.group && x.group.name}
          &nbsp; ({moment(x.treatment_start).format('M/D/YYYY')} - {moment(x.treatment_end).format('M/D/YYYY')})
        </div>) }
      </div>
    );
  }

  addToCareTeam = () => {
    if (safe(this.props,'currentInbox.type') === 3) {
      // group
      this.props.addToCareTeam(this.props.patientId,3,safe(this.props,'currentInbox.group_id'));
    } else {
      // provider
      this.props.addToCareTeam(this.props.patientId,1,this.props.activeUserId);
    }

    this.props.closeDialog();
  }

  removeFromCareTeam = () => {
    if (safe(this.props,'currentInbox.type') === 3) {
      // group
      this.props.removeFromCareTeam(this.props.patientId,3,safe(this.props,'currentInbox.group_id'));
    } else {
      // provider
      this.props.removeFromCareTeam(this.props.patientId,1,this.props.activeUserId);
    }

    this.props.closeDialog();
  }

  isProviderOnCareTeam = (id) => {
    return (this.props.careteam.find(x=>{
      if (x.active && x.user && x.user.id === id) {
        return true;
      }
      return false;
    })) ? true : false;
  }

  canBeOnCareTeam = () => {
    return (this.props.currentInbox && (this.props.currentInbox.type === 1 ||
      this.props.currentInbox.type === 3)) ? true : false;
  }

  isOnCareTeam = () => {
    const groupMember = (this.props.currentInbox.type === 3) ? true : false;
    if (groupMember) {
      return this.props.careteam.find(x=>{
        return (x.active && x.group && x.group.id === this.props.currentInbox.group_id) ? true : false;
      });
    } else {
      return this.props.careteam.find(x=>{
        return (x.active && x.user && x.user.id === this.props.activeUserId) ? true : false;
      });
    }
  }

  customFooter = () => {
    const buttons = [];
    if (this.props.isChartOpen && this.canBeOnCareTeam()) {
      if (this.isOnCareTeam()) {
        buttons.push(<Button key={1001} type='primary' onClick={this.removeFromCareTeam}>Remove myself from care team</Button>);
      } else {
        buttons.push(<Button key={1001} type='primary' onClick={this.addToCareTeam}>Add myself to care team</Button>);
      }
    }
    buttons.push(<Button key={1002} type='primary' onClick={this.handleCancel}>Close</Button>)
    return buttons;
  }

  render() {
    const footer = this.customFooter();
    return (
      <Modal
        title={safe(this.props,'patientData.full_name')+ ' Care Team'}
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={footer}
      >
        {this.contents()}
      </Modal>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    careteam: safe(state,'shared.careteam.list.data',[]),
    patientData: safe(state,'shared.patient.load.data',{}),
    activeUserId: safe(state,'shared.domain.activeUser.id'),
    currentInbox: safe(state,'emr.inbox.current'),
    careteamAdd: safe(state,'shared.careteam.add',{}),
    careteamRemove: safe(state,'shared.careteam.remove',{}),
    isChartOpen: safe(state,'shared.patient.load.data.open',true),
    isAdmin: isAdmin(state),
    full_provider_list: safe(state,'emr.providers.list.data',[]),
  }
}

export default connect(mapStateToProps,{...shared_actions,...emr_actions})(CareTeam);
