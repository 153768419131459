import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'qs';

import ChartHeader from './Chart_/ChartHeader';
import ChartNotes from '../Emr_/Patient_/Chart_/ChartNotes';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/emr';


class Chart extends React.Component {

  constructor(props) {
    super(props);
    const qs = (this.props.location.search && this.props.location.search.length > 0) ? this.props.location.search.substring(1) : '';
    const values = queryString.parse(qs);
    this.state = {
      patientId: parseInt(this.props.match.params.patient_id,10),
      endDate:values.e,
      startDate:values.s,
      providers:values.p.split('-'),
      print: false
    }
  }

  componentDidMount() {
    this.props.getPatientChartForPrinting(this.state.patientId,
      this.state.startDate,this.state.endDate,this.state.providers);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data && !this.state.print) {
      this.setState({
        print: true
      });
      setTimeout(()=>{window.print()},3000);
    }
  }

  render() {
    const patientData = safe(this.props.data,'patient',{});
    const noteList = safe(this.props.data,'notes',[]);
    return (
      <div>
        <ChartHeader
          patientId={this.state.patientId}
          patientData={patientData}
        />
        <ChartNotes
          noteList={noteList}
          userId={this.props.userId}
          noteListProcessing={this.props.processing}
          print={true}
        />
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: safe(state,'shared.auth.data.id',0),
    data: safe(state,'emr.chart.printload.data',{}),
    processing: safe(state,'emr.chart.printload.processing',false),
  }
}

export default connect(mapStateToProps,actions)(withRouter(Chart));
