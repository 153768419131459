import React from 'react';
import { DeleteOutlined, DownloadOutlined, ReadOutlined } from '@ant-design/icons';
import { Table, Button, Spin, Tag, Radio } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import { safe } from '../../../helpers/reduxSelectors';
import * as billing_actions from '../../../actions/billing';
import * as shared_actions from '../../../actions/shared';
import UploadFilesDialog from './Clearinghouse_/UploadFilesDialog';
import DisplaySourceDialog from './Clearinghouse_/DisplaySourceDialog';
import DisplayResponseDialog from './Clearinghouse_/DisplayResponseDialog';
import ManualClaimsDialog from './Clearinghouse_/ManualClaimsDialog';
import { processEventNotification } from '../../../helpers/util';

class Clearinghouse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      uploadDialogVisible: false,
      sourceDialogVisible: false,
      responseDialogVisible: false,
      manualClaimsDialogVisible: false,
      sourceId: null,
      responseId: null
    }
    this.pageSize = 50;
  }

  componentDidMount() {
    this.props.getClearinghouseList(this.props.clearinghouselist_type,
      this.props.clearinghouse_page,this.pageSize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clearinghouse_page !== this.props.clearinghouse_page ||
      prevProps.clearinghouselist_type !== this.props.clearinghouselist_type) {
      this.props.getClearinghouseList(this.props.clearinghouselist_type,
        this.props.clearinghouse_page,this.pageSize);
    }

    processEventNotification(prevProps,this.props,'clearinghouseDeleteEvent','Item deleted');
    processEventNotification(prevProps,this.props,'clearinghouseSubmitEvent','Claims file marked submitted');
    processEventNotification(prevProps,this.props,'clearinghouseGenerateEvent','Claims file generated');
    processEventNotification(prevProps,this.props,'clearinghouseRegenerateEvent','Claims file regenerated');
    processEventNotification(prevProps,this.props,'clearinghouseProcessEvent','File processed');
  }

  showUploadDialog = () => {
    this.setState({
      uploadDialogVisible: true
    });
  }

  closeUploadDialog = (reloadList) => {
    this.setState({
      uploadDialogVisible: false
    });
    if (reloadList) {
      this.props.getClearinghouseList(this.props.clearinghouselist_type,
        this.props.clearinghouse_page,this.pageSize);
    }
  }

  showSourceDialog = (id) => {
    this.setState({
      sourceDialogVisible: true,
      sourceId: id
    });
  }

  closeSourceDialog = () => {
    this.setState({
      sourceDialogVisible: false
    });
  }

  showResponseDialog = (id) => {
    this.setState({
      responseDialogVisible: true,
      responseId: id
    });
  }

  closeResponseDialog = () => {
    this.setState({
      responseDialogVisible: false
    });
  }

  showManualClaimsDialog = () => {
    this.setState({
      manualClaimsDialogVisible: true
    });
  }

  closeManualClaimsDialog = () => {
    this.setState({
      manualClaimsDialogVisible: false
    });
  }

  generateClaimsFile = () => {
    this.props.generateClaimsFile(this.props.clearinghouselist_type,
      this.props.clearinghouse_page,this.pageSize);
  }

  tableChange = (x) => {
    this.props.setBillingState({
      clearinghouse_page:x.current
    });
  }

  deleteItem = (id) => {
    this.props.deleteClearinghouseItem(id,this.props.clearinghouselist_type,
      this.props.clearinghouse_page,this.pageSize);
  }

  submitItem = (id) => {
    this.props.submitClearinghouseItem(id,this.props.clearinghouselist_type,
      this.props.clearinghouse_page,this.pageSize);
  }

  regenerateItem = (id) => {
    this.props.regenerateClearinghouseItem(id,this.props.clearinghouselist_type,
      this.props.clearinghouse_page,this.pageSize);
  }

  processItem = (id) => {
    this.props.processClearinghouseItem(id,this.props.clearinghouselist_type,
      this.props.clearinghouse_page,this.pageSize);
  }

  uploadResponseFiles = () => {
    this.showUploadDialog();
  }

  changeType = (e) => {
    this.props.setBillingState({
      clearinghouselist_type: e.target.value,
      clearinghouse_page: 1
    });
  }

  render() {
    const columns = [{
      title: 'PCPA ID',
      dataIndex: 'id',
      width: '100',
      key: 'id',
      render: (value) => {
        return (value+'').padStart(9,'0');
      }
    },{
      title: 'Date Received/Send',
      dataIndex: 'createdAt',
      width: 200,
      key: 'createdAt',
      render: (value) => {
        return moment(value).format('MM/DD/YYYY HH:mm:ss');
      }
    },{
      title: 'Status',
      dataIndex: 'source',
      width: 150,
      key: 'source',
      render: (value,record) => {
        switch (value) {
          case 1: return <Tag color='orange'>GENERATED</Tag>;
          case 2:
            if (record.status === 1) {
              return <span><Tag color='green'>SUBMITTED</Tag> <Tag color='green'>A</Tag></span>;
            } else if (record.status === 2) {
              return <span><Tag color='green'>SUBMITTED</Tag> <Tag color='red'>R</Tag></span>;
            } else if (record.status === 3) {
              return <span><Tag color='green'>SUBMITTED</Tag> <Tag color='yellow'>E</Tag></span>;
            } else {
              return <Tag color='green'>SUBMITTED</Tag>;
            }
          case 3: return <Tag color='blue'>RECEIVED</Tag>;
          case 4: return <Tag color='purple'>RECEIVED AMD</Tag>;
          default:
            return '';
        }
      }
    },{
      title: 'Type',
      dataIndex: 'type',
      width: 100,
      key:'type',
      render: (value,record) => {
        switch (value) {
          case 1: return <a onClick={()=>{this.showSourceDialog(record.id)}}>837P</a>;
          case 2: return <a onClick={()=>{this.showSourceDialog(record.id)}}>TA1</a>;
          case 3: return <a onClick={()=>{this.showSourceDialog(record.id)}}>ACK</a>;
          case 4: return <a onClick={()=>{this.showSourceDialog(record.id)}}>EBR</a>;
          case 5: return <a onClick={()=>{this.showSourceDialog(record.id)}}>DPR</a>;
          case 6: return <a onClick={()=>{this.showSourceDialog(record.id)}}>IBR</a>;
          case 7: return <a onClick={()=>{this.showSourceDialog(record.id)}}>835P</a>;
          default: return '-';
        }
      }
    },{
      title: 'Download',
      width: 60,
      key: 'download',
      align: 'center',
      render: (value,record) => {
        if (record.type === 1 && record.source === 1) {   // 837P File
          return (
            <a href={'/api/v1/cms1500/'+record.id} target='_new'>CMS1500</a>
          );
        } else {
          return (
            <a href={'/api/v1/clearinghouse/edi/'+record.id + '?raw=1'} download={record.filename}>
              <DownloadOutlined style={{fontSize:'22px'}} />
            </a>
          );
        }
      }
    },{
      title: 'View',
      width: 60,
      key: 'view',
      align: 'center',
      render: (value,record) => {
        return (
          <a onClick={()=>{this.showResponseDialog(record.id)}}>
            <ReadOutlined style={{fontSize:'22px'}} />
          </a>
        );
      }
    },{
      title: 'Outgoing',
      dataIndex: 'filename',
      key: 'outgoing',
      width: 200,
      render: (value, record) => {
        if (record.type === 1) {
          return value;
        }
        return '';
      }
    },{
      title: 'Incoming',
      dataIndex: 'filename',
      key: 'incoming',
      render: (value, record) => {
        if (record.type !== 1) {
          return value;
        }
        return '';
      }
    },{
      title: 'Action',
      align: 'right',
      key: 'action',
      render: (value,record) => {
        if (record.source === 1) {
          return (
            <div style={{whiteSpace:'nowrap'}}>
              <Button onClick={()=>this.submitItem(record.id)} style={{marginRight:'10px'}}>Submit</Button>
              <a onClick={()=>this.deleteItem(record.id)}>
                <DeleteOutlined style={{fontSize:'22px'}} />
              </a>
            </div>
          );
        } else if (record.source === 2) {
          return (
            <Button onClick={()=>this.regenerateItem(record.id)}>Regenerate</Button>
          );
        } else {
          if (!record.status) {
            return (
              <div style={{whiteSpace:'nowrap'}}>
                <Button onClick={()=>this.processItem(record.id)} style={{marginRight:'10px'}}>Process</Button>
              </div>
            );
          }
          return null;
        }
      }
    }];

    /*                <a onClick={()=>this.deleteItem(record.id)}>
                      <Icon type='delete' style={{fontSize:'22px'}}/>
                    </a>
                    */


/*
<Button
  type='primary'
  onClick={this.generateClaimsFile}
>
  Auto-Generate Claim File
</Button>
 */
    return (
      <div className='main'>
        <Spin spinning={this.props.isBusy}>
          <h3>Clearinghouse</h3>
          <Button
            type='primary'
            onClick={this.generateClaimsFile}
          >
            Auto-Generate Claim File
          </Button>
          <div style={{paddingBottom:'10px'}}>
            <table style={{width:'100%'}}>
              <tbody>
                <tr>
                  <td style={{textAlign:'left'}}>
                    <Button
                      type='primary'
                      style={{marginLeft:'10px'}}
                      onClick={this.showManualClaimsDialog}
                    >
                      Manually-Generate Claim File (for Paper Claims)
                    </Button>
                  </td>
                  <td style={{textAlign:'right'}}>
                    <Button
                      type='primary'
                      onClick={this.uploadResponseFiles}
                    >
                      Upload Clearinghouse Response Files
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style={{paddingBottom:'10px',textAlign:'center'}}>
            <Radio.Group value={this.props.clearinghouselist_type} onChange={this.changeType} buttonStyle="solid">
              <Radio.Button value={1}>All</Radio.Button>
              <Radio.Button value={2}>All Submissions</Radio.Button>
              <Radio.Button value={3}>Non-Ack Submissions</Radio.Button>
              <Radio.Button value={4}>Payments</Radio.Button>
              <Radio.Button value={5}>Claim Submissions &amp; Acknowledgements</Radio.Button>
            </Radio.Group>
          </div>

          <Table
            columns={columns}
            dataSource={this.props.rows}
            bordered={false}
            size='small'
            onChange={this.tableChange}
            rowKey='id'
            pagination={{
              current: this.props.clearinghouse_page,
              pageSize: this.pageSize,
              total: this.props.count,
              showQuickJumper: true,
            }}
          />
        </Spin>
        <UploadFilesDialog
          visible={this.state.uploadDialogVisible}
          closeDialog={this.closeUploadDialog}
        />
        <DisplaySourceDialog
          visible={this.state.sourceDialogVisible}
          closeDialog={this.closeSourceDialog}
          sourceId={this.state.sourceId}
        />
        <DisplayResponseDialog
          visible={this.state.responseDialogVisible}
          closeDialog={this.closeResponseDialog}
          responseId={this.state.responseId}
        />
        <ManualClaimsDialog
          visible={this.state.manualClaimsDialogVisible}
          handleClose={this.closeManualClaimsDialog}
          clearinghouselist_type={this.props.clearinghouselist_type}
          clearinghouse_page={this.props.clearinghouse_page}
          clearinghouse_page_size={this.pageSize}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.clearinghouse.list.processing',false) ||
      safe(state,'billing.clearinghouse.generate.processing',false) ||
      safe(state,'billing.clearinghouse.delete.processing',false) ||
      safe(state,'billing.clearinghouse.submit.processing',false) ||
      safe(state,'billing.clearinghouse.regenerate.processing',false) ||
      safe(state,'billing.clearinghouse.process.processing',false)
    ) ? true : false,
    rows: safe(state,'billing.clearinghouse.list.data.rows',[]),
    count: safe(state,'billing.clearinghouse.list.data.count',1),
    clearinghouse_page: safe(state,'shared.domain.billing.clearinghouse_page',1),
    clearinghouselist_type: safe(state,'shared.domain.billing.clearinghouselist_type',1),

    clearinghouseGenerateEvent: safe(state,'billing.clearinghouse.generate',{}),
    clearinghouseDeleteEvent: safe(state,'billing.clearinghouse.delete',{}),
    clearinghouseSubmitEvent: safe(state,'billing.clearinghouse.submit',{}),
    clearinghouseRegenerateEvent: safe(state,'billing.clearinghouse.regenerate',{}),
    clearinghouseProcessEvent: safe(state,'billing.clearinghouse.process',{})
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(withRouter(Clearinghouse));
