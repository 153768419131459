import React from 'react';
import {
  CloseOutlined,
  CustomerServiceOutlined,
  DollarOutlined,
  PlusCircleOutlined,
  RightOutlined,
  StarFilled,
} from '@ant-design/icons';
import { List, Avatar, Badge, Rate, Tooltip, Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import InboxSelector from './InboxList_/InboxSelector';
import AdminLabelSelector from './InboxList_/AdminLabelSelector';
import { safe } from '../../../../../helpers/reduxSelectors';
import * as actions from '../../../../../actions/emr';

class InboxList extends React.Component {
  state = {
    g: {}
  }

  setupGroupMembers = () => {
    let g = {};
//    if (false) {
    if (this.props.groups) {
      this.props.groups.forEach(group=>{
        group.members.forEach(x=>{
          g[x.user.id] = {
            initials: x.user.initials,
            color: x.user.color
          }
        });
      });
    }
    this.setState({
      groups: g
    });
  }

  componentDidMount() {
    this.setupGroupMembers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.groups !== this.props.groups) {
      this.setupGroupMembers();
    }
  }

  topBar = () => {
    const style = {
      padding:'5px',
      height:'43px',
      backgroundColor:'#F3F3F3',
      textAlign:'center',
      borderBottom:'1px solid lightgray'
    };

    return (
      <div style={style}>
        <table width='100%'>
          <tbody>
            <tr>
              <td style={{width:'20px'}}>
                <Rate count={1} value={this.props.inboxStar ? 1 : 0} onChange={this.props.setInboxStar} />
              </td>
              <td style={{textAlign:'left'}}>
                <InboxSelector />
                <AdminLabelSelector />
              </td>
              <td style={{textAlign:'right'}}>
                <Button type='link' size='small' onClick={this.props.onNewThread}>
                  <PlusCircleOutlined style={{fontSize:'24px'}} />
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  getIndividualMemberName = (member) => {
    if (member.type === this.props.currentInbox.type &&
      member.user_id === this.props.currentInbox.user_id &&
      member.group_id === this.props.currentInbox.group_id) {
      return 'Me';
    }
    return member.name;
  }

  sfunc = (a,b) => {
    if (a === 'Me') {
      return 1;
    }
    if (b === 'Me') {
      return -1;
    }
    return a.localeCompare(b);
  }

  getNameList = (members) => {
    return (
      <div style={{fontSize:'smaller'}}>
        { members.map(m=>this.getIndividualMemberName(m)).sort(this.sfunc).join(' | ') }
      </div>
    )
  }

  getInitials = (name) => {
    return (name) ? (name.match(/\b\w/g) || []).join('').toUpperCase() : null;
  }

  getImageName = (z) => {
    return '/api/v1/provider/'+z.user_id+'/image';
  }

  getAvatar = (z) => {
    if (z.type === 1) {
      return (<Avatar style={{marginRight:'5px'}} src={this.getImageName(z)} />);
    } else if (z.type === 2) {
      return (<Avatar style={{backgroundColor:'red',marginRight:'5px'}}>{this.getInitials(z.name)}</Avatar>);
    } else if (z.type === 3) {
      if (z.group_id === 1) {
        return <Avatar style={{backgroundColor:'orange',color:'black',marginRight:'5px'}} icon={<CustomerServiceOutlined />}/>;
      } else if (z.group_id === 2) {
        return <Avatar style={{backgroundColor:'green',marginRight:'5px'}} icon={<DollarOutlined />} />;
      } else {
        return (<Avatar />);
      }
    }
    return null;
  }

  getAvatars = (members,receptionist,billingist) => {
    let i = 0;
    return members.map(z=>{
      i++;
      let name = z.name;
      if (z.type === 3) {
        if (z.group_id === 1 && receptionist) {
          name +=  ': ' + receptionist;
        }
        if (z.group_id === 2 && billingist) {
          name +=  ': ' + billingist;
        }
      }
      const q = this.getAvatar(z);
      if (q !== null) {
        return (
          <Tooltip key={i} title={name}>
            {this.getAvatar(z)}
          </Tooltip>
        );
      } else {
        return null;
      }
    });
  }

  listItemDescription = (item) => {
    const starred = item.starred;
    return (
      <div>
        {starred && <div style={{fontSize:'smaller',color:'green'}}>
          <StarFilled style={{color:'rgb(250,219,20)'}} />
          &nbsp; {item.starred_message}
        </div>}
      </div>
    );
  }

  setArchive = (e,id) => {
    e.stopPropagation();
    this.props.hideMessageStatus(id);
  }

  getActions = (lastAddedAt,unread,starred,id) => {
    const d = moment(lastAddedAt);
    const archive = (!unread && !starred) ? <Button onClick={(e)=>{this.setArchive(e,id)}} type='link' icon={<CloseOutlined />} size='small'/> : '';
    if (d.isValid()) {
      return [<div>{archive} {d.format('M/D/YY')} <RightOutlined /></div>];
    } else {
      return [<RightOutlined />];
    }
  }

  generateAvatar = (item) => {
    if (this.props.currentInbox.type === 3) {
      let id = null;
      if (this.props.currentInbox.group_id === 1) {
        id = safe(item,'thread.patient.receptionist_id')
      } else if (this.props.currentInbox.group_id === 2) {
        id = safe(item,'thread.patient.billingist_id')
      }
      if (this.state.groups) {
        const entry = this.state.groups[id];
        const color = (entry) ? entry.color : 'black';
        const initials = (entry) ? entry.initials : 'NONE';
        return (
          <Avatar style={{ backgroundColor:color, verticalAlign: 'middle' }}>
            { initials }
          </Avatar>
        );
      } else {
        return null;
        //return <Avatar shape="square" icon="user" />;
      }
    } else {
      return null;
      //return <Avatar shape="square" icon="user" />;
    }
    /*
    if (safe(item,'thread.patient.receptionist_id') === this.props.activeUserId ||
        safe(item,'thread.patient.billingist_id') === this.props.activeUserId) {
      return (
        <Avatar style={{ backgroundColor:this.props.activeUserColor, verticalAlign: 'middle' }}>
          { this.props.activeUserInitials }
        </Avatar>
      );
    }

    return <Avatar shape="square" icon="user" />
    */
  }

  ordering = (a) => {
    if (a.type === 1) {
      return 2;
    } else if (a.type === 2) {
      return 1;
    } else {
      return 3;
    }
  }

  getListItemTitle = (item) => {
    const name = safe(item,'thread.patient.full_name');
    const members = safe(item,'thread.members',[]).sort((a,b)=>{
      return this.ordering(a)-this.ordering(b);
    });
    const receptionist = safe(item,'thread.patient.receptionist.full_name');
    const billingist = safe(item,'thread.patient.billingist.full_name');

    return (
      <div>
        <span style={{fontWeight:'bold',paddingRight:'5px'}}>{name}</span>
        {this.getAvatars(members,receptionist,billingist)}
      </div>
    );
  }

  listItem = (item) => {
    return (
      <List.Item
        actions={this.getActions(item.lastAddedAt,item.unread,item.starred,item.id)}
        onClick={()=>{this.props.onSelect(item)}}
        className='message-item'
      >
        <List.Item.Meta
          avatar={
            <Badge dot={(item.unread > 0) ? true : false}>
              { this.generateAvatar(item) }
            </Badge>
          }
          title={this.getListItemTitle(item)}
          description={this.listItemDescription(item)}
        />
      </List.Item>
    );
  }

  render() {
    const accounts = safe(this.props,'inbox.data',[]);
    if (!Array.isArray(accounts)) {
      return (
        <div>Mailbox temporarily unavailable. Retrying connection...</div>
      );
    }

    const currentAccount = accounts.find(x=>{
      return (x.member.type === this.props.currentInbox.type &&
        x.member.user_id === this.props.currentInbox.user_id &&
        x.member.group_id === this.props.currentInbox.group_id) ? true : false;
    });
    const threads = safe(currentAccount,'data.threads',[]);

    const fthreads = threads.filter(x=>(this.props.inboxStar) ? x.starred : true);

    let fathreads = fthreads;
    if (this.props.currentInbox.type === 3) {
      let label = null;
      if (this.props.currentInbox.group_id === 1) {
        label = 'receptionist_id';
      } else if (this.props.currentInbox.group_id === 2) {
        label = 'billingist_id';
      }

      fathreads = fthreads.filter(x=>{
        if (this.props.adminLabel === null) {
          return true;
        }
        return (this.props.adminLabel === x.thread.patient[label]) ? true : false;
      });
    }

    const sfthreads = fathreads.sort((a,b)=>{
      if (a.unread > 0 && b.unread > 0) {
        return -moment(a.lastAddedAt).diff(b.lastAddedAt);
      } else if (a.unread > 0 && b.unread === 0) {
        return -1;
      } else if (b.unread > 0 && a.unread === 0) {
        return 1;
      } else {
        return -moment(a.lastAddedAt).diff(b.lastAddedAt);
      }
    });

    return (
      <div>
        {this.topBar()}
        <List
          itemLayout="horizontal"
          className='message-list'
          dataSource={sfthreads}
          style={{paddingLeft: '5px',cursor:'pointer'}}
          renderItem={this.listItem}
        />
      </div>
    )
  }
}

const mapStateToProps = (state,ownProps) => {
  return {
    inbox: safe(state,'emr.inbox.list',{}),
    inboxStar: safe(state,'emr.inbox.star',false),
    currentInbox: safe(state,'emr.inbox.current'),
    activeUserInitials: safe(state,'shared.domain.activeUser.initials'),
    activeUserColor: safe(state,'shared.domain.activeUser.color'),
    activeUserId: safe(state,'shared.domain.activeUser.id'),
    adminLabel: safe(state,'emr.inbox.label',safe(state,'shared.domain.activeUser.id')),
    groups: safe(state,'shared.groups.list.data'),
  }
}

export default connect(mapStateToProps,actions)(withRouter(InboxList));
