import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { connect } from 'react-redux';

import {
  BarsOutlined,
  DollarOutlined,
  EditOutlined,
  ForkOutlined,
  FormOutlined,
  PlusCircleOutlined,
  SwapOutlined,
} from '@ant-design/icons';

import { Dropdown, Menu, notification } from 'antd';

import FormattedCell from '../../../shared/FormattedCell';

//import AdjustmentModal from './Ledger_/AdjustmentModal';
import HistoryModal from './Ledger_/HistoryModal';
import AdjustmentModal from './Ledger_/AdjustmentModal';
import EditLineModal from './Ledger_/EditLineModal';
import TransferModal from './Ledger_/TransferModal';
import NotesModal from './Ledger_/NotesModal';

import * as actions from '../../../../actions/billing';
import { safe } from '../../../../helpers/reduxSelectors';

class Ledger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adjustmentModalVisible: false,
      historyModalVisible: false,
      editLineModalVisible: false,
      transferModalVisible: false,
      notesModalVisible: false,
      selectedRecordId: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.repriceEvent !== this.props.repriceEvent) {
      if (this.props.repriceEvent.success) {
        notification['success']({
          message: 'Success',
          description: 'Service Line Repriced',
        });
      } else if (this.props.repriceEvent.error) {
        notification['error']({
          message: 'Error',
          description: safe(this.props, 'repriceEvent.error.data.message', 'Unable to reprice service line'),
        });
      }
    }

    if (prevProps.queueEvent !== this.props.queueEvent) {
      if (this.props.queueEvent.success) {
        notification['success']({
          message: 'Success',
          description: 'Service line queued/unqueud for claim submission',
        });
      } else if (this.props.queueEvent.error) {
        notification['error']({
          message: 'Error',
          description: safe(
            this.props,
            'queueEvent.error.data.message',
            'Unable to queue service line for claim submission',
          ),
        });
      }
    }
  }

  modalShow = (name, state) => {
    this.setState({
      [name]: state,
    });
  };

  setQueue = (serviceId, ptOrIns, state) => {
    this.props.setQueue(this.props.patientId, serviceId, ptOrIns, state);
  };

  menuClick = ({ key }) => {
    const result = key.split('|');
    const action = parseInt(result[0], 10);
    const record_id = parseInt(result[1], 10);

    this.setState({
      selectedRecordId: record_id,
    });

    switch (parseInt(action, 10)) {
      case 1:
        this.modalShow('historyModalVisible', true);
        break; // View Line
      case 2:
        this.modalShow('editLineModalVisible', true);
        break; // Edit Line
      case 3:
        this.modalShow('adjustmentModalVisible', true);
        break; // Rate Adj
      case 4:
        this.modalShow('transferModalVisible', true);
        break;
      case 5:
        this.setQueue(record_id, 2, 1);
        break; // Queue Ins
      case 7:
        this.setQueue(record_id, 2, 0);
        break; // Unque Ins
      case 8:
        this.props.repriceServiceLine(this.props.patientId, record_id);
        break;
      case 9:
        this.modalShow('notesModalVisible', true);
        break;
      default:
        break;
    }
  };

  createKey = (a, b) => {
    return a + '|' + b;
  };

  //  <Menu.Item key={this.createKey('1',record.id)}><Icon type='bars'/>View Line History</Menu.Item>

  showHistory = (record_id) => {
    this.setState(
      {
        selectedRecordId: record_id,
      },
      () => {
        this.modalShow('historyModalVisible', true);
      },
    );
  };
  //         <Menu.Item key={this.createKey('9',record.id)}><Icon type='meh'/>Fix Orphaned Payments</Menu.Item>

  actionMenu = (record) => {
    const menu = (
      <Menu onClick={this.menuClick}>
        <Menu.Item key={this.createKey('2', record.id)}>
          <EditOutlined />
          Edit Line Details
        </Menu.Item>
        <Menu.Item key={this.createKey('3', record.id)}>
          <ForkOutlined />
          Rate Adjustment
        </Menu.Item>
        <Menu.Item key={this.createKey('4', record.id)}>
          <SwapOutlined />
          Rate Transfer
        </Menu.Item>
        <Menu.Item key={this.createKey('8', record.id)}>
          <DollarOutlined />
          Reprice
        </Menu.Item>
        <Menu.Item key={this.createKey('9', record.id)}>
          <FormOutlined />
          Add a Note
        </Menu.Item>
        {(record.ins_queue || (!record.ins_queue && record.ins_outstanding !== 0)) && <Menu.Divider />}
        {!record.ins_queue && record.ins_outstanding !== 0 && (
          <Menu.Item key={this.createKey('5', record.id)}>
            <PlusCircleOutlined />
            Queue Insurance Claim
          </Menu.Item>
        )}
        {record.ins_queue && (
          <Menu.Item key={this.createKey('7', record.id)}>
            <PlusCircleOutlined />
            Unqueue Insurance Claim
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <a className="ant-dropdown-link">actions...</a>
      </Dropdown>
    );
  };

  getCarrierName = (dos) => {
    const d = moment(dos);
    for (const x of this.props.policies) {
      if (d.isAfter(x.start_date) && d.isBefore(x.end_date)) {
        return x['carriers.abbr'];
      }
    }
    return '-';
  };

  fn = (x) => {
    if (parseFloat(x) === 0) {
      return '';
    }
    return numeral(x).format('$0,0.00');
  };

  floatRound = (x) => {
    return Math.round((x + 0.00001) * 100) / 100;
  };

  getProviderNames = (record) => {
    const rp = safe(record.rendering_provider, 'full_name', '');
    const sp = safe(record.servicing_provider, 'full_name', '');
    if (rp !== sp) {
      return (
        <span>
          R-{rp}
          <br />
          S-{sp}
        </span>
      );
    }
    return rp;
  };

  render() {
    const unapplied = safe(this.props, 'pt_totals.pt_total_collected', 0) - safe(this.props, 'pt_totals.pt_paid', 0);

    return (
      <div>
        <div>
          <table style={{ marginLeft: '5px', marginRight: '5px' }} className="ledger-table" cellPadding="5">
            <thead style={{ borderBottom: '1px solid lightgray' }}>
              <tr>
                <th colSpan={6}></th>
                <th colSpan={5} style={{ backgroundColor: '#F5EFEF', textAlign: 'center' }}>
                  Insurance
                </th>
                <th colSpan={5} style={{ textAlign: 'center' }}>
                  Patient
                </th>
              </tr>
              <tr>
                <th style={{ width: '30px', textAlign: 'left' }}></th>
                <th style={{ width: '115px', textAlign: 'left' }}>DOS</th>
                <th style={{ width: '100px', textAlign: 'left' }}>Type</th>
                <th style={{ width: '115px', textAlign: 'left' }}>CPT</th>
                <th style={{ minWidth: '150px', textAlign: 'left' }}>Provider</th>
                <th style={{ width: '80px', textAlign: 'left' }}>Carrier</th>
                <th style={{ width: '100px', backgroundColor: '#F5EFEF', textAlign: 'right' }}>Rate</th>
                <th style={{ width: '100px', backgroundColor: '#F5EFEF', textAlign: 'right' }}>Adj</th>
                <th style={{ width: '100px', backgroundColor: '#F5EFEF', textAlign: 'right' }}>Paid</th>
                <th style={{ width: '100px', backgroundColor: '#F5EFEF', textAlign: 'right' }}>Bal</th>
                <th style={{ width: '20px', backgroundColor: '#F5EFEF', textAlign: 'right' }}></th>
                <th style={{ width: '100px', textAlign: 'right' }}>Rate</th>
                <th style={{ width: '100px', textAlign: 'right' }}>Adj</th>
                <th style={{ width: '100px', textAlign: 'right' }}>Paid</th>
                <th style={{ width: '100px', textAlign: 'right' }}>Bal</th>
                <th style={{ width: '100px', textAlign: 'right' }}>Unapp</th>
              </tr>
              <tr style={{ borderBottom: '1px solid black' }}>
                <th colSpan={6} style={{ textAlign: 'right' }}></th>
                <th style={{ width: '100px', backgroundColor: '#F5EFEF', textAlign: 'right' }}>
                  {numeral(safe(this.props, 'pt_totals.ins_rate', 0)).format('$0,0.00')}
                </th>
                <th style={{ width: '100px', backgroundColor: '#F5EFEF', textAlign: 'right' }}>
                  {numeral(safe(this.props, 'pt_totals.ins_adj', 0)).format('$0,0.00')}
                </th>
                <th style={{ width: '100px', backgroundColor: '#F5EFEF', textAlign: 'right' }}>
                  {numeral(safe(this.props, 'pt_totals.ins_paid', 0)).format('$0,0.00')}
                </th>
                <th style={{ width: '100px', backgroundColor: '#F5EFEF', textAlign: 'right' }}>
                  <FormattedCell
                    value={this.floatRound(safe(this.props, 'pt_totals.ins_outstanding', 0))}
                    blankonzero={false}
                  />
                </th>
                <th style={{ width: '20px', backgroundColor: '#F5EFEF' }}></th>
                <th style={{ width: '100px', textAlign: 'right' }}>
                  {numeral(safe(this.props, 'pt_totals.pt_rate', 0)).format('$0,0.00')}
                </th>
                <th style={{ width: '100px', textAlign: 'right' }}>
                  {numeral(safe(this.props, 'pt_totals.pt_adj', 0)).format('$0,0.00')}
                </th>
                <th style={{ width: '100px', textAlign: 'right' }}>
                  {numeral(safe(this.props, 'pt_totals.pt_paid', 0)).format('$0,0.00')}
                </th>
                <th style={{ width: '100px', textAlign: 'right' }}>
                  <FormattedCell value={safe(this.props, 'pt_totals.pt_outstanding', 0)} blankonzero={false} />
                </th>
                <th style={{ width: '100px', textAlign: 'right' }}>
                  <FormattedCell reversed={false} blankonzero={false} value={unapplied} />
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.data.map((record) => (
                <tr key={record.id} style={{ borderBottom: '1px solid lightgray', lineHeight: '24px' }}>
                  <td>
                    <a onClick={() => this.showHistory(record.id)}>
                      <BarsOutlined />
                    </a>
                  </td>
                  <td style={{ textAlign: 'right' }}>{moment(record.dos).format('M/DD/YYYY')}</td>
                  <td>{record.product_id !== 1 ? safe(record, 'product.name') : ''}</td>
                  <td style={{ maxWidth: '115px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {record.cpt_codes && record.product_id === 1
                      ? record.cpt_codes.map((x) => <span key={x}>{x} </span>)
                      : ''}
                  </td>
                  <td style={{ minWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {this.getProviderNames(record)}
                  </td>
                  <td>
                    {
                      record.product_id === 1 && safe(record, 'carriers.abbr')
                      //this.getCarrierName(record.dos)
                    }
                  </td>
                  <td className="highlighted-col">{this.fn(record.ins_rate)}</td>
                  <td className="highlighted-col">{this.fn(record.ins_adj)}</td>
                  <td className="highlighted-col">{this.fn(record.ins_paid)}</td>
                  <td className="highlighted-col">
                    {<FormattedCell value={this.floatRound(record.ins_outstanding)} blankonzero={true} />}
                  </td>
                  <td className="highlighted-col">{record.ins_queue ? '*' : ' '}</td>
                  <td style={{ textAlign: 'right' }}>{this.fn(record.pt_rate)}</td>
                  <td style={{ textAlign: 'right' }}>{this.fn(record.pt_adj)}</td>
                  <td style={{ textAlign: 'right' }}>{this.fn(record.pt_paid)}</td>
                  <td style={{ textAlign: 'right' }}>
                    {<FormattedCell value={this.floatRound(record.pt_outstanding)} blankonzero={true} />}
                  </td>
                  <td style={{ textAlign: 'right' }}>{this.actionMenu(record)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <AdjustmentModal
            visible={this.state.adjustmentModalVisible}
            serviceId={this.state.selectedRecordId}
            patientId={this.props.patientId}
            closeDialog={() => {
              this.modalShow('adjustmentModalVisible', false);
            }}
          />
          <HistoryModal
            visible={this.state.historyModalVisible}
            patientId={this.props.patientId}
            serviceId={this.state.selectedRecordId}
            closeDialog={() => {
              this.modalShow('historyModalVisible', false);
            }}
          />
          <EditLineModal
            visible={this.state.editLineModalVisible}
            patientId={this.props.patientId}
            serviceId={this.state.selectedRecordId}
            closeDialog={() => {
              this.modalShow('editLineModalVisible', false);
            }}
          />
          <TransferModal
            visible={this.state.transferModalVisible}
            serviceId={this.state.selectedRecordId}
            patientId={this.props.patientId}
            closeDialog={() => {
              this.modalShow('transferModalVisible', false);
            }}
          />
          <NotesModal
            visible={this.state.notesModalVisible}
            serviceId={this.state.selectedRecordId}
            patientId={this.props.patientId}
            closeDialog={() => {
              this.modalShow('notesModalVisible', false);
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    repriceEvent: safe(state, 'billing.serviceline.reprice', {}),
    queueEvent: safe(state, 'billing.queue', {}),
  };
};

export default connect(mapStateToProps, actions)(Ledger);
