import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { safe } from '../../../../../helpers/reduxSelectors';

const fields = {
  bio: { title: 'Bio', type: 'longstring' },
  bio2: { title: 'Bio-2', type: 'longstring' },
  quote: { title: 'Quote', type: 'longstring' },
  title: { title: 'Title', type: 'string' },
  image_url: { title: 'Image', type: 'longstring' },
  languages: { title: 'Languages', type: 'string' },
  telemedicine_url: { title: 'VSee URL', type: 'longstring' },
  telemedicine_room: { title: 'VSee Room', type: 'string' },
};

class HistoryBio extends React.Component {
  static propTypes = {
    data: PropTypes.object,
  };

  renderValue = (x, type) => {
    switch (type) {
      case 'boolean':
        return x ? 'Yes' : 'No';
      case 'date':
        return x ? moment(x).format('MM/DD/YYYY') : '';
      default:
        return x;
    }
  };

  renderValues = () => {
    return Object.keys(fields).map((field) => {
      const { title, type } = fields[field];
      const value = safe(this.props.data, field);
      if (value) {
        return (
          <div key={field}>
            {title}: {this.renderValue(value, type)}
          </div>
        );
      }
      return false;
    });
  };

  renderUpdate = () => {
    const changed_fields = Object.keys(fields).filter((field) => {
      return safe(this.props.data, field + '.before') !== safe(this.props.data, field + '.after');
    });
    return changed_fields.map((field) => {
      const { title, type } = fields[field];
      const before = this.renderValue(safe(this.props.data, field + '.before'), type);
      const after = this.renderValue(safe(this.props.data, field + '.after'), type);
      return (
        <div key={field}>
          {title}: {before} {'->'} {after}
        </div>
      );
    });
  };

  render() {
    if (!this.props.data) {
      return null;
    } else if (safe(this.props.data, 'id.before')) {
      return <div>{this.renderUpdate()}</div>;
    } else {
      return <div>{this.renderValues()}</div>;
    }
  }
}

export default HistoryBio;
