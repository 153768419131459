import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, DatePicker, Input } from 'antd';
import moment from 'moment';

import * as billing_actions from '../../../../actions/billing';
import * as shared_actions from '../../../../actions/shared';
import { safe } from '../../../../helpers/reduxSelectors';

class RecallEntryModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func
  }

  static defaultProps = {
    visible: false
  }

  onOk = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      // Need to insert patient_id in this!
      if (!err) {
        const claims = safe(this.props,'ediData.claims',[]);
        const claimIds = claims.map(x=>x.control_number);

        if (this.props.claim) {
          this.props.saveEraRecallItem(this.props.era_id,{
            claim_ids: claimIds,
            ...values,
            patient_control_number: safe(this.props,'claim.control_number'),
            billing_patient_id: safe(this.props,'claim.reconciled.billing_patient_id')
          },0);
        } else {
          console.log('no claim')
        }
        /*
        if (this.props.initialValues) {
          this.props.saveRecallItem(
            this.props.recall_list_selection,
            this.props.recall_status_selection,
            this.props.initialValues.id,
            values,
            this.props.recalllist_page,
            15
          );
        } else {
          this.props.createRecallItem(
            this.props.recall_list_selection,
            this.props.recall_status_selection,
            values,
            this.props.recalllist_page,
            15
          );
        }
        */
        this.props.onClose();
      }
    });
  }

  onChangePatientValue = (value) => {
    this.props.getPatientList(value);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible === true) {
      this.props.form.resetFields();
      this.props.getRecallLists();
    }
  }

  getPatientName = () => {
    const fn = safe(this.props,'claim.patient.firstname');
    const ln = safe(this.props,'claim.patient.lastname');
    return (fn && ln) ? fn + ' ' + ln : null;
  }

  getInitialTitle = () => {
    const dos = safe(this.props,'claim.dos');
    const claim_num = safe(this.props,'claim.payer_control_number');
    const provider_fn = safe(this.props,'claim.provider.firstname');
    const provider_ln = safe(this.props,'claim.provider.lastname');
    const provider = (provider_fn && provider_ln) ? provider_fn + ' ' + provider_ln : null;

    const items = [];
    if (dos) {
      items.push('DOS: '+dos);
    }
    if (provider) {
      items.push('Provider: '+provider);
    }
    if (claim_num) {
      items.push('Claim #: '+claim_num);
    }

    return (items.length > 0) ? items.join(', ') : '';
  }

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };

    const initialValues = safe(this.props,'initialValues',{});

    return (
      <Modal
        title='Recall List Item'
        visible={this.props.visible}
        onCancel={this.props.onClose}
        onOk={this.onOk}
        okText='Save'
      >
        <Form className='recall-entry-modal' autoComplete='off'>
          <FormItem {...formItemLayout} label="Title">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: " ", whitespace: true }],
              initialValue: this.getInitialTitle()
            })(
              <Input.TextArea rows={3} />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="List">
            {getFieldDecorator('list_id', {
              rules: [{ required: true, message: " " }],
              initialValue: initialValues.list_id
            })(
              <Select>
                { this.props.listData.map(z=><Option key={z.id} value={z.id}>{z.name}</Option>) }
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Recall Date">
            {getFieldDecorator('recall_date', {
              rules: [{ required: true, message: " " }],
              initialValue: (initialValues.recall_date) ? moment(initialValues.recall_date) : moment().add(7, 'days')
            })(
              <DatePicker format='M/DD/YYYY' />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Patient">
            {getFieldDecorator('patient_name', {
              initialValue: this.getPatientName()
            })(
              <Input readOnly={true}/>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Status">
            {getFieldDecorator('status', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(initialValues,'status',1)
            })(
              <Select>
                <Option value={1}>Open</Option>
                <Option value={2}>Closed</Option>
              </Select>
            )}
          </FormItem>

        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    listData: safe(state,'billing.recall.lists_list.data',[]),
    patientList: safe(state,'shared.patient.list.data',[]),
    ediData: safe(state,'billing.era.reconciled.data',{}),
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(Form.create()(RecallEntryModal));
