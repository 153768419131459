import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Spin } from 'antd';
import numeral from 'numeral';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';

class ReportsPtUnallocated extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pageSize: 100,
      currentPage: 1
    }
  }

  componentDidMount() {
    this.props.reportsGetPtUnallocated(this.state.currentPage,this.state.pageSize);
  }

  tableChange = (x) => {
    this.setState({
      currentPage:x.current
    },() => {
      this.props.reportsGetPtUnallocated(this.state.currentPage,this.state.pageSize)
    });
  }

  render() {
    const columns = [{
      title: 'Patient ID',
      dataIndex: 'id',
      render: (val,record) => numeral(safe(record,'patients.id',0)).format('000000')
    },{
      title: 'Patient',
      dataIndex: 'full_name',
      render: (val,record) => safe(record,'patients.full_name')
    },{
      title: 'Patient Unapplied',
      dataIndex: 'pt_unapplied',
      render: (val) => numeral(val).format('$0.00')
    }];

    const data = safe(this.props.list,'rows',[]);
    const count = safe(this.props.list,'count',0);

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
            <span style={{float:'right'}}><a href="/api/v1/reports/ptunallocated?csv=1">CSV Download</a></span>
          </div>

          <h3>Patient Unapplied Payments</h3>
          <Table
            className='reports-sessionlist-table'
            columns={columns}
            dataSource={data}
            bordered={true}
            size='small'
            rowKey='id'
            scroll={{ x:true }}
            onChange={this.tableChange}
            pagination={{
              current: this.state.currentPage,
              pageSize: this.state.pageSize,
              total: count
            }}
          />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    list: safe(state,'reports.ptunallocated.data',{}),
    isBusy: safe(state,'reports.ptunallocated.processing',false)
  }
}

export default connect(mapStateToProps,actions)(ReportsPtUnallocated);
