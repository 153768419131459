import React from 'react';
import { Row, Col } from 'antd';
import numeral from 'numeral';

import { safe } from '../../../../helpers/reduxSelectors';

class DPR extends React.Component {

  formatDate = (d) => {
    const year = d.substring(0,4);
    const month = d.substring(4,6);
    const day = d.substring(6,8);
    return month + '/' + day + '/' + year;
  }

  showPatient = (data) => {
    const style = (data.type === 'R') ?  { color:'red',paddingBottom:'10px' } : {paddingBottom:'10px'};

    // We're not displaying: patient_account_num which is the patient control #

    return (
      <div style={style}>
        <Row>
          <Col span={5} style={{overflow:'hidden'}}>
            <div>{data.patient_name}</div>
          </Col>
          <Col span={3}>
            <div>{this.formatDate(data.from_date)}</div>
          </Col>
          <Col span={3}>
            <div>{this.formatDate(data.process_date)}</div>
          </Col>
          <Col span={3}>
            <div>{data.payer_claim_id}</div>
          </Col>
          <Col span={2}>
            <div>{data.type}</div>
          </Col>
          <Col span={5}>
            <div>{data.payer_name}</div>
          </Col>
          <Col span={3}>
            <div>{numeral(data.total_charges).format('$0,0.00')}</div>
          </Col>
        </Row>

        { data.type !== 'A' && data.message_text.length > 0 &&
          <Row>
            <Col span={24}>
              <div style={{...style,paddingLeft:'20px',paddingRight:'20px'}}>
                { data.message_text}
              </div>
            </Col>
          </Row>
        }

      </div>
    );
  }

  header = (data) => {
    return (
      <div>
        <Row>
          <Col span={6}>
            <div><strong>Date/Time Received: </strong> </div>
            <div><strong>Submission PCPA ID: </strong></div>
            <div><strong>Submission Filename: </strong></div>
          </Col>
          <Col span={6}>
            <div>{data.date_time_received}</div>
            <div>{data.file_control_num}</div>
            <div>{data.original_filename}</div>
          </Col>
          <Col span={6}>
            <div><strong>Availity Customer ID: </strong></div>
            <div><strong>Availity File ID: </strong></div>
          </Col>
          <Col span={6}>
            <div>{data.availity_customer_id}</div>
            <div>{data.availity_file_id}</div>
          </Col>
        </Row>
        <br />
        {data.patients && data.patients.length > 0 && <Row style={{borderBottom:'1px solid lightgray'}}>
          <Col span={5}><strong>Patient Name</strong></Col>
          <Col span={3}><strong>Date of Service</strong></Col>
          <Col span={3}><strong>Process Date</strong></Col>
          <Col span={3}><strong>Payer Claim #</strong></Col>
          <Col span={2}><strong>Status</strong></Col>
          <Col span={5}><strong>Payer</strong></Col>
          <Col span={3}><strong>Amount</strong></Col>
        </Row> }

        { data.patients.map(patient=>this.showPatient(patient)) }
      </div>
    );
  }

  render() {
    const content = safe(this.props,'data.content',[]);

    return (
      <div>
        { content.map(x=>this.header(x)) }
      </div>
    )
  }

}

export default DPR;
