/**
 * YearMonthSelector.tsx
 * This component is a selector for a year/month which is rarely used but
 * provides additional parameters to any report that uses the isYearMonth flag.
 * Due to re-rendering of the parent components, some special logic inside
 * useEffect() was required to ensure the user selection was remembered.
 */
import { useEffect, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Form, Select } from 'antd';
import { DateTime, Settings } from 'luxon';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import formItemRules from 'components/App_/ReportsV2_/formItemRules';

Settings.defaultZoneName = 'America/Los_Angeles';

type OptionsListT = Array<Record<string, number>>;

interface PropsI {
  callback: () => void;
  start: number;
  end?: number;
  monthText?: string;
}

const generateYearMonthList = (start: number, end?: number) => {
  let curMonth = DateTime.now().month;
  let curYear = DateTime.now().year;
  const list: OptionsListT = [];
  while (curYear * 100 + curMonth >= start && (end ? curMonth < end : true)) {
    list.push({ year: curYear, month: curMonth });
    if (--curMonth < 1) {
      curMonth = 12;
      curYear--;
    }
  }
  return list;
};

const YearMonthSelector: React.FC<PropsI> = (props: PropsI) => {
  const { callback, start, end, monthText } = props;
  const form = useContextSelector(InlineContext, (c) => c.state.form);
  const [selection, setSelection] = useState<number | undefined>();

  useEffect(() => {
    const curYearMonth = form?.getFieldValue('yearMonth');
    const newList = generateYearMonthList(start, end);
    if (!curYearMonth) {
      const firstItem = newList[0];
      const { year, month } = firstItem;
      const yearMonth = `${year}-${month}`;
      form?.setFieldsValue({ yearMonth });
      setSelection(newList && newList.length > 0 ? 0 : undefined);
    } else {
      const [year, month] = curYearMonth.split('-');
      const foundSelection = newList.findIndex((x) => {
        return x.year === parseInt(year, 10) && x.month === parseInt(month, 10);
      });
      if (foundSelection > -1) {
        setSelection(foundSelection);
      }
    }
  }, []);

  const onSelectChange = (val) => {
    const list = generateYearMonthList(start, end);
    const year = list[val].year;
    const month = list[val].month;
    const yearMonth = `${year}-${month}`;
    form?.setFieldsValue({ yearMonth });
    setSelection(val);
    callback();
  };

  return (
    <Form.Item name="yearMonth" rules={formItemRules.yearMonth}>
      <span>{monthText?.length ? monthText : 'Month:'} </span>
      <Select onChange={onSelectChange} value={selection} style={{ width: '250px' }}>
        {generateYearMonthList(props.start, props.end).map((x, i) => (
          <Select.Option key={i} value={i}>
            {x.year}-{x.month}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default YearMonthSelector;
