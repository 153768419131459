import * as types from '../../actions/billing/types';

const defaultState = {
  create: {},
  list: {},
  load: {}
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case types.BILLING_STATEMENTS_CREATE_REQUEST:
      return {
        ...state,
        create: {
          processing: true,
        }
      };
    case types.BILLING_STATEMENTS_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.BILLING_STATEMENTS_CREATE_FAILURE:
      return {
        ...state,
        create: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.BILLING_STATEMENTS_CREATE_CLEAR:
      return {
        ...state,
        create: {}
      };

    case types.BILLING_STATEMENTS_LIST_REQUEST:
      return {
        ...state,
        list: {
          processing: true,
        }
      };
    case types.BILLING_STATEMENTS_LIST_SUCCESS:
      return {
        ...state,
        list: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.BILLING_STATEMENTS_LIST_FAILURE:
      return {
        ...state,
        list: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.BILLING_STATEMENTS_LIST_CLEAR:
      return {
        ...state,
        list: {}
      };

    case types.BILLING_STATEMENTS_LOAD_REQUEST:
      return {
        ...state,
        load: {
          processing: true,
        }
      };
    case types.BILLING_STATEMENTS_LOAD_SUCCESS:
      return {
        ...state,
        load: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.BILLING_STATEMENTS_LOAD_FAILURE:
      return {
        ...state,
        load: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.BILLING_STATEMENTS_LOAD_CLEAR:
      return {
        ...state,
        load: {}
      };

    default:
      return state;
  }
}
