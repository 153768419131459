/**
 * CSVDownload.tsx
 * A component that ties into form validation for generating
 * a CSV url to then generate a CSV report for download.
 */
import { useContextSelector } from 'use-context-selector';
import { Button, Form } from 'antd';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import styles from './CSVDownload.style';

interface PropsI {
  prefix?: string;
}

const CSVDownload: React.FC<PropsI> = (props: PropsI) => {
  const { prefix } = props;
  const form = useContextSelector(InlineContext, (c) => c.state.form);

  const buildCsvLink = () => {
    const getValues = async () => {
      let baseUrl = `/api/v2/reports/csv`;
      try {
        const values = await form?.validateFields();
        const { reportId, dateType, startDate, endDate, yearMonth, payPeriod } = values;
        const sd = startDate?.format('YYYY-MM-DD');
        const ed = endDate?.format('YYYY-MM-DD');
        baseUrl = `${baseUrl}/${prefix}/${reportId}/${dateType}/${sd}/${ed}/${yearMonth}/${payPeriod}`;
        window.open(baseUrl, '_blank');
      } catch (e) {
        console.log('Failed:', e);
      }
    };
    getValues();
  };

  return (
    <Form.Item style={styles.root}>
      <Button type="link" onClick={() => buildCsvLink()} style={styles.btn}>
        CSV Download
      </Button>
    </Form.Item>
  );
};

export default CSVDownload;
