/**
 * PatientAuditLog.tsx
 */
import reports from 'reports';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import InlineFormWrapper from 'components/App_/ReportsV2_/InlineFormWrapper';
import ReportWrapper from 'components/App_/ReportsV2_/ReportWrapper';
import Table from 'components/App_/ReportsV2_/Table';
import columns, { types } from 'components/App_/ReportsV2_/TableColumns_/patientAuditLog';
import { PaginatedDataT } from 'reports';
import PatientSelector from 'components/App_/ReportsV2_/InlineControls_/PatientSelector';
import DisplayReport from 'components/App_/ReportsV2_/InlineControls_/DisplayReport';
import useAutoRun from 'components/App_/ReportsV2_/useAutoRun';
import DateRangeSelector from 'components/App_/ReportsV2_/InlineControls_/DateRangeSelector';
import { Form, Select } from 'antd';
import moment from 'moment';
import styles from './styles';
import { useEffect } from 'react';

const { Option } = Select;

const reportId = 33;
const page = 1;
const pageSize = 50;

const PatientAuditLog: React.FC = () => {
  const inlineData = useContextSelector(InlineContext, (c) => c.state.inlineData) as PaginatedDataT;
  const report = reports.find((r) => r.id === reportId);

  useEffect(() => {
    form?.setFieldsValue({ startDate: moment().subtract({ day: 30 }) });
    form?.setFieldsValue({ endDate: moment().add({ day: 1 }) });
  }, []);

  useAutoRun(reportId);

  const formOptions = {
    page,
    pageSize,
    reportId,
  };

  const form = useContextSelector(InlineContext, (c) => c.state.form);

  const onChange = (value) => {
    form?.setFieldsValue({ type: value });
  };

  const tableOptions = {
    data: inlineData?.rows,
    columns: [...columns],
    page,
    pageSize,
    reportId,
    rowKey: 'id',
    scrollX: true,
    total: inlineData?.count,
  };
  return (
    <ReportWrapper>
      <h3>{report?.name}</h3>
      <div style={styles.root}>
        <InlineFormWrapper {...formOptions}>
          <DateRangeSelector />
          <Form.Item name="type">
            <Select allowClear style={{ width: 200 }} placeholder="Select a type" onChange={onChange}>
              {types.map((type, key) => (
                <Option key={key + 1} value={key + 1}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <PatientSelector />
          <DisplayReport />
        </InlineFormWrapper>
      </div>
      <Table {...tableOptions} />
    </ReportWrapper>
  );
};

export default PatientAuditLog;
