import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin } from 'antd';

import Emr from './App_/Emr';
import Billing from './App_/Billing';
import Reports from './App_/Reports';
import ReportsV2 from './App_/ReportsV2';
import HR from './App_/HR';
import Display from './App_/Display';
import Settings from './App_/Settings';
import Intakes from './App_/Intakes';
import Scheduler from './App_/Scheduler';
import Login from './App_/Login';
import Logout from './App_/Logout';
import {
  isAdmin,
  isStaffSupervisor,
  isIntake,
  isSuperUser,
  isHR,
  loginInProcess,
  loginSuccess,
  loginError,
} from '../helpers/reduxSelectors';

import * as actions from '../actions/shared';

class App extends Component {
  componentDidMount() {
    this.props.loginUser();
  }

  render() {
    if (this.props.userLoggedIn) {
      return (
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/emr" component={Emr} />
          {this.props.isAdmin && <Route path="/billing" component={Billing} />}
          {(this.props.isStaffSupervisor || this.props.isSuperUser) && <Route path="/reports-v1" component={Reports} />}
          {(this.props.isStaffSupervisor || this.props.isSuperUser) && (
            <Route path="/reports" component={ReportsV2} activeUserName={this.props.activeUserName} />
          )}
          {(this.props.isHr || this.props.isSuperUser) && (
            <Route path="/hr" component={HR} activeUserName={this.props.activeUserName} />
          )}
          {(this.props.isStaffSupervisor || this.props.isSuperUser) && (
            <Route path="/settings" component={Settings} />
          )}
          <Route path="/display" component={Display} />
          {(this.props.isIntake || this.props.isAdmin) && <Route path="/intakes" component={Intakes} />}
          {(this.props.isStaffSupervisor || this.props.isSuperUser) && (
            <Route path="/scheduler" component={Scheduler} />
          )}
          <Redirect to="/login" />
        </Switch>
      );
    } else if (this.props.loginInProcess) {
      const style = {
        top: 0,
        left: 0,
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        textAlign: 'center',
        padding: '45vh',
      };
      return (
        <div style={style}>
          <Spin spinning={true} size="large" tip="Logging In" />
        </div>
      );
    } else {
      return (
        <Switch>
          <Route component={Login} />
        </Switch>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loginInProcess: loginInProcess(state),
    userLoggedIn: loginSuccess(state),
    loginError: loginError(state),

    isAdmin: isAdmin(state),
    isIntake: isIntake(state),
    isStaffSupervisor: isStaffSupervisor(state),
    isSuperUser: isSuperUser(state),
  };
};

export default connect(mapStateToProps, actions)(withRouter(App));
