import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  AlertOutlined,
  BarsOutlined,
  CarOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  ExperimentOutlined,
  FileTextOutlined,
  HourglassOutlined,
  InboxOutlined,
  LockOutlined,
  MailOutlined,
  PictureOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  PrinterOutlined,
  QuestionCircleFilled,
  UnlockOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { Button, Menu, Dropdown, Badge, Tag, Modal, notification } from 'antd';

import CreatePatientChart from './EmrHeader_/CreatePatientChart';
import Messenger from './EmrHeader_/Messenger';
import VacationCoverage from './EmrHeader_/VacationCoverage';
import VacationCoverageAdmin from './EmrHeader_/VacationCoverageAdmin';
import HeaderLogo from '../../shared/HeaderLogo';
import AppSelector from '../../shared/AppSelector';
import PatientSelector from '../../shared/PatientSelector';
import PortalRegistration from '../../shared/PortalRegistration';
import DeleteLateCancelDialog from './EmrHeader_/DeleteLateCancelDialog';
import Timeoff from './EmrHeader_/Timeoff';
import DirectorList from './DirectorList';

import {
  safe,
  isAdmin,
  isStaffSupervisor,
  isClinician,
  isPrescriber,
  isTherapist,
} from '../../../helpers/reduxSelectors';
import * as emr_actions from '../../../actions/emr';
import * as shared_actions from '../../../actions/shared';

class EmrHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPatientModalVisible: false,
      emailDrawerVisible: false,
      vacationModalVisible: false,
      vacationAdminModalVisible: false,
      portalRegistrationDialogVisible: false,
      deleteLateCancelDialogVisible: false,
      timeoffModalVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deleteCoverageEvent !== this.props.deleteCoverageEvent && this.props.deleteCoverageEvent.success) {
      this.props.loginUser();
    }

    if (prevProps.toggleRegistration !== this.props.toggleRegistration && this.props.toggleRegistration.success) {
      notification.success({
        message: 'Registration Updated',
      });
    }
  }

  /*
  componentDidMount() {
    this.props.rcopiaNotificationsList()

    const timer = setInterval(()=>{
      this.props.rcopiaNotificationsList();
    },30000);

    this.setState({
      timer: timer
    });
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearInterval(this.state.timer);
    }
  }
  */

  closePortalRegistrationDialog = () => {
    this.setState({ portalRegistrationDialogVisible: false });
  };

  openPortalRegistrationDialog = () => {
    this.setState({ portalRegistrationDialogVisible: true });
  };
  openNewPatientModal = () => {
    this.setState({ newPatientModalVisible: true });
  };

  closeNewPatientModal = () => {
    this.setState({ newPatientModalVisible: false });
  };

  createProgressNote = () => {
    const ed = this.props.earliestUnsignedNote();
    const days = 7;
    if (ed && moment().diff(ed, 'days') > days) {
      Modal.error({
        title: 'New Progress Note Cannot be Created',
        content: 'You must first finish and sign all of your progress notes older than ' + days + ' days.',
      });
      return;
    }

    if (this.props.isPrescriber) {
      this.props.noteCreate(this.props.patientId, 2, {}); // Type 2 - Prescriber progress note
      this.props.history.push('/emr/patients/' + this.props.patientId + '/progressnote/0');
    } else if (this.props.isTherapist) {
      this.props.noteCreate(this.props.patientId, 1, {}); // Type 1 - Therapist progress note
      this.props.history.push('/emr/patients/' + this.props.patientId + '/progressnote/0');
    }
  };

  createGeneralChartNote = () => {
    this.props.noteCreate(this.props.patientId, 3, {}); // Type 3 - General Chart Note
    this.props.history.push('/emr/patients/' + this.props.patientId + '/generalnote/0');
  };

  createLetterFromTemplateNote = () => {
    this.props.history.push('/emr/patients/' + this.props.patientId + '/letternote');
  };

  createAdminTimeNote = () => {
    this.props.history.push('/emr/patients/' + this.props.patientId + '/admintimenote');
  };

  createLateCancelNote = () => {
    this.props.history.push('/emr/patients/' + this.props.patientId + '/latecancelnote');
  };

  createLabRequestNote = () => {
    this.props.history.push('/emr/patients/' + this.props.patientId + '/labrequestnote');
  };

  printChart = () => {
    this.props.history.push('/emr/patients/' + this.props.patientId + '/chartoutput');
  };

  onClickLogout = () => {
    this.props.history.push('/logout');
  };

  archiveChart = () => {
    this.props.archiveChart(this.props.patientId);
  };

  unarchiveChart = () => {
    this.props.unarchiveChart(this.props.patientId);
  };

  putChartOnHold = () => {
    this.props.placeChartOnHold(this.props.patientId);
  };

  removeChartHold = () => {
    this.props.removeChartHold(this.props.patientId);
  };

  addToCareTeam = () => {
    const type = this.props.currentInbox.type;
    const id = type === 1 ? this.props.currentInbox.user_id : this.props.currentInbox.group_id;
    this.props.addToCareTeam(this.props.patientId, type, id);
  };

  removeFromCareTeam = () => {
    const type = this.props.currentInbox.type;
    const id = type === 1 ? this.props.currentInbox.user_id : this.props.currentInbox.group_id;
    this.props.removeFromCareTeam(this.props.patientId, type, id);
  };

  isOnCareTeam = () => {
    return this.props.careteam.find((x) => x.user && x.user.id === this.props.activeUserId && x.active) ? true : false;
  };

  colorAmount = (x) => {
    return (
      <Tag style={{ width: '40px', textAlign: 'right' }} color="#87d068">
        {x}
      </Tag>
    );
  };

  zeroPadNum(num, size) {
    let s = num + '';
    return s.padStart(size - s.length, '0');
  }

  getPatientName = () => {
    const { full_name, id } = this.props.patientData;
    return full_name + ' (' + this.zeroPadNum(id, 7) + ')';
  };

  onClickDrFirstMenu = ({ key }) => {
    let url = null;
    switch (key) {
      case 'patient':
        url = '/display/rcopia/patient/' + this.props.patientId;
        break;
      case 'message':
        url = '/display/rcopia/message';
        break;
      case 'report':
        url = '/display/rcopia/report';
        break;
      default:
        break;
    }
    if (url) {
      const win = window.open(url, '_blank');
      win.focus();
    }
  };

  getRcopiaMenu = () => {
    return (
      <Menu
        style={{ border: '1px solid #2b8ed8', paddingBottom: '0px', paddingTop: '0px' }}
        onClick={this.onClickDrFirstMenu}
      >
        <Menu.Item key="report">Reports</Menu.Item>
        <Menu.Item key="message">
          <span>{this.colorAmount(this.props.rx_refills)} Refills</span>
        </Menu.Item>
        <Menu.Item key="message">
          <span>{this.colorAmount(this.props.rx_pending)} Rx Pending</span>
        </Menu.Item>
        <Menu.Item key="message">
          <span>{this.colorAmount(this.props.rx_need_signing)} Rx Needing Signature</span>
        </Menu.Item>
        <Menu.Item key="message">
          <span>{this.colorAmount(this.props.rx_change)} Rx Changed</span>
        </Menu.Item>

        {this.props.patientId && (
          <Menu.Item key="patient" style={{ borderTop: '1px dashed lightgray', padding: '15px' }}>
            <span>ePrescribe {this.getPatientName()}</span>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  onSelectPatient = (patient_id) => {
    const paths = this.props.location.pathname.split('/');
    if (paths[2] === 'appointments') {
      this.props.setEmrState({
        patientId: patient_id,
      });
      this.props.history.push('/emr/appointments/' + patient_id);
    } else {
      this.props.history.push('/emr/patients/' + patient_id);
    }
  };

  onOpenMessenger = () => {
    this.setState({
      emailDrawerVisible: true,
    });
  };

  onCloseMessenger = () => {
    this.setState({
      emailDrawerVisible: false,
    });
  };

  vacationCoverage = () => {
    if (this.props.isClinician) {
      this.setState({
        vacationModalVisible: true,
      });
    } else if (this.props.isAdmin) {
      this.setState({
        vacationAdminModalVisible: true,
      });
    }
  };

  closeVacationModal = () => {
    if (this.props.isClinician) {
      this.setState({
        vacationModalVisible: false,
      });
    } else {
      this.setState({
        vacationAdminModalVisible: false,
      });
    }
  };

  showTimeoffModal = () => {
    this.setState({
      timeoffModalVisible: true,
    });
  };

  closeTimeoffModal = () => {
    this.setState({
      timeoffModalVisible: false,
    });
  };

  showDirectorListModal = () => {
    this.setState({
      directorListModalVisible: true,
    });
  };

  closeDirectorListModal = () => {
    this.setState({
      directorListModalVisible: false,
    });
  };

  openDeleteLateCancelDialog = () => {
    this.setState({
      deleteLateCancelDialogVisible: true,
    });
  };

  closeDeleteLateCancelDialog = () => {
    this.setState({
      deleteLateCancelDialogVisible: false,
    });
  };

  onClickMenu = ({ key }) => {
    switch (key) {
      case 'createchart':
        this.openNewPatientModal();
        break;
      case 'createprogressnote':
        this.createProgressNote();
        break;
      case 'creategeneralnote':
        this.createGeneralChartNote();
        break;
      case 'createlettertemplate':
        this.createLetterFromTemplateNote();
        break;
      case 'createadmintimenote':
        this.createAdminTimeNote();
        break;
      case 'labrequest':
        this.createLabRequestNote();
        break;
      case 'latecancelnote':
        this.createLateCancelNote();
        break;
      case 'printchart':
        this.printChart();
        break;
      case 'archive':
        this.archiveChart();
        break;
      case 'unarchive':
        this.unarchiveChart();
        break;
      case 'putonhold':
        this.putChartOnHold();
        break;
      case 'removehold':
        this.removeChartHold();
        break;
      case 'addtocareteam':
        this.addToCareTeam();
        break;
      case 'removefromcareteam':
        this.removeFromCareTeam();
        break;
      case 'coverage':
        this.vacationCoverage();
        break;
      case 'timeoff':
        this.showTimeoffModal();
        break;
      case 'resendportal':
        this.openPortalRegistrationDialog();
        break;
      case 'deletelatecancel':
        this.openDeleteLateCancelDialog();
        break;
      case 'toggle_reg_complete':
        this.toggleRegComplete();
      case 'director_list':
        this.showDirectorListModal();
        break;
      default:
        break;
    }
  };

  getMenu = () => {
    const isChartOpen = !this.props.isChartOnHold && this.props.isChartOpen ? true : false;
    const isOnCareTeam = this.isOnCareTeam();

    /*
            {this.props.isClinician && isChartOpen && isOnCareTeam && this.props.patientId &&
              <Menu.Item key='createprogressnote'>
                <Icon type='bars'/>
                <span>Progress Note</span>
              </Menu.Item>
            }

            {(this.props.isClinician || this.props.isAdmin) && <Menu.Item key='coverage'>
              <Icon type='car'/>
              <span>Vacation Coverage</span>
            </Menu.Item> }
    */
    return (
      <Menu style={{ border: '1px solid lightgrey', padding: '1px' }} onClick={this.onClickMenu}>
        {this.props.isAdmin && (
          <Menu.Item key="createchart">
            <PlusCircleOutlined />
            <span>Create New Patient Chart</span>
          </Menu.Item>
        )}

        {this.props.isChartOpen &&
          ((this.props.isClinician && isOnCareTeam) || this.props.isAdmin) &&
          this.props.patientId && (
            <Menu.Item key="creategeneralnote">
              <BarsOutlined />
              <span>General Chart Note</span>
            </Menu.Item>
          )}

        {this.props.isClinician && isChartOpen && isOnCareTeam && this.props.patientId && (
          <Menu.Item key="createlettertemplate">
            <PictureOutlined />
            <span>Template Letter</span>
          </Menu.Item>
        )}

        {isChartOpen && this.props.isClinician && isOnCareTeam && this.props.patientId && (
          <Menu.Item key="createadmintimenote">
            <InboxOutlined />
            <span>Record Administrative Time</span>
          </Menu.Item>
        )}

        {this.props.isPrescriber && isChartOpen && isOnCareTeam && this.props.patientId && (
          <Menu.Item key="labrequest">
            <ExperimentOutlined />
            <span>Lab Request</span>
          </Menu.Item>
        )}

        {this.props.isAdmin && !this.props.isClinician && this.props.patientId && isChartOpen && (
          <Menu.Item key="latecancelnote">
            <HourglassOutlined />
            <span>Late Cancellation</span>
          </Menu.Item>
        )}

        {this.props.isChartOpen && this.props.patientId && (
          <Menu.Item key="resendportal">
            <MailOutlined />
            <span>Resend Portal Invitation</span>
          </Menu.Item>
        )}

        {this.props.isAdmin && this.props.patientId && (
          <Menu.Item key="printchart">
            <PrinterOutlined />
            <span>Print Chart</span>
          </Menu.Item>
        )}

        {this.props.isAdmin && this.props.isChartOpen && this.props.patientId && (
          <Menu.Item key="archive">
            <UploadOutlined />
            <span>Archive this Chart</span>
          </Menu.Item>
        )}

        {this.props.isAdmin && !this.props.isChartOpen && this.props.patientId && (
          <Menu.Item key="unarchive">
            <DownloadOutlined />
            <span>Unarchive this Chart</span>
          </Menu.Item>
        )}

        {this.props.isAdmin && !this.props.isChartOnHold && this.props.patientId && (
          <Menu.Item key="putonhold">
            <LockOutlined />
            <span>Put this Chart on Hold</span>
          </Menu.Item>
        )}

        {this.props.isAdmin && this.props.isChartOnHold && this.props.patientId && (
          <Menu.Item key="removehold">
            <UnlockOutlined />
            <span>Remove Hold from this Chart</span>
          </Menu.Item>
        )}

        {this.props.isStaffSupervisor && this.props.patientId && (
          <Menu.Item key="deletelatecancel">
            <AlertOutlined />
            <span>Delete a Late Cancel/No Show Session</span>
          </Menu.Item>
        )}

        {this.props.isAdmin && this.props.patientId && (
          <Menu.Item key="toggle_reg_complete">
            <EditOutlined />
            <span>Toggle Patient Registration Complete</span>
          </Menu.Item>
        )}

        {this.props.isAdmin && (
          <Menu.Item key="director_list">
            <FileTextOutlined />
            <span>Director List</span>
          </Menu.Item>
        )}

        {this.props.isClinician && <Menu.Divider />}

        {this.props.isClinician && (
          <Menu.Item key="timeoff">
            <CarOutlined />
            <span>Time Off Requests</span>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  rxMenu = () => {
    if (!this.props.isPrescriber) {
      return null;
    }

    return (
      <Dropdown overlay={this.getRcopiaMenu()} placement="bottomCenter" trigger={['click']}>
        <Badge dot={this.props.rx_total > 0}>
          <Button size="small">Rx</Button>
        </Badge>
      </Dropdown>
    );
  };

  mailMenu = () => {
    let unread = 0;
    if (this.props.inbox && this.props.inbox.length > 0) {
      //      console.log(this.props.assignedAdminGroupMemberId,this.props.inbox);
      this.props.inbox.forEach((box) => {
        const data = safe(box, 'data.threads');

        if (this.props.isClinician) {
          unread += data.reduce((acc, x) => acc + x.unread, 0);
        } else {
          data.forEach((x) => {
            if (
              x.unread &&
              (safe(x, 'thread.patient.billingist_id') === this.props.assignedAdminGroupMemberId ||
                safe(x, 'thread.patient.receptionist_id') === this.props.assignedAdminGroupMemberId)
            ) {
              unread++;
            }
          });
        }
      });
    }
    // this.props.assignedAdminGroupMemberId
    return (
      <Badge count={unread}>
        <span style={{ fontSize: '20pt', cursor: 'pointer', color: 'white' }} onClick={this.onOpenMessenger}>
          <MailOutlined />
        </span>
      </Badge>
    );
  };

  removeCoverage = () => {
    this.props.deleteMyVacationCoverage();
  };

  displayVacationCoverage = () => {
    if (this.props.activeUser.coveringProvider) {
      const sd = moment(safe(this.props, 'activeUser.covering_start_date')).format('MM/DD');
      const ed = moment(safe(this.props, 'activeUser.covering_end_date')).format('MM/DD');
      const provider =
        safe(this.props, 'activeUser.coveringProvider.firstname') +
        ' ' +
        safe(this.props, 'activeUser.coveringProvider.lastname');
      return (
        <span
          style={{
            backgroundColor: 'yellow',
            color: 'black',
            display: 'inline-block',
            borderRadius: '5px',
            paddingLeft: '5px',
            paddingRight: '5px',
          }}
        >
          On Vacation {sd}-{ed} <strong>{provider}</strong> Covering
          <Button
            icon={<CloseOutlined />}
            type="link"
            onClick={() => {
              this.removeCoverage();
            }}
          />
        </span>
      );
    }
  };

  toggleRegComplete = () => {
    this.props.toggleRegistrationComplete(this.props.patientId);
  };

  mainPlusMenu = () => {
    return (
      <Dropdown overlay={this.getMenu()} placement="bottomCenter" trigger={['click']}>
        <Button shape="circle" icon={<PlusOutlined />} size="small" style={{ marginLeft: '20px' }} />
      </Dropdown>
    );
  };

  // { this.rxMenu() }
  gotoHelpLink = () => {
    window.open('https://pcpaadmin.com', '_blank');
  };

  displayHelp = () => {
    return (
      <span onClick={this.gotoHelpLink}>
        <QuestionCircleFilled style={{ fontSize: '20pt', cursor: 'pointer' }} />
      </span>
    );
  };

  render() {
    const bgcolor = '#2b8ed8';

    const style = {
      background: bgcolor,
      color: 'white',
      paddingTop: '10px',
      paddingLeft: '0px',
      paddingRight: '0px',
    };

    return (
      <div style={style} className="app-emr">
        <table style={{ width: '100%' }} cellPadding={0} cellSpacing={0}>
          <tbody>
            <tr valign="top">
              <td style={{ width: '140px', whiteSpace: 'nowrap' }}>
                <HeaderLogo title="PCPA EMR" />
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <PatientSelector onSelectPatient={this.onSelectPatient} />
                {this.mainPlusMenu()}
              </td>
              <td></td>
              <td style={{ width: '100%', textAlign: 'center' }}>{this.displayVacationCoverage()}</td>
              <td style={{ width: '60px', whiteSpace: 'nowrap', paddingRight: '10px' }}>{this.displayHelp()}</td>
              <td style={{ width: '60px', whiteSpace: 'nowrap', paddingRight: '10px', verticalAlign: 'bottom' }}>
                {this.mailMenu()}
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <AppSelector activeUserName={this.props.activeUserName} />
              </td>
            </tr>
          </tbody>
        </table>

        <Messenger visible={this.state.emailDrawerVisible} onClose={this.onCloseMessenger} />

        <CreatePatientChart visible={this.state.newPatientModalVisible} closeWindow={this.closeNewPatientModal} />

        <VacationCoverageAdmin visible={this.state.vacationAdminModalVisible} closeDialog={this.closeVacationModal} />

        <Timeoff visible={this.state.timeoffModalVisible} closeDialog={this.closeTimeoffModal} />
        
        <DirectorList visible={this.state.directorListModalVisible} closeDialog={this.closeDirectorListModal} />

        <VacationCoverage visible={this.state.vacationModalVisible} closeDialog={this.closeVacationModal} />

        <PortalRegistration
          visible={this.state.portalRegistrationDialogVisible}
          closeDialog={this.closePortalRegistrationDialog}
        />

        <DeleteLateCancelDialog
          visible={this.state.deleteLateCancelDialogVisible}
          closeDialog={this.closeDeleteLateCancelDialog}
          patientId={this.props.patientId}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    patientId: safe(state, 'emr.emr.patientId'),
    patientData: safe(state, 'shared.patient.load.data', {}),
    activeUserName: safe(state, 'shared.domain.activeUser.full_name'),
    activeUserId: safe(state, 'shared.domain.activeUser.id'),
    activeUser: safe(state, 'shared.domain.activeUser'),
    isChartOnHold: safe(state, 'shared.patient.load.data.on_hold', false),
    isChartOpen: safe(state, 'shared.patient.load.data.open', true),
    isAdmin: isAdmin(state),
    isStaffSupervisor: isStaffSupervisor(state),
    isClinician: isClinician(state),
    isPrescriber: isPrescriber(state),
    isTherapist: isTherapist(state),
    rx_refills: safe(state, 'emr.rcopia.notifications.data.rx_refills', 0),
    rx_pending: safe(state, 'emr.rcopia.notifications.data.rx_pending', 0),
    rx_need_signing: safe(state, 'emr.rcopia.notifications.data.rx_need_signing', 0),
    rx_change: safe(state, 'emr.rcopia.notifications.data.rx_change', 0),
    rx_total: safe(state, 'emr.rcopia.notifications.data.rx_total', 0),
    careteam: safe(state, 'shared.careteam.list.data', []),
    inbox: safe(state, 'emr.inbox.list.data', []),
    currentInbox: safe(state, 'emr.inbox.current'),
    deleteCoverageEvent: safe(state, 'emr.coverage.delete', {}),
    assignedAdminGroupMemberId: safe(state, 'emr.inbox.label', safe(state, 'shared.domain.activeUser.id')),
    toggleRegistration: safe(state, 'emr.registration.toggle', {}),
  };
};

export default connect(mapStateToProps, { ...emr_actions, ...shared_actions })(withRouter(EmrHeader));
