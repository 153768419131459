import React from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';

import InsArPatient from './Ar_/InsArPatient';
import InsArClaim from './Ar_/InsArClaim';
import PtArTrad from './Ar_/PtArTrad';
import PracticeAr from './Ar_/PracticeAr';
import queryString from 'qs';

import { safe } from '../../../helpers/reduxSelectors';

import * as billing_actions from '../../../actions/billing';
import * as shared_actions from '../../../actions/shared';

class Ar extends React.Component {

  constructor(props) {
    super(props);
    this.pageSize = 50;
  }

  componentDidMount() {
    const arTab = this.props.match.params.id;
    const searchString = (this.props.location.search.length > 0) ? this.props.location.search.substring(1) : '';
    const arCarrier = safe(queryString.parse(searchString),'c',0);

    this.props.setBillingState({
      ar_tab: arTab
    });

    if (arTab === "2") {
      this.props.setBillingState({
        ar_carrier: arCarrier
      })
    }

    this.loadData(arTab,arCarrier);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id ||
        prevProps.location.search !== this.props.location.search) {
      const arTab = this.props.match.params.id;
      const searchString = (this.props.location.search.length > 0) ? this.props.location.search.substring(1) : '';
      const arCarrier = safe(queryString.parse(searchString),'c',0);

      this.props.setBillingState({
        ar_tab: arTab
      });

      if (arTab === "2") {
        this.props.setBillingState({
          ar_carrier: arCarrier
        })
      }

      this.loadData(arTab,arCarrier);
    }
  }

  loadData = (arTab,arCarrier) => {
    switch (arTab) {
      case "1":
        this.props.loadPtAr(this.props.ar_current_page_1*this.pageSize,
          this.pageSize,this.props.ar_page_1_sort,false);
        break;
      case "2":
        this.props.loadInsClaimAr(this.props.ar_current_page_2*this.pageSize,
          this.pageSize,arCarrier,false);
        break;
      case "3":
        this.props.loadInsPtAr(this.props.ar_current_page_3*this.pageSize,
          this.pageSize,this.props.ar_page_3_sort,false);
        break;
      case "4":
        this.props.loadPracticeAr(false);
        break;
      default:
        break;
    }
  }

  onTabSelect = (key) => {
    if (key === "2") {
      this.props.history.push(key + '?c=' + this.props.activeArCarrier)
    } else {
      this.props.history.push(key);
    }
  }

  render() {
    const TabPane = Tabs.TabPane;

    return (
      <div className='main'>
        <h3>Accounts Receivable</h3>
        <Tabs activeKey={this.props.activeArTab} onChange={this.onTabSelect}>
          <TabPane tab="Pt A/R" key="1">
            <PtArTrad pageSize={this.pageSize}/>
          </TabPane>
          <TabPane tab="Ins A/R (by Claim)" key="2">
            <InsArClaim pageSize={this.pageSize}/>
          </TabPane>
          <TabPane tab="Ins A/R (by Patient)" key="3">
            <InsArPatient pageSize={this.pageSize}/>
          </TabPane>
          <TabPane tab="Practice A/R" key="4">
            <PracticeAr/>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ar_current_page_1: safe(state,'shared.domain.billing.ar_current_page_1',0),
    ar_current_page_2: safe(state,'shared.domain.billing.ar_current_page_2',0),
    ar_current_page_3: safe(state,'shared.domain.billing.ar_current_page_3',0),
    activeArTab: safe(state,'shared.domain.billing.ar_tab',"1"),
    activeArCarrier: safe(state,'shared.domain.billing.ar_carrier',"0"),
    ar_page_1_sort: safe(state,'shared.domain.billing.ar_page_1_sort',0),
    ar_page_3_sort: safe(state,'shared.domain.billing.ar_page_3_sort',0)
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(Ar);
