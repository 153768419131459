import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import { connect } from 'react-redux';

import * as actions from '../../../../../../actions/emr';

class InActiveProblemList extends React.Component {
  static propTypes = {
    patientId: PropTypes.number,
    list: PropTypes.array
  }
  
  reactivate = (id) => {
    this.props.activateProblemListItem(this.props.patientId,id);
  }

  renderOtherList() {
    return (
      <div>
        <h3 style={{marginTop:'30px',textAlign:'center',color:'grey'}}>Other Clinician Patient Problems</h3>
        <Table
          style={{marginRight:'60px',marginLeft:'60px'}}
          size='small'
          bordered={true}
          pagination={false}
          rowKey='id'
          rowClassName={(record,index)=> {
            return (record.active) ? 'active_problem' : 'inactive_problem';
          }}
          dataSource={this.props.list}
          columns={[{
            title: 'Problem Description',
            dataIndex: 'description',
            width: '35%',
            render: (text, record) => {
              return text;
            },
          }, {
            title: 'In Use By',
            width: '20%',
            dataIndex: 'user_id',
            render: (text,record) => {
              //const name = (record.creator) ? record.creator.full_name : null;
              return Object.values(record.members).join(',');
            }
          }, {
            title: 'Action',
            width: '10%',
            editable: true,
            render: (text, record) => {
              return <Button onClick={()=>this.reactivate(record.id)}>Add to my list</Button>
            }
          }]}
        />
      </div>
    );
  }

  render() {
    if (this.props.list.length === 0) {
      return null;
    }
    return this.renderOtherList();
  }
}

export default connect(null,actions)(InActiveProblemList);
