import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Spin } from 'antd';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';

class ReportsUnsignedMissing extends React.Component {

  componentDidMount() {
    this.props.getUnsignedMissingReport();
  }

  render() {
    const columns = [{
      title: 'Provider',
      dataIndex: 'name',
    },{
      title: 'Unsigned Progress Notes',
      dataIndex: 'unsigned',
    },{
      title: 'Sessions Missing Progress Notes',
      dataIndex: 'missing'
    },{
      title: 'Total Unsigned or Missing > 7 Days',
      dataIndex: 'over_7'
    },{
      title: 'Total',
      dataIndex: 'total'
    }];

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
            <span style={{float:'right'}}><a href="/api/v1/reports/unsignedmissing?csv=1">CSV Download</a></span>
          </div>

          <h3>Unsigned and Missed Session Report</h3>
          <Table
            className='reports-sessionlist-table'
            columns={columns}
            dataSource={this.props.data}
            bordered={true}
            size='small'
            rowKey='id'
            scroll={{ x:true }}
            pagination={false}
          />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: safe(state,'reports.unsignedmissed.data',[]),
    isBusy: safe(state,'reports.unsignedmissed.processing',false)
  }
}

export default connect(mapStateToProps,actions)(ReportsUnsignedMissing);
