import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import HeaderLogo from '../shared/HeaderLogo';
import AppSelector from '../shared/AppSelector';
import ReportsHome from './Reports_/ReportsHome';
import ReportsSessionList from './Reports_/ReportsSessionList';
import ReportsPatients from './Reports_/ReportsPatients';
import ReportsGroupStats from './Reports_/ReportsGroupStats';
import ReportsPayroll from './Reports_/ReportsPayroll';
import ReportsChargeCollection from './Reports_/ReportsChargeCollection';
import ReportsAdjustments from './Reports_/ReportsAdjustments';
import ReportsLyra from './Reports_/ReportsLyra';
import ReportsIntakes from './Reports_/ReportsIntakes';
import ReportsUnsignedMissing from './Reports_/ReportsUnsignedMissing';
import ReportsDupSessions from './Reports_/ReportsDupSessions';
import ReportsPtCredits from './Reports_/ReportsPtCredits';
import ReportsPtUnallocated from './Reports_/ReportsPtUnallocated';
import ReportsOnHold from './Reports_/ReportsOnHold';
import ReportsPostPayments from './Reports_/ReportsPostPayments';

//import ReportsLifestance from './Reports_/ReportsLifestance';
import ReportsLS_td_adjs from './Reports_/ReportsLS_td_adjs';
import ReportsLS_td_charges from './Reports_/ReportsLS_td_charges';
import ReportsLS_td_payments from './Reports_/ReportsLS_td_payments';
import ReportsLS_claim_summary from './Reports_/ReportsLS_claim_summary';
import ReportsLS_ar_aging from './Reports_/ReportsLS_ar_aging';
import ReportsLS_ar_aging_detail from './Reports_/ReportsLS_ar_aging_detail';
import ReportsLS_ar_carrier_aging from './Reports_/ReportsLS_ar_carrier_aging';
import ReportsLS_patient_totals from './Reports_/ReportsLS_patient_totals';
import ReportsLS_scheduled_sessions from './Reports_/ReportsLS_scheduled_sessions';
import ReportsHourlyEmployees from './Reports_/ReportsHourlyEmployees';
import ReportsSessionCounts from './Reports_/ReportsSessionCounts';
import ReportsEval from './Reports_/ReportsEval';
import ReportsOpenTimes from './Reports_/ReportsOpenTimes';
import ReportsScheduleTemplates from './Reports_/ReportsScheduleTemplates';

import Timeout from '../shared/Timeout';

import { safe } from '../../helpers/reduxSelectors';
import { VLayout, VLayoutHeader, VLayoutContent } from '../shared/Layout';

class Reports extends React.Component {
  render() {
    const headerStyle = {
      background: '#2b8ed8',
      color: 'white',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '5px',
      height: '42px',
      minHeight: '42px',
    };

    return (
      <Timeout>
        <VLayout>
          <VLayoutHeader style={headerStyle}>
            <HeaderLogo title="PCPA Reports" />
            <AppSelector activeUserName={this.props.activeUserName} />
          </VLayoutHeader>
          <VLayoutContent>
            <Switch>
              <Route path="/reports/patients" component={ReportsPatients} />
              <Route path="/reports/sessionlist" component={ReportsSessionList} />
              <Route path="/reports/groupstats" component={ReportsGroupStats} />
              <Route path="/reports/payroll" component={ReportsPayroll} />
              <Route path="/reports/chargecollection" component={ReportsChargeCollection} />
              <Route path="/reports/adjustments" component={ReportsAdjustments} />
              <Route path="/reports/lyra" component={ReportsLyra} />
              <Route path="/reports/dupsessions" component={ReportsDupSessions} />
              <Route path="/reports/intakes" component={ReportsIntakes} />
              <Route path="/reports/unsignedmissing" component={ReportsUnsignedMissing} />
              <Route path="/reports/ptcredits" component={ReportsPtCredits} />
              <Route path="/reports/ptunallocated" component={ReportsPtUnallocated} />
              <Route path="/reports/lifestance/td_adjs" component={ReportsLS_td_adjs} />
              <Route path="/reports/lifestance/td_charges" component={ReportsLS_td_charges} />
              <Route path="/reports/lifestance/td_payments" component={ReportsLS_td_payments} />
              <Route path="/reports/lifestance/claim_summary" component={ReportsLS_claim_summary} />
              <Route path="/reports/lifestance/ar_aging" component={ReportsLS_ar_aging} />
              <Route path="/reports/lifestance/ar_aging_detail" component={ReportsLS_ar_aging_detail} />
              <Route path="/reports/lifestance/ar_carrier_aging" component={ReportsLS_ar_carrier_aging} />
              <Route path="/reports/lifestance/patient_totals" component={ReportsLS_patient_totals} />
              <Route path="/reports/lifestance/scheduled_sessions" component={ReportsLS_scheduled_sessions} />
              <Route path="/reports/hourlyemployees" component={ReportsHourlyEmployees} />
              <Route path="/reports/sessioncounts" component={ReportsSessionCounts} />
              <Route path="/reports/onhold" component={ReportsOnHold} />
              <Route path="/reports/evals" component={ReportsEval} />
              <Route path="/reports/opentimes" component={ReportsOpenTimes} />
              <Route path="/reports/scheduletemplates" component={ReportsScheduleTemplates} />
              <Route path="/reports/paymentspostcount" component={ReportsPostPayments} />
              <Route path="/reports" component={ReportsHome} />
            </Switch>
          </VLayoutContent>
        </VLayout>
      </Timeout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeUserName: safe(state, 'shared.domain.activeUser.full_name'),
  };
};

export default connect(mapStateToProps, null)(Reports);
