export const LINKED_LIST_REQUEST = 'LINKED_LIST_REQUEST';
export const LINKED_LIST_SUCCESS = 'LINKED_LIST_SUCCESS';
export const LINKED_LIST_FAILURE = 'LINKED_LIST_FAILURE';
export const LINKED_LIST_CLEAR = 'LINKED_LIST_CLEAR';
export const LINKED_DELETE_REQUEST = 'LINKED_DELETE_REQUEST';
export const LINKED_DELETE_SUCCESS = 'LINKED_DELETE_SUCCESS';
export const LINKED_DELETE_FAILURE = 'LINKED_DELETE_FAILURE';
export const LINKED_DELETE_CLEAR = 'LINKED_DELETE_CLEAR';
export const LINKED_ADD_REQUEST = 'LINKED_ADD_REQUEST';
export const LINKED_ADD_SUCCESS = 'LINKED_ADD_SUCCESS';
export const LINKED_ADD_FAILURE = 'LINKED_ADD_FAILURE';
export const LINKED_ADD_CLEAR = 'LINKED_ADD_CLEAR';

export const PACCOUNT_SEARCH_REQUEST = 'PACCOUNT_SEARCH_REQUEST';
export const PACCOUNT_SEARCH_SUCCESS = 'PACCOUNT_SEARCH_SUCCESS';
export const PACCOUNT_SEARCH_FAILURE = 'PACCOUNT_SEARCH_FAILURE';
export const PACCOUNT_SEARCH_CLEAR = 'PACCOUNT_SEARCH_CLEAR';
export const PACCOUNT_LOAD_REQUEST = 'PACCOUNT_LOAD_REQUEST';
export const PACCOUNT_LOAD_SUCCESS = 'PACCOUNT_LOAD_SUCCESS';
export const PACCOUNT_LOAD_FAILURE = 'PACCOUNT_LOAD_FAILURE';
export const PACCOUNT_LOAD_CLEAR = 'PACCOUNT_LOAD_CLEAR';
export const PACCOUNT_UPDATE_REQUEST = 'PACCOUNT_UPDATE_REQUEST';
export const PACCOUNT_UPDATE_SUCCESS = 'PACCOUNT_UPDATE_SUCCESS';
export const PACCOUNT_UPDATE_FAILURE = 'PACCOUNT_UPDATE_FAILURE';
export const PACCOUNT_UPDATE_CLEAR = 'PACCOUNT_UPDATE_CLEAR';
export const PACCOUNT_LINKEDLIST_REQUEST = 'PACCOUNT_LINKEDLIST_REQUEST';
export const PACCOUNT_LINKEDLIST_SUCCESS = 'PACCOUNT_LINKEDLIST_SUCCESS';
export const PACCOUNT_LINKEDLIST_FAILURE = 'PACCOUNT_LINKEDLIST_FAILURE';
export const PACCOUNT_LINKEDLIST_CLEAR = 'PACCOUNT_LINKEDLIST_CLEAR';
export const PACCOUNT_ADDLINK_REQUEST = 'PACCOUNT_ADDLINK_REQUEST';
export const PACCOUNT_ADDLINK_SUCCESS = 'PACCOUNT_ADDLINK_SUCCESS';
export const PACCOUNT_ADDLINK_FAILURE = 'PACCOUNT_ADDLINK_FAILURE';
export const PACCOUNT_ADDLINK_CLEAR = 'PACCOUNT_ADDLINK_CLEAR';
export const PACCOUNT_DELETELINK_REQUEST = 'PACCOUNT_DELETELINK_REQUEST';
export const PACCOUNT_DELETELINK_SUCCESS = 'PACCOUNT_DELETELINK_SUCCESS';
export const PACCOUNT_DELETELINK_FAILURE = 'PACCOUNT_DELETELINK_FAILURE';
export const PACCOUNT_DELETELINK_CLEAR = 'PACCOUNT_DELETELINK_CLEAR';
export const PACCOUNT_DELETE_REQUEST = 'PACCOUNT_DELETE_REQUEST';
export const PACCOUNT_DELETE_SUCCESS = 'PACCOUNT_DELETE_SUCCESS';
export const PACCOUNT_DELETE_FAILURE = 'PACCOUNT_DELETE_FAILURE';
export const PACCOUNT_DELETE_CLEAR = 'PACCOUNT_DELETE_CLEAR';
export const PACCOUNT_FEATUREFLAG_SET_REQUEST = 'PACCOUNT_FEATUREFLAG_SET_REQUEST';
export const PACCOUNT_FEATUREFLAG_SET_SUCCESS = 'PACCOUNT_FEATUREFLAG_SET_SUCCESS';
export const PACCOUNT_FEATUREFLAG_SET_FAILURE = 'PACCOUNT_FEATUREFLAG_SET_FAILURE';
export const PACCOUNT_FEATUREFLAG_SET_CLEAR = 'PACCOUNT_FEATUREFLAG_SET_CLEAR';

export const REGISTRATION_LIST_REQUEST = 'REGISTRATION_LIST_REQUEST';
export const REGISTRATION_LIST_SUCCESS = 'REGISTRATION_LIST_SUCCESS';
export const REGISTRATION_LIST_FAILURE = 'REGISTRATION_LIST_FAILURE';
export const REGISTRATION_LIST_CLEAR = 'REGISTRATION_LIST_CLEAR';
export const REGISTRATION_VERIFY_REQUEST = 'REGISTRATION_VERIFY_REQUEST';
export const REGISTRATION_VERIFY_SUCCESS = 'REGISTRATION_VERIFY_SUCCESS';
export const REGISTRATION_VERIFY_FAILURE = 'REGISTRATION_VERIFY_FAILURE';
export const REGISTRATION_VERIFY_CLEAR = 'REGISTRATION_VERIFY_CLEAR';

export const PATIENT_BULKCREATE_REQUEST = 'PATIENT_BULKCREATE_REQUEST';
export const PATIENT_BULKCREATE_SUCCESS = 'PATIENT_BULKCREATE_SUCCESS';
export const PATIENT_BULKCREATE_FAILURE = 'PATIENT_BULKCREATE_FAILURE';
export const PATIENT_BULKCREATE_CLEAR = 'PATIENT_BULKCREATE_CLEAR';
