const styles: Record<string, React.CSSProperties> = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem',
  },
  popoverWrapper: {
    flexBasis: '240px',
    margin: 'auto',
  },
};

export default styles;
