import axios from 'axios';
import * as types from './types';

const displayLogout = (dispatch,err) => {
  if (err && err.response && err.response.status === 401) {
    dispatch ({type:'USER_LOGIN_CLEAR'});
  }
}

export const getRcopiaSSOurl = (type,patient_id) => async dispatch => {
  dispatch ({type: types.CONNECTURL_GET_REQUEST});
  try {
    let url = null;
    switch (type) {
      case 'patient': url = '/api/v1/rcopia/sso/patient/'+patient_id; break;
      case 'message': url = '/api/v1/rcopia/sso/message'; break;
      case 'report': url = '/api/v1/rcopia/sso/report'; break;
      default: throw new Error('Invalid SSO type');
    }
    const res = await axios.get(url);
    dispatch ({type: types.CONNECTURL_GET_SUCCESS, payload: res.data});
  } catch (err) {
    displayLogout(dispatch,err);
    dispatch ({type: types.CONNECTURL_GET_FAILURE, error: err.response});
  }
}
