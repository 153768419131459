import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { safe } from '../../../../../../helpers/reduxSelectors';
import AttachmentList from './AttachmentList';

class IntakeNote extends React.Component {

  office2String = (x) => {
    switch (x) {
      case 'sf': return 'San Francisco';
      case 'wc': return 'Walnut Creek';
      case 'la': return 'Los Angeles';
      case 'bg': return 'Burlingame';
      case 'tp': return 'Telepsych';
      default: return 'Other';
    }
  }

  relation2String = (x) => {
    switch (x) {
      case 1: return 'Myself';
      case 2: return 'My child';
      case 3: return 'Myself and My Partner (Couples Therapy)';
      case 4: return 'My family (Family Therapy)';
      default: return 'Other';
    }
  }

  type2String = (x) => {
    switch (x) {
      case 'psychiatrist': return 'Psychiatrist';
      case 'therapist': return 'Therapist';
      case 'both': return 'Both';
      case 'notsure': return 'Not Sure';
      default: return 'Other';
    }
  }

  clean = (x) => {
    return (x) ? x : 'BLANK';
  }

  mediCal = (x) => {
    if (x === 0) {
      return 'No';
    } else if (x === 1) {
      return 'Yes';
    }
    return 'Unknown';
  }

  subscriberRelation = (x) => {
    if (x === 1) {
      return 'Self';
    } else if (x === 4) {
      return 'Other';
    } else {
      return 'Unknown';
    }
  }

  roi = (prefix,label,data,inverse=false) => {
    if (data[prefix+'_select'] === !inverse) {
      return (
        <div>
          <div><strong>{label}</strong></div>
          <div>Name: {data[prefix+'_name']}</div>
          <div>Address: {data[prefix+'_address']},
            {data[prefix+'_city']}, {data[prefix+'_state']} {data[prefix+'_zip']}</div>
          <div>Phone: {data[prefix+'_phone']}</div>
        </div>
      );
    } else {
      return <div><strong>{label}</strong>: NONE</div>;
    }
  }

  tos = (data) => {
    const items = [];

    if (data.tos_tos) {
      items.push(<p key={1}>&#10004; I agree to PCPA's terms of service</p>);
    }

    if (data.tos_lxns) {
      items.push(<p key={3}>&#10004; I have read and agree to PCPA's Late Cancellation Policy and I
      understand that my credit card will be automatically charged
      $175 for any late cancellation or missed appointment.
      I understand that there are no exceptions or refunds offered,
      but that I can elect to have a video session if I cannot make
      it into the office.</p>);
    }

    if (data.tos_insurance) {
      items.push(<p key={4}>&#10004; I understand that PCPA will verify my benefits
      as a courtesy, but that benefit information is not
      guaranteed until confirmed by my insurance company.
      I understand that I am responsible for understanding
      my benefits, and that if my insurance is not accepted,
      I will be responsible for PCPA's self-pay rates.</p>);
    }

    if (data.tos_complaints) {
      items.push(<p key={5}>&#10004; I understand that I am able to submit complaints and grievances to LifeStance Health through the following link: http://www.pcpasf.com/feedback</p>);
    }

/*
    if (data.roi_pcp_select) {
      items.push(<p key={5} style={{fontStyle:'italic'}}>Acknowledgement of no PCP/Pediatrician: No</p>);
    }
*/
    return items;
  }

  generate = (data,signedAt) => {
    if (data) {
      return (
        <div>
          <div style={{fontStyle:'italic'}}>
            Completed: {moment(signedAt).format('M/D/YYYY h:m:ss A')}
          </div>

          <div>
            <div>
              {this.tos(data)}
            </div>

            <div>
              <div><strong>Signature</strong>:</div>
              <img style={{border:'2px solid lightgray'}} alt='signature' src={(data.tos_signature) ? data.tos_signature.canvas : ''}/>
              <div><strong>Signed By</strong>: {(data.tos_signature) ? data.tos_signature.name : ''}</div>
              <div><strong>Signed Date/Time</strong>: {moment(signedAt).format('M/D/YYYY h:mm:ss A')}</div>
            </div>

            <hr />
          </div>

          <div style={{pageBreakBefore:'always'}}>
            <div style={{textAlign:'center'}}>
              <h2>Pacific Coast Psychiatric Associates</h2>
              <div>490 Post Street, #1043 Francisco, CA 94102</div>
              <div>PH: 4154090944 Fax: 4154478665 www.pcpasf.com reception@pcpasf.com</div>
              <h3>AUTHORIZATION TO EXCHANGE PROTECTED HEALTH INFORMATION</h3>
            </div>

            <hr />

            <div><strong>PATIENT NAME</strong>: {this.clean(data.pt_firstname)} {this.clean(data.pt_lastname)}</div>
            <div><strong>DOB</strong>: {(data.pt_dob) ? moment(data.pt_dob).format('MM/DD/YYYY') : this.clean(undefined)}</div>
            <br />
            <div>
              <div>INFORMATION TO BE EXCHANGED WITH:</div>
              <div>{this.roi('roi_pcp','Pediatrician/PCP',data)}</div>
              <div>{this.roi('roi_psy','Psychiatrist',data)}</div>
              <div>{this.roi('roi_thera','Therapist',data)}</div>
            </div>

            <hr />
            <p style={{fontStyle:'italic'}}>I understand that only the patient who has consented for care
              (including minors 13 years of age and older) can authorize for release
              of records. I understand that these records may contain information
              relating to psychiatric/mental health, HIV/AIDS, sexually transmitted
              diseases, and/or drug/alcohol abuse. I give my specific authorization
              for these records to be released. I understand that authorizing the
              disclosure of this health information is voluntary. I do no need to
              sign this form in order to assure treatment or payment. I understand
              that I can cancel this authorization at any time by writing to PCPA,
              490 Post St #1043, SF CA 94102. I understand that once the information
              has been released according to the terms of this authorization, that
              the information cannot be recalled. I understand that any disclosure
              of information carries with it the potential for further release or
              distribution by the recipient that may not be protected by federal
              confidentiality rules. I may cancel this authorization at any time,
              except to the extent that action has already been taken. To revoke
              Authorization to Release Patient Health Information, I must do so
              in writing. Unless I cancel earlier, this authorization will expire
              when treatment with PCPA has ended or one year after date of last
              visit. Further, I understand that a copy of this document may be
              faxed or mailed to the above providers.
            </p>

            <div>
              <div><strong>Signature</strong>:</div>
              <img style={{border:'2px solid lightgray'}} alt='signature' src={(data.tos_signature) ? data.tos_signature.canvas : ''}/>
              <div><strong>Signed By</strong>: {(data.tos_signature) ? data.tos_signature.name : ''}</div>
              <div><strong>Signed Date/Time</strong>: {moment(signedAt).format('M/D/YYYY h:mm:ss A')}</div>
            </div>

            <hr />
            <h3 style={{textAlign:'center'}}>To Providers: This document is for your records. Please do not send
            any patient records until specifically requested. Thank You.</h3>
            <hr />
          </div>

          <div style={{pageBreakBefore:'always'}}>
            <div style={{textAlign:'center'}}>
              <h2>Pacific Coast Psychiatric Associates</h2>
              <h3>HIPAA Disclosure Authorization For Use of Web-Based Forms</h3>
              <div style={{fontStyle:'italic'}}>Authorization for Use or Disclosure of
              Protected Health Information</div>
              <div style={{fontStyle:'italic'}}>Required by the Health Insurance
              Portability and Accountability Act, 45 C.F.R. Parts 160 and 164</div>
              <hr />
            </div>

            <div><strong>PATIENT NAME</strong>: {this.clean(data.pt_firstname)} {this.clean(data.pt_lastname)}</div>
            <div><strong>DOB</strong>: {(data.pt_dob) ? moment(data.pt_dob).format('MM/DD/YYYY') : this.clean(undefined)}</div>

            <ol>
              <li>I authorize Pacific Coast Psychiatric Associates (PCPA) to use
                certain forms of convenient, but non-encrypted, data transmission
                services and platform to transmit or receive my protected health
                information when using any web-based forms that are on PCPA’s
                website or linked to PCPA’s website (the “Web-Based Forms”).
                The intended recipients that I am authorizing to access my protected
                health information through these Web-Based Forms include any PCPA
                employee, practitioner or contractor who may need access to this
                information for treatment, coordination of care, payment, billing,
                or administrative purposes.  These Web-Based Forms are convenient,
                but, because they may not at all times be encrypted, I understand
                that my protected health information could be intercepted by
                someone other than the intended PCPA recipients if I consent to
                the use of these Web-Based Forms by my signature below.</li>

              <li>This authorization for release of information covers the period
                of healthcare from all past, present, and future periods.</li>

              <li>I authorize the release of my complete health record and any
                related information I may provide to PCPA through these Web-Based
                Forms (including records, if such exist, relating to mental
                healthcare, communicable diseases, HIV or AIDS, and treatment of
                alcohol or drug abuse).</li>

              <li>This medical information may be used for treatment, coordination
                of care, payment, billing, administrative, or any other purposes
                as I may direct.</li>

              <li>This authorization shall be in full force and effect until six
                months from the date I terminate my agreement to use PCPA’s
                services, at which time this authorization expires.</li>

              <li>I understand that I have the right to revoke this authorization,
                in writing, at any time. I understand that a revocation is not
                effective to the extent that any person or entity has already
                acted in reliance on my authorization.</li>

              <li>I understand that my treatment, payment, enrollment, or
                eligibility for benefits will not be conditioned on whether
                I sign this authorization.</li>

              <li>I understand that information used or disclosed pursuant to
                this authorization may be disclosed by any recipients and may no
                longer be protected by federal or state law.</li>

              <li>I have been offered a copy of this form. (Click your browser's
                "Print" button if you would like to print this form.)</li>
            </ol>
            <div>
              <div><strong>Signature</strong>:</div>
              <img style={{border:'2px solid lightgray'}} alt='signature' src={(data.tos_signature) ? data.tos_signature.canvas : ''}/>
              <div><strong>Signed By</strong>: {(data.tos_signature) ? data.tos_signature.name : ''}</div>
              <div><strong>Signed Date/Time</strong>: {moment(signedAt).format('M/D/YYYY h:mm:ss A')}</div>
            </div>
          </div>

          <AttachmentList list={this.props.attachments} />
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <div className='chartnotetitle'>New Patient Registration</div>
        { this.generate(safe(this.props,'data.note'),safe(this.props,'data.signedAt')) }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    attachments: safe(ownProps,'data.attachments',[]),
  }
}

export default connect(mapStateToProps,null)(IntakeNote);
