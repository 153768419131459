import React from 'react';
import EditPatient from './EditPatient';
import { AppChart, AppChartContent } from './Layout';

class EditPatientContainer extends React.Component {
  render() {
    return (
      <AppChartContent>
        <AppChart>
          <EditPatient use_container={true} {...this.props}/>
        </AppChart>
      </AppChartContent>
    );
  }
}

export default EditPatientContainer;
