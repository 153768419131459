import React from 'react';
import { CustomerServiceOutlined, DollarOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Checkbox, Avatar } from 'antd';
import { connect } from 'react-redux';
import numeral from 'numeral';

import PatientSelector from '../../../../shared/PatientSelector';
import * as actions from '../../../../../actions/emr';
import { safe } from '../../../../../helpers/reduxSelectors';

class NewThread extends React.Component {
  state = {
    currentPatientId: null,
    currentPatientName: null,
    selectedClinicians: [],
    selectedAdmins: [],
    selectedPortals: []
  };

  componentDidMount() {
    this.onSelectPatient(this.props.patientId,this.getPatientName(this.props.patientData));
  }

  componentDidUpdate(prevProps,prevState) {
    if (prevProps.patientId !== this.props.patientId) {
      this.onSelectPatient(this.props.patientId,this.getPatientName(this.props.patientData));
    }

    if (prevState.currentPatientId !== this.state.currentPatientId) {
      this.props.getPtContacts(this.state.currentPatientId);
    }

    if (prevState.threadCreateSuccess !== this.props.threadCreateSuccess &&
      this.props.threadCreateSuccess) {
      if (this.props.threadCreate.id) {
        this.props.onSelect(this.props.threadCreate);
      }
      this.props.useNewThreadClear();
    }
  }

  getPatientName = (x) => {
    const {full_name,id} = x;
    return full_name + ' ('+numeral(id).format('000000')+')';
  }

  onSelectPatient = (ptid,ptname) => {
    this.setState({
      currentPatientId: ptid,
      currentPatientName: ptname,
      selectedClinicians: [],
      selectedAdmins: [],
      selectedPortals: []
    });
  }

  changeType = (currentArray,fieldName,id,newVal) => {
    if (newVal) {
      const b = currentArray.find(x=>x === id);
      if (!b) {
        this.setState({
          [fieldName]: [...currentArray,id]
        });
      }
    } else {
      this.setState({
        [fieldName]: currentArray.filter(x=>x !== id)
      })
    }
  }

  checkboxChange = (type,id,e) => {
    const newVal = e.target.checked;
    if (type === 1) {
      this.changeType(this.state.selectedClinicians,'selectedClinicians',id,newVal);
    } else if (type === 2) {
      this.changeType(this.state.selectedPortals,'selectedPortals',id,newVal);
    } else if (type === 3) {
      this.changeType(this.state.selectedAdmins,'selectedAdmins',id,newVal);
    }
  }

  isChecked = (type,id) => {
    let data;
    switch (type) {
      case 1: data = this.state.selectedClinicians; break;
      case 2: data = this.state.selectedPortals; break;
      case 3: data = this.state.selectedAdmins; break;
      default: return false;
    }
    return (data && data.find(x=>x===id)) ? true : false;
  }

  getImageName = (id) => {
    return '/api/v1/provider/'+id+'/image';
  }

  renderClinicians = (data) => {
    if (!data || data.length === 0) {
      return null;
    }

    // We must remove the sender from the list of recipients as it always
    // includes myself.

    const fData = (this.props.currentInbox.type !== 1) ? data : data.filter(x=>{
      return (x.id !== this.props.currentInbox.user_id) ;
    });

    if (fData.length === 0) {
      return null;
    }

    return (
      <div style={{paddingTop:'20px'}}>
        <h4>Clinicians</h4>
        { fData.map(x=>{
          return (
            <div key={1*100000+x.id} style={{paddingBottom:'5px'}}>
              <Checkbox
                onChange={(val)=>this.checkboxChange(1,x.id,val)}
                checked={this.isChecked(1,x.id)}
              >
                <Avatar style={{marginRight:'5px'}} src={this.getImageName(x.id)} />
                {x.name}
              </Checkbox>
            </div>
          );
        })}
      </div>
    )
  }

  renderGroups = (data) => {
    if (!data || data.length === 0) {
      return null;
    }

    const fData = (this.props.currentInbox.type !== 3) ? data : data.filter(x=>{
      return (x.id !== this.props.currentInbox.group_id) ;
    });

    if (fData.length === 0) {
      return null;
    }

    return (
      <div style={{paddingTop:'20px'}}>
        <h4>Administrative Groups</h4>
        { fData.map(x=>{
          return (
            <div key={3*100000+x.id} style={{paddingBottom:'5px'}}>
              <Checkbox
                onChange={(val)=>this.checkboxChange(3,x.id,val)}
                checked={this.isChecked(3,x.id)}
              >
                {x.id === 1 && <Avatar style={{backgroundColor:'orange',color:'black',marginRight:'5px'}} icon={<CustomerServiceOutlined />}/>}
                {x.id === 2 && <Avatar style={{backgroundColor:'green',marginRight:'5px'}} icon={<DollarOutlined />} /> }
                {x.name}
              </Checkbox>
            </div>
          );
        })}
      </div>
    );
  }

  getInitials = (name) => {
    return (name) ? (name.match(/\b\w/g) || []).join('').toUpperCase() : null;
  }

  renderPortals = (data) => {
    if (!data || data.length === 0) {
      return null;
    }

    return (
      <div style={{paddingTop:'20px'}}>
        <h4>Portal Users</h4>
        { data.map(x=>{
          return (
            <div key={2*100000+x.id} style={{paddingBottom:'5px'}}>
              <Checkbox
                onChange={(val)=>this.checkboxChange(2,x.id,val)}
                checked={this.isChecked(2,x.id)}
              >
                <Avatar style={{backgroundColor:'red',marginRight:'5px'}}>{this.getInitials(x.name)}</Avatar>
                {x.name}
              </Checkbox>
            </div>
          );
        })}
      </div>
    )
  }

  isOnCareTeam = () => {
    if (!this.props.currentInbox) {
      return false;
    }

    if (this.props.currentInbox.type === 1) {
      const clinicians = this.props.ptcontacts.clinicians;
      return (clinicians &&
        clinicians.find(x=>x.id === this.props.currentInbox.user_id)) ? true : false;
    } else {
      const groups = this.props.ptcontacts.admin;
      return (groups &&
        groups.find(x=>x.id === this.props.currentInbox.group_id)) ? true : false;
    }
  }

  renderChoices = (data) => {
    const style = {
      paddingLeft: '15px',
      paddingRight: '5px',
      marginTop: '10px'
    }

    if (!this.props.ptcontactsSuccess) {
      return false;
    }

    if (this.isOnCareTeam()) {
      return (
        <div style={style}>
          <h3 style={{paddingBottom:'0px'}}>{this.state.currentPatientName}</h3>
          { this.renderClinicians(data.clinicians) }
          { this.renderGroups(data.admin) }
          { this.renderPortals(data.portals) }
        </div>
      );
    } else {
      if (this.state.currentPatientId) {
        return (
          <div style={style}>
            <h3>{this.state.currentPatientName}</h3>
            <h4>This patient is not on your care team</h4>
            <p>To message this patient, you must first add yourself to the patient's
            care team.</p>
          </div>
        );
      } else {
        return (
          <div style={style}>
            <h3>No patient selected</h3>
          </div>
        )
      }
    }

  }

  onCreateThread = () => {
    // create thread here!
    const ptid = (this.state.currentPatientId) ? this.state.currentPatientId : this.props.patientId;

    this.props.useNewThread(ptid,this.props.currentInbox,{
      clinicians: this.state.selectedClinicians,
      admins: this.state.selectedAdmins,
      portals: this.state.selectedPortals,
    });
  }

  render() {
    const barStyle = {
      flex:0,
      paddingLeft:'5px',
      paddingRight:'5px',
      paddingTop:'5px',
      paddingBottom:'12px',
      height:'43px',
      marginBottom:'5px',
      backgroundColor:'#F3F3F3',
      color:'black',
      borderBottom: '1px solid lightgray',
      cursor: 'pointer'
    };

    const count = (this.state.selectedClinicians.length +
      this.state.selectedAdmins.length + this.state.selectedPortals.length);

    return (
      <div>
        <div style={barStyle}>
          <table style={{width:'100%'}} cellPadding={0} cellSpacing={0}>
            <tbody>
              <tr>
                <td style={{textAlign:'left'}}>
                  <span onClick={this.props.onBack}><LeftOutlined /> Group Messaging</span>
                </td>
                <td style={{textAlign:'right'}}>
                  <Button
                    disabled={count === 0}
                    type='primary'
                    onClick={this.onCreateThread}
                  >
                    { count > 0 ? 'OK ('+count+')' : 'OK' }
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <PatientSelector
          style={{width:'100%',paddingLeft:'5px',paddingRight:'5px'}}
          onSelectPatient={this.onSelectPatient}
        />

        { this.renderChoices(this.props.ptcontacts) }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    patientId: safe(state,'emr.emr.patientId'),
    patientData: safe(state,'shared.patient.load.data',{}),
    ptcontacts: safe(state,'emr.ptcontacts.data',{}),
    ptcontactsProcessing: safe(state,'emr.ptcontacts.processing'),
    ptcontactsSuccess: safe(state,'emr.ptcontacts.success'),
    currentInbox: safe(state,'emr.inbox.current'),
    threadCreate: safe(state,'emr.threadCreate.data',{}),
    threadCreateSuccess: safe(state,'emr.threadCreate.success')
  }
};

export default connect(mapStateToProps,actions)(NewThread);
