import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, DatePicker, Input } from 'antd';
import moment from 'moment';

import * as billing_actions from '../../../../actions/billing';
import * as shared_actions from '../../../../actions/shared';
import { safe, getBillingState } from '../../../../helpers/reduxSelectors';

const { Option } = Select;

class RecallEntryModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func
  }

  static defaultProps = {
    visible: false
  }

  onOk = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.props.initialValues) {
          this.props.saveRecallItem(
            this.props.recall_list_selection,
            this.props.recall_status_selection,
            this.props.initialValues.id,
            values,
            this.props.recalllist_page,
            15
          );
        } else {
          this.props.createRecallItem(
            this.props.recall_list_selection,
            this.props.recall_status_selection,
            values,
            this.props.recalllist_page,
            15
          );
        }
        this.props.onClose();
      }
    });
  }

  onChangePatientValue = (value) => {
    this.props.getPatientList(value);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible === true) {
      this.props.form.resetFields();
    }
  }

  onChange = (value, patient) => {
    console.log('onChange', { value, patient });
    this.setState({ patient });
  }

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };

    const initialValues = safe(this.props,'initialValues',{});
    
    return (
      <Modal
        title='Recall List Item'
        visible={this.props.visible}
        onCancel={this.props.onClose}
        onOk={this.onOk}
        okText='Save'
      >
        <Form className='recall-entry-modal' autoComplete='off'>
          <FormItem {...formItemLayout} label="Title">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: " ", whitespace: true }],
              initialValue: initialValues.title
            })(
              <Input.TextArea rows={3} />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="List">
            {getFieldDecorator('list_id', {
              rules: [{ required: true, message: " " }],
              initialValue: initialValues.list_id
            })(
              <Select>
                { this.props.listData.map(z=><Option key={z.id} value={z.id}>{z.name}</Option>) }
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Recall Date">
            {getFieldDecorator('recall_date', {
              rules: [{ required: true, message: " " }],
              initialValue: (initialValues.recall_date) ? moment(initialValues.recall_date) : moment().add(7, 'days')
            })(
              <DatePicker format='M/DD/YYYY' />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Patient">
            {getFieldDecorator('billing_patient_id', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(initialValues,'billing_patient.id','').toString(),
            })(
              <Select
                allowClear
                showSearch
                filterOption={false}
                onSearch={this.onChangePatientValue}
                defaultActiveFirstOption={false}
                notFoundContent={null}
              >
                {this.props.patientList.map((item, key) => {
                  return <Option key={key} value={item.id}>{item.full_name}</Option>;
                })}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Status">
            {getFieldDecorator('status', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(initialValues,'status',1)
            })(
              <Select>
                <Option value={1}>Open</Option>
                <Option value={2}>Closed</Option>
              </Select>
            )}
          </FormItem>

        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    listData: safe(state,'billing.recall.lists_list.data',[]),
    patientList: safe(state,'shared.patient.list.data',[]),
    recall_list_selection: getBillingState(state,'recall_list_selection',0),
    recall_status_selection: getBillingState(state,'recall_status_selection',1),
    recalllist_page: safe(state,'shared.domain.billing.recalllist_page',1)
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(Form.create()(RecallEntryModal));
