import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Select, message, Divider, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/emr';
import { Parser as HtmlToReactParser } from 'html-to-react';

class Letters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      letter_id: null,
    };
  }

  componentDidMount() {
    this.props.getTemplateLetterList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        message.success('New letter template created');
        this.onChangeLetter(safe(this.props, 'create.data.id'));
      }
      if (this.props.create.error) {
        message.error('Unable to create a new letter template');
      }
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.success === true) {
        message.success('Letter template saved');
      }
      if (this.props.save.saveSuccess === false) {
        message.error('Unable to save letter template');
      }
    }
  }

  renderFieldDescriptions = () => {
    return (
      <div style={{ paddingTop: '20px' }}>
        <Divider />
        <table align="center">
          <tbody>
            <tr>
              <td>|*header*|</td>
              <td>Displays the PCPA logo and letter running head</td>
            </tr>
            <tr>
              <td>|*current_date*|</td>
              <td>Display the current date</td>
            </tr>
            <tr>
              <td>|*current_time*|</td>
              <td>Display the current time</td>
            </tr>
            <tr>
              <td>|*patient_firstname*|</td>
              <td>Display the selected patients first name</td>
            </tr>
            <tr>
              <td>|*patient_fullname*|</td>
              <td>Display the selected patients full name</td>
            </tr>
            <tr>
              <td>|*patient_dob*|</td>
              <td>Display the selected patients date of birth</td>
            </tr>
            <tr>
              <td>|*patient_address*|</td>
              <td>Display the selected patients street address</td>
            </tr>
            <tr>
              <td>|*patient_city*|</td>
              <td>Display the selected patients city</td>
            </tr>
            <tr>
              <td>|*patient_state*|</td>
              <td>Display the selected patients state</td>
            </tr>
            <tr>
              <td>|*patient_zip*|</td>
              <td>Display the selected patients zip code</td>
            </tr>
            <tr>
              <td>|*provider_fullname*|</td>
              <td>Display the providers full name</td>
            </tr>
            <tr>
              <td>|*provider_firstname*|</td>
              <td>Display the providers first name</td>
            </tr>
            <tr>
              <td>|*provider_lastname*|</td>
              <td>Display the providers last name</td>
            </tr>
            <tr>
              <td>|*provider_license*|</td>
              <td>Display the providers license #</td>
            </tr>
            <tr>
              <td>|*provider_license_state*|</td>
              <td>Display the state of licensure</td>
            </tr>
            <tr>
              <td>|*org_address*|</td>
              <td>Display PCPAs administrative street address</td>
            </tr>
            <tr>
              <td>|*org_city*|</td>
              <td>Display PCPAs administrative city</td>
            </tr>
            <tr>
              <td>|*org_state*|</td>
              <td>Display PCPAs administrative state</td>
            </tr>
            <tr>
              <td>|*org_zip*|</td>
              <td>Display PCPAs administrative zip code</td>
            </tr>
            <tr>
              <td>|*org_phone*|</td>
              <td>Display PCPAs administrative phone</td>
            </tr>
            <tr>
              <td>|*org_fax*|</td>
              <td>Display PCPAs administrative FAX</td>
            </tr>
            <tr>
              <td>|*primary_diagnosis*|</td>
              <td>Display patient's primary parity diagnosis (ICD Code)</td>
            </tr>
            <tr>
              <td>|*primary_diagnosis_description*|</td>
              <td>Display patient's primary parity diagnosis (description)</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  onClickSave = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var htmlToReactParser = new HtmlToReactParser();
        try {
          const result = htmlToReactParser.parse(values.letter);
          if (result) {
            this.props.saveTemplateLetter(this.state.letter_id, values);
          } else {
            message.error('The letter you entered is not valid HTML. Please fix and resave.');
          }
        } catch (err) {
          message.error('The letter you entered is not valid HTML. Please fix and resave.');
        }
      }
    });
  };

  onCreateNewTemplate = () => {
    this.props.createNewTemplateLetter({ title: 'New Letter' });
  };

  getOptions = () => {
    const Option = Select.Option;

    return safe(this.props, 'load.data', []).map((x) => {
      return (
        <Option key={x.id} value={x.id}>
          {x.title}
        </Option>
      );
    });
  };

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;

    return (
      <Form style={{ paddingTop: '40px' }}>
        <FormItem label="Letter Title">
          {getFieldDecorator('title', {
            rules: [{ required: true, message: 'Please enter a title for this letter', whitespace: true }],
          })(<Input disabled={this.state.letter_id === null} />)}
        </FormItem>

        <FormItem>
          {getFieldDecorator('is_visible', {
            valuePropName: 'checked',
          })(<Checkbox disabled={this.state.letter_id === null}>This letter is visible to all providers</Checkbox>)}
        </FormItem>

        <FormItem label="Letter Content (HTML)">
          {getFieldDecorator('letter', {
            rules: [{ required: true, message: 'Please enter content for this letter', whitespace: true }],
          })(<TextArea disabled={this.state.letter_id === null} rows={12} autosize={{ minRows: 12, maxRows: 15 }} />)}
        </FormItem>

        <Button type="primary" style={{ float: 'right' }} onClick={this.onClickSave}>
          Save
        </Button>
      </Form>
    );
  };

  onChangeLetter = (value) => {
    this.setState({
      letter_id: value,
    });

    let data = safe(this.props, 'load.data', []).find((x) => x.id === value);
    this.props.form.setFieldsValue({
      title: data.title,
      is_visible: data.is_visible,
      letter: data.letter,
    });
  };

  render() {
    return (
      <div style={{ padding: '10px' }}>
        <h3>Template Letter Configuration</h3>
        <table width="100%">
          <tbody>
            <tr>
              <td>
                <strong>
                  <nobr>Choose Template:</nobr>
                </strong>
              </td>
              <td width="100%">
                <Select value={this.state.letter_id} onChange={this.onChangeLetter} style={{ width: '100%' }}>
                  {this.getOptions()}
                </Select>
              </td>
              <td>
                <Button style={{ marginLeft: '5px' }} onClick={this.onCreateNewTemplate}>
                  Create New Template
                </Button>
              </td>
            </tr>
          </tbody>
        </table>

        {this.renderForm()}

        {this.renderFieldDescriptions()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    load: safe(state, 'emr.templateletter.load', {}),
    save: safe(state, 'emr.templateletter.save', {}),
    create: safe(state, 'emr.templateletter.create', {}),
  };
};

export default connect(mapStateToProps, actions)(Form.create()(Letters));
