import axios from 'axios';
import * as types from './types';

const pmLogout = (dispatch,err) => {
  if (err && err.response && err.response.status === 401) {
    dispatch ({type:'USER_LOGIN_CLEAR'});
  }
}

export const feeScheduleGroupList = () => async dispatch => {
  dispatch ({type: types.PM_FEESCHEDULE_GROUP_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/pm/feeschedule/group');
    dispatch ({type: types.PM_FEESCHEDULE_GROUP_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_FEESCHEDULE_GROUP_LIST_FAILURE, error: err.response});
  }
}

export const feeScheduleList = (group_id) => async dispatch => {
  dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/pm/feeschedule/group/'+group_id);
    dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_LIST_FAILURE, error: err.response});
  }
}

export const feeScheduleLoad = (schedule_id) => async dispatch => {
  dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_LOAD_REQUEST});
  try {
    const res = await axios.get('/api/v1/pm/feeschedule/schedule/'+schedule_id);
    dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_LOAD_SUCCESS, payload: res.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_LOAD_FAILURE, error: err.response});
  }
}

export const feeScheduleGroupCreate = (data) => async dispatch => {
  dispatch ({type: types.PM_FEESCHEDULE_GROUP_CREATE_REQUEST});
  try {
    const res = await axios.post('/api/v1/pm/feeschedule/group',data);
    dispatch ({type: types.PM_FEESCHEDULE_GROUP_CREATE_SUCCESS, payload: res.data});
    dispatch ({type: types.PM_FEESCHEDULE_GROUP_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_FEESCHEDULE_GROUP_CREATE_FAILURE, error: err.response});
  }
}

export const feeScheduleCreate = (data) => async dispatch => {
  dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_CREATE_REQUEST});
  try {
    const res = await axios.post('/api/v1/pm/feeschedule/schedule',data);
    dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_CREATE_SUCCESS, payload: res.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_CREATE_FAILURE, error: err.response});
  }
}

export const feeScheduleGroupUpdate = (group_id,data) => async dispatch => {
  dispatch ({type: types.PM_FEESCHEDULE_GROUP_UPDATE_REQUEST});
  try {
    const res = await axios.put('/api/v1/pm/feeschedule/group/'+group_id,data);
    dispatch ({type: types.PM_FEESCHEDULE_GROUP_UPDATE_SUCCESS, payload: res.data});
    dispatch ({type: types.PM_FEESCHEDULE_GROUP_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_FEESCHEDULE_GROUP_UPDATE_FAILURE, error: err.response});
  }
}

export const feeScheduleUpdate = (schedule_id,data) => async dispatch => {
  dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_UPDATE_REQUEST});
  try {
    const res = await axios.put('/api/v1/pm/feeschedule/schedule/'+schedule_id,data);
    dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_UPDATE_SUCCESS, payload: res.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_UPDATE_FAILURE, error: err.response});
  }
}

export const feeScheduleGroupRemove = (group_id) => async dispatch => {
  dispatch ({type: types.PM_FEESCHEDULE_GROUP_REMOVE_REQUEST});
  try {
    const res = await axios.delete('/api/v1/pm/feeschedule/group/'+group_id);
    dispatch ({type: types.PM_FEESCHEDULE_GROUP_REMOVE_SUCCESS, payload: res.data});
    dispatch ({type: types.PM_FEESCHEDULE_GROUP_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_FEESCHEDULE_GROUP_REMOVE_FAILURE, error: err.response});
  }
}

export const feeScheduleRemove = (schedule_id) => async dispatch => {
  dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_REMOVE_REQUEST});
  try {
    const res = await axios.delete('/api/v1/pm/feeschedule/schedule/'+schedule_id);
    dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_REMOVE_SUCCESS, payload: res.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_FEESCHEDULE_SCHEDULE_REMOVE_FAILURE, error: err.response});
  }
}

export const feeScheduleGroupListClear = () => async dispatch => {
  dispatch ({type: types.PM_FEESCHEDULE_GROUP_LIST_CLEAR});
}

// Charge Codes calls

export const getCodeList = () => async dispatch => {
  dispatch ({type: types.PM_CODES_LIST_REQUEST});
  try {
    const res = await axios.get('/api/v1/pm/codes');
    dispatch ({type: types.PM_CODES_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_CODES_LIST_FAILURE, error: err.response.data});
  }
}

export const codeListClear = () => async dispatch => {
  dispatch ({type: types.PM_CODES_LIST_CLEAR});
}

export const codeDeactivate = (id) => async dispatch => {
  dispatch ({type: types.PM_CODES_DEACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/pm/codes/'+id+'/deactivate');
    dispatch ({type: types.PM_CODES_DEACTIVATE_SUCCESS});
    dispatch ({type: types.PM_CODES_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_CODES_DEACTIVATE_FAILURE, error: err.response.data});
  }
};

export const codeDeactivateClear = () => async dispatch => {
  dispatch ({type: types.PM_CODES_DEACTIVATE_CLEAR});
}

export const codeReactivate = (id) => async dispatch => {
  dispatch ({type: types.PM_CODES_REACTIVATE_REQUEST});
  try {
    const res = await axios.get('/api/v1/pm/codes/'+id+'/reactivate');
    dispatch ({type: types.PM_CODES_REACTIVATE_SUCCESS});
    dispatch ({type: types.PM_CODES_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_CODES_REACTIVATE_FAILURE, error: err.response.data});
  }
};

export const codeReactivateClear = () => async dispatch => {
  dispatch ({type: types.PM_CODES_REACTIVATE_CLEAR});
}

export const codeLoad = (id) => async dispatch => {
  dispatch ({type: types.PM_CODES_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/pm/codes/'+id);
    dispatch ({type: types.PM_CODES_LOAD_SUCCESS, payload: resp.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_CODES_LOAD_FAILURE, error: err.response.data});
  }
}

export const codeLoadClear = () => async dispatch => {
  dispatch ({type: types.PM_CODES_LOAD_CLEAR});
}

export const codeSave = (id,data) => async dispatch => {
  dispatch ({type: types.PM_CODES_SAVE_REQUEST});
  try {
    await axios.put('/api/v1/pm/codes/'+id,data);
    dispatch ({type: types.PM_CODES_SAVE_SUCCESS});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_CODES_SAVE_FAILURE, error: err.response.data});
  }
}

export const codeSaveClear = () => async dispatch => {
  dispatch ({type: types.PM_CODES_SAVE_CLEAR});
}

export const codeCreate = (data) => async dispatch => {
  dispatch ({type: types.PM_CODES_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/pm/codes',data);
    dispatch ({type: types.PM_CODES_CREATE_SUCCESS, payload: resp.data});
  } catch (err) {
    pmLogout(dispatch,err);
    dispatch ({type: types.PM_CODES_CREATE_FAILURE, error: err.response.data});
  }
}

export const codeCreateClear = () => async dispatch => {
  dispatch ({type: types.PM_CODES_CREATE_CLEAR});
}
