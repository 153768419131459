import { Dispatch, SetStateAction } from 'react';
import { Checkbox } from 'antd';

interface PropsI {
  child: boolean;
  setChild: Dispatch<SetStateAction<boolean>>;
  adult: boolean;
  setAdult: Dispatch<SetStateAction<boolean>>;
};

const PickAdultChild: React.FC<PropsI> = (props: PropsI) => {
  const { child, setChild, adult, setAdult } = props;

  const onChangeAdult = (e) => {
    setAdult(Boolean(e.target.checked));
  };

  const onChangeChild = (e) => {
    setChild(Boolean(e.target.checked));
  };

  return (
    <>
      <Checkbox checked={adult} onChange={onChangeAdult}>Adult</Checkbox>
      <Checkbox checked={child} onChange={onChangeChild}>Child</Checkbox>
    </>
  );
};

export default PickAdultChild;
