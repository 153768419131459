import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Modal } from 'antd';

import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/settings';

class ReassignDialog extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    transferGroupId: PropTypes.number,
    transferFromUserId: PropTypes.number,
    transferFromUserName: PropTypes.string
  }

  onCancel = () => {
    this.props.closeDialog();
  }

  onSave = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.reassignAdminSupport(this.props.transferGroupId,
          this.props.transferFromUserId,values.percentage,values.assign_to_id);
        this.props.closeDialog();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible === true) {
      this.props.form.resetFields();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const tgi = parseInt(this.props.transferGroupId,10);

    const title = (tgi === 1) ? 'Reception' : 'Billing';
    const list = (tgi === 1) ? this.props.receptionList : this.props.billingList;

    const userList = list
      .filter(x=>x.user_id && x.user_id !== this.props.transferFromUserId && x.group_member_accept_assignment)
      .map(y=>{
        return {
          id: y.user_id,
          name: y.name
        }
      }).sort((a,b)=>{
        return a.name.localeCompare(b.name);
      });

    return (
      <Modal
        title={title + ': Reassign Patients'}
        visible={this.props.visible}
        onOk={this.onSave}
        okText='Save'
        onCancel={this.onCancel}
      >
        <Form>

          <Form.Item label="Active Patients From">
            {(this.props.transferFromUserName) ? this.props.transferFromUserName : 'UNASSIGNED'}
          </Form.Item>

          <Form.Item label="Number of Patients">
            {getFieldDecorator('percentage', {
              rules: [{ required: true, message: "" }],
              initialValue: 10
            })(
              <Select>
                <Select.Option value={1}>10%</Select.Option>
                <Select.Option value={2}>20%</Select.Option>
                <Select.Option value={3}>30%</Select.Option>
                <Select.Option value={4}>40%</Select.Option>
                <Select.Option value={5}>50%</Select.Option>
                <Select.Option value={6}>60%</Select.Option>
                <Select.Option value={7}>70%</Select.Option>
                <Select.Option value={8}>80%</Select.Option>
                <Select.Option value={9}>90%</Select.Option>
                <Select.Option value={10}>100%</Select.Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Assign To">
            {getFieldDecorator('assign_to_id', {
              rules: [{ required: true, message: "Please choose a group member" }],
            })(
              <Select>
                { userList.map(x=><Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>) }
              </Select>
            )}
          </Form.Item>

        </Form>

      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    receptionList: safe(state,'settings.groups.info.data.reception',[]),
    billingList: safe(state,'settings.groups.info.data.billing',[]),
  }
}

export default connect(mapStateToProps,actions)(Form.create()(ReassignDialog));
