import axios from 'axios';
import * as types from './types';
import qs from 'qs';

const intakesLogout = (dispatch,err) => {
  if (err && err.response && err.response.status === 401) {
    dispatch ({type:'USER_LOGIN_CLEAR'});
  }
}

export const bulkCreatePatients = (data) => async dispatch => {
  dispatch ({type:types.PATIENT_BULKCREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/patient/bulk/create',data);
    dispatch ({type:types.PATIENT_BULKCREATE_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.PATIENT_BULKCREATE_FAILURE, error: err.response});
  }
}

export const getLinkedAccounts = (patient_id) => async dispatch => {
  dispatch ({type:types.LINKED_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/accounts/'+patient_id);
    dispatch ({type:types.LINKED_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.LINKED_LIST_FAILURE, error: err.response});
  }
}

export const clearLinkedAccounts = () => dispatch => {
  dispatch ({type:types.LINKED_LIST_CLEAR});
}

export const deleteLinkedAccount = (patient_id,id) => async dispatch => {
  dispatch ({type:types.LINKED_DELETE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/accounts/'+patient_id+'/'+id);
    dispatch ({type:types.LINKED_DELETE_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.LINKED_DELETE_FAILURE, error: err.response});
  }
}

export const addLinkedAccount = (patient_id,id) => async dispatch => {
  dispatch ({type:types.LINKED_ADD_REQUEST});
  try {
    const resp = await axios.post('/api/v1/accounts/'+patient_id+'/'+id);
    dispatch ({type:types.LINKED_ADD_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.LINKED_ADD_FAILURE, error: err.response});
  }
}

export const portalAccountSearch = (query) => async dispatch => {
  dispatch ({type:types.PACCOUNT_SEARCH_REQUEST});
  try {
    const q = qs.stringify({query:query});
    const resp = await axios.get('/api/v1/portal?'+q);
    dispatch ({type:types.PACCOUNT_SEARCH_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.PACCOUNT_SEARCH_FAILURE, error: err.response});
  }
}

export const portalAccountSearchClear = () => dispatch => {
  dispatch ({type:types.PACCOUNT_SEARCH_CLEAR});
}

export const portalAccountLoad = (id) => async dispatch => {
  dispatch ({type:types.PACCOUNT_LOAD_REQUEST});
  try {
    const resp = await axios.get('/api/v1/portal/'+id);
    dispatch ({type:types.PACCOUNT_LOAD_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.PACCOUNT_LOAD_FAILURE, error: err.response});
  }
}

export const setPortalFeatureFlag = (portal_id,value) => async dispatch => {
  dispatch ({type:types.PACCOUNT_FEATUREFLAG_SET_REQUEST});
  try {
    const resp = await axios.get('/api/v1/portal/'+portal_id+'/featureflag/'+value);
    dispatch ({type:types.PACCOUNT_FEATUREFLAG_SET_SUCCESS, payload: resp.data });
    dispatch ({type:types.PACCOUNT_LINKEDLIST_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.PACCOUNT_FEATUREFLAG_SET_FAILURE, error: err.response});
  }
}

export const getPatientsLinkedToPortal = (portal_id) => async dispatch => {
  dispatch ({type:types.PACCOUNT_LINKEDLIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/portal/'+portal_id + '/links');
    dispatch ({type:types.PACCOUNT_LINKEDLIST_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.PACCOUNT_LINKEDLIST_FAILURE, error: err.response});
  }
}

export const portalAccountAddLink = (patient_id,portal_id,relation) => async dispatch => {
  dispatch ({type:types.PACCOUNT_ADDLINK_REQUEST});
  try {
    const resp = await axios.get('/api/v1/portal/'+portal_id + '/links/'+patient_id+'/'+relation);
    dispatch ({type:types.PACCOUNT_ADDLINK_SUCCESS, payload: resp.data });
    dispatch ({type:types.PACCOUNT_LINKEDLIST_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.PACCOUNT_ADDLINK_FAILURE, error: err.response});
  }
}

export const portalAccountDeleteLink = (portal_id,link_id) => async dispatch => {
  dispatch ({type:types.PACCOUNT_DELETELINK_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/portal/'+portal_id+'/links/'+link_id);
    dispatch ({type:types.PACCOUNT_DELETELINK_SUCCESS, payload: resp.data });
    dispatch ({type:types.PACCOUNT_LINKEDLIST_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.PACCOUNT_DELETELINK_FAILURE, error: err.response});
  }
}

export const portalAccountDelete = (portal_id) => async dispatch => {
  dispatch ({type:types.PACCOUNT_DELETE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/portal/'+portal_id);
    dispatch ({type:types.PACCOUNT_DELETE_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.PACCOUNT_DELETE_FAILURE, error: err.response});
  }
}

export const portalAccountUpdate = (id,data) => async dispatch => {
  dispatch ({type:types.PACCOUNT_UPDATE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/portal/'+id,data);
    dispatch ({type:types.PACCOUNT_UPDATE_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.PACCOUNT_UPDATE_FAILURE, error: err.response});
  }
}

export const getRegistrationList = () => async dispatch => {
  dispatch ({type:types.REGISTRATION_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/registrations');
    dispatch ({type:types.REGISTRATION_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.REGISTRATION_LIST_FAILURE, error: err.response});
  }
}

export const registrationVerify = (id,page,page_size) => async dispatch => {
  dispatch ({type:types.REGISTRATION_VERIFY_REQUEST});
  try {
    const q = qs.stringify({
      page: page,
      pagesize: page_size,
    });
    const resp = await axios.get('/api/v1/registrations/'+id+'?'+q);
    dispatch ({type:types.REGISTRATION_VERIFY_SUCCESS, payload: resp.data });
    dispatch ({type:types.REGISTRATION_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    intakesLogout(dispatch,err);
    dispatch ({type:types.REGISTRATION_VERIFY_FAILURE, error: err.response});
  }
}
