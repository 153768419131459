import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Button, Row, Col, message, Spin, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions_shared from '../../../actions/shared';
import * as actions_billing from '../../../actions/pm';

import { safe } from '../../../helpers/reduxSelectors';
import { usStateAbbrOptions } from '../../../helpers/states';

class CarriersEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      carrier_id: parseInt(this.props.match.params.id,10)
    }
  }

  componentDidMount() {
    this.props.feeScheduleGroupList();
    if (this.state.carrier_id !== 0) {
      this.props.carrierLoad(this.state.carrier_id);
    } else {
      this.props.carrierLoadClear();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load !== this.props.load) {
      if (this.props.load.error) {
        this.props.history.push('/settings/carriers');
        message.error('Unable to load carrier information');
      }
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.success) {
        this.props.carrierSaveClear();
        this.props.history.push('/settings/carriers');
        message.success('Carrier saved');
      } else if (this.props.error) {
        message.error('Unable to save carrier');
      }
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        this.props.carrierCreateClear();
        this.props.history.push('/settings/carriers');
        message.success('Carrier created');
      } else if (this.props.error) {
        message.error('Unable to save carrier');
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.carrier_id === 0) {
          this.props.carrierCreate(values);
        } else {
          this.props.carrierSave(this.state.carrier_id,values);
        }
      }
    });
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const TextArea = Input.TextArea;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };

    const data = safe(this.props,'load.data',{});
    const fs = safe(this.props,'feeschedulesList',[]);

    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off" className='carriers-form'>
        <FormItem {...formItemLayout} label="Carrier Name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.name
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Abbreviation">
          {getFieldDecorator('abbr', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.abbr
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Payer ID"
        >
          {getFieldDecorator('payerid', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.payerid
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Self Pay"
        >
          {getFieldDecorator('selfpay', {
            valuePropName: 'checked',
            initialValue: data.selfpay
          })(
            <Checkbox autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Address 1"
        >
          {getFieldDecorator('address1', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.address1
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Address 2"
        >
          {getFieldDecorator('address2', {
            initialValue: data.address2
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="City"
        >
          {getFieldDecorator('city', {
            rules: [{
              required: true, message: ' ',
            }],
            initialValue: data.city
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="State">
          {getFieldDecorator('state', {
            rules: [{required: true, message: ' '}],
            initialValue: (data.state || 'CA'),
          })(
            <Select autoComplete="off">
              {usStateAbbrOptions}
            </Select>
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Zip">
          {getFieldDecorator('zip', {
            rules: [{
              required: true, message: ' ',
            }],
            initialValue: data.zip
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Allowable Fee Schedule">
          {getFieldDecorator('allowable_feeschedule_group_id', {
            rules: [{required: true, message: ' '}],
            initialValue: data.allowable_feeschedule_group_id,
          })(
            <Select autoComplete="off">
              { fs.map(x=><Option value={x.id}>{x.name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Charge Fee Schedule">
          {getFieldDecorator('charge_feeschedule_group_id', {
            rules: [{required: true, message: ' '}],
            initialValue: data.charge_feeschedule_group_id,
          })(
            <Select autoComplete="off">
              { fs.map(x=><Option value={x.id}>{x.name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Notes'>
          {getFieldDecorator('notes', {
            initialValue: data.notes
          })(
            <TextArea rows={4}/>
          )}
        </FormItem>

        <Row style={{paddingTop:'20px'}}>
          <Col xs={36} sm={16}>
            <Button
              htmlType="submit"
              style={{float:'right'}}
              disabled={this.props.isBusy}
              type="primary">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    return (
      <div style={{padding:'10px'}}>
        <h3>{(this.state.carrier_id === 0) ? 'Add Carrier' : 'Edit Carrier'}</h3>
        <Spin spinning={this.props.isBusy} size="large">
          { this.renderForm() }
        </Spin>
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    load: safe(state,'shared.carrier.load'),
    save: safe(state,'shared.carrier.save'),
    create: safe(state,'shared.carrier.create'),
    isBusy: (
      safe(state,'shared.carrier.load.processing') ||
      safe(state,'shared.carrier.save.processing') ||
      safe(state,'shared.carrier.create.processing') ||
      safe(state,'pm.feeschedule.group.list.processing')
    ) ? true : false,
    feeschedulesList: safe(state,'pm.feeschedule.group.list.data',[]),
  }
}

export default connect(mapStateToProps,{...actions_shared,...actions_billing})(Form.create()(withRouter(CarriersEdit)));
