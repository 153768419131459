import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';

//import { filterNotes } from '../../../../../helpers/attachments';

import GeneralChartNote from './ChartNotes_/GeneralChartNote';
import LabsChartNote from './ChartNotes_/LabsChartNote';
import PrescriberProgressNote from './ChartNotes_/PrescriberProgressNote';
import TemplateLetterChartNote from './ChartNotes_/TemplateLetterChartNote';
import TherapistProgressNote from './ChartNotes_/TherapistProgressNote';
import AdminTimeNote from './ChartNotes_/AdminTimeNote';
import HFNote from './ChartNotes_/HFNote';
import UnknownChartNote from './ChartNotes_/UnknownChartNote';
import IntakeNote from './ChartNotes_/IntakeNote';
import BriefView from './ChartNotes_/BriefView';
import ConsentsNote from './ChartNotes_/ConsentsNote';

import * as actions from '../../../../../actions/emr';
import { safe } from '../../../../../helpers/reduxSelectors';

class ChartNotes extends Component {
  static defaultProps = {
    print: false
  };

  static propTypes = {
    noteList: PropTypes.array.isRequired,
    userId: PropTypes.number.isRequired,
    print: PropTypes.bool
  };

  renderNoteContent = (x) => {
    switch (x.type) {
      case 1: return <TherapistProgressNote data={x} userId={this.props.userId} print={this.props.print}/>;
      case 2: return <PrescriberProgressNote data={x} userId={this.props.userId} print={this.props.print}/>;
      case 3: return <GeneralChartNote data={x} userId={this.props.userId} print={this.props.print}/>;
      case 4: return <AdminTimeNote data={x} userId={this.props.userId} print={this.props.print}/>;
      case 5: return <LabsChartNote data={x} print={this.props.print} />;
      case 6: return <TemplateLetterChartNote data={x} print={this.props.print}/>;
      case 7: return <HFNote data={x} print={this.props.print} />
      case 8: return <IntakeNote data={x} print={this.props.print} />
      case 9: return <ConsentsNote data={x} print={this.props.print} />
      default: return <UnknownChartNote data={x} print={this.props.print}/>;
    }
  }

  renderIndividualNote = (x) => {
    return (
      <div key={x.id} className='chartnote'>
        <div className='chartnote_datecolumn'>
          <div className='chartnote_dateformat'>
            {moment(x.note_date).format('M/D/YYYY')}
          </div>
        </div>
        <div className='chartnote_notecolumn'>
          {this.renderNoteContent(x)}
        </div>
      </div>
    );
  }

  changeFilter = (value) => {
    this.props.setEmrState({
      chartFilterNotes: value
    });
  }

  renderFilterControls = () => {
    if (this.props.print) {
      return null;
    }

    return (
      <div style={{paddingBottom:'25px',paddingTop:'0px'}}>
        <Select value={this.props.chartFilterNotes} style={{width:'300px',marginRight:'10px'}} onChange={this.changeFilter}>
          <Select.Option value={1}>All Notes</Select.Option>
          <Select.Option value={2}>Only Notes with Attachments</Select.Option>
        </Select>
      </div>
    )
  }

  render() {
  //  const hasAttachments = (this.props.chartFilterNotes === 2) ? true : false;
    const list = this.props.noteList;
    //const list = filterNotes(this.props.noteList,hasAttachments);

    if (list.length === 0) {
      if (this.props.noteListProcessing) {
        return null;
      }

      return (
        <div style={{padding:'15px'}}>
          { this.renderFilterControls() }
          No Notes
        </div>
      );
    } else {
      return (
        <div style={{padding:'15px'}}>
          { this.renderFilterControls() }
          { list.map(x => (
            <BriefView key={x.id} brief={true} print={this.props.print}>
              { this.renderIndividualNote(x) }
            </BriefView>
          )) }
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    chartBriefView: safe(state,'emr.emr.chartBriefView',false),
    chartFilterNotes: safe(state,'emr.emr.chartFilterNotes',1)
  }
}

export default connect(mapStateToProps,actions)(withRouter(ChartNotes));
