import { combineReducers } from 'redux';
import { ajaxReducer, ajaxReducerPreserveState } from '../../helpers/util';
import settingsReducer from './settingsReducer';

export default combineReducers({
  template: combineReducers({
    list: ajaxReducer('SCHEDULER_TEMPLATE_LIST'),
    get: ajaxReducer('SCHEDULER_TEMPLATE_GET'),
    create: ajaxReducer('SCHEDULER_TEMPLATE_CREATE'),
    update: ajaxReducer('SCHEDULER_TEMPLATE_UPDATE'),
    remove: ajaxReducer('SCHEDULER_TEMPLATE_REMOVE'),
    copy: ajaxReducer('SCHEDULER_TEMPLATE_COPY'),
  }),
  template_item: combineReducers({
    create: ajaxReducer('SCHEDULER_TEMPLATEITEM_CREATE'),
    update: ajaxReducer('SCHEDULER_TEMPLATEITEM_UPDATE'),
    remove: ajaxReducer('SCHEDULER_TEMPLATEITEM_REMOVE'),
  }),
  provider: combineReducers({
    list: ajaxReducerPreserveState('SCHEDULER_PROVIDER_LIST'),
    get: ajaxReducer('SCHEDULER_PROVIDER_GET'),
    create: ajaxReducer('SCHEDULER_PROVIDER_CREATE'),
    update: ajaxReducer('SCHEDULER_PROVIDER_UPDATE'),
    remove: ajaxReducer('SCHEDULER_PROVIDER_REMOVE'),
    locations: ajaxReducer('SCHEDULER_PROVIDER_LOCATIONS'),
    productivity: ajaxReducer('SCHEDULER_PROVIDER_PRODUCTIVITY'),
    coverage: ajaxReducer('SCHEDULER_PROVIDER_COVERAGE')
  }),
  provider_info: combineReducers({
    get: ajaxReducer('SCHEDULER_PROVIDERINFO_GET'),
    save: ajaxReducer('SCHEDULER_PROVIDERINFO_SAVE'),
  }),
  session: combineReducers({
    create: ajaxReducer('SCHEDULER_SESSION_CREATE'),
    update: ajaxReducer('SCHEDULER_SESSION_UPDATE'),
    remove: ajaxReducer('SCHEDULER_SESSION_REMOVE'),
  }),
  calendar: combineReducers({
    list: ajaxReducer('SCHEDULER_CALENDAR_LIST'),
    search: ajaxReducer('SCHEDULER_CALENDAR_SEARCH'),
  }),
  timeoff: combineReducers({
    list: ajaxReducer('SCHEDULER_TIMEOFF_LIST'),
    update: ajaxReducer('SCHEDULER_TIMEOFF_UPDATE'),
    reject: ajaxReducer('SCHEDULER_TIMEOFF_REJECT'),
    approve: ajaxReducer('SCHEDULER_TIMEOFF_APPROVE'),
    cancel: ajaxReducer('SCHEDULER_TIMEOFF_CANCEL'),
    create: ajaxReducer('SCHEDULER_TIMEOFF_CREATE'),
    stats: ajaxReducer('SCHEDULER_TIMEOFF_STATS')
  }),
  history: combineReducers({
    list: ajaxReducer('SCHEDULER_HISTORY_LIST')
  }),
  productivity: ajaxReducer('SCHEDULER_PRODUCTIVITY'),
  intakes: ajaxReducer('SCHEDULER_INTAKES_UPDATE'),
  provider_intakes: combineReducers({
    removeItem: ajaxReducer('SCHEDULER_INTAKES_DELETEITEM'),
    newitem: ajaxReducer('SCHEDULER_INTAKES_CREATEITEM'),
    setavail: ajaxReducer('SCHEDULER_INTAKES_SETAVAIL'),
  }),
  settings: settingsReducer,
});
