import { notification } from 'antd';
import { safe } from './reduxSelectors';

export function ajaxReducer(name,initialState={}) {
  return function(state = initialState,action) {
    switch (action.type) {
      case (name+'_REQUEST'):return {processing:true}
      case (name+'_SUCCESS'):return {processing:false,success:true,data:action.payload}
      case (name+'_FAILURE'):return {processing:false,success:false,error:action.error}
      case (name+'_CLEAR'):return {}
      default: return state;
    }
  }
}

export function ajaxReducerPreserveState(name,initialState={}) {
  return function(state = initialState,action) {
    switch (action.type) {
      case (name+'_REQUEST'):return {...state,processing:true}
      case (name+'_SUCCESS'):return {processing:false,success:true,data:action.payload}
      case (name+'_FAILURE'):return {...state,processing:false,success:false,error:action.error}
      case (name+'_CLEAR'):return {}
      default: return state;
    }
  }
}

export function globalStateReducer(name,initialState=null) {
  return function (state = initialState,action) {
    switch (action.type) {
      case (name): return action.payload
      default: return state;
    }
  }
}

export function processEventNotification(prevProps,currentProps,eventName,successMessage,failureMessage) {
  if (prevProps[eventName] !== currentProps[eventName]) {
    if (currentProps[eventName].success) {
      notification['success']({
        message: 'Success',
        description: successMessage
      });
    } else if (currentProps[eventName].error) {
      let desc = (failureMessage) ? failureMessage : safe(currentProps[eventName].error,'data.message')
      const detail = (failureMessage) ? '' : safe(currentProps[eventName].error,'data.detail');
      if (detail) {
        desc = desc + '. ' + detail;
      }
      notification['error']({
        message: 'Error',
        description: desc
      });
    }
  }
}

export function processEventNotification2(prevProps,currentProps,eventName,successMessage,placement='topRight',failureMessage) {
  if (prevProps[eventName] !== currentProps[eventName]) {
    if (currentProps[eventName].success) {
      notification['success']({
        message: 'Success',
        description: successMessage,
        placement: placement
      });
    } else if (currentProps[eventName].error) {
      const desc = (failureMessage) ? failureMessage : safe(currentProps[eventName].error,'data.message');
      notification['error']({
        message: 'Error',
        description: desc,
        placement: placement
      });
    }
  }
}

export function handleAjaxEvents(a,b,onLoad,onError) {
  if (a && b) {
    if (b.success !== a.success && b.success && typeof onLoad === 'function') {
      onLoad();
    } else if (b.error !== a.error && b.error && typeof onError === 'function') {
      onError();
    }
  }
}
