/**
 * QueueProviderWrapper.tsx
 * Provides queue report API / form / state functionality on context
 */
import { useState } from 'react';
import QueueContext, { defaultState } from 'components/App_/ReportsV2_/QueueContext';
import { Form, notification } from 'antd';
import useApiPost from 'hooks/useApiPost';

interface PropsI {
  children: React.ReactNode;
}

const StateProviderWrapper: React.FC<PropsI> = (props: PropsI): any => {
  const [baseState, setState] = useState(defaultState);
  const [form] = Form.useForm();
  const queueReportAPI = useApiPost('/api/v2/reports/queue');

  const doQueueReport = async () => {
    if (!queueReportAPI?.loading) {
      const validatedData = await form.validateFields();
      if (validatedData) {
        const response = await queueReportAPI?.send(validatedData);
        setState((s) => ({ ...s, isModalVisible: false }));
        if (response?.error) {
          notification.error({
            message: 'Report Queue Problem',
            description: response?.message,
          });
        }
        notification.success({
          message: 'Report Queued Successfully',
          description: 'Your report has been scheduled!',
        });
      }
    }
  };

  const state = {
    ...baseState,
    form,
    queueReportAPI,
  };

  const context = {
    state,
    setState,
    doQueueReport,
  };

  return <QueueContext.Provider value={context}>{props.children}</QueueContext.Provider>;
};

export default StateProviderWrapper;
