import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, DatePicker, Select, Checkbox, Spin } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import { safe } from '../../../../helpers/reduxSelectors';
import * as emr_actions from '../../../../actions/emr';
import * as shared_actions from '../../../../actions/shared';

class VacationCoverage extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true) {
      this.props.form.resetFields();
      this.onModalVisible();
    }

    if (prevProps.save_coverage !== this.props.save_coverage && this.props.save_coverage.success) {
      this.props.loginUser(); // Reloads the user info including the coverage information for top bar.
    }
  }

  onModalVisible = () => {
    this.props.loadVacationCoverage();
    this.props.getCoverageProviders();
  }

  onClickSave = () => {
    this.props.form.validateFields((err,values) => {
      if (!err) {
        if (values.ooo) {
          this.props.saveVacationCoverage({
            covering_provider_id: values.covering_provider_id,
            covering_start_date: values.covering_dates[0],
            covering_end_date: values.covering_dates[1]
          });
        } else {
          this.props.saveVacationCoverage({
            covering_provider_id: null,
            covering_start_date: null,
            covering_end_date: null
          });
        }
        this.props.closeDialog();
      }
    });
  }

  onClickCancel = () => {
    if (this.props.form.isFieldsTouched(['ooo','covering_provider_id','covering_dates'])) {
      const This = this;
      Modal.confirm({
        title: 'Vacation settings changed',
        content: 'Are you sure you want to leave without saving?',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          This.props.closeDialog();
        }
      });
    } else {
      this.props.closeDialog();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const sd = safe(this.props,'load_coverage.covering_start_date');
    const ed = safe(this.props,'load_coverage.covering_end_date');

    return (
      <Modal
        title='Vacation Coverage'
        visible={this.props.visible}
        okText='Save'
        onOk={this.onClickSave}
        onCancel={this.onClickCancel}
      >
        <Spin spinning={this.props.isBusy}>
          <Form>
            <Form.Item>
              {getFieldDecorator('ooo',{
                valuePropName: 'checked',
                initialValue: (safe(this.props,'load_coverage.covering_provider_id',null) === null) ? false : true
              })(
                <Checkbox>Out of the Office</Checkbox>
              )}
            </Form.Item>

            {this.props.form.getFieldValue('ooo') && <Form.Item label='Covering Provider'>
              {getFieldDecorator('covering_provider_id',{
                rules: [{required: true, message: 'Select a covering provider'}],
                initialValue: safe(this.props,'load_coverage.covering_provider_id')
              })(
                <Select>
                  { this.props.provider_list.map(x=><Select.Option value={parseInt(x.id,10)} key={x.id}>{x.full_name}</Select.Option>) }
                </Select>
              )}
            </Form.Item> }

            {this.props.form.getFieldValue('ooo') && <Form.Item label="Coverage Start and End Dates">
              {getFieldDecorator('covering_dates', {
                rules: [{ type: 'array', required: true, message: 'Please select dates' }],
                initialValue: (sd && ed) ? [moment(sd),moment(ed)] : null
              })(
                <DatePicker.RangePicker format='M/D/YYYY'/>
              )}
            </Form.Item> }
          </Form>
        </Spin>
      </Modal>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: false,
    activeUserId: safe(state,'shared.domain.activeUser.id'),
    provider_list: safe(state,'emr.coverage.list.data',[]),
    load_coverage: safe(state,'emr.coverage.load.data',{}),
    save_coverage: safe(state,'emr.coverage.save')
  }
}

export default connect(mapStateToProps,{...emr_actions,...shared_actions})(Form.create()(VacationCoverage));
