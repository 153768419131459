import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, TimePicker, Button, Input, Row, Col, Select, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import * as emr_actions from '../../../../actions/emr';
import * as shared_actions from '../../../../actions/shared';
import { safe } from '../../../../helpers/reduxSelectors';

class LateCancelNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: parseInt(this.props.match.params.patient_id, 10),
    };
  }

  componentDidMount() {
    this.props.getUserList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.note.load !== this.props.note.load) {
      if (this.props.note.load.success) {
      } else if (this.props.note.error) {
        this.props.noteCreateClear();
        message.error('Error loading note');
        this.props.history.push('/emr/patients/' + this.state.patientId);
      }
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        this.props.noteCreateClear();
        this.props.history.push('/emr/patients/' + this.state.patientId);
        message.success('Late cancel note created');
      } else if (this.props.create.error) {
        this.props.noteCreateClear();
        this.props.history.push('/emr/patients/' + this.statePatientId);
        message.error('Unable to create a late cancel note.');
      }
    }
  }

  cancelNote = () => {
    this.props.history.push('/emr/patients/' + this.state.patientId);
  };

  saveNote = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        //        const provider = this.props.userList.find(a => a.id === values.provider_id);
        //        const type = (provider.roles.includes('prescriber')) ? 2 : 1;

        // Set this to a bogus type of 10 which will be switched out on the server side
        // once the user's data are loaded in and turned into type 1 or 2 depending
        // on whether it's a prescriber or therapist progress note
        this.props.noteCreate(this.state.patientId, 10, {
          ...values,
          session_status: 3, // Late Cancel
        });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const Option = Select.Option;
    const defaultNote = ['Patient late cancelled session.', '-' + this.props.activeUserName].join('\n');

    const ul = this.props.userList
      .filter((a) => {
        return a.roles.includes('prescriber') || a.roles.includes('therapist');
      })
      .map((a) => {
        return (
          <Option key={a.id} value={a.id}>
            {a.full_name}
          </Option>
        );
      });
    //        <Link to={"/emr/patients/"+this.state.patientId}>&lt;&lt; Return to patient chart</Link>

    return (
      <div style={{ padding: '15px' }}>
        <h3 style={{ paddingTop: '10px', paddingBottom: '20px' }}>Admin{'->'}Provider Late Cancellation Note</h3>

        <Form layout={'vertical'} autoComplete="off">
          <Row>
            <Col span={8}>
              <FormItem label="Date">
                {getFieldDecorator('note_date', {
                  rules: [
                    { type: 'object', required: true, message: 'Please enter a date for this note', whitespace: true },
                  ],
                  initialValue: moment(new Date()),
                })(<DatePicker format="MM/DD/YYYY" />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Time">
                {getFieldDecorator('note_time', {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please enter the time for this note',
                      whitespace: true,
                    },
                  ],
                  initialValue: moment(new Date()),
                })(<TimePicker format="h:mm" minuteStep={5} />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Provider">
                {getFieldDecorator('provider_id', {
                  rules: [{ required: true, message: 'Please enter a provider for this note' }],
                })(
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {ul}
                  </Select>,
                )}
              </FormItem>
            </Col>
          </Row>
          <FormItem label="Late Cancel Note">
            {getFieldDecorator('other_note', {
              rules: [{ required: true, message: 'Enter general note.' }],
              initialValue: defaultNote,
            })(<TextArea rows={6} />)}
          </FormItem>
        </Form>

        <Row>
          <Col span={12}></Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={this.saveNote}>
              Save Note for Provider
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    create: safe(state, 'emr.note.create', {}),
    note: safe(state, 'emr.note', {}),
    activeUserName: safe(state, 'shared.domain.activeUser.full_name', null),
    userList: safe(state, 'shared.user.list.data', []),
    patientData: safe(state, 'shared.patient.load.data', {}),
  };
};

export default connect(mapStateToProps, { ...emr_actions, ...shared_actions })(
  Form.create()(withRouter(LateCancelNote)),
);
