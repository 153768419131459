import { combineReducers } from 'redux';
import { ajaxReducer, ajaxReducerPreserveState } from '../../helpers/util.js'

import domainReducer from './domainReducer';

export default combineReducers({
  auth: ajaxReducerPreserveState('USER_LOGIN'),
  logout: ajaxReducer('USER_LOGOUT'),
  user: combineReducers({
    load: ajaxReducer('USER_LOAD'),
    save: ajaxReducer('USER_SAVE'),
    list: ajaxReducer('USER_LIST'),
    deactivate: ajaxReducer('USER_DEACTIVATE'),
    reactivate: ajaxReducer('USER_REACTIVATE'),
  }),
  carrier: combineReducers({
    load: ajaxReducer('CARRIER_LOAD'),
    save: ajaxReducer('CARRIER_SAVE'),
    create: ajaxReducer('CARRIER_CREATE'),
    list: ajaxReducer('CARRIER_LIST'),
    deactivate: ajaxReducer('CARRIER_DEACTIVATE'),
    reactivate: ajaxReducer('CARRIER_REACTIVATE'),
  }),
  location: combineReducers({
    load: ajaxReducer('LOCATION_LOAD'),
    save: ajaxReducer('LOCATION_SAVE'),
    create: ajaxReducer('LOCATION_CREATE'),
    list: ajaxReducer('LOCATION_LIST'),
    deactivate: ajaxReducer('LOCATION_DEACTIVATE'),
    reactivate: ajaxReducer('LOCATION_REACTIVATE'),
  }),
  billing_location: combineReducers({
    load: ajaxReducer('BILLINGLOCATION_LOAD'),
    save: ajaxReducer('BILLINGLOCATION_SAVE'),
    create: ajaxReducer('BILLINGLOCATION_CREATE'),
    list: ajaxReducer('BILLINGLOCATION_LIST'),
    deactivate: ajaxReducer('BILLINGLOCATION_DEACTIVATE'),
    reactivate: ajaxReducer('BILLINGLOCATION_REACTIVATE'),
  }),
  location_group: combineReducers({
    load: ajaxReducer('LOCATIONGROUP_LOAD'),
    save: ajaxReducer('LOCATIONGROUP_SAVE'),
    create: ajaxReducer('LOCATIONGROUP_CREATE'),
    list: ajaxReducer('LOCATIONGROUP_LIST'),
    remove: ajaxReducer('LOCATIONGROUP_DEACTIVATE'),
  }),
  alert: combineReducers({
    list: ajaxReducer('ALERTS_LIST'),
    save: ajaxReducer('ALERTS_SAVE'),
    delete:ajaxReducer('ALERTS_DELETE'),
  }),
  effectivedate: combineReducers({
    load: ajaxReducer('EFFECTIVEDATES_LOAD'),
    save: ajaxReducer('EFFECTIVEDATES_SAVE'),
    delete: ajaxReducer('EFFECTIVEDATES_DELETE')
  }),
  careteam: combineReducers({
    list: ajaxReducer('CARETEAM_LIST'),
    add: ajaxReducer('CARETEAM_ADD'),
    remove: ajaxReducer('CARETEAM_REMOVE')
  }),
  groups: combineReducers({
    list: ajaxReducer('GROUPS_LIST'),
    load: ajaxReducer('GROUPS_LOAD'),
    save: ajaxReducer('GROUPS_SAVE'),
    create: ajaxReducer('GROUPS_CREATE')
  }),
  adminList: ajaxReducer('USER_ADMINLIST'),
  ptreg: ajaxReducer('PATIENT_REG'),
  patient: combineReducers({
    load: ajaxReducer('PATIENT_LOAD'),
    list: ajaxReducer('PATIENT_LIST'),
    listadvanced: ajaxReducer('PATIENT_LISTADVANCED'),
    create: ajaxReducer('PATIENT_CREATE'),
    save: ajaxReducer('PATIENT_SAVE'),
    placehold: ajaxReducer('PATIENT_PLACEHOLD'),
    removehold: ajaxReducer('PATIENT_REMOVEHOLD'),
    archive: ajaxReducer('PATIENT_ARCHIVE'),
    unarchive: ajaxReducer('PATIENT_UNARCHIVE'),
    recent: ajaxReducerPreserveState('RECENT_PATIENTLIST')
  }),
  domain: domainReducer
});
