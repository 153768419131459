import React from 'react';

export class SPALayout extends React.Component {
  render() {
    return (
      <div style={{height:'100vh',...this.props.style}}>
        {this.props.children}
      </div>
    );
  }
}

export class VLayout extends React.Component {
  render() {
    return (
      <div style={{height:'100%',display:'flex',flexDirection:'column',...this.props.style}}>
        {this.props.children}
      </div>
    )
  }
}

export class VLayoutHeader extends React.Component {
  render() {
    return (
      <div style={{flex:'0 1 0%',...this.props.style}}>
        {this.props.children}
      </div>
    )
  }
}

export class VLayoutContent extends React.Component {
  render() {
    return (
      <div style={{flex:'1 1 0%',overflowY:'auto',...this.props.style}}>
        {this.props.children}
      </div>
    )
  }
}

export class VLayoutFooter extends React.Component {
  render() {
    return (
      <div style={{flex:'0 1 0%',...this.props.style}}>
        {this.props.children}
      </div>
    )
  }
}

export class HLayout extends React.Component {
  render() {
    return (
      <div style={{height:'100%',display:'flex',flexDirection:'row',...this.props.style}}>
        {this.props.children}
      </div>
    )
  }
}

export class HLayoutLeft extends React.Component {
  render() {
    return (
      <div style={{width:'50px',flex:'0 1 0%',...this.props.style}}>
        {this.props.children}
      </div>
    )
  }
}

export class HLayoutContent extends React.Component {
  render() {
    return (
      <div style={{flex:'1 1 0%',overflowX:'auto',...this.props.style}}>
        {this.props.children}
      </div>
    )
  }
}

export class HLayoutRight extends React.Component {
  render() {
    return (
      <div style={{width:'50px',flex:'0 1 0%',...this.props.style}}>
        {this.props.children}
      </div>
    )
  }
}

export class AppLayout extends React.Component {
  render() {
    const style = {
      width:'100vw',
      height:'100vh',
      display:'flex',
      flexDirection:'column'
    };
    return (
      <div style={style}>
        {this.props.children}
      </div>
    )
  }
}

export class AppHeader extends React.Component {
  render() {
    const style = {
      height:this.props.height,
      backgroundColor:'#2b8ed8',
      color:'white'
    };
    return (
      <div style={style}>
        {this.props.children}
      </div>
    );
  }
}

export class AppMain extends React.Component {
  render() {
    const style = {
      flex: 1,
      display: 'flex',
      flexDirection: 'row'
    };
    return (
      <div style={style}>
        {this.props.children}
      </div>
    );
  }
}

export class AppNav extends React.Component {
  render() {
    const style = {
      width:this.props.width,
      minWidth:this.props.width,
      overflow:'auto',
      borderRight: '1px solid #e8e8e8'
    };
    return (
      <div style={style}>
        {this.props.children}
      </div>
    );
  }
}

export class AppContent extends React.Component {
  render() {
    const style = {
      flex: 1,
      overflow:'auto'
    };
    return (
      <div style={style}>
        {this.props.children}
      </div>
    )
  }
}

export class AppBillingPatientContent extends React.Component {
  render() {
    const style = {
      flex: 1,
      display: 'flex',
      flexDirection: 'row'
    };
    return (
      <div style={style}>
        {this.props.children}
      </div>
    )
  }
}

export class AppBillingPatientAssist extends React.Component {
  render() {
    const style = {
      width: (this.props.collapsed) ? 0 : this.props.width,
      overflow:'auto',
      borderLeft: '1px solid #e8e8e8'
    };
    return (
      <div style={style}>
        {this.props.children}
      </div>
    );
  }
}
// or


export class AppChartContent extends React.Component {
  render() {
    const style = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    }
    return (
      <div style={style}>
        {this.props.children}
      </div>
    );
  }
}

export class AppChartHeader extends React.Component {
  render() {
    const style = (this.props.height) ? { height:this.props.height } : {};
    return (
      <div style={style}>
        {this.props.children}
      </div>
    );
  }
}

export class AppChart extends React.Component {
  render() {
    const style = {
      flex: 1,
      overflow: 'auto'
    };
    return (
      <div style={style} id='chartpane'>
        {this.props.children}
      </div>
    );
  }
}
