import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { safe } from '../../../../../helpers/reduxSelectors';

const fields = {
  start_date: { title: 'Start Date', type: 'datetime' },
  end_date: { title: 'End Date', type: 'datetime' },
  total_hrs: { title: 'Min Total Hrs', type: 'number' },
  max_total_hrs: { title: 'Max Total Hrs', type: 'number' },
  locked: { title: 'Locked', type: 'boolean' },
  comments: { title: 'Title', type: 'string' },
};

class HistoryTemplate extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    type: PropTypes.number,
  };

  renderValue = (x, type) => {
    switch (type) {
      case 'boolean':
        return x ? 'Yes' : 'No';
      case 'datetime':
        return x ? moment(x).format('MM/DD/YYYY') : '';
      default:
        return x;
    }
  };

  renderValues = () => {
    return Object.keys(fields).filter((field) => {
      const { title, type } = fields[field];
      const value = safe(this.props.data, field);
      if (value) {
        return (
          <div key={field}>
            {title}: {this.renderValue(value, type)}
          </div>
        );
      }
      return false;
    });
  };

  renderUpdate = () => {
    const changed_fields = Object.keys(fields).filter((field) => {
      return safe(this.props.data, field + '.before') !== safe(this.props.data, field + '.after');
    });
    return changed_fields.map((field) => {
      const { title, type } = fields[field];
      const before = this.renderValue(safe(this.props.data, field + '.before'), type);
      const after = this.renderValue(safe(this.props.data, field + '.after'), type);
      return (
        <div key={field}>
          {title}: {before} {'->'} {after}
        </div>
      );
    });
  };

  render() {
    if (!this.props.data) {
      return null;
    } else if (safe(this.props.data, 'id.before')) {
      return <div>{this.renderUpdate()}</div>;
    } else {
      return <div>{this.renderValues()}</div>;
    }
  }
}

export default HistoryTemplate;
