/**
 * context.ts
 * The context and state used by the NoteHide.tsx context and state provider
 * Useful for seeing what is available to all sub-components
 */
import { Dispatch, SetStateAction } from 'react';
import { PostReturnI } from 'hooks/definitions';
import { FormInstance } from 'antd';
import { createContext } from 'use-context-selector';

export type DoHideNoteT = () => void;

export interface NoteHideContextI {
  attachmentsDisabled?: boolean;
  form?: FormInstance<any>;
  hideNoteAPI?: PostReturnI;
  note?: Record<string, unknown>;
  isModalVisible?: boolean;
  isConfirmVisible?: boolean;
}

type SetReportsContextI = Dispatch<SetStateAction<NoteHideContextI>>;

export interface ContextT {
  state: NoteHideContextI;
  setState: SetReportsContextI;
  doHideNote: DoHideNoteT;
}

export const defaultState: NoteHideContextI = {
  attachmentsDisabled: false,
  form: undefined,
  hideNoteAPI: undefined,
  note: undefined,
  isModalVisible: undefined,
  isConfirmVisible: undefined,
};

const defaultContext = {
  state: defaultState,
  setState: () => null,
  doHideNote: () => null,
};

export default createContext<ContextT>(defaultContext);
