import * as types from '../../actions/billing/types';

const initialState = {
  create: {},
  apply: {},
  refund: {},
  list: {},
  unapply: {},
  refundlink: {}
}

export default function(state = initialState, action) {
  switch (action.type) {

    case types.BILLING_PTPAYMENT_CREATE_REQUEST:
      return {
        ...state,
        create: {
          processing: true
        }
      }

    case types.BILLING_PTPAYMENT_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          processing: false,
          success: true
        }
      }

    case types.BILLING_PTPAYMENT_CREATE_FAILURE:
      return {
        ...state,
        create: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_PTPAYMENT_CREATE_CLEAR:
      return {
        ...state,
        create: {}
      };

    case types.BILLING_PTPAYMENT_REFUND_REQUEST:
      return {
        ...state,
        refund: {
          processing: true
        }
      }

    case types.BILLING_PTPAYMENT_REFUND_SUCCESS:
      return {
        ...state,
        refund: {
          processing: false,
          success: true
        }
      }

    case types.BILLING_PTPAYMENT_REFUND_FAILURE:
      return {
        ...state,
        refund: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_PTPAYMENT_REFUND_CLEAR:
      return {
        ...state,
        refund: {}
      };

    case types.BILLING_PTPAYMENT_APPLY_REQUEST:
      return {
        ...state,
        apply: {
          processing: true
        }
      }

    case types.BILLING_PTPAYMENT_APPLY_SUCCESS:
      return {
        ...state,
        apply: {
          processing: false,
          success: true
        }
      }

    case types.BILLING_PTPAYMENT_APPLY_FAILURE:
      return {
        ...state,
        apply: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_PTPAYMENT_APPLY_CLEAR:
      return {
        ...state,
        apply: {}
      };

    case types.BILLING_PTPAYMENT_UNAPPLY_REQUEST:
      return {
        ...state,
        unapply: {
          processing: true
        }
      }

    case types.BILLING_PTPAYMENT_UNAPPLY_SUCCESS:
      return {
        ...state,
        unapply: {
          processing: false,
          success: true
        }
      }

    case types.BILLING_PTPAYMENT_UNAPPLY_FAILURE:
      return {
        ...state,
        unapply: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_PTPAYMENT_UNAPPLY_CLEAR:
      return {
        ...state,
        unapply: {}
      };


    case types.BILLING_PTPAYMENT_LIST_REQUEST:
      return {
        ...state,
        list: {
          processing: true
        }
      }

    case types.BILLING_PTPAYMENT_LIST_SUCCESS:
      return {
        ...state,
        list: {
          processing: false,
          success: true,
          data: action.payload
        }
      }

    case types.BILLING_PTPAYMENT_LIST_FAILURE:
      return {
        ...state,
        list: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_PTPAYMENT_LIST_CLEAR:
      return {
        ...state,
        list: {}
      };


    case types.BILLING_PTPAYMENT_REFUNDLINK_REQUEST:
      return {
        ...state,
        refundlink: {
          processing: true
        }
      }

    case types.BILLING_PTPAYMENT_REFUNDLINK_SUCCESS:
      return {
        ...state,
        refundlink: {
          processing: false,
          success: true,
          data: action.payload
        }
      }

    case types.BILLING_PTPAYMENT_REFUNDLINK_FAILURE:
      return {
        ...state,
        refundlink: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_PTPAYMENT_REFUNDLINK_CLEAR:
      return {
        ...state,
        refundlink: {}
      };

    default:
      return state;
  }
}
