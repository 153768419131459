import React from 'react';
import { connect } from 'react-redux';
import { Empty } from 'antd';
import moment from 'moment';

import * as actions from '../../../../../../actions/emr';
import { safe } from '../../../../../../helpers/reduxSelectors';

class MessageList extends React.Component {

  state = {
    manuallyScrolled: false
  }

  componentDidMount() {
//    console.log('Mounted: ',this.state.manuallyScrolled);
  }

  // Scroll Height = entire height of all text in history
  // Client Height = height of window
  // Scroll Top How far down we've scrolled.

  scrollToBottom = () => {
    let element = document.getElementById('message-panel');
    element.scrollTop = element.scrollHeight-element.clientHeight;
  }

  isNearBottom = () => {
    const element = document.getElementById('message-panel');
    return (Math.abs(element.scrollHeight-element.clientHeight-element.scrollTop) < 100) ? true : false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.messages.length !== this.props.messages.length) {
      if (!this.state.manuallyScrolled) {
        this.scrollToBottom();
      }
    }
  }

  onScroll = (e) => {
    this.setState({
      manuallyScrolled: !this.isNearBottom()
    });
  }

  renderMessage = (x) => {
    const leftStyle = {
      display: 'inline-block',
      maxWidth: '65%',
      border: '1px solid lightgray',
      backgroundColor: 'white',
      borderRadius: '3px',
      color: '#646464',
      fontSize: '14px',
      padding: '5px',
      margin: '0px'
    };

    const rightStyle = {
      ...leftStyle,
      float:'right',
      backgroundColor: '#a0e759', //'#05728f',
      color: '#353535'
    };

    const leftLabel = {
      margin: '0px',
      display: 'block',
      fontSize: 'smaller',
      color: '#aaaaaa'
    }

    const rightLabel = {
      ...leftLabel,
      float:'right'
    };

    let isMe = false;
    if (x.author.type === 1 && this.props.currentInbox.type === 1 && x.author.user_id === this.props.currentInbox.user_id) {
      isMe = true;
    }
    if (x.author.type === 3 && this.props.currentInbox.type === 3 && x.author.group_id === this.props.currentInbox.group_id) {
      isMe = true;
    }
//    const isMe = (x.author.type === 1 && x.author.user_id === this.props.activeUserId) ? true : false;

    const style = (isMe) ? leftStyle : rightStyle;
    const label = (isMe) ? leftLabel : rightLabel;

    return (
      <div style={{marginBottom:'10px',marginTop:'20px'}} key={x.id}>
        <div style={label}>{x.author.name}</div>
        <div style={{clear:'both'}}/>
        <div style={style}>{x.content}</div>
        <div style={{clear:'both'}}/>
        <div style={label}>
            {moment(x.createdAt).format('M/D/YY hh:mm A')}
        </div>
        <div style={{clear:'both'}}/>
      </div>
    );
  }

  renderList = () => {
    if (!this.props.loading && this.props.messages.length === 0) {
      return (
        <Empty description='No messages' style={{paddingTop:'40px'}}/>
      )
    } else {
      return (
        <div>
          { this.props.messages.map(this.renderMessage)}
        </div>
      )
    }
  }

  render() {
    const style = {
      border: '1px solid lightgray',
      backgroundColor:'white',
      flexGrow:1,
      margin:'0px 5px 5px 5px',
      padding:'5px',
      overflowY:'scroll'
    };

    return (
      <div id='message-panel' onScroll={this.onScroll} style={style}>
        { this.renderList() }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentInbox: safe(state,'emr.inbox.current'),
    activeUserId: safe(state,'shared.domain.activeUser.id'),
  }
}

export default connect(mapStateToProps,actions)(MessageList);
