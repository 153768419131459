import React from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { Select, Table, Button } from 'antd';
import moment from 'moment';
import { Link,withRouter } from 'react-router-dom';

import FormattedCell from '../../../../shared/FormattedCell';
import IndividualAmount from './IndividualAmount';
import InterestPaymentEntry from './InterestPaymentEntry';

import * as billing_actions from '../../../../../actions/billing';
import * as shared_actions from '../../../../../actions/shared';
import { safe } from '../../../../../helpers/reduxSelectors';

import isEqual from 'lodash/isEqual';

const { Option } = Select;

class ChooseServiceLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: '',
      addingInterestPayment: false,
      originalPaymentData: props.paymentData,
      newPaymentData: [],
      patientList: props.patientList
    }
    props.getServiceLinesClear()
  }

  componentDidUpdate(prevProps) {
    if (this.props.patientList.length) {
      if (!isEqual(prevProps.patientList, this.props.patientList)) {
        this.setState({ patientList: this.props.patientList });
      }
    }
  }

  onChange = (value) => {
    this.setState({ selection: value });
    this.props.getServiceLines(value)
  }

  onClickClear = () => {
    this.setState({
      selection: ''
    });
    this.props.getServiceLinesClear();
  }

  onSelect = (id,amount,claimNum,description,code) => {
    // eslint-disable-next-line
    const record = this.props.paymentServicesList.find(x=>(x.id == id && x.cpt_codes==code));
    // eslint-disable-next-line
    const chosenPatient = this.state.patientList.find(x=>x.id==this.state.selection);

    const newState = [...this.state.newPaymentData,{
      billing_service_id: id,
      dos: record.dos,
      billing_patient_id: this.state.selection,
      patient_name: chosenPatient.full_name,
      amount: amount,
      claim_num: claimNum,
      description: description,
      code:code
    }];

    this.setState({
      newPaymentData: newState
    });

    this.props.updateList(newState);
  }

  deleteItem = (id) => {
    this.setState({
      newPaymentData: this.state.newPaymentData.filter(x=>x.billing_service_id !== id)
    },() => {
      this.props.updateList(this.state.newPaymentData);
    });
  }

  unapply = (id) => {
    this.props.unapplyInsPayment(this.props.paymentId,id);
  }

  renderLoadedPayment = () => {

    const columns = [{
      title: 'DOS',
      dataIndex: 'dos',
      width: 100,
      render: (value,record) => {
        const dos = safe(record,'billing_services.dos');
        if (!dos) {
          return null;
        }
        return moment(dos).format('M/DD/YYYY');
      }
    }, {
      title: 'Created',
      dataIndex: 'createdAt',
      width: 150,
      render: (value,record) => {
        return (
          <span>{safe(record,'creator.initials','')} ({moment(value).format('M/DD/YYYY')})</span>
        );
      }
    }, {
      title: 'Patient',
      width: 150,
      dataIndex: 'patient_name',
      render: (value,record) => {
        // this 110851 is not showing up.
        if (record.billing_patients) {
          return <Link to={'/billing/patients/'+record.billing_patients.id}>{record.billing_patients.patients.full_name}</Link>;
        } else {
          return '--';
        }
      }
    }, {
      title: 'Amount',
      dataIndex: 'ins_amount',
      align: 'right',
      width: 100,
      render: (value,record) => {
        return <FormattedCell colors={false} blankonzero={false} value={value} />
      }
    }, {
      title: 'Claim #',
      dataIndex: 'claim_num',
      width: 150,
    }, {
      title: 'Code',
      dataIndex: 'code',
      width: 100
    }, {
      title: 'Description',
      dataIndex: 'description'
    },{
      width: 60,
      render: (value,record) => {
        return (record.unapplyable) ? <a onClick={()=>{this.unapply(record.id)}}>Unapply</a> : null;
      }
    }];


    if (!this.props.paymentData.payment_details || this.props.paymentData.payment_details === 0) {
      return null;
    }

    return (
      <div style={{border:'1px solid lightgray',marginBottom:'5px',backgroundColor:'#FAEBEB',padding:'5px',minHeight:'150px'}}>
        <h4>Already Stored Payment Details</h4>
        <Table
          columns={columns}
          className='service-loaded-list'
          rowKey='id'
          dataSource={this.props.paymentData.payment_details}
          pagination={false}
          border={false}
          size='small'
        />
      </div>
    )
  }


  renderSelection = () => {

    const columns = [{
      title: 'DOS',
      dataIndex: 'dos',
      width: 100,
      render: (value) => {
        if (value == null) {
          return null;
        }
        return moment(value).format('M/DD/YYYY');
      }
    }, {
      title: 'Created',
      width: 150,
      dataIndex: 'createdAt',
      render: (val,record) => {
        return null;
      }
    }, {
      title: 'Patient',
      width: 150,
      dataIndex: 'patient_name'
      /*
      render: (value,record) => {
        return <Link to={'/billing/patients/'+record.billing_patient_id}>{value}</Link>;
      } */
    }, {
      title: 'Amount',
      dataIndex: 'amount',
      align: 'right',
      width: 100,
      render: (value,record) => {
        return <FormattedCell colors={false} blankonzero={false} value={value} />
      }
    }, {
      title: 'Claim #',
      dataIndex: 'claim_num',
      width: 150,
    },{
      title: 'Code',
      dataIndex: 'code',
      width: 100
    },{
      title: 'Description',
      dataIndex: 'description'
    },{
      title: 'Action',
      width: 60,
      align:'left',
      render: (value,record) => {
        return <a onClick={()=>this.deleteItem(record.billing_service_id)} style={{fontSize:'16px'}}><DeleteOutlined /></a>;
      }
    }];

    if (!this.state.newPaymentData || this.state.newPaymentData.length === 0) {
      return null;
    }

    return (
      <div style={{border:'1px solid lightgray',backgroundColor:'#EBF5FA',padding:'5px',minHeight:'150px'}}>
        <h4>Currently Added Payment Details</h4>
        <Table
          columns={columns}
          className='service-selection-list'
          rowKey='billing_service_id'
          dataSource={this.state.newPaymentData}
          pagination={false}
          border={false}
          size='small'
        />
      </div>
    )
  }

  addInterest = () => {
    return <div>
      Adding interest
    </div>
  }

  onAddInterest = () => {
    this.setState({
      addingInterestPayment: true
    });
  }

  onCancelInterest = () => {
    this.setState({
      addingInterestPayment: false
    });
  }

  onAddInterestAmount = (amount) => {
    const newState = [...this.state.newPaymentData,{
      billing_service_id: null,
      dos: null,
      billing_patient_id: null,
      patient_name: null,
      amount: amount,
      description: 'Interest Payment'
    }];

    this.setState({
      newPaymentData: newState,
      addingInterestPayment: false
    });

    this.props.updateList(newState);
  }

  renderChooseLines = () => {
    const columns = [{
      title: 'DOS',
      dataIndex: 'dos',
      width: 100,
      render: (value,record) => {
        return moment(value).format('M/DD/YYYY');
      }
    },{
      title: 'Codes',
      dataIndex: 'cpt_codes',
      width: 150,
      render: (value,record) => {
        return value;
        //return (value) ? value.map(x=><span key={x}>{x} </span>) : null;
      }
    },{
      title: 'Provider',
      dataIndex: 'rendering_provider_id',
      width: 175,
      render: (value,record) => {
        const val = safe(record,'rendering_provider.full_name','');
        return (
          <div style={{width:175,whiteSpace: 'nowrap',overflow:'hidden',textOverflow: 'ellipsis'}}>{val}</div>
        );
      }
    },{
      title: 'Code Rate',
      dataIndex: 'ins_rate',
      width: 100,
      align: 'right',
      render: (value,record) => {
        const rates = safe(record,'orig_rates.rates',{});
        return <FormattedCell colors={false} value={rates[record.cpt_codes]}/>
        //return <FormattedCell colors={false} value={value}/>
      }
    },{
      title: 'Tot Ins Out',
      dataIndex: 'ins_outstanding',
      width: 100,
      align: 'right',
      render: (value,record) => {
        return <FormattedCell blankonzero={false} value={value}/>
      }
    },{
      title: 'Amount and Description',
      dataIndex: 'ins_amount',
      render: (value,record) => {
        const paymentExists = this.state.newPaymentData.find(x=>{
          return (x.billing_service_id===record.id && x.code === record.cpt_codes) ? true : false
        });
        const disabled = (paymentExists) ? true : false;

        return <IndividualAmount disabled={disabled} id={record.id} code={record.cpt_codes} onSelect={this.onSelect} amount={record.ins_outstanding}/>
      }
    }];

    const alreadyHasInterest = (this.state.newPaymentData.find(x=>x.billing_service_id === '-')) ? true : false;
    //console.log(this.props.paymentServicesList)
    return (
      <div style={{marginTop:'5px',backgroundColor:'#F5F0F0',padding:'5px',border:'1px solid lightgray'}}>
        {!this.state.addingInterestPayment &&
          <div style={{paddingBottom: '5px'}}>
            <Select
              style={{ width: 400 }}
              placeholder="Select Patient"
              showSearch
              filterOption={false}
              value={this.state.selection}
              onChange={this.onChange}
              onSearch={this.props.getPatientList}
              defaultActiveFirstOption={false}
              notFoundContent={null}
            >
              {this.props.patientList.map((item, key) => {
                return <Option key={key} value={item.id}>{item.full_name}</Option>;
              })}
            </Select>
            <Button style={{marginLeft:'5px'}} onClick={this.onClickClear}>Clear</Button>
            {!alreadyHasInterest && <a onClick={this.onAddInterest} style={{float:'right',lineHeight:'32px'}}>Add an interest payment</a>}
          </div>
        }

        {!this.state.addingInterestPayment && this.props.paymentServicesList && this.props.paymentServicesList.length > 0 &&
          <Table
            columns={columns}
            className='services-list'
            rowKey='key'
            dataSource={this.props.paymentServicesList}
            pagination={false}
            border={false}
            size='small'
          />
        }

        {this.state.addingInterestPayment &&
          <InterestPaymentEntry onAddInterest={this.onAddInterestAmount} onClose={this.onCancelInterest} />
        }

      </div>
    );
  }

  render() {
    const isDeleted = safe(this.props,'paymentData.deleted') ? true : false;

    return (
      <div>
        { this.renderLoadedPayment() }
        { !isDeleted && this.renderSelection() }
        { !isDeleted && this.renderChooseLines() }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    patientList: safe(state,'shared.patient.list.data',[]),
    paymentServicesList: safe(state,'billing.serviceline.list.data',[])
  }
}

export default connect(mapStateToProps,{...shared_actions,...billing_actions})(withRouter(ChooseServiceLine));
