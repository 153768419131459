import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BreakLines extends Component {
  static propTypes = {
    text: PropTypes.string
  }
  render() {
    if (!this.props.text)
      return null;
    return this.props.text.split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    });
  }
}

export default BreakLines;
