import React from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Spin, Button, notification } from 'antd';
import { Elements, StripeProvider } from 'react-stripe-elements';

import * as actions from '../../../../../../actions/billing';
import { safe } from '../../../../../../helpers/reduxSelectors';
import CreditCardForm from './CreditCardForm';

class CreditCardDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      enterCardVisible: false
    }
    notification.config({
      placement: 'topRight'
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.ccCreateSuccess === false && prevProps.ccCreateSuccess !== false) {
      notification['error']({
        message:'Unable to Store Credit Card',
        description: safe(this.props,'ccCreateError.data.message'),
        duration: 0
      });
    }

    if (this.props.ccDeleteSuccess === false && prevProps.ccDeleteSuccess !== false) {
      notification['error']({
        message:'Unable to Delete Stored Card',
        description: safe(this.props,'ccDeleteError.data.message'),
        duration: 0
      });
    }

    if (this.props.ccSetDefaultSuccess === false && prevProps.ccSetDefaultSuccess !== false) {
      notification['error']({
        message:'Unable to Set as Default Card',
        description: safe(this.props,'ccSetDefaultError.data.message'),
        duration: 0
      });
    }

    if (this.props.ccListSuccess === false && prevProps.ccListSuccess !== false) {
      notification['error']({
        message:'Unable to Set as Default Card',
        description: safe(this.props,'ccListError.data.message'),
        duration: 0
      });
    }
  }

  showDialog = () => {
    this.props.getStripePublishedApiKey();
    this.props.getPatientCreditCardList(this.props.patientId);
    this.setState({
      visible: true
    });
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      enterCardVisible: false
    });
  }

  handleOk = (token) => {
    this.props.createNewCreditCard(this.props.patientId,token.token);
    this.setState({
      enterCardVisible: false
    });
  }

  enterNewCard = () => {
    if (this.state.enterCardVisible) {
      return (
        <StripeProvider apiKey={this.props.stripepk}>
          <Elements>
            <CreditCardForm handleResult={this.handleOk} />
          </Elements>
        </StripeProvider>
      );
    }
    return null;
  }

  showEnterCard = () => {
    this.setState({
      enterCardVisible: true
    });
  }

  deleteCard = (cardId) => {
    this.props.deleteCreditCard(this.props.patientId,cardId);
  }

  setDefaultCard = (cardId) => {
    this.props.setDefaultCreditCard(this.props.patientId,cardId);
  }

  renderModalContent() {
    const defaultSource = safe(this.props,'ccList.default_source');
    const cards = safe(this.props,'ccList.sources.data',[]);

    if (this.props.isBusy) {
      return null;
    }

    if (this.state.enterCardVisible) {
      return this.enterNewCard();
    }

    if (cards.length === 0) {
      return (
        <div>
          <div>
            <h3>No Credit Cards on File</h3>
          </div>
          <div style={{paddingTop:'20px'}}>
            <a onClick={this.showEnterCard}><PlusOutlined /> Add a New Card</a>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <table className='credit-card-list'>
            <tbody>
              { cards.map(x => {
                  const isDef = (defaultSource === x.id);
                  return (
                    <tr key={x.id}>
                      <td style={{width:'100px',textAlign:'left'}}>{(!isDef) ? <a onClick={()=>this.setDefaultCard(x.id)}>Set Default</a> : 'Default'}</td>
                      <td style={{textAlign:'left'}}>{x.brand}</td>
                      <td>****{x.last4}</td>
                      <td>{x.exp_month}/{x.exp_year}</td>
                      <td><a onClick={()=>this.deleteCard(x.id)}><DeleteOutlined /></a></td>
                    </tr>
                  );
              }) }
            </tbody>
          </table>
          <div style={{paddingTop:'30px'}}>
            <Button type='primary' className='cc-button' onClick={this.showEnterCard}>Add a New Card</Button>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <Modal
          title={"Credit Cards: " + this.props.patientName}
          visible={this.state.visible}
          onOk={this.handleOk}
          okText='Submit'
          onCancel={this.handleCancel}
          width={500}
          bodyStyle={{minHeight:'180px',backgroundColor:'rgb(246,249,252)',padding:'20px',textAlign:'center'}}
          footer={null}
        >
          <Spin spinning={this.props.isBusy}>
            {this.renderModalContent()}
          </Spin>
        </Modal>
        <a onClick={this.showDialog}>
          {this.props.children}
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isBusy: (
      safe(state,'billing.cc.list.processing') ||
      safe(state,'billing.cc.delete.processing') ||
      safe(state,'billing.cc.create.processing') ||
      safe(state,'billing.cc.setdefault.processing') ||
      safe(state,'billing.cc.stripepk.processing')
    ) ? true : false,
    stripepk: safe(state,'billing.cc.stripepk.data.pk'),

    ccList: safe(state,'billing.cc.list.data',{}),

    ccListSuccess: safe(state,'billing.cc.list.success'),
    ccListError: safe(state,'billing.cc.list.error'),

    ccCreateSuccess: safe(state,'billing.cc.create.success'),
    ccCreateError: safe(state,'billing.cc.create.error'),

    ccDeleteSuccess: safe(state,'billing.cc.delete.success'),
    ccDeleteError: safe(state,'billing.cc.delete.error'),

    ccSetDefaultSuccess: safe(state,'billing.cc.setdefault.success'),
    ccSetDefaultError: safe(state,'billing.cc.setdefault.error')

  }
}

export default connect(mapStateToProps,actions)(CreditCardDialog);
