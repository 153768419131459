import React from 'react';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Table, Select, Modal, notification } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/intakes';

import PatientSelector from '../../shared/PatientSelector';
import PortalAccountSelector from './Accounts_/PortalAccountSelector';

class Accounts extends React.Component {
  state = {
    portal_account: null,
    patient_account: null,
    patient_to_add_relation: 1,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.portal_user_delete_success !== this.props.portal_user_delete_success) {
      if (this.props.portal_user_delete_success) {
        notification['success']({
          message: 'Portal Account Deleted',
        });
      } else if (this.props.portal_user_delete_error) {
        notification['error']({
          message: 'Error Deleting Portal Account'
        });
      }
    }
  }

  onSelectPortalAccount = (value) => {
    this.setState({
      portal_account: value,
      patient_account: null
    },()=>{
      this.props.getPatientsLinkedToPortal(value.id);
      this.props.clearLinkedAccounts();
    });
  }

  onSelectPatientChart = (value) => {
    this.setState({
      patient_account: value,
      portal_account: null
    },()=>{
      this.props.getLinkedAccounts(value);
    })
  }

  deleteLink = (link_id,portal_id,patient_id) => {
    this.props.portalAccountDeleteLink(portal_id,link_id);
  }

  deletePortalAccount = (portal_id) => {
    const self = this;
    Modal.confirm({
      title: 'Are you sure you want to delete this portal account?',
      content: 'It cannot be undone and the patient will no longer be able to login to the patient portal',
      onOk() {
        self.props.portalAccountDelete(portal_id);
        self.setState({
          portal_account: null,
          patient_account: null
        });
      },
      onCancel() {},
    });
  }

  changeSelect = (value) => {
    this.setState({
      patient_to_add_relation: value
    });
  }

  onChooseNewPatient = (value) => {
    this.props.portalAccountAddLink(value,this.state.portal_account.id,
      this.state.patient_to_add_relation);
    this.setState({
      patient_to_add_relation: 1,
    });
  }

  displayLinkedPatients = () => {
    if (this.props.portal_links && this.props.portal_links.length > 0) {
      const columns = [{
        title: 'Relation',
        dataIndex: 'relation',
        key: 1,
        render: (val) => {
          return (val === 1) ? 'Self' : 'Other';
        }
      },{
        title: "Created",
        dataIndex: 'createdAt',
        key: 2,
        render: (val) => {
          return moment(val).format('MM/DD/YYYY HH:mm:ss');
        }
      },{
        title: 'Patient ID',
        key: 3,
        dataIndex: 'patient',
        render: (val,record) => {
          return safe(record,'patient.id');
        }
      },{
        title: 'Patient',
        key: 4,
        dataIndex: 'patient',
        render: (val,record) => {
          return safe(record,'patient.full_name');
        }
      },{
        title: '',
        key: 5,
        render: (val,record) => {
          return (
            <a onClick={()=>this.deleteLink(record.id,record.portal_id,record.patient.id)}>
              <DeleteOutlined />
            </a>
          );
        }
      }];

      return (
        <div>
          <h4 style={{paddingTop:'20px'}}>Linked Patients</h4>
          <Table
            dataSource={this.props.portal_links}
            columns={columns}
            rowKey='id'
            size='small'
            pagination={false}
          />

          <div>
            <h4 style={{paddingTop:'20px'}}>Link a Patient to this Portal Account</h4>
            Relation:
            <Select style={{width:'100px'}} value={this.state.patient_to_add_relation} onChange={this.changeSelect}>
              <Select.Option value={1}>Self</Select.Option>
              <Select.Option value={2}>Other</Select.Option>
            </Select>
            &nbsp; Patient:
            <PatientSelector onSelectPatient={this.onChooseNewPatient}/>
          </div>
        </div>
      );
    }
    return (
      <div>
        <h4 style={{paddingTop:'20px'}}>No Linked Patients</h4>
        <h4 style={{paddingTop:'20px'}}>Link a Patient to this Portal Account</h4>
        Relation:
        <Select style={{width:'100px'}} value={this.state.patient_to_add_relation} onChange={this.changeSelect}>
          <Select.Option value={1}>Self</Select.Option>
          <Select.Option value={2}>Other</Select.Option>
        </Select>
        &nbsp; Patient:
        <PatientSelector onSelectPatient={this.onChooseNewPatient}/>
      </div>
    );
  }

  changeFeature = (e) => {
    this.props.setPortalFeatureFlag(this.state.portal_account.id,(e) ? 1 : 0);
  }

  displayPortalAccount = () => {
    const columns = [{
      title: 'Name',
      dataIndex: 'name'
    },{
      title: 'Email',
      dataIndex: 'email'
    },{
      title: 'Verified',
      dataIndex: 'email_verified',
      render: (val) => {
        if (val)
          return <CheckOutlined />;
        return null;
      }
    },{
      title: 'Google Account',
      dataIndex: 'google_id',
      render: (val) => {
        if (val)
          return <CheckOutlined />;
        return null;
      }
    },{
      title: 'Phone',
      dataIndex: 'phone'
    },{
      title: 'Verified',
      dataIndex: 'phone_verified',
      render: (val) => {
        if (val)
          return <CheckOutlined />;
        return null;
      }
    },{
      title: "Created",
      dataIndex: 'createdAt',
      render: (val) => {
        return moment(val).format('MM/DD/YYYY HH:mm:ss');
      }
    },
    /*{
      title: 'Enable Online Scheduling',
      render: (val,record) => {
        return (
          <Switch defaultChecked={record.feature_flag} onClick={this.changeFeature}/>
        )
      }
    }, */
    {
      title: 'Delete',
      render: (val,record) => {
        return (
          <a onClick={()=>this.deletePortalAccount(record.id)}>
            <DeleteOutlined />
          </a>
        );
      }
    }];

    return (
      <div>
        <h4 style={{paddingTop:'20px'}}>Portal Account</h4>
        <Table
          size='small'
          dataSource={[this.state.portal_account]}
          columns={columns}
          rowKey='id'
          pagination={false}
        />
        { this.displayLinkedPatients() }
      </div>
    );
  }

  portalAccounts = () => {
    return (
      <div>
        <h3>Search Portal Accounts</h3>
        <div>
          Portal Account Email: <PortalAccountSelector onSelectPatient={this.onSelectPortalAccount} />
          <span style={{fontWeight:'bold',paddingLeft:'10px',paddingRight:'10px'}}>- OR -</span>
          Patient Name: <PatientSelector onSelectPatient={this.onSelectPatientChart}/>
        </div>
        { this.displayLinkedList() }
        { this.state.portal_account && this.displayPortalAccount() }
      </div>
    );
  }

  displayLinkedList = () => {
    if (this.props.linked_list && this.props.linked_list.length > 0) {
      const patient_name = safe(this.props.linked_list[0],'patient.full_name');
      return (
        <div>
          <h4 style={{paddingTop:'20px'}}>{patient_name} Links to Portal Accounts:</h4>
          <ol>
            {this.props.linked_list.map(x=>{
              return (
                <li key={x.id}>
                  {x.portal.name} (<a onClick={()=>{this.onSelectPortalAccount(x.portal)}}>{x.portal.email}</a>)
                </li>
              )
            })}
          </ol>
        </div>
      )
    } else {
      /*
      if (this.state.patient_account) {
        return (
          <div>
            <h4>No links to this patient account</h4>
          </div>
        )
      }
      */
      return null;
    }
  }

  render() {
    return (
      <div style={{padding:'10px'}}>
        { this.portalAccounts() }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    linked_list: safe(state,'intakes.linked_list.data',[]),
    portal_links: safe(state,'intakes.portal_links.data',[]),
    portal_user_delete_success: safe(state,'intakes.portal_delete.success'),
    portal_user_delete_error: safe(state,'intakes.portal_delete.error'),
  }
}

export default connect(mapStateToProps,actions)(Accounts);
