import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ApptList from './SessionPanel_/ApptList';
import SessionOperations from './SessionPanel_/SessionOperations';
import Reschedule from './SessionPanel_/Reschedule';

class SessionPanel extends React.Component {

  static propTypes = {
    patient_id: PropTypes.number,
    session_id: PropTypes.number,
    pane_id: PropTypes.number,
    careteam: PropTypes.array,
    session_history: PropTypes.array,
    searchFilter: PropTypes.func,
    session_data: PropTypes.object
  }

  switchPane = (patient_id,session_id,pane_id) => {
    this.props.history.push('/emr/appointments/'+patient_id+'/'+session_id+'/'+pane_id);
  }

  renderPanes() {
    switch(this.props.pane_id) {
      case 0: // nothing selected, just use pane=1
      case 1: return (
        <ApptList
          patient_id={this.props.patient_id}
          careteam={this.props.careteam}
          session_history={this.props.session_history}
          switchPane={this.switchPane}
          searchFilter={this.props.searchFilter}
        />
      );
      case 2: return (
        <SessionOperations
          patient_id={this.props.patient_id}
          session_id={this.props.session_id}
          data={this.props.session_data}
          switchPane={this.switchPane}
          loading={this.props.loading}
        />
      );
      case 3: return (
        <Reschedule
          patient_id={this.props.patient_id}
          session_data={this.props.session_data}
          switchPane={this.switchPane}
        />
      )
      default: break;
    }
    return null;
  }

  renderPatientName = () => {
    if (this.props.patient_name) {
      return (
        <div style={{background:'#e6f7ff',marginLeft:'1px'}}>
          <h3 style={{padding:'10px'}}>{this.props.patient_name}</h3>
        </div>
      );
    }
    return null;
  }

  render() {
    return this.renderPanes();
  }

}

export default withRouter(SessionPanel);
