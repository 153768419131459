import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  BarsOutlined,
  BookOutlined,
  CalendarOutlined,
  DollarOutlined,
  DownOutlined,
  InboxOutlined,
  LogoutOutlined,
  SettingOutlined,
  DatabaseOutlined
} from '@ant-design/icons';

import { Menu, Dropdown } from 'antd';
import { Link, withRouter } from 'react-router-dom';

import { isAdmin, isStaffSupervisor, isIntake, isSuperUser } from '../../helpers/reduxSelectors';

class AppSelector extends React.Component {
  static propTypes = {
    activeUserName: PropTypes.string,
  };

  render() {
    if (this.props.activeUserName) {
      const menuAdmin = (
        <Menu style={{ border: '1px solid lightgrey' }}>
          <Menu.Item key="EMR">
            <Link to={{ pathname: '/emr/patients/0' }} target="_blank">
              <BookOutlined />
              &nbsp;
              <span>EMR</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="billing">
            <Link to={{ pathname: '/billing' }} target="_blank">
              <DollarOutlined />
              &nbsp;
              <span>Billing</span>
            </Link>
          </Menu.Item>
          {(this.props.isStaffSupervisor || this.props.isSuperUser) && (
            <Menu.Item key="reports">
              <Link to={{ pathname: '/reports' }} target="_blank">
                <BarsOutlined />
                &nbsp;
                <span>Reports</span>
              </Link>
            </Menu.Item>
          )}
          {(this.props.isStaffSupervisor || this.props.isSuperUser) && (
            <Menu.Item key="scheduler">
              <Link to={{ pathname: '/scheduler' }} target="_blank">
                <CalendarOutlined />
                &nbsp;
                <span>Scheduler</span>
              </Link>
            </Menu.Item>
          )}
          {(this.props.isIntake || this.props.isSuperUser) && (
            <Menu.Item key="intakes">
              <Link to={{ pathname: '/intakes' }} target="_blank">
                <InboxOutlined />
                &nbsp;
                <span>Intakes</span>
              </Link>
            </Menu.Item>
          )}
          {(this.props.isHR || this.props.isSuperUser) && (
            <Menu.Item key="hr">
              <Link to={{ pathname: '/hr' }} target="_blank">
                <DatabaseOutlined />
                &nbsp;
                <span>HR</span>
              </Link>
            </Menu.Item>
          )}
          {(this.props.isStaffSupervisor || this.props.isSuperUser) && (
            <Menu.Item key="settings">
              <Link to={{ pathname: '/settings' }} target="_blank">
                <SettingOutlined />
                &nbsp;
                <span>Settings</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Divider />
          <Menu.Item key="logout" onClick={() => this.props.history.push('/logout')}>
            <LogoutOutlined />
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      );

      const menuProvider = (
        <Menu onClick={() => this.props.history.push('/logout')} style={{ border: '1px solid lightgray' }}>
          <Menu.Item key="logout">
            <LogoutOutlined />
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      );

      const menu = this.props.isAdmin ? menuAdmin : menuProvider;

      return (
        <div style={{ float: 'right', paddingRight: '10px', paddingTop: '5px' }}>
          <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
            <span style={{ cursor: 'pointer', fontWeight: 'bold', color: 'white' }}>
              {this.props.activeUserName} <DownOutlined />
            </span>
          </Dropdown>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAdmin: isAdmin(state),
    isIntake: isIntake(state),
    isStaffSupervisor: isStaffSupervisor(state),
    isSuperUser: isSuperUser(state),
  };
};

export default connect(mapStateToProps, null)(withRouter(AppSelector));
