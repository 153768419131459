import React from 'react';
import PropTypes from 'prop-types';
import { HomeOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';

import { safe } from '../../../../helpers/reduxSelectors';
import Time from '../../../shared/Time';

class SessionList extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    onSelect: PropTypes.func
  }

  state = {
    expanded: false
  }

  toggleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  getTelemedicine = (x) => {
    if (x.location_id === 4 || x.allow_video === 0 || x.allow_video === 1) {
      return <VideoCameraOutlined />;
    }
    return null;
  }

  getOffice = (x) => {
    if (x.location_id !== 4 && (x.allow_video === 0 || x.allow_video === 2)) {
      return <HomeOutlined />;
    }
    return null;
  }

  displayItem = (x) => {
    const loc = safe(this.props,'locs',[]).find(q => q.id === x.location_id);
    const abbr = (loc) ? loc.abbr : '';
    return (
      <div className='session-list-item' key={'SL'+x.id+'-'+x.start_time} onClick={()=>this.props.onSelect(x)}>
        <div>{moment(x.session_date).format('ddd, M/D/YYYY')} {this.getTelemedicine(x)} {this.getOffice(x)} {new Time(x.start_time).toString(false)}
        &nbsp;({x.repeats} wks) {abbr}</div>
      </div>
    );
  }

  removeSessionsBeforePresent = (data) => {
    const nowTime = moment().format('Hmm');
    const nowDate = moment().format('YYYYMMDD');
    return data.filter(x=>{
      if (moment(x.session_date).format('YYYYMMDD')===nowDate) {
        return (x.start_time < nowTime) ? false : true;
      }
      return true;
    });
  }

  render() {
    const data = this.removeSessionsBeforePresent(safe(this.props,'data',[]));
    if (this.state.expanded) {
      // Display the whole list with a less link at the bottom
      return (
        <div>
          {data.map(x=>this.displayItem(x))}
          <div style={{paddingTop:'10px'}}>
            <Button type='link' onClick={this.toggleExpanded}>less..</Button>
          </div>
        </div>
      );
    } else {
      if (data.length <= 4) {
        // Display the whole list.
        return data.map(x=>this.displayItem(x));
      } else {
        // Display only the first 4 items and show "expand" afterwards.
        return (
          <div>
            {data.slice(0,4).map(x=>this.displayItem(x))}
            <div style={{paddingTop:'10px'}}>
              <Button type='link' onClick={this.toggleExpanded}>more..</Button>
            </div>
          </div>
        );
      }
    }
  }
}

export default SessionList;
