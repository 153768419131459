import * as types from '../../actions/billing/types';

const initialState = {
  list: {},
  load: {},
  save: {},
  create: {},
  delete: {},
  history: {}
}

export default function(state = initialState, action) {
  switch (action.type) {

    case types.BILLING_POLICY_LIST_REQUEST:
      return {
        ...state,
        list: {
          processing: true
        }
      };

    case types.BILLING_POLICY_LIST_SUCCESS:
      return {
        ...state,
        list: {
          processing: false,
          success: true,
          data: action.payload
        }
      };

    case types.BILLING_POLICY_LIST_FAILURE:
      return {
        ...state,
        list: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_POLICY_LIST_CLEAR:
      return {
        ...state,
        list: {}
      };


    case types.BILLING_POLICY_LOAD_REQUEST:
      return {
        ...state,
        load: {
          processing: true
        }
      };

    case types.BILLING_POLICY_LOAD_SUCCESS:
      return {
        ...state,
        load: {
          processing: false,
          success: true,
          data: action.payload
        }
      };

    case types.BILLING_POLICY_LOAD_FAILURE:
      return {
        ...state,
        load: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_POLICY_LOAD_CLEAR:
      return {
        ...state,
        load: {}
      };

    case types.BILLING_POLICY_CREATE_REQUEST:
      return {
        ...state,
        create: {
          processing: true
        }
      }

    case types.BILLING_POLICY_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          processing: false,
          success: true
        }
      }

    case types.BILLING_POLICY_CREATE_FAILURE:
      return {
        ...state,
        create: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_POLICY_CREATE_CLEAR:
      return {
        ...state,
        create: {}
      };

    case types.BILLING_POLICY_SAVE_REQUEST:
      return {
        ...state,
        save: {
          processing: true
        }
      }

    case types.BILLING_POLICY_SAVE_SUCCESS:
      return {
        ...state,
        save: {
          processing: false,
          success: true
        }
      }

    case types.BILLING_POLICY_SAVE_FAILURE:
      return {
        ...state,
        save: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_POLICY_SAVE_CLEAR:
      return {
        ...state,
        save: {}
      };

    case types.BILLING_POLICY_DELETE_REQUEST:
      return {
        ...state,
        delete: {
          processing: true
        }
      }

    case types.BILLING_POLICY_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          processing: false,
          success: true
        }
      }

    case types.BILLING_POLICY_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_POLICY_DELETE_CLEAR:
      return {
        ...state,
        delete: {}
      };

    case types.BILLING_POLICY_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          processing: true
        }
      }

    case types.BILLING_POLICY_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          processing: false,
          success: true,
          data: action.payload
        }
      }

    case types.BILLING_POLICY_HISTORY_FAILURE:
      return {
        ...state,
        history: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_POLICY_HISTORY_CLEAR:
      return {
        ...state,
        history: {}
      };

    default:
      return state;
  }
}
