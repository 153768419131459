import * as types from '../../actions/billing/types';

const defaultState = {
  load: {},
  adj: {},
  history: {},
  trans: {}
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case types.BILLING_LEDGER_LOAD_REQUEST:
      return {
        ...state,
        load: {
          processing: true,
        }
      };
    case types.BILLING_LEDGER_LOAD_SUCCESS:
      return {
        ...state,
        load: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.BILLING_LEDGER_LOAD_FAILURE:
      return {
        ...state,
        load: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.BILLING_LEDGER_LOAD_CLEAR:
      return {
        ...state,
        load: {}
      };


    case types.BILLING_LEDGER_ADJ_REQUEST:
      return {
        ...state,
        adj: {
          processing: true,
        }
      };
    case types.BILLING_LEDGER_ADJ_SUCCESS:
      return {
        ...state,
        adj: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.BILLING_LEDGER_ADJ_FAILURE:
      return {
        ...state,
        adj: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.BILLING_LEDGER_ADJ_CLEAR:
      return {
        ...state,
        adj: {}
      };

    case types.BILLING_LEDGER_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          processing: true,
        }
      };
    case types.BILLING_LEDGER_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.BILLING_LEDGER_HISTORY_FAILURE:
      return {
        ...state,
        history: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.BILLING_LEDGER_HISTORY_CLEAR:
      return {
        ...state,
        history: {}
      };

    case types.BILLING_LEDGER_TRANS_REQUEST:
      return {
        ...state,
        trans: {
          processing: true,
        }
      };
    case types.BILLING_LEDGER_TRANS_SUCCESS:
      return {
        ...state,
        trans: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.BILLING_LEDGER_TRANS_FAILURE:
      return {
        ...state,
        trans: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.BILLING_LEDGER_TRANS_CLEAR:
      return {
        ...state,
        trans: {}
      };



    default:
      return state;
  }
}
