const styles: Record<string, React.CSSProperties> = {
  root: {
    marginLeft: 'auto',
    marginRight: '0px',
    textAlign: 'right',
  },
  btn: {
    paddingRight: '0px',
  },
};

export default styles;
