import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CloseOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { List, Select, Input, DatePicker, Modal } from 'antd';
import moment from 'moment';

import { safe } from '../../../../../helpers/reduxSelectors';
import * as actions from '../../../../../actions/billing';
import { processEventNotification } from '../../../../../helpers/util';

class Recall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      renderInactive: false
    }
  }

  componentDidMount() {
    this.props.getRecallLists();
  }

  componentDidUpdate(prevProps) {
    processEventNotification(prevProps,this.props,'saveEvent','Recall Entry Saved');
    processEventNotification(prevProps,this.props,'createEvent','Recall Entry Created');
    processEventNotification(prevProps,this.props,'deactivateEvent','Recall Entry Closed');
    processEventNotification(prevProps,this.props,'reactivateEvent','Recall Entry Reopened');
  }

  closeListItem = (item_id) => {
    this.props.saveRecallPatientItem(this.props.patientId,item_id,{status:2});
  }

  activateRecallModal = (item_id) => {
    this.props.form.resetFields();
    this.setState({
      modalVisible: true,
      modalItemId: item_id
    });
  }

  onModalClose = () => {
    this.setState({
      modalVisible: false
    });
  }


  onModalOk = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.modalItemId) {
          this.props.saveRecallPatientItem(this.props.patientId,this.state.modalItemId,values);
        } else {
          this.props.createRecallPatientItem(this.props.patientId,values);
        }
        this.onModalClose();
      }
    });
  }

  renderRecallModal = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };

    const initialValues = {};
    if (this.state.modalItemId) {
      const r = this.props.data.find(x=>x.id === this.state.modalItemId);
      if (r) {
        initialValues.title = r.title;
        initialValues.list_id = r.list_id;
        initialValues.recall_date = r.recall_date;
        initialValues.status = r.status;
      }
    }

    return (
      <Modal
        title='Recall List Item'
        visible={this.state.modalVisible}
        onCancel={this.onModalClose}
        onOk={this.onModalOk}
        okText='Save'
      >
        <Form className='recall-entry-modal' autoComplete='off'>
          <FormItem {...formItemLayout} label="Title">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: " ", whitespace: true }],
              initialValue: initialValues.title
            })(
              <Input.TextArea rows={3} />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="List">
            {getFieldDecorator('list_id', {
              rules: [{ required: true, message: " " }],
              initialValue: initialValues.list_id
            })(
              <Select>
                { this.props.listData.map(z=><Option key={z.id} value={z.id}>{z.name}</Option>) }
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Recall Date">
            {getFieldDecorator('recall_date', {
              rules: [{ required: true, message: " " }],
              initialValue: (initialValues.recall_date) ? moment(initialValues.recall_date) : moment().add(7, 'days')
            })(
              <DatePicker format='M/DD/YYYY' />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Status">
            {getFieldDecorator('status', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(initialValues,'status',1)
            })(
              <Select>
                <Option value={1}>Open</Option>
                <Option value={2}>Closed</Option>
              </Select>
            )}
          </FormItem>

        </Form>
      </Modal>
    );
  }

  getActions = (item) => {
    if (item.status === 1) {
      return [
        <a onClick={()=>this.activateRecallModal(item.id)}><EditOutlined /></a>,
        <a onClick={()=>this.closeListItem(item.id)}><CloseOutlined /></a>
      ];
    } else {
      return [
        <a onClick={()=>this.activateRecallModal(item.id)}><EditOutlined /></a>
      ];
    }
  }

  renderListItem = (item) => {
    const closed = (item.status === 2) ? 'CLOSED ' : '';
    return (
      <List.Item actions={this.getActions(item)}>
        <List.Item.Meta
          title={item['list.name']}
          description={<span>
            <span style={{fontWeight:'bold'}}>{closed + moment(item['recall_date']).format('MM/DD/YYYY')}</span>
            : {item.title}
          </span>}
        />
      </List.Item>
    );
  }

  toggleInactive = () => {
    this.setState({
      renderInactive: !this.state.renderInactive
    });
  }

  renderInactive = () => {
    const inactiveItems = this.props.data.filter(x=>x.status===2);
    if (inactiveItems.length > 0) {
      const text = (this.state.renderInactive) ? 'Hide closed items...' : 'Show closed items...';
      return (
        <div>
          <a onClick={this.toggleInactive}>{text} ({inactiveItems.length})</a>
        </div>
      );
    }
    return null;
  }

  render() {
    let items = this.props.data;
    if (!this.state.renderInactive) {
      items = this.props.data.filter(x=>x.status===1);
    }

    return (
      <div>

        <div style={{textAlign:'center',paddingBottom: '10px'}}>
          <a onClick={()=>this.activateRecallModal(null)}><PlusCircleOutlined /> Add New Recall Item</a>
        </div>

        <List itemLayout="horizontal" dataSource={items} renderItem={this.renderListItem} />

        { this.renderInactive() }
        { this.renderRecallModal() }

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    listData: safe(state,'billing.recall.lists_list.data',[]),
    saveEvent: safe(state,'billing.recall.patient_save',{}),
    createEvent: safe(state,'billing.recall.patient_create',{}),
    deactivateEvent: safe(state,'billing.recall.items_deactivate',{}),
    reactivateEvent: safe(state,'billing.recall.items_reactivate',{})
  }
}

export default connect(mapStateToProps,actions)(Form.create()(Recall));
