import React from 'react';
import { connect } from 'react-redux';
import MailOutlined from '@ant-design/icons/MailOutlined';
import { Modal, Spin, Button } from 'antd';
import moment from 'moment';

import * as actions from '../../../../../actions/emr';
import { safe } from '../../../../../helpers/reduxSelectors';

const TYPE_APPT_SCHEDULED = 10;
const TYPE_APPT_SCHEDULED_RECURRING = 11;
const TYPE_APPT_DELETED = 12;
const TYPE_APPT_DELETED_RECURRING = 13;
const TYPE_APPT_LATECANCEL = 14;
const TYPE_APPT_RESCHEDULED = 15;
const TYPE_APPT_REMINDER = 16;
const TYPE_APPT_DELETED_FOR_DOCTOR_VACATION = 17;
const TYPE_APPT_SCHEDULED_CHILDINTAKE = 18;

const STATUS_QUEUED = 1;
const STATUS_INPROCESS = 2;
const STATUS_SENT = 3;
const STATUS_CANCELLED = 4;
const STATUS_FAILED = 5;

const METHOD_EMAIL = 1;
const METHOD_SMS = 2;
const METHOD_NONE = 3;

class PatientNotifications extends React.Component {
  state = {
    visible: false,
    selectedRow: null,
  };

  showDialog = () => {
    this.setState(
      {
        visible: true,
        selectedRow: null,
      },
      () => {
        this.props.getPatientNotificationHistory(this.props.patient_id);
      },
    );
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      selectedRow: null,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
      selectedRow: null,
    });
  };

  getNotificationType = (x) => {
    switch (x) {
      case TYPE_APPT_SCHEDULED: return 'APPT SCHEDULED';
      case TYPE_APPT_SCHEDULED_RECURRING: return 'RECURRING APPT SCHEDULED';
      case TYPE_APPT_DELETED: return 'APPT CANCELLED';
      case TYPE_APPT_DELETED_RECURRING: return 'RECURRING APPT CANCELLED';
      case TYPE_APPT_LATECANCEL: return 'APPT LATE-CANCELLED';
      case TYPE_APPT_RESCHEDULED: return 'APPT RESCHEDULED';
      case TYPE_APPT_REMINDER: return 'APPT REMINDER';
      case TYPE_APPT_DELETED_FOR_DOCTOR_VACATION: return 'APPT PTO CANCEL';
      case TYPE_APPT_SCHEDULED_CHILDINTAKE: return 'APPT CHILD INTAKE';
      default: return '-';
    }
  };

  getNotificationMethod = (x) => {
    switch (x) {
      case METHOD_EMAIL:
        return 'EMAIL';
      case METHOD_SMS:
        return 'SMS';
      case METHOD_NONE:
        return 'NOT-SENT';
      default:
        return '-';
    }
  };

  getNotificationStatus = (x) => {
    switch (x) {
      case STATUS_QUEUED:
        return 'QUEUED';
      case STATUS_INPROCESS:
        return 'IN-PROCESS';
      case STATUS_SENT:
        return 'SENT';
      case STATUS_CANCELLED:
        return 'CANCELLED';
      case STATUS_FAILED:
        return 'FAILED';
      default:
        return '-';
    }
  };

  displayEmail = () => {
    if (this.state.selectedRow) {
      const { method, data } = this.state.selectedRow;

      const is_sms = method === METHOD_SMS;

      const from = method === METHOD_SMS ? safe(data, 'sms_from') : safe(data, 'email_from');
      const to = method === METHOD_SMS ? safe(data, 'sms_to') : safe(data, 'email_to');
      const subject = method === METHOD_SMS ? '' : safe(data, 'email_title');
      const content = method === METHOD_SMS ? safe(data, 'sms_content') : safe(data, 'email_content');

      return (
        <div
          style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ddd', marginTop: '10px', padding: '5px' }}
        >
          <div>Method: {this.getNotificationMethod(method)}</div>
          <div>From: {from}</div>
          <div>To: {to}</div>
          {!is_sms && <div>Subject: {subject}</div>}

          <div>
            <div>Content: </div>
            <iframe title="Email History" srcDoc={content} width="100%" frameBorder={0} height={400} />
          </div>
        </div>
      );
    } else {
      return <div style={{ height: '400px', border: '1px solid #ddd', marginTop: '10px' }}></div>;
    }
  };

  getPortalUser = (x) => {
    return x && x.name ? x.name : '';
  };

  getAddress = (x) => {
    if (x.method === METHOD_SMS) {
      return safe(x, 'data.sms_to', '');
    } else if (x.method === METHOD_EMAIL) {
      return safe(x, 'data.email_to', '');
    } else {
      return '-';
    }
  };

  selectRow(event, row) {
    this.setState({
      selectedRow: row,
    });
  }

  renderModalContent = () => {
    return (
      <div>
        <div style={{ height: '250px', overflowY: 'auto', border: '1px solid #ddd' }}>
          <table className="notifications-table" width="100%">
            <thead>
              <tr>
                <th>Created</th>
                <th>Method</th>
                <th>Portal User</th>
                <th>Status</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {this.props.data.map((x) => {
                const className =
                  this.state.selectedRow && x.id === this.state.selectedRow.id
                    ? 'notifications-selected-row'
                    : 'notifications-normal-row';
                return (
                  <tr className={className} key={x.id} onClick={(e) => this.selectRow(e, x)}>
                    <td>{moment(x.createdAt).format('MM/DD/YYYY hh:mm:ss')}</td>
                    <td>{this.getNotificationMethod(x.method)}</td>
                    <td>{this.getPortalUser(x.portal)}</td>
                    <td>{this.getNotificationStatus(x.status)}</td>
                    <td>{this.getNotificationType(x.type)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {this.displayEmail()}
      </div>
    );
  };

  render() {
    return (
      <Spin spinning={this.props.isBusy}>
        <Modal
          title="Patient Appointment Notifications"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          footer={null}
          width={800}
        >
          {this.renderModalContent()}
        </Modal>
        <div style={{ padding: '15px' }}>
          <Button onClick={this.showDialog} size="small" icon={<MailOutlined />} type="primary">
            Patient Appt Notification History
          </Button>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state, 'emr.notifications.list.processing') ? true : false,
    data: safe(state, 'emr.notifications.list.data', []),
  };
};

export default connect(mapStateToProps, actions)(PatientNotifications);
