import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Spin, message, Result, notification } from 'antd';
import { safe, isAdmin } from '../../../../helpers/reduxSelectors';

//import ChartNotes from './Chart_/ChartNotes';
import ChartNotesTable from './Chart_/ChartNotesTable';

import * as emr_actions from '../../../../actions/emr';
import * as shared_actions from '../../../../actions/shared';

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: parseInt(this.props.match.params.patient_id,10)
    }
  }

  reloadChart() {
    if (this.state.patientId !== 0) {
      this.props.getPatientChart(this.state.patientId);
    }
  }

  componentDidMount() {
    this.reloadChart();
    let element = document.getElementById('chartnotes');
    if (element) {
      element.scrollTop = 0;
    }
  }

  scrollDown = () => {
    let element = document.getElementById('chartnotes');
    if (element) {
      element.scrollTop = 500;
    }
  }

  componentDidUpdate(prevProps) {
    const patientId = parseInt(this.props.match.params.patient_id,0);
    if (patientId !== this.state.patientId) {
      this.setState({
        patientId: patientId
      }, () => {
        this.reloadChart()
      });
    }

    // previously chartpane, now chartnotes
    if (prevProps.chartIsLoaded !== this.props.chartIsLoaded && this.props.chartIsLoaded) {
      let element = document.getElementById('chartnotes');
      if (element) {
        element.scrollTop = 0;
      }
    }

    if (prevProps.chartLoadingError !== this.props.chartLoadingError && this.props.chartLoadingError) {
      this.props.history.replace('/emr/patients/0');
      notification.error({
        message: 'Unable to load chart',
        description: this.props.chartLoadingError.data.message
      });
    }

    if (prevProps.addendum !== this.props.addendum) {
      if (this.props.addendum.success) {
        this.props.noteCreateAddendumClear();
        this.props.getNotesList(this.state.patientId);
      } else if (this.props.addendum.error){
        this.props.noteCreateAddendumClear();
      }
    }

    if (prevProps.approve !== this.props.approve) {
      if (this.props.approve.success) {
        this.props.noteApproveClear();
        this.props.getNotesList(this.state.patientId);
      } else if (this.props.approve.error){
        this.props.noteApproveClear();
        message.error("Unable to approve progress note")
      }
    }

    if (prevProps.reject !== this.props.reject) {
      if (this.props.reject.success) {
        this.props.noteRejectClear();
        this.props.getNotesList(this.state.patientId);
      } else if (this.props.reject.error) {
        this.props.noteRejectClear();
        this.props.getNotesList(this.state.patientId);
        message.error("Unable to return progress note to supervisee")
      }
    }
  }

  render() {

    if (this.state.patientId === 0) {
      return (
        <div>
          <Result
            title="No Chart Loaded"
            status='404'
          />
        </div>
      );
    } else {
      return (
        <div id='chartnotes' style={{overflowY:'scroll', height:'100%'}}>
          <Spin spinning={this.props.isBusy} size='large'>
            <ChartNotesTable
              noteList={this.props.noteList}
              userId={this.props.userId}
              noteListProcessing={this.props.chartIsProcessing}
              patientId={this.state.patientId}
            />
          </Spin>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    noteList: safe(state,'emr.note.list.data',[]),
    chartIsProcessing: safe(state,'emr.chart.fullload.processing',false),
    chartIsLoaded: safe(state,'emr.chart.fullload.success',false),
    chartLoadingError: safe(state,'emr.chart.fullload.error'),
    recentPatientList : safe(state,'shared.patient.recent.data',[]),
    userId: safe(state,'shared.auth.data.id',0),
    roles: safe(state,'shared.auth.data.roles',[]),
    isAdmin: isAdmin(state),
    isBusy: (
      safe(state,'emr.chart.fullload.processing',false) ||
      safe(state,'shared.patient.load.processing',false) ||
      safe(state,'shared.alert.load.processing',false) ||
      safe(state,'emr.note.load.processing',false) ||
      safe(state,'emr.note.approve.processing',false) ||
      safe(state,'emr.note.reject.processing',false) ||
      safe(state,'emr.note.addendum.processing',false)
    ) ? true : false,
    approve: safe(state,'emr.note.approve',{}),
    reject: safe(state,'emr.note.reject',{}),
    addendum: safe(state,'emr.note.addendum',{})
  }
}

export default connect(mapStateToProps,{...emr_actions,...shared_actions})(withRouter(Chart));
