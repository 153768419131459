import React from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/intakes';
import moment from 'moment';

class Registrations extends React.Component {
  state = {
    page_size: 50,
    current_page: 1
  }

  componentDidMount() {
    this.props.getIntakeList(this.state.current_page,this.state.page_size);
  }

  changePage = ({current}) => {
    this.setState({
      current_page: current
    },()=>{
      this.props.getIntakeList(this.state.current_page,this.state.page_size);
    });
  }

  render() {
    const columns = [{
      title: 'Reg #',
      dataIndex: 'id',
      key: 1
    },{
      title: 'Started',
      dataIndex: 'started',
      key: 2,
      render: (val) => {
        if (val) {
          const m = moment(val);
          if (m.isValid()) {
            return m.format('MM/DD/YY HH:mm:ss')
          }
        }
        return null;
      }
    },{
      title: 'Completed',
      dataIndex: 'completed',
      key: 3,
      render: (val) => {
        if (val) {
          const m = moment(val);
          if (m.isValid()) {
            return m.format('MM/DD/YY HH:mm:ss')
          }
        }
        return null;
      }
    }];

    return (
      <div style={{padding:'10px'}}>
        <h3>Scheduled Evals</h3>
        <Table
          dataSource={this.props.intake_list}
          columns={columns}
          size='small'
          onChange={this.changePage}
          rowKey='id'
          pagination={{
            current: this.state.current_page,
            pageSize: this.state.page_size,
            total: this.props.intake_list_count,
            showQuickJumper: true
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    intake_list: safe(state,'intakes.intake_list.data.rows',[]),
    intake_list_count: safe(state,'intakes.intake_list.data.count',0)
  }
}

export default connect(mapStateToProps,actions)(Registrations);
