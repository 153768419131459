import React from 'react';
import { connect } from 'react-redux';
import { Modal, Spin } from 'antd';
import moment from 'moment';
import numeral from 'numeral';

import * as actions from '../../../../../../actions/billing';
import { safe } from '../../../../../../helpers/reduxSelectors';

class EmailListDialog extends React.Component {
  state = {
    visible: false,
    selectedRow: null
  }

  showDialog = () => {
    this.setState({
      visible: true,
      selectedRow: null
    },()=>{
      this.props.getEmailStatements(this.props.patientId);
    });
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      selectedRow: null
    });
  }

  handleOk = () => {
    this.setState({
      visible: false,
      selectedRow: null
    });
  }

  getStatusString(x) {
    switch (x) {
      case 1: return 'QUEUED';
      case 2: return 'INPROCESS';
      case 3: return 'SENT';
      case 4: return 'CANCELLED';
      case 5: return 'FAILED';
      default: return '-';
    }
  }

  getTypeString(x) {
    switch (x) {
      case 1: return 'CC CHARGE';
      case 2: return 'NEW CHARGE';
      case 3: return 'CC REFUND';
      case 4: return 'BALANCE';
      default: return '-';
    }
  }

  displayEmail = () => {
    if (this.state.selectedRow) {
      return (
        <div style={{height:'200px',overflowY: 'scroll',border: '1px solid #ddd',marginTop:'10px'}}>
          <div>From: {safe(this.state,'selectedRow.data.fromAddress')}</div>
          <div>Subject: {safe(this.state,'selectedRow.data.title')}</div>
          <div>
            <iframe title='Email History' srcDoc={safe(this.state,'selectedRow.data.content')} width='100%' frameBorder={0} height={200}/>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{height: '200px',border: '1px solid #ddd', marginTop:'10px'}}>
        </div>
      )
    }
  }

  selectRow(event,row) {
    this.setState({
      selectedRow: row
    });
  }

  renderModalContent = () => {
    return (
      <div>
        <div style={{height:'300px',overflowY:'auto',border:'1px solid #ddd'}}>
          <table className='email-statements-table' width='100%'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Created</th>
                <th>Status</th>
                <th>Type</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {this.props.estatements.map(x=>{
                const className = (this.state.selectedRow && x.id === this.state.selectedRow.id) ? 'email-statements-selected-row' : 'email-statements-normal-row';
                return (
                  <tr className={className} key={x.id} onClick={(e)=>this.selectRow(e,x)}>
                    <td>{x.id}</td>
                    <td>{moment(x.createdAt).format('MM/DD/YYYY hh:mm:ss')}</td>
                    <td>{this.getStatusString(x.status)}</td>
                    <td>{this.getTypeString(x.type)}</td>
                    <td>{(x.balance) ? numeral(x.balance).format('$0,0.00') : ''}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        { this.displayEmail() }
      </div>
    )
  }

  render() {
    return (
      <div>
        <Spin spinning={this.props.isBusy}>
          <Modal
            title="Patient Billing Emails"
            visible={this.state.visible}
            onCancel={this.handleCancel}
            destroyOnClose={true}
            footer={null}
            width={700}
          >
            {this.renderModalContent()}
          </Modal>
          <a onClick={this.showDialog}>
            {this.props.children}
          </a>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.estatements.list.processing')
    ) ? true : false,
    estatements: safe(state,'billing.estatements.list.data',[])
  }
}

export default connect(mapStateToProps,actions)(EmailListDialog);
