import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Divider } from 'antd';
import { withRouter } from 'react-router-dom';

class RecentPatientList extends React.Component {
  static propTypes = {
    list: PropTypes.array
  }

  renderName = (x) => {
    let avatarLabel = undefined;
    //let backgroundColor = undefined;
    //let color='rgb(0,0,0)';
    if (x.on_hold && !x.open) {
      //backgroundColor = 'rgb(245,240,255)';
      avatarLabel = '@';
    } else if (x.on_hold) {
      //backgroundColor='rgb(255,241,240)';
//      avatarLabel = '';
    } else if (!x.open) {
      //backgroundColor='rgb(255,251,230)';
      avatarLabel = '@';
    }
    //<Avatar style={{color:color,backgroundColor:backgroundColor}} size='small'> </Avatar>
    if (avatarLabel) {
      return (<span>{avatarLabel} {x.full_name}</span>);
    } else {
      return (<span>{x.full_name}</span>);
    }
  }

  getUrlPrefix = () => {
    const paths = this.props.location.pathname.split('/');
    return (paths[2] === 'appointments') ? '/emr/appointments/' : '/emr/patients/';
  }

  render() {
    const urlPrefix = this.getUrlPrefix();
    if (this.props.list && this.props.list.length > 0) {
      return (
        <div style={{paddingLeft:'10px'}}>
          <Divider />
          <h4>Recent Patients</h4>
          <div style={{paddingTop: '0px', paddingLeft:'10px'}}>
            {this.props.list.map(a => (
              <div key={a.id} style={{padding:'3px',fontSize:'smaller'}}>
                <Link to={urlPrefix+a.id}>
                  {this.renderName(a)}
                </Link>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  }
}


export default withRouter(RecentPatientList);
