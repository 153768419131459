/* eslint-disable react/display-name */
/**
 * Director.tsx
 * 
 * Provider
 * C-Prod
 * Most Recent PP Prod (%prod for last 2 full weeks per pay period)
 * Productivity for 2 PPs before most recent
 * 
 * Current Term in One Column (Ex: 1/1/20-1/1/21)
 * Notes >2 Days
 * Notes >7 Days
 */
import { useState } from 'react';
import { Select, Spin, Tabs, notification } from 'antd';
import useFetch, { CachePolicies } from 'use-http';
import { useContextSelector } from 'use-context-selector';
import ProductivityTab from './Director_/Productivity';
import TimeoffTab from './Director_/Timeoff';
import NotesTab from './Director_/Notes';
import OppositeEnds from '../../shared/OppositeEnds';
import DirectorProvider from './Director_/DirectorProvider';
import DirectorContext from './Director_/DirectorContext';

const Option = Select.Option;
const TabPane = Tabs.TabPane;

const Director: React.FC = () => {
  const getProviderList = useContextSelector(DirectorContext, (c) => c.getProviderList);
  const [providerId, setProviderId] = useState<undefined | number>(undefined);
  const [filter, setFilter] = useState(1);
  const [trigger, setTrigger] = useState(false);
  const providerAddAPI = useFetch({ cachePolicy: CachePolicies.NO_CACHE });
  
  const isBusy = getProviderList.loading || providerAddAPI?.loading;
  const activeKey = filter.toString();

  const providerAdd = async (id: number) => {
    if (!providerAddAPI?.loading) {
      const response = await providerAddAPI?.post(`/api/v1/director/${id}`);
      if (response?.error) {
        notification.error({
          message: 'Problem adding provider',
          description: response?.message,
        });
      }
    }
  };

  const onSelectProvider = async (id) => {
    await providerAdd(id);
    setTrigger((t) => !t);
    setProviderId(undefined);
  };

  const onChange = (id) => {
    setProviderId(id);
  };

  const providerSearch = () => {
    return (
      <Select
        showSearch
        style={{ width: '300px' }}
        placeholder="Add a provider"
        optionFilterProp="children"
        onSelect={onSelectProvider}
        onChange={onChange}
        value={providerId}
        filterOption={(input, option) => {
          return option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
        }
      >
        {getProviderList.data?.map((x) => {
          return <Option key={x.id} value={x.id}>{x.full_name}</Option>;
        })}
      </Select>
    )
  };

  const onTabChange = (key) => {
    setFilter(parseInt(key));
  };

  return (
    <div style={{padding:'20px'}}>
      <Spin spinning={isBusy}>
        <OppositeEnds left={<h3>Director</h3>} right={(
          <>
            {providerSearch()}
          </>
        )} />
        <Tabs activeKey={activeKey} onChange={onTabChange}>
          <TabPane tab="Timeoff" key="0">
            <TimeoffTab active={activeKey === '0'} />
          </TabPane>
          <TabPane tab="Productivity" key="1">
            <ProductivityTab reload={trigger} active={activeKey === '1'}  />
          </TabPane>
          <TabPane tab="Notes" key="2">
            <NotesTab active={activeKey === '2'} />
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  );
};

const DirectorWrapper: React.FC = () => {
  return (
    <DirectorProvider>
      <Director />
    </DirectorProvider>
  );
};

export default DirectorWrapper;
