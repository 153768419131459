import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Select, Row, Col, Table, Modal } from 'antd';
import { safe } from '../../helpers/reduxSelectors';

import * as actions from '../../actions/shared';
import { isAdmin } from '../../helpers/reduxSelectors';

class Alerts extends Component {
  static propTypes = {
    alertData: PropTypes.array,
    patientId: PropTypes.number,
    visible: PropTypes.bool
  }

  onClickNewAlert = () => {
    const values = this.props.form.getFieldsValue();
    if (values.comments && values.comments.length > 0 && values.audience) {
      this.props.saveAlert(this.props.patientId,values)
    }
    this.props.form.resetFields();
  }

  onClickDeleteAlert = (alertId) => {
    this.props.deleteAlert(alertId);
  }

  audienceLabel = (x) => {
    switch (x) {
      case 1: return 'For Me';
      case 3: return 'Admin Only';
      case 2: return 'All';
      default: return '-';
    }
  }

  renderAlerts = () => {
    if (this.props.alertData.length === 0) {
      return null;
    }

    const data = this.props.alertData.map(x => {
      return {
        id: x.id,
        date: moment(x.createdAt).format('MM/DD/YYYY hh:mm'),
        creator: safe(x,'creator.full_name'),
        audience: this.audienceLabel(x.audience),
        comments: x.comments
      }
    });

    return (
      <div>
        <Table
          bordered={false}
          dataSource={data}
          showHeader={false}
          rowKey='id'
          pagination={false}
          columns={[{
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '30%',
            render: (text,row,index) => {
              return (<span>{text} ({row.audience})<br/>{row.creator}</span>);
            }
          },
          {
            title: 'Note',
            dataIndex: 'comments',
            key: 'comments',
            width: '60%'
          }, {
            title: 'Action',
            width: '10%',
            align: 'center',
            render: (text, row, index) => {
              return (
                <a onClick={() => {this.onClickDeleteAlert(row.id)}}>
                  <DeleteOutlined style={{fontSize:'14pt'}} />
                </a>
              );
            }
          }]}
        />
      </div>
    );
  }

  // Handle enter being pressed in text box.
  keyPress = (e) => {
    if(e.keyCode === 13){
       this.onClickNewAlert();
    }
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;

    return (
      <Form autoComplete='off'>
        <Row gutter={8}>
          <Col span={16}>
            <FormItem style={{marginBottom:'0px'}}>
              {getFieldDecorator('comments', {})(
                <Input onKeyDown={this.keyPress} autoComplete='off' placeholder='Enter alert text here'/>
              )}
            </FormItem>
          </Col>

          <Col span={5}>
            <FormItem style={{marginBottom:'0px'}}>
              {getFieldDecorator('audience', {initialValue:2})(
                <Select>
                  <Option value={1}>Only Me</Option>
                  {this.props.isAdmin && <Option value={3}>Admin Only</Option> }
                  <Option value={2}>All</Option>
                </Select>
              )}
            </FormItem>
          </Col>

          <Col span={3} style={{marginBottom:'0px'}}>
            <Button style={{width:'100%'}} type='primary' onClick={this.onClickNewAlert}>Save</Button>
          </Col>
        </Row>
      </Form>
    )
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        title='Alerts'
        onCancel={this.props.closeDialog}
        footer={null}
        width={800}
        bodyStyle={{padding:'5px',height:'450px',overflowY:'scroll'}}
      >
        <div>
          {this.renderForm()}
          {this.renderAlerts()}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAdmin: isAdmin(state)
  }
}

export default connect(mapStateToProps,actions)(Form.create()(Alerts));
