import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Table, Modal, Tabs, Spin } from 'antd';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/shared';
import { processEventNotification } from '../../../helpers/util';
import OppositeEnds from '../../shared/OppositeEnds';

class BillingLocations extends Component {

  componentDidMount() {
    this.props.getBillingLocationsList();
  }

  componentDidUpdate(prevProps) {
    processEventNotification(prevProps,this.props,'deactivate','Billing Location Deactivated');
    processEventNotification(prevProps,this.props,'reactivate','Billing Location Reactivated');
  }

  deactivate = (record) => {
    const This = this;

    Modal.confirm({
      title: 'Deactivate location: ' + record.address1 + '?',
      onOk() {
        This.props.billingLocationDeactivate(record.id)
      },
      onCancel() { }
    });
  }

  reactivate = (record) => {
    const This = this;

    Modal.confirm({
      title: 'Reactivate location: ' + record.address1 + '?',
      onOk() {
        This.props.billingLocationReactivate(record.id)
      },
      onCancel() { }
    });
  }

  renderActiveTable = () => {
    const columns = [{
      title: 'Address',
      dataIndex: 'address1',
      render: (value,record) => <Link to={'/settings/blocations/'+record.id}>{value}</Link>
    },{
      title: 'Address 2',
      dataIndex: 'address2'
    },{
      title: 'City',
      dataIndex: 'city',
    },{
      title: 'State',
      dataIndex: 'state'
    },{
      title: 'Zip',
      dataIndex: 'zip'
    },{
      tite: 'Action',
      width: 100,
      render: (text,record) => {
        return <a onClick={() => { this.deactivate(record) }}>Deactivate</a>;
      }
    }];

    const data = safe(this.props,'locationsList',[]).filter(item => item.active === true);

    return (
      <Table
        dataSource={data}
        columns={columns}
        rowKey="id"
        size='small'
        pagination={false}
      />
    );
  }

  renderInactiveTable = () => {
    const columns = [{
      title: 'Address',
      dataIndex: 'address1'
    },{
      title: 'Address 2',
      dataIndex: 'address2'
    },{
      title: 'City',
      dataIndex: 'city',
    },{
      title: 'State',
      dataIndex: 'state'
    },{
      title: 'Zip',
      dataIndex: 'zip'
    },{
      title: 'Deactivated',
      dataIndex: 'deactivatedAt',
      render: (value) => (new Date(value)).toLocaleString()
    },{
      tite: 'Action',
      width: 100,
      render: (text,record) => {
        return <a onClick={() => { this.reactivate(record) }}>Reactivate</a>;
      }
    }];

    const data = this.props.locationsList.filter(item => item.active === false);

    return (
      <Table
        dataSource={data}
        columns={columns}
        rowKey="id"
        size='small'
        pagination={false}
      />
    );
  }

  newServiceButton = () => {
    return (
      <Button
        type='primary'
        onClick={() => this.props.history.push('/settings/blocations/0')}
        disabled={this.props.isBusy}
      >
        Add New Billing Location
      </Button>
    );
  }

  render() {

    const TabPane = Tabs.TabPane;

    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <OppositeEnds left={<h3>Billing Locations</h3>} right={this.newServiceButton()} />
          <Tabs defaultActiveKey="1">
            <TabPane tab="Active" key="1">
              { this.renderActiveTable() }
            </TabPane>
            <TabPane tab="Inactive" key="2">
              { this.renderInactiveTable() }
            </TabPane>
          </Tabs>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'shared.billing_location.list.processing',false) ||
      safe(state,'shared.billing_location.deactivate.processing',false) ||
      safe(state,'shared.billing_location.reactivate.processing',false)
    ) ? true : false,
    locationsList: safe(state,'shared.billing_location.list.data',[]),
    deactivate: safe(state,'shared.billing_location.deactivate',{}),
    reactivate: safe(state,'shared.billing_location.reactivate',{})
  }
}

export default connect(mapStateToProps,actions)(BillingLocations);
