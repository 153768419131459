import React, {Component} from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, message, Spin, Select } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/shared';

class LocationGroupsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id,10),
      name: '',
      abbr: '',
      refs: [],
    }
  }

  componentDidMount() {
    this.props.getLocationsList();
    if (this.state.id !== 0) {
      this.props.locationGroupLoad(this.state.id);
    } else {
      this.props.locationGroupLoadClear();
      this.setState({
        name: '',
        abbr: '',
        refs: []
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load.success !== this.props.load.success && this.props.load.success) {
      this.setState({
        name: safe(this.props,'load.data.name',''),
        abbr: safe(this.props,'load.data.abbr',''),
        refs: safe(this.props,'load.data.refs',[])
      });
    }

    if (prevProps.load !== this.props.load) {
      if (this.props.load.error) {
        this.props.history.push('/settings/glocations');
        message.error('Unable to load location group information');
      }
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.success) {
        this.props.locationGroupSaveClear();
        this.props.history.push('/settings/glocations');
        message.success('Location group saved');
      } else if (this.props.error) {
        message.error('Unable to save location group');
      }
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        this.props.locationGroupCreateClear();
        this.props.history.push('/settings/glocations');
        message.success('Location Group Created');
      } else if (this.props.error) {
        message.error('Unable to save location group');
      }
    }
  }

  handleSubmit = (e) => {
    const values = {
      name: this.state.name,
      abbr: this.state.abbr,
      refs: this.state.refs
    }
    if (this.state.name.trim().length === 0) {
      return;
    }

    if (this.state.id === 0) {
      this.props.locationGroupCreate(values);
    } else {
      this.props.locationGroupSave(this.state.id,values);
    }
  }

  renderLocation = (x) => {
    const result = [];
    if (x.address1) {
      result.push(x.address1);
    }
    if (x.address2) {
      result.push(x.address2);
    }
    if (x.city) {
      result.push(x.city);
    }
    return <span>{result.join(', ')}</span>;
  }

  changeName = (e) => {
    this.setState({
      name: e.target.value
    });
  }

  changeAbbr = (e) => {
    this.setState({
      abbr: e.target.value
    });
  }

  required = () => {
    return <span style={{color:'#f5222d'}}>*</span>;
  }

  renderForm = () => {
    const included = [];
    const excluded = [];
    this.props.locationsList.forEach(x=>{
      if (x.id) {
        if (this.state.refs.includes(x.id)) {
          included.push(x);
        } else {
          excluded.push(x);
        }
      }
    });

    return (
      <div>
        <Row style={{marginTop:'10px'}}>
          <Col span={5} style={{textAlign:'right',verticalAlign: 'middle'}}>{this.required()} Name: </Col>
          <Col span={12} style={{marginLeft:'10px'}}><Input value={this.state.name} onChange={this.changeName}/></Col>
        </Row>

        <Row style={{marginTop:'10px'}}>
          <Col span={5} style={{textAlign:'right',verticalAlign: 'middle'}}>Abbreviation: </Col>
          <Col span={12} style={{marginLeft:'10px'}}><Input value={this.state.abbr} onChange={this.changeAbbr}/></Col>
        </Row>

        <Row style={{marginTop:'10px'}}>
          <Col span={5} style={{textAlign:'right'}}>Service Locations: </Col>
          <Col span={12} style={{marginLeft:'10px'}}>
            <table>
              <tbody>
                {
                  included.map(q => (
                    <tr key={q.id}>
                      <td>{ this.renderLocation(q) }</td>
                      <td><a onClick={()=>this.deleteLocation(q.id)}><DeleteOutlined /></a></td>
                    </tr>
                  ))
                }
                <tr>
                  <td>
                    <Select style={{width:'400px'}} value={null} onSelect={this.selectLocation}>
                      { excluded.map(y=><Select.Option key={y.id} value={y.id}>{this.renderLocation(y)}</Select.Option>) }
                      </Select>
                  </td>
                  <td>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <Row>
          <Col span={17}>
            <Button
              style={{float:'right'}}
              disabled={this.props.isLoading}
              type="primary"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  selectLocation = (id) => {
    if (!this.state.refs.includes(id)) {
      this.setState({
        refs: [...this.state.refs,id]
      });
    }
  }

  deleteLocation = (id) => {
    this.setState({
      refs: this.state.refs.filter(x=>x !== id)
    });
  }

  render() {
    const name = safe(this.props,'load.data.address1','None');

    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <h3>Location Group / {name}</h3>
          { this.renderForm() }
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'shared.location_group.load.processing',false) ||
      safe(state,'shared.location_group.save.processing',false) ||
      safe(state,'shared.location_group.create.processing',false)
    ) ? true : false,
    locationsList: safe(state,'shared.location.list.data',[]),
    load: safe(state,'shared.location_group.load',{}),
    save: safe(state,'shared.location_group.save',{}),
    create: safe(state,'shared.location_group.create',{}),
    update: safe(state,'shared.location_group.update',{})
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(LocationGroupsEdit)));
