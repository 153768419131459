import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, DatePicker, Button, Checkbox, Table, Spin } from 'antd';
import { HLayout, HLayoutRight, HLayoutContent } from '../../shared/Layout';
import OppositeEnds from '../../shared/OppositeEnds'
import moment from 'moment';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import FileDownload from 'js-file-download';
import Papa from 'papaparse';
import axios from 'axios';

import { safe } from '../../../helpers/reduxSelectors';
import * as shared_actions from '../../../actions/shared';
import * as billing_actions from '../../../actions/billing';
import { connect } from 'react-redux';

class ClaimSearch extends React.Component {
  state = {
    page: 1,
    pageSize: 50,
    downloadBusy: false
  }

  componentDidMount() {
    this.props.claimsSearchClear();
    this.props.getLocationsList();
    this.props.getUserList();
    this.props.getCarrierList();
    this.props.getCptList();
  }

  onSubmit = () => {
    this.props.form.validateFields((err,values) => {
      if (!err) {
        this.props.claimsSearch(values,this.state.page,this.state.pageSize);
      }
    });
  }

  // Do a try catch below and also check if the larger outside call fails.

  onDownload = async () => {
    const fields = this.props.form.getFieldsValue();
    this.setState({ downloadBusy: true });
    try {
      const data = await axios.post('/api/v1/claims/search',fields);
      const d = safe(data,'data.rows',[]);
      FileDownload(Papa.unparse(d),'claimsearch-'+moment().format('MMDDYYYYHHmmss')+'.csv');
    } catch (err) {

    }
    this.setState({ downloadBusy: false });
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;

    const now = moment();
    const sixMonthsAgo = moment().subtract(6,'months');

    const formItemLayout = {
      labelCol: {
        sm: { span: 8 },
      },
      wrapperCol: {
        sm: { span: 16 },
      },
    };

    return (
      <Form autoComplete="off" layout='horizontal'>
        <FormItem {...formItemLayout} label="Start DOS">
          {getFieldDecorator('start_date', {
            rules: [{ required: true} ],
            initialValue: sixMonthsAgo
          })(
            <DatePicker placeholder='Start DOS' format='MM/DD/YYYY' allowClear/>
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="End DOS">
          {getFieldDecorator('end_date', {
            rules: [{ required: true} ],
            initialValue: now
          })(
            <DatePicker placeholder='End DOS' format='MM/DD/YYYY' allowClear/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Provider">
          {getFieldDecorator('provider_id', {})(
            <Select allowClear>
              { this.props.providers.map(x=><Option key={'P'+x.id} value={x.id}>{x.full_name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Provider Type">
          {getFieldDecorator('provider_class', {
            initialValue: 0
          })(
            <Select allowClear>
              <Option value={0}>All Provider Types</Option>
              <Option value={1}>MD</Option>
              <Option value={2}>NP</Option>
              <Option value={3}>PhD</Option>
              <Option value={4}>MA</Option>
              <Option value={5}>Prescribers</Option>
              <Option value={6}>Therapists</Option>
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Carrier">
          {getFieldDecorator('carrier_id', {})(
            <Select allowClear>
              { this.props.carriers.map(x=><Option key={'C' + x.id} value={x.id}>{x.name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Locations">
          {getFieldDecorator('location_id', {})(
            <Select allowClear>
              { this.props.locations.map(x=><Option key={'L' + x.id} value={x.id}>{x.name}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="CPT Codes">
          {getFieldDecorator('cpt_codes', {})(
            <Select mode="tags" allowClear>
              { this.props.cpts.map(x=><Option key={'CP'+x.id} value={x.code}>{x.code}</Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Is Queued?">
          {getFieldDecorator('is_queued', {
            initialValue: false,
            valuePropName: 'checked'
          })(
            <Checkbox/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Insurance Balance?">
          {getFieldDecorator('has_ins_balance', {
            initialValue: true,
            valuePropName: 'checked'
          })(
            <Checkbox/>
          )}
        </FormItem>

        <OppositeEnds
          left={<Button onClick={() => this.props.form.resetFields() }>Reset</Button>}
          right={<div>
            <Button onClick={this.onDownload}>Download</Button> &nbsp;
            <Button type='primary' onClick={this.onSubmit}>Search</Button>
          </div>}
        />

      </Form>
    );
  }

  tableChange = (x) => {
    this.setState({
      page: x.current,
    },() => {
      const values = this.props.form.getFieldsValue();
      this.props.claimsSearch(values,this.state.page,this.state.pageSize);
    });
  }

  getTitle = () => {
    if (this.props.count === undefined) {
      return <h1>Claim Search</h1>;
    } else {
      return <h1>Claim Search ({this.props.count} results)</h1>;
    }
  }

  getTable = () => {
    const columns = [{
      title: 'DOS',
      dataIndex: 'dos',
      width: 120,
      render: (value) => moment(value).format('MM/DD/YYYY')
    },{
      title: 'Patient',
      width: 170,
      dataIndex: 'patient',
      render: (value,record) => {
        return <Link to={'/billing/patients/'+record.patient_id}>{value}</Link>
      }
    },{
      title: 'Provider',
      width: 170,
      dataIndex: 'provider',
    },{
      title: 'Carrier',
      dataIndex: 'carrier',
    },{
      title: 'Location',
      dataIndex: 'location',
    },{
      title: 'CPT',
      dataIndex: 'cpt_codes',
    },{
      title: 'Outstanding',
      dataIndex: 'ins_outstanding',
      align: 'right',
      render: (q) => {
        return (q || q===0) ? numeral(q).format('$0,0.00') : null;
      }
    }];

    if (this.props.count === undefined) {
      return null;
    }

    return (
      <Table
        columns={columns}
        dataSource={this.props.rows}
        bordered={false}
        size='small'
        onChange={this.tableChange}
        rowKey='id'
        pagination={{
          current: this.state.page,
          pageSize: this.state.pageSize,
          total: this.props.count,
          showQuickJumper: true,
        }}
      />
    );
  }

  render() {
    return (
      <HLayout>
        <HLayoutContent style={{padding: '10px'}}>
          { this.getTitle() }
          <Spin spinning={this.props.isBusy || this.state.downloadBusy}>
            { this.getTable() }
          </Spin>
        </HLayoutContent>
        <HLayoutRight style={{width: '450px',overflowY:'scroll', minWidth:'450px',borderLeft: '1px solid lightgray',padding:'10px'}}>
          { this.renderForm() }
        </HLayoutRight>
      </HLayout>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'shared.carrier.list.processing',false) ||
      safe(state,'shared.location.list.processing',false) ||
      safe(state,'shared.user.list.processing',false) ||
      safe(state,'billing.cpt.list.processing',false) ||
      safe(state,'billing.claims.search.processing',false)
    ) ? true : false,
    carriers: safe(state,'shared.carrier.list.data',[]),
    locations: safe(state,'shared.location.list.data',[]),
    providers: safe(state,'shared.user.list.data',[]).filter(x=>x.credential > 0 && x.active),
    cpts: safe(state,'billing.cpt.list.data',[]),
    rows: safe(state,'billing.claims.search.data.rows',[]),
    count: safe(state,'billing.claims.search.data.count'),
  }
};

export default connect(mapStateToProps,{...billing_actions, ...shared_actions})(Form.create()(ClaimSearch));
