import { combineReducers } from 'redux';
import { ajaxReducer, ajaxReducerPreserveState } from '../../helpers/util';

import billingCommentReducer from './billingCommentReducer';
import billingLedgerReducer from './billingLedgerReducer';
import billingStatementsReducer from './billingStatementsReducer';
import billingArReducer from './billingArReducer';
import billingPolicyReducer from './billingPolicyReducer';
import billingInsPaymentReducer from './billingInsPaymentReducer';
import billingPtPaymentReducer from './billingPtPaymentReducer';
import billingServiceLineReducer from './billingServiceLineReducer';
import recallReducer from './recallReducer';
import eraReducer from './eraReducer';
import creditCardReducer from './creditCardReducer';
import billingRecentReducer from './billingRecentReducer';

import cptReducer from './cptReducer';
import productReducer from './productReducer';
import payerReducer from './payerReducer';
import tpaReducer from './tpaReducer';
//import ratetableReducer from './ratetableReducer';
import diagnosisReducer from './diagnosisReducer';

export default combineReducers({
  attachments: combineReducers({
    list: ajaxReducer('BILLING_ATTACHMENTS_LIST'),
    create: ajaxReducerPreserveState('BILLING_ATTACHMENTS_CREATE'),
    rename: ajaxReducer('BILLING_ATTACHMENTS_RENAME'),
    delete: ajaxReducer('BILLING_ATTACHMENTS_DELETE')
  }),
  ratetable: combineReducers({
    load: ajaxReducer('RATETABLE_LOAD'),
    save: ajaxReducer('RATETABLE_SAVE'),
    create: ajaxReducer('RATETABLE_CREATE'),
    list: ajaxReducer('RATETABLE_LIST'),
    deactivate: ajaxReducer('RATETABLE_DEACTIVATE'),
    reactivate: ajaxReducer('RATETABLE_REACTIVATE')
  }),
//  ratetable: ratetableReducer,
  comments: billingCommentReducer,
  ledger: billingLedgerReducer,
  policies: billingPolicyReducer,
  statements: billingStatementsReducer,
  ar: billingArReducer,
  inspayments: billingInsPaymentReducer,
  ptpayments: billingPtPaymentReducer,
  serviceline: billingServiceLineReducer,
  diagnosis: diagnosisReducer,
  recall: recallReducer,
  era: eraReducer,
  clearinghouse: combineReducers({
    list: ajaxReducer('CLEARINGHOUSE_LIST'),
    load: ajaxReducer('CLEARINGHOUSE_LOAD'),
    generate: ajaxReducer('CLEARINGHOUSE_GENERATE'),
    generatelist: ajaxReducer('CLEARINGHOUSE_GENERATELIST'),
    delete: ajaxReducer('CLEARINGHOUSE_DELETE'),
    submit: ajaxReducer('CLEARINGHOUSE_SUBMIT'),
    regenerate: ajaxReducer('CLEARINGHOUSE_REGENERATE'),
    process: ajaxReducer('CLEARINGHOUSE_PROCESS')
  }),
  cc: creditCardReducer,
  recent: billingRecentReducer,
  cpt: cptReducer,
  product: productReducer,
  payer: payerReducer,
  tpa: tpaReducer,
  queue: ajaxReducer('BILLING_QUEUE_SET'),
  queue_list: ajaxReducer('BILLING_QUEUED_SERVICES'),
  authorizations: combineReducers({
    list: ajaxReducer('AUTHORIZATION_LIST'),
    update: ajaxReducer('AUTHORIZATION_UPDATE'),
    create: ajaxReducer('AUTHORIZATION_CREATE'),
    delete: ajaxReducer('AUTHORIZATION_DELETE')
  }),
  ptapplylist: combineReducers({
    list: ajaxReducer('BILLING_PTAPPLYLIST_LIST'),
    apply: ajaxReducer('BILLING_PTAPPLYLIST_APPLY')
  }),
  autopay: combineReducers({
    set: ajaxReducer('AUTOPAY_SET'),
    list: ajaxReducer('BILLING_AUTOPAYLIST')
  }),
  claims: combineReducers({
    list: ajaxReducer('CLAIMS_LIST'),
    search: ajaxReducer('CLAIMS_SEARCH'),
  }),
  paymentdetail: ajaxReducer('PAYMENT_DETAIL'),
  estatements: combineReducers({
    list: ajaxReducer('BILLING_EMAILSTATEMENTS_LIST')
  })
});
