import React from 'react';
import { Spin, Modal, Table } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import * as actions from '../../../../../../actions/billing';
import { safe } from '../../../../../../helpers/reduxSelectors';

class PolicyHistoryDialog extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.getBillingPoliciesHistory(this.props.patientId);
    }
  }

  handleCancel = () => {
    this.props.hideDialog();
  }

  render() {
    const columns=[{
      title: 'Date',
      dataIndex: 'createdAt',
      width: 170,
      render: (value) => {
        return moment(value).format('MM/DD/YYYY HH:mm:ss');
      }
    },{
      title: 'Action',
      dataIndex: 'action',
      width: 80,
      render: (value) => {
        if (value === 1) {
          return 'CREATE';
        } else if (value === 2) {
          return 'UPDATE';
        } else {
          return 'DELETE';
        }
      }
    },{
      title: 'Policy',
      dataIndex: 'billing_paypolicy_id',
      width: 80
    },{
      title: 'Author',
      dataIndex: 'creator_id',
      width: 80,
      render: (value,record) => {
        return safe(record,'creator.initials','');
      }
    },{
      title: 'Changes',
      render: (value,record) => {
        return (
          <div>
            { Object.keys(record.changed_fields).map(x=>{
              return <div>
                <span style={{fontWeight:'bold'}}>{record.changed_fields[x].title}</span>:
                {record.changed_fields[x].before + "->" + record.changed_fields[x].after}</div>;
            }) }
          </div>
        );
      }
    }];

    return (
      <Modal
        title="Payment Policy Change History"
        visible={this.props.visible}
        destroyOnClose={true}
        onCancel={this.handleCancel}
        footer={null}
        width={800}
        bodyStyle={{paddingLeft:'0px',paddingRight:'0px',height:'450px',overflowY:'scroll'}}
      >
        <Spin spinning={this.props.isBusy}>
          <Table
            columns={columns}
            dataSource={this.props.data}
            size='small'
            pagination={false}
            className='sl_history_table'
            style={{verticalAlign:'top'}}
          />
        </Spin>
      </Modal>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: safe(state,'billing.policies.history.data',[]),
    isBusy: (
      safe(state,'billing.policies.history.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(PolicyHistoryDialog);
