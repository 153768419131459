import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Checkbox, Row, Col, Button } from 'antd';
import moment from 'moment';
import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/emr';

class ChartOutput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId:parseInt(this.props.match.params.patient_id,10),
    }
  }

  componentDidMount() {
    this.props.getChartParameters(this.state.patientId);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const s = values.start_date.format('YYYY-MM-DD');
        const e = values.end_date.format('YYYY-MM-DD');
        const p = values.providers.join('-')
        const url = '/display/chart/'+this.state.patientId+'?s='+s+'&e='+e+'&p='+p;
        var win = window.open(url, '_blank');
        win.focus();
        this.props.history.push('/emr/patients/'+this.state.patientId);
      }
    });
  }

  renderOtherFields() {
    const CheckboxGroup = Checkbox.Group;
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };

    if (!this.props.chartParamsLoaded) {
      return null;
    }

    if (!this.props.chartParams.startDate || !this.props.chartParams.endDate ||
        !this.props.chartParams.providers.length === 0) {
      return null;
    }

    const data = this.props.chartParams;

    return (
      <div>
        <Form onSubmit={this.handleSubmit} autoComplete="off">

          <FormItem {...formItemLayout} label="Starting Date">
            {getFieldDecorator('start_date', {
              rules: [{ required: true, message: "Please enter a starting date" }],
              initialValue: moment(data.startDate)
            })(
              <DatePicker format='M/DD/YYYY' />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Ending Date">
            {getFieldDecorator('end_date', {
              rules: [{ required: true, message: "Please enter an ending date" }],
              initialValue: moment(data.endDate)
            })(
              <DatePicker format='M/DD/YYYY' />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Providers">
            {getFieldDecorator('providers', {
              rules: [{ required: true, message: "Please choose at least one provider" }]
            })(
              <CheckboxGroup style={{ width: '100%'}}>
                <Row>
                  { this.props.chartParams.providers.map(a => (
                    <Col key={a.id}>
                      <Checkbox value={a.id}>&nbsp;
                      {a.name} ({moment(a.start).format('M/D/YYYY')}-{moment(a.end).format('M/D/YYYY')})
                      </Checkbox>
                    </Col>
                  )) }
                </Row>
              </CheckboxGroup>
            )}
          </FormItem>

          <Row>
            <Col xs={36} sm={16}>
              <Button
                htmlType="submit"
                style={{float:'right'}}
                disabled={this.props.isBusy}
                type="primary"
              >
                Display Chart
              </Button>
            </Col>
          </Row>

        </Form>
      </div>
    );
  }

  render() {
    return (
      <div style={{padding:'15px'}}>
        <h3>Chart Output</h3>
        {this.renderOtherFields()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'emr.chart.params.processing',false)
    ) ? true : false,
    chartParams: safe(state,'emr.chart.params.data',{}),
    chartParamsLoaded: safe(state,'emr.chart.params.success',false)
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(ChartOutput)));
