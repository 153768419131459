import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CloseOutlined, SearchOutlined, TagTwoTone, UploadOutlined } from '@ant-design/icons';
import {
  Table,
  Radio,
  Upload,
  Button,
  message,
  notification,
  DatePicker,
  Input,
  InputNumber,
  Select,
} from 'antd';
import moment from 'moment';
import numeral from 'numeral';

import * as billing_actions from '../../../actions/billing';
import * as shared_actions from '../../../actions/shared';
import { safe, getBillingState } from '../../../helpers/reduxSelectors';

class Era extends Component {

  componentDidMount() {
    this.props.getPayerList();
    this.props.getERAList(this.props.era_page,this.props.era_pagesize,
      this.gatherSearchParams());
  }

  tableChange = ({current}) => {
    this.props.setBillingState({
      era_page: current
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.era_page !== this.props.era_page ||
        prevProps.era_type !== this.props.era_type) {
      this.props.getERAList(
        this.props.era_page,
        this.props.era_pagesize,
        this.gatherSearchParams()
      );
    }
  }

  gatherSearchParams = () => {
    return {
      era_search_check_date: (this.props.era_search_check_date) ? moment(this.props.era_search_check_date,'MM/DD/YYYY').format('MM/DD/YYYY') : undefined,
      era_search_check_num: this.props.era_search_check_num,
      era_search_amount: this.props.era_search_amount,
      era_search_payer_name: this.props.era_search_payer_name,
      era_type: this.props.era_type
    };
  }

  resetSearchbar = () => {
    this.props.setBillingState({
      era_search_check_date: undefined,
      era_search_check_num: undefined,
      era_search_amount: undefined,
      era_search_payer_name: undefined,
      era_page: 1
    });
    if (this.props.era_page === 1) {
      this.props.getERAList(1,this.props.era_pagesize,{
        era_type: this.props.era_type
      });
    }
  }

  search = () => {
    if (this.props.era_page === 1) {
      this.props.getERAList(
        this.props.era_page,
        this.props.era_pagesize,
        this.gatherSearchParams()
      );
    } else {
      // Setting the page back to 1 causes it to automatically reload unless
      // it's already at 1!
      this.props.setBillingState({
        era_page: 1
      });
    }
  }

  renderSearchBar = () => {
    const Option = Select.Option;

    const columns = [{
      title: 'Check Date',
      dataIndex: 'check_date',
      align:'left',
      width: 150,
      render: (value,record) => {
        return (
          <DatePicker
            style={{width:'100%'}}
            placeholder='Check Date'
            format='MM/DD/YYYY'
            value={this.props.era_search_check_date}
            onChange={(value)=>{this.props.setBillingState({era_search_check_date:value})}}
          />
        );
      }
    },{
      title: 'Check #',
      dataIndex: 'check_num',
      width: 175,
      render: (value,record) => {
        return (
          <Input
            value={this.props.era_search_check_num}
            placeholder='Check #'
            onChange={({target})=>{this.props.setBillingState({era_search_check_num:target.value})}}
            style={{width:'100%'}}
          />
        );
      }
    },{
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      align: 'right',
      render: (value,record) => {
        return (
          <InputNumber
            style={{width:'100%'}}
            placeholder='Amount'
            value={this.props.era_search_amount}
            onChange={(value)=>{this.props.setBillingState({era_search_amount:value})}}
          />
        );
      }
    },{
      title: 'Payer',
      dataIndex: 'payer_id',
      align: 'left',
      render: (value,record) => {
        return (
          <Select
            style={{width:'100%'}}
            value={this.props.era_search_payer_name}
            placeholder='Payer'
            onChange={(value)=>{this.props.setBillingState({era_search_payer_name:value})}}
          >
            { this.props.payerList.map(x=><Option value={x.name} key={x.id}>{x.name}</Option>) }
          </Select>
        );
      }
    },{
      align: 'right',
      width: 60,
      render: (value,record) => {
        return (
          <span style={{width:'100%',whiteSpace:'nowrap'}}>
            <Button onClick={this.resetSearchbar}><CloseOutlined /></Button>&nbsp;
            <Button onClick={this.search} type='primary'><SearchOutlined /></Button>&nbsp;
          </span>
        );
      }
    }];

    return (
      <Table
        bordered={false}
        rowKey='id'
        columns={columns}
        size='small'
        dataSource={[{}]}
        pagination={false}
        showHeader={false}
        className='table-nohighlight'
      />
    );
  }

  onEraTypeChange = (event) => {
    this.props.setBillingState({
      era_type: event.target.value,
      era_page: 1
    });
  }

  tagItem = (id,value) => {
    this.props.tagERA(id,value,this.props.era_page,this.props.era_pagesize,
      this.gatherSearchParams());
  }

  render() {
    const columns = [{
      width: 30,
      render: (value,record) => {
        return (record.tagged) ? <TagTwoTone twoToneColor="#eb2f96" /> : null;
      }
    },{
      title: 'Status',
      dataIndex: 'status',
      width: 80,
      render: (value) => {
        if (value === 1) {
          return 'NEW';
        } else if (value === 2) {
          return 'POSTED';
        }
      }
    },{
      title: 'Upload Date',
      dataIndex: 'createdAt',
      width: 110,
      render: (value) => {
        return moment(value).format('MM/DD/YYYY');
      }
    },{
      title: 'Filename',
      dataIndex: 'filename',
      render: (text,record) => {
        return <Link to={'/billing/era/reconcile/'+record.id}>{text}</Link>
      }
    },{
      title: 'Check Date',
      dataIndex: 'check_date',
      width: 110,
      render: (value) => {
        return moment(value).format('MM/DD/YYYY');
      }
    },{
      title: 'Payer',
      dataIndex: 'payer_name',
    },{
      title: 'Payer EIN',
      dataIndex: 'payer_ein',
      width: 150
    },{
      title: 'Summary',
      dataIndex: 'filenae',
      render: (text,record) => {
        return <Link to={'/billing/era/detail/'+record.id}>Summary</Link>
      }
    },{
      title: 'Check #',
      dataIndex: 'check_num',
      width: 160
    },{
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      align:'right',
      render: (value) => {
        return numeral(value).format('$0,0.00');
      }
    },{
      title: 'Action',
      dataIndex: 'tagged',
      render: (value,record) => {
        if (value) {
          return <Button onClick={()=>{this.tagItem(record.id,0)}}>Untag</Button>
        } else {
          return <Button onClick={()=>{this.tagItem(record.id,1)}}>Tag</Button>
        }
      }
    }];

    const uploadProps = {
      action: '/api/v1/era',
      showUploadList: false,
      onChange: (info) => {
        if (info.file.status === 'done') {
          let output = [];
          info.file.response.forEach(r => {
            output.push(((r.status) ? 'Processed' : 'Skipped') + ": " + r.file);
          })
          notification['info']({
            message: 'ERA Processing Results',
            description: output.join('\n')
          });
          this.props.getERAList(this.props.era_page,this.props.era_pagesize,this.props.era_type);
        } else if (info.file.status === 'error') {
          message.error('Error uploading EDI 835 file');
        }
      },
    };

    const data = safe(this.props,'eraList.rows',[]);
    const count = safe(this.props,'eraList.count',0);

    return (
      <div className='main'>
        <h3>ERA</h3>
        <div>
          <div style={{paddingBottom:'20px'}}>
            <Radio.Group value={this.props.era_type} onChange={this.onEraTypeChange} buttonStyle="solid">
              <Radio.Button value={1}>NEW</Radio.Button>
              <Radio.Button value={2}>POSTED</Radio.Button>
              <Radio.Button value={3}>ALL</Radio.Button>
              <Radio.Button value={4}>TAGGED</Radio.Button>
            </Radio.Group>
            <Upload {...uploadProps} style={{paddingLeft:'50px'}}>
              <Button>
                <UploadOutlined /> Upload Individual 835 EDI File or 835 Zip File
              </Button>
            </Upload>
          </div>
        </div>

        { this.renderSearchBar() }

        <Table
          columns={columns}
          dataSource={data}
          rowKey='id'
          size='small'
          onChange={this.tableChange}
          pagination={{
            showQuickJumper: true,
            position: 'bottom',
            current: this.props.era_page,
            pageSize: this.props.era_pagesize,
            total: count
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    era_page: getBillingState(state,'era_page',1),
    era_pagesize: getBillingState(state,'era_pagesize',50),
    era_type: getBillingState(state,'era_type',1),
    eraList: safe(state,'billing.era.list.data',{}),

    payerList: safe(state,'billing.payer.list.data',[]),

    era_search_check_date: safe(state,'shared.domain.billing.era_search_check_date'),
    era_search_check_num: safe(state,'shared.domain.billing.era_search_check_num'),
    era_search_amount: safe(state,'shared.domain.billing.era_search_amount'),
    era_search_payer_name: safe(state,'shared.domain.billing.era_search_payer_name'),
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(Era);
