import React from 'react';
import { Table, Tag, Spin } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import * as actions from '../../../../actions/scheduler';
import * as shared_actions from '../../../../actions/shared';
import { safe } from '../../../../helpers/reduxSelectors';
import HistoryTemplate from './History_/HistoryTemplate';
import HistoryTemplateItem from './History_/HistoryTemplateItem';
import HistoryTerm from './History_/HistoryTerm';
import HistoryBio from './History_/HistoryBio';

class History extends React.Component {
  state = {
    page: 1,
    pagesize: 50
  }

  componentDidMount() {
    this.props.loadHistory(this.props.provider_id,this.state.page,this.state.pagesize);
    this.props.getLocationsList();
  }

  reload = () => {
    this.props.loadHistory(this.props.provider_id,this.state.page,this.state.pagesize);
  }

  componentDidUpdate(prevProps,prevState) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.reload();
    }

    if (prevProps.provider_id !== this.props.provider_id && this.props.provider_id !== 0) {
      this.setState({
        page: 1
      },this.reload);
    }

    if (prevState.page !== this.state.page) {
      this.reload();
    }
  }

  changePage = ({current}) => {
    this.setState({
      page: current
    });
  }

  getTypeName = (table) => {
    switch (table) {
      case 'schedule_templates': return 'TEMPLATE';
      case 'schedule_template_items': return 'TEMPLATE ITEM';
      case 'schedule_terms': return 'TERM';
      case 'provider_bios': return 'WEB';
      default: return 'OTHER';
    }
  }

  render() {
    const columns = [{
      title: 'Type',
      key: 'table_name',
      dataIndex: 'table_name',
      width: 150,
      render: (value) => {
        return (
          <span style={{whiteSpace:'nowrap'}}>
            { this.getTypeName(value) }
          </span>
        );
      }
    },{
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 150,
      render: (value) => {
        switch (value) {
          case 1: return <Tag color='green'>New</Tag>;
          case 2: return <Tag color='blue'>Changed</Tag>;
          case 3: return <Tag color='red'>Removed</Tag>;
          default: return <Tag color='purple'>Other</Tag>;
        }
      }
    },{
      title: 'Time/Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 150,
      render: (value) => {
        return (
          <span style={{whiteSpace: 'nowrap'}}>
            {moment(value).format('MM/DD/YYYY HH:mm:ss')}
          </span>
        );
      }
    },{
      title: 'User',
      key: 'user',
      dataIndex: 'actor_id',
      width: 200,
      render: (value,record) => {
        return (
          <span style={{whiteSpace: 'nowrap'}}>
            {record.actor.full_name}
          </span>
        );
      }
    },{
      title: 'Data',
      key: 'data',
      dataIndex: 'data',
      width: 500,
      render: (value,record) => {
        switch (record.table_name) {
          case 'provider_bios':
            return <HistoryBio data={value} />
          case 'schedule_terms':
            return <HistoryTerm data={value} />;
          case 'schedule_templates':
            return <HistoryTemplate data={value} />;
          case 'schedule_template_items':
            return <HistoryTemplateItem data={value} locations={this.props.locations} />;
          default:
            return JSON.stringify(value,undefined,2);
        }
      }
    }];

    return (
      <div style={{padding:'5px'}}>
        <Spin spinning={this.props.is_busy}>
          <Table
            columns={columns}
            dataSource={this.props.history}
            size='small'
            rowKey='id'
            onChange={this.changePage}
            tableLayout='fixed'
            rowClassName='history-table-row'
            pagination={{
              current: this.state.page,
              pageSize: this.state.pagesize,
              total: this.props.history_count,
              showQuickJumper: true,
            }}
          />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    is_busy: safe(state,'history.list.processing',false),
    history: safe(state,'scheduler.history.list.data.rows',[]),
    history_count: safe(state,'scheduler.history.list.data.count',0),
    locations: safe(state,'shared.location.list.data',[])
  }
}

export default connect(mapStateToProps,{...actions,...shared_actions})(History);
