/**
 * ChartAuditLog.tsx
 */
import reports from 'reports';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import InlineFormWrapper from 'components/App_/ReportsV2_/InlineFormWrapper';
import ReportWrapper from 'components/App_/ReportsV2_/ReportWrapper';
import Table from 'components/App_/ReportsV2_/Table';
import columns, { types } from 'components/App_/ReportsV2_/TableColumns_/chartAuditLog';
import { PaginatedDataT } from 'reports';
import PatientSelector from 'components/App_/ReportsV2_/InlineControls_/PatientSelector';
import ProviderSelector from 'components/App_/ReportsV2_/InlineControls_/ProviderSelector';
import DisplayReport from 'components/App_/ReportsV2_/InlineControls_/DisplayReport';
import useAutoRun from 'components/App_/ReportsV2_/useAutoRun';
import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import { Button, DatePicker, Form, Popconfirm, Select, Space, notification } from 'antd';
import useApiPost from 'hooks/useApiPost';
import styles from './styles';

const { Option } = Select;

export const CHART_VIEW = 1;
export const NOTE_HIDE = 2;
export const NOTE_UNHIDE = 3;
export const NOTE_ATTACHMENT_HIDE = 4;
export const NOTE_ATTACHMENT_UNHIDE = 5;
export const PATIENT_DEMOGRAPHICS_CHANGED = 6;
export const CHART_NOTE_EXPANDED = 7;
export const DOCUMENT_CREATED = 8;
export const DOCUMENT_DELETED = 9;
export const DOCUMENT_RETRIEVED = 10;
export const DOCUMENT_LIST_RETRIEVED = 11;
export const DOCUMENT_TRANSFERRED_TO_CHART = 12;
export const ATTACHMENT_TRANSFERRED_TO_DOCUMENT_BOX = 13;

const reportId = 29;
const page = 1;
const pageSize = 50;

const ChartAuditLog: React.FC = () => {
  const inlineData = useContextSelector(InlineContext, (c) => c.state.inlineData) as PaginatedDataT;
  const report = reports.find((r) => r.id === reportId);

  useAutoRun(reportId);

  const formOptions = {
    page,
    pageSize,
    reportId,
  };

  const hideNoteAPI = useApiPost('/api/v2/notes/hide');
  const doRunReport = useContextSelector(InlineContext, (c) => c.doRunReport);
  const form = useContextSelector(InlineContext, (c) => c.state.form);

  const onUnhideNote = async (noteId: number) => {
    if (!hideNoteAPI?.loading) {
      const response = await hideNoteAPI?.send({ noteId, isHidden: false });
      if (response?.error) {
        notification.error({
          message: 'Removal Problem',
          description: response?.message,
        });
      } else {
        doRunReport();
      }
    }
  };

  const onDateChange = (d: moment.Moment | null) => {
    const date = d?.seconds(0).milliseconds(0);
    form?.setFieldsValue({ date });
  };

  const onUnhideNoteAttachment = async (attachmentId: number) => {
    if (!hideNoteAPI?.loading) {
      const response = await hideNoteAPI?.send({ attachmentIds: [attachmentId], isHidden: false });
      if (response?.error) {
        notification.error({
          message: 'Removal Problem',
          description: response?.message,
        });
      } else {
        doRunReport();
      }
    }
  };

  const onChange = (value) => {
    form?.setFieldsValue({ type: value });
  };

  const smartColumn: ColumnI = {
    title: 'Actions',
    key: 'actions',
    // eslint-disable-next-line react/display-name
    render: (_val, record) => {
      if (!record.actionExpended) {
        return (
          <Space>
            {record.type === NOTE_HIDE && record.noteId && (
              <Popconfirm
                title="Are you sure to unhide this note?"
                onConfirm={() => onUnhideNote(record.noteId as number)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" type="link" danger>
                  Unhide
                </Button>
              </Popconfirm>
            )}

            {record.type === NOTE_ATTACHMENT_HIDE && record.attachmentId && (
              <Popconfirm
                title="Are you sure to unhide this note attachment?"
                onConfirm={() => onUnhideNoteAttachment(record.attachmentId as number)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" type="link" danger>
                  Unhide
                </Button>
              </Popconfirm>
            )}
          </Space>
        );
      }
      return null;
    },
  };

  const tableOptions = {
    data: inlineData?.rows,
    columns: [...columns, smartColumn],
    page,
    pageSize,
    reportId,
    rowKey: 'id',
    scrollX: true,
    total: inlineData?.count,
  };
  return (
    <ReportWrapper>
      <h3>{report?.name}</h3>
      <div style={styles.root}>
        <InlineFormWrapper {...formOptions}>
          <Form.Item name="date">
            <DatePicker showTime={false} onChange={onDateChange} />
          </Form.Item>
          <Form.Item name="type">
            <Select allowClear style={{ width: 200 }} placeholder="Select a type" onChange={onChange}>
              {types.map((type, key) => (
                <Option key={key + 1} value={key + 1}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <ProviderSelector />
          <PatientSelector />
          <DisplayReport />
        </InlineFormWrapper>
      </div>
      <Table {...tableOptions} />
    </ReportWrapper>
  );
};

export default ChartAuditLog;
