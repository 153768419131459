import axios from 'axios';
import qs from 'qs';
import * as types from './types';

const schedulerLogout = (dispatch,err) => {
  if (err && err.response && err.response.status === 401) {
    dispatch ({type:'USER_LOGIN_CLEAR'});
  }
}

export const setSchedulerState = (data) => dispatch => {
  dispatch ({
    type: types.SCHEDULER_STATE_SET,
    payload: data
  });
}

export const intakesDeleteIntakeTime = (template_item_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_INTAKES_DELETEITEM_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/scheduler/intakes/'+template_item_id);
    dispatch ({type:types.SCHEDULER_INTAKES_DELETEITEM_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_INTAKES_DELETEITEM_FAILURE, error: err.response});
  }
}

export const intakesNewIntakeTime = (data) => async dispatch => {
  dispatch ({type:types.SCHEDULER_INTAKES_CREATEITEM_REQUEST});
  try {
    const resp = await axios.post('/api/v1/scheduler/intakes',data);
    dispatch ({type:types.SCHEDULER_INTAKES_CREATEITEM_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_INTAKES_CREATEITEM_FAILURE, error: err.response});
  }
}

export const intakesSetAvailability = (status) => async dispatch => {

  dispatch ({type:types.SCHEDULER_INTAKES_SETAVAIL_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/intakes/availability/'+(status ? 1 : 0));
    dispatch ({type:types.SCHEDULER_INTAKES_SETAVAIL_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_INTAKES_SETAVAIL_FAILURE, error: err.response});
  }
}

export const updateAllowIntakes = (provider_id,value) => async dispatch => {
  dispatch ({type:types.SCHEDULER_INTAKES_UPDATE_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/intakes/'+value);
    dispatch ({type:types.SCHEDULER_INTAKES_UPDATE_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_INTAKES_UPDATE_FAILURE, error: err.response});
  }
}

export const clearAllowIntakes = () => async dispatch => {
  dispatch ({type:types.SCHEDULER_INTAKES_UPDATE_CLEAR});
}

export const getAllProductivity = () => async dispatch => {
  dispatch ({type:types.SCHEDULER_PRODUCTIVITY_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/productivity');
    dispatch ({type:types.SCHEDULER_PRODUCTIVITY_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_PRODUCTIVITY_FAILURE, error: err.response});
  }
}

export const getCoverageProviders = (provider_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_PROVIDER_COVERAGE_REQUEST});
  try {
    const resp = await axios.get('/api/v1/coverageproviders/'+provider_id);
    dispatch ({type:types.SCHEDULER_PROVIDER_COVERAGE_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_PROVIDER_COVERAGE_FAILURE, error: err.response});
  }
}

export const getAdminTimeoffList = (filter,provider_id,page,pagesize) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TIMEOFF_LIST_REQUEST});
  try {
    const query = qs.stringify({ page, pagesize, provider_id },{ addQueryPrefix: true });
    const resp = await axios.get('/api/v1/scheduler/timeoff/'+filter+query);
    dispatch ({type:types.SCHEDULER_TIMEOFF_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TIMEOFF_LIST_FAILURE, error: err.response});
  }
}

export const rejectTimeoff = (timeoff_id,description,filter,provider_id, page,pagesize) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TIMEOFF_REJECT_REQUEST});
  try {
    const query = qs.stringify({ page, pagesize, provider_id },{ addQueryPrefix: true });
    const resp = await axios.post('/api/v1/scheduler/timeoff/'+timeoff_id+'/reject/'+filter+query,{
      description
    });
    dispatch ({type:types.SCHEDULER_TIMEOFF_REJECT_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TIMEOFF_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TIMEOFF_REJECT_FAILURE, error: err.response});
  }
}

export const approveTimeoff = (timeoff_id,description,filter,provider_id,page,pagesize) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TIMEOFF_APPROVE_REQUEST});
  try {
    const query = qs.stringify({ page, pagesize, provider_id },{ addQueryPrefix: true });
    const resp = await axios.post('/api/v1/scheduler/timeoff/'+timeoff_id+'/approve/'+filter+query,{
      description
    });
    dispatch ({type:types.SCHEDULER_TIMEOFF_APPROVE_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TIMEOFF_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TIMEOFF_APPROVE_FAILURE, error: err.response});
  }
}

export const cancelTimeoff = (timeoff_id,description,filter,provider_id, page,pagesize) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TIMEOFF_CANCEL_REQUEST});
  try {
    const query = qs.stringify({ page, pagesize, provider_id },{ addQueryPrefix: true });
    const resp = await axios.post('/api/v1/scheduler/timeoff/'+timeoff_id+'/cancel/'+filter+query,{
      description
    });
    dispatch ({type:types.SCHEDULER_TIMEOFF_CANCEL_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TIMEOFF_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TIMEOFF_CANCEL_FAILURE, error: err.response});
  }
}

export const updateAdminTimeoff = (timeoff_id,data,filter,provider_id, page,pagesize) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TIMEOFF_UPDATE_REQUEST});
  try {
    const query = qs.stringify({ page, pagesize, provider_id },{ addQueryPrefix: true });
    const resp = await axios.put('/api/v1/scheduler/timeoff/'+timeoff_id+'/'+filter+query,data);
    dispatch ({type:types.SCHEDULER_TIMEOFF_LIST_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TIMEOFF_UPDATE_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TIMEOFF_UPDATE_FAILURE, error: err.response});
  }
}

export const timeoffStats = (timeoff_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TIMEOFF_STATS_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/timeoff/'+timeoff_id+'/stats');
    dispatch ({type:types.SCHEDULER_TIMEOFF_STATS_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TIMEOFF_STATS_FAILURE, error: err.response});
  }
}

export const createAdminTimeoff = (provider_id,data,filter,display_provider_id,page,pagesize) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TIMEOFF_CREATE_REQUEST});
  try {
    const query = qs.stringify({ page, pagesize, provider_id:display_provider_id },{ addQueryPrefix: true });
    const resp = await axios.post('/api/v1/scheduler/timeoff/'+provider_id+'/'+filter+query,data);
    dispatch ({type:types.SCHEDULER_TIMEOFF_LIST_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TIMEOFF_CREATE_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TIMEOFF_CREATE_FAILURE, error: err.response});
  }
}

export const getProviderProductivity = (provider_id,term_id,grouping) => async dispatch => {
  dispatch ({type:types.SCHEDULER_PROVIDER_PRODUCTIVITY_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/productivity/'+term_id+'/'+grouping);
    dispatch ({type:types.SCHEDULER_PROVIDER_PRODUCTIVITY_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_PROVIDER_PRODUCTIVITY_FAILURE, error: err.response});
  }
}

export const clearProviderProductivity = () => async dispatch => {
  dispatch ({type:types.SCHEDULER_PROVIDER_PRODUCTIVITY_CLEAR});
}

export const getProviderBio = (provider_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_PROVIDERINFO_GET_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/bio');
    dispatch ({type:types.SCHEDULER_PROVIDERINFO_GET_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_PROVIDERINFO_GET_FAILURE, error: err.response});
  }
}

export const saveProviderBio = (provider_id,data) => async dispatch => {
  dispatch ({type:types.SCHEDULER_PROVIDERINFO_SAVE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/scheduler/provider/'+provider_id+'/bio',data);
    dispatch ({type:types.SCHEDULER_PROVIDERINFO_SAVE_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_PROVIDERINFO_SAVE_FAILURE, error: err.response});
  }
}

export const getProviderLocations = (provider_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_PROVIDER_LOCATIONS_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/locations');
    dispatch ({type:types.SCHEDULER_PROVIDER_LOCATIONS_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_PROVIDER_LOCATIONS_FAILURE, error: err.response});
  }
}

export const getProviderList = (director_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_PROVIDER_LIST_REQUEST});
  try {
    const resp = await axios.get(`/api/v1/scheduler/provider/${director_id}`);
    dispatch ({type:types.SCHEDULER_PROVIDER_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_PROVIDER_LIST_FAILURE, error: err.response});
  }
}

export const getProviderTerm = (provider_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_PROVIDER_GET_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/term');
    dispatch ({type:types.SCHEDULER_PROVIDER_GET_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_PROVIDER_GET_FAILURE, error: err.response});
  }
}

export const createProviderTerm = (provider_id,data) => async dispatch => {
  dispatch ({type:types.SCHEDULER_PROVIDER_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/scheduler/provider/'+provider_id+'/term',data);
    dispatch ({type:types.SCHEDULER_PROVIDER_CREATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_PROVIDER_GET_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_PROVIDER_CREATE_FAILURE, error: err.response});
  }
}

export const updateProviderTerm = (provider_id,term_id,data) => async dispatch => {
  dispatch ({type:types.SCHEDULER_PROVIDER_UPDATE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/scheduler/provider/'+provider_id+'/term/'+term_id,data);
    dispatch ({type:types.SCHEDULER_PROVIDER_UPDATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_PROVIDER_GET_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_PROVIDER_UPDATE_FAILURE, error: err.response});
  }
}

export const removeProviderTerm = (provider_id,term_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_PROVIDER_REMOVE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/scheduler/provider/'+provider_id+'/term/'+term_id);
    dispatch ({type:types.SCHEDULER_PROVIDER_REMOVE_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_PROVIDER_GET_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_PROVIDER_REMOVE_FAILURE, error: err.response});
  }
}

export const getTemplatesList = (provider_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TEMPLATE_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/template');
    dispatch ({type:types.SCHEDULER_TEMPLATE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TEMPLATE_LIST_FAILURE, error: err.response});
  }
}

export const createTemplate = (provider_id,data) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TEMPLATE_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/scheduler/provider/'+provider_id+'/template',data);
    dispatch ({type:types.SCHEDULER_TEMPLATE_CREATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TEMPLATE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TEMPLATE_CREATE_FAILURE, error: err.response});
  }
}

export const copyTemplate = (provider_id,template_id,data) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TEMPLATE_COPY_REQUEST});
  try {
    const resp = await axios.post('/api/v1/scheduler/provider/'+provider_id+'/template/'+template_id,data);
    dispatch ({type:types.SCHEDULER_TEMPLATE_COPY_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TEMPLATE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TEMPLATE_COPY_FAILURE, error: err.response});
  }
}

export const getTemplate = (provider_id,template_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TEMPLATE_GET_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/template/'+template_id);
    dispatch ({type:types.SCHEDULER_TEMPLATE_GET_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TEMPLATE_GET_FAILURE, error: err.response});
  }
}

export const updateTemplate = (provider_id,template_id,data) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TEMPLATE_UPDATE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/scheduler/provider/'+provider_id+'/template/'+template_id,data);
    dispatch ({type:types.SCHEDULER_TEMPLATE_UPDATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TEMPLATE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TEMPLATE_UPDATE_FAILURE, error: err.response});
  }
}

export const removeTemplate = (provider_id,template_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TEMPLATE_REMOVE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/scheduler/provider/'+provider_id+'/template/'+template_id);
    dispatch ({type:types.SCHEDULER_TEMPLATE_REMOVE_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TEMPLATE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TEMPLATE_REMOVE_FAILURE, error: err.response});
  }
}

export const lockTemplate = (provider_id,template_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TEMPLATE_LOCK_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/template/'+template_id+'/lock');
    dispatch ({type:types.SCHEDULER_TEMPLATE_LOCK_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TEMPLATE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TEMPLATE_LOCK_FAILURE, error: err.response});
  }
}

export const unlockTemplate = (provider_id,template_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TEMPLATE_UNLOCK_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/template/'+template_id+'/unlock');
    dispatch ({type:types.SCHEDULER_TEMPLATE_UNLOCK_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TEMPLATE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TEMPLATE_UNLOCK_FAILURE, error: err.response});
  }
}

export const createTemplateItem = (provider_id,template_id,data) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TEMPLATEITEM_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/scheduler/provider/'+provider_id+'/template/'+template_id+'/item',data);
    dispatch ({type:types.SCHEDULER_TEMPLATEITEM_CREATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TEMPLATE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TEMPLATEITEM_CREATE_FAILURE, error: err.response});
  }
}

export const updateTemplateItem = (provider_id,template_id,template_item_id,data) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TEMPLATEITEM_UPDATE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/scheduler/provider/'+provider_id+'/template/'+template_id+'/item/'+template_item_id,data);
    dispatch ({type:types.SCHEDULER_TEMPLATEITEM_UPDATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TEMPLATE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TEMPLATEITEM_UPDATE_FAILURE, error: err.response});
  }
}

export const removeTemplateItem = (provider_id,template_id,template_item_id) => async dispatch => {
  dispatch ({type:types.SCHEDULER_TEMPLATEITEM_REMOVE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/scheduler/provider/'+provider_id+'/template/'+template_id+'/item/'+template_item_id);
    dispatch ({type:types.SCHEDULER_TEMPLATEITEM_REMOVE_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_TEMPLATE_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_TEMPLATEITEM_REMOVE_FAILURE, error: err.response});
  }
}

export const getProviderCalendar = (provider_id,cal_type,cal_date) => async dispatch => {
  dispatch ({type:types.SCHEDULER_CALENDAR_LIST_REQUEST});
  try {
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/calendar/'+cal_type+'/'+cal_date);
    dispatch ({type:types.SCHEDULER_CALENDAR_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_CALENDAR_LIST_FAILURE, error: err.response});
  }
}

export const createSession = (provider_id,cal_type,cal_date,data) => async dispatch => {
  dispatch ({type:types.SCHEDULER_SESSION_CREATE_REQUEST});
  try {
    const resp = await axios.post('/api/v1/scheduler/provider/'+provider_id+'/calendar/'+cal_type+'/'+cal_date,data);
    dispatch ({type:types.SCHEDULER_SESSION_CREATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_CALENDAR_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_SESSION_CREATE_FAILURE, error: err.response});
  }
}

export const updateSession = (provider_id,cal_type,cal_date,session_id,seq_type,data) => async dispatch => {
  dispatch ({type:types.SCHEDULER_SESSION_UPDATE_REQUEST});
  try {
    const resp = await axios.put('/api/v1/scheduler/provider/'+provider_id+'/calendar/'+cal_type+'/'+cal_date,data);
    dispatch ({type:types.SCHEDULER_SESSION_UPDATE_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_CALENDAR_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_SESSION_UPDATE_FAILURE, error: err.response});
  }
}

export const removeSession = (provider_id,cal_type,cal_date,session_id,seq_type) => async dispatch => {
  dispatch ({type:types.SCHEDULER_SESSION_REMOVE_REQUEST});
  try {
    const resp = await axios.delete('/api/v1/scheduler/provider/'+provider_id+'/calendar/'+cal_type+'/'+cal_date+'/'+session_id+'/'+seq_type);
    dispatch ({type:types.SCHEDULER_SESSION_REMOVE_SUCCESS, payload: resp.data });
    dispatch ({type:types.SCHEDULER_CALENDAR_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_SESSION_REMOVE_FAILURE, error: err.response});
  }
}

export const searchCalendar = (data) => async dispatch => {
  dispatch ({type:types.SCHEDULER_CALENDAR_SEARCH_REQUEST});
  try {
    const resp = await axios.post('/api/v1/scheduler/search',data);
    dispatch ({type:types.SCHEDULER_CALENDAR_SEARCH_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_CALENDAR_SEARCH_FAILURE, error: err.response});
  }
}

export const loadHistory = (provider_id,page,pagesize) => async dispatch => {
  dispatch ({type:types.SCHEDULER_HISTORY_LIST_REQUEST});
  try {
    const query = qs.stringify({ page, pagesize },{ addQueryPrefix: true });
    const resp = await axios.get('/api/v1/scheduler/provider/'+provider_id+'/history'+query);
    dispatch ({type:types.SCHEDULER_HISTORY_LIST_SUCCESS, payload: resp.data });
  } catch (err) {
    schedulerLogout(dispatch,err);
    dispatch ({type:types.SCHEDULER_HISTORY_LIST_FAILURE, error: err.response});
  }
}
