import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, DatePicker, Radio, Input, Tag } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import * as actions from '../../../../../../actions/emr';
import { getSessionStartTimes, availableTypes } from '../../cal';
import { safe } from '../../../../../../helpers/reduxSelectors';
import Time from '../../../../../shared/Time';

const diffHrs = Time.getTzOffset();

class BlockedOut extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.form.resetFields();
    }

    if (this.props.createButtonPressed &&
        prevProps.createButtonPressed !== this.props.createButtonPressed) {
      this.create();
    }
  }

  create = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.createSession(this.props.provider_id,1,this.props.calendar_date,{
          ...values,
          type: 2, // blocked time
          verify: true   // Requires the server to first check if space is empty
        });
        this.props.onClose();
      }
    });
  }

  getTimeRange = () => {
    // First check to see if the entire duration is in the list, if so, remove it!
    const duration = this.props.form.getFieldValue('duration');

    if (duration === this.props.openItem.duration) {
      const t = new Time(this.props.openItem.start_time);
      let myStartTime;
      if (diffHrs) {
        let localTime = t.toInt() + (diffHrs * 100);
        if (localTime > 2400) {
          const diff = localTime - 2400;
          localTime = diff.toString().padStart(4, '0');
        }
        myStartTime = diffHrs ? new Time(localTime) : null;
      }
      return [{
        id: t.toInt(),
        name: t.toString(false),
        local: myStartTime ? myStartTime.toString(false) : null
      }];
    }

    return getSessionStartTimes(
      this.props.openItem,
      this.props.form.getFieldValue('duration'),
      safe(this.props,'provider_roles',[]).includes('prescriber'),
      2, // Session type = FOLLOWUP
      this.props.intake_duration
    );
  }

  render() {
    if (!this.props.openItem || !this.props.visible) {
      return null;
    }

    const FormItem = Form.Item;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };

    const isPrescriber = safe(this.props,'provider_roles',[]).includes('prescriber');
    const { allow_20m, allow_30m, allow_45m, allow_60m, allow_intake,
      duration } = this.props.openItem;
    const intake_duration = (this.props.intake_duration) ? this.props.intake_duration : 60;
    const at = availableTypes(duration,allow_20m,allow_30m,allow_45m,allow_60m,
      allow_intake,intake_duration,isPrescriber);
    const segDuration = safe(this.props,'openItem.duration');

    const durations = [segDuration];
    if (at.allow_20m && segDuration !== 20) {
      durations.push(20);
    }
    if (at.allow_30m && segDuration !== 30) {
      durations.push(30);
    }
    if (at.allow_45m && segDuration !== 45) {
      durations.push(45);
    }
    if (at.allow_60m && segDuration !== 60) {
      durations.push(60);
    }

    const sd = safe(this.props,'openItem.session_date');
    const st = safe(this.props,'openItem.start_time');
    const { session_date, session_date_local } = Time.fixDateTime(sd, st);

    return (
      <div>
        <p style={{fontStyle:'italic'}}>Blocked-out time prevents others from
        scheduling patients in your calendar at these times. Providers do not
        get credit for blocked-out time.</p>

        <Form className='compressed-form' autoComplete='false'>

          <FormItem {...formItemLayout} label="Provider">
            {getFieldDecorator('provider_id', {
              initialValue: this.props.provider_id
            })(
              <span>{this.props.provider_name}</span>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Session Date">
            {getFieldDecorator('session_date', {
              initialValue: moment(safe(this.props,'openItem.session_date')).tz('America/Los_Angeles')
            })(
              <span>{moment(safe(this.props,'openItem.session_date')).format('M/D/YYYY')}</span>
            )}
            <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
            {diffHrs && session_date_local ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {session_date_local.format('MM/DD/YYYY')}</Tag> : null}
          </FormItem>

          <FormItem {...formItemLayout} label="Duration">
            {getFieldDecorator('duration', {
              rules: [{ required: true, message: " " }],
              initialValue: segDuration
            })(
              <Select>
                {durations.map(x=><Option value={x} key={'d'+x}>
                  {(x === segDuration) ? 'Entire Block' : x + ' mins'}
                </Option>)}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Starting Time">
            {getFieldDecorator('start_time', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(this.props,'openItem.start_time')
            })(
              <Select>
                { this.getTimeRange().map((x) => {
                  let time = `${x.name} ${Time.getPSTPDT()}`;
                  if (diffHrs) {
                    time = `${time} - (${x.local} Local)`;
                  }
                  return <Option key={x.id} value={x.id}>{time}</Option>;
                }) }
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Note">
            {getFieldDecorator('comment', {})(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Repeats">
            {getFieldDecorator('repeat', {
              rules: [{ required: true, message: " " }],
              initialValue: false
            })(
              <Radio.Group>
                <Radio value={false}>Doesn't Repeat</Radio>
                <Radio value={true}>Repeats Weekly</Radio>
              </Radio.Group>
            )}
          </FormItem>

          { this.props.form.getFieldValue('repeat') &&
            <FormItem {...formItemLayout} label="Repeats Every">
              {getFieldDecorator('repeat_frequency', {
                rules: [{ required: true, message: " " }],
                initialValue: 1
              })(
                <Select>
                  <Option value={1}>1 Week</Option>
                  <Option value={2}>2 Weeks</Option>
                  <Option value={3}>3 Weeks</Option>
                  <Option value={4}>4 Weeks</Option>
                  <Option value={5}>5 Weeks</Option>
                  <Option value={6}>6 Weeks</Option>
                  <Option value={7}>7 Weeks</Option>
                  <Option value={8}>8 Weeks</Option>
                </Select>
              )}
            </FormItem>
          }

          { this.props.form.getFieldValue('repeat') &&
            <FormItem {...formItemLayout} label="Repeat Type">
              {getFieldDecorator('repeat_type', {
                rules: [{ required: true, message: " " }],
                initialValue: 1
              })(
                <Select>
                  <Option value={1}>Until an End Date</Option>
                  <Option value={2}>For # of Sessions</Option>
                </Select>
              )}
            </FormItem>
          }

          { this.props.form.getFieldValue('repeat') &&
            this.props.form.getFieldValue('repeat_type') === 2 &&
            <FormItem {...formItemLayout} label="Repeats For">
              {getFieldDecorator('repeat_num_sessions', {
                rules: [{ required: true, message: " " }],
                initialValue: 30
              })(
                <Select>
                  <Option value={5}>5 Sessions</Option>
                  <Option value={6}>6 Sessions</Option>
                  <Option value={7}>7 Sessions</Option>
                  <Option value={8}>8 Sessions</Option>
                  <Option value={9}>9 Sessions</Option>
                  <Option value={10}>10 Sessions</Option>
                  <Option value={11}>11 Sessions</Option>
                  <Option value={12}>12 Sessions</Option>
                  <Option value={13}>13 Sessions</Option>
                  <Option value={14}>14 Sessions</Option>
                  <Option value={15}>15 Sessions</Option>
                  <Option value={16}>16 Sessions</Option>
                  <Option value={17}>17 Sessions</Option>
                  <Option value={18}>18 Sessions</Option>
                  <Option value={19}>19 Sessions</Option>
                  <Option value={20}>20 Sessions</Option>
                  <Option value={21}>21 Sessions</Option>
                  <Option value={22}>22 Sessions</Option>
                  <Option value={23}>23 Sessions</Option>
                  <Option value={24}>24 Sessions</Option>
                  <Option value={25}>25 Sessions</Option>
                  <Option value={26}>26 Sessions</Option>
                  <Option value={27}>27 Sessions</Option>
                  <Option value={28}>28 Sessions</Option>
                  <Option value={29}>29 Sessions</Option>
                  <Option value={30}>30 Sessions</Option>
                </Select>
              )}
            </FormItem>
          }

          { this.props.form.getFieldValue('repeat') &&
            this.props.form.getFieldValue('repeat_type') === 1 &&
            <FormItem {...formItemLayout} label="Repeats Until">
              {getFieldDecorator('repeat_end_date', {
                rules: [{ required: true, message: " " }],
                initialValue: moment(moment().format('YYYY')+'-12-31')
              })(
                <DatePicker format='M/D/YYYY'/>
              )}
            </FormItem>
          }

        </Form>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    calendar_date: safe(state,'emr.emr.active_date',moment().format('YYYY-MM-DD')),
    intake_duration: safe(state,'emr.emr.calendarProvider.intake_duration',60),
  }
}

export default connect(mapStateToProps,actions)(Form.create()(BlockedOut));
