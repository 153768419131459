import React from 'react';
import { connect } from 'react-redux';

import { safe } from '../../../helpers/reduxSelectors';
import Productivity from './Productivity';

class ProductivityWrapper extends React.Component {
  render() {
    if (!this.props.activeUserId) {
      return null;
    }

    return (
      <Productivity
        userId={this.props.activeUserId}
        userName={this.props.activeUserName}
        userIsPrescriber={this.props.activeUserIsPrescriber}
        userEmploymentType={this.props.activeUserEmploymentType}
      />
    );

  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeUserName: safe(state,'shared.domain.activeUser.full_name'),
    activeUserId: safe(state,'shared.domain.activeUser.id'),
    activeUserEmploymentType: safe(state,'shared.domain.activeUser.employment_type',1),
    activeUserIsPrescriber: safe(state,'shared.domain.activeUser.roles',[]).includes('prescriber'),
  }
}

export default connect(mapStateToProps,null)(ProductivityWrapper);
