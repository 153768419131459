import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Modal, Tabs, Spin } from 'antd';
import moment from 'moment';

import * as actions from '../../../actions/pm';
import { safe } from '../../../helpers/reduxSelectors';
import { processEventNotification } from '../../../helpers/util';

class ChargeCodes extends Component {
  componentDidMount() {
    this.props.getCodeList();
  }

  componentDidUpdate(prevProps) {
    processEventNotification(prevProps,this.props,'deactivate','Code deactivated');
    processEventNotification(prevProps,this.props,'reactivate','Code reactivated');
  }

  deactivate = (record) => {
    const This = this;

    Modal.confirm({
      title: 'Deactivate Code: ' + record.code + '?',
      onOk() {
        This.props.codeDeactivate(record.id);
      },
      onCancel() { }
    });
  }

  reactivate = (record) => {
    const This = this;

    Modal.confirm({
      title: 'Reactivate Code: ' + record.code + '?',
      onOk() {
        This.props.codeReactivate(record.id)
      },
      onCancel() { }
    });
  }

  renderActiveTable = () => {
    const columns = [{
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (val,record) => (
        <Link to={"/settings/codes/"+record.id} style={{whiteSpace: 'nowrap'}}>{val}</Link>
      )
    },{
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },{
      title: 'Action',
      width: 100,
      fixed: 'right',
      key: 'action',
      render: (val,record) => {
        return (
          <span>
            <a onClick={() => { this.deactivate(record) }}>Deactivate</a>
          </span>
        );
      }
    }];

    return (
      <Table
        columns={columns}
        dataSource={this.props.codesList.filter(x => x.active)}
        rowKey="id"
        size='small'
        pagination={false}
      />
    );
  }

  renderInactiveTable = () => {
    const columns = [{
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (val,record) => (
        <Link to={"/settings/codes/"+record.id} style={{whiteSpace: 'nowrap'}}>{val}</Link>
      )
    },{
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },{
      title: 'Deactivated',
      dataIndex: 'deactivatedAt',
      key: "deactivatedAt",
      render: (val) => moment(val).format('MM/DD/YYYY HH:mm:ss')
    },{
      title: 'Action',
      width: 100,
      fixed: 'right',
      key: 'action',
      render: (val,record) => {
        return (
          <span>
            <a onClick={() => { this.reactivate(record) }}>Reactivate</a>
          </span>
        );
      }
    }];

    return (
      <Table
        columns={columns}
        dataSource={this.props.codesList.filter(x => !x.active)}
        rowKey="id"
        size='small'
        pagination={false}
      />
    );
  }

  onClickAddChargeCode = () => {
    this.props.history.push('/settings/codes/0');
  }

  render() {
    const TabPane = Tabs.TabPane;
    return (
      <Spin spinning={this.props.isBusy} size='large'>
        <div style={{padding:'10px'}}>
          <h3>Charge Codes</h3>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Active" key="1">
              { this.renderActiveTable() }
            </TabPane>
            <TabPane tab="Inactive" key="2">
              { this.renderInactiveTable() }
            </TabPane>
          </Tabs>
          <div style={{position:'fixed',bottom:40,right:40}}>
            <Button
              disabled={this.props.isBusy}
              onClick={this.onClickAddChargeCode}
              size='large'
              shape='circle'
              type="primary"
              icon={<PlusOutlined />}/>
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deactivate: safe(state,'pm.codes.deactivate'),
    reactivate: safe(state,'pm.codes.reactivate'),
    codesList: safe(state,'pm.codes.list.data',[]),
    isBusy: (
      safe(state,'pm.codes.load.processing') ||
      safe(state,'pm.codes.list.processing') ||
      safe(state,'pm.codes.deactivate.processing') ||
      safe(state,'pm.codes.reactivate.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(ChargeCodes);
