import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Spin } from 'antd';
import numeral from 'numeral';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';

class ReportsOnHold extends React.Component {
  componentDidMount() {
    this.props.reportsOnHold();
  }

  render() {
    const columns = [{
      title: 'Patient',
      dataIndex: 'patient'
    },{
      title: 'Patient ID',
      dataIndex: 'id',
      render: (val) => numeral(val).format('000000')
    },{
      title: 'Patient Balance',
      dataIndex: 'pt_outstanding',
      render: (val) => numeral(val).format('$0,0.00')
    }];

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
            <span style={{float:'right'}}><a href="/api/v1/reports/onhold?csv=1">CSV Download</a></span>
          </div>

          <h3>Patients On-Hold with Balances</h3>
          <Table
            className='reports-sessionlist-table'
            columns={columns}
            dataSource={this.props.list}
            bordered={true}
            size='small'
            rowKey='yw'
            scroll={{ x:true }}
            pagination={false}
          />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'reports.onhold.processing',false),
    list: safe(state,'reports.onhold.data',[]),
  }
}

export default connect(mapStateToProps,actions)(ReportsOnHold);
