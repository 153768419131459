import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import numeral from 'numeral';

const columns: Array<ColumnI> = [
  {
    title: 'Patient',
    dataIndex: 'patient',
  },
  {
    title: 'Patient ID',
    dataIndex: 'id',
    render: (val) => numeral(val).format('000000'),
  },
  {
    title: 'Patient Balance',
    dataIndex: 'pt_outstanding',
    render: (val) => numeral(val).format('$0,0.00'),
  },
];

export default columns;
