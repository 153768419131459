import React, { Component } from 'react';
import { connect } from 'react-redux';
//import axios from 'axios';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/display';

class RcopiaPatient extends Component {

  constructor(props) {
    super(props);
    this.state = {
      patient_id: parseInt(this.props.match.params.patient_id,10),
    }
  }

  componentDidMount() {
    this.props.getRcopiaSSOurl('patient',this.state.patient_id);
    // Setup window close listeners
    // window.addEventListener("beforeunload", this.handleBeforeunload);
  }

  render() {
    if (this.props.isSuccess) {
      window.location.href=this.props.url;
    }
    return (<div>Connecting to Dr. First...</div>);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isProcessing: safe(state,'display.rcopia.connecturl.processing'),
    isSuccess: safe(state,'display.rcopia.connecturl.success'),
    url: safe(state,'display.rcopia.connecturl.data.url')
  }
}

export default connect(mapStateToProps,actions)(RcopiaPatient);

/*
componentWillUnmount() {
  window.removeEventListener("beforeunload", this.handleBeforeunload);
}

handleBeforeunload = event => {
  // Update info with RCopia here.
  axios.get('/api/v1/rcopia/meds/queue/' + this.state.patient_id);
}

render() {
  const iframe_style = {
    display: 'block',
    width: '100vw',
    height: '100vh',
    maxWidth: '100%',
    margin: 0,
    padding: 0,
    border: '0 none',
    boxSizing: 'border-box'
  };

  if (this.props.isSuccess) {
    return (
      <iframe src={this.props.url} style={iframe_style} title='Dr First RCopia ePrescribe'/>
    );
  } else {
    return (
      <div>
        Connecting to Dr. First...
      </div>
    );
  }
}
*/
