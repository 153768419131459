import React from 'react';
import { Modal, Button, Checkbox, Tag } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/emr';
import Time from '../../../shared/Time';

const diffHrs = Time.getTzOffset();

class ReserveSessionModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    patient_id: PropTypes.number,
    patient_name: PropTypes.string,
    session_id: PropTypes.number,
    data: PropTypes.object,
    onClose: PropTypes.func,
  }

  state = {
    all: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.setState({
        all: false
      });
    }
  }

  handleOk = () => {
    this.props.onClose();
  }

  handleCancel = () => {
    this.props.onClose();
  }

  getTypeName = (x) => {
    switch (x) {
      case 1: return 'Intake';
      case 2: return 'Followup';
      default: return 'Other';
    }
  }

  getTreatmentType = (x) => {
    switch (x) {
      case 1: return 'Psychiatry';
      case 2: return 'Therapy';
      default: return 'Other';
    }
  }

  getMode = (x,location_id) => {
    // Telemedicine location is video only
    if (location_id === 4) {
      return 'Video Only';
    }

    switch (x) {
      case 0: return 'In-Office or Video';
      case 1: return 'Video Only';
      case 2: return 'In-Office Only';
      default: return 'Other';
    }
  }

  setRepeat = (e) => {
    this.setState({
      all: e.target.checked
    });
  }

  reserve = (telemedicine) => {
    const data = this.props.data;

    this.props.reserveAppt(data.user.id,{
      type: 1, // encounter note
      session_type: data.intake_followup,
      patient_id: this.props.patient_id,
      provider_id: data.user.id,
      location_id: data.location_id,
      telemedicine: telemedicine,
      session_date: data.session_date,
      start_time: data.start_time,
      duration: data.duration,
      repeat: this.state.all,
      repeat_type: 2,  // # of times
      repeat_frequency: 1, // Once a week
      repeat_num_sessions: (this.state.all) ? data.repeats : 0,
      repeat_end_date: null,
      verify: true
    });

    this.props.onClose();
  }

  renderContent = (data) => {
    if (data) {
      const providerName = safe(data,'user.full_name');
      const recurringSessions = (data.repeats > 1 && data.treatment_type === 2 && data.intake_followup === 2) ? true : false;
      const sd = data.session_date;
      const st = data.start_time;
      const { session_date, session_date_local, time, local_time } = Time.fixDateTime(sd, st);

      return (
        <div>
          <div>Patient: {this.props.patient_name}</div>
          <div>Provider: {providerName}</div>
          <div>Type: {this.getTypeName(data.intake_followup)}</div>
          <div>Treatment: {this.getTreatmentType(data.treatment_type)}</div>
          <div>Duration: {data.duration} min</div>
          <div>
            Date: {moment(data.session_date).format('MM/DD/YYYY')}
            {diffHrs ? (
              <>
                <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
                {st ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {session_date_local.format('MM/DD/YYYY')}</Tag> : null}
              </>
            ): null}   
          </div>
          <div>
            Time: {Time.isValid(data.start_time) ? (new Time(data.start_time)).toString(false) : ''}
            {diffHrs ? (
              <>
                <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
                {local_time ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {(local_time)?.toString(false)}</Tag> : null}
              </>
            ): null}   
          </div>
          <div>Location: {safe(data,'location.name')}</div>
          <div>Mode: {this.getMode(data.allow_video,data.location_id)}</div>
          { recurringSessions && <div>Consecutive Weekly Sessions Available: {data.repeats}</div> }
          {recurringSessions && <div style={{paddingTop:'10px'}}>
            <Checkbox checked={this.state.all} onClick={this.setRepeat}/> &nbsp;Reserve all {data.repeats} sessions
          </div>}

          <div style={{paddingTop:'15px'}}>
            {(data.location_id === 4 || data.allow_video === 0 || data.allow_video === 1) && <Button
              style={{marginLeft:'5px'}}
              onClick={()=>this.reserve(1)}
              type='primary'>
                Video Appt
            </Button> }
            {(data.location_id !== 4 && (data.allow_video === 0 || data.allow_video === 2)) && <Button
              style={{marginLeft:'5px'}}
              onClick={()=>this.reserve(0)}
              type='primary'>
                Office Appt @ {safe(data,'location.name')}
            </Button> }
          </div>
        </div>

      );
    }
    return null;
  }

  render() {
    return (
      <Modal
        title={'Reserve Session for '+this.props.patient_name}
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        width={500}
        height={300}
        footer={<Button onClick={()=>this.props.onClose()}>Cancel</Button>}
      >
        { this.renderContent(this.props.data) }
      </Modal>
    );
  }
}

export default connect(null,actions)(ReserveSessionModal);
