/**
 * Modal.tsx
 * The modal wrapper for the form with a modal trigger button
 */
import { useEffect } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Button, Modal } from 'antd';
import NoteHideForm from './Form';
import Context, { defaultState } from './context';

const NoteHide: React.FC = () => {
  const setState = useContextSelector(Context, (c) => c.setState);
  const isModalVisible = useContextSelector(Context, (c) => c.state.isModalVisible);
  const form = useContextSelector(Context, (c) => c.state.form);

  const showModal = () => {
    setState((s) => ({ ...s, isModalVisible: true }));
  };

  const handleCancel = () => {
    setState((s) => ({ ...s, isModalVisible: false }));
  };

  useEffect(() => {
    if (isModalVisible === false) {
      setState(defaultState);
      form?.resetFields();
    }
  }, [isModalVisible]);

  return (
    <>
      <Button type="link" onClick={showModal}>
        Note Delete
      </Button>
      <Modal title="HIPAA-Compliant Note Removal" visible={isModalVisible} footer={null} onCancel={handleCancel}>
        <NoteHideForm />
      </Modal>
    </>
  );
};

export default NoteHide;
