import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { MailOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Table, Spin } from 'antd';
import numeral from 'numeral';
import moment from 'moment';

import PreparedStatementsDialog from './PreparedStatements_/PreparedStatementsDialog';
import EmailListDialog from './PreparedStatements_/EmailListDialog';

import { safe } from '../../../../../helpers/reduxSelectors';

class PreparedStatements extends Component {

  render() {
    const columns = [{
      title: 'id',
      dataIndex: 'id',
      render: (value) => {
        return <Link target='_blank' to={'/display/statement/'+this.props.patientId+'/'+value}>
          {numeral(value).format('000000')}
        </Link>;
      }
    },{
      title: 'Date',
      dataIndex: 'createdAt',
      align: 'right',
      render: (value) => {
        return moment(value).format('MM/DD/YYYY');
      }
    },{
      title: 'Author',
      dataIndex: 'creator',
      render: (value,record) => {
        return safe(record,'creator.initials','');
      }
    },{
      title: 'Type',
      dataIndex: 'type',
      render: (value) => {
        if (value === 1) {
          return 'Superbill';
        } else if (value === 2) {
          return 'Statement';
        } else {
          return 'Unknown';
        }
      }
    }];

    const data = safe(this.props,'data',[]);

    return (
      <Spin spinning={this.props.isBusy}>
        <div>
          <table style={{paddingTop: '10px',width:'100%'}}>
            <tbody>
              <tr>
                <td>
                  <PreparedStatementsDialog patientId={this.props.patientId}>
                    <PlusCircleOutlined /> Generate Superbill or Statement
                  </PreparedStatementsDialog>
                </td>
                <td style={{textAlign:'right'}}>
                  <EmailListDialog patientId={this.props.patientId}>
                    <span><MailOutlined />&nbsp; Emails</span>
                  </EmailListDialog>
                </td>
              </tr>
            </tbody>
          </table>

          <Table
            columns={columns}
            bordered={false}
            dataSource={data}
            size='small'
            rowKey='id'
            pagination={false}
            showHeader={false}
          />
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.statements.list.processing',false) ||
      safe(state,'billing.statements.create.processing',false)
    ) ? true : false
  }
}

export default connect(mapStateToProps,null)(withRouter(PreparedStatements));
