/**
 * Time
 *
 * A simple Time object that allows the storage and manipulation of 24-hr
 * time as an integer.
 *
 * @author Josh Krieger <josh@crystalbays.com>
 * @copyright Crystal Bay Software, 2018-2019
 */
import moment from 'moment';
import momentTz from 'moment-timezone';

class Time {

  /**
   * Parses a simple integer representation of a 24-hr time in an object of the
   * form: { hours: h, mins: m }
   *
   * @function parseTime
   * @params {integer} time - a number/string 0->2359
   */

  constructor(time) {
    const t = parseInt(time,10);
    if (isNaN(t) || t < 0) {
      throw new Error('Invalid time. Must be a valid positive number')
    }
    const t2 = t % 2400;
    this.hours = Math.trunc(t2/100);
    this.mins = t2 - (this.hours*100);
  }

  /**
   * Turns the object representation of a time back into a number
   *
   * @function toInt
   */

  toInt() {
    return this.hours*100 + this.mins;
  }

  /**
   * Turns the object representation of a time into a floating point number
   *
   * @function toFloat
   */

  toFloat() {
    return this.hours + (this.mins/60);
  }

  /**
   * Turns the object representaiton into a string
   */

  toString(militaryTime=true) {
    if (militaryTime) {
      const h = (this.hours < 10) ? '0'+this.hours : ''+this.hours;
      const m = (this.mins < 10) ? '0'+this.mins : ''+this.mins;
      return h + ":" + m;
    } else {
      const ampm = (this.hours >= 12) ? 'PM' : 'AM';
      const h = (this.hours > 12) ? this.hours - 12 : this.hours;
      const m = (this.mins < 10) ? '0'+this.mins : ''+this.mins;
      return (h === 0 ? '12' : h) + ":" + m + ' ' + ampm;
    }
  }

  /**
   * Adds a certain number of minutes to the object's time.
   *
   * @function addTime
   */

  add(deltaMins) {
    const dm = parseInt(deltaMins,10);
    const addHours = Math.trunc((this.mins+dm)/60);
    const newMins = (this.mins+dm) % 60;

    if (this.hours + addHours > 23) {
      throw new Error('Time extends to the next day')
    }

    return new Time((this.hours + addHours)*100+newMins);
  }

  sub(deltaMins) {
    const dm = parseInt(deltaMins,10);
    let subHours = Math.trunc((this.mins-dm)/60);
    let newMins = (this.mins-dm) % 60;
    if (newMins < 0) {
      subHours -= 1;
      newMins += 60;
    }

    if (this.hours + subHours < 0) {
      throw new Error('Time extends to the next day')
    }

    return new Time((this.hours + subHours)*100+newMins);
  }


  /**
   * Gets the difference in minutes between this and another Time object
   *
   * @function getDuration
   */

  diff(endTime) {
    return (endTime.hours - this.hours)*60 + (endTime.mins - this.mins);
  }

  /**
   * Simple equality tester
   */

  equal(x) {
    return (this.hours === x.hours && this.mins === x.mins) ? true : false;
  }

  static range(startTime,endTime,deltaMins) {
    const diffHrs = Time.getTzOffset();
    const et = endTime.toInt();
    const result = [];
    while (startTime.toInt() <= et) {
      let myStartTime;
      if (diffHrs) {
        let localTime = startTime.toInt() + (diffHrs * 100);
        if (localTime > 2400) {
          const diff = localTime - 2400;
          localTime = diff.toString().padStart(4, '0');
        }
        myStartTime = diffHrs ? new Time(localTime) : null;
      }
      result.push({
        id: startTime.toInt(),
        name: startTime.toString(false),
        local: myStartTime ? myStartTime.toString(false) : null
      });
      startTime = startTime.add(deltaMins);
    }
    return result;
  }

  static isValid(t) {
    try {
      new Time(t);
      return true;
    } catch (err) {
      return false;
    }
  }

  static getTzOffset() {
    const localNow = moment();
    const pdtNow = momentTz.tz('America/Los_Angeles');
    const a = localNow.utcOffset(); // china = 720
    const b = pdtNow.utcOffset(); // -420
    const diffMins = a - b; // = 1140
    const diffHrs = diffMins / 60; // 19hrs
    return diffHrs;
  }

  static createMilitaryTime(diffHrs) {
    if (diffHrs < 0) {
      return 2400 + (diffHrs * 100);
    } else {
      return diffHrs * 100;
    }
  }

  static getPSTPDT() {
    const pdtNow = momentTz.tz('America/Los_Angeles');
    return pdtNow.isDST() ? 'PDT' : 'PST';
  }

  static fixDateTime(sd, st) {
    const diffHrs = Time.getTzOffset();
    const timeMoment = moment(st?.toString(), 'Hmm', true);
    const session_date = moment(sd).startOf('day').add(timeMoment.hour(), 'hours').add(timeMoment.minute(), 'minutes'); 
    const session_date_local = session_date.clone().add(diffHrs, 'hours');
    const st_local = st + Time.createMilitaryTime(diffHrs); // create local start time
    const time = st ? new Time(st) : null;
    const local_time = st_local ? new Time(st_local) : null;
    return {
      session_date,
      session_date_local,
      time,
      local_time
    };
  }
}

export default Time;
