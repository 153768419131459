import React from 'react';
import PropTypes from 'prop-types';

class OppositeEnds extends React.Component {

  static propTypes = {
    left: PropTypes.element,
    right: PropTypes.element
  }

  render() {
    return (
      <table style={{width:'100%'}}>
        <tbody>
          <tr>
            <td style={{textAlign:'left'}}>
              {this.props.left}
            </td>
            <td style={{textAlign:'right'}}>
              {this.props.right}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
  
}

export default OppositeEnds;
