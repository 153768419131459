import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';

import { safe } from '../../helpers/reduxSelectors';
import * as actions from '../../actions/shared';

const { Option } = Select;

class PatientFormSelector extends React.Component {
  state = {
    selection: ''
  };

  onSearch = (value) => {
    this.props.getPatientList(value);
  };

  onChange = (value) => {
    this.setState({ selection: value });
    if (this.props.onChange && typeof this.props.onChange === 'function') {
      this.props.onChange(parseInt(value, 10));
    }
  };

  render() {
    return (
      <Select
        placeholder="Search by patient name, id, or dob"
        allowClear
        showSearch
        filterOption={false}
        value={this.state.selection}
        onChange={this.onChange}
        onSearch={this.onSearch}
        defaultActiveFirstOption={false}
        notFoundContent={null}
      >
        {this.props.patientList.map((item, key) => {
          let val = item.full_name + ' (' + item.id + ')';
          if (!item.open) {
            val = '@' + val;
          }
          if (item.on_hold) {
            val = '#' + val;
          }
          if (item.oncareteam) {
            val = '> ' + val;
          }
          return <Option key={key} value={item.id}>{val}</Option>;
        })}
      </Select>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    patientList: safe(state, 'shared.patient.list.data', []),
  };
};

export default connect(mapStateToProps, actions)(PatientFormSelector);
