import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Tabs, Divider } from 'antd';

import MyReports from './MyReports';

class ReportsHome extends React.Component {
  state = {
    activeTabKey: '1',
  };

  changeTab = (key) => {
    this.setState({
      activeTabKey: key,
    });
  };

  render() {
    const TabPane = Tabs.TabPane;

    return (
      <div style={{ padding: '10px' }}>
        <Tabs activeKey={this.state.activeTabKey} onChange={this.changeTab}>
          <TabPane tab="Reports" key="1">
            <h3>PCPA Reports</h3>
            <ul>
              <li>
                <Link to="/reports/sessionlist">Completed Sessions List</Link>
              </li>
              <li>
                <Link to="/reports/adjustments">Adjustments Report</Link>
              </li>
              <li>
                <Link to="/reports/lyra">Lyra Claims Report</Link>
              </li>
              <li>
                <Link to="/reports/dupsessions">Duplicate Sessions</Link>
              </li>
              <li>
                <Link to="/reports/intakes">Filled/Unfilled Intakes Report</Link>
              </li>
              <li>
                <Link to="/reports/unsignedmissing">Unsigned and Missing Sessions Report</Link>
              </li>
              <li>
                <Link to="/reports/ptcredits">Patient Credits</Link>
              </li>
              <li>
                <Link to="/reports/ptunallocated">Patient Unapplied Payments</Link>
              </li>
              <li>
                <Link to="/reports/sessioncounts">Session Counts</Link>
              </li>
              <li>
                <Link to="/reports/onhold">Patients On Hold with Balances</Link>
              </li>
              <li>
                <Link to="/reports/paymentspostcount">Count of Payments Posted Per User</Link>
              </li>
            </ul>
            <Divider />
            <ul>
              <li>
                <Link to="/reports/payroll">Payroll Report</Link>
              </li>
              <li>
                <Link to="/reports/chargecollection">Charge Collection Report</Link>
              </li>
              <li>
                <Link to="/reports/hourlyemployees">Hourly Employees Payroll</Link>
              </li>
            </ul>

            <Divider />
            <ul>
              <li>
                <Link to="/reports/patients">Patient List</Link>
              </li>
              <li>
                <Link to="/reports/evals">List of Evals</Link>
              </li>
              <li>
                <Link to="/reports/opentimes">Provider Open Times</Link>
              </li>
              <li>
                <Link to="/reports/scheduletemplates">Provider Schedule Templates</Link>
              </li>
            </ul>

            <Divider />
            <h4>LifeStance</h4>
            <ul>
              <li>
                <Link to="/reports/lifestance/td_adjs">LifeStance Transaction Details - Adjustments</Link>
              </li>
              <li>
                <Link to="/reports/lifestance/td_charges">LifeStance Transaction Details - Charges</Link>
              </li>
              <li>
                <Link to="/reports/lifestance/td_payments">LifeStance Transaction Details - Payments</Link>
              </li>
              <li>
                <Link to="/reports/lifestance/claim_summary">LifeStance Claim Summary</Link>
              </li>
              <li>
                <Link to="/reports/lifestance/ar_aging">LifeStance AR Aging</Link>
              </li>
              <li>
                <Link to="/reports/lifestance/ar_carrier_aging">LifeStance AR Carrier Aging</Link>
              </li>
              <li>
                <Link to="/reports/lifestance/ar_aging_detail">LifeStance AR Aging Detail</Link>
              </li>
              <li>
                <Link to="/reports/lifestance/patient_totals">LifeStance Patient Totals</Link>
              </li>
              <li>
                <Link to="/reports/lifestance/scheduled_sessions">LifeStance Future Scheduled Sessions</Link>
              </li>
            </ul>
          </TabPane>
          <TabPane tab="My Reports" key="2">
            <MyReports visible={this.state.activeTabKey === '2'} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(ReportsHome);
