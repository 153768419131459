import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import numeral from 'numeral';

const columns: Array<ColumnI> = [
  {
    title: 'Month',
    dataIndex: 'month',
    key: 'month',
    width: 200,
  },
  {
    title: 'Pt Adjustments',
    dataIndex: 'pt_adjustments',
    key: 'pt_adjustments',
    align: 'right',
    render: (value) => numeral(value).format('$0,0.00'),
  },
  {
    title: 'Pt Writeoff',
    dataIndex: 'pt_writeoff',
    key: 'pt_writeoff',
    align: 'right',
    render: (value) => numeral(value).format('$0,0.00'),
  },
  {
    title: 'Pt Collections',
    dataIndex: 'pt_collections',
    key: 'pt_collections',
    align: 'right',
    render: (value) => numeral(value).format('$0,0.00'),
  },
  {
    title: 'Pt Repricing',
    dataIndex: 'pt_repricing',
    key: 'pt_repricing',
    align: 'right',
    render: (value) => numeral(value).format('$0,0.00'),
  },
  {
    title: 'Pt Other',
    dataIndex: 'pt_other',
    key: 'pt_other',
    align: 'right',
    render: (value) => numeral(value).format('$0,0.00'),
  },
  {
    title: 'Pt Total',
    dataIndex: 'pt_total',
    key: 'pt_total',
    align: 'right',
    render: (value) => numeral(value).format('$0,0.00'),
  },
  {
    title: 'Ins Adjustments',
    dataIndex: 'ins_adjustments',
    key: 'ins_adjustments',
    align: 'right',
    render: (value) => numeral(value).format('$0,0.00'),
  },
  {
    title: 'Ins Writeoff',
    dataIndex: 'ins_writeoff',
    key: 'ins_writeoff',
    align: 'right',
    render: (value) => numeral(value).format('$0,0.00'),
  },
  {
    title: 'Ins Collections',
    dataIndex: 'ins_collections',
    key: 'ins_collections',
    align: 'right',
    render: (value) => numeral(value).format('$0,0.00'),
  },
  {
    title: 'Ins Repricing',
    dataIndex: 'ins_repricing',
    key: 'ins_repricing',
    align: 'right',
    render: (value) => numeral(value).format('$0,0.00'),
  },
  {
    title: 'Ins Other',
    dataIndex: 'ins_other',
    key: 'ins_other',
    align: 'right',
    render: (value) => numeral(value).format('$0,0.00'),
  },
  {
    title: 'Ins Total',
    dataIndex: 'ins_total',
    key: 'ins_total',
    align: 'right',
    render: (value) => numeral(value).format('$0,0.00'),
  },
];

export default columns;
