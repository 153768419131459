import React from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { Spin, Table, Button } from 'antd';
import moment from 'moment';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';
import { processEventNotification } from '../../../helpers/util';

class MyReports extends React.Component {
  state = {
    pageSize: 15,
    currentPage: 1
  }

  componentDidMount() {
    if (this.props.visible) {
      this.props.loadMyReports(this.state.currentPage,this.state.pageSize);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        this.props.loadMyReports(this.state.currentPage,this.state.pageSize);
      }
    }
    processEventNotification(prevProps,this.props,'deleteQueue','Report Deleted From Queue');
  }

  deleteReport = (queue_id) => {
    this.props.queueDelete(queue_id,this.state.currentPage,this.state.pageSize);
  }

  cancelReport = (queue_id) => {
    this.props.queueCancel(queue_id,this.state.currentPage,this.state.pageSize);
  }

  tableChange = (x) => {
    this.setState({
      currentPage:x.current
    },() => {
      this.props.loadMyReports(this.state.currentPage,this.state.pageSize);
    });
  }

  displayParams = (report_id,params) => {
    if (!params) {
      return ['None'];
    }
    if ((report_id >= 1 && report_id <= 7) || report_id === 14) {
      // Payroll Report

      const result = [];
      if (params['date_type']) {
        result.push('Sorting By='+params['date_type']);
      }
      if (params['start_date']) {
        result.push('Starting DOS='+moment(params['start_date']).format('MM/DD/YYYY'));
      }
      if (params['end_date']) {
        result.push('Ending DOS='+moment(params['end_date']).format('MM/DD/YYYY'));
      }

      return result;
    }
    return ['None'];
  }

  render() {
    const columns = [{
      title: 'ID',
      dataIndex: 'id',
      key: '1'
    },{
      title: 'Report',
      dataIndex: 'report_id',
      render: (value)=> {
        switch (value) {
          case 1: return 'Payroll';
          case 2: return 'Charge Collection';
          case 3: return 'Lifestance';
          case 4: return 'LS Adjustments';
          case 5: return 'LS Charges';
          case 6: return 'LS Payments';
          case 7: return 'LS Claim Summary';
          case 8: return 'LS AR Aging';
          case 9: return 'LS AR Aging Detail';
          case 10: return 'LS AR Carrier Aging';
          case 11: return 'LS Patient Totals';
          case 14: return 'Completed Sessions List';
          case 15: return 'Future Scheduled Sessions';
          default:
            return '--';
        }
      }
    },{
      title: 'Created',
      dataIndex: 'createdAt',
      key: '2',
      render: (value) => {
        if (value) {
          return moment(value).format('MM/DD/YYYY HH:mm:ss');
        } else {
          return '--';
        }
      }
    },{
      title: 'Status',
      dataIndex: 'status',
      key: '3',
      render: (value)=>{
        switch (value) {
          case 1: return 'QUEUED';
          case 2: return 'RUNNING';
          case 3: return 'FAILED';
          case 4: return 'COMPLETED';
          case 5: return 'TERMINATED';
          default:
            return '--';
        }
      }
    },{
      title: 'Parameters',
      key: '4',
      dataIndex: 'params',
      render: (params,record) => {
        return this.displayParams(record.report_id,params).map(x=>{
          return <div>{x}</div>
        });
      }
    },{
      title: 'Cloud File',
      dataIndex: 'file',
      key: '5',
      render: (value,record) => {
        if (record.status === 4) {
          return <a href={'/api/v1/reports/download/'+record.id} download={record.file}>{value}</a>;
        } else {
          return value;
        }
      }
    },{
      title: 'Completed',
      dataIndex: 'completedAt',
      key: '6',
      render: (value)=> {
        if (value) {
          return moment(value).format('MM/DD/YYYY HH:mm:ss');
        } else {
          return '--';
        }
      }
    },{
      title: 'Actions',
      dataIndex: 'file',
      key: '7',
      align: 'right',
      render: (value,record) => {
        if (record.status === 2) {
          return (
            <Button style={{paddingLeft:'5px'}} type='link' onClick={()=>this.cancelReport(record.id)}>
              Cancel
            </Button>
          );
        } else {
          return (
            <div>
              <Button style={{paddingLeft:'5px'}} type='link' onClick={()=>this.deleteReport(record.id)}>
                <DeleteOutlined style={{fontSize:'18px'}} />
              </Button>
            </div>
          );
        }
      }
    }];

    const data = safe(this.props.list,'rows',[]);
    const count = safe(this.props.list,'count',0);

    return (
      <div>
        <Spin spinning={this.props.isBusy}>
          <h3>My Reports</h3>
          <Table
            className='reports-sessionlist-table'
            columns={columns}
            dataSource={data}
            bordered={true}
            size='small'
            rowKey='id'
            scroll={{ x:true }}
            onChange={this.tableChange}
            pagination={{
              current: this.state.currentPage,
              pageSize: this.state.pageSize,
              total: count
            }}
          />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    list: safe(state,'reports.queue.list.data',[]),
    isBusy: (
      safe(state,'reports.queue.list.processing',false) ||
      safe(state,'reports.queue.delete.processing',false)
    ) ? true : false,
    addDelete: safe(state,'reports.queue.delete',{})
  }
}

export default connect(mapStateToProps,actions)(MyReports);
