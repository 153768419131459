import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Select } from 'antd';
import { connect } from 'react-redux';

import * as actions from '../../../../../../actions/emr';
import { safe } from '../../../../../../helpers/reduxSelectors';

const { Option } = Select;

class AddProblemItem extends Component {
  static propTypes = {
    patientId: PropTypes.number
  }

  constructor(props) {
    super(props);
    this.state = {
      new_description: null,
      new_diagnosis: null,
    }
  }

  componentDidMount() {
    if (!this.props.diagnosisList) {
      this.props.getDiagnosisList('');
    }
  }

  onClickCreate = () => {
    if (this.state.new_description && this.state.new_description.trim().length > 0) {
      this.props.createProblemListItem(this.props.patientId,{
        description: this.state.new_description,
        diagnosis_id: this.state.new_diagnosis,
        progression: 4,
        severity: 2
      });
      this.setState({
        new_diagnosis: null,
        new_description: null
      });
    }
  }
  
  render() {
    return (
      <div style={{paddingBottom:'20px'}}>
        <table width='100%'>
          <tbody>
            <tr>
              <td width='30%'>
                <Input
                  placeholder='Enter problem description...'
                  style={{width:'99%',paddingRight:'20px'}}
                  value={this.state.new_description}
                  onChange={(event)=>this.setState({new_description:event.target.value})}
                />
              </td>
              <td width='70%'>
                <Select
                  style={{width:'100%',marginRight:'10px'}}
                  placeholder="Search for diagnosis..."
                  allowClear
                  showSearch
                  filterOption={false}
                  value={this.state.new_diagnosis}
                  onChange={(value)=> this.setState({new_diagnosis:value})}
                  onSearch={(value) => this.props.getDiagnosisList(value)}
                  defaultActiveFirstOption={false}
                  notFoundContent={null}
                >
                  {this.props.diagnosisList.map((item, key) => {
                    return <Option key={key} value={item.id}>{item.icd10cm+'-'+item.description}</Option>;
                  })}
                </Select>
              </td>
              <td align='right' width='125px'>
                <Button type='primary' onClick={this.onClickCreate}>Add Problem</Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    diagnosisList: safe(state,'emr.diagnosis.data',[])
  }
}

export default connect(mapStateToProps,actions)(AddProblemItem);
