import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Spin, Button, notification } from 'antd';

import SupervisorCell from './Supervisor_/SupervisorCell';
import Comments from './Supervisor_/Comments';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/emr';

class Supervisor extends Component {

  state = {
    selection: {},
    errors: [],
  }

  componentDidMount() {
    this.props.getSuperviseeCountersignList();
  }

  resetErrors = () => {
    this.setState({
      errors: []
    });
  }

  setErrors = (data) => {
    this.setState({
      errors: data
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bulkSaveEvent !== this.props.bulkSaveEvent) {
      console.log('---',this.props.bulkSaveEvent);
      if (prevProps.bulkSaveEvent.success !== this.props.bulkSaveEvent.success &&
        this.props.bulkSaveEvent.success) {
        if (this.props.bulkSaveEvent.data && this.props.bulkSaveEvent.data.length > 0) {
          notification.error({
            message: 'Error',
            description: this.props.bulkSaveEvent.data.length + ' notes could not be approved'
          });
          this.setErrors(this.props.bulkSaveEvent.data);
        } else {
          notification.success({
            message: 'Success',
            description: 'Supervisee notes approved'
          });
          this.resetErrors();
        }
        this.props.bulkSupervisorApproveClear();
        this.props.getSuperviseeCountersignList();
        this.props.getUnsignedNotesList();

      } else if (prevProps.bulkSaveEvent.error !== this.props.bulkSaveEvent.error &&
        this.props.bulkSaveEvent.error) {
        notification.error({
          message: 'Unable to Approve Notes',
        });
        this.resetErrors();
        this.props.bulkSupervisorApproveClear();
        this.props.getSuperviseeCountersignList();
        this.props.getUnsignedNotesList();
      }
    }

    if (prevProps.noteRejectEvent !== this.props.noteRejectEvent) {
      if (prevProps.noteRejectEvent.success !== this.props.noteRejectEvent.success
        && this.props.noteRejectEvent.success) {
        this.props.noteRejectClear();
        this.props.getSuperviseeCountersignList();
        this.props.getUnsignedNotesList();
      } else if (prevProps.noteRejectEvent.error !== this.props.noteRejectEvent.error
        && this.props.noteRejectEvent.error) {
        notification.error({
          message: 'Unable to Reject Note',
          description: this.props.noteRejectEvent.error
        });
        this.props.noteRejectClear();
        this.props.getSuperviseeCountersignList();
        this.props.getUnsignedNotesList();
      }
    }
  }

  getListOfProviders = () => {
    let providers = {};
    this.props.list.forEach(x => {
      providers[x.provider_id] = x.provider.full_name;
    });
    return providers;
  }

  getListForProvider = (provider_id) => {
    return this.props.list.filter(x => x.provider_id === parseInt(provider_id,10));
  }

  onSelectChange = (providerId,selectedRowKeys) => {
    if (selectedRowKeys.length === 0) {
      const sel = {
        ...this.state.selection
      }
      delete(sel[providerId]);
      this.setState({
        selection: sel
      });
    } else {
      this.setState({
        selection: {
          ...this.state.selection,
          [providerId]: selectedRowKeys
        }
      });
    }
  }

  isInSelection = (x) => {
    const list = Object.values(this.state.selection);
    return (list.filter(item =>item.includes(x)).length > 0) ? true : false;
  }

  hasErrorMessage = (provider_id,note_id) => {
    if (this.state.errors && this.state.errors.length > 0) {
      const x = this.state.errors.find(item=>item.provider_id == provider_id &&
        item.note_id == note_id);
      if (x) {
        return x.message;
      }
    }
    return null;
  }

  displayProviderTable = (list,providerId,providerName) => {
    const columns = [{
      title: 'Patient Name',
      dataIndex: 'full_name',
      render: (text,record) => {
        const error = this.hasErrorMessage(record.provider_id,record.id);
        return (
          <div>
            { error !== null && <div style={{color:'red',paddingBottom:'5px'}}>{error}</div> }
            <SupervisorCell note={record} userId={this.props.userId}/>
          </div>
        );
      }
    },{
      title: 'Revision Comments',
      width: '400px',
      dataIndex: 'supervisor_comment',
      render: (text,record) => {
        return (
          <Comments
            comments={record.comments}
//            disabled={this.isInSelection(record.id)}
            noteId={record.id}
            userId={this.props.userId}
            providerId={providerId}
            onSelectChange={this.onSelectChange}
          />
        );
      }
    }];

    const sel = (this.state.selection[providerId]) ? this.state.selection[providerId] : [];
    const rowSelection = {
      selectedRowKeys: sel,
      onChange: (x) => this.onSelectChange(providerId,x)
    };

    return (
      <div key={providerId} style={{paddingBottom:'20px'}}>
        <h4>{providerName}</h4>
        <Table
          rowSelection={rowSelection}
          dataSource={list}
          columns={columns}
          bordered={true}
          pagination={false}
          rowKey='id'
          className='comments'
          rowClassName='supervisor-table-row'
        />
      </div>
    );
  }

  displayAll = () => {
    const providers = this.getListOfProviders();
    const provider_ids = Object.keys(providers);

    if (provider_ids.length === 0) {
      return (
        <div>
          No Supervisee Notes to Countersign
        </div>
      );
    }

    return provider_ids.map(provider_id => {
      return this.displayProviderTable(this.getListForProvider(provider_id),provider_id,providers[provider_id]);
    });
  }

  handleSubmit = () => {
    const sel = { ...this.state.selection };
    this.setState({
      selection: {}
    },()=>{
      this.props.bulkSupervisorApprove(sel);
    });
  }

  render() {
    const disabled =
      (this.props.isBusy ||
      Object.keys(this.state.selection).length === 0 ||
      this.props.list.length === 0) ? true : false;

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <h3>Supervisee Notes to Approve</h3>
          <div style={{textAlign:'right'}}>
            <Button type='primary' disabled={disabled} onClick={this.handleSubmit}>Approve Selected Notes</Button>
          </div>
          {this.displayAll()}
          <div style={{textAlign:'right'}}>
            <Button type='primary' disabled={disabled} onClick={this.handleSubmit}>Approve Selected Notes</Button>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'emr.note.superviseeunsignedlist.processing') ||
      safe(state,'emr.note.supervisorbulkapprove.processing') ||
      safe(state,'emr.note.reject.processing')
    ) ? true : false,
    userId: safe(state,'shared.auth.data.id'),
    list: safe(state,'emr.note.superviseeunsignedlist.data',[]),
    bulkSaveEvent: safe(state,'emr.note.supervisorbulkapprove'),
    noteRejectEvent: safe(state,'emr.note.reject'),
  }
}

export default connect(mapStateToProps,actions)(Supervisor);
