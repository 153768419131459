/**
 * StartDateType.tsx
 */
import { useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Button, DatePicker, Dropdown, Form, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import formItemRules from 'components/App_/ReportsV2_/formItemRules';
import QueueContext from 'components/App_/ReportsV2_/QueueContext';
import { startDateTypes } from 'reports';

const StartDateType: React.FC = () => {
  const form = useContextSelector(QueueContext, (c) => c.state.form);
  const isRecurring = useContextSelector(QueueContext, (c) => c.state.isRecurring);
  const [startDateType, setStartDateType] = useState<number | undefined>();

  const onSelectStartDateType = (startDateType: number) => {
    form?.setFieldsValue({ startDateType });
    setStartDateType(startDateType);
  };

  const onStartDateChange = (date: null | moment.Moment) => {
    const startDate = date?.seconds(0).milliseconds(0);
    form?.setFieldsValue({ startDate });
  };

  const paramsStartDateParams = {
    placeholder: '',
    onChange: onStartDateChange,
  };

  const StartDateTypeSelect = () => (
    <Form.Item
      style={{ display: 'inline-block' }}
      className="m0 mr8"
      name="startDateType"
      rules={formItemRules.startDateType}
    >
      <h5>Start Date Type</h5>
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            {startDateTypes.map((type, key) => (
              <Menu.Item key={key} onClick={() => onSelectStartDateType(key + 1)}>
                {type}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button type="default" size="small">
          {startDateType ? startDateTypes[startDateType - 1] : 'Select'} <DownOutlined />
        </Button>
      </Dropdown>
    </Form.Item>
  );

  const StartDatePicker = () => (
    <Form.Item style={{ display: 'inline-block' }} className="m0" name="startDate" rules={formItemRules.startDate}>
      <h5>Start Date</h5>
      <DatePicker {...paramsStartDateParams} size="small" />
    </Form.Item>
  );

  return (
    <div className="mt4">
      {isRecurring && <StartDateTypeSelect />}
      {!isRecurring || (isRecurring && startDateType === 1) ? <StartDatePicker /> : null}
    </div>
  );
};

export default StartDateType;
