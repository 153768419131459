import React from 'react';
import { DeleteOutlined, ScheduleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, DatePicker, Select, Spin, Button, Tag } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import * as emr_actions from '../../../../../actions/emr';
import * as shared_actions from '../../../../../actions/shared';
import { safe } from '../../../../../helpers/reduxSelectors';
import Time from '../../../../shared/Time';
import DeleteSessionModal from './DeleteSessionModal';
import UpdateSessionModal from './UpdateSessionModal';

const diffHrs = Time.getTzOffset();

class ModifySession extends React.Component {

  state = {
    deleteModalVisible: false,
    updateModalVisible: false,
    updateModalData: {}
  }

  handleDelete = (item) => {
    this.setState({
      deleteModalVisible: true
    });
  }

  handleSave = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.item.repeat) {
          this.setState({
            updateModalVisible: true,
            updateModalData: values,
          });
        } else {
          this.props.updateSession(this.props.provider_id,1,this.props.calendar_date,
            this.props.item.id,this.state.sequence_type,values);
          this.props.onClose();
        }
      }
    });
  }

  handleCancel = () => {
    this.props.onClose();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.form.resetFields();
      this.props.getLocationsList();
//      this.props.getProviderLocations(this.props.provider_id);
    }
  }

  displayRepeatsSituation = () => {
    const repeat_frequency = safe(this.props,'item.repeat_frequency');
    const repeat_type = safe(this.props,'item.repeat_type');
    const repeat_end_date = safe(this.props,'item.repeat_end_date');
    const repeat_num_sessions = safe(this.props,'item.repeat_num_sessions');
    const freq = (repeat_frequency > 1) ? repeat_frequency + ' weeks' : 'week'

    const style = {
      textAlign: 'center',
      fontWeight: 'bold',
      fontStyle: 'italic',
      paddingTop: '10px'
    }
    if (repeat_type === 1) {
      // to end date
      return (
        <div style={style}>
          Repeats every {freq} until {moment(repeat_end_date).format('M/D/YYYY')}
        </div>
      );
    } else if (repeat_type === 2) {
      // num of sessions
      return (
        <div style={style}>
          Repeats every {freq} for a total of {repeat_num_sessions} sessions
        </div>
      );
    }
    return (
      <div style={style}>
        This session does not repeat
      </div>
    )
  }

  renderAdminForm = () => {
    const FormItem = Form.Item;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };

    const manualTimeRange = Time.range(new Time(600),new Time(2200),15);
    const durations = [15,30,45,60,75,90,105,120];

    const st = safe(this.props,'item.start_time') || 0;
    const et = safe(this.props,'item.end_time')|| 0;

    const sd = safe(this.props,'item.session_date');
    const { session_date, session_date_local, time, local_time } = Time.fixDateTime(sd, st);
    
    return (
      <Spin spinning={this.props.isBusy}>
        <Form className='compressed-form' autoComplete='false'>

          <FormItem {...formItemLayout} label="Provider">
            {getFieldDecorator('provider_id', {
              initialValue: safe(this.props,'item.provider_id')
            })(
              <span>{this.props.provider_name}</span>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Session Date">
            {getFieldDecorator('session_date', {
              rules: [{ required: true, message: " " }],
              initialValue: session_date
            })(
              <DatePicker format='MM/DD/YYYY' onChange={this.dateChanged} />
            )}
            <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
            {diffHrs && st ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {session_date_local.format('MM/DD/YYYY')}</Tag> : null}
          </FormItem>

          <FormItem {...formItemLayout} label="Starting Time">
            {getFieldDecorator('start_time', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(this.props,'item.start_time')
            })(
              <Select style={{width:'85%',marginRight:'5px'}}>
                {manualTimeRange.map((x) => {
                  let t = `${x.name} ${Time.getPSTPDT()}`;
                  if (diffHrs) {
                    t = `${t} - (${x.local} Local)`;
                  }
                  return <Option key={x.id} value={x.id}>{t}</Option>;
                })}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Duration">
            {getFieldDecorator('duration', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(this.props,'item.duration')
            })(
              <Select>
                {durations.map(x=><Option value={x} key={'d'+x}>{x} mins</Option>)}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Note">
            {getFieldDecorator('comment', {
              rules: [{ required: true, message: " " }],
              initialValue: safe(this.props,'item.comment')
            })(
              <Input />
            )}
          </FormItem>

          { this.displayRepeatsSituation() }

        </Form>
      </Spin>
    );

  }

  toggleTelemedicine = () => {
    const This = this;
    const telemedicine = safe(this.props,'item.telemedicine',0);
    const title = (telemedicine === 1) ? 'Change to an in-office session?' :
      'Change to a video session?';

    Modal.confirm({
      title: title,
      okText: 'Yes',
      onOk() {
        This.props.updateSession(This.props.provider_id,1,This.props.calendar_date,
          This.props.item.id,1,{
            telemedicine: (telemedicine === 1) ? 0 : 1
          });
        This.props.onClose();
      },
      onCancel() {},
    });
  }

  renderForm = () => {
    const isPrescriber = safe(this.props,'provider_roles',[]).includes('prescriber');
    let defaultDuration = (isPrescriber) ? 30 : 45;
    const st = safe(this.props,'item.start_time') || 0;
    const et = safe(this.props,'item.end_time')|| 0;
    if (st && et) {
      defaultDuration = (new Time(st)).diff(new Time(et));
    }

    const location_id = safe(this.props,'item.location_id');
    const loc = this.props.locationsList.find(x=>x.id === location_id);
    const sd = safe(this.props,'item.session_date');
    const { session_date, session_date_local, time, local_time } = Time.fixDateTime(sd, st);

    return (
      <div>
        <div>Provider: {this.props.provider_name}</div>
        <div>
          Session Date: {session_date?.format('M/D/YYYY')}
          {diffHrs ? (
            <>
              <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
              {session_date_local ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {session_date_local.format('MM/DD/YYYY')}</Tag> : null}
            </>
          ) : null}
        </div>
        <div>Type: {(safe(this.props,'item.session_type',2) === 1) ? 'Intake' : 'Followup'}</div>
        <div>Duration: {defaultDuration}</div>
        <div>Telemedicine: {(safe(this.props,'item.telemedicine',0) === 1) ? 'Yes' : 'No'}
          &nbsp; <a onClick={this.toggleTelemedicine}>(change)</a></div>
        <div>Location: {(loc) ? loc.name : ''}</div>
        <div>
          Starting Time: {(time)?.toString(false)}
          {diffHrs ? (
            <>
              <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
              {local_time ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {local_time.toString(false)}</Tag> : null}
            </>
          ) : null}
        </div>
        { this.displayRepeatsSituation() }
      </div>
    );
  }

  closeDeleteModal = () => {
    this.setState({
      deleteModalVisible: false
    },()=>{
      this.props.onClose();
    });
  }

  closeUpdateModal = () => {
    this.setState({
      updateModalVisible: false
    },()=>{
      this.props.onClose();
    });
  }

  getModalTitle = () => {
    return (
      <Link to={'/emr/patients/'+safe(this.props,'item.patient_id',0)}>
        {safe(this.props,'item.patient')}
      </Link>
    )
  }

  handleReschedule = () => {
    const patient_id = safe(this.props,'item.patient_id',0);
    const session_id = safe(this.props,'item.id',0);
    this.props.history.push(`/emr/appointments/${patient_id}/${session_id}/3`);
  }

  getModalFooter = () => {
    const isSession = (safe(this.props,'item.type',1) === 1) ? true : false;

    if (isSession) {
      return (
        <table style={{width:'100%'}}>
          <tbody>
            <tr>
              <td style={{textAlign:'left'}}>
                <Button type='danger' icon={<DeleteOutlined />} onClick={this.handleDelete}>Delete</Button>
              </td>
              <td style={{textAlign:'center'}}>
                <Button type='primary' icon={<ScheduleOutlined />} onClick={this.handleReschedule}>Reschedule</Button>
              </td>
              <td style={{textAlign:'right'}}>
                <Button onClick={this.handleCancel}>Cancel</Button>
              </td>
            </tr>
          </tbody>
        </table>
      );
    } else {
      return (
        <table style={{width:'100%'}}>
          <tbody>
            <tr>
              <td style={{textAlign:'left'}}>
                <Button type='danger' icon={<DeleteOutlined />} onClick={this.handleDelete}>Delete</Button>
              </td>
              <td style={{textAlign:'right'}}>
                <Button type='primary' onClick={this.handleSave}>Update</Button>
                <Button onClick={this.handleCancel}>Cancel</Button>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  }

  render() {
    const isSession = (safe(this.props,'item.type',1) === 1) ? true : false;
    return (
      <div>
        <Modal
          title={isSession ? this.getModalTitle(this.props.seg) : 'Admin Time'}
          visible={this.props.visible}
          onCancel={this.handleCancel}
          footer={this.getModalFooter()}
          centered={true}
        >
          {(isSession) ? this.renderForm() : this.renderAdminForm() }
        </Modal>

        <DeleteSessionModal
          visible={this.state.deleteModalVisible}
          onClose={this.closeDeleteModal}
          data={this.props.item}
        />

        <UpdateSessionModal
          visible={this.state.updateModalVisible}
          onClose={this.closeUpdateModal}
          item={this.props.item}
          newData={this.state.updateModalData}
          provider_id={this.props.provider_id}
          provider_name={this.props.provider_name}
        />

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    locationsList: safe(state,'shared.location.list.data',[]),
//    locationsList: safe(state,'emr.provider.locations.data',[]),
    openings: safe(state,'emr.provider.opentimes.data',[]),
    activeUserId: safe(state,'shared.domain.activeUser.id'),
    calendar_date: safe(state,'emr.emr.active_date',moment().format('YYYY-MM-DD')),
    isBusy: (
      safe(state,'emr.provider.opentimes.processing',false)
    ) ? true : false
  }
}

export default connect(mapStateToProps,{...emr_actions,...shared_actions})(Form.create()(withRouter(ModifySession)));
