import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input } from 'antd';

class CommentModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    action: PropTypes.string,
    timeoff_id: PropTypes.number,
    onClose: PropTypes.func,
    onApprove: PropTypes.func,
    onReject: PropTypes.func
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.form.resetFields();
    }
  }

  handleOk = () => {
    this.props.form.validateFields((err,values) => {
      if (!err) {
        if (this.props.action === 'reject') {
          this.props.onReject(this.props.timeoff_id,values.response_comment);
        } else if (this.props.action === 'approve') {
          this.props.onApprove(this.props.timeoff_id,values.response_comment);
        }
        this.props.onClose();
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    let title = '';
    let okText = '';

    if (this.props.action === 'reject') {
      title = 'Reject Time Off Request';
      okText = 'Reject';
    } else if (this.props.action === 'approve') {
      title = 'Approve Time Off Request';
      okText = 'Approve';
    }

    return (
      <Modal
        title={title}
        visible={this.props.visible}
        onCancel={this.props.onClose}
        okText={okText}
        width={650}
        onOk={this.handleOk}
      >
        <Form>
          <Form.Item {...formItemLayout} label='Response Comment'>
            {getFieldDecorator('response_comment',{
            })(
              <Input.TextArea />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(CommentModal);
