import { combineReducers } from 'redux';
import { ajaxReducer } from '../../helpers/util';

export default combineReducers({
  notifications: combineReducers({
    save: ajaxReducer('NOTIFICATIONS_SAVE'),
    load: ajaxReducer('NOTIFICATIONS_LOAD'),
    list: ajaxReducer('NOTIFICATIONS_LIST')
  }),
  integrations: combineReducers({
    load: ajaxReducer('INTEGRATIONS_LOAD'),
    save: ajaxReducer('INTEGRATIONS_SAVE'),
  }),
  scheduling: combineReducers({
    load: ajaxReducer('SCHEDULING_LOAD'),
    save: ajaxReducer('SCHEDULING_SAVE')
  }),
  groups: combineReducers({
    info: ajaxReducer('GROUPS_INFO'),
    memberadd: ajaxReducer('GROUPS_MEMBERADD'),
    memberdel: ajaxReducer('GROUPS_MEMBERDEL'),
    memberupdate: ajaxReducer('GROUPS_MEMBERUPDATE'),
    userlist: ajaxReducer('GROUPS_USERLIST'),
    reassign: ajaxReducer('GROUPS_REASSIGN')
  }),
  careteam: combineReducers({
    groupassign: ajaxReducer('CARETEAM_ASSIGNGROUP')
  }),
  holidays: combineReducers({
    list: ajaxReducer('HOLIDAY_LIST'),
    load: ajaxReducer('HOLIDAY_LOAD'),
    create: ajaxReducer('HOLIDAY_CREATE'),
    update: ajaxReducer('HOLIDAY_UPDATE'),
    remove: ajaxReducer('HOLIDAY_REMOVE')
  }),
  portal: combineReducers({
    load: ajaxReducer('PORTAL_LOAD'),
    save: ajaxReducer('PORTAL_SAVE')
  }),
  org_load: ajaxReducer('ORG_LOAD'),
  org_save: ajaxReducer('ORG_SAVE'),

  emails_save: ajaxReducer('EMAILS_SAVE'),
  emails_load: ajaxReducer('EMAILS_LOAD'),
  emails_list: ajaxReducer('EMAILS_LIST'),
});
