/* eslint-disable react/display-name */
import { useState } from 'react';
import { Button, Popover, Space, Table } from 'antd';
import moment from 'moment';
import reports, {
  INSTANCE_RUNNING,
  INSTANCE_COMPLETE,
  INSTANCE_CANCELED,
  INSTANCE_READY,
  INSTANCE_FAILED,
} from 'reports';
import DetailsTable from 'components/App_/ReportsV2_/DetailsTable';
import FormattedParams from './FormattedParams';

const wrapperStyle = {
  maxWidth: '80rem',
  margin: 'auto',
};

interface PropsI {
  data: any;
}

const ProcessedReports: React.FC<PropsI> = (props: PropsI) => {
  const { data } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const onTableChange = (pg) => {
    const page = pg.current;
    const pageSize = pg.pageSize;
    setPage(page);
    setPageSize(pageSize);
  };

  const columns: Array<Record<string, unknown>> = [
    {
      title: 'Report Type',
      dataIndex: 'reportId',
      render: (val: number) => {
        const foundReport = reports.find((r: any) => r.id === val);
        return foundReport?.name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (val: number) => {
        switch (val) {
          case INSTANCE_RUNNING:
            return 'Running'; // prepared
          case INSTANCE_COMPLETE:
            return 'Complete';
          case INSTANCE_FAILED:
            return 'Failed';
          case INSTANCE_CANCELED:
            return 'Canceled';
          case INSTANCE_READY:
          default:
            return 'Ready';
        }
      },
    },
    {
      title: 'Scheduled Run Date',
      dataIndex: 'scheduledRunDate',
      render: (val: string) => {
        if (val) {
          return moment(val).format('llll');
        }
        return '';
      },
    },
  ];

  const pagination =
    data.length > 5
      ? {
          showQuickJumper: true,
          current: page,
          pageSize,
          total: data.length,
        }
      : false;

  const flattenedData: Record<string, unknown>[] = [];
  data.forEach((report) => {
    report.instances.forEach((instance) => {
      flattenedData.push({
        ...instance,
        reportId: report.reportId,
      });
    });
  });

  const subColumns: Array<Record<string, unknown>> = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (val: number) => {
        switch (val) {
          case 1:
            return 'Running';
          case 2:
            return 'Complete';
          case 3:
            return 'Failed';
          case 0:
          default:
            return 'Ready';
        }
      },
    },
    {
      title: 'Completed Date',
      dataIndex: 'completedAt',
      render: (val: string) => (val ? moment(val).format('llll') : 'n/a'),
    },
    {
      title: 'Actions',
      key: 'operation',
      render: (_val: string, record) => {
        return (
          <Space size="middle" className="tac">
            <Popover content={<FormattedParams params={record.params} />} trigger="click">
              <Button size="small" type="default">
                View Parameters
              </Button>
            </Popover>
            {record.status === 2 && (
              <Button type="link" href={`/api/v2/reports/${record.fileName}`}>
                Download CSV
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className="p4 tac" style={wrapperStyle}>
        <h4>Processed Reports</h4>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (instance) => <DetailsTable columns={subColumns} details={instance?.details} />,
            rowExpandable: (instance) => Boolean((instance?.details as Record<string, unknown>[]).length),
          }}
          dataSource={flattenedData}
          bordered={true}
          size="small"
          rowKey="id"
          onChange={onTableChange}
          pagination={pagination}
        />
      </div>
    </>
  );
};

export default ProcessedReports;
