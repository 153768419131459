import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Spin, DatePicker, Button } from 'antd';
import numeral from 'numeral';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';

class ReportsIntakes extends React.Component {

  displayReport = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.getIntakesReport(values);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: 'Provider',
      dataIndex: 'provider_name'
    },{
      title: 'Adult Intakes Completed',
      dataIndex: 'adult_intakes',
      render: (value) => (value) ? value : null
    },{
      title: 'Adult Intakes Unfilled',
      dataIndex: 'adult_intakes_remaining',
      render: (value) => (value) ? value : null
    },{
      title: 'Child Intakes Completed',
      dataIndex: 'child_intakes',
      render: (value) => (value) ? value : null
    },{
      title: 'Child Intakes Unfilled',
      dataIndex: 'child_intakes_remaining',
      render: (value) => (value) ? value : null
    },{
      title: 'Intakes % Filled',
      dataIndex: 'intakes_pfilled',
      render: (value) => (value) ? numeral(value).format('%') : null
    }];

    //             <span style={{float:'right'}}><a href="/api/v1/reports/intakes?csv=1">CSV Download</a></span>

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
          </div>

          <h3>Filled/Unfilled Intakes Report</h3>

          <Form layout="inline">
            <Form.Item>
             {getFieldDecorator('start_date', {
               rules: [{ required: true, message: ' ' }],
             })(
               <DatePicker format='MM/DD/YYYY' placeholder="Starting Date"/>
             )}
             </Form.Item>
             <Form.Item>
              {getFieldDecorator('end_date', {
                rules: [{ required: true, message: ' ' }],
              })(
                <DatePicker format='MM/DD/YYYY' placeholder="Ending Date"/>
              )}
             </Form.Item>

            <Button type='primary' onClick={this.displayReport}>Display Report</Button>
          </Form>

          { this.props.data && this.props.data.length > 0 &&
          <Table
            className='reports-sessionlist-table'
            columns={columns}
            dataSource={this.props.data}
            bordered={true}
            size='small'
            rowKey='id'
            pagination={false}
          /> }
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: safe(state,'reports.intakes.data',[]),
    isBusy: safe(state,'reports.intakes.processing',false)
  }
}

export default connect(mapStateToProps,actions)(Form.create()(ReportsIntakes));
