import React from 'react';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { List, Tag } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import AuthorizationEdit from './Authorizations_/AuthorizationEdit';
import { safe } from '../../../../../helpers/reduxSelectors';
import * as actions from '../../../../../actions/billing';
import { processEventNotification } from '../../../../../helpers/util';

class Authorizations extends React.Component {
  state = {
    dialogVisible: false,
    dialogData: null
  }

  componentDidUpdate(prevProps) {
    processEventNotification(prevProps,this.props,'deleteEvent','Authorization Deleted');
  }

  openEditDialog = (data) => {
    this.setState({
      dialogVisible: true,
      dialogData: data
    });
  }

  closeEditDialog = () => {
    this.setState({
      dialogVisible: false,
      dialogData: null
    });
  }

  deleteAuth = (id) => {
    this.props.deleteAuthorization(this.props.patientId,id);
  }

  render() {
    return (
      <div>
        <div style={{paddingBottom: '10px',textAlign:'center'}}>
          <a onClick={()=>this.openEditDialog(0)}>
            <PlusCircleOutlined />&nbsp; Add an Authorization
          </a>
        </div>

        <List
          itemLayout="horizontal"
          dataSource={this.props.data}
          renderItem={item => {
            const uses = (item.uses) ? ' ('+item.uses+' uses)' : '';
            const actions = [
              <a onClick={()=>this.openEditDialog(item)}><EditOutlined /></a>,
              <a onClick={()=>this.deleteAuth(item.id)}><DeleteOutlined /></a>
            ];
            const type = (parseInt(item.type,10) === 2) ? 'Psychiatry' : 'Therapy';
            const active = (item.active) ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>;
            return (
              <List.Item actions={actions}>
                <List.Item.Meta
                  title={<span>{active}{safe(item,'carrier.name') + ': ' + item.auth_num}</span>}
                  description={moment(item.start_date).format('M/D/YYYY') + '-' +
                    moment(item.end_date).format('M/D/YYYY') + uses + ' ' + type}
                />
              </List.Item>
            );
          }}
        />

        <AuthorizationEdit
          data={this.state.dialogData}
          visible={this.state.dialogVisible}
          patientId={this.props.patientId}
          closeDialog={this.closeEditDialog}
        />

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deleteEvent: safe(state,'billing.authorizations.delete',{})
  }
}

export default connect(mapStateToProps,actions)(Authorizations);
