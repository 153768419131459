import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import numeral from 'numeral';

const columns: Array<ColumnI> = [
  {
    title: 'Provider',
    dataIndex: 'provider_name',
  },
  {
    title: 'Adult Intakes Completed',
    dataIndex: 'adult_intakes',
    render: (value) => (value ? value : null),
  },
  {
    title: 'Adult Intakes Unfilled',
    dataIndex: 'adult_intakes_remaining',
    render: (value) => (value ? value : null),
  },
  {
    title: 'Child Intakes Completed',
    dataIndex: 'child_intakes',
    render: (value) => (value ? value : null),
  },
  {
    title: 'Child Intakes Unfilled',
    dataIndex: 'child_intakes_remaining',
    render: (value) => (value ? value : null),
  },
  {
    title: 'Intakes % Filled',
    dataIndex: 'intakes_pfilled',
    render: (value) => (value ? numeral(value).format('%') : null),
  },
];

export default columns;
