/**
 * Integrate Sentry Error Logging
 * Only works for Production & Staging
 * TODO: Add production DSN
 */
import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing'; // removing this removes 429 too many requests errors

type NODE_ENVT = 'development' | 'production' | 'staging';

export default (): void => {
  let dsn: null | string = '';
  // console.log('### REACT_APP_ENV', process.env.REACT_APP_ENV);
  switch (process.env.REACT_APP_ENV as NODE_ENVT) {
    case 'production':
      dsn = 'https://1f70ae30e80c4706acc8a78204097334@o210121.ingest.sentry.io/1335574';
      break;
    case 'staging':
    case 'development':
      dsn = 'https://7ffea636c283429fa79f5e4399f5dfa1@o210121.ingest.sentry.io/5247971';
      break;
    default:
      dsn = null;
  }
  if (dsn?.length) {
    Sentry.init({
      dsn,
      // integrations: [new Integrations.BrowserTracing()],
      // tracesSampleRate: 1.0,
    });
  }
};
