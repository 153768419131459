import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Radio } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import * as actions from '../../../../../actions/emr';
import { safe } from '../../../../../helpers/reduxSelectors';

class UpdateSessionModal extends React.Component {

  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    newData: PropTypes.object,
    item: PropTypes.object
  }

  state = {
    sequence_type: null
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.setState({
        sequence_type: null
      });
    }
  }

  handleCancel = () => {
    this.props.onClose();
  }

  handleOk = () => {
    this.props.updateSession(this.props.provider_id,1,this.props.calendar_date,
      this.props.item.id,this.state.sequence_type,this.props.newData);
    this.props.onClose();
  }

  onChange = (e) => {
    this.setState({
      sequence_type: e.target.value
    });
  }

  getModalFooter = () => {
  //  const repeat = safe(this.props,'item.repeat',false);

    return (
      <div style={{textAlign:'right'}}>
        <Button onClick={this.handleCancel}>Cancel</Button>
        <Button disabled={!this.state.sequence_type} type='primary' onClick={this.handleOk}>Save</Button>
      </div>
    );
  }

  render() {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    return (
      <Modal
        title='Update Recurring Session?'
        visible={this.props.visible}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        footer={this.getModalFooter()}
        width={400}
        centered={true}
      >
        <div>
          <div>Would you like to update this session or this and all subsequent
          sessions in the series?</div>
          <Radio.Group onChange={this.onChange} value={this.state.sequence_type}>
            <Radio style={radioStyle} value={1}>
              This session only
            </Radio>
            <Radio style={radioStyle} value={2}>
              This session and all subsequent sessions
            </Radio>
          </Radio.Group>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeUserId: safe(state,'shared.domain.activeUser.id'),
    calendar_date: safe(state,'emr.emr.active_date',moment().format('YYYY-MM-DD'))
  }
}

export default connect(mapStateToProps,actions)(UpdateSessionModal);
