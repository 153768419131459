import React from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Select, Input, InputNumber, message, Button, Spin } from 'antd';
import numeral from 'numeral';
import moment from 'moment';

import ChooseServiceLine from './PaymentEntry_/ChooseServiceLine';
import FormattedCell from '../../../shared/FormattedCell';

import { safe } from '../../../../helpers/reduxSelectors';
import { paymentMethods } from '../../../../helpers/paymentMethods';
import * as actions from '../../../../actions/billing';

class PaymentEntry extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      payment_id: parseInt(this.props.match.params.id,10),
      storedData: []
    }
  }

  componentDidMount() {
    this.props.getPayerList();
    if (this.state.payment_id !== 0) {
      this.props.getPayment(this.state.payment_id);
    } else {
      this.props.getPaymentClear();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load !== this.props.load) {
      if (this.props.load.error) {
        this.props.history.push('/billing/payments/1');
        message.error('Unable to load payment information');
      }
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.success) {
        this.props.savePaymentClear();
        this.props.history.push('/billing/payments/1');
        message.success('Payment saved');
      } else if (this.props.error) {
        message.error('Unable to save payment');
      }
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        this.props.createPaymentClear();
        this.props.history.push('/billing/payments/1');
        message.success('Payment created');
      } else if (this.props.error) {
        message.error('Unable to save payment');
      }
    }

    if (this.props.loadSuccess && this.props.loadSuccess !== prevProps.loadSuccess) {
      this.props.updateBillingRecentList(3,this.state.payment_id,numeral(this.props.paymentData.amount).format('$0,0.00'));
    }

  }

  onSave = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.payment_id === 0) {
          this.props.createPayment({
            ...values,
            details: [
              ...this.state.storedData
            ]
          });
        } else {
          this.props.savePayment(this.state.payment_id,{
              ...values,
              details: [
                ...this.state.storedData
              ]
          });
        }
      }
    });
  }

  computeUnallocated = () => {
    let total = (this.state.storedData.reduce((acc,val)=>acc+parseFloat(val.amount),0));

    const pd = safe(this.props,'paymentData.payment_details',[]);
    let total2 = pd.reduce((acc,val)=>acc+parseFloat(val.ins_amount),0.0);
    total = (total + total2).toFixed(2);

    // Somehow after the value is stored in the field, the redraw doesn't get triggered.
    //const payment_amount = safe(this.props,'paymentData.amount',0.0);
    const payment_amount = this.props.form.getFieldValue('amount');
    if (payment_amount === parseFloat(payment_amount)) {
      return payment_amount - total;
    }
    return -total;
  }

  updateList =(newData) => {
    this.setState({
      storedData: newData
    })
  }

  whoCreatedPayment = () => {
    if (this.state.payment_id === 0) {
      return null;
    }
    const createdAt = moment(this.props.paymentData.createdAt).format('MM/DD/YYYY HH:mm:ss');
    const creator = safe(this.props,'paymentData.creator.initials','');

    const isDeleted = safe(this.props,'paymentData.deleted') ? true : false;
    const deletedAt = moment(this.props.paymentData.deletedAt).format('MM/DD/YYYY HH:mm:ss');
    const deletor = safe(this.props,'paymentData.deletor.initials','');

    return (
      <div style={{clear:'left',paddingLeft:'10px',fontStyle:'italic'}}>
        <span>Payment originally created by {creator} on {createdAt}. </span>
        { isDeleted && <span>Deleted by {deletor} on {deletedAt}.</span> }
      </div>
    );
  }

  isPaymentHeaderComplete = () => {
    const values = this.props.form.getFieldsValue();

    if (values.check_date && values.billing_payer_id && values.payment_type &&
      values.check_num && values.amount) {
        return true;
    }
    return false;
  }

  paymentParameters = () => {
    const FormItem = Form.Item;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const checkDate = safe(this.props,'paymentData.check_date',undefined);
    const isDeleted = safe(this.props,'paymentData.deleted') ? true : false;
    const initialValues = {
      check_date: (checkDate) ? moment(checkDate) : undefined,
      billing_payer_id: safe(this.props,'paymentData.billing_payer_id'),
      payment_type: safe(this.props,'paymentData.payment_type'),
      check_num: safe(this.props,'paymentData.check_num'),
      amount: safe(this.props,'paymentData.amount')
    };
    const payerList = this.props.payerList.map(x=><Option key={x.id} value={x.id}>{x.name}</Option>);
    const pMethods = paymentMethods.map(x=><Option key={x.id} value={x.id}>{x.name}</Option>);

    return (
      <div style={{backgroundColor:'#F5F0F0',marginBottom:'5px',padding:'5px',border:'1px solid lightgray'}}>
        <Form style={{display:'flex'}} layout='vertical' className='paymententry' onSubmit={this.handleSubmit} autoComplete="off">

          <FormItem style={{marginLeft:'5px',width:175}}>
            {getFieldDecorator('check_date', {
              rules: [{ required: true, message: " "}],
              initialValue: initialValues.check_date
            })(
              <DatePicker placeholder='Payment Date' format='M/DD/YYYY' disabled={isDeleted}/>
            )}
          </FormItem>

          <FormItem style={{marginLeft:'5px',width:250}}>
            {getFieldDecorator('billing_payer_id', {
              rules: [{ required: true, message: " "}],
              initialValue: initialValues.billing_payer_id
            })(
              <Select placeholder='Payer' disabled={isDeleted}>
                { payerList }
              </Select>
            )}
          </FormItem>

          <FormItem style={{marginLeft:'5px',width:150}}>
            {getFieldDecorator('payment_type', {
              rules: [{ required: true, message: " "}],
              initialValue: initialValues.payment_type
            })(
              <Select placeholder='Pay Method' disabled={isDeleted}>
                { pMethods }
              </Select>
            )}
          </FormItem>

          <FormItem style={{marginLeft:'5px',flexGrow:1}}>
            {getFieldDecorator('check_num', {
              rules: [{ required: true, message: " ", whitespace: true }],
              initialValue: initialValues.check_num
            })(
              <Input placeholder='Payment #' disabled={isDeleted}/>
            )}
          </FormItem>

          <FormItem style={{marginLeft:'5px',width:'150px'}}>
            {getFieldDecorator('amount', {
              rules: [
                { required: true, message: " " },
                {validator: (rule,value,callback) => {
                  if (value && parseFloat(value) !== value) {
                    callback(' ');
                  }
                  callback()
                }}
              ],
              initialValue: initialValues.amount
            })(
              <InputNumber precision={2} max={1000000} placeholder='Amount' disabled={isDeleted}/>
            )}
          </FormItem>

          {!isDeleted && <div style={{marginLeft:'5px',whiteSpace: 'nowrap',marginRight:'10px',width:200,textAlign:'right',lineHeight:'32px'}}>
            <strong>Unallocated</strong>:
            <FormattedCell blankonzero={false} reversed={false} value={this.computeUnallocated()}/>
          </div> }
        </Form>
        { this.whoCreatedPayment() }
      </div>
    );
  }
//                disabled={!this.isPaymentHeaderComplete()}
  eraButtonLink = (era_id) => {
    if (era_id) {
      return (
        <Button onClick={()=>this.props.history.push('/billing/era/reconcile/'+era_id)}>
          View Corresponding ERA {numeral(era_id).format('000000')}
        </Button>
      );
    }
  }
  render() {
    const isDeleted = safe(this.props,'paymentData.deleted') ? true : false;

    return (
      <div className='main' style={{minWidth:'1000px'}}>

        <Spin spinning={this.props.isBusy}>
          <h3>
            Insurance Payment Details: {numeral(this.state.payment_id).format('000000')}
            &nbsp; { this.eraButtonLink(safe(this.props,'paymentData.era_id')) }
            { !isDeleted &&
            <span style={{float:'right'}}>
              <Button
                onClick={this.onSave}
                size='small'
                type='primary'
              >
                Save
              </Button>
            </span> }
          </h3>

          { this.paymentParameters() }

          <ChooseServiceLine
            paymentId={this.state.payment_id}
            paymentData={this.props.paymentData}
            updateList={this.updateList}
          />
        </Spin>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    load: safe(state,'billing.inspayments.load'),
    paymentData: safe(state,'billing.inspayments.load.data',{}),
    loadSuccess: safe(state,'billing.inspayments.load.success',false),
    save: safe(state,'billing.inspayments.save'),
    create: safe(state,'billing.inspayments.create'),
    payerList: safe(state,'billing.payer.list.data',[]),
    isBusy: (
      safe(state,'billing.payer.list.processing') ||
      safe(state,'billing.inspayments.load.processing') ||
      safe(state,'billing.inspayments.save.processing') ||
      safe(state,'billing.inspayments.create.processing') ||
      safe(state,'billing.payer.list.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(Form.create()(PaymentEntry));
