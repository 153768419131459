import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';

const columns: Array<ColumnI> = [
  {
    title: 'EMR ID',
    dataIndex: 'id',
  },
  {
    title: 'Active/Inactive',
    dataIndex: 'active',
  },
  {
    title: 'First Name',
    dataIndex: 'firstname',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastname',
  },
  {
    title: 'Middle Name',
    dataIndex: 'middlename',
  },
  {
    title: 'Nickname',
    dataIndex: 'nickname',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'MSFT Email',
    dataIndex: 'microsoft_email',
  },
  {
    title: 'Employment Relationship',
    dataIndex: 'employment_relationship',
  },
  {
    title: 'Credential',
    dataIndex: 'credential',
  },
  {
    title: 'NPI',
    dataIndex: 'npi',
  },
  {
    title: 'License',
    dataIndex: 'license',
  },
  {
    title: 'Primary Office Location',
    dataIndex: 'primaryLocation',
  },
  {
    title: 'Secondary Office Location',
    dataIndex: 'secondaryLocation',
  },
  {
    title: 'Is a Supervisor?',
    dataIndex: 'is_supervisor',
  },
  {
    title: 'Supervised by',
    dataIndex: 'supervisedBy',
  },
];

export default columns;
