import React from 'react';
import { Input, Button } from 'antd';

class InterestPaymentEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0.0
    }
  }

  onChange = (event) => {
    this.setState({
      amount: event.target.value
    })
  }

  onSave = () => {
    this.props.onAddInterest(parseFloat(this.state.amount));
  }

  onCancel = () => {
    this.props.onClose();
  }

  render() {
    return (
      <div>
        Interest Amount: <Input style={{width:'150px'}} onChange={this.onChange} value={this.state.amount} />
        <Button style={{marginLeft:'5px',width:'80px'}} type='primary' onClick={this.onSave}>Add</Button>
        <Button style={{float:'right',width:'80px'}} onClick={this.onCancel}>Cancel</Button>
      </div>
    )
  }
}

export default InterestPaymentEntry;
