import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, DatePicker, Radio, Select, Input } from 'antd';
import { connect } from 'react-redux';

import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/scheduler';
import { processEventNotification } from '../../../../helpers/util';
import Time from '../../../shared/Time';

class CreateTimeoffModal extends React.Component {

  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    directorId: PropTypes.number
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.form.resetFields();
      if (this.props.directorId) {
        this.props.getProviderList(this.props.directorId);
      } else {
        this.props.getProviderList();
      }
    }

    processEventNotification(prevProps,this.props,'createEvent','Time Off Created');
  }

  handleCancel = () => {
    this.props.onClose();
  }

  handleOk = () => {
    this.props.form.validateFields((err,values) => {
      if (!err) {
        // For case of a partial-day, just put in the end date as the same
        // as the start date.
        const newValues = { ...values };
        if (newValues.duration === 1) {
          newValues.end_date = newValues.start_date
        } else {
          newValues.start_date = values.timeoff_dates[0];
          newValues.end_date = values.timeoff_dates[1];
        }
        this.props.onCreate(newValues);
        this.props.onClose();
      }
    });
  }

  providerSelected = (provider_id) => {
    this.props.getCoverageProviders(provider_id);
  }

  renderForm = () => {
    const { getFieldDecorator } = this.props.form;
    const manualTimeRange = Time.range(new Time(600),new Time(2200),15);
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const cpl = [{
      id: null,
      full_name: 'No Provider'
    },...this.props.covering_provider_list];

    return (
      <Form>
        <Form.Item {...formItemLayout} label='Provider'>
          {getFieldDecorator('provider_id',{
            rules: [{required: true, message: 'Select a provider'}],
          })(
            <Select
              showSearch
              onSelect={this.providerSelected}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              { this.props.provider_list.map(x=><Select.Option value={parseInt(x.id,10)} key={x.id}>{x.full_name}</Select.Option>) }
            </Select>
          )}
        </Form.Item>

        { this.props.form.getFieldValue('provider_id') && <div>
        <Form.Item {...formItemLayout} label='Request Type'>
          {getFieldDecorator('type', {
            rules: [{required: true, message: ' '}],
            initialValue: 4
          })(
            <Radio.Group>
               <Radio value={4}>PTO</Radio>
               <Radio value={5}>UTO</Radio>
             </Radio.Group>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Duration'>
          {getFieldDecorator('duration', {
            rules: [{required: true, message: ' '}],
            initialValue: 2
          })(
            <Radio.Group>
               <Radio value={1}>Partial Day</Radio>
               <Radio value={2}>One or More Full Days</Radio>
             </Radio.Group>
          )}
        </Form.Item>

        {this.props.form.getFieldValue('duration') !== 1 &&
          <Form.Item {...formItemLayout} label="Start and End Dates">
            {getFieldDecorator('timeoff_dates', {
              rules: [{ type: 'array', required: true, message: ' ' }],
            })(
              <DatePicker.RangePicker format='M/D/YYYY'/>
            )}
          </Form.Item>
        }

        {this.props.form.getFieldValue('duration') === 1 &&
          <Form.Item {...formItemLayout} label="Date">
            {getFieldDecorator('start_date', {
              rules: [{ required: true, message: ' ' }],
            })(
              <DatePicker format='M/D/YYYY'/>
            )}
          </Form.Item>
        }

        {this.props.form.getFieldValue('duration') === 1 &&
          <Form.Item {...formItemLayout} label="Starting Time">
            {getFieldDecorator('start_time', {
              rules: [{ required: true, message: ' ' }],
            })(
              <Select>
                {manualTimeRange.map(x=><Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
              </Select>
            )}
          </Form.Item>
        }

        {this.props.form.getFieldValue('duration') === 1 &&
          <Form.Item {...formItemLayout} label="Ending Time">
            {getFieldDecorator('end_time', {
              rules: [{ required: true, message: ' ' }],
            })(
              <Select>
                {manualTimeRange.map(x=><Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
              </Select>
            )}
          </Form.Item>
        }

        <Form.Item {...formItemLayout} label='Covering Provider'>
          {getFieldDecorator('covering_provider_id',{
            rules: [{required: true, message: 'Select a covering provider'}],
          })(
            <Select>
              { cpl.map(x=><Select.Option value={parseInt(x.id,10)} key={x.id}>{x.full_name}</Select.Option>) }
            </Select>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Request Comment'>
          {getFieldDecorator('request_comment',{
          })(
            <Input.TextArea />
          )}
        </Form.Item>
        </div> }
      </Form>
    );
  }

  render() {
    return (
      <Modal
        title='New Time Off Request'
        visible={this.props.visible}
        onCancel={this.handleCancel}
        okText='Create'
        width={650}
        onOk={this.handleOk}
      >
        <div>
          {this.renderForm()}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    covering_provider_list: safe(state,'scheduler.provider.coverage.data',[]),
    provider_list: safe(state,'scheduler.provider.list.data',[]),
    createEvent: safe(state,'scheduler.timeoff.create',{})
  }
}

export default connect(mapStateToProps,actions)(Form.create()(CreateTimeoffModal));
