export const REPORTS_PAYMENTSPOSTED_REQUEST = 'REPORTS_PAYMENTSPOSTED_REQUEST';
export const REPORTS_PAYMENTSPOSTED_SUCCESS = 'REPORTS_PAYMENTSPOSTED_SUCCESS';
export const REPORTS_PAYMENTSPOSTED_FAILURE = 'REPORTS_PAYMENTSPOSTED_FAILURE';
export const REPORTS_PAYMENTSPOSTED_CLEAR = 'REPORTS_PAYMENTSPOSTED_CLEAR';

export const REPORTS_EVALS_REQUEST = 'REPORTS_EVALS_REQUEST';
export const REPORTS_EVALS_SUCCESS = 'REPORTS_EVALS_SUCCESS';
export const REPORTS_EVALS_FAILURE = 'REPORTS_EVALS_FAILURE';
export const REPORTS_EVALS_CLEAR = 'REPORTS_EVALS_CLEAR';
export const REPORTS_OPENTIMES_REQUEST = 'REPORTS_OPENTIMES_REQUEST';
export const REPORTS_OPENTIMES_SUCCESS = 'REPORTS_OPENTIMES_SUCCESS';
export const REPORTS_OPENTIMES_FAILURE = 'REPORTS_OPENTIMES_FAILURE';
export const REPORTS_OPENTIMES_CLEAR = 'REPORTS_OPENTIMES_CLEAR';
export const REPORTS_SCHEDULETEMPLATES_REQUEST = 'REPORTS_SCHEDULETEMPLATES_REQUEST';
export const REPORTS_SCHEDULETEMPLATES_SUCCESS = 'REPORTS_SCHEDULETEMPLATES_SUCCESS';
export const REPORTS_SCHEDULETEMPLATES_FAILURE = 'REPORTS_SCHEDULETEMPLATES_FAILURE';
export const REPORTS_SCHEDULETEMPLATES_CLEAR = 'REPORTS_SCHEDULETEMPLATES_CLEAR';

export const REPORTS_ONHOLD_REQUEST = 'REPORTS_ONHOLD_REQUEST';
export const REPORTS_ONHOLD_SUCCESS = 'REPORTS_ONHOLD_SUCCESS';
export const REPORTS_ONHOLD_FAILURE = 'REPORTS_ONHOLD_FAILURE';
export const REPORTS_ONHOLD_CLEAR = 'REPORTS_ONHOLD_CLEAR';
export const REPORTS_SESSIONCOUNTS_REQUEST = 'REPORTS_SESSIONCOUNTS_REQUEST';
export const REPORTS_SESSIONCOUNTS_SUCCESS = 'REPORTS_SESSIONCOUNTS_SUCCESS';
export const REPORTS_SESSIONCOUNTS_FAILURE = 'REPORTS_SESSIONCOUNTS_FAILURE';
export const REPORTS_SESSIONCOUNTS_CLEAR = 'REPORTS_SESSIONCOUNTS_CLEAR';
export const REPORTS_HOURLYEMPLOYEES_REQUEST = 'REPORTS_HOURLYEMPLOYEES_REQUEST';
export const REPORTS_HOURLYEMPLOYEES_SUCCESS = 'REPORTS_HOURLYEMPLOYEES_SUCCESS';
export const REPORTS_HOURLYEMPLOYEES_FAILURE = 'REPORTS_HOURLYEMPLOYEES_FAILURE';
export const REPORTS_HOURLYEMPLOYEES_CLEAR = 'REPORTS_HOURLYEMPLOYEES_CLEAR';
export const REPORTS_PTCREDITS_REQUEST = 'REPORTS_PTCREDITS_REQUEST';
export const REPORTS_PTCREDITS_SUCCESS = 'REPORTS_PTCREDITS_SUCCESS';
export const REPORTS_PTCREDITS_FAILURE = 'REPORTS_PTCREDITS_FAILURE';
export const REPORTS_PTCREDITS_CLEAR = 'REPORTS_PTCREDITS_CLEAR';
export const REPORTS_PTUNALLOCATED_REQUEST = 'REPORTS_PTUNALLOCATED_REQUEST';
export const REPORTS_PTUNALLOCATED_SUCCESS = 'REPORTS_PTUNALLOCATED_SUCCESS';
export const REPORTS_PTUNALLOCATED_FAILURE = 'REPORTS_PTUNALLOCATED_FAILURE';
export const REPORTS_PTUNALLOCATED_CLEAR = 'REPORTS_PTUNALLOCATED_CLEAR';

export const REPORTS_DUPSESSIONS_REQUEST = 'REPORTS_DUPSESSIONS_REQUEST';
export const REPORTS_DUPSESSIONS_SUCCESS = 'REPORTS_DUPSESSIONS_SUCCESS';
export const REPORTS_DUPSESSIONS_FAILURE = 'REPORTS_DUPSESSIONS_FAILURE';
export const REPORTS_DUPSESSIONS_CLEAR = 'REPORTS_DUPSESSIONS_CLEAR';
export const REPORTS_INTAKES_REQUEST = 'REPORTS_INTAKES_REQUEST';
export const REPORTS_INTAKES_SUCCESS = 'REPORTS_INTAKES_SUCCESS';
export const REPORTS_INTAKES_FAILURE = 'REPORTS_INTAKES_FAILURE';
export const REPORTS_INTAKES_CLEAR = 'REPORTS_INTAKES_CLEAR';

export const REPORTS_UNSIGNEDMISSED_REQUEST = 'REPORTS_UNSIGNEDMISSED_REQUEST';
export const REPORTS_UNSIGNEDMISSED_SUCCESS = 'REPORTS_UNSIGNEDMISSED_SUCCESS';
export const REPORTS_UNSIGNEDMISSED_FAILURE = 'REPORTS_UNSIGNEDMISSED_FAILURE';
export const REPORTS_UNSIGNEDMISSED_CLEAR = 'REPORTS_UNSIGNEDMISSED_CLEAR';

export const REPORTS_QUEUE_ADD_REQUEST = 'REPORTS_QUEUE_ADD_REQUEST';
export const REPORTS_QUEUE_ADD_SUCCESS = 'REPORTS_QUEUE_ADD_SUCCESS';
export const REPORTS_QUEUE_ADD_FAILURE = 'REPORTS_QUEUE_ADD_FAILURE';
export const REPORTS_QUEUE_ADD_CLEAR = 'REPORTS_QUEUE_ADD_CLEAR';
export const REPORTS_QUEUE_DELETE_REQUEST = 'REPORTS_QUEUE_DELETE_REQUEST';
export const REPORTS_QUEUE_DELETE_SUCCESS = 'REPORTS_QUEUE_DELETE_SUCCESS';
export const REPORTS_QUEUE_DELETE_FAILURE = 'REPORTS_QUEUE_DELETE_FAILURE';
export const REPORTS_QUEUE_DELETE_CLEAR = 'REPORTS_QUEUE_DELETE_CLEAR';
export const REPORTS_QUEUE_CANCEL_REQUEST = 'REPORTS_QUEUE_CANCEL_REQUEST';
export const REPORTS_QUEUE_CANCEL_SUCCESS = 'REPORTS_QUEUE_CANCEL_SUCCESS';
export const REPORTS_QUEUE_CANCEL_FAILURE = 'REPORTS_QUEUE_CANCEL_FAILURE';
export const REPORTS_QUEUE_CANCEL_CLEAR = 'REPORTS_QUEUE_CANCEL_CLEAR';

export const REPORTS_QUEUE_LIST_REQUEST = 'REPORTS_QUEUE_LIST_REQUEST';
export const REPORTS_QUEUE_LIST_SUCCESS = 'REPORTS_QUEUE_LIST_SUCCESS';
export const REPORTS_QUEUE_LIST_FAILURE = 'REPORTS_QUEUE_LIST_FAILURE';
export const REPORTS_QUEUE_LIST_CLEAR = 'REPORTS_QUEUE_LIST_CLEAR';

export const REPORTS_BILLS_REQUEST = 'REPORTS_BILLS_REQUEST';
export const REPORTS_BILLS_SUCCESS = 'REPORTS_BILLS_SUCCESS';
export const REPORTS_BILLS_FAILURE = 'REPORTS_BILLS_FAILURE';
export const REPORTS_BILLS_CLEAR = 'REPORTS_BILLS_CLEAR';

export const REPORTS_PAYROLL_REQUEST = 'REPORTS_PAYROLL_REQUEST';
export const REPORTS_PAYROLL_SUCCESS = 'REPORTS_PAYROLL_SUCCESS';
export const REPORTS_PAYROLL_FAILURE = 'REPORTS_PAYROLL_FAILURE';
export const REPORTS_PAYROLL_CLEAR = 'REPORTS_PAYROLL_CLEAR';

export const REPORTS_PATIENTS_REQUEST = 'REPORTS_PATIENTS_REQUEST';
export const REPORTS_PATIENTS_SUCCESS = 'REPORTS_PATIENTS_SUCCESS';
export const REPORTS_PATIENTS_FAILURE = 'REPORTS_PATIENTS_FAILURE';
export const REPORTS_PATIENTS_CLEAR = 'REPORTS_PATIENTS_CLEAR';

export const REPORTS_GROUPSTATS_REQUEST = 'REPORTS_GROUPSTATS_REQUEST';
export const REPORTS_GROUPSTATS_SUCCESS = 'REPORTS_GROUPSTATS_SUCCESS';
export const REPORTS_GROUPSTATS_FAILURE = 'REPORTS_GROUPSTATS_FAILURE';
export const REPORTS_GROUPSTATS_CLEAR = 'REPORTS_GROUPSTATS_CLEAR';

export const REPORTS_CHARGECOLLECTION_REQUEST = 'REPORTS_CHARGECOLLECTION_REQUEST';
export const REPORTS_CHARGECOLLECTION_SUCCESS = 'REPORTS_CHARGECOLLECTION_SUCCESS';
export const REPORTS_CHARGECOLLECTION_FAILURE = 'REPORTS_CHARGECOLLECTION_FAILURE';
export const REPORTS_CHARGECOLLECTION_CLEAR = 'REPORTS_CHARGECOLLECTION_CLEAR';

export const REPORTS_ADJUSTMENTS_REQUEST = 'REPORTS_ADJUSTMENTS_REQUEST';
export const REPORTS_ADJUSTMENTS_SUCCESS = 'REPORTS_ADJUSTMENTS_SUCCESS';
export const REPORTS_ADJUSTMENTS_FAILURE = 'REPORTS_ADJUSTMENTS_FAILURE';
export const REPORTS_ADJUSTMENTS_CLEAR = 'REPORTS_ADJUSTMENTS_CLEAR';

export const REPORTS_LYRA_REQUEST = 'REPORTS_LYRA_REQUEST';
export const REPORTS_LYRA_SUCCESS = 'REPORTS_LYRA_SUCCESS';
export const REPORTS_LYRA_FAILURE = 'REPORTS_LYRA_FAILURE';
export const REPORTS_LYRA_CLEAR = 'REPORTS_LYRA_CLEAR';

export const REPORTS_LIFESTANCE_REQUEST = 'REPORTS_LIFESTANCE_REQUEST';
export const REPORTS_LIFESTANCE_SUCCESS = 'REPORTS_LIFESTANCE_SUCCESS';
export const REPORTS_LIFESTANCE_FAILURE = 'REPORTS_LIFESTANCE_FAILURE';
export const REPORTS_LIFESTANCE_CLEAR = 'REPORTS_LIFESTANCE_CLEAR';
