/**
 * Adjustments.tsx
 */
import reports from 'reports';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import ReportWrapper from 'components/App_/ReportsV2_/ReportWrapper';
import InlineFormWrapper from 'components/App_/ReportsV2_/InlineFormWrapper';
import Table from 'components/App_/ReportsV2_/Table';
import columns from 'components/App_/ReportsV2_/TableColumns_/adjustments';
import CSVDownload from '../InlineControls_/CSVDownload';
import { InlineDataT } from 'reports';
import useAutoRun from 'components/App_/ReportsV2_/useAutoRun';

const reportId = 16;

const Adjustments: React.FC = () => {
  const inlineData = useContextSelector(InlineContext, (c) => c.state.inlineData) as InlineDataT;
  const report = reports.find((r) => r.id === reportId);

  useAutoRun(reportId);

  const formOptions = { reportId };

  const tableOptions = {
    data: inlineData,
    columns,
    reportId,
    rowKey: 'month',
    total: inlineData?.length,
  };

  return (
    <ReportWrapper>
      <h3>{report?.name}</h3>
      <InlineFormWrapper {...formOptions}>
        <CSVDownload prefix={report?.csvPrefix} />
      </InlineFormWrapper>
      <Table {...tableOptions} />
    </ReportWrapper>
  );
};

export default Adjustments;
