import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Button, notification, Spin } from 'antd';
import { connect } from 'react-redux';

import * as actions from '../../../../../actions/billing';
import AttachmentsListItem from './AdminAttachments_/AttachmentsListItem';
import { safe } from '../../../../../helpers/reduxSelectors';

class AdminAttachments extends React.Component {

  render() {
    const This = this;
    const uploadProps = {
      action: '/api/v1/adminattachments/'+this.props.patientId,
      showUploadList: false,
      onChange(info) {
        if (info.file.status === 'done') {
          This.props.setAdminAttachmentsCreateSuccess(info.file.response);
          notification['success']({
            message: 'Success',
            description: 'File Upload Succeeded',
            placement: 'bottomLeft'
          });
        } else if (info.file.status === 'uploading') {
          This.props.setAdminAttachmentsCreateRequest();
        } else if (info.file.status === 'error') {
          This.props.setAdminAttachmentsCreateFailure(info.file.response);
          notification['error']({
            message: 'Error',
            description: 'File Upload Failed',
            placement: 'bottomLeft'
          });
        }
      },
    };

    return (
      <div>
        <div style={{paddingBottom: '10px',textAlign:'center'}}>
          <Upload {...uploadProps}>
            <Button>
              <UploadOutlined /> Click to Upload an Attachment
            </Button>
          </Upload>
        </div>

        <Spin spinning={this.props.isBusy}>
          <table style={{width:'100%'}}>
            <tbody>
              {this.props.data.map(x=><AttachmentsListItem key={x.id} patientId={this.props.patientId} item={x}/>)}
            </tbody>
          </table>
        </Spin>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.attachments.delete.processing',false) ||
      safe(state,'billing.attachments.rename.processing',false) ||
      safe(state,'billing.attachments.create.processing',false)
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(AdminAttachments);
