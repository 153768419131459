import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Spin } from 'antd';
import numeral from 'numeral';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';

class ReportsAdjustments extends React.Component {

  componentDidMount() {
    this.props.getAdjustmentsReport();
  }

  render() {
    const columns = [{
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      width: 200
    },{
      title: 'Pt Adjustments',
      dataIndex: 'pt_adjustments',
      key: 'pt_adjustments',
      align: 'right',
      render: (value)=>numeral(value).format('$0,0.00')
    },{
      title: 'Pt Writeoff',
      dataIndex: 'pt_writeoff',
      key: 'pt_writeoff',
      align: 'right',
      render: (value)=>numeral(value).format('$0,0.00')
    },{
      title: 'Pt Collections',
      dataIndex: 'pt_collections',
      key: 'pt_collections',
      align: 'right',
      render: (value)=>numeral(value).format('$0,0.00')
    },{
      title: 'Pt Repricing',
      dataIndex: 'pt_repricing',
      key: 'pt_repricing',
      align: 'right',
      render: (value)=>numeral(value).format('$0,0.00')
    },{
      title: 'Pt Other',
      dataIndex: 'pt_other',
      key: 'pt_other',
      align: 'right',
      render: (value)=>numeral(value).format('$0,0.00')
    },{
      title: 'Pt Total',
      dataIndex: 'pt_total',
      key: 'pt_total',
      align: 'right',
      render: (value)=>numeral(value).format('$0,0.00')
    },{
      title: 'Ins Adjustments',
      dataIndex: 'ins_adjustments',
      key: 'ins_adjustments',
      align: 'right',
      render: (value)=>numeral(value).format('$0,0.00')
    },{
      title: 'Ins Writeoff',
      dataIndex: 'ins_writeoff',
      key: 'ins_writeoff',
      align: 'right',
      render: (value)=>numeral(value).format('$0,0.00')
    },{
      title: 'Ins Collections',
      dataIndex: 'ins_collections',
      key: 'ins_collections',
      align: 'right',
      render: (value)=>numeral(value).format('$0,0.00')
    },{
      title: 'Ins Repricing',
      dataIndex: 'ins_repricing',
      key: 'ins_repricing',
      align: 'right',
      render: (value)=>numeral(value).format('$0,0.00')
    },{
      title: 'Ins Other',
      dataIndex: 'ins_other',
      key: 'ins_other',
      align: 'right',
      render: (value)=>numeral(value).format('$0,0.00')
    },{
      title: 'Ins Total',
      dataIndex: 'ins_total',
      key: 'ins_total',
      align: 'right',
      render: (value)=>numeral(value).format('$0,0.00')
    }


  ];

    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
            <span style={{float:'right'}}><a href="/api/v1/reports/adjustments?csv=1">CSV Download</a></span>
          </div>

          <h3>Adjustments Report</h3>
          <Table
            className='reports-sessionlist-table'
            columns={columns}
            dataSource={this.props.data}
            bordered={true}
            size='small'
            rowKey='id'
            scroll={{ x:true }}
            pagination={false}
          />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: safe(state,'reports.adjustments.data',[]),
    isBusy: safe(state,'reports.adjustments.processing',false)
  }
}

export default connect(mapStateToProps,actions)(ReportsAdjustments);
