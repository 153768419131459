import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import moment from 'moment';

const columns: Array<ColumnI> = [
  {
    title: 'Week',
    dataIndex: 'yw',
  },
  {
    title: 'Start Date',
    dataIndex: 'start',
    render: (val) => moment(val).format('MM/DD/YYYY'),
  },
  {
    title: 'End Date',
    dataIndex: 'end',
    render: (val) => moment(val).format('MM/DD/YYYY'),
  },
  {
    title: 'All Visits',
    dataIndex: 'all_visits',
  },
  {
    title: 'All New Visits',
    dataIndex: 'all_new_visits',
  },
  {
    title: 'All Telemedicine Visit',
    dataIndex: 'all_telemedicine',
  },
  {
    title: 'All Telemedicine Intakes',
    dataIndex: 'telemedicine_intakes',
  },
];

export default columns;
