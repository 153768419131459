import React from 'react';
import { Modal, Button, Spin } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/billing';
import EBR from './EBR';
import DPR from './DPR';
import IBR from './IBR';
import TA1 from './TA1';
import EDI837P from './EDI837P';

class DisplayResponseDialog extends React.Component {

  componentDidMount() {
    if (this.props.responseId) {
      this.props.loadClearinghouseEntry(this.props.responseId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.loadClearinghouseEntry(this.props.responseId);
    }
    /*

    if (prevProps.responseId !== this.props.responseId && this.props.responseId) {
      this.props.loadClearinghouseEntry(this.props.responseId);
    }
    */
  }

  onCancel = () => {
    this.props.closeDialog(true);
  }

  onOk = () => {
    this.props.closeDialog(true);
  }

  display = (data) => {
    switch (data.type) {
      case 'EBR': return <EBR data={data}/>
      case 'DPR': return <DPR data={data}/>
      case 'IBR': return <IBR data={data}/>
      case 'TA1': return <TA1 data={data}/>
      case '837P': return <EDI837P data={data}/>
      default: return (
        <div>
          <pre>
          {JSON.stringify(data,undefined,2)}
          </pre>
        </div>
      );
    }
  }

  render() {
    const ediData = safe(this.props,'data.data',{});
    const createdDate = moment(this.props.data.createdAt).format('MM/DD/YYYY HH:mm:ss');

    return (
      <Modal
        title='Display EDI Readable Output'
        visible={this.props.visible}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='Submit'
        width={1000}
        bodyStyle={{height:'600px',overflowY:'scroll',overflowX:'hidden'}}
        footer={[
          <Button key="close" onClick={this.onCancel}>Close</Button>,
        ]}
      >
        <div >
          <Spin spinning={this.props.isBusy}>
            <h3>{this.props.data.filename} ({createdDate})</h3>
            { this.display(ediData) }
          </Spin>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.clearinghouse.load.processing',false)
    ) ? true : false,
    data: safe(state,'billing.clearinghouse.load.data',{})
  }
}

export default connect(mapStateToProps,actions)(DisplayResponseDialog);
