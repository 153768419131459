import React from 'react';
import { Modal, Spin } from 'antd';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import ClaimDisplay from '../App_/Billing_/Era_/ClaimDisplay';
import { safe } from '../../helpers/reduxSelectors';
import * as actions from '../../actions/billing';

class ClaimsViewDialog extends React.Component {

  static propTypes = {
    visible: PropTypes.bool,
    id: PropTypes.number,
    closeDialog: PropTypes.func
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.getPaymentDetail(this.props.id);
    }
  }

  // <ClaimDisplay data={claim}/>
  renderClaims = () => {
    const n = this.props.paymentdetail.length;
    if (this.props.paymentdetail && n > 0) {

      return this.props.paymentdetail.map(x=>{
        return (
          <div>
            <ClaimDisplay data={x}/>
            {n > 1 && <hr />}
          </div>
        );
      })
    } else {
      return (
        <div>
          <h3>No ERA on file for this payment</h3>
        </div>
      )
    }
  }

  render() {
    return (
      <Modal
        title="Claim Details"
        visible={this.props.visible}
        onOk={this.props.closeDialog}
        onCancel={this.props.closeDialog}
        width={1200}
        bodyStyle={{padding:'5px',height:'450px',overflowY:'scroll'}}
        footer={null}
      >
        <Spin spinning={this.props.isBusy}>
          { this.renderClaims() }
        </Spin>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.paymentdetail.processing',false)
    ) ? true : false,
    paymentdetail: safe(state,'billing.paymentdetail.data',[])
  }
}

export default connect(mapStateToProps,actions)(ClaimsViewDialog);
