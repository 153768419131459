import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Modal, Tabs, message, Spin } from 'antd';

import { safe } from '../../../helpers/reduxSelectors';

import * as billing_actions from '../../../actions/billing';
import * as shared_actions from '../../../actions/shared';

class Carriers extends Component {
  componentDidMount() {
    this.props.getCarrierList();
  }

  onClickAddCarrier = () => {
    this.props.history.push('/settings/carriers/0');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deactivate !== this.props.deactivate) {
      if (this.props.deactivate.success) {
        message.success("Carrier Deactivated");
        this.props.carrierDeactivateClear();
      }
      if (this.props.deactivate.error) {
        message.error(safe(this.props,'deactivate.error.data.message'));
        this.props.carrierDeactivateClear();
      }
    }

    if (prevProps.reactivate !== this.props.reactivate) {
      if (this.props.reactivate.success) {
        message.success("Carrier Reactivated");
        this.props.carrierReactivateClear();
      }
      if (this.props.reactivate.error) {
        message.error(safe(this.props,'reactivate.error.data.message'));
        this.props.carrierReactivateClear();
      }
    }
  }

  deactivate = (record) => {
    const confirm = Modal.confirm;
    const du = this.props.carrierDeactivate;

    confirm({
      title: 'Deactivate carrier: ' + record.name + '?',
      onOk() { du(record.id) },
      onCancel() {}
    });
  }

  reactivate = (record) => {
    const confirm = Modal.confirm;
    const ru = this.props.carrierReactivate;

    confirm({
      title: 'Reactivate carrier: ' + record.name + '?',
      onOk() { ru(record.id) },
      onCancel() {}
    });
  }

  renderActiveTable = () => {
    const columns = [{
      title: 'Abbr',
      dataIndex: 'abbr',
      width: 80
    },{
      title: 'Name',
      dataIndex: 'name',
      width: 200,
      render: (text,record) => (
        <Link to={"/settings/carriers/"+record.id} style={{whiteSpace: 'nowrap'}}>{text}</Link>
      )
    },{
      title: 'Payer ID',
      dataIndex: 'payerid',
      width: 100
    },{
      title: 'Self Pay',
      dataIndex: 'selfpay',
      width: 100,
      render: (text,record) => {
        return (record.selfpay) ? 'Yes' : 'No';
      }
    },{
      title: 'Address',
      dataIndex: 'address1',
      render: (text,record) => {
        if (record.selfpay) {
          return null;
        }
        let address = (record.address2 && record.address2.length > 0)
          ? record.address1 + ', ' + record.address2 : record.address1;
        return (
          <span>{address}, {record.city}, {record.state} {record.zip}</span>
        );
      }
    },{
      title: 'Allowable FS',
      dataIndex: 'allowable_feeschedule_group',
      width: 100,
      render: (val) => {
        return (val) ? val.name : '--';
      }
    },{
      title: 'Charge FS',
      dataIndex: 'charge_feeschedule_group',
      width: 100,
      render: (val) => {
        return (val) ? val.name : '--';
      }
    },{
      title: 'Notes',
      dataIndex: 'notes',
      width: 200
    },{
      title: 'Action',
      width: 100,
      fixed: 'right',
      render: (text, record) => (
        <span>
          <a onClick={() => { this.deactivate(record) }}>Deactivate</a>
        </span>
      )
    }];

    return (
      <Table
        dataSource={this.props.carrierList.filter(item => item.active === true)}
        rowKey="id"
        size='small'
        pagination={false}
        columns={columns}
      />
    );
  }

  renderInactiveTable = () => {
    const columns = [{
      title: 'Name',
      dataIndex: 'name',
      width: 200
    },{
      title: 'Payer ID',
      dataIndex: 'payerid',
      width: 100
    },{
      title: 'Self Pay',
      dataIndex: 'selfpay',
      width: 100,
      render: (text,record) => {
        return (record.selfpay) ? 'Yes' : '';
      }
    },{
      title: 'Address',
      dataIndex: 'address1',
      render: (text,record) => {
        let address = (record.address2 && record.address2.length > 0)
          ? record.address1 + ', ' + record.address2 : record.address1;
        return (
          <span>{address}, {record.city}, {record.state} {record.zip}</span>
        );
      }
    },{
      title: 'Deactivated',
      dataIndex:'deactivatedAt',
      width: 200,
      render: (text,record) => (
        (new Date(text)).toLocaleString()
      )
    },{
      title: 'Action',
      width: 100,
      fixed: 'right',
      render: (text, record) => (
        <span>
          <a onClick={() => { this.reactivate(record) }}>Reactivate</a>
        </span>
      )
    }];

    return (
      <Table
        columns={columns}
        dataSource={this.props.carrierList.filter(item => item.active === false)}
        rowKey="id"
        size='small'
        pagination={false}
      />
    );
  }

  render() {
    const TabPane = Tabs.TabPane;

    return (
      <Spin spinning={this.props.isBusy} size="large">
        <div style={{padding:'10px'}}>
          <h3>Carriers</h3>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Active" key="1">
              { this.renderActiveTable() }
            </TabPane>
            <TabPane tab="Inactive" key="2">
              { this.renderInactiveTable() }
            </TabPane>
          </Tabs>
          <div style={{position:'fixed',bottom:40,right:40}}>
            <Button onClick={this.onClickAddCarrier}
              disabled={this.props.isBusy}
              size='large'
              shape='circle'
              type="primary"
              icon={<PlusOutlined />}
            />
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    load : safe(state,'shared.carrier.load'),
    list: safe(state,'shared.carrier.list'),
    carrierList: safe(state,'shared.carrier.list.data',[]),
    deactivate: safe(state,'shared.carrier.deactivate'),
    reactivate: safe(state,'shared.carrier.reactivate'),
    isBusy: (
      safe(state,'shared.carrier.load.processing') ||
      safe(state,'shared.carrier.list.processing') ||
      safe(state,'shared.carrier.deactivate.processing') ||
      safe(state,'shared.carrier.reactivate.processing')
    ) ? true: false
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(Carriers);
