import { combineReducers } from 'redux';
import { ajaxReducer, ajaxReducerPreserveState } from '../../helpers/util.js'

import emrReducer from './emrReducer';
import inboxReducer from './inboxReducer';

export default combineReducers({
  note: combineReducers({
    unsignedlist: ajaxReducerPreserveState('NOTE_UNSIGNEDLIST'),
    create: ajaxReducer('NOTE_CREATE'),
    load: ajaxReducer('NOTE_LOAD'),
    list: ajaxReducer('NOTE_LIST'),
    save: ajaxReducer('NOTE_SAVE'),
    autosave: ajaxReducer('NOTE_AUTOSAVE'),
    sign: ajaxReducer('NOTE_SIGN'),
    delete: ajaxReducer('NOTE_DELETE'),
    reject: ajaxReducer('NOTE_REJECT'),
    approve: ajaxReducer('NOTE_APPROVE'),
    addendum: ajaxReducer('NOTE_ADDENDUM_CREATE'),
    superviseeunsignedlist: ajaxReducer('NOTE_SUPERVISEEUNSIGNEDLIST'),
    supervisorbulkapprove: ajaxReducer('NOTE_SUPERVISORBULKAPPROVE'),
    latecanceldelete: ajaxReducer('NOTE_LATECANCEL_DELETE'),
    missingnotes: ajaxReducerPreserveState('NOTE_MISSINGLIST')
  }),
  attachments: combineReducers({
    list: ajaxReducer('ATTACHMENTS_LIST'),
    rename: ajaxReducer('ATTACHMENTS_RENAME'),
    delete: ajaxReducer('ATTACHMENTS_DELETE'),
  }),
  chart: combineReducers({
    params: ajaxReducer('CHART_PARAMS'),
    fullload: ajaxReducer('CHART_FULLLOAD'),
    printload: ajaxReducer('CHART_PRINTLOAD'),
  }),
  comments: combineReducers({
    list: ajaxReducer('COMMENT_LIST'),
    add: ajaxReducer('COMMENT_ADD'),
  }),
  diagnosis: ajaxReducer('DIAGNOSIS_LIST'),
  diagnosis_primary: ajaxReducer('DIAGNOSIS_LOAD'),
  problemlist: combineReducers({
    load: ajaxReducer('PROBLEMLIST_LOAD'),
    create: ajaxReducer('PROBLEMLIST_CREATE'),
    save: ajaxReducer('PROBLEMLIST_SAVE'),
    deactivate: ajaxReducer('PROBLEMLIST_DEACTIVATE'),
    activate: ajaxReducer('PROBLEMLIST_ACTIVATE'),
  }),
  supervisor: ajaxReducer('SUPERVISOR_LIST'),
  templateletter: combineReducers({
    load: ajaxReducer('TEMPLATELETTER_LIST_LOAD'),
    save: ajaxReducer('TEMPLATELETTER_SAVE'),
    create: ajaxReducer('TEMPLATELETTER_CREATE'),
    fields: ajaxReducer('TEMPLATELETTER_FIELDS_LOAD')
  }),
  rcopia: combineReducers({
    notifications: ajaxReducer('RCOPIA_NOTIFICATIONS')
  }),
  coverage: combineReducers({
    load: ajaxReducer('COVERAGE_LOAD'),
    save: ajaxReducer('COVERAGE_SAVE'),
    list: ajaxReducer('COVERAGE_LIST'),
    delete: ajaxReducer('COVERAGE_DELETE'),
    admindelete: ajaxReducer('COVERAGE_ADMINDELETE'),
    adminlist: ajaxReducer('COVERAGE_ADMINLIST')
  }),
  documents: combineReducers({
    list: ajaxReducer('DOCUMENTS_LIST'),
    delete: ajaxReducer('DOCUMENTS_DELETE'),
    transfer: ajaxReducer('DOCUMENTS_TRANSFER'),
    transfer2: ajaxReducer('DOCUMENTS_TRANSFER2')
  }),
  providers: combineReducers({
    list: ajaxReducer('PROVIDERS_LIST')
  }),
  provider: combineReducers({
    locations: ajaxReducer('PROVIDER_LOCATIONS'),
    opentimes: ajaxReducer('PROVIDER_OPENTIMES'),
    history: ajaxReducer('PROVIDER_HISTORY'),
    productivity: ajaxReducer('PROVIDER_PRODUCTIVITY')
  }),
  messagethreads: combineReducers({
    list: ajaxReducer('MESSAGETHREADS_LIST'),
    messages: ajaxReducer('MESSAGETHREADS_MESSAGES')
  }),
  calendar: combineReducers({
    list: ajaxReducer('CALENDAR_LIST'),
    search: ajaxReducer('CALENDAR_SEARCH'),
    holidays: ajaxReducer('CALENDAR_HOLIDAYS'),
    searchparams: ajaxReducer('CALENDAR_SEARCHPARAMS'),
  }),
  session: combineReducers({
    create: ajaxReducer('SESSION_CREATE'),
    remove: ajaxReducer('SESSION_REMOVE'),
    update: ajaxReducer('SESSION_UPDATE'),
    createnote: ajaxReducer('SESSION_CREATENOTE'),
    history: ajaxReducer('SESSION_HISTORY'),
    reserve: ajaxReducer('SESSION_RESERVE'),
    load: ajaxReducer('SESSION_LOAD'),
    latecancel: ajaxReducer('SESSION_LATECANCEL')
  }),
  appt: combineReducers({
    create: ajaxReducer('APPT_CREATE'),
    remove: ajaxReducer('APPT_REMOVE'),
    update: ajaxReducer('APPT_UPDATE'),
    reserve: ajaxReducer('APPT_RESERVE'),
    latecancel: ajaxReducer('APPT_LATECANCEL')
  }),
  timeoff: combineReducers({
    list: ajaxReducer('TIMEOFF_LIST'),
    create: ajaxReducer('TIMEOFF_CREATE'),
    update: ajaxReducer('TIMEOFF_UPDATE'),
    delete: ajaxReducer('TIMEOFF_DELETE')
  }),
  caseload: ajaxReducer('CASELOAD_LIST'),
  ptcontacts: ajaxReducer('PTCONTACTS_LIST'),
  threadCreate: ajaxReducer('THREAD_CREATE'),
  threadStar: ajaxReducer('THREAD_STAR'),
  notifications: combineReducers({
    list: ajaxReducer('NOTIFICATIONS_LIST')
  }),
  registration: combineReducers({
    toggle: ajaxReducer('REGISTRATION_TOGGLE')
  }),
  director: combineReducers({
    list: ajaxReducer('DIRECTOR_LIST'),
    add: ajaxReducer('DIRECTOR_ADD'),
    remove: ajaxReducer('DIRECTOR_REMOVE'),
  }),
  inbox: inboxReducer,
  emr: emrReducer,
  chart_audit_log: combineReducers({
    create: ajaxReducer('CHART_AUDIT_LOG_CREATE'),
  }),
});
