/* eslint-disable react/display-name */
/**
 * ADP.tsx
 * The main HR dashboard.
 */
import { useState } from 'react';
import useApiGet from 'hooks/useApiGet';
import { Button, Tabs, Tag, Table, /*Modal, message,*/ Spin, notification } from 'antd';
import moment from 'moment';
import Time from '../../shared/Time';
import useApiPost from 'hooks/useApiPost';

const TabPane = Tabs.TabPane;

const displayDateTimeRange = (start_date,end_date,start_time,end_time,duration) => {
  if (duration === 1) {
    // Partial-Day Timeoff
    try {
      const st = (new Time(start_time)).toString(false);
      const et = (new Time(end_time)).toString(false);
      const sd = moment(start_date).format('MM/DD/YYYY');
      return <span style={{whiteSpace:'nowrap'}}>{sd},{st}-{et}</span>;
    } catch (err) {
      return null;
    }
  } else if (duration === 2) {
    const sd = moment(start_date).format('MM/DD/YYYY');
    const ed = moment(end_date).format('MM/DD/YYYY');
    if (moment(start_date).isSame(end_date,'day')) {
      return <span style={{whiteSpace:'nowrap'}}>{sd}</span>;
    } else {
      return <span style={{whiteSpace:'nowrap'}}>{sd}-{ed}</span>;
    }
  }
}

const ADP: React.FC = () => {
  const [trigger, setTrigger] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [filter, setFilter] = useState(0);

  const timeoffs = useApiGet(`/api/v2/hr/adp/timeoffs/${filter}?page=${page}&pagesize=${pageSize}`, [], [trigger]);
  const approveAPI = useApiPost(`/api/v2/hr/adp/timeoffs/approve`);
  const unapproveAPI = useApiPost(`/api/v2/hr/adp/timeoffs/unapprove`);
  
  const isBusy = timeoffs?.loading || approveAPI?.loading || unapproveAPI?.loading;

  const onTableChange = (p) => {
    const newPage = p.current;
    const newPageSize = p.pageSize;
    // ensure re-run only happens when a real change occurs
    if ((page && newPage !== page) || (pageSize && newPageSize !== pageSize)) {
      setPage(newPage);
      setPageSize(newPageSize);
      setTrigger((t) => !t);
    }
  };

  const onApprove = async (timeoffId: number) => {
    if (!approveAPI?.loading) {
      const response = await approveAPI?.send({ timeoffId });
      if (response?.error) {
        notification.error({
          message: 'Approval Problem',
          description: response?.message,
        });
      } else {
        setTrigger((t) => !t);
      }
    }
  };

  const onUnapprove = async (timeoffId: number) => {
    if (!unapproveAPI?.loading) {
      const response = await unapproveAPI?.send({ timeoffId });
      if (response?.error) {
        notification.error({
          message: 'Unapproval Problem',
          description: response?.message,
        });
      } else {
        setTrigger((t) => !t);
      }
    }
  };

  const renderTable = (sent_to_adp_status) => {
    const columns = [{
      title: 'Requested',
      dataIndex: 'createdAt',
      render: (value) => {
        return (
          <span style={{whiteSpace:'nowrap'}}>{moment(value).format('MM/DD/YYYY H:mm A')}</span>
        );
      }
    },{
      title: 'Dates/Times',
      dataIndex: 'start_date',
      render: (_value,record) => displayDateTimeRange(record.start_date, record.end_date,record.start_time,record.end_time,record.duration)
    },{
      title: 'Status',
      dataIndex: 'status',
      render: (value) => {
        switch (value) {
          case 1: return <Tag color='blue'>Request</Tag>;
          case 2: return <Tag color='green'>Approved</Tag>;
          case 3: return <Tag color='red'>Rejected</Tag>;
          case 4: return <Tag color='orange'>Cancelled</Tag>;
          default: return '-';
        }
      }
    },{
      title: 'Type',
      dataIndex: 'type',
      render: (value) => {
        switch (value) {
          case 4: return <Tag color='purple'>PTO</Tag>;
          case 5: return <Tag color='orange'>UTO</Tag>;
          default: return '-';
        }
      }
    },{
      title: 'Provider',
      dataIndex: 'provider_id',
      render: (_value,record) => {
        return (
          <span style={{whiteSpace:'nowrap'}}>
            {record?.provider?.full_name}
          </span>
        );
      }
    },{
      title: 'Covering Provider',
      dataIndex: 'covering_provider_id',
      render: (_value,record) => {
        if (record.status === 1 || record.status === 2) {
          return (
            <span style={{whiteSpace:'nowrap'}}>
              {(record.covering_provider) ? record?.covering_provider?.full_name : 'No Covering Provider'}
            </span>
          );
        }
        return record?.covering_provider?.full_name;
      }
    },{
      title: 'Comments',
      dataIndex: 'request_comment',
      render: (value,record) => {
        return (
          <div>
            {value && <div>REQ: {value}</div>}
            {record.response_comment && <div>RES: {record.response_comment}</div>}
          </div>
        )
      }
    },{
      title: 'Actions',
      align: 'right',
      render: (_value,record) => {
        switch (record.sent_to_adp) {
          case false:
            return (
              <span style={{whiteSpace:'nowrap'}}>
                <Button onClick={()=>{onApprove(record.id)}} style={{background:'green',color:'white'}}>Add to ADP</Button>
              </span>
            );
          case true:
            return (
              <span style={{whiteSpace:'nowrap'}}>
                <Button onClick={()=>{onUnapprove(record.id)}} style={{background:'red',color:'white'}}>Remove from ADP</Button>
              </span>
            );
          default: return null;
        }
      }
    }] as any;

    const dataSource = (timeoffs?.data?.rows || []).filter((r) => r.sent_to_adp == sent_to_adp_status);
    const pagination = !isBusy && timeoffs?.data?.rows?.length ? {
      showQuickJumper: true,
      current: page,
      pageSize,
      total: timeoffs?.data?.count || 0,
    } : false;

    return (
      <Table
        rowKey="id"
        size='small'
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        onChange={onTableChange}
      />
    );
  }

  const onTabChange = (key) => {
    setFilter(parseInt(key));
    setTrigger((t) => !t);
  };

  return (
    <Spin spinning={isBusy} size='large'>
      <div style={{padding:'10px'}}>
        <h3>ADP Timeoff Entry</h3>
        <Tabs activeKey={filter.toString()} onChange={onTabChange}>
          <TabPane tab="New" key="0">
            {renderTable(false)}
          </TabPane>
          <TabPane tab="Entered into ADP" key="1">
            {renderTable(true)}
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  );
};

export default ADP;
