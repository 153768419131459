import * as types from '../../actions/billing/types';

const defaultState = {
  list1: {},
  list2: {},
  list3: {},
  list4: {}
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case types.AR_LIST1_REQUEST:
      return {
        ...state,
        list1: {
          processing: true,
        }
      };
    case types.AR_LIST1_SUCCESS:
      return {
        ...state,
        list1: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.AR_LIST1_FAILURE:
      return {
        ...state,
        list1: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.AR_LIST1_CLEAR:
      return {
        ...state,
        list1: {}
      };

    case types.AR_LIST2_REQUEST:
      return {
        ...state,
        list2: {
          processing: true,
        }
      };
    case types.AR_LIST2_SUCCESS:
      return {
        ...state,
        list2: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.AR_LIST2_FAILURE:
      return {
        ...state,
        list2: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.AR_LIST2_CLEAR:
      return {
        ...state,
        list2: {}
      };

    case types.AR_LIST3_REQUEST:
      return {
        ...state,
        list3: {
          processing: true,
        }
      };
    case types.AR_LIST3_SUCCESS:
      return {
        ...state,
        list3: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.AR_LIST3_FAILURE:
      return {
        ...state,
        list3: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.AR_LIST3_CLEAR:
      return {
        ...state,
        list3: {}
      };

    case types.AR_LIST4_REQUEST:
      return {
        ...state,
        list4: {
          processing: true,
        }
      };
    case types.AR_LIST4_SUCCESS:
      return {
        ...state,
        list4: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.AR_LIST4_FAILURE:
      return {
        ...state,
        list4: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.AR_LIST4_CLEAR:
      return {
        ...state,
        list4: {}
      };

    default:
      return state;
  }
}
