import * as types from '../../actions/billing/types';

const defaultState = {
  lists_list: {},
  lists_listfull: {},
  lists_deactivate: {},
  lists_reactivate: {},
  lists_create: {},
  lists_save: {},
  lists_load: {},
  items_list: {},
  items_create: {},
  items_save: {},
  patient_list: {},
  patient_create: {},
  patient_save: {},
  comments_list: {},
  comments_create: {}
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case types.RECALL_PATIENT_LIST_REQUEST:
      return {
        ...state,
        patient_list: {
          processing: true,
        }
      };
    case types.RECALL_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        patient_list: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_PATIENT_LIST_FAILURE:
      return {
        ...state,
        patient_list: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_PATIENT_LIST_CLEAR:
      return {
        ...state,
        patient_list: {}
      };

    case types.RECALL_PATIENT_CREATE_REQUEST:
      return {
        ...state,
        patient_create: {
          processing: true,
        }
      };
    case types.RECALL_PATIENT_CREATE_SUCCESS:
      return {
        ...state,
        patient_create: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_PATIENT_CREATE_FAILURE:
      return {
        ...state,
        patient_create: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_PATIENT_CREATE_CLEAR:
      return {
        ...state,
        patient_create: {}
      };

    case types.RECALL_PATIENT_SAVE_REQUEST:
      return {
        ...state,
        patient_save: {
          processing: true,
        }
      };
    case types.RECALL_PATIENT_SAVE_SUCCESS:
      return {
        ...state,
        patient_save: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_PATIENT_SAVE_FAILURE:
      return {
        ...state,
        patient_save: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_PATIENT_SAVE_CLEAR:
      return {
        ...state,
        patient_save: {}
      };

    case types.RECALL_LISTS_LIST_REQUEST:
      return {
        ...state,
        lists_list: {
          processing: true,
        }
      };
    case types.RECALL_LISTS_LIST_SUCCESS:
      return {
        ...state,
        lists_list: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_LISTS_LIST_FAILURE:
      return {
        ...state,
        lists_list: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_LISTS_LIST_CLEAR:
      return {
        ...state,
        lists_list: {}
      };

    case types.RECALL_LISTS_LISTFULL_REQUEST:
      return {
        ...state,
        lists_listfull: {
          processing: true,
        }
      };
    case types.RECALL_LISTS_LISTFULL_SUCCESS:
      return {
        ...state,
        lists_listfull: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_LISTS_LISTFULL_FAILURE:
      return {
        ...state,
        lists_listfull: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_LISTS_LISTFULL_CLEAR:
      return {
        ...state,
        lists_listfull: {}
      };

    case types.RECALL_LISTS_DEACTIVATE_REQUEST:
      return {
        ...state,
        lists_deactivate: {
          processing: true,
        }
      };
    case types.RECALL_LISTS_DEACTIVATE_SUCCESS:
      return {
        ...state,
        lists_deactivate: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_LISTS_DEACTIVATE_FAILURE:
      return {
        ...state,
        lists_deactivate: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_LISTS_DEACTIVATE_CLEAR:
      return {
        ...state,
        lists_deactivate: {}
      };

    case types.RECALL_LISTS_REACTIVATE_REQUEST:
      return {
        ...state,
        lists_reactivate: {
          processing: true,
        }
      };
    case types.RECALL_LISTS_REACTIVATE_SUCCESS:
      return {
        ...state,
        lists_reactivate: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_LISTS_REACTIVATE_FAILURE:
      return {
        ...state,
        lists_reactivate: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_LISTS_REACTIVATE_CLEAR:
      return {
        ...state,
        lists_reactivate: {}
      };

    case types.RECALL_LISTS_CREATE_REQUEST:
      return {
        ...state,
        lists_create: {
          processing: true,
        }
      };
    case types.RECALL_LISTS_CREATE_SUCCESS:
      return {
        ...state,
        lists_create: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_LISTS_CREATE_FAILURE:
      return {
        ...state,
        lists_create: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_LISTS_CREATE_CLEAR:
      return {
        ...state,
        lists_create: {}
      };

    case types.RECALL_LISTS_SAVE_REQUEST:
      return {
        ...state,
        lists_save: {
          processing: true,
        }
      };
    case types.RECALL_LISTS_SAVE_SUCCESS:
      return {
        ...state,
        lists_save: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_LISTS_SAVE_FAILURE:
      return {
        ...state,
        lists_save: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_LISTS_SAVE_CLEAR:
      return {
        ...state,
        lists_save: {}
      };


    case types.RECALL_LISTS_LOAD_REQUEST:
      return {
        ...state,
        lists_load: {
          processing: true,
        }
      };
    case types.RECALL_LISTS_LOAD_SUCCESS:
      return {
        ...state,
        lists_load: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_LISTS_LOAD_FAILURE:
      return {
        ...state,
        lists_load: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_LISTS_LOAD_CLEAR:
      return {
        ...state,
        lists_load: {}
      };

    case types.RECALL_ITEMS_LIST_REQUEST:
      return {
        ...state,
        items_list: {
          processing: true,
        }
      };
    case types.RECALL_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        items_list: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_ITEMS_LIST_FAILURE:
      return {
        ...state,
        items_list: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_ITEMS_LIST_CLEAR:
      return {
        ...state,
        items_list: {}
      };

    case types.RECALL_ITEMS_CREATE_REQUEST:
      return {
        ...state,
        items_create: {
          processing: true,
        }
      };
    case types.RECALL_ITEMS_CREATE_SUCCESS:
      return {
        ...state,
        items_create: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_ITEMS_CREATE_FAILURE:
      return {
        ...state,
        items_create: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_ITEMS_CREATE_CLEAR:
      return {
        ...state,
        items_create: {}
      };

    case types.RECALL_ITEMS_SAVE_REQUEST:
      return {
        ...state,
        items_save: {
          processing: true,
        }
      };
    case types.RECALL_ITEMS_SAVE_SUCCESS:
      return {
        ...state,
        items_save: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_ITEMS_SAVE_FAILURE:
      return {
        ...state,
        items_save: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_ITEMS_SAVE_CLEAR:
      return {
        ...state,
        items_save: {}
      };


    case types.RECALL_COMMENTS_LIST_REQUEST:
      return {
        ...state,
        comments_list: {
          processing: true,
        }
      };
    case types.RECALL_COMMENTS_LIST_SUCCESS:
      return {
        ...state,
        comments_list: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_COMMENTS_LIST_FAILURE:
      return {
        ...state,
        comments_list: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_COMMENTS_LIST_CLEAR:
      return {
        ...state,
        comments_list: {}
      };


    case types.RECALL_COMMENTS_CREATE_REQUEST:
      return {
        ...state,
        comments_create: {
          processing: true,
        }
      };
    case types.RECALL_COMMENTS_CREATE_SUCCESS:
      return {
        ...state,
        comments_create: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.RECALL_COMMENTS_CREATE_FAILURE:
      return {
        ...state,
        comments_create: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.RECALL_COMMENTS_CREATE_CLEAR:
      return {
        ...state,
        comments_create: {}
      };

    default:
      return state;
  }
}
