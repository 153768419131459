import React from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Spin, Select, notification } from 'antd';

import * as actions from '../../../../../../actions/billing';
import { safe } from '../../../../../../helpers/reduxSelectors';

class RecordPaymentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.createSuccess === true && prevProps.createSuccess !== true) {
      this.setState({
        visible: false
      });
      notification['success']({
        message: 'Success',
        description: 'Patient payment created'
      });
    } else if (this.props.createSuccess === false && prevProps.createSuccess !== false) {
      this.setState({
        visible: false
      });
      notification['error']({
        message:'Unable to Issue a Patient Payment',
        description: safe(this.props,'createError.data.message'),
        duration: 0
      });
    }
  }

  showDialog = () => {
    this.props.form.resetFields();
    this.setState({
      visible: true
    });
  }

  handleCancel = () => {
    this.setState({
      visible: false
    });
  }

  handleOk = () => {
    this.props.form.validateFields((err,values) => {
      this.props.createPtPayment(this.props.patientId,values.payment_type,
        values.payment_amount,values.check_num);
      this.setState({
        visible: false
      });
    });

  }

  renderModalContent = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
      },
    };

    return (
      <Form className='payment-form' autoComplete='false'>
        <FormItem {...formItemLayout} label="Payment Type">
          {getFieldDecorator('payment_type', {
            rules: [{ required: true, message: " " }],
            initialValue: 3
          })(
            <Select>
              <Option value={3}>Stripe Default Credit Card</Option>
              <Option value={1}>Check</Option>
            </Select>
          )}
        </FormItem>

        { this.props.form.getFieldValue('payment_type') === 1 &&
          <FormItem {...formItemLayout} label="Check Number">
            {getFieldDecorator('check_num', {
              rules: [{ required: true, message: " " }],
            })(
              <Input />
            )}
          </FormItem>
        }

        <FormItem {...formItemLayout} label="Payment Amount">
          {getFieldDecorator('payment_amount', {
            rules: [
              { required: true, message: " " },
              { validator: (rule,value,callback) => {
                  if (isNaN(value)) {
                    callback(' ');
                  }
                  callback();
              }}
            ],
            initialValue: 0.0
          })(
            <Input />
          )}
        </FormItem>

      </Form>
    )
  }

  render() {
    return (
      <div>
        <Spin spinning={this.props.isBusy}>
          <Modal
            title="Charge Patient"
            visible={this.state.visible}
            onOk={this.handleOk}
            okText='Submit'
            onCancel={this.handleCancel}
          >
            {this.renderModalContent()}
          </Modal>
          <a onClick={this.showDialog}>
            {this.props.children}
          </a>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.ptpayments.create.processing',false)
    ) ? true : false,
    createSuccess: safe(state,'billing.ptpayments.create.success'),
    createError: safe(state,'billing.ptpayments.create.error')
  }
}

export default connect(mapStateToProps,actions)(Form.create()(RecordPaymentDialog));
