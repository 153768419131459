import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, message, Spin } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../../actions/pm';
import { safe } from '../../../helpers/reduxSelectors';

class ChargeCodesEdit extends Component {
  getCodeId = () => {
    return parseInt(this.props.match.params.id,10);
  }

  componentDidMount() {
    const code_id = this.getCodeId();
    if (code_id !== 0) {
      this.props.codeLoad(code_id);
    } else {
      this.props.codeLoadClear();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load !== this.props.load) {
      if (this.props.load.error) {
        this.props.history.push('/settings/codes');
        message.error('Unable to load charge code information');
      }
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.success) {
        this.props.codeSaveClear();
        this.props.history.push('/settings/codes');
        message.success('Charge code saved');
      } else if (this.props.error) {
        message.error('Unable to save charge code');
      }
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        this.props.codeCreateClear();
        this.props.history.push('/settings/codes');
        message.success('Charge code created');
      } else if (this.props.error) {
        message.error('Unable to save charge code');
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const code_id = this.getCodeId();
        if (code_id === 0) {
          this.props.codeCreate(values);
        } else {
          this.props.codeSave(code_id,values);
        }
      }
    });
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const data = safe(this.props,'load.data',{});

    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off" className='cpt-form'>
        <FormItem {...formItemLayout} label="Charge Code Name">
          {getFieldDecorator('code', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.code
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Description">
          {getFieldDecorator('description', {
            initialValue: data.description
          })(
            <Input />
          )}
        </FormItem>

        <Row>
          <Col xs={36} sm={16}>
            <Button
              htmlType="submit"
              style={{float:'right'}}
              disabled={this.props.isBusy}
              type="primary">
                Save
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <h3>Edit Charge Code</h3>
          { this.renderForm() }
        </Spin>
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    load: safe(state,'pm.codes.load'),
    save: safe(state,'pm.codes.save'),
    create: safe(state,'pm.codes.create'),
    isBusy: (
      safe(state,'pm.codes.load.processing') ||
      safe(state,'pm.codes.save.processing') ||
      safe(state,'pm.codes.create.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(ChargeCodesEdit)));
