import { Dispatch, SetStateAction } from 'react';
import { InlineDataT, PaginatedDataT } from 'reports';
import { PostReturnI } from 'hooks/definitions';
import { FormInstance } from 'antd';
import { createContext } from 'use-context-selector';

export type DoRunReportT = () => void;

export interface InlineContextI {
  popoverVisible: boolean;
  inlineData?: InlineDataT | PaginatedDataT | undefined;
  form?: FormInstance<any>;
  runReportAPI: PostReturnI | undefined;
  loading: boolean;
}

type SetStateT = Dispatch<SetStateAction<InlineContextI>>;

export interface ContextT {
  state: InlineContextI;
  setState: SetStateT;
  doRunReport: DoRunReportT;
}

export const defaultState: InlineContextI = {
  popoverVisible: false,
  inlineData: undefined,
  form: undefined,
  runReportAPI: undefined,
  loading: false,
};

const defaultContext = {
  state: defaultState,
  setState: () => null,
  doRunReport: () => null,
};

export default createContext<ContextT>(defaultContext);
