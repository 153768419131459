import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Modal, Tabs, message, Spin } from 'antd';

import * as actions from '../../../actions/billing';
import { safe } from '../../../helpers/reduxSelectors';

class Cpt extends Component {
  componentDidMount() {
    this.props.getCptList();
  }

  onClickAddCpt = () => {
    this.props.history.push('/settings/cpt/0');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deactivate !== this.props.deactivate) {
      if (this.props.deactivate.success) {
        message.success("CPT code deactivated");
        this.props.cptDeactivateClear();
      }
      if (this.props.deactivate.error) {
        message.error(safe(this.props,'deactivate.error.data.message'));
        this.props.cptDeactivateClear();
      }
    }

    if (prevProps.reactivate !== this.props.reactivate) {
      if (this.props.reactivate.success) {
        message.success("CPT code reactivated");
        this.props.cptReactivateClear();
      }
      if (this.props.reactivate.error) {
        message.error(safe(this.props,'reactivate.error.data.message'));
        this.props.cptReactivateClear();
      }
    }
  }

  deactivate = (record) => {
    const confirm = Modal.confirm;
    const du = this.props.cptDeactivate;

    confirm({
      title: 'Deactivate CPT: ' + record.code + '?',
      onOk() { du(record.id) },
      onCancel() { }
    });
  }

  reactivate = (record) => {
    const confirm = Modal.confirm;
    const ru = this.props.cptReactivate;

    confirm({
      title: 'Reactivate CPT: ' + record.code + '?',
      onOk() { ru(record.id) },
      onCancel() { }
    });
  }

  renderActiveTable = () => {
    const { Column } = Table;

    return (
      <Table
        dataSource={this.props.cptList.filter(item => item.active === true)}
        rowKey="id"
        size='small'
        pagination={false}
      >
        <Column
          title="Code"
          dataIndex="code"
          key="code"
          render={(text,record) => (
            <Link to={"/settings/cpt/"+record.id} style={{whiteSpace: 'nowrap'}}>{text}</Link>
          )}
        />

        <Column
          title="Description"
          dataIndex="description"
          key="description"
        />

        <Column
          title="Type"
          dataIndex="type"
          key="type"
          render={(text,record) => {
            if (text === 1) {
              return "All Clinicians";
            } else if (text === 2) {
              return "Prescribers Only";
            } else if (text === 3) {
              return "Therapists Only";
            } else {
              return "Other";
            }
          }}
        />
        <Column
          title="Action"
          key="action"
          width={100}
          fixed="right"
          render={(text, record, index) => (
            <span>
              <a onClick={() => { this.deactivate(record) }}>Deactivate</a>
            </span>
          )}
        />
      </Table>
    );
  }

  renderInactiveTable = () => {
    const { Column } = Table;

    return (
      <Table
        dataSource={this.props.cptList.filter(item => item.active === false)}
        rowKey="id"
        size='small'
        pagination={false}
      >
        <Column
          title="Code"
          dataIndex="code"
          key="code"
        />

        <Column
          title="Description"
          dataIndex="description"
          key="description"
        />

        <Column
          title="Type"
          dataIndex="type"
          key="type"
          render={(text,record) => {
            if (text === 1) {
              return "All Clinicians";
            } else if (text === 2) {
              return "Prescribers Only";
            } else if (text === 3) {
              return "Therapists Only";
            } else {
              return "Other";
            }
          }}
        />

        <Column
          title="Deactivated"
          dataIndex="deactivatedAt"
          key="deactivatedAt"
          render={(text,record) => (
            (new Date(text)).toLocaleString()
          )}
        />

        <Column
          title="Action"
          key="action"
          width={100}
          fixed="right"
          render={(text, record) => (
            <span>
              <a onClick={() => { this.reactivate(record) }}>Reactivate</a>
            </span>
          )}
        />
      </Table>
    );
  }

  render() {
    const TabPane = Tabs.TabPane;

    return (
      <Spin spinning={this.props.isBusy} size='large'>
        <div style={{padding:'10px'}}>
          <h3>CPT Codes</h3>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Active" key="1">
              { this.renderActiveTable() }
            </TabPane>
            <TabPane tab="Inactive" key="2">
              { this.renderInactiveTable() }
            </TabPane>
          </Tabs>
          <div style={{position:'fixed',bottom:40,right:40}}>
            <Button
              disabled={this.props.isBusy}
              onClick={this.onClickAddCpt}
              size='large'
              shape='circle'
              type="primary"
              icon={<PlusOutlined />}/>
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deactivate: safe(state,'billing.cpt.deactivate'),
    reactivate: safe(state,'billing.cpt.reactivate'),
    cptList: safe(state,'billing.cpt.list.data',[]),
    isBusy: (
      safe(state,'billing.cpt.load.processing') ||
      safe(state,'billing.cpt.list.processing') ||
      safe(state,'billing.cpt.deactivate.processing') ||
      safe(state,'billing.cpt.reactivate.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(Cpt);
