import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Spin, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../../actions/settings';
import { safe } from '../../../helpers/reduxSelectors';

class Integrations extends Component {

  componentDidMount() {
    this.props.getIntegrationsInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.save.processing) {
      if (this.props.save.success) {
        message.success('Integration settings saved');
      } else {
        message.error('Unable to save Integration settings');
      }
      this.props.clearIntegrationsSave();
    }
  }

  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.saveIntegrationsInfo(values);
      }
    });
  }

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const data = safe(this.props,'load.data',{});
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };

    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy}>
          <h3>Integration Settings</h3>
          <Form className='org-form'>

            <Divider>Clearinghouse</Divider>

            <FormItem {...formItemLayout} label="Name">
              {getFieldDecorator('clearinghouse_name', {
                initialValue: data.clearinghouse_name
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Host">
              {getFieldDecorator('clearinghouse_host', {
                initialValue: data.clearinghouse_host
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Port">
              {getFieldDecorator('clearinghouse_port', {
                initialValue: data.clearinghouse_port
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Username">
              {getFieldDecorator('clearinghouse_username', {
                initialValue: data.clearinghouse_username
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Password">
              {getFieldDecorator('clearinghouse_password', {
                initialValue: data.clearinghouse_password
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Upload Folder">
              {getFieldDecorator('clearinghouse_uploadfolder', {
                initialValue: data.clearinghouse_uploadfolder
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Download Folder">
              {getFieldDecorator('clearinghouse_downloadfolder', {
                initialValue: data.clearinghouse_downloadfolder
              })(
                <Input />
              )}
            </FormItem>

            <Divider>AMD SFTP</Divider>

            <FormItem {...formItemLayout} label="Host">
              {getFieldDecorator('amdsftp_host', {
                initialValue: data.amdsftp_host
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Port">
              {getFieldDecorator('amdsftp_port', {
                initialValue: data.amdsftp_port
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Username">
              {getFieldDecorator('amdsftp_username', {
                initialValue: data.amdsftp_username
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Password">
              {getFieldDecorator('amdsftp_password', {
                initialValue: data.amdsftp_password
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Download Folder">
              {getFieldDecorator('amdsftp_downloadfolder', {
                initialValue: data.amdsftp_downloadfolder
              })(
                <Input />
              )}
            </FormItem>

            <Divider>Cloudinary</Divider>

            <FormItem {...formItemLayout} label="API Key">
              {getFieldDecorator('cloudinary_api_key', {
                initialValue: data.cloudinary_api_key
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="API Secret">
              {getFieldDecorator('cloudinary_api_secret', {
                initialValue: data.cloudinary_api_secret
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Cloud Name">
              {getFieldDecorator('cloudinary_cloudname', {
                initialValue: data.cloudinary_cloudname
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Base URL">
              {getFieldDecorator('cloudinary_base_url', {
                initialValue: data.cloudinary_base_url
              })(
                <Input />
              )}
            </FormItem>

            <Divider>Stripe</Divider>

            <FormItem {...formItemLayout} label="Stripe Published Key">
              {getFieldDecorator('stripe_pk', {
                initialValue: data.stripe_pk
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Stripe Secret Key">
              {getFieldDecorator('stripe_sk', {
                initialValue: data.stripe_sk
              })(
                <Input />
              )}
            </FormItem>

            <Divider>Twilio</Divider>

            <FormItem {...formItemLayout} label="Twilio Account SID">
              {getFieldDecorator('twilio_account_sid', {
                initialValue: data.twilio_account_sid
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Twilio Auth Token">
              {getFieldDecorator('twilio_auth_token', {
                initialValue: data.twilio_auth_token
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Twilio SMS Phone #">
              {getFieldDecorator('twilio_sms_number', {
                initialValue: data.twilio_sms_number
              })(
                <Input />
              )}
            </FormItem>

            <Divider>Flexbooker</Divider>
            <FormItem {...formItemLayout} label="Flexbooker SSO Private Key">
              {getFieldDecorator('flexbooker_privatekey', {
                initialValue: data.flexbooker_privatekey
              })(
                <Input.TextArea />
              )}
            </FormItem>

            <Divider>SendGrid</Divider>
            <FormItem {...formItemLayout} label="SendGrid API Key">
              {getFieldDecorator('sendgrid_api_key', {
                initialValue: data.sendgrid_api_key
              })(
                <Input />
              )}
            </FormItem>

            <Divider>RCopia E-Prescribe</Divider>

            <FormItem {...formItemLayout} label="RCopia System Name">
              {getFieldDecorator('rcopia_portal_system_name', {
                initialValue: data.rcopia_portal_system_name
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="RCopia Practice Username">
              {getFieldDecorator('rcopia_practice_user_name', {
                initialValue: data.rcopia_practice_user_name
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="RCopia Secret Key">
              {getFieldDecorator('rcopia_secret_key', {
                initialValue: data.rcopia_secret_key
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="RCopia SSO URL">
              {getFieldDecorator('rcopia_sso_url', {
                initialValue: data.rcopia_sso_url
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="RCopia upload URL">
              {getFieldDecorator('rcopia_upload_url', {
                initialValue: data.rcopia_upload_url
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="RCopia download URL">
              {getFieldDecorator('rcopia_download_url', {
                initialValue: data.rcopia_download_url
              })(
                <Input />
              )}
            </FormItem>

            <Row style={{paddingTop:'30px'}}>
              <Col xs={36} sm={16}>
                <Button
                  disabled={this.props.isBusy}
                  onClick={this.handleSubmit}
                  style={{float:'right'}}
                  type="primary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    load: safe(state,'settings.integrations.load'),
    save: safe(state,'settings.integrations.save'),
    isBusy: (
      safe(state,'settings.integrations.load.processing') ||
      safe(state,'settings.integrations.save.processing')
    ) ? true : false
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(Integrations)));
