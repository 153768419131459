import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter, Route } from 'react-router-dom';
import reduxThunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import Button from 'antd/lib/button';

import reducers from './reducers';
import App from './components/App';
import initSentry from 'config/sentry';

import 'antd/dist/antd.css';
import 'assets/app.scss';
import 'assets/billing.scss';
import 'assets/calendar.scss';
import 'assets/emr.scss';
import 'assets/other.scss';
import 'assets/reports.scss';
import 'assets/scheduler.scss';
import 'assets/settings.scss';
import 'assets/statement.scss';
import 'assets/standardize.scss';

const store = createStore(reducers, applyMiddleware(reduxThunk));

initSentry();

ReactDOM.render(
  <Sentry.ErrorBoundary
    fallback={
      <Button type="link" onClick={() => Sentry.showReportDialog()}>
        Report feedback
      </Button>
    }
  >
    <Provider store={store}>
      <BrowserRouter>
        <Route component={App} />
      </BrowserRouter>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);

/**
 * Copy of Ant-Design Team fix for this benign warning polluting Sentry logs
 * @param  {[type]} typeof [description]
 * @return {[type]}        [description]
 */
if (typeof window !== 'undefined') {
  window.addEventListener('error', function (e) {
    // Ignore ResizeObserver error
    if (e.message === 'ResizeObserver loop limit exceeded') {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
  });
}
