import * as types from '../../actions/emr/types';

const defaultState = {
  patientId: undefined,
  chartBriefView: false,
  chartFilterNotes: 1
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case types.EMR_SET:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
