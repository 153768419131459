/**
 * FormConfirm.tsx
 * A component used for confirming selected form items for removal
 */
import { useContextSelector } from 'use-context-selector';
import { Button, Divider, List } from 'antd';
import { FileOutlined, PaperClipOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import Context from './context';
import renderNoteType from './renderNoteType';

interface ItemI {
  fileName: string;
  id: number;
  title: string;
  type: number;
}

interface ReviewItem {
  title: string;
  value: unknown;
  isAttachment?: boolean;
}

type ReviewItemsT = Array<ReviewItem>;

const NoteHideFormConfirm: React.FC = () => {
  const setState = useContextSelector(Context, (c) => c.setState);
  const note = useContextSelector(Context, (c) => c.state.note);
  const form = useContextSelector(Context, (c) => c.state.form);
  const hideNoteAPI = useContextSelector(Context, (c) => c.state.hideNoteAPI);
  const doHideNote = useContextSelector(Context, (c) => c.doHideNote);
  const attachments = get(note, 'attachments', []) as Array<ItemI>;

  const findAttachmentTitle = (attachmentId): string => {
    const found = attachments.find((attachment) => attachment.id === attachmentId);
    if (found) {
      return found.title;
    }
    return 'n/a';
  };

  const formatReviewItems = () => {
    const values = form?.getFieldsValue();
    if (values) {
      const items: ReviewItemsT = [];
      const { noteId, attachmentIds } = values;
      const noteDate = DateTime.fromISO(note?.note_date as string);
      if (noteId) {
        const title = `${renderNoteType(note?.type as number)} from ${noteDate.toFormat('MM/dd/yyyy')}`;
        items.push({
          title,
          value: noteId,
        });
      }
      if (attachmentIds) {
        attachmentIds.forEach((attachmentId) => {
          items.push({
            title: findAttachmentTitle(attachmentId),
            value: attachmentId,
            isAttachment: true,
          });
        });
      }
      return items;
    }
  };

  const handleCancel = () => {
    setState((s) => ({ ...s, isModalVisible: false }));
  };

  return (
    <>
      <h4>Review Items:</h4>
      <List
        size="small"
        bordered
        dataSource={formatReviewItems()}
        renderItem={(item) => {
          return (
            <List.Item>
              {item.isAttachment ? <PaperClipOutlined /> : <FileOutlined />} {item.title}
            </List.Item>
          );
        }}
      />
      <Divider />
      <p>
        The following items will be removed from the chart. This should only be done in situations where there has been
        a mistake where there&apos;s potentially a HIPAA violation. The system internally keeps records of all note and
        attachment removals.
      </p>
      <div
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>
        <Button key="submit" type="primary" danger loading={hideNoteAPI?.loading} onClick={doHideNote}>
          Confirm
        </Button>
      </div>
    </>
  );
};

export default NoteHideFormConfirm;
