import React from 'react';
import { Table, Layout, Select } from 'antd';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { withRouter, Link } from 'react-router-dom';

import * as billing_actions from '../../../../actions/billing';
import * as shared_actions from '../../../../actions/shared';
import { safe } from '../../../../helpers/reduxSelectors';

class InsArClaim extends React.Component {
  componentDidMount() {
    this.props.getCarrierList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ar_current_page_2 !== this.props.ar_current_page_2) {
      this.props.loadInsClaimAr(this.props.ar_current_page_2*this.props.pageSize,
        this.props.pageSize,this.props.ar_carrier);
    }
  }

  changePage = ({current}) => {
    this.props.setBillingState({
      ar_current_page_2: current
    });
  }

  changeCarrier = (value) => {
    this.props.history.push('2?c='+value);
  }

  rowClick = (record) => {
    //console.log(record);
  }

  render() {
    const columns=[{
      title: 'Patient',
      dataIndex: 'patient_name',
      render: (value,record) => {
        return <Link to={'/billing/patients/'+record.patient_id}>{value}</Link>
      }
    },{
      title: 'Carrier',
      dataIndex: 'carrier_name'
    },{
      title: 'DOS',
      dataIndex: 'dos'
    },{
      title: 'Days',
      dataIndex: 'days'
    },{
      title: 'Amount',
      dataIndex: 'amount',
      align: 'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    }];

    const { Content } = Layout;

    const count = safe(this.props.data,'count',0);
    const rows = safe(this.props.data,'rows',[]);

    const Option = Select.Option;
    const carriers = [
      <Option key="0" value="0">All Carriers</Option>,
      this.props.carrierList.map(x => <Option key={x.id.toString()} value={x.id.toString()}>{x.name}</Option>)
    ];

    return (
      <Layout>
        <Content style={{backgroundColor:'white'}}>
          <div>
            <table style={{width:'100%',marginBottom:'10px'}}>
              <tbody>
                <tr>
                  <td>
                    <strong>Carrier</strong>: &nbsp;
                    <Select
                      style={{width:'300px'}}
                      value={this.props.ar_carrier}
                      onChange={this.changeCarrier}>
                      { carriers }
                    </Select>
                  </td>
                  <td style={{textAlign:'right'}}>
                    <a href='/api/v1/ar/ins_per_service?start=1&limit=1&csv=1'>CSV Download</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <Table
              columns={columns}
              dataSource={rows}
              onChange={this.changePage}
              onRow={(record) => { return { onClick: () => this.rowClick(record) } }}
              pagination={{
                current: this.props.ar_current_page_2,
                pageSize: this.props.pageSize,
                total: count,
                showQuickJumper: true
              }}
              rowKey='billing_service_id'
              size='small'
            />
          </div>
        </Content>
      </Layout>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'billing.ar.list2.processing',false) ? true : false,
    data: safe(state,'billing.ar.list2.data',{}),
    ar_current_page_2: safe(state,'shared.domain.billing.ar_current_page_2',0),
    ar_carrier: safe(state,'shared.domain.billing.ar_carrier','0'),
    carrierList: safe(state,'shared.carrier.list.data',[])
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(withRouter(InsArClaim));
