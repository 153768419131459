import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';

import * as actions from '../../../../../../actions/emr';
import { attachments } from '../../../../../../helpers/attachments';

class AttachmentUploadItem extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    filename: PropTypes.string,
    noteId: PropTypes.number
  }

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      name: this.props.name,
      type: this.props.type
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name) {
      this.setState({name:this.props.name});
    }

    if (prevProps.type !== this.props.type) {
      this.setState({type:this.props.type});
    }
  }

  edit = () => {
    this.setState({
      editing: !this.state.editing
    });
  }

  cancel = () => {
    this.setState({
      editing: false,
      name: this.props.name,
      type: this.props.type
    });
  }

  delete = () => {
    this.props.deleteAttachment(this.props.noteId,this.props.id);
  }

  save = () => {
    this.props.renameAttachment(
      this.props.noteId,
      this.props.id,
      this.state.name,
      this.state.type
    );
    this.edit();
  }

  onInputChange = (event) => {
    this.setState({
      name: event.target.value
    });
  }

  displayName = () => {
    if (this.state.editing) {
      return (
        <Input
          style={{marginBottom:'5px'}}
          value={this.state.name}
          defaultValue={this.state.name}
          onChange={this.onInputChange}
          onPressEnter={this.save}
        />
      );
    } else {
      return (
        <a target='_blank' href={'/attachments/'+this.props.filename}>{this.state.name}</a>
      );
    }
  }

  onSelectChange = (value) => {
    this.setState({
      type: value
    });
  }

  displayType = () => {
    const Option = Select.Option;
    if (this.state.editing) {
      return (
        <Select
          onChange={this.onSelectChange}
          value={this.state.type}
        >
          { attachments.map(a => <Option value={a.id}>{a.title}</Option>) }
        </Select>
      );
    } else {
      const x = attachments.find(a=>a.id===this.state.type);
      return (x === undefined) ? '-' : x.title;
    }
  }
//          <a onClick={this.cancel}>Cancel</a>
//           <a onClick={this.save}>Save</a>

  displayOptions = () => {
    if (this.state.editing) {
      return (
        <div>
          <Button onClick={this.cancel}>Cancel</Button>
          &nbsp;
          <Button type='primary' onClick={this.save}>Save</Button>
        </div>
      );
    } else {
      return (
        <span>
          <a onClick={this.edit} style={{fontSize:'larger'}}><EditOutlined /></a>
          &nbsp; &nbsp; &nbsp;
          <a onClick={this.delete} style={{fontSize:'larger'}}><DeleteOutlined /></a>
        </span>
      );
    }
  }

  render() {
    return (
      <tr>
        <td width='300' style={{paddingRight:'10px'}}>
          { this.displayName() }
        </td>
        <td width='200'>
          { this.displayType() }
        </td>
        <td>
          { this.displayOptions() }
        </td>
      </tr>
    );
  }
}

export default connect(null,actions)(AttachmentUploadItem);
