import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import moment from 'moment';

const address = (r) => {
  const result: Array<string> = [];
  if (r) {
    if (r.address1) {
      result.push(r.address1);
    }
    if (r.address2) {
      result.push(r.address2);
    }
    if (r.city) {
      result.push(r.city);
    }
    if (r.state) {
      result.push(r.state);
    }
    if (r.zip) {
      result.push(r.zip);
    }
  }
  return result.join(',');
};

const columns: Array<ColumnI> = [
  {
    title: 'Patient ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Patient',
    dataIndex: 'full_name',
    key: 'patient',
  },
  {
    title: 'Patient DOB',
    dataIndex: 'dob',
    key: 'dob',
    render: (val: string): string => {
      const m = moment(val);
      return m.isValid() ? m.format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'Patient Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Patient Gender',
    dataIndex: 'gender',
    key: 'gender',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Address',
    dataIndex: 'address1',
    key: 'address',
    render: (_val, record) => address(record) as string,
  },
  {
    title: 'Chart Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string): string => {
      return moment(val).format('MM/DD/YYYY');
    },
  },
];

export default columns;
