/**
 * ReportParametersWrapper.tsx
 * The bottom half of the QueueReports modal form which contains the
 * parameters for the report itself.
 */
import { Badge, Card } from 'antd';

interface PropsI {
  children: React.ReactNode;
}

const ReportParametersWrapper: React.FC<PropsI> = (props: PropsI) => {
  const { children } = props;
  return (
    <Badge.Ribbon text="Report Parameters" color="purple">
      <Card className="mt4">{children}</Card>
    </Badge.Ribbon>
  );
};

export default ReportParametersWrapper;
