import React from 'react';
import { Input, Button } from 'antd';

class IndividualAmount extends React.Component {
  static defaultProps = {
    disabled: false
  }

  constructor(props) {
    super(props);
    this.state = {
      amount: props.amount,
      claimNum: null,
      description: null
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.amount !== this.props.amount) {
      this.setState({
        amount: this.props.amount
      });
    }
  }

  onAmountChange = (event) => {
    this.setState({amount:event.target.value});
  }

  onDescriptionChange = (event) => {
    this.setState({description:event.target.value});
  }

  onClaimNumChange = (event) => {
    this.setState({claimNum:event.target.value});
  }

  onClickAdd = () => {
    this.props.onSelect(this.props.id,this.state.amount,this.state.claimNum,this.state.description,this.props.code);
    this.setState({
      description:'',
      claimNum: '',
      amount: this.props.amount
    });
  }

  shouldDisableAddButton = () => {
    if (this.props.disabled ||
        isNaN(this.state.amount) ||
        this.state.claimNum === undefined ||
        this.state.claimNum === null ||
        this.state.claimNum.length < 1) {
      return true;
    }
    return false;
  }

  render() {
    const d = this.shouldDisableAddButton();
    return (
      <div style={{display:'flex'}}>
        <Input disabled={this.props.disabled} style={{height:'21px',width:'100px',flex:'none'}} onChange={this.onAmountChange} value={this.state.amount}/>
        <Input disabled={this.props.disabled} placeholder='Claim #' style={{marginLeft:'5px',height:'21px',width:'150px',flex:'none'}} onChange={this.onClaimNumChange} value={this.state.claimNum} />
        <Input disabled={this.props.disabled} placeholder='Description' style={{marginLeft:'5px',height:'21px',flexGrow:1}} onChange={this.onDescriptionChange} value={this.state.description}/>
        <Button disabled={d} onClick={this.onClickAdd} style={{marginLeft:'5px',height:'21px',width:'75px',flex:'none'}}>Add</Button>
      </div>
    )
  }
}

export default IndividualAmount;
