/**
 * EndDateType.tsx
 */
import { useEffect, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Button, DatePicker, Dropdown, Form, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import formItemRules from 'components/App_/ReportsV2_/formItemRules';
import QueueContext from 'components/App_/ReportsV2_/QueueContext';
import { endDateTypes as defaultEndDateTypes } from 'reports';

const EndDateType: React.FC = () => {
  const form = useContextSelector(QueueContext, (c) => c.state.form);
  const isRecurring = useContextSelector(QueueContext, (c) => c.state.isRecurring);
  const [endDateType, setEndDateType] = useState<number | undefined>();
  const [endDateTypes, setEndDateTypes] = useState<Array<string>>(defaultEndDateTypes);

  useEffect(() => {
    if (!isRecurring) {
      setEndDateType(1);
      setEndDateTypes([defaultEndDateTypes[0]]);
    } else {
      setEndDateType(undefined);
      setEndDateTypes(defaultEndDateTypes);
    }
  }, [isRecurring]);

  const onSelectEndDateType = (endDateType: number) => {
    form?.setFieldsValue({ endDateType });
    setEndDateType(endDateType);
  };

  const onEndDateChange = (date: null | moment.Moment) => {
    const endDate = date?.seconds(0).milliseconds(0);
    form?.setFieldsValue({ endDate });
  };

  const paramsEndDateParams = {
    placeholder: '',
    onChange: onEndDateChange,
  };

  const EndDateTypeSelect = () => (
    <Form.Item
      style={{ display: 'inline-block' }}
      className="m0 mr8"
      name="endDateType"
      rules={formItemRules.endDateType}
    >
      <h5>End Date Type</h5>
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            {endDateTypes.map((type, key) => (
              <Menu.Item key={key} onClick={() => onSelectEndDateType(key + 1)}>
                {type}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button type="default" size="small">
          {endDateType ? endDateTypes[endDateType - 1] : 'Select'} <DownOutlined />
        </Button>
      </Dropdown>
    </Form.Item>
  );

  const EndDatePicker = () => (
    <Form.Item style={{ display: 'inline-block' }} className="m0" name="endDate" rules={formItemRules.endDate}>
      <h5>End Date</h5>
      <DatePicker {...paramsEndDateParams} size="small" />
    </Form.Item>
  );

  return (
    <div className="mt4">
      {isRecurring && <EndDateTypeSelect />}
      {endDateType === 1 ? <EndDatePicker /> : null}
    </div>
  );
};

export default EndDateType;
