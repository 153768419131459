/**
 * PayPeriodSelector.tsx
 */
import { useEffect, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Form, Select } from 'antd';
import { DateTime, Settings } from 'luxon';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import formItemRules from 'components/App_/ReportsV2_/formItemRules';

Settings.defaultZoneName = 'America/Los_Angeles';

interface PayPeriodsListI {
  start: string;
  end: string;
}

interface PropsI {
  callback: () => void;
}

type PayPeriodsT = Array<PayPeriodsListI>;

const getPayPeriods = (): PayPeriodsT => {
  let start = DateTime.fromISO('2020-03-02T09:00:00.000');
  let end = start.plus({ days: 13 });

  const list: PayPeriodsT = [];
  while (DateTime.now() > end) {
    list.push({ start: start.toFormat('MM/dd/yyyy'), end: end.toFormat('MM/dd/yyyy') });
    start = start.plus({ days: 14 });
    end = end.plus({ days: 14 });
  }

  return list.reverse();
};

const PayPeriodSelector: React.FC<PropsI> = (props: PropsI) => {
  const { callback } = props;
  const form = useContextSelector(InlineContext, (c) => c.state.form);
  const [selection, setSelection] = useState<number>();

  useEffect(() => {
    const curPayPeriod = form?.getFieldValue('payPeriod');
    const newList = getPayPeriods();
    if (!curPayPeriod) {
      const firstItem = newList[0];
      const { start, end } = firstItem;
      const payPeriod = `${start}-${end}`;
      form?.setFieldsValue({ payPeriod });
      setSelection(newList && newList.length > 0 ? 0 : undefined);
    } else {
      const [start, end] = curPayPeriod.split('-');
      const foundSelection = newList.findIndex((x) => {
        return x.start === start && x.end === end;
      });
      if (foundSelection > -1) {
        const foundItem = newList[foundSelection];
        const payPeriod = `${foundItem.start}-${foundItem.end}`;
        form?.setFieldsValue({ payPeriod });
        setSelection(foundSelection);
      }
    }
  }, []);

  const onSelectChange = (val) => {
    const list = getPayPeriods();
    const { start, end } = list[val];
    const payPeriod = `${start}-${end}`;
    form?.setFieldsValue({ payPeriod });
    setSelection(val);
    callback();
  };

  return (
    <Form.Item name="payPeriod" rules={formItemRules.payPeriod}>
      <span>Pay Period: </span>
      <Select onChange={onSelectChange} value={selection} style={{ width: '250px' }}>
        {getPayPeriods().map((x, i) => (
          <Select.Option key={i} value={i}>
            {x.start}-{x.end}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default PayPeriodSelector;
