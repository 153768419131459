import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, message, Spin, Select } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { usStateAbbrOptions } from '../../../helpers/states';
import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/shared';

class ServiceLocationsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id,10)
    }
  }

  componentDidMount() {
    this.props.getBillingLocationsList();
    if (this.state.id !== 0) {
      this.props.locationLoad(this.state.id);
    } else {
      this.props.locationLoadClear();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load !== this.props.load) {
      if (this.props.load.error) {
        this.props.history.push('/settings/locations');
        message.error('Unable to load locations information');
      }
    }

    if (prevProps.save !== this.props.save) {
      if (this.props.save.success) {
        this.props.locationSaveClear();
        this.props.history.push('/settings/locations');
        message.success('Location saved');
      } else if (this.props.error) {
        message.error('Unable to save location');
      }
    }

    if (prevProps.create !== this.props.create) {
      if (this.props.create.success) {
        this.props.locationCreateClear();
        this.props.history.push('/settings/locations');
        message.success('Location created');
      } else if (this.props.error) {
        message.error('Unable to save location');
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.id === 0) {
          this.props.locationCreate(values);
        } else {
          this.props.locationSave(this.state.id,values);
        }
      }
    });
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const data = safe(this.props,'load.data',{});
    const bll = safe(this.props,'billing_location_list',[]).filter(x=>x.active);

    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off" className='locations-form'>
        <FormItem {...formItemLayout} label="Location Name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.name
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Abbreviation">
          {getFieldDecorator('abbr', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.abbr
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Address 1"
        >
          {getFieldDecorator('address1', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: data.address1
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Address 2"
        >
          {getFieldDecorator('address2', {
            initialValue: data.address2
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="City"
        >
          {getFieldDecorator('city', {
            rules: [{
              required: true, message: ' ',
            }],
            initialValue: data.city
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="State">
          {getFieldDecorator('state', {
            rules: [{required: true, message: ' '}],
            initialValue: (data.state || 'CA'),
          })(
            <Select autoComplete="off">
              {usStateAbbrOptions}
            </Select>
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Zip">
          {getFieldDecorator('zip', {
            rules: [{
              required: true, message: ' ',
            }],
            initialValue: data.zip
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Billing Location">
          {getFieldDecorator('billing_location_id', {
            rules: [
              {required: true, message: ' '},
            ],
            initialValue: data.billing_location_id
          })(
            <Select>
              {bll.map(x=><Select.Option key={x.id} value={x.id}>{x.address1} {x.address2}</Select.Option>) }
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Notes'>
          {getFieldDecorator('notes', {
            initialValue: data.notes
          })(
            <TextArea rows={4}/>
          )}
        </FormItem>

        <br />

        <Row>
          <Col xs={36} sm={16}><Button htmlType="submit" style={{float:'right'}} disabled={this.props.isLoading} type="primary">Save</Button></Col>
        </Row>
      </Form>
    );
  }

  render() {
    const name = safe(this.props,'load.data.name','None');

    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <h3>Service Locations / {name}</h3>
          { this.renderForm() }
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'shared.location.load.processing',false) ||
      safe(state,'shared.location.save.processing',false) ||
      safe(state,'shared.location.create.processing',false) ||
      safe(state,'shared.billing_location.list.processing',false)
    ) ? true : false,
    load: safe(state,'shared.location.load',{}),
    save: safe(state,'shared.location.save',{}),
    create: safe(state,'shared.location.create',{}),
    billing_location_list: safe(state,'shared.billing_location.list.data',[])
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(ServiceLocationsEdit)));
