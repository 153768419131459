import React from 'react';
import Time from '../../../shared/Time';
import ReactResizeDetector from 'react-resize-detector';

import {
  FileAddOutlined,
  FormOutlined,
  HistoryOutlined,
  HomeOutlined,
  LeftOutlined,
  RightOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';

import { Button, DatePicker, Modal, notification, Tag } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { safe, isClinician, isAdmin, isClinicalSupervisor } from '../../../../helpers/reduxSelectors';
import * as emr_actions from '../../../../actions/emr';
import * as shared_actions from '../../../../actions/shared';
//import CreateSessionFromOpening from './CalView_/CreateSessionFromOpening';
import ManualSessionCreate from './CalView_/ManualSessionCreate';
import AutoSessionCreate from './CalView_/AutoSessionCreate';
import ModifySession from './CalView_/ModifySession';
import SessionHistory from './CalView_/SessionHistory';
import DeleteSessionModal from './CalView_/DeleteSessionModal';
import ProviderSelector from '../../../shared/ProviderSelector';
import IntakesModal from './CalView_/IntakesModal';
import { filterData, availableTypes } from './cal';

const TYPE_SESSION_BLOCK = 1;
const TYPE_OPEN_BLOCK = 2;

const SESSION_TYPE_ENCOUNTER = 1;
const SESSION_TYPE_BLACKOUT = 2;
//const SESSION_TYPE_HOLIDAY = 3;
const SESSION_TYPE_PTO = 4;
const SESSION_TYPE_UTO = 5;
//const SESSION_TYPE_ADMIN = 6;
const SESSION_TYPE_ADMIN_PRIVATE = 7;

class CalView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      dateRange: [],
      deleteModalVisible: false,
      deleteModalData: {},
      autoSessionModalVisible: false,
      autoSessionModalData: {},
      editSessionModalVisible: false,
      editSessionModalData: {},
      historyDrawerVisible: false,
      manualSessionModalVisible: false,
      intakesModalVisible: false,
      calendarPopup: false
    }
  }

  componentDidMount() {
    this.computeDateRange();
    //    this.props.getHolidays();
    this.props.getLocationsList();
    if (this.props.calendarProvider.id) {
      this.props.getProviderCalendar(this.props.calendarProvider.id, 1, this.props.active_date);
    }

    if (!this.props.calendarProvider.id && this.props.isClinician) {
      this.props.setEmrState({
        calendarProvider: {
          id: this.props.activeProvider.id,
          full_name: this.props.activeProvider.full_name,
          roles: this.props.activeProvider.roles,
          employment_type: this.props.activeProvider.employment_type,
          intake_duration: this.props.activeProvider.intake_duration,
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeProvider.id !== this.props.activeProvider.id && this.props.isClinician) {
      this.props.setEmrState({
        calendarProvider: {
          id: this.props.activeProvider.id,
          full_name: this.props.activeProvider.full_name,
          roles: this.props.activeProvider.roles,
          employment_type: this.props.activeProvider.employment_type,
          intake_duration: this.props.activeProvider.intake_duration,
        },
      });
    }

    if (
      prevProps.active_date !== this.props.active_date ||
      prevProps.calendarProvider.id !== this.props.calendarProvider.id
    ) {
      this.computeDateRange();
      if (this.props.calendarProvider.id) {
        this.props.getProviderCalendar(this.props.calendarProvider.id, 1, this.props.active_date);
      }
    }

    if (prevProps.createNoteEvent.success !== this.props.createNoteEvent.success) {
      // I don't think this extra hack of the page we are on is necessary
      const path = safe(this.props, 'location.pathname', '').split('/');
      if (path.length > 1 && path[1] === 'scheduler') {
        if (this.props.createNoteEvent.success) {
          const d = safe(this.props, 'createNoteEvent.data', {});
          this.props.history.push('/emr/patients/' + d.patient_id + '/progressnote/' + d.note_id);
        } else if (this.props.createNoteEvent.error) {
          notification.error({
            message: "Can't Open Chart Note",
            description: safe(this.props.createNoteEvent, 'error.data.message'),
          });
        }
      }
    }

    if (prevProps.createSessionEvent !== this.props.createSessionEvent) {
      if (this.props.createSessionEvent.error) {
        notification.error({
          message: 'Unable to Create Session',
          description: safe(this.props.createSessionEvent.error, 'data.message'),
        });
        this.props.getProviderCalendar(this.props.calendarProvider.id, 1, this.props.active_date);
      }
    }
  }

  showHistoryDrawer = () => {
    this.setState({
      historyDrawerVisible: true,
    });
  };

  closeHistoryDrawer = () => {
    this.setState({
      historyDrawerVisible: false,
    });
  };

  showAutoSessionModal = (openItem) => {
    this.setState({
      autoSessionModalVisible: true,
      autoSessionModalData: openItem,
    });
  };

  closeAutoSessionModal = () => {
    this.setState({
      autoSessionModalVisible: false,
      autoSessionModalData: {},
    });
  };

  showEditSessionModal = (data) => {
    if (!data || !data.note || !data.note.signedAt) {
      this.setState({
        editSessionModalVisible: true,
        editSessionModalData: data,
      });
    } else {
      this.props.history.push('/emr/patients/' + data.patient_id);
    }
  };

  closeEditSessionModal = () => {
    this.setState({
      editSessionModalVisible: false,
      editSessionModalData: {},
    });
  };

  showManualSessionModal = () => {
    this.setState({
      manualSessionModalVisible: true,
    });
  };

  closeManualSessionModal = () => {
    this.setState({
      manualSessionModalVisible: false,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalVisible: false,
    });
  };

  closeIntakesModal = () => {
    this.setState({
      intakesModalVisible: false,
    });
  }

  showIntakesModal = () => {
    this.setState({
      intakesModalVisible: true,
    });
  }

  showDeleteBlockedOutModal = (seg) => {
    this.setState({
      deleteModalVisible: true,
      deleteModalData: seg,
    });
  };

  goToPreviousWeek = () => {
    this.props.setEmrState({
      active_date: moment(this.props.active_date).subtract(7, 'days').format('YYYY-MM-DD'),
    });
    this.setState({
      calendarPopup: false,
    });
  };

  goToToday = () => {
    this.props.setEmrState({
      active_date: moment().format('YYYY-MM-DD'),
    });
    this.setState({
      calendarPopup: false,
    });
  };

  goToNextWeek = () => {
    this.props.setEmrState({
      active_date: moment(this.props.active_date).add(7, 'days').format('YYYY-MM-DD'),
    });
    this.setState({
      calendarPopup: false,
    });
  };

  skipToWeek = (d) => {
    this.props.setEmrState({
      active_date: d.format('YYYY-MM-DD'),
    });
    this.setState({
      calendarPopup: false,
    });
  };

  setCalendarPopup = (e) => {
    this.setState({
      calendarPopup: e,
    });
  };

  // .format('ddd, MMM D')
  computeDateRange = () => {
    const start_date = moment(this.props.active_date).subtract(moment(this.props.active_date).day(), 'days');
    const dates = [start_date];
    for (let i = 1; i <= 6; i++) {
      dates.push(moment(start_date).add(i, 'days'));
    }
    this.setState({
      dateRange: dates,
    });
  };

  renderCalHeader = () => {
    return (
      <thead style={{ position: 'sticky', top: -1, zIndex: 2 }}>
        <tr>
          <td>
            <div className="fc-row">
              <table>
                <thead>
                  <tr style={{ backgroundColor: '#ffffff' }}>
                    <th className="fc-axis" style={{ width: '40px' }}>
                      <Tag color="green">{Time.getPSTPDT()}</Tag>
                    </th>
                    {this.state.dateRange.map((x) => {
                      const holiday = this.props.holidays.find((y) => y.date === x.format('YYYY-MM-DD'));
                      return (
                        <th key={x.format('ddd, MMM D')}>
                          {holiday ? <span style={{ color: 'purple' }}>{holiday.name}</span> : x.format('ddd, MMM D')}
                        </th>
                      );
                    })}
                  </tr>
                  <tr>
                    <th className="fc-axis" style={{ width: '40px' }}></th>
                    {this.state.dateRange.map((x) => {
                      const pto = this.inDateRange(x, this.props.vacations);
                      const prefix = pto && pto.type === 4 ? 'PTO' : 'UTO';
                      const covering_provider =
                        pto && pto.covering_provider ? pto.covering_provider : 'No Covering Provider';
                      return (
                        <th key={x.format('ddd, MMM D')}>
                          {pto ? (
                            <span style={{ color: 'orange' }}>
                              {prefix}: {covering_provider}
                            </span>
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
              </table>
            </div>
          </td>
        </tr>
      </thead>
    );
  };

  renderTime = (hr) => {
    if (hr < 12) {
      return hr + ' AM';
    } else {
      return hr >= 13 ? hr - 12 + ' PM' : hr + ' PM';
    }
  };

  renderSlats = (start_time = 600, end_time = 2245) => {
    const s = new Time(start_time);
    const e = new Time(end_time);

    const list = Time.range(s, e, 15);

    return (
      <div className="fc-slats">
        <table>
          <tbody>
            {list.map((t, index) => {
              const display = index % 4 === 0 ? true : false;
              const hr = Math.trunc(t.id / 100);
              return (
                <tr key={t.id} className={!display ? 'fc-minor' : ''}>
                  <td className="fc-axis fc-time" style={{ width: '40px' }}>
                    {display ? <span>{this.renderTime(hr)}</span> : null}
                  </td>
                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  inDateRange = (m, list) => {
    if (list) {
      for (let x of list) {
        if (m.isBetween(x.start_date, x.end_date, 'day', '[]')) {
          return x;
        }
      }
    }
    return null;
  };

  renderBg = () => {
    return (
      <div className="fc-bg">
        <table>
          <tbody>
            <tr>
              <td className="fc-axis" style={{ width: '40px' }}></td>
              {this.state.dateRange.map((x) => {
                let cn = '';
                if (moment().format('YYMMDD') === x.format('YYMMDD')) {
                  cn = 'fc-today';
                }
                if (this.props.holidays.find((y) => y.date === x.format('YYYY-MM-DD'))) {
                  cn = 'fc-holiday';
                }
                const vac = this.inDateRange(x, this.props.vacations);
                if (vac) {
                  cn = vac.type === 4 ? 'fc-pto' : 'fc-uto';
                }

                return <td key={x.format('YYMMDD')} className={cn}></td>;
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  getCurrentTime = () => {
    const t = moment();
    return ((this.state.height - 21) / 17.0) * (t.format('HH') - 6 + t.format('mm') / 60) + 'px';
  };

  getOpenSegmentSubtitle = ({ allow_video, location_id }) => {
    const loc = this.props.locationList.find((x) => x.id === location_id);
    const videoIcon = location_id === 4 || allow_video === 0 || allow_video === 1;
    const officeIcon = allow_video === 0 || allow_video === 2;
    const officeName = allow_video === 0 || allow_video === 2 ? safe(loc, 'name') : undefined;

    return (
      <span>
        {officeName}
        {officeIcon && <HomeOutlined style={{ marginLeft: '5px' }} />}
        {videoIcon && <VideoCameraOutlined style={{ marginLeft: '5px' }} />}
      </span>
    );
  };

  getOpenSegmentTitle = (seg) => {
    const isPrescriber = safe(this.props, 'calendarProvider.roles', []).includes('prescriber');
    const at = availableTypes(
      seg.duration,
      seg.allow_20m,
      seg.allow_30m,
      seg.allow_45m,
      seg.allow_60m,
      seg.allow_intake,
      this.props.intake_duration,
      isPrescriber,
    );

    const items = [];
    if (at.allow_20m) {
      items.push('20');
    }
    if (at.allow_30m) {
      items.push('30');
    }
    if (at.allow_45m) {
      items.push('45');
    }
    if (at.allow_60m) {
      items.push('60');
    }
    if (at.allow_intake) {
      items.push('Eval');
    }

    //    const loc = this.props.locationList.find(x=>x.id===seg.location_id);

    if (seg.age_category === 1) {
      items.push('Adult');
    } else if (seg.age_category === 2) {
      items.push('Child');
    }

    return items.join('/');
  };

  renderSession = (seg) => {
    const offset = (this.state.height - 42) / 17.0;

    const { id, item_type, start_time } = seg;
    const time = new Time(start_time);
    //    const status = 1;
    let title = '';
    let subtitle = undefined;
    const short = false;

    const style = {
      top: offset * seg.top + 'px',
      bottom: offset * seg.bottom + 'px',
      zIndex: seg.level + 1,
      left: seg.backwardCoord * 100 + '%',
      right: (1 - Math.min(1, seg.backwardCoord + (seg.forwardCoord - seg.backwardCoord) * 2)) * 100 + '0%',
      // add padding to the edge so that forward stacked events don't cover the resizer's Icon
      // 10 is a guesstimate of the icon's width
      marginRight: seg.forwardPressure ? 10 * 2 : 0,
    };
    const hasNote = seg.note_id ? true : false;
    const hasSignedNote = seg.signed ? true : false;
    const isTelemedicine = seg.telemedicine ? true : false;
    const canSchedule =
      this.props.isAdmin || (this.props.isClinician && this.props.activeProvider.id === this.props.calendarProvider.id)
        ? true
        : false;

    let f = null;
    const classList = ['fc-time-grid-event', 'fc-v-event', 'fc-event'];

    if (item_type === TYPE_SESSION_BLOCK) {
      switch (seg.type) {
        case SESSION_TYPE_ENCOUNTER:
          if (seg.session_status === 3) {
            classList.push('fc-event-latecancel');
          }
          if (hasSignedNote) {
            f = () => {
              this.props.history.push('/emr/patients/' + safe(seg, 'patient_id'));
            };
            classList.push('fc-event-signed');
          } else {
            if (seg.session_type === 1) {
              classList.push('fc-event-intake');
            }
            if (canSchedule) {
              f = () => {
                this.showEditSessionModal(seg);
              };
            } else {
              f = () => {
                this.props.history.push('/emr/patients/' + safe(seg, 'patient_id'));
              };
            }
          }
          title = safe(seg, 'patient');
          break;
        case SESSION_TYPE_BLACKOUT:
          f = () => {};
          classList.push('fc-event-blackout');
          title = seg.comment ? 'Blocked-Out: ' + seg.comment : 'Blocked-Out';
          f = () => {
            this.showDeleteBlockedOutModal(seg);
          };
          break;
        case SESSION_TYPE_PTO:
          f = () => {};
          classList.push('fc-event-pto');
          title = seg && seg.covering_provider ? 'PTO: ' + safe(seg, 'covering_provider') + ' Covering' : 'PTO';
          break;
        case SESSION_TYPE_UTO:
          f = () => {};
          classList.push('fc-event-pto');
          title = 'UTO';
          break;
        case SESSION_TYPE_ADMIN_PRIVATE:
          if (this.props.isAdmin) {
            f = () => {
              this.showEditSessionModal(seg);
            };
          } else {
            f = () => {};
          }
          classList.push('fc-event-admin');
          title = seg && seg.comment ? 'ADMIN: ' + safe(seg, 'comment') : 'ADMIN';
          break;
        default:
          return null; // don't render other segments
      }
    } else if (item_type === TYPE_OPEN_BLOCK) {
      if (canSchedule) {
        f = (e) => {
          this.showAutoSessionModal(seg);
        };
      } else {
        f = () => {};
      }

      title = this.getOpenSegmentSubtitle(seg);
      subtitle = this.getOpenSegmentTitle(seg);
      classList.push('fc-event-opentime');
    } else {
      return null;
    }

    if (short) {
      classList.push('fc-short');
    }

    const myNote = seg.user_id === this.props.activeProvider.id ? true : false;

    return (
      <a key={id} className={classList.join(' ')} style={style} onClick={f}>
        <div className="fc-content">
          <div className="fc-time">
            {time.toString(false)}
            {isTelemedicine && <VideoCameraOutlined style={{ paddingLeft: '5px' }} />}
            {item_type === 1 && hasNote && !hasSignedNote && (
              <span style={{ float: 'right', fontSize: '15px', paddingRight: '2px' }}>
                <FormOutlined />
              </span>
            )}
          </div>
          <div className="fc-title">{title}</div>
          {subtitle !== undefined && <div className="fc-title">{subtitle}</div>}
        </div>
        <div className="fc-bg">
          {canSchedule &&
            myNote &&
            item_type === TYPE_SESSION_BLOCK &&
            seg.type === SESSION_TYPE_ENCOUNTER &&
            !hasSignedNote && (
              <div
                onClick={(e) => {
                  this.openChartNote(e, seg);
                }}
                style={{
                  position: 'absolute',
                  right: 0,
                  width: '20px',
                  height: '100%',
                  marginTop: '0px',
                  marginBottom: '1px',
                  background: 'red',
                }}
              ></div>
            )}
        </div>
      </a>
    );
  };

  openChartNote = (e, seg) => {
    e.stopPropagation();
    if (seg.note_id) {
      // Just go to the existing note.
      if (seg.user_id === this.props.activeProvider.id) {
        this.props.history.push('/emr/patients/' + seg.patient_id + '/progressnote/' + seg.note_id);
      } else {
        notification.error({
          message: "Can't Open Chart Note",
          description: "You can't open some one else's chart note",
        });
      }
    } else {
      if (seg.on_careteam) {
        this.props.createProgressNoteFromSession(this.props.calendarProvider.id, seg.id);
      } else {
        const This = this;
        Modal.confirm({
          title: "Do you want to be added to this patient's careteam",
          content: 'In order to create a chart note for this patient you must first be part of the careteam',
          okText: 'Add to Careteam',
          onOk() {
            This.props.createProgressNoteFromSession(This.props.calendarProvider.id, seg.id, 1);
          },
          onCancel() {},
        });
      }
    }
  };

  renderDayColumn = (date, events) => {
    return (
      <td key={date}>
        <div className="fc-content-col">
          <div className="fc-event-container">{events && events.map((x) => this.renderSession(x))}</div>
        </div>
      </td>
    );
  };

  // fc-view, fc-body not necessary
  renderMain = (data) => {
    // console.log('INSIDE', data);
    return (
      <div className="fc-view-container">
        <div className="fc-view">
          <table>
            {this.renderCalHeader()}
            <tbody className="fc-body">
              <tr>
                <td>
                  <div className="fc-scroller" style={{ height: '100%' }}>
                    <div className="fc-time-grid">
                      {this.renderBg()}
                      {this.renderSlats()}
                      <div className="fc-content-skeleton">
                        <table>
                          <tbody>
                            <tr>
                              <td className="fc-axis" style={{ width: '40px' }}></td>
                              {this.state.dateRange.map((x) =>
                                this.renderDayColumn(x.format('YYYY-MM-DD'), data[x.format('YYYY-MM-DD')]),
                              )}
                            </tr>
                          </tbody>
                        </table>
                        {
                          // indicator line goes here
                        }
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  //                        <div className="fc-now-indicator fc-now-indicator-line" style={{top: this.getCurrentTime()}}></div>

  onResize = (width, height) => {
    this.setState({
      height: height,
    });
  };

  /*
  newSession = () => {
    this.showCreateSessionModal({
      session_date: moment().format('YYYY-MM-DD')
    });
  }
*/
  weekPickerFooter = () => {
    return (
      <Button size="small" type="primary" onClick={this.goToToday}>
        Today
      </Button>
    );
  };

  onSelectProvider = (p) => {
    //    console.log('-->',p);
    this.props.setEmrState({
      calendarProvider: {
        ...p,
      },
    });
  };

  resetToActiveProvider = () => {
    //    console.log(this.props.activeProvider.id,this.props.activeProvider.full_name);
    this.props.setEmrState({
      calendarProvider: {
        id: this.props.activeProvider.id,
        full_name: this.props.activeProvider.full_name,
        roles: this.props.activeProvider.roles,
        employment_type: this.props.activeProvider.employment_type,
      },
    });
  };

  render() {
    const data = JSON.parse(JSON.stringify(this.props.data));
    filterData(data);

    const canSchedule =
      (this.props.isAdmin && this.props.calendarProvider.id) ||
      (this.props.isClinician && this.props.activeProvider.id === this.props.calendarProvider.id)
        ? true
        : false;

/*
{ this.props.isClinician && this.props.calendarProvider.id === this.props.activeProvider.id &&
  <Button style={{marginLeft: '5px'}} onClick={this.showIntakesModal}>Intakes</Button> }
 */

    return (
      <>
        <div style={{
          display: 'flex',
          flexDirection:'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          marginTop: '10px',
          padding: '10px'
          }}>
          <div>
            <ProviderSelector
              onSelect={this.onSelectProvider}
              initialValue={this.props.calendarProvider}
            />
            { this.props.isClinician && this.props.calendarProvider.id !== this.props.activeProvider.id &&
              <Button type='link' onClick={this.resetToActiveProvider} style={{marginLeft:'5px'}}>{this.props.activeProvider.initials}</Button>}
          </div>
          <div>
            <Button type="link" onClick={this.goToPreviousWeek}>
              <LeftOutlined /> Previous
            </Button>

            <DatePicker
              picker="week"
              allowClear={false}
              onChange={this.skipToWeek}
              value={moment(this.props.active_date)}
              format="MMM D, YYYY"
              style={{ paddingRight: '8px' }}
              onOpenChange={this.setCalendarPopup}
              open={this.state.calendarPopup}
              renderExtraFooter={this.weekPickerFooter}
            />

            <Button type="link" onClick={this.goToNextWeek}>
              Next
              <RightOutlined />
            </Button>
          </div>
          <div>
            <Button disabled={!canSchedule} onClick={this.showManualSessionModal} icon={<FileAddOutlined />}>
              Manually Schedule
            </Button>
            &nbsp;
            <Button disabled={!canSchedule} onClick={this.showHistoryDrawer} icon={<HistoryOutlined />}>
              Session History
            </Button>
          </div>
        </div>
        <div className="fc fc-unthemed fc-ltr" style={{ margin: '10px' }}>
          <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} />
          {this.renderMain(data)}
        </div>

        <AutoSessionCreate
          visible={this.state.autoSessionModalVisible}
          onClose={this.closeAutoSessionModal}
          openItem={this.state.autoSessionModalData}
          provider_id={this.props.calendarProvider.id}
          provider_name={this.props.calendarProvider.full_name}
          provider_roles={this.props.calendarProvider.roles}
        />

        <ManualSessionCreate
          visible={this.state.manualSessionModalVisible}
          onClose={this.closeManualSessionModal}
          provider_id={this.props.calendarProvider.id}
          provider_name={this.props.calendarProvider.full_name}
          provider_roles={this.props.calendarProvider.roles}
        />

        <ModifySession
          visible={this.state.editSessionModalVisible}
          onClose={this.closeEditSessionModal}
          item={this.state.editSessionModalData}
          provider_id={this.props.calendarProvider.id}
          provider_name={this.props.calendarProvider.full_name}
          provider_roles={this.props.calendarProvider.roles}
        />

        <SessionHistory
          visible={this.state.historyDrawerVisible}
          onClose={this.closeHistoryDrawer}
          provider_id={this.props.calendarProvider.id}
          provider_name={this.props.calendarProvider.full_name}
        />

        <DeleteSessionModal
          visible={this.state.deleteModalVisible}
          onClose={this.closeDeleteModal}
          data={this.state.deleteModalData}
        />

        <IntakesModal
          visible={this.state.intakesModalVisible}
          onClose={this.closeIntakesModal}
        />
      </>
    );
  }
}
/*
<CreateSessionFromOpening
  visible={this.state.createSessionModalVisible}
  onClose={this.closeCreateSessionModal}
  openItem={this.state.createSessionModalData}
  provider_id={this.props.calendarProvider.id}
  provider_name={this.props.calendarProvider.full_name}
  provider_roles={this.props.calendarProvider.roles}
/>

*/

const mapStateToProps = (state, ownProps) => {
  return {
    active_date: safe(state, 'emr.emr.active_date', moment().format('YYYY-MM-DD')),
    data: safe(state, 'emr.calendar.list.data.sessions', []),
    holidays: safe(state, 'emr.calendar.list.data.holidays', []),
    vacations: safe(state, 'emr.calendar.list.data.vacations', []),
    activeProvider: safe(state, 'shared.domain.activeUser'),
    calendarProvider: safe(state, 'emr.emr.calendarProvider', {}),
    createNoteEvent: safe(state, 'emr.session.createnote', {}),
    isBusy: safe(state, 'emr.calendar.list.processing', false),
    //    holidays: safe(state,'emr.calendar.holidays.data',[]),
    locationList: safe(state, 'shared.location.list.data', []),

    createSessionEvent: safe(state, 'emr.session.create', {}),

    isClinician: isClinician(state),
    isAdmin: isAdmin(state) || isClinicalSupervisor(state),
    intake_duration: safe(state, 'shared.domain.activeUser.intake_duration', 60),
  };
};

export default connect(mapStateToProps, { ...emr_actions, ...shared_actions })(withRouter(CalView));
