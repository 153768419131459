/**
 * useAutoRun.tsx
 * A hook for reports to auto-run on page load.
 */
import { useEffect } from 'react';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';

const useAutoRun = (reportId: number): null => {
  const doRunReport = useContextSelector(InlineContext, (c) => c.doRunReport);

  useEffect(() => {
    if (reportId) {
      doRunReport();
    }
  }, [reportId]);

  return null;
};

export default useAutoRun;
