import React, { Component } from 'react';
import { connect } from 'react-redux';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/display';

class RcopiaReport extends Component {
  componentDidMount() {
    this.props.getRcopiaSSOurl('report'); // Get the URL from RCopia
  }

  render() {
    if (this.props.isSuccess) {
      window.location.href=this.props.url;
    }
    return (<div>Connecting to Dr. First...</div>);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isProcessing: safe(state,'display.rcopia.connecturl.processing'),
    isSuccess: safe(state,'display.rcopia.connecturl.success'),
    url: safe(state,'display.rcopia.connecturl.data.url')
  }
}

export default connect(mapStateToProps,actions)(RcopiaReport);
