import React, { Component } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Button, Input, Row, Col, message, Modal, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import AttachmentUpload from './ProgressNote_/AttachmentUpload';

import * as emr_actions from '../../../../actions/emr';
import * as shared_actions from '../../../../actions/shared';
import { validIndex } from '../../../../helpers/js';
import { safe, isAdmin, isClinician } from '../../../../helpers/reduxSelectors';

class GeneralChartNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noteId: parseInt(this.props.match.params.note_id,10),
      patientId: parseInt(this.props.match.params.patient_id,10),
      enterProviderDialogVisible: false,
      selectedProvider: null
    }
    this.textInput = React.createRef();
  }

  componentDidMount() {
    this.props.getUserList();
    this.props.getAttachments(this.state.noteId);
    if (validIndex(this.state.patientId) && validIndex(this.state.noteId)) {
      if (this.state.noteId !== 0) {
        this.props.noteLoad(this.state.noteId);
      }
    } else {
      this.props.history.push('/emr/patients');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.note_id !== this.props.match.params.note_id) {
      this.setState({
        noteId: parseInt(this.props.match.params.note_id,10)
      },()=>{
        this.props.noteLoad(this.state.noteId);
      });
    }

    if (prevProps.note.load !== this.props.note.load) {
      if (this.props.note.load.success) {
      } else if (this.props.note.error) {
        this.props.noteCreateClear();
        message.error("Error loading note");
        this.props.history.push('/emr/patients/'+this.state.patientId);
      }
    }

    if (prevProps.note.create !== this.props.note.create) {
      if (this.props.note.create.success) {
        this.props.noteCreateClear();
        message.success("General chart note created");
        this.props.history.replace('/emr/patients/'+this.state.patientId+
          '/generalnote/'+this.props.note.create.data.id);
      } if (this.props.note.create.error) {
        this.props.noteCreateClear();
        message.error("Unable to create general chart note.");
        this.props.history.push('/emr/patients/'+this.state.patientId);
      }
    }

    if (prevProps.note.save !== this.props.note.save) {
      if (this.props.note.save.success) {
        this.props.noteSaveClear();
        message.success("General chart note saved")
        this.props.history.push('/emr/patients/'+this.state.patientId);
      } else if (this.props.note.save.error) {
        this.props.noteSaveClear();
        message.error("Unable to save general chart note.")
      }
    }

    if (prevProps.note.sign !== this.props.note.sign) {
      if (this.props.note.sign.success) {
        this.props.noteSignClear();
        this.props.history.push('/emr/patients/'+this.state.patientId);
        message.success("General general chart note signed")
      } else if (this.props.note.sign.error) {
        this.props.noteSignClear();
        message.error("Unable to sign general chart note")
      }
    }

    if (prevProps.note.delete !== this.props.note.delete) {
      if (this.props.note.delete.success) {
        this.props.noteDeleteClear();
        this.props.history.push('/emr/patients/'+this.state.patientId);
        message.success("General chart note deleted")
      } else if (this.props.note.delete.error) {
        this.props.noteDeleteClear();
        message.error("Unable to delete general chart note")
      }
    }

  }

  cancelNote = () => {
    if (this.state.noteId === 0) {
      this.props.history.push('/emr/patients/'+this.state.patientId);
    } else {
      this.props.noteDelete(this.state.noteId);
    }
  }

  showDiscardConfirm = () => {
    let title = 'Are you sure you want to discard this note?';
    const dn = this.cancelNote;
    if (this.props.attachments.length) {
      title = `${title} There's ${this.props.attachments.length} attachment(s) which will be deleted!`;
    }
    Modal.confirm({
      title,
      onOk() {
        dn();
      },
    });
  }

  saveNote = () => {
    const values = this.props.form.getFieldsValue();
    if (this.state.noteId === 0) {
      this.props.noteCreate(this.state.patientId,3,values);
    } else {
      this.props.noteSave(this.state.noteId,values);
    }
  }

  signNote = () => {
    const values = this.props.form.getFieldsValue();

    if (this.state.noteId === 0) {
      this.props.noteCreate(this.state.patientId,3,{
        ...values,
        sign: true
      });
    } else {
      this.props.noteSign(this.state.noteId,values);
    }
  }

  displaySendNote = () => {
    this.setState({
      enterProviderDialogVisible: true,
      selectedProvider: null
    });
  }

  onSelectProvider = (e) => {
    this.setState({
      selectedProvider: e
    });
  }

  onCancelEnterProviderDialog = () => {
    this.setState({
      enterProviderDialogVisible: false,
      selectedProvider: null
    });
  }

  onOkEnterProviderDialog = () => {
    if (this.state.selectedProvider && this.state.noteId) {
      const values = {
        ...(this.props.form.getFieldsValue()),
        provider_id: this.state.selectedProvider
      }
      this.props.noteSave(this.state.noteId,values);
      this.setState({
        enterProviderDialogVisible: false
      });
    }
  }

  changer = (e) => {
    console.log(this.textInput);
  }

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const { data }= this.props.note.load;
    const initialValues = {
      note_date: (data && data.note_date) ? data.note_date : new Date(),
      general_note: (data && data.note.general_note) ? data.note.general_note : undefined
    }

  //  console.log('-->',this.state.noteId,this.props.match.params.note_id);

    const ul = this.props.userList.filter(a => {
      return (a.roles.includes('prescriber') || a.roles.includes('therapist'));
    }).map(a=> {
      return (<Select.Option key={a.id} value={a.id}>{a.full_name}</Select.Option>);
    });

    return (
      <div style={{padding:'15px'}}>
        <h3 style={{paddingTop:'10px',paddingBottom:'20px'}}>General Chart Note</h3>
        <Form layout={'vertical'} autoComplete="off">
          <Row>
            <Col span={8}>
              <FormItem label="Date">
                {getFieldDecorator('note_date', {
                  rules: [{ type: 'object', required: true, message: "Please enter a date for this note", whitespace: true }],
                  initialValue: moment(initialValues.note_date)
                })(
                  <DatePicker format='MM/DD/YYYY' />
                )}
              </FormItem>
            </Col>
          </Row>
          <FormItem label="General Chart Note">
            {getFieldDecorator('general_note', {
              rules: [{ required: true, message: "Enter general note." }],
              initialValue: initialValues.general_note
            })(
              <TextArea rows={15} ref={this.textInput}/>
            )}
          </FormItem>
        </Form>

        <AttachmentUpload patientId={this.state.patientId} noteId={this.state.noteId}/>

        <Row>
          <Col span={12}>
            { (this.state.noteId !== 0) &&
              <Button type="danger" icon={<DeleteOutlined />} onClick={this.showDiscardConfirm}>Discard Note</Button>
            }
          </Col>
          <Col span={12} style={{textAlign:'right'}}>
            <Button style={{marginRight:'40px'}} onClick={this.saveNote}>Save Note</Button>
            {!this.props.isClinician && this.props.isAdmin && <Button type='primary' onClick={this.displaySendNote}>Send Note to Provider</Button> }
            { this.props.isClinician && <Button type="primary" onClick={this.signNote}>Sign Note</Button> }
          </Col>
        </Row>

        <Modal
          title='Choose Provider to Send Note'
          visible={this.state.enterProviderDialogVisible}
          onCancel={this.onCancelEnterProviderDialog}
          width={400}
          footer={[
            <Button key='cancel' onClick={this.onCancelEnterProviderDialog}>Cancel</Button>,
            <Button key='send' type='primary' disabled={!this.state.selectedProvider} onClick={this.onOkEnterProviderDialog}>Send</Button>
          ]}
        >
          <Select
            showSearch
            onSelect={this.onSelectProvider}
            value={this.state.selectedProvider}
            optionFilterProp="children"
            style={{width:'100%'}}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {ul}
          </Select>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    note: safe(state,'emr.note',{}),
    loadSuccess: safe(state,'emr.note.load.success'),
    loadProcessing: safe(state,'emr.note.load.processing'),
    isAdmin: isAdmin(state),
    isClinician: isClinician(state),
    userList: safe(state,'shared.user.list.data',[]),
    attachments: safe(state,'emr.attachments.list.data',[])
  }
}

export default connect(mapStateToProps,{...shared_actions,...emr_actions})(Form.create()(withRouter(GeneralChartNote)));
