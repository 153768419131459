import React, { Component } from 'react';
import { Table, Layout, Spin, Select } from 'antd';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { withRouter, Link } from 'react-router-dom';

import { safe } from '../../../../helpers/reduxSelectors';
import * as billing_actions from '../../../../actions/billing';
import * as shared_actions from '../../../../actions/shared';

class InsArPatient extends Component {

  changePage = ({current}) => {
    this.props.setBillingState({
      ar_current_page_3: current
    });
  }

  changeSort = (x) => {
    this.props.setBillingState({
      ar_page_3_sort: x
    });
  }

  csvDownload = () => {
    this.props.loadInsPtAr(this.props.ar_current_page_3*this.props.pageSize,
      this.props.pageSize,this.props.ar_page_3_sort,true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ar_current_page_3 !== this.props.ar_current_page_3 ||
        prevProps.ar_page_3_sort !== this.props.ar_page_3_sort) {
      this.props.loadInsPtAr(this.props.ar_current_page_3*this.props.pageSize,
        this.props.pageSize,this.props.ar_page_3_sort,false);
    }
  }

  render() {
    const columns=[{
      title: 'Patient',
      dataIndex: 'patient_name',
      render: (value,record) => {
        return <Link to={'/billing/patients/'+record.patient_id}>{value}</Link>
      }
    },{
      title: '31-60 Days',
      dataIndex: 'ar31to60',
      align:'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    },{
      title: '61-90 Days',
      dataIndex: 'ar61to90',
      align:'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    },{
      title: '90 Days+',
      dataIndex: 'arOver90',
      align: 'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    },{
      title: 'Total Balance',
      dataIndex: 'total',
      align:'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    }];

    const { Content } = Layout;

    const count = safe(this.props.data,'count',0);
    const rows = safe(this.props.data,'rows',[]);

    const Option = Select.Option;

    return (
      <Layout>
        <Content style={{backgroundColor:'white'}}>
          <Spin spinning={this.props.isBusy}>
            <div>
              <table style={{marginBottom:'10px',width:'100%'}}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Sort By</strong>: &nbsp;
                      <Select
                        style={{width:'300px'}}
                        value={this.props.ar_page_3_sort}
                        onChange={this.changeSort}>
                        <Option value={0}>Total Balance</Option>
                        <Option value={4}>&gt;90 Days</Option>
                        <Option value={3}>61-90 Days</Option>
                        <Option value={2}>31-60 Days</Option>
                      </Select>
                    </td>
                    <td style={{textAlign:'right'}}>
                      <a href='/api/v1/ar/ins_per_pt?start=1&limit=1&sortcolumn=1&csv=1'>CSV Download</a>
                    </td>
                  </tr>
                </tbody>
              </table>

              <Table
                columns={columns}
                dataSource={rows}
                onChange={this.changePage}
                pagination={{
                  current: this.props.ar_current_page_3,
                  pageSize: this.props.pageSize,
                  total: count,
                  showQuickJumper: true
                }}
                size='small'
                rowKey='patient_id'
              />
            </div>
          </Spin>
        </Content>
      </Layout>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'billing.ar.list3.processing',false) ? true : false,
    data: safe(state,'billing.ar.list3.data',{}),
    ar_current_page_3: safe(state,'shared.domain.billing.ar_current_page_3',0),
    ar_page_3_sort: safe(state,'shared.domain.billing.ar_page_3_sort',0),
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(withRouter(InsArPatient));
