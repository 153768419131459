import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin, Button } from 'antd';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';
import { processEventNotification } from '../../../helpers/util';

const REPORT_SCHEDULED_SESSIONS = 15;

class ReportsLS_scheduled_sessions extends React.Component {

  componentDidUpdate(prevProps) {
    processEventNotification(prevProps,this.props,'addQueue','Report Added to Queue');
  }

  queueReport = () => {
    this.props.queueReport(REPORT_SCHEDULED_SESSIONS,{});
  }

  render() {
    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
          </div>

          <h3>Scheduled Future Sessions</h3>

          <Button onClick={this.queueReport}>Queue Report for Download</Button>

          <p>This report cannot be displayed. It can only be downloaded.</p>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'reports.queue.add.processing',false)
    ) ? true : false,
    addQueue: safe(state,'reports.queue.add',{})
  }
}

export default connect(mapStateToProps,actions)(ReportsLS_scheduled_sessions);
