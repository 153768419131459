import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, Spin } from 'antd';
import { connect } from 'react-redux';

import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/scheduler';

class CoveringProviderModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onUpdate: PropTypes.func,
    data: PropTypes.object
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.getCoverageProviders(safe(this.props,'data.provider.id'));
    }
  }

  handleOk = () => {
    this.props.form.validateFields((err,values) => {
      if (!err) {
        this.props.onUpdate(this.props.data.id,values);
        this.props.onClose();
      }
    });
  }

  renderForm = () => {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };

    const pl = [{
      id: 0,
      full_name: 'No Covering Provider'
    },...this.props.provider_list];

    return (
      <Form className='compressed-form' autoComplete='false'>
        <Form.Item {...formItemLayout} label='Covering Provider'>
          {getFieldDecorator('covering_provider_id',{
            rules: [{required: true, message: 'Select a covering provider'}],
            initialValue: safe(this.props,'data.covering_provider.id',0)
          })(
            <Select>
              { pl.map(x=><Select.Option value={parseInt(x.id,10)} key={x.id}>{x.full_name}</Select.Option>) }
            </Select>
          )}
        </Form.Item>
      </Form>
    );
  }

  render() {


    return (
      <Modal
        title='Update Covering Provider'
        visible={this.props.visible}
        onCancel={this.props.onClose}
        okText='Save'
        width={650}
        onOk={this.handleOk}
      >
        <Spin spinning={this.props.isBusy}>
          {!this.props.isBusy && this.renderForm()}
        </Spin>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    provider_list: safe(state,'scheduler.provider.coverage.data',[]),
    isBusy: safe(state,'scheduler.provider.coverage.processing') ? true : false
  }
}

export default connect(mapStateToProps,actions)(Form.create()(CoveringProviderModal));
