import React from 'react';
import { Radio, Badge, Select } from 'antd';
import { connect } from 'react-redux';

import { safe } from '../../../../../../helpers/reduxSelectors';
import * as actions from '../../../../../../actions/emr';

const { Option } = Select;

class InboxSelector extends React.Component {

  onChangeSelect = (obj) => {
    this.props.setCurrentInbox(JSON.parse(obj))
  }

  onChangeTab = (e) => {
    this.props.setCurrentInbox(JSON.parse(e.target.value))
  }

  shortenName = (name) => {
    const [shortenedName, creds] = name.split(',');
    const [first, last] = shortenedName.split(' ');
    const firstInitial = first[0];
    const lastInitial = last[0];
    return `${firstInitial}.${lastInitial}., ${creds}`;
  }

  render() {
    let counter = 0;
    const shortNames = this.props.inboxes.length > 2;
    const currentInboxIdx = this.props.inboxes.findIndex((i) => i.member.user_id === this.props.currentInbox.user_id);
    
    if (this.props.inboxes.length > 2) {
      return (
        <Select
          style={{width:'300px'}}
          value={JSON.stringify(this.props.currentInbox)}
          onChange={this.onChangeSelect}>
          {this.props.inboxes.map((x, key) => {
            return <Option value={JSON.stringify(x.member)} key={counter++}>{x.member.name}</Option>;
          })}
        </Select>
      );
    } else {
      return (
        <Radio.Group
          onChange={this.onChangeTab}
          buttonStyle="solid"
          value={JSON.stringify(this.props.currentInbox)}
        >
          {this.props.inboxes.map((x, key)=>{
            return (
            <Radio.Button key={counter++} value={JSON.stringify(x.member)}>
              <Badge dot={x.data.total_unread > 0}>
                {shortNames && currentInboxIdx !== key ? this.shortenName(x.member.name) : x.member.name}&nbsp;
              </Badge>
            </Radio.Button>
          ) })}
        </Radio.Group>
      );
    }
  }
}

const mapStateToProps = (state,ownProps) => {
  return {
    inboxes: safe(state,'emr.inbox.list.data',[]),
    currentInbox: safe(state,'emr.inbox.current')
  }
}
// .map(x=>x.member)
export default connect(mapStateToProps,actions)(InboxSelector);
