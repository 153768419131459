import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Input } from 'antd';
import { connect } from 'react-redux';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/pm';
import { processEventNotification } from '../../../helpers/util';

class FeeScheduleGroupAddEditModal extends React.Component {
  static propTypes = {
    data: PropTypes.object
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.props.form.resetFields();
    }
    processEventNotification(prevProps,this.props,'fs_create_event','Fee Schedule Group Created');
    processEventNotification(prevProps,this.props,'fs_create_update','Fee Schedule Group Changed');
    processEventNotification(prevProps,this.props,'fs_create_remove','Fee Schedule Group Removed');
  }

  handleOk = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const group_id = safe(this.props.data,'id');
        if (!group_id) {
          this.props.feeScheduleGroupCreate(values);
        } else {
          this.props.feeScheduleGroupUpdate(group_id,{
            name: values.name
          });
        }
        this.props.closeModal();
      }
    });
  }

  handleDelete = () => {
    const id = safe(this.props.data,'id');
    const This = this;

    Modal.confirm({
      title: 'Remove this fee schedule group?',
      onOk() {
        This.props.feeScheduleGroupRemove(id);
        This.props.closeModal();
      },
      onCancel() { }
    });
  }

  getTitle = () => {
    return (this.props.data) ? 'Edit Fee Schedule Group' : 'Add Fee Schedule Group';
  }

  getFooter = () => {
    const id = safe(this.props.data,'id');
    if (id) {
      return [
        <Button key={1} onClick={this.handleDelete} style={{float:'left'}} type='danger'>Delete</Button>,
        <Button key={3} onClick={this.handleOk} type='primary'>Save</Button>
      ];
    } else {
      return [
        <Button key={3} onClick={this.handleOk} type='primary'>Save</Button>
      ]
    }
  }

  getGroupName = () => {
    return safe(this.props.data,'name','');
  }

  formContents = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      <Form autoComplete="off" >
        <FormItem {...formItemLayout} label="Group Name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: " ", whitespace: true }],
            initialValue: this.getGroupName()
          })(
            <Input />
          )}
        </FormItem>
      </Form>
    );
  }

  render() {
    return (
      <Modal
        title={this.getTitle()}
        visible={this.props.visible}
        footer={this.getFooter()}
        onCancel={this.props.closeModal}
      >
        {this.formContents()}
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    fs_create_event: safe(state,'pm.feeschedule.group.create',{}),
    fs_update_event: safe(state,'pm.feeschedule.group.update',{}),
    fs_remove_event: safe(state,'pm.feeschedule.group.remove',{})
  }
}

export default connect(mapStateToProps,actions)(Form.create()(FeeScheduleGroupAddEditModal));
