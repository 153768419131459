import React from 'react';
import { Link, withRouter } from 'react-router-dom';

class RecentList extends React.Component {

  getTypeName = (type) => {
    switch(parseInt(type,10)) {
      case 1: return 'PAT';
      case 2: return 'ERA';
      case 3: return 'PAY';
      default: return '---';
    }
  }

  getItemUrl = (x) => {
    switch(parseInt(x.type,10)) {
      case 1: return '/billing/patients/'+x.ref_id;
      case 2: return '/billing/era/reconcile/'+x.ref_id;
      case 3: return '/billing/payments/1/'+x.ref_id;
      default: return '#';
    }
  }

  render() {
    let counter = 1;
    return (
      <div>
        {this.props.data.map(x=>{
            return (
              <div key={counter++}>
                <Link to={ this.getItemUrl(x) }>
                  { this.getTypeName(x.type) }:
                  &nbsp;
                  { x.label }
                </Link>
              </div>
            )
         })}
      </div>
    );
  }
}


export default withRouter(RecentList);
