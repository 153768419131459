/**
 * OptumCollections.tsx
 */
import reports from 'reports';
import { DateTime, Info } from 'luxon';
import { useContextSelector } from 'use-context-selector';
import InlineContext from 'components/App_/ReportsV2_/InlineContext';
import InlineFormWrapper from 'components/App_/ReportsV2_/InlineFormWrapper';
import ReportWrapper from 'components/App_/ReportsV2_/ReportWrapper';
import CSVDownload from '../InlineControls_/CSVDownload';
import { InlineDataT } from 'reports';
import useAutoRun from 'components/App_/ReportsV2_/useAutoRun';
import { Table } from 'antd';
import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';
import numeral from 'numeral';

const { ColumnGroup, Column } = Table;

const reportId = 34;

const OptumCollections: React.FC = () => {
  const inlineData = useContextSelector(InlineContext, (c) => c.state.inlineData) as InlineDataT;
  const report = reports.find((r) => r.id === reportId);

  useAutoRun(reportId);

  const formOptions = { reportId, month: DateTime.now().month };

  const currentMonth = DateTime.now().month;
  let idx = 1;
  const columns: Array<ColumnI> = [];
  for (const _month of Info.months()) {
    if (idx > currentMonth) {
      break;
    }
    const month = String(idx).padStart(2, '0');
    columns.push({
      title: `2022-${month}`,
      dataIndex: `2022-${month}`,
      render: (value) => numeral(value).format(`$0,0.00`),
    });
    idx += 1;
  }

  return (
    <ReportWrapper>
      <h3>{report?.name}</h3>
      <InlineFormWrapper {...formOptions}>
        <CSVDownload prefix={report?.csvPrefix} />
        </InlineFormWrapper>
      <Table dataSource={inlineData} pagination={false} size="small">
        <Column title="Service Month" dataIndex="Service Month" />
        <ColumnGroup title="Deposit Month">
          {columns.map((c) => (<Column title={c.title} dataIndex={c.dataIndex} />))}
        </ColumnGroup>
      </Table>
    </ReportWrapper>
  );
};

export default OptumCollections;
