import React from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/intakes';
import moment from 'moment';
import numeral from 'numeral';

import OppositeEnds from '../../shared/OppositeEnds';

class Registrations extends React.Component {
  state = {
    data: []
  }

  componentDidMount() {
    this.props.getRegistrationList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load_success !== this.props.load_success && this.props.load_success) {
      this.setState({
        data: this.processData(this.props.original_data)
      });
    }
  }

  processData = (data) => {
    if (Array.isArray(data)) {
      const results = {};
      data.forEach(x=>{
        const date = moment(x.createdAt).format('MM/DD/YYYY');
        if (!results[date]) {
          results[date] = {
            date: date,
            total_started: 0,
            total_completed: 0,
            total_scheduled_atreg: 0,
            total_scheduled_todate: 0,
            details: []
          };
        }
        results[date].total_started++;
        results[date].total_completed += (x.complete) ? 1 : 0;
        results[date].total_scheduled_atreg += (x.scheduled_atreg) ? 1 : 0;
        results[date].total_scheduled_todate += (x.scheduled_todate) ? 1 : 0;
        results[date].details.push(x);
      });
      return Object.values(results);
    }
    return [];
  }

  displayDetails(data) {
    const columns = [{
      title: 'Time',
      dataIndex: 'createdAt',
      key: 2,
      render: (val) => {
        if (val) {
          const m = moment(val);
          if (m.isValid()) {
            return m.format('h:mm A')
          }
        }
        return null;
      }
    },{
      title: 'Completed',
      dataIndex: 'complete',
      key: 3,
      render: (val) => {
        if (val) {
          const m = moment(val);
          if (m.isValid()) {
            return m.format('MM/DD/YY HH:mm:ss')
          }
        }
        return 'No';
      }
    },{
      title: 'Portal Account Email',
      dataIndex: 'portal_email',
      key: 4
    },{
      title: 'Name',
      dataIndex: 'portal_firstname',
      key: 5,
      render: (val,record) => {
        return record.portal_firstname + ' ' + record.portal_lastname
      }
    },{
      title: 'Email Verified',
      dataIndex: 'portal_email_verified',
      render: (val) => {
        return (val) ? 'Verified' : null;
      }
    },{
      title: 'Patient Chart ID',
      dataIndex: 'patient_id',
      render: (val) => {
        return (val) ? val : '--';
      }
    },{
      title: 'Scheduled At Reg',
      dataIndex: 'scheduled_atreg',
      render: (val) => {
        return (val) ? 'Yes' : 'No';
      }
    },{
      title: 'Scheduled To Date',
      dataIndex: 'scheduled_todate',
      render: (val) => {
        return (val) ? 'Yes' : 'No';
      }
    }];

    return (
      <div style={{padding:'10px'}}>
        <Table
          dataSource={safe(data,'details',[])}
          columns={columns}
          size='small'
          rowKey='id'
          pagination={false}
        />
      </div>
    );
  }

  csvDownload = () => {

  }

  render() {
    const columns = [{
      title: 'Date',
      dataIndex: 'date'
    },{
      title: '# Registrations',
      dataIndex: 'total_started'
    },{
      title: '# Completed',
      dataIndex: 'total_completed'
    },{
      title: '# Scheduled At Reg',
      dataIndex: 'total_scheduled_atreg'
    },{
      title: '# Scheduled To Date',
      dataIndex: 'total_scheduled_todate'
    },{
      title: 'Conversion Rate',
      render: (value,record) => {
        if (record.total_scheduled_todate > 0) {
          return numeral(record.total_scheduled_todate/record.total_started).format('0.0%')
        }
        return null;
      }
    }];

    return (
      <div style={{padding:'10px'}}>
        <OppositeEnds
          left={<h3>Registrations</h3>}
          right={<a href='/api/v1/registrations?csv=1'>CSV Download</a>}
        />
        <Table
          dataSource={this.state.data}
          columns={columns}
          size='small'
          rowKey='date'
          expandedRowRender={this.displayDetails}
          pagination={false}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    load_success: safe(state,'intakes.reg_list.success'),
    original_data: safe(state,'intakes.reg_list.data',[])
  }
}

export default connect(mapStateToProps,actions)(Registrations);
