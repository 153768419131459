import { useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import { PostReturnI } from './definitions';

const useApiPost = (url: string): PostReturnI => {
  const [value, setValue] = useState();
  const { loading, error, post, data } = useFetch({ cachePolicy: CachePolicies.NO_CACHE });

  const send = async (sendData) => {
    const resp = await post(url, sendData);
    if (!error) {
      setValue(resp);
    }
    return resp;
  };

  if (error) {
    return {
      send,
      data: value,
      loading,
      error: data,
    };
  }
  return {
    send,
    data: value,
    loading,
  };
};

export default useApiPost;
