import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, DatePicker, Select, Checkbox, Radio } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import * as actions from '../../../../../actions/scheduler';
import { safe } from '../../../../../helpers/reduxSelectors';
import { processEventNotification } from '../../../../../helpers/util';
import Time from '../../../../shared/Time';

const MODAL_CREATE = 1;
const MODAL_EDIT = 2;
const MODAL_COPY = 3;

class TemplateItem extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    modal_type: PropTypes.number.isRequired,
    provider_id: PropTypes.number,
    template_id: PropTypes.number,
    template_item_id: PropTypes.number,
    template_item_data: PropTypes.object,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.form.resetFields();
      this.props.getProviderLocations(this.props.provider_id);
    }
    processEventNotification(prevProps,this.props,'createTemplateItemEvent','Template Item Created');
    processEventNotification(prevProps,this.props,'updateTemplateItemEvent','Template Item Updated');
    processEventNotification(prevProps,this.props,'removeTemplateItemEvent','Template Item Delete');
  }

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        switch (this.props.modal_type) {
          case MODAL_CREATE:
            this.props.createTemplateItem(this.props.provider_id,
              this.props.template_id,values);
            break;

          case MODAL_EDIT:
            this.props.updateTemplateItem(this.props.provider_id,
              this.props.template_id,this.props.template_item_id,values);
            break;

          case MODAL_COPY:
            this.props.createTemplateItem(this.props.provider_id,
              this.props.template_id,values);
            break;

          default:
            break;
        }
        this.props.onClose();
      }
    });
  }

  isIntakeDurationOK = (provider_id) => {
    const p = this.props.providerList.find(x=>x.id===provider_id);
    if (!p) {
      return false;
    }
    const providerRole = p.roles;
    try {
      const st = new Time(this.props.form.getFieldValue('start_time'));
      const et = new Time(this.props.form.getFieldValue('end_time'));
      const d = st.diff(et);

      if (providerRole && providerRole.includes('therapist')) {
        if (d >= 45) {
          return true;
        }
      } else if (providerRole && providerRole.includes('prescriber')) {
        if (d >= 60) {
          return true
        }
      }
    } catch (err) {
    }
    return false;
  }

  isDurationOK = (duration,provider_id) => {
    const p = this.props.providerList.find(x=>x.id===provider_id);
    if (!p) {
      return false;
    }
    const providerRole = p.roles;
    try {
      const st = new Time(this.props.form.getFieldValue('start_time'));
      const et = new Time(this.props.form.getFieldValue('end_time'));
      const d = st.diff(et);

      if (providerRole && providerRole.includes('therapist')) {
        if (d >= 30 && duration === 30 && p.employment_type === 2) {
          return true;
        }

        if (d >= 45 && duration === 45 && p.employment_type === 2) {
          return true;
        }
        if (d >= 60 && duration === 60) {
          return true;
        }
        return false;
      } else if (providerRole && providerRole.includes('prescriber')) {
        if (d >= 20 && duration === 20) {
          return true;
        }
        if (d >= 30 && duration === 30) {
          return true;
        }
        if (d >= 45 && duration === 45) {
          return true;
        }
        if (d >= 60 && duration === 60) {
          return true;
        }
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
      },
    };

    const timeRange = Time.range(new Time(600),new Time(2200),5);

    return (
      <Form className='compressed-form' autoComplete='false'>

        <FormItem {...formItemLayout} label="Type">
          {getFieldDecorator('type', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'template_item_data.type',1)
          })(
            <Radio.Group disabled={this.props.modal_type !== MODAL_CREATE}>
              <Radio value={1}>Recurring</Radio>
              <Radio value={2}>One-Time</Radio>
              <Radio value={3}>Extra Recurring</Radio>
            </Radio.Group>
          )}
        </FormItem>

        {(this.props.form.getFieldValue('type') === 1 || this.props.form.getFieldValue('type') === 3)
         && <FormItem {...formItemLayout} label="Day of Week">
          {getFieldDecorator('day_of_week', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'template_item_data.day_of_week')
          })(
            <Select>
              <Option value={1}>Mon</Option>
              <Option value={2}>Tue</Option>
              <Option value={3}>Wed</Option>
              <Option value={4}>Thu</Option>
              <Option value={5}>Fri</Option>
              <Option value={6}>Sat</Option>
              <Option value={0}>Sun</Option>
            </Select>
          )}
        </FormItem> }

        {this.props.form.getFieldValue('type') === 2 && <FormItem {...formItemLayout} label="Date">
          {getFieldDecorator('fixed_date', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'template_item_data.fixed_date') ? moment(safe(this.props,'template_item_data.fixed_date')) : undefined
          })(
            <DatePicker format='M/D/YYYY'/>
          )}
        </FormItem> }

        <FormItem {...formItemLayout} label="Starting Time">
          {getFieldDecorator('start_time', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'template_item_data.start_time')
          })(
            <Select>
              {timeRange.map(x=><Option key={x.id} value={x.id}>{x.name}</Option>)}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Ending Time">
          {getFieldDecorator('end_time', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'template_item_data.end_time'),
          })(
            <Select>
              {timeRange.map(x=><Option key={x.id} value={x.id}>{x.name}</Option>)}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Allow 20 min">
          {getFieldDecorator('allow_20m', {
            valuePropName: 'checked',
            initialValue: safe(this.props,'template_item_data.allow_20m')
          })(
            <Checkbox disabled={!this.isDurationOK(20,this.props.provider_id)} />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Allow 30 min">
          {getFieldDecorator('allow_30m', {
            valuePropName: 'checked',
            initialValue: safe(this.props,'template_item_data.allow_30m')
          })(
            <Checkbox disabled={!this.isDurationOK(30,this.props.provider_id)} />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Allow 45 min">
          {getFieldDecorator('allow_45m', {
            valuePropName: 'checked',
            initialValue: safe(this.props,'template_item_data.allow_45m'),
          })(
            <Checkbox disabled={!this.isDurationOK(45,this.props.provider_id)} />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Allow 60 min">
          {getFieldDecorator('allow_60m', {
            valuePropName: 'checked',
            initialValue: safe(this.props,'template_item_data.allow_60m')
          })(
            <Checkbox disabled={!this.isDurationOK(60,this.props.provider_id)} />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Allow Intakes">
          {getFieldDecorator('allow_intake', {
            valuePropName: 'checked',
            initialValue: safe(this.props,'template_item_data.allow_intake')
          })(
            <Checkbox disabled={!this.isIntakeDurationOK(this.props.provider_id)} />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Age">
          {getFieldDecorator('age_category', {
            initialValue: safe(this.props,'template_item_data.age_category',0)
          })(
            <Select>
              <Option value={0}>Adult/Child</Option>
              <Option value={1}>Adult Only</Option>
              <Option value={2}>Child Only (&lt; 18)</Option>
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="TeleMedicine">
          {getFieldDecorator('allow_video', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'template_item_data.allow_video',0),
          })(
            <Select>
              <Option value={0}>Video/Office</Option>
              <Option value={1}>Video Only</Option>
              <Option value={2}>Office Only</Option>
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Location">
          {getFieldDecorator('location_id', {
            rules: [{ required: true, message: " " }],
            initialValue: safe(this.props,'template_item_data.location_id')
          })(
            <Select>
              {this.props.locationsList.map(x=><Select.Option value={x.id} key={x.id}>{x.name}</Select.Option>)}
            </Select>
          )}
        </FormItem>
      </Form>
    );
  }

  getModalTitle = () => {
    switch (this.props.modal_type) {
      case MODAL_CREATE: return 'Create Schedule Template Item';
      case MODAL_EDIT: return 'Edit Schedule Template Item';
      case MODAL_COPY: return 'Copy Schedule Template Item';
      default: return 'Schedule Template Item';
    }
  }

  render() {
    return (
      <Modal
        title={this.getModalTitle()}
        visible={this.props.visible}
        onCancel={this.props.onClose}
        onOk={this.handleOk}
        okText={(this.props.modal_type === MODAL_EDIT) ? 'Save' : 'Create'}
      >
        {this.renderForm()}
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    createTemplateItemEvent: safe(state,'scheduler.template_item.create',{}),
    updateTemplateItemEvent: safe(state,'scheduler.template_item.update',{}),
    removeTemplateItemEvent: safe(state,'scheduler.template_item.remove',{}),
    locationsList: safe(state,'scheduler.provider.locations.data',[]),
    providerList: safe(state,'scheduler.provider.list.data',[]),
  }
}

export default connect(mapStateToProps,actions)(Form.create()(TemplateItem));
