import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Modal, Tabs, message, Spin } from 'antd';
import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/billing';

class RecallList extends Component {

  componentDidMount() {
    this.props.getFullRecallLists();
  }

  onClickAddRecallList = () => {
    this.props.history.push('/settings/recalllist/0');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deactivate !== this.props.deactivate) {
      if (this.props.deactivate.success) {
        message.success("Recall List Deactivated");
        this.props.deactivateRecallListClear();
      }
      if (this.props.deactivate.error) {
        message.error(safe(this.props,'deactivate.error.data.message'));
        this.props.deactivateRecallListClear();
      }
    }

    if (prevProps.reactivate !== this.props.reactivate) {
      if (this.props.reactivate.success) {
        message.success("Recall List Reactivated");
        this.props.reactivateRecallListClear();
      }
      if (this.props.reactivate.error) {
        message.error(safe(this.props,'reactivate.error.data.message'));
        this.props.reactivateRecallListClear();
      }
    }
  }

  deactivate = (record) => {
    const confirm = Modal.confirm;
    const du = this.props.deactivateRecallList;

    confirm({
      title: 'Deactivate recall list: ' + record.name + '?',
      onOk() { du(record.id) },
      onCancel() { }
    });
  }

  reactivate = (record) => {
    const confirm = Modal.confirm;
    const ru = this.props.reactivateRecallList;

    confirm({
      title: 'Reactivate recall list: ' + record.name + '?',
      onOk() { ru(record.id) },
      onCancel() { }
    });
  }

  renderActiveTable = () => {
    const { Column } = Table;

    return (
      <Table
        dataSource={this.props.recallList.filter(item => item.active === true)}
        rowKey="id"
        size='small'
        pagination={false}
      >
        <Column
          title="Name"
          dataIndex="name"
          key="name"
          defaultSortOrder='ascend'
          sorter={(a, b) => a.name.localeCompare(b.name)}
          render={(text,record) => (
            <Link to={"/settings/recalllist/"+record.id} style={{whiteSpace: 'nowrap'}}>{text}</Link>
          )}
        />

        <Column
          title="Action"
          key="action"
          width={100}
          render={(text, record, index) => (
            <span>
              <a onClick={() => { this.deactivate(record) }}>Deactivate</a>
            </span>
          )}
        />
      </Table>
    );
  }

  renderInactiveTable = () => {
    const { Column } = Table;

    return (
      <Table
        dataSource={this.props.recallList.filter(item => item.active === false)}
        rowKey="id"
        size='small'
        pagination={false}
      >
        <Column
          title="Name"
          dataIndex="name"
          key="name"
          defaultSortOrder='ascend'
          sorter={(a, b) => a.name.localeCompare(b.name)}
        />

        <Column
          title="Deactivated"
          dataIndex="deactivatedAt"
          key="deactivatedAt"
          render={(text,record) => (
            (new Date(text)).toLocaleString()
          )}
        />

        <Column
          title="Action"
          key="action"
          width={100}
          render={(text, record) => (
            <span>
              <a onClick={() => { this.reactivate(record) }}>Reactivate</a>
            </span>
          )}
        />
      </Table>
    );
  }

  render() {
    const TabPane = Tabs.TabPane;

    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <h3>Recall Lists</h3>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Active" key="1">
              { this.renderActiveTable() }
            </TabPane>
            <TabPane tab="Inactive" key="2">
              { this.renderInactiveTable() }
            </TabPane>
          </Tabs>
          <div style={{position:'fixed',bottom:40,right:40}}>
            <Button
              onClick={this.onClickAddRecallList}
              disabled={this.props.isBusy}
              size='large'
              shape='circle'
              type="primary"
              icon={<PlusOutlined />}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'billing.recall.lists_listfull.processing',false) ||
      safe(state,'billing.recall.lists_reactivate.processing',false) ||
      safe(state,'billing.recall.lists_deactivate.processing',false)
    ) ? true : false,

    recallList: safe(state,'billing.recall.lists_listfull.data',[]),
    deactivate: safe(state,'billing.recall.lists_deactivate',{}),
    reactivate: safe(state,'billing.recall.lists_reactivate',{})
  }
}

export default connect(mapStateToProps,actions)(RecallList);
