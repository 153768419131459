import { combineReducers } from 'redux';

import billingReducer from './billing';
import emrReducer from './emr';
import reportsReducer from './reports';
import sharedReducer from './shared';
import displayReducer from './display';
import intakesReducer from './intakes';
import settingsReducer from './settings';
import schedulerReducer from './scheduler';
import pmReducer from './pm';

export default combineReducers({
  billing: billingReducer,
  emr: emrReducer,
  reports: reportsReducer,
  shared: sharedReducer,
  display: displayReducer,
  intakes: intakesReducer,
  settings: settingsReducer,
  scheduler: schedulerReducer,
  pm: pmReducer
});
