import ColumnI from 'components/App_/ReportsV2_/TableColumns_/column';

const columns: Array<ColumnI> = [
  {
    title: 'User',
    dataIndex: 'creator',
  },
  {
    title: 'Payments Posted',
    dataIndex: 'total',
  },
];

export default columns;
