import * as types from '../../actions/billing/types';

const defaultState = {
  list: {},
  create: {},
  delete: {},
  setdefault: {},
  stripepk: {}
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case types.CC_LIST_REQUEST:
      return {
        ...state,
        list: {
          processing: true,
        }
      };
    case types.CC_LIST_SUCCESS:
      return {
        ...state,
        list: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.CC_LIST_FAILURE:
      return {
        ...state,
        list: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.CC_LIST_CLEAR:
      return {
        ...state,
        list: {}
      };

    case types.CC_CREATE_REQUEST:
      return {
        ...state,
        create: {
          processing: true,
        }
      };
    case types.CC_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.CC_CREATE_FAILURE:
      return {
        ...state,
        create: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.CC_CREATE_CLEAR:
      return {
        ...state,
        create: {}
      };

    case types.CC_DELETE_REQUEST:
      return {
        ...state,
        delete: {
          processing: true,
        }
      };
    case types.CC_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.CC_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.CC_DELETE_CLEAR:
      return {
        ...state,
        delete: {}
      };

    case types.CC_SETDEFAULT_REQUEST:
      return {
        ...state,
        setdefault: {
          processing: true,
        }
      };
    case types.CC_SETDEFAULT_SUCCESS:
      return {
        ...state,
        setdefault: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.CC_SETDEFAULT_FAILURE:
      return {
        ...state,
        setdefault: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.CC_SETDEFAULT_CLEAR:
      return {
        ...state,
        setdefault: {}
      };

    case types.CC_GETSTRIPEPK_REQUEST:
      return {
        ...state,
        stripepk: {
          processing: true,
        }
      };
    case types.CC_GETSTRIPEPK_SUCCESS:
      return {
        ...state,
        stripepk: {
          processing: false,
          success: true,
          data: action.payload
        }
      };
    case types.CC_GETSTRIPEPK_FAILURE:
      return {
        ...state,
        stripepk: {
          processing: false,
          success: false,
          error: action.error
        }
      };
    case types.CC_GETSTRIPEPK_CLEAR:
      return {
        ...state,
        stripepk: {}
      };

    default:
      return state;
  }
}
