import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/shared';

class PatientHeader extends React.Component {
  static propTypes = {
    patient_id: PropTypes.number
  }

  componentDidMount() {
    this.props.loadPatient(this.props.patient_id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.patient_id !== this.props.patient_id) {
      this.props.loadPatient(this.props.patient_id);
    }
  }

  getPatientName = () => {
    return safe(this.props.data,'full_name','')
  }

  getPatientDob = () => {
    const dob = safe(this.props.data,'dob');
    if (dob) {
      const m = moment(dob);
      if (m.isValid()) {
        return '(' + m.format('MM/DD/YYYY') + ')';
      }
    }
    return null;
  }

  getArchived = () => {
    const chart_open = safe(this.props.data,'open',true);
    return (chart_open) ? null : <Tag color='orange'>Archived</Tag>;
  }

  getOnHold = () => {
    const on_hold = safe(this.props.data,'on_hold',false);
    return (on_hold) ? <Tag color='red'>On Hold</Tag> : null;
  }

  render() {
    return (
      <div style={{borderBottom:'1px solid lightgray',paddingBottom:'10px',marginBottom:'20px'}}>
        <span style={{fontSize:'24px'}}>{ this.getPatientName() }</span>
        &nbsp;
        { this.getPatientDob() }
        &nbsp;
        { this.getArchived() }
        { this.getOnHold() }
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    is_busy: safe(state,'shared.patient.load.processing',false),
    data: safe(state,'shared.patient.load.data',{}),
  }
}

export default connect(mapStateToProps,actions)(PatientHeader);
