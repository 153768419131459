export const BILLING_EMAILSTATEMENTS_LIST_REQUEST = 'BILLING_EMAILSTATEMENTS_LIST_REQUEST';
export const BILLING_EMAILSTATEMENTS_LIST_SUCCESS = 'BILLING_EMAILSTATEMENTS_LIST_SUCCESS';
export const BILLING_EMAILSTATEMENTS_LIST_FAILURE = 'BILLING_EMAILSTATEMENTS_LIST_FAILURE';
export const BILLING_EMAILSTATEMENTS_LIST_CLEAR = 'BILLING_EMAILSTATEMENTS_LIST_CLEAR';

export const PAYMENT_DETAIL_REQUEST = 'PAYMENT_DETAIL_REQUEST';
export const PAYMENT_DETAIL_SUCCESS = 'PAYMENT_DETAIL_SUCCESS';
export const PAYMENT_DETAIL_FAILURE = 'PAYMENT_DETAIL_FAILURE';
export const PAYMENT_DETAIL_CLEAR = 'PAYMENT_DETAIL_CLEAR';

export const CLAIMS_LIST_REQUEST = 'CLAIMS_LIST_REQUEST';
export const CLAIMS_LIST_SUCCESS = 'CLAIMS_LIST_SUCCESS';
export const CLAIMS_LIST_FAILURE = 'CLAIMS_LIST_FAILURE';
export const CLAIMS_LIST_CLEAR = 'CLAIMS_LIST_CLEAR';
export const CLAIMS_TAG_REQUEST = 'CLAIMS_TAG_REQUEST';
export const CLAIMS_TAG_SUCCESS = 'CLAIMS_TAG_SUCCESS';
export const CLAIMS_TAG_FAILURE = 'CLAIMS_TAG_FAILURE';
export const CLAIMS_TAG_CLEAR = 'CLAIMS_TAG_CLEAR';

export const CLAIMS_SEARCH_REQUEST = 'CLAIMS_SEARCH_REQUEST';
export const CLAIMS_SEARCH_SUCCESS = 'CLAIMS_SEARCH_SUCCESS';
export const CLAIMS_SEARCH_FAILURE = 'CLAIMS_SEARCH_FAILURE';
export const CLAIMS_SEARCH_CLEAR = 'CLAIMS_SEARCH_CLEAR';

export const BILLING_AUTOPAYLIST_REQUEST = 'BILLING_AUTOPAYLIST_REQUEST';
export const BILLING_AUTOPAYLIST_SUCCESS = 'BILLING_AUTOPAYLIST_SUCCESS';
export const BILLING_AUTOPAYLIST_FAILURE = 'BILLING_AUTOPAYLIST_FAILURE';
export const BILLING_AUTOPAYLIST_CLEAR = 'BILLING_AUTOPAYLIST_CLEAR';

export const BILLING_PTAPPLYLIST_LIST_REQUEST = 'BILLING_PTAPPLYLIST_LIST_REQUEST';
export const BILLING_PTAPPLYLIST_LIST_SUCCESS = 'BILLING_PTAPPLYLIST_LIST_SUCCESS';
export const BILLING_PTAPPLYLIST_LIST_FAILURE = 'BILLING_PTAPPLYLIST_LIST_FAILURE';
export const BILLING_PTAPPLYLIST_LIST_CLEAR = 'BILLING_PTAPPLYLIST_LIST_CLEAR';
export const BILLING_PTAPPLYLIST_APPLY_REQUEST = 'BILLING_PTAPPLYLIST_APPLY_REQUEST';
export const BILLING_PTAPPLYLIST_APPLY_SUCCESS = 'BILLING_PTAPPLYLIST_APPLY_SUCCESS';
export const BILLING_PTAPPLYLIST_APPLY_FAILURE = 'BILLING_PTAPPLYLIST_APPLY_FAILURE';
export const BILLING_PTAPPLYLIST_APPLY_CLEAR = 'BILLING_PTAPPLYLIST_APPLY_CLEAR';

export const AUTHORIZATION_LIST_REQUEST = 'AUTHORIZATION_LIST_REQUEST';
export const AUTHORIZATION_LIST_SUCCESS = 'AUTHORIZATION_LIST_SUCCESS';
export const AUTHORIZATION_LIST_FAILURE = 'AUTHORIZATION_LIST_FAILURE';
export const AUTHORIZATION_LIST_CLEAR = 'AUTHORIZATION_LIST_CLEAR';
export const AUTHORIZATION_UPDATE_REQUEST = 'AUTHORIZATION_UPDATE_REQUEST';
export const AUTHORIZATION_UPDATE_SUCCESS = 'AUTHORIZATION_UPDATE_SUCCESS';
export const AUTHORIZATION_UPDATE_FAILURE = 'AUTHORIZATION_UPDATE_FAILURE';
export const AUTHORIZATION_UPDATE_CLEAR = 'AUTHORIZATION_UPDATE_CLEAR';
export const AUTHORIZATION_CREATE_REQUEST = 'AUTHORIZATION_CREATE_REQUEST';
export const AUTHORIZATION_CREATE_SUCCESS = 'AUTHORIZATION_CREATE_SUCCESS';
export const AUTHORIZATION_CREATE_FAILURE = 'AUTHORIZATION_CREATE_FAILURE';
export const AUTHORIZATION_CREATE_CLEAR = 'AUTHORIZATION_CREATE_CLEAR';
export const AUTHORIZATION_DELETE_REQUEST = 'AUTHORIZATION_DELETE_REQUEST';
export const AUTHORIZATION_DELETE_SUCCESS = 'AUTHORIZATION_DELETE_SUCCESS';
export const AUTHORIZATION_DELETE_FAILURE = 'AUTHORIZATION_DELETE_FAILURE';
export const AUTHORIZATION_DELETE_CLEAR = 'AUTHORIZATION_DELETE_CLEAR';

export const BILLING_MESSAGES_THREADLIST_REQUEST = 'BILLING_MESSAGES_THREADLIST_REQUEST';
export const BILLING_MESSAGES_THREADLIST_SUCCESS = 'BILLING_MESSAGES_THREADLIST_SUCCESS';
export const BILLING_MESSAGES_THREADLIST_FAILURE = 'BILLING_MESSAGES_THREADLIST_FAILURE';
export const BILLING_MESSAGES_THREADLIST_CLEAR = 'BILLING_MESSAGES_THREADLIST_CLEAR';
export const BILLING_MESSAGES_LIST_REQUEST = 'BILLING_MESSAGES_LIST_REQUEST';
export const BILLING_MESSAGES_LIST_SUCCESS = 'BILLING_MESSAGES_LIST_SUCCESS';
export const BILLING_MESSAGES_LIST_FAILURE = 'BILLING_MESSAGES_LIST_FAILURE';
export const BILLING_MESSAGES_LIST_CLEAR = 'BILLING_MESSAGES_LIST_CLEAR';

export const BILLING_RECENT_LIST_REQUEST = 'BILLING_RECENT_LIST_REQUEST';
export const BILLING_RECENT_LIST_SUCCESS = 'BILLING_RECENT_LIST_SUCCESS';
export const BILLING_RECENT_LIST_FAILURE = 'BILLING_RECENT_LIST_FAILURE';
export const BILLING_RECENT_LIST_CLEAR = 'BILLING_RECENT_LIST_CLEAR';

export const BILLING_RECENT_UPDATE_REQUEST = 'BILLING_RECENT_UPDATE_REQUEST';
export const BILLING_RECENT_UPDATE_SUCCESS = 'BILLING_RECENT_UPDATE_SUCCESS';
export const BILLING_RECENT_UPDATE_FAILURE = 'BILLING_RECENT_UPDATE_FAILURE';
export const BILLING_RECENT_UPDATE_CLEAR = 'BILLING_RECENT_UPDATE_CLEAR';

export const CC_GETSTRIPEPK_REQUEST = 'CC_GETSTRIPEPK_REQUEST';
export const CC_GETSTRIPEPK_SUCCESS = 'CC_GETSTRIPEPK_SUCCESS';
export const CC_GETSTRIPEPK_FAILURE = 'CC_GETSTRIPEPK_FAILURE';
export const CC_GETSTRIPEPK_CLEAR= 'CC_GETSTRIPEPK_CLEAR';
export const CC_LIST_REQUEST = 'CC_LIST_REQUEST';
export const CC_LIST_SUCCESS = 'CC_LIST_SUCCESS';
export const CC_LIST_FAILURE = 'CC_LIST_FAILURE';
export const CC_LIST_CLEAR = 'CC_LIST_CLEAR';
export const CC_CREATE_REQUEST = 'CC_CREATE_REQUEST';
export const CC_CREATE_SUCCESS = 'CC_CREATE_SUCCESS';
export const CC_CREATE_FAILURE = 'CC_CREATE_FAILURE';
export const CC_CREATE_CLEAR = 'CC_CREATE_CLEAR';
export const CC_DELETE_REQUEST = 'CC_DELETE_REQUEST';
export const CC_DELETE_SUCCESS = 'CC_DELETE_SUCCESS';
export const CC_DELETE_FAILURE = 'CC_DELETE_FAILURE';
export const CC_DELETE_CLEAR = 'CC_DELETE_CLEAR';
export const CC_SETDEFAULT_REQUEST = 'CC_SETDEFAULT_REQUEST';
export const CC_SETDEFAULT_SUCCESS = 'CC_SETDEFAULT_SUCCESS';
export const CC_SETDEFAULT_FAILURE = 'CC_SETDEFAULT_FAILURE';
export const CC_SETDEFAULT_CLEAR = 'CC_SETDEFAULT_CLEAR';

export const CC_CHARGE_REQUEST = 'CC_CHARGE_REQUEST';
export const CC_REFUND_REQUEST = 'CC_REFUND_REQUEST';

export const AUTOPAY_SET_REQUEST = 'AUTOPAY_SET_REQUEST';
export const AUTOPAY_SET_SUCCESS = 'AUTOPAY_SET_SUCCESS';
export const AUTOPAY_SET_FAILURE = 'AUTOPAY_SET_FAILURE';
export const AUTOPAY_SET_CLEAR = 'AUTOPAY_SET_CLEAR';

export const CLEARINGHOUSE_LIST_REQUEST = 'CLEARINGHOUSE_LIST_REQUEST';
export const CLEARINGHOUSE_LIST_SUCCESS = 'CLEARINGHOUSE_LIST_SUCCESS';
export const CLEARINGHOUSE_LIST_FAILURE = 'CLEARINGHOUSE_LIST_FAILURE';
export const CLEARINGHOUSE_LIST_CLEAR = 'CLEARINGHOUSE_LIST_CLEAR';
export const CLEARINGHOUSE_LOAD_REQUEST = 'CLEARINGHOUSE_LOAD_REQUEST';
export const CLEARINGHOUSE_LOAD_SUCCESS = 'CLEARINGHOUSE_LOAD_SUCCESS';
export const CLEARINGHOUSE_LOAD_FAILURE = 'CLEARINGHOUSE_LOAD_FAILURE';
export const CLEARINGHOUSE_LOAD_CLEAR = 'CLEARINGHOUSE_LOAD_CLEAR';
export const CLEARINGHOUSE_GENERATE_REQUEST = 'CLEARINGHOUSE_GENERATE_REQUEST';
export const CLEARINGHOUSE_GENERATE_SUCCESS = 'CLEARINGHOUSE_GENERATE_SUCCESS';
export const CLEARINGHOUSE_GENERATE_FAILURE = 'CLEARINGHOUSE_GENERATE_FAILURE';
export const CLEARINGHOUSE_GENERATE_CLEAR = 'CLEARINGHOUSE_GENERATE_CLEAR';

export const CLEARINGHOUSE_GENERATELIST_REQUEST = 'CLEARINGHOUSE_GENERATELIST_REQUEST';
export const CLEARINGHOUSE_GENERATELIST_SUCCESS = 'CLEARINGHOUSE_GENERATELIST_SUCCESS';
export const CLEARINGHOUSE_GENERATELIST_FAILURE = 'CLEARINGHOUSE_GENERATELIST_FAILURE';
export const CLEARINGHOUSE_GENERATELIST_CLEAR = 'CLEARINGHOUSE_GENERATELIST_CLEAR';

export const CLEARINGHOUSE_DELETE_REQUEST = 'CLEARINGHOUSE_DELETE_REQUEST';
export const CLEARINGHOUSE_DELETE_SUCCESS = 'CLEARINGHOUSE_DELETE_SUCCESS';
export const CLEARINGHOUSE_DELETE_FAILURE = 'CLEARINGHOUSE_DELETE_FAILURE';
export const CLEARINGHOUSE_DELETE_CLEAR = 'CLEARINGHOUSE_DELETE_CLEAR';
export const CLEARINGHOUSE_SUBMIT_REQUEST = 'CLEARINGHOUSE_SUBMIT_REQUEST';
export const CLEARINGHOUSE_SUBMIT_SUCCESS = 'CLEARINGHOUSE_SUBMIT_SUCCESS';
export const CLEARINGHOUSE_SUBMIT_FAILURE = 'CLEARINGHOUSE_SUBMIT_FAILURE';
export const CLEARINGHOUSE_SUBMIT_CLEAR = 'CLEARINGHOUSE_SUBMIT_CLEAR';
export const CLEARINGHOUSE_REGENERATE_REQUEST = 'CLEARINGHOUSE_REGENERATE_REQUEST';
export const CLEARINGHOUSE_REGENERATE_SUCCESS = 'CLEARINGHOUSE_REGENERATE_SUCCESS';
export const CLEARINGHOUSE_REGENERATE_FAILURE = 'CLEARINGHOUSE_REGENERATE_FAILURE';
export const CLEARINGHOUSE_REGENERATE_CLEAR = 'CLEARINGHOUSE_REGENERATE_CLEAR';
export const CLEARINGHOUSE_PROCESS_REQUEST = 'CLEARINGHOUSE_PROCESS_REQUEST';
export const CLEARINGHOUSE_PROCESS_SUCCESS = 'CLEARINGHOUSE_PROCESS_SUCCESS';
export const CLEARINGHOUSE_PROCESS_FAILURE = 'CLEARINGHOUSE_PROCESS_FAILURE';
export const CLEARINGHOUSE_PROCESS_CLEAR = 'CLEARINGHOUSE_PROCESS_CLEAR';


export const ERA_LIST_REQUEST = 'ERA_LIST_REQUEST';
export const ERA_LIST_SUCCESS = 'ERA_LIST_SUCCESS';
export const ERA_LIST_FAILURE = 'ERA_LIST_FAILURE';
export const ERA_LIST_CLEAR = 'ERA_LIST_CLEAR';
export const ERA_LOAD_REQUEST = 'ERA_LOAD_REQUEST';
export const ERA_LOAD_SUCCESS = 'ERA_LOAD_SUCCESS';
export const ERA_LOAD_FAILURE = 'ERA_LOAD_FAILURE';
export const ERA_LOAD_CLEAR = 'ERA_LOAD_CLEAR';
export const ERA_CREATE_REQUEST = 'ERA_CREATE_REQUEST';
export const ERA_CREATE_SUCCESS = 'ERA_CREATE_SUCCESS';
export const ERA_CREATE_FAILURE = 'ERA_CREATE_FAILURE';
export const ERA_CREATE_CLEAR = 'ERA_CREATE_CLEAR';
export const ERA_RECONCILED_LOAD_REQUEST = 'ERA_RECONCILED_LOAD_REQUEST';
export const ERA_RECONCILED_LOAD_SUCCESS = 'ERA_RECONCILED_LOAD_SUCCESS';
export const ERA_RECONCILED_LOAD_FAILURE = 'ERA_RECONCILED_LOAD_FAILURE';
export const ERA_RECONCILED_LOAD_CLEAR = 'ERA_RECONCILED_LOAD_CLEAR';
export const ERA_CLAIMSRECALL_LIST_REQUEST = 'ERA_CLAIMSRECALL_LIST_REQUEST';
export const ERA_CLAIMSRECALL_LIST_SUCCESS = 'ERA_CLAIMSRECALL_LIST_SUCCESS';
export const ERA_CLAIMSRECALL_LIST_FAILURE = 'ERA_CLAIMSRECALL_LIST_FAILURE';
export const ERA_CLAIMSRECALL_LIST_CLEAR = 'ERA_CLAIMSRECALL_LIST_CLEAR';
export const ERA_CLAIMSRECALL_SAVE_REQUEST = 'ERA_CLAIMSRECALL_REQUEST_REQUEST';
export const ERA_CLAIMSRECALL_SAVE_SUCCESS = 'ERA_CLAIMSRECALL_SUCCESS_REQUEST';
export const ERA_CLAIMSRECALL_SAVE_FAILURE = 'ERA_CLAIMSRECALL_FAILURE_REQUEST';
export const ERA_CLAIMSRECALL_SAVE_CLEAR = 'ERA_CLAIMSRECALL_CLEAR_REQUEST';

export const ERA_POST_REQUEST = 'ERA_POST_REQUEST';
export const ERA_POST_SUCCESS = 'ERA_POST_SUCCESS';
export const ERA_POST_FAILURE = 'ERA_POST_FAILURE';
export const ERA_POST_CLEAR = 'ERA_POST_CLEAR';
export const ERA_MARK_REQUEST = 'ERA_MARK_REQUEST';
export const ERA_MARK_SUCCESS = 'ERA_MARK_SUCCESS';
export const ERA_MARK_FAILURE = 'ERA_MARK_FAILURE';
export const ERA_MARK_CLEAR = 'ERA_MARK_CLEAR';
export const ERA_TAG_REQUEST = 'ERA_TAG_REQUEST';
export const ERA_TAG_SUCCESS = 'ERA_TAG_SUCCESS';
export const ERA_TAG_FAILURE = 'ERA_TAG_FAILURE';
export const ERA_TAG_CLEAR = 'ERA_TAG_CLEAR';

export const ERA_ADDRECALL_REQUEST = 'ERA_ADDRECALL_REQUEST';
export const ERA_ADDRECALL_SUCCESS = 'ERA_ADDRECALL_SUCCESS';
export const ERA_ADDRECALL_FAILURE = 'ERA_ADDRECALL_FAILURE';
export const ERA_ADDRECALL_CLEAR = 'ERA_ADDRECALL_CLEAR';

export const RECALL_LISTS_LIST_REQUEST = 'RECALL_LISTS_LIST_REQUEST';
export const RECALL_LISTS_LIST_SUCCESS = 'RECALL_LISTS_LIST_SUCCESS';
export const RECALL_LISTS_LIST_FAILURE = 'RECALL_LISTS_LIST_FAILURE';
export const RECALL_LISTS_LIST_CLEAR = 'RECALL_LISTS_LIST_CLEAR';
export const RECALL_LISTS_LISTFULL_REQUEST = 'RECALL_LISTS_LISTFULL_REQUEST';
export const RECALL_LISTS_LISTFULL_SUCCESS = 'RECALL_LISTS_LISTFULL_SUCCESS';
export const RECALL_LISTS_LISTFULL_FAILURE = 'RECALL_LISTS_LISTFULL_FAILURE';
export const RECALL_LISTS_LISTFULL_CLEAR = 'RECALL_LISTS_LISTFULL_CLEAR';
export const RECALL_LISTS_DEACTIVATE_REQUEST = 'RECALL_LISTS_DEACTIVATE_REQUEST';
export const RECALL_LISTS_DEACTIVATE_SUCCESS = 'RECALL_LISTS_DEACTIVATE_SUCCESS';
export const RECALL_LISTS_DEACTIVATE_FAILURE = 'RECALL_LISTS_DEACTIVATE_FAILURE';
export const RECALL_LISTS_DEACTIVATE_CLEAR = 'RECALL_LISTS_DEACTIVATE_CLEAR';
export const RECALL_LISTS_REACTIVATE_REQUEST = 'RECALL_LISTS_REACTIVATE_REQUEST';
export const RECALL_LISTS_REACTIVATE_SUCCESS = 'RECALL_LISTS_REACTIVATE_SUCCESS';
export const RECALL_LISTS_REACTIVATE_FAILURE = 'RECALL_LISTS_REACTIVATE_FAILURE';
export const RECALL_LISTS_REACTIVATE_CLEAR = 'RECALL_LISTS_REACTIVATE_CLEAR';
export const RECALL_LISTS_CREATE_REQUEST = 'RECALL_LISTS_CREATE_REQUEST';
export const RECALL_LISTS_CREATE_SUCCESS = 'RECALL_LISTS_CREATE_SUCCESS';
export const RECALL_LISTS_CREATE_FAILURE = 'RECALL_LISTS_CREATE_FAILURE';
export const RECALL_LISTS_CREATE_CLEAR = 'RECALL_LISTS_CREATE_CLEAR';
export const RECALL_LISTS_SAVE_REQUEST = 'RECALL_LISTS_SAVE_REQUEST';
export const RECALL_LISTS_SAVE_SUCCESS = 'RECALL_LISTS_SAVE_SUCCESS';
export const RECALL_LISTS_SAVE_FAILURE = 'RECALL_LISTS_SAVE_FAILURE';
export const RECALL_LISTS_SAVE_CLEAR = 'RECALL_LISTS_SAVE_CLEAR';
export const RECALL_LISTS_LOAD_REQUEST = 'RECALL_LISTS_LOAD_REQUEST';
export const RECALL_LISTS_LOAD_SUCCESS = 'RECALL_LISTS_LOAD_SUCCESS';
export const RECALL_LISTS_LOAD_FAILURE = 'RECALL_LISTS_LOAD_FAILURE';
export const RECALL_LISTS_LOAD_CLEAR = 'RECALL_LISTS_LOAD_CLEAR';
export const RECALL_ITEMS_LIST_REQUEST = 'RECALL_ITEMS_LIST_REQUEST';
export const RECALL_ITEMS_LIST_SUCCESS = 'RECALL_ITEMS_LIST_SUCCESS';
export const RECALL_ITEMS_LIST_FAILURE = 'RECALL_ITEMS_LIST_FAILURE';
export const RECALL_ITEMS_LIST_CLEAR = 'RECALL_ITEMS_LIST_CLEAR';
export const RECALL_ITEMS_CREATE_REQUEST = 'RECALL_ITEMS_CREATE_REQUEST';
export const RECALL_ITEMS_CREATE_SUCCESS = 'RECALL_ITEMS_CREATE_SUCCESS';
export const RECALL_ITEMS_CREATE_FAILURE = 'RECALL_ITEMS_CREATE_FAILURE';
export const RECALL_ITEMS_CREATE_CLEAR = 'RECALL_ITEMS_CREATE_CLEAR';
export const RECALL_ITEMS_SAVE_REQUEST = 'RECALL_ITEMS_SAVE_REQUEST';
export const RECALL_ITEMS_SAVE_SUCCESS = 'RECALL_ITEMS_SAVE_SUCCESS';
export const RECALL_ITEMS_SAVE_FAILURE = 'RECALL_ITEMS_SAVE_FAILURE';
export const RECALL_ITEMS_SAVE_CLEAR = 'RECALL_ITEMS_SAVE_CLEAR';
export const RECALL_COMMENTS_LIST_REQUEST = 'RECALL_COMMENTS_LIST_REQUEST';
export const RECALL_COMMENTS_LIST_SUCCESS = 'RECALL_COMMENTS_LIST_SUCCESS';
export const RECALL_COMMENTS_LIST_FAILURE = 'RECALL_COMMENTS_LIST_FAILURE';
export const RECALL_COMMENTS_LIST_CLEAR = 'RECALL_COMMENTS_LIST_CLEAR';
export const RECALL_COMMENTS_CREATE_REQUEST = 'RECALL_COMMENTS_CREATE_REQUEST';
export const RECALL_COMMENTS_CREATE_SUCCESS = 'RECALL_COMMENTS_CREATE_SUCCESS';
export const RECALL_COMMENTS_CREATE_FAILURE = 'RECALL_COMMENTS_CREATE_FAILURE';
export const RECALL_COMMENTS_CREATE_CLEAR = 'RECALL_COMMENTS_CREATE_CLEAR';

export const RECALL_PATIENT_LIST_REQUEST = 'RECALL_PATIENT_LIST_REQUEST';
export const RECALL_PATIENT_LIST_SUCCESS = 'RECALL_PATIENT_LIST_SUCCESS';
export const RECALL_PATIENT_LIST_FAILURE = 'RECALL_PATIENT_LIST_FAILURE';
export const RECALL_PATIENT_LIST_CLEAR = 'RECALL_PATIENT_LIST_CLEAR';
export const RECALL_PATIENT_SAVE_REQUEST = 'RECALL_PATIENT_SAVE_REQUEST';
export const RECALL_PATIENT_SAVE_SUCCESS = 'RECALL_PATIENT_SAVE_SUCCESS';
export const RECALL_PATIENT_SAVE_FAILURE = 'RECALL_PATIENT_SAVE_FAILURE';
export const RECALL_PATIENT_SAVE_CLEAR = 'RECALL_PATIENT_SAVE_CLEAR';
export const RECALL_PATIENT_CREATE_REQUEST = 'RECALL_PATIENT_CREATE_REQUEST';
export const RECALL_PATIENT_CREATE_SUCCESS = 'RECALL_PATIENT_CREATE_SUCCESS';
export const RECALL_PATIENT_CREATE_FAILURE = 'RECALL_PATIENT_CREATE_FAILURE';
export const RECALL_PATIENT_CREATE_CLEAR = 'RECALL_PATIENT_CREATE_CLEAR';

export const BILLING_QUEUE_SET_REQUEST = 'BILLING_QUEUE_SET_REQUEST';
export const BILLING_QUEUE_SET_SUCCESS = 'BILLING_QUEUE_SET_SUCCESS';
export const BILLING_QUEUE_SET_FAILURE = 'BILLING_QUEUE_SET_FAILURE';
export const BILLING_QUEUE_SET_CLEAR = 'BILLING_QUEUE_SET_CLEAR';

export const BILLING_QUEUED_SERVICES_REQUEST = 'BILLING_QUEUED_SERVICES_REQUEST';
export const BILLING_QUEUED_SERVICES_SUCCESS = 'BILLING_QUEUED_SERVICES_SUCCESS';
export const BILLING_QUEUED_SERVICES_FAILURE = 'BILLING_QUEUED_SERVICES_FAILURE';
export const BILLING_QUEUED_SERVICES_CLEAR = 'BILLING_QUEUED_SERVICES_CLEAR';

export const BILLING_DIAGNOSIS_LIST_REQUEST = 'BILLING_DIAGNOSIS_LIST_REQUEST';
export const BILLING_DIAGNOSIS_LIST_SUCCESS = 'BILLING_DIAGNOSIS_LIST_SUCCESS';
export const BILLING_DIAGNOSIS_LIST_FAILURE = 'BILLING_DIAGNOSIS_LIST_FAILURE';
export const BILLING_DIAGNOSIS_LIST_CLEAR = 'BILLING_DIAGNOSIS_LIST_CLEAR';

export const BILLING_SERVICELINE_LOAD_REQUEST = 'BILLING_SERVICELINE_LOAD_REQUEST';
export const BILLING_SERVICELINE_LOAD_SUCCESS = 'BILLING_SERVICELINE_LOAD_SUCCESS';
export const BILLING_SERVICELINE_LOAD_FAILURE = 'BILLING_SERVICELINE_LOAD_FAILURE';
export const BILLING_SERVICELINE_LOAD_CLEAR = 'BILLING_SERVICELINE_LOAD_CLEAR'
export const BILLING_SERVICELINE_SAVE_REQUEST = 'BILLING_SERVICELINE_SAVE_REQUEST';
export const BILLING_SERVICELINE_SAVE_SUCCESS = 'BILLING_SERVICELINE_SAVE_SUCCESS';
export const BILLING_SERVICELINE_SAVE_FAILURE = 'BILLING_SERVICELINE_SAVE_FAILURE';
export const BILLING_SERVICELINE_SAVE_CLEAR = 'BILLING_SERVICELINE_SAVE_CLEAR';
export const BILLING_SERVICELINE_CREATE_REQUEST = 'BILLING_SERVICELINE_CREATE_REQUEST';
export const BILLING_SERVICELINE_CREATE_SUCCESS = 'BILLING_SERVICELINE_CREATE_SUCCESS';
export const BILLING_SERVICELINE_CREATE_FAILURE = 'BILLING_SERVICELINE_CREATE_FAILURE';
export const BILLING_SERVICELINE_CREATE_CLEAR = 'BILLING_SERVICELINE_CREATE_CLEAR';
export const BILLING_SERVICELINE_HISTORY_REQUEST = 'BILLING_SERVICELINE_HISTORY_REQUEST';
export const BILLING_SERVICELINE_HISTORY_SUCCESS = 'BILLING_SERVICELINE_HISTORY_SUCCESS';
export const BILLING_SERVICELINE_HISTORY_FAILURE = 'BILLING_SERVICELINE_HISTORY_FAILURE';
export const BILLING_SERVICELINE_HISTORY_CLEAR = 'BILLING_SERVICELINE_HISTORY_CLEAR'
export const BILLING_SERVICELINE_REPRICE_REQUEST = 'BILLING_SERVICELINE_REPRICE_REQUEST';
export const BILLING_SERVICELINE_REPRICE_SUCCESS = 'BILLING_SERVICELINE_REPRICE_SUCCESS';
export const BILLING_SERVICELINE_REPRICE_FAILURE = 'BILLING_SERVICELINE_REPRICE_FAILURE';
export const BILLING_SERVICELINE_REPRICE_CLEAR = 'BILLING_SERVICELINE_REPRICE_CLEAR';
export const BILLING_SERVICELINE_ORPHAN_REQUEST = 'BILLING_SERVICELINE_ORPHAN_REQUEST';
export const BILLING_SERVICELINE_ORPHAN_SUCCESS = 'BILLING_SERVICELINE_ORPHAN_SUCCESS';
export const BILLING_SERVICELINE_ORPHAN_FAILURE = 'BILLING_SERVICELINE_ORPHAN_FAILURE';
export const BILLING_SERVICELINE_ORPHAN_CLEAR = 'BILLING_SERVICELINE_ORPHAN_CLEAR';
export const BILLING_SERVICELINE_ADDNOTE_REQUEST = 'BILLING_SERVICELINE_ADDNOTE_REQUEST';
export const BILLING_SERVICELINE_ADDNOTE_SUCCESS = 'BILLING_SERVICELINE_ADDNOTE_SUCCESS';
export const BILLING_SERVICELINE_ADDNOTE_FAILURE = 'BILLING_SERVICELINE_ADDNOTE_FAILURE';
export const BILLING_SERVICELINE_ADDNOTE_CLEAR = 'BILLING_SERVICELINE_ADDNOTE_CLEAR';

export const BILLING_SERVICELINE_LIST_REQUEST = 'BILLING_SERVICELINE_LIST_REQUEST';
export const BILLING_SERVICELINE_LIST_SUCCESS = 'BILLING_SERVICELINE_LIST_SUCCESS';
export const BILLING_SERVICELINE_LIST_FAILURE = 'BILLING_SERVICELINE_LIST_FAILURE';
export const BILLING_SERVICELINE_LIST_CLEAR = 'BILLING_SERVICELINE_LIST_CLEAR';

export const BILLING_SERVICELINE_ADJUSTPT_REQUEST = 'BILLING_SERVICELINE_ADJUSTPT_REQUEST';
export const BILLING_SERVICELINE_ADJUSTPT_SUCCESS = 'BILLING_SERVICELINE_ADJUSTPT_SUCCESS';
export const BILLING_SERVICELINE_ADJUSTPT_FAILURE = 'BILLING_SERVICELINE_ADJUSTPT_FAILURE';
export const BILLING_SERVICELINE_ADJUSTPT_CLEAR = 'BILLING_SERVICELINE_ADJUSTPT_CLEAR';

export const BILLING_LEDGER_LOAD_REQUEST = 'BILLING_LEDGER_LOAD_REQUEST';
export const BILLING_LEDGER_LOAD_SUCCESS = 'BILLING_LEDGER_LOAD_SUCCESS';
export const BILLING_LEDGER_LOAD_FAILURE = 'BILLING_LEDGER_LOAD_FAILURE';
export const BILLING_LEDGER_LOAD_CLEAR = 'BILLING_LEDGER_LOAD_CLEAR';
export const BILLING_LEDGER_ADJ_REQUEST = 'BILLING_LEDGER_ADJ_REQUEST';
export const BILLING_LEDGER_ADJ_SUCCESS = 'BILLING_LEDGER_ADJ_SUCCESS';
export const BILLING_LEDGER_ADJ_FAILURE = 'BILLING_LEDGER_ADJ_FAILURE';
export const BILLING_LEDGER_ADJ_CLEAR = 'BILLING_LEDGER_ADJ_CLEAR';
export const BILLING_LEDGER_HISTORY_REQUEST = 'BILLING_LEDGER_HISTORY_REQUEST';
export const BILLING_LEDGER_HISTORY_SUCCESS = 'BILLING_LEDGER_HISTORY_SUCCESS';
export const BILLING_LEDGER_HISTORY_FAILURE = 'BILLING_LEDGER_HISTORY_FAILURE';
export const BILLING_LEDGER_HISTORY_CLEAR = 'BILLING_LEDGER_HISTORY_CLEAR';
export const BILLING_LEDGER_TRANS_REQUEST = 'BILLING_LEDGER_TRANS_REQUEST';
export const BILLING_LEDGER_TRANS_SUCCESS = 'BILLING_LEDGER_TRANS_SUCCESS';
export const BILLING_LEDGER_TRANS_FAILURE = 'BILLING_LEDGER_TRANS_FAILURE';
export const BILLING_LEDGER_TRANS_CLEAR = 'BILLING_LEDGER_TRANS_CLEAR';

export const BILLING_COMMENTS_CREATE_REQUEST = 'BILLING_COMMENTS_CREATE_REQUEST';
export const BILLING_COMMENTS_CREATE_SUCCESS = 'BILLING_COMMENTS_CREATE_SUCCESS';
export const BILLING_COMMENTS_CREATE_FAILURE = 'BILLING_COMMENTS_CREATE_FAILURE';
export const BILLING_COMMENTS_CREATE_CLEAR = 'BILLING_COMMENTS_CREATE_CLEAR';
export const BILLING_COMMENTS_LIST_REQUEST = 'BILLING_COMMENTS_LIST_REQUEST';
export const BILLING_COMMENTS_LIST_SUCCESS = 'BILLING_COMMENTS_LIST_SUCCESS';
export const BILLING_COMMENTS_LIST_FAILURE = 'BILLING_COMMENTS_LIST_FAILURE';
export const BILLING_COMMENTS_LIST_CLEAR = 'BILLING_COMMENTS_LIST_CLEAR';

export const BILLING_POLICY_HISTORY_REQUEST = 'BILLING_POLICY_HISTORY_REQUEST';
export const BILLING_POLICY_HISTORY_SUCCESS = 'BILLING_POLICY_HISTORY_SUCCESS';
export const BILLING_POLICY_HISTORY_FAILURE = 'BILLING_POLICY_HISTORY_FAILURE';
export const BILLING_POLICY_HISTORY_CLEAR = 'BILLING_POLICY_HISTORY_CLEAR';
export const BILLING_POLICY_LIST_REQUEST = 'BILLING_POLICY_LIST_REQUEST';
export const BILLING_POLICY_LIST_SUCCESS = 'BILLING_POLICY_LIST_SUCCESS';
export const BILLING_POLICY_LIST_FAILURE = 'BILLING_POLICY_LIST_FAILURE';
export const BILLING_POLICY_LIST_CLEAR = 'BILLING_POLICY_LIST_CLEAR';
export const BILLING_POLICY_LOAD_REQUEST = 'BILLING_POLICY_LOAD_REQUEST';
export const BILLING_POLICY_LOAD_SUCCESS = 'BILLING_POLICY_LOAD_SUCCESS';
export const BILLING_POLICY_LOAD_FAILURE = 'BILLING_POLICY_LOAD_FAILURE';
export const BILLING_POLICY_LOAD_CLEAR = 'BILLING_POLICY_LOAD_CLEAR';
export const BILLING_POLICY_SAVE_REQUEST = 'BILLING_POLICY_SAVE_REQUEST';
export const BILLING_POLICY_SAVE_SUCCESS = 'BILLING_POLICY_SAVE_SUCCESS';
export const BILLING_POLICY_SAVE_FAILURE = 'BILLING_POLICY_SAVE_FAILURE';
export const BILLING_POLICY_SAVE_CLEAR = 'BILLING_POLICY_SAVE_CLEAR';
export const BILLING_POLICY_CREATE_REQUEST = 'BILLING_POLICY_CREATE_REQUEST';
export const BILLING_POLICY_CREATE_SUCCESS = 'BILLING_POLICY_CREATE_SUCCESS';
export const BILLING_POLICY_CREATE_FAILURE = 'BILLING_POLICY_CREATE_FAILURE';
export const BILLING_POLICY_CREATE_CLEAR = 'BILLING_POLICY_CREATE_CLEAR';
export const BILLING_POLICY_DELETE_REQUEST = 'BILLING_POLICY_DELETE_REQUEST';
export const BILLING_POLICY_DELETE_SUCCESS = 'BILLING_POLICY_DELETE_SUCCESS';
export const BILLING_POLICY_DELETE_FAILURE = 'BILLING_POLICY_DELETE_FAILURE';
export const BILLING_POLICY_DELETE_CLEAR = 'BILLING_POLICY_DELETE_CLEAR';

export const BILLING_STATEMENTS_LIST_REQUEST = 'BILLING_STATEMENTS_LIST_REQUEST';
export const BILLING_STATEMENTS_LIST_SUCCESS = 'BILLING_STATEMENTS_LIST_SUCCESS';
export const BILLING_STATEMENTS_LIST_FAILURE = 'BILLING_STATEMENTS_LIST_FAILURE';
export const BILLING_STATEMENTS_LIST_CLEAR = 'BILLING_STATEMENTS_LIST_CLEAR';
export const BILLING_STATEMENTS_LOAD_REQUEST = 'BILLING_STATEMENTS_LOAD_REQUEST';
export const BILLING_STATEMENTS_LOAD_SUCCESS = 'BILLING_STATEMENTS_LOAD_SUCCESS';
export const BILLING_STATEMENTS_LOAD_FAILURE = 'BILLING_STATEMENTS_LOAD_FAILURE';
export const BILLING_STATEMENTS_LOAD_CLEAR = 'BILLING_STATEMENTS_LOAD_CLEAR';
export const BILLING_STATEMENTS_CREATE_REQUEST = 'BILLING_STATEMENTS_CREATE_REQUEST';
export const BILLING_STATEMENTS_CREATE_SUCCESS = 'BILLING_STATEMENTS_CREATE_SUCCESS';
export const BILLING_STATEMENTS_CREATE_FAILURE = 'BILLING_STATEMENTS_CREATE_FAILURE';
export const BILLING_STATEMENTS_CREATE_CLEAR = 'BILLING_STATEMENTS_CREATE_CLEAR';

export const BILLING_INSPAYMENT_LOAD_REQUEST = 'BILLING_INSPAYMENT_LOAD_REQUEST';
export const BILLING_INSPAYMENT_LOAD_SUCCESS = 'BILLING_INSPAYMENT_LOAD_SUCCESS';
export const BILLING_INSPAYMENT_LOAD_FAILURE = 'BILLING_INSPAYMENT_LOAD_FAILURE';
export const BILLING_INSPAYMENT_LOAD_CLEAR = 'BILLING_INSPAYMENT_LOAD_CLEAR';
export const BILLING_INSPAYMENT_SAVE_REQUEST = 'BILLING_INSPAYMENT_SAVE_REQUEST';
export const BILLING_INSPAYMENT_SAVE_SUCCESS = 'BILLING_INSPAYMENT_SAVE_SUCCESS';
export const BILLING_INSPAYMENT_SAVE_FAILURE = 'BILLING_INSPAYMENT_SAVE_FAILURE';
export const BILLING_INSPAYMENT_SAVE_CLEAR = 'BILLING_INSPAYMENT_SAVE_CLEAR';
export const BILLING_INSPAYMENT_CREATE_REQUEST = 'BILLING_INSPAYMENT_CREATE_REQUEST';
export const BILLING_INSPAYMENT_CREATE_SUCCESS = 'BILLING_INSPAYMENT_CREATE_SUCCESS';
export const BILLING_INSPAYMENT_CREATE_FAILURE = 'BILLING_INSPAYMENT_CREATE_FAILURE';
export const BILLING_INSPAYMENT_CREATE_CLEAR = 'BILLING_INSPAYMENT_CREATE_CLEAR';
export const BILLING_INSPAYMENT_LIST_REQUEST = 'BILLING_INSPAYMENT_LIST_REQUEST';
export const BILLING_INSPAYMENT_LIST_SUCCESS = 'BILLING_INSPAYMENT_LIST_SUCCESS';
export const BILLING_INSPAYMENT_LIST_FAILURE = 'BILLING_INSPAYMENT_LIST_FAILURE';
export const BILLING_INSPAYMENT_LIST_CLEAR = 'BILLING_INSPAYMENT_LIST_CLEAR';
export const BILLING_INSPAYMENT_DELETE_REQUEST = 'BILLING_INSPAYMENT_DELETE_REQUEST';
export const BILLING_INSPAYMENT_DELETE_SUCCESS = 'BILLING_INSPAYMENT_DELETE_SUCCESS';
export const BILLING_INSPAYMENT_DELETE_FAILURE = 'BILLING_INSPAYMENT_DELETE_FAILURE';
export const BILLING_INSPAYMENT_DELETE_CLEAR = 'BILLING_INSPAYMENT_DELETE_CLEAR';
export const BILLING_INSPAYMENT_UNAPPLY_REQUEST = 'BILLING_INSPAYMENT_UNAPPLY_REQUEST';
export const BILLING_INSPAYMENT_UNAPPLY_SUCCESS = 'BILLING_INSPAYMENT_UNAPPLY_SUCCESS';
export const BILLING_INSPAYMENT_UNAPPLY_FAILURE = 'BILLING_INSPAYMENT_UNAPPLY_FAILURE';
export const BILLING_INSPAYMENT_UNAPPLY_CLEAR = 'BILLING_INSPAYMENT_UNAPPLY_CLEAR';

export const BILLING_PTPAYMENT_CREATE_REQUEST = 'BILLING_PTPAYMENT_CREATE_REQUEST';
export const BILLING_PTPAYMENT_CREATE_SUCCESS = 'BILLING_PTPAYMENT_CREATE_SUCCESS';
export const BILLING_PTPAYMENT_CREATE_FAILURE = 'BILLING_PTPAYMENT_CREATE_FAILURE';
export const BILLING_PTPAYMENT_CREATE_CLEAR = 'BILLING_PTPAYMENT_CREATE_CLEAR';
export const BILLING_PTPAYMENT_REFUND_REQUEST = 'BILLING_PTPAYMENT_REFUND_REQUEST';
export const BILLING_PTPAYMENT_REFUND_SUCCESS = 'BILLING_PTPAYMENT_REFUND_SUCCESS';
export const BILLING_PTPAYMENT_REFUND_FAILURE = 'BILLING_PTPAYMENT_REFUND_FAILURE';
export const BILLING_PTPAYMENT_REFUND_CLEAR = 'BILLING_PTPAYMENT_REFUND_CLEAR';
export const BILLING_PTPAYMENT_APPLY_REQUEST = 'BILLING_PTPAYMENT_APPLY_REQUEST';
export const BILLING_PTPAYMENT_APPLY_SUCCESS = 'BILLING_PTPAYMENT_APPLY_SUCCESS';
export const BILLING_PTPAYMENT_APPLY_FAILURE = 'BILLING_PTPAYMENT_APPLY_FAILURE';
export const BILLING_PTPAYMENT_APPLY_CLEAR = 'BILLING_PTPAYMENT_APPLY_CLEAR';
export const BILLING_PTPAYMENT_UNAPPLY_REQUEST = 'BILLING_PTPAYMENT_UNAPPLY_REQUEST';
export const BILLING_PTPAYMENT_UNAPPLY_SUCCESS = 'BILLING_PTPAYMENT_UNAPPLY_SUCCESS';
export const BILLING_PTPAYMENT_UNAPPLY_FAILURE = 'BILLING_PTPAYMENT_UNAPPLY_FAILURE';
export const BILLING_PTPAYMENT_UNAPPLY_CLEAR = 'BILLING_PTPAYMENT_UNAPPLY_CLEAR';
export const BILLING_PTPAYMENT_LIST_REQUEST = 'BILLING_PTPAYMENT_LIST_REQUEST';
export const BILLING_PTPAYMENT_LIST_SUCCESS = 'BILLING_PTPAYMENT_LIST_SUCCESS';
export const BILLING_PTPAYMENT_LIST_FAILURE = 'BILLING_PTPAYMENT_LIST_FAILURE';
export const BILLING_PTPAYMENT_LIST_CLEAR = 'BILLING_PTPAYMENT_LIST_CLEAR';
export const BILLING_PTPAYMENT_REFUNDLINK_REQUEST = 'BILLING_PTPAYMENT_REFUNDLINK_REQUEST';
export const BILLING_PTPAYMENT_REFUNDLINK_SUCCESS = 'BILLING_PTPAYMENT_REFUNDLINK_SUCCESS';
export const BILLING_PTPAYMENT_REFUNDLINK_FAILURE = 'BILLING_PTPAYMENT_REFUNDLINK_FAILURE';
export const BILLING_PTPAYMENT_REFUNDLINK_CLEAR = 'BILLING_PTPAYMENT_REFUNDLINK_CLEAR';

export const BILLING_ATTACHMENTS_LIST_REQUEST = 'BILLING_ATTACHMENTS_LIST_REQUEST';
export const BILLING_ATTACHMENTS_LIST_SUCCESS = 'BILLING_ATTACHMENTS_LIST_SUCCESS';
export const BILLING_ATTACHMENTS_LIST_FAILURE = 'BILLING_ATTACHMENTS_LIST_FAILURE';
export const BILLING_ATTACHMENTS_LIST_CLEAR = 'BILLING_ATTACHMENTS_LIST_CLEAR';
export const BILLING_ATTACHMENTS_RENAME_REQUEST = 'BILLING_ATTACHMENTS_RENAME_REQUEST';
export const BILLING_ATTACHMENTS_RENAME_SUCCESS = 'BILLING_ATTACHMENTS_RENAME_SUCCESS';
export const BILLING_ATTACHMENTS_RENAME_FAILURE = 'BILLING_ATTACHMENTS_RENAME_FAILURE';
export const BILLING_ATTACHMENTS_RENAME_CLEAR = 'BILLING_ATTACHMENTS_RENAME_CLEAR';
export const BILLING_ATTACHMENTS_DELETE_REQUEST = 'BILLING_ATTACHMENTS_DELETE_REQUEST';
export const BILLING_ATTACHMENTS_DELETE_SUCCESS = 'BILLING_ATTACHMENTS_DELETE_SUCCESS';
export const BILLING_ATTACHMENTS_DELETE_FAILURE = 'BILLING_ATTACHMENTS_DELETE_FAILURE';
export const BILLING_ATTACHMENTS_DELETE_CLEAR = 'BILLING_ATTACHMENTS_DELETE_CLEAR';
export const BILLING_ATTACHMENTS_CREATE_REQUEST = 'BILLING_ATTACHMENTS_CREATE_REQUEST';
export const BILLING_ATTACHMENTS_CREATE_SUCCESS = 'BILLING_ATTACHMENTS_CREATE_SUCCESS';
export const BILLING_ATTACHMENTS_CREATE_FAILURE = 'BILLING_ATTACHMENTS_CREATE_FAILURE';
export const BILLING_ATTACHMENTS_CREATE_CLEAR = 'BILLING_ATTACHMENTS_CREATE_CLEAR';

export const AR_LIST1_REQUEST = 'AR_LIST1_REQUEST';
export const AR_LIST1_SUCCESS = 'AR_LIST1_SUCCESS';
export const AR_LIST1_FAILURE = 'AR_LIST1_FAILURE';
export const AR_LIST1_CLEAR = 'AR_LIST1_CLEAR';
export const AR_LIST2_REQUEST = 'AR_LIST2_REQUEST';
export const AR_LIST2_SUCCESS = 'AR_LIST2_SUCCESS';
export const AR_LIST2_FAILURE = 'AR_LIST2_FAILURE';
export const AR_LIST2_CLEAR = 'AR_LIST2_CLEAR';
export const AR_LIST3_REQUEST = 'AR_LIST3_REQUEST';
export const AR_LIST3_SUCCESS = 'AR_LIST3_SUCCESS';
export const AR_LIST3_FAILURE = 'AR_LIST3_FAILURE';
export const AR_LIST3_CLEAR = 'AR_LIST3_CLEAR';
export const AR_LIST4_REQUEST = 'AR_LIST4_REQUEST';
export const AR_LIST4_SUCCESS = 'AR_LIST4_SUCCESS';
export const AR_LIST4_FAILURE = 'AR_LIST4_FAILURE';
export const AR_LIST4_CLEAR = 'AR_LIST4_CLEAR';

// CRUD + List actions for RATETABLEs
export const RATETABLE_LOAD_REQUEST = 'RATETABLE_LOAD_REQUEST';
export const RATETABLE_LOAD_SUCCESS = 'RATETABLE_LOAD_SUCCESS';
export const RATETABLE_LOAD_FAILURE = 'RATETABLE_LOAD_FAILURE';
export const RATETABLE_LOAD_CLEAR = 'RATETABLE_LOAD_CLEAR'
export const RATETABLE_SAVE_REQUEST = 'RATETABLE_SAVE_REQUEST';
export const RATETABLE_SAVE_SUCCESS = 'RATETABLE_SAVE_SUCCESS';
export const RATETABLE_SAVE_FAILURE = 'RATETABLE_SAVE_FAILURE';
export const RATETABLE_SAVE_CLEAR = 'RATETABLE_SAVE_CLEAR'
export const RATETABLE_CREATE_REQUEST = 'RATETABLE_CREATE_REQUEST';
export const RATETABLE_CREATE_SUCCESS = 'RATETABLE_CREATE_SUCCESS';
export const RATETABLE_CREATE_FAILURE = 'RATETABLE_CREATE_FAILURE';
export const RATETABLE_CREATE_CLEAR = 'RATETABLE_CREATE_CLEAR'
export const RATETABLE_DEACTIVATE_REQUEST = 'RATETABLE_DEACTIVATE_REQUEST';
export const RATETABLE_DEACTIVATE_SUCCESS = 'RATETABLE_DEACTIVATE_SUCCESS';
export const RATETABLE_DEACTIVATE_FAILURE = 'RATETABLE_DEACTIVATE_FAILURE';
export const RATETABLE_DEACTIVATE_CLEAR = 'RATETABLE_DEACTIVATE_CLEAR';
export const RATETABLE_REACTIVATE_REQUEST = 'RATETABLE_REACTIVATE_REQUEST';
export const RATETABLE_REACTIVATE_SUCCESS = 'RATETABLE_REACTIVATE_SUCCESS';
export const RATETABLE_REACTIVATE_FAILURE = 'RATETABLE_REACTIVATE_FAILURE';
export const RATETABLE_REACTIVATE_CLEAR = 'RATETABLE_REACTIVATE_CLEAR';
export const RATETABLE_LIST_REQUEST = 'RATETABLE_LIST_REQUEST';
export const RATETABLE_LIST_SUCCESS = 'RATETABLE_LIST_SUCCESS';
export const RATETABLE_LIST_FAILURE = 'RATETABLE_LIST_FAILURE';
export const RATETABLE_LIST_CLEAR = 'RATETABLE_LIST_CLEAR';

// CRUD + List actions for Payers
export const PAYER_LOAD_REQUEST = 'PAYER_LOAD_REQUEST';
export const PAYER_LOAD_SUCCESS = 'PAYER_LOAD_SUCCESS';
export const PAYER_LOAD_FAILURE = 'PAYER_LOAD_FAILURE';
export const PAYER_LOAD_CLEAR = 'PAYER_LOAD_CLEAR'
export const PAYER_SAVE_REQUEST = 'PAYER_SAVE_REQUEST';
export const PAYER_SAVE_SUCCESS = 'PAYER_SAVE_SUCCESS';
export const PAYER_SAVE_FAILURE = 'PAYER_SAVE_FAILURE';
export const PAYER_SAVE_CLEAR = 'PAYER_SAVE_CLEAR'
export const PAYER_CREATE_REQUEST = 'PAYER_CREATE_REQUEST';
export const PAYER_CREATE_SUCCESS = 'PAYER_CREATE_SUCCESS';
export const PAYER_CREATE_FAILURE = 'PAYER_CREATE_FAILURE';
export const PAYER_CREATE_CLEAR = 'PAYER_CREATE_CLEAR'
export const PAYER_DEACTIVATE_REQUEST = 'PAYER_DEACTIVATE_REQUEST';
export const PAYER_DEACTIVATE_SUCCESS = 'PAYER_DEACTIVATE_SUCCESS';
export const PAYER_DEACTIVATE_FAILURE = 'PAYER_DEACTIVATE_FAILURE';
export const PAYER_DEACTIVATE_CLEAR = 'PAYER_DEACTIVATE_CLEAR';
export const PAYER_REACTIVATE_REQUEST = 'PAYER_REACTIVATE_REQUEST';
export const PAYER_REACTIVATE_SUCCESS = 'PAYER_REACTIVATE_SUCCESS';
export const PAYER_REACTIVATE_FAILURE = 'PAYER_REACTIVATE_FAILURE';
export const PAYER_REACTIVATE_CLEAR = 'PAYER_REACTIVATE_CLEAR';
export const PAYER_LIST_REQUEST = 'PAYER_LIST_REQUEST';
export const PAYER_LIST_SUCCESS = 'PAYER_LIST_SUCCESS';
export const PAYER_LIST_FAILURE = 'PAYER_LIST_FAILURE';
export const PAYER_LIST_CLEAR = 'PAYER_LIST_CLEAR';

export const TPA_LOAD_REQUEST = 'TPA_LOAD_REQUEST';
export const TPA_LOAD_SUCCESS = 'TPA_LOAD_SUCCESS';
export const TPA_LOAD_FAILURE = 'TPA_LOAD_FAILURE';
export const TPA_LOAD_CLEAR = 'TPA_LOAD_CLEAR'
export const TPA_SAVE_REQUEST = 'TPA_SAVE_REQUEST';
export const TPA_SAVE_SUCCESS = 'TPA_SAVE_SUCCESS';
export const TPA_SAVE_FAILURE = 'TPA_SAVE_FAILURE';
export const TPA_SAVE_CLEAR = 'TPA_SAVE_CLEAR'
export const TPA_CREATE_REQUEST = 'TPA_CREATE_REQUEST';
export const TPA_CREATE_SUCCESS = 'TPA_CREATE_SUCCESS';
export const TPA_CREATE_FAILURE = 'TPA_CREATE_FAILURE';
export const TPA_CREATE_CLEAR = 'TPA_CREATE_CLEAR'
export const TPA_DEACTIVATE_REQUEST = 'TPA_DEACTIVATE_REQUEST';
export const TPA_DEACTIVATE_SUCCESS = 'TPA_DEACTIVATE_SUCCESS';
export const TPA_DEACTIVATE_FAILURE = 'TPA_DEACTIVATE_FAILURE';
export const TPA_DEACTIVATE_CLEAR = 'TPA_DEACTIVATE_CLEAR';
export const TPA_REACTIVATE_REQUEST = 'TPA_REACTIVATE_REQUEST';
export const TPA_REACTIVATE_SUCCESS = 'TPA_REACTIVATE_SUCCESS';
export const TPA_REACTIVATE_FAILURE = 'TPA_REACTIVATE_FAILURE';
export const TPA_REACTIVATE_CLEAR = 'TPA_REACTIVATE_CLEAR';
export const TPA_LIST_REQUEST = 'TPA_LIST_REQUEST';
export const TPA_LIST_SUCCESS = 'TPA_LIST_SUCCESS';
export const TPA_LIST_FAILURE = 'TPA_LIST_FAILURE';
export const TPA_LIST_CLEAR = 'TPA_LIST_CLEAR';


// CRUD + List actions for CPT
export const CPT_LOAD_REQUEST = 'CPT_LOAD_REQUEST';
export const CPT_LOAD_SUCCESS = 'CPT_LOAD_SUCCESS';
export const CPT_LOAD_FAILURE = 'CPT_LOAD_FAILURE';
export const CPT_LOAD_CLEAR = 'CPT_LOAD_CLEAR'
export const CPT_SAVE_REQUEST = 'CPT_SAVE_REQUEST';
export const CPT_SAVE_SUCCESS = 'CPT_SAVE_SUCCESS';
export const CPT_SAVE_FAILURE = 'CPT_SAVE_FAILURE';
export const CPT_SAVE_CLEAR = 'CPT_SAVE_CLEAR'
export const CPT_CREATE_REQUEST = 'CPT_CREATE_REQUEST';
export const CPT_CREATE_SUCCESS = 'CPT_CREATE_SUCCESS';
export const CPT_CREATE_FAILURE = 'CPT_CREATE_FAILURE';
export const CPT_CREATE_CLEAR = 'CPT_CREATE_CLEAR'
export const CPT_DEACTIVATE_REQUEST = 'CPT_DEACTIVATE_REQUEST';
export const CPT_DEACTIVATE_SUCCESS = 'CPT_DEACTIVATE_SUCCESS';
export const CPT_DEACTIVATE_FAILURE = 'CPT_DEACTIVATE_FAILURE';
export const CPT_DEACTIVATE_CLEAR = 'CPT_DEACTIVATE_CLEAR';
export const CPT_REACTIVATE_REQUEST = 'CPT_REACTIVATE_REQUEST';
export const CPT_REACTIVATE_SUCCESS = 'CPT_REACTIVATE_SUCCESS';
export const CPT_REACTIVATE_FAILURE = 'CPT_REACTIVATE_FAILURE';
export const CPT_REACTIVATE_CLEAR = 'CPT_REACTIVATE_CLEAR';
export const CPT_LIST_REQUEST = 'CPT_LIST_REQUEST';
export const CPT_LIST_SUCCESS = 'CPT_LIST_SUCCESS';
export const CPT_LIST_FAILURE = 'CPT_LIST_FAILURE';
export const CPT_LIST_CLEAR = 'CPT_LIST_CLEAR';

export const PRODUCT_LOAD_REQUEST = 'PRODUCT_LOAD_REQUEST';
export const PRODUCT_LOAD_SUCCESS = 'PRODUCT_LOAD_SUCCESS';
export const PRODUCT_LOAD_FAILURE = 'PRODUCT_LOAD_FAILURE';
export const PRODUCT_LOAD_CLEAR = 'PRODUCT_LOAD_CLEAR'
export const PRODUCT_SAVE_REQUEST = 'PRODUCT_SAVE_REQUEST';
export const PRODUCT_SAVE_SUCCESS = 'PRODUCT_SAVE_SUCCESS';
export const PRODUCT_SAVE_FAILURE = 'PRODUCT_SAVE_FAILURE';
export const PRODUCT_SAVE_CLEAR = 'PRODUCT_SAVE_CLEAR'
export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST';
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_FAILURE = 'PRODUCT_CREATE_FAILURE';
export const PRODUCT_CREATE_CLEAR = 'PRODUCT_CREATE_CLEAR'
export const PRODUCT_DEACTIVATE_REQUEST = 'PRODUCT_DEACTIVATE_REQUEST';
export const PRODUCT_DEACTIVATE_SUCCESS = 'PRODUCT_DEACTIVATE_SUCCESS';
export const PRODUCT_DEACTIVATE_FAILURE = 'PRODUCT_DEACTIVATE_FAILURE';
export const PRODUCT_DEACTIVATE_CLEAR = 'PRODUCT_DEACTIVATE_CLEAR';
export const PRODUCT_REACTIVATE_REQUEST = 'PRODUCT_REACTIVATE_REQUEST';
export const PRODUCT_REACTIVATE_SUCCESS = 'PRODUCT_REACTIVATE_SUCCESS';
export const PRODUCT_REACTIVATE_FAILURE = 'PRODUCT_REACTIVATE_FAILURE';
export const PRODUCT_REACTIVATE_CLEAR = 'PRODUCT_REACTIVATE_CLEAR';
export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAILURE = 'PRODUCT_LIST_FAILURE';
export const PRODUCT_LIST_CLEAR = 'PRODUCT_LIST_CLEAR';
