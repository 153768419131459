import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Input } from 'antd';
import * as actions from '../../../../actions/emr';
import moment from 'moment';

class Message extends Component {
  static propTypes = {
    comments: PropTypes.array,
    noteId: PropTypes.number,
    disabled: PropTypes.bool
  }

  render() {
    const leftStyle = {
      display: 'inline-block',
      maxWidth: '65%',
      border: '1px solid black',
      backgroundColor: 'white',
      borderRadius: '3px',
      color: '#646464',
      fontSize: '14px',
      padding: '5px',
      margin: '0px'
    };

    const rightStyle = {
      ...leftStyle,
      float:'right',
      backgroundColor: 'rgb(82, 196,26)', //'#05728f',
      color: 'white'
    };

    const leftLabel = {
      margin: '0px',
      display: 'block',
      fontSize: 'smaller'
    }

    const rightLabel = {
      ...leftLabel,
      float:'right'
    };

    const style = (this.props.isMe) ? leftStyle : rightStyle;
    const label = (this.props.isMe) ? leftLabel : rightLabel;

    return (
      <div style={{marginBottom:'10px'}}>
        <div style={style}>{this.props.message}</div>
        <div style={{clear:'both'}}/>
        <div style={label}>
          {this.props.timedate.format('ddd, MMM D, YYYY hh:mm:ss')} {this.props.userName}
        </div>
        <div style={{clear:'both'}}/>
      </div>
    );
    /*
    const align = (this.props.isMe) ? 'left' : 'right';
    const bgColor = (this.props.isMe) ? 'white' : 'rgb(13,198,0)';
    const color = (this.props.isMe) ? 'black' : 'white';

    return (
      <div align={align}>
        <div style={{display:'inline',paddingTop:'20px',paddingBottom:'10px',border:'1px solid rgb(220,220,220)',backgroundColor:bgColor,color:color,borderRadius:'5px'}}>
          <span style={{padding:'10px'}}>
            {this.props.message}
          </span>
        </div>
        <div style={{fontSize:'smaller'}}>
          {this.props.timedate.format('ddd, MMM D, YYYY hh:mm:ss')} {this.props.userName}
        </div>
      </div>
    )
    */
  }
}

Message.propTypes = {
  message: PropTypes.string,
  timedate: PropTypes.object,
  userName: PropTypes.string,
  isMe: PropTypes.bool
}

class MessageList extends Component {
  renderList = () => {
    if (this.props.comments.length === 0) {
      return null;
    }

    return this.props.comments.map(x =>
      <div key={x.id}>
        <Message
          message={x.message}
          timedate={moment(x.createdAt)}
          userName={x.user.full_name}
          isMe={(x.user.id===this.props.providerId) ? true : false}
        />
      </div>
    );
  }

  render() {
    const style = {
      backgroundColor:'rgb(240,240,240)',
      padding:'5px',
      minHeight:'200px',
      border:'1px solid rgb(169,169,169)'
    };

    return (
      <div style={style}>
        { this.renderList() }
      </div>
    );
  }

}

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: ''
    }
  }

  submitComment = () => {
    this.props.onSelectChange(this.props.providerId, []);
    this.props.noteReject(this.props.noteId,this.state.comment);
    this.setState({
      comment: ''
    });
  }

  onChangeComment = (event) => {
    this.setState({
      comment: event.target.value
    })
  }

  displayForm = () => {
    const TextArea = Input.TextArea;
    return (
      <div style={{paddingTop:'5px'}}>
        <TextArea
          placeholder='Add comments to supervisee here'
          style={{marginBottom:'5px'}}
          disabled={this.props.disabled}
          onChange={this.onChangeComment}
          value={this.state.comment}
          rows={2}
        />
        <Button
          disabled={this.props.disabled || this.state.comment.length === 0}
          onClick={this.submitComment}
          type="primary"
        >
          Return Note to Supervisee for Revision
        </Button>
      </div>
    )
  }

  render() {
    return (
      <div>
        <MessageList comments={this.props.comments} providerId={this.props.userId}/>
        { this.displayForm() }
      </div>
    );
  }
}

export default connect(null,actions)(Comments);
