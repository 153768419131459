import React from 'react';
import { CloseOutlined, EyeOutlined } from '@ant-design/icons';
import { Rate, Input, Button } from 'antd';
import { connect } from 'react-redux';

import * as actions from '../../../../../../actions/emr';
import { safe } from '../../../../../../helpers/reduxSelectors';

class ThreadComment extends React.Component {
  state = {
    threadStatus: null,
    starred: false,
    starred_message: false
  }

  componentDidMount() {
    this.setState({
      threadStatus: this.props.threadStatus,
      starred: safe(this.props,'threadStatus.starred'),
      starred_message: safe(this.props,'threadStatus.starred_message')
    });
  }

  componentDidUpdate(prevProps) {
    if (safe(prevProps,'threadStatus.id') !== safe(this.props,'threadStatus.id')) {
      this.setState({
        threadStatus: this.props.threadStatus,
        starred: safe(this.props,'threadStatus.starred'),
        starred_message: safe(this.props,'threadStatus.starred_message')
      });
    }
  }

  onStar = (value) => {
    this.setState({
      starred: (value) ? true : false,
      starred_message: (!value) ? null : this.state.starred_message
    },()=>{
      this.props.starThread(this.state.threadStatus.id,this.state.starred,this.state.starred_message);
      if (this.state.starred) {
        this.nameInput.focus();
      }
    });
  }

  onCommentChange = (e) => {
    this.setState({
      starred_message: e.target.value
    });
  }

  storeComment = () => {
    this.props.starThread(this.state.threadStatus.id,this.state.starred,this.state.starred_message);
  }

  unread = () => {
    this.props.onBack();
    this.props.unreadThread(this.state.threadStatus.id);
  }

  archive = () => {
    this.props.onBack();
    this.props.hideMessageStatus(this.state.threadStatus.id);
  }

  render() {
    return (
      <div style={{width:'100%',minHeight:'50px',padding:'5px'}}>
        <table style={{width:'100%'}}>
          <tbody>
            <tr>
              <td style={{width:'40px',textAlign:'center'}}>
                <Rate
                  count={1}
                  onChange={this.onStar}
                  value={this.state.starred ? 1 : 0}
                />
              </td>
              <td>
                <Input.TextArea
                  autosize={true}
                  rows={1}
                  disabled={!this.state.starred}
                  value={this.state.starred_message}
                  onChange={this.onCommentChange}
                  onBlur={this.storeComment}
                  ref={(input) => { this.nameInput = input; }}
                />
              </td>
              <td style={{width:'40px',textAlign:'right'}}>
                <Button onClick={this.unread} shape='circle' icon={<EyeOutlined />}/>
              </td>
              <td style={{width:'40px',textAlign:'right'}}>
                <Button type='link' icon={<CloseOutlined />} onClick={this.archive} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default connect(null,actions)(ThreadComment);
