import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BellOutlined, EditOutlined, InboxOutlined, MailOutlined } from '@ant-design/icons';
import { Row, Col, Badge, message } from 'antd';
import numeral from 'numeral';
import moment from 'moment';

import Alerts from '../../../shared/Alerts';
import ChartStatusBar from '../../../shared/ChartStatusBar';
import CareTeam from './ChartHeader_/CareTeam';
import Documents from './ChartHeader_/Documents';
import MessagingHistory from './ChartHeader_/MessagingHistory';
//import SchedulingModal from './ChartHeader_/SchedulingModal';

import * as shared_actions from '../../../../actions/shared';
import * as emr_actions from '../../../../actions/emr';

import { safe, isPrescriber } from '../../../../helpers/reduxSelectors';
import { isEmpty } from '../../../../helpers/js';
import { genders } from '../../../../helpers/genders';

class ChartHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: parseInt(this.props.match.params.patient_id, 10),
      alertsVisible: false,
      careTeamDialogVisible: false,
      documentsDialogVisible: false,
      messagingHistoryDialogVisible: false,
      schedulingDialogVisible: false,
    };
  }

  componentDidMount() {
    this.props.loadPatient(this.state.patientId);
    this.props.loadAlerts(this.state.patientId);
    this.props.loadCareTeam(this.state.patientId);
  }

  componentDidUpdate(prevProps) {
    if (parseInt(this.props.match.params.patient_id, 10) !== this.state.patientId) {
      this.setState(
        {
          patientId: parseInt(this.props.match.params.patient_id, 10),
        },
        () => {
          this.props.loadPatient(this.state.patientId);
          this.props.loadAlerts(this.state.patientId);
          this.props.loadCareTeam(this.state.patientId);
        },
      );
    }

    if (prevProps.archive !== this.props.archive) {
      if (this.props.archive.success) {
        this.props.chartArchiveClear();
        this.props.loadPatient(this.state.patientId);
      } else if (this.props.archive.error) {
        message.error(safe(this.props.archive, 'error.data.message', "Can't archive chart"));
        this.props.chartArchiveClear();
      }
    }

    if (prevProps.registration_toggle !== this.props.registration_toggle) {
      if (this.props.registration_toggle.success) {
        this.props.toggleRegistrationClear();
        this.props.loadPatient(this.state.patientId);
      } else if (this.props.registration_toggle.error) {
        this.props.toggleRegistrationClear();
        message.error('Unable to toggle registration');
      }
    }

    if (prevProps.unarchive !== this.props.unarchive) {
      if (this.props.unarchive.success) {
        this.props.chartUnarchiveClear();
        this.props.loadPatient(this.state.patientId);
      } else if (this.props.unarchive.error) {
        this.props.chartUnarchiveClear();
        message.error('Unable to unarchive chart');
      }
    }

    if (prevProps.placeHold !== this.props.placeHold) {
      if (this.props.placeHold.success) {
        this.props.chartPlaceHoldClear();
        this.props.loadPatient(this.state.patientId);
      } else if (this.props.placeHold.error) {
        this.props.chartPlaceHoldClear();
        message.error('Unable to place hold on chart');
      }
    }

    if (prevProps.removeHold !== this.props.removeHold) {
      if (this.props.removeHold.success) {
        this.props.chartRemoveHoldClear();
        this.props.loadPatient(this.state.patientId);
      } else if (this.props.removeHold.error) {
        this.props.chartRemoveHoldClear();
        message.error('Unable to remove hold on chart');
      }
    }
  }

  label = (x) => {
    return this.props.print ? x + ': ' : null;
  };

  getPatientName = () => {
    const { full_name, id } = this.props.patientData;
    return full_name + ' (' + numeral(id).format('000000') + ')';
  };

  getPatientAddress = () => {
    const { address1, address2 } = this.props.patientData;

    if (address1 && address2 && address1.length > 0 && address2.length > 0) {
      return (
        <div>
          {this.label('Address')}
          {address1},{address2}
        </div>
      );
    } else if (address1 && address1.length > 0) {
      return (
        <div>
          {this.label('Address')}
          {address1}
        </div>
      );
    } else if (address2 && address2.length > 0) {
      return (
        <div>
          {this.label('Address')}
          {address2}
        </div>
      );
    }
    return null;
  };

  getPatientCityStateZip = () => {
    const { city, state, zip } = this.props.patientData;
    if (city && state && zip) {
      return (
        <div>
          {city}, {state}, {zip}
        </div>
      );
    } else {
      return null;
    }
  };

  getPatientPhone = () => {
    const { phone } = this.props.patientData;
    return phone ? (
      <div>
        {this.label('Phone')}
        {phone}
      </div>
    ) : null;
  };

  getPatientInsurance = () => {
    const { carrier_name } = this.props.patientData;
    return carrier_name ? (
      <div>
        {this.label('Insurance')}
        {carrier_name}
      </div>
    ) : null;
  };

  getPatientEmail = () => {
    const { email } = this.props.patientData;
    return email ? (
      <div>
        {this.label('Email')}
        {email}
      </div>
    ) : null;
  };

  getPatientDOB = () => {
    const { dob, age } = this.props.patientData;
    const dobString = moment(dob).tz('America/Los_Angeles').format('M/D/YYYY');
    return dob ? (
      <div>
        {this.label('Date of Birth')}
        {dobString} ({age} years old)
      </div>
    ) : null;
  };

  getPatientGender = () => {
    const { gender } = this.props.patientData;
    const record = genders.find((a) => a.id === gender);
    return record ? (
      <div>
        {this.label('Gender')}
        {record.text}
      </div>
    ) : null;
  };

  getEmergencyContactName = () => {
    const { ec_name, ec_relation } = this.props.patientData;
    if (ec_name) {
      if (ec_relation && ec_relation.length > 0) {
        return (
          <div>
            EC: {ec_name} ({ec_relation})
          </div>
        );
      } else {
        return <div>EC: {ec_name}</div>;
      }
    }
    return null;
  };

  getEmergencyContactContact = () => {
    const { ec_email, ec_phone } = this.props.patientData;
    let result = [];

    if (ec_phone) {
      result.push(<div key="phone">{ec_phone}</div>);
    }

    if (ec_email) {
      result.push(<div key="email">{ec_email}</div>);
    }
    return result;
  };

  showEditButton = () => {
    if (this.props.print) {
      return null;
    }

    return (
      <Link to={'/emr/patients/' + this.state.patientId + '/edit'}>
        <EditOutlined style={{ fontSize: '18pt' }} />
      </Link>
    );
  };

  showDocumentsButton = () => {
    if (this.props.print) {
      return null;
    }
    return (
      <a onClick={this.showDocumentsDialog}>
        <InboxOutlined style={{ fontSize: '18pt' }} />
      </a>
    );
  };

  showDocumentsDialog = () => {
    this.setState({
      documentsDialogVisible: true,
    });
  };

  closeDocumentsDialog = () => {
    this.setState({
      documentsDialogVisible: false,
    });
  };

  showMessagingHistoryDialog = () => {
    this.setState({
      messagingHistoryDialogVisible: true,
    });
  };

  closeMessagingHistoryDialog = () => {
    this.setState({
      messagingHistoryDialogVisible: false,
    });
  };

  showMessagingHistoryButton = () => {
    if (this.props.print) {
      return null;
    }

    return (
      <a onClick={this.showMessagingHistoryDialog} style={{ marginLeft: '20px' }}>
        <MailOutlined style={{ fontSize: '14pt' }} />
      </a>
    );
  };

  /*
  drfirst = () => {
    const url = '/display/rcopia/'+this.state.patientId;
    const win = window.open(url, '_blank');
    win.focus();
  }

  showRCopiaButton = () => {
    if (this.props.print || !this.props.isPrescriber) {
      return null;
    }

    return (
      <Button size='small' style={{marginLeft:'10px',backgroundColor:'orange',color:'white',borderColor:'orange'}} type='primary' onClick={()=>{this.drfirst()}}>ePrescribe</Button>
    );
  }
  */

  showAlerts = () => {
    this.setState({
      alertsVisible: true,
    });
  };

  closeAlerts = () => {
    this.setState({
      alertsVisible: false,
    });
  };

  showSchedulingDialog = () => {
    this.props.history.push('/emr/search/' + this.state.patientId);
    /*
    this.setState({
      schedulingDialogVisible: true
    });
    */
  };

  closeSchedulingDialog = () => {
    this.setState({
      schedulingDialogVisible: false,
    });
  };

  showAlertsButton = () => {
    if (this.props.print) {
      return null;
    }

    const alertsCount = this.props.alertsList ? this.props.alertsList.length : 0;
    return (
      <Badge count={alertsCount} showZero={false}>
        <a onClick={this.showAlerts}>
          <BellOutlined style={{ fontSize: '18pt' }} />
        </a>
      </Badge>
    );
  };

  getCareTeam = () => {
    if (this.props.careteam) {
      const active = this.props.careteam.filter((y) => y.active);
      if (active.length === 0) {
        return <div>No active members</div>;
      }

      let receptionist = safe(this.props, 'patientData.receptionist.full_name');
      if (receptionist) {
        receptionist = 'Reception (' + receptionist + ')';
      } else {
        receptionist = 'Reception';
      }
      let billingist = safe(this.props, 'patientData.billingist.full_name');
      if (billingist) {
        billingist = 'Billing (' + billingist + ')';
      } else {
        billingist = 'Billing';
      }

      return active.map((x) => (
        <div key={x.id}>
          {x.user && safe(x, 'user.full_name')}
          {x.group && x.group.id === 1 && receptionist}
          {x.group && x.group.id === 2 && billingist}
          {x.group && x.group.id !== 1 && x.group.id !== 2 && safe(x, 'group.name')}
        </div>
      ));
    }
    return null;
  };

  closeCareTeamDialog = () => {
    this.setState({
      careTeamDialogVisible: false,
    });
  };

  openCareTeamDialog = () => {
    this.setState({
      careTeamDialogVisible: true,
    });
  };

  render() {
    if (!this.props.patientData || !safe(this.props, 'patientData.id') || isEmpty(this.props.patientData)) {
      return null;
    }

    if (this.props.print) {
      return (
        <div style={{ backgroundColor: 'rgb(248,248,248)', padding: '15px', borderBottom: '1px solid #e8e8e8' }}>
          <h3>{this.getPatientName()} </h3>
          {this.getPatientAddress()}
          {this.getPatientCityStateZip()}
          {this.getPatientPhone()}
          {this.getPatientEmail()}
          {this.getPatientDOB()}
          {this.getPatientGender()}
          {this.getEmergencyContactName()}
          {this.getEmergencyContactContact()}
        </div>
      );
    } else {
      return (
        <div>
          <ChartStatusBar
            isChartOnHold={this.props.isChartOnHold}
            isChartOpen={this.props.isChartOpen}
            isPortalRegistered={this.props.isPortalRegistered}
            isRegistrationComplete={this.props.isRegistrationComplete}
          />
          <div style={{ backgroundColor: 'rgb(248,248,248)', padding: '12px', borderBottom: '1px solid #e8e8e8' }}>
            <h3>
              {this.getPatientName()}
              {this.showEditButton()}
              {this.showDocumentsButton()}
              {this.showAlertsButton()}
            </h3>
            <Row gutter={16} style={{ width: '100%' }}>
              <Col span={6}>
                {this.getPatientAddress()}
                {this.getPatientCityStateZip()}
                {this.getPatientPhone()}
                {this.getPatientInsurance()}
              </Col>
              <Col span={6}>
                {this.getPatientDOB()}
                {this.getPatientEmail()}
                {this.getPatientGender()}
              </Col>
              <Col span={6}>
                {this.getEmergencyContactName()}
                {this.getEmergencyContactContact()}
              </Col>
              <Col span={6}>
                <div>
                  <a onClick={this.openCareTeamDialog}>Care Team</a>
                  {this.showMessagingHistoryButton()}
                </div>
                {this.getCareTeam()}
              </Col>
            </Row>
            <Alerts
              visible={this.state.alertsVisible}
              alertData={this.props.alertsList}
              patientId={this.state.patientId}
              closeDialog={this.closeAlerts}
            />
            <CareTeam
              data={this.props.careteam}
              visible={this.state.careTeamDialogVisible}
              patientId={this.state.patientId}
              closeDialog={this.closeCareTeamDialog}
            />
            <Documents
              patientId={this.state.patientId}
              visible={this.state.documentsDialogVisible}
              closeDialog={this.closeDocumentsDialog}
            />
            <MessagingHistory
              patientId={this.state.patientId}
              visible={this.state.messagingHistoryDialogVisible}
              closeDialog={this.closeMessagingHistoryDialog}
            />
          </div>
        </div>
      );
    }
  }
}

/*
<SchedulingModal
  patientId={this.state.patientId}
  visible={this.state.schedulingDialogVisible}
  onClose={this.closeSchedulingDialog}
/>
*/

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state, 'shared.patient.load.processing', false) ? true : false,
    patientData: safe(state, 'shared.patient.load.data', {}),
    alertsList: safe(state, 'shared.alert.list.data', []),
    isChartOnHold: safe(state, 'shared.patient.load.data.on_hold', false),
    isChartOpen: safe(state, 'shared.patient.load.data.open', true),
    isPortalRegistered: safe(state, 'shared.patient.load.data.portal_registered'),
    isRegistrationComplete: safe(state, 'shared.patient.load.data.registration_complete'),
    registration_toggle: safe(state, 'emr.registration.toggle', {}),
    archive: safe(state, 'shared.patient.archive', {}),
    unarchive: safe(state, 'shared.patient.unarchive', {}),
    placeHold: safe(state, 'shared.patient.placehold', {}),
    removeHold: safe(state, 'shared.patient.removehold', {}),
    careteam: safe(state, 'shared.careteam.list.data', []),
    isPrescriber: isPrescriber(state),
  };
};

export default connect(mapStateToProps, { ...shared_actions, ...emr_actions })(ChartHeader);
