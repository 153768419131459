import React, { Component } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Button,
  Row,
  Col,
  message,
  Checkbox,
  Select,
  DatePicker,
  Spin,
  Divider,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { safe } from '../../helpers/reduxSelectors';
import { usStateAbbrOptions } from '../../helpers/states';
import { genders } from '../../helpers/genders';
import * as actions from '../../actions/shared';
import PhoneInput from './PhoneInput';

class EditPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: parseInt(this.props.match.params.patient_id,10)
    }
  }

  componentDidMount() {
    this.props.loadPatient(this.state.patientId);
    this.props.loadAdminStaff();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.save !== this.props.save) {
      if (this.props.save.success) {
        this.props.history.push('../'+this.state.patientId);
        message.success('Patient Saved');
      } else if (this.props.save.error) {
        this.props.history.push('../'+this.state.patientId);
        message.error('Unable to Save Patient');
      }
    }
  }

  onSaveButton = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.savePatient(this.state.patientId,values);
      }
    });
  }

  onCancelButton = () => {
    this.props.history.push('../'+this.state.patientId);
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };

    return (
      <Form autoComplete="off" className='editpatient-form'>
        <FormItem {...formItemLayout} label="First name">
          {getFieldDecorator('firstname', {
            rules: [{ required: true, message: "Please enter a first name", whitespace: true }],
            initialValue: this.props.patientData.firstname
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Last name">
          {getFieldDecorator('lastname', {
            rules: [{ required: true, message: "Please enter a last name", whitespace: true }],
            initialValue: this.props.patientData.lastname
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Middle name"
        >
          {getFieldDecorator('middlename', {
            initialValue: this.props.patientData.middlename
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Suffix"
        >
          {getFieldDecorator('suffix', {
            initialValue: this.props.patientData.suffix
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Nickname"
        >
          {getFieldDecorator('nickname', {
            initialValue: this.props.patientData.nickname
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Email"
        >
          {getFieldDecorator('email', {
            rules: [{
              type: 'email', message: 'This is not valid email address'
            }, {
              required: true, message: 'Please input an email address'
            }
            ],
            initialValue: this.props.patientData.email
          })(
            <Input />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Date of Birth">
          {getFieldDecorator('dob', {
            rules: [{ required: true, message: "Enter date of birth" }],
            initialValue: (this.props.patientData.dob) ? moment(this.props.patientData.dob).tz('America/Los_Angeles') : null
          })(
            <DatePicker format='M/D/YYYY' />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Gender"
        >
          {getFieldDecorator('gender', {
            initialValue: this.props.patientData.gender
          })(
            <Select>
              { genders.map(a=>(<Option key={a.id} value={a.id}>{a.text}</Option>)) }
            </Select>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="SSN"
        >
          {getFieldDecorator('ssn', {
            initialValue: this.props.patientData.ssn
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Address 1"
        >
          {getFieldDecorator('address1', {
            initialValue: this.props.patientData.address1
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Address 2"
        >
          {getFieldDecorator('address2', {
            initialValue: this.props.patientData.address2
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="City"
        >
          {getFieldDecorator('city', {
            initialValue: this.props.patientData.city
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="State">
          {getFieldDecorator('state', {
            initialValue: (this.props.patientData.state) ?  this.props.patientData.state : 'CA'
          })(
            <Select autoComplete="off">
              {usStateAbbrOptions}
            </Select>
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Zip">
          {getFieldDecorator('zip', {
            initialValue: this.props.patientData.zip
          })(
            <Input autoComplete="off"/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Phone"
        >
          {getFieldDecorator('phone', {
            initialValue: this.props.patientData.phone
          })(
            <PhoneInput />
          )}
        </FormItem>

        <Divider>Emergency Contact</Divider>

        <FormItem
          {...formItemLayout}
          label="Emergency Contact Name"
        >
          {getFieldDecorator('ec_name', {
            initialValue: this.props.patientData.ec_name
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Emergency Contact Relationship"
        >
          {getFieldDecorator('ec_relation', {
            initialValue: this.props.patientData.ec_relation
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Emergency Contact Phone"
        >
          {getFieldDecorator('ec_phone', {
            initialValue: this.props.patientData.ec_phone
          })(
            <PhoneInput />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Emergency Contact Email"
        >
          {getFieldDecorator('ec_email', {
            rules: [{
              type: 'email', message: 'This is not valid email address'
            }],
            initialValue: this.props.patientData.ec_email
          })(
            <Input />
          )}
        </FormItem>

        <Divider>Guardian Information</Divider>

        <FormItem
          {...formItemLayout}
          label="Guardian #1 Name"
        >
          {getFieldDecorator('guardian_name', {
            initialValue: this.props.patientData.guardian_name
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Relationship to Guardian #1"
        >
          {getFieldDecorator('guardian_relation', {
            initialValue: this.props.patientData.guardian_relation
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Guardian #1 Phone"
        >
          {getFieldDecorator('guardian_phone', {
            initialValue: this.props.patientData.guardian_phone
          })(
            <PhoneInput />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Guardian #1 Email"
        >
          {getFieldDecorator('guardian_email', {
            rules: [{
              type: 'email', message: 'This is not valid email address'
            }],
            initialValue: this.props.patientData.guardian_email
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Guardian #2 Name"
        >
          {getFieldDecorator('guardian_name2', {
            initialValue: this.props.patientData.guardian_name2
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Relationship to Guardian #2"
        >
          {getFieldDecorator('guardian_relation2', {
            initialValue: this.props.patientData.guardian_relation2
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Guardian #2 Phone"
        >
          {getFieldDecorator('guardian_phone2', {
            initialValue: this.props.patientData.guardian_phone2
          })(
            <PhoneInput />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Guardian #2 Email"
        >
          {getFieldDecorator('guardian_email2', {
            rules: [{
              type: 'email', message: 'This is not valid email address'
            }],
            initialValue: this.props.patientData.guardian_email2
          })(
            <Input />
          )}
        </FormItem>

        <Divider>Administrative Settings</Divider>

        <FormItem {...formItemLayout} label='Assigned Receptionist'>
          {getFieldDecorator('receptionist_id',{
            initialValue: this.props.patientData.receptionist_id
          })(
            <Select>
              <Option key={null} value={null}>None</Option>
              {this.props.adminList.filter(x=>x.group_id===1).map(x=>(
                <Option key={x.id} value={x.user_id}>
                  {safe(x,'user.full_name')}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Assigned Biller'>
          {getFieldDecorator('billingist_id',{
            initialValue: this.props.patientData.billingist_id
          })(
            <Select>
              <Option key={null} value={null}>None</Option>
              {this.props.adminList.filter(x=>x.group_id===2).map(x=>(
                <Option key={x.id} value={x.user_id}>
                  {safe(x,'user.full_name')}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Test Account'>
          {getFieldDecorator('test_account',{
            initialValue: this.props.patientData.test_account,
            valuePropName: 'checked'
          })(
            <Checkbox/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="HealthFusion ID"
        >
          {getFieldDecorator('hfid', {
            initialValue: this.props.patientData.hfid
          })(
            <Input disabled={true}/>
          )}
        </FormItem>

        <Row style={{paddingTop:'20px'}}>
          <Col xs={12} sm={6}></Col>
          <Col xs={24} sm={10}>

            <Button
              onClick={this.onCancelButton}
              style={{float:'left'}}
              disabled={this.props.isBusy}
              type="danger"
              icon={<DeleteOutlined />}
            >
              Cancel
            </Button>

            <Button
              onClick={this.onSaveButton}
              style={{float:'right'}}
              disabled={this.props.isBusy}
              type="primary"
            >
              Save
            </Button>

          </Col>
        </Row>

      </Form>
    );
  }

  render() {
    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <h3>Edit Patient Information</h3>
          { this.renderForm() }
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'shared.patient.load.processing',false) ||
      safe(state,'shared.patient.save.processing',false) ||
      safe(state,'shared.adminList.processing,false')
    ) ? true : false,
    patientData: safe(state,'shared.patient.load.data',{}),
    save: safe(state,'shared.patient.save',{}),
    adminList: safe(state,'shared.adminList.data',[])
  }
}

export default connect(mapStateToProps,actions)(Form.create()(withRouter(EditPatient)));
