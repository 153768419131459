export const x12CarCodes = [
  { xActive:true,xCode:"1",xDescription:"Deductible Amount",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"2",xDescription:"Coinsurance Amount",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"3",xDescription:"Co-payment Amount",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"4",xDescription:"The procedure code is inconsistent with the modifier used or a required modifier is missing. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"5",xDescription:"The procedure code/type of bill is inconsistent with the place of service. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 03/01/2018"},
  { xActive:true,xCode:"6",xDescription:"The procedure/revenue code is inconsistent with the patient's age. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"7",xDescription:"The procedure/revenue code is inconsistent with the patient's gender. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"8",xDescription:"The procedure code is inconsistent with the provider type/specialty (taxonomy). Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"9",xDescription:"The diagnosis is inconsistent with the patient's age. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"10",xDescription:"The diagnosis is inconsistent with the patient's gender. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"11",xDescription:"The diagnosis is inconsistent with the procedure. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"12",xDescription:"The diagnosis is inconsistent with the provider type. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"13",xDescription:"The date of death precedes the date of service.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"14",xDescription:"The date of birth follows the date of service.",xRange:"Start: 01/01/1995"},
  { xActive:false,xCode:"15",xDescription:"The authorization number is missing, invalid, or does not apply to the billed services or provider.",xRange:"Start: 01/01/1995 | Last Modified: 11/01/2017 | Stop: 05/01/2018"},
  { xActive:true,xCode:"16",xDescription:"Claim/service lacks information or has submission/billing error(s). Usage: Do not use this code for claims attachment(s)/other documentation. At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT.) Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 03/01/2018"},
  { xActive:false,xCode:"17",xDescription:"Requested information was not provided or was insufficient/incomplete. At least one Remark Code must be provided (may be comprised of either the Remittance Advice Remark Code or NCPDP Reject Reason Code.)",xRange:"Start: 01/01/1995 | Last Modified: 09/21/2008 | Stop: 07/01/2009"},
  { xActive:true,xCode:"18",xDescription:"Exact duplicate claim/service (Use only with Group Code OA except where state workers' compensation regulations requires CO)",xRange:"Start: 01/01/1995 | Last Modified: 06/02/2013"},
  { xActive:true,xCode:"19",xDescription:"This is a work-related injury/illness and thus the liability of the Worker's Compensation Carrier.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"20",xDescription:"This injury/illness is covered by the liability carrier.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"21",xDescription:"This injury/illness is the liability of the no-fault carrier.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"22",xDescription:"This care may be covered by another payer per coordination of benefits.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"23",xDescription:"The impact of prior payer(s) adjudication including payments and/or adjustments. (Use only with Group Code OA)",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2012"},
  { xActive:true,xCode:"24",xDescription:"Charges are covered under a capitation agreement/managed care plan.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:false,xCode:"25",xDescription:"Payment denied. Your Stop loss deductible has not been met.",xRange:"Start: 01/01/1995 | Stop: 04/01/2008"},
  { xActive:true,xCode:"26",xDescription:"Expenses incurred prior to coverage.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"27",xDescription:"Expenses incurred after coverage terminated.",xRange:"Start: 01/01/1995"},
  { xActive:false,xCode:"28",xDescription:"Coverage not in effect at the time the service was provided.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Redundant to codes 26&27.</i>"},
  { xActive:true,xCode:"29",xDescription:"The time limit for filing has expired.",xRange:"Start: 01/01/1995"},
  { xActive:false,xCode:"30",xDescription:"Payment adjusted because the patient has not met the required eligibility, spend down, waiting, or residency requirements.",xRange:"Start: 01/01/1995 | Stop: 02/01/2006"},
  { xActive:true,xCode:"31",xDescription:"Patient cannot be identified as our insured.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"32",xDescription:"Our records indicate the patient is not an eligible dependent.",xRange:"Start: 01/01/1995 | Last Modified: 03/01/2018"},
  { xActive:true,xCode:"33",xDescription:"Insured has no dependent coverage.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"34",xDescription:"Insured has no coverage for newborns.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"35",xDescription:"Lifetime benefit maximum has been reached.",xRange:"Start: 01/01/1995 | Last Modified: 10/31/2002"},
  { xActive:false,xCode:"36",xDescription:"Balance does not exceed co-payment amount.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"37",xDescription:"Balance does not exceed deductible.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"38",xDescription:"Services not provided or authorized by designated (network/primary care) providers.",xRange:"Start: 01/01/1995 | Last Modified: 06/02/2013 | Stop: 01/01/2013<br /><i>Notes: CARC codes 242 and 243 are replacements for this deactivated code</i>"},
  { xActive:true,xCode:"39",xDescription:"Services denied at the time authorization/pre-certification was requested.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"40",xDescription:"Charges do not meet qualifications for emergent/urgent care. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:false,xCode:"41",xDescription:"Discount agreed to in Preferred Provider contract.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"42",xDescription:"Charges exceed our fee schedule or maximum allowable amount. (Use CARC 45)",xRange:"Start: 01/01/1995 | Last Modified: 10/31/2006 | Stop: 06/01/2007"},
  { xActive:false,xCode:"43",xDescription:"Gramm-Rudman reduction.",xRange:"Start: 01/01/1995 | Stop: 07/01/2006"},
  { xActive:true,xCode:"44",xDescription:"Prompt-pay discount.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"45",xDescription:"Charge exceeds fee schedule/maximum allowable or contracted/legislated fee arrangement. Usage: This adjustment amount cannot equal the total service or claim charge amount; and must not duplicate provider adjustment amounts (payments and contractual reductions) that have resulted from prior payer(s) adjudication. (Use only with Group Codes PR or CO depending upon liability)",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:false,xCode:"46",xDescription:"This (these) service(s) is (are) not covered.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 96.</i>"},
  { xActive:false,xCode:"47",xDescription:"This (these) diagnosis(es) is (are) not covered, missing, or are invalid.",xRange:"Start: 01/01/1995 | Stop: 02/01/2006"},
  { xActive:false,xCode:"48",xDescription:"This (these) procedure(s) is (are) not covered.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 96.</i>"},
  { xActive:true,xCode:"49",xDescription:"This is a non-covered service because it is a routine/preventive exam or a diagnostic/screening procedure done in conjunction with a routine/preventive exam. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"50",xDescription:"These are non-covered services because this is not deemed a 'medical necessity' by the payer. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"51",xDescription:"These are non-covered services because this is a pre-existing condition. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:false,xCode:"52",xDescription:"The referring/prescribing/rendering provider is not eligible to refer/prescribe/order/perform the service billed.",xRange:"Start: 01/01/1995 | Stop: 02/01/2006"},
  { xActive:true,xCode:"53",xDescription:"Services by an immediate relative or a member of the same household are not covered.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"54",xDescription:"Multiple physicians/assistants are not covered in this case. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"55",xDescription:"Procedure/treatment/drug is deemed experimental/investigational by the payer. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"56",xDescription:"Procedure/treatment has not been deemed 'proven to be effective' by the payer. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:false,xCode:"57",xDescription:"Payment denied/reduced because the payer deems the information submitted does not support this level of service, this many services, this length of service, this dosage, or this day's supply.",xRange:"Start: 01/01/1995 | Stop: 06/30/2007<br /><i>Notes: Split into codes 150, 151, 152, 153 and 154.</i>"},
  { xActive:true,xCode:"58",xDescription:"Treatment was deemed by the payer to have been rendered in an inappropriate or invalid place of service. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"59",xDescription:"Processed based on multiple or concurrent procedure rules. (For example multiple surgery or diagnostic imaging, concurrent anesthesia.) Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"60",xDescription:"Charges for outpatient services are not covered when performed within a period of time prior to or after inpatient services.",xRange:"Start: 01/01/1995 | Last Modified: 06/01/2008"},
  { xActive:true,xCode:"61",xDescription:"Adjusted for failure to obtain second surgical opinion",xRange:"Start: 01/01/1995 | Last Modified: 03/01/2017<br /><i>Notes: The description effective date was inadvertently published as 3/1/2016 on 7/1/2016. That has been corrected to 1/1/2017.</i>"},
  { xActive:false,xCode:"62",xDescription:"Payment denied/reduced for absence of, or exceeded, pre-certification/authorization.",xRange:"Start: 01/01/1995 | Last Modified: 10/31/2006 | Stop: 04/01/2007"},
  { xActive:false,xCode:"63",xDescription:"Correction to a prior claim.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"64",xDescription:"Denial reversed per Medical Review.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"65",xDescription:"Procedure code was incorrect. This payment reflects the correct code.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:true,xCode:"66",xDescription:"Blood Deductible.",xRange:"Start: 01/01/1995"},
  { xActive:false,xCode:"67",xDescription:"Lifetime reserve days. (Handled in QTY, QTY01=LA)",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"68",xDescription:"DRG weight. (Handled in CLP12)",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:true,xCode:"69",xDescription:"Day outlier amount.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"70",xDescription:"Cost outlier - Adjustment to compensate for additional costs.",xRange:"Start: 01/01/1995 | Last Modified: 06/30/2001"},
  { xActive:false,xCode:"71",xDescription:"Primary Payer amount.",xRange:"Start: 01/01/1995 | Stop: 06/30/2000<br /><i>Notes: Use code 23.</i>"},
  { xActive:false,xCode:"72",xDescription:"Coinsurance day. (Handled in QTY, QTY01=CD)",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"73",xDescription:"Administrative days.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:true,xCode:"74",xDescription:"Indirect Medical Education Adjustment.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"75",xDescription:"Direct Medical Education Adjustment.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"76",xDescription:"Disproportionate Share Adjustment.",xRange:"Start: 01/01/1995"},
  { xActive:false,xCode:"77",xDescription:"Covered days. (Handled in QTY, QTY01=CA)",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:true,xCode:"78",xDescription:"Non-Covered days/Room charge adjustment.",xRange:"Start: 01/01/1995"},
  { xActive:false,xCode:"79",xDescription:"Cost Report days. (Handled in MIA15)",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"80",xDescription:"Outlier days. (Handled in QTY, QTY01=OU)",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"81",xDescription:"Discharges.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"82",xDescription:"PIP days.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"83",xDescription:"Total visits.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"84",xDescription:"Capital Adjustment. (Handled in MIA)",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:true,xCode:"85",xDescription:"Patient Interest Adjustment (Use Only Group code PR)",xRange:"Start: 01/01/1995 | Last Modified: 07/09/2007<br /><i>Notes: Only use when the payment of interest is the responsibility of the patient.</i>"},
  { xActive:false,xCode:"86",xDescription:"Statutory Adjustment.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Duplicative of code 45.</i>"},
  { xActive:false,xCode:"87",xDescription:"Transfer amount.",xRange:"Start: 01/01/1995 | Last Modified: 09/20/2009 | Stop: 01/01/2012"},
  { xActive:false,xCode:"88",xDescription:"Adjustment amount represents collection against receivable created in prior overpayment.",xRange:"Start: 01/01/1995 | Stop: 06/30/2007"},
  { xActive:true,xCode:"89",xDescription:"Professional fees removed from charges.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"90",xDescription:"Ingredient cost adjustment. Usage: To be used for pharmaceuticals only.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"91",xDescription:"Dispensing fee adjustment.",xRange:"Start: 01/01/1995"},
  { xActive:false,xCode:"92",xDescription:"Claim Paid in full.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"93",xDescription:"No Claim level Adjustments.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: As of 004010, CAS at the claim level is optional.</i>"},
  { xActive:true,xCode:"94",xDescription:"Processed in Excess of charges.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"95",xDescription:"Plan procedures not followed.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"96",xDescription:"Non-covered charge(s). At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT.) Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"97",xDescription:"The benefit for this service is included in the payment/allowance for another service/procedure that has already been adjudicated. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:false,xCode:"98",xDescription:"The hospital must file the Medicare claim for this inpatient non-physician service.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"99",xDescription:"Medicare Secondary Payer Adjustment Amount.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:true,xCode:"100",xDescription:"Payment made to patient/insured/responsible party.",xRange:"Start: 01/01/1995 | Last Modified: 05/01/2018"},
  { xActive:true,xCode:"101",xDescription:"Predetermination: anticipated payment upon completion of services or claim adjudication.",xRange:"Start: 01/01/1995 | Last Modified: 02/28/1999"},
  { xActive:true,xCode:"102",xDescription:"Major Medical Adjustment.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"103",xDescription:"Provider promotional discount (e.g., Senior citizen discount).",xRange:"Start: 01/01/1995 | Last Modified: 06/30/2001"},
  { xActive:true,xCode:"104",xDescription:"Managed care withholding.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"105",xDescription:"Tax withholding.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"106",xDescription:"Patient payment option/election not in effect.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"107",xDescription:"The related or qualifying claim/service was not identified on this claim. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"108",xDescription:"Rent/purchase guidelines were not met. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"109",xDescription:"Claim/service not covered by this payer/contractor. You must send the claim/service to the correct payer/contractor.",xRange:"Start: 01/01/1995 | Last Modified: 01/29/2012"},
  { xActive:true,xCode:"110",xDescription:"Billing date predates service date.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"111",xDescription:"Not covered unless the provider accepts assignment.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"112",xDescription:"Service not furnished directly to the patient and/or not documented.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:false,xCode:"113",xDescription:"Payment denied because service/procedure was provided outside the United States or as a result of war.",xRange:"Start: 01/01/1995 | Last Modified: 02/28/2001 | Stop: 06/30/2007<br /><i>Notes: Use Codes 157, 158 or 159.</i>"},
  { xActive:true,xCode:"114",xDescription:"Procedure/product not approved by the Food and Drug Administration.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"115",xDescription:"Procedure postponed, canceled, or delayed.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"116",xDescription:"The advance indemnification notice signed by the patient did not comply with requirements.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"117",xDescription:"Transportation is only covered to the closest facility that can provide the necessary care.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"118",xDescription:"ESRD network support adjustment.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"119",xDescription:"Benefit maximum for this time period or occurrence has been reached.",xRange:"Start: 01/01/1995 | Last Modified: 02/29/2004"},
  { xActive:false,xCode:"120",xDescription:"Patient is covered by a managed care plan.",xRange:"Start: 01/01/1995 | Stop: 06/30/2007<br /><i>Notes: Use code 24.</i>"},
  { xActive:true,xCode:"121",xDescription:"Indemnification adjustment - compensation for outstanding member responsibility.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"122",xDescription:"Psychiatric reduction.",xRange:"Start: 01/01/1995"},
  { xActive:false,xCode:"123",xDescription:"Payer refund due to overpayment.",xRange:"Start: 01/01/1995 | Stop: 06/30/2007<br /><i>Notes: Refer to implementation guide for proper handling of reversals.</i>"},
  { xActive:false,xCode:"124",xDescription:"Payer refund amount - not our patient.",xRange:"Start: 01/01/1995 | Last Modified: 06/30/1999 | Stop: 06/30/2007<br /><i>Notes: Refer to implementation guide for proper handling of reversals.</i>"},
  { xActive:false,xCode:"125",xDescription:"Submission/billing error(s). At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT.)",xRange:"Start: 01/01/1995 | Last Modified: 09/20/2009 | Stop: 11/01/2013"},
  { xActive:false,xCode:"126",xDescription:"Deductible -- Major Medical",xRange:"Start: 02/28/1997 | Last Modified: 09/30/2007 | Stop: 04/01/2008<br /><i>Notes: Use Group Code PR and code 1.</i>"},
  { xActive:false,xCode:"127",xDescription:"Coinsurance -- Major Medical",xRange:"Start: 02/28/1997 | Last Modified: 09/30/2007 | Stop: 04/01/2008<br /><i>Notes: Use Group Code PR and code 2.</i>"},
  { xActive:true,xCode:"128",xDescription:"Newborn's services are covered in the mother's Allowance.",xRange:"Start: 02/28/1997"},
  { xActive:true,xCode:"129",xDescription:"Prior processing information appears incorrect. At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT.)",xRange:"Start: 02/28/1997 | Last Modified: 01/30/2011"},
  { xActive:true,xCode:"130",xDescription:"Claim submission fee.",xRange:"Start: 02/28/1997 | Last Modified: 06/30/2001"},
  { xActive:true,xCode:"131",xDescription:"Claim specific negotiated discount.",xRange:"Start: 02/28/1997"},
  { xActive:true,xCode:"132",xDescription:"Prearranged demonstration project adjustment.",xRange:"Start: 02/28/1997"},
  { xActive:true,xCode:"133",xDescription:"The disposition of this service line is pending further review. (Use only with Group Code OA). Usage: Use of this code requires a reversal and correction when the service line is finalized (use only in Loop 2110 CAS segment of the 835 or Loop 2430 of the 837).",xRange:"Start: 07/01/2014 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"134",xDescription:"Technical fees removed from charges.",xRange:"Start: 10/31/1998"},
  { xActive:true,xCode:"135",xDescription:"Interim bills cannot be processed.",xRange:"Start: 10/31/1998 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"136",xDescription:"Failure to follow prior payer's coverage rules. (Use only with Group Code OA)",xRange:"Start: 10/31/1998 | Last Modified: 07/01/2013"},
  { xActive:true,xCode:"137",xDescription:"Regulatory Surcharges, Assessments, Allowances or Health Related Taxes.",xRange:"Start: 02/28/1999 | Last Modified: 09/30/2007"},
  { xActive:false,xCode:"138",xDescription:"Appeal procedures not followed or time limits not met.",xRange:"Start: 06/30/1999 | Last Modified: 11/01/2017 | Stop: 05/01/2018"},
  { xActive:true,xCode:"139",xDescription:"Contracted funding agreement - Subscriber is employed by the provider of services. Use only with Group Code CO.",xRange:"Start: 06/30/1999 | Last Modified: 05/01/2018"},
  { xActive:true,xCode:"140",xDescription:"Patient/Insured health identification number and name do not match.",xRange:"Start: 06/30/1999"},
  { xActive:false,xCode:"141",xDescription:"Claim spans eligible and ineligible periods of coverage.",xRange:"Start: 06/30/1999 | Last Modified: 09/30/2007 | Stop: 07/01/2012"},
  { xActive:true,xCode:"142",xDescription:"Monthly Medicaid patient liability amount.",xRange:"Start: 06/30/2000 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"143",xDescription:"Portion of payment deferred.",xRange:"Start: 02/28/2001"},
  { xActive:true,xCode:"144",xDescription:"Incentive adjustment, e.g. preferred product/service.",xRange:"Start: 06/30/2001"},
  { xActive:false,xCode:"145",xDescription:"Premium payment withholding",xRange:"Start: 06/30/2002 | Last Modified: 09/30/2007 | Stop: 04/01/2008<br /><i>Notes: Use Group Code CO and code 45.</i>"},
  { xActive:true,xCode:"146",xDescription:"Diagnosis was invalid for the date(s) of service reported.",xRange:"Start: 06/30/2002 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"147",xDescription:"Provider contracted/negotiated rate expired or not on file.",xRange:"Start: 06/30/2002"},
  { xActive:true,xCode:"148",xDescription:"Information from another provider was not provided or was insufficient/incomplete. At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT.)",xRange:"Start: 06/30/2002 | Last Modified: 09/20/2009"},
  { xActive:true,xCode:"149",xDescription:"Lifetime benefit maximum has been reached for this service/benefit category.",xRange:"Start: 10/31/2002"},
  { xActive:true,xCode:"150",xDescription:"Payer deems the information submitted does not support this level of service.",xRange:"Start: 10/31/2002 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"151",xDescription:"Payment adjusted because the payer deems the information submitted does not support this many/frequency of services.",xRange:"Start: 10/31/2002 | Last Modified: 01/27/2008"},
  { xActive:true,xCode:"152",xDescription:"Payer deems the information submitted does not support this length of service. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 10/31/2002 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"153",xDescription:"Payer deems the information submitted does not support this dosage.",xRange:"Start: 10/31/2002 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"154",xDescription:"Payer deems the information submitted does not support this day's supply.",xRange:"Start: 10/31/2002 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"155",xDescription:"Patient refused the service/procedure.",xRange:"Start: 06/30/2003 | Last Modified: 09/30/2007"},
  { xActive:false,xCode:"156",xDescription:"Flexible spending account payments. Note: Use code 187.",xRange:"Start: 09/30/2003 | Last Modified: 01/25/2009 | Stop: 10/01/2009"},
  { xActive:true,xCode:"157",xDescription:"Service/procedure was provided as a result of an act of war.",xRange:"Start: 09/30/2003 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"158",xDescription:"Service/procedure was provided outside of the United States.",xRange:"Start: 09/30/2003 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"159",xDescription:"Service/procedure was provided as a result of terrorism.",xRange:"Start: 09/30/2003 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"160",xDescription:"Injury/illness was the result of an activity that is a benefit exclusion.",xRange:"Start: 09/30/2003 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"161",xDescription:"Provider performance bonus",xRange:"Start: 02/29/2004"},
  { xActive:false,xCode:"162",xDescription:"State-mandated Requirement for Property and Casualty, see Claim Payment Remarks Code for specific explanation.",xRange:"Start: 02/29/2004 | Stop: 07/01/2014<br /><i>Notes: Use code P1</i>"},
  { xActive:true,xCode:"163",xDescription:"Attachment/other documentation referenced on the claim was not received.",xRange:"Start: 06/30/2004 | Last Modified: 06/02/2013"},
  { xActive:true,xCode:"164",xDescription:"Attachment/other documentation referenced on the claim was not received in a timely fashion.",xRange:"Start: 06/30/2004 | Last Modified: 06/02/2013"},
  { xActive:false,xCode:"165",xDescription:"Referral absent or exceeded.",xRange:"Start: 10/31/2004 | Last Modified: 11/01/2017 | Stop: 05/01/2018"},
  { xActive:true,xCode:"166",xDescription:"These services were submitted after this payers responsibility for processing claims under this plan ended.",xRange:"Start: 02/28/2005"},
  { xActive:true,xCode:"167",xDescription:"This (these) diagnosis(es) is (are) not covered. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 06/30/2005 | Last Modified: 07/01/2017"},
  { xActive:false,xCode:"168",xDescription:"Service(s) have been considered under the patient's medical plan. Benefits are not available under this dental plan.",xRange:"Start: 06/30/2005 | Last Modified: 11/01/2017 | Stop: 05/01/2018"},
  { xActive:true,xCode:"169",xDescription:"Alternate benefit has been provided.",xRange:"Start: 06/30/2005 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"170",xDescription:"Payment is denied when performed/billed by this type of provider. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 06/30/2005 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"171",xDescription:"Payment is denied when performed/billed by this type of provider in this type of facility. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 06/30/2005 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"172",xDescription:"Payment is adjusted when performed/billed by a provider of this specialty. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 06/30/2005 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"173",xDescription:"Service/equipment was not prescribed by a physician.",xRange:"Start: 06/30/2005 | Last Modified: 07/01/2013"},
  { xActive:true,xCode:"174",xDescription:"Service was not prescribed prior to delivery.",xRange:"Start: 06/30/2005 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"175",xDescription:"Prescription is incomplete.",xRange:"Start: 06/30/2005 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"176",xDescription:"Prescription is not current.",xRange:"Start: 06/30/2005 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"177",xDescription:"Patient has not met the required eligibility requirements.",xRange:"Start: 06/30/2005 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"178",xDescription:"Patient has not met the required spend down requirements.",xRange:"Start: 06/30/2005 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"179",xDescription:"Patient has not met the required waiting requirements. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 06/30/2005 | Last Modified: 03/01/2017"},
  { xActive:true,xCode:"180",xDescription:"Patient has not met the required residency requirements.",xRange:"Start: 06/30/2005 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"181",xDescription:"Procedure code was invalid on the date of service.",xRange:"Start: 06/30/2005 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"182",xDescription:"Procedure modifier was invalid on the date of service.",xRange:"Start: 06/30/2005 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"183",xDescription:"The referring provider is not eligible to refer the service billed. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 06/30/2005 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"184",xDescription:"The prescribing/ordering provider is not eligible to prescribe/order the service billed. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 06/30/2005 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"185",xDescription:"The rendering provider is not eligible to perform the service billed. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 06/30/2005 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"186",xDescription:"Level of care change adjustment.",xRange:"Start: 06/30/2005 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"187",xDescription:"Consumer Spending Account payments (includes but is not limited to Flexible Spending Account, Health Savings Account, Health Reimbursement Account, etc.)",xRange:"Start: 06/30/2005 | Last Modified: 01/25/2009"},
  { xActive:true,xCode:"188",xDescription:"This product/procedure is only covered when used according to FDA recommendations.",xRange:"Start: 06/30/2005"},
  { xActive:true,xCode:"189",xDescription:"'Not otherwise classified' or 'unlisted' procedure code (CPT/HCPCS) was billed when there is a specific procedure code for this procedure/service",xRange:"Start: 06/30/2005"},
  { xActive:true,xCode:"190",xDescription:"Payment is included in the allowance for a Skilled Nursing Facility (SNF) qualified stay.",xRange:"Start: 10/31/2005"},
  { xActive:false,xCode:"191",xDescription:"Not a work related injury/illness and thus not the liability of the workers' compensation carrier Note: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') for the jurisdictional regulation. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF)",xRange:"Start: 10/31/2005 | Last Modified: 10/17/2010 | Stop: 07/01/2014<br /><i>Notes: Use code P2</i>"},
  { xActive:true,xCode:"192",xDescription:"Non standard adjustment code from paper remittance. Usage: This code is to be used by providers/payers providing Coordination of Benefits information to another payer in the 837 transaction only. This code is only used when the non-standard code cannot be reasonably mapped to an existing Claims Adjustment Reason Code, specifically Deductible, Coinsurance and Co-payment.",xRange:"Start: 10/31/2005 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"193",xDescription:"Original payment decision is being maintained. Upon review, it was determined that this claim was processed properly.",xRange:"Start: 02/28/2006 | Last Modified: 01/27/2008"},
  { xActive:true,xCode:"194",xDescription:"Anesthesia performed by the operating physician, the assistant surgeon or the attending physician.",xRange:"Start: 02/28/2006 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"195",xDescription:"Refund issued to an erroneous priority payer for this claim/service.",xRange:"Start: 02/28/2006 | Last Modified: 09/30/2007"},
  { xActive:false,xCode:"196",xDescription:"Claim/service denied based on prior payer's coverage determination.",xRange:"Start: 06/30/2006 | Stop: 02/01/2007<br /><i>Notes: Use code 136.</i>"},
  { xActive:true,xCode:"197",xDescription:"Precertification/authorization/notification/pre-treatment absent.",xRange:"Start: 10/31/2006 | Last Modified: 05/01/2018"},
  { xActive:true,xCode:"198",xDescription:"Precertification/notification/authorization/pre-treatment exceeded.",xRange:"Start: 10/31/2006 | Last Modified: 05/01/2018"},
  { xActive:true,xCode:"199",xDescription:"Revenue code and Procedure code do not match.",xRange:"Start: 10/31/2006"},
  { xActive:true,xCode:"200",xDescription:"Expenses incurred during lapse in coverage",xRange:"Start: 10/31/2006"},
  { xActive:true,xCode:"201",xDescription:"Patient is responsible for amount of this claim/service through 'set aside arrangement' or other agreement. (Use only with Group Code PR)  At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT.)",xRange:"Start: 10/31/2006 | Last Modified: 09/28/2014<br /><i>Notes: Not for use by Workers' Compensation payers; use code P3 instead.</i>"},
  { xActive:true,xCode:"202",xDescription:"Non-covered personal comfort or convenience services.",xRange:"Start: 02/28/2007 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"203",xDescription:"Discontinued or reduced service.",xRange:"Start: 02/28/2007 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"204",xDescription:"This service/equipment/drug is not covered under the patient’s current benefit plan",xRange:"Start: 02/28/2007"},
  { xActive:true,xCode:"205",xDescription:"Pharmacy discount card processing fee",xRange:"Start: 07/09/2007"},
  { xActive:true,xCode:"206",xDescription:"National Provider Identifier - missing.",xRange:"Start: 07/09/2007 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"207",xDescription:"National Provider identifier - Invalid format",xRange:"Start: 07/09/2007 | Last Modified: 06/01/2008"},
  { xActive:true,xCode:"208",xDescription:"National Provider Identifier - Not matched.",xRange:"Start: 07/09/2007 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"209",xDescription:"Per regulatory or other agreement. The provider cannot collect this amount from the patient. However, this amount may be billed to subsequent payer. Refund to patient if collected. (Use only with Group code OA)",xRange:"Start: 07/09/2007 | Last Modified: 07/01/2013"},
  { xActive:true,xCode:"210",xDescription:"Payment adjusted because pre-certification/authorization not received in a timely fashion",xRange:"Start: 07/09/2007"},
  { xActive:true,xCode:"211",xDescription:"National Drug Codes (NDC) not eligible for rebate, are not covered.",xRange:"Start: 07/09/2007"},
  { xActive:true,xCode:"212",xDescription:"Administrative surcharges are not covered",xRange:"Start: 11/05/2007"},
  { xActive:true,xCode:"213",xDescription:"Non-compliance with the physician self referral prohibition legislation or payer policy.",xRange:"Start: 01/27/2008"},
  { xActive:false,xCode:"214",xDescription:"Workers' Compensation claim adjudicated as non-compensable. This Payer not liable for claim or service/treatment. Note: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') for the jurisdictional regulation. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF). To be used for Workers' Compensation only",xRange:"Start: 01/27/2008 | Last Modified: 10/17/2010 | Stop: 07/01/2014<br /><i>Notes: Use code P4</i>"},
  { xActive:true,xCode:"215",xDescription:"Based on subrogation of a third party settlement",xRange:"Start: 01/27/2008"},
  { xActive:true,xCode:"216",xDescription:"Based on the findings of a review organization",xRange:"Start: 01/27/2008"},
  { xActive:false,xCode:"217",xDescription:"Based on payer reasonable and customary fees. No maximum allowable defined by legislated fee arrangement. (Note: To be used for Property and Casualty only)",xRange:"Start: 01/27/2008 | Last Modified: 09/30/2012 | Stop: 07/01/2014<br /><i>Notes: Use code P5</i>"},
  { xActive:false,xCode:"218",xDescription:"Based on entitlement to benefits. Note: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') for the jurisdictional regulation. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF). To be used for Workers' Compensation only",xRange:"Start: 01/27/2008 | Last Modified: 10/17/2010 | Stop: 07/01/2014<br /><i>Notes: Use code P6</i>"},
  { xActive:true,xCode:"219",xDescription:"Based on extent of injury. Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') for the jurisdictional regulation. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF).",xRange:"Start: 01/27/2008 | Last Modified: 07/01/2017"},
  { xActive:false,xCode:"220",xDescription:"The applicable fee schedule/fee database does not contain the billed code. Please resubmit a bill with the appropriate fee schedule/fee database code(s) that best describe the service(s) provided and supporting documentation if required. (Note: To be used for Property and Casualty only)",xRange:"Start: 01/27/2008 | Last Modified: 09/30/2012 | Stop: 07/01/2014<br /><i>Notes: Use code P7</i>"},
  { xActive:false,xCode:"221",xDescription:"Claim is under investigation. Note: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') for the jurisdictional regulation. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF). (Note: To be used by Property & Casualty only)",xRange:"Start: 01/27/2008 | Last Modified: 07/01/2013 | Stop: 07/01/2014<br /><i>Notes: Use code P8</i>"},
  { xActive:true,xCode:"222",xDescription:"Exceeds the contracted maximum number of hours/days/units by this provider for this period. This is not patient specific. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 06/01/2008 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"223",xDescription:"Adjustment code for mandated federal, state or local law/regulation that is not already covered by another code and is mandated before a new code can be created.",xRange:"Start: 06/01/2008"},
  { xActive:true,xCode:"224",xDescription:"Patient identification compromised by identity theft. Identity verification required for processing this and future claims.",xRange:"Start: 06/01/2008"},
  { xActive:true,xCode:"225",xDescription:"Penalty or Interest Payment by Payer (Only used for plan to plan encounter reporting within the 837)",xRange:"Start: 06/01/2008"},
  { xActive:true,xCode:"226",xDescription:"Information requested from the Billing/Rendering Provider was not provided or not provided timely or was insufficient/incomplete. At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT.)",xRange:"Start: 09/21/2008 | Last Modified: 07/01/2013"},
  { xActive:true,xCode:"227",xDescription:"Information requested from the patient/insured/responsible party was not provided or was insufficient/incomplete. At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT.)",xRange:"Start: 09/21/2008 | Last Modified: 09/20/2009"},
  { xActive:true,xCode:"228",xDescription:"Denied for failure of this provider, another provider or the subscriber to supply requested information to a previous payer for their adjudication",xRange:"Start: 09/21/2008"},
  { xActive:true,xCode:"229",xDescription:"Partial charge amount not considered by Medicare due to the initial claim Type of Bill being 12X. Usage: This code can only be used in the 837 transaction to convey Coordination of Benefits information when the secondary payer's cost avoidance policy allows providers to bypass claim submission to a prior payer. (Use only with Group Code PR)",xRange:"Start: 01/25/2009 | Last Modified: 07/01/2017"},
  { xActive:false,xCode:"230",xDescription:"No available or correlating CPT/HCPCS code to describe this service. Note: Used only by Property and Casualty.",xRange:"Start: 01/25/2009 | Stop: 07/01/2014<br /><i>Notes: Use code P9</i>"},
  { xActive:true,xCode:"231",xDescription:"Mutually exclusive procedures cannot be done in the same day/setting. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 07/01/2009 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"232",xDescription:"Institutional Transfer Amount. Usage: Applies to institutional claims only and explains the DRG amount difference when the patient care crosses multiple institutions.",xRange:"Start: 11/01/2009 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"233",xDescription:"Services/charges related to the treatment of a hospital-acquired condition or preventable medical error.",xRange:"Start: 01/24/2010"},
  { xActive:true,xCode:"234",xDescription:"This procedure is not paid separately. At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT.)",xRange:"Start: 01/24/2010"},
  { xActive:true,xCode:"235",xDescription:"Sales Tax",xRange:"Start: 06/06/2010"},
  { xActive:true,xCode:"236",xDescription:"This procedure or procedure/modifier combination is not compatible with another procedure or procedure/modifier combination provided on the same day according to the National Correct Coding Initiative or workers compensation state regulations/ fee schedule requirements.",xRange:"Start: 01/30/2011 | Last Modified: 07/01/2013"},
  { xActive:true,xCode:"237",xDescription:"Legislated/Regulatory Penalty. At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT.)",xRange:"Start: 06/05/2011"},
  { xActive:true,xCode:"238",xDescription:"Claim spans eligible and ineligible periods of coverage, this is the reduction for the ineligible period. (Use only with Group Code PR)",xRange:"Start: 03/01/2012 | Last Modified: 07/01/2013"},
  { xActive:true,xCode:"239",xDescription:"Claim spans eligible and ineligible periods of coverage. Rebill separate claims.",xRange:"Start: 03/01/2012 | Last Modified: 01/29/2012"},
  { xActive:true,xCode:"240",xDescription:"The diagnosis is inconsistent with the patient's birth weight. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 06/03/2012 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"241",xDescription:"Low Income Subsidy (LIS) Co-payment Amount",xRange:"Start: 06/03/2012"},
  { xActive:true,xCode:"242",xDescription:"Services not provided by network/primary care providers.",xRange:"Start: 06/03/2012 | Last Modified: 06/02/2013<br /><i>Notes: This code replaces deactivated code 38</i>"},
  { xActive:true,xCode:"243",xDescription:"Services not authorized by network/primary care providers.",xRange:"Start: 06/03/2012 | Last Modified: 06/02/2013<br /><i>Notes: This code replaces deactivated code 38</i>"},
  { xActive:false,xCode:"244",xDescription:"Payment reduced to zero due to litigation. Additional information will be sent following the conclusion of litigation. To be used for Property & Casualty only.",xRange:"Start: 09/30/2012 | Stop: 07/01/2014<br /><i>Notes: Use code P10</i>"},
  { xActive:true,xCode:"245",xDescription:"Provider performance program withhold.",xRange:"Start: 09/30/2012"},
  { xActive:true,xCode:"246",xDescription:"This non-payable code is for required reporting only.",xRange:"Start: 09/30/2012"},
  { xActive:true,xCode:"247",xDescription:"Deductible for Professional service rendered in an Institutional setting and billed on an Institutional claim.",xRange:"Start: 09/30/2012<br /><i>Notes: For Medicare Bundled Payment use only, under the Patient Protection and Affordable Care Act (PPACA).</i>"},
  { xActive:true,xCode:"248",xDescription:"Coinsurance for Professional service rendered in an Institutional setting and billed on an Institutional claim.",xRange:"Start: 09/30/2012<br /><i>Notes: For Medicare Bundled Payment use only, under the Patient Protection and Affordable Care Act (PPACA).</i>"},
  { xActive:true,xCode:"249",xDescription:"This claim has been identified as a readmission. (Use only with Group Code CO)",xRange:"Start: 09/30/2012"},
  { xActive:true,xCode:"250",xDescription:"The attachment/other documentation that was received was the incorrect attachment/document. The expected attachment/document is still missing. At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT).",xRange:"Start: 09/30/2012 | Last Modified: 06/01/2014"},
  { xActive:true,xCode:"251",xDescription:"The attachment/other documentation that was received was incomplete or deficient. The necessary information is still needed to process the claim. At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT).",xRange:"Start: 09/30/2012 | Last Modified: 06/01/2014"},
  { xActive:true,xCode:"252",xDescription:"An attachment/other documentation is required to adjudicate this claim/service. At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT).",xRange:"Start: 09/30/2012 | Last Modified: 06/02/2013"},
  { xActive:true,xCode:"253",xDescription:"Sequestration - reduction in federal payment",xRange:"Start: 06/02/2013 | Last Modified: 11/01/2013"},
  { xActive:true,xCode:"254",xDescription:"Claim received by the dental plan, but benefits not available under this plan.  Submit these services to the patient's medical plan for further consideration.",xRange:"Start: 06/02/2013 | Last Modified: 11/01/2017<br /><i>Notes: Use CARC 290 if the claim was forwarded.</i>"},
  { xActive:false,xCode:"255",xDescription:"The disposition of the related Property & Casualty claim (injury or illness) is pending due to litigation. (Use only with Group Code OA)",xRange:"Start: 06/02/2013 | Stop: 07/01/2014<br /><i>Notes: Use code P11</i>"},
  { xActive:true,xCode:"256",xDescription:"Service not payable per managed care contract.",xRange:"Start: 06/02/2013"},
  { xActive:true,xCode:"257",xDescription:"The disposition of the claim/service is undetermined during the premium payment grace period, per Health Insurance Exchange requirements. This claim/service will be reversed and corrected when the grace period ends (due to premium payment or lack of premium payment). (Use only with Group Code OA)",xRange:"Start: 11/01/2013 | Last Modified: 06/01/2014<br /><i>Notes: To be used after the first month of the grace period.</i>"},
  { xActive:true,xCode:"258",xDescription:"Claim/service not covered when patient is in custody/incarcerated. Applicable federal, state or local authority may cover the claim/service.",xRange:"Start: 11/01/2013"},
  { xActive:true,xCode:"259",xDescription:"Additional payment for Dental/Vision service utilization.",xRange:"Start: 01/26/2014"},
  { xActive:true,xCode:"260",xDescription:"Processed under Medicaid ACA Enhanced Fee Schedule",xRange:"Start: 01/26/2014"},
  { xActive:true,xCode:"261",xDescription:"The procedure or service is inconsistent with the patient's history.",xRange:"Start: 06/01/2014"},
  { xActive:true,xCode:"262",xDescription:"Adjustment for delivery cost. Usage: To be used for pharmaceuticals only.",xRange:"Start: 11/01/2014 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"263",xDescription:"Adjustment for shipping cost. Usage: To be used for pharmaceuticals only.",xRange:"Start: 11/01/2014 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"264",xDescription:"Adjustment for postage cost. Usage: To be used for pharmaceuticals only.",xRange:"Start: 11/01/2014 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"265",xDescription:"Adjustment for administrative cost. Usage: To be used for pharmaceuticals only.",xRange:"Start: 11/01/2014 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"266",xDescription:"Adjustment for compound preparation cost. Usage: To be used for pharmaceuticals only.",xRange:"Start: 11/01/2014 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"267",xDescription:"Claim/service spans multiple months. At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT.)",xRange:"Start: 11/01/2014 | Last Modified: 04/01/2015"},
  { xActive:true,xCode:"268",xDescription:"The Claim spans two calendar years. Please resubmit one claim per calendar year.",xRange:"Start: 11/01/2014"},
  { xActive:true,xCode:"269",xDescription:"Anesthesia not covered for this service/procedure. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 03/01/2015 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"270",xDescription:"Claim received by the medical plan, but benefits not available under this plan. Submit these services to the patient’s dental plan for further consideration.",xRange:"Start: 07/01/2015 | Last Modified: 11/01/2017<br /><i>Notes: Use CARC 291 if the claim was forwarded.</i>"},
  { xActive:true,xCode:"271",xDescription:"Prior contractual reductions related to a current periodic payment as part of a contractual payment schedule when deferred amounts have been previously reported. (Use only with Group Code OA)",xRange:"Start: 11/01/2015 | Last Modified: 03/01/2018"},
  { xActive:true,xCode:"272",xDescription:"Coverage/program guidelines were not met.",xRange:"Start: 11/01/2015"},
  { xActive:true,xCode:"273",xDescription:"Coverage/program guidelines were exceeded.",xRange:"Start: 11/01/2015"},
  { xActive:true,xCode:"274",xDescription:"Fee/Service not payable per patient Care Coordination arrangement.",xRange:"Start: 11/01/2015"},
  { xActive:true,xCode:"275",xDescription:"Prior payer's (or payers') patient responsibility (deductible, coinsurance, co-payment) not covered. (Use only with Group Code PR)",xRange:"Start: 11/01/2015"},
  { xActive:true,xCode:"276",xDescription:"Services denied by the prior payer(s) are not covered by this payer.",xRange:"Start: 11/01/2015"},
  { xActive:true,xCode:"277",xDescription:"The disposition of the claim/service is undetermined during the premium payment grace period, per Health Insurance SHOP Exchange requirements. This claim/service will be reversed and corrected when the grace period ends (due to premium payment or lack of premium payment). (Use only with Group Code OA)",xRange:"Start: 11/01/2015<br /><i>Notes: To be used during 31 day SHOP grace period.</i>"},
  { xActive:true,xCode:"278",xDescription:"Performance program proficiency requirements not met. (Use only with Group Codes CO or PI) Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 07/01/2016 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"279",xDescription:"Services not provided by Preferred network providers. Usage: Use this code when there are member network limitations. For example, using contracted providers not in the member's 'narrow' network.",xRange:"Start: 11/01/2016 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"280",xDescription:"Claim received by the medical plan, but benefits not available under this plan. Submit these services to the patient's Pharmacy plan for further consideration.",xRange:"Start: 03/01/2017 | Last Modified: 11/01/2017<br /><i>Notes: Use CARC 292 if the claim was forwarded.</i>"},
  { xActive:true,xCode:"281",xDescription:"Deductible waived per contractual agreement. Use only with Group Code CO.",xRange:"Start: 07/01/2017"},
  { xActive:true,xCode:"282",xDescription:"The procedure/revenue code is inconsistent with the type of bill. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 07/01/2017"},
  { xActive:true,xCode:"283",xDescription:"Attending provider is not eligible to provide direction of care.",xRange:"Start: 11/01/2017"},
  { xActive:true,xCode:"284",xDescription:"Precertification/authorization/notification/pre-treatment number may be valid but does not apply to the billed services.",xRange:"Start: 11/01/2017"},
  { xActive:true,xCode:"285",xDescription:"Appeal procedures not followed",xRange:"Start: 11/01/2017"},
  { xActive:true,xCode:"286",xDescription:"Appeal time limits not met",xRange:"Start: 11/01/2017"},
  { xActive:true,xCode:"287",xDescription:"Referral exceeded",xRange:"Start: 11/01/2017"},
  { xActive:true,xCode:"288",xDescription:"Referral absent",xRange:"Start: 11/01/2017"},
  { xActive:true,xCode:"289",xDescription:"Services considered under the dental and medical plans, benefits not available.",xRange:"Start: 11/01/2017<br /><i>Notes: Also see CARCs 254, 270 and 280.</i>"},
  { xActive:true,xCode:"290",xDescription:"Claim received by the dental plan, but benefits not available under this plan. Claim has been forwarded to the patient's medical plan for further consideration.",xRange:"Start: 11/01/2017<br /><i>Notes: Use CARC 254 if the claim was not forwarded.</i>"},
  { xActive:true,xCode:"291",xDescription:"Claim received by the medical plan, but benefits not available under this plan. Claim has been forwarded to the patient's dental plan for further consideration.",xRange:"Start: 11/01/2017<br /><i>Notes: Use CARC 270 if the claim was not forwarded.</i>"},
  { xActive:true,xCode:"292",xDescription:"Claim received by the medical plan, but benefits not available under this plan. Claim has been forwarded to the patient's pharmacy plan for further consideration.",xRange:"Start: 11/01/2017<br /><i>Notes: Use CARC 280 if the claim was not forwarded.</i>"},
  { xActive:true,xCode:"293",xDescription:"Payment made to employer.",xRange:"Start: 05/01/2018"},
  { xActive:true,xCode:"294",xDescription:"Payment made to attorney.",xRange:"Start: 11/01/2017"},
  { xActive:true,xCode:"295",xDescription:"Pharmacy Direct/Indirect Remuneration (DIR)",xRange:"Start: 03/01/2018"},
  { xActive:true,xCode:"296",xDescription:"Precertification/authorization/notification/pre-treatment number may be valid but does not apply to the provider.",xRange:"Start: 07/01/2018"},
  { xActive:true,xCode:"A0",xDescription:"Patient refund amount.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"A1",xDescription:"Claim/Service denied. At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT.)",xRange:"Start: 01/01/1995 | Last Modified: 09/20/2009"},
  { xActive:false,xCode:"A2",xDescription:"Contractual adjustment.",xRange:"Start: 01/01/1995 | Last Modified: 02/28/2007 | Stop: 01/01/2008<br /><i>Notes: Use Code 45 with Group Code 'CO' or use another appropriate specific adjustment code.</i>"},
  { xActive:false,xCode:"A3",xDescription:"Medicare Secondary Payer liability met.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"A4",xDescription:"Medicare Claim PPS Capital Day Outlier Amount.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007 | Stop: 04/01/2008"},
  { xActive:true,xCode:"A5",xDescription:"Medicare Claim PPS Capital Cost Outlier Amount.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"A6",xDescription:"Prior hospitalization or 30 day transfer requirement not met.",xRange:"Start: 01/01/1995"},
  { xActive:false,xCode:"A7",xDescription:"Presumptive Payment Adjustment",xRange:"Start: 01/01/1995 | Stop: 07/01/2015"},
  { xActive:true,xCode:"A8",xDescription:"Ungroupable DRG.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"B1",xDescription:"Non-covered visits.",xRange:"Start: 01/01/1995"},
  { xActive:false,xCode:"B2",xDescription:"Covered visits.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:false,xCode:"B3",xDescription:"Covered charges.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:true,xCode:"B4",xDescription:"Late filing penalty.",xRange:"Start: 01/01/1995"},
  { xActive:false,xCode:"B5",xDescription:"Coverage/program guidelines were not met or were exceeded.",xRange:"Start: 01/01/1995 | Last Modified: 11/01/2015 | Stop: 05/01/2016<br /><i>Notes: This code has been replaced by 272 and 273.</i>"},
  { xActive:false,xCode:"B6",xDescription:"This payment is adjusted when performed/billed by this type of provider, by this type of provider in this type of facility, or by a provider of this specialty.",xRange:"Start: 01/01/1995 | Stop: 02/01/2006"},
  { xActive:true,xCode:"B7",xDescription:"This provider was not certified/eligible to be paid for this procedure/service on this date of service. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"B8",xDescription:"Alternative services were available, and should have been utilized. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"B9",xDescription:"Patient is enrolled in a Hospice.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"B10",xDescription:"Allowed amount has been reduced because a component of the basic procedure/test was paid. The beneficiary is not liable for more than the charge limit for the basic procedure/test.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"B11",xDescription:"The claim/service has been transferred to the proper payer/processor for processing. Claim/service not covered by this payer/processor.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"B12",xDescription:"Services not documented in patient's medical records.",xRange:"Start: 01/01/1995 | Last Modified: 03/01/2018"},
  { xActive:true,xCode:"B13",xDescription:"Previously paid. Payment for this claim/service may have been provided in a previous payment.",xRange:"Start: 01/01/1995"},
  { xActive:true,xCode:"B14",xDescription:"Only one visit or consultation per physician per day is covered.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:true,xCode:"B15",xDescription:"This service/procedure requires that a qualifying service/procedure be received and covered. The qualifying other service/procedure has not been received/adjudicated. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present.",xRange:"Start: 01/01/1995 | Last Modified: 07/01/2017"},
  { xActive:true,xCode:"B16",xDescription:"'New Patient' qualifications were not met.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:false,xCode:"B17",xDescription:"Payment adjusted because this service was not prescribed by a physician, not prescribed prior to delivery, the prescription is incomplete, or the prescription is not current.",xRange:"Start: 01/01/1995 | Stop: 02/01/2006"},
  { xActive:false,xCode:"B18",xDescription:"This procedure code and modifier were invalid on the date of service.",xRange:"Start: 01/01/1995 | Last Modified: 09/21/2008 | Stop: 03/01/2009"},
  { xActive:false,xCode:"B19",xDescription:"Claim/service adjusted because of the finding of a Review Organization.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:true,xCode:"B20",xDescription:"Procedure/service was partially or fully furnished by another provider.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:false,xCode:"B21",xDescription:"The charges were reduced because the service/care was partially furnished by another physician.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003"},
  { xActive:true,xCode:"B22",xDescription:"This payment is adjusted based on the diagnosis.",xRange:"Start: 01/01/1995 | Last Modified: 02/28/2001"},
  { xActive:true,xCode:"B23",xDescription:"Procedure billed is not authorized per your Clinical Laboratory Improvement Amendment (CLIA) proficiency test.",xRange:"Start: 01/01/1995 | Last Modified: 09/30/2007"},
  { xActive:false,xCode:"D1",xDescription:"Claim/service denied. Level of subluxation is missing or inadequate.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 16 and remark codes if necessary.</i>"},
  { xActive:false,xCode:"D2",xDescription:"Claim lacks the name, strength, or dosage of the drug furnished.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 16 and remark codes if necessary.</i>"},
  { xActive:false,xCode:"D3",xDescription:"Claim/service denied because information to indicate if the patient owns the equipment that requires the part or supply was missing.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 16 and remark codes if necessary.</i>"},
  { xActive:false,xCode:"D4",xDescription:"Claim/service does not indicate the period of time for which this will be needed.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 16 and remark codes if necessary.</i>"},
  { xActive:false,xCode:"D5",xDescription:"Claim/service denied. Claim lacks individual lab codes included in the test.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 16 and remark codes if necessary.</i>"},
  { xActive:false,xCode:"D6",xDescription:"Claim/service denied. Claim did not include patient's medical record for the service.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 16 and remark codes if necessary.</i>"},
  { xActive:false,xCode:"D7",xDescription:"Claim/service denied. Claim lacks date of patient's most recent physician visit.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 16 and remark codes if necessary.</i>"},
  { xActive:false,xCode:"D8",xDescription:"Claim/service denied. Claim lacks indicator that 'x-ray is available for review.'",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 16 and remark codes if necessary.</i>"},
  { xActive:false,xCode:"D9",xDescription:"Claim/service denied. Claim lacks invoice or statement certifying the actual cost of the lens, less discounts or the type of intraocular lens used.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 16 and remark codes if necessary.</i>"},
  { xActive:false,xCode:"D10",xDescription:"Claim/service denied. Completed physician financial relationship form not on file.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 17.</i>"},
  { xActive:false,xCode:"D11",xDescription:"Claim lacks completed pacemaker registration form.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 17.</i>"},
  { xActive:false,xCode:"D12",xDescription:"Claim/service denied. Claim does not identify who performed the purchased diagnostic test or the amount you were charged for the test.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 17.</i>"},
  { xActive:false,xCode:"D13",xDescription:"Claim/service denied. Performed by a facility/supplier in which the ordering/referring physician has a financial interest.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 17.</i>"},
  { xActive:false,xCode:"D14",xDescription:"Claim lacks indication that plan of treatment is on file.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 17.</i>"},
  { xActive:false,xCode:"D15",xDescription:"Claim lacks indication that service was supervised or evaluated by a physician.",xRange:"Start: 01/01/1995 | Stop: 10/16/2003<br /><i>Notes: Use code 17.</i>"},
  { xActive:false,xCode:"D16",xDescription:"Claim lacks prior payer payment information.",xRange:"Start: 01/01/1995 | Stop: 06/30/2007<br /><i>Notes: Use code 16 with appropriate claim payment remark code [N4].</i>"},
  { xActive:false,xCode:"D17",xDescription:"Claim/Service has invalid non-covered days.",xRange:"Start: 01/01/1995 | Stop: 06/30/2007<br /><i>Notes: Use code 16 with appropriate claim payment remark code.</i>"},
  { xActive:false,xCode:"D18",xDescription:"Claim/Service has missing diagnosis information.",xRange:"Start: 01/01/1995 | Stop: 06/30/2007<br /><i>Notes: Use code 16 with appropriate claim payment remark code.</i>"},
  { xActive:false,xCode:"D19",xDescription:"Claim/Service lacks Physician/Operative or other supporting documentation",xRange:"Start: 01/01/1995 | Stop: 06/30/2007<br /><i>Notes: Use code 16 with appropriate claim payment remark code.</i>"},
  { xActive:false,xCode:"D20",xDescription:"Claim/Service missing service/product information.",xRange:"Start: 01/01/1995 | Stop: 06/30/2007<br /><i>Notes: Use code 16 with appropriate claim payment remark code.</i>"},
  { xActive:false,xCode:"D21",xDescription:"This (these) diagnosis(es) is (are) missing or are invalid",xRange:"Start: 01/01/1995 | Stop: 06/30/2007"},
  { xActive:false,xCode:"D22",xDescription:"Reimbursement was adjusted for the reasons to be provided in separate correspondence. (Note: To be used for Workers' Compensation only) - Temporary code to be added for timeframe only until 01/01/2009. Another code to be established and/or for 06/2008 meeting for a revised code to replace or strategy to use another existing code",xRange:"Start: 01/27/2008 | Stop: 01/01/2009"},
  { xActive:false,xCode:"D23",xDescription:"This dual eligible patient is covered by Medicare Part D per Medicare Retro-Eligibility.  At least one Remark Code must be provided (may be comprised of either the NCPDP Reject Reason Code, or Remittance Advice Remark Code that is not an ALERT.)",xRange:"Start: 11/01/2009 | Stop: 01/01/2012"},
  { xActive:true,xCode:"P1",xDescription:"State-mandated Requirement for Property and Casualty, see Claim Payment Remarks Code for specific explanation. To be used for Property and Casualty only.",xRange:"Start: 11/01/2013<br /><i>Notes: This code replaces deactivated code 162</i>"},
  { xActive:true,xCode:"P2",xDescription:"Not a work related injury/illness and thus not the liability of the workers' compensation carrier Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') for the jurisdictional regulation. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF). To be used for Workers' Compensation only.",xRange:"Start: 11/01/2013 | Last Modified: 07/01/2017<br /><i>Notes: This code replaces deactivated code 191</i>"},
  { xActive:true,xCode:"P3",xDescription:"Workers' Compensation case settled. Patient is responsible for amount of this claim/service through WC 'Medicare set aside arrangement' or other agreement. To be used for Workers' Compensation only. (Use only with Group Code PR)",xRange:"Start: 11/01/2013<br /><i>Notes: This code replaces deactivated code 201</i>"},
  { xActive:true,xCode:"P4",xDescription:"Workers' Compensation claim adjudicated as non-compensable. This Payer not liable for claim or service/treatment. Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') for the jurisdictional regulation. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF). To be used for Workers' Compensation only",xRange:"Start: 11/01/2013 | Last Modified: 07/01/2017<br /><i>Notes: This code replaces deactivated code 214</i>"},
  { xActive:true,xCode:"P5",xDescription:"Based on payer reasonable and customary fees. No maximum allowable defined by legislated fee arrangement. To be used for Property and Casualty only.",xRange:"Start: 11/01/2013<br /><i>Notes: This code replaces deactivated code 217</i>"},
  { xActive:true,xCode:"P6",xDescription:"Based on entitlement to benefits. Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') for the jurisdictional regulation. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF). To be used for Property and Casualty only.",xRange:"Start: 11/01/2013 | Last Modified: 07/01/2017<br /><i>Notes: This code replaces deactivated code 218</i>"},
  { xActive:true,xCode:"P7",xDescription:"The applicable fee schedule/fee database does not contain the billed code. Please resubmit a bill with the appropriate fee schedule/fee database code(s) that best describe the service(s) provided and supporting documentation if required. To be used for Property and Casualty only.",xRange:"Start: 11/01/2013<br /><i>Notes: This code replaces deactivated code 220</i>"},
  { xActive:true,xCode:"P8",xDescription:"Claim is under investigation. Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') for the jurisdictional regulation. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF). To be used for Property and Casualty only.",xRange:"Start: 11/01/2013 | Last Modified: 07/01/2017<br /><i>Notes: This code replaces deactivated code 221</i>"},
  { xActive:true,xCode:"P9",xDescription:"No available or correlating CPT/HCPCS code to describe this service. To be used for Property and Casualty only.",xRange:"Start: 11/01/2013<br /><i>Notes: This code replaces deactivated code 230</i>"},
  { xActive:true,xCode:"P10",xDescription:"Payment reduced to zero due to litigation. Additional information will be sent following the conclusion of litigation. To be used for Property and Casualty only.",xRange:"Start: 11/01/2013<br /><i>Notes: This code replaces deactivated code 244</i>"},
  { xActive:true,xCode:"P11",xDescription:"The disposition of the related Property & Casualty claim (injury or illness) is pending due to litigation. To be used for Property and Casualty only. (Use only with Group Code OA)",xRange:"Start: 11/01/2013<br /><i>Notes: This code replaces deactivated code 255</i>"},
  { xActive:true,xCode:"P12",xDescription:"Workers' compensation jurisdictional fee schedule adjustment. Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Class of Contract Code Identification Segment (Loop 2100 Other Claim Related Information REF). If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for Workers' Compensation only.",xRange:"Start: 11/01/2013 | Last Modified: 07/01/2017<br /><i>Notes: This code replaces deactivated code W1</i>"},
  { xActive:true,xCode:"P13",xDescription:"Payment reduced or denied based on workers' compensation jurisdictional regulations or payment policies, use only if no other code is applicable. Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') if the jurisdictional regulation applies. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for Workers' Compensation only.",xRange:"Start: 11/01/2013 | Last Modified: 07/01/2017<br /><i>Notes: This code replaces deactivated code W2</i>"},
  { xActive:true,xCode:"P14",xDescription:"The Benefit for this Service is included in the payment/allowance for another service/procedure that has been performed on the same day. Usage: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present. To be used for Property and Casualty only.",xRange:"Start: 11/01/2013 | Last Modified: 07/01/2017<br /><i>Notes: This code replaces deactivated code W3</i>"},
  { xActive:true,xCode:"P15",xDescription:"Workers' Compensation Medical Treatment Guideline Adjustment. To be used for Workers' Compensation only.",xRange:"Start: 11/01/2013<br /><i>Notes: This code replaces deactivated code W4</i>"},
  { xActive:true,xCode:"P16",xDescription:"Medical provider not authorized/certified to provide treatment to injured workers in this jurisdiction. To be used for Workers' Compensation only. (Use with Group Code CO or OA)",xRange:"Start: 11/01/2013<br /><i>Notes: This code replaces deactivated code W5</i>"},
  { xActive:true,xCode:"P17",xDescription:"Referral not authorized by attending physician per regulatory requirement. To be used for Property and Casualty only.",xRange:"Start: 11/01/2013<br /><i>Notes: This code replaces deactivated code W6</i>"},
  { xActive:true,xCode:"P18",xDescription:"Procedure is not listed in the jurisdiction fee schedule.  An allowance has been made for a comparable service. To be used for Property and Casualty only.",xRange:"Start: 11/01/2013<br /><i>Notes: This code replaces deactivated code W7</i>"},
  { xActive:true,xCode:"P19",xDescription:"Procedure has a relative value of zero in the jurisdiction fee schedule, therefore no payment is due. To be used for Property and Casualty only.",xRange:"Start: 11/01/2013<br /><i>Notes: This code replaces deactivated code W8</i>"},
  { xActive:true,xCode:"P20",xDescription:"Service not paid under jurisdiction allowed outpatient facility fee schedule. To be used for Property and Casualty only.",xRange:"Start: 11/01/2013<br /><i>Notes: This code replaces deactivated code W9</i>"},
  { xActive:true,xCode:"P21",xDescription:"Payment denied based on the Medical Payments Coverage (MPC) and/or Personal Injury Protection (PIP) Benefits jurisdictional regulations, or payment policies. Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') if the jurisdictional regulation applies. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for Property and Casualty Auto only.",xRange:"Start: 11/01/2013 | Last Modified: 03/01/2018<br /><i>Notes: This code replaces deactivated code Y1</i>"},
  { xActive:true,xCode:"P22",xDescription:"Payment adjusted based on the Medical Payments Coverage (MPC) and/or Personal Injury Protection (PIP) Benefits jurisdictional regulations, or payment policies. Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') if the jurisdictional regulation applies. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for Property and Casualty Auto only.",xRange:"Start: 11/01/2013 | Last Modified: 03/01/2018<br /><i>Notes: This code replaces deactivated code Y2</i>"},
  { xActive:true,xCode:"P23",xDescription:"Medical Payments Coverage (MPC) or Personal Injury Protection (PIP) Benefits jurisdictional fee schedule adjustment. Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Class of Contract Code Identification Segment (Loop 2100 Other Claim Related Information REF). If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for Property and Casualty Auto only.",xRange:"Start: 11/01/2013 | Last Modified: 07/01/2017<br /><i>Notes: This code replaces deactivated code Y3</i>"},
  { xActive:true,xCode:"P24",xDescription:"Payment adjusted based on Preferred Provider Organization (PPO).  Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Class of Contract Code Identification Segment (Loop 2100 Other Claim Related Information REF). If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for Property and Casualty only. Use only with Group Code CO.",xRange:"Start: 11/01/2017"},
  { xActive:true,xCode:"P25",xDescription:"Payment adjusted based on Medical Provider Network (MPN).  Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Class of Contract Code Identification Segment (Loop 2100 Other Claim Related Information REF). If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for Property and Casualty only. (Use only with Group Code CO).",xRange:"Start: 11/01/2017"},
  { xActive:true,xCode:"P26",xDescription:"Payment adjusted based on Voluntary Provider network (VPN).  Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Class of Contract Code Identification Segment (Loop 2100 Other Claim Related Information REF). If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for Property and Casualty only. (Use only with Group Code CO).",xRange:"Start: 11/01/2017"},
  { xActive:true,xCode:"P27",xDescription:"Payment denied based on the Liability Coverage Benefits jurisdictional regulations and/or payment policies. Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') if the jurisdictional regulation applies. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for Property and Casualty Auto only.",xRange:"Start: 11/01/2017"},
  { xActive:true,xCode:"P28",xDescription:"Payment adjusted based on the Liability Coverage Benefits jurisdictional regulations and/or payment policies. Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') if the jurisdictional regulation applies. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for Property and Casualty Auto only.",xRange:"Start: 11/01/2017"},
  { xActive:true,xCode:"P29",xDescription:"Liability Benefits jurisdictional fee schedule adjustment. Usage: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Class of Contract Code Identification Segment (Loop 2100 Other Claim Related Information REF). If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for Property and Casualty Auto only.",xRange:"Start: 11/01/2017"},
  { xActive:false,xCode:"W1",xDescription:"Workers' compensation jurisdictional fee schedule adjustment.  Note: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Class of Contract Code Identification Segment (Loop 2100 Other Claim Related Information REF). If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply.",xRange:"Start: 02/29/2000 | Last Modified: 06/02/2013 | Stop: 07/01/2014<br /><i>Notes: Use code P12</i>"},
  { xActive:false,xCode:"W2",xDescription:"Payment reduced or denied based on workers' compensation jurisdictional regulations or payment policies, use only if no other code is applicable. Note: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') if the jurisdictional regulation applies. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for Workers' Compensation only.",xRange:"Start: 10/17/2010 | Last Modified: 06/02/2013 | Stop: 07/01/2014<br /><i>Notes: Use code P13</i>"},
  { xActive:false,xCode:"W3",xDescription:"The Benefit for this Service is included in the payment/allowance for another service/procedure that has been performed on the same day. Note: Refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment Information REF), if present. For use by Property and Casualty only.",xRange:"Start: 09/30/2012 | Stop: 07/01/2014<br /><i>Notes: Use code P14</i>"},
  { xActive:false,xCode:"W4",xDescription:"Workers' Compensation Medical Treatment Guideline Adjustment.",xRange:"Start: 09/30/2012 | Stop: 07/01/2014<br /><i>Notes: Use code P15</i>"},
  { xActive:false,xCode:"W5",xDescription:"Medical provider not authorized/certified to provide treatment to injured workers in this jurisdiction. (Use with Group Code CO or OA)",xRange:"Start: 06/02/2013 | Stop: 07/01/2014<br /><i>Notes: Use code P16</i>"},
  { xActive:false,xCode:"W6",xDescription:"Referral not authorized by attending physician per regulatory requirement.",xRange:"Start: 06/02/2013 | Stop: 07/01/2014<br /><i>Notes: Use code P17</i>"},
  { xActive:false,xCode:"W7",xDescription:"Procedure is not listed in the jurisdiction fee schedule.  An allowance has been made for a comparable service.",xRange:"Start: 06/02/2013 | Stop: 07/01/2014<br /><i>Notes: Use code P18</i>"},
  { xActive:false,xCode:"W8",xDescription:"Procedure has a relative value of zero in the jurisdiction fee schedule, therefore no payment is due.",xRange:"Start: 06/02/2013 | Stop: 07/01/2014<br /><i>Notes: Use code P19</i>"},
  { xActive:false,xCode:"W9",xDescription:"Service not paid under jurisdiction allowed outpatient facility fee schedule.",xRange:"Start: 06/02/2013 | Stop: 07/01/2014<br /><i>Notes: Use code P20</i>"},
  { xActive:false,xCode:"Y1",xDescription:"Payment denied based on Medical Payments Coverage (MPC) or Personal Injury Protection (PIP) Benefits jurisdictional regulations or payment policies, use only if no other code is applicable. Note: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') if the jurisdictional regulation applies. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for P&C Auto only.",xRange:"Start: 09/30/2012 | Last Modified: 06/02/2013 | Stop: 07/01/2014<br /><i>Notes: Use code P21</i>"},
  { xActive:false,xCode:"Y2",xDescription:"Payment adjusted based on Medical Payments Coverage (MPC) or Personal Injury Protection (PIP) Benefits jurisdictional regulations or payment policies, use only if no other code is applicable. Note: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Insurance Policy Number Segment (Loop 2100 Other Claim Related Information REF qualifier 'IG') if the jurisdictional regulation applies. If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for P&C Auto only.",xRange:"Start: 09/30/2012 | Last Modified: 06/02/2013 | Stop: 07/01/2014<br /><i>Notes: Use code P22</i>"},
  { xActive:false,xCode:"Y3",xDescription:"Medical Payments Coverage (MPC) or Personal Injury Protection (PIP) Benefits jurisdictional fee schedule adjustment. Note: If adjustment is at the Claim Level, the payer must send and the provider should refer to the 835 Class of Contract Code Identification Segment (Loop 2100 Other Claim Related Information REF). If adjustment is at the Line Level, the payer must send and the provider should refer to the 835 Healthcare Policy Identification Segment (loop 2110 Service Payment information REF) if the regulations apply. To be used for P&C Auto only.",xRange:"Start: 09/30/2012 | Last Modified: 06/02/2013 | Stop: 07/01/2014<br /><i>Notes: Use code P23</i>"},
];
