import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Spin } from 'antd';

import ClaimsTable from './ClaimsTable';
//import BillsTable from './BillsTable';
import RatesTable from './RatesTable';
import PaymentsTable from './PaymentsTable';
import NotesTable from './NotesTable';
import ServiceLineHistoryTable from './ServiceLineHistoryTable';

import * as actions from '../../../../../actions/billing';
import { safe } from '../../../../../helpers/reduxSelectors';

class HistoryModal extends React.Component {

  static propTypes = {
    patientId: PropTypes.number.isRequired,
    serviceId: PropTypes.number
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible === true) {
      if (this.props.serviceId) {
        this.props.getLedgerLineHistory(this.props.patientId,this.props.serviceId);
      }
    }
  }

/*
{this.props.bills && this.props.bills.length > 0 &&
    <BillsTable data={this.props.bills}/>
}
*/
  render() {
    return (
      <Modal
        title="Service History"
        visible={this.props.visible}
        onOk={this.props.closeDialog}
        onCancel={this.props.closeDialog}
        width={1000}
        bodyStyle={{padding:'5px',height:'450px',overflowY:'scroll'}}
        footer={null}
      >
        <Spin spinning={this.props.isBusy}>

          {this.props.claims && this.props.claims.length > 0 &&
            <ClaimsTable data={this.props.claims}/>
          }
          { this.props.payments && this.props.payments.length > 0 &&
            <div style={{paddingTop:'10px'}}>
              <h4>Payments & Credits</h4>
              <PaymentsTable data={this.props.payments}/>
            </div>
          }
          { this.props.rates && this.props.rates.length > 0 &&
            <div style={{paddingTop:'10px'}}>
              <h4>Rates</h4>
              <RatesTable data={this.props.rates}/>
            </div>
          }
          { this.props.notes && this.props.notes.length > 0 &&
            <div style={{paddingTop:'10px'}}>
              <h4>Notes</h4>
              <NotesTable data={this.props.notes}/>
            </div>
          }
          { this.props.servicelines && this.props.servicelines.length > 0 &&
            <div style={{paddingTop:'10px'}}>
              <h4>Claim Field Revision History</h4>
              <ServiceLineHistoryTable data={this.props.servicelines}/>
            </div>
          }
        </Spin>
      </Modal>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'billing.ledger.history.processing',false) ? true : false,
    bills: safe(state,'billing.ledger.history.data.bills',[]),
    payments: safe(state,'billing.ledger.history.data.payments',[]),
    rates: safe(state,'billing.ledger.history.data.rates',[]),
    claims: safe(state,'billing.ledger.history.data.claims',[]),
    notes: safe(state,'billing.ledger.history.data.notes',[]),
    servicelines: safe(state,'billing.ledger.history.data.servicelines')
  }
}

export default connect(mapStateToProps,actions)(HistoryModal);
