import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, DatePicker, Select, Radio, Spin, Tabs, Tag } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import * as actions from '../../../../../actions/emr';
import { safe, isAdmin, isClinicalSupervisor } from '../../../../../helpers/reduxSelectors';
import Time from '../../../../shared/Time';
import PatientFormSelector from '../../../../shared/PatientFormSelector';

const diffHrs = Time.getTzOffset();

class ManualSessionCreate extends React.Component {

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    provider_id: PropTypes.number,
    provider_name: PropTypes.string,
    provider_roles: PropTypes.array
  }

  state = {
    activeTab: 'session'
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        this.props.form.resetFields();
        this.props.getProviderLocations(this.props.provider_id);
      }
      this.setState({
        activeTab: 'session'
      });
    }
  }

  handleCancel = () => {
    this.props.onClose();
  }

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let type = 1;
        switch (this.state.activeTab) {
          case 'admin': type = 7; break;
          case 'session': type = 1; break;
          case 'blocked': type = 2; break;
          default: break;
        }
        this.props.createSession(this.props.provider_id,1,this.props.calendar_date,{
          ...values,
          session_date: moment(values.session_date).format('YYYY-MM-DD'),
          type: type
        });
        this.props.onClose();
      }
    });
  }

  renderAdminForm = () => {
    const FormItem = Form.Item;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };
    const durations = [10,15,20,30,40,45,60,75,80,90,100,105,120];
    const manualTimeRange = Time.range(new Time(600),new Time(2200),15);
    const sd = this.props.form.getFieldValue('session_date');
    const st = this.props.form.getFieldValue('start_time');
    const { session_date, session_date_local } = Time.fixDateTime(sd, st);
    
    return (
      <Spin spinning={this.props.isBusy}>
        <Form className='compressed-form' autoComplete='false'>

          <FormItem {...formItemLayout} label="Provider">
            {getFieldDecorator('provider_id', {
              initialValue: this.props.provider_id
            })(
              <span>{this.props.provider_name}</span>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Date">
            {getFieldDecorator('session_date', {
              rules: [{ required: true, message: " " }],
              initialValue: moment().tz('America/Los_Angeles')
            })(
              <DatePicker format='MM/DD/YYYY' style={{ width: '140px' }} />
            )}
            <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
            {diffHrs && st ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {session_date_local.format('MM/DD/YYYY')}</Tag> : null}
          </FormItem>

          <FormItem {...formItemLayout} label="Duration">
            {getFieldDecorator('duration', {
              rules: [{ required: true, message: " " }],
              initialValue: 30
            })(
              <Select>
                {durations.map(x=><Option value={x} key={'d'+x}>{x} mins</Option>)}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Starting Time">
            {getFieldDecorator('start_time', {
              rules: [{ required: true, message: " " }],
            })(
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().startsWith(input.toLowerCase())
                }
                style={{width:'85%',marginRight:'5px'}}
              >
                {manualTimeRange.map((x) => {
                  let time = `${x.name} ${Time.getPSTPDT()}`;
                  if (diffHrs) {
                    time = `${time} - (${x.local} Local)`;
                  }
                  return <Option key={x.id} value={x.id}>{time}</Option>;
                })}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Note">
            {getFieldDecorator('comment', {
              rules: [{ required: true, message: " " }],
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Repeats">
            {getFieldDecorator('repeat', {
              rules: [{ required: true, message: " " }],
              initialValue: false
            })(
              <Radio.Group>
                <Radio value={false}>Doesn't Repeat</Radio>
                <Radio value={true}>Repeats Weekly</Radio>
              </Radio.Group>
            )}
          </FormItem>

          { this.props.form.getFieldValue('repeat') &&
            <FormItem {...formItemLayout} label="Repeats Every">
              {getFieldDecorator('repeat_frequency', {
                rules: [{ required: true, message: " " }],
                initialValue: 1
              })(
                <Select>
                  <Option value={1}>1 Week</Option>
                  <Option value={2}>2 Weeks</Option>
                  <Option value={3}>3 Weeks</Option>
                  <Option value={4}>4 Weeks</Option>
                  <Option value={5}>5 Weeks</Option>
                  <Option value={6}>6 Weeks</Option>
                  <Option value={7}>7 Weeks</Option>
                  <Option value={8}>8 Weeks</Option>
                </Select>
              )}
            </FormItem>
          }

          { this.props.form.getFieldValue('repeat') &&
            <FormItem {...formItemLayout} label="Repeat Type">
              {getFieldDecorator('repeat_type', {
                rules: [{ required: true, message: " " }],
                initialValue: 1
              })(
                <Select>
                  <Option value={1}>Until an End Date</Option>
                  <Option value={2}>For # of Sessions</Option>
                </Select>
              )}
            </FormItem>
          }

          { this.props.form.getFieldValue('repeat') &&
            this.props.form.getFieldValue('repeat_type') === 2 &&
            <FormItem {...formItemLayout} label="Repeats For">
              {getFieldDecorator('repeat_num_sessions', {
                rules: [{ required: true, message: " " }],
                initialValue: 30
              })(
                <Select>
                  <Option value={5}>5 Sessions</Option>
                  <Option value={6}>6 Sessions</Option>
                  <Option value={7}>7 Sessions</Option>
                  <Option value={8}>8 Sessions</Option>
                  <Option value={9}>9 Sessions</Option>
                  <Option value={10}>10 Sessions</Option>
                  <Option value={11}>11 Sessions</Option>
                  <Option value={12}>12 Sessions</Option>
                  <Option value={13}>13 Sessions</Option>
                  <Option value={14}>14 Sessions</Option>
                  <Option value={15}>15 Sessions</Option>
                  <Option value={16}>16 Sessions</Option>
                  <Option value={17}>17 Sessions</Option>
                  <Option value={18}>18 Sessions</Option>
                  <Option value={19}>19 Sessions</Option>
                  <Option value={20}>20 Sessions</Option>
                  <Option value={21}>21 Sessions</Option>
                  <Option value={22}>22 Sessions</Option>
                  <Option value={23}>23 Sessions</Option>
                  <Option value={24}>24 Sessions</Option>
                  <Option value={25}>25 Sessions</Option>
                  <Option value={26}>26 Sessions</Option>
                  <Option value={27}>27 Sessions</Option>
                  <Option value={28}>28 Sessions</Option>
                  <Option value={29}>29 Sessions</Option>
                  <Option value={30}>30 Sessions</Option>
                </Select>
              )}
            </FormItem>
          }

          { this.props.form.getFieldValue('repeat') &&
            this.props.form.getFieldValue('repeat_type') === 1 &&
            <FormItem {...formItemLayout} label="Repeats Until">
              {getFieldDecorator('repeat_end_date', {
                rules: [{ required: true, message: " " }],
                initialValue: moment(moment().format('YYYY')+'-12-31')
              })(
                <DatePicker format='M/D/YYYY'/>
              )}
            </FormItem>
          }

        </Form>
      </Spin>
    );
  }

  renderBlockedForm = () => {
    const FormItem = Form.Item;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };
    const durations = [15,20,30,40,45,60,75,80,90,100,105,120];
    const manualTimeRange = Time.range(new Time(600),new Time(2200),15);
    const sd = this.props.form.getFieldValue('session_date');
    const st = this.props.form.getFieldValue('start_time');
    const { session_date, session_date_local } = Time.fixDateTime(sd, st);

    return (
      <Spin spinning={this.props.isBusy}>
        <Form className='compressed-form' autoComplete='false'>

          <FormItem {...formItemLayout} label="Provider">
            {getFieldDecorator('provider_id', {
              initialValue: this.props.provider_id
            })(
              <span>{this.props.provider_name}</span>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Date">
            {getFieldDecorator('session_date', {
              rules: [{ required: true, message: " " }],
              initialValue: moment().tz('America/Los_Angeles')
            })(
              <DatePicker format='MM/DD/YYYY' style={{ width: '140px' }} />
            )}
            <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
            {diffHrs && st ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {session_date_local.format('MM/DD/YYYY')}</Tag> : null}
          </FormItem>

          <FormItem {...formItemLayout} label="Duration">
            {getFieldDecorator('duration', {
              rules: [{ required: true, message: " " }],
              initialValue: 30
            })(
              <Select>
                {durations.map(x=><Option value={x} key={'d'+x}>{x} mins</Option>)}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Starting Time">
            {getFieldDecorator('start_time', {
              rules: [{ required: true, message: " " }],
            })(
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().startsWith(input.toLowerCase())
                }
                style={{width:'85%',marginRight:'5px'}}
              >
                {manualTimeRange.map((x)=> {
                  let time = `${x.name} ${Time.getPSTPDT()}`;
                  if (diffHrs) {
                    time = `${time} - (${x.local} Local)`;
                  }
                  return <Option key={x.id} value={x.id}>{time}</Option>;
                })}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Note">
            {getFieldDecorator('comment', {
              rules: [{ required: true, message: " " }],
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Repeats">
            {getFieldDecorator('repeat', {
              rules: [{ required: true, message: " " }],
              initialValue: false
            })(
              <Radio.Group>
                <Radio value={false}>Doesn't Repeat</Radio>
                <Radio value={true}>Repeats Weekly</Radio>
              </Radio.Group>
            )}
          </FormItem>

          { this.props.form.getFieldValue('repeat') &&
            <FormItem {...formItemLayout} label="Repeats Every">
              {getFieldDecorator('repeat_frequency', {
                rules: [{ required: true, message: " " }],
                initialValue: 1
              })(
                <Select>
                  <Option value={1}>1 Week</Option>
                  <Option value={2}>2 Weeks</Option>
                  <Option value={3}>3 Weeks</Option>
                  <Option value={4}>4 Weeks</Option>
                  <Option value={5}>5 Weeks</Option>
                  <Option value={6}>6 Weeks</Option>
                  <Option value={7}>7 Weeks</Option>
                  <Option value={8}>8 Weeks</Option>
                </Select>
              )}
            </FormItem>
          }

          { this.props.form.getFieldValue('repeat') &&
            <FormItem {...formItemLayout} label="Repeat Type">
              {getFieldDecorator('repeat_type', {
                rules: [{ required: true, message: " " }],
                initialValue: 1
              })(
                <Select>
                  <Option value={1}>Until an End Date</Option>
                  <Option value={2}>For # of Sessions</Option>
                </Select>
              )}
            </FormItem>
          }

          { this.props.form.getFieldValue('repeat') &&
            this.props.form.getFieldValue('repeat_type') === 2 &&
            <FormItem {...formItemLayout} label="Repeats For">
              {getFieldDecorator('repeat_num_sessions', {
                rules: [{ required: true, message: " " }],
                initialValue: 30
              })(
                <Select>
                  <Option value={5}>5 Sessions</Option>
                  <Option value={6}>6 Sessions</Option>
                  <Option value={7}>7 Sessions</Option>
                  <Option value={8}>8 Sessions</Option>
                  <Option value={9}>9 Sessions</Option>
                  <Option value={10}>10 Sessions</Option>
                  <Option value={11}>11 Sessions</Option>
                  <Option value={12}>12 Sessions</Option>
                  <Option value={13}>13 Sessions</Option>
                  <Option value={14}>14 Sessions</Option>
                  <Option value={15}>15 Sessions</Option>
                  <Option value={16}>16 Sessions</Option>
                  <Option value={17}>17 Sessions</Option>
                  <Option value={18}>18 Sessions</Option>
                  <Option value={19}>19 Sessions</Option>
                  <Option value={20}>20 Sessions</Option>
                  <Option value={21}>21 Sessions</Option>
                  <Option value={22}>22 Sessions</Option>
                  <Option value={23}>23 Sessions</Option>
                  <Option value={24}>24 Sessions</Option>
                  <Option value={25}>25 Sessions</Option>
                  <Option value={26}>26 Sessions</Option>
                  <Option value={27}>27 Sessions</Option>
                  <Option value={28}>28 Sessions</Option>
                  <Option value={29}>29 Sessions</Option>
                  <Option value={30}>30 Sessions</Option>
                </Select>
              )}
            </FormItem>
          }

          { this.props.form.getFieldValue('repeat') &&
            this.props.form.getFieldValue('repeat_type') === 1 &&
            <FormItem {...formItemLayout} label="Repeats Until">
              {getFieldDecorator('repeat_end_date', {
                rules: [{ required: true, message: " " }],
                initialValue: moment(moment().format('YYYY')+'-12-31')
              })(
                <DatePicker format='M/D/YYYY'/>
              )}
            </FormItem>
          }

        </Form>
      </Spin>
    );
  }

  getDurations(isPrescriber) {
    const session_date = this.props.form.getFieldValue('session_date');
    const session_type = this.props.form.getFieldValue('session_type');

    if (isPrescriber) {
      return [20,30,45,60];
    } else {
      // All intakes are 60 mins
      if (session_type === 1) {
        return [(this.props.intake_duration) ? this.props.intake_duration : 60];
      }
      return [45,60];
    }
  }

  onChangeDate = () => {
    this.props.form.setFieldsValue({
      duration: undefined
    });
  }

  onChangeType = () => {
    this.props.form.setFieldsValue({
      duration: undefined
    });
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };

    const isPrescriber = safe(this.props,'provider_roles',[]).includes('prescriber');
    const durations = this.getDurations(isPrescriber);

    const manualTimeRange = Time.range(new Time(600),new Time(2200),5);
    const sd = this.props.form.getFieldValue('session_date');
    const st = this.props.form.getFieldValue('start_time');
    const { session_date, session_date_local } = Time.fixDateTime(sd, st);

    return (
      <Spin spinning={this.props.isBusy}>
        <Form className='compressed-form' autoComplete='false'>

          <FormItem {...formItemLayout} label="Provider">
            {getFieldDecorator('provider_id', {
              initialValue: this.props.provider_id
            })(
              <span>{this.props.provider_name}</span>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Patient">
            {getFieldDecorator('patient_id', {
              rules: [{ required: true, message: " " }],
            })(
              <PatientFormSelector />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Session Date">
            {getFieldDecorator('session_date', {
              rules: [{ required: true, message: " " }],
              initialValue: moment().tz('America/Los_Angeles')
            })(
              <DatePicker format='MM/DD/YYYY' onChange={this.onChangeDate} style={{ width: '140px' }} />
            )}
            <Tag color="green" style={{ marginLeft: '.5rem'}}>{Time.getPSTPDT()}</Tag>
            {diffHrs && st ? <Tag color="blue" style={{ marginLeft: '.2rem'}}>Local {session_date_local.format('MM/DD/YYYY')}</Tag> : null}
          </FormItem>

          <FormItem {...formItemLayout} label="Type">
            {getFieldDecorator('session_type', {
              rules: [{ required: true, message: " " }],
              initialValue: 2
            })(
              <Select onChange={this.onChangeType}>
                <Option value={1}>Intake</Option>
                <Option value={2}>Followup</Option>
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Duration">
            {getFieldDecorator('duration', {
              rules: [{ required: true, message: " " }],
              initialValue: (!isPrescriber) ? 60 : 30
            })(
              <Select>
                {durations.map(x=><Option value={x} key={'d'+x}>{x} mins</Option>)}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Telemedicine">
            {getFieldDecorator('telemedicine', {
              rules: [{ required: true, message: " " }],
            })(
              <Select>
                <Option value={1}>Yes</Option>
                <Option value={0}>No</Option>
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Location">
            {getFieldDecorator('location_id', {
              rules: [{ required: true, message: " " }],
              initialValue: (this.props.locationsList.length > 0) ? this.props.locationsList[0].id : undefined
            })(
              <Select>
                {this.props.locationsList.map(x=><Select.Option value={x.id} key={x.id}>{x.name}</Select.Option>)}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Starting Time">
            {getFieldDecorator('start_time', {
              rules: [{ required: true, message: " " }],
            })(
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                { manualTimeRange.map((x) => {
                  let time = `${x.name} ${Time.getPSTPDT()}`;
                  if (diffHrs) {
                    time = `${time} - (${x.local} Local)`;
                  }
                  return <Option key={x.id} value={x.id}>{time}</Option>;
                }) }
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Repeats">
            {getFieldDecorator('repeat', {
              rules: [{ required: true, message: " " }],
              initialValue: false
            })(
              <Radio.Group>
                <Radio value={false}>Doesn't Repeat</Radio>
                <Radio value={true}>Repeats Weekly</Radio>
              </Radio.Group>
            )}
          </FormItem>

          { this.props.form.getFieldValue('repeat') &&
            <FormItem {...formItemLayout} label="Repeats Every">
              {getFieldDecorator('repeat_frequency', {
                rules: [{ required: true, message: " " }],
                initialValue: 1
              })(
                <Select>
                  <Option value={1}>1 Week</Option>
                  <Option value={2}>2 Weeks</Option>
                  <Option value={3}>3 Weeks</Option>
                  <Option value={4}>4 Weeks</Option>
                  <Option value={5}>5 Weeks</Option>
                  <Option value={6}>6 Weeks</Option>
                  <Option value={7}>7 Weeks</Option>
                  <Option value={8}>8 Weeks</Option>
                </Select>
              )}
            </FormItem>
          }

          { this.props.form.getFieldValue('repeat') &&
            <FormItem {...formItemLayout} label="Repeat Type">
              {getFieldDecorator('repeat_type', {
                rules: [{ required: true, message: " " }],
                initialValue: 1
              })(
                <Select>
                  <Option value={1}>Until an End Date</Option>
                  <Option value={2}>For # of Sessions</Option>
                </Select>
              )}
            </FormItem>
          }

          { this.props.form.getFieldValue('repeat') &&
            this.props.form.getFieldValue('repeat_type') === 2 &&
            <FormItem {...formItemLayout} label="Repeats For">
              {getFieldDecorator('repeat_num_sessions', {
                rules: [{ required: true, message: " " }],
                initialValue: 30
              })(
                <Select>
                  <Option value={5}>5 Sessions</Option>
                  <Option value={6}>6 Sessions</Option>
                  <Option value={7}>7 Sessions</Option>
                  <Option value={8}>8 Sessions</Option>
                  <Option value={9}>9 Sessions</Option>
                  <Option value={10}>10 Sessions</Option>
                  <Option value={11}>11 Sessions</Option>
                  <Option value={12}>12 Sessions</Option>
                  <Option value={13}>13 Sessions</Option>
                  <Option value={14}>14 Sessions</Option>
                  <Option value={15}>15 Sessions</Option>
                  <Option value={16}>16 Sessions</Option>
                  <Option value={17}>17 Sessions</Option>
                  <Option value={18}>18 Sessions</Option>
                  <Option value={19}>19 Sessions</Option>
                  <Option value={20}>20 Sessions</Option>
                  <Option value={21}>21 Sessions</Option>
                  <Option value={22}>22 Sessions</Option>
                  <Option value={23}>23 Sessions</Option>
                  <Option value={24}>24 Sessions</Option>
                  <Option value={25}>25 Sessions</Option>
                  <Option value={26}>26 Sessions</Option>
                  <Option value={27}>27 Sessions</Option>
                  <Option value={28}>28 Sessions</Option>
                  <Option value={29}>29 Sessions</Option>
                  <Option value={30}>30 Sessions</Option>
                </Select>
              )}
            </FormItem>
          }

          { this.props.form.getFieldValue('repeat') &&
            this.props.form.getFieldValue('repeat_type') === 1 &&
            <FormItem {...formItemLayout} label="Repeats Until">
              {getFieldDecorator('repeat_end_date', {
                rules: [{ required: true, message: " " }],
                initialValue: moment(moment().format('YYYY')+'-12-31')
              })(
                <DatePicker format='M/D/YYYY'/>
              )}
            </FormItem>
          }

        </Form>
      </Spin>
    );
  }

  changeTab = (key) => {
    this.setState({
      activeTab: key
    },()=>{
      this.props.form.resetFields();
    });
  }

  renderDialog = () => {
    const TabPane = Tabs.TabPane;

    if (this.props.isAdmin) {
      return (
        <div className='schedule-session'>
          <Tabs activeKey={this.state.activeTab} onChange={this.changeTab}>
            <TabPane tab='Session' key='session'>
              { (this.state.activeTab === 'session') ? this.renderForm() : null }
            </TabPane>

            <TabPane tab='Admin Time' key='admin'>
              { (this.state.activeTab === 'admin') ? this.renderAdminForm() : null }
            </TabPane>

            <TabPane tab='Blocked-Out Time' key='blocked'>
              { (this.state.activeTab === 'blocked') ? this.renderBlockedForm() : null }
            </TabPane>
          </Tabs>
        </div>
      );
    } else {
      return this.renderForm();
    }
  }

  render() {
    return (
      <Modal
        title="Manually Schedule Session"
        visible={this.props.visible}
        destroyOnClose={true}
        onOk={this.handleOk}
        okText='Create'
        onCancel={this.handleCancel}
      >
        {this.renderDialog()}
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    locationsList: safe(state,'emr.provider.locations.data',[]),
    calendar_date: safe(state,'emr.emr.active_date',moment().format('YYYY-MM-DD')),
    isAdmin: isAdmin(state) || isClinicalSupervisor(state),
    isHourly: (safe(state,'emr.emr.calendarProvider.employment_type',1) === 2) ? true : false,
    isBusy: (
      safe(state,'emr.session.create.processing',false) ||
      safe(state,'emr.provider.opentimes.processing',false)
    ) ? true : false,
    intake_duration: safe(state,'shared.domain.activeUser.intake_duration',60),
  }
}

export default connect(mapStateToProps,actions)(Form.create()(ManualSessionCreate));
