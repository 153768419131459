import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Tabs, Table, Modal, message, Spin } from 'antd';
import * as actions from '../../../actions/shared';
import { credentialToString } from '../../../helpers/credential';
import { rolesToString } from '../../../helpers/roles';
import { safe } from '../../../helpers/reduxSelectors';

class Users extends Component {

  componentDidMount() {
    this.props.getUserList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deactivate !== this.props.deactivate) {
      if (this.props.deactivate.success) {
        this.props.userDeactivateClear();
        message.success("User Deactivated");
      } else if (this.props.deactivate.error) {
        this.props.userDeactivateClear();
        message.error(this.props.deactivate.error.data.detail);
      }
    }

    if (prevProps.reactivate !== this.props.reactivate) {
      if (this.props.reactivate.success) {
        this.props.userReactivateClear();
        message.success("User Reactivated");
      } else if (this.props.reactivate.error) {
        this.props.userReactivateClear();
        message.error(this.props.deactivate.error.data.detail);
      }
    }
  }

  displayAvatar = (r) => {
    const record = (r) ? r : {};
    if (record && record.credential >= 1) {
      return (
        <span>
          <Avatar style={{marginRight:'5px'}} src={this.getImageName(record.id)}/>
          {record.full_name}
        </span>
      );
    } else {
      return (
        <span>
          <Avatar style={{marginRight:'5px',backgroundColor:'blue'}}>{record.initials}</Avatar>
          {record.full_name}
        </span>
      )
    }
  }

  deactivate = (record) => {
    const confirm = Modal.confirm;
    const du = this.props.userDeactivate;

    confirm({
      title: 'Deactivate ' + record.full_name + '?',
      onOk() { du(record.id) },
      onCancel() { }
    });
  }

  reactivate = (record) => {
    const confirm = Modal.confirm;
    const ru = this.props.userReactivate;

    confirm({
      title: 'Reactivate ' + record.full_name + '?',
      onOk() { ru(record.id) },
      onCancel() { }
    });
  }

  getImageName = (id) => {
    return '/api/v1/provider/'+id+'/image';
  }

  renderActiveTable = () => {
    const { Column } = Table;
    const dataSource = safe(this.props,'list.data',[]).filter(item => item.active === true);

    return (
      <Table dataSource={dataSource} pagination={false} rowKey="id" size='small'>
        <Column
          title="Name"
          dataIndex="full_name"
          key="full_name"
          defaultSortOrder='ascend'
          sorter={(a, b) => a.full_name.localeCompare(b.full_name)}
          render={(text,record) => (
            <Link to={"/settings/users/"+record.id} style={{whiteSpace: 'nowrap'}}>
              { this.displayAvatar(record) }
            </Link>
          )}
        />

        <Column
          title="Credential"
          dataIndex="credential"
          sorter={(a, b) => a.credential - b.credential}
          render={(text,record) => {
            return credentialToString(text)
          }}
        />

        <Column
          title="Roles"
          dataIndex="roles"
          render={(text,record) =>
            rolesToString(text)
          }
        />

        <Column
          title="Supervisor"
          dataIndex="is_supervisor"
          render={(text,record) => {
            if (record.supervisorUser) {
              return record.supervisorUser.full_name;
            } else {
              return (record.is_supervisor) ? "SUPERVISOR" : "";
            }
          }}
        />

        <Column
          title="Action"
          width={100}
          render={(text, record, index) => (
            <a onClick={() => { this.deactivate(record) }}>Deactivate</a>
          )}
        />
      </Table>
    );
  }

  renderInactiveTable = () => {
    const { Column } = Table;
    const dataSource = safe(this.props,'list.data',[]).filter(item => item.active === false);

    return (
      <Table dataSource={dataSource} rowKey="id" pagination={false} size='small'>
        <Column
          title="Name"
          dataIndex="full_name"
          key="full_name"
          defaultSortOrder='ascend'
          sorter={(a, b) => a.full_name.localeCompare(b.full_name)}
        />

        <Column
          title="Credential"
          dataIndex="credential"
          sorter={(a, b) => a.credential - b.credential}
          render={(text,record) =>
            credentialToString(text)
          }
        />

        <Column
          title="Roles"
          dataIndex="roles"
          render={(text,record) =>
            rolesToString(text)
          }
        />

        <Column
          title="Supervisor"
          render={(text,record) => {
            if (record.supervisorUser) {
              return record.supervisorUser.full_name;
            } else {
              return '';
            }
          }}
        />

        <Column
          title="Deactivated"
          dataIndex="deactivatedAt"
          render={(text,record) => (
            moment(text).format('MM/DD/YYYY hh:mm A')
          )}
        />

        <Column
          title="Action"
          key="action"
          width={100}
          render={(text, record) => (
            <span>
              <a onClick={() => { this.reactivate(record) }}>Reactivate</a>
            </span>
          )}
        />
      </Table>
    );
  }

  userStats = () => {
    let clinicians = 0;
    let admin = 0;

    safe(this.props,'list.data',[]).forEach(x=>{
      if (x.active) {
        if (x.credential) {
          clinicians++;
        } else {
          admin++;
        }
      }
    });

    return (
      <div>
        <div>
          <strong>Active Clinician Accounts: </strong>: {clinicians}
        </div>
        <div>
          <strong>Active Admin Accounts: </strong>: {admin}
        </div>
        <div>
          <strong>Total Active Accounts</strong>: {clinicians+admin}
        </div>
      </div>
    );
  }

  render() {
    const TabPane = Tabs.TabPane;

    return (
      <div style={{padding:'10px'}}>
        <Spin spinning={this.props.isBusy} size="large">
          <h3>Users</h3>
          { this.userStats() }
          <Tabs defaultActiveKey="1">
            <TabPane tab="Active" key="1">
              { this.renderActiveTable() }
            </TabPane>
            <TabPane tab="Inactive" key="2">
              { this.renderInactiveTable() }
            </TabPane>
          </Tabs>
          <div style={{position:'fixed',bottom:40,right:40}}>
            <Button
              onClick={() => {this.props.history.push('/settings/users/0')}}
              size='large'
              shape='circle'
              type="primary"
              icon={<PlusOutlined />}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'shared.user.list.processing',false) ||
      safe(state,'shared.user.deactivate.processing',false) ||
      safe(state,'user.reactivate.processing',false)
    ) ? true : false,
    list: safe(state,'shared.user.list',{}),
    deactivate: safe(state,'shared.user.deactivate',{}),
    reactivate: safe(state,'shared.user.reactivate',{})
  }
}

export default connect(mapStateToProps,actions)(Users);
