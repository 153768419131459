import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Spin, Button, Table } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import { safe } from '../../../../helpers/reduxSelectors';
import * as actions from '../../../../actions/emr';
import { processEventNotification } from '../../../../helpers/util';
import VacationCoverageEntry from './VacationCoverageEntry';

class VacationCoverageAdmin extends React.Component {
  state = {
    entryDialogVisible: false,
    providerId: null
  };

  componentDidUpdate(prevProps,prevState) {
    if (prevProps.visible === false && this.props.visible === true) {
      this.props.getAdminCoverageList();
    }
    processEventNotification(prevProps,this.props,'deleteEvent','Coverage Deleted');
  }

  onClickCancel = () => {
    this.props.closeDialog();
  }

  onClickSave = () => {
    this.props.form.validateFields((err,values) => {
      if (!err) {
      }
    });
  }

  deleteEntry = (provider_id) => {
    this.props.deleteProviderCoverage(provider_id);
  }

  displayCoverageTable = () => {
    const columns = [{
      title: 'Clinician on Vacation',
      dataIndex: 'full_name',
      render: (value,record) => {
        return (
          <Button
            onClick={()=>{this.showEntryDialog(record.id)}}
            type='link'
            size='small'
          >
            {value}
          </Button>
        );
      }
    },{
      title: 'Coverage Start',
      width: 150,
      dataIndex: 'covering_start_date',
      render: (value) => {
        const d = moment(value).format('MM/DD/YYYY');
        if (moment().diff(value,'days') > 0) {
          return <span style={{color:'red'}}>{d}</span>
        } else {
          return d;
        }
      }
    },{
      title: 'Coverage End',
      width: 150,
      dataIndex: 'covering_end_date',
      render: (value) => {
        const d = moment(value).format('MM/DD/YYYY');
        if (moment().diff(value,'days') > 0) {
          return <span style={{color:'red'}}>{d}</span>
        } else {
          return d;
        }
      }
    },{
      title: 'Covering Clinician',
      dataIndex: 'covering_provider_id',
      render: (value,record) => {
        return safe(record,'coveringProvider.full_name')
      }
    },{
      title: 'Action',
      width: 50,
      align: 'right',
      render: (value,record) => {
        return <Button size='small' type='link' onClick={() => { this.deleteEntry(record.id)} }><DeleteOutlined /></Button>;
      }
    }];

    return (
      <div>
        <div style={{paddingBottom:'20px'}}>
          <Button type='primary' onClick={()=>{this.showEntryDialog(null)}}>Add New Vacation Coverage</Button>
        </div>
        <div style={{overflow: 'scroll',height:'500px'}}>
          <Table
            dataSource={this.props.list}
            columns={columns}
            size='small'
            rowKey='id'
            pagination={false}
          />
        </div>
      </div>
    )
  }

  showEntryDialog = (providerId) => {
    this.setState({
      entryDialogVisible: true,
      providerId: providerId
    });
  }

  closeEntryDialog = () => {
    this.setState({
      entryDialogVisible: false
    });
  }

  render() {
    return (
      <div>
        <Modal
          title='Vacation Coverage'
          visible={this.props.visible}
          okText='Save'
          onOk={this.onClickSave}
          onCancel={this.onClickCancel}
          footer={null}
          width={900}
        >
          <Spin spinning={this.props.isBusy}>
            { this.displayCoverageTable() }
            <VacationCoverageEntry
              visible={this.state.entryDialogVisible}
              provider_id={this.state.providerId}
              closeDialog={this.closeEntryDialog}
            />
          </Spin>
        </Modal>

      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: (
      safe(state,'emr.coverage.adminlist.processing',false)
    ) ? true : false,
    list: safe(state,'emr.coverage.adminlist.data',[]),
    deleteEvent: safe(state,'emr.admincoverage.delete',{})
  }
}

export default connect(mapStateToProps,actions)(Form.create()(VacationCoverageAdmin));
