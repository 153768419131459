export const SCHEDULER_STATE_SET = 'SCHEDULER_STATE_SET';

export const SCHEDULER_INTAKES_SETAVAIL_REQUEST = 'SCHEDULER_INTAKES_SETAVAIL_REQUEST';
export const SCHEDULER_INTAKES_SETAVAIL_SUCCESS = 'SCHEDULER_INTAKES_SETAVAIL_SUCCESS';
export const SCHEDULER_INTAKES_SETAVAIL_FAILURE = 'SCHEDULER_INTAKES_SETAVAIL_FAILURE';
export const SCHEDULER_INTAKES_SETAVAIL_CLEAR = 'SCHEDULER_INTAKES_SETAVAIL_CLEAR';
export const SCHEDULER_INTAKES_CREATEITEM_REQUEST = 'SCHEDULER_INTAKES_CREATEITEM_REQUEST';
export const SCHEDULER_INTAKES_CREATEITEM_SUCCESS = 'SCHEDULER_INTAKES_CREATEITEM_SUCCESS';
export const SCHEDULER_INTAKES_CREATEITEM_FAILURE = 'SCHEDULER_INTAKES_CREATEITEM_FAILURE';
export const SCHEDULER_INTAKES_CREATEITEM_CLEAR = 'SCHEDULER_INTAKES_CREATEITEM_CLEAR';
export const SCHEDULER_INTAKES_DELETEITEM_REQUEST = 'SCHEDULER_INTAKES_DELETEITEM_REQUEST';
export const SCHEDULER_INTAKES_DELETEITEM_SUCCESS = 'SCHEDULER_INTAKES_DELETEITEM_SUCCESS';
export const SCHEDULER_INTAKES_DELETEITEM_FAILURE = 'SCHEDULER_INTAKES_DELETEITEM_FAILURE';
export const SCHEDULER_INTAKES_DELETEITEM_CLEAR = 'SCHEDULER_INTAKES_DELETEITEM_CLEAR';

export const SCHEDULER_INTAKES_UPDATE_REQUEST = 'SCHEDULER_INTAKES_UPDATE_REQUEST';
export const SCHEDULER_INTAKES_UPDATE_SUCCESS = 'SCHEDULER_INTAKES_UPDATE_SUCCESS';
export const SCHEDULER_INTAKES_UPDATE_FAILURE = 'SCHEDULER_INTAKES_UPDATE_FAILURE';
export const SCHEDULER_INTAKES_UPDATE_CLEAR = 'SCHEDULER_INTAKES_UPDATE_CLEAR';

export const SCHEDULER_PROVIDER_COVERAGE_REQUEST = 'SCHEDULER_PROVIDER_COVERAGE_REQUEST';
export const SCHEDULER_PROVIDER_COVERAGE_SUCCESS = 'SCHEDULER_PROVIDER_COVERAGE_SUCCESS';
export const SCHEDULER_PROVIDER_COVERAGE_FAILURE = 'SCHEDULER_PROVIDER_COVERAGE_FAILURE';
export const SCHEDULER_PROVIDER_COVERAGE_CLEAR = 'SCHEDULER_PROVIDER_COVERAGE_CLEAR';

export const SCHEDULER_TIMEOFF_STATS_REQUEST = 'SCHEDULER_TIMEOFF_STATS_REQUEST';
export const SCHEDULER_TIMEOFF_STATS_SUCCESS = 'SCHEDULER_TIMEOFF_STATS_SUCCESS';
export const SCHEDULER_TIMEOFF_STATS_FAILURE = 'SCHEDULER_TIMEOFF_STATS_FAILURE';
export const SCHEDULER_TIMEOFF_STATS_CLEAR = 'SCHEDULER_TIMEOFF_STATS_CLEAR';

export const SCHEDULER_TIMEOFF_LIST_REQUEST = 'SCHEDULER_TIMEOFF_LIST_REQUEST';
export const SCHEDULER_TIMEOFF_LIST_SUCCESS = 'SCHEDULER_TIMEOFF_LIST_SUCCESS';
export const SCHEDULER_TIMEOFF_LIST_FAILURE = 'SCHEDULER_TIMEOFF_LIST_FAILURE';
export const SCHEDULER_TIMEOFF_LIST_CLEAR = 'SCHEDULER_TIMEOFF_LIST_CLEAR';
export const SCHEDULER_TIMEOFF_UPDATE_REQUEST = 'SCHEDULER_TIMEOFF_UPDATE_REQUEST';
export const SCHEDULER_TIMEOFF_UPDATE_SUCCESS = 'SCHEDULER_TIMEOFF_UPDATE_SUCCESS';
export const SCHEDULER_TIMEOFF_UPDATE_FAILURE = 'SCHEDULER_TIMEOFF_UPDATE_FAILURE';
export const SCHEDULER_TIMEOFF_UPDATE_CLEAR = 'SCHEDULER_TIMEOFF_UPDATE_CLEAR';
export const SCHEDULER_TIMEOFF_REJECT_REQUEST = 'SCHEDULER_TIMEOFF_REJECT_REQUEST';
export const SCHEDULER_TIMEOFF_REJECT_SUCCESS = 'SCHEDULER_TIMEOFF_REJECT_SUCCESS';
export const SCHEDULER_TIMEOFF_REJECT_FAILURE = 'SCHEDULER_TIMEOFF_REJECT_FAILURE';
export const SCHEDULER_TIMEOFF_REJECT_CLEAR = 'SCHEDULER_TIMEOFF_REJECT_CLOSE';
export const SCHEDULER_TIMEOFF_APPROVE_REQUEST = 'SCHEDULER_TIMEOFF_APPROVE_REQUEST';
export const SCHEDULER_TIMEOFF_APPROVE_SUCCESS = 'SCHEDULER_TIMEOFF_APPROVE_SUCCESS';
export const SCHEDULER_TIMEOFF_APPROVE_FAILURE = 'SCHEDULER_TIMEOFF_APPROVE_FAILURE';
export const SCHEDULER_TIMEOFF_APPROVE_CLEAR = 'SCHEDULER_TIMEOFF_APPROVE_CLOSE';
export const SCHEDULER_TIMEOFF_CANCEL_REQUEST = 'SCHEDULER_TIMEOFF_CANCEL_REQUEST';
export const SCHEDULER_TIMEOFF_CANCEL_SUCCESS = 'SCHEDULER_TIMEOFF_CANCEL_SUCCESS';
export const SCHEDULER_TIMEOFF_CANCEL_FAILURE = 'SCHEDULER_TIMEOFF_CANCEL_FAILURE';
export const SCHEDULER_TIMEOFF_CANCEL_CLEAR = 'SCHEDULER_TIMEOFF_CANCEL_CLOSE';
export const SCHEDULER_TIMEOFF_CREATE_REQUEST = 'SCHEDULER_TIMEOFF_CREATE_REQUEST';
export const SCHEDULER_TIMEOFF_CREATE_SUCCESS = 'SCHEDULER_TIMEOFF_CREATE_SUCCESS';
export const SCHEDULER_TIMEOFF_CREATE_FAILURE = 'SCHEDULER_TIMEOFF_CREATE_FAILURE';
export const SCHEDULER_TIMEOFF_CREATE_CLEAR = 'SCHEDULER_TIMEOFF_CREATE_CLOSE';

export const SCHEDULER_PROVIDERINFO_GET_REQUEST = 'SCHEDULER_PROVIDERINFO_GET_REQUEST';
export const SCHEDULER_PROVIDERINFO_GET_SUCCESS = 'SCHEDULER_PROVIDERINFO_GET_SUCCESS';
export const SCHEDULER_PROVIDERINFO_GET_FAILURE = 'SCHEDULER_PROVIDERINFO_GET_FAILURE';
export const SCHEDULER_PROVIDERINFO_GET_CLEAR = 'SCHEDULER_PROVIDERINFO_GET_CLEAR';
export const SCHEDULER_PROVIDERINFO_SAVE_REQUEST = 'SCHEDULER_PROVIDERINFO_SAVE_REQUEST';
export const SCHEDULER_PROVIDERINFO_SAVE_SUCCESS = 'SCHEDULER_PROVIDERINFO_SAVE_SUCCESS';
export const SCHEDULER_PROVIDERINFO_SAVE_FAILURE = 'SCHEDULER_PROVIDERINFO_SAVE_FAILURE';
export const SCHEDULER_PROVIDERINFO_SAVE_CLEAR = 'SCHEDULER_PROVIDERINFO_SAVE_CLEAR';

export const SCHEDULER_PRODUCTIVITY_REQUEST = 'SCHEDULER_PRODUCTIVITY_REQUEST';
export const SCHEDULER_PRODUCTIVITY_SUCCESS = 'SCHEDULER_PRODUCTIVITY_SUCCESS';
export const SCHEDULER_PRODUCTIVITY_FAILURE = 'SCHEDULER_PRODUCTIVITY_FAILURE';
export const SCHEDULER_PRODUCTIVITY_CLEAR = 'SCHEDULER_PRODUCTIVITY_CLEAR';

export const SCHEDULER_PROVIDER_PRODUCTIVITY_REQUEST = 'SCHEDULER_PROVIDER_PRODUCTIVITY_REQUEST';
export const SCHEDULER_PROVIDER_PRODUCTIVITY_SUCCESS = 'SCHEDULER_PROVIDER_PRODUCTIVITY_SUCCESS';
export const SCHEDULER_PROVIDER_PRODUCTIVITY_FAILURE = 'SCHEDULER_PROVIDER_PRODUCTIVITY_FAILURE';
export const SCHEDULER_PROVIDER_PRODUCTIVITY_CLEAR = 'SCHEDULER_PROVIDER_PRODUCTIVITY_CLEAR';

export const SCHEDULER_TEMPLATE_LIST_REQUEST = 'SCHEDULER_TEMPLATE_LIST_REQUEST';
export const SCHEDULER_TEMPLATE_LIST_SUCCESS = 'SCHEDULER_TEMPLATE_LIST_SUCCESS';
export const SCHEDULER_TEMPLATE_LIST_FAILURE = 'SCHEDULER_TEMPLATE_LIST_FAILURE';
export const SCHEDULER_TEMPLATE_LIST_CLEAR = 'SCHEDULER_TEMPLATE_LIST_CLEAR';
export const SCHEDULER_TEMPLATE_GET_REQUEST = 'SCHEDULER_TEMPLATE_GET_REQUEST';
export const SCHEDULER_TEMPLATE_GET_SUCCESS = 'SCHEDULER_TEMPLATE_GET_SUCCESS';
export const SCHEDULER_TEMPLATE_GET_FAILURE = 'SCHEDULER_TEMPLATE_GET_FAILURE';
export const SCHEDULER_TEMPLATE_GET_CLEAR = 'SCHEDULER_TEMPLATE_GET_CLEAR';
export const SCHEDULER_TEMPLATE_CREATE_REQUEST = 'SCHEDULER_TEMPLATE_CREATE_REQUEST';
export const SCHEDULER_TEMPLATE_CREATE_SUCCESS = 'SCHEDULER_TEMPLATE_CREATE_SUCCESS';
export const SCHEDULER_TEMPLATE_CREATE_FAILURE = 'SCHEDULER_TEMPLATE_CREATE_FAILURE';
export const SCHEDULER_TEMPLATE_CREATE_CLEAR = 'SCHEDULER_TEMPLATE_CREATE_CLEAR';
export const SCHEDULER_TEMPLATE_UPDATE_REQUEST = 'SCHEDULER_TEMPLATE_UPDATE_REQUEST';
export const SCHEDULER_TEMPLATE_UPDATE_SUCCESS = 'SCHEDULER_TEMPLATE_UPDATE_SUCCESS';
export const SCHEDULER_TEMPLATE_UPDATE_FAILURE = 'SCHEDULER_TEMPLATE_UPDATE_FAILURE';
export const SCHEDULER_TEMPLATE_UPDATE_CLEAR = 'SCHEDULER_TEMPLATE_UPDATE_CLEAR';
export const SCHEDULER_TEMPLATE_REMOVE_REQUEST = 'SCHEDULER_TEMPLATE_REMOVE_REQUEST';
export const SCHEDULER_TEMPLATE_REMOVE_SUCCESS = 'SCHEDULER_TEMPLATE_REMOVE_SUCCESS';
export const SCHEDULER_TEMPLATE_REMOVE_FAILURE = 'SCHEDULER_TEMPLATE_REMOVE_FAILURE';
export const SCHEDULER_TEMPLATE_REMOVE_CLEAR = 'SCHEDULER_TEMPLATE_REMOVE_CLEAR';
export const SCHEDULER_TEMPLATE_LOCK_REQUEST = 'SCHEDULER_TEMPLATE_LOCK_REQUEST';
export const SCHEDULER_TEMPLATE_LOCK_SUCCESS = 'SCHEDULER_TEMPLATE_LOCK_SUCCESS';
export const SCHEDULER_TEMPLATE_LOCK_FAILURE = 'SCHEDULER_TEMPLATE_LOCK_FAILURE';
export const SCHEDULER_TEMPLATE_LOCK_CLEAR = 'SCHEDULER_TEMPLATE_LOCK_CLEAR';
export const SCHEDULER_TEMPLATE_UNLOCK_REQUEST = 'SCHEDULER_TEMPLATE_UNLOCK_REQUEST';
export const SCHEDULER_TEMPLATE_UNLOCK_SUCCESS = 'SCHEDULER_TEMPLATE_UNLOCK_SUCCESS';
export const SCHEDULER_TEMPLATE_UNLOCK_FAILURE = 'SCHEDULER_TEMPLATE_UNLOCK_FAILURE';
export const SCHEDULER_TEMPLATE_UNLOCK_CLEAR = 'SCHEDULER_TEMPLATE_UNLOCK_CLEAR';
export const SCHEDULER_TEMPLATE_COPY_REQUEST = 'SCHEDULER_TEMPLATE_COPY_REQUEST';
export const SCHEDULER_TEMPLATE_COPY_SUCCESS = 'SCHEDULER_TEMPLATE_COPY_SUCCESS';
export const SCHEDULER_TEMPLATE_COPY_FAILURE = 'SCHEDULER_TEMPLATE_COPY_FAILURE';
export const SCHEDULER_TEMPLATE_COPY_CLEAR = 'SCHEDULER_TEMPLATE_COPY_CLEAR';

export const SCHEDULER_TEMPLATEITEM_CREATE_REQUEST = 'SCHEDULER_TEMPLATEITEM_CREATE_REQUEST';
export const SCHEDULER_TEMPLATEITEM_CREATE_SUCCESS = 'SCHEDULER_TEMPLATEITEM_CREATE_SUCCESS';
export const SCHEDULER_TEMPLATEITEM_CREATE_FAILURE = 'SCHEDULER_TEMPLATEITEM_CREATE_FAILURE';
export const SCHEDULER_TEMPLATEITEM_CREATE_CLEAR = 'SCHEDULER_TEMPLATEITEM_CREATE_CLEAR';
export const SCHEDULER_TEMPLATEITEM_UPDATE_REQUEST = 'SCHEDULER_TEMPLATEITEM_UPDATE_REQUEST';
export const SCHEDULER_TEMPLATEITEM_UPDATE_SUCCESS = 'SCHEDULER_TEMPLATEITEM_UPDATE_SUCCESS';
export const SCHEDULER_TEMPLATEITEM_UPDATE_FAILURE = 'SCHEDULER_TEMPLATEITEM_UPDATE_FAILURE';
export const SCHEDULER_TEMPLATEITEM_UPDATE_CLEAR = 'SCHEDULER_TEMPLATEITEM_UPDATE_CLEAR';
export const SCHEDULER_TEMPLATEITEM_REMOVE_REQUEST = 'SCHEDULER_TEMPLATEITEM_REMOVE_REQUEST';
export const SCHEDULER_TEMPLATEITEM_REMOVE_SUCCESS = 'SCHEDULER_TEMPLATEITEM_REMOVE_SUCCESS';
export const SCHEDULER_TEMPLATEITEM_REMOVE_FAILURE = 'SCHEDULER_TEMPLATEITEM_REMOVE_FAILURE';
export const SCHEDULER_TEMPLATEITEM_REMOVE_CLEAR = 'SCHEDULER_TEMPLATEITEM_REMOVE_CLEAR';

export const SCHEDULER_PROVIDER_LIST_REQUEST = 'SCHEDULER_PROVIDER_LIST_REQUEST';
export const SCHEDULER_PROVIDER_LIST_SUCCESS = 'SCHEDULER_PROVIDER_LIST_SUCCESS';
export const SCHEDULER_PROVIDER_LIST_FAILURE = 'SCHEDULER_PROVIDER_LIST_FAILURE';
export const SCHEDULER_PROVIDER_LIST_CLEAR = 'SCHEDULER_PROVIDER_LIST_CLEAR';
export const SCHEDULER_PROVIDER_GET_REQUEST = 'SCHEDULER_PROVIDER_GET_REQUEST';
export const SCHEDULER_PROVIDER_GET_SUCCESS = 'SCHEDULER_PROVIDER_GET_SUCCESS';
export const SCHEDULER_PROVIDER_GET_FAILURE = 'SCHEDULER_PROVIDER_GET_FAILURE';
export const SCHEDULER_PROVIDER_GET_CLEAR = 'SCHEDULER_PROVIDER_GET_CLEAR';
export const SCHEDULER_PROVIDER_CREATE_REQUEST = 'SCHEDULER_PROVIDER_CREATE_REQUEST';
export const SCHEDULER_PROVIDER_CREATE_SUCCESS = 'SCHEDULER_PROVIDER_CREATE_SUCCESS';
export const SCHEDULER_PROVIDER_CREATE_FAILURE = 'SCHEDULER_PROVIDER_CREATE_FAILURE';
export const SCHEDULER_PROVIDER_CREATE_CLEAR = 'SCHEDULER_PROVIDER_CREATE_CLEAR';
export const SCHEDULER_PROVIDER_UPDATE_REQUEST = 'SCHEDULER_PROVIDER_UPDATE_REQUEST';
export const SCHEDULER_PROVIDER_UPDATE_SUCCESS = 'SCHEDULER_PROVIDER_UPDATE_SUCCESS';
export const SCHEDULER_PROVIDER_UPDATE_FAILURE = 'SCHEDULER_PROVIDER_UPDATE_FAILURE';
export const SCHEDULER_PROVIDER_UPDATE_CLEAR = 'SCHEDULER_PROVIDER_UPDATE_CLEAR';
export const SCHEDULER_PROVIDER_REMOVE_REQUEST = 'SCHEDULER_PROVIDER_REMOVE_REQUEST';
export const SCHEDULER_PROVIDER_REMOVE_SUCCESS = 'SCHEDULER_PROVIDER_REMOVE_SUCCESS';
export const SCHEDULER_PROVIDER_REMOVE_FAILURE = 'SCHEDULER_PROVIDER_REMOVE_FAILURE';
export const SCHEDULER_PROVIDER_REMOVE_CLEAR = 'SCHEDULER_PROVIDER_REMOVE_CLEAR';
export const SCHEDULER_PROVIDER_LOCATIONS_REQUEST = 'SCHEDULER_PROVIDER_LOCATIONS_REQUEST';
export const SCHEDULER_PROVIDER_LOCATIONS_SUCCESS = 'SCHEDULER_PROVIDER_LOCATIONS_SUCCESS';
export const SCHEDULER_PROVIDER_LOCATIONS_FAILURE = 'SCHEDULER_PROVIDER_LOCATIONS_FAILURE';
export const SCHEDULER_PROVIDER_LOCATIONS_CLEAR = 'SCHEDULER_PROVIDER_LOCATIONS_CLEAR';

export const SCHEDULER_SESSION_CREATE_REQUEST = 'SCHEDULER_SESSION_CREATE_REQUEST';
export const SCHEDULER_SESSION_CREATE_SUCCESS = 'SCHEDULER_SESSION_CREATE_SUCCESS';
export const SCHEDULER_SESSION_CREATE_FAILURE = 'SCHEDULER_SESSION_CREATE_FAILURE';
export const SCHEDULER_SESSION_CREATE_CLEAR = 'SCHEDULER_SESSION_CREATE_CLEAR';
export const SCHEDULER_SESSION_UPDATE_REQUEST = 'SCHEDULER_SESSION_UPDATE_REQUEST';
export const SCHEDULER_SESSION_UPDATE_SUCCESS = 'SCHEDULER_SESSION_UPDATE_SUCCESS';
export const SCHEDULER_SESSION_UPDATE_FAILURE = 'SCHEDULER_SESSION_UPDATE_FAILURE';
export const SCHEDULER_SESSION_UPDATE_CLEAR = 'SCHEDULER_SESSION_UPDATE_CLEAR';
export const SCHEDULER_SESSION_REMOVE_REQUEST = 'SCHEDULER_SESSION_REMOVE_REQUEST';
export const SCHEDULER_SESSION_REMOVE_SUCCESS = 'SCHEDULER_SESSION_REMOVE_SUCCESS';
export const SCHEDULER_SESSION_REMOVE_FAILURE = 'SCHEDULER_SESSION_REMOVE_FAILURE';
export const SCHEDULER_SESSION_REMOVE_CLEAR = 'SCHEDULER_SESSION_REMOVE_CLEAR';

export const SCHEDULER_CALENDAR_LIST_REQUEST = 'SCHEDULER_CALENDAR_LIST_REQUEST';
export const SCHEDULER_CALENDAR_LIST_SUCCESS = 'SCHEDULER_CALENDAR_LIST_SUCCESS';
export const SCHEDULER_CALENDAR_LIST_FAILURE = 'SCHEDULER_CALENDAR_LIST_FAILURE';
export const SCHEDULER_CALENDAR_LIST_CLEAR = 'SCHEDULER_CALENDAR_LIST_CLEAR';
export const SCHEDULER_CALENDAR_SEARCH_REQUEST = 'SCHEDULER_CALENDAR_SEARCH_REQUEST';
export const SCHEDULER_CALENDAR_SEARCH_SUCCESS = 'SCHEDULER_CALENDAR_SEARCH_SUCCESS';
export const SCHEDULER_CALENDAR_SEARCH_FAILURE = 'SCHEDULER_CALENDAR_SEARCH_FAILURE';
export const SCHEDULER_CALENDAR_SEARCH_CLEAR = 'SCHEDULER_CALENDAR_SEARCH_CLEAR';

export const SCHEDULER_HISTORY_LIST_REQUEST = 'SCHEDULER_HISTORY_LIST_REQUEST';
export const SCHEDULER_HISTORY_LIST_SUCCESS = 'SCHEDULER_HISTORY_LIST_SUCCESS';
export const SCHEDULER_HISTORY_LIST_FAILURE = 'SCHEDULER_HISTORY_LIST_FAILURE';
export const SCHEDULER_HISTORY_LIST_CLEAR = 'SCHEDULER_HISTORY_LIST_CLEAR';
