import React from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';

import InsPayments from './Payments_/InsPayments';
import PtPayments from './Payments_/PtPayments';

import { safe } from '../../../helpers/reduxSelectors';
import * as billing_actions from '../../../actions/billing';
import * as shared_actions from '../../../actions/shared';

class Payments extends React.Component {

  componentDidUpdate(prevProps) {
    // Anytime, the URL selection of ins or pt payments changes, then change
    // the underlying selection in the state. We do this so that we can easily
    // return to this page.
    if (prevProps.match.params.type !== this.props.match.params.type) {
      this.props.setBillingState({
        payments_tab:this.props.match.params.type
      });
    }
  }

  onTabSelect = (key) => {
    this.props.history.push(key);
  }

  render() {
    const TabPane = Tabs.TabPane;

    return (
      <div className='main'>
        <h3>Payments</h3>
        <Tabs activeKey={this.props.payments_tab} onChange={this.onTabSelect}>
          <TabPane tab="Insurance Payments" key="1">
            <InsPayments/>
          </TabPane>
          <TabPane tab="Patient Payments" key="2">
            <PtPayments/>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    payments_tab: safe(state,'shared.domain.billing.payments_tab',"1"),
  }
}

export default connect(mapStateToProps,{...billing_actions,...shared_actions})(Payments);
