import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, DatePicker, Button } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import * as actions from '../../../../../actions/scheduler';
import { safe } from '../../../../../helpers/reduxSelectors';
import { processEventNotification } from '../../../../../helpers/util';

class EditTemplate extends React.Component {
  static propTypes = {
    template_id: PropTypes.number,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    provider_id: PropTypes.number,
    provider_roles: PropTypes.array
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.props.form.resetFields();
    }
    processEventNotification(prevProps,this.props,'updateTemplateEvent','Template Updated');
  }

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updateTemplate(this.props.provider_id,this.props.template_id,{
          ...values,
          max_total_hrs: (this.props.is_prescriber) ? values.total_hrs : values.max_total_hrs,
          start_date: moment(values.start_date).tz('America/Los_Angeles').format('YYYY-MM-DD'),
          end_date: moment(values.end_date).tz('America/Los_Angeles').format('YYYY-MM-DD')
        })
        this.props.onClose();
      }
    });
  }

  handleDelete = () => {
    const This = this;
    Modal.confirm({
      title: 'Are you sure you want to delete this item?',
      onOk() {
        This.props.removeTemplate(This.props.provider_id,This.props.template_id);
        This.props.onClose();
      },
      onCancel() {
      },
    });
  }

  renderForm = () => {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
      },
    };

    let data = this.props.templateList.find(x=>x.id === this.props.template_id);
    if (!data) {
      data = {};
    }

    const hours_title = (this.props.is_prescriber) ? "Weekly Hours" : 'Min Weekly Hours';

    return (
      <Form className='compressed-form' autoComplete='false'>

        <FormItem {...formItemLayout} label="Clinician Type">
          {getFieldDecorator('roles', {
            initialValue: (this.props.is_prescriber) ? 'Prescriber' : 'Therapist'
          })(
            <span>{(this.props.is_prescriber) ? 'Prescriber' : 'Therapist'}</span>
          )}
        </FormItem>


        <FormItem {...formItemLayout} label="Start Date">
          {getFieldDecorator('start_date', {
            rules: [{ required: true, message: " " }],
            initialValue: (data.start_date) ? moment(data.start_date) : undefined
          })(
            <DatePicker format='MM/DD/YYYY' />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="End Date">
          {getFieldDecorator('end_date', {
            rules: [{ required: true, message: " " }],
            initialValue: (data.end_date) ? moment(data.end_date) : undefined
          })(
            <DatePicker format='MM/DD/YYYY'/>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label={hours_title}>
          {getFieldDecorator('total_hrs', {
            rules: [{ required: true, message: " " }],
            initialValue: (data.total_hrs) ? data.total_hrs : undefined
          })(
            <Input />
          )}
        </FormItem>

        {!this.props.is_prescriber && <FormItem {...formItemLayout} label='Max Weekly Hours'>
          {getFieldDecorator('max_total_hrs', {
            rules: [{ required: true, message: " " }],
            initialValue: (data.max_total_hrs) ? data.max_total_hrs : undefined
          })(
            <Input />
          )}
        </FormItem> }

        <FormItem {...formItemLayout} label="Title">
          {getFieldDecorator('comments', {
            rules: [{ required: true, message: " " }],
            initialValue: data.comments
          })(
            <Input />
          )}
        </FormItem>
      </Form>
    );
  }

  modalFooter = () => {
    return (
      <table style={{width:'100%'}}>
        <tbody>
          <tr>
            <td style={{textAlign:'left'}}>
              <Button key="delete" type="danger" onClick={this.handleDelete}>Delete</Button>
            </td>
            <td style={{textAlign:'right'}}>
              <Button key="back" onClick={this.props.onClose}>Cancel</Button>
              <Button key="save" type="primary" onClick={this.handleOk}>Save</Button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <Modal
        title="Edit Template"
        visible={this.props.visible}
        onCancel={this.props.onClose}
        footer={this.modalFooter()}
      >
        {this.renderForm()}
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    updateTemplateEvent: safe(state,'scheduler.template.update',{}),
    templateList: safe(state,'scheduler.template.list.data',[]),
    is_prescriber: safe(ownProps,'provider_roles',[]).includes('prescriber')
  }
}

export default connect(mapStateToProps,actions)(Form.create()(EditTemplate));
