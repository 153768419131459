import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Spin, DatePicker, Button, Radio } from 'antd';

import { safe } from '../../../helpers/reduxSelectors';
import * as actions from '../../../actions/reports';
import { processEventNotification } from '../../../helpers/util';

class ReportsPayroll extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pageSize: 15,
      currentPage: 1,
      params: {},
      display: false
    }
  }

  componentDidUpdate(prevProps) {
    processEventNotification(prevProps,this.props,'addQueue','Report Added to Queue');
  }

  tableChange = (x) => {
    this.setState({
      currentPage:x.current
    },() => {
      this.props.getPayrollList(this.state.params,this.state.currentPage,this.state.pageSize)
    });
  }

  displayReport = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          params: values,
          currentPage: 1,
          display: true
        },()=>{
          this.props.getPayrollList(this.state.params,this.state.currentPage,this.state.pageSize);
        });
      }
    });
  }

  queueReport = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          params: values,
          display: false,
        },()=>{
          this.props.queueReport(1,this.state.params);
        });
      }
    });
  }

  reportForm = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Form layout="inline">
          <table style={{width:'100%'}}>
            <tbody>
              <tr style={{verticalAlign:'top'}}>
                <td>
                  <Form.Item>
                  {getFieldDecorator('date_type', {
                    initialValue: 'dos'
                  })(
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value="dos">Date of Service</Radio.Button>
                      <Radio.Button value="cd">Creation Date</Radio.Button>
                    </Radio.Group>
                  )}
                  </Form.Item>
                  <Form.Item>
                   {getFieldDecorator('start_date', {
                     rules: [{ required: true, message: ' ' }],
                   })(
                     <DatePicker format='MM/DD/YYYY' placeholder="Starting Date"/>
                   )}
                   </Form.Item>
                   <Form.Item>
                    {getFieldDecorator('end_date', {
                      rules: [{ required: true, message: ' ' }],
                    })(
                      <DatePicker format='MM/DD/YYYY' placeholder="Ending Date"/>
                    )}
                   </Form.Item>
                   &nbsp;
                   <Button type='primary' onClick={this.displayReport}>Display Report</Button>
                </td>
                <td style={{textAlign:'right'}}>
                  <Button onClick={this.queueReport}>Queue Report for Download</Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      </div>
    );
  }

  report = () => {
    const columns = [{
      title: 'Date of Service',
      dataIndex: 'dos',
      key: 'dos',
      width: 100
    },{
      title: 'Responsible Payer',
      dataIndex: 'responsible_payer',
      key: 'responsible_payer',
      width: 150
    },{
      title: 'Rendering Provider',
      dataIndex: 'rendering_provider',
      key: 'redering_provider',
      width: 200
    },{
      title: 'Servicing Provider',
      dataIndex: 'servicing_provider',
      key: 'servicing_provider',
      width: 200
    },{
      title: 'Procedure Code',
      dataIndex: 'cpt',
      key: 'cpt',
      width: 100
    },{
      title: 'Units',
      dataIndex: 'units',
      key: 'units',
      width: '75'
    },{
      title: 'Location Name',
      dataIndex: 'location',
      key: 'location',
      width: 200
    },{
      title: 'Charge Amount',
      dataIndex: 'charge_amount',
      key: 'charge_amount',
      width: 100
    },{
      title: 'Total Payment',
      dataIndex: 'total_payment',
      key: 'total_payment'
    },{
      title: 'Patient DOB',
      dataIndex: 'patient_dob',
      key: 'patient_dob',
      width: 100
    },{
      title: 'Patient Gender',
      dataIndex: 'patient_gender',
      key: 'patient_gender',
      width: 100
    },{
      title: 'Patient Age',
      dataIndex: 'patient_age',
      key: 'patient_age',
      width: 100
    },{
      title: 'Patient ID',
      dataIndex: 'patient_id',
      key: 'patient_id',
      width: 100
    },{
      title: 'Patient HFID',
      dataIndex: 'patient_hfid',
      key: 'patient_hfid',
      width: 100
    },{
      title: 'Patient Name',
      dataIndex: 'patient_name',
      key: 'patient',
      width: 200
    },{
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt'
    }];

    const data = safe(this.props.list,'rows',[]);
    const count = safe(this.props.list,'count',0);

    if (this.state.display) {
      return (
        <Table
          className='reports-sessionlist-table'
          columns={columns}
          dataSource={data}
          bordered={true}
          size='small'
          rowKey='id'
          scroll={{ x:true }}
          onChange={this.tableChange}
          pagination={{
            showQuickJumper: true,
            current: this.state.currentPage,
            pageSize: this.state.pageSize,
            total: count
          }}
        />
      );
    }
  }
//             <span style={{float:'right'}}><a href="/api/v1/reports/payroll?csv=1">CSV Download</a></span>

  render() {
    return (
      <div style={{padding:'15px'}}>
        <Spin spinning={this.props.isBusy}>
          <div style={{paddingBottom:'10px'}}>
            <Link to='/reports'>&lt;&lt; Back to list of reports</Link>
          </div>

          <h3>Payroll Report</h3>

          { this.reportForm() }
          { this.report() }

        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    list: safe(state,'reports.payroll.data',{}),
    isBusy: safe(state,'reports.payroll.processing',false),
    addQueue: safe(state,'reports.queue.add',{})
  }
}

export default connect(mapStateToProps,actions)(Form.create()(ReportsPayroll));
