import React, { Component } from 'react';

import {
  DownloadOutlined,
  LockOutlined,
  PlusOutlined,
  SmileOutlined,
  UnlockOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { Menu, Dropdown, Button, Divider } from 'antd';
import { Route, withRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import NewServiceModal from './Billing_/NewServiceModal';
import Patients from './Billing_/Patients';
import EditPatientContainer from '../shared/EditPatientContainer';
import Ar from './Billing_/Ar';
import Payers from './Billing_/Payers';
import PayersEdit from './Billing_/PayersEdit';
import Tpa from './Billing_/Tpa';
import TpaEdit from './Billing_/TpaEdit';
import Payments from './Billing_/Payments';
import PaymentEntry from './Billing_/Payments_/PaymentEntry';
import PtPaymentApply from './Billing_/PtPaymentApply';
import Claims from './Billing_/Claims';
import ClaimSearch from './Billing_/ClaimSearch';
import Era from './Billing_/Era';
import HF from './Billing_/Era_/HF';
import EraReconcile from './Billing_/Era_/EraReconcile';
import Clearinghouse from './Billing_/Clearinghouse';
import RecallList from './Billing_/RecallList';
import Autopay from './Billing_/Autopay';
import HeaderLogo from '../shared/HeaderLogo';
import AppSelector from '../shared/AppSelector';
import PatientSelector from '../shared/PatientSelector';
import Timeout from '../shared/Timeout';
import RecentList from './Billing_/RecentList';
import { processEventNotification } from '../../helpers/util';

import { VLayout, VLayoutHeader, VLayoutContent, HLayout, HLayoutLeft, HLayoutContent } from '../shared/Layout';

import { safe } from '../../helpers/reduxSelectors';
import * as billing_actions from '../../actions/billing';
import * as shared_actions from '../../actions/shared';

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  componentDidMount() {
    this.props.getBillingRecentList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.getBillingRecentList();
    }
    processEventNotification(prevProps, this.props, 'applyEvent', 'Payment applied');
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  onClickLogout = () => {
    this.props.history.push('/logout');
  };

  onSelectPatient = (patient_id) => {
    this.props.history.push('/billing/patients/' + patient_id);
  };

  menuClick = (event) => {
    switch (event.key) {
      case 'apply':
        this.props.applyPtFundsCredits(this.props.patientId);
        break;
      case 'archive':
        this.props.archiveChart(this.props.patientId);
        break;
      case 'unarchive':
        this.props.unarchiveChart(this.props.patientId);
        break;
      case 'hold':
        this.props.placeChartOnHold(this.props.patientId);
        break;
      case 'unhold':
        this.props.removeChartHold(this.props.patientId);
        break;
      case 'newservice':
        this.setState({ modalVisible: true });
        break;
      default:
        break;
    }
  };

  renderHeader = () => {
    // Only on the patient page show these options!
    const isLedgerPage = /^\/billing\/patients\/(\d+)/.test(this.props.location.pathname);

    const menu = (
      <Menu onClick={this.menuClick} style={{ border: '1px solid #2b8ed8' }}>
        {this.props.patientId && (
          <Menu.Item key="newservice">
            <PlusOutlined />
            <span>New Service Line</span>
          </Menu.Item>
        )}

        {this.props.patientId && (
          <Menu.Item key="apply">
            <SmileOutlined />
            <span>Apply Patient Payments/Credits</span>
          </Menu.Item>
        )}

        {this.props.isChartOpen && this.props.patientId && (
          <Menu.Item key="archive">
            <UploadOutlined />
            <span>Archive this Chart</span>
          </Menu.Item>
        )}

        {!this.props.isChartOpen && this.props.patientId && (
          <Menu.Item key="unarchive">
            <DownloadOutlined />
            <span>Unarchive this Chart</span>
          </Menu.Item>
        )}

        {!this.props.isChartOnHold && this.props.patientId && (
          <Menu.Item key="hold">
            <LockOutlined />
            <span>Put this Chart on Hold</span>
          </Menu.Item>
        )}

        {this.props.isChartOnHold && this.props.patientId && (
          <Menu.Item key="unhold">
            <UnlockOutlined />
            <span>Remove Hold from this Chart</span>
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <div
        style={{ background: '#2b8ed8', color: 'white', paddingTop: '10px', paddingLeft: '0px', paddingRight: '0px' }}
      >
        <HeaderLogo title="PCPA Billing" />
        <PatientSelector onSelectPatient={this.onSelectPatient} />

        {isLedgerPage && this.props.patientId !== 0 && (
          <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
            <Button shape="circle" icon={<PlusOutlined />} size="small" style={{ marginLeft: '20px' }} />
          </Dropdown>
        )}
        <AppSelector activeUserName={this.props.activeUserName} />
        <NewServiceModal
          visible={this.state.modalVisible}
          closeModal={this.closeModal}
          patientId={this.props.patientId}
        />
      </div>
    );
  };

  showRecentList = () => {
    if (this.props.recent.length > 0) {
      return (
        <div style={{ marginTop: '10px', paddingTop: '5px', paddingLeft: '5px', borderTop: '1px solid lightgray' }}>
          <h4>Recent Actions</h4>
          <RecentList data={this.props.recent} />
        </div>
      );
    }
    return null;
  };

  onClickMenu = ({ key }) => {
    switch (key) {
      case 'patients':
        let currentPatient = this.props.billingState.patientId;
        if (!currentPatient) {
          currentPatient = 0;
        }
        this.props.history.push('/billing/patients/' + currentPatient);
        break;
      case 'ar':
        if (this.props.activeArTab === '2') {
          this.props.history.push('/billing/ar/2?c=' + this.props.activeArCarrier);
        } else {
          this.props.history.push('/billing/ar/' + this.props.activeArTab);
        }
        break;
      case 'payments':
        this.props.history.push('/billing/payments/' + this.props.payments_tab);
        break;
      default:
        this.props.history.push('/billing/' + key);
    }
  };

  navMenu = () => {
    let pathname = this.props.location.pathname;
    let folders = pathname.split('/');
    let page = folders[2];

    return (
      <Menu style={{ padding: '0px', borderRight: '0px' }} onClick={this.onClickMenu} selectedKeys={[page]}>
        <Menu.Item key="patients">
          <span>Patients</span>
        </Menu.Item>

        <Menu.Item key="ar">
          <span>A/R</span>
        </Menu.Item>

        <Menu.Item key="payments">
          <span>Payments</span>
        </Menu.Item>

        <Menu.Item key="recalllist">
          <span>Recall List</span>
        </Menu.Item>

        <Menu.Item key="ptpaymentapply">
          <span>Apply Pt Payments</span>
        </Menu.Item>

        <Menu.Item key="autopay">
          <span>Autopay</span>
        </Menu.Item>

        <Menu.Item key="era">
          <span>ERA</span>
        </Menu.Item>

        <Menu.Item key="claims">
          <span>Claims</span>
        </Menu.Item>

        <Menu.Item key="clearinghouse">
          <span>Clearinghouse</span>
        </Menu.Item>

        <Menu.Item key="tpa">
          <span>Third-Party Processors</span>
        </Menu.Item>

        <Menu.Item key="payers">
          <span>Payers</span>
        </Menu.Item>

        <Menu.Item key="search">
          <span>Search</span>
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    return (
      <Timeout>
        <VLayout>
          <VLayoutHeader style={{ height: '50px', minHeight: '50px', backgroundColor: '#2b8ed8', color: 'white' }}>
            {this.renderHeader()}
          </VLayoutHeader>

          <VLayoutContent>
            <HLayout>
              <HLayoutLeft
                style={{ width: '200px', minWidth: '200px', borderRight: '1px solid #e8e8e8', overflow: 'auto' }}
              >
                {this.navMenu()}
                {this.showRecentList()}
                <Divider />
              </HLayoutLeft>

              <HLayoutContent>
                <Switch>
                  <Route path="/billing/patients/:patient_id/edit" component={EditPatientContainer} />
                  <Route path="/billing/patients/:id" component={Patients} />
                  <Route path="/billing/ar/:id" component={Ar} />
                  <Route path="/billing/payers/:id" component={PayersEdit} />
                  <Route path="/billing/payers" component={Payers} />
                  <Route path="/billing/tpa/:id" component={TpaEdit} />
                  <Route path="/billing/tpa" component={Tpa} />
                  <Route path="/billing/payments/:type/:id" component={PaymentEntry} />
                  <Route path="/billing/payments/:type" component={Payments} />
                  <Route path="/billing/era/reconcile/:id" component={EraReconcile} />
                  <Route path="/billing/era/detail/:id" component={HF} />
                  <Route path="/billing/era" component={Era} />
                  <Route path="/billing/clearinghouse" component={Clearinghouse} />
                  <Route path="/billing/recalllist" component={RecallList} />
                  <Route path="/billing/ptpaymentapply" component={PtPaymentApply} />
                  <Route path="/billing/autopay" component={Autopay} />
                  <Route path="/billing/claims" component={Claims} />
                  <Route path="/billing/search" component={ClaimSearch} />
                </Switch>
              </HLayoutContent>
            </HLayout>
          </VLayoutContent>
        </VLayout>
      </Timeout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeUserName: safe(state, 'shared.domain.activeUser.full_name', null),
    billingState: safe(state, 'shared.domain.billing', {}),
    isChartOnHold: safe(state, 'shared.patient.load.data.on_hold', false),
    isChartOpen: safe(state, 'shared.patient.load.data.open', true),
    patientId: safe(state, 'shared.domain.billing.patientId'),
    activeArTab: safe(state, 'shared.domain.billing.ar_tab', '1'),
    activeArCarrier: safe(state, 'shared.domain.billing.ar_carrier', '0'),
    payments_tab: safe(state, 'shared.domain.billing.payments_tab', '1'),
    recent: safe(state, 'billing.recent.list.data', []),
    applyEvent: safe(state, 'billing.ptpayments.apply', {}),
  };
};

export default connect(mapStateToProps, { ...shared_actions, ...billing_actions })(withRouter(Billing));
