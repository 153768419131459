import * as types from '../../actions/billing/types';

const initialState = {
  load: {},
  save: {},
  create: {},
  history: {},
  reprice: {},
  list: {},
  adjustpt: {},
  orphan: {},
  addnote: {},
}

export default function(state = initialState, action) {
  switch (action.type) {

    case types.BILLING_SERVICELINE_LOAD_REQUEST:
      return {
        ...state,
        load: {
          processing: true
        }
      };

    case types.BILLING_SERVICELINE_LOAD_SUCCESS:
      return {
        ...state,
        load: {
          processing: false,
          success: true,
          data: action.payload
        }
      };

    case types.BILLING_SERVICELINE_LOAD_FAILURE:
      return {
        ...state,
        load: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_SERVICELINE_LOAD_CLEAR:
      return {
        ...state,
        load: {}
      };

    case types.BILLING_SERVICELINE_SAVE_REQUEST:
      return {
        ...state,
        save: {
          processing: true
        }
      }

    case types.BILLING_SERVICELINE_SAVE_SUCCESS:
      return {
        ...state,
        save: {
          processing: false,
          success: true
        }
      }

    case types.BILLING_SERVICELINE_SAVE_FAILURE:
      return {
        ...state,
        save: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_SERVICELINE_SAVE_CLEAR:
      return {
        ...state,
        save: {}
      };


    case types.BILLING_SERVICELINE_CREATE_REQUEST:
      return {
        ...state,
        create: {
          processing: true
        }
      }

    case types.BILLING_SERVICELINE_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          processing: false,
          success: true
        }
      }

    case types.BILLING_SERVICELINE_CREATE_FAILURE:
      return {
        ...state,
        create: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_SERVICELINE_CREATE_CLEAR:
      return {
        ...state,
        create: {}
      };

    case types.BILLING_SERVICELINE_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          processing: true
        }
      }

    case types.BILLING_SERVICELINE_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          processing: false,
          success: true
        }
      }

    case types.BILLING_SERVICELINE_HISTORY_FAILURE:
      return {
        ...state,
        history: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_SERVICELINE_HISTORY_CLEAR:
      return {
        ...state,
        history: {}
      };


    case types.BILLING_SERVICELINE_REPRICE_REQUEST:
      return {
        ...state,
        reprice: {
          processing: true
        }
      };

    case types.BILLING_SERVICELINE_REPRICE_SUCCESS:
      return {
        ...state,
        reprice: {
          processing: false,
          success: true,
          data: action.payload
        }
      };

    case types.BILLING_SERVICELINE_REPRICE_FAILURE:
      return {
        ...state,
        reprice: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_SERVICELINE_REPRICE_CLEAR:
      return {
        ...state,
        reprice: {}
      };

    case types.BILLING_SERVICELINE_LIST_REQUEST:
      return {
        ...state,
        list: {
          processing: true
        }
      };

    case types.BILLING_SERVICELINE_LIST_SUCCESS:
      return {
        ...state,
        list: {
          processing: false,
          success: true,
          data: action.payload
        }
      };

    case types.BILLING_SERVICELINE_LIST_FAILURE:
      return {
        ...state,
        list: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_SERVICELINE_LIST_CLEAR:
      return {
        ...state,
        list: {}
      };


    case types.BILLING_SERVICELINE_ADJUSTPT_REQUEST:
      return {
        ...state,
        adjustpt: {
          processing: true
        }
      };

    case types.BILLING_SERVICELINE_ADJUSTPT_SUCCESS:
      return {
        ...state,
        adjustpt: {
          processing: false,
          success: true,
          data: action.payload
        }
      };

    case types.BILLING_SERVICELINE_ADJUSTPT_FAILURE:
      return {
        ...state,
        adjustpt: {
          processing: false,
          success: false,
          error: action.error
        }
      };

    case types.BILLING_SERVICELINE_ADJUSTPT_CLEAR:
      return {
        ...state,
        adjustpt: {}
      };

      case types.BILLING_SERVICELINE_ORPHAN_REQUEST:
        return {
          ...state,
          orphan: {
            processing: true
          }
        };

      case types.BILLING_SERVICELINE_ORPHAN_SUCCESS:
        return {
          ...state,
          orphan: {
            processing: false,
            success: true,
            data: action.payload
          }
        };

      case types.BILLING_SERVICELINE_ORPHAN_FAILURE:
        return {
          ...state,
          orphan: {
            processing: false,
            success: false,
            error: action.error
          }
        };

      case types.BILLING_SERVICELINE_ORPHAN_CLEAR:
        return {
          ...state,
          orphan: {}
        };


        case types.BILLING_SERVICELINE_ADDNOTE_REQUEST:
          return {
            ...state,
            addnote: {
              processing: true
            }
          };

        case types.BILLING_SERVICELINE_ADDNOTE_SUCCESS:
          return {
            ...state,
            addnote: {
              processing: false,
              success: true,
              data: action.payload
            }
          };

        case types.BILLING_SERVICELINE_ADDNOTE_FAILURE:
          return {
            ...state,
            addnote: {
              processing: false,
              success: false,
              error: action.error
            }
          };

        case types.BILLING_SERVICELINE_ADDNOTE_CLEAR:
          return {
            ...state,
            addnote: {}
          };

    default:
      return state;
  }
}
