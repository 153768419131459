import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import BreakLines from './BreakLines';
import AttachmentList from './AttachmentList';

import { safe } from '../../../../../../helpers/reduxSelectors';

class GeneralChartNote extends Component {

  static defaultProps = {
    print: false
  };

  static propTypes = {
    providerName: PropTypes.string.isRequired,
    note: PropTypes.string,
    signedDateTime: PropTypes.string,
    editURL: PropTypes.string,
    print: PropTypes.bool
  };

  renderTitle = (x) => {
    const hasLink = (this.props.signedDateTime === null && this.props.providerId === this.props.userId);
    return (x ? (
      <div className='chartnotetitle'>
        {hasLink ? (<Link to={this.props.editURL}>{x}</Link>) : x}
      </div>) : null);
  }

  renderProvider = () => {
    return (this.props.providerName ? (
      <div className='chartnotefield'>
        <span className='chartnotefieldlabel'>Provider </span>{this.props.providerName}
      </div>) : null);
  }

  renderNote = () => {
    return (this.props.note ? (
      <div className='chartnotefield'>
        <span className='chartnotefieldlabel'>Note </span><br />
        <BreakLines text={this.props.note}/>
      </div>
    ) : null);
  }

  renderSignature = () => {
    return (this.props.signedDateTime ? (
      <div className='chartnotesignature'>
        Electronically signed by {this.props.providerName} at {moment(this.props.signedDateTime).format("MM/DD/YYYY h:mm A")}
      </div>
    ) : null);
  }

  render() {
    const noteUnsigned = (this.props.signedDateTime === null) ? true : false;
    const noteAuthorIsThisProvider = (this.props.providerId === this.props.userId) ? true : false;
    const noteInProcess = (noteUnsigned && !noteAuthorIsThisProvider);

    /*
    if (noteInProcess) {
      return (
        <div style={{border:'1px dashed lightgray',backgroundColor:'rgb(219,236,252)',marginRight:'20px'}}>
          <div className='chartnotetitle' style={{padding:'20px'}}>
            General Chart Note by {this.props.providerName} in process...
          </div>
        </div>
      );
    } else {
    */
    return (
      <div>
        { !noteInProcess && this.renderTitle('General Chart Note') }
        { noteInProcess && this.renderTitle('IN PROCESS: General Chart Note') }
        { this.renderProvider() }
        { this.renderNote() }
        { this.renderSignature() }
        <AttachmentList list={this.props.attachments} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    attachments: safe(ownProps,'data.attachments',[]),
    providerName: safe(ownProps,'data.provider.full_name'),
    note: safe(ownProps,'data.note.general_note'),
    signedDateTime: safe(ownProps,'data.signedAt',null),
    providerId: safe(ownProps,'data.provider_id'),
    editURL:'/emr/patients/'+ownProps.data.patient_id+'/generalnote/'+ownProps.data.id
  }
}

export default connect(mapStateToProps,null)(GeneralChartNote);
