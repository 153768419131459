/**
 * Dashboard.tsx
 * The main dashboard which displays the Select Report popover,
 * a "processed" reports list and a "scheduled" reports list.
 * The dashboard refreshes every 15 seconds to grab the user's
 * latest reports. checkAnyInstanceStatus() simply filters the
 * reports list to split it into either of the two lists.
 */
import { useEffect, useState } from 'react';
import SelectReportPopover from './SelectReportPopover';
import ProcessedReports from './Dashboard_/ProcessedReports';
import ScheduledReports from './Dashboard_/ScheduledReports';
import useApiGet from 'hooks/useApiGet';
import { SCHEDULE_UNPREPARED } from 'reports';

const Dashboard: React.FC = () => {
  const [trigger, setTrigger] = useState(false);
  const myReports = useApiGet('/api/v2/reports', [], [trigger]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!myReports.loading) {
        setTrigger((t) => !t);
      }
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  const checkAnyInstanceStatus = (instances, equal: boolean) => {
    let qualifies = false;
    if (instances.length) {
      instances.forEach((instance) => {
        const { status } = instance;
        if ((equal && status === 0) || (!equal && status !== 0)) {
          qualifies = true;
        }
      });
    }
    return qualifies;
  };

  const scheduledReports = myReports?.data
    ? myReports?.data.filter((report) => {
        return report.status === SCHEDULE_UNPREPARED || checkAnyInstanceStatus(report.instances, true);
      })
    : [];

  const processedReports = myReports?.data
    ? myReports?.data.filter((report) => {
        return report.status !== SCHEDULE_UNPREPARED && checkAnyInstanceStatus(report.instances, false);
      })
    : [];

  return (
    <div className="p4">
      <SelectReportPopover />
      <ProcessedReports data={processedReports} />
      <ScheduledReports data={scheduledReports} />
    </div>
  );
};

export default Dashboard;
