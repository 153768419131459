import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Layout, Spin, Select } from 'antd';
import numeral from 'numeral';
import { withRouter, Link } from 'react-router-dom';

import * as billing_actions from '../../../../actions/billing';
import * as shared_actions from '../../../../actions/shared';
import { safe } from '../../../../helpers/reduxSelectors';

class PtArTrad extends Component {

  changePage = ({current}) => {
    this.props.setBillingState({
      ar_current_page_1: current
    });
  }

  changeSort = (x) => {
    this.props.setBillingState({
      ar_page_1_sort: x
    });
  }
/*
  changeRows = ({current}) => {
    this.props.setBillingState({
      ar_page_1_rows: current
    });
  }
  */

  componentDidUpdate(prevProps) {
    if (prevProps.ar_current_page_1 !== this.props.ar_current_page_1 ||
        prevProps.ar_page_1_sort !== this.props.ar_page_1_sort) {
      this.props.loadPtAr(this.props.ar_current_page_1*this.props.pageSize,
        this.props.pageSize,this.props.ar_page_1_sort);
    }
  }

  render() {
    const Option = Select.Option;

    const columns=[{
      title: 'Patient',
      dataIndex: 'patient_name',
      render: (value,record) => {
        return <Link to={'/billing/patients/'+record.patient_id}>{value}</Link>
      }
    },{
      title: '0-30 Days',
      dataIndex: 'ar0to30',
      align:'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    },{
      title: '31-60 Days',
      dataIndex: 'ar31to60',
      align:'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    },{
      title: '61-90 Days',
      dataIndex: 'ar61to90',
      align:'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    },{
      title: '90 Days+',
      dataIndex: 'arOver90',
      align: 'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    },{
      title: 'Total Balance',
      dataIndex: 'total',
      align:'right',
      render: (value,record) => {
        return numeral(value).format('$0,0.00')
      }
    }];

    const { Content } = Layout;

    const count = safe(this.props,'data.count',0);
    const rows = safe(this.props,'data.rows',[]);

    return (
      <Layout>
        <Content style={{backgroundColor:'white'}}>
          <Spin spinning={this.props.isBusy}>
            <div>
              <table style={{marginBottom:'10px',width:'100%'}}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Sort By</strong>: &nbsp;
                      <Select
                        style={{width:'300px'}}
                        value={this.props.ar_page_1_sort}
                        onChange={this.changeSort}>
                        <Option value={0}>Total Balance</Option>
                        <Option value={4}>&gt;90 Days</Option>
                        <Option value={3}>61-90 Days</Option>
                        <Option value={2}>31-60 Days</Option>
                        <Option value={1}>0-30 Days</Option>
                      </Select>
                    </td>
                    <td style={{textAlign:'right'}}>
                      <a href='/api/v1/ar/pt?start=1&limit=1&sortcolumn=1&csv=1'>CSV Download</a>
                    </td>
                  </tr>
                </tbody>
              </table>

              <Table
                columns={columns}
                dataSource={rows}
                onChange={this.changePage}
                pagination={{
                  current: this.props.ar_current_page_1,
                  pageSize: this.props.pageSize,
                  total: count,
                  showQuickJumper: true
                }}
                size='small'
                rowKey='patient_id'
              />
            </div>
          </Spin>
        </Content>
      </Layout>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBusy: safe(state,'billing.ar.list1.processing',false) ? true : false,
    data: safe(state,'billing.ar.list1.data',{}),
    ar_current_page_1: safe(state,'shared.domain.billing.ar_current_page_1',0),
    ar_page_1_sort: safe(state,'shared.domain.billing.ar_page_1_sort',0)
  }
}

export default connect(mapStateToProps,{...shared_actions,...billing_actions})(withRouter(PtArTrad));
