import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Divider } from 'antd';

import AddProblemItem from './ProblemList_/AddProblemItem';
import ActiveProblemList from './ProblemList_/ActiveProblemList';
import InActiveProblemList from './ProblemList_/InActiveProblemList';

import * as actions from '../../../../../actions/emr';
import { safe } from '../../../../../helpers/reduxSelectors';

class ProblemList extends Component {
  static propTypes = {
    patientId: PropTypes.number,
    onChange: PropTypes.func
  }

  componentDidMount() {
    this.props.getProblemList(this.props.patientId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.patientId !== this.props.patientId) {
      this.props.getProblemList(this.props.patientId);
    }

    if (prevProps.main !== this.props.main && this.props.onChange) {
      this.props.onChange(this.props.main);
    }
  }

  displayDiagnosis = (x) => {
    if (x['diagnosis.icd10cm']) {
      return <span> ({x['diagnosis.icd10cm'] + '-' + x['diagnosis.description']})</span>
    }
    return null;
  }

  findMissing = () => {
    const missing = this.props.previousMain.filter(x =>
      !(this.props.main.find(y => (y.id === x.id)))
    );
    if (missing.length > 0) {
      return (
        <div style={{color:'red'}}>
          {missing.map(x => (<div key={x.id}>Discontinued Problem: {x.description} {this.displayDiagnosis(x)}</div>))}
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        <Divider />
        <h3>Problem List and Diagnosis</h3>
        <AddProblemItem patientId={this.props.patientId}/>
        <ActiveProblemList
          patientId={this.props.patientId}
          list={this.props.main}
          previousList={this.props.previousMain}
        />
        {this.findMissing()}
        <InActiveProblemList patientId={this.props.patientId} list={this.props.other}/>
        <Divider />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    main: safe(state,'emr.problemlist.load.data.main',[]),
    previousMain: safe(state,'emr.problemlist.load.data.previousMain',[]),
    other: safe(state,'emr.problemlist.load.data.other',[])
  }
}

export default connect(mapStateToProps,actions)(ProblemList);
