import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import moment from 'moment';

import { safe } from '../../../../../../helpers/reduxSelectors';
import * as actions from '../../../../../../actions/billing';

class Policy extends React.Component {

  static propTypes = {
    data: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    }
  }

  renderSelfPay = () => {
    return null;
  }

  renderInsPay = () => {
    if (this.props.self_pay) {
      return null;
    }

    return (
      <div>
        <div>Policy #: {this.props.policy_id_num}</div>
        <div>Copay: {this.props.copay}</div>
        <div>Coinsurance: {this.props.coinsurance}%</div>
        {this.props.carrier_phone && <div>Carrier Phone: {this.props.carrier_phone}</div>}
      </div>
    );
  }

  onClickEditPolicy = () => {
    this.props.showDialog(this.props.id,this.props.billing_patient_id,this.props.data);
  }

  onClickDeletePolicy = () => {
    this.props.deleteBillingPolicy(this.props.billing_patient_id,this.props.id);
  }

  render() {

    if (!this.props.id) {
      return (
        <div className='policy-card'>
          <h4 style={{textAlign:'center'}}>No Payment Policies On File</h4>
        </div>
      );
    }

    return (
      <div className='policy-card'>
        <h4>
          {this.props.carrierName} ({this.props.id})
          <span style={{float:'right'}}>
            <a onClick={this.onClickEditPolicy}>Edit</a>
          </span>
        </h4>
        <div>
          <span className='policy-card-date'>
            {moment(this.props.start_date).format('M/DD/YYYY')}
          </span>
          &nbsp;-&nbsp;
          <span className='policy-card-date'>
            {moment(this.props.end_date).format('M/DD/YYYY')}
          </span>
        </div>
        { this.renderSelfPay() }
        { this.renderInsPay() }
        <div align='right'>
          <Popconfirm
            placement='topRight'
            title="Are you sure delete this billing policy?"
            onConfirm={this.onClickDeletePolicy}
            okText="Yes"
            cancelText="No"
          >
            <a><DeleteOutlined /></a>
          </Popconfirm>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    id: safe(ownProps,'data.id',undefined),
    billing_patient_id: safe(ownProps,'data.billing_patient_id',undefined),
    start_date: moment(safe(ownProps,'data.start_date',undefined)),
    end_date: moment(safe(ownProps,'data.end_date',undefined)),
    policy_id_num: safe(ownProps,'data.policy_id_num',undefined),
    copay: safe(ownProps,'data.copay',0.0),
    coinsurance: safe(ownProps,'data.coinsurance',0.0),
    carrier_phone: safe(ownProps,'data.carrier_phone',undefined),
    carrierName: safe(ownProps,'data',{})['carriers.name'],
    self_pay: safe(ownProps,'data',{})['carriers.selfpay']
  }
}

export default connect(mapStateToProps,actions)(Policy);
