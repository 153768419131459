import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { safe } from '../../../../../../helpers/reduxSelectors';

class ConsentsNote extends React.Component {

  static propTypes = {
    data: PropTypes.object,
    print: PropTypes.bool
  }

  signature = () => {
    const signature = safe(this.props.data,'note.signature');
    const name = safe(this.props.data,'note.signature_name');
    const completed_date = safe(this.props.data,'signedAt');

    return (
      <div>
        <div><strong>Signature</strong>:</div>
        <img style={{border:'1px solid lightgray'}} alt='signature' src={(signature) ? signature : ''}/>
        <div><strong>Signed By</strong>: {(name) ? name : ''}</div>
        <div><strong>Signed Date/Time</strong>: {moment(completed_date).format('M/D/YYYY h:mm:ss A')}</div>
      </div>
    );
  }

  getConsentText = () => {
    const ct = safe(this.props,'data.note.consent','');

    return (
      <div dangerouslySetInnerHTML={{
        __html: '<div>' + ct + '</div>'
      }} />
    );
  }

  render() {
    return (
      <div>
        <div className='chartnotetitle'>
          Patient Consent
        </div>
        <div>
          { this.getConsentText() }
        </div>
        <div>
          { this.signature() }
        </div>
      </div>
    );
  }

}

export default ConsentsNote;
